import axios from "axios"
import useSWR from "swr"

import { getApiBaseUrl } from "app/utils"

export interface V0RedirectResponse {
  redirectUrl: string
}

export default function useV0Redirect(
  orderId: string,
  orderedResultId: string,
  token: string
) {
  return useSWR<V0RedirectResponse>("patient_portal/v0_redirect", {
    async fetcher(url) {
      const response = await axios.request<V0RedirectResponse>({
        baseURL: getApiBaseUrl() + "/api/",
        url,
        method: "post",
        data: {
          orderId,
          orderedResultId,
          token,
        },
      })
      return response.data
    },
  })
}
