import { combineReducers } from "redux"

import orders from "./orders.reducer"
import splitit from "./splitit.reducer"

const reducer = combineReducers({
  orders,
  splitit,
})

export default reducer
