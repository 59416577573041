import { StorefrontLabTestType } from "app/hooks/use-resource"
import { EmptyComparisonValue } from "app/main/comparison/components/ComparisonLabTests/components"
import { pluralizeDays } from "app/main/comparison/utils"

interface Props {
  labTest: StorefrontLabTestType
}

const TurnAroundTimeColumn = ({ labTest }: Props) => {
  return (
    <>
      {labTest?.attributes.estimated_days_for_results ? (
        `${labTest.attributes.estimated_days_for_results} ${pluralizeDays(
          labTest.attributes.estimated_days_for_results
        )}`
      ) : (
        <EmptyComparisonValue />
      )}
    </>
  )
}

export default TurnAroundTimeColumn
