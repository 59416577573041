import BodyText from "app/components/design-system/BodyText"

interface Props {
  icon: React.ReactNode
  title: string
}

const SettingPill = ({ icon, title }: Props) => {
  return (
    <div className="flex w-auto px-2 py-[2px] bg-sky-100  rounded-full gap-1 items-center">
      {icon}
      <BodyText size="sm" weight="semibold" className="text-sky-600">
        {title}
      </BodyText>
    </div>
  )
}

export default SettingPill
