import { Link as MuiLink } from "@material-ui/core"
import { makeStyles, Theme } from "@material-ui/core"

import SpeechBubbleIcon from "app/assets/icons/speech-bubble-blue.svg"
import BodyText from "app/components/design-system/BodyText"
import DesignSystemButton from "app/components/design-system/Button"
import {
  AnyLimitedLabTest,
  LabCompany,
  Practitioner,
  PractitionerListItem,
} from "app/types"

const useStyles = makeStyles((theme: Theme) => ({
  linkStyle: {
    fontWeight: theme.typography.fontWeightMedium,
    "&:hover": {
      cursor: "pointer",
    },
  },
  bodyTextStyle: {
    fontSize: 13,
    marginBottom: "14px",
    whiteSpace: "pre-line",
    textAlign: "left",
  },
  buttonStyle: {
    alignItems: "center",
  },
  buttonIconStyle: {
    marginRight: 8,
  },
}))

interface Props {
  name: string
  labCompany?: LabCompany
  labTest?: AnyLimitedLabTest
  practitioner?: Practitioner | PractitionerListItem
  unavailableState?: string | boolean
}

const OrderingRightsTooltip = (props: Props) => {
  const classes = useStyles()

  return props.labTest ? (
    <BodyText>
      {`${
        props.labTest.lab_company.short_name
      } typically does not allow practitioners licensed as ${
        props.name
      } to order${
        props.unavailableState ? ` from ${props.unavailableState}` : ""
      }. Please `}
      <MuiLink
        className={classes.linkStyle}
        onClick={() => window.Intercom("showNewMessage")}
      >
        message our team
      </MuiLink>
      {` if you think this is not accurate for you.`}
    </BodyText>
  ) : (
    <>
      <BodyText className={classes.bodyTextStyle}>
        {`Should ${props.practitioner?.titled_full_name} be able to order from ${props.labCompany?.name}?
        Let us know and we can update this for you!`}
      </BodyText>
      <DesignSystemButton
        color="secondary"
        className={classes.buttonStyle}
        onClick={() => window.Intercom("showNewMessage")}
      >
        <img
          className={classes.buttonIconStyle}
          src={SpeechBubbleIcon}
          alt="speech"
        />
        Request Access to {props.labCompany?.name}
      </DesignSystemButton>
    </>
  )
}

export default OrderingRightsTooltip
