import { Cache } from "swr"

export interface SubscriptionCache<Value = any> extends Cache<Value> {
  subscribe(key: string, callback: (prev: any, value: any) => void): () => void
}

export class DefaultSubscriptionCache<Value = any>
  extends Map<string, Value>
  implements SubscriptionCache<Value>
{
  subscriptions: Record<string, ((prev: any, value: any) => void)[]> = {}

  subscribe(
    key: string,
    callback: (prev: any, value: any) => void
  ): () => void {
    const subscribers = (this.subscriptions[key] =
      this.subscriptions[key] || [])

    subscribers.push(callback)

    return () => subscribers.splice(subscribers.indexOf(callback), 1)
  }

  set(key: string, value: Value): this {
    const prev = this.get(key)

    super.set(key, value)

    const subscribers = this.subscriptions[key]
    if (subscribers) {
      for (const subscriber of subscribers) {
        subscriber(prev, value)
      }
    }

    return this
  }
}

export function createCache() {
  return new DefaultSubscriptionCache()
}

export function isSubscriptionCache<Value = any>(
  object: any
): object is SubscriptionCache<Value> {
  return object instanceof DefaultSubscriptionCache
}
