import { KIT_GROUP_TYPE } from "app/constants.typed"
import {
  PatientPortalKit,
  PatientPortalOrderedTest,
} from "app/patient-portal/types"
import useCachedCollection from "app/swr/hooks/use-cached-collection"
import useCachedResource from "app/swr/hooks/use-cached-resource"

import PatientPortalOrderKitListKitTest from "./PatientPortalOrderKitListKitTest"

export default function PatientPortalOrderKitListKit({
  kitId,
}: {
  kitId: string
}) {
  const kit = useCachedResource<PatientPortalKit>({
    type: "patient_portal_kit",
    id: kitId,
  })
  const orderedTests = useCachedCollection<PatientPortalOrderedTest>(
    kit?.relationships.ordered_tests.data
  )

  if (!kit) {
    return null
  }

  switch (kit.attributes.group_type) {
    case KIT_GROUP_TYPE.PANEL: {
      const primaryOrderedTest =
        orderedTests.find(
          (orderedTest) => orderedTest.attributes.lab_results
        ) ||
        orderedTests.find((orderedTest) =>
          Boolean(orderedTest.relationships.specimen_issue?.data)
        ) ||
        orderedTests?.[0]
      if (!primaryOrderedTest) {
        return null
      }
      return (
        <PatientPortalOrderKitListKitTest
          kit={kit}
          orderedTestId={primaryOrderedTest.id}
        />
      )
    }
    case KIT_GROUP_TYPE.PARENT:
    case KIT_GROUP_TYPE.INDIVIDUAL:
    default: {
      return (
        <>
          {kit.relationships.ordered_tests.data.map((orderedTestRef) => (
            <PatientPortalOrderKitListKitTest
              key={orderedTestRef.id}
              kit={kit}
              orderedTestId={orderedTestRef.id}
            />
          ))}
        </>
      )
    }
  }
}
