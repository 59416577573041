import FoodPlanGenerateConfigurationCard from "app/food-plans/components/FoodPlanGenerateConfigurationCard"
import FoodPlanUpdateConfigurationCard from "app/food-plans/components/FoodPlanUpdateConfigurationCard"
import { UsePatientFoodPlanResources } from "app/food-plans/hooks/use-patient-food-plan-resources"
import { FoodPlanVersion } from "types/food-plan-version"

const PatientFoodPlanCreateSidebar = ({
  foodPlanVersion,
  isRestarting,
  isStartingGenerate,
  onStartGenerate,
  onStartCancel,
  onPatch,
  onRestart,
}: {
  foodPlanVersion: FoodPlanVersion
  isRestarting?: boolean
  isStartingGenerate?: boolean
  onPatch: UsePatientFoodPlanResources["onPatch"]
  onRestart: UsePatientFoodPlanResources["onRestart"]
  onStartGenerate: UsePatientFoodPlanResources["onStartGenerate"]
  onStartCancel: UsePatientFoodPlanResources["onStartCancel"]
}) => {
  switch (foodPlanVersion.attributes.state) {
    case "draft":
    default:
      return (
        <FoodPlanGenerateConfigurationCard
          defaultValues={foodPlanVersion.attributes}
          isStartingGenerate={isStartingGenerate}
          onPatch={onPatch}
          onStartGenerate={onStartGenerate}
          onStartCancel={onStartCancel}
        />
      )
    case "ready":
    case "processing":
      return (
        <FoodPlanGenerateConfigurationCard
          defaultValues={foodPlanVersion.attributes}
          isGenerating
          onPatch={onPatch}
          onStartGenerate={onStartGenerate}
          onStartCancel={onStartCancel}
        />
      )
    case "complete":
      return (
        <FoodPlanUpdateConfigurationCard
          defaultValues={foodPlanVersion.attributes}
          isRestarting={isRestarting}
          isStartingGenerate={isStartingGenerate}
          onRestart={onRestart}
          onStartGenerate={onStartGenerate}
          onStartCancel={onStartCancel}
        />
      )
    case "failed":
      return (
        <FoodPlanGenerateConfigurationCard
          defaultValues={foodPlanVersion.attributes}
          errorMessage="Looks like we're having trouble connecting to Rupa AI. We're sorry about that! Please try again later"
          isStartingGenerate={isStartingGenerate}
          onPatch={onPatch}
          onStartGenerate={onStartGenerate}
          onStartCancel={onStartCancel}
        />
      )
  }
}

const PatientFoodPlanUpdateSidebar = ({
  foodPlanVersion,
  isRestarting,
  isStartingGenerate,
  onStartGenerate,
  onStartCancel,
  onRestart,
}: {
  foodPlanVersion: FoodPlanVersion
  isRestarting?: boolean
  isStartingGenerate?: boolean
  onRestart: UsePatientFoodPlanResources["onRestart"]
  onStartGenerate: UsePatientFoodPlanResources["onStartGenerate"]
  onStartCancel: UsePatientFoodPlanResources["onStartCancel"]
}) => {
  switch (foodPlanVersion.attributes.state) {
    case "ready":
    case "processing":
      return (
        <FoodPlanUpdateConfigurationCard
          defaultValues={foodPlanVersion.attributes}
          isGenerating
          onRestart={onRestart}
          onStartGenerate={onStartGenerate}
          onStartCancel={onStartCancel}
        />
      )
    case "draft":
    case "complete":
    default:
      return (
        <FoodPlanUpdateConfigurationCard
          defaultValues={{
            // Suggestions should be undefaulted when we are generating an additional update
            suggestions: "",
            layout: foodPlanVersion.attributes.layout,
          }}
          isRestarting={isRestarting}
          isStartingGenerate={isStartingGenerate}
          onRestart={onRestart}
          onStartGenerate={onStartGenerate}
          onStartCancel={onStartCancel}
        />
      )
    case "failed":
      return (
        <FoodPlanUpdateConfigurationCard
          defaultValues={foodPlanVersion.attributes}
          errorMessage="Looks like we're having trouble connecting to Rupa AI. We're sorry about that! Please try again later"
          isRestarting={isRestarting}
          isStartingGenerate={isStartingGenerate}
          onRestart={onRestart}
          onStartGenerate={onStartGenerate}
          onStartCancel={onStartCancel}
        />
      )
  }
}

export interface PatientFoodPlanSidebarProps {
  foodPlanVersion: FoodPlanVersion
  isRestarting?: boolean
  isStartingGenerate?: boolean
  onStartGenerate: UsePatientFoodPlanResources["onStartGenerate"]
  onPatch: UsePatientFoodPlanResources["onPatch"]
  onRestart: UsePatientFoodPlanResources["onRestart"]
  onStartCancel: UsePatientFoodPlanResources["onStartCancel"]
}

export default function PatientFoodPlanSidebar({
  foodPlanVersion,
  isRestarting,
  isStartingGenerate,
  onPatch,
  onRestart,
  onStartGenerate,
  onStartCancel,
}: PatientFoodPlanSidebarProps) {
  switch (foodPlanVersion.attributes.prompt_type) {
    case "create":
    default:
      return (
        <PatientFoodPlanCreateSidebar
          foodPlanVersion={foodPlanVersion}
          isRestarting={isRestarting}
          isStartingGenerate={isStartingGenerate}
          onPatch={onPatch}
          onRestart={onRestart}
          onStartGenerate={onStartGenerate}
          onStartCancel={onStartCancel}
        />
      )
    case "update":
      return (
        <PatientFoodPlanUpdateSidebar
          isRestarting={isRestarting}
          isStartingGenerate={isStartingGenerate}
          foodPlanVersion={foodPlanVersion}
          onRestart={onRestart}
          onStartGenerate={onStartGenerate}
          onStartCancel={onStartCancel}
        />
      )
  }
}
