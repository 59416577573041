import { Grid, GridSize } from "@material-ui/core"

import BodyText from "app/components/design-system/BodyText"
import { FormFieldLabel } from "app/components/forms/fields"
import { ControlledTextField } from "app/main/patient-checkout/fields"
import makeAppStyles from "app/utils/makeAppStyles"

interface HookFormTextFieldProps {
  label: string
  fieldName: string
  gridSize?: GridSize
  required?: boolean
  fieldPadding?: string
}

const useStyles = makeAppStyles((theme) => ({
  formField: {
    display: "flex",
    flexDirection: "column",
  },
  fieldSet: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
}))

const HookFormTextField = ({
  label,
  fieldName,
  gridSize = 6,
  required = true,
  fieldPadding = "8px",
}: HookFormTextFieldProps) => {
  const classes = useStyles()
  return (
    <Grid
      item
      xs={12}
      md={gridSize}
      className={classes.formField}
      style={{ padding: fieldPadding }}
    >
      <FormFieldLabel
        label={
          <BodyText
            style={{ float: "left", marginRight: 4 }}
            size="base"
            weight="semibold"
          >
            {label}
          </BodyText>
        }
        required={required}
      />
      <ControlledTextField
        className={classes.fieldSet}
        name={fieldName}
        required={required}
      />
    </Grid>
  )
}
export default HookFormTextField
