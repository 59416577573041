import { memo } from "react"

import { ReactComponent as AddIcon } from "app/assets/icons/add-blue.svg"
import useComparisonActions from "app/main/comparison/hooks/use-comparison-actions"
import { colors, primaryColor, titleFontFamily } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"

const useStyles = makeAppStyles((theme) => ({
  buttonContainer: {
    width: `calc(100% + ${theme.spacing(3.5)}px)`,
    // apply negative margin to compensate for extra padding on ComparedLabTest
    margin: theme.spacing(0, -1.75),
    height: "100%",
    minHeight: 70,
  },
  button: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: 10,
    color: primaryColor,
    backgroundColor: colors.blueGray[50],
    border: `1px solid ${colors.blueGray[300]}`,
    borderRadius: 9,
    fontFamily: titleFontFamily,
    fontSize: 15,
    fontWeight: theme.typography.fontWeightMedium,
    "& span": {
      marginTop: 2,
    },
    "&:hover": {
      backgroundColor: colors.lightBlue[50],
      border: `1px solid ${primaryColor}`,
    },
  },
}))

const MemoizedComparisonOpenSearchModalButton = memo(
  ({ actions }: { actions: ReturnType<typeof useComparisonActions> }) => {
    const classes = useStyles()
    return (
      <div className={classes.buttonContainer}>
        <button
          className={classes.button}
          onClick={() => actions.openSearchModal()}
          type="button"
        >
          <AddIcon viewBox="0 0 12 13" />
          <span>Add Test to Comparison</span>
        </button>
      </div>
    )
  }
)

export default function ComparisonOpenSearchModalButton() {
  const actions = useComparisonActions()
  return <MemoizedComparisonOpenSearchModalButton actions={actions} />
}
