import { forwardRef, ComponentProps } from "react"

import clsx from "clsx"

import FormatBold from "@material-ui/icons/FormatBold"
import FormatItalic from "@material-ui/icons/FormatItalic"
import FormatListBulleted from "@material-ui/icons/FormatListBulleted"
import FormatListNumbered from "@material-ui/icons/FormatListNumbered"
import FormatUnderlined from "@material-ui/icons/FormatUnderlined"
import FormatLink from "@material-ui/icons/Link"

import makeAppStyles from "app/utils/makeAppStyles"

const useStyles = makeAppStyles((theme) => ({
  icon: {
    fill: "currentColor",
    fontSize: 18,
  },
}))

const iconMap = {
  format_bold: FormatBold,
  format_italic: FormatItalic,
  format_underlined: FormatUnderlined,
  format_hyperlink: FormatLink,
  format_list_bulleted: FormatListBulleted,
  format_list_numbered: FormatListNumbered,
}

export type IconProps = {
  className?: string
  name: keyof typeof iconMap
} & ComponentProps<typeof FormatBold>

export default forwardRef<any, IconProps>(function Icon(
  { className, name, ...props },
  ref
) {
  const classes = useStyles()
  const IconComponent = iconMap[name]
  return (
    <IconComponent
      {...props}
      className={clsx(className, classes.icon)}
      ref={ref}
    />
  )
})
