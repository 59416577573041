import { useMemo } from "react"

import TableRenderer from "./BiomarkerCustomDescriptionTable/TableRenderer"
import {
  biomarkerDescriptionStateColumn,
  biomarkerNameColumn,
  biomarkerLowDescriptionStateColumn,
  biomarkerHighDescriptionStateColumn,
} from "./BiomarkerCustomDescriptionTable/columns"
import useTable from "./BiomarkerCustomDescriptionTable/hooks/use-table"

const PlaceHolderCustomDescriptionsTable = () => {
  const columns = useMemo(() => {
    return [
      biomarkerNameColumn,
      biomarkerDescriptionStateColumn,
      biomarkerLowDescriptionStateColumn,
      biomarkerHighDescriptionStateColumn,
    ]
  }, [])

  const table = useTable({
    columns,
    data: [],
    isLoading: false,
    isValidating: false,
  })

  return <TableRenderer table={table} />
}

export default PlaceHolderCustomDescriptionsTable
