import { Skeleton } from "@rupahealth/design"

import makeAppStyles from "app/utils/makeAppStyles"

import { STICKY_TABLE_COLUMN_WIDTH } from "../constants/constants"

const useStyles = makeAppStyles(() => ({
  stickyCol: {
    left: 0,
    position: "absolute",
    top: "auto",
    width: STICKY_TABLE_COLUMN_WIDTH,
    textAlign: "left !important" as any,
    boxShadow: "none",
  },
}))

const ResultsOverTimeTableDatesHeaderSkeleton = () => {
  const classes = useStyles()

  return (
    <tr>
      <th className={classes.stickyCol} style={{ opacity: 0 }}>
        Filler
      </th>
      {/* We do not know the number of date columns to render here, so we default
        to 3 columns. */}
      <th className="py-3 text-center" style={{ minWidth: "33%" }}>
        <Skeleton className="w-[150px] h-6" />
      </th>
      <th className="py-3 text-center" style={{ minWidth: "33%" }}>
        <Skeleton className="w-[150px] h-6" />
      </th>
      <th className="py-3 text-center" style={{ minWidth: "33%" }}>
        <Skeleton className="w-[150px] h-6" />
      </th>
    </tr>
  )
}

export default ResultsOverTimeTableDatesHeaderSkeleton
