import { Grid, Paper } from "@material-ui/core"

import { ReactComponent as CircleXIcon } from "app/assets/icons/circular/circle-x.svg"
import { ToUpdateYourInfoMessageUs } from "app/components/OrderingAccess/lab-ordering-access-v2/LabOrderingAccessBanner"
import { ORDERING_ISSUE_TYPES, QUEST_COMPANY_KEYS } from "app/constants"
import useFeatureFlag, { FeatureFlag } from "app/hooks/use-feature-flag"
import { colors } from "app/theme"
import { LabCompanyAlert, OrderingIssue } from "app/types"
import makeAppStyles from "app/utils/makeAppStyles"

import CompanySection from "./CompanySection"
import DetailsSection from "./DetailsSection"
import PatientCollectionInstructionsSection from "./PatientCollectionInstructionsSection"
import PhlebotomySection from "./PhlebotomySection"
import PriceSection from "./PriceSection"
import ProcessingTimeSection from "./ProcessingTimeSection"
import SampleReportSection from "./SampleReportSection"
import ShippingTimeSection from "./ShippingTimeSection"
import StateRestrictions from "./StateRestrictions"
import TypeSection from "./TypeSection"

const useStyles = makeAppStyles({
  separator: {
    borderBottom: `1px solid ${colors.blueGray[200]}`,
  },
  sectionsContainer: {
    "&:not(:first-child)": {
      marginTop: 22.5,
    },
  },
  sectionsGrid: {
    padding: "0px 25px",
  },
  section: {
    paddingTop: 18,
    paddingBottom: 18,
    borderBottom: `1px solid ${colors.blueGray[200]}`,
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    width: "100%",
    height: "100%",
  },
})

interface Props {
  details: string
  rupaUrl: string | undefined
  url: string
  vendorPhysAuthApproved: boolean
  labCompanyName: string
  labCompanyDetails: string
  labCompanyKey: string
  msrpPrice: string
  rupaPrice: string
  labTestTypes: string[]
  shippingDaysMin: number | null
  shippingDaysMax: number | null
  activeShippingAlert?: LabCompanyAlert
  estimatedDaysForResults: number | null
  cheatsheetUrl: string
  sampleReportUrl: string
  stateOrderingRestrictions: object
  isPhlebotomyRequired: boolean
  isAuthenticated?: boolean
  orderingIssue?: OrderingIssue
}

const InformationSection: React.FC<Props> = ({
  details,
  rupaUrl,
  url,
  isAuthenticated = true,
  vendorPhysAuthApproved,
  labCompanyName,
  labCompanyDetails,
  labCompanyKey,
  msrpPrice,
  rupaPrice,
  labTestTypes,
  shippingDaysMin,
  shippingDaysMax,
  activeShippingAlert,
  estimatedDaysForResults,
  cheatsheetUrl,
  sampleReportUrl,
  stateOrderingRestrictions,
  isPhlebotomyRequired,
  orderingIssue,
}) => {
  const styles = useStyles()

  const [beastCoastEnabled] = useFeatureFlag(FeatureFlag.BeastCoast)

  return (
    <Paper className={styles.sectionsContainer}>
      <Grid container aria-label="Test Details" className={styles.sectionsGrid}>
        {orderingIssue && (
          <div className="w-full bg-red-50 p-[10px] rounded-lg mt-5">
            <div className="flex items-center gap-2">
              <CircleXIcon className="fill-red-500 shrink-0" />
              <span>
                <span className="font-semibold">{orderingIssue.message}</span>
                {orderingIssue.type !==
                  ORDERING_ISSUE_TYPES.GENERAL_AVAILABILITY && (
                  <ToUpdateYourInfoMessageUs linkClassName="font-normal text-body underline" />
                )}
              </span>
            </div>
          </div>
        )}
        {(details || rupaUrl || url) && (
          <Grid item xs={12}>
            <div className={styles.section}>
              <DetailsSection
                body={details}
                url={rupaUrl || url}
                vendorPhysAuthApproved={vendorPhysAuthApproved}
              />
            </div>
          </Grid>
        )}
        <Grid container>
          <Grid item xs={12} sm={6}>
            <div className={styles.section}>
              <CompanySection
                name={labCompanyName}
                details={labCompanyDetails}
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={6}>
            <div className={styles.section}>
              <PriceSection
                msrpPrice={msrpPrice}
                rupaPrice={rupaPrice}
                isAuthenticated={isAuthenticated}
                priceVariesByPanel={QUEST_COMPANY_KEYS.includes(labCompanyKey)}
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={6}>
            <div className={styles.section}>
              <TypeSection types={labTestTypes} />
            </div>
          </Grid>
          <Grid item xs={12} sm={6}>
            <div className={styles.section}>
              <PhlebotomySection
                labCompanyKey={labCompanyKey}
                isPhlebotomyRequired={isPhlebotomyRequired}
              />
            </div>
          </Grid>
          {!!(shippingDaysMax || shippingDaysMin) && (
            <Grid item xs={12} sm={6}>
              <div className={styles.section}>
                <ShippingTimeSection
                  shippingDaysMin={shippingDaysMin}
                  shippingDaysMax={shippingDaysMax}
                  activeShippingAlert={activeShippingAlert}
                />
              </div>
            </Grid>
          )}
          {!!estimatedDaysForResults && (
            <Grid item xs={12} sm={6}>
              <div className={styles.section}>
                <ProcessingTimeSection
                  estimatedDaysForResults={estimatedDaysForResults}
                />
              </div>
            </Grid>
          )}
          {cheatsheetUrl && (
            <Grid item xs={12} sm={6}>
              <div className={styles.section}>
                <PatientCollectionInstructionsSection
                  collectionInstructionsUrl={cheatsheetUrl}
                />
              </div>
            </Grid>
          )}
          {sampleReportUrl && (
            <Grid item xs={12} sm={6}>
              <div className={styles.section}>
                <SampleReportSection sampleReportUrl={sampleReportUrl} />
              </div>
            </Grid>
          )}
        </Grid>
        {beastCoastEnabled && (
          <div className={styles.section}>
            <StateRestrictions
              stateOrderingRestrictions={stateOrderingRestrictions}
            />
          </div>
        )}
      </Grid>
    </Paper>
  )
}

export default InformationSection
