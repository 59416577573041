/*
 * The PageToolbar is placed at the top of each main authenticated page. It
 * should have the title of the page (Dashboard, Account Settings, etc) along with
 * any actions that can be performed on that page (e.g. starting an order). The
 * PageToolbar is sticky.
 */
import * as React from "react"

import { makeStyles, Theme } from "@material-ui/core"

import { colors } from "app/theme"

import Markdown from "./Markdown"
import PageBreadcrumbs, { PageBreadcrumb } from "./PageBreadcrumbs"
import DisplayText from "./design-system/DisplayText"

export interface PageToolbarProps {
  breadcrumbActionsSlot?: React.ReactNode
  breadcrumbs?: PageBreadcrumb[]
  children?: React.ReactNode
  fullBorder?: boolean
  greyBackground?: boolean
  subtitle?: React.ReactNode
  title?: string | React.ReactNode
  toolbarsSlot?: React.ReactNode
  topContainerStyle?: any
  titleActionsSlot?: React.ReactNode
}

const useStyles = makeStyles<
  Theme,
  {
    hasSubtitle: boolean
    hasBreadcrumb: boolean
    fullBorder: boolean
    greyBackground: boolean
    hasTitle: boolean
    hasChildren: boolean
  }
>((theme) => ({
  container: {
    background: (props) =>
      props.greyBackground ? colors.coolGray[50] : "white",
    border: (props) =>
      props.fullBorder ? `1px solid ${colors.coolGray[200]}` : "auto",
    borderBottom: `1px solid ${colors.coolGray[200]}`,
    top: 0,
    zIndex: theme.zIndex.mobileStepper,
    [theme.breakpoints.up("md")]: {
      position: "sticky",
    },
  },
  topContainer: {
    display: "flex",
    flexFlow: "column",
    padding: theme.spacing(3.5, 2, 2),

    [theme.breakpoints.up("md")]: {
      justifyContent: "space-between",
      alignItems: "center",
      flexFlow: "row",
      padding: theme.spacing(3, 3, 2.25),
    },
  },
  titleContainer: {
    display: "flex",
    flexFlow: "column",
  },
  mainTitleContainer: {
    display: "flex",
    flexFlow: "row",
    alignItems: "center",
    flexWrap: "wrap",
    gap: 10,
  },
  pageActions: {
    display: "flex",
    flexFlow: "column",
    alignItems: "stretch",
    marginTop: 8,

    "& > *": {
      marginTop: 16,
    },

    [theme.breakpoints.up("md")]: {
      marginTop: 0,
      flexFlow: "row",
      alignItems: "center",

      "& > *": {
        marginTop: 0,
      },

      "& > *:not(:last-child)": {
        marginRight: 16,
      },
    },
  },
  breadcrumbContainer: {
    borderTop: (props) =>
      props.hasTitle ? `1px solid ${colors.coolGray[200]}` : 0,
  },
}))

const Toolbar = ({
  breadcrumbActionsSlot,
  breadcrumbs,
  children,
  fullBorder = false,
  greyBackground = false,
  subtitle,
  title,
  toolbarsSlot,
  topContainerStyle = {},
  titleActionsSlot,
}: PageToolbarProps) => {
  const {
    container,
    topContainer,
    titleContainer,
    mainTitleContainer,
    pageActions,
    breadcrumbContainer,
  } = useStyles({
    hasSubtitle: Boolean(subtitle),
    hasBreadcrumb: Boolean(breadcrumbs),
    fullBorder: Boolean(fullBorder),
    greyBackground: Boolean(greyBackground),
    hasTitle: Boolean(title),
    hasChildren: Boolean(children),
  })

  return (
    <div className={container}>
      {title && (
        <div className={topContainer} style={topContainerStyle}>
          <div className={titleContainer}>
            <div className={mainTitleContainer}>
              <DisplayText size="xl" weight="semibold">
                {title}
              </DisplayText>
              {titleActionsSlot}
            </div>
            {typeof subtitle === "string" ? (
              <Markdown>{subtitle}</Markdown>
            ) : (
              subtitle
            )}
          </div>
          {children && <div className={pageActions}>{children}</div>}
        </div>
      )}

      {(breadcrumbs || breadcrumbActionsSlot) && (
        <PageBreadcrumbs
          actionsSlot={breadcrumbActionsSlot}
          breadcrumbs={breadcrumbs}
          className={breadcrumbContainer}
        />
      )}

      {toolbarsSlot}
    </div>
  )
}

export default Toolbar
