import { useState } from "react"

import useAppSelector from "app/hooks/useAppSelector"
import { getOrderPatientLabel } from "app/utils/order-utils"

import useSnippetFormModal from "./hooks/use-snippet-form-modal"
import useSnippets from "./hooks/use-snippets"
import EmptySnippetsTable from "./table/EmptySnippetsTable"
import SnippetsTable from "./table/SnippetsTable"
import SnippetsTableLoader from "./table/SnippetsTableLoader"

const NotesToClientsSnippetsTable = () => {
  const [currPage, setCurrPage] = useState(1)

  const {
    data: snippets,
    paginationInfo,
    isLoading,
    isValidating,
    updateSnippet,
    deleteSnippet,
  } = useSnippets({
    snippetType: "notes_to_patient",
    page: currPage,
  })

  const practitioner = useAppSelector(({ practitioner }) => practitioner)

  const [isSnippetUpdateLoading, setIsSnippetUpdateLoading] = useState(false)
  const [isSnippetDeleteLoading, setIsSnippetDeleteLoading] = useState(false)

  const snippetFormModal = useSnippetFormModal()

  const handleDelete = async (snippetId: number) => {
    setIsSnippetDeleteLoading(true)

    await deleteSnippet(snippetId)
    await setIsSnippetDeleteLoading(false)

    snippetFormModal.remove()
  }

  const handleSave = async (snippetData: any, snippetId?: number) => {
    if (!snippetId) {
      return
    }

    setIsSnippetUpdateLoading(true)

    await updateSnippet(snippetId, snippetData)

    setIsSnippetUpdateLoading(false)

    snippetFormModal.remove()
  }

  if (isLoading) {
    return <SnippetsTableLoader />
  }

  if (snippets?.length === 0) {
    return (
      <EmptySnippetsTable
        tableType={`Notes to ${getOrderPatientLabel(
          practitioner?.use_physician_services_terminology
        )}`}
      />
    )
  }

  return (
    <>
      <SnippetsTable
        snippets={snippets}
        page={currPage}
        totalNumResults={paginationInfo?.count ?? 0}
        isValidating={isValidating}
        onChangePage={setCurrPage}
        onEditClick={(snippet) =>
          snippetFormModal.show({
            snippet,
            title: "Edit Order Instruction Snippet",
            isSaveLoading: isSnippetUpdateLoading,
            isDeleteLoading: isSnippetDeleteLoading,
            useRichTextEditor: false,
            onClose: snippetFormModal.remove,
            handleDelete,
            handleSave,
          })
        }
      />
    </>
  )
}

export default NotesToClientsSnippetsTable
