import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  isAxiosError,
} from "axios"
import Cookies from "js-cookie"
import jwtDecode from "jwt-decode"
import { isUndefined, omit } from "lodash"

import { captureException, captureMessage } from "@sentry/react"

import { ROLES } from "app/main/login/types"
import { getApiBaseUrl } from "app/utils"

/**
 * Represents the access token response.
 */
export interface AccessTokenResponse {
  access: string
}

/**
 * Represents the refresh ID token.
 */
export interface RefreshIdToken {
  user_id: number
  /**
   * The expiration time of the token in seconds since the Unix epoch.
   */
  exp: number
  token_type: "refresh_id"
}

export type AuthenticateListener = (
  isAuthenticated: boolean,
  next?: string
) => void
export type LogoutListener = (message?: string) => void

/**
 * The name of the cookie that is used to store the refresh ID token.
 */
export const REFRESH_ID_TOKEN_COOKIE = "refresh_id_token"

/**
 * The name of the field in the magic link token to determine if impersonation is being used.
 */
export const IMPERSONATING_FIELD = "impersonating"

/**
 * The name of the field in the magic link token to determine the user who is doing the impersonation.
 */
export const IMPERSONATING_USER_FIELD = "impersonating_user"

/**
 * The bearer token prefix for the Authorization header.
 */
export const BEARER_TOKEN_PREFIX = "Bearer"

export class SimpleJWTAuthService {
  /**
   * The Axios instance that is used to make requests to the authentication endpoints.
   *
   * This instance is configured to send cookies with every request.
   * This instance does not have interceptors configured to automatically refresh the access token.
   */
  private authAxios: AxiosInstance

  /**
   * The cached JWT access token. May be decoded for token claims like expiration.
   */
  private token: string | null = null

  /**
   * A promise to refresh the access token. Used to ensure multiple requests for the access token are not made at one time.
   */
  private tokenRefreshPromise: Promise<void> | null = null

  /**
   * The list of functions to call when the service is authenticated.
   */
  private authenticateListeners: AuthenticateListener[] = []

  /**
   * The list of functions to call when the user is logged out.
   */
  private logoutListeners: LogoutListener[] = []

  constructor({ authAxios }: { authAxios: AxiosInstance }) {
    this.authAxios = authAxios
  }

  /**
   * Gets the raw token.
   *
   * @returns the raw token
   * @internal
   */
  getRawToken(): string | null {
    return this.token
  }

  /**
   * Setter for the token.
   *
   * @param token the access token to set
   * @internal
   */
  setRawToken(token: string | null): void {
    this.token = token
  }

  /**
   * Registers a function to call when the service is authenticated.
   *
   * @param fn the function to call when the service is authenticated
   * @returns a function to unregister the listener
   */
  onAuthenticate(fn: AuthenticateListener): () => void {
    this.authenticateListeners.push(fn)

    return () => {
      this.authenticateListeners = this.authenticateListeners.filter(
        (listener) => listener !== fn
      )
    }
  }

  /**
   * Registers a function to call when the user is logged out.
   *
   * @param fn the function to call when the user is logged out
   * @returns a function to unregister the listener
   */
  onLogout(fn: LogoutListener) {
    this.logoutListeners.push(fn)

    return () => {
      this.logoutListeners = this.logoutListeners.filter(
        (listener) => listener !== fn
      )
    }
  }

  /**
   * Authenticates the service.
   *
   * This process involves the following:
   * - Checking if the user is already logged in
   * - Retrieving an initial access token for the user
   * - Trigger authenticate listeners
   *
   * @param [next] the next URL to redirect to after authentication, if provided
   * @returns A promise that resolves when the authentication process is complete
   */
  async authenticate(next?: string): Promise<void> {
    try {
      if (this.getRefreshIdToken()) {
        await this.getAccessToken()
      }
    } catch (error) {
      // Prevent the error from bubbling up to the top level.
      captureException(error)
    }

    // Always notify listeners whether the service has been authenticated or not.
    this.authenticateListeners.forEach((fn) => fn(this.isAuthenticated(), next))
  }

  /**
   * Log in the user with the given email and password.
   *
   * @param email the email
   * @param password the password
   * @param [role] the role
   */
  async login(email: string, password: string, role?: String): Promise<void> {
    if (this.hasRefreshSession()) {
      // If a user is already authenticated, we should log them out before logging in.
      await this.logout()
    }

    var url = getApiBaseUrl() + "/api/auth/token/"
    if (role === ROLES.PATIENT) {
      url += "patient/"
    }
    const response = await this.authAxios.post<AccessTokenResponse>(url, {
      email,
      password,
    })

    this.setRawToken(response.data.access)

    // Re-authenticate to set up interceptors and trigger post-authentication setup.
    await this.authenticate()
  }

  /**
   * Log in the user given the auth0 token.
   *
   * @param auth0_token the auth0 token being used to log in
   */
  async loginWithAuth0Token(auth0_token: string): Promise<void> {
    if (this.hasRefreshSession()) {
      // If a user is already authenticated, we should log them out before logging in with an auth0 token.
      await this.logout()
    }

    try {
      const response = await this.authAxios.post<AccessTokenResponse>(
        getApiBaseUrl() + "/api/auth/auth0/exchange/",
        {
          auth0_token,
        }
      )

      this.setRawToken(response.data.access)
    } catch (error) {
      // This should rarely fail, so we want to capture the error.
      captureException(error)

      throw error
    }

    // Re-authenticate to set up interceptors and trigger post-authentication setup.
    await this.authenticate()
  }

  /**
   * Log in the user given the magic link token.
   *
   * @param magic_link_token the magic link token being used to log in
   */
  async loginWithMagicLinkToken(
    magic_link_token: string,
    next: string = "/"
  ): Promise<void> {
    if (this.hasRefreshSession()) {
      // If a user is already authenticated, we should log them out before logging in with a magic link.
      await this.logout()
    }

    try {
      const response = await this.authAxios.post<AccessTokenResponse>(
        getApiBaseUrl() + "/api/auth/magictoken/",
        {
          magic_link_token,
        }
      )

      this.setRawToken(response.data.access)
    } catch (error) {
      // This should rarely fail, so we want to capture the error.
      captureException(error)

      throw error
    }

    // Re-authenticate to set up interceptors and trigger post-authentication setup.
    // Pass the next URL to redirect to after authentication and user data has been retrieved.
    await this.authenticate(next)
  }

  /**
   * Verifies the user's email address.
   *
   * @param token the verify email token to use
   * @param next the next URL to redirect to after authentication
   */
  async verifyEmail(token: string | null, next: string): Promise<void> {
    if (this.hasRefreshSession()) {
      // If a user is already authenticated, we should log them out first.
      await this.logout()
    }

    try {
      await this.authAxios.put(getApiBaseUrl() + `/api/auth/verifyemail/`, {
        token,
      })
    } catch (error) {
      captureException(error)

      throw error
    }

    await this.authenticate(next)
  }

  /**
   * Returns whether or not the user is authenticated
   *
   * @returns whether the user is authenticated
   */
  isAuthenticated(): boolean {
    return (
      // We check for the raw token as we just need to know if the user has authenticated for a token, even if it is expired.
      Boolean(this.getRawToken())
    )
  }

  /**
   * Retrieves the refresh ID token, if it exists, from a {@link REFRESH_ID_TOKEN_COOKIE} cookie.
   *
   * @returns the decoded refresh token or undefined if missing
   */
  getRefreshIdToken(): RefreshIdToken | undefined {
    const tokenString = Cookies.get(REFRESH_ID_TOKEN_COOKIE)
    if (!tokenString) {
      return undefined
    }

    try {
      return jwtDecode<RefreshIdToken>(tokenString)
    } catch (error) {
      // If we fail to decode, then return undefined
      return undefined
    }
  }

  /**
   * Retrieves the expiration of the refresh token.
   *
   * @returns the expiration of the refresh token
   */
  getRefreshExpiration(): number | undefined {
    const idToken = this.getRefreshIdToken()
    if (!idToken) {
      return undefined
    }

    return idToken.exp
  }

  /**
   * Whether or not the user has a refresh session.
   *
   * @returns whether the user has a refresh session
   */
  hasRefreshSession(): boolean {
    return !!this.getRefreshIdToken()
  }

  /**
   * Retrieves the access token for the user.
   * If the token is already available from {@link getCachedAccessToken} it is returned.
   * Otherwise, delegates to {@link refreshAccessToken}.
   *
   * @returns the access token for the user
   */
  async getAccessToken(): Promise<string> {
    const cachedToken = this.getCachedAccessToken()
    if (cachedToken) {
      return cachedToken
    }

    // Refresh the access token, which will also store it in the token variable
    await this.refreshAccessToken()

    // Return the token we just refreshed
    return this.getRawToken() as string
  }

  /**
   * Gets the cached access token if it is valid and not expired.
   * If token found is expired, it is cleared.
   *
   * @returns the cached access token if it is valid and not expired, null otherwise
   */
  getCachedAccessToken(): string | null {
    const token = this.getRawToken()
    if (token) {
      try {
        const decoded = jwtDecode<any>(token)

        const currentTime = Math.floor(Date.now() / 1000)

        // Add a 30 second buffer
        if (decoded.exp && decoded.exp - 30 > currentTime) {
          // If we have a token and it is not expired, return it.
          return token
        }
      } catch (error) {
        // If we can't decode the token, we will treat it as expired and clear it below.
      }
    }

    return null
  }

  /**
   * Refreshes the access token for the user by making a request to the refresh token endpoint.
   */
  async refreshAccessToken(): Promise<void> {
    if (this.tokenRefreshPromise) {
      // If a token promise is already in progress, wait for it
      return this.tokenRefreshPromise
    }

    const executeRefreshToken = async (): Promise<void> => {
      try {
        // Make the request to the refresh token endpoint
        const response = await this.authAxios.post<AccessTokenResponse>(
          getApiBaseUrl() + "/api/auth/token/refresh/"
        )

        // Store the new access token
        this.setRawToken(response.data.access)
      } catch (error: any) {
        if (isAxiosError(error)) {
          // This should rarely fail, so we want to capture the error.
          captureException(error, {
            extra: {
              refreshIdToken: this.getRefreshIdToken(),
              response: error?.response,
            },
          })
        }

        if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {
          // Force a logout if we can't refresh the token.
          this.logout(
            "Your session has expired. Please log in again to continue."
          )
        }

        throw error
      }
    }

    try {
      // Keep track of the token promise to ensure multiple requests for the token are not made at one time
      this.tokenRefreshPromise = executeRefreshToken()
      // Wait for the token promise to resolve
      await this.tokenRefreshPromise
    } finally {
      // Clear the token promise so that future requests can make a new token promise
      this.tokenRefreshPromise = null
    }
  }

  /**
   * Logs the user out by sending a logout request, and clearing local state.
   *
   * @param message the message to display to the user after logging out. If not provided, no message will be displayed.
   * @returns a promise that resolves when the logout process is complete
   */
  async logout(message?: string): Promise<void> {
    try {
      await this.authAxios.post(getApiBaseUrl() + "/api/auth/logout/")
    } catch (error: any) {
      // This should rarely fail, so we want to capture the error.
      if (isAxiosError(error)) {
        captureException(error, {
          extra: { logoutMessage: message, response: error?.response },
        })
      }

      // If the server failed, clear this cookie for good measure.
      Cookies.remove(REFRESH_ID_TOKEN_COOKIE)
    }

    this.setRawToken(null)

    Cookies.remove(IMPERSONATING_FIELD)
    Cookies.remove(IMPERSONATING_USER_FIELD)

    this.logoutListeners.forEach((fn) => fn(message))
  }

  /**
   * Determines whether the user should be idle logged out. This checks cookies to see if the user should still have a valid refresh token.
   *
   * @returns whether the user should be idle logged out
   */
  shouldIdleLogout(): boolean {
    const refreshExpiration = this.getRefreshExpiration()

    if (this.isAuthenticated()) {
      // If the user is authenticated, then we should idle logout if they do not have a refresh token or if the refresh token is expired
      return !refreshExpiration || refreshExpiration < Date.now() / 1000
    }

    // If not authenticated, then we should not idle logout as there is nothing to logout
    return false
  }

  /**
   * Starts an interval to automatically logout the user if they are idle for too long.
   *
   * @returns a function that can be called to stop the idle logout interval
   */
  registerIdleLogoutInterval(): () => void {
    const interval = setInterval(() => {
      if (this.shouldIdleLogout()) {
        this.logout("You have been logged out due to inactivity.")
      }
    }, 5_000)

    return () => clearInterval(interval)
  }

  /**
   * Attaches axios interceptors to automatically include and refresh the access token on requests.
   *
   * @param apiAxios the axios instance to attach interceptors to
   * @returns a function that can be called to remove the interceptors
   */
  attachInterceptors(apiAxios: AxiosInstance): () => void {
    /**
     * Interceptor that automatically refreshes and includes the access token in requests.
     */
    const requestInterceptor = apiAxios.interceptors.request.use(
      async (config) => {
        if (this.isAuthenticated()) {
          const accessToken = await this.getAccessToken()

          // If an access token is present, and there is not already an authorization header, add the access token to the request.
          if (accessToken && isUndefined(config.headers["authorization"])) {
            config.headers[
              "authorization"
            ] = `${BEARER_TOKEN_PREFIX} ${accessToken}`
          }

          // if the access token is not present, and not on the request already, something is wrong.
          if (!accessToken && isUndefined(config.headers["authorization"])) {
            this._reportNoAccessTokenAvailable(config)
          }
        }

        return config
      },
      (error) => {
        return Promise.reject(error)
      }
    )

    /**
     * Interceptor that refreshes the access token and retries requests that fail with a 401/403 status code.
     */
    const responseInterceptor = apiAxios.interceptors.response.use(
      (response) => {
        return response
      },
      async (error) => {
        if (this.isAuthenticated()) {
          const originalRequest = error.config
          const wasUnauthorized =
            error.response?.status === 403 || error.response?.status === 401

          if (wasUnauthorized && !originalRequest._retry) {
            const usedToken =
              originalRequest?.headers?.authorization?.split(" ")?.[1]

            // Only retry the request if the token used is the same as the one we have.
            if (this.getRawToken() === usedToken) {
              const retryRequest = {
                ...originalRequest,
                // Mark as a retry request
                _retry: true,
                // Strip the invalid or expired token from the request
                headers: omit(originalRequest.headers, ["authorization"]),
              }

              await this.refreshAccessToken()

              return apiAxios(retryRequest)
            }
          }

          if (wasUnauthorized && originalRequest._retry) {
            // Report when we unexpectedly get a 401/403 after refreshing the access token and retrying.
            this._reportUnexpectedUnauthorizedRequest(error)
          }
        }

        return Promise.reject(error)
      }
    )

    return () => {
      apiAxios.interceptors.request.eject(requestInterceptor)
      apiAxios.interceptors.response.eject(responseInterceptor)
    }
  }

  /**
   * Internal method used to report when an access token is not available yet it is expected to be available.
   */
  _reportNoAccessTokenAvailable(config: AxiosRequestConfig) {
    const rawToken = this.getRawToken()
    const idToken = this.getRefreshIdToken()

    captureMessage("No access token available to authenticate request.", {
      level: "warning",
      tags: {
        code: "no_access_token_available",
      },
      extra: {
        accessTokenInfo: rawToken ? decodeIfPossible(rawToken) : undefined,
        idTokenInfo: idToken,
        url: config.url,
        method: config.method,
      },
    })
  }

  /**
   * Internal method used to report when an unexpected unauthorized request is made. This indicates something may be off with the access token.
   */
  _reportUnexpectedUnauthorizedRequest(error: AxiosError) {
    const rawToken = this.getRawToken()
    const idToken = this.getRefreshIdToken()

    captureMessage("Unexpected unauthorized request.", {
      level: "warning",
      tags: {
        code: "unexpected_unauthorized_request",
      },
      extra: {
        accessTokenInfo: rawToken ? decodeIfPossible(rawToken) : undefined,
        authorizationHeaderInfo: error.config?.headers?.authorization
          ? decodeIfPossible(
              error.config?.headers?.authorization?.split(" ")[1]
            )
          : undefined,
        idTokenInfo: idToken,
        url: error.config?.url,
        method: error.config?.method,
        responseData: error.response?.data,
      },
    })
  }
}

function decodeIfPossible(token: string) {
  try {
    return jwtDecode(token)
  } catch (error) {
    return null
  }
}

const authService = new SimpleJWTAuthService({
  authAxios: axios.create({
    withCredentials: true,
  }),
})

export default authService
