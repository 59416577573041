import useCachedCollection from "app/swr/hooks/use-cached-collection"
import useCollectionSWR from "app/swr/hooks/use-collection-swr"
import { ResourceCollection } from "app/swr/types"
import { Biomarker } from "types/biomarker"

export default function useAvailableBiomarkers(
  search: string,
  filterForBodySystems?: boolean
) {
  const { data, isLoading, isValidating } = useCollectionSWR<
    ResourceCollection<Biomarker>
  >("/biomarkers/", {
    params: {
      ...(filterForBodySystems && { "filter[body_systems.isnull]": "false" }),
      order_by: "long_name",
      "filter[search]": search,
      "page[limit]": "50",
    },
  })

  const biomarkers = useCachedCollection<Biomarker>(data)

  return {
    biomarkers,
    isLoading,
    isValidating,
  }
}
