import { HeaderContext } from "@tanstack/react-table"
import { CellContext } from "@tanstack/react-table"

import BodyText from "app/components/design-system/BodyText"
import DashboardPractitionerFilter from "app/main/dashboard/components/DashboardPractitionerFilter"
import useCachedResource from "app/swr/hooks/use-cached-resource"
import { DashboardOrder } from "types/dashboard/dashboard-order"
import { DashboardPractitioner } from "types/dashboard/dashboard-practitioner"

import columnHelper from "./column-helper"

export const PractitionerCell = ({
  getValue,
}: CellContext<
  DashboardOrder,
  DashboardOrder["relationships"]["practitioner"]
>) => {
  const practitioner = useCachedResource<DashboardPractitioner>(
    getValue()?.data
  )
  return (
    <BodyText weight="semibold" variant="body1">
      {practitioner?.attributes.titled_full_name || "---"}
    </BodyText>
  )
}

export const PractitionerHeader = ({
  column,
}: HeaderContext<
  DashboardOrder,
  DashboardOrder["relationships"]["practitioner"]
>) => {
  return (
    <DashboardPractitionerFilter
      onFilterChange={column.setFilterValue}
      value={column.getFilterValue() as string}
    />
  )
}

export const PRACTITIONER_COLUMN_ID = "practitioner"

const practitionerColumn = columnHelper.accessor("relationships.practitioner", {
  id: PRACTITIONER_COLUMN_ID,
  cell: (info) => <PractitionerCell {...info} />,
  header: (info) => <PractitionerHeader {...info} />,
  minSize: 200,
  size: 300,
})

export default practitionerColumn
