import { RootState } from "app/types"

import * as Actions from "../actions/overrideLabTest.actions"

const initialState: RootState["overrideLabTest"] = {
  labCompany: null,
  unavailableState: undefined,
}

function overrideLabTestReducer(
  state = initialState,
  action: Actions.ActionTypes
): typeof initialState {
  switch (action.type) {
    case Actions.SET_OVERRIDE_LAB_TEST: {
      return {
        labCompany: action.payload.labCompany,
        unavailableState: action.payload.unavailableState,
      }
    }
    case Actions.RESET_OVERRIDE_LAB_TEST: {
      return {
        labCompany: null,
        unavailableState: undefined,
      }
    }
    default: {
      return state
    }
  }
}

export default overrideLabTestReducer
