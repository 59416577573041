import { ReactElement } from "react"

import { makeStyles, Theme } from "@material-ui/core"
import IconButton from "@material-ui/core/IconButton"

import { ReactComponent as ArrowTopRight } from "app/assets/icons/arrow-top-right.svg"
import { ReactComponent as FiltersIcon } from "app/assets/icons/filters-inactive.svg"
import CrossIcon from "app/assets/icons/gray-cross.svg"
import AlertTriangleRed from "app/assets/images/alert-triangle-red.svg"
import AlertsNotice from "app/assets/images/alerts-info.svg"
import AlertsResultsDelay from "app/assets/images/alerts-results-delay.svg"
import AlertsShippingDelay from "app/assets/images/alerts-shipping-delay.svg"
import useDismissBanner from "app/banners/use-dismiss-banner"
import BodyText from "app/components/design-system/BodyText"
import {
  AnyBanner,
  BannerDisplayType,
  BannerLink as BannerLinkType,
  Banner as BannerResourceType,
  BannerType,
} from "app/main/banner/types"
import { cancelRed, colors, primaryColor } from "app/theme"

import BannerLink from "./BannerLink"
import { isAlert } from "./banner-utils"

const typeToInfo: {
  [key in BannerDisplayType]: {
    icon: string
    linkIcon: ReactElement
    backgroundColor: string
    textColor: string
  }
} = {
  [BannerDisplayType.DASHBOARD_BANNER]: {
    icon: AlertsNotice,
    backgroundColor: colors.lightBlue[50],
    textColor: primaryColor,
    linkIcon: <ArrowTopRight />,
  },
  [BannerDisplayType.LAB_COMPANY_RESULTS_ALERT]: {
    icon: AlertsResultsDelay,
    backgroundColor: colors.rose[50],
    textColor: cancelRed,
    linkIcon: <FiltersIcon fill={primaryColor} />,
  },
  [BannerDisplayType.LAB_COMPANY_SHIPPING_ALERT]: {
    icon: AlertsShippingDelay,
    backgroundColor: colors.rose[50],
    textColor: cancelRed,
    linkIcon: <FiltersIcon fill={primaryColor} />,
  },
  [BannerDisplayType.SPECIMEN_ISSUE_ALERT]: {
    icon: AlertTriangleRed,
    backgroundColor: colors.rose[50],
    textColor: cancelRed,
    linkIcon: <FiltersIcon fill={primaryColor} />,
  },
}

const useStyles = makeStyles<
  Theme,
  {
    textColor: string
    backgroundColor: string
  }
>((theme) => ({
  container: {
    display: "flex",
    flex: 1,
    padding: "12px 0",
    gap: 6,
    alignItems: "flex-start",

    flexFlow: "column",
    [theme.breakpoints.up("sm")]: {
      flexFlow: "row",
      alignItems: "center",
    },
  },
  pill: {
    borderRadius: 100,
    padding: "1px 8px",
    background: (props) => props.backgroundColor,
    display: "flex",
    alignItems: "center",
    marginBottom: 6,

    [theme.breakpoints.up("sm")]: {
      marginBottom: 0,
    },
  },
  pillIcon: {
    marginRight: 6,
  },
  pillText: {
    color: (props) => props.textColor,
  },
}))

interface Props {
  banner: AnyBanner
  link?: BannerLinkType
  onDismiss: () => void
}

const Banner = ({ banner, link, onDismiss }: Props) => {
  const { title, subtitle, display_type: displayType } = banner.attributes
  const { icon, linkIcon, textColor, backgroundColor } = typeToInfo[displayType]
  const { container, pill, pillIcon, pillText } = useStyles({
    backgroundColor,
    textColor,
  })

  return (
    <div className={`${container}`}>
      <div className={pill}>
        <img src={icon} className={pillIcon} alt="" />
        <BodyText className={pillText} weight="semibold" size="md">
          {title}
        </BodyText>
      </div>
      <BodyText size="md" weight="semibold">
        {subtitle}
      </BodyText>
      {link && <BannerLink link={link} icon={linkIcon} />}
      {banner.type === BannerType.BACKEND_BANNER && !isAlert(banner) && (
        <DismissButton banner={banner} onDismiss={onDismiss} />
      )}
    </div>
  )
}

interface DismissButtonProps {
  banner: BannerResourceType
  onDismiss: () => void
}

const DismissButton: React.FC<DismissButtonProps> = ({ banner, onDismiss }) => {
  const dismissBanner = useDismissBanner(banner, onDismiss)
  return (
    <IconButton
      className="right-6 -mt-1 absolute md:relative md:top-0 md:right-0 md:ml-auto md:mt-0 p-2"
      aria-label="close"
      onClick={dismissBanner}
    >
      <img src={CrossIcon} alt="Cross" />
    </IconButton>
  )
}

export default Banner
