import { LAB_COMPANY_KEY } from "app/constants"
import { OrderIntent } from "app/hooks/use-resource"

import { useGetCartLabTests } from "./use-get-cart-lab-tests"
import { useGetLabTestCompanies } from "./use-get-lab-test-companies"

/*
 * Grab all lab tests in the order, from storefront products and bundles. Determine if there are both AML and Labcorp x AML tests in the order.
 */
export const useHasMixedAMLOrder = (cart: OrderIntent | null) => {
  const labTests = useGetCartLabTests(cart)
  const labCompaniesInOrder = useGetLabTestCompanies(labTests)

  const labCompanyKeys = labCompaniesInOrder.map(
    (labCompany) => labCompany?.attributes?.key
  )
  const showAMLWarning =
    labCompanyKeys.includes(LAB_COMPANY_KEY.ACCESS_MEDICAL_LABS) &&
    labCompanyKeys.includes(LAB_COMPANY_KEY.LABCORP_BY_ACCESS_MEDICAL_LABS)

  return showAMLWarning
}
