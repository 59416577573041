import { KIT_STATUS, ORDER_STATUS } from "app/constants.typed"

import { StatusConfiguration } from "../../types"
import { StatusConfigurationProps } from "../../types/dashboard-types"
import { PROGRESS_PERCENTAGES } from "../consts"

const SubTitle = ({ patient }: StatusConfigurationProps) => {
  return (
    <span>
      {`The authorizing physician was unable to sign for ${patient?.attributes.full_name}.`}
    </span>
  )
}

const canceledPhysServicesStatusConfiguration: StatusConfiguration<StatusConfigurationProps> =
  {
    when: {
      orderStatus: ORDER_STATUS.CANCELED,
      kitStatus: KIT_STATUS.PHYSICIAN_AUTHORIZATION_DENIED,
    },
    progressPercentage: PROGRESS_PERCENTAGES.CANCELED,
    title: "Denied by Authorizing Physician",
    subTitle: SubTitle,
  }

export default canceledPhysServicesStatusConfiguration
