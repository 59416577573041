import { Link } from "react-router-dom"

import { makeStyles } from "@material-ui/core"

import BloodDropCircle from "app/assets/images/blood-drop.svg"
import CardWithIcon from "app/components/CardWithIcon"
import PageToolbar from "app/components/PageToolbar"
import BodyText from "app/components/design-system/BodyText"
import { colors, primaryColor } from "app/theme"

import PhlebotomistMap from "./PhlebotomistMap"

const useStyles = makeStyles({
  wrapper: {
    padding: 24,
  },
  titleWrapper: {
    display: "flex",
    alignItems: "center",
    marginBottom: 6,
  },
  createPreferredPhlebWrapper: {
    display: "flex",
    background: "white",
    border: `1px solid ${colors.blueGray[200]}`,
    padding: 14,
    borderRadius: 6,
    marginTop: 24,
  },
  bloodDropImgWrapper: {
    flexBasis: "105px",
    paddingRight: "13px",
  },
  mapWrapper: {
    width: "100%",
    height: 644,
    borderRadius: "24px",
    position: "relative",
    overflow: "hidden",
    marginTop: 24,
  },
  createLink: {
    fontWeight: 600,
    color: primaryColor,
  },
  styledDivider: {
    height: 1,
    backgroundColor: colors.blueGray[300],
    marginTop: 24,
    marginBottom: 24,
  },
})

const PhlebotomistMapPage = () => {
  const classes = useStyles()

  const cardText = (
    <BodyText>
      Have specific phlebotomists you want your patients to see, or work with a
      phlebotomist in-house? Add a preferred phlebotomist and their information
      will be sent to every patient within 25 miles who requires a blood draw.{" "}
      <Link to="/settings/phlebotomists" className={classes.createLink}>
        Add a preferred phlebotomist in settings.
      </Link>
    </BodyText>
  )

  return (
    <>
      <PageToolbar title="Phlebotomy Map" />
      <div className={classes.wrapper}>
        <div className={classes.titleWrapper}>
          <BodyText weight="semibold" size="lg">
            Phlebotomy Network
          </BodyText>
        </div>
        <BodyText>
          After you place an order with a blood draw requirement, Rupa sends
          your patients custom instructions with their phlebotomy options. We
          send your patients local phlebotomy options who can draw for their
          kits.
        </BodyText>

        <div style={{ marginTop: 24 }}>
          <CardWithIcon
            icon={BloodDropCircle}
            title="Add a Preferred Phlebotomist"
            text={cardText}
          />
        </div>

        <div className={classes.mapWrapper}>
          <PhlebotomistMap />
        </div>

        <hr className={classes.styledDivider} />
      </div>
      {/* <BlockSeperator /> */}
    </>
  )
}

export default PhlebotomistMapPage
