import { AxiosError } from "axios"

import { ReactComponent as ErrorPageIcon } from "app/assets/icons/error-page.svg"
import DisplayText from "app/components/design-system/DisplayText"
import { ResourceErrorResponse } from "app/swr/types"
import makeAppStyles from "app/utils/makeAppStyles"

const useStyles = makeAppStyles((theme) => ({
  root: {
    maxWidth: 540,
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginLeft: "auto",
    marginRight: "auto",
    gap: theme.spacing(2.0),
    padding: theme.spacing(4.0),
  },
  errorIcon: {
    maxWidth: "100%",
    width: 320,
    height: "auto",
  },
}))

export default function EditorLoadingError({
  error,
}: {
  error: AxiosError<ResourceErrorResponse, any>
}) {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <ErrorPageIcon className={classes.errorIcon} viewBox="0 0 790 513" />
      <DisplayText size="2xl" variant="h1">
        Uh-oh!
      </DisplayText>
      <DisplayText align="center" size="base" variant="body1">
        There was an error retrieving the results for this order, and our team
        has been notified. Try refreshing the page and trying again.
      </DisplayText>
    </div>
  )
}
