enum FieldNames {
  BIRTHDAY = "birthday",
  CONSENT = "consent",
  BRL_CONSENT = "brl_consent",
  SYMPTOM_ATTESTATION = "symptom_attestation",
  EMAIL = "email",
  FIRST_NAME = "first_name",
  BIOLOGICAL_SEX = "biological_sex",
  INSURANCE_CLAIMS_CITY = "insurance.claims_city",
  INSURANCE_CLAIMS_PHONE_NUMBER = "insurance.claims_phone_number",
  INSURANCE_CLAIMS_STATE = "insurance.claims_state",
  INSURANCE_CLAIMS_STREET = "insurance.claims_street",
  INSURANCE_CLAIMS_ZIPCODE = "insurance.claims_zipcode",
  INSURANCE_GROUP_NUMBER = "insurance.group_number",
  INSURANCE_PROVIDER = "insurance.provider",
  INSURANCE_SUBSCRIBERS_BIRTHDAY = "insurance.subscribers_birthday",
  INSURANCE_SUBSCRIBERS_FIRST_NAME = "insurance.subscribers_first_name",
  INSURANCE_SUBSCRIBERS_ID_NUMBER = "insurance.subscribers_id_number",
  INSURANCE_SUBSCRIBERS_LAST_NAME = "insurance.subscribers_last_name",
  INSURANCE_SUBSCRIBERS_RELATIONSHIP = "insurance.subscribers_relationship",
  SCARLET_BLOOD_DRAW_ZIPCODE = "scarlet.blood_draw_zipcode",
  SCARLET_IS_ELIGIBLE = "scarlet.is_eligible_for_scarlet",
  LAST_NAME = "last_name",
  PAYMENT_METHOD = "payment_method",
  PAYMENT_METHOD_TYPE = "payment_method.type",
  PHONE_NUMBER = "phone_number",
  SAMPLE_COLLECTION_STATE = "sample_collection_state",
  SHIPPING_CITY = "default_shipping_address.city",
  SHIPPING_STATE = "default_shipping_address.state",
  SHIPPING_STREET = "default_shipping_address.street_1",
  STREET_SECONDARY = "default_shipping_address.street_2",
  SHIPPING_ZIPCODE = "default_shipping_address.zipcode",
  SPLITIT_BILLING_CITY = "payment_method.splitit.billing_address.city",
  SPLITIT_BILLING_STATE = "payment_method.splitit.billing_address.state",
  SPLITIT_BILLING_STREET = "payment_method.splitit.billing_address.street_1",
  SPLITIT_BILLING_SECONDARY_STREET = "payment_method.splitit.billing_address.street_2",
  SPLITIT_BILLING_ZIPCODE = "payment_method.splitit.billing_address.zipcode",
  SPLITIT_IS_BILLING_SAME_AS_SHIPPING = "payment_method.splitit.is_billing_same_as_shipping",
  SPLITIT_IS_TERMS_ACCEPTED = "payment_method.splitit.is_terms_accepted",
  VENDOR_PHYS_AUTH_AUTHORIZING_PHYSICIAN_CONSENT = "vendor_phys_auth_authorizing_physician_consent",

  GUARDIAN_FIRST_NAME = "guardian_first_name",
  GUARDIAN_LAST_NAME = "guardian_last_name",
}

export default FieldNames
