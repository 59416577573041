import { useHistory } from "react-router-dom"

import { getFoodPlanPath } from "app/food-plans/utils/paths"
import useEventCallback from "app/hooks/use-event-callback"

export default function useRedirectToEditFoodPlan() {
  const history = useHistory()

  return useEventCallback((patientId: string, foodPlanId: string) => {
    history.push(getFoodPlanPath(patientId, foodPlanId))
  })
}
