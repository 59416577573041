import { useState } from "react"
import { useDispatch } from "react-redux"

import { ReactComponent as EmailIcon } from "app/assets/icons/envelope/email-icon.svg"
import BodyText from "app/components/design-system/BodyText"
import DesignSystemButton from "app/components/design-system/Button"
import usePatchInterpretation from "app/results-summary/hooks/use-patch-interpretation"
import { UseResultsVisualizerResourcesHook } from "app/results-summary/hooks/use-results-summary-resources"
import useCachedResource from "app/swr/hooks/use-cached-resource"
import { colors, white } from "app/theme"
import { handleApiError, handleApiSuccess } from "app/utils"
import makeAppStyles from "app/utils/makeAppStyles"
import { Order } from "types/order"
import { Patient } from "types/patient"

const useStyles = makeAppStyles((theme) => ({
  infoText: {
    marginTop: theme.spacing(1),
    textAlign: "center",
    color: colors.blueGray[500],
  },
  icon: {
    marginBottom: 3,
  },
}))

export interface SendToPatientButtonProps {
  interpretationSwr: UseResultsVisualizerResourcesHook["interpretationSwr"]
  order: Order
}

export default function SendToPatientButton({
  interpretationSwr,
  order,
}: SendToPatientButtonProps) {
  const classes = useStyles()
  const dispatch = useDispatch()

  const patchInterpretation = usePatchInterpretation(interpretationSwr)
  const { data: interpretation } = interpretationSwr

  const patient = useCachedResource<Patient>(order?.relationships.patient.data)

  const [isLoading, setIsLoading] = useState(false)

  async function sendToPatient() {
    try {
      setIsLoading(true)
      await patchInterpretation({
        attributes: { release_to_patient: true },
      })
      dispatch(
        handleApiSuccess(
          "An email with instructions how to access the interpretation has been sent to the patient."
        )
      )
    } catch (error) {
      dispatch(handleApiError(error))
    } finally {
      setIsLoading(false)
    }
  }

  const pdfAvailable =
    interpretation?.relationships.biomarkers.data.length ||
    interpretation?.relationships.tasks.data.length

  const patientLabel = patient ? patient?.attributes.first_name : "Patient"

  return (
    <div>
      <DesignSystemButton
        disabled={!pdfAvailable}
        fullWidth
        loading={isLoading}
        onClick={sendToPatient}
        startIcon={
          <EmailIcon
            className={classes.icon}
            fill={pdfAvailable ? white : colors.gray[300]}
          />
        }
      >
        Email to {patientLabel}
      </DesignSystemButton>
      <BodyText size="sm" weight="semibold" className={classes.infoText}>
        {patientLabel} will receive an email with a link to the patient portal
        where they can download a PDF of this results summary.
      </BodyText>
    </div>
  )
}
