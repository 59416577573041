import { Box } from "@material-ui/core"

import BodyText from "app/components/design-system/BodyText"
import { navy, colors } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"

import AccountCardWrapper from "../AccountCardWrapper"
import EditAccountTooltip from "../tooltips/EditAccountTooltip"

const useStyles = makeAppStyles((theme) => ({
  accountStatusRow: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
  },
  accountStatusText: {
    color: navy,
  },
  accountStatusMessage: {
    backgroundColor: colors.blueGray[100],
    border: "2px solid white",
    borderRadius: 25,
    color: colors.gray[800],
    fontSize: 14,
    marginTop: "26px",
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(1),
    width: "fit-content",
    display: "flex",
    "flex-direction": "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  statusIndicator: {
    borderRadius: "100%",
    width: 10,
    height: 10,
    marginRight: theme.spacing(1),
    backgroundColor: colors.amber[500],
  },
}))

const ProcessingAccountCard = ({ company }) => {
  const classes = useStyles()
  return (
    <AccountCardWrapper
      company={company}
      isConfirmed={false}
      actionElement={<EditAccountTooltip companyName={company.display} />}
    >
      <div className={classes.accountStatusRow}>
        <Box className={classes.accountStatusMessage}>
          <Box className={classes.statusIndicator} />
          <BodyText size="sm" className={classes.accountStatusText}>
            Your {company.display} account is being verified.
          </BodyText>
        </Box>
      </div>
    </AccountCardWrapper>
  )
}

export default ProcessingAccountCard
