import { Fade } from "@material-ui/core"

import ComparedLabTest from "app/main/comparison/components/ComparisonLabTests/components/ComparedLabTest"
import ComparisonOpenSearchModalButton from "app/main/comparison/components/ComparisonLabTests/components/ComparisonOpenSearchModalButton"
import ComparisonRow from "app/main/comparison/components/ComparisonLabTests/components/ComparisonRow"
import { COLUMN_TRANSITION_TIMEOUT } from "app/main/comparison/constants"

export default function ComparisonLabTestList() {
  return (
    <ComparisonRow Column={ComparedLabTest}>
      {({ classes: rowClasses }) => (
        <Fade key="ADD_COMPARED_TEST" timeout={COLUMN_TRANSITION_TIMEOUT}>
          <div className={rowClasses.column}>
            <ComparisonOpenSearchModalButton />
          </div>
        </Fade>
      )}
    </ComparisonRow>
  )
}
