import { useCallback, useMemo, useRef, useState } from "react"

import { CircularProgress } from "@material-ui/core"
import * as Popover from "@radix-ui/react-popover"

import { ReactComponent as SearchIcon } from "app/assets/images/search-icon-blue.svg"
import AutoSuggestContent from "app/components/search/AutoSuggest/AutoSuggestContent"
import AutoSuggestGrouping from "app/components/search/AutoSuggest/AutoSuggestGrouping"
import AutoSuggestSurveyRequest from "app/components/search/AutoSuggest/AutoSuggestSurveyRequest"
import useResizeObserver from "app/hooks/use-resize-observer"
import { INTERCOM_ADD_BIOMARKER_SURVEY_ID } from "app/settings"
import { colors, primaryColor, shadows, textPrimaryColor } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"
import { BiomarkerIdentifier } from "types/biomarker"
import { BiomarkerGroupingIdentifier } from "types/biomarker_grouping"

import { TYPE_KEYS } from "../constants"
import useBiomarkerSearch from "../hooks/use-biomarker-search"
import useDropdownSelect from "../hooks/use-dropdown-select"
import BiomarkerAutoSuggestAction from "./BiomarkerAutoSuggestAction"
import BiomarkerGroupingAutoSuggestAction from "./BiomarkerGroupingAutoSuggestAction"

const useStyles = makeAppStyles((theme) => ({
  container: {
    background: "white",
    border: `2px solid ${colors.blueGray[300]}`,
    borderRadius: 8,
    boxShadow: shadows.default,
    padding: 12,
    transition: "0.08s border-color, 0.16s box-shadow",
    width: "100%",
    display: "flex",
    alignItems: "flex-start",
    borderColor: primaryColor,
    "-webkit-appearance": "none !important",

    "&:focus-within": {
      // Deliberately not from the design system as this shadow is custom
      boxShadow: "0px 0px 4px #0075CD",
    },
  },
  searchIconContainer: {
    marginRight: 11,
    background: colors.blueGray[100],
    padding: 8,
    borderRadius: 6,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  searchIcon: {
    width: 18,
    height: 18,
  },
  inputContainer: {
    flex: 1,
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    gap: theme.spacing(1.5),
    minHeight: 34,
  },
  input: {
    flex: 1,
    color: textPrimaryColor,
    fontSize: 16,
    lineHeight: 1.25,
    [theme.breakpoints.up("sm")]: {
      fontSize: "inherit",
    },

    "&::placeholder": {
      color: colors.blueGray[500],
      fontWeight: 600,
    },
  },
  spinner: {
    marginTop: 6,
  },
  noMatchText: {
    color: colors.blueGray[500],
  },
}))

export interface SearchProps {
  autoFocus?: boolean
  handleSelectItem: (
    item: BiomarkerIdentifier | BiomarkerGroupingIdentifier
  ) => void
  selectedIdentifiers: (BiomarkerIdentifier | BiomarkerGroupingIdentifier)[]
}

export default function BiomarkerSearch({
  autoFocus,
  handleSelectItem,
  selectedIdentifiers,
}: SearchProps) {
  const triggerRef = useRef<any>()
  const { width } = useResizeObserver({
    ref: triggerRef,
    box: "border-box",
  })
  const styles = useStyles()
  const inputRef = useRef<HTMLInputElement>(null)

  const [search, setSearch] = useState("")

  const selectedBiomarkersIdentifiers = useMemo(() => {
    return selectedIdentifiers.filter(
      (obj) => obj.type === TYPE_KEYS.BIOMARKER
    ) as BiomarkerIdentifier[]
  }, [selectedIdentifiers])

  const selectedBiomarkerGroupingsIdentifiers = useMemo(() => {
    return selectedIdentifiers.filter(
      (obj) => obj.type === TYPE_KEYS.BIOMARKER_GROUPING
    ) as BiomarkerGroupingIdentifier[]
  }, [selectedIdentifiers])

  // For handling search and fetching biomarkers/biomarker groupings
  const {
    isLoadingBiomarkers,
    biomarkers,
    isLoadingBiomarkerGroupings,
    biomarkerGroupings,
  } = useBiomarkerSearch(search)

  // For handling dropdown actions/state
  const {
    highlightedItem,
    moveCursorDown,
    moveCursorToItem,
    moveCursorUp,
    open,
    selectItem,
    setOpen,
  } = useDropdownSelect({
    items: [...biomarkerGroupings, ...biomarkers],
    loading: isLoadingBiomarkerGroupings || isLoadingBiomarkers,
    onItemSelect: handleSelectItem,
    search: search,
    setSearch: setSearch,
  })

  // Handle keyboard actions with in suggestion dropdown
  const handleKeyDown = useCallback<
    React.KeyboardEventHandler<HTMLInputElement>
  >(
    (e) => {
      // keyboard actions for dropdown only available while open
      if (open) {
        if (e.key === "ArrowUp") {
          // disable moving text cursor to start
          e.preventDefault()

          moveCursorUp()
        } else if (e.key === "ArrowDown") {
          // disable moving text cursor to end
          e.preventDefault()

          moveCursorDown()
        } else if (e.key === "Enter") {
          // disable enter behavior
          e.preventDefault()
          if (highlightedItem) {
            selectItem(highlightedItem)
          }
        }
      }
    },
    [moveCursorUp, moveCursorDown, selectItem, search, highlightedItem]
  )

  const showBiomarkersSection = useMemo(() => {
    return Boolean(biomarkers.length) && Boolean(search)
  }, [biomarkers, search])

  const noResults = useMemo(() => {
    return !Boolean(biomarkers.length) && !Boolean(biomarkerGroupings.length)
  }, [biomarkers, biomarkerGroupings])

  return (
    <Popover.Root open={open} onOpenChange={setOpen} modal={false}>
      <Popover.Trigger
        asChild
        onClick={(e) => {
          if (open) {
            // disable closing on click
            e.preventDefault()
          } else {
            setOpen(true)
          }
        }}
        ref={triggerRef}
      >
        <div className={styles.container} tabIndex={-1}>
          <div className={styles.searchIconContainer}>
            <SearchIcon className={styles.searchIcon} />
          </div>
          <div className={styles.inputContainer}>
            <input
              aria-label="Search for biomarkers"
              autoFocus={autoFocus}
              className={styles.input}
              onChange={(event) => setSearch(event.target.value)}
              onKeyDown={handleKeyDown}
              placeholder={
                "Search for individual biomarkers or common biomarker groupings (CBC, CMP, etc.)"
              }
              ref={inputRef}
              value={search}
              autoComplete="off"
              autoCorrect="off"
              autoCapitalize="off"
              spellCheck="false"
            />
          </div>
        </div>
      </Popover.Trigger>

      {open && (
        <AutoSuggestContent width={width || "auto"}>
          {noResults && (
            <AutoSuggestSurveyRequest
              surveyId={INTERCOM_ADD_BIOMARKER_SURVEY_ID}
              beforeButtonText="No biomarkers or biomarker groupings match your search."
              buttonText="Submit a request to us"
              afterButtonText="for a marker to be added!"
            />
          )}
          {Boolean(biomarkerGroupings.length) && (
            <AutoSuggestGrouping label={"Biomarker Grouping"} separator={true}>
              {isLoadingBiomarkerGroupings && (
                <CircularProgress size={16} className={styles.spinner} />
              )}
              {biomarkerGroupings.map((biomarkerGroupingIdentifier) => (
                <BiomarkerGroupingAutoSuggestAction
                  key={biomarkerGroupingIdentifier.id}
                  biomarkerGroupingIdentifier={biomarkerGroupingIdentifier}
                  handleSelectBiomarkerGrouping={selectItem}
                  highlightedItem={highlightedItem}
                  moveCursorToItem={moveCursorToItem}
                  selectedBiomarkerGroupingsIdentifiers={
                    selectedBiomarkerGroupingsIdentifiers
                  }
                />
              ))}
            </AutoSuggestGrouping>
          )}
          {showBiomarkersSection && (
            <AutoSuggestGrouping label={"Biomarkers"} separator={!noResults}>
              {isLoadingBiomarkers && (
                <CircularProgress size={16} className={styles.spinner} />
              )}
              {biomarkers?.map((biomarkerIdentifier) => (
                <BiomarkerAutoSuggestAction
                  key={biomarkerIdentifier.id}
                  biomarkerIdentifier={biomarkerIdentifier}
                  handleSelectBiomarker={selectItem}
                  highlightedItem={highlightedItem}
                  moveCursorToItem={moveCursorToItem}
                  selectedBiomarkersIdentifiers={selectedBiomarkersIdentifiers}
                  selectedBiomarkerGroupingsIdentifiers={
                    selectedBiomarkerGroupingsIdentifiers
                  }
                />
              ))}
            </AutoSuggestGrouping>
          )}
          {!noResults && (
            <AutoSuggestSurveyRequest
              surveyId={INTERCOM_ADD_BIOMARKER_SURVEY_ID}
              beforeButtonText="Not seeing a biomarker you'd like?"
              buttonText="Submit a request to us"
              afterButtonText="for a marker to be added!"
            />
          )}
        </AutoSuggestContent>
      )}
    </Popover.Root>
  )
}
