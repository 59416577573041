export enum Status {
  Low = "low",
  BelowOptimal = "below_optimal",
  Normal = "normal",
  Abnormal = "abnormal",
  AboveOptimal = "above_optimal",
  High = "high",
  Optimal = "optimal",
}

export const gradients = {
  abnormal: {
    active: "linear-gradient(270deg, #F6BD59 0%, #F87671 100%)",
    inactive:
      "linear-gradient(0deg, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7)), linear-gradient(270deg, #F5D350 0%, #F87471 100%)",
  },
  nonOptimal: {
    active: "linear-gradient(90deg, #e9da2d 0%, #90C74D 100%)",
    inactive: "linear-gradient(90deg, #e9da2d 0%, #90C74D 100%)",
  },
  normal: {
    active: "linear-gradient(90deg, #90C74D 0%, #43D495 49.48%, #90C74D 100%)",
  },
  normalDirectional: {
    active: "linear-gradient(90deg, #43D495 0%, #90C74D 100%)",
  },
}
