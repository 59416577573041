import * as React from "react"

import { FieldError } from "react-hook-form"

import { FormHelperText, Typography } from "@material-ui/core"

import { colors } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"

const useStyles = makeAppStyles((theme) => ({
  root: {
    width: "100%",
    display: "flex",
    flexFlow: "column nowrap",
    justifyContent: "flex-start",
  },
  label: {
    display: "inline-flex",
    flexFlow: "row nowrap",
    alignItems: "center",
    gap: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
    fontSize: 12,
    fontWeight: 600,
    textTransform: "uppercase",
    color: colors.blueGray[500],
    "& > svg": {
      fill: "currentColor",
    },
  },
  required: {
    fontSize: 12,
    fontWeight: 600,
  },
}))

/**
 * The base props shared by all variants of the text field.
 */
export interface FieldDecorationProps {
  error?: FieldError
  label: React.ReactNode
  labelIcon?: React.ReactNode
  required?: boolean
}

export default function FieldDecoration({
  children,
  error,
  label,
  labelIcon,
  required,
}) {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <Typography className={classes.label} component="label">
        {labelIcon}
        {label}
        {required && (
          <Typography
            className={classes.required}
            color="error"
            component="span"
          >
            {" *"}
          </Typography>
        )}
      </Typography>

      {children}

      {error?.message && (
        <FormHelperText component="span" error>
          {error.message}
        </FormHelperText>
      )}
    </div>
  )
}
