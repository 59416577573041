import { useEffect, useMemo } from "react"

import { API } from "app/api"
import { ORDERING_RIGHTS_ACTIONS } from "app/constants"

import {
  fetchTestsFailure,
  fetchTestsRequest,
  fetchTestsSuccess,
} from "../store/comparison.actions"
import { ComparisonState, ComparisonDispatch } from "../types"

async function getLabTestsByIds(ids: string[], orderId?: string) {
  const params = new URLSearchParams()

  // ensure page size includes all ids provided
  params.append("page_size", `${ids.length}`)
  params.append("allow_comparisons", "true")

  ids.forEach((id) => params.append("id", id))

  if (orderId) {
    params.append("order_id", orderId)
  }
  params.append(
    "action",
    orderId
      ? ORDERING_RIGHTS_ACTIONS.ADD_TO_CART
      : ORDERING_RIGHTS_ACTIONS.START_ORDER_WITH
  )

  return await API.LabTest.getList(params.toString())
}

/**
 * Hook used to fetch missing lab test data for the current set of compared tests.
 *
 * @param state the comparison state
 * @param dispatch the comparison dispatch function
 */
export default function useFetchMissingLabTests(
  state: ComparisonState,
  dispatch: ComparisonDispatch,
  orderId?: string
) {
  const idsToFetch = useMemo(
    () => state.labTestIds.filter((id) => !state.labTestById[id]),
    [state.labTestIds, state.labTestById]
  )

  useEffect(() => {
    if (state.isLoading || !idsToFetch || idsToFetch.length === 0) {
      return
    }

    async function fetchMissingLabTests() {
      dispatch(fetchTestsRequest())

      try {
        const response = await getLabTestsByIds(idsToFetch, orderId)

        dispatch(fetchTestsSuccess(response.data.results))
      } catch (error: any) {
        dispatch(fetchTestsFailure(error))
      }
    }

    fetchMissingLabTests()
  }, [idsToFetch])
}
