import { ReactComponent as ArrowTopRightIcon } from "app/assets/icons/arrow-top-right.svg"
import PatientPortalInfoCardBackground from "app/assets/images/patient-portal/patient-portal-more-info-card-background.jpeg"
import BodyText from "app/components/design-system/BodyText"
import DesignSystemButton from "app/components/design-system/Button"
import DisplayText from "app/components/design-system/DisplayText"
import { colors, navy, shadows } from "app/theme"
import { AuthUser } from "app/types"
import makeAppStyles from "app/utils/makeAppStyles"

import { PatientPortalUser } from "./types"

const useStyles = makeAppStyles((theme) => ({
  emptyTextContainer: {
    background: "rgba(255, 255, 255, 0.8)",
    display: "flex",
    flexDirection: "column",
    gap: 16,
    alignItems: "center",
    textAlign: "center",
    borderRadius: 12,
    padding: "40px 24px",
  },
  emptyText: {
    color: colors.blueGray[500],
    lineHeight: "150%",
    textAlign: "center",
  },
  moreInfoCard: {
    borderRadius: 12,
    background: `url(${PatientPortalInfoCardBackground})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "50% 100%",
    boxShadow: shadows.sm,
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "40px 24px",
  },
  moreInfoText: {
    textAlign: "center",
    marginBottom: 16,
  },
  smallTitle: {
    fontSize: 19,
    fontFamily: "Josefin Sans",
    fontWeight: 600,
    color: navy,
    lineHeight: "150%",
  },
  arrowIcon: { position: "relative", top: -2 },
}))

const PatientPortalEmptyState = ({
  patientUser,
  authUser,
}: {
  patientUser?: PatientPortalUser
  authUser?: AuthUser
}) => {
  const classes = useStyles()

  let emailText = "your email"

  if (patientUser?.attributes.email) {
    emailText = patientUser?.attributes.email
  } else if (authUser?.email) {
    emailText = authUser?.email
  }

  return (
    <>
      <div
        className={classes.emptyTextContainer}
        data-testid="patients-empty-state"
      >
        <BodyText weight="semibold" size="base">
          We couldn't find any orders for you under {emailText}.
        </BodyText>
        <BodyText className={classes.emptyText}>
          If your practitioner placed an order for you with Rupa, you may have
          signed up with a different email. We recommend checking your other
          inboxes for an order confirmation email from Rupa. If you can't find
          an order confirmation email in other inboxes, contact your
          practitioner to confirm the email used on the order.
        </BodyText>
      </div>

      <div className={classes.moreInfoCard}>
        <DisplayText className={classes.smallTitle}>
          Looking for something else?
        </DisplayText>
        <BodyText size="base" className={classes.moreInfoText}>
          We're building the future of root cause medicine. Tell us what you're
          interested in!
        </BodyText>
        <DesignSystemButton
          color="text"
          variant="text"
          endIcon={<ArrowTopRightIcon className={classes.arrowIcon} />}
          onClick={() =>
            window.open("https://www.rupahealth.com/patient-interest", "_blank")
          }
        >
          Let us know what you're looking for
        </DesignSystemButton>
      </div>
    </>
  )
}

export default PatientPortalEmptyState
