import DateInput, { DateInputProps } from "./DateInput"
import FieldLabel, { FieldLabelProps } from "./FieldLabel"

export type DateFieldProps = DateInputProps &
  FieldLabelProps & {
    labelClassName?: string
    inputClassName?: string
  }

const DateField = ({
  InputProps,
  className,
  infoText,
  inputClassName,
  inputProps,
  label,
  labelClassName,
  required,
  ...dateInputProps
}: DateFieldProps) => {
  return (
    <div className={className}>
      {Boolean(label) && (
        <FieldLabel
          className={labelClassName}
          label={label}
          infoText={infoText}
          required={required}
        />
      )}
      <DateInput
        className={inputClassName}
        InputProps={{
          ...InputProps,
          inputProps: {
            "aria-label": typeof label === "string" ? label : undefined,
            ...inputProps,
            ...InputProps?.inputProps,
          },
        }}
        required={required}
        {...dateInputProps}
      />
    </div>
  )
}

export default DateField
