import { useEffect, useState } from "react"

import DateFnsUtils from "@date-io/date-fns"
import {
  styled,
  useMediaQuery,
  createMuiTheme,
  useTheme,
  makeStyles,
} from "@material-ui/core"
import IconButton from "@material-ui/core/IconButton"
import CloseIcon from "@material-ui/icons/Close"
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers"
import { ThemeProvider } from "@material-ui/styles"

import BodyText from "app/components/design-system/BodyText"
import DesignSystemButton from "app/components/design-system/Button"
import DisplayText from "app/components/design-system/DisplayText"
import { Dialog } from "app/components/modals"
import { navy, primaryColor, darkGray, colors } from "app/theme"
import { getOrderTypeLabel } from "app/utils/order-utils"

const customDatePickerTheme = createMuiTheme({
  overrides: {
    MuiPickersBasePicker: {
      pickerView: {
        width: 400,
        height: 300,
        maxWidth: "inherit",
      },
    },
    MuiPickersDay: {
      day: {
        color: primaryColor,
        width: 30,
        height: 30,
        margin: "6px 10px",
      },
      daySelected: {
        backgroundColor: primaryColor,
      },
      dayDisabled: {
        color: colors.coolGray[400],
      },
    },
    MuiPickersCalendarHeader: {
      dayLabel: {
        width: 44,
        color: darkGray,
      },
      switchHeader: {
        maxWidth: 300,
        margin: "6px auto",
      },
    },
    MuiSvgIcon: {
      root: {
        width: 24,
        height: 24,
        fill: primaryColor,
      },
    },
  },
  typography: {
    htmlFontSize: 20,
    body1: {
      fontSize: 20,
      fontWeight: 600,
      color: primaryColor,
      lineHeight: 1,
      fontFamily: "Open Sans",
    },
    body2: {
      fontSize: 20,
      width: 28,
      height: 28,
      fontFamily: "Open Sans",
    },
    caption: {
      fontSize: 20,
      fontFamily: "Open Sans",
    },
  },
})

const useStyles = makeStyles(() => ({
  paper: { maxWidth: "620px" },
}))

const ScheduleOrderWrapper = styled("div")(({ isMobile }) => ({
  padding: isMobile ? "0px 45px 48px" : "5px 85px 53px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
}))

const CloseButton = styled(IconButton)({
  color: navy,
  top: 1,
  float: "right",
})

const StyledDesignSystemButton = styled(DesignSystemButton)({
  marginTop: 16,
})

const StyledBodyText = styled(BodyText)({
  textAlign: "center",
})

const StyledDisplayText = styled(DisplayText)({
  textAlign: "center",
})

const Separator = styled("hr")({
  height: 1,
  width: "100%",
  backgroundColor: colors.coolGray[300],
  marginTop: 10,
  marginBottom: 10,
})

const Separator20 = styled("hr")({
  height: 1,
  width: "100%",
  backgroundColor: colors.coolGray[300],
  marginTop: 20,
  marginBottom: 20,
})

const ScheduleOrderModal = ({ order, open, onSubmit, onClose }) => {
  const classes = useStyles()
  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="team-member-title"
      open={open}
      fullWidth
      disableBackdropClick
      disableEscapeKeyDown
      classes={{ paper: classes.paper }}
    >
      <ModalBody order={order} onSubmit={onSubmit} onClose={onClose} />
    </Dialog>
  )
}

export default ScheduleOrderModal

function ModalBody({ order, onSubmit, onClose }) {
  const theme = useTheme()

  const isBelowMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"))
  const isExactSM = useMediaQuery((theme) => theme.breakpoints.only("sm"))

  // Will be true only for screen widths strictly less than 800px
  const isMobile = isBelowMobile && !isExactSM

  const [selectedDate, setSelectedDate] = useState(new Date())

  const handleDateChange = (date) => {
    setSelectedDate(date)
  }

  useEffect(() => {
    if (order.date_scheduled) {
      setSelectedDate(new Date(order.date_scheduled))
    }
  }, [order])

  return (
    <>
      <div>
        <CloseButton aria-label="close" onClick={onClose} key="close-button">
          <CloseIcon />
        </CloseButton>
      </div>
      <ScheduleOrderWrapper isMobile={isMobile}>
        <StyledDisplayText size="2xl">
          {`Schedule ${order.patient.first_name}’s ${getOrderTypeLabel(
            order.requires_vendor_physician_authorization
          )}`}
        </StyledDisplayText>
        <Separator />
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <ThemeProvider theme={theme}>
            <ThemeProvider
              theme={(outerTheme) => ({
                ...outerTheme,
                ...customDatePickerTheme,
              })}
            >
              <DatePicker
                autoOk
                variant="static"
                openTo="date"
                disableToolbar={true}
                disablePast={true}
                value={selectedDate}
                onChange={handleDateChange}
              />
            </ThemeProvider>
          </ThemeProvider>
        </MuiPickersUtilsProvider>
        <Separator20 />
        <StyledBodyText>{`This ${getOrderTypeLabel(
          order.requires_vendor_physician_authorization
        ).toLowerCase()} will automatically be sent to ${
          order.patient.first_name
        } on ${selectedDate.toDateString().substring(4)}.`}</StyledBodyText>
        <StyledDesignSystemButton
          color="secondary"
          onClick={() => onSubmit(selectedDate)}
        >
          Save Schedule
        </StyledDesignSystemButton>
      </ScheduleOrderWrapper>
    </>
  )
}
