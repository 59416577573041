import { ReactElement } from "react"

import clsx from "clsx"
import moment from "moment"

import { Theme, Typography, useMediaQuery } from "@material-ui/core"

import EditDarkIcon from "app/assets/images/edit-dark.svg"
import EditIcon from "app/assets/images/edit.svg"
import OrderStatus from "app/components/OrderStatus"
import Tooltip from "app/components/Tooltip"
import DesignSystemButton from "app/components/design-system/Button"
import { ORDER_STATUS } from "app/constants"
import { canOrderAgain } from "app/dataServices/orderDataService"
import { hasInstantRequisitions } from "app/models/order"
import OpenResultsSummaryModalButton from "app/results-summary/components/OpenResultsSummaryModalButton/OpenResultsSummaryModalButton"
import { colors, textPrimaryColor } from "app/theme"
import { PatientOrdersOrder } from "app/types"
import makeAppStyles from "app/utils/makeAppStyles"

import { CancelOrderButton } from "./CancelOrderButton"
import { OrderAgainButton } from "./OrderAgainButton"
import PatientOrdersPhysicianAuthorizationMessage from "./PatientOrdersPhysicianAuthorizationMessage"
import ResultsReviewedButton from "./ResultsReviewedButton"
import ViewRequisitionsButton from "./ViewRequisitionsButton"
import usePatientOrderStyles from "./hooks/usePatientOrderStyles"

const useStyles = makeAppStyles((theme) => ({
  header: {
    display: "flex",
    flexDirection: "column",
    gap: 6,

    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
      alignItems: "space-between",
    },
  },
  labelsSlot: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
  titleSlot: {
    display: "flex",
    flexDirection: "column",
    marginBottom: 8,
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
    },
  },
  title: {
    fontSize: 24,
    fontWeight: 600,
    lineHeight: 1.4,
    color: textPrimaryColor,
    marginRight: 24,
  },
  details: {
    display: "flex",
    flexDirection: "column",
    gap: 5,
    fontSize: 14,
    textTransform: "uppercase",
    color: colors.blueGray[500],
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
    },
  },
  statusSlot: {
    flex: "0 1 auto",
  },
  headerActions: {
    display: "flex",
    gap: 22,
    justifyContent: "stretch",
    alignItems: "stretch",
    [theme.breakpoints.down("md")]: {
      display: "grid",

      columnGap: 16,
      rowGap: 16,
      gridTemplateColumns: "1fr 1fr",
    },
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "1fr",
      rowGap: 12,
    },
  },
}))

export interface PatientOrdersOrderHeaderProps {
  order: PatientOrdersOrder
  onEditOrder: () => void
}

const displayOrderStatus = (order: PatientOrdersOrder) => {
  // TODO: Revamp order status in the backend to reflect the different possible ordering statuses for blood draw companies
  const orderMayNotHaveShipment = order.ordered_tests.some(
    (ot) => ot.sample_collection_coordinator === "LAB"
  )
  if (
    order.status === ORDER_STATUS.AWAITING_SHIPMENT &&
    orderMayNotHaveShipment
  ) {
    return ORDER_STATUS.IN_PROGRESS
  }
  return order.status
}

const HeaderActions = ({
  onEditOrder,
  order,
}: PatientOrdersOrderHeaderProps) => {
  const sharedClasses = usePatientOrderStyles()
  const headerClasses = useStyles()
  return (
    <div className={headerClasses.headerActions}>
      {[ORDER_STATUS.DRAFT, ORDER_STATUS.PENDING_PAYMENT].some(
        (status) => status === order.status
      ) && (
        <Tooltip
          arrow
          disableHoverListener={!hasInstantRequisitions(order)}
          title="Edit cart is not available for orders with instant requisitions. If needed, you can cancel this order and create a new one."
        >
          <span className={sharedClasses.buttonContainer}>
            <DesignSystemButton
              color="secondary"
              shadow="default"
              disabled={hasInstantRequisitions(order)}
              startIcon={
                hasInstantRequisitions(order) ? EditDarkIcon : EditIcon
              }
              onClick={() => onEditOrder()}
              className={sharedClasses.button}
              size="xsmall"
            >
              Edit
            </DesignSystemButton>
          </span>
        </Tooltip>
      )}

      {[
        ORDER_STATUS.PENDING_PAYMENT,
        ORDER_STATUS.BLOCKED_MISSING_SIGNATURE,
        ORDER_STATUS.BLOCKED_PRACTITIONER_ACCOUNT_UNVERIFIED,
      ].some((status) => status === order.status) && (
        <CancelOrderButton orderId={order.id} />
      )}

      {hasInstantRequisitions(order) &&
        order.status !== ORDER_STATUS.CANCELED && (
          <ViewRequisitionsButton order={order} patient={order.patient} />
        )}

      {canOrderAgain(order) && <OrderAgainButton order={order} />}

      {[ORDER_STATUS.COMPLETE, ORDER_STATUS.RESULTS_REVIEWED].some(
        (status) => status === order.status
      ) && <ResultsReviewedButton order={order} />}

      {order.has_ordered_results && (
        <OpenResultsSummaryModalButton orderId={order?.id} />
      )}
    </div>
  )
}

export default function PatientOrdersOrderHeader({
  onEditOrder,
  order,
}: PatientOrdersOrderHeaderProps) {
  const classes = useStyles()
  const isMobile = !useMediaQuery((theme: Theme) => theme.breakpoints.up("md"))

  let PlacedByComponent: ReactElement | null = null

  if (order.storefront) {
    PlacedByComponent = (
      <span>
        LabShop: <span className="font-semibold">{order.storefront.name}</span>
      </span>
    )
  } else if (
    order.practitioner &&
    order.created_by.id === order.practitioner.user.id
  ) {
    PlacedByComponent = (
      <span>
        Placed by:{" "}
        <span className="font-semibold">{order.created_by.full_name}</span>
      </span>
    )
  } else {
    PlacedByComponent = (
      <span>
        Placed by:{" "}
        <span className="font-semibold">{order.created_by.full_name}</span>
        {order.practitioner && (
          <>
            {" "}
            for{" "}
            <span className="font-semibold">
              {order.practitioner.user.full_name}
            </span>
          </>
        )}
      </span>
    )
  }

  return (
    <div className="flex flex-col gap-6">
      <div className={classes.header}>
        <div className={classes.labelsSlot}>
          <div className={classes.titleSlot}>
            <Typography
              className={clsx(classes.title, "fs-exclude")}
              variant="h5"
            >
              {order.status === ORDER_STATUS.DRAFT
                ? `Last Updated ${moment(order.updated_at).format("LL")}`
                : `Placed ${moment(order.date_sent_to_patient).format("LL")}`}
            </Typography>
          </div>
          <Typography className={classes.details} variant="h6">
            {PlacedByComponent}
            {order.requires_physician_authorization && (
              <>
                {!isMobile && <span>{"·"}</span>}
                <span>{"Physician Authorized Order"}</span>
              </>
            )}
            {order.requires_vendor_physician_authorization && (
              <>
                {!isMobile && <span>{"·"}</span>}
                <PatientOrdersPhysicianAuthorizationMessage />
              </>
            )}
          </Typography>
        </div>
        <div className={classes.statusSlot}>
          <OrderStatus
            status={displayOrderStatus(order)}
            total_completed_ordered_tests={order.total_completed_ordered_tests}
            total_ordered_tests={order.total_ordered_tests}
            specimen_issue_status={order.specimen_issue_status}
          />
        </div>
        {/* {isMobile && <HeaderActions onEditOrder={onEditOrder} order={order} />} */}
      </div>
      <div className="mb-[29.5px]">
        <HeaderActions onEditOrder={onEditOrder} order={order} />
      </div>
    </div>
  )
}
