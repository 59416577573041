import BodyText from "app/components/design-system/BodyText"
import useCachedResource from "app/swr/hooks/use-cached-resource"
import { ResourceIdentifier } from "app/swr/types"
import { colors, rupaTeal, textPrimaryColor } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"
import { ResultsInterpretationBiomarker } from "types/results-interpretation"

import NumericBiomarkerGraphic from "./NumericBiomarkerGraphic"

const useStyles = makeAppStyles((theme) => ({
  root: {
    width: "100%",
    display: "flex",
    flexFlow: "column nowrap",
    gap: theme.spacing(1.0),
  },
  header: {
    display: "flex",
    flexFlow: "column nowrap",
    gap: theme.spacing(1.0),
    [theme.breakpoints.up("md")]: {
      flexFlow: "row nowrap",
    },
  },
  headerLabel: {
    flex: "1 1 30%",
    display: "flex",
    flexFlow: "column nowrap",
  },
  headerLabelName: {
    color: rupaTeal,
    fontWeight: 700,
    fontSize: 15,
  },
  headerLabelValue: {
    color: textPrimaryColor,
    fontWeight: 600,
    fontSize: 29,
  },
  headerLabelUnits: {
    color: colors.blueGray[400],
    fontWeight: 600,
    fontSize: 18,
  },
  headerGraphic: {
    flex: "2 2 70%",
  },
}))

export default function BiomarkerRenderer({
  identifier,
}: {
  identifier: ResourceIdentifier
}) {
  const classes = useStyles()
  const biomarker =
    useCachedResource<ResultsInterpretationBiomarker>(identifier)

  if (!biomarker) {
    return null
  }

  const {
    description,
    name,
    normal_max,
    normal_min,
    units,
    value,
    value_type,
  } = biomarker.attributes

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <div className={classes.headerLabel}>
          <div className={classes.headerLabelName}>{name}</div>

          <div className={classes.headerLabelValue}>
            {value || "---"}

            {units && <span className={classes.headerLabelUnits}>{units}</span>}
          </div>
        </div>
        <div className={classes.headerGraphic}>
          {value &&
            (() => {
              switch (value_type) {
                case "numeric": {
                  return (
                    <NumericBiomarkerGraphic
                      normalMax={normal_max}
                      normalMin={normal_min}
                      value={value}
                    />
                  )
                }
              }
            })()}
        </div>
      </div>
      {description && (
        <BodyText color="textPrimary" variant="body1">
          {description}
        </BodyText>
      )}
    </div>
  )
}
