import { useRouteMatch } from "react-router-dom"

import Container from "@material-ui/core/Container"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"

import { PatientPaths } from "app/Routes"
import facebookIcon from "app/assets/images/facebook-icon.svg"
import instagramIcon from "app/assets/images/instagram-icon.svg"
import linkedinIcon from "app/assets/images/linkedin-icon.svg"
import mediumIcon from "app/assets/images/medium-icon.svg"
import Logo from "app/components/Logo"

function PatientLinks() {
  return (
    <Grid item sm={7}>
      <Grid container>
        <Grid item xs={4}>
          <Typography variant="body1" className="mb-8">
            <a
              href="https://www.rupahealth.com/return-policy"
              target="_blank"
              rel="noopener noreferrer"
              className="text-gray-900"
            >
              Return Policy
            </a>
          </Typography>
          <Typography variant="body1" className="mb-8">
            <a
              href="https://support.rupahealth.com/en/collections/6796338-patient-support"
              target="_blank"
              rel="noopener noreferrer"
              className="text-gray-900"
            >
              FAQ
            </a>
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="body1" className="mb-8">
            <a
              href="https://support.rupahealth.com/en/collections/6796347-navigating-your-blood-tests"
              target="_blank"
              rel="noopener noreferrer"
              className="text-gray-900"
            >
              Scheduling Blood Draws
            </a>
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  )
}

function Footer() {
  const isPatientPath = Boolean(useRouteMatch(PatientPaths))

  return (
    <footer
      className="relative border-t pt-9 pb-2 bg-white mt-6 flex-shrink-0"
      style={{ borderColor: "#5F9AAC" }}
    >
      <Container fixed maxWidth="xl">
        <Grid container>
          <Grid item xs={5}>
            <div className="pr-6 pb-3">
              <Logo />
            </div>
          </Grid>
          {isPatientPath && PatientLinks()}
        </Grid>

        <Grid container>
          <Grid item xs={6} className="py-3">
            <Typography variant="body1">
              &copy; {new Date().getFullYear()} Rupa Inc. All Rights Reserved
              &nbsp;|&nbsp;
              <a
                href="https://www.rupahealth.com/privacy-policy"
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-700"
              >
                Privacy Policy
              </a>
              &nbsp;|&nbsp;
              <a
                href="https://www.rupahealth.com/terms-of-service"
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-700"
              >
                Terms of Service
              </a>
            </Typography>
          </Grid>
          <Grid item xs={6} className="flex items-center justify-end">
            <a
              href="https://www.instagram.com/rupa_health/"
              target="_blank"
              rel="noopener noreferrer"
              className="ml-3 opacity-75"
            >
              <img
                src={instagramIcon}
                alt="Rupa Health Instagram"
                width={17}
                height={17}
              />
            </a>
            <a
              href="https://www.facebook.com/rupahealth"
              target="_blank"
              rel="noopener noreferrer"
              className="ml-3 opacity-75"
            >
              <img
                src={facebookIcon}
                alt="Rupa Health Facebook"
                width={17}
                height={17}
              />
            </a>
            <a
              href="https://www.linkedin.com/company/rupahealth/"
              target="_blank"
              rel="noopener noreferrer"
              className="ml-3 opacity-75"
            >
              <img
                src={linkedinIcon}
                alt="Rupa Health LinkedIn"
                width={17}
                height={17}
              />
            </a>
            <a
              href="https://medium.com/@rupahealth"
              target="_blank"
              rel="noopener noreferrer"
              className="ml-3 opacity-75 pt-1"
            >
              <img
                src={mediumIcon}
                alt="Rupa Health Medium Blog"
                width={17}
                height={17}
              />
            </a>
          </Grid>
        </Grid>
      </Container>
    </footer>
  )
}

export default Footer
