import makeAppStyles from "app/utils/makeAppStyles"

import BodyText from "../design-system/BodyText"

const useStyles = makeAppStyles<{ floating: boolean }>({
  container: {
    display: "flex",
    alignItems: "center",
    padding: "8px",
    borderRadius: (props) => (props.floating ? 12 : 6),
    border: "1px solid rgba(255, 255, 255, 0)",
    width: "100%",
    textAlign: "left",

    "&:hover": {
      background: "rgba(255, 255, 255, 0.1)",
      border: "1px solid rgba(255, 255, 255, 0.03)",
    },
  },
  iconStyle: {
    marginRight: 16,
    width: 19,
  },
  labelStyle: {
    flex: 1,
    color: "white",
  },
})

interface Props {
  icon?: string
  label: string
  onClick: () => void
  floating?: boolean
}

const AccountMenuItem = ({ icon, label, onClick, floating = false }: Props) => {
  const { container, iconStyle, labelStyle } = useStyles({ floating })

  return (
    <button className={container} onClick={onClick}>
      {icon && <img className={iconStyle} src={icon} alt="" />}
      <BodyText weight="semibold" className={labelStyle}>
        {label}
      </BodyText>
    </button>
  )
}

export default AccountMenuItem
