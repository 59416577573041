import { useEffect, useState } from "react"

import { faXmark } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button } from "@rupahealth/design"

import Tooltip from "app/components/Tooltip"
import { RupaCircularProgress } from "app/components/design-system/RupaCircularProgress"

interface Props {
  percentComplete: number
  name: string
  onRemove: () => void
  onComplete: () => Promise<void>
}

export const ResultInProgressPill: React.FC<Props> = ({
  percentComplete,
  name,
  onRemove,
  onComplete,
}) => {
  const [progress, setProgress] = useState(percentComplete)

  useEffect(() => {
    // Start a timer that runs every 2 seconds and increments progress (this is not a true
    // representation of the progress, but a way to make the UX better)
    const interval = setInterval(() => {
      setProgress((prev) => {
        if (percentComplete > prev) return percentComplete
        return prev < 90 ? prev + 2 : prev
      })
    }, 2000)

    return () => clearInterval(interval)
  }, [percentComplete])

  useEffect(() => {
    const complete = async () => {
      setProgress(100)
      await onComplete()
      onRemove()
    }

    if (percentComplete === 100) complete()
  }, [percentComplete])

  return (
    <div className="flex flex-nowrap items-center gap-2 w-full sm:w-52 bg-white border border-slate-200 shadow-sm rounded-md py-1.5 px-2 text-sm">
      <RupaCircularProgress
        value={progress}
        variant={progress === 100 ? "indeterminate" : "determinate"}
        size={18}
        thickness={7}
      />
      <Tooltip
        className="min-w-0 overflow-hidden whitespace-nowrap overflow-ellipsis"
        title={name}
        arrow
      >
        <span>{name}</span>
      </Tooltip>
      <div className="ml-auto flex items-center justify-center">
        <Button
          variant="ghost"
          size="icon"
          className="h-4 w-4 rounded-sm"
          onClick={onRemove}
        >
          <FontAwesomeIcon
            icon={faXmark}
            className="text-slate-400 text-xl19"
          />
        </Button>
      </div>
    </div>
  )
}
