import { makeStyles } from "@material-ui/core/styles"

import BodyText from "app/components/design-system/BodyText"
import { LABCORP_COMPANY_KEYS, QUEST_COMPANY_KEYS } from "app/constants"
import { navy } from "app/theme"

const useStyles = makeStyles({
  instructions: {
    color: navy,
    opacity: "50%",
    maxWidth: 200,
  },
})

const getPhlebotomyInstructions = (labCompanyKey) => {
  if (LABCORP_COMPANY_KEYS.includes(labCompanyKey)) {
    return <BodyText>Phlebotomy required at Labcorp draw site</BodyText>
  }
  if (QUEST_COMPANY_KEYS.includes(labCompanyKey)) {
    return <BodyText>Phlebotomy required at Quest draw site</BodyText>
  }
  return <BodyText>Phlebotomy required</BodyText>
}

export const PhlebotomyRequired = ({ labCompanyKey }) => {
  const classes = useStyles()

  return (
    <div className={classes.instructions}>
      {getPhlebotomyInstructions(labCompanyKey)}
    </div>
  )
}
