import { ComponentProps } from "react"

import { Grow } from "@material-ui/core"
import MuiDialog from "@material-ui/core/Dialog"
import MuiDialogActions from "@material-ui/core/DialogActions"
import MuiDialogContent, {
  DialogContentProps,
} from "@material-ui/core/DialogContent"
import MuiDialogTitle from "@material-ui/core/DialogTitle"
import IconButton from "@material-ui/core/IconButton"
import Typography from "@material-ui/core/Typography"
import { fade, makeStyles, withStyles } from "@material-ui/core/styles"

import CrossIcon from "../assets/icons/blue-cross.svg"
import { textPrimaryColor, veryLightBlue } from "../theme"

const styles = (theme) => ({
  closeButton: {
    padding: 16,
  },
  title: {
    paddingTop: 12,
    paddingBottom: 12,
    paddingRight: 8,
  },
  titleSmall: {
    paddingTop: 0,
    paddingBottom: 0,
    paddingRight: 8,
  },
})

export const ModalHeader = withStyles(styles)((props: any) => {
  const { title, subtitle, classes, onClose, ariaLabelId, size, ...other } =
    props
  return (
    <MuiDialogTitle
      disableTypography
      {...other}
      className={size === "sm" ? classes.titleSmall : classes.title}
    >
      <div className="flex items-center py-1 fs-unmask">
        <div className="flex flex-grow flex-col pr-6">
          <Typography
            className="font-semibold text-lg flex-grow"
            variant="h4"
            id={ariaLabelId}
          >
            {title}
          </Typography>
          {subtitle && (
            <Typography className="text-base mt-1">{subtitle}</Typography>
          )}
        </div>
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            className={classes.closeButton}
          >
            <img src={CrossIcon} alt="Cross" />
          </IconButton>
        ) : null}
      </div>
    </MuiDialogTitle>
  )
})

export interface ModalContentProps {
  backgroundColor?: string
  borderTop?: string
  borderBottom?: string
}

const useModalStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    backgroundColor: (props: ModalContentProps) =>
      props.backgroundColor || fade(textPrimaryColor, 0.05),
    borderTop: (props: ModalContentProps) =>
      props.borderTop || `solid 1px ${veryLightBlue}`,
    borderBottom: (props: ModalContentProps) =>
      props.borderBottom || `solid 1px ${veryLightBlue}`,
  },
}))

export const ModalContent = (props: ModalContentProps & DialogContentProps) => {
  const styles = useModalStyles(props)

  return <MuiDialogContent className={styles.root} {...props} />
}

export const ModalActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
}))(MuiDialogActions)

/**
 * Default modal component. By default disables enforcing focus on just the modal so the chat modal still works.
 * @param  props
 * @returns
 */
export const Dialog = (props: ComponentProps<typeof MuiDialog>) => {
  return (
    <MuiDialog
      disableEnforceFocus
      TransitionComponent={Grow}
      transitionDuration={{
        appear: 500,
        enter: 500,
        exit: 300,
      }}
      {...props}
    />
  )
}
