import { SURVEY_STATUS } from "app/constants"
import {
  trackPractitionerOnboardingSurveyCompleted,
  trackPractitionerOnboardingSurveySkipped,
} from "app/services/segment"
import { updatePractitionerDontHandleErrors } from "app/store/actions"
import {
  OnboardingSelections,
  OnboardingVersions,
  ReferralSource,
} from "app/types"
import { handleApiError } from "app/utils"

export const SHOW_SURVEY_MODAL = "[DASHBOARD] SHOW SURVEY MODAL"
export const FINISH_SURVEY_STEP = "[DASHBOARD] FINISH SURVEY STEP"

type ShowSurveyModalAction = { type: typeof SHOW_SURVEY_MODAL }
type FinishSurveyStepAction = { type: typeof FINISH_SURVEY_STEP }

export type OnboardingActionType =
  | ShowSurveyModalAction
  | FinishSurveyStepAction

export function startOnboardingSurveyIfRequired(
  practitioner,
  taskBasedOnboarding
) {
  return async (dispatch, getState) => {
    if (!practitioner.id || !window.Intercom) {
      return
    }

    if (!needsOnboarding(practitioner)) {
      dispatch({ type: FINISH_SURVEY_STEP })
      return
    }

    // don't show survey if prac was invited by someone else in the clinic, unless task based onboarding is enabled
    if (
      practitioner.referral_source !== ReferralSource.CLINIC_INVITATION ||
      taskBasedOnboarding
    ) {
      dispatch({
        type: SHOW_SURVEY_MODAL,
      })
    } else {
      try {
        await dispatch(
          updatePractitionerDontHandleErrors({
            onboarding_survey_status: SURVEY_STATUS.USER_EXCLUDED,
            onboarding_version: practitioner.onboarding_version
              ? practitioner.onboarding_version
              : OnboardingVersions.V1,
            onboarding_selection: practitioner.onboarding_selection
              ? practitioner.onboarding_selection
              : OnboardingSelections.ONE_ON_ONE,
          })
        )
        trackIntercomOnboardingComplete({
          is_clinic_staff: practitioner.is_clinic_staff,
          physician_services_intention:
            practitioner.physician_services_intention,
        })
        dispatch({ type: FINISH_SURVEY_STEP })
      } catch (error) {
        dispatch(handleApiError(error))
      }
    }
  }
}

function needsOnboarding(practitioner) {
  return (
    practitioner &&
    !practitioner.is_clinic_staff &&
    practitioner.onboarding_survey_status &&
    [
      SURVEY_STATUS.AWAITING_USER_TRIGGER,
      SURVEY_STATUS.STARTED_AND_INCOMPLETE,
    ].includes(practitioner.onboarding_survey_status)
  )
}

export function trackIntercomOnboardingComplete(fields) {
  window.Intercom("trackEvent", "onboarding-complete")
  window.Intercom("update", fields)
}

export function submitOnboardingSurvey(answers, practitioner) {
  return async (dispatch) => {
    try {
      await dispatch(
        updatePractitionerDontHandleErrors({
          onboarding_survey_answers: answers,
          onboarding_survey_status: SURVEY_STATUS.COMPLETED,
          // if the onboarding version and selections are already set, do not override them
          onboarding_version: practitioner.onboarding_version
            ? practitioner.onboarding_version
            : OnboardingVersions.V1,
          onboarding_selection: practitioner.onboarding_selection
            ? practitioner.onboarding_selection
            : OnboardingSelections.ONE_ON_ONE,
        })
      )
      trackPractitionerOnboardingSurveyCompleted()
      trackIntercomOnboardingComplete({
        is_clinic_staff: practitioner.is_clinic_staff,
        physician_services_intention: practitioner.physician_services_intention,
        ...answers,
      })
      dispatch({ type: FINISH_SURVEY_STEP })
    } catch (error) {
      dispatch(handleApiError(error))
    }
  }
}

export function skipOnboardingSurvey(practitioner) {
  return async (dispatch) => {
    try {
      await dispatch(
        updatePractitionerDontHandleErrors({
          onboarding_survey_status: SURVEY_STATUS.SKIPPED,
          onboarding_version: practitioner.onboarding_version
            ? practitioner.onboarding_version
            : OnboardingVersions.V1,
          onboarding_selection: practitioner.onboarding_selection
            ? practitioner.onboarding_selection
            : OnboardingSelections.ONE_ON_ONE,
        })
      )
      trackPractitionerOnboardingSurveySkipped()
      trackIntercomOnboardingComplete({
        is_clinic_staff: practitioner.is_clinic_staff,
        physician_services_intention: practitioner.physician_services_intention,
      })
      dispatch({ type: FINISH_SURVEY_STEP })
    } catch (error) {
      dispatch(handleApiError(error))
    }
  }
}
