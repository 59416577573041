import { useState } from "react"

import { styled } from "@material-ui/core"

import DropletIcon from "app/assets/icons/droplet-dark.svg"
import { ReactComponent as InfoCircleIcon } from "app/assets/images/info-circle-dark.svg"
import Tooltip from "app/components/Tooltip"
import BodyText from "app/components/design-system/BodyText"
import { getLabCompanyText } from "app/phlebotomyUtils"
import { primaryColor } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"

const BodyContainer = styled("div")({
  marginTop: 14,
  display: "flex",
  flexDirection: "row",
})

const IconImage = styled("img")({
  width: 10.24,
})

interface Props {
  isPhlebotomyRequired: boolean
  labCompanyKey: string
}

const usePhlebotomySectionStyles = makeAppStyles({
  tooltipIcon: {
    width: 15,
    height: 15,
    fill: primaryColor,
    verticalAlign: "middle",
    marginLeft: "6px",
  },
})

const PhlebotomySection = ({ isPhlebotomyRequired, labCompanyKey }: Props) => {
  const { columnNode, tooltipNode } = getLabCompanyText(labCompanyKey)
  const [isTooltipOpen, setIsTooltipOpen] = useState(false)
  const classes = usePhlebotomySectionStyles()

  return (
    <>
      <BodyText size="sm">Phlebotomy</BodyText>
      <BodyContainer>
        <IconImage src={DropletIcon} alt="phlebotomy" />
        <div>
          <div className="pl-1.5 font-semibold">
            {isPhlebotomyRequired ? columnNode : "Not Required"}

            {!!tooltipNode && isPhlebotomyRequired && (
              <Tooltip
                arrow
                interactive
                open={isTooltipOpen}
                onClose={() => setIsTooltipOpen(false)}
                onOpen={() => setIsTooltipOpen(true)}
                placement="bottom"
                title={tooltipNode}
              >
                <span>
                  <InfoCircleIcon
                    className={classes.tooltipIcon}
                    viewBox="0 0 15 15"
                  />
                </span>
              </Tooltip>
            )}
          </div>
        </div>
      </BodyContainer>
    </>
  )
}

export default PhlebotomySection
