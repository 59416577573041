import { useMemo } from "react"

import { isEmpty } from "lodash"

import {
  ColumnDef,
  ColumnFiltersState,
  ExpandedState,
  OnChangeFn,
  PaginationState,
  getCoreRowModel,
  getExpandedRowModel,
  useReactTable,
} from "@tanstack/react-table"

import { DashboardOrder } from "types/dashboard/dashboard-order"

declare module "@tanstack/react-table" {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface TableMeta<TData extends unknown> {
    count?: number
    isLoading: boolean
    isValidating: boolean
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface ColumnMeta<TData extends unknown, TValue> {
    /**
     * Styles to be applied to each cell in the column, including both data and header cells.
     *
     */
    cellStyle?: React.CSSProperties

    /**
     * Styles to be applied to each data cell in the column.
     */
    dataCellStyle?: React.CSSProperties

    /**
     * Styles to be applied to each header cell in the column.
     */
    headerCellStyle?: React.CSSProperties

    /**
     * Whether or not the column should be sticky on hover.
     */
    stickyOnRowHover?: "left" | "right"
  }
}

export enum TabId {
  DRAFT = "draft",
  IN_PROGRESS = "in_progress",
  RESULTS_IN = "results_in",
  REVIEWED = "reviewed",
  ALL = "all",
  ALERTS = "alerts",
}

export enum DashboardEvents {
  DASHBOARD_TAB_CLICKED = "Dashboard Tab Clicked",
}

export interface GlobalFilterState {
  tab?: TabId
}

export interface UseTableProps {
  columns: ColumnDef<DashboardOrder, any>[]
  columnFilters: ColumnFiltersState
  count?: number
  data: DashboardOrder[]
  expanded: ExpandedState
  globalFilter: GlobalFilterState
  isLoading: boolean
  isValidating: boolean
  onColumnFiltersChange: OnChangeFn<ColumnFiltersState>
  onExpandedChange: OnChangeFn<ExpandedState>
  onGlobalFilterChange: OnChangeFn<GlobalFilterState>
  onPaginationChange: OnChangeFn<PaginationState>
  pagination: PaginationState
}

export default function useTable({
  columns,
  columnFilters,
  count,
  data,
  expanded,
  globalFilter,
  isLoading,
  isValidating,
  onColumnFiltersChange,
  onExpandedChange,
  onGlobalFilterChange,
  onPaginationChange,
  pagination,
}: UseTableProps) {
  const meta = useMemo(
    () => ({
      count,
      isLoading,
      isValidating,
    }),
    [count, isLoading, isValidating]
  )
  const pageCount = count ? Math.ceil(count / pagination.pageSize) : -1

  return useReactTable({
    columns,
    data,
    // TODO: Add back expansion once we implement TableRowDetailRenderer
    enableExpanding: true,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getRowId(row) {
      return row.id
    },
    getRowCanExpand(row) {
      return (
        row.original.attributes.cached_status !== "Draft" &&
        !isEmpty(row.original.relationships.kits.data)
      )
    },
    manualExpanding: true,
    manualFiltering: true,
    manualPagination: true,
    meta,
    onColumnFiltersChange,
    onExpandedChange,
    onGlobalFilterChange,
    onPaginationChange,
    pageCount,
    state: {
      columnFilters,
      expanded,
      globalFilter,
      pagination,
    },
  })
}
