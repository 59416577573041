import * as React from "react"

import NiceModal, { muiDialog, useModal } from "@ebay/nice-modal-react"
import { Dialog, Slide } from "@material-ui/core"
import { TransitionProps } from "@material-ui/core/transitions"

import ResultsPDFViewer from "app/results-summary/components/ResultsPDFViewer"
import ResultsSummaryEditor from "app/results-summary/components/ResultsSummaryEditor"
import useInterpretationDraft from "app/results-summary/hooks/use-interpretation-draft"
import useResultsSummaryResources from "app/results-summary/hooks/use-results-summary-resources"

import ResultsSummaryModalContent from "./ResultsSummaryModalContent"
import ResultsSummaryModalHeader from "./ResultsSummaryModalHeader"

export interface ResultsSummaryModalProps {
  orderId: string
}

const ResultsSummaryModalTransition = React.forwardRef(
  (
    props: TransitionProps & { children?: React.ReactElement },
    ref: React.Ref<unknown>
  ) => {
    return <Slide direction="up" ref={ref} {...props} />
  }
)

const ResultsSummaryModal = ({ orderId }: ResultsSummaryModalProps) => {
  const modal = useModal()
  const resources = useResultsSummaryResources(orderId)
  const { isInitializing, orderSwr, interpretationSwr } = resources
  const interpretationDraft = useInterpretationDraft(interpretationSwr)
  const muiDialogControl = muiDialog(modal)

  return (
    <Dialog
      {...muiDialogControl}
      disableEnforceFocus
      disableEscapeKeyDown
      fullScreen
      transitionDuration={500}
      TransitionComponent={ResultsSummaryModalTransition}
    >
      <ResultsSummaryModalHeader
        onClose={muiDialogControl.onClose}
        order={orderSwr.data}
        showLoading={
          orderSwr.isValidating ||
          isInitializing ||
          interpretationSwr.isValidating ||
          interpretationDraft.isPatching
        }
      />

      <ResultsSummaryModalContent
        editor={
          <ResultsSummaryEditor {...resources} {...interpretationDraft} />
        }
        fileViewer={<ResultsPDFViewer orderSwr={orderSwr} />}
      />
    </Dialog>
  )
}

const ResultsSummaryNiceModal = NiceModal.create<ResultsSummaryModalProps>(
  (props) => {
    return <ResultsSummaryModal key={props.orderId} {...props} />
  }
)

export default ResultsSummaryNiceModal
