import { makeStyles } from "@material-ui/core/styles"

import BodyText from "app/components/design-system/BodyText"

import { TableCellProps } from "./types"

const useStyles = makeStyles((theme) => ({
  patientNameContainer: {
    display: "flex",
    alignItems: "center",
    minWidth: 300,
  },
}))

export default function PatientNameTableCell({ order }: TableCellProps) {
  const classes = useStyles()
  return (
    <div className={classes.patientNameContainer}>
      <BodyText weight="semibold">{order.patient.full_name}</BodyText>
    </div>
  )
}
