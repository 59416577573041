import { makeStyles, Typography } from "@material-ui/core"

import ThumbnailIcon from "app/assets/icons/results-summary/results-thumbnail-icon.svg"
import { NavyTooltip } from "app/components/NavyTooltip"
import useCachedResource from "app/swr/hooks/use-cached-resource"
import { ResourceIdentifier } from "app/swr/types"
import { colors, primaryColor } from "app/theme"
import { Kit } from "types/kit"
import { LabCompany } from "types/lab-company"
import { OrderedResult } from "types/ordered-result"

const useStyles = makeStyles((theme) => ({
  thumbnail: {
    borderRadius: 3,
    width: 42,
    height: 55,
    "&:hover": {
      border: `2px solid ${colors.lightBlue[200]}`,
      cursor: "pointer",
    },
    "&[data-is-active=true]": {
      backgroundColor: colors.lightBlue[50],
      border: `2px solid ${primaryColor}`,
      color: primaryColor,
    },
  },
  labTestName: {
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: 12,
  },
  labCompanyName: {
    fontSize: 12,
  },
}))

export interface ResultsPDFViewerProps {
  orderedResultIdentifier: ResourceIdentifier
  onThumbnailClick: (id: string) => void
  isActive: boolean
}

const ResultsPDFThumbnail = ({
  orderedResultIdentifier,
  onThumbnailClick,
  isActive,
}: ResultsPDFViewerProps) => {
  const classes = useStyles()
  const orderedResult = useCachedResource<OrderedResult>(
    orderedResultIdentifier
  )
  const kit = useCachedResource<Kit>(orderedResult?.relationships.kit.data)
  const labCompany = useCachedResource<LabCompany>(
    orderedResult?.relationships.lab_company.data
  )
  const thumbnailUrl =
    orderedResult?.attributes.result_file_thumbnail ?? ThumbnailIcon

  return (
    <NavyTooltip
      arrow
      interactive
      placement="bottom"
      disableTouchListener
      title={
        <div>
          <Typography className={classes.labTestName}>
            {kit?.attributes.name}
          </Typography>
          <Typography className={classes.labCompanyName}>
            {labCompany?.attributes.name}
          </Typography>
        </div>
      }
    >
      <img
        className={classes.thumbnail}
        src={thumbnailUrl}
        onClick={() => onThumbnailClick(orderedResultIdentifier.id)}
        data-is-active={isActive}
        alt={kit?.attributes.name}
      />
    </NavyTooltip>
  )
}

export default ResultsPDFThumbnail
