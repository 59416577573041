import { z } from "zod"

export const CreateEcommerceFormSchema = () =>
  z.object({
    draft_name: z.string().nonempty("A Storefront Title is required."),
    draft_description: z
      .string()
      .nonempty("A LabShop description is required."),
    draft_description_richtext: z.any(),
    draft_logo: z.string().nullable(),
    draft_video_link: z
      .string()
      .regex(
        /^(http:\/\/|https:\/\/)(vimeo\.com|youtu\.be|www\.youtube\.com)\/([\w\/]+)([\?-].*)?$/gi, // eslint-disable-line no-useless-escape
        "Please enter a valid YouTube or Vimeo link"
      )
      .nullable(),
    draft_send_notification_emails: z.boolean(),
    draft_send_to_ehr: z.boolean(),
    draft_ordering_practitioner_id: z.string(),
    draft_custom_fee_id: z.string().nullable(),
  })
