import makeUseResource from "./make-resource"
import {
  StorefrontType,
  StorefrontClinicType,
  StorefrontLabTestType,
  StorefrontLabTestBundleType,
  StorefrontCustomFeeType,
  StorefrontStripeConnectAccountType,
  StorefrontLabCompanyType,
  StorefrontLabTestTypeType,
  StorefrontProductType,
  StorefrontBiomarkerType,
  StorefrontHealthCategoryType,
  StorefrontPractitionerType,
  StorefrontSectionType,
} from "./types"

const {
  defaultState,
  useResource,
  useResourceByIdSelector,
  useResourceSelector,
  ResourceProvider,
} = makeUseResource<
  | StorefrontType
  | StorefrontClinicType
  | StorefrontLabTestType
  | StorefrontLabTestBundleType
  | StorefrontCustomFeeType
  | StorefrontStripeConnectAccountType
  | StorefrontLabCompanyType
  | StorefrontLabTestTypeType
  | StorefrontProductType
  | StorefrontBiomarkerType
  | StorefrontHealthCategoryType
  | StorefrontPractitionerType
  | StorefrontSectionType
>()

export type ResourceProviderProps = React.ComponentProps<
  typeof ResourceProvider
>

export {
  defaultState,
  useResource,
  useResourceByIdSelector,
  useResourceSelector,
  ResourceProvider,
}
