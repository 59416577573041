import { useCallback, useEffect, useMemo, useState } from "react"

import { Grid, TablePagination } from "@material-ui/core"
import { Skeleton } from "@rupahealth/design"
import { PaginationState } from "@tanstack/react-table"

import useFeatureFlag, { FeatureFlag } from "app/hooks/use-feature-flag"
import useAppSelector from "app/hooks/useAppSelector"
import { ResultsUploadButton } from "app/main/results-upload-v2/ResultsUploadButton"
import { PatientPortalUser } from "app/patient-portal/types"
import {
  DOCUMENT_ACCESS_EVENTS,
  trackDocumentAccessEvent,
} from "app/services/segment"
import { Patient, ResourceType } from "app/types"
import { Document } from "types/patient-with-documents"

import { PatientOrdersTabHeader } from "../components/PatientOrdersTabHeader"
import ResultVisualiztionNotifications from "../trends/components/ResultVisualizationNotifications"
import DocumentTypeSelect from "./DocumentTypeSelect"
import FoodPlanCreateButton from "./FoodPlanCreateButton"
import FoodPlanDocumentCard from "./FoodPlanDocumentCard"
import ImportedUserResultDocumentCard from "./ImportedUserResultDocumentCard"
import OrderUserResultDocumentCard from "./OrderUserResultDocumentCard"
import PatientDocumentsListEmpty from "./PatientDocumentsListEmpty"
import ResultsInterpretationDocumentCard from "./ResultsInterpretationDocumentCard"
import { usePatientDocuments } from "./hooks/use-patient-documents"

interface Props {
  inPatientPortal: boolean
  patient?: Patient
  user?: PatientPortalUser
}

const PatientDocumentsList: React.FC<Props> = ({
  inPatientPortal,
  patient,
  user,
}) => {
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 18,
  })
  const [isResultsOverTimeManualUploadEnabled] = useFeatureFlag(
    FeatureFlag.ResultsOverTimeManualResultsUpload
  )
  const [showResultVisualizationNotifications] = useFeatureFlag(
    FeatureFlag.ShowResultVisualizationNotifications
  )

  const patientId = inPatientPortal
    ? user?.relationships.patient.data?.[0]?.id ?? ""
    : patient?.id ?? ""
  const patientUserId = inPatientPortal
    ? user?.id ?? ""
    : patient?.user.id ?? ""
  const patientFirstName = inPatientPortal
    ? user?.attributes.first_name ?? ""
    : patient?.first_name ?? ""

  const practitioner = useAppSelector(({ practitioner }) => practitioner)

  useEffect(() => {
    if (patientId) {
      if (inPatientPortal && patientUserId) {
        trackDocumentAccessEvent(
          DOCUMENT_ACCESS_EVENTS.DOCUMENT_ACCESS_PATIENT_DOCUMENTS_PAGE_VISIT,
          {
            user_id: patientUserId,
            patient_id: patientId,
          }
        )
      } else if (practitioner?.id) {
        trackDocumentAccessEvent(
          DOCUMENT_ACCESS_EVENTS.DOCUMENT_ACCESS_DOCUMENTS_PAGE_VISIT,
          {
            user_id: practitioner.user?.id,
            practitioner_id: practitioner?.id,
          }
        )
      }
    }
  }, [patientId, patientUserId, practitioner])

  const {
    data: documents,
    isLoading,
    isValidating,
    totalDocumentCount,
    selectedDocumentType,
    onCreateFoodPlan,
    onDocumentDelete,
    onDocumentsUpdate,
  } = usePatientDocuments({
    patientId,
    patientUserId,
    patientFirstName,
    pagination,
  })

  // Show loading state when validating because ResultsInProgress may force
  // validation
  const showLoadingState = useMemo(
    () => isLoading || isValidating,
    [isLoading, isValidating]
  )

  const DocumentCard = useCallback(
    (document: Document) => {
      switch (document.attributes.resource_type) {
        case ResourceType.FOOD_PLAN:
          return (
            <FoodPlanDocumentCard
              patientId={patientId}
              document={document}
              inPatientPortal={inPatientPortal}
              onDelete={onDocumentDelete}
            />
          )
        case ResourceType.ORDER_USER_RESULT:
          return (
            <OrderUserResultDocumentCard
              patientId={patientId}
              patientUserId={patientUserId}
              document={document}
              inPatientPortal={inPatientPortal}
            />
          )
        case ResourceType.RESULTS_INTERPRETATION:
          return (
            <ResultsInterpretationDocumentCard
              document={document}
              inPatientPortal={inPatientPortal}
              onDelete={onDocumentDelete}
            />
          )
        case ResourceType.IMPORTED_USER_RESULT:
          return (
            <ImportedUserResultDocumentCard
              patientId={patientId}
              document={document}
              inPatientPortal={inPatientPortal}
              onDelete={onDocumentDelete}
            />
          )
      }
    },
    [onDocumentDelete, patient]
  )

  const handlePageChange = (newPageIndex: number) => {
    setPagination((prev) => ({ ...prev, pageIndex: newPageIndex }))
  }

  return (
    <div className="flex flex-col gap-5">
      {showResultVisualizationNotifications && !inPatientPortal && (
        <ResultVisualiztionNotifications patientId={patientId} />
      )}

      <div className="rounded-lg p-7 bg-white shadow">
        {/* dont show the header in case it's pract mode (when patient is provided) because in patient portal buttons 
        should be hidden for now and later will be located outside of this compoenent in top page header  */}
        {!inPatientPortal && (
          <PatientOrdersTabHeader
            title={`${patientFirstName}'s Documents`}
            actionButtons={[
              <FoodPlanCreateButton onCreate={onCreateFoodPlan} />,
              ...(isResultsOverTimeManualUploadEnabled
                ? [
                    <ResultsUploadButton
                      patientId={patientId}
                      onClose={onDocumentsUpdate}
                    />,
                  ]
                : []),
            ]}
          />
        )}

        <div className="mb-4">
          <DocumentTypeSelect
            disabled={showLoadingState}
            inPatientPortal={inPatientPortal}
          />
        </div>

        {showLoadingState ? (
          <LoadingGrid />
        ) : documents.length ? (
          <Grid container spacing={2}>
            {documents.map((document) => (
              <Grid item key={document.id} xs={12} sm={12} md={6} lg={4}>
                {DocumentCard(document)}
              </Grid>
            ))}
          </Grid>
        ) : (
          <PatientDocumentsListEmpty
            patientPortalUserId={user?.id}
            patientFirstName={patientFirstName}
            totalDocumentCount={totalDocumentCount}
            selectedDocumentType={selectedDocumentType}
          />
        )}
      </div>
      <TablePagination
        component="div"
        className="mt-2 mb-20"
        count={totalDocumentCount}
        page={pagination.pageIndex}
        onChangePage={(_, newPage) => handlePageChange(newPage)}
        rowsPerPage={pagination.pageSize}
        rowsPerPageOptions={[]}
      />
    </div>
  )
}

const LoadingGrid = () => {
  return (
    <Grid container spacing={2}>
      {Array.from(Array(6).keys()).map((idx) => (
        <Grid item key={idx} xs={12} sm={12} md={6} lg={4}>
          <div className="flex items-center rounded-lg p-3 gap-3.5">
            <Skeleton className="h-[110px] min-w-[90px]" />
            <div className="space-y-5 w-full">
              <Skeleton className="h-4 w-2/5" />
              <div className="space-y-2">
                <Skeleton className="h-4 w-3/4" />
                <Skeleton className="h-3 w-1/2" />
              </div>
            </div>
          </div>
        </Grid>
      ))}
    </Grid>
  )
}

export default PatientDocumentsList
