import { BiomarkerCustomDescription } from "types/biomarker-custom-description"
import { BloodReportResult } from "types/blood-report-result"

import BloodLabDashboardsSnapshotBiomarkerGrouping from "./BloodLabDashboardsSnapshotBiomarkerGrouping"

export default function BloodLabDashboardsSnapshotAbnormalSection({
  bloodReportResults,
  showHighLowDescriptions,
  biomarkerCustomDescriptionsMapping,
}: {
  bloodReportResults: BloodReportResult[]
  showHighLowDescriptions?: boolean
  biomarkerCustomDescriptionsMapping?: Record<
    string,
    BiomarkerCustomDescription
  >
}) {
  return (
    <div className="rounded-xl border border-red-500 shadow-sm overflow-hidden">
      <div className="bg-red-50 text-red-900 px-5 py-3 rounded-xl font-semibold leading-normal">
        Abnormal
      </div>
      <div>
        <BloodLabDashboardsSnapshotBiomarkerGrouping
          bloodReportResults={bloodReportResults}
          showHighLowDescriptions={showHighLowDescriptions}
          biomarkerCustomDescriptionsMapping={
            biomarkerCustomDescriptionsMapping
          }
        />
      </div>
    </div>
  )
}
