import { ReactComponent as BloodIcon } from "app/assets/icons/bundle-blood.svg"
import { ReactComponent as UpRightArrowIcon } from "app/assets/icons/up-right-arrow.svg"
import useFeatureFlag, { FeatureFlag } from "app/hooks/use-feature-flag"
import { colors, primaryColor, navy } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"

import PatientPortalBanner from "./PatientPortalBanner"

const useStyles = makeAppStyles((theme) => ({
  text: {
    color: navy,
    fontFamily: '"Open Sans", sans-serif',
    fontSize: "15px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "135%",
    paddingRight: 50,
  },
  bookingLink: {
    color: primaryColor,
  },
  icon: {
    marginLeft: 4.5,
    marginBottom: 3,
  },
}))

export interface PatientPortalBookingBannerProps {
  bookingUrl: string
}

export default function PatientPortalBookingBanner({
  bookingUrl,
}: PatientPortalBookingBannerProps) {
  const classes = useStyles()

  const [AxlePhlebotomyFlagEnabled] = useFeatureFlag(FeatureFlag.AxlePhlebotomy)

  if (!AxlePhlebotomyFlagEnabled || !bookingUrl) {
    return null
  }

  return (
    <PatientPortalBanner
      icon={
        <BloodIcon
          className={classes.icon}
          fill={primaryColor}
          viewBox="0 0 18 18"
        />
      }
      borderColor={colors.blue[50]}
      backgroundColor={colors.blue[50]}
      iconBackgroundColor={colors.blue[100]}
    >
      <div className={classes.text}>
        You paid for a mobile blood draw but haven’t booked an appointment yet.{" "}
        <a
          href={bookingUrl}
          target="_blank"
          rel="noopener noreferrer"
          className={classes.bookingLink}
        >
          Book now <UpRightArrowIcon fill={primaryColor} />
        </a>
      </div>
    </PatientPortalBanner>
  )
}
