import BodyText from "app/components/design-system/BodyText"
import { PatientPortalOrder } from "app/types"
import makeAppStyles from "app/utils/makeAppStyles"

import { CompanyPanel } from "../types"
import PanelLineItem from "./PanelLineItem"

interface DrawCenterLineItemsProps {
  panels: CompanyPanel[]
  showPrices: boolean
  order: PatientPortalOrder
  isEditing: boolean
  updatingOrderedTestIds: string[]
  isUpdatingOrder: boolean
  enableLineItems: (orderedTestIds: string[]) => void
  disableLineItems: (orderedTestIds: string[]) => void
}

const useStyles = makeAppStyles(() => ({
  infoWrapper: {
    display: "flex",
    alignItems: "center",
  },
}))

const DrawCenterLineItems = ({
  panels,
  showPrices,
  order,
  isEditing,
  updatingOrderedTestIds,
  isUpdatingOrder,
  enableLineItems,
  disableLineItems,
}: DrawCenterLineItemsProps) => {
  const classes = useStyles()
  return (
    <div className="mt-6">
      <div className={classes.infoWrapper}>
        <BodyText weight="semibold">
          You'll get an order form for these tests to take to a draw center:
        </BodyText>
      </div>
      {panels.map((panel) => (
        <PanelLineItem
          key={panel.groupKey}
          panel={panel}
          showPrices={showPrices}
          order={order}
          isEditing={isEditing}
          updatingOrderedTestIds={updatingOrderedTestIds}
          enableLineItems={enableLineItems}
          disableLineItems={disableLineItems}
          isUpdatingOrder={isUpdatingOrder}
        />
      ))}
    </div>
  )
}
export default DrawCenterLineItems
