import {
  Accordion as MuiAccordion,
  AccordionDetails,
  AccordionSummary,
  styled,
  withStyles,
} from "@material-ui/core"

import BlueCloseIcon from "app/assets/icons/blue-cross-thin.svg"
import Markdown from "app/components/Markdown"
import BodyText from "app/components/design-system/BodyText"
import { colors } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"

interface Props {
  question: string
  answer: string
  expanded: boolean
  onExpand: () => void
}

const Accordion = withStyles({
  root: {
    boxShadow: "none",
    borderBottom: `1px solid ${colors.blueGray[200]}`,
    "&:before": {
      display: "none",
    },
    padding: "20px 0",
    "&$expanded": {
      margin: 0,
    },
  },
  expanded: {
    margin: 0,
  },
})(MuiAccordion)

const Summary = withStyles({
  root: {
    minHeight: "auto",
    "&$expanded": {
      minHeight: "auto",
    },
  },
  content: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    margin: 0,
    "&$expanded": {
      margin: 0,
    },
  },
  expanded: {
    margin: 0,
  },
})(AccordionSummary)

const Question = styled(BodyText)({
  flex: 1,
  paddingRight: 16,
})

const CloseButton = styled("div")({
  background: colors.trueGray[100],
  height: 35,
  width: 35,
  borderRadius: 100,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
})

const CloseIcon = styled("img")({
  transition: "0.48s transform",
  width: 10,
})

const Details = styled(AccordionDetails)({
  paddingRight: 51,
})

const useStyles = makeAppStyles(() => ({
  content: {
    "& span": {
      display: "block",
      marginBottom: 12,
    },
  },
}))

const FAQ = ({ question, answer, expanded, onExpand }: Props) => {
  const classes = useStyles()

  return (
    <Accordion expanded={expanded} onChange={onExpand}>
      <Summary>
        <Question size="base" weight="semibold">
          {question}
        </Question>
        <CloseButton>
          <CloseIcon
            src={BlueCloseIcon}
            alt=""
            style={{
              transform: `rotate(-${expanded ? 0 : 135}deg)`,
            }}
          />
        </CloseButton>
      </Summary>
      <Details>
        <Markdown padded={false} className={classes.content}>
          {answer}
        </Markdown>
      </Details>
    </Accordion>
  )
}

export default FAQ
