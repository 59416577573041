import * as Actions from "../actions"

const initialState = {
  stripeConnectAccounts: [],
  customFees: [],
}

const customFeesReducer = function (state = initialState, action) {
  switch (action.type) {
    case Actions.GET_STRIPE_CONNECT_ACCOUNTS: {
      return {
        ...state,
        stripeConnectAccounts: action.payload,
      }
    }
    case Actions.GET_CUSTOM_FEES: {
      return {
        ...state,
        customFees: action.payload,
      }
    }
    case Actions.REMOVE_CUSTOM_FEES: {
      return {
        ...state,
        customFees: action.payload,
      }
    }
    default: {
      return state
    }
  }
}

export default customFeesReducer
