import { useMemo } from "react"

import DropperIcon from "app/assets/icons/dropper-dark.svg"
import HealthCategoryIcon from "app/assets/icons/health-category.svg"
import HeartIcon from "app/assets/icons/heart.svg"
import OfficeBlockIcon from "app/assets/icons/office-block-dark-gray.svg"
import SigningPractitionerFilterIcon from "app/assets/icons/person-shield.svg"
import PhlebotomyIcon from "app/assets/icons/phlebotomists.svg"
import StateRestrictionFilterIcon from "app/assets/icons/state-restriction-filter.svg"
import Logo from "app/components/Logo"
import PhysicianServicesInfoTooltip from "app/components/OrderingAccess/lab-ordering-access-v2/components/PhysicianServicesInfoTooltip"
import SampleTypePill from "app/components/SampleTypePill"
import {
  VENDOR_PHYSICIAN_AUTHORIZATION_LABEL,
  VIBRANT_COMPANY_KEYS,
} from "app/constants"
import useFeatureFlag, { FeatureFlag } from "app/hooks/use-feature-flag"
import useAppSelector from "app/hooks/useAppSelector"
import { selectPractitioner } from "app/store/selectors/practitioner.selectors"
import { labTestLocation } from "app/types"

import { PHYSICIAN_SERVICES_FILTER_VALUE } from "../consts"
import { FilterConfig } from "../types"

export const ALLOWED_IN_STATE = "allowed_in_state"

/*
 * Returns a list of filter configuration used to render the filters.
 */
const useFilterConfig = (location: labTestLocation) => {
  const healthCategories = useAppSelector(
    (state) => state.healthCategory.sortedByName
  )
  const allLabCompanies = useAppSelector((state) => state.labCompany.list)
  const labCompanies = useMemo(() => {
    return (
      allLabCompanies?.filter(
        (labCompany) => !VIBRANT_COMPANY_KEYS.includes(labCompany.key)
      ) || []
    )
  }, [allLabCompanies])

  const labTestTypes = useAppSelector((state) => state.labTestType.list)

  const practitioner = useAppSelector(selectPractitioner)
  const allTeamMembers = useAppSelector((state) => state.teamMember)
  const teamMemberOptions = useMemo(() => {
    return (
      Object.values(allTeamMembers)
        .filter((tm) => !tm.is_clinic_staff)
        .map((tm) => ({ value: tm.id, label: tm.titled_full_name }))
        .sort((a, b) => a.label.localeCompare(b.label)) || []
    )
  }, [allTeamMembers])

  const [beastCoastEnabled] = useFeatureFlag(FeatureFlag.BeastCoast)
  const [signingPracFilterEnabled] = useFeatureFlag(
    FeatureFlag.CatalogSigningPractitionerFilter
  )
  const showSigningPractitionerFilter = useMemo(() => {
    // Don't show it on the public catalog page
    return signingPracFilterEnabled && practitioner
  }, [signingPracFilterEnabled, practitioner])

  if (
    !(healthCategories && labCompanies && labTestTypes && teamMemberOptions)
  ) {
    return null
  }

  const signingPractitionerFilter: FilterConfig = {
    label: "Signing Practitioner",
    filterName: "signing_practitioner",
    displayLocations: [labTestLocation.CATALOG],
    icon: SigningPractitionerFilterIcon,
    defaultText: "",
    isRadio: true,
    options: [
      {
        value: PHYSICIAN_SERVICES_FILTER_VALUE,
        label: VENDOR_PHYSICIAN_AUTHORIZATION_LABEL,
      },
      ...teamMemberOptions,
    ],
    renderLabel: ({ value, label }) =>
      value === PHYSICIAN_SERVICES_FILTER_VALUE ? (
        <div className="flex items-center gap-2 font-semibold">
          <Logo className="w-[15px]" />
          {label}
        </div>
      ) : (
        <span className="font-semibold">{label}</span>
      ),
    renderLabelChildren: ({ value }) => {
      if (practitioner && value === PHYSICIAN_SERVICES_FILTER_VALUE) {
        return (
          <PhysicianServicesInfoTooltip
            showTurnOnButton={
              !practitioner.vendor_physician_authorization_enabled
            }
            physicianAuthorizationEnabled={
              practitioner.vendor_physician_authorization_enabled
            }
            returnToDescriptor="Lab Test Catalog"
          />
        )
      }
      return null
    },
  }

  const filterConfig: FilterConfig[] = [
    {
      label: "Category",
      filterName: "health_category",
      icon: HealthCategoryIcon,
      options: healthCategories.map((healthCompany) => ({
        value: healthCompany.id,
        label: healthCompany.name,
      })),
    },
    {
      label: "Lab Company",
      filterName: "lab_company",
      icon: OfficeBlockIcon,
      options: labCompanies.map((labCompany) => ({
        value: labCompany.id,
        label: labCompany.name,
      })),
    },
    ...(showSigningPractitionerFilter ? [signingPractitionerFilter] : []),
    {
      label: "Sample Type",
      filterName: "lab_test_type",
      icon: DropperIcon,
      options: labTestTypes.map((labTestType) => ({
        value: labTestType.id,
        label: labTestType.name,
      })),
      renderLabel: ({ label }) => <SampleTypePill name={label} />,
    },
    {
      label: "Phlebotomy",
      filterName: "phlebotomy_required",
      icon: PhlebotomyIcon,
      options: [
        {
          value: "true",
          label: "Required",
        },
        {
          value: "false",
          label: "Not Required",
        },
      ],
      isRadio: true,
    },
    {
      label: "Favorites",
      filterName: "favorites",
      icon: HeartIcon,
      options: [
        {
          value: "true",
          label: "Favorites Only",
        },
      ],
      isRadio: true,
      displayLocations: [labTestLocation.CATALOG],
    },
  ]

  if (beastCoastEnabled) {
    filterConfig.push({
      label: "Patient Location-Based Restrictions",
      filterName: ALLOWED_IN_STATE,
      icon: StateRestrictionFilterIcon,
      defaultText: "",
      options: [
        {
          value: "NY",
          label: "Patient is in NY",
        },
        {
          value: "NJ",
          label: "Patient is in NJ",
        },
        {
          value: "RI",
          label: "Patient is in RI",
        },
        {
          value: "",
          label: "Patient is in All Other 47",
        },
      ],
      isRadio: true,
      displayLocations: [labTestLocation.CATALOG, labTestLocation.CHECKOUT],
    })
  }

  return filterConfig.filter(
    // Remove filters that should not be displayed for the given location (CART, CATALOG, etc.)
    (config) =>
      !config.displayLocations || config.displayLocations.includes(location)
  )
}

export default useFilterConfig
