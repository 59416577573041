import { useState } from "react"
import { useDispatch } from "react-redux"

import { useMount } from "react-use"

import useFeatureFlag from "app/hooks/use-feature-flag"
import { FeatureFlag } from "app/providers/FeatureFlagProvider"
import { LabCompanyAlert, NoticeAlert } from "app/types"

import { API } from "../../../api"
import { handleApiError } from "../../../utils"
import AlertsListLegacy from "./AlertsListLegacy"
import BannerList from "./BannerList"

const Alerts: React.FC = (props) => {
  const dispatch = useDispatch()

  const [notice, setNotice] = useState<NoticeAlert | null>(null)
  const [labCompanyAlerts, setLabCompanyAlerts] = useState<LabCompanyAlert[]>(
    []
  )

  useMount(async () => {
    try {
      const [noticeData, labCompanyAlertsData] = await Promise.all([
        API.DashboardBanner.get(),
        API.ActiveAlerts.get(),
      ])

      setNotice(noticeData.data)
      setLabCompanyAlerts(labCompanyAlertsData.data.results)
    } catch (error) {
      dispatch(handleApiError(error))
    }
  })

  const [isBannerRenderingEnabled, isBannerRendineringLoading] = useFeatureFlag(
    FeatureFlag.RenderBannerObjects
  )

  if (isBannerRendineringLoading) {
    return null
  }

  if (isBannerRenderingEnabled) {
    return <BannerList {...props} />
  }
  return (
    <AlertsListLegacy notice={notice} labCompanyAlerts={labCompanyAlerts} />
  )
}

export default Alerts
