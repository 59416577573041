import { makeStyles } from "@material-ui/core/styles"

import { ReactComponent as CheckIcon } from "app/assets/icons/check-circle.svg"
import { colors } from "app/theme"

const useStyles = makeStyles(() => ({
  completedStep: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  completedStepText: {
    marginLeft: 10,
    fontWeight: 400,
  },
}))

export const TaskStepComplete = ({ taskText, style = {} }) => {
  const classes = useStyles()
  return (
    <div className={classes.completedStep} style={{ ...style }}>
      <CheckIcon
        width={18}
        height={18}
        viewBox="0 0 14 14"
        fill={colors.emerald[500]}
      />
      <div className={classes.completedStepText}>{taskText}</div>
    </div>
  )
}
