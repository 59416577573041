import { useDispatch } from "react-redux"

import useEventCallback from "app/hooks/use-event-callback"
import { handleApiSuccess } from "app/utils"

export default function useHandleApiSuccess() {
  const dispatch = useDispatch()
  return useEventCallback((message, autoHideDuration = 6000) =>
    dispatch(handleApiSuccess(message, autoHideDuration))
  )
}
