import { useState } from "react"

import clsx from "clsx"

import Loading from "app/components/Loading"
import BodyText from "app/components/design-system/BodyText"
import { StyledSwitch } from "app/main/settings/components/StyledSwitch"
import resourceRequest from "app/swr/utils/resource-request"
import { navy } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"

import AccountCardWrapper from "../AccountCardWrapper"
import InsuranceToggleTooltip from "../tooltips/InsuranceToggleTooltip"
import RemoveAccountTooltip from "../tooltips/RemoveAccountTooltip"

const useStyles = makeAppStyles(() => ({
  accountTitleRow: {
    margin: "24px 24px 0",
    display: "flex",
    justifyContent: "space-between",
  },
  accountStatusRow: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    padding: "6px 24px 24px",
  },
  accountStatusText: {
    color: navy,
  },
  toggleRow: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
  },
}))

const ConfirmedAccountCard = ({
  company,
  account,
  mutateLabCompanyAccounts,
}) => {
  const classes = useStyles()
  const toggleValue = account.attributes.default_for_insurance_orders
  const [isToggling, setIsToggling] = useState(false)
  const onInsuranceToggle = async () => {
    setIsToggling(true)

    const patch = {
      data: {
        id: account.id,
        type: "lab_company_account",
        attributes: {
          default_for_insurance_orders:
            !account.attributes.default_for_insurance_orders,
        },
      },
    }
    try {
      await resourceRequest({
        method: "PATCH",
        url: `/lab_company_account/${account.id}/`,
        data: patch,
      })
      await mutateLabCompanyAccounts()
    } finally {
      setIsToggling(false)
    }
  }
  return (
    <AccountCardWrapper
      company={company}
      isConfirmed={true}
      actionElement={<RemoveAccountTooltip companyName={company.display} />}
    >
      <div className={classes.accountStatusRow}>
        <BodyText size="md" className={classes.accountStatusText}>
          {account.attributes.registration_account_id}
        </BodyText>
      </div>
      <div className={clsx(classes.toggleRow, "mx-1")}>
        {isToggling ? (
          <div className="flex justify-center">
            <Loading ariaLabel="loading_insurance_change" />
          </div>
        ) : (
          <>
            <StyledSwitch
              checked={toggleValue}
              onChange={onInsuranceToggle}
              name="allow_in_office_kits"
              value={toggleValue}
            />
            <div className="mx-1">
              Give patients insurance option for all orders{" "}
              <InsuranceToggleTooltip />
            </div>
          </>
        )}
      </div>
    </AccountCardWrapper>
  )
}

export default ConfirmedAccountCard
