import { ReactComponent as CalendarIcon } from "app/assets/images/calendar-blue.svg"
import {
  ComparisonRow,
  ComparisonColumnProps,
  EmptyComparisonValue,
} from "app/main/comparison/components/ComparisonLabTests/components"
import useAnyComparedLabTestHasProperty from "app/main/comparison/hooks/use-any-compared-lab-test-has-property"

const ConsultationAvailabilityColumn = ({ labTest }: ComparisonColumnProps) => {
  return (
    <>
      {labTest?.lab_company.consultation_availability || (
        <EmptyComparisonValue />
      )}
    </>
  )
}

export default function ComparisonConsultationAvailabilityRow() {
  const hasConsultationAvailability = useAnyComparedLabTestHasProperty(
    "lab_company.consultation_availability"
  )
  if (!hasConsultationAvailability) {
    return null
  }
  return (
    <ComparisonRow
      Column={ConsultationAvailabilityColumn}
      headerIcon={<CalendarIcon viewBox="0 0 21 18" />}
      headerLabel="Consultation Availability"
    />
  )
}
