import { RootState } from "app/types"

import * as Actions from "../actions/practitionerEmrIntegrations.actions"

export const initialState: RootState["emrIntegrations"] = {
  error: undefined,
  pending: false,
  results: [],
}

export default function emrIntegrations(
  state = initialState,
  action:
    | Actions.GetPractitionerEMRIntegrationsAction
    | Actions.FailedPractitionerEMRIntegrationsAction
    | Actions.ReceivePractitionerEMRIntegrationsAction
): RootState["emrIntegrations"] {
  switch (action.type) {
    case Actions.GET_EMR_INTEGRATIONS: {
      return {
        ...state,
        pending: true,
      }
    }
    case Actions.FAILED_EMR_INTEGRATIONS: {
      return {
        ...state,
        error: action.payload,
        pending: false,
      }
    }
    case Actions.RECEIVE_EMR_INTEGRATIONS: {
      return {
        ...state,
        error: undefined,
        pending: false,
        results: action.payload,
      }
    }
    default:
      return state
  }
}
