import { Theme } from "@material-ui/core"

import {
  StorefrontLabTestBundleType,
  StorefrontLabTestType,
  useResourceByIdSelector,
} from "app/hooks/use-resource"
import makeAppStyles from "app/utils/makeAppStyles"

import EcommerceBundleCard from "../EcommerceBundleCard"
import EcommerceLabTestCard from "../EcommerceLabTestCard"

const styles = (theme: Theme) => ({
  container: { width: "100%", height: "100%" },
})

const useStyles = makeAppStyles(styles)

interface Props {
  id?: string | null
  openProductCustomizeModal: (productId: string) => void
  removeLabTest: (labTest: StorefrontLabTestType | undefined) => void
  removeBundle: (bundle: StorefrontLabTestBundleType | undefined) => void
  hidePhysServicesPricing: boolean
}

const UnsortableProduct = ({
  id,
  openProductCustomizeModal,
  removeLabTest,
  removeBundle,
  hidePhysServicesPricing,
}: Props) => {
  const classes = useStyles()

  const product = useResourceByIdSelector("storefront_product", id ? id : "")

  return (
    <div className={classes.container}>
      {product?.relationships.lab_test.data && (
        <EcommerceLabTestCard
          product={product}
          onLabTestDelete={removeLabTest}
          openModal={() => openProductCustomizeModal(product.id)}
          hidePhysServicesPricing={hidePhysServicesPricing}
        />
      )}
      {product?.relationships.bundle.data && (
        <EcommerceBundleCard
          product={product}
          onRemove={removeBundle}
          openModal={() => openProductCustomizeModal(product.id)}
          hidePhysServicesPricing={hidePhysServicesPricing}
        />
      )}
    </div>
  )
}

export default UnsortableProduct
