import { useState } from "react"
import * as React from "react"

import { FieldError } from "react-hook-form"

import { Typography } from "@material-ui/core"
import { Elements } from "@stripe/react-stripe-js"
import { loadStripe, PaymentMethod, StripeError } from "@stripe/stripe-js"

import Button from "app/components/design-system/Button"
import PaymentCollectionStripeCardForm from "app/main/payment-collection/components/PaymentCollectionStripeCardForm"
import PaymentCollectionStripeCardReadOnly from "app/main/payment-collection/components/PaymentCollectionStripeCardReadOnly"
import { stripePublishableAPIKey } from "app/settings"
import { colors, shadows } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"

const stripePromise = loadStripe(stripePublishableAPIKey)

const useStyles = makeAppStyles((theme) => ({
  container: {
    display: "flex",
    flexFlow: "column nowrap",
    gap: theme.spacing(1.0),
    padding: theme.spacing(3),
    backgroundColor: "white",
    boxShadow: shadows.default,
    border: `1px solid ${colors.blueGray[200]}`,
    borderRadius: 8,
  },
  header: {
    display: "flex",
    flexFlow: "row nowrap",
    alignItems: "center",
    justifyContent: "space-between",
    fontSize: 24,
    gap: theme.spacing(0.5),
  },
  clearButton: {
    marginTop: -4,
  },
}))

type Classes = ReturnType<typeof useStyles>

const PaymentCollectionPaymentMethodHeader = ({
  children,
  classes,
  clearable,
  onClear,
}: {
  children: React.ReactNode
  classes: Classes
  clearable: boolean
  onClear: () => void
}) => {
  return (
    <Typography className={classes.header} variant="h4" color="textPrimary">
      {children}

      {clearable && (
        <Button
          className={classes.clearButton}
          color="text"
          onClick={onClear}
          size="small"
          type="button"
        >
          {"Update"}
        </Button>
      )}
    </Typography>
  )
}

export interface PaymentCollectionStripeCardProps {
  active: boolean
  amountFormatted: string
  clearable: boolean
  emptyMessage: string
  error?: FieldError
  onClear: () => void
  onError: (stripeError: StripeError) => void
  onSubmit: (stripePaymentMethod: PaymentMethod) => void
  title: string
}

export default function PaymentCollectionStripeCard({
  active,
  amountFormatted,
  clearable,
  emptyMessage,
  error,
  onClear,
  onError,
  onSubmit,
  title,
}: PaymentCollectionStripeCardProps) {
  const classes = useStyles()
  const [paymentMethodData, setPaymentMethodData] = useState<PaymentMethod>()
  return (
    <div className={classes.container}>
      <PaymentCollectionPaymentMethodHeader
        classes={classes}
        clearable={clearable}
        onClear={() => {
          setPaymentMethodData(undefined)
          onClear()
        }}
      >
        {title}
      </PaymentCollectionPaymentMethodHeader>

      {active ? (
        <Elements stripe={stripePromise}>
          <PaymentCollectionStripeCardForm
            amountFormatted={amountFormatted}
            error={error}
            onError={onError}
            onSubmit={(stripePaymentMethod) => {
              setPaymentMethodData(stripePaymentMethod)
              onSubmit(stripePaymentMethod)
            }}
          />
        </Elements>
      ) : (
        <PaymentCollectionStripeCardReadOnly
          amountFormatted={amountFormatted}
          emptyMessage={emptyMessage}
          error={error}
          paymentMethodData={paymentMethodData}
        />
      )}
    </div>
  )
}
