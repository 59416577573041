import { cn } from "@rupahealth/design"

import BodyText from "app/components/design-system/BodyText"

interface Props {
  number: number
  title: string
  className?: string
}

const FormSectionTitle = ({ number, title, className }: Props) => {
  return (
    <div className={cn("flex gap-[5px] items-center", className)}>
      <div className="rounded-full bg-body-500 text-white font-bold px-[5px] text-[11px] w-[17px] h-[17px] flex items-center">
        {number}
      </div>
      <BodyText size="md" weight="semibold">
        {title}
      </BodyText>
    </div>
  )
}

export default FormSectionTitle
