import { ReactNode, useState } from "react"

import { DialogContent, Tab, Tabs, useMediaQuery } from "@material-ui/core"

import { colors } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"

const useMobileSwitchStyles = makeAppStyles((theme) => ({
  mobileSwitch: {
    display: "flex",
    width: "100%",
    padding: theme.spacing(1.0, 1.0, 0.0, 1.0),
    backgroundColor: colors.blueGray[100],
    borderBottomWidth: 1,
    borderColor: colors.blueGray[300],
  },
  mobileSwitchTabs: {
    width: "100%",
  },
}))

interface MobileSwitchProps {
  mobileTab: "fileViewer" | "editor"
  setMobileTab(mobileTab: "fileViewer" | "editor"): void
}

const MobileSwitch = ({ mobileTab, setMobileTab }: MobileSwitchProps) => {
  const classes = useMobileSwitchStyles()

  return (
    <div className={classes.mobileSwitch}>
      <Tabs
        aria-label="Toggle Lab Reports & Summarize"
        className={classes.mobileSwitchTabs}
        centered
        indicatorColor="primary"
        onChange={(_, nextMobileTab) => setMobileTab(nextMobileTab)}
        textColor="primary"
        value={mobileTab}
        variant="fullWidth"
      >
        <Tab disableRipple label="Lab Reports" value="fileViewer" />
        <Tab disableRipple label="Summarize" value="editor" />
      </Tabs>
    </div>
  )
}

const useResultsSummaryModalContentStyles = makeAppStyles((theme) => ({
  dialogContent: {
    display: "flex",
    flexFlow: "column nowrap",
    alignItems: "center",
    backgroundColor: colors.blueGray[100],
    borderBottomWidth: 1,
    borderColor: colors.blueGray[300],
    padding: 0,
    [theme.breakpoints.up("md")]: {
      flexFlow: "row nowrap",
    },
  },
  editor: {
    order: 2,
    flex: "1 1 auto",
    width: "100%",
    backgroundColor: "white",
    borderColor: colors.blueGray[300],
    borderLeftWidth: 1,
    borderBottomWidth: 1,
    [theme.breakpoints.up("md")]: {
      flex: "1 1 50%",
      width: "auto",
      height: "100%",
      overflow: "auto",
    },
  },
  fileViewer: {
    order: 1,
    flex: "1 1 auto",
    width: "100%",
    [theme.breakpoints.up("md")]: {
      flex: "1 1 50%",
      width: "auto",
      height: "100%",
      overflow: "auto",
    },
  },
}))

interface ResultsSummaryModalContentProps {
  editor: ReactNode
  fileViewer: ReactNode
}

export default function ResultsSummaryModalContent({
  fileViewer,
  editor,
}: ResultsSummaryModalContentProps) {
  const classes = useResultsSummaryModalContentStyles()
  const [mobileTab, setMobileTab] = useState<"fileViewer" | "editor">("editor")
  const isMobile = useMediaQuery<any>((theme) => theme.breakpoints.down("sm"))
  const fileViewerSection = (
    <div className={classes.fileViewer}>{fileViewer}</div>
  )
  const editorSection = <div className={classes.editor}>{editor}</div>

  let content: ReactNode

  if (isMobile) {
    // For the mobile view, we render a tab switch to toggle between the file viewer and editor.
    content = (
      <>
        <MobileSwitch mobileTab={mobileTab} setMobileTab={setMobileTab} />

        {mobileTab === "fileViewer" && fileViewerSection}

        {mobileTab === "editor" && editorSection}
      </>
    )
  } else {
    // For the desktop view, we render each section side-by-side as 50% width.
    content = (
      <>
        {fileViewerSection}
        {editorSection}
      </>
    )
  }

  return (
    <DialogContent className={classes.dialogContent}>{content}</DialogContent>
  )
}
