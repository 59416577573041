import { Skeleton } from "@material-ui/lab"

import { LAB_COMPANY_ACCOUNT_STATUS } from "app/constants"

import { LabCompanyAccount } from "../types"
import ConfirmedAccountCard from "./wrapped-components/ConfirmedAccountCard"
import EmptyAccountCard from "./wrapped-components/EmptyAccountCard"
import FailedAccountCard from "./wrapped-components/FailedAccountCard"
import ProcessingAccountCard from "./wrapped-components/ProcessingAccountCard"

interface AccountCardProps {
  displayCompany: { key: string; display: string }
  account?: LabCompanyAccount
  isLoadingLabCompanyAccounts: boolean
  mutateLabCompanyAccounts: () => void
}

const AccountCard = ({
  displayCompany,
  account,
  isLoadingLabCompanyAccounts,
  mutateLabCompanyAccounts,
}: AccountCardProps) => {
  // TODO: allow edits to account if status is created or manual registration required
  if (isLoadingLabCompanyAccounts) {
    return <Skeleton variant="rect" width={340} height={154} />
  }
  if (
    account?.attributes.status === LAB_COMPANY_ACCOUNT_STATUS.PENDING ||
    account?.attributes.status ===
      LAB_COMPANY_ACCOUNT_STATUS.MANUAL_REGISTRATION_REQUIRED ||
    account?.attributes.status === LAB_COMPANY_ACCOUNT_STATUS.CREATED
  ) {
    return <ProcessingAccountCard company={displayCompany} />
  }
  if (
    account?.attributes.status === LAB_COMPANY_ACCOUNT_STATUS.FAILED ||
    account?.attributes.status === LAB_COMPANY_ACCOUNT_STATUS.DENIED
  ) {
    return <FailedAccountCard company={displayCompany} account={account} />
  }
  if (account?.attributes.status === LAB_COMPANY_ACCOUNT_STATUS.CONFIRMED) {
    return (
      <ConfirmedAccountCard
        company={displayCompany}
        account={account}
        mutateLabCompanyAccounts={mutateLabCompanyAccounts}
      />
    )
  }
  return (
    <EmptyAccountCard
      displayCompany={displayCompany}
      mutateLabCompanyAccounts={mutateLabCompanyAccounts}
    />
  )
}

export default AccountCard
