import { useState } from "react"
import { useDispatch } from "react-redux"

import { useDebounce } from "@fuse/hooks"

import { API } from "app/api"
import useEventCallback from "app/hooks/use-event-callback"
import { handleApiError, handleApiSuccess } from "app/utils"

export default function useSendActivationLinks() {
  const dispatch = useDispatch()
  const [isSending, setIsSending] = useState(false)

  const _sendActivationLinks = useEventCallback(
    async (orderId: string, emailOfPractitioner: string) => {
      setIsSending(true)
      try {
        await API.Order.sendAccountActivationLinks(orderId)
        dispatch(
          handleApiSuccess(
            `Account activation link sent to ${emailOfPractitioner}`
          )
        )
      } catch (error) {
        dispatch(handleApiError(error))
      } finally {
        setIsSending(false)
      }
    }
  )

  // useDebounce to prevent multiple emails from being sent
  const sendActivationLinks = useDebounce(_sendActivationLinks, 300, {
    leading: true,
    trailing: false,
  }) as typeof _sendActivationLinks

  return {
    isSending,
    sendActivationLinks,
  }
}
