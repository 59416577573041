import { useEffect, useState } from "react"

import { Theme } from "@material-ui/core"

import BodyText from "app/components/design-system/BodyText"
import {
  OrderIntent,
  StorefrontProductType,
  StorefrontSectionType,
} from "app/hooks/use-resource"
import makeAppStyles from "app/utils/makeAppStyles"

import { CardOptions } from "../PublicStorefront"
import StorefrontCards from "./StorefrontCards"

interface Props {
  cart: OrderIntent | null
  currentProductId: string
  onAddTest: (type: CardOptions, id: string) => void
  currentSection: StorefrontSectionType | undefined
  allProducts: StorefrontProductType[]
  labshopLogo: string | null
  hidePhysServicesPricing: boolean
}

const MAX_NUMBER_OF_OTHER_TESTS = 4

const styles = (theme: Theme) => ({
  otherTestTitle: {
    "text-align": "center",
    marginBottom: 16,
  },
})

const useStyles = makeAppStyles(styles)

const OtherTests = ({
  cart,
  currentProductId,
  onAddTest,
  currentSection,
  allProducts,
  labshopLogo,
  hidePhysServicesPricing,
}: Props) => {
  const [showOtherTests, setShowOtherTests] = useState(true)
  const [productRefIds, setProductRefIds] = useState<string[]>([])

  const classes = useStyles()

  useEffect(() => {
    if (allProducts.length <= 1) {
      setShowOtherTests(false)
    } else {
      let products = currentSection
        ? currentSection?.relationships.storefront_products.data
            .filter((st) => st.id !== currentProductId)
            .map((st) => st.id)
            .slice(0, MAX_NUMBER_OF_OTHER_TESTS) // we only want to show 4 other tests max.
        : []

      if (products.length < MAX_NUMBER_OF_OTHER_TESTS) {
        const nonSectionProducts: string[] = allProducts
          .filter(
            (product) =>
              !products.includes(product.id) && product.id !== currentProductId
          )
          .map((product) => product?.id)
          .slice(0, MAX_NUMBER_OF_OTHER_TESTS - products.length)
        products.push(...nonSectionProducts)
      }

      setProductRefIds(products)
    }
  }, [])

  if (showOtherTests) {
    return (
      <>
        <div className={classes.otherTestTitle}>
          <BodyText size="md" weight="semibold">
            Other Tests
          </BodyText>
        </div>

        <StorefrontCards
          cart={cart}
          storefrontProductRefIds={productRefIds}
          onAddToCart={onAddTest}
          showTestingAvailabilityText={false}
          labshopLogo={labshopLogo}
          hidePhysServicesPricing={hidePhysServicesPricing}
        />
      </>
    )
  }

  return <></>
}

export default OtherTests
