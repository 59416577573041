import * as React from "react"
import { useMemo, useState } from "react"
import { useDispatch } from "react-redux"

import { differenceBy, every, merge, some, sum } from "lodash"

import { Button } from "@material-ui/core"
import Grow from "@material-ui/core/Grow"
import Paper from "@material-ui/core/Paper"
import Typography from "@material-ui/core/Typography"
import AddIcon from "@material-ui/icons/Add"
import { Skeleton } from "@material-ui/lab"

import { ReactComponent as BasketIcon } from "app/assets/icons/basket.svg"
import { ReactComponent as ComboGroupsIcon } from "app/assets/icons/combo-group-icon.svg"
import BodyText from "app/components/design-system/BodyText"
import InfoTextTooltip from "app/components/design-system/InfoTextTooltip"
import useComboGroupConfigurationModal from "app/components/modals/ComboGroupModal/hooks/use-combo-group-configuration-modal"
import {
  ORDER_LINE_ITEM_TYPE,
  ORDER_STATUS,
  QUEST_COMPANY_KEYS,
} from "app/constants"
import { IN_OFFICE_KIT_STATUS, OrderStatusUnion } from "app/constants.typed"
import { hasInstantRequisitionTests } from "app/dataServices/orderDataService"
import { isOrderedTestOrderable } from "app/dataServices/orderingRights"
import useFeatureFlag, { FeatureFlag } from "app/hooks/use-feature-flag"
import useAppSelector from "app/hooks/useAppSelector"
import { trackEventWithProperties } from "app/services/segment.typed"
import useCachedCollection from "app/swr/hooks/use-cached-collection"
import useCollectionSWR from "app/swr/hooks/use-collection-swr"
import { ResourceCollection } from "app/swr/types"
import { cancelRed, colors, navy, rupaTeal } from "app/theme"
import {
  ComboGroupOption,
  LabCompany,
  LabTest,
  Order,
  OrderLineItem,
  OrderTestWarningTypes,
  OrderedTest,
  Practitioner,
  SigningPractitioner,
} from "app/types"
import { formatDollars, handleApiError } from "app/utils"
import makeAppStyles from "app/utils/makeAppStyles"
import {
  getLineItemWithMaxAgeRestriction,
  getMinimumAgeAndWarning,
} from "app/utils/order-utils"
import { LabCompany as SWRLabCompany } from "types/lab-company"

import {
  ComboGroupAddToCartEventType,
  ComboGroupEvents,
} from "../combo-groups/constants"
import { InOfficeKitType } from "../in-office-kits/types"
import { requiresActivation } from "../in-office-kits/utils"
import { DeliveryMethod } from "./DeliveryMethod"
import IOKActivationDropdown from "./IOKActivationDropdown"
import { LabTestError } from "./LabTestItem"
import { OrderTestWarning } from "./OrderTestWarning"
import { PanelItem } from "./PanelItem"
import { PhlebotomyRequired } from "./PhlebotomyRequired"
import useCheckoutDraft from "./hooks/use-checkout-draft"
import FastingCheckbox from "./orderSidebar/FastingCheckbox"
import InsuranceToggle from "./orderSidebar/insurance/InsuranceToggle"
import * as Actions from "./store/actions/orders.actions"

const REMOVE_TIMEOUT_DURATION = 250

interface Props {
  order: Order
  lineItems: OrderLineItem[]
  panelName: string
  labCompany: LabCompany
  status: OrderStatusUnion
  edit: boolean
  onRemovePanel: (orderedTests: OrderedTest[]) => void
  onRemoveTest: (orderedTest: OrderedTest) => void
  onInstantRequisitionChange: (instantRequisition: boolean) => void
  parentOrderedTest?: OrderedTest
  parentLabTest?: LabTest
  openModalWithLabTest?: (labTest: LabTest) => void
  orderSigningPractitioner: Practitioner | SigningPractitioner | undefined
  comboGroupId?: string
  comboGroupOptions?: ComboGroupOption[]
  isLoadingLabCompanyAccounts?: boolean
  companiesWithConfirmedAccounts?: SWRLabCompany[]
  setKitActivationIdSelectedFor: (setter: (prev: string[]) => string[]) => void
}

const useStyles = makeAppStyles({
  panelCardWrapper: {
    padding: "12px 16px",
  },
  panelCardTitleWrapper: {
    display: "flex",
    justifyContent: "space-between",
  },
  panelCardTitle: {
    display: "flex",
    alignItems: "flex-start",
  },
  panelTitleText: {
    fontSize: "15px",
    fontWeight: 700,
    color: navy,
    flexGrow: 1,
  },
  orderTestWarningsWrapper: {
    display: "flex",
  },
  panelCardPricePlaceholder: {
    backgroundColor: colors.blueGray[200],
    borderRadius: 12,
    marginLeft: "auto",
    height: 14,
    marginTop: 5,
    marginBottom: 5,
    width: 60,
  },
  priceVaryText: {
    fontSize: "15px",
    color: navy,
    textAlign: "right",
    fontWeight: 700,
  },
  priceMayVaryTooltipText: {
    fontSize: "13px",
    fontWeight: 400,
  },
})

export default function PanelCard({
  order,
  lineItems,
  panelName,
  labCompany,
  status,
  edit,
  onRemovePanel,
  onRemoveTest,
  onInstantRequisitionChange,
  parentOrderedTest,
  parentLabTest,
  openModalWithLabTest,
  orderSigningPractitioner,
  comboGroupId,
  comboGroupOptions,
  isLoadingLabCompanyAccounts,
  companiesWithConfirmedAccounts,
  setKitActivationIdSelectedFor,
}: Props) {
  const classes = useStyles()

  const [visible, setVisible] = useState(true)
  const [loading, setLoading] = useState(false)
  const [removing, setRemoving] = useState(false)

  const [insuranceToggledOn, setInsuranceToggledOn] = React.useState(
    Boolean(order?.insurance_enabled_for?.includes(labCompany.key))
  )
  const [inOfficeKitsV2] = useFeatureFlag(FeatureFlag.InOfficeKitsV2)
  const [isComboGroupsEnabled] = useFeatureFlag(FeatureFlag.ComboGroupsEnabled)
  const [isInsuranceUpdatesForHealthGorillaEnabled] = useFeatureFlag(
    FeatureFlag.InsuranceUpdatesForHealthGorilla
  )
  const modal = useComboGroupConfigurationModal()
  const practitioner = useAppSelector(({ practitioner }) => practitioner)
  const dispatch = useDispatch()
  const { values: checkoutDraftValues } = useCheckoutDraft()

  const hasIOKRequiringActivation = requiresActivation(labCompany.key)

  const orderedTests = lineItems
    .filter((lineItem) => lineItem.ordered_test)
    .map((lineItem) => lineItem.ordered_test)

  const validTestIds = orderedTests
    ?.map((test) => test?.id)
    .filter(Boolean) as string[]

  const { data: inStockInOfficeKitIdentifiers } = useCollectionSWR<
    ResourceCollection<InOfficeKitType>
  >(
    validTestIds ? "/in_office_kit/" : null,
    {
      params: {
        ordered_tests: validTestIds,
        status: IN_OFFICE_KIT_STATUS.IN_STOCK,
      },
    },
    {
      onError: (error) => {
        if ((error as any).response?.status !== 404) {
          handleApiError(error)
        }
      },
      shouldHandleApiError: false,
    }
  )

  const inStockInOfficeKits = useCachedCollection<InOfficeKitType>(
    inStockInOfficeKitIdentifiers
  )

  const orderedTestLineItems = lineItems.flatMap((lineItem) =>
    lineItem.type === ORDER_LINE_ITEM_TYPE.LAB_TEST ? lineItem : []
  )
  const isComboGroup = Boolean(comboGroupId) && isComboGroupsEnabled

  const isPriced = every(
    lineItems,
    (lineItem) => typeof lineItem.cost !== "undefined"
  )
  const rupaCostSum = sum(
    lineItems.map((lineItem) =>
      parseFloat(lineItem.discounted_cost || lineItem.cost)
    )
  )
  const totalRupaCost = formatDollars(rupaCostSum)
  const totalMsrpCost = formatDollars(
    sum(
      lineItems.map((lineItem) =>
        lineItem.cost !== undefined ? parseFloat(lineItem.cost) : 0
      )
    )
  )

  let totalComboGroupOptionsCost = ""
  let comboSavingsTotal = ""
  if (isComboGroup) {
    // Sum all options in combo group
    const totalComboOptionCost = sum(
      comboGroupOptions?.map((option) =>
        option.lab_test.rupa_price !== undefined
          ? parseFloat(option.lab_test.rupa_price)
          : 0
      ) || []
    )

    const totalAddOnCost = sum(
      orderedTestLineItems.flatMap((lineItem) => {
        return lineItem.ordered_test.lab_test.id !== parentLabTest?.id
          ? parseFloat(lineItem.discounted_cost || lineItem.cost)
          : 0
      })
    )

    comboSavingsTotal = formatDollars(
      totalComboOptionCost + totalAddOnCost - rupaCostSum
    )

    totalComboGroupOptionsCost = formatDollars(
      totalComboOptionCost + totalAddOnCost
    )
  }

  const handleRemovePanel = () => {
    if (removing) {
      // The panel is already being removed; ignore the duplicate request.
      return
    }
    setVisible(false)
    setRemoving(true)
    setTimeout(
      () =>
        onRemovePanel(
          orderedTestLineItems.map((lineItem) => lineItem.ordered_test)
        ),
      REMOVE_TIMEOUT_DURATION
    )
  }

  const availableAddOns = differenceBy(
    parentLabTest?.add_ons,
    order.line_items,
    (item) => ("name" in item ? item.name : item.title)
  )
  const hasMoreAddons = !!availableAddOns.length

  const allTestsSupportInstantReq = every(
    orderedTestLineItems,
    (lineItem) => lineItem.ordered_test.lab_test.has_instant_requisition
  )
  const hasInStockInOfficeKits = !!inStockInOfficeKits?.length

  const instantRequisitionEnabled = orderedTests[0]?.instant_requisition

  const phlebotomyRequired = some(
    orderedTestLineItems.map((lineItem) =>
      some(
        lineItem.ordered_test.lab_test.lab_test_types.map(
          (labTest) => labTest.phlebotomy_required
        )
      )
    )
  )

  const comboGroupLineItem = orderedTestLineItems.find(
    (li) => li.ordered_test.combo_group === comboGroupId
  )
  const comboGroupTargetLabTest = comboGroupLineItem?.ordered_test?.lab_test
  const comboGroupOptionIds =
    comboGroupLineItem?.ordered_test?.combo_group_options?.map(
      (option) => option.id
    )
  const addOnIds = orderedTestLineItems.flatMap((lineItem) => {
    const labTest = lineItem.ordered_test.lab_test
    if (labTest.id === parentLabTest?.id) {
      return []
    }

    return labTest.id
  })

  const handleUpdateComboGroup = async (payload) => {
    await dispatch(Actions.updateComboGroupTest(parentOrderedTest?.id, payload))
    trackEventWithProperties(
      ComboGroupEvents.COMBO_GROUP_ADD_TO_CART_FROM_MODAL_CLICKED,
      {
        comboGroupLabTestId: comboGroupTargetLabTest!.id,
        practitionerId: practitioner.id,
        comboGroupId,
        orderId: order.id,
        orderedTestId: parentOrderedTest?.id,
        type: ComboGroupAddToCartEventType.UPDATE,
      }
    )
    modal.hide()
  }

  // Show delivery method if allow_in_office_kits is set to True or if the order has any
  // instant_requisition_tests (This is to handle existing orders).
  // Don't show while using vendor physician authorization
  const showDeliveryMethod = inOfficeKitsV2
    ? !order?.requires_vendor_physician_authorization &&
      (order?.practitioner?.clinic?.clinic_features?.includes(
        "allow_in_office_kits"
      ) ||
        hasInstantRequisitionTests(order))
    : !order?.requires_vendor_physician_authorization &&
      (order?.practitioner?.allow_in_office_kits ||
        hasInstantRequisitionTests(order))

  const lineItemWithShippingDelay = orderedTestLineItems.flatMap((lineItem) =>
    lineItem?.ordered_test?.lab_test?.active_shipping_alert ? lineItem : []
  )[0]

  const lineItemWithMaxAgeRestriction = useMemo(
    () => getLineItemWithMaxAgeRestriction(orderedTestLineItems),
    [lineItems]
  )

  const filteredLineItems = lineItems.filter((lineItem) => {
    if (parentLabTest) {
      return lineItem?.ordered_test?.lab_test?.id !== parentLabTest.id
    }

    return true
  })

  const { error, errorText, warningOnly, warningText } = useMemo(() => {
    let errorItems = orderedTestLineItems
      .map((lineItem) =>
        merge(
          { labTestName: lineItem.title },
          isOrderedTestOrderable(lineItem.ordered_test)
        )
      )
      .filter((item) => item.error)
    let warningItems = errorItems.filter((item) => item.warningOnly)

    let error = errorItems.length > 0
    let warningOnly = warningItems.length === errorItems.length

    let errorText = !error
      ? ""
      : lineItems.length === 1
      ? errorItems[0].errorText
      : `The following tests are currently unavailable: ${errorItems
          .filter((item) => item.error && !item.warningOnly)
          .map((item) => item.labTestName)
          .join(", ")}.`

    let warningText = !warningItems.length
      ? ""
      : lineItems.length === 1
      ? warningItems[0].errorText
      : `Warning: The following tests can not be collected in ${
          order.shipping_state
        } and require a waiver: ${warningItems
          .map((item) => item.labTestName)
          .join(", ")}.`

    if (
      isComboGroup &&
      comboGroupLineItem?.type === ORDER_LINE_ITEM_TYPE.LAB_TEST
    ) {
      if (
        comboGroupLineItem?.ordered_test.combo_group_options?.length !==
        comboGroupLineItem?.ordered_test.lab_test.combo_num_selections
      ) {
        error = true
        errorText = `You must select ${comboGroupLineItem?.ordered_test.lab_test.combo_num_selections} options to order this test`
        warningOnly = false
      }
    }

    return { error, errorText, warningOnly, warningText }
  }, [lineItems, checkoutDraftValues])

  const { minAge, minAgeWarning } = getMinimumAgeAndWarning(
    order,
    lineItemWithMaxAgeRestriction?.ordered_test?.lab_test
  )

  if (loading) {
    return (
      <Grow
        in={visible}
        style={{ transformOrigin: "50% 0 0" }}
        timeout={REMOVE_TIMEOUT_DURATION}
      >
        <Skeleton
          className={classes.panelCardPricePlaceholder}
          variant="rect"
          animation="wave"
          height={150}
          width="100%"
        />
      </Grow>
    )
  }

  return (
    <Grow
      in={visible}
      style={{ transformOrigin: "50% 0 0" }}
      timeout={REMOVE_TIMEOUT_DURATION}
    >
      <Paper
        style={
          error
            ? {
                border: `1px solid ${
                  warningOnly ? colors.blueGray[400] : cancelRed
                }`,
              }
            : undefined
        }
        className="bg-gray-100 rounded-lg flex flex-col mb-4 border-b-4 border-gray-400"
        elevation={0}
      >
        {error && (
          <LabTestError
            errorText={
              warningOnly ? (
                warningText
              ) : (
                <>
                  {errorText}
                  <br></br> {warningText}
                </>
              )
            }
            warningOnly={warningOnly}
          />
        )}
        <div className={classes.panelCardWrapper}>
          <div className={classes.panelCardTitleWrapper}>
            <div className={classes.panelCardTitle}>
              <Typography className={classes.panelTitleText}>
                {panelName}
              </Typography>
              {phlebotomyRequired && (
                <PhlebotomyRequired labCompany={labCompany} />
              )}
            </div>
            <PanelCardPrice
              cost={isComboGroup ? totalComboGroupOptionsCost : totalMsrpCost}
              discountedCost={totalRupaCost}
              isPriced={isPriced}
              insuranceToggledOn={insuranceToggledOn}
            />
          </div>
          <div className="flex mb-4">
            <div className="flex-grow">
              <Typography className="text-base15 text-gray-700">
                {labCompany?.name}
              </Typography>
              {(Boolean(minAge) || lineItemWithShippingDelay) && (
                <div className={classes.orderTestWarningsWrapper}>
                  {Boolean(minAge) && (
                    <OrderTestWarning
                      type={OrderTestWarningTypes.AGE_RESTRICTION}
                      title={`Age ${minAge}+`}
                      warning={minAgeWarning}
                    />
                  )}

                  {lineItemWithShippingDelay && (
                    <OrderTestWarning
                      type={OrderTestWarningTypes.SHIPPING_DELAY}
                      title="Shipping Delay"
                      warning={`${lineItemWithShippingDelay.ordered_test.lab_test.active_shipping_alert?.title}. ${lineItemWithShippingDelay.ordered_test.lab_test.active_shipping_alert?.subtitle}`}
                    />
                  )}
                </div>
              )}
            </div>
            {(status === ORDER_STATUS.DRAFT || edit) && (
              <div
                onClick={handleRemovePanel}
                className="cursor-pointer font-semibold text-base15 text-right"
                style={{ color: "#0073ca" }}
              >
                Remove
              </div>
            )}
          </div>
          {isComboGroup && !error && (
            <BodyText size="sm" style={{ color: rupaTeal, marginBottom: 8 }}>
              <BasketIcon /> Saving{" "}
              <span style={{ fontWeight: "bold" }}>{comboSavingsTotal}</span>{" "}
              with combo
            </BodyText>
          )}
          {isComboGroup &&
            comboGroupOptions &&
            comboGroupOptions.map((option) => (
              <PanelItem
                title={option.name ? option.name : option.lab_test.name}
                showPricing={false}
              />
            ))}
          {isComboGroup && Boolean(filteredLineItems.length) && (
            <BodyText
              size="xs"
              color="textPrimary"
              style={{ marginTop: 15, marginBottom: 4 }}
            >
              Add-ons
            </BodyText>
          )}
          {filteredLineItems.map((lineItem) => {
            if (lineItem.type === ORDER_LINE_ITEM_TYPE.LAB_TEST) {
              const labCompanyKey =
                lineItem.ordered_test.lab_test.lab_company.key
              const isInQuest = QUEST_COMPANY_KEYS.includes(labCompanyKey)

              return (
                <div key={lineItem.ordered_test.lab_test.id} className="mt-1">
                  <PanelItem
                    title={lineItem.title}
                    cost={lineItem.cost}
                    discountedCost={lineItem.discounted_cost}
                    onRemove={() => onRemoveTest(lineItem.ordered_test)}
                    showPricing={!insuranceToggledOn && !isInQuest}
                  />
                </div>
              )
            }

            return (
              <div key={lineItem.id} className="mt-4">
                <PanelItem
                  title={lineItem.title}
                  cost={lineItem.cost}
                  discountedCost={lineItem.discounted_cost}
                  showPricing={
                    lineItem.type !== ORDER_LINE_ITEM_TYPE.QUEST_BLOOD_DRAW_FEE
                  }
                />
              </div>
            )
          })}
          {isInsuranceUpdatesForHealthGorillaEnabled && (
            <InsuranceToggle
              order={order}
              companyKey={labCompany.key}
              insuranceToggledOn={insuranceToggledOn}
              setIsToggling={(val) => setLoading(val)}
              setInsuranceToggledOn={setInsuranceToggledOn}
              isLoadingLabCompanyAccounts={isLoadingLabCompanyAccounts}
              companiesWithConfirmedAccounts={companiesWithConfirmedAccounts}
            />
          )}
          {isComboGroup ? (
            <Button
              variant="outlined"
              size="small"
              color="primary"
              className="mt-1 mb-1 bg-white"
              fullWidth
              startIcon={<ComboGroupsIcon />}
              onClick={() => {
                modal.show({
                  updating: true,
                  initialTargetId: comboGroupTargetLabTest!.id,
                  comboGroupId: comboGroupId!,
                  initialOptionIds: comboGroupOptionIds!,
                  initialAddOnIds: addOnIds,
                  onAddToCart: handleUpdateComboGroup,
                })
                trackEventWithProperties(
                  ComboGroupEvents.COMBO_GROUP_EDIT_COMBO_CLICKED,
                  {
                    comboGroupLabTestId: comboGroupTargetLabTest!.id,
                    practitionerId: practitioner.id,
                    comboGroupId,
                    orderId: order.id,
                    orderedTestId: parentOrderedTest?.id,
                  }
                )
              }}
            >
              Edit Combo Tests
            </Button>
          ) : (
            hasMoreAddons &&
            parentLabTest &&
            openModalWithLabTest && (
              <Button
                variant="outlined"
                size="small"
                color="primary"
                className="mt-1 mb-1 bg-white"
                fullWidth
                startIcon={<AddIcon />}
                onClick={() => openModalWithLabTest(parentLabTest)}
              >
                Add-on Tests Available
              </Button>
            )
          )}

          {showDeliveryMethod && orderedTestLineItems.length ? (
            <DeliveryMethod
              order={order}
              disabled={status !== ORDER_STATUS.DRAFT || edit}
              orderedTests={orderedTests}
              onInstantRequisitionChange={onInstantRequisitionChange}
              orderSigningPractitioner={orderSigningPractitioner}
              supportsInstantRequisition={allTestsSupportInstantReq}
              requiresStockedKitForOrderedTest={hasIOKRequiringActivation}
              hasInStockInOfficeKits={hasInStockInOfficeKits}
            />
          ) : null}

          <FastingCheckbox order={order} lineItems={orderedTestLineItems} />
        </div>

        {hasIOKRequiringActivation &&
          hasInStockInOfficeKits &&
          instantRequisitionEnabled && (
            <IOKActivationDropdown
              inStockInOfficeKits={inStockInOfficeKits}
              orderedTestIds={
                orderedTests.map((test) => test?.id).filter(Boolean) as string[]
              }
              labCompanyKey={labCompany.key}
              setKitActivationIdSelectedFor={setKitActivationIdSelectedFor}
            />
          )}
      </Paper>
    </Grow>
  )
}

const PanelCardPrice = ({
  cost,
  discountedCost,
  isPriced,
  insuranceToggledOn,
}) => {
  const classes = useStyles()
  if (insuranceToggledOn) {
    return (
      <Typography className={classes.priceVaryText}>
        Price may vary&nbsp;
        <InfoTextTooltip iconFill={colors.blueGray[500]}>
          <span className={classes.priceMayVaryTooltipText}>
            The patient's insurance plan will determine the insurance coverage,
            eligibility, and any out-of-pocket payments for Labcorp tests.
          </span>
        </InfoTextTooltip>
      </Typography>
    )
  }
  return isPriced ? (
    <Typography className="font-bold text-lg" align="right">
      {discountedCost !== cost ? (
        <>
          <span className="text-gray-500 line-through">{cost}</span>
          <span className="ml-2">{discountedCost}</span>
        </>
      ) : (
        <span className="ml-2">{discountedCost}</span>
      )}
    </Typography>
  ) : (
    <Skeleton
      className={classes.panelCardPricePlaceholder}
      variant="rect"
      animation="wave"
    />
  )
}
