import clsx from "clsx"
import { uniq } from "lodash"

import { Chip } from "@material-ui/core"

import { labTestColors } from "app/constants"
import { colors } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"

const colorGroups = uniq(Object.values(labTestColors))

const textColorForColorGroup = (colorGroup) => {
  const colorsMap = {
    pink: colors.rose[700],
    yellow: colors.yellow[700],
    tangerine: colors.orange[700],
    purple: colors.violet[600],
    sky: colors.lightBlue[700],
    cyan: colors.cyan[900],
  }
  return colorsMap[colorGroup]
}

const backgroundColorForColorGroup = (colorGroup) => {
  const colorsMap = {
    pink: colors.rose[100],
    yellow: colors.yellow[100],
    tangerine: colors.orange[100],
    purple: colors.violet[100],
    sky: colors.lightBlue[50],
    cyan: colors.lightBlue[50],
  }
  return colorsMap[colorGroup]
}

const colorGroupForName = (name) => {
  return name && labTestColors[name.toLowerCase()]
    ? labTestColors[name.toLowerCase()]
    : "cyan"
}

const useStyles = makeAppStyles(
  colorGroups.reduce(
    (classes, colorGroup) => ({
      ...classes,
      [`root-${colorGroup}`]: {
        backgroundColor: backgroundColorForColorGroup(colorGroup),
      },
      [`label-${colorGroup}`]: {
        color: textColorForColorGroup(colorGroup),
      },
    }),
    {
      label: {
        fontSize: 12,
        fontWeight: 700,
        lineHeight: 1.3,
        overflow: "hidden",
        textOverflow: "ellipsis",
        textTransform: "uppercase",
        whiteSpace: "nowrap",
      },
    }
  )
)

export default function SampleTypeChip({ name }: { name: string }) {
  const colorGroup = colorGroupForName(name)
  const classes = useStyles()
  return (
    <Chip
      classes={{
        root: classes[`root-${colorGroup}`],
        label: clsx(classes.label, classes[`label-${colorGroup}`]),
      }}
      label={name}
      size="small"
    />
  )
}
