import * as React from "react"

import { ReactComponent as AlertTriangleIcon } from "app/assets/images/alert-triangle.svg"
import { colors } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"

const useStyles = makeAppStyles((theme) => ({
  root: {
    display: "flex",
    flexFlow: "row nowrap",
    padding: theme.spacing(1.25, 1.75),
    gap: theme.spacing(1.25),
    fontSize: 14,
    fontWeight: 400,
    lineHeight: 1.35,
    color: colors.amber[900],
    backgroundColor: colors.amber[100],
    borderRadius: 6,
    "& > svg": {
      flex: "0 0 14px",
      width: 14,
      height: 14,
      fill: colors.amber[600],
    },
    "& > span": {
      flex: "1 1 auto",
    },
  },
}))

export interface FoodPlanErrorMessageProps {
  children: React.ReactNode
}

export default function FoodPlanErrorMessage({
  children,
}: FoodPlanErrorMessageProps) {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <AlertTriangleIcon viewBox="0 0 19 18" />
      <span>{children}</span>
    </div>
  )
}
