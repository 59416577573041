import * as React from "react"

import clsx from "clsx"

import { COLUMN_PADDING } from "app/main/comparison/constants"
import { colors, rupaTeal } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"

const useStyles = makeAppStyles((theme) => ({
  header: {
    position: "relative",
    height: 30,
    padding: `0 ${COLUMN_PADDING}px`,
    display: "flex",
    alignItems: "center",
    borderRadius: 6,
  },
  headerText: {
    position: "sticky",
    left: COLUMN_PADDING,
    display: "flex",
    alignItems: "center",
    gap: 4,
    fontSize: 13,
    fontWeight: theme.typography.fontWeightMedium,
    lineHeight: 1.3,
    textTransform: "uppercase",
    "& svg": {
      height: 13,
      width: 13,
      fill: "currentColor",
    },
  },
  headerSlate: {
    color: colors.blueGray[500],
    backgroundColor: colors.blueGray[100],
  },
  headerRupaTeal: {
    color: "white",
    backgroundColor: rupaTeal,
  },
}))

export interface ComparisonRowHeaderProps {
  children: React.ReactNode
  className?: string
  color?: "slate" | "teal"
  icon?: React.ReactNode
}

export default function ComparisonRowHeader({
  children,
  className,
  color = "slate",
  icon,
}: ComparisonRowHeaderProps) {
  const classes = useStyles()
  return (
    <div
      className={clsx(classes.header, className, {
        [classes.headerSlate]: color === "slate",
        [classes.headerRupaTeal]: color === "teal",
      })}
    >
      <div className={classes.headerText}>
        {icon}
        {children}
      </div>
    </div>
  )
}
