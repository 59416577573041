import { useEffect } from "react"

import { trackEventWithProperties } from "app/services/segment.typed"
import { Practitioner } from "app/types"

import InventoryTable from "./InventoryTable"
import { IOKInventoryEvents } from "./inventory-types"

interface Props {
  practitioner: Practitioner
}
const InventoryTab: React.FC<Props> = ({ practitioner }) => {
  useEffect(() => {
    trackEventWithProperties(IOKInventoryEvents.VISIT, {
      practitioner_id: practitioner.id,
    })
  }, [])

  return <InventoryTable />
}

export default InventoryTab
