import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"

import { Divider, Grid, Theme, useMediaQuery } from "@material-ui/core"
import { Skeleton } from "@material-ui/lab"

import { UserPaths } from "app/Routes"
import { ReactComponent as QuestionCircleIcon } from "app/assets/icons/rupa-blood-dashboards/question-circle-outlined.svg"
import { ReactComponent as VideoPlayIcon } from "app/assets/icons/rupa-blood-dashboards/video-play-on-screen.svg"
import CogIconGray from "app/assets/images/cog-icon-gray.svg"
import PageToolbar from "app/components/PageToolbar"
import BodyText from "app/components/design-system/BodyText"
import DesignSystemButton from "app/components/design-system/Button"
import useConfirmationModal from "app/components/modals/generic/hooks/use-confirmation-modal"
import useVideoModal from "app/hooks/use-video-modal"
import useAppSelector from "app/hooks/useAppSelector"
import { updatePractitioner } from "app/store/actions"
import { colors } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"

import SideNav from "../SideNav"
import ResultsSummaryLogoSettings from "../components/ResultsSummaryLogoSettings"
import RupaBloodDashboardsBanner from "./RupaBloodDashboardsBanner"
import RupaBloodDashboardsCustomizeBiomarkerDescriptions from "./RupaBloodDashboardsCustomizeBiomarkerDescriptions"
import RupaBloodDashboardsEnableSwitch from "./RupaBloodDashboardsEnableSwitch"
import RupaBloodDashboardsGreyedOutComponents from "./RupaBloodDashboardsGreyedOutComponents"
import RupaBloodDashboardsOptimalRanges from "./RupaBloodDashboardsOptimalRanges"
import { ClinicSettings } from "./constants"

const useStyles = makeAppStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    padding: 24,
    gap: 24,
    width: "100%",
    minHeight: "100vh",
  },
  loadingContainer: {
    display: "flex",
    flexDirection: "column",
    padding: 24,
    gap: 26,
    width: "100%",
  },
  buttonsContainer: {
    display: "flex",
    gap: 20,
    alignItems: "center",
  },
  verticalDivider: {
    width: 1,
    height: 19,
    background: colors.blueGray[300],
  },
  confirmationContent: {
    display: "flex",
    flexDirection: "column",
    gap: 14,
  },
})

const RupaBloodDashboards = () => {
  const classes = useStyles()

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"))

  const dispatch = useDispatch()

  const [isRupaBloodDashboardsEnabled, setIsRupaBloodDashboardsEnabled] =
    useState(false)
  const [isOptimalRangesEnabled, setIsOptimalRangesEnabled] = useState(false)

  const practitioner = useAppSelector(({ practitioner }) => practitioner)
  const clinic = practitioner?.clinic

  const tutorialVideoModal = useVideoModal()
  const confirmationModal = useConfirmationModal()

  useEffect(() => {
    if (
      clinic?.clinic_settings.includes(ClinicSettings.USE_RUPA_BLOOD_REPORTS)
    ) {
      setIsRupaBloodDashboardsEnabled(true)
    }
    if (
      clinic?.clinic_settings.includes(
        ClinicSettings.USE_OPTIMAL_RANGES_FOR_BLOOD_REPORTS
      )
    ) {
      setIsOptimalRangesEnabled(true)
    }
  }, [clinic])

  const handleToggleClinicSetting = async (settings: ClinicSettings[]) => {
    if (!clinic) return

    let settingsToPatch = clinic.clinic_settings

    settings.forEach((setting) => {
      if (settingsToPatch.includes(setting)) {
        settingsToPatch = settingsToPatch.filter((s) => s !== setting)
      } else {
        settingsToPatch.push(setting)
      }
    })

    await dispatch(
      updatePractitioner({
        clinic: {
          clinic_settings: settingsToPatch,
        },
      })
    )

    if (settingsToPatch.includes(ClinicSettings.USE_RUPA_BLOOD_REPORTS)) {
      setIsRupaBloodDashboardsEnabled(true)
    } else {
      setIsRupaBloodDashboardsEnabled(false)
    }

    if (
      settingsToPatch.includes(
        ClinicSettings.USE_OPTIMAL_RANGES_FOR_BLOOD_REPORTS
      )
    ) {
      setIsOptimalRangesEnabled(true)
    } else {
      setIsOptimalRangesEnabled(false)
    }
  }

  const handleToggleBloodDashboardsEnabled = async () => {
    if (isRupaBloodDashboardsEnabled) {
      confirmationModal.show({
        title: "Turn Off Blood Lab Dashboards",
        message: <TurnOffBloodDashboardsConfirmationContent />,
        backButtonTitle: "Keep It On",
        confirmButtonTitle: "Turn Off",
        confirmButtonColor: "destructiveSecondary",
        onClose: () => confirmationModal.hide(),
        handleConfirm: async () => {
          await handleToggleClinicSetting([
            ClinicSettings.USE_RUPA_BLOOD_REPORTS,
            ClinicSettings.USE_OPTIMAL_RANGES_FOR_BLOOD_REPORTS,
          ])
          confirmationModal.hide()
        },
        loading: false,
        maxWidth: "xs",
        fullWidthButtons: true,
      })
    } else {
      await handleToggleClinicSetting([
        ClinicSettings.USE_RUPA_BLOOD_REPORTS,
        ClinicSettings.USE_OPTIMAL_RANGES_FOR_BLOOD_REPORTS,
      ])
    }
  }

  return (
    <Grid container>
      {!isMobile && (
        <Grid item xs={12} md={3}>
          <SideNav />
        </Grid>
      )}
      <Grid item xs={12} md={9}>
        {!isMobile ? (
          <PageToolbar
            title={"Results Visualization"}
            greyBackground={true}
            fullBorder={true}
          >
            <div className={classes.buttonsContainer}>
              <DesignSystemButton
                color="text"
                onClick={() =>
                  window.open(
                    "https://www.rupahealth.com/blood-lab-dashboards",
                    "_blank"
                  )
                }
                startIcon={
                  <QuestionCircleIcon
                    width={15}
                    height={15}
                    viewBox="0 0 13 13"
                  />
                }
                loading={false}
              >
                Learn More
              </DesignSystemButton>
              <div className={classes.verticalDivider}></div>
              <DesignSystemButton
                color="text"
                onClick={() =>
                  tutorialVideoModal.show({
                    src: "https://player.vimeo.com/video/864582287?h=28a5c69f42",
                    title: "An Overview of Rupa Blood Dashboards",
                  })
                }
                startIcon={<VideoPlayIcon />}
                loading={false}
              >
                Watch Tutorial
              </DesignSystemButton>
            </div>
          </PageToolbar>
        ) : (
          <PageToolbar
            breadcrumbs={[
              {
                to: UserPaths.SETTINGS,
                icon: CogIconGray,
                label: "Settings",
              },
              {
                label: "Results Visualization",
              },
            ]}
            title="Results Visualization"
            greyBackground={true}
            fullBorder={true}
          />
        )}
        {clinic ? (
          <Grid container justify="space-between" alignItems="center">
            <div className={classes.container}>
              <RupaBloodDashboardsBanner />

              <RupaBloodDashboardsEnableSwitch
                toggled={isRupaBloodDashboardsEnabled}
                onToggle={handleToggleBloodDashboardsEnabled}
              />

              {isRupaBloodDashboardsEnabled ? (
                <>
                  <div className={classes.divider}>
                    <Divider />
                  </div>

                  <ResultsSummaryLogoSettings clinic={clinic} />

                  <div className={classes.divider}>
                    <Divider />
                  </div>

                  <RupaBloodDashboardsCustomizeBiomarkerDescriptions />

                  <div className={classes.divider}>
                    <Divider />
                  </div>

                  <RupaBloodDashboardsOptimalRanges
                    toggled={isOptimalRangesEnabled}
                    onToggle={() =>
                      handleToggleClinicSetting([
                        ClinicSettings.USE_OPTIMAL_RANGES_FOR_BLOOD_REPORTS,
                      ])
                    }
                  />
                </>
              ) : (
                <RupaBloodDashboardsGreyedOutComponents />
              )}
            </div>
          </Grid>
        ) : (
          <div className={classes.loadingContainer}>
            <Skeleton height={20} width={600} variant="rect" />
            <Skeleton height={20} width={500} variant="rect" />
            <Skeleton height={20} width={550} variant="rect" />
          </div>
        )}
      </Grid>
    </Grid>
  )
}

const TurnOffBloodDashboardsConfirmationContent = () => {
  const classes = useStyles()

  return (
    <div className={classes.confirmationContent}>
      <BodyText>
        We want to make Blood Dashboards something you'll love! So we'd
        appreciate to hear why you're turning off Blood Lab Dashboards for your
        clients.
      </BodyText>
      <DesignSystemButton
        color="secondary"
        fullWidth
        onClick={() => window.Intercom("startSurvey", 36295770)}
      >
        Share Feedback
      </DesignSystemButton>
      <BodyText>
        P.S. You can always turn off Optimal Ranges, but keep Blood Dashboards
        on!
      </BodyText>
    </div>
  )
}

export default RupaBloodDashboards
