import * as React from "react"
import { ReactElement } from "react"

import clsx from "clsx"
import { NavLink } from "react-router-dom"

import {
  CircularProgress,
  Link,
  Theme,
  makeStyles,
  useMediaQuery,
} from "@material-ui/core"

import { UserPaths } from "app/Routes"
import { ReactComponent as ArrowTopRight } from "app/assets/icons/arrow-top-right.svg"
import theme, {
  colors,
  navy,
  red,
  settingsNavBarLabelWidth,
  settingsNavBarLabelWidthTablet,
  userNavBarWidth,
} from "app/theme"

import BodyText from "../design-system/BodyText"

interface BaseProps {
  icon?: string | React.ReactNode
  activeIcon?: string | React.ReactNode
  customIcon?: ReactElement
  iconInContainer?: boolean
  children: string | React.ReactNode
  unreadCount?: number
  animationOrder?: number
  isInsideSettings?: boolean
  loading?: boolean
  external?: boolean
  isFullWidth?: boolean
}

interface PropsWithTo extends BaseProps {
  to: string
  href?: never
  onClick?: never
  target?: never
  activeRequiresExact?: boolean
}

interface PropsWithHref extends BaseProps {
  to?: never
  href: string
  onClick?: never
  target?: string
  activeRequiresExact?: never
}

interface PropsWithOnClick extends BaseProps {
  to?: never
  href?: never
  onClick: () => void
  target?: never
  activeRequiresExact?: never
}

type Props = PropsWithTo | PropsWithHref | PropsWithOnClick

const activeIconStyle = {
  "& .icon": {
    opacity: 0,
  },
  "& .icon--active": {
    opacity: 1,
  },
}

const useStyles = makeStyles<
  typeof theme,
  {
    animationOrder: number
    isInsideSettings: boolean
    isTablet: boolean
    isFullWidth: boolean
  }
>({
  containerStyle: {
    display: "block",
    border: "1px solid rgba(255, 255, 255, 0)",
    borderRadius: (props) => (props.isInsideSettings ? 9 : 6),
    cursor: "pointer",
    height: 46,
    padding: 12,
    transition: "0.16s background, 0.16s border",

    "--animation-order": (props) => props.animationOrder,

    "&:hover": {
      background: (props) =>
        props.isInsideSettings
          ? colors.blueGray[100]
          : "rgba(255, 255, 255, 0.1)",
      border: "1px solid rgba(255, 255, 255, 0.03)",
      transition: "0.16s border",

      "& .navbar-item__badge": {
        border: `3px solid ${colors.blueGray[600]}`,
      },

      "& .navbar-item__external-icon": {
        opacity: 1,
      },
    },
    "&.active": {
      background: (props) =>
        props.isInsideSettings
          ? colors.blueGray[100]
          : "rgba(255, 255, 255, 0.1)",
      border: "1px solid rgba(255, 255, 255, 0.03)",
      transition: "0.16s border",

      "& .navbar-item__badge": {
        border: `3px solid ${colors.blueGray[600]}`,
      },
      ...activeIconStyle,
    },

    "& .icon--active": {
      opacity: 0,
    },
  },
  innerContainerStyle: {
    // This is the width of the expanded sidebar - all padding. This makes the inner container the size of
    // max content size when expanded/hovered, so that content doesn't reflow when the navbar container changes.
    width: (props) =>
      props.isInsideSettings
        ? props.isTablet
          ? settingsNavBarLabelWidthTablet
          : props.isFullWidth
          ? "100%"
          : settingsNavBarLabelWidth
        : props.isFullWidth
        ? "100%"
        : userNavBarWidth - 54,
    height: "100%",
    display: "flex",
    alignItems: "center",
  },
  iconContainerStyle: {
    position: "relative",
    width: 16,
    height: 16,
  },
  iconStyle: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  badgeStyle: {
    position: "absolute",
    background: red,
    top: -4,
    right: -4,
    borderRadius: 7,
    width: 14,
    height: 14,
    border: `3px solid ${navy}`,
  },
  externalIconStyle: {
    opacity: 0,
    transition: "opacity 0.16s",

    "& svg path": {
      fill: "white",
    },
  },
  labelContainerStyle: {
    alignItems: "center",
    flex: 1,
    transition: "opacity 0.24s",
  },
  unreadCountStyle: {
    color: "white",
    padding: "2px 12px",
    background: "rgba(255, 255, 255, 0.1)",
    borderRadius: 50,
  },
})

/*
 * Note: All the styling that handles responsiveness and expanding the collapsed sidebar
 * is contained within the parent `UserNavBar`.
 */

const NavBarItem = ({
  icon,
  activeIcon,
  customIcon,
  children,
  to,
  href,
  unreadCount,
  animationOrder = 0,
  onClick,
  loading,
  external,
  isInsideSettings = false,
  isFullWidth = false,
  target = "_blank",
  activeRequiresExact,
  iconInContainer = false,
}: Props) => {
  const isTablet = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"))

  const {
    iconContainerStyle,
    iconStyle,
    unreadCountStyle,

    labelContainerStyle,
    badgeStyle,
    containerStyle,
    innerContainerStyle,
    externalIconStyle,
  } = useStyles({ animationOrder, isInsideSettings, isTablet, isFullWidth })

  const mergedContainerStyles = clsx(containerStyle, "navbar-item")

  let rightIcon: React.ReactNode = null

  if (loading) {
    rightIcon = (
      <CircularProgress
        aria-label="Loading"
        style={{ color: "white" }}
        thickness={6}
        size={12}
      />
    )
  } else if ((href || external) && target === "_blank") {
    rightIcon = (
      <div className={clsx(externalIconStyle, "navbar-item__external-icon")}>
        <ArrowTopRight />
      </div>
    )
  }

  const iconElement =
    activeIcon && icon ? (
      <>
        {typeof icon === "string" ? (
          <img className={clsx("icon", iconStyle)} src={icon} alt="" />
        ) : (
          <span className={clsx("icon", iconStyle)}>{icon}</span>
        )}
        {typeof activeIcon === "string" ? (
          <img
            className={clsx("icon--active", iconStyle)}
            src={activeIcon}
            alt=""
          />
        ) : (
          <span className={clsx("icon--active", iconStyle)}>{activeIcon}</span>
        )}
      </>
    ) : null

  const content = (
    <div className={innerContainerStyle}>
      <div className={iconContainerStyle}>
        {iconInContainer ? (
          <div className="w-8 h-8 items-center justify-center flex bg-white/20 rounded-md -ml-1.5 -mt-2">
            <div className="relative w-4 h-4">{iconElement}</div>
          </div>
        ) : (
          iconElement
        )}
        {Boolean(customIcon) && customIcon}
        {Boolean(unreadCount) && (
          <div className={clsx(badgeStyle, "navbar-item__badge")} />
        )}
      </div>
      <div
        className={clsx(
          labelContainerStyle,
          "navbar-item__label",
          iconInContainer && "ml-1.5"
        )}
      >
        {isInsideSettings ? (
          <BodyText className="font-semibold text-lg17 ml-4 w-full md:w-auto">
            {children}
          </BodyText>
        ) : (
          <div className="font-semibold text-white ml-4">{children}</div>
        )}
      </div>
      {Boolean(unreadCount) && (
        <BodyText className={clsx(unreadCountStyle, "navbar-item__unread")}>
          {unreadCount}
        </BodyText>
      )}

      {Boolean(rightIcon) && (
        <div className={iconContainerStyle}>{rightIcon}</div>
      )}
    </div>
  )

  if (to) {
    return (
      <NavLink
        className={mergedContainerStyles}
        to={to}
        exact
        isActive={(match, location) => {
          // Exact match
          if (match) {
            return true
          }

          // isSubpageActive is true when start of paths match. For example for the settings nav:
          // location = "/settings/bundles", to = "/settings/profile" (toplevel settings nav has this as its 'to')
          // Because the first item in paths match "/settings", the subpage link is active
          let isSubpageActive = location.pathname.startsWith(
            "/" + to.split("/")[1]
          )

          // Show top level nav item as active when location inside any of the subpages
          if (
            to !== UserPaths.ROOT &&
            !isInsideSettings &&
            isSubpageActive &&
            !activeRequiresExact
          ) {
            return true
          } else {
            return false
          }
        }}
      >
        {content}
      </NavLink>
    )
  }

  if (onClick) {
    return (
      <Link className={mergedContainerStyles} onClick={onClick}>
        {content}
      </Link>
    )
  }

  // We want the referrer to be passed to Mixpanel for analytics purposes.
  /* eslint-disable react/jsx-no-target-blank */
  return (
    <a
      className={mergedContainerStyles}
      href={href}
      target={target}
      rel="noopener"
    >
      {content}
    </a>
  )
  /* eslint-enable react/jsx-no-target-blank */
}

export default NavBarItem
