import { useState } from "react"

import useEventCallback from "app/hooks/use-event-callback"
import useHandleApiError from "app/hooks/use-handle-api-error"
import useMutateResource from "app/swr/hooks/use-mutate-resource"
import resourceRequest from "app/swr/utils/resource-request"
import setIn from "app/utils/set-in"
import {
  ResultsInterpretation,
  ResultsInterpretationBiomarker,
} from "types/results-interpretation"

export default function useDeleteInterpretationBiomarker(
  biomarker: ResultsInterpretationBiomarker
) {
  const [isDeleting, setIsDeleting] = useState(false)
  const handleApiError = useHandleApiError()
  const mutateResource = useMutateResource()

  const deleteBiomarker = useEventCallback(async () => {
    const sendDeleteRequest = async () => {
      setIsDeleting(true)

      try {
        await resourceRequest({
          method: "delete",
          url: `/results_interpretation_biomarkers/${biomarker.id}/`,
        })

        return undefined
      } catch (error) {
        handleApiError(error)

        // re-throw
        throw error
      } finally {
        setIsDeleting(false)
      }
    }

    const updateCache = (currentData?: ResultsInterpretation) => {
      if (!currentData) {
        return undefined as any
      }

      const biomarkerRelationship = currentData.relationships.biomarkers

      const nextData = setIn(currentData, "relationships.biomarkers", {
        data: biomarkerRelationship.data.filter(
          (identifier) => biomarker.id !== identifier.id
        ),
        meta: {
          count: biomarkerRelationship.meta.count - 1,
        },
      })

      return nextData
    }

    await mutateResource<ResultsInterpretation>(
      biomarker.relationships.interpretation.data,
      sendDeleteRequest,
      {
        populateCache(_, currentData) {
          return updateCache(currentData)
        },
        optimisticData: updateCache,
        rollbackOnError: true,
        revalidate: true,
      }
    )
  })

  return {
    deleteBiomarker,
    isDeleting,
  }
}
