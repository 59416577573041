import { ReactComponent as CheatSheetIcon } from "app/assets/icons/cheat-sheet.svg"
import { ReactComponent as UpRightArrowIcon } from "app/assets/icons/up-right-arrow.svg"
import {
  ComparisonRow,
  ComparisonColumnProps,
  EmptyComparisonValue,
} from "app/main/comparison/components/ComparisonLabTests/components"
import useAnyComparedLabTestHasProperty from "app/main/comparison/hooks/use-any-compared-lab-test-has-property"
import { primaryColor } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"

const useCollectionInstructionsColumnStyles = makeAppStyles((theme) => ({
  collectionInstructionsLink: {
    color: primaryColor,
    textDecoration: "none",
    fontWeight: theme.typography.fontWeightMedium,
    "& svg": {
      width: 12,
      height: 12,
      fill: "currentColor",
      verticalAlign: "middle",
    },
  },
}))

const CollectionInstructionsColumn = ({ labTest }: ComparisonColumnProps) => {
  const classes = useCollectionInstructionsColumnStyles()
  return (
    <>
      {labTest?.cheatsheet_url ? (
        <a
          className={classes.collectionInstructionsLink}
          href={labTest.cheatsheet_url}
          target="__blank"
        >
          View Instructions <UpRightArrowIcon viewBox="0 0 12 12" />
        </a>
      ) : (
        <EmptyComparisonValue />
      )}
    </>
  )
}

export default function ComparisonCollectionInstructionsRow() {
  const hasCheatSheetUrl = useAnyComparedLabTestHasProperty("cheatsheet_url")
  if (!hasCheatSheetUrl) {
    return null
  }
  return (
    <ComparisonRow
      Column={CollectionInstructionsColumn}
      headerIcon={<CheatSheetIcon viewBox="0 0 13 16" />}
      headerLabel="Collection Instructions"
    />
  )
}
