import { useMemo } from "react"

import TableRenderer from "./TableRenderer"
import {
  biomarkerNameColumn,
  biomarkerDescriptionStateColumn,
  biomarkerLowDescriptionStateColumn,
  biomarkerHighDescriptionStateColumn,
} from "./columns"
import useBiomarkerCustomDescriptions from "./hooks/use-biomarker-custom-descriptions"
import useTable from "./hooks/use-table"

export default function BiomarkerCustomDescriptionsTable() {
  const { customBiomarkerDescriptions, isLoading } =
    useBiomarkerCustomDescriptions()

  const columns = useMemo(() => {
    return [
      biomarkerNameColumn,
      biomarkerDescriptionStateColumn,
      biomarkerLowDescriptionStateColumn,
      biomarkerHighDescriptionStateColumn,
    ]
  }, [])

  const table = useTable({
    columns,
    data: customBiomarkerDescriptions,
    isLoading: isLoading,
    isValidating: false,
  })

  return <TableRenderer table={table} />
}
