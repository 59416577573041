/**
 * Returns the path to the food plans of a patient.
 *
 * @param patientId the ID of the patient
 * @param foodPlanId the ID of the food plan
 * @returns the path to the food plan
 */
export function getFoodPlanPath(patientId: string, foodPlanId: string) {
  return `/patient/${patientId}/food-plans/${foodPlanId}`
}
