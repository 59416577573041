import { isUserGuest } from "./auth/util"
import { AuthUser, Role } from "./types"

export function checkUserHasPermission(user: AuthUser, permittedRoles: Role[]) {
  return permittedRoles.some((role) => {
    switch (role) {
      case Role.STAFF:
        return user?.is_staff
      case Role.PROVIDER:
        return user?.is_provider
      case Role.PATIENT:
        return user?.has_patients
      case Role.GUEST:
        return isUserGuest(user)
      default:
        return false
    }
  })
}
