import { useMemo } from "react"

import { faCircleQuestion } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Tooltip, TooltipProvider, cn } from "@rupahealth/design"

import GenovaImage from "app/assets/images/iok-activation-example-genova.png"
import ZRTImage from "app/assets/images/iok-activation-example-zrt.png"
import { LAB_COMPANY_KEY } from "app/constants"

interface Props {
  labCompanyKey: string | undefined
  tooltipContentChildren?: React.ReactNode
  className?: string
}
const IOKActivationCodeInfoTooltip: React.FC<Props> = ({
  labCompanyKey,
  tooltipContentChildren,
  className,
}) => {
  const [tooltipContent, toolTipClassName] = useMemo(() => {
    switch (labCompanyKey) {
      case LAB_COMPANY_KEY.GENOVA:
        return [
          <img
            src={GenovaImage}
            className="border border-slate-200"
            alt="genovaActivationExample"
          />,
        ]
      case LAB_COMPANY_KEY.ZRT:
        return [
          <img
            src={ZRTImage}
            className="border border-slate-200"
            alt="ZRTActivationExample"
          />,
        ]
      case LAB_COMPANY_KEY.MOSAIC_DIAGNOSTICS:
      default:
        return [
          "Kit ID is on a sticker on the outside of the box.",
          "text-center w-[184px]",
        ]
    }
  }, [labCompanyKey])

  return (
    <TooltipProvider>
      <Tooltip
        arrow
        align="center"
        content={
          <>
            {tooltipContent}
            {tooltipContentChildren}
          </>
        }
        side="bottom"
        sideOffset={1}
        className={toolTipClassName}
      >
        <p
          className={cn(
            "inline-block text-base15 font-semibold text-primary cursor-help",
            className
          )}
        >
          <FontAwesomeIcon icon={faCircleQuestion} className="mr-1" />
          Where is this?
        </p>
      </Tooltip>
    </TooltipProvider>
  )
}

export default IOKActivationCodeInfoTooltip
