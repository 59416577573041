import { sum } from "lodash"

import Grow from "@material-ui/core/Grow"
import Paper from "@material-ui/core/Paper"
import Typography from "@material-ui/core/Typography"

import { PHYSICIAN_SERVICES_FEE_AMOUNT } from "app/constants"
import useFeatureFlag, { FeatureFlag } from "app/hooks/use-feature-flag"
import {
  StorefrontLabTestType,
  useResourceSelector,
  useResourceByIdSelector,
  StorefrontType,
} from "app/hooks/use-resource"
import { PanelItem } from "app/main/checkout/PanelItem"
import { formatDollars } from "app/utils"

import PriceBreakdown from "./PriceBreakdown"

type BundleCartCardProps = {
  storefront: StorefrontType | null
  storefrontProductId: string | undefined
  onRemove: (id: string) => unknown
}

export function BundleCartCard({
  storefront,
  storefrontProductId,
  onRemove,
}: BundleCartCardProps) {
  const [hidePhysServicesPricing] = useFeatureFlag(
    FeatureFlag.HidePhysicianServicesPricing
  )
  const [practitionerLicenseFlagEnabled] = useFeatureFlag(
    FeatureFlag.LabshopsUsePractitionerLicense
  )
  const usePractitionerLicense = Boolean(
    practitionerLicenseFlagEnabled &&
      storefront?.attributes.use_practitioner_license
  )

  const clinic = useResourceByIdSelector(
    "clinic",
    storefront?.relationships.clinic?.data.id
  )
  const product = useResourceByIdSelector(
    "storefront_product",
    storefrontProductId
  )
  const bundle = useResourceByIdSelector(
    "lab_test_bundle",
    product?.relationships.bundle.data?.id
  )
  const labTests = useResourceSelector((entities) =>
    bundle?.relationships.lab_tests.data.map(
      ({ id }) => entities?.lab_test?.[id]
    )
  ) as StorefrontLabTestType[]
  const customFee = useResourceByIdSelector(
    "custom_fee",
    product?.relationships.custom_fee?.data?.id
  )

  const totalLabTestRupaCost = sum(
    labTests.map((labTest) => parseFloat(labTest.attributes.rupa_price))
  )
  const totalRupaCost = formatDollars(
    sum(labTests.map((labTest) => parseFloat(labTest.attributes.rupa_price))) +
      (customFee?.attributes.price || 0) / 100 +
      (hidePhysServicesPricing || usePractitionerLicense
        ? 0
        : PHYSICIAN_SERVICES_FEE_AMOUNT)
  )

  return (
    <Grow in={true} style={{ transformOrigin: "50% 0 0" }} timeout={250}>
      <Paper
        className="bg-gray-100 rounded-lg px-6 py-3 flex flex-col mb-6 border-b-4 border-gray-400"
        elevation={0}
      >
        <div className="flex">
          <div className="flex flex-grow">
            <div>
              <Typography className="text-base15 font-bold text-gray-800 flex-grow">
                {bundle?.attributes.name}
              </Typography>
              <Typography className="text-base15 text-gray-700">
                Custom Bundle
              </Typography>
            </div>
          </div>
          <div className="mb-6">
            <Typography className="font-bold text-lg" align="right">
              <span className="ml-2">{totalRupaCost}</span>
            </Typography>
            <div
              onClick={() => onRemove(storefrontProductId || "")}
              className="cursor-pointer font-semibold text-base15 text-right"
              style={{ color: "#0073ca" }}
            >
              Remove
            </div>
          </div>
        </div>
        {labTests.map(({ id, attributes: test }) => (
          <div key={id} className="mt-1">
            <PanelItem
              title={test.name}
              cost={test.rupa_price}
              discountedCost={""}
            />
          </div>
        ))}
        <PriceBreakdown
          rupaPrice={Number(totalLabTestRupaCost)}
          customFeePrice={Number(customFee?.attributes.price || 0 / 100)}
          customFeeName={customFee?.attributes.name}
          clinicName={clinic?.attributes.name}
          hidePhysServicesPricing={
            hidePhysServicesPricing || usePractitionerLicense
          }
        />
      </Paper>
    </Grow>
  )
}
