import BodyText from "app/components/design-system/BodyText"
import useCachedCollection from "app/swr/hooks/use-cached-collection"
import { colors } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"
import { LabTest } from "types/lab-test"

import ComboGroupLabTestList from "./ComboGroupLabTestList"

const useStyles = makeAppStyles((theme) => ({
  container: {
    backgroundColor: "white",
    padding: 20,
  },
  headerContainer: {
    paddingBottom: 10,
    borderBottom: `1px solid ${colors.blueGray[200]}`,
  },
  warningContainer: {
    border: `1px solid ${colors.blueGray[300]}`,
    backgroundColor: `${colors.blueGray[50]}`,
    borderRadius: 7,
    padding: 10,
    margin: "10px 0px",
  },
}))

export interface ComboGroupModalBodyProps {
  targetLabTest?: LabTest
  selectedLabTestIds: string[]
  onAdd: (id: string) => void
  onRemove: (id: string) => void
  isLoading: boolean
}

export default function ComboGroupAddOns({
  targetLabTest,
  selectedLabTestIds,
  onAdd,
  onRemove,
  isLoading,
}: ComboGroupModalBodyProps) {
  const classes = useStyles()

  const addOns = useCachedCollection<LabTest>(
    targetLabTest?.relationships.add_ons.data
  )

  return (
    <div className={classes.container}>
      <div className={classes.headerContainer}>
        <BodyText size="sm" weight="semibold">
          Add-ons
        </BodyText>
        {targetLabTest?.attributes.combo_max_add_on_message && (
          <div className={classes.warningContainer}>
            <BodyText size="sm">
              {targetLabTest?.attributes.combo_max_add_on_message}
            </BodyText>
          </div>
        )}
      </div>
      <ComboGroupLabTestList
        labTests={addOns}
        selectedIds={selectedLabTestIds}
        onAdd={onAdd}
        onRemove={onRemove}
        isLoading={isLoading}
        maxAddOns={targetLabTest?.attributes.combo_max_add_on_selections}
      />
    </div>
  )
}
