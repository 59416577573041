import AddOnSection from "./AddOnSection"
import BiomarkersSection from "./BiomarkersSection"
import CompanySection from "./CompanySection"
import DetailsSection from "./DetailsSection"
import LabTestModal from "./LabTestModal"
import PatientCollectionInstructionsSection from "./PatientCollectionInstructionsSection"
import PhlebotomySection from "./PhlebotomySection"
import PriceSection from "./PriceSection"
import ProcessingTimeSection from "./ProcessingTimeSection"
import SampleReportSection from "./SampleReportSection"
import ShippingTimeSection from "./ShippingTimeSection"
import TitleSection from "./TitleSection"
import TypeSection from "./TypeSection"

export {
  AddOnSection,
  BiomarkersSection,
  CompanySection,
  DetailsSection,
  PatientCollectionInstructionsSection,
  PhlebotomySection,
  PriceSection,
  ProcessingTimeSection,
  SampleReportSection,
  TitleSection,
  ShippingTimeSection,
  TypeSection,
}
export default LabTestModal
