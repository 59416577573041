import { CircularProgress } from "@material-ui/core"
import { Button } from "@rupahealth/design"

export function RemoveLineItemButton({
  removing,
  disabled,
  orderedTestIds,
  updatingOrderedTestIds,
  enableLineItems,
  disableLineItems,
}: {
  removing: boolean
  disabled: boolean
  orderedTestIds: string[]
  updatingOrderedTestIds: string[]
  enableLineItems: (orderedTestIds: string[]) => void
  disableLineItems: (orderedTestIds: string[]) => void
}) {
  const onClick = () => {
    if (removing) {
      disableLineItems(orderedTestIds)
    } else {
      enableLineItems(orderedTestIds)
    }
  }
  const loading = updatingOrderedTestIds.includes(orderedTestIds[0])

  return (
    <div className="flex flex-row gap-2 items-start">
      {loading && <CircularProgress size={16} />}
      <Button
        type="button"
        variant="ghost"
        className="text-primary text-sm py-0 px-0 h-auto"
        onClick={onClick}
        disabled={disabled}
      >
        {removing ? "Remove" : "Add Back"}
      </Button>
    </div>
  )
}
