import { useState } from "react"

import BodyText from "app/components/design-system/BodyText"
import { INSURANCE_COMPANY_KEYS } from "app/constants"
import { formatDollars } from "app/utils"
import makeAppStyles from "app/utils/makeAppStyles"

const useStyles = makeAppStyles({
  panelItem: {
    background: "#F8F8F9",
    padding: "7px 13px",
  },
  panelItemCpt: {
    background: "#F8F8F9",
    padding: "7px 13px",
    display: "flex",
    flexDirection: "column",
    marginTop: 8,
  },
  cptPreviewText: {
    marginRight: 8,
    display: "inline",
  },
  cptExpandText: {
    cursor: "pointer",
    textDecorationLine: "underline",
    display: "inline",
  },
  cptCollapseText: {
    cursor: "pointer",
    textDecorationLine: "underline",
    marginTop: 12,
  },
})

interface PanelNameProps {
  testName: string
  price: string
  showPrice: boolean
  cptCodes: string[]
  labCompanyKey: string
}

const PanelName = ({
  testName,
  price,
  showPrice,
  cptCodes,
  labCompanyKey,
}: PanelNameProps) => {
  const classes = useStyles()
  const [cptExpanded, setCptExpanded] = useState(false)

  const isInsuranceTest = INSURANCE_COMPANY_KEYS.includes(labCompanyKey)

  if (isInsuranceTest) {
    const cptCodesToDisplay = cptCodes || []
    const firstThreeCptCodes = cptCodesToDisplay.slice(0, 3)
    const remainderCptCodes = cptCodesToDisplay.slice(3)
    const displayCptCodes = cptExpanded ? cptCodesToDisplay : firstThreeCptCodes

    return (
      <div className={classes.panelItemCpt}>
        <BodyText weight="bold" size="sm">
          {testName}
        </BodyText>
        <div>
          {cptCodesToDisplay.length > 0 && (
            <BodyText
              size="sm"
              weight="regular"
              className={classes.cptPreviewText}
            >
              CPT: {displayCptCodes.join(", ")}
            </BodyText>
          )}
          {remainderCptCodes.length > 0 && (
            <>
              {!cptExpanded && (
                <BodyText
                  size="sm"
                  weight="semibold"
                  onClick={() => setCptExpanded(true)}
                  className={classes.cptExpandText}
                >
                  + {remainderCptCodes.length} more
                </BodyText>
              )}
              {cptExpanded && (
                <BodyText
                  size="sm"
                  weight="semibold"
                  onClick={() => setCptExpanded(false)}
                  className={classes.cptCollapseText}
                >
                  Collapse
                </BodyText>
              )}
            </>
          )}
        </div>
      </div>
    )
  }

  return (
    <div className="flex justify-between items-center mt-1 mb-1">
      <BodyText>{testName}</BodyText>
      {showPrice && (
        <BodyText weight="semibold" className="ml-2">
          {formatDollars(price)}
        </BodyText>
      )}
    </div>
  )
}

export default PanelName
