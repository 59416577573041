import { useContext, useState } from "react"

import { CircularProgress } from "@material-ui/core"

import { ReactComponent as MarkReviewedIcon } from "app/assets/icons/mark-as-reviewed.svg"
import { ReactComponent as MarkedReviewedIcon } from "app/assets/icons/marked-as-reviewed.svg"
import TableRendererContext from "app/dashboard/contexts/TableRendererContext"
import useEventCallback from "app/hooks/use-event-callback"
import useHandleApiError from "app/hooks/use-handle-api-error"
import useCachedResource from "app/swr/hooks/use-cached-resource"
import useMutateResource from "app/swr/hooks/use-mutate-resource"
import { ResourceResponse } from "app/swr/types"
import resourceRequest from "app/swr/utils/resource-request"
import { DashboardKit } from "types/dashboard/dashboard-kit"
import { DashboardOrder } from "types/dashboard/dashboard-order"
import { DashboardOrderedResult } from "types/dashboard/dashboard-ordered-result"
import { OrderedResult } from "types/ordered-result"

import ActionButton from "./ActionButton"

export interface MarkResultsReviewedActionProps {
  kit: DashboardKit
  order: DashboardOrder
}

export default function MarkResultsReviewedAction({
  kit,
}: MarkResultsReviewedActionProps) {
  const [isLoading, setIsLoading] = useState(false)
  const latestOrderedResult = useCachedResource<DashboardOrderedResult>(
    kit.relationships.latest_ordered_result?.data
  )
  const hasResultsReviewed =
    !!latestOrderedResult?.attributes.date_result_reviewed
  const disabled = !latestOrderedResult || isLoading

  const toggleResultsReviewed = useToggleResultReviewed()

  const onClick = useEventCallback(async () => {
    if (disabled) {
      return
    }

    try {
      setIsLoading(true)

      await toggleResultsReviewed(latestOrderedResult?.id, !hasResultsReviewed)
    } finally {
      setIsLoading(false)
    }
  })

  return (
    <ActionButton
      data-cy="mark-result-reviewed-button"
      disabled={disabled}
      onClick={onClick}
      tooltipTitle={
        hasResultsReviewed
          ? "Unmark result as reviewed"
          : "Mark result as reviewed"
      }
    >
      {isLoading ? (
        <CircularProgress
          size={15}
          title="Toggling whether result is reviewed"
        />
      ) : latestOrderedResult?.attributes.date_result_reviewed ? (
        <MarkedReviewedIcon
          data-cy="mark-result-reviewed-button-marked"
          fill="currentColor"
          height="100%"
          title="mark-reviewed-icon"
          width="100%"
          viewBox="0 0 19 18"
        />
      ) : (
        <MarkReviewedIcon
          data-cy="mark-result-reviewed-button-unmarked"
          fill="currentColor"
          height="100%"
          title="mark-reviewed-icon"
          width="100%"
          viewBox="0 0 19 17"
        />
      )}
    </ActionButton>
  )
}

function useToggleResultReviewed() {
  const { unreadResults, revalidateOrders } = useContext(TableRendererContext)
  const mutateResource = useMutateResource()
  const handleApiError = useHandleApiError()
  return useEventCallback(
    async (orderedResultId: string, markAsReviewed: boolean) => {
      try {
        const request = resourceRequest<ResourceResponse<OrderedResult>>({
          method: "patch",
          url: `/ordered_results/${orderedResultId}/`,
          data: {
            data: {
              type: "ordered_result",
              id: orderedResultId,
              attributes: { mark_as_reviewed: markAsReviewed },
            },
          },
        })

        await mutateResource<DashboardOrderedResult>(
          {
            type: "dashboard_ordered_result",
            id: orderedResultId,
          },
          async (cachedData) => {
            if (!cachedData) {
              return cachedData
            }

            const response = await request

            return {
              ...cachedData,
              attributes: {
                ...cachedData.attributes,
                date_result_reviewed:
                  response.data.attributes.date_result_reviewed,
                // This will always be marked to false since we are marking the result as reviewed
                has_unread_notification: false,
              },
              relationships: {
                ...cachedData.relationships,
              },
            }
          },
          {
            throwOnError: true,
          }
        )

        // Revalidate unread results as the count may have changed
        unreadResults.revalidate()

        // Revalidate current page of orders to be sure we have the latest data without unread notifications
        revalidateOrders()
      } catch (error) {
        handleApiError(error)
      }
    }
  )
}
