import { useEffect, useMemo, useRef, useState } from "react"

import BodyText from "app/components/design-system/BodyText"
import makeAppStyles from "app/utils/makeAppStyles"

const MAX_SHORT_DESC_CHARACTERS = 140

const useStyles = makeAppStyles(() => ({
  container: {
    overflow: "hidden",
    transition: "height 0.15s ease-in-out",
  },
}))

interface Props {
  biomarkerDescription?: string
  biomarkerShortDescription?: string
  biomarkerCustomDescription?: string
}

const BiomarkerDescription = ({
  biomarkerDescription,
  biomarkerShortDescription,
  biomarkerCustomDescription,
}: Props) => {
  const classes = useStyles()

  const [showFullDescription, setShowFullDescription] = useState<boolean>(false)
  const [containerHeight, setContainerHeight] = useState<number | null>(null)

  const [shortDescriptionToUse, descriptionToUse] = useMemo(() => {
    let shortDescriptionToUse = ""
    let descriptionToUse = ""

    if (biomarkerCustomDescription) {
      shortDescriptionToUse = biomarkerCustomDescription
        .slice(0, MAX_SHORT_DESC_CHARACTERS)
        .trim()
      descriptionToUse = biomarkerCustomDescription
    } else {
      if (biomarkerShortDescription) {
        shortDescriptionToUse = biomarkerShortDescription
        descriptionToUse = biomarkerDescription || "No description available."
      } else if (biomarkerDescription) {
        shortDescriptionToUse = biomarkerDescription
          .slice(0, MAX_SHORT_DESC_CHARACTERS)
          .trim()
        descriptionToUse = biomarkerDescription
      } else {
        shortDescriptionToUse = "No description available."
        descriptionToUse = "No description available."
      }
    }

    return [shortDescriptionToUse, descriptionToUse]
  }, [
    biomarkerDescription,
    biomarkerShortDescription,
    biomarkerCustomDescription,
  ])

  // Ref to track the last time the containerHeight was updated
  const lastUpdateRef = useRef(Date.now())
  const containerRef = useRef<any>(null)
  const fullDescriptionRef = useRef<any>(null)
  const shortDescriptionRef = useRef<any>(null)

  useEffect(() => {
    // Check for a change in height every 100ms
    // This is in place because on init of the component, the height
    // of the description may not be accurate.
    // This will stop once container heights remain the same for 1 second
    const intervalId = setInterval(() => {
      const targetNode = showFullDescription
        ? fullDescriptionRef.current
        : shortDescriptionRef.current
      const newHeight = targetNode?.offsetHeight

      if (newHeight !== containerHeight) {
        // Update the container height and the last update timestamp
        setContainerHeight(newHeight)
        lastUpdateRef.current = Date.now()
      }
    }, 100)

    // Setup a timeout to clear the interval after 2 seconds of no updates
    const timeoutId = setTimeout(() => {
      const timeSinceLastUpdate = Date.now() - lastUpdateRef.current
      if (timeSinceLastUpdate >= 1000) {
        // 2 seconds
        clearInterval(intervalId)
      }
    }, 1000) // Check 2 seconds after component mounts/updates

    return () => {
      // Clear both interval and timeout on cleanup
      clearInterval(intervalId)
      clearTimeout(timeoutId)
    }
  }, [showFullDescription, containerHeight])

  return (
    <div
      ref={containerRef}
      className={classes.container}
      style={{ height: containerHeight ? `${containerHeight}px` : "auto" }}
    >
      <div ref={showFullDescription ? fullDescriptionRef : shortDescriptionRef}>
        <BodyText>
          {showFullDescription ? descriptionToUse : shortDescriptionToUse}
          {Boolean(shortDescriptionToUse !== descriptionToUse) && (
            <span
              className="font-semibold underline cursor-pointer ml-1"
              onClick={() => setShowFullDescription((prev) => !prev)}
            >
              {showFullDescription ? "Show less" : "Show more"}
            </span>
          )}
        </BodyText>
      </div>
    </div>
  )
}

export default BiomarkerDescription
