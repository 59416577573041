import { Typography } from "@material-ui/core"

import RupaConsentLabel from "app/main/patient-checkout/sections/RupaConsentLabel"
import { FieldNames } from "app/main/payment-collection/constants"
import { PaymentCollectionConfiguration } from "app/main/payment-collection/types"
import { ControlledCheckboxInput } from "app/patient-portal/fields"
import { colors, shadows } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"

const useStyles = makeAppStyles((theme) => ({
  container: {
    padding: theme.spacing(3),
    backgroundColor: "white",
    boxShadow: shadows.default,
    border: `1px solid ${colors.blueGray[200]}`,
    borderRadius: 8,
  },
  header: {
    fontSize: 24,
  },
  termsLink: {
    color: theme.palette.text.primary,
    textDecoration: "underline !important",
  },
  consentText: {
    display: "inline",
  },
  consentTextAsterisk: {
    display: "inline",
    color: colors.red[600],
  },
}))

export interface PaymentCollectionConsentProps {
  config: PaymentCollectionConfiguration
}

// We aren't able to select the Order object associated with this payment
// collection using common patterns e.g.
// const order = selectOrderState(state).order
// and
// const order = useAppSelector(({ orders }) => orders.orders.order)
// because it's not available within the state.
// Which means we're unable to make a direct check on the order's
// requires_vendor_physician_authorization field.
// So, we're passing config down from the parent component, and are using
// config's ...physician_authorization_total value as a proxy for whether
// or not the order requires physician authorization.
export default function PaymentCollectionConsent({
  config,
}: PaymentCollectionConsentProps) {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <Typography className={classes.header} variant="h4" color="textPrimary">
        Consent
      </Typography>
      <ControlledCheckboxInput
        color="primary"
        defaultValue={false}
        inputProps={
          {
            "data-testid": "consent-checkbox",
          } as any
        }
        label={
          <RupaConsentLabel
            clinicName={config.practitioner.clinic_name}
            requiresPhysicianServices={
              +config.pricing.order_pricing.physician_authorization_total > 0
            }
          />
        }
        name={FieldNames.CONSENT}
      />
    </div>
  )
}
