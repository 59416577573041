import { useMemo, useState } from "react"

import { ReactComponent as Checklist } from "app/assets/icons/clipboard-checklist-blue.svg"
import BiomarkerValueSection from "app/main/blood-lab-dashboards/BloodLabDashboardsSnapshotModal/BloodLabDashboardsSnapshotBiomarkerValueSection"
import { BiomarkerStatus } from "app/patient-portal/blood-lab-dashboard/constants"
import useCachedResource from "app/swr/hooks/use-cached-resource"
import { Biomarker } from "types/biomarker"
import { BiomarkerCustomDescription } from "types/biomarker-custom-description"
import { BloodReportResult } from "types/blood-report-result"

import BloodLabDashboardsBiomarkerCard from "../BloodLabDashboardsModal/BloodLabDashboardsBiomarkerCard"
import NumericBiomarkerGraphic from "../NumericBiomarkerGraphic/NumericBiomarkerGraphic"
import { getValueForNumericBiomarkerGraphicFromBloodReportResult } from "../NumericBiomarkerGraphic/utils"
import { getBiomarkerStatus, parseStringValueToFloat } from "../utils"

export default function BloodLabDashboardsSnapshotBiomarkerCardFromDiscreteResult({
  bloodReportResult,
  showHighLowDescriptions,
  biomarkerCustomDescription,
}: {
  bloodReportResult: BloodReportResult
  showHighLowDescriptions?: boolean
  biomarkerCustomDescription?: BiomarkerCustomDescription
}) {
  // State to track hover status
  const [isHovered, setIsHovered] = useState(false)

  // Function to handle mouse enter
  const handleMouseEnter = () => {
    setIsHovered(true)
  }

  // Function to handle mouse leave
  const handleMouseLeave = () => {
    setIsHovered(false)
  }

  const biomarker = useCachedResource<Biomarker>(
    bloodReportResult.relationships.biomarker.data
  )

  const biomarkerStatus = getBiomarkerStatus(bloodReportResult)

  const descriptions = {
    low:
      biomarkerCustomDescription?.attributes.low_description ??
      biomarker?.attributes.low_description,
    high:
      biomarkerCustomDescription?.attributes.high_description ??
      biomarker?.attributes.high_description,
    main:
      biomarkerCustomDescription?.attributes.description ??
      biomarker?.attributes.description,
  }

  const showNumericRangeBar = useMemo(() => {
    return (
      (bloodReportResult?.attributes.standard_range_min ||
        bloodReportResult?.attributes.standard_range_max) &&
      !Number.isNaN(parseStringValueToFloat(bloodReportResult.attributes.value))
    )
  }, [bloodReportResult])

  const biomarkerName =
    bloodReportResult.attributes.biomarker_short_name ===
    bloodReportResult.attributes.biomarker_long_name
      ? bloodReportResult.attributes.biomarker_short_name
      : `${bloodReportResult.attributes.biomarker_long_name} (${bloodReportResult.attributes.biomarker_short_name})`

  return (
    <div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className={"w-full"}
    >
      <BloodLabDashboardsBiomarkerCard
        isHovered={isHovered}
        name={biomarkerName}
        valueSection={
          <BiomarkerValueSection
            alternateValue={bloodReportResult.attributes.alternate_value}
            value={bloodReportResult.attributes.display_value || ""}
            unit={bloodReportResult.attributes.unit ?? ""}
            status={biomarkerStatus}
          />
        }
        graphicSection={
          showNumericRangeBar ? (
            <NumericBiomarkerGraphic
              normalMax={bloodReportResult.attributes.standard_range_max}
              normalMin={bloodReportResult.attributes.standard_range_min}
              optimalMax={bloodReportResult?.attributes.optimal_range_max}
              optimalMin={bloodReportResult?.attributes.optimal_range_min}
              value={getValueForNumericBiomarkerGraphicFromBloodReportResult(
                bloodReportResult
              )}
              status={bloodReportResult?.attributes.status}
              highlightCenter={true}
              singleLabel={true}
              isCondensed={true}
              isHovered={isHovered}
              hideTooltip={true}
            />
          ) : (
            <div className="h-[58px]"></div>
          )
        }
      >
        <div>{descriptions.main}</div>
        {showHighLowDescriptions &&
          (biomarkerStatus === BiomarkerStatus.LOW ||
            biomarkerStatus === BiomarkerStatus.BELOW_OPTIMAL) && (
            <div className={"flex flex-col gap-4"}>
              <div>
                <span className={"font-semibold"}>▼</span> {descriptions.low}
              </div>
              {biomarker?.attributes.low_symptoms && (
                <div>
                  <span>
                    <Checklist
                      viewBox="0 0 100 100"
                      className={"h-4 w-4 mr-[2px]"}
                    />
                  </span>{" "}
                  {biomarker?.attributes.low_symptoms}
                </div>
              )}
            </div>
          )}

        {showHighLowDescriptions &&
          (biomarkerStatus === BiomarkerStatus.HIGH ||
            biomarkerStatus === BiomarkerStatus.ABOVE_OPTIMAL) && (
            <div className={"flex flex-col gap-4"}>
              <div>
                <span className={"font-semibold"}>▲</span> {descriptions.high}
              </div>
              {biomarker?.attributes.high_symptoms && (
                <div>
                  <span>
                    <Checklist
                      viewBox="0 0 100 100"
                      className={"h-4 w-4 mr-[2px]"}
                    />
                  </span>{" "}
                  {biomarker?.attributes.high_symptoms}
                </div>
              )}
            </div>
          )}
      </BloodLabDashboardsBiomarkerCard>
    </div>
  )
}
