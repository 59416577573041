import { useCallback, useMemo } from "react"

import { cn } from "@rupahealth/design"

import { ReactComponent as ResultsEditIcon } from "app/assets/icons/flask.svg"
import { ReactComponent as ManualPatientResultDocumentIcon } from "app/assets/images/documents/ordered-result-document-icon.svg"
import useUserResultModal from "app/main/results-upload-v2/user-result-modal/UserResultModal"
import useCachedResource from "app/swr/hooks/use-cached-resource"
import { Document } from "types/patient-with-documents"
import { UserResult } from "types/user-result"

import DocumentCardActionsMenu from "./DocumentCardActionsMenu"
import PatientDocumentCard from "./PatientDocumentCard"
import PendingTrackingInTrendsSubTitle, {
  TrackingInTrendsSubTitle,
} from "./PendingTrackingInTrendsSubTitle"
import useDocumentsDescriptors from "./hooks/use-documents-descriptors"

interface Props {
  patientId: string
  document: Document
  inPatientPortal: boolean
  onDelete: (document: Document) => void
}

const ImportedUserResultDocumentCard: React.FC<Props> = ({
  patientId,
  document,
  inPatientPortal,
  onDelete,
}) => {
  const userResult = useCachedResource<UserResult>(
    document.relationships.user_result?.data
  )

  const userResultModal = useUserResultModal()

  const isPendingTrackingInTrends = useMemo(() => {
    return (
      userResult?.attributes.supports_trends_visualization &&
      !userResult?.attributes.has_snapshot
    )
  }, [userResult])

  const fileUrl = userResult?.attributes.pdf_file

  const canDownload = document.meta.can_read && !!fileUrl
  const canEdit = document.meta.can_write
  const canDelete = document.meta.can_delete

  const onViewOrEdit = () => {
    userResultModal.show({ patientId, userResultId: userResult?.id })
  }

  const onDownload = () => {
    if (canDownload) {
      window.open(fileUrl)
    }
  }

  const Icon = useCallback(
    (className: string) => {
      if (userResult?.attributes.latest_thumbnail) {
        return (
          <img
            className={cn(className, "w-[90px] h-[111px]")}
            src={userResult?.attributes.latest_thumbnail}
            alt="Report Thumbnail"
          />
        )
      }
      return <ManualPatientResultDocumentIcon className={className} />
    },
    [document]
  )

  const { subtitle } = useDocumentsDescriptors(document)

  let editLabel = "View Data"
  if (canEdit) {
    editLabel = isPendingTrackingInTrends
      ? "Prepare to Track in Trends"
      : "Edit Data"
  }

  const preSubTitle = useMemo(() => {
    if (userResult?.attributes.has_snapshot) {
      return <TrackingInTrendsSubTitle title="In Trends" />
    }
    return (
      <PendingTrackingInTrendsSubTitle title="Details Required for Trends" />
    )
  }, [userResult])

  return (
    <PatientDocumentCard
      onClick={onViewOrEdit}
      Icon={Icon}
      preTitle={document.attributes.lab_company_name?.toUpperCase()}
      title="Imported Results"
      preSubTitle={preSubTitle}
      subTitle={subtitle}
      actions={
        <DocumentCardActionsMenu
          onDownload={canDownload ? onDownload : undefined}
          onDelete={canDelete ? () => onDelete(document) : undefined}
          onEdit={onViewOrEdit}
          editIcon={<ResultsEditIcon />}
          editLabel={editLabel}
          deleteLabel="Remove Data"
        />
      }
    />
  )
}

export default ImportedUserResultDocumentCard
