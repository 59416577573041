import { cn } from "@rupahealth/design"

import BodyText from "app/components/design-system/BodyText"

export enum BloodLabDashboardsGroupByGroupings {
  LAB_TEST = "lab_test",
  BODY_SYSTEM = "body_system",
}

interface Props {
  currentGrouping: BloodLabDashboardsGroupByGroupings
  handleSetGrouping: (grouping: BloodLabDashboardsGroupByGroupings) => void
}

const GroupByToggle = ({ currentGrouping, handleSetGrouping }: Props) => {
  return (
    <div className="flex w-full md:w-[250px] h-[40px] p-1 items-center rounded-lg bg-slate-200 gap-1">
      <div
        className={cn(
          "flex",
          "flex-col",
          "justify-center",
          "items-center",
          "gap-2",
          "flex-1",
          "self-stretch",
          "rounded-md",
          "cursor-pointer",
          "whitespace-nowrap",
          "pl-4",
          "pr-4",
          {
            "bg-white":
              currentGrouping === BloodLabDashboardsGroupByGroupings.LAB_TEST,
            "shadow-sm":
              currentGrouping === BloodLabDashboardsGroupByGroupings.LAB_TEST,
          }
        )}
        data-cy="blood-lab-dashboard-lab-test-option"
        onClick={() => {
          handleSetGrouping(BloodLabDashboardsGroupByGroupings.LAB_TEST)
        }}
      >
        <BodyText
          size="sm"
          weight={
            currentGrouping === BloodLabDashboardsGroupByGroupings.LAB_TEST
              ? "semibold"
              : "regular"
          }
        >
          Lab Test
        </BodyText>
      </div>
      <div
        className={cn(
          "flex",
          "flex-col",
          "justify-center",
          "items-center",
          "gap-2",
          "flex-1",
          "self-stretch",
          "rounded-md",
          "cursor-pointer",
          "whitespace-nowrap",
          "pl-4",
          "pr-4",
          {
            "bg-white":
              currentGrouping ===
              BloodLabDashboardsGroupByGroupings.BODY_SYSTEM,
            "shadow-sm":
              currentGrouping ===
              BloodLabDashboardsGroupByGroupings.BODY_SYSTEM,
          }
        )}
        data-cy="blood-lab-dashboard-body-system-option"
        onClick={() => {
          handleSetGrouping(BloodLabDashboardsGroupByGroupings.BODY_SYSTEM)
        }}
      >
        <BodyText
          size="sm"
          weight={
            currentGrouping === BloodLabDashboardsGroupByGroupings.BODY_SYSTEM
              ? "semibold"
              : "regular"
          }
        >
          Body System
        </BodyText>
      </div>
    </div>
  )
}

export default GroupByToggle
