import { useDispatch } from "react-redux"

import { CellContext } from "@tanstack/table-core"

import { resendRegistrationEmail } from "app/auth/store/actions"
import SignatureAndLicenseModalButton from "app/components/modals/SignatureAndLicenseModal/SignatureAndLicenseModalButton"
import useEventCallback from "app/hooks/use-event-callback"
import useAppSelector from "app/hooks/useAppSelector"
import ESignatureTooltip from "app/main/dashboard/OrderList/ESignatureTooltip"
import useCachedResource from "app/swr/hooks/use-cached-resource"
import { DashboardOrder } from "types/dashboard/dashboard-order"
import { DashboardPractitioner } from "types/dashboard/dashboard-practitioner"

import useRevalidateOrders from "../../hooks/use-revalidate-orders"
import useSendActivationLinks from "./use-send-activation-links"

/**
 * Reimplementation of the Esignature behavior from ViewOrderTableCell. This is not ideal, and probably deserves a revisit at some point to clean up.
 */
export default function ESignatureRequiredButton({
  className,
  row,
}: CellContext<DashboardOrder, unknown> & { className: string }) {
  const revalidateOrders = useRevalidateOrders()

  const dispatch = useDispatch()
  const loggedInPractitioner = useAppSelector(
    ({ practitioner }) => practitioner
  )
  const signingPractitioner = useCachedResource<DashboardPractitioner>(
    row.original.relationships.signing_practitioner?.data
  )

  const isLoggedInSigningPractitioner =
    signingPractitioner?.id === loggedInPractitioner.id
  const isSigningPractitionerVerified = Boolean(
    signingPractitioner?.attributes?.is_verified
  )
  const canSignForOrder =
    isLoggedInSigningPractitioner && isSigningPractitionerVerified

  const disabled = !canSignForOrder

  const { isSending, sendActivationLinks } = useSendActivationLinks()

  const handleResendVerificationEmail = useEventCallback(() => {
    dispatch(
      resendRegistrationEmail({ email: loggedInPractitioner?.user?.email })
    )
  })

  const handleEsignatureClose = useEventCallback((fullVerificationComplete) => {
    if (fullVerificationComplete) {
      revalidateOrders()
    }
  })

  return (
    <>
      <ESignatureTooltip
        canSignForOrder={canSignForOrder}
        currentPractitioner={loggedInPractitioner}
        loading={isSending}
        onClickResendVerificationEmail={handleResendVerificationEmail}
        sendActivationLinks={() =>
          signingPractitioner &&
          sendActivationLinks(
            row.original.id,
            signingPractitioner?.attributes.email
          )
        }
        signingPractitioner={
          signingPractitioner
            ? {
                id: signingPractitioner.id,
                email: signingPractitioner.attributes.email,
                titledName: signingPractitioner.attributes.titled_full_name,
              }
            : undefined
        }
        resizeButton={false}
      >
        <SignatureAndLicenseModalButton
          location="dashboard_sign_now_button"
          className={className}
          disabled={disabled}
          returnLinkText="Return to Dashboard"
          onClose={handleEsignatureClose}
        >
          Sign Now
        </SignatureAndLicenseModalButton>
      </ESignatureTooltip>
    </>
  )
}
