import { AuthUser } from "app/types"

export const isUserGuest = (user: AuthUser) => {
  return !user?.is_provider && !user?.is_staff && !user?.has_patients
}

export const isUserPractitioner = (user: AuthUser) => {
  return user?.is_provider || user?.is_staff
}

export const isUserPatient = (user: AuthUser) => {
  return user?.has_patients
}
