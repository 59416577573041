import _ from "lodash"

import { LAB_COMPANY_ACCOUNT_STATUS } from "../constants"

/**
 * Checks through a list of LabCompanyAccounts to see if any have the "confirmed" status matching the provided lab
 * company key.
 * @param labAccounts: A list of labCompanyAccount objects
 * @param labCompanyKey: A string representing the key for the lab company to be checked
 */
export function isLabAccountConfirmed(labAccounts, labCompanyKey) {
  for (const labAccount of labAccounts) {
    if (
      _.some(
        labAccount.lab_companies,
        (labCompany) => labCompany.key === labCompanyKey
      ) &&
      labAccount.status === LAB_COMPANY_ACCOUNT_STATUS.CONFIRMED
    ) {
      return true
    }
  }

  return false
}

/**
 * Checks through a list of LabCompanyAccounts to see if any have the "confirmed" status matching the provided lab
 * company key and have an account id.
 * @param labAccounts: A list of labCompanyAccount objects
 * @param labCompanyKey: A string representing the key for the lab company to be checked
 */
export function hasLabAccountConfirmedWithAccountId(
  labAccounts,
  labCompanyKey
) {
  for (const labAccount of labAccounts) {
    if (
      _.some(
        labAccount.lab_companies,
        (labCompany) => labCompany.key === labCompanyKey
      ) &&
      labAccount.status === LAB_COMPANY_ACCOUNT_STATUS.CONFIRMED &&
      labAccount.account_id
    ) {
      return true
    }
  }

  return false
}
