import * as React from "react"

import clsx from "clsx"

import { shadows } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"

const useStyles = makeAppStyles((theme) => ({
  root: {
    width: "100%",
    display: "flex",
    flexFlow: "column nowrap",
    padding: theme.spacing(2.5, 2.0),
    backgroundColor: "white",
    boxShadow: shadows.default,
    gap: theme.spacing(2.5),
    borderRadius: 8,
  },
}))

export default function FoodPlanCard({
  children,
  className,
}: {
  children: React.ReactNode
  className?: string
}) {
  const classes = useStyles()

  return <div className={clsx(classes.root, className)}>{children}</div>
}
