import { Link } from "react-router-dom"

import { Typography } from "@material-ui/core"

function Error500Page() {
  return (
    <div className="flex flex-col flex-1 items-center justify-center p-4">
      <div className="max-w-lg text-center">
        <Typography variant="h1" color="inherit" className="font-semibold mb-4">
          500
        </Typography>

        <Typography variant="h5" color="textSecondary" className="mb-4">
          Well, you broke the internet!
        </Typography>

        <Typography variant="subtitle1" color="textSecondary" className="mb-12">
          Just kidding, looks like we have an internal issue, please try again
          in couple minutes
        </Typography>

        <Link className="font-semibold" to="/apps/dashboards/project">
          Report this problem
        </Link>
      </div>
    </div>
  )
}

export default Error500Page
