import { BEARER_TOKEN_PREFIX } from "app/auth/services/simplejwt-auth-service"

import { Resource } from "./types"

interface FetcherOptions {
  accessToken?: string | (() => Promise<string | undefined>)
}

export class JSONAPIError extends Error {
  constructor(
    readonly errors: {
      code: string
      detail: string
      source: string
      status: string
    }[],
    readonly status_code: number
  ) {
    super()

    this.errors = errors
    this.status_code = status_code
  }
}

export interface FetcherResponse<R> {
  data: R | R[]
  included?: R[]
}

export default function makeFetcher<ValidResource extends Resource>() {
  async function fetcher(url: string, options: FetcherOptions = {}) {
    const headers = {
      "content-type": "application/vnd.api+json",
    }
    if (options.accessToken) {
      if (typeof options.accessToken === "function") {
        options.accessToken = await options.accessToken()
      }

      headers["authorization"] = `${BEARER_TOKEN_PREFIX} ${options.accessToken}`
    }

    const response = await fetch(url, {
      headers,
      credentials: "include",
    })

    if (!response?.ok) {
      let errors: JSONAPIError["errors"]

      try {
        const json = await response.json()
        errors = json["errors"]
      } catch (error) {
        errors = [
          {
            code: "unknown_error",
            detail: "An unknown error occurred. Please contact support.",
            source: "",
            status: "500",
          },
        ]
      }

      throw new JSONAPIError(errors, response?.status || 500)
    }

    const data = await response.json()
    return data as FetcherResponse<ValidResource>
  }

  return fetcher
}
