import React from "react"

import NiceModal, { useModal } from "@ebay/nice-modal-react"
import { Dialog, DialogContent } from "@rupahealth/design"

import { Practitioner } from "app/types"

import CredentialModalProvider, {
  CredentialModalProviderProps,
  CredentialModalStep,
  useCredentialModalContext,
} from "./CredentialModalProvider"
import Step1LicenseType from "./Step1LicenseType"
import Step2LicenseUpload from "./Step2LicenseUpload"
import Step3Signature from "./Step3Signature"

export type CredentialsModalPhase = "license" | "signature"

export interface CredentialsModalProps
  extends Omit<CredentialModalProviderProps, "children"> {}

interface InnerProps {
  practitioner: Practitioner
}

const CredentialsModal: React.FC<InnerProps> = ({ practitioner }) => {
  const { modalOpen, closeModal } = useCredentialModalContext()

  const onOpenChange = (open: boolean) => {
    if (!open) closeModal()
  }

  return (
    <Dialog open={modalOpen} onOpenChange={onOpenChange}>
      <DialogContent className="bg-slate-100 p-0 max-h-[90vh] overflow-y-auto max-w-[90vw] md:max-w-2xl border-[4px]">
        <CredentialsModalInner practitioner={practitioner} />
      </DialogContent>
    </Dialog>
  )
}

function CredentialsModalInner({ practitioner }: InnerProps) {
  const { step } = useCredentialModalContext()

  if (step === CredentialModalStep.LicenseType) {
    return <Step1LicenseType />
  } else if (step === CredentialModalStep.LicenseUpload) {
    return <Step2LicenseUpload practitioner={practitioner} />
  } else if (step === CredentialModalStep.Signature) {
    return <Step3Signature />
  }
  return null
}

const CredentialsNiceModal = NiceModal.create<CredentialsModalProps>(
  (props) => (
    <CredentialModalProvider {...props}>
      <CredentialsModal {...props} />
    </CredentialModalProvider>
  )
)

export function useCredentialsModal() {
  return useModal(CredentialsNiceModal)
}
