import { useEffect, useState } from "react"

import { Dialog, makeStyles, Theme } from "@material-ui/core"
import MuiDialogContent from "@material-ui/core/DialogContent"
import MuiDialogTitle from "@material-ui/core/DialogTitle"
import IconButton from "@material-ui/core/IconButton"
import CloseIcon from "@material-ui/icons/Close"

import DesignSystemButton, {
  ButtonProps,
} from "app/components/design-system/Button"
import DisplayText from "app/components/design-system/DisplayText"
import { ModalActions } from "app/components/modals"
import { colors, navy } from "app/theme"

const styles = (theme: Theme) => ({
  dialogPaper: {
    [theme.breakpoints.up("md")]: {
      minWidth: 445,
    },
    margin: 15,
    borderRadius: 7,
  },
  titleAndInfoContainer: {
    display: "flex",
    "flex-direction": "column",
    "flex-wrap": "wrap",
    marginRight: 24,
  },
  closeButton: {
    color: navy,
    position: "relative" as "relative",
    top: -5,
    right: 0,
    marginRight: -12,
  },
  styledDialogTitle: {
    display: "flex",
    "flex-direction": "row",
    justifyContent: "space-between",
    "flex-wrap": "nowrap",
    backgroundColor: "white",
    borderBottomWidth: 1,
    borderColor: colors.blueGray[200],
    alignItems: "center",
    padding: "15px 24px 8px 24px",
  },
  styledContent: {
    padding: theme.spacing(2),
    backgroundColor: colors.coolGray[100],
  },
  divider: {
    width: "100%",
  },
})

const useStyles = makeStyles(styles)

interface ConfirmationModalData {
  title: string
  text: string
  buttonText: string
}

interface GenericConfirmationModalProps {
  open: boolean
  onClose: () => void
  data: ConfirmationModalData
  actionFn: () => void
  actionButtonColor?: ButtonProps["color"]
}

export default function GenericConfirmationModal({
  open,
  onClose,
  data,
  actionFn,
  actionButtonColor = "primary",
}: GenericConfirmationModalProps) {
  const classes = useStyles(styles)

  const [loading, setLoading] = useState(false)

  const handleBtnClick = () => {
    setLoading(true)
    actionFn()
  }

  useEffect(() => {
    setLoading(false)
  }, [open])

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="resend-confirmation-modal"
      classes={{
        paper: classes.dialogPaper,
      }}
    >
      <TitleSection title={data.title} onClose={onClose} />
      <MuiDialogContent className={classes.styledContent}>
        {data.text}
      </MuiDialogContent>
      <ModalActions>
        <DesignSystemButton
          onClick={handleBtnClick}
          color={actionButtonColor}
          loading={loading}
        >
          {data.buttonText}
        </DesignSystemButton>
      </ModalActions>
    </Dialog>
  )
}

const TitleSection = ({ title, onClose }) => {
  const classes = useStyles(styles)
  const closeButton = onClose && (
    <IconButton
      aria-label="close"
      onClick={onClose}
      key="close-button"
      className={classes.closeButton}
    >
      <CloseIcon />
    </IconButton>
  )

  return (
    <MuiDialogTitle disableTypography className={classes.styledDialogTitle}>
      <div className={classes.titleAndInfoContainer}>
        <DisplayText weight="semibold" size="lg">
          {title}
        </DisplayText>
      </div>
      {closeButton}
    </MuiDialogTitle>
  )
}
