import { StorefrontLabCompanyType } from "app/hooks/use-resource"
import { EmptyComparisonValue } from "app/main/comparison/components/ComparisonLabTests/components"

interface Props {
  labCompany: StorefrontLabCompanyType | undefined
}

const CertificationsColumn = ({ labCompany }: Props) => {
  if (!labCompany?.attributes.certifications?.length) {
    return <EmptyComparisonValue />
  } else {
    const certifications = labCompany.attributes.certifications
    return <>{certifications.join(", ")}</>
  }
}

export default CertificationsColumn
