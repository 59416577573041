import { useAsync } from "react-use"

import { API } from "app/api"
import { PaymentCollectionConfiguration } from "app/main/payment-collection/types"

export interface HookResultReady {
  config: PaymentCollectionConfiguration
  error: undefined
  ready: true
}

export interface HookResultNotReady {
  config: undefined
  error: undefined
  ready: false
}

export interface HookResultError {
  config: undefined
  error: Error
  ready: false
}

export default function usePaymentCollectionConfiguration(
  token: string
): HookResultNotReady | HookResultReady | HookResultError {
  const { error, value } = useAsync(async () => {
    const response = await API.PaymentCollection.get(token)
    return response.data
  }, [token])

  if (error) {
    return {
      config: undefined,
      error,
      ready: false,
    }
  }

  if (value) {
    return {
      config: value,
      error: undefined,
      ready: true,
    }
  }

  return {
    config: undefined,
    error: undefined,
    ready: false,
  }
}
