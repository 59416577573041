import {
  Editor,
  Range,
  Point,
  Element as SlateElement,
  Transforms,
} from "slate"

export default function withTables<T extends Editor>(editor: T): T {
  const { deleteBackward, deleteForward, insertBreak } = editor

  editor.deleteBackward = (unit) => {
    const { selection } = editor

    if (selection && Range.isCollapsed(selection)) {
      const [cell] = Editor.nodes(editor, {
        match: (n) =>
          !Editor.isEditor(n) &&
          SlateElement.isElement(n) &&
          (n.type === "table-cell" || n.type === "table-cell-header"),
      })

      if (cell) {
        const [, cellPath] = cell
        const start = Editor.start(editor, cellPath)

        if (Point.equals(selection.anchor, start)) {
          return
        }
      }
    }

    deleteBackward(unit)
  }

  editor.deleteForward = (unit) => {
    const { selection } = editor

    if (selection && Range.isCollapsed(selection)) {
      const [cell] = Editor.nodes(editor, {
        match: (n) =>
          !Editor.isEditor(n) &&
          SlateElement.isElement(n) &&
          (n.type === "table-cell" || n.type === "table-cell-header"),
      })

      if (cell) {
        const [, cellPath] = cell
        const end = Editor.end(editor, cellPath)

        if (Point.equals(selection.anchor, end)) {
          return
        }
      }
    }

    deleteForward(unit)
  }

  editor.insertBreak = () => {
    const { selection } = editor

    if (selection) {
      const [table] = Editor.nodes(editor, {
        match: (n) =>
          !Editor.isEditor(n) &&
          SlateElement.isElement(n) &&
          n.type === "table",
      })

      if (table) {
        const [, tablePath] = table
        const end = Editor.end(editor, tablePath)

        if (Point.equals(selection.anchor, end)) {
          const next = Editor.next(editor, {
            at: tablePath,
          })

          if (!next) {
            // If attempting to insert a break at end of the table, and there is no next node, insert a new paragraph after and move selection to it.
            Transforms.insertNodes(
              editor,
              { type: "paragraph", children: [{ text: "" }] },
              { at: [editor.children.length], select: true }
            )
          } else {
            // If attempting to insert a break at end of the table, and there is a next node, move selection to it.
            Transforms.select(editor, {
              anchor: Editor.start(editor, next[1]),
              focus: Editor.start(editor, next[1]),
            })
          }
        }

        // Return early to prevent default insertBreak behavior when breaking within a table.
        return
      }
    }

    insertBreak()
  }

  return editor
}
