import { useSelector } from "react-redux"

import { isUserGuest } from "app/auth/util"

import { RootState } from "../types"

export function IsLoggedIn(): boolean {
  return useSelector((state: RootState) => {
    return IsUserLoggedIn(state.auth.user)
  })
}

export function IsUserLoggedIn(user: RootState["auth"]["user"]) {
  return !isUserGuest(user)
}
