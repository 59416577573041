import { useState } from "react"

import useEventCallback from "app/hooks/use-event-callback"
import useHandleApiError from "app/hooks/use-handle-api-error"
import { getIdentifier } from "app/swr/helpers/resource"
import useMutateResource from "app/swr/hooks/use-mutate-resource"
import { ResourceIdentifier, ResourceResponse } from "app/swr/types"
import resourceRequest from "app/swr/utils/resource-request"
import { LabCompanyBiomarkerRange } from "types/lab-company-biomarker-range"

export default function usePatchOptimalRange(
  optimalRange: LabCompanyBiomarkerRange
) {
  const handleApiError = useHandleApiError()
  const mutateResource = useMutateResource()

  const [loading, setLoading] = useState(false)

  const identifier: ResourceIdentifier = getIdentifier(optimalRange)

  const patchOptimalRange = useEventCallback(
    async (attributes: LabCompanyBiomarkerRange["attributes"]) => {
      setLoading(true)

      const sendPatchRequest = async () => {
        try {
          const { data } = await resourceRequest<
            ResourceResponse<LabCompanyBiomarkerRange>
          >({
            method: "patch",
            url: `/lab_company_biomarker_range/${optimalRange.id}/`,
            data: {
              data: {
                ...identifier,
                attributes,
              },
            },
          })

          setLoading(false)

          return data
        } catch (error) {
          handleApiError(error)

          setLoading(false)

          // re-throw so that the caller can handle the error
          throw error
        }
      }

      return mutateResource<LabCompanyBiomarkerRange>(
        identifier,
        sendPatchRequest,
        {
          optimisticData(currentData) {
            if (!currentData) {
              // should never be reached, but type error without
              return undefined as any
            }

            return {
              ...currentData,
              attributes: {
                ...currentData.attributes,
                ...attributes,
              },
            }
          },
          rollbackOnError: true,
        }
      )
    }
  )

  return {
    patchOptimalRange,
    loading,
  }
}
