import { Theme } from "@material-ui/core"
import { Skeleton } from "@material-ui/lab"

import makeAppStyles from "app/utils/makeAppStyles"

const useStyles = makeAppStyles((theme: Theme) => ({
  loadingContainer: {
    padding: "60px 64px",
    display: "flex",
    flexDirection: "column",
    gap: 16,
    width: "100%",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {},
  },
  skeleton: {
    borderRadius: 12,
  },
}))

const PatientPortalPatientsLoadingState = () => {
  const classes = useStyles()
  return (
    <div className={classes.loadingContainer}>
      <Skeleton
        variant="rect"
        animation="wave"
        height={84}
        width={500}
        className={classes.skeleton}
      />
      <Skeleton
        variant="rect"
        animation="wave"
        height={84}
        width={500}
        className={classes.skeleton}
      />
      <Skeleton
        variant="rect"
        animation="wave"
        height={84}
        width={500}
        className={classes.skeleton}
      />
      <Skeleton
        variant="rect"
        animation="wave"
        height={84}
        width={500}
        className={classes.skeleton}
      />
    </div>
  )
}

export default PatientPortalPatientsLoadingState
