import { makeStyles } from "@material-ui/core"

import HealthCategoryIcon from "app/assets/icons/health-category.svg"
import BodyText from "app/components/design-system/BodyText"
import { colors, navy } from "app/theme"

const useStyles = makeStyles((theme) => ({
  specialtyPillContainer: {
    alignItems: "center",
    backgroundColor: colors.blueGray[100],
    borderRadius: 20,
    color: navy,
    display: "inline-flex",
    fontSize: 13,
    fontWeight: theme.typography.fontWeightMedium,
    justifyContent: "center",
    margin: 3,
    padding: "6px 13px",
  },
  specialtyPillIcon: {
    marginRight: 6,
  },
}))

interface SpecialtyPillProps {
  label: string
}

export default function SpecialtyPill({ label }: SpecialtyPillProps) {
  const classes = useStyles({})

  return (
    <div className={classes.specialtyPillContainer}>
      <img
        className={classes.specialtyPillIcon}
        src={HealthCategoryIcon}
        alt="specialty"
      />
      <BodyText size="sm" weight="semibold">
        {label}
      </BodyText>
    </div>
  )
}
