import { useEffect, useMemo, useState } from "react"
import { useDispatch } from "react-redux"

import _ from "lodash"

import { DropdownSelectField } from "app/components/forms/DropdownSelectField"
import { STATES_WITH_ORDERING_RIGHTS } from "app/constants"

import {
  GENERAL_BLOCKED_SHIPPINGS_STATES,
  US_STATES,
} from "../patient-checkout/utils/usStates"
import useCheckoutDraft from "./hooks/use-checkout-draft"
import { getOrder } from "./store/actions"

const STATE_MAPPING = _.invert(
  _.omit(US_STATES, GENERAL_BLOCKED_SHIPPINGS_STATES)
)

export function StateSelect({ orderId }: { orderId: string }) {
  const { pending, values, updateValue } = useCheckoutDraft()
  const [stateChanged, setStateChanged] = useState(false)
  const dispatch = useDispatch()

  function handleShippingStateChange(event, newValue) {
    setStateChanged(true)
    if (newValue === null) {
      updateValue("shipping_state", "")
    } else {
      updateValue("shipping_state", STATE_MAPPING[newValue])
    }
  }

  useEffect(() => {
    if (pending || !stateChanged) return

    // Once state change is complete get updated ordered tests
    dispatch(getOrder(orderId, { include: ["line_items"] }))
    setStateChanged(false)
  }, [pending])

  const disabledOptions = useMemo(() => {
    return Boolean(values.bank_account) || Boolean(values.payment_card)
      ? STATES_WITH_ORDERING_RIGHTS.map((stateAbbrev) => US_STATES[stateAbbrev])
      : []
  }, [values.bank_account, values.payment_card])

  return (
    <div>
      <DropdownSelectField
        options={Object.keys(STATE_MAPPING)}
        value={_.findKey(STATE_MAPPING, (val) => val === values.shipping_state)}
        disabledOptions={disabledOptions}
        disabledMessage={
          "You cannot use practitioner pay for orders with patients based in NY, NJ or RI."
        }
        onChange={handleShippingStateChange}
        placeholder="Patient state..."
        id="checkout-state-select"
      />
    </div>
  )
}
