import { useMemo } from "react"

import { ClinicSettings } from "app/main/settings/RupaBloodDashboards/constants"
import {
  PatientPortalPatient,
  PatientPortalPractitioner,
  PatientPortalClinic,
  PatientPortalLabCompany,
} from "app/patient-portal/types"
import { BiomarkerCustomDescription } from "types/biomarker-custom-description"
import { BloodReportResult } from "types/blood-report-result"
import { Clinic } from "types/clinic"
import { LabCompany } from "types/lab-company"
import { OrderedResult } from "types/ordered-result"
import { Patient } from "types/patient"
import { Practitioner } from "types/practitioner"
import { UserResult } from "types/user-result"

const useBundleResultsPdfPayload = ({
  outOfRangeBloodReportResults,
  bloodReportResultsByLabTest,
  userResults,
  orderedResult,
  labCompany,
  patient,
  practitioner,
  clinic,
  usesClinicOptimalRange,
  biomarkerCustomDescriptionsMapping,
  snapshotId,
  isLoading,
}: {
  outOfRangeBloodReportResults: BloodReportResult[]
  bloodReportResultsByLabTest: {
    labTestName: string
    bloodReportResults: BloodReportResult[]
  }[]
  userResults?: UserResult[]
  orderedResult?: OrderedResult
  labCompany?: LabCompany | PatientPortalLabCompany
  patient?: Patient | PatientPortalPatient
  practitioner?: Practitioner | PatientPortalPractitioner
  clinic?: Clinic | PatientPortalClinic
  usesClinicOptimalRange?: boolean
  biomarkerCustomDescriptionsMapping: Record<string, BiomarkerCustomDescription>
  snapshotId?: string
  isLoading?: boolean
}) => {
  const userResult = userResults?.[0]
  const sampleCollectionDate = userResult?.attributes?.collection_date
    ? userResult?.attributes?.collection_date
    : orderedResult?.attributes.date_samples_observed

  const isFasted = userResult?.attributes.fasting_required
    ? userResult?.attributes.fasting_required
    : orderedResult?.attributes.requires_fasting

  const labCompanyName = userResult?.attributes?.other_lab_company_name
    ? userResult?.attributes?.other_lab_company_name
    : labCompany?.attributes.name

  const optimalRangesSettingTurnedOn =
    clinic?.attributes.clinic_settings.includes(
      ClinicSettings.USE_OPTIMAL_RANGES_FOR_BLOOD_REPORTS
    )

  const rangesByText = useMemo(() => {
    if (!optimalRangesSettingTurnedOn) {
      return ""
    }
    if (
      !clinic ||
      (biomarkerCustomDescriptionsMapping &&
        Object.keys(biomarkerCustomDescriptionsMapping).length === 0)
    ) {
      return "Rupa Health"
    }
    return clinic?.attributes.name ?? "Clinic"
  }, [biomarkerCustomDescriptionsMapping, clinic])

  const descriptionsByText = useMemo(() => {
    if (!clinic || !usesClinicOptimalRange) {
      return "Rupa Health"
    }
    return clinic?.attributes.name ?? "Clinic"
  }, [usesClinicOptimalRange, clinic, optimalRangesSettingTurnedOn])

  return {
    outOfRangeBloodReportResults,
    bloodReportResultsByLabTest,
    sampleCollectionDate,
    isFasted,
    labCompanyName,
    patient,
    practitioner,
    clinic,
    descriptionsByText,
    rangesByText,
    biomarkerCustomDescriptionsMapping,
    snapshotId,
    isLoading,
  }
}

export interface ResultsPdfPayload {
  outOfRangeBloodReportResults: BloodReportResult[]

  bloodReportResultsByLabTest: {
    labTestName: string
    bloodReportResults: BloodReportResult[]
  }[]
  sampleCollectionDate?: string | null
  isFasted?: boolean
  labCompanyName?: string
  patient?: Patient | PatientPortalPatient
  practitioner?: Practitioner | PatientPortalPractitioner
  clinic?: Clinic | PatientPortalClinic
  descriptionsByText: string
  rangesByText: string
  biomarkerCustomDescriptionsMapping: Record<string, BiomarkerCustomDescription>
  snapshotId?: string
  isLoading?: boolean
}

export default useBundleResultsPdfPayload
