import { colors, textPrimaryColor, titleFontFamilyImportant } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"

const useStyles = makeAppStyles((theme) => ({
  titleInput: {
    padding: theme.spacing(1.0, 2.0, 0.5, 2.0),
    color: textPrimaryColor,
    fontFamily: titleFontFamilyImportant,
    fontSize: 19,
    fontWeight: 400,
    backgroundColor: colors.blueGray[100],
    borderRadius: 6,
  },
}))

export default function TitleTextInput({
  onChange,
  value,
}: {
  onChange: (value: string) => void
  value: string
}) {
  const classes = useStyles()
  return (
    <input
      className={classes.titleInput}
      defaultValue={value}
      onChange={(e) => onChange(e.target.value)}
      type="text"
      autoComplete="off"
      autoCorrect="off"
      autoCapitalize="off"
      spellCheck="false"
    />
  )
}
