import useEventCallback from "app/hooks/use-event-callback"
import useHandleApiError from "app/hooks/use-handle-api-error"
import useCachedCollection from "app/swr/hooks/use-cached-collection"
import useCollectionSWR from "app/swr/hooks/use-collection-swr"
import useMutateResource from "app/swr/hooks/use-mutate-resource"
import { ResourceCollection, ResourceResponse } from "app/swr/types"
import resourceRequest from "app/swr/utils/resource-request"
import setIn from "app/utils/set-in"
import { Practitioner, PractitionerIdentifier } from "types/practitioner"
import { User } from "types/user"

/**
 * Hook for updating the point of contact of a given practitioner.
 * @param practitioner The practitioner to update
 * @returns A callback that takes a new poc and updates the practitioner
 */
export function useUpdatePractitionerPoc(practitioner: Practitioner) {
  const mutateResource = useMutateResource()
  const handleApiError = useHandleApiError()
  return useEventCallback(async (updPoc: PractitionerIdentifier | null) => {
    const sendPatchRequest = async () => {
      try {
        const { data } = await resourceRequest<ResourceResponse<Practitioner>>({
          method: "patch",
          url: `/practitioners/${practitioner.id}/`,
          data: {
            data: {
              id: practitioner.id,
              type: practitioner.type,
              relationships: {
                point_of_contact: {
                  data: updPoc,
                },
              },
            },
          },
        })
        return data
      } catch (error) {
        handleApiError(error)

        // re-throw
        throw error
      }
    }

    return mutateResource<Practitioner>(practitioner, sendPatchRequest, {
      optimisticData(currentData) {
        if (!currentData) {
          // should never be reached, but type error without
          return undefined as any
        }
        return setIn(currentData, "relationships.point_of_contact.data", updPoc)
      },
      rollbackOnError: true,
    })
  })
}

/**
 * Fetches resources for configuring notification settings
 * @returns all practitioners of the current users' clinic, and the user objects for those practitioners
 */
export function useNotificationsSettingsResource() {
  const {
    data: practitionerResourceIds,
    error,
    isLoading: isPracListLoading,
  } = useCollectionSWR<ResourceCollection<PractitionerIdentifier>>(
    `/practitioners`,
    {
      include: ["clinic", "user", "point_of_contact"],
      params: {
        "page[limit]": "200",
      },
    }
  )

  const allPractitioners = useCachedCollection<Practitioner>(
    practitionerResourceIds
  )
  const nonClinicStaff =
    allPractitioners?.length > 0
      ? allPractitioners.filter(
          (prac) => prac && prac.attributes.is_clinic_staff === false
        ) // useCachedCollection sometimes returns [undefined]
      : []

  const practitionerUsers = useCachedCollection<User>(
    allPractitioners.map((prac) => prac.relationships.user.data)
  )

  type ErrorResult = {
    ready: false
    error: NonNullable<typeof error>
    data: null
    allPractitioners: null
    nonClinicStaff: null
    practitionerUsers: null
  }

  type NotReadyResult = {
    ready: false
    isLoading: boolean
    error: null
    allPractitioners: null
    nonClinicStaff: null
    practitionerUsers: null
  }

  type ReadyResult = {
    ready: true
    error: null
    allPractitioners: NonNullable<typeof allPractitioners>
    nonClinicStaff: NonNullable<typeof nonClinicStaff>
    practitionerUsers: NonNullable<typeof practitionerUsers>
  }

  if (error) {
    return {
      ready: false,
      error,
      data: null,
      allPractitioners: null,
      nonClinicStaff: null,
      practitionerUsers: null,
    } as ErrorResult
  }

  if (!allPractitioners || isPracListLoading) {
    return {
      ready: false,
      isLoading: isPracListLoading,
      error: null,
      data: null,
      allPractitioners: null,
      nonClinicStaff: null,
      practitionerUsers: null,
    } as NotReadyResult
  }

  return {
    ready: true,
    error: null,
    allPractitioners,
    nonClinicStaff,
    practitionerUsers,
  } as ReadyResult
}
