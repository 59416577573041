import { CheckoutDraftState } from "app/types"

export const CHECKOUT_DRAFT_UPDATE = "[CHECKOUT DRAFT] UPDATE"
export const CHECKOUT_DRAFT_RESET = "[CHECKOUT DRAFT] RESET"
export const CHECKOUT_DRAFT_REQUEST_PENDING = "[CHECKOUT DRAFT] REQUEST_PENDING"
export const CHECKOUT_DRAFT_REQUEST_SUCCESS = "[CHECKOUT DRAFT] REQUEST_SUCCESS"
export const CHECKOUT_DRAFT_REQUEST_FAILURE = "[CHECKOUT DRAFT] REQUEST_FAILURE"

export interface CheckoutDraftAction<T = string> {
  type: T
}

export interface CheckoutDraftUpdateAction
  extends CheckoutDraftAction<typeof CHECKOUT_DRAFT_UPDATE> {
  payload: CheckoutDraftState["values"]
}

export function updateValuesAction(
  update: CheckoutDraftState["values"]
): CheckoutDraftUpdateAction {
  return {
    type: CHECKOUT_DRAFT_UPDATE,
    payload: update,
  }
}

export interface CheckoutDraftResetAction
  extends CheckoutDraftAction<typeof CHECKOUT_DRAFT_RESET> {
  payload: CheckoutDraftState["values"]
}

export function resetValuesAction(
  values: CheckoutDraftState["values"]
): CheckoutDraftResetAction {
  return {
    type: CHECKOUT_DRAFT_RESET,
    payload: values,
  }
}

export type CheckoutDraftRequestPendingAction = CheckoutDraftAction<
  typeof CHECKOUT_DRAFT_REQUEST_PENDING
>

export function requestPendingAction(): CheckoutDraftRequestPendingAction {
  return {
    type: CHECKOUT_DRAFT_REQUEST_PENDING,
  }
}

export type CheckoutDraftRequestSuccessAction = CheckoutDraftAction<
  typeof CHECKOUT_DRAFT_REQUEST_SUCCESS
>

export function requestSuccessAction(): CheckoutDraftRequestSuccessAction {
  return {
    type: CHECKOUT_DRAFT_REQUEST_SUCCESS,
  }
}

export interface CheckoutDraftRequestFailureAction
  extends CheckoutDraftAction<typeof CHECKOUT_DRAFT_REQUEST_FAILURE> {
  payload: Error
}

export function requestFailureAction(
  error: Error
): CheckoutDraftRequestFailureAction {
  return {
    type: CHECKOUT_DRAFT_REQUEST_FAILURE,
    payload: error,
  }
}
