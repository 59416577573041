import { Fragment, useMemo } from "react"

import { faChevronDown } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import * as SelectPrimitive from "@radix-ui/react-select"
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
} from "@rupahealth/design"

import groupBy from "lodash/groupBy"

import { useLabOrderingAccess } from "./LabOrderingAccessDataProvider"

interface Props {}

const LabOrderingAccessSigningPractitionerSelect: React.FC<Props> = () => {
  const {
    practitionerOptions,
    selectedPractitioner,
    setSelectedPractitionerId,
  } = useLabOrderingAccess()

  const groupedOptions = useMemo(() => {
    return Object.values(groupBy(practitionerOptions, (o) => o.group))
  }, [practitionerOptions])

  return (
    <div className="flex flex-wrap items-center">
      <div className="font-semibold font-sans mr-2">View access as:</div>
      <Select
        variant="default"
        onValueChange={setSelectedPractitionerId}
        value={selectedPractitioner?.id}
      >
        <SelectPrimitive.Trigger className="flex flex-nowrap items-center justify-between font-semibold font-sans text-primary">
          <SelectPrimitive.Value>
            <span className="mr-1">
              {selectedPractitioner?.titled_full_name}
            </span>
            <FontAwesomeIcon className="text-[17px]" icon={faChevronDown} />
          </SelectPrimitive.Value>
        </SelectPrimitive.Trigger>
        <SelectContent className="z-popper fixed mx-3" position="item-aligned">
          {groupedOptions.map((options, idx) => (
            <Fragment key={`signing-prac-select-group-${idx}`}>
              <SelectGroup>
                {options.map(({ id, label }) => (
                  <SelectItem
                    key={`group-${idx}-${id}`}
                    value={id}
                    className="cursor-pointer"
                  >
                    {label}
                  </SelectItem>
                ))}
              </SelectGroup>
              {idx < groupedOptions.length - 1 && (
                <hr className="h-[1px] bg-slate-200" />
              )}
            </Fragment>
          ))}
        </SelectContent>
      </Select>
    </div>
  )
}

export default LabOrderingAccessSigningPractitionerSelect
