import { useController, useFormContext } from "react-hook-form"

import CheckboxInput, { CheckboxInputProps } from "./CheckboxInput"

export type ControlledCheckboxInputProps = Omit<
  CheckboxInputProps,
  "defaultValue" | "error" | "helperText" | "onBlur" | "onChange" | "value"
> & {
  defaultValue?: boolean
  name: string
  required?: boolean
  shouldUnregister?: boolean
}

const ControlledCheckboxInput = ({
  defaultValue,
  name,
  required,
  shouldUnregister,
  ...checkboxInputProps
}: ControlledCheckboxInputProps) => {
  const { control } = useFormContext()
  const {
    field: { ref, ...controlProps },
    fieldState: { error },
  } = useController({
    control,
    defaultValue,
    name: `${name}` as const,
    shouldUnregister,
  })
  return (
    <CheckboxInput
      error={Boolean(error)}
      helperText={error?.message}
      inputRef={ref}
      required={required}
      {...checkboxInputProps}
      {...controlProps}
    />
  )
}

export default ControlledCheckboxInput
