import { useEffect, useMemo, useState } from "react"

import { format, getDaysInMonth } from "date-fns"
import { range, reverse } from "lodash"

import { KeyboardDatePickerProps } from "@material-ui/pickers"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@rupahealth/design"

export type DateInputProps = Omit<
  KeyboardDatePickerProps,
  "inputVariant" | "value"
> & {
  value: string | number | Date
}

/*
 * A date component comprised of a select field for year, month, and day.
 *
 * The year provides options for the past 120 years.
 */
const DateInput = ({
  InputProps,
  className,
  value,
  required,
  onChange,
  ...inputProps
}: DateInputProps) => {
  const initialDate = useMemo(() => {
    if (!value) {
      return null
    }

    return new Date(value)
  }, [value])

  const [localDay, setLocalDay] = useState<string | undefined>()
  const [localMonth, setLocalMonth] = useState<string | undefined>()
  const [localYear, setLocalYear] = useState<string | undefined>()

  useEffect(() => {
    if (initialDate) {
      setLocalDay(initialDate.getDate().toString())
      setLocalMonth(initialDate.getMonth().toString())
      setLocalYear(initialDate.getFullYear().toString())
    }
  }, [initialDate])

  // Generate the options for each select.
  const { days, months, years } = useMemo(() => {
    const now = new Date()
    const thisYear = now.getFullYear()

    // Months are formatted in their long name format, with
    // the number (0, 11), given as the value.
    const months = range(0, 12).map((month) => ({
      label: format(new Date(2000, month, 1), "MMMM"),
      value: month.toString(),
    }))

    // Years are rendered for the past 120 years.
    const years = reverse(range(thisYear - 120, thisYear + 1)).map((year) => ({
      label: year.toString(),
      value: year.toString(),
    }))

    const days = localMonth
      ? range(
          1,
          // create placeholder date with the correct month to get the number of days in the month
          getDaysInMonth(new Date(2000, parseInt(localMonth), 1)) + 1
        ).map((day) => ({
          label: day.toString(),
          value: day.toString(),
        }))
      : []

    return {
      days,
      months,
      years,
    }
  }, [localMonth])

  useEffect(() => {
    // Only update the date if all three values are set.
    if (localDay && localMonth && localYear) {
      onChange(
        new Date(parseInt(localYear), parseInt(localMonth), parseInt(localDay))
      )
    }
  }, [localDay, localMonth, localYear])

  // Build props for each select input.
  const selects = useMemo(() => {
    return [
      {
        label: "Month",
        value: localMonth,
        onChangeValue: (month: string) => {
          if (month) {
            setLocalMonth(month)
          }
        },
        items: months,
      },
      {
        label: "Day",
        value: localDay,
        onChangeValue: (day: string) => {
          if (day) {
            setLocalDay(day)
          }
        },
        items: days,
      },
      {
        label: "Year",
        value: localYear,
        onChangeValue: (year: string) => {
          if (year) {
            setLocalYear(year)
          }
        },
        items: years,
      },
    ]
  }, [days, months, years, localDay, localMonth, localYear])

  return (
    <div className="flex gap-2 z-popper">
      {selects.map(({ label, value, onChangeValue, items }) => (
        <Select
          key={label}
          value={value}
          onValueChange={onChangeValue}
          required={required}
        >
          <SelectTrigger className="text-body" aria-label={label}>
            <SelectValue placeholder={label} />
          </SelectTrigger>
          <SelectContent className="z-popper">
            {items.map((item) => (
              <SelectItem value={item.value.toString()} key={item.value}>
                {item.label}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      ))}
    </div>
  )
}

export default DateInput
