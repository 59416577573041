import { useEffect, useMemo, useState } from "react"

import { makeStyles } from "@material-ui/core/styles"

import Button from "app/components/design-system/Button"
import { buildLoginUrl } from "app/patient-portal/util"
import { PatientSignupError, PatientSignupErrors } from "app/types"

const useStyles = makeStyles((theme) => ({
  container: {
    borderRadius: 6,
    border: `1px solid #F3C85E`,
    backgroundColor: "#FDF6DE",
    padding: 10,
    width: "100%",
    marginBottom: 10,
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "center",
  },
  message: {
    color: "#6A411C",
    fontSize: 15,
    fontWeight: 600,
  },
  linkButton: {
    "&.MuiButton-root": {
      minWidth: 40,
      padding: "0 4px",
    },
    textAlign: "center",
  },
}))

export default function PatientSignupErrorComponent({
  signupError,
  email,
}: {
  signupError: PatientSignupError
  email: string
}) {
  const classes = useStyles()

  const [message, setMessage] = useState("")
  const [loginLink, setLoginLink] = useState<string | null>(null)

  const next = useMemo(() => {
    const query = new URLSearchParams(window.location.search)
    return query.get("next") ?? undefined
  }, [window.location.search])

  useEffect(() => {
    if (signupError.code === PatientSignupErrors.EMAIL_IN_USE) {
      setMessage(
        "This email is already in use, but has no patients or practitioners associated with it. Please reach out to support if you would like to use this email."
      )
      setLoginLink(null)
    } else if (
      signupError.code === PatientSignupErrors.EMAIL_IN_USE_BY_PATIENT
    ) {
      setMessage("An account already exists for this email!")
      setLoginLink(buildLoginUrl("patient", email, next))
    } else if (
      signupError.code === PatientSignupErrors.EMAIL_IN_USE_BY_PRACTITIONER
    ) {
      setMessage(
        "An account for this email address as a practitioner already exists! To access the patient portal, start an order for this email in your practitioner portal."
      )
      setLoginLink(buildLoginUrl("practitioner", email, next))
    } else if (signupError.code === PatientSignupErrors.INVALID_PASSWORD) {
      setMessage(`Invalid password: ${signupError.errors[0]}`)
      setLoginLink(null)
    } else if (signupError.code === PatientSignupErrors.INVALID_EMAIL) {
      setMessage("Invalid email!")
      setLoginLink(null)
    } else if (signupError.code === PatientSignupErrors.MISSING_FIELD) {
      setMessage(`Missing ${signupError.errors[0]}!`)
      setLoginLink(null)
    } else {
      setMessage("Unknown error! Please reach out to support.")
      setLoginLink(null)
    }
  }, [signupError, email, next])

  return (
    <div className={classes.container}>
      <p className={classes.message}>{message}</p>
      {loginLink && (
        <Button color="text" href={loginLink} className={classes.linkButton}>
          Log in here
        </Button>
      )}
    </div>
  )
}
