import * as React from "react"

import { GenerateId } from "jss"

import {
  StylesProvider,
  createGenerateClassName,
  ThemeProvider,
  createMuiTheme,
} from "@material-ui/core/styles"

import theme from "app/theme"

const defaultGenerateClassName = createGenerateClassName()
const muiTheme = createMuiTheme(theme)

export interface MaterialUIProvidersProps {
  children?: React.ReactNode
  generateClassName?: GenerateId
}

const MaterialUIProviders = ({
  children,
  generateClassName = defaultGenerateClassName,
}: MaterialUIProvidersProps) => (
  <StylesProvider generateClassName={generateClassName} injectFirst>
    <ThemeProvider theme={muiTheme}>{children}</ThemeProvider>
  </StylesProvider>
)

export default MaterialUIProviders
