import React, { useEffect, useState } from "react"

import NiceModal, { muiDialog, useModal } from "@ebay/nice-modal-react"
import {
  CircularProgress,
  FadeProps,
  Grow,
  IconButton,
  Link,
} from "@material-ui/core"
import MuiDialogContent from "@material-ui/core/DialogContent"
import MuiDialogTitle from "@material-ui/core/DialogTitle"
import shadows from "@material-ui/core/styles/shadows"
import CloseIcon from "@material-ui/icons/Close"
import { cn } from "@rupahealth/design"

import { ReactComponent as LabReportIcon } from "app/assets/images/results-over-time/lab-report.svg"
import { ReactComponent as ResultFileIcon } from "app/assets/images/results-over-time/result-file.svg"
import BetaChip from "app/components/design-system/BetaChip"
import BodyText from "app/components/design-system/BodyText"
import { Dialog } from "app/components/modals"
import useConfirmationModal from "app/components/modals/generic/hooks/use-confirmation-modal"
import ResultsUploadManualEntryLoadingState from "app/main/results-upload/modals/manual-upload/ResultsUploadManualEntryLoadingState"
import { colors } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"

import useCreateUserResult from "../hooks/use-create-user-result"
import UserResultProvider, {
  useUserResultContext,
} from "../providers/UserResultDataProvider"
import ContentCard from "./components/ContentCard"
import UserResultEditableFormContainer from "./components/UserResultEditableFormContainer"
import UserResultReadOnlyFormContainer from "./components/UserResultReadOnlyFormContainer"
import ResultsUploadPdfAllSetCard from "./pdf-upload/ResultsUploadPdfAllSetCard"

interface Props {
  patientId: string
  existingUserResultId?: string
  uploadedFile?: File
  uploadedFileUrl?: string
  uploadedFileName?: string
  isReadOnly?: boolean
  onClose: () => void
}
// TODO: PROD-2332 remove this file

/**
 * Wrapper for the modal that provides the data context for the user result
 */
const ResultsUploadManualEntryModal = ({
  existingUserResultId,
  isReadOnly,
  ...innerProps
}: Props) => {
  const [userResultId, setUserResultId] = useState(existingUserResultId)

  return (
    <UserResultProvider userResultId={userResultId}>
      <ResultsUploadManualEntryModalInner
        {...innerProps}
        userResultId={userResultId}
        setUserResultId={setUserResultId}
        isReadOnly={isReadOnly}
      />
    </UserResultProvider>
  )
}

interface InnerProps extends Omit<Props, "existingUserResultId"> {
  userResultId: string | undefined
  isReadOnly?: boolean
  setUserResultId(userResultId: string | undefined): void
}

const GrowTransition = React.forwardRef<unknown, FadeProps>(function Transition(
  props,
  ref
) {
  return <Grow ref={ref} {...props} timeout={500} />
})

const useStyles = makeAppStyles<{ showAllSetCard: boolean }>(() => ({
  dialogPaper: {
    margin: 15,
    borderRadius: 12,
    border: "4px solid white",
    boxShadow: shadows["2xl"],
    width: (props) => (props.showAllSetCard ? 580 : "100%"),
    transition: "all 500ms",
  },
}))

const ResultsUploadManualEntryModalInner = ({
  patientId,
  uploadedFile,
  uploadedFileUrl,
  uploadedFileName,
  userResultId,
  isReadOnly,
  setUserResultId,
  onClose,
}: InnerProps) => {
  const [showAllSetCard, setShowAllSetCard] = useState(false)

  const classes = useStyles({ showAllSetCard })

  const modal = useModal()
  const muiDialogControl = muiDialog(modal)

  const {
    userResult,
    userResultSWR: {
      isLoading: isUserResultLoading,
      isValidating: isUserResultValidating,
      error: userResultError,
    },
    userBiomarkerResultsSWR: {
      isLoading: isUserBiomarkerResultsLoading,
      isValidating: isUserBiomarkerResultsValidating,
      error: userBiomarkerResultError,
    },
  } = useUserResultContext()

  const { createUserResult, isCreating } = useCreateUserResult()

  const confirmationModal = useConfirmationModal()

  const handleSnapshotCreate = () => {
    setShowAllSetCard(true)
  }

  const confirmClose = () => {
    confirmationModal.show({
      title: "Are You Sure?",
      message: (
        <BodyText>If you exit now, your results won't be saved.</BodyText>
      ),
      backButtonTitle: "Back to Review",
      confirmButtonTitle: "Exit Without Saving",
      confirmButtonColor: "destructiveSecondary",
      onClose: confirmationModal.remove,
      handleConfirm: async () => {
        onClose()
        confirmationModal.remove()
      },
      loading: false,
      maxWidth: "xs",
      fullWidthButtons: true,
    })
  }

  useEffect(() => {
    if (
      !userResultId &&
      !isUserResultLoading &&
      !isUserBiomarkerResultsLoading &&
      !userResultError
    ) {
      createUserResult({ patientId }).then(
        (newId) => newId && setUserResultId(newId)
      )
    }
  }, [userResult, isUserResultLoading, isUserBiomarkerResultsLoading])

  if (userResultError || userBiomarkerResultError) {
    return (
      <Dialog
        {...muiDialogControl}
        disableEnforceFocus
        disableEscapeKeyDown
        fullWidth
        maxWidth="md"
        className={"fs-unmask"}
        classes={{
          paper: classes.dialogPaper,
        }}
        TransitionComponent={GrowTransition}
      >
        <TitleSection
          onClose={showAllSetCard || isReadOnly ? onClose : confirmClose}
          title="Manually Entered Results"
          isValidating={false}
          isReadOnly={isReadOnly}
        />
        <MuiDialogContent className="sm:p-[22.5px] bg-gray-100 flex flex-col gap-4 p-3">
          <ContentCard>
            Oh no! Something went wrong! Please try again.
          </ContentCard>
        </MuiDialogContent>
      </Dialog>
    )
  }

  return (
    <>
      <Dialog
        {...muiDialogControl}
        disableEnforceFocus
        disableEscapeKeyDown
        fullWidth
        maxWidth={"md"}
        className={"fs-unmask"}
        classes={{
          paper: classes.dialogPaper,
        }}
        TransitionComponent={GrowTransition}
      >
        {showAllSetCard ? (
          <div className="flex justify-between mb-[18px] mt-5 mx-5">
            <span className="font-title font-semibold text-xl19">
              Import Lab Results{" "}
              <BetaChip
                backgroundColor={colors.teal[100]}
                color={colors.teal[700]}
                className="ml-1"
              />
            </span>

            <IconButton
              aria-label="close"
              onClick={onClose}
              key="close-button"
              className="text-body p-0 m-0"
            >
              <CloseIcon />
            </IconButton>
          </div>
        ) : (
          <TitleSection
            title="Manually Entered Results"
            onClose={isReadOnly ? onClose : confirmClose}
            isValidating={
              isUserResultValidating || isUserBiomarkerResultsValidating
            }
            isReadOnly={isReadOnly}
          />
        )}
        {(isUserResultLoading || isCreating) && (
          <MuiDialogContent className="sm:p-[22.5px] bg-gray-100 flex flex-col gap-4 p-3">
            <ContentCard>
              <ResultsUploadManualEntryLoadingState />
            </ContentCard>
          </MuiDialogContent>
        )}

        {showAllSetCard ? (
          <div className="px-[22.5px] pb-[22.5px]">
            <ResultsUploadPdfAllSetCard onClose={onClose} />
          </div>
        ) : (
          <div
            className={cn(
              "px-[22.5px] pb-[22.5px] bg-gray-100",
              isUserResultLoading || isCreating
                ? "opacity-0 h-0"
                : "opacity-100 h-full"
            )}
          >
            {(uploadedFile || (uploadedFileUrl && uploadedFileName)) && (
              <div className="w-full rounded-lg border border-sky-300 bg-sky-50 flex gap-[10px] mt-[22.5px] p-3 items-center">
                <ResultFileIcon />
                <BodyText weight="semibold">
                  <Link
                    className="cursor-pointer"
                    href={uploadedFileUrl}
                    target="_blank"
                  >
                    {uploadedFile ? uploadedFile["name"] : uploadedFileName}
                  </Link>
                </BodyText>
              </div>
            )}

            {!isCreating && (
              <div className="pt-5 min-h-3.5">
                {isReadOnly ? (
                  <UserResultReadOnlyFormContainer
                    userResultId={userResultId || ""}
                  />
                ) : (
                  <UserResultEditableFormContainer
                    userResultId={userResultId || ""}
                    onSnapshotCreate={handleSnapshotCreate}
                    isManualEntry={true}
                  />
                )}
              </div>
            )}
          </div>
        )}
      </Dialog>
    </>
  )
}

const TitleSection = ({ title, onClose, isValidating, isReadOnly }) => {
  const closeButton = onClose && (
    <IconButton
      aria-label="close"
      onClick={onClose}
      key="close-button"
      className="text-body relative top-[1px] right-0 mr-[-12px]"
    >
      <CloseIcon />
    </IconButton>
  )

  return (
    <MuiDialogTitle
      disableTypography
      className="flex justify-between flex-nowrap bg-white items-start py-4 px-6"
    >
      <div className="flex gap-5 items-center">
        <div className="w-[73px]">
          <LabReportIcon />
        </div>

        <div className="flex flex-col gap-1">
          <span className="flex items-center font-title font-semibold text-xl19">
            {title}
            {isValidating && <CircularProgress size={16} className="ml-2" />}
          </span>
          {!isReadOnly && (
            <BodyText>
              You'll always be able to edit these later in Documents.
            </BodyText>
          )}
        </div>
      </div>
      {closeButton}
    </MuiDialogTitle>
  )
}

const ResultsUploadManualEntryNiceModal = NiceModal.create<Props>(
  ResultsUploadManualEntryModal
)

export default function useResultsUploadManualEntryModal() {
  return useModal(ResultsUploadManualEntryNiceModal)
}
