import { useEffect, useMemo, useState } from "react"

import Plyr from "plyr-react"

import { Theme, useMediaQuery, makeStyles } from "@material-ui/core"

import BodyText from "app/components/design-system/BodyText"
import useFeatureFlag, { FeatureFlag } from "app/hooks/use-feature-flag"
import {
  OrderIntent,
  StorefrontSectionType,
  StorefrontType,
  useResourceSelector,
} from "app/hooks/use-resource"
import { colors, rupaTeal } from "app/theme"
import "plyr-react/dist/plyr.css"

import { CardOptions } from "../PublicStorefront"
import HowItWorksCard from "./HowItWorksCard"
import HowItWorksCardMobile from "./HowItWorksCardMobile"
import StorefrontFAQ from "./StorefrontFAQ"
import StorefrontSection from "./StorefrontSection"

enum VideoProvider {
  youtube = "youtube",
  vimeo = "vimeo",
}

interface EmbedInfo {
  videoId: string
  videoProvider: VideoProvider | undefined
}

const styles = (theme: Theme) => ({
  loaderContainer: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  container: {
    display: "flex",
    "flex-direction": "column",
  },
  contentWrapper: {
    display: "flex",
    "flex-direction": "column",
    width: "100%",
    maxWidth: 1150,
  },
  heroContainer: {
    background: "white",
    "flex-direction": "column",
    alignItems: "center",
    width: "100%",
    display: "flex",
    // 27px matches the transparentCard padding in StorefrontSection
    padding: "40px 27px",
  },
  heroContent: {
    width: "100%",
    padding: "0 27px",
    display: "flex",
    justifyContent: "space-between",
    "flex-direction": "row",
    "flex-wrap": "nowrap",
    gap: 50,
    [theme.breakpoints.down("sm")]: {
      "flex-wrap": "wrap",
      "flex-direction": "column",
      alignItems: "center",
      gap: 0,
    },
  },
  storefrontLogo: {
    maxHeight: "215px",
  },
  heroTitle: {
    fontSize: 30,
    display: "block",
    marginTop: 20,
  },
  heroDescription: {
    marginTop: 6,
  },
  howItWorksContainer: {
    display: "flex" as any,
    flexDirection: "column" as any,
    alignItems: "center",
    width: "100%",
    background: "white",
    borderTop: `1px solid ${colors.blueGray[200]}`,
    borderBottom: `1px solid ${colors.blueGray[200]}`,
    padding: "55px 0",
  },
  productsContainer: {
    background: colors.blueGray[50],
    display: "flex" as any,
    flexDirection: "column" as any,
    alignItems: "center",
    width: "100%",
    padding: "40px 0",
  },
  faqContainer: {
    background: "white",
    display: "flex" as any,
    flexDirection: "column" as any,
    alignItems: "center",
    width: "100%",
    paddingBottom: 25,
    marginBottom: -25,
  },
  storefrontContentFull: {
    justifyContent: "center",
    width: "100%",
    "text-align": "left",
  },
  storefrontContent: {
    flex: 1,
    display: "flex",
    "flex-direction": "column",
    justifyContent: "center",
    "text-align": "left",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      display: "flex",
      "flex-direction": "column",
      alignItems: "center",
      "text-align": "center",
    },
  },
  videoContainer: {
    flex: 1,
    maxWidth: 550,
    width: 550,
    margin: "auto",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
      width: "100%",
      marginTop: 25,
    },
    "--plyr-color-main": "white",
    "--plyr-video-control-color": rupaTeal,
    "--plyr-video-control-color-hover": rupaTeal,
    "--plyr-video-control-background-hover": "white",
    "& > .plyr": {
      borderRadius: 16,
    },
    "& > .plyr.plyr--stopped .plyr__controls": { display: "none" },
  },
  sectionsContainer: {
    display: "flex",
    "flex-direction": "column",
    "justify-content": "center",
    "align-items": "center",
    gap: 55,
  },
  richtextContainer: {
    // any "p" tags should have a min height of 15px
    "& p": {
      minHeight: 15,
    },
  },
})

const useStyles = makeStyles(styles)

interface Props {
  storefront: StorefrontType
  cart: OrderIntent | null
  handleAddRemoveCart: (
    id: string,
    type: CardOptions,
    requestBody: any,
    alreadyAdded: boolean
  ) => void
}

const PublicStorefrontListProducts = ({
  storefront,
  cart,
  handleAddRemoveCart,
}: Props) => {
  const classes = useStyles()

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"))

  const [hidePhysServicesPricing] = useFeatureFlag(
    FeatureFlag.HidePhysicianServicesPricing
  )
  const [practitionerLicenseFlagEnabled] = useFeatureFlag(
    FeatureFlag.LabshopsUsePractitionerLicense
  )
  const usePractitionerLicense = Boolean(
    practitionerLicenseFlagEnabled &&
      storefront?.attributes.use_practitioner_license
  )

  const [videoId, setVideoId] = useState("")
  const [videoProvider, setVideoProvider] = useState<VideoProvider | undefined>(
    undefined
  )

  const sections: (StorefrontSectionType | undefined)[] = useResourceSelector(
    (entities) => {
      if (!storefront?.relationships.storefront_sections) {
        return []
      }

      return storefront?.relationships?.storefront_sections.data.map((ref) => {
        return entities?.storefront_section?.[ref.id]
      })
    }
  )

  const sortedSections = useMemo(() => {
    const transparentSection = sections.find(
      (section) => section?.attributes?.is_transparent
    )
    const sortedSections = sections
      .filter(
        (section) =>
          section !== undefined && !section.attributes?.is_transparent
      )
      // 1, 2, 3 etc
      .sort(
        (sectionA, sectionB) =>
          (sectionA?.attributes?.sort_order ?? 0) -
          (sectionB?.attributes?.sort_order ?? 0)
      )
    return [...sortedSections, transparentSection]
  }, [sections])

  const onAddRemoveCart = async (type: CardOptions, id: string) => {
    const cartItemType = "storefront_product"
    const existingRelationships =
      cart?.relationships?.storefront_products?.data ||
      new Array<{ id: string; type: string }>()

    const alreadyAdded = existingRelationships.some(
      (data) => data.id === id && data.type === cartItemType
    )

    // If the resource is all ready added, filter it out, otherwise add to the
    // existing relationship
    const newRelationship = alreadyAdded
      ? existingRelationships.filter(
          (data) => data.id !== id && data.type === cartItemType
        )
      : [...existingRelationships, { id, type: cartItemType }]

    const requestBody = {
      data: {
        id: cart?.id,
        type: "order_intent",
        ...cart,
        relationships: {
          ...cart?.relationships,
          storefront_products: { data: newRelationship },
        },
      },
    }

    handleAddRemoveCart(id, type, requestBody, alreadyAdded)
  }

  const getVideoEmbedInfo = (url: string): EmbedInfo => {
    try {
      if (url.includes("youtube.com")) {
        if (url.includes("embed")) {
          return {
            videoId: url.split("embed/")[1],
            videoProvider: VideoProvider.youtube,
          }
        } else {
          let videoId = url.split("v=")[1]
          const ampersandPosition = videoId.indexOf("&")
          if (ampersandPosition !== -1) {
            videoId = videoId.substring(0, ampersandPosition)
          }

          return {
            videoId,
            videoProvider: VideoProvider.youtube,
          }
        }
      } else if (url.includes("youtu.be")) {
        return {
          videoId: url.split("youtu.be/")[1],
          videoProvider: VideoProvider.youtube,
        }
      } else if (url.includes("vimeo.com")) {
        if (url.includes("video/")) {
          return {
            videoId: url.split("video/")[1],
            videoProvider: VideoProvider.vimeo,
          }
        } else {
          // All vimeo links have the first series of numbers as the video id
          const regex = /(\d+)/s
          const videoIdMatches = url.match(regex)

          if (videoIdMatches) {
            return {
              videoId: videoIdMatches[0],
              videoProvider: VideoProvider.vimeo,
            }
          }
        }
      }
    } catch {
      return { videoId: "", videoProvider: undefined }
    }

    return { videoId: "", videoProvider: undefined }
  }

  const renderVideo = useMemo(
    () => (
      <div className={classes.videoContainer} data-testid="video-container">
        <Plyr
          style={{ borderRadius: 16 }}
          source={{
            type: "video",
            sources: [
              {
                src: videoId,
                provider: videoProvider,
              },
            ],
          }}
          options={{ hideControls: true }}
        />
      </div>
    ),
    [videoId, videoProvider]
  )

  useEffect(() => {
    if (storefront.attributes.video_link && !videoId && !videoProvider) {
      const { videoId, videoProvider } = getVideoEmbedInfo(
        storefront.attributes.video_link
      )
      setVideoId(videoId)
      setVideoProvider(videoProvider)
    }
  }, [])

  return (
    <div className={classes.container}>
      <div className={classes.heroContainer}>
        <div className={classes.contentWrapper}>
          <div className={classes.heroContent}>
            <div
              className={
                storefront.attributes.video_link
                  ? classes.storefrontContent
                  : classes.storefrontContentFull
              }
            >
              {storefront.attributes.logo && (
                <div>
                  <img
                    src={storefront.attributes.logo}
                    alt="storefront logo"
                    className={classes.storefrontLogo}
                  />
                </div>
              )}
              <BodyText variant="h1" className={classes.heroTitle}>
                {storefront.attributes.name}
              </BodyText>

              {!storefront.attributes.description_richtext_html && (
                <BodyText variant="body1" className={classes.heroDescription}>
                  {storefront.attributes.description}
                </BodyText>
              )}
              {storefront.attributes.description_richtext_html && (
                <BodyText variant="body1" className={classes.heroDescription}>
                  <div
                    className={classes.richtextContainer}
                    dangerouslySetInnerHTML={{
                      __html: storefront.attributes.description_richtext_html,
                    }}
                  />
                </BodyText>
              )}
            </div>

            {storefront.attributes.video_link && renderVideo}
          </div>
        </div>
      </div>

      <div className={classes.productsContainer}>
        <div className={classes.contentWrapper}>
          <div className={classes.sectionsContainer}>
            {sortedSections.map(
              (section) =>
                section && (
                  <StorefrontSection
                    key={section.id}
                    section={section}
                    cart={cart}
                    onAddToCart={onAddRemoveCart}
                    labshopLogo={storefront.attributes.logo}
                    hidePhysServicesPricing={
                      hidePhysServicesPricing || usePractitionerLicense
                    }
                  />
                )
            )}
          </div>
        </div>
      </div>

      <div className={classes.howItWorksContainer}>
        <div className={classes.contentWrapper}>
          {isMobile ? <HowItWorksCardMobile /> : <HowItWorksCard />}
        </div>
      </div>

      <div className={classes.faqContainer} id="faq">
        <div className={classes.contentWrapper}>
          <StorefrontFAQ />
        </div>
      </div>
    </div>
  )
}

export default PublicStorefrontListProducts
