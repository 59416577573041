import { useState } from "react"

import { isEqual } from "lodash"

import RichTextEditor, { getInitialValue } from "app/components/RichTextEditor"
import RichTextRenderer from "app/components/RichTextRenderer"
import DisplayText from "app/components/design-system/DisplayText"
import EditorFormPlaceholder from "app/results-summary/components/ResultsSummaryEditor/components/EditorFormPlaceholder"
import TitleTextInput from "app/results-summary/components/ResultsSummaryEditor/components/TitleTextInput"
import makeAppStyles from "app/utils/makeAppStyles"
import { EditorState } from "types/editor-state"

import FormSection from "./FormSection"

const useStyles = makeAppStyles((theme) => ({
  title: {
    marginTop: 3,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
}))

export interface ContentTitleFormProps {
  addLabel: string
  content: EditorState
  showAddSnippetButton?: boolean
  isDirty?: boolean
  isPatching?: boolean
  onChangeContent: (content: EditorState) => void
  onChangeTitle: (title: string) => void
  title: string
}

export default function ContentTitleForm({
  addLabel,
  content,
  showAddSnippetButton = false,
  isDirty = false,
  isPatching = false,
  onChangeContent,
  onChangeTitle,
  title,
}: ContentTitleFormProps) {
  const classes = useStyles()
  const [isEditing, setIsEditing] = useState(false)
  const hasEmptyState = isEqual(content, getInitialValue())

  return (
    <FormSection
      disableEditButton={isEditing && (isPatching || isDirty)}
      isEditing={isEditing}
      onToggleEditing={() => setIsEditing(!isEditing)}
      showEditButton
      title={
        isEditing ? (
          <TitleTextInput onChange={onChangeTitle} value={title} />
        ) : (
          <DisplayText className={classes.title} size="lg" variant="h4">
            {title}
          </DisplayText>
        )
      }
    >
      {isEditing ? (
        <RichTextEditor
          onChange={onChangeContent}
          value={content}
          showInsertSnippetButton={showAddSnippetButton}
        />
      ) : hasEmptyState ? (
        <EditorFormPlaceholder
          addLabel={addLabel}
          onClick={() => setIsEditing(true)}
          type="content"
        />
      ) : (
        <RichTextRenderer>{content}</RichTextRenderer>
      )}
    </FormSection>
  )
}
