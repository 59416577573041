import clsx from "clsx"
import { noop } from "lodash"

import { CircularProgress } from "@material-ui/core"

import { ReactComponent as GenerateFoodPlanIcon } from "app/assets/icons/generate-food-plan.svg"
import { ReactComponent as ListIcon } from "app/assets/icons/list.svg"
import { ReactComponent as TableIcon } from "app/assets/icons/table.svg"
import Button from "app/components/design-system/Button"
import FoodPlanButtonSelectField from "app/food-plans/components/FoodPlanButtonSelectField"
import FoodPlanCard from "app/food-plans/components/FoodPlanCard"
import FoodPlanErrorMessage from "app/food-plans/components/FoodPlanErrorMessage"
import FoodPlanTextAreaField from "app/food-plans/components/FoodPlanTextAreaField"
import useFoodPlanGenerateForm, {
  FoodPlanGenerateConfigurationFormValues,
} from "app/food-plans/hooks/use-food-plan-generate-form"
import { colors, navy } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"

import FoodPlanCalorieIntakeField from "./FoodPlanCalorieIntakeField"

const useStyles = makeAppStyles((theme) => ({
  rootMuted: {
    pointerEvents: "none",
    "& > *": {
      opacity: 0.5,
    },
  },
  generateButton: {
    display: "flex",
    alignItems: "center",
    "& .MuiButton-label": {
      marginBottom: -3,
    },
    "& .MuiButton-startIcon": {
      margin: theme.spacing(0.0, 1.0, 0.5, 0.0),
    },
  },
  cancelButton: {
    background: colors.blueGray[200],
    color: navy,
    border: `1px solid ${colors.blueGray[300]}`,
    "&:hover": {
      background: colors.blueGray[300],
      color: navy,
    },
  },
}))

export interface FoodPlanGenerateConfigurationCardProps {
  defaultValues?: FoodPlanGenerateConfigurationFormValues
  errorMessage?: string
  isGenerating?: boolean
  isStartingGenerate?: boolean
  muted?: boolean
  onPatch?: (values: FoodPlanGenerateConfigurationFormValues) => Promise<void>
  onStartGenerate?: (
    values: FoodPlanGenerateConfigurationFormValues
  ) => Promise<void>
  onStartCancel?: () => Promise<void>
}

export default function FoodPlanGenerateConfigurationCard({
  defaultValues,
  errorMessage,
  isGenerating,
  isStartingGenerate,
  muted,
  onPatch,
  onStartGenerate,
  onStartCancel,
}: FoodPlanGenerateConfigurationCardProps) {
  const classes = useStyles()

  const methods = useFoodPlanGenerateForm({
    defaultValues,
    onPatch,
  })

  const isDisabled = isGenerating || isStartingGenerate || muted
  const canCancel = isStartingGenerate || isGenerating

  return (
    <form
      onSubmit={methods.handleSubmit(
        (isGenerating ? onStartCancel : onStartGenerate) || noop
      )}
    >
      <FoodPlanCard className={clsx({ [classes.rootMuted]: muted })}>
        <FoodPlanTextAreaField
          autoFocus={!muted}
          control={methods.control}
          disabled={isDisabled}
          label="Meal Frequency"
          name="meal_frequency"
          placeholder="How often should meals be? E.g. 3 meals per day with 2 snacks, intermittent fasting, only lunch and dinner, etc."
        />

        <FoodPlanTextAreaField
          control={methods.control}
          disabled={isDisabled}
          label="Diet Plan Type"
          name="food_plan_type"
          placeholder="What type of diet would you like? E.g. Low-FODMAP, Anti-inflammatory, Mediterranean, Keto, etc."
        />

        <FoodPlanTextAreaField
          control={methods.control}
          disabled={isDisabled}
          label="Preferences"
          name="preferences"
          placeholder="Personalize the plan with your client's preferences. E.g. Vegan, quick, prefers bowls, California summer produce, plant-based, etc."
        />

        <FoodPlanTextAreaField
          control={methods.control}
          disabled={isDisabled}
          label="Dietary Restrictions"
          name="dietary_restrictions"
          placeholder="Note any allergies or restrictions. E.g. Allergic to peanuts, gluten-free, dairy-free, etc."
        />

        <FoodPlanCalorieIntakeField
          control={methods.control}
          disabled={isDisabled}
        />

        <FoodPlanButtonSelectField
          control={methods.control}
          disabled={isDisabled}
          label="Layout"
          name="layout"
          options={[
            {
              icon: <TableIcon fill="currentColor" viewBox="0 0 16 12" />,
              label: "Table",
              value: "table",
            },
            {
              icon: <ListIcon fill="currentColor" viewBox="0 0 15 11" />,
              label: "List",
              value: "list",
            },
          ]}
        />

        {errorMessage && (
          <FoodPlanErrorMessage>{errorMessage}</FoodPlanErrorMessage>
        )}

        <Button
          className={clsx(classes.generateButton, [
            canCancel && classes.cancelButton,
          ])}
          color="primary"
          fullWidth
          size="medium"
          startIcon={
            isStartingGenerate || isGenerating ? (
              <CircularProgress color="inherit" size={15} />
            ) : (
              <GenerateFoodPlanIcon fill="currentColor" viewBox="0 0 15 18" />
            )
          }
          type="submit"
        >
          {isStartingGenerate || isGenerating ? "Cancel" : "Generate Food Plan"}
        </Button>
      </FoodPlanCard>
    </form>
  )
}
