import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"

import axios from "axios"
import { useHistory } from "react-router-dom"
import { useSWRConfig } from "swr"

import authService from "app/auth/services/simplejwt-auth-service"
import { clearUserData, retrieveUserData } from "app/auth/store/actions"
import { showMessage } from "app/store/actions"

/**
 * Hook that initializes the simplejwt authentication service.
 *
 * @returns whether initialization is complete
 */
export default function useSimpleJWTAuth() {
  const { mutate } = useSWRConfig()
  const history = useHistory()
  const dispatch = useDispatch()
  const [isInitialized, setIsInitialized] = useState(false)

  useEffect(() => {
    const deregisterOnAuthenticate = authService.onAuthenticate(
      async (isAuthenticated, next) => {
        if (isAuthenticated) {
          await dispatch(retrieveUserData())
        }

        if (next) {
          history.push(next)
        }

        setIsInitialized(true)
      }
    )

    const deregisterOnLogout = authService.onLogout(async (message) => {
      await dispatch(clearUserData())

      if (message) {
        dispatch(showMessage({ message }))
      }

      // Clear SWR cache on logout
      mutate(() => true, undefined, { revalidate: false })
    })

    const deregisterInterceptors = authService.attachInterceptors(axios)

    authService.authenticate()

    const clearIdleLogoutInterval = authService.registerIdleLogoutInterval()

    return () => {
      clearIdleLogoutInterval()
      deregisterOnAuthenticate()
      deregisterOnLogout()
      deregisterInterceptors()
    }
  }, [])

  return isInitialized
}
