/**
 * Retry an operation until a successful result, or the retries are exceeded.
 *
 * @param operation the function
 * @param delay the delay in ms
 * @param retries the number of retries
 */
export default async function retryOperation<T>(
  operation: () => Promise<T>,
  options: RetryOptions
) {
  return new Promise((resolve, reject) => {
    return operation()
      .then(resolve)
      .catch((reason) => {
        if (options.retries > 0) {
          return wait(options.delay)
            .then(
              retryOperation.bind(null, operation, {
                ...options,
                retries: options.retries - 1,
              })
            )
            .then(resolve)
            .catch(reject)
        }
        return reject(reason)
      })
  })
}

export interface RetryOptions {
  delay: number
  retries: number
}

async function wait(ms: number) {
  return new Promise((r) => setTimeout(r, ms))
}
