import { Button } from "@rupahealth/design"

import { ReactComponent as VideoPlayIcon } from "app/assets/icons/rupa-blood-dashboards/video-play-on-screen.svg"
import useVideoModal from "app/hooks/use-video-modal"

const IOKWatchTutorialButton: React.FC = () => {
  const tutorialVideoModal = useVideoModal()

  return (
    <Button
      type="button"
      variant="ghost"
      className="flex items-center text-[15px] gap-[6px] py-1 px-2 h-auto text-primary hover:text-primary rounded-md"
      onClick={() =>
        tutorialVideoModal.show({
          src: "https://player.vimeo.com/video/915770897?h=fe33dde27c",
          title: "An Overview of Rupa In-Office Kits",
        })
      }
    >
      <VideoPlayIcon />
      Watch Tutorial
    </Button>
  )
}

export default IOKWatchTutorialButton
