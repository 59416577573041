import React, { useEffect, useMemo, useState } from "react"

import { format } from "date-fns"

import { faCalendar } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import * as Popover from "@radix-ui/react-popover"
import { Button, Calendar, CalendarProps, cn } from "@rupahealth/design"

import { safeParseDate } from "app/utils/format-date-for-human"

export interface RupaDatePickerProps {
  value: Date | string | undefined
  onChange: (date?: Date) => void
  disabled?: boolean
  errorOutline?: boolean
  placeholder?: string
  align?: Popover.PopoverContentProps["align"]
  modal?: boolean
  calendarProps?: Omit<
    CalendarProps,
    "mode" | "selected" | "onSelect" | "defaultMonth"
  >
}

const RupaDatePicker: React.FC<RupaDatePickerProps> = ({
  onChange,
  value,
  disabled,
  errorOutline,
  placeholder = "Select date...",
  align,
  modal,
  calendarProps,
}) => {
  const [open, setOpen] = useState(false)
  const [internalDate, setInternalDate] = useState<Date | undefined>(undefined)

  const defaultMonth = useMemo(() => {
    if (internalDate) {
      return new Date(internalDate.getFullYear(), internalDate.getMonth(), 1)
    } else {
      return new Date(new Date().getFullYear(), new Date().getMonth(), 1)
    }
  }, [internalDate])

  useEffect(() => {
    const newDate = safeParseDate(value)
    setInternalDate(newDate)
  }, [value])

  const onDateSelect = (date: Date | undefined) => {
    onChange(date)
    setInternalDate(date)
    setOpen(false)
  }

  return (
    <Popover.Root open={open} onOpenChange={setOpen} modal={modal}>
      <Popover.Trigger asChild onClick={() => setOpen((prev) => !prev)}>
        <Button
          variant="outline"
          className={cn(
            "w-full justify-start text-left font-normal text-body rounded-md",
            {
              "text-muted-foreground": !internalDate,
              "border-destructive": errorOutline,
            }
          )}
          disabled={disabled}
        >
          <FontAwesomeIcon
            icon={faCalendar}
            className={cn(
              "mr-2",
              internalDate ? "text-body" : "text-slate-400"
            )}
          />
          {internalDate ? (
            format(internalDate, "PPP")
          ) : (
            <span className="text-slate-400">{placeholder}</span>
          )}
        </Button>
      </Popover.Trigger>

      <Popover.Portal>
        <Popover.Content
          className="my-1 z-popover w-fit rounded-md border border-slate-200 bg-white shadow"
          align={align}
        >
          <Calendar
            mode="single"
            selected={internalDate}
            onSelect={onDateSelect}
            defaultMonth={defaultMonth}
            initialFocus
            {...calendarProps}
          />
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  )
}

export default RupaDatePicker
