import { ReactComponent as ArrowTopRightIcon } from "app/assets/icons/arrow-top-right.svg"
import MagazineIcon from "app/assets/images/patient-portal/magazine-icon.svg"
import PodcastIcon from "app/assets/images/patient-portal/podcast-icon.svg"
import ReferenceGuideIcon from "app/assets/images/patient-portal/reference-guide-icon.svg"
import DesignSystemButton from "app/components/design-system/Button"
import DisplayText from "app/components/design-system/DisplayText"
import makeAppStyles from "app/utils/makeAppStyles"

import Card from "../Card"
import MessageSupport from "../MessageSupport"
import PatientPortalFooterCard from "./PatientPortalFooterCard"

const PODCAST_URL = "https://www.rupahealth.com/the-root-cause-medicine-podcast"
const MAGAZINE_URL = "https://www.rupahealth.com/magazine"
const REFERENCE_GUIDE_URL = "https://www.rupahealth.com/reference-guide"

const useStyles = makeAppStyles((theme) => ({
  arrowIcon: { position: "relative", top: -2 },
}))

const PatientPortalGeneralQuickLinks = () => {
  const classes = useStyles()

  return (
    <Card className="flex flex-col gap-5">
      <div className="flex justify-between items-center">
        <DisplayText size="lg" weight="semibold">
          Quick Links
        </DisplayText>
        <MessageSupport />
      </div>
      <div className="flex flex-col w-full gap-4 sm:flex-row sm:items-stretch">
        <PatientPortalFooterCard
          icon={<img src={PodcastIcon} alt="" />}
          title={"The Root Cause Medicine Podcast"}
          text={
            "Hear from renowned medical experts in the root cause medicine space."
          }
          actionButton={
            <DesignSystemButton
              color="text"
              variant="text"
              endIcon={<ArrowTopRightIcon className={classes.arrowIcon} />}
            >
              Listen Now
            </DesignSystemButton>
          }
          onClick={() => window.open(PODCAST_URL, "_blank")}
        />
        <PatientPortalFooterCard
          icon={<img src={MagazineIcon} alt="" />}
          title={"Rupa Magazine"}
          text={
            "Read the latest articles about integrative medicine, holistic health, and more."
          }
          actionButton={
            <DesignSystemButton
              color="text"
              variant="text"
              endIcon={<ArrowTopRightIcon className={classes.arrowIcon} />}
            >
              Go to Rupa Magazine
            </DesignSystemButton>
          }
          onClick={() => window.open(MAGAZINE_URL, "_blank")}
        />
        <PatientPortalFooterCard
          icon={<img src={ReferenceGuideIcon} alt="" />}
          title={"Lab Reference Guide"}
          text={
            "Find all the information you need about lab testing, lab companies, and more."
          }
          actionButton={
            <DesignSystemButton
              color="text"
              variant="text"
              endIcon={<ArrowTopRightIcon className={classes.arrowIcon} />}
            >
              Go to Reference Guide
            </DesignSystemButton>
          }
          onClick={() => window.open(REFERENCE_GUIDE_URL, "_blank")}
        />
      </div>
    </Card>
  )
}

export default PatientPortalGeneralQuickLinks
