import { API } from "app/api"
import { handleApiSuccess, handleApiError } from "app/utils"

export const GET_PHLEBOTOMISTS = "[APP] GET_PHLEBOTOMISTS"

export function getPhlebotomists() {
  return (dispatch) => {
    const request = API.Phlebotomist.getList()

    return request
      .then(async (response) => {
        await dispatch({
          type: GET_PHLEBOTOMISTS,
          payload: response.data.results,
        })
      })
      .catch((error) => dispatch(handleApiError(error)))
  }
}

export function createPhlebotomist(
  name,
  url,
  locationTypes,
  email,
  phone,
  addressStreet,
  addressCity,
  addressState,
  addressZip,
  hours,
  fee,
  externalNotes,
  onClose
) {
  const request = API.Phlebotomist.create({
    name,
    url,
    email,
    phone,
    hours,
    fee,
    location_types: locationTypes,
    address_street: addressStreet,
    address_city: addressCity,
    address_state: addressState,
    address_zip: addressZip,
    external_notes: externalNotes,
  })

  return (dispatch, getState) =>
    request
      .then(async (response) => {
        dispatch(handleApiSuccess("Phlebotomist added"))

        let phlebotomists = getState().phlebotomist
        phlebotomists = [...phlebotomists, response.data]

        await dispatch({
          type: GET_PHLEBOTOMISTS,
          payload: phlebotomists,
        })

        window.Intercom("trackEvent", "phlebotomist-added")

        // Close Modal
        onClose()
      })
      .catch((error) => dispatch(handleApiError(error)))
}

export function updatePhlebotomist(
  phlebotomistId,
  name,
  url,
  locationTypes,
  email,
  phone,
  addressStreet,
  addressCity,
  addressState,
  addressZip,
  hours,
  fee,
  externalNotes,
  onClose
) {
  const request = API.Phlebotomist.update(phlebotomistId, {
    name,
    url,
    email,
    phone,
    hours,
    fee,
    location_types: locationTypes,
    address_street: addressStreet,
    address_city: addressCity,
    address_state: addressState,
    address_zip: addressZip,
    external_notes: externalNotes,
  })

  return (dispatch, getState) =>
    request
      .then(async (response) => {
        dispatch(handleApiSuccess("Phlebotomist updated"))

        let phlebotomists = getState().phlebotomist
        phlebotomists = [...phlebotomists].filter(
          (phlebotomist) => phlebotomist.id !== phlebotomistId
        )
        phlebotomists = [...phlebotomists, response.data]

        await dispatch({
          type: GET_PHLEBOTOMISTS,
          payload: phlebotomists,
        })

        // Close Modal
        onClose()
      })
      .catch((error) => dispatch(handleApiError(error)))
}

export function deletePhlebotomist(phlebotomistId, onClose) {
  const request = API.Phlebotomist.delete(phlebotomistId)

  return (dispatch, getState) =>
    request
      .then(async (response) => {
        dispatch(handleApiSuccess("Phlebotomist deleted"))

        let phlebotomists = getState().phlebotomist
        phlebotomists = [...phlebotomists].filter(
          (phlebotomist) => phlebotomist.id !== phlebotomistId
        )

        await dispatch({
          type: GET_PHLEBOTOMISTS,
          payload: phlebotomists,
        })

        // Close Modal
        onClose()
      })
      .catch((error) => dispatch(handleApiError(error)))
}
