import { useMemo } from "react"

import { ReactComponent as AcceptPartialIcon } from "app/assets/icons/specimen-issue-actions/specimen-issue-action-accept-partial.svg"
import { ReactComponent as CancelAndRefundIcon } from "app/assets/icons/specimen-issue-actions/specimen-issue-action-cancel-and-refund.svg"
import { ReactComponent as ConvertTestIcon } from "app/assets/icons/specimen-issue-actions/specimen-issue-action-convert-test.svg"
import { ReactComponent as FullRecollectionIcon } from "app/assets/icons/specimen-issue-actions/specimen-issue-action-full-recollection.svg"
import { ReactComponent as PartialRecollectionIcon } from "app/assets/icons/specimen-issue-actions/specimen-issue-action-partial-recollection.svg"
import { ReactComponent as RedrawInOfficeIcon } from "app/assets/icons/specimen-issue-actions/specimen-issue-action-recollect-in-office.svg"
import { ReactComponent as ContinueProcessingIcon } from "app/assets/icons/specimen-issue-actions/specimen-issue-continue-test-processing.svg"
import {
  SPECIMEN_ISSUE_ACTION,
  SpecimenIssueActionUnion,
} from "app/constants.typed"
import uniq from "lodash/uniq"

interface SpecimenIssueActionComponent {
  title: string
  subtitle?: string
  Icon: React.FC<React.SVGProps<SVGSVGElement>>
}

const SPECIMEN_ISSUE_ACTION_COMPONENTS: {
  [action: SpecimenIssueActionUnion]: SpecimenIssueActionComponent
} = {
  [SPECIMEN_ISSUE_ACTION.SHIP_RECOLLECTION_FULL]: {
    title: "Reship kit for full recollection",
    subtitle: "We will send a complimentary recollection kit.",
    Icon: FullRecollectionIcon,
  },
  [SPECIMEN_ISSUE_ACTION.SHIP_RECOLLECTION_PARTIAL]: {
    title: "Reship kit for partial recollection",
    subtitle: "We will send a complimentary recollection kit.",
    Icon: PartialRecollectionIcon,
  },
  [SPECIMEN_ISSUE_ACTION.REDRAW_IN_OFFICE_KIT]: {
    title: "Recollect kit in office",
    subtitle: "We will send you a new digital requisition form.",
    Icon: RedrawInOfficeIcon,
  },
  [SPECIMEN_ISSUE_ACTION.ACCEPT_PARTIAL_RESULT]: {
    title: "Accept partial results",
    subtitle: "You'll receive a refund for the markers that couldn't be run.",
    Icon: AcceptPartialIcon,
  },
  [SPECIMEN_ISSUE_ACTION.CONVERT_TESTS]: {
    title: "Adjust to a different test",
    subtitle: "A different test can be performed with the available sample.",
    Icon: ConvertTestIcon,
  },
  [SPECIMEN_ISSUE_ACTION.CANCEL_AND_REFUND]: {
    title: "Cancel & refund",
    Icon: CancelAndRefundIcon,
  },
  [SPECIMEN_ISSUE_ACTION.CONTINUE_TEST_PROCESSING]: {
    title: "Continue test processing",
    subtitle:
      "Continue processing the sample as is, even though issues occurred.",
    Icon: ContinueProcessingIcon,
  },
  [SPECIMEN_ISSUE_ACTION.RECOLLECT_AT_LABCORP]: {
    title: "Recollect at Labcorp",
    Icon: RedrawInOfficeIcon,
  },
}

export function useSpecimenIssueActionComponents(
  action: SpecimenIssueActionUnion
): SpecimenIssueActionComponent {
  return useMemo(() => SPECIMEN_ISSUE_ACTION_COMPONENTS[action], [action])
}

export function useSortedSpecimenIssueActions(
  actions: SpecimenIssueActionUnion[]
) {
  const sortedActions = Object.keys(SPECIMEN_ISSUE_ACTION_COMPONENTS)
  return useMemo(() => {
    return uniq(actions).sort(
      (a, b) => sortedActions.indexOf(a) - sortedActions.indexOf(b)
    )
  }, [actions])
}

export function formatSpecimenIssueActionForSubtitle(
  action: SpecimenIssueActionUnion | null | undefined
) {
  switch (action) {
    case SPECIMEN_ISSUE_ACTION.SHIP_RECOLLECTION_FULL:
      return "Reship Kit for Full Recollection"
    case SPECIMEN_ISSUE_ACTION.SHIP_RECOLLECTION_PARTIAL:
      return "Reship Kit for Partial Recollection"
    case SPECIMEN_ISSUE_ACTION.CONVERT_TESTS:
      return "Convert Tests"
    case SPECIMEN_ISSUE_ACTION.ACCEPT_PARTIAL_RESULT:
      return "Accept Partial Result"
    case SPECIMEN_ISSUE_ACTION.REDRAW_IN_OFFICE_KIT:
      return "Redraw Kit In-Office"
    case SPECIMEN_ISSUE_ACTION.CANCEL_AND_REFUND:
      return "Cancel & Refund"
    case SPECIMEN_ISSUE_ACTION.CONTINUE_TEST_PROCESSING:
      return "Continue Test Processing"
    case SPECIMEN_ISSUE_ACTION.RECOLLECT_AT_LABCORP:
      return "Recollect at Labcorp"
    default:
      return undefined
  }
}

export function formatSpecimenIssueActionForSuccess(
  action: SpecimenIssueActionUnion | null | undefined,
  patientName: string | undefined
) {
  switch (action) {
    case SPECIMEN_ISSUE_ACTION.SHIP_RECOLLECTION_FULL:
      return patientName
        ? `We'll re-ship the kit to ${patientName}.`
        : "We'll re-ship the kit."
    case SPECIMEN_ISSUE_ACTION.SHIP_RECOLLECTION_PARTIAL:
      return patientName
        ? `We'll re-ship the kit to ${patientName}.`
        : "We'll re-ship the kit."
    case SPECIMEN_ISSUE_ACTION.CONVERT_TESTS:
      return "We'll perform a different test with the available sample."
    case SPECIMEN_ISSUE_ACTION.ACCEPT_PARTIAL_RESULT:
      return "We'll issue a refund for the markers that couldn't be run."
    case SPECIMEN_ISSUE_ACTION.REDRAW_IN_OFFICE_KIT:
    case SPECIMEN_ISSUE_ACTION.RECOLLECT_AT_LABCORP:
      return "We'll send you a new digital requisition form."
    case SPECIMEN_ISSUE_ACTION.CANCEL_AND_REFUND:
      return "We'll cancel the test and issue a refund."
    case SPECIMEN_ISSUE_ACTION.CONTINUE_TEST_PROCESSING:
      return "We'll continue processing the test as is, even though issues occurred."
    default:
      return undefined
  }
}
