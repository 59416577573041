import { useState } from "react"
import { useDispatch } from "react-redux"

import axios from "axios"
import useSWR from "swr"

import Button from "@material-ui/core/Button"
import { makeStyles } from "@material-ui/core/styles"

import { ReactComponent as EmailInviteIcon } from "app/assets/icons/envelope/clinic-invite-email-green.svg"
import { ReactComponent as EmailBubbleIcon } from "app/assets/icons/envelope/email-filled-with-bubble.svg"
import { resendRegistrationEmail } from "app/auth/store/actions/user.actions"
import { trackResendEmailVerification } from "app/services/segment"
import { Practitioner } from "app/types"
import { getApiBaseUrl } from "app/utils"

import TaskContainer from "../TaskContainer"
import taskStyles from "../TaskStyles"
import { OnboardingTaskItemProps } from "./OnboardingTaskItem"

const useStyles = makeStyles(() => ({
  sentTextContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: 6,
    marginBottom: 25,
  },
}))

const VerifyEmail: React.FC<OnboardingTaskItemProps> = ({ task }) => {
  const taskClasses = taskStyles()
  const classes = useStyles()
  const dispatch = useDispatch()

  const { data: loggedInPractitioner } = useSWR(
    "/api/practitioner",
    async (url) => {
      const response = await axios.get(getApiBaseUrl() + url)
      return response.data as Practitioner
    }
  )

  const [sendingEmail, setSendingEmail] = useState(false)

  const resendVerificationEmail = async () => {
    setSendingEmail(true)
    await dispatch(
      resendRegistrationEmail({ email: loggedInPractitioner?.user.email })
    )
    setTimeout(() => setSendingEmail(false), 30000)
    trackResendEmailVerification()
  }

  return (
    <TaskContainer
      taskKey={task.key}
      header="Verify Your Email"
      isTaskComplete={task.complete}
      timeTag={"30 secs"}
    >
      <EmailBubbleIcon className={taskClasses.emailIcon} />
      <div className={taskClasses.taskText}>
        We just need to verify your email before you can submit your first
        order!
      </div>
      <Button
        variant="contained"
        color="primary"
        className={taskClasses.taskButton}
        onClick={resendVerificationEmail}
        style={{ marginBottom: 0 }}
        disabled={sendingEmail}
      >
        <span className={taskClasses.buttonText}>
          Re-send Verification Email
        </span>
      </Button>
      <div className={classes.sentTextContainer}>
        <EmailInviteIcon style={{ flexShrink: 0 }} />
        <div>Sent to {loggedInPractitioner?.user.email}</div>
      </div>
    </TaskContainer>
  )
}

export default VerifyEmail
