import SpecimenIssueDescription from "app/components/SpecimenIssues/SpecimenIssueDescription"
import SpecimenIssueRecollectionDetails from "app/components/SpecimenIssues/SpecimenIssueRecollectionDetails"
import SpecimenIssueResolveTitle from "app/components/SpecimenIssues/SpecimenIssueResolveTitle"
import { formatSpecimenIssueActionForSubtitle } from "app/components/SpecimenIssues/helpers"
import {
  LAB_COMPANIES_WE_CAN_GENERATE_REQUISITIONS_FOR,
  LAB_COMPANIES_WITH_NEW_RECOLLECTION_REQUISITIONS,
} from "app/constants"
import { ORDERED_TEST_STATUS } from "app/constants.typed"
import * as PatientOrderActions from "app/main/patient-orders/store/actions"
import formatDateForHuman from "app/utils/format-date-for-human"

import { StatusConfiguration } from "../../types"
import {
  SpecimenIssueProps,
  StatusConfigurationProps,
} from "../../types/patient-orders-types"
import { PROGRESS_PERCENTAGES } from "../consts"

const LastUpdatedAt = ({ orderedTest }: StatusConfigurationProps) => {
  const lastUpdatedAt =
    orderedTest.specimen_issue?.updated_at || orderedTest.updated_at
  return <>{formatDateForHuman(lastUpdatedAt)}</>
}

const ActionRequiredSpecimenIssueTitle = ({
  orderedTest,
  order,
  patient,
  dispatch,
  specimenIssueResolveModal,
  pathname,
  currentPractitioner,
}: StatusConfigurationProps) => {
  return (
    <SpecimenIssueResolveTitle
      specimenIssue={orderedTest.specimen_issue}
      patient={patient}
      testDisplayName={orderedTest.lab_test.name}
      onSuccess={async () => {
        await dispatch(PatientOrderActions.updateOrder(order.id))
      }}
      modal={specimenIssueResolveModal}
      pathname={pathname}
      practitioner={currentPractitioner}
    />
  )
}

const ActionRequiredSpecimenIssueSubTitle = ({
  specimenIssue,
  specimenIssueShowMore,
  setSpecimenIssueShowMore,
}: SpecimenIssueProps) => {
  if (!specimenIssue) return null
  return (
    <div>
      {specimenIssue && (
        <SpecimenIssueDescription
          description={specimenIssue.description}
          showMore={specimenIssueShowMore}
          setShowMore={setSpecimenIssueShowMore}
        />
      )}
    </div>
  )
}

const RecollectingSpecimenIssueSubTitle = ({
  specimenIssue,
  specimenIssueShowMore,
  setSpecimenIssueShowMore,
  orderedTest,
  labCompany,
}: StatusConfigurationProps) => {
  const is_requisition_provided =
    orderedTest.instant_requisition || labCompany.only_in_house_phlebotomy

  const is_requisition_ready =
    is_requisition_provided &&
    LAB_COMPANIES_WITH_NEW_RECOLLECTION_REQUISITIONS.includes(labCompany.key)
      ? !!specimenIssue?.recollection_requisition
      : Boolean(orderedTest.requisition) ||
        LAB_COMPANIES_WE_CAN_GENERATE_REQUISITIONS_FOR.includes(labCompany.key)

  return (
    <div className="w-full">
      <div>
        {specimenIssue && (
          <SpecimenIssueDescription
            description={specimenIssue.description}
            showMore={specimenIssueShowMore}
            setShowMore={setSpecimenIssueShowMore}
          />
        )}
      </div>

      <div className="mt-2 pt-2 border-t border-t-slate-200">
        <SpecimenIssueRecollectionDetails
          is_requisition_provided={is_requisition_provided}
          is_requisition_ready={is_requisition_ready}
          is_shipped_kit={!orderedTest.instant_requisition}
          date_recollection_ordered_with_lab={
            specimenIssue?.date_recollection_ordered_with_lab
          }
          estimated_recollection_arrival_date={
            specimenIssue?.estimated_recollection_arrival_date
          }
          recollection_shipment_tracking_number={
            specimenIssue?.recollection_shipment_tracking_number
          }
          date_recollection_sample_received_at_lab={
            specimenIssue?.date_recollection_sample_received_at_lab
          }
          estimated_recollection_results_date={
            specimenIssue?.estimated_recollection_results_date
          }
          lab_company_notifies_sample_at_lab={labCompany.notifies_sample_at_lab}
          lab_company_provides_results_eta={labCompany.provides_results_eta}
          lab_company_short_name={labCompany.short_name}
        />
      </div>
    </div>
  )
}

const ProcessingSpecimenIssueActionSubTitle = ({
  specimenIssue,
  specimenIssueShowMore,
  setSpecimenIssueShowMore,
}: SpecimenIssueProps) => {
  let subtitle: string
  const formattedActionChosen = formatSpecimenIssueActionForSubtitle(
    specimenIssue?.action_chosen
  )
  if (formattedActionChosen) {
    subtitle = `We are processing your request to ${formattedActionChosen}.`
  } else {
    subtitle = "We are processing the Specimen Issue."
  }

  if (!specimenIssue) return null

  return (
    <div>
      <span>{subtitle}</span>
      {specimenIssue && (
        <SpecimenIssueDescription
          description={specimenIssue.description}
          showMore={specimenIssueShowMore}
          setShowMore={setSpecimenIssueShowMore}
        />
      )}
    </div>
  )
}

export const actionRequiredSpecimenIssueStatusConfiguration: StatusConfiguration<StatusConfigurationProps> =
  {
    when: {
      orderedTestStatus: ORDERED_TEST_STATUS.ACTION_REQUIRED_SPECIMEN_ISSUE,
    },
    progressPercentage: PROGRESS_PERCENTAGES.NEW_SPECIMEN_ISSUE,
    title: ActionRequiredSpecimenIssueTitle,
    subTitle: ActionRequiredSpecimenIssueSubTitle,
    lastUpdatedAt: LastUpdatedAt,
  }

export const recollectingSpecimenIssueStatusConfiguration: StatusConfiguration<StatusConfigurationProps> =
  {
    when: { orderedTestStatus: ORDERED_TEST_STATUS.RECOLLECTION_IN_PROGRESS },
    progressPercentage: PROGRESS_PERCENTAGES.RECOLLECTION_IN_PROGRESS,
    title: "Sample Recollection in Progress",
    subTitle: RecollectingSpecimenIssueSubTitle,
    lastUpdatedAt: LastUpdatedAt,
  }

export const processingSpecimenIssueStatusConfiguration: StatusConfiguration<StatusConfigurationProps> =
  {
    when: {
      orderedTestStatus: ORDERED_TEST_STATUS.PROCESSING_SPECIMEN_ISSUE_ACTION,
    },
    progressPercentage: PROGRESS_PERCENTAGES.PROCESSING_SPECIMEN_ISSUE_ACTION,
    title: "Processing Specimen Issue Action",
    subTitle: ProcessingSpecimenIssueActionSubTitle,
    lastUpdatedAt: LastUpdatedAt,
  }

export const pendingDetailsSpecimenIssueStatusConfiguration: StatusConfiguration<StatusConfigurationProps> =
  {
    when: {
      orderedTestStatus: ORDERED_TEST_STATUS.PENDING_DETAILS_SPECIMEN_ISSUE,
    },
    progressPercentage: PROGRESS_PERCENTAGES.NEW_SPECIMEN_ISSUE,
    title: "New Specimen Issue",
    subTitle: "We are gathering details from the lab and will update shortly!",
    lastUpdatedAt: LastUpdatedAt,
  }
