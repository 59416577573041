import { useState, useMemo, useEffect } from "react"

import clsx from "clsx"

import { Grow, IconButton, Typography, makeStyles } from "@material-ui/core"

import couponTagTealIcon from "app/assets/icons/coupon-tag-teal.svg"
import couponTagIcon from "app/assets/icons/coupon-tag.svg"
import CrossIcon from "app/assets/icons/gray-cross.svg"
import { ReactComponent as TrashCan } from "app/assets/icons/trash-can.svg"
import Tooltip from "app/components/Tooltip"
import Button from "app/components/design-system/Button"
import { CouponDeleteModal } from "app/components/modals/RemoveCouponModal"
import { veryLightBlue, colors } from "app/theme"
import { formatDollars } from "app/utils"

export const COUPON_ACTION = {
  APPLY: "apply",
  REMOVE: "remove",
}

interface CouponProps {
  title: string
  subtitle?: string
  cost?: string
  onApply?: () => Promise<void>
  onRemove?: () => Promise<void>
  onDelete?: () => Promise<void>
  action?: string
  disabledReason?: string
  className?: string
  percentOffTestName?: string
  isPercentOffCoupon?: boolean
  isAutomaticCoupon?: boolean
  isFirstOrderOnly?: boolean
}

const useStyles = makeStyles({
  couponTagContainer: {
    padding: 14.5,
    background: "rgba(74, 168, 188, 0.1)",
    display: "inline-block",
    width: "45px",
    height: "45px",
    borderRadius: 8,
    "&.disabled": {
      backgroundColor: colors.blueGray[50],
    },
  },
  iconButton: {
    marginLeft: 8,
    marginTop: 2.5,
    padding: 0,
  },
})

export function Coupon({
  title,
  subtitle,
  cost,
  onApply,
  onRemove,
  onDelete,
  action,
  disabledReason,
  className,
  percentOffTestName,
  isPercentOffCoupon,
  isAutomaticCoupon,
  isFirstOrderOnly,
}: CouponProps) {
  const { couponTagContainer, iconButton } = useStyles()
  const [actionPending, setActionPending] = useState(false)
  const [couponDeleteModalOpen, setCouponDeleteModalOpen] = useState(false)

  const handleAction = async (actionMethod) => {
    setActionPending(true)
    await actionMethod()
    setActionPending(false)
  }

  const disabledClasses = disabledReason ? "opacity-50" : ""

  const formatDiscountAmount = (costAmount) => {
    if (isPercentOffCoupon) {
      return ""
    } else {
      return `-${formatDollars(Math.abs(costAmount))}`
    }
  }

  useEffect(() => {
    if (!disabledReason) {
      return
    }

    if (action === COUPON_ACTION.REMOVE) handleAction(onRemove)
  }, [disabledReason, action, onRemove])

  const formattedDiscountAmount = useMemo(
    () => formatDiscountAmount(cost),
    [cost]
  )
  const displayCouponTitle = isPercentOffCoupon && !isAutomaticCoupon
  const displayCouponSubtitle =
    subtitle && (!isPercentOffCoupon || isAutomaticCoupon)
  const showApplyAction = action === COUPON_ACTION.APPLY
  // Don't show the remove button for automatic coupons
  const showRemoveAction = action === COUPON_ACTION.REMOVE && !isAutomaticCoupon

  return (
    <Grow in={true} style={{ transformOrigin: "50% 0 0" }} timeout={250}>
      <Tooltip
        title={disabledReason || ""}
        disableHoverListener={!disabledReason}
        placement="bottom"
        arrow
      >
        <div
          className={clsx(
            "rounded pl-3 pr-3 min-h-48 py-2 flex justify-between items-center shadow fs-unmask",
            className
          )}
          style={{ borderColor: veryLightBlue }}
        >
          <div
            className={clsx("flex mr-2 items-top", disabledClasses)}
            style={{ alignItems: "center" }}
          >
            {disabledReason ? (
              <img src={couponTagIcon} alt="tag" className="ml-4" />
            ) : (
              <div className={clsx("mr-1", couponTagContainer)}>
                <img
                  src={couponTagTealIcon}
                  alt="tag"
                  style={{ maxWidth: "initial" }}
                />
              </div>
            )}
            <Typography className="text-base text-gray-800 ml-2">
              <p className="font-bold">
                {displayCouponTitle
                  ? `${parseInt(cost || "0")}% Off ${percentOffTestName}`
                  : title}
              </p>
              {displayCouponSubtitle && <p>{subtitle}</p>}
            </Typography>
          </div>
          <div
            className={clsx("flex items-center justify-end", disabledClasses)}
          >
            {cost && (
              <Typography className="font-bold text-lg" align="right">
                {formattedDiscountAmount}
              </Typography>
            )}
            {showApplyAction && (
              <Button
                disabled={actionPending || !!disabledReason}
                color="text"
                onClick={() => handleAction(onApply)}
              >
                Apply
              </Button>
            )}
            {showRemoveAction && (
              <IconButton
                disabled={actionPending || !!disabledReason}
                className={iconButton}
                onClick={() => handleAction(onRemove)}
              >
                <img src={CrossIcon} alt="Remove" />
              </IconButton>
            )}
            {isFirstOrderOnly && action && (
              <>
                <IconButton
                  className={iconButton}
                  onClick={() => setCouponDeleteModalOpen(true)}
                >
                  <TrashCan
                    fill={
                      disabledReason
                        ? colors.blueGray[900]
                        : colors.blueGray[500]
                    }
                  />
                </IconButton>
                <CouponDeleteModal
                  open={couponDeleteModalOpen}
                  onClose={() => setCouponDeleteModalOpen(false)}
                  onSuccess={onDelete}
                  title={"Remove Coupon From Account"}
                />
              </>
            )}
          </div>
        </div>
      </Tooltip>
    </Grow>
  )
}
