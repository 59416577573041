import { makeStyles, Theme } from "@material-ui/core"

import DropShippingTeal from "app/assets/icons/labshops/dropshipping.svg"
import FileTeal from "app/assets/icons/labshops/file.svg"
import HouseTeal from "app/assets/icons/labshops/house.svg"
import ShippingTeal from "app/assets/icons/labshops/shipping-truck.svg"
import ShoppingCartTeal from "app/assets/icons/labshops/shopping-cart.svg"
import BodyText from "app/components/design-system/BodyText"
import { navy } from "app/theme"

const styles = (theme: Theme) => ({
  howItWorksCard: {
    padding: "0px 0",
    display: "flex",
    justifyContent: "center",
    "flex-direction": "column",
    alignItems: "center",
    gap: 34,
  },
  howItWorksProcessContainer: {
    width: "100%",
    display: "flex",
    "flex-direction": "column",
    alignItems: "center",
  },
  hotItWorksProcessObject: {
    width: "100%",
    marginBottom: 35,
    display: "flex",
    "flex-direction": "column",
    alignItems: "center",
    "text-align": "center",
    gap: 9,
  },
  processIcon: {
    width: "60px",
    height: "60px",
  },
})

const useStyles = makeStyles(styles)

const HowItWorksCardMobile = () => {
  const classes = useStyles()

  return (
    <div className={classes.howItWorksCard}>
      <BodyText
        style={{
          color: navy,
          fontSize: 17,
          fontFamily: "Open Sans",
          fontWeight: 600,
        }}
      >
        How it Works
      </BodyText>

      <div className={classes.howItWorksProcessContainer}>
        <div className={classes.hotItWorksProcessObject}>
          <div className={classes.processIcon}>
            <img src={ShoppingCartTeal} alt="shopping cart" />
          </div>
          <BodyText weight="semibold">Order your kit on Rupa</BodyText>
        </div>
        <div className={classes.hotItWorksProcessObject}>
          <div className={classes.processIcon}>
            <img src={ShippingTeal} alt="shipping" />
          </div>
          <BodyText weight="semibold">
            Your kit is shipped on the following business day
          </BodyText>
        </div>
        <div className={classes.hotItWorksProcessObject}>
          <div className={classes.processIcon}>
            <img src={HouseTeal} alt="house" />
          </div>
          <BodyText weight="semibold">
            Receive your test kit and follow our personalized instructions email
          </BodyText>
        </div>
        <div className={classes.hotItWorksProcessObject}>
          <div className={classes.processIcon}>
            <img src={DropShippingTeal} alt="package" />
          </div>
          <BodyText weight="semibold">
            Complete your test at home, and mail back to the lab using a
            pre-paid shipping label
          </BodyText>
        </div>
        <div className={classes.hotItWorksProcessObject}>
          <div className={classes.processIcon}>
            <img src={FileTeal} alt="file" />
          </div>
          <BodyText weight="semibold">
            You'll be emailed a secure link to access your results
          </BodyText>
        </div>
      </div>
    </div>
  )
}

export default HowItWorksCardMobile
