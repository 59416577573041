import * as React from "react"

import { ListItemText, styled } from "@material-ui/core"

import CheckMarkIcon from "app/assets/icons/check-mark-cog.svg"
import InfoIcon from "app/assets/icons/info-dark-gray-circle.svg"
import { ReactComponent as BooksLeaningIcon } from "app/assets/images/books-leaning-icon.svg"
import BodyText from "app/components/design-system/BodyText"
import LearnMoreBanner from "app/components/design-system/LearnMoreBanner"
import { VENDOR_PHYSICIAN_AUTHORIZATION_LABEL } from "app/constants"
import { LabTest, labTestLocation } from "app/types"

const StyledListItemText = styled(ListItemText)({
  width: "100%",
})

const BodyContainer = styled("div")({
  display: "flex",
  flexDirection: "row",
  marginTop: 12,
})
const LeftInfoSpacer = styled("div")({
  width: 24,
})
const InfoIconImage = styled("img")({
  width: 15.4,
  height: 15.4,
})
const LeftPhysicianAuthorizationAvailableSpacer = styled("div")({
  width: 24,
  display: "flex",
  "align-items": "center",
})
const PhysicianAuthorizationAvailableImage = styled("img")({
  width: 17.4,
  height: 17.4,
})
const RightBodyContainer = styled("div")({
  width: "calc(100% - 24px)",
})

interface Props {
  body: LabTest["details"]
  url: LabTest["rupa_url"] | LabTest["url"]
  vendorPhysAuthApproved: LabTest["physician_authorization_approved"]
  labTestId?: LabTest["id"]
  location?: labTestLocation
}

const DetailsSection = ({ body, url, vendorPhysAuthApproved }: Props) => {
  if (!body && !url) {
    return null
  }

  let moreInfoLink: React.ReactNode = null
  let PhysicianAuthorizationAvailable: React.ReactNode = null

  if (url) {
    moreInfoLink = (
      <LearnMoreBanner
        icon={<BooksLeaningIcon />}
        title="Learn more about this test in the Reference Guide."
        text="Explore in-depth guides, free Live Classes, and more."
        onClick={() => {
          window.open(url, "_blank")
        }}
      />
    )
  }

  if (vendorPhysAuthApproved) {
    PhysicianAuthorizationAvailable = (
      <BodyContainer>
        <LeftPhysicianAuthorizationAvailableSpacer>
          <PhysicianAuthorizationAvailableImage src={CheckMarkIcon} />
        </LeftPhysicianAuthorizationAvailableSpacer>
        <RightBodyContainer>
          <BodyText size="base" weight="regular">
            Available with {VENDOR_PHYSICIAN_AUTHORIZATION_LABEL}
          </BodyText>
        </RightBodyContainer>
      </BodyContainer>
    )
  }

  return (
    <StyledListItemText>
      <BodyText size="sm">Details</BodyText>
      <BodyContainer>
        <LeftInfoSpacer>
          <InfoIconImage src={InfoIcon} alt="Details" />
        </LeftInfoSpacer>
        <RightBodyContainer>
          <BodyText size="base">{body}</BodyText>
        </RightBodyContainer>
      </BodyContainer>
      <div className="mt-[18px] mb-[18px]">{moreInfoLink}</div>

      {PhysicianAuthorizationAvailable}
    </StyledListItemText>
  )
}

export default DetailsSection
