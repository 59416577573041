import { every } from "lodash"

import { ORDER_STATUS } from "app/constants"
import { ResourceResponse } from "app/swr/types"
import resourceRequest from "app/swr/utils/resource-request"
import { OrderedTest, Order } from "app/types"
import { handleApiError } from "app/utils"
import { OrderedResult as OrderedResultSwr } from "types/ordered-result"

/**
 * Given an ordered test with updated mark result reviewed, update the order with the new ordered test
 * and properly set the order date results reviewed and order status if needed.
 * @param updatedOrderedTest ordered test that has been updated
 * @param order order to be updated
 * @returns updated order
 */
function updateOrderGivenOrderedTest(
  updatedOrderedTest: OrderedTest,
  order: Order
) {
  // Replace ordered test in list with updated ordered test
  const updatedOrderedTests = order.ordered_tests.map((o) =>
    o.id === updatedOrderedTest.id ? updatedOrderedTest : o
  )

  // Calculate updated date_results_reviewed from order
  let orderDateResultsReviewed = order.date_results_reviewed
  if (
    every(
      updatedOrderedTests,
      (t) => t.latest_ordered_result?.date_result_reviewed
    )
  ) {
    orderDateResultsReviewed =
      updatedOrderedTest.latest_ordered_result?.date_result_reviewed || ""
  } else {
    orderDateResultsReviewed = ""
  }

  // Return order with updated fields
  return {
    ...order,
    date_results_reviewed: orderDateResultsReviewed,
    ordered_tests: updatedOrderedTests,
    status: orderDateResultsReviewed
      ? ORDER_STATUS.RESULTS_REVIEWED
      : order.status,
  }
}

/**
 * Given an updated ordered result, update an ordered test with that ordered result
 * @param updatedOrderedResult ordered result that has been updated
 * @param orderedTest ordered test to update
 * @returns updated ordered test
 */
function updateOrderedTestGivenOrderedResult(
  updatedOrderedResult,
  orderedTest: OrderedTest
) {
  return {
    ...orderedTest,
    is_ordered_test_result_unread: false,
    latest_ordered_result: updatedOrderedResult,
  }
}

/**
 * Given an updated ordered result, update an entire order
 * Properly updates all ordered tests that are associated with the updated ordered result
 * @param updatedOrderedResult ordered result that has been updated
 * @param order order to update
 * @returns updated order
 */
function updateCompleteOrderWithUpdatedOrderedResult(
  updatedOrderedResult,
  order: Order
) {
  const orderedTestObjectsToUpdate = order.ordered_tests.filter((o) =>
    updatedOrderedResult.ordered_tests.includes(o.id)
  )

  for (let orderedTest of orderedTestObjectsToUpdate) {
    const updatedOrderedTest = updateOrderedTestGivenOrderedResult(
      updatedOrderedResult,
      orderedTest
    )
    order = updateOrderGivenOrderedTest(updatedOrderedTest, order)
  }
  return order
}

/**
 * Generic action for toggling the mark result reviewed flag on an ordered test item
 * Will optimistically update the cache with the new value and then send a request to the server
 * @param orderedTest ordered test that is being toggled
 * @param order parent order
 * @param dispatch_type type of dispatch to use
 * @returns updated ordered test
 */
export function markOrderedTestResultAction(
  orderedTest: OrderedTest,
  order: Order,
  dispatch_type: string
) {
  // Must be an ordered result to mark reviewed
  if (!orderedTest.latest_ordered_result) {
    return (dispatch) => {
      dispatch(handleApiError("Missing result"))
    }
  }

  // Find the new date to update to
  const updatedDateResultsReviewed = orderedTest.latest_ordered_result
    .date_result_reviewed
    ? null
    : new Date().toISOString()

  // Calculate the optimistic update
  const optimisticLatestOrderedResult = {
    ...orderedTest.latest_ordered_result,
    date_result_reviewed: updatedDateResultsReviewed,
  }
  const optimisticOrder = updateCompleteOrderWithUpdatedOrderedResult(
    optimisticLatestOrderedResult,
    order
  )

  // Build request patching with new value
  const request = resourceRequest<ResourceResponse<OrderedResultSwr>>({
    method: "patch",
    url: `/ordered_results/${orderedTest.latest_ordered_result.id}/`,
    data: {
      data: {
        type: "ordered_result",
        id: orderedTest.latest_ordered_result.id,
        attributes: { mark_as_reviewed: Boolean(updatedDateResultsReviewed) },
      },
    },
  })

  return (dispatch) => {
    // Do optimistic update
    dispatch({
      type: dispatch_type,
      payload: optimisticOrder,
    })

    // Send request to server and handle response
    request
      .then((response) => {
        const latestOrderedResult = {
          ...orderedTest.latest_ordered_result,
          date_result_reviewed: response.data.attributes.date_result_reviewed,
          ordered_tests: response.data.relationships.ordered_tests.data.map(
            (d) => d.id
          ),
        }

        dispatch({
          type: dispatch_type,
          payload: updateCompleteOrderWithUpdatedOrderedResult(
            latestOrderedResult,
            order
          ),
        })
      })
      .catch((error) => {
        dispatch(handleApiError(error))
        dispatch({
          type: dispatch_type,
          payload: order,
        })
      })
  }
}
