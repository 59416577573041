import { useController, useFormContext } from "react-hook-form"

import AutoCompleteField, { AutoCompleteFieldProps } from "./AutoCompleteField"

export type ControlledAutoCompleteFieldProps = Omit<
  AutoCompleteFieldProps,
  "error" | "helperText" | "onBlur" | "onChange" | "value"
> & {
  defaultValue?: string
  name: string
  shouldUnregister?: boolean
}

const ControlledAutoCompleteField = ({
  defaultValue,
  name,
  shouldUnregister,
  ...autoCompleteFieldProps
}: ControlledAutoCompleteFieldProps) => {
  const { control } = useFormContext()
  const {
    field: { ref, ...controlProps },
    fieldState: { error },
  } = useController({
    control,
    defaultValue: defaultValue,
    name: `${name}` as const,
    shouldUnregister,
  })
  return (
    <AutoCompleteField
      error={Boolean(error)}
      helperText={error?.message}
      inputRef={ref}
      {...autoCompleteFieldProps}
      {...controlProps}
    />
  )
}

export default ControlledAutoCompleteField
