import useEventCallback from "app/hooks/use-event-callback"
import useHandleApiError from "app/hooks/use-handle-api-error"
import { ResourceResponse } from "app/swr/types"
import resourceRequest from "app/swr/utils/resource-request"
import {
  TransactionReport,
  TransactionReportCreate,
  TransactionReportStartDownloadAttributes,
} from "types/transaction-report"

/**
 * Hook that returns a function that starts the download of a transaction report for a practitioner.
 *
 * @returns a function that takes TransactionReportStartDownloadAttributes and starts the download of a transaction report for a practitioner
 */
export default function useStartTransactionReportDownload() {
  const handleApiError = useHandleApiError()

  return useEventCallback(
    async (attributes: TransactionReportStartDownloadAttributes) => {
      try {
        const { data } = await resourceRequest<
          ResourceResponse<TransactionReport>,
          TransactionReportCreate
        >({
          method: "post",
          url: "/transaction_reports/",
          data: {
            data: {
              type: "transaction_report",
              attributes,
            },
          },
        })

        return data.id
      } catch (error) {
        handleApiError(error)
      }
    }
  )
}
