import { Fragment, ReactNode, useRef, useState } from "react"
import { useEffect } from "react"

import * as DropdownMenu from "@radix-ui/react-dropdown-menu"
import { cn } from "@rupahealth/design"

import { ReactComponent as TrashCanIcon } from "app/assets/icons/trash-can.svg"
import { useRefWidth } from "app/hooks/use-ref-width"
import useCheckoutDraft from "app/main/checkout/hooks/use-checkout-draft"

import RupaCheckbox from "../RupaCheckbox"
import FilterButton from "./FilterButton"
import { useDropdownHeight } from "./hooks/use-dropdown-height"
import { ALLOWED_IN_STATE } from "./hooks/use-filter-config"
import useFilterConfigUtilities from "./hooks/use-filter-config-utilities"
import {
  FilterConfig,
  FilterNames,
  FiltersMap,
  OnSelectFilterOption,
} from "./types"

interface Props {
  filterConfig: FilterConfig
  filtersMap: FiltersMap
  onResetFilter: (filterName?: FilterNames) => void
  onSelectFilterOption: OnSelectFilterOption
}

const Filter: React.FC<Props> = ({
  filterConfig,
  filtersMap,
  onResetFilter,
  onSelectFilterOption,
}) => {
  const buttonRef = useRef<HTMLButtonElement>(null)
  const dropdownHeight = useDropdownHeight(buttonRef)
  const buttonWidth = useRefWidth(buttonRef)

  const [isSelectedLocally, setIsSelectedLocally] = useState(false)

  const { getIsSelected, onSelect, renderLabel } = useFilterConfigUtilities({
    filterConfig,
    filtersMap,
    onSelectFilterOption,
  })

  const numSelected = filtersMap[filterConfig.filterName].size
  const resetDisabled = numSelected === 0
  const { values } = useCheckoutDraft()

  // select Patient Based-Location Restrictions filter to the state selected in the order (shipping_state)
  useEffect(() => {
    // skip in case the cahnge been made manually in this filter (isSelectedLocally)
    if (!isSelectedLocally && filterConfig.filterName === ALLOWED_IN_STATE) {
      const optionToSelect = filterConfig.options.find(
        (option) =>
          values.shipping_state && option.value === values.shipping_state
      )

      if (optionToSelect) {
        if (!getIsSelected(optionToSelect)) {
          // set Patient Based-Location Restrictions filter to shipping_state if present in the filter
          onSelect(optionToSelect)
        }
      }
    }

    // reset
    setIsSelectedLocally(false)
  }, [values.shipping_state, getIsSelected, numSelected])

  const handleOnSelect = (e, option) => {
    e.preventDefault()

    setIsSelectedLocally(true)
    onSelect(option)
  }

  const handleOnReset = (e) => {
    e.preventDefault()

    setIsSelectedLocally(true)
    onResetFilter(filterConfig.filterName)
  }

  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger asChild>
        <FilterButton
          label={filterConfig.label}
          count={numSelected}
          filterName={filterConfig.filterName}
          ref={buttonRef}
          defaultText={filterConfig.defaultText}
        />
      </DropdownMenu.Trigger>
      <DropdownMenu.Portal>
        <DropdownMenu.Content
          className="bg-white rounded-md border border-slate-200 shadow-lg px-2 z-popper mt-5 overflow-y-auto"
          style={{ maxHeight: dropdownHeight, minWidth: buttonWidth }}
          avoidCollisions={false}
        >
          <DropdownMenu.Item
            key="reset-item"
            className={cn(
              "flex gap-[10px] items-center py-3 px-2 cursor-pointer text-primary",
              {
                "cursor-not-allowed text-slate-400": resetDisabled,
              }
            )}
            onSelect={(e) => handleOnReset(e)}
            disabled={resetDisabled}
          >
            <TrashCanIcon fill="currentColor" width={16} />
            <span className="font-semibold">Reset</span>
          </DropdownMenu.Item>
          <DropdownMenu.Separator className="bg-slate-200 h-px w-full" />
          {filterConfig.options.map((option, idx) => (
            <Fragment key={option.value}>
              <DropdownMenu.CheckboxItem>
                <FilterCheckboxItem
                  key={option.value}
                  checked={getIsSelected(option)}
                  onSelect={(e) => handleOnSelect(e, option)}
                  label={renderLabel(option)}
                  labelChildren={filterConfig.renderLabelChildren?.(option)}
                />
              </DropdownMenu.CheckboxItem>
              {idx < filterConfig.options.length - 1 && (
                <DropdownMenu.Separator className="bg-slate-200 h-px w-full" />
              )}
            </Fragment>
          ))}
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  )
}

interface CheckboxItemProps {
  checked: boolean
  onSelect: React.MouseEventHandler
  label: ReactNode
  labelChildren?: ReactNode
  className?: string
}

export const FilterCheckboxItem: React.FC<CheckboxItemProps> = ({
  checked,
  onSelect,
  label,
  labelChildren,
}) => {
  return (
    <div className="flex items-center">
      <div
        className="group flex flex-1 gap-2 items-center p-2 cursor-pointer"
        onClick={onSelect}
      >
        <RupaCheckbox checked={checked} />
        <div className="font-semibold">{label}</div>
      </div>
      {labelChildren && (
        <div className="cursor-default pr-2">{labelChildren}</div>
      )}
    </div>
  )
}

export default Filter
