import { useContext } from "react"

import ComparisonContext from "../contexts/ComparisonContext"

/**
 * Hook that returns the comparison dispatch function.
 *
 * @example
 * ```
 * const dispatch = useComparisonDispatch()
 *
 * function onAddTest(labTest) {
 *   dispatch(Actions.addComparedTest(labTest.id, labTest))
 * }
 * ```
 *
 * @returns the comparison dispatch
 */
export default function useComparisonDispatch() {
  return useContext(ComparisonContext)[1]
}
