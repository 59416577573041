import { useEffect, useState } from "react"

import {
  Divider,
  FormHelperText,
  Link,
  Typography,
  makeStyles,
} from "@material-ui/core"

import questionMarkIcon from "app/assets/icons/question-mark.svg"
import Portal from "app/components/Portal"
import BodyText from "app/components/design-system/BodyText"
import useAppSelector from "app/hooks/useAppSelector"
import PaymentMethodBase from "app/main/patient-checkout/sections/payment-methods/PaymentMethodBase"
import {
  selectSplitItHowItWorksUrl,
  selectSplitItInstallmentAmounts,
} from "app/main/patient-checkout/store/selectors"
import { selectPractitioner } from "app/store/selectors/practitioner.selectors"
import { colors } from "app/theme"
import { PaymentMethodType } from "app/types"
import { formatDollars } from "app/utils"

import SplitItBillingAddressForm from "./SplitItBillingAddressForm"
import SplitItCreditCardForm from "./SplitItCreditCardForm"
import SplitItTermsAndConditions from "./SplitItTermsAndConditions"

interface SplitItPaymentMethodProps {
  active: boolean
  paymentMethodError?: string
  disabled: boolean
}

const SplitItPaymentMethod = ({
  active,
  paymentMethodError,
  disabled,
}: SplitItPaymentMethodProps) => {
  const classes = useStyles()
  const installmentAmounts = useAppSelector(selectSplitItInstallmentAmounts)
  const practitioner = useAppSelector(selectPractitioner)

  const greaterFirstInstallment =
    installmentAmounts &&
    Math.abs(installmentAmounts[0] - installmentAmounts[1]) > 0.01

  const installmentTextComponent = greaterFirstInstallment ? (
    <Typography
      className={classes.headerTitle}
      color="primary"
      component="span"
    >
      {"Pay "}
      <Typography className={classes.headerInstallmentAmount} component="span">
        {`${formatDollars(installmentAmounts[0])}`}
      </Typography>
      {" today + "}
      <Typography className={classes.headerInstallmentAmount} component="span">
        {`${formatDollars(installmentAmounts[1])}/month`}
      </Typography>
      {" for 2 months with SplitIt"}
    </Typography>
  ) : (
    <Typography
      className={classes.headerTitle}
      color="primary"
      component="span"
    >
      {"Pay in 3 interest-free installments"}
      {installmentAmounts && (
        <>
          {" of "}
          <Typography
            className={classes.headerInstallmentAmount}
            component="span"
          >
            {`${formatDollars(installmentAmounts[0])}/month`}
          </Typography>
          {" with SplitIt"}
        </>
      )}
    </Typography>
  )

  const greaterFirstInstallmentExplanation = greaterFirstInstallment && (
    <BodyText size="sm" weight="semibold">
      Your first installment will be {formatDollars(installmentAmounts[0])} and
      the following two will be {formatDollars(installmentAmounts[1])}. This is
      because of the fee added by {practitioner?.titled_full_name} that Rupa
      sends directly to them. Please contact them directly with any questions
      about this.
    </BodyText>
  )

  return (
    <PaymentMethodBase
      active={active}
      className={classes.wrapper}
      header={
        <SplitItHeader installmentTextComponent={installmentTextComponent} />
      }
      title="Pay in 3 interest-free installments"
      type={PaymentMethodType.SPLITIT}
      disabled={disabled}
    >
      {active && (
        <SplitItForm
          paymentMethodError={paymentMethodError}
          greaterFirstInstallmentExplanation={
            greaterFirstInstallmentExplanation
          }
        />
      )}
    </PaymentMethodBase>
  )
}

const SplitItHeader = ({ installmentTextComponent }) => {
  const classes = useStyles()

  return (
    <div className={classes.headerWrapper}>
      {installmentTextComponent}
      <div className={classes.howSplitItWorksWrapper}>
        <HowSplitItWorks />
      </div>
    </div>
  )
}

const SplitItForm = ({
  paymentMethodError,
  greaterFirstInstallmentExplanation,
}) => {
  const classes = useStyles()
  return (
    <div className={classes.splitItFormContainer}>
      {greaterFirstInstallmentExplanation}
      <SplitItCreditCardForm />
      {paymentMethodError && (
        <FormHelperText error>{paymentMethodError}</FormHelperText>
      )}
      <SplitItBillingAddressForm />
      <Divider className={classes.splitItFormDivider} />
      <SplitItTermsAndConditions />
    </div>
  )
}

const HowSplitItWorks = () => {
  const howItWorksUrl = useAppSelector(selectSplitItHowItWorksUrl)
  const [isOpen, setIsOpen] = useState(false)
  const classes = useStyles()

  if (!howItWorksUrl) {
    return null
  }

  function handleClick(e) {
    e.preventDefault()
    e.stopPropagation()
    e.nativeEvent.stopImmediatePropagation()

    setIsOpen(true)
  }

  return (
    <>
      <Link className={classes.howSplitItWorksLink} onClick={handleClick}>
        <Typography component="span">
          <img
            alt="How SplitIt Works"
            className={classes.howSplitItWorksIcon}
            src={questionMarkIcon}
          />
          <Typography className={classes.howSplitItWorksText} component="span">
            {"How SplitIt Works"}
          </Typography>
        </Typography>
      </Link>
      {isOpen && (
        <HowSplitItWorksPortal
          href={howItWorksUrl}
          onClose={() => setIsOpen(false)}
        />
      )}
    </>
  )
}

const HowSplitItWorksPortal = ({ href, onClose }) => {
  useEffect(() => {
    function handleMessage(event: MessageEvent) {
      if (event?.origin.endsWith(".splitit.com")) {
        onClose()
      }
    }

    window.addEventListener("message", handleMessage, false)
    return () => window.removeEventListener("message", handleMessage, false)
  }, [])

  return (
    <Portal id="how-splitit-works">
      <iframe
        title="How SplitIt Works"
        src={href}
        allowFullScreen
        style={{
          position: "fixed",
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          overflow: "hidden",
          height: "100%",
          width: "100%",
          border: "none",
          zIndex: 10000,
        }}
      />
    </Portal>
  )
}

const useStyles = makeStyles((theme) => ({
  wrapper: {
    borderTopWidth: "1px",
    borderColor: colors.gray[300],
    borderBottomRightRadius: "8px",
    borderBottomLeftRadius: "8px",
  },
  headerWrapper: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
      alignItems: "center",
    },
  },
  headerTitle: {
    fontWeight: theme.typography.fontWeightMedium,
  },
  headerInstallmentAmount: {
    fontWeight: theme.typography.fontWeightBold,
  },
  howSplitItWorksWrapper: {
    flex: "1 1 0%",
    marginTop: theme.spacing(1.25),
    [theme.breakpoints.up("sm")]: {
      marginTop: 0,
      textAlign: "right",
    },
  },
  howSplitItWorksLink: {
    display: "inline-flex",
    alignItems: "center",
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
  },
  howSplitItWorksIcon: {
    marginTop: 1,
    marginRight: theme.spacing(0.5),
    verticalAlign: "middle",
  },
  howSplitItWorksText: {
    fontWeight: theme.typography.fontWeightMedium,
    verticalAlign: "middle",
  },
  splitItFormContainer: {
    backgroundColor: colors.trueGray[100],
    padding: theme.spacing(2, 4),
  },
  splitItFormDivider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}))

export default SplitItPaymentMethod
