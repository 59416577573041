import format from "date-fns/format"

/**
 * Normalize dates for sending to the backend.
 * It's important to strip the timezone information from the date string first, to ensure that the correct date is stored.
 * @param date
 * @returns {string | *}
 */
export function normalizeDate(date) {
  const dateWithoutTimezone = new Date(date).toDateString()
  return format(new Date(dateWithoutTimezone), "yyyy-MM-dd")
}
