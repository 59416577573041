import { AnyLabTest, OptionType, labTestLocation } from "app/types"

export type FilterNames =
  | "lab_company"
  | "lab_test_type"
  | "phlebotomy_required"
  | "health_category"
  | "biomarker"
  | "lab_test"
  | "favorites"
  | "allowed_in_state"
  | "signing_practitioner"

export type NonFilterNames =
  | "order_by"
  | "order_direction"
  | "order_id"
  | "page"
  | "search"
  | "action"
  | "allow_comparisons"
  | "storefront_id"

export type QueryNames = FilterNames | NonFilterNames

export const FILTER_NAMES = new Set<FilterNames>([
  "lab_company",
  "lab_test_type",
  "phlebotomy_required",
  "health_category",
  "biomarker",
  "lab_test",
  "favorites",
  "allowed_in_state",
  "signing_practitioner",
])

export const QUERY_NAMES = new Set<QueryNames>([
  "lab_company",
  "lab_test_type",
  "phlebotomy_required",
  "favorites",
  "health_category",
  "biomarker",
  "allowed_in_state",
  "signing_practitioner",
  "order_by",
  "order_direction",
  "page",
  "search",
  "order_id",
  "action",
])

export type FiltersMap = {
  [filterName in FilterNames]: Map<
    string,
    {
      filterName: FilterNames
      value: string
      label: string
      index: number
      displayLocations?: labTestLocation[]
    }
  >
}

export interface NonFilterMapParam {
  queryName: NonFilterNames
  value: string
  label: string
  index: number
}

export interface QueryMap {
  filters: FiltersMap
  order_by?: NonFilterMapParam
  order_direction?: NonFilterMapParam
  page?: NonFilterMapParam
  search?: NonFilterMapParam
  shipping_state?: NonFilterMapParam
  clinic_state?: NonFilterMapParam
  clinic_country?: NonFilterMapParam
  using_physician_services?: NonFilterMapParam
  order_id?: NonFilterMapParam
  action?: NonFilterMapParam
}

export interface QueryParam {
  queryName: QueryNames
  value: string
  label: string | null
  nonRemovable?: boolean
}

export type Query = QueryParam[]

export interface FilterConfig {
  label: string
  filterName: FilterNames
  icon: string
  options: OptionType[]
  isRadio?: boolean
  renderLabel?: (option: OptionType) => React.ReactNode
  renderLabelChildren?: (option: OptionType) => React.ReactNode
  displayLocations?: labTestLocation[]
  defaultText?: string
}

export type OnSelectFilterOption = (
  filterName: FilterNames,
  option: OptionType,
  isRadio: boolean
) => void

export type OnSetQueryParams = (
  params: { queryName: NonFilterNames; value: string }[],
  shouldSetPending?: boolean
) => void

export type OnResetFilter = (filterName?: FilterNames) => void

export type SelectedLabTests = { [labTestId: string]: AnyLabTest }
