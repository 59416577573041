import { ORDERED_TEST_STATUS, ORDER_PAYER } from "app/constants.typed"
import { getRequisitionLabCompanyName } from "app/utils/order-status-utils"

import { StatusConfiguration } from "../../types"
import { StatusConfigurationProps } from "../../types/patient-portal-types"
import { PROGRESS_PERCENTAGES } from "../consts"

const Title = ({
  order,
  labCompany,
  orderedTest,
}: StatusConfigurationProps) => {
  if (
    order &&
    order?.attributes.payer !== ORDER_PAYER.PATIENT &&
    !order?.attributes.date_patient_checkout
  ) {
    return "Consent Form Pending"
  }

  switch (orderedTest.attributes.sample_collection_coordinator) {
    // We don't allow patients to generate requisitions for clinic orders
    case "LAB":
      if (orderedTest.attributes.requisition) {
        return "Requisition is Ready"
      } else {
        const requisitionLabCompanyName = getRequisitionLabCompanyName(
          labCompany?.attributes?.key
        )
        return `Awaiting requisition from ${requisitionLabCompanyName}`
      }
    default:
      if (!order) return null
      if (order.attributes.payer !== ORDER_PAYER.PATIENT) {
        return "Consent Form Complete"
      }
      return "Invoice Paid"
  }
}

const SubTitle = ({ labCompany, orderedTest }: StatusConfigurationProps) => {
  switch (orderedTest.attributes.sample_collection_coordinator) {
    case "LAB":
      if (!orderedTest.attributes.requisition) {
        const requisitionLabCompanyName = getRequisitionLabCompanyName(
          labCompany?.attributes.key
        )
        return `Please do not go to a ${requisitionLabCompanyName} location until you have your requisition form.`
      }
  }
}

const progressPercentage = ({
  order,
  orderedTest,
}: StatusConfigurationProps) => {
  return orderedTest.attributes.sample_collection_coordinator === "LAB" ||
    order?.attributes.payer !== ORDER_PAYER.PATIENT
    ? PROGRESS_PERCENTAGES.PAID
    : PROGRESS_PERCENTAGES.AWAITING_PATIENT_CHECKOUT
}

const paidStatusConfiguration: StatusConfiguration<StatusConfigurationProps> = {
  when: { orderedTestStatus: ORDERED_TEST_STATUS.PAID },
  progressPercentage: progressPercentage,
  title: Title,
  subTitle: SubTitle,
}

export default paidStatusConfiguration
