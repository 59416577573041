import BodyText from "app/components/design-system/BodyText"

import BiomarkerCustomDescriptionsTable from "./BiomarkerCustomDescriptionTable/BiomarkerCustomDescriptionsTable"

const RupaBloodDashboardsCustomizeBiomarkerDescriptions = () => {
  return (
    <>
      <div>
        <BodyText weight="semibold" size="md">
          Customize Biomarker Descriptions (Optional)
        </BodyText>

        <BodyText>
          Rupa already provides descriptions for every biomarker! You can
          optionally modify any of these descriptions by customizing a biomarker
          description below.
        </BodyText>
      </div>

      <div>
        <BiomarkerCustomDescriptionsTable />
      </div>
    </>
  )
}

export default RupaBloodDashboardsCustomizeBiomarkerDescriptions
