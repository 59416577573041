import * as React from "react"
import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"

import { MTable, MTablePagination } from "material-table"
import { useLocation } from "react-router-dom"

import EmptyPatientOrdersImage from "app/assets/images/empty-patient-orders.svg"
import Loading from "app/components/Loading"
import PageEmptyState from "app/components/PageEmptyState"
import BodyText from "app/components/design-system/BodyText"
import { ORDER_STATUS } from "app/constants"
import useAppSelector from "app/hooks/useAppSelector"
import { Patient } from "app/types"
import makeAppStyles from "app/utils/makeAppStyles"

import PatientOrdersOrder from "./PatientOrdersOrder"
import { usePatientOrdersListQueryState } from "./hooks/use-patient-orders-list"
import * as Actions from "./store/actions"

const useStyles = makeAppStyles({
  container: {
    display: "flex",
    flexFlow: "column",
    marginBottom: 24,
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-end",
    marginBottom: 14,
  },
  paginationControls: {
    alignSelf: "flex-end",
    justifySelf: "flex-end",

    // Reverses the margin that the MuiTable pagination already has
    marginBottom: -9,
  },
})

function useOrderListTitle() {
  const ordersPage = useAppSelector(({ patient }) => patient.orders)
  const scheduledOrdersPage = useAppSelector(
    ({ patient }) => patient.scheduledOrders
  )

  const isPaginated =
    (ordersPage?.count || 0) > Actions.PATIENT_ORDERS_PAGE_SIZE
  const hasScheduledOrders = Boolean(scheduledOrdersPage?.results?.length)

  if (hasScheduledOrders) {
    return "Placed Orders"
  } else if (isPaginated) {
    return "Orders"
  }

  return null
}

const PatientOrdersList = ({
  showDrafts: initialShowDrafts,
  patient,
}: {
  showDrafts: boolean
  patient: Patient
}) => {
  const location = useLocation()
  const query = new URLSearchParams(location.search)
  const [showDrafts, setShowDrafts] = useState(initialShowDrafts)

  const dispatch = useDispatch()
  const styles = useStyles()
  const ordersPage = useAppSelector(({ patient }) => patient.orders)
  const orderId = query.get("orderId")
  const title = useOrderListTitle()

  const { status, page, setPageQueryParam, setStatusQueryParam } =
    usePatientOrdersListQueryState()

  useEffect(() => {
    setShowDrafts(status === ORDER_STATUS.DRAFT)
  }, [status])

  // Load orders whenever the page or showDrafts changes
  useEffect(() => {
    dispatch(
      Actions.getOrdersByPatient({
        patientId: patient.id,
        page,
        showDrafts,
        orderId,
      })
    )

    return () => {
      dispatch(Actions.clearOrders())
    }
  }, [patient.id, dispatch, page, showDrafts])

  if (!ordersPage) {
    return <Loading ariaLabel="Loading orders" />
  }

  const { results: orders, count, next } = ordersPage

  if (orders.length === 0) {
    return (
      <PageEmptyState
        title={`${patient.first_name} doesn’t have any submitted orders yet.`}
        subtitle={
          showDrafts ? (
            <span>
              Are you looking for a submitted order? See {patient.first_name}’s
              submitted orders{" "}
              <span
                className="text-primary font-semibold p-0 hover:text-primary-600 cursor-pointer"
                onClick={() => setStatusQueryParam(null)}
              >
                here
              </span>
              .
            </span>
          ) : (
            <span>
              Are you looking for a draft order? See {patient.first_name}’s
              drafts{" "}
              <span
                className="text-primary font-semibold p-0 hover:text-primary-600 cursor-pointer"
                onClick={() => setStatusQueryParam(ORDER_STATUS.DRAFT)}
              >
                here
              </span>
              .
            </span>
          )
        }
        image={EmptyPatientOrdersImage}
      />
    )
  }

  let paginationControls: React.ReactNode = null
  const showPagination = next || page > 1

  if (showPagination) {
    paginationControls = (
      <div className={styles.paginationControls}>
        <MTablePagination
          count={count}
          rowsPerPage={Actions.PATIENT_ORDERS_PAGE_SIZE}
          page={page - 1}
          onChangePage={(event, newPage) => {
            setPageQueryParam(newPage + 1)
          }}
          // @ts-ignore
          icons={MTable.defaultProps.icons}
          showFirstLastPageButtons={false}
        />
      </div>
    )
  }

  return (
    <div className={styles.container}>
      {Boolean(title || paginationControls) && (
        <div className={styles.header}>
          <BodyText size="md" weight="semibold">
            {title}
          </BodyText>
          {paginationControls}
        </div>
      )}
      {orders.map((order, index) => (
        <PatientOrdersOrder key={order.id} order={order} orderIndex={index} />
      ))}
      {paginationControls}
    </div>
  )
}

export default PatientOrdersList
