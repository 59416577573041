import { useEffect } from "react"

import { debounce } from "lodash"
import { FieldErrors, useForm } from "react-hook-form"
import { z } from "zod"

import { zodResolver } from "@hookform/resolvers/zod"

import { getIdentifier } from "app/swr/helpers/resource"
import { Biomarker } from "types/biomarker"
import { BiomarkerCustomDescription } from "types/biomarker-custom-description"

import usePatchCustomBiomarkerDescription from "./use-patch-custom-biomarker-description"

/**
 * The schema for the biomarker attributes that can be edited.
 */
export const CustomBiomarkerDescriptionEditableAttributesSchema = z.object({
  description: z.string().nullable(),
  low_description: z.string().nullable(),
  high_description: z.string().nullable(),
})

/**
 * Sets up a form hook to edit a biomarker. The form is debounced to avoid sending too many requests to the server.
 *
 * @param biomarker the biomarker to edit
 * @returns a form hook to edit the biomarker
 */
export default function useBiomarkerCustomDescriptionForm(
  biomarkerCustomDescription: BiomarkerCustomDescription,
  biomarker?: Biomarker
) {
  const { patchBiomarkerCustomDescription, loading } =
    usePatchCustomBiomarkerDescription(
      getIdentifier(biomarkerCustomDescription),
      biomarker
    )

  const methods = useForm<BiomarkerCustomDescription["attributes"]>({
    criteriaMode: "all",
    defaultValues: {
      description: Boolean(biomarkerCustomDescription.attributes.description)
        ? biomarkerCustomDescription.attributes.description
        : biomarker?.attributes.description,
      low_description: Boolean(
        biomarkerCustomDescription.attributes.low_description
      )
        ? biomarkerCustomDescription.attributes.low_description
        : biomarker?.attributes.low_description,
      high_description: Boolean(
        biomarkerCustomDescription.attributes.high_description
      )
        ? biomarkerCustomDescription.attributes.high_description
        : biomarker?.attributes.high_description,
    },
    mode: "onSubmit",
    resolver: zodResolver(CustomBiomarkerDescriptionEditableAttributesSchema),
    reValidateMode: "onSubmit",
    shouldFocusError: false,
    shouldUnregister: true,
  })

  const { handleSubmit, watch } = methods

  /**
   * On valid form data, send the data to the server.
   */
  const onValid = patchBiomarkerCustomDescription

  /**
   * On invalid form data, only send the valid data to the server.
   * @param data the data received during the change event
   */
  const onInvalid =
    (data: BiomarkerCustomDescription["attributes"]) =>
    (errors: FieldErrors<BiomarkerCustomDescription["attributes"]>) => {
      const validData = Object.fromEntries(
        Object.entries(data).filter(([key]) => !errors[key])
      ) as BiomarkerCustomDescription["attributes"]

      patchBiomarkerCustomDescription(validData)
    }

  const handleSubmitForm = async (data) => {
    await handleSubmit(onValid, onInvalid(data))()
  }

  /**
   * Debounce the form submission on change to avoid sending too many requests to the server.
   */
  useEffect(() => {
    const debounceOnSubmit = debounce(
      (data) => handleSubmit(onValid, onInvalid(data))(),
      600
    )
    const subscription = watch(debounceOnSubmit)

    return () => {
      // Rather than cancel, we flush, as this ensures the save still goes through.
      debounceOnSubmit.flush()

      subscription.unsubscribe()
    }
  }, [handleSubmit, watch])

  return { methods, handleSubmitForm, isPatchLoading: loading }
}
