import { Skeleton } from "@material-ui/lab"

import { ReactComponent as BiomarkerIcon } from "app/assets/icons/biomarker.svg"
import BodyText from "app/components/design-system/BodyText"
import InfoTextTooltip from "app/components/design-system/InfoTextTooltip"
import { colors, primaryColor } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"
import { BiomarkerIdentifier } from "types/biomarker"

import { Panel } from "../types"

const useStyles = makeAppStyles((theme) => ({
  biomarkerIcon: {
    marginRight: 4,
  },
  biomarkerContainer: {
    display: "flex",
    flexWrap: "wrap",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  infoIcon: {
    width: 12,
    height: 12,
    marginLeft: 4,
    marginTop: 4.5,
    display: "flex",
    alignItems: "center",
    fontWeight: 100,
  },
  tooltipText: {
    textAlign: "left",
    fontWeight: 400,
  },
}))

export default function PanelItemBiomarkers({
  panel,
  combinedSelectedBiomarkerIdentifiers,
}: {
  panel?: Panel
  combinedSelectedBiomarkerIdentifiers: BiomarkerIdentifier[]
}) {
  const classes = useStyles()

  // Return skeleton if panel is not loaded yet
  if (!panel) {
    return <Skeleton className={classes.biomarkerContainer} />
  }

  return (
    <span className={classes.biomarkerContainer}>
      <BodyText weight="semibold">
        <BiomarkerIcon
          fill={colors.blueGray[400]}
          className={classes.biomarkerIcon}
        />
        {panel.biomarkers.length} of{" "}
        {combinedSelectedBiomarkerIdentifiers.length} Biomarkers
      </BodyText>
      <InfoTextTooltip iconFill={primaryColor} className={classes.infoIcon}>
        <div className={classes.tooltipText}>
          {panel.lab_company.name} has {panel.biomarkers.length} out of the{" "}
          {combinedSelectedBiomarkerIdentifiers.length} biomarkers you selected.
        </div>
      </InfoTextTooltip>
    </span>
  )
}
