import { ComponentType, useRef } from "react"
import { useStore } from "react-redux"

import { Reducer } from "redux"

import { RupaStore } from "./configureStore"

const withReducer =
  (key: string, reducer: Reducer<any, any>) =>
  <P extends object>(WrappedComponent: ComponentType<P>): ComponentType<P> =>
  (props: P) => {
    const store = useStore() as RupaStore
    const isInjected = useRef(false)

    // inject the reducer on the first render
    if (!isInjected.current) {
      store.injectReducer(key, reducer)
      isInjected.current = true
    }

    return <WrappedComponent {...props} />
  }

export default withReducer
