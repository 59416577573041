import { isEmpty } from "lodash"

import { ReactComponent as BiomarkerIcon } from "app/assets/icons/biomarker.svg"
import { ReactComponent as DownloadBlueIcon } from "app/assets/images/download-blue.svg"
import { useAcknowledgeUnreadResult } from "app/dashboard/components/TableRowDetailRenderer/actions/DownloadResultsDropdownMenuAction"
import useEventCallback from "app/hooks/use-event-callback"
import { colors, primaryColor } from "app/theme"
import interleave from "app/utils/interleave"
import makeAppStyles from "app/utils/makeAppStyles"
import { Biomarker } from "types/biomarker"

const useStyles = makeAppStyles((theme) => ({
  container: {
    padding: 13,
    borderRadius: "9.103px",
    border: `1.517px solid ${colors.blueGray[200]}`,
    background: "white",
    display: "flex",
  },
  textContainer: {
    marginLeft: 5,
    width: "90%",
  },
  descriptionSection: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  },
  introText: {
    fontFamily: "Open Sans",
    fontSize: "15px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "135%",
    marginBottom: 5,
    [theme.breakpoints.down("sm")]: {
      flexGrow: 1,
      width: "100%",
    },
  },
  biomarkerText: {
    fontFamily: "Open Sans",
    fontSize: "15px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "135%",
    marginBottom: 5,
    marginLeft: 5,
  },
  link: {
    color: primaryColor,
    cursor: "pointer",
    fontWeight: 600,
    display: "flex",
    alignItems: "center",
  },
  biomarkerIcon: {
    marginTop: 2,
    width: 20,
    height: 20,
  },
  downloadIcon: {
    marginLeft: 5,
  },
}))

export default function BloodReportsBiomarkerMissingWarning({
  biomarkers,
  downloadableResultUrls,
  orderedResultId,
}: {
  downloadableResultUrls: string[]
  biomarkers: Biomarker[]
  orderedResultId: string
}) {
  const classes = useStyles()
  const hasResultsToDownload = !isEmpty(downloadableResultUrls)
  const acknowledgeUnreadResult = useAcknowledgeUnreadResult()
  const onDownloadResults = useEventCallback(async () => {
    if (!hasResultsToDownload) {
      return
    }

    downloadableResultUrls.forEach((url, index) => {
      window.open(url, `result_${index}`)
    })
    acknowledgeUnreadResult(orderedResultId)
  })
  return (
    <div className={classes.container}>
      <BiomarkerIcon className={classes.biomarkerIcon} viewBox="0 0 14 14" />
      <div className={classes.textContainer}>
        <div className={classes.descriptionSection}>
          <div className={classes.introText}>
            Some biomarkers could not be displayed:
          </div>
          {interleave(
            biomarkers.map((biomarker) => (
              <div className={classes.biomarkerText}>
                {biomarker.attributes.short_name}
              </div>
            )),
            ","
          )}
        </div>
        <div className={classes.link} onClick={onDownloadResults}>
          See them all in the original report
          <DownloadBlueIcon className={classes.downloadIcon} />
        </div>
      </div>
    </div>
  )
}
