import React, { useEffect, useState } from "react"

import { validateFileMime } from "app/components/upload/helpers"
import { RESULTS_UPLOAD_IMAGE_TYPES } from "app/main/results-upload-v2/results-upload-modal/utils"
import { getRandomId } from "app/utils/get-random-id"
import clone from "lodash/clone"
import isEmpty from "lodash/isEmpty"

import { ResultsUploadProps } from "../hooks/use-results-upload"
import { Step1Upload } from "./Step1Upload"
import { Step2Confirm } from "./Step2Confirm"

export enum ResultsUploadFormStep {
  Upload = "upload",
  Confirm = "confirm",
}

export type ResultsUploadFileGroup = { [fileId: string]: File }
export type ResultsUploadFileGroups = {
  [groupId: string]: ResultsUploadFileGroup | File
}

interface Props extends ResultsUploadProps {
  onClose: (force?: boolean) => void
  setDirty: (dirty: boolean) => void
}

export const ResultsUploadForm: React.FC<Props> = ({ setDirty, ...props }) => {
  const [step, setStep] = useState<ResultsUploadFormStep>(
    ResultsUploadFormStep.Upload
  )
  const [fileGroups, setFileGroups] = useState<ResultsUploadFileGroups>({})

  /**
   * Set the dirty state based on whether files are uploaded
   */
  useEffect(() => {
    setDirty(Object.keys(fileGroups).length > 0)
  }, [fileGroups, setDirty])

  /**
   * Add files to a group or create new groups
   */
  const addFiles = (files: File[], groupId?: string) => {
    setFileGroups((p) => {
      const next = clone(p)

      if (groupId && next[groupId]) {
        files.forEach((file) => {
          next[groupId][getRandomId()] = file
        })
        return next
      }

      const imagesGroup: ResultsUploadFileGroup = {}

      files.forEach((file) => {
        if (validateFileMime(file, ".pdf")) {
          next[getRandomId()] = file
        } else if (validateFileMime(file, RESULTS_UPLOAD_IMAGE_TYPES)) {
          imagesGroup[getRandomId()] = file
        }
      })

      if (!isEmpty(imagesGroup)) {
        next[getRandomId()] = imagesGroup
      }
      return next
    })
  }

  /**
   * Remove a file from a group or remove the group
   */
  const removeFile = (groupId: string, fileId?: string) => {
    setFileGroups((prev) => {
      if (!prev[groupId]) return prev

      const next = clone(prev)

      if (!fileId) {
        delete next[groupId]
        return next
      }

      delete next[groupId][fileId]

      if (isEmpty(next[groupId])) {
        delete next[groupId]
      }
      return next
    })
  }

  if (step === ResultsUploadFormStep.Upload) {
    return (
      <Step1Upload
        {...props}
        fileGroups={fileGroups}
        addFiles={addFiles}
        removeFile={removeFile}
        setStep={setStep}
      />
    )
  } else if (step === ResultsUploadFormStep.Confirm) {
    return (
      <Step2Confirm
        {...props}
        fileGroups={fileGroups}
        goBack={() => setStep(ResultsUploadFormStep.Upload)}
      />
    )
  }

  return null
}
