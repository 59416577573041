import { Children, Fragment, ReactNode } from "react"

/**
 * Interleaves the children nodes with separator nodes.
 *
 * @example
 * interleaveChildren(
 *   [<div key="a">a</div>, <div key="b">b</div>],
 *   () => <span>separator</span>
 * )
 *
 * @param children the children nodes
 * @param renderSeparator a function that returns the separator node
 * @returns the children nodes interleaved with the separator nodes
 */
export default function interleaveChildren(
  children: ReactNode,
  renderSeparator: () => ReactNode
) {
  const childrenCount = Children.count(children)
  return Children.map(children, (child, index) => {
    if (index >= childrenCount - 1) {
      return child
    }

    return [child, <Fragment key={index}>{renderSeparator()}</Fragment>]
  })
}
