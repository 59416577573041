/**
 * Keeps track of a refs width in a state variable
 */
import React, { useEffect, useState } from "react"

export const useRefWidth = (
  ref: React.MutableRefObject<HTMLElement | null>
) => {
  const [width, setWidth] = useState<number>()
  const timer = React.useRef<NodeJS.Timer>()

  const resetWidth = () => {
    if (!ref.current) return
    setWidth(ref.current.clientWidth)
  }

  useEffect(() => {
    let attempts = 0
    while (attempts < 20 && !ref.current) {
      timer.current = setInterval(resetWidth, 500)
      attempts++
    }

    window.addEventListener("resize", resetWidth)

    return () => {
      window.removeEventListener("resize", resetWidth)
      if (timer.current) clearTimeout(timer.current)
    }
  }, [])

  return width
}
