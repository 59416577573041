import * as Actions from "../actions"

const initialState = {
  bankAccounts: null,
  pending: false,
  addAccountPending: false,
}

const bankAccountsReducer = function (state = initialState, action) {
  switch (action.type) {
    case Actions.CLEAR_ORDER: {
      return { ...initialState }
    }
    case Actions.GET_BANK_ACCOUNTS_REQUEST: {
      return {
        ...state,
        pending: true,
      }
    }
    case Actions.GET_BANK_ACCOUNTS_SUCCESS: {
      return {
        ...state,
        bankAccounts: action.payload,
        pending: false,
      }
    }
    case Actions.GET_BANK_ACCOUNTS_FAILURE: {
      return {
        ...state,
        pending: false,
      }
    }
    case Actions.ADD_BANK_ACCOUNT_REQUEST: {
      return {
        ...state,
        addAccountPending: true,
      }
    }
    case Actions.ADD_BANK_ACCOUNT_SUCCESS: {
      return {
        ...state,
        addAccountPending: false,
        bankAccounts: [...(state.bankAccounts || []), action.payload],
      }
    }
    case Actions.ADD_BANK_ACCOUNT_FAILURE: {
      return {
        ...state,
        addAccountPending: false,
      }
    }
    case Actions.DELETE_BANK_ACCOUNT: {
      return {
        ...state,
        bankAccounts: state.bankAccounts.filter(
          (account) => account.id !== action.payload
        ),
      }
    }
    case Actions.UPDATE_BANK_ACCOUNT: {
      return {
        ...state,
        bankAccounts: state.bankAccounts.map((bankAccount) =>
          bankAccount.id === action.payload.id ? action.payload : bankAccount
        ),
      }
    }
    default: {
      return state
    }
  }
}

export default bankAccountsReducer
