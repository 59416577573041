import { PatientPortalUser } from "app/patient-portal/types"
import useCachedCollection from "app/swr/hooks/use-cached-collection"
import useCollectionSWR from "app/swr/hooks/use-collection-swr"
import { ResourceCollection } from "app/swr/types"

export default function usePatientPortalUsers() {
  const { data, isLoading } = useCollectionSWR<
    ResourceCollection<PatientPortalUser>
  >(
    `/patient_portal/user/`,
    {
      include: ["patient"],
    },
    {
      revalidateIfStale: false,
      revalidateOnMount: true,
      revalidateOnFocus: false,
      revalidateOnReconnect: true,
    }
  )

  const users = useCachedCollection<PatientPortalUser>(data)

  return {
    users,
    isLoading,
  }
}
