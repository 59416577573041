import * as React from "react"

import { find } from "lodash"

import Tooltip from "app/components/Tooltip"
import InfoTextTooltip from "app/components/design-system/InfoTextTooltip"
import RupaLogoIcon from "app/components/svgs/RupaLogoIcon"
import { VENDOR_PHYSICIAN_AUTHORIZATION_LABEL } from "app/constants"
import { textPrimaryColor } from "app/theme"
import {
  OneOfAnyPractitionerOption,
  DefaultPractitionerOption,
  PractitionerOptionType,
  VendorPhysAuthPractitionerOption,
  VENDOR_PHYS_AUTH_PRACTITIONER_OPTION,
} from "app/types"
import makeAppStyles from "app/utils/makeAppStyles"

import PractitionerSelect from "./PractitionerSelect"

const usePhysicianAuthorizationDisplayValueStyles = makeAppStyles({
  displayValue: {
    display: "flex",
    gap: 6,
    alignItems: "center",
  },
})

export const PhysicianAuthorizationDisplayValue = () => {
  const classes = usePhysicianAuthorizationDisplayValueStyles()
  return (
    <div className={classes.displayValue}>
      <RupaLogoIcon height={16} width={16} />
      {VENDOR_PHYSICIAN_AUTHORIZATION_LABEL}
    </div>
  )
}

export interface SigningPractitionerSelectItemProps {
  option: DefaultPractitionerOption
}

export const SigningPractitionerSelectItem = ({
  option,
}: SigningPractitionerSelectItemProps) => {
  return (
    <PractitionerSelect.Item value={option.practitioner.id}>
      {option.practitioner.user.full_name}
    </PractitionerSelect.Item>
  )
}

export interface VendorPhysAuthSigningPractitionerSelectItemProps {
  option: VendorPhysAuthPractitionerOption
}

export const VendorPhysAuthSigningPractitionerSelectItem = ({
  option,
}: VendorPhysAuthSigningPractitionerSelectItemProps) => {
  return (
    <PractitionerSelect.Item value={option.type}>
      <PhysicianAuthorizationDisplayValue />
    </PractitionerSelect.Item>
  )
}

const useSelectStyles = makeAppStyles({
  label: {
    display: "flex",
    alignItems: "center",
    gap: 4,
    fontSize: 11,
    fontWeight: 400,
    lineHeight: 1.5,
    color: textPrimaryColor,
    marginBottom: 4,
  },
  infoText: {
    display: "inline-flex",
    "& svg": {
      width: 13,
      height: 13,
    },
  },
})

export interface SigningPractitionerSelectProps {
  infoText: string
  label: React.ReactNode
  onSelect: (option?: OneOfAnyPractitionerOption) => void
  options: OneOfAnyPractitionerOption[]
  value?: OneOfAnyPractitionerOption
  tooltipText: any
}

function getSelectDisplayValue(value?: OneOfAnyPractitionerOption) {
  if (!value) {
    return undefined
  }

  switch (value.type) {
    case PractitionerOptionType.DEFAULT:
      return value.practitioner.user.full_name
    case PractitionerOptionType.VENDOR_PHYS_AUTH:
      return <PhysicianAuthorizationDisplayValue />
    default:
      return undefined
  }
}

function getSelectValue(value?: OneOfAnyPractitionerOption) {
  if (!value) {
    return undefined
  }

  switch (value.type) {
    case PractitionerOptionType.DEFAULT:
      return value.practitioner.id
    case PractitionerOptionType.VENDOR_PHYS_AUTH:
      return value.type
    default:
      return undefined
  }
}

export default function SigningPractitionerSelect({
  infoText,
  label,
  onSelect,
  options,
  value,
  tooltipText,
}: SigningPractitionerSelectProps) {
  const classes = useSelectStyles()
  const selectDisplayValue = getSelectDisplayValue(value)
  const selectValue = getSelectValue(value)

  return (
    <div>
      <label className={classes.label} id="signing-practitioner-label">
        {label}
        <InfoTextTooltip className={classes.infoText}>
          {infoText}
        </InfoTextTooltip>
      </label>
      <Tooltip title={tooltipText} placement="bottom" arrow interactive>
        <div>
          <PractitionerSelect
            aria-labelledby="signing-practitioner-label"
            displayValue={selectDisplayValue}
            onValueChange={(nextValue) => {
              if (nextValue === PractitionerOptionType.VENDOR_PHYS_AUTH) {
                onSelect(VENDOR_PHYS_AUTH_PRACTITIONER_OPTION)
              } else {
                const nextOption = find(
                  options,
                  (option) =>
                    option.type === PractitionerOptionType.DEFAULT &&
                    option.practitioner.id === nextValue
                )
                onSelect(nextOption ? nextOption : undefined)
              }
            }}
            readOnly={Boolean(value) && options.length <= 1}
            value={selectValue}
          >
            {options.map((option) => {
              if (option.type === PractitionerOptionType.VENDOR_PHYS_AUTH) {
                return (
                  <VendorPhysAuthSigningPractitionerSelectItem
                    key={option.type}
                    option={option}
                  />
                )
              }
              return (
                <SigningPractitionerSelectItem
                  key={option.practitioner.id}
                  option={option}
                />
              )
            })}
          </PractitionerSelect>
        </div>
      </Tooltip>
    </div>
  )
}
