import axios from "axios"
import useSWR from "swr"

import { getApiBaseUrl } from "app/utils"

export interface AccountExistsResponse {
  account_exists: boolean
}

export default function useAccountExists(patientPortalToken: string | null) {
  return useSWR<AccountExistsResponse>(
    patientPortalToken ? "/patient_portal/token/account_exists" : undefined,
    {
      async fetcher(url) {
        const response = await axios.request<AccountExistsResponse>({
          baseURL: getApiBaseUrl() + "/api/",
          url,
          method: "post",
          data: { token: patientPortalToken },
        })

        return response.data
      },
    }
  )
}
