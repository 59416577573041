import * as Actions from "../actions"

const phlebotomistReducer = function (state = {}, action) {
  switch (action.type) {
    case Actions.GET_PHLEBOTOMISTS: {
      return action.payload
    }
    default: {
      return state
    }
  }
}

export default phlebotomistReducer
