import { BiomarkerStatus } from "app/patient-portal/blood-lab-dashboard/constants"
import { colors } from "app/theme"

import { gradients } from "../blood-lab-dashboards/NumericBiomarkerGraphic/constants"
import {
  getColorFromGradient,
  getRatioBetweenPoints,
} from "../blood-lab-dashboards/NumericBiomarkerGraphic/utils"
import { STATUS_GRADIENT_MAP } from "../patient-orders/trends/constants/constants"
import { ResultsOverTimeResultChartData } from "../patient-orders/trends/types/types"
import CustomDot from "./chart-components/CustomDot"

const INACTIVE_DOT_SIZE = "26"
const DOT_SIZE_SIMPLE = "17"
const ACTIVE_DOT_SIZE = "31"
const INACTIVE_DOT_C_DEDUCTION = 12
const DOT_C_DEDUCTION_SIMPLE = 9
const ACTIVE_DOT_C_DEDUCTION = 14.5

/**
 * Renders a custom dot for an Area Chart or Line Chart for the Recharts library.
 *
 * Dot rendered will be based upon standard and optimal ranges to determine coloring,
 * and will be larger if the dot is being hovered over or clicked.
 */
export const renderDot = ({ cx, cy, value, payload, activeDot, hover }) => {
  const yValue = value
  const isClickedActive = activeDot === payload.created_at
  const isActive = isClickedActive || hover
  const width = isActive ? ACTIVE_DOT_SIZE : INACTIVE_DOT_SIZE
  const height = isActive ? ACTIVE_DOT_SIZE : INACTIVE_DOT_SIZE
  const deductionC = isActive
    ? ACTIVE_DOT_C_DEDUCTION
    : INACTIVE_DOT_C_DEDUCTION

  if (Number.isNaN(yValue)) {
    return <></>
  }

  const standardRangeMinStringified = isNaN(payload.standardRangeMin)
    ? ""
    : payload.standardRangeMin.toString()
  const standardRangeMaxStringified = isNaN(payload.standardRangeMax)
    ? ""
    : payload.standardRangeMax.toString()
  const optimalRangeMinStringified = isNaN(payload.optimalRangeMin)
    ? ""
    : payload.optimalRangeMin.toString()
  const optimalRangeMaxStringified = isNaN(payload.optimalRangeMax)
    ? ""
    : payload.optimalRangeMax.toString()

  if (
    Number.isNaN(payload.standardRangeMin) &&
    Number.isNaN(payload.standardRangeMax)
  ) {
    return (
      <CustomDot
        cx={cx}
        cy={cy}
        fillColor={colors.blueGray[400]}
        cxDeduction={deductionC}
        cyDeduction={deductionC}
        width={width}
        height={height}
        showActiveHighlight={isClickedActive}
        showShadows={true}
      />
    )
  }

  const status = payload.status
  const ratio = getRatioBetweenPoints(
    yValue,
    standardRangeMinStringified,
    standardRangeMaxStringified,
    optimalRangeMinStringified,
    optimalRangeMaxStringified
  )

  const color = status
    ? getColorFromGradient(STATUS_GRADIENT_MAP[status], ratio * 100)
    : colors.blueGray[400]

  return (
    <CustomDot
      cx={cx}
      cy={cy}
      fillColor={color}
      cxDeduction={deductionC}
      cyDeduction={deductionC}
      width={width}
      height={height}
      showActiveHighlight={isClickedActive}
      showShadows={true}
    />
  )
}

/**
 * Renders a simple custom dot for an Area Chart or Line Chart for the Recharts library.
 *
 * "Simple" here means that the dot will not have any hover functionality or shadows
 *
 * Dot rendered will be based upon standard and optimal ranges to determine coloring,
 * and will be larger if the dot is being hovered over or clicked.
 */
export const renderSimpleDot = ({ cx, cy, value, payload, id }) => {
  const width = DOT_SIZE_SIMPLE
  const height = DOT_SIZE_SIMPLE
  const deductionC = DOT_C_DEDUCTION_SIMPLE

  if (Number.isNaN(value)) {
    return <></>
  }

  let fillColor = colors.emerald[500]
  if (
    Number.isNaN(payload.standardRangeMin) &&
    Number.isNaN(payload.standardRangeMax)
  ) {
    fillColor = colors.blueGray[400]
  } else if (
    payload.status === BiomarkerStatus.LOW ||
    payload.status === BiomarkerStatus.HIGH
  ) {
    fillColor = colors.rose[500]
  } else if (
    payload.status === BiomarkerStatus.BELOW_OPTIMAL ||
    payload.status === BiomarkerStatus.ABOVE_OPTIMAL
  ) {
    fillColor = colors.lime[300]
  }

  return (
    <CustomDot
      key={id}
      cx={cx}
      cy={cy}
      fillColor={fillColor}
      cxDeduction={deductionC}
      cyDeduction={deductionC}
      width={width}
      height={height}
      showActiveHighlight={false}
      showShadows={false}
    />
  )
}

export const parseStringValueToFloat = (value?: string | null): number => {
  if (!value) return parseFloat("") // returning NaN if no value

  const regex = /[-+]?\d*\.?\d+/
  const matches = value.match(regex)

  return matches ? parseFloat(matches[0]) : parseFloat("") // returning NaN if no matches
}

export const roundNumber = (value: number) => {
  return Math.round(value * 100) / 100
}

export const calculateInbetweenLinearGradientColor = (
  currStatus: BiomarkerStatus,
  previousStatus: BiomarkerStatus
): string | null => {
  let inBetweenColor: string | null = null

  if (
    (previousStatus === BiomarkerStatus.LOW &&
      [BiomarkerStatus.NORMAL, BiomarkerStatus.OPTIMAL].includes(currStatus)) ||
    (currStatus === BiomarkerStatus.LOW &&
      [BiomarkerStatus.NORMAL, BiomarkerStatus.OPTIMAL].includes(
        previousStatus
      ))
  ) {
    inBetweenColor = getColorFromGradient(gradients.nonOptimal.active, 100)
  } else if (
    (previousStatus === BiomarkerStatus.HIGH &&
      [BiomarkerStatus.NORMAL, BiomarkerStatus.OPTIMAL].includes(currStatus)) ||
    (currStatus === BiomarkerStatus.HIGH &&
      [BiomarkerStatus.NORMAL, BiomarkerStatus.OPTIMAL].includes(
        previousStatus
      ))
  ) {
    inBetweenColor = getColorFromGradient(gradients.nonOptimal.active, 0)
  }

  return inBetweenColor
}

const getMinValue = (a, b) => {
  if (isNaN(a) && isNaN(b)) {
    return NaN
  } else if (isNaN(a)) {
    return b
  } else if (isNaN(b)) {
    return a
  } else {
    return Math.min(a, b)
  }
}

const getMaxValue = (a, b) => {
  if (isNaN(a) && isNaN(b)) {
    return NaN
  } else if (isNaN(a)) {
    return b
  } else if (isNaN(b)) {
    return a
  } else {
    return Math.max(a, b)
  }
}

export const getMinAndMaxChartValues = (
  resultData: ResultsOverTimeResultChartData[]
) => {
  const maxResultValue = Math.max(...resultData.map((result) => result.value))
  const minResultValue = Math.min(...resultData.map((result) => result.value))
  const standardRangeMin = resultData[0]?.standardRangeMin
  const standardRangeMax = resultData[0]?.standardRangeMax
  const optimalRangeMin = resultData[0]?.optimalRangeMin
  const optimalRangeMax = resultData[0]?.optimalRangeMax

  const minRangeValue = getMinValue(standardRangeMin, optimalRangeMin)
  const maxRangeValue = getMaxValue(standardRangeMax, optimalRangeMax)

  let maxChartRangeValue = maxResultValue
  let minChartRangeValue = minResultValue

  if (minRangeValue < minResultValue) {
    minChartRangeValue = minRangeValue
  }

  if (maxRangeValue > maxResultValue) {
    maxChartRangeValue = maxRangeValue
  }

  return [minChartRangeValue, maxChartRangeValue]
}
