import { useState } from "react"

import clsx from "clsx"

import { Box, Grid, Theme, makeStyles, useMediaQuery } from "@material-ui/core"

import RichTextEditor from "app/components/RichTextEditor"
import BodyText from "app/components/design-system/BodyText"
import { FormFieldLabel } from "app/components/forms/fields"
import {
  ControlledSelectField,
  ControlledTextField,
} from "app/main/patient-checkout/fields"
import { US_STATES } from "app/main/patient-checkout/utils/usStates"
import useCachedCollection from "app/swr/hooks/use-cached-collection"
import useCollectionSWR from "app/swr/hooks/use-collection-swr"
import { colors, navy } from "app/theme"

import PractitionerProfileStateSelect from "./PractitionerProfileStateSelect"
import UploadProfilePhoto from "./UploadProfilePhoto"
import { PractitionerProfile } from "./types"

const styles = (theme: Theme) => ({
  disabledButtonOverride: {
    "&.Mui-disabled": {
      pointerEvents: "auto",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginTop: 8,
    },
  },
  copyLinkBtn: {
    [theme.breakpoints.down("sm")]: {
      alignSelf: "start",
    },
  },
  tutorialButton: {
    marginTop: theme.spacing(1.0),
  },
  publishButtonDisabled: {
    background: colors.blueGray[400],
    color: "white",
    "&:hover": {
      background: colors.blueGray[400],
      color: "white",
      cursor: "unset",
    },
  },
  publishButton: {
    background: colors.emerald[500],
    color: "white",
  },
  headerText: {
    marginBottom: theme.spacing(4.25),
    [theme.breakpoints.down("md")]: {
      marginBottom: 0,
    },
  },
  label: {
    marginTop: theme.spacing(2),
  },
  container: {
    display: "flex",
    "flex-direction": "row",
    [theme.breakpoints.down("md")]: {
      "flex-direction": "column",
    },
    width: "100%",
  },
  fieldSet: {
    width: "100%",
    marginRight: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    marginBottom: theme.spacing(2),
  },
  noSelection: {
    // Placeholder text color
    "& .MuiSelect-select.MuiSelect-select": {
      color: navy,
      opacity: 0.5,
    },
  },
  fieldLabel: {
    color: navy,
  },
  richTextEditor: {
    background: "white",
    border: `1px solid ${colors.blueGray[200]}`,
    marginBottom: theme.spacing(4.25),
    paddingBottom: 32,
  },
  formRow: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  gridContainer: {
    alignItems: "end",
    marginBottom: theme.spacing(4.25),
    [theme.breakpoints.down("md")]: {
      marginBottom: theme.spacing(2),
    },
  },
  practiceDescription: {
    marginBottom: theme.spacing(1),
    float: "left",
    width: "100%",
  },
})

// @ts-ignore
const useStyles = makeStyles(styles)

const getYearsList = () => {
  const currentYear = new Date().getFullYear()
  const endYear = 1940
  const yearsList: Number[] = []

  for (let year = currentYear; year >= endYear; year--) {
    yearsList.push(year)
  }

  return yearsList
}

interface Props {
  profile: PractitionerProfile
  practiceDescriptionField: any
  educationCredentialsField: any
  educationCredentialsFormState: any
  statesSelectField: any
  statesSelectFormState: any
  mutateProfile: any
  yearStartedPracticingField: any
  numberOfPatientsProvidedCareForField: any
  stateField: any
  practiceLocationField: any
  waitlistTimeForAppointmentField: any
}

export default function PractitionerProfileAbout({
  profile,
  mutateProfile,
  practiceDescriptionField,
  educationCredentialsField,
  educationCredentialsFormState,
  statesSelectField,
  statesSelectFormState,
  yearStartedPracticingField,
  numberOfPatientsProvidedCareForField,
  stateField,
  practiceLocationField,
  waitlistTimeForAppointmentField,
}: Props) {
  const classes = useStyles()
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"))

  const [showProfilePhotoRequired, setShowProfilePhotoRequired] =
    useState(false)

  const { data: appointmentAvailabilityOptionsIdentifiers } =
    useCollectionSWR<any>("/webflow_field_options/", {
      params: {
        "filter[field_slug]": "appointment-availability",
      },
    })

  const appointmentAvailabilityOptions = useCachedCollection<any>(
    appointmentAvailabilityOptionsIdentifiers ?? []
  )

  const { data: practiceLocationOptionsIdentifiers } = useCollectionSWR<any>(
    "/webflow_field_options/",
    {
      params: {
        "filter[field_slug]": "practice-location",
      },
    }
  )

  const practiceLocationOptions = useCachedCollection<any>(
    practiceLocationOptionsIdentifiers ?? []
  )

  const { data: numPatientsProvidedCareForOptionsIdentifiers } =
    useCollectionSWR<any>("/webflow_field_options/", {
      params: {
        "filter[field_slug]": "patients-served",
      },
    })

  const numPatientsProvidedCareForOptions = useCachedCollection<any>(
    numPatientsProvidedCareForOptionsIdentifiers ?? []
  )

  return (
    <div>
      <BodyText size="lg" weight="semibold" className={classes.headerText}>
        About
      </BodyText>
      <Grid container>
        <>
          <Box className={classes.label}>
            <FormFieldLabel
              label={
                <BodyText
                  style={{ float: "left", marginRight: 4 }}
                  weight="semibold"
                  size="base"
                >
                  Profile Photo
                </BodyText>
              }
              required
            />
            <BodyText size="sm">
              Choose a clear and friendly profile picture that showcases your
              personality and professionalism. This image will help potential
              clients recognize and connect with you.
            </BodyText>
            {showProfilePhotoRequired && (
              <BodyText size="sm" color="error">
                Profile photo is required.
              </BodyText>
            )}
          </Box>
          <UploadProfilePhoto
            onClearProfilePhoto={() => setShowProfilePhotoRequired(true)}
            onUploadProfilePhoto={() => setShowProfilePhotoRequired(false)}
            profile={profile}
            mutateProfile={mutateProfile}
          />
        </>
      </Grid>
      <Grid item>
        <Box className={classes.label}>
          <Grid container className={classes.gridContainer}>
            <Grid container className={classes.gridContainer}>
              <Grid
                item
                xs={12}
                md={6}
                style={{ paddingRight: isMobile ? 0 : 20 }}
                className={classes.formRow}
              >
                <FormFieldLabel
                  label={
                    <BodyText
                      style={{ float: "left", marginRight: 4 }}
                      weight="semibold"
                      size="base"
                    >
                      Website{" "}
                    </BodyText>
                  }
                  required
                />
                <ControlledTextField
                  className={classes.fieldSet}
                  name="website"
                  placeholder="https://www.rupahealth.com"
                  defaultValue=""
                  required={true}
                />
              </Grid>
              <Grid item xs={12} md={6} className={classes.formRow}>
                <div className="mb-1 fs-unmask">
                  <BodyText
                    style={{ float: "left", marginRight: 4 }}
                    weight="semibold"
                    size="base"
                  >
                    Phone Number
                  </BodyText>
                  <BodyText size="sm" weight="regular">
                    {" (optional)"}
                  </BodyText>
                </div>
                <ControlledTextField
                  className={classes.fieldSet}
                  name="phone_number"
                  placeholder=""
                  defaultValue=""
                />
              </Grid>
            </Grid>
            <Grid item xs={12} md={12} className={classes.formRow}>
              <div className="mb-1 fs-unmask">
                <BodyText
                  size="base"
                  weight="semibold"
                  style={{ float: "left", marginRight: 4 }}
                >
                  Practice description for clients
                </BodyText>
                <BodyText size="sm" weight="regular">
                  {" (optional)"}
                </BodyText>
                <BodyText size="sm" className={classes.practiceDescription}>
                  A concise overview of your practice helps clients understand
                  your approach and expertise. Make it engaging and informative!{" "}
                </BodyText>
              </div>
              <RichTextEditor
                className={classes.richTextEditor}
                name="practice_description"
                controlled
                onBlur={practiceDescriptionField.onBlur}
                onChange={practiceDescriptionField.onChange}
                placeholder="Your practice description"
                // @ts-ignore
                value={practiceDescriptionField.value}
              />
            </Grid>
            <Grid item xs={12} md={12} className={classes.formRow}>
              <FormFieldLabel
                label={
                  <BodyText
                    style={{ float: "left", marginRight: 4 }}
                    size="base"
                    weight="semibold"
                  >
                    How soon could someone get an appointment with you?
                  </BodyText>
                }
                required
              />
              <ControlledSelectField
                className={clsx(classes.fieldSet, {
                  [classes.noSelection]: !waitlistTimeForAppointmentField.value,
                })}
                name="waitlist_time_for_appointment"
                placeholder="Select"
                required
              >
                {appointmentAvailabilityOptions
                  .map((option) => (
                    <option key={option.id} value={option.id}>
                      {option.attributes.field_name}
                    </option>
                  ))
                  .reverse()}
              </ControlledSelectField>
              <Grid container className={classes.gridContainer}>
                <Grid
                  item
                  xs={12}
                  md={6}
                  style={{ paddingRight: isMobile ? 0 : 20 }}
                  className={classes.formRow}
                >
                  <FormFieldLabel
                    label={
                      <BodyText
                        style={{ float: "left", marginRight: 4 }}
                        size="base"
                        weight="semibold"
                      >
                        Practice Location
                      </BodyText>
                    }
                    required
                  />
                  <ControlledSelectField
                    className={clsx(classes.fieldSet, {
                      [classes.noSelection]: !practiceLocationField.value,
                    })}
                    name="practice_location"
                    placeholder="In-person, Telehealth, or Both"
                    required
                  >
                    {practiceLocationOptions
                      .map((option) => (
                        <option key={option.id} value={option.id}>
                          {option.attributes.field_name}
                        </option>
                      ))
                      .reverse()}
                  </ControlledSelectField>
                </Grid>
                <Grid item xs={12} md={6} className={classes.formRow}>
                  <FormFieldLabel
                    label={
                      <BodyText
                        style={{ float: "left", marginRight: 4 }}
                        size="base"
                        weight="semibold"
                      >
                        Zip Code
                      </BodyText>
                    }
                    required
                  />
                  <ControlledTextField
                    className={classes.fieldSet}
                    name="zip_code"
                    defaultValue=""
                    required
                  />
                </Grid>
              </Grid>
              <Grid container className={classes.gridContainer}>
                <Grid
                  item
                  xs={12}
                  md={6}
                  style={{ paddingRight: isMobile ? 0 : 20 }}
                  className={classes.formRow}
                >
                  <FormFieldLabel
                    label={
                      <BodyText
                        style={{ float: "left", marginRight: 4 }}
                        size="base"
                        weight="semibold"
                      >
                        City
                      </BodyText>
                    }
                    required
                  />
                  <ControlledTextField
                    className={classes.fieldSet}
                    name="city"
                    defaultValue=""
                    required
                  />
                </Grid>
                <Grid item xs={12} md={6} className={classes.formRow}>
                  <FormFieldLabel
                    label={
                      <BodyText
                        style={{ float: "left", marginRight: 4 }}
                        size="base"
                        weight="semibold"
                      >
                        State
                      </BodyText>
                    }
                    required
                  />
                  <ControlledSelectField
                    className={clsx(classes.fieldSet, {
                      [classes.noSelection]: !stateField.value,
                    })}
                    name="state"
                    placeholder="Select"
                    required
                  >
                    {Object.entries(US_STATES).map(([code, name]) => (
                      <option key={code} value={code}>
                        {name}
                      </option>
                    ))}
                  </ControlledSelectField>
                </Grid>
              </Grid>
              <Grid container className={classes.gridContainer}>
                <Grid
                  item
                  xs={12}
                  md={6}
                  style={{ paddingRight: isMobile ? 0 : 20 }}
                  className={classes.formRow}
                >
                  <div className="mb-1 fs-unmask">
                    <BodyText weight="semibold" size="base">
                      How many patients have you provided care to in your
                      career? <span style={{ color: "#dc2626" }}>*</span>
                    </BodyText>
                  </div>
                  <ControlledSelectField
                    className={clsx(classes.fieldSet, {
                      [classes.noSelection]:
                        !numberOfPatientsProvidedCareForField.value,
                    })}
                    name="number_of_patients_provided_care_for"
                    placeholder="Select"
                    required
                  >
                    {numPatientsProvidedCareForOptions
                      .map((option) => (
                        <option key={option.id} value={option.id}>
                          {option.attributes.field_name}
                        </option>
                      ))
                      .reverse()}
                  </ControlledSelectField>
                </Grid>
                <Grid item xs={12} md={6} className={classes.formRow}>
                  <div className="mb-1 fs-unmask">
                    <FormFieldLabel
                      label={
                        <BodyText
                          weight="semibold"
                          size="base"
                          style={{ float: "left", marginRight: 4 }}
                        >
                          Year Started Practicing
                        </BodyText>
                      }
                      required
                    />
                  </div>
                  <ControlledSelectField
                    className={clsx(classes.fieldSet, {
                      [classes.noSelection]: !yearStartedPracticingField.value,
                    })}
                    name="year_started_practicing"
                    defaultValue=""
                    required
                  >
                    {getYearsList().map((year) => (
                      <option key={year.toString()} value={year.toString()}>
                        {year}
                      </option>
                    ))}
                  </ControlledSelectField>
                </Grid>
              </Grid>
              <Grid item xs={12} md={12} className={classes.formRow}>
                <FormFieldLabel
                  label={
                    <BodyText
                      weight="semibold"
                      size="base"
                      style={{ float: "left", marginRight: 4 }}
                    >
                      Education Credentials
                    </BodyText>
                  }
                  required
                />
                {educationCredentialsFormState.errors[
                  "education_credentials"
                ] && (
                  <BodyText size="sm" color="error">
                    {
                      educationCredentialsFormState.errors[
                        "education_credentials"
                      ].message
                    }
                  </BodyText>
                )}
                <RichTextEditor
                  className={classes.richTextEditor}
                  name="education_credentials"
                  controlled
                  onBlur={educationCredentialsField.onBlur}
                  onChange={educationCredentialsField.onChange}
                  placeholder="Share more for prospective clients about how long you've practiced or what you studied."
                  // @ts-ignore
                  value={educationCredentialsField.value}
                />
              </Grid>
              <Grid item xs={12} md={12} className={classes.formRow}>
                <PractitionerProfileStateSelect
                  statesSelectField={statesSelectField}
                  statesSelectFormState={statesSelectFormState}
                />
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </div>
  )
}
