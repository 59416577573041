import NiceModal, { useModal } from "@ebay/nice-modal-react"

import { Practitioner } from "app/types"

import { PhysicianServicesConfirmationModal } from "../PhysicianServicesConfirmationModal"

interface PhysicianServicesConfirmationModalProps {
  onClose: () => void
  onConfirm: () => void
  practitioner: Practitioner
}

const PhysicianServicesConfirmationModalNiceModal =
  NiceModal.create<PhysicianServicesConfirmationModalProps>((props) => {
    return (
      <PhysicianServicesConfirmationModal
        open
        onClose={props.onClose}
        onConfirm={props.onConfirm}
        practitioner={props.practitioner}
      />
    )
  })

export default function usePhysicianServicesConfirmationModal() {
  return useModal(PhysicianServicesConfirmationModalNiceModal)
}
