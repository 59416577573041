import { makeStyles } from "@material-ui/core"

import Tooltip from "app/components/Tooltip"
import BodyText from "app/components/design-system/BodyText"
import { ORDER_TEST_WARNINGS } from "app/constants"
import { colors } from "app/theme"
import { OrderTestWarningTypes } from "app/types"

const useStyles = makeStyles({
  wrapper: {
    marginTop: 12,
    marginRight: 7,
  },
  warningWrapper: {
    display: "flex",
    width: "max-content",
    alignItems: "center",
    border: `1px solid ${colors.blueGray[300]}`,
    borderRadius: 37,
    padding: "5px 12px",
    background: colors.blueGray[50],
  },
  icon: {
    marginRight: 4,
  },
})

interface Props {
  type: OrderTestWarningTypes
  title: string
  warning: string
}

export const OrderTestWarning = ({ type, title, warning }: Props) => {
  const classes = useStyles()

  return (
    <div className={classes.wrapper}>
      <Tooltip
        interactive
        title={<BodyText>{warning}</BodyText>}
        placement="bottom"
        arrow
      >
        <div className={classes.warningWrapper}>
          <img
            src={ORDER_TEST_WARNINGS[type].icon}
            alt="warning-icon"
            className={classes.icon}
          />
          <BodyText size="xs" weight="semibold">
            {title}
          </BodyText>
        </div>
      </Tooltip>
    </div>
  )
}
