import React, { Fragment } from "react"

import { Dialog, DialogContent, DialogTitle } from "@material-ui/core"

import { OptionType } from "app/types"

import BodyText from "../design-system/BodyText"
import Button from "../design-system/Button"
import DisplayText from "../design-system/DisplayText"
import FilterIcon from "../search/FilterIcon/FilterIcon"
import { FilterCheckboxItem } from "./Filter"
import useFilterConfigUtilities from "./hooks/use-filter-config-utilities"
import { FilterConfig, FilterNames, FiltersMap } from "./types"

interface Props {
  isOpen: boolean
  onClose: () => void
  filtersMap: FiltersMap
  filterConfigs: FilterConfig[]
  onResetFilter: () => void
  onSelectFilterOption: (
    filterName: FilterNames,
    option: OptionType,
    isRadio: boolean
  ) => void
}

/*
 * Renders all available filters within a modal. Used when all the filters can't fit
 * on the screen.
 */
const FiltersModal = ({
  isOpen,
  onClose,
  filterConfigs,
  onResetFilter,
  ...sectionProps
}: Props) => {
  return (
    <Dialog onClose={onClose} open={isOpen} className="fs-unmask" maxWidth="md">
      <DialogTitle
        disableTypography
        className="flex items-center justify-between border-b border-slate-300"
      >
        <DisplayText weight="semibold" size="lg">
          Filters
        </DisplayText>
        <div className="flex items-center gap-4">
          <Button
            color="secondary"
            onClick={() => {
              onResetFilter()
              onClose()
            }}
          >
            Reset
          </Button>
          <Button color="primary" onClick={onClose}>
            Done
          </Button>
        </div>
      </DialogTitle>
      <DialogContent className="p-6 bg-gray-100 max-w-[700px] md:w-[700px]">
        <div className="bg-white shadow-sm rounded-[4px] p-3">
          {filterConfigs.map((filterConfig, idx) => (
            <Fragment key={filterConfig.filterName}>
              <FilterSection filterConfig={filterConfig} {...sectionProps} />
              {idx !== filterConfigs.length - 1 && (
                <div className="bg-slate-300 h-px w-full my-3" />
              )}
            </Fragment>
          ))}
        </div>
      </DialogContent>
    </Dialog>
  )
}

interface SectionProps
  extends Pick<Props, "filtersMap" | "onSelectFilterOption"> {
  filterConfig: FilterConfig
}

const FilterSection: React.FC<SectionProps> = ({
  filterConfig,
  filtersMap,
  onSelectFilterOption,
}) => {
  const { getIsSelected, onSelect, renderLabel } = useFilterConfigUtilities({
    filterConfig,
    filtersMap,
    onSelectFilterOption,
  })

  return (
    <div>
      <div className="flex items-center gap-[10px] p-2">
        <FilterIcon
          type={filterConfig.filterName}
          className="fill-body w-4 h-4"
        />
        <BodyText>{filterConfig.label}</BodyText>
      </div>
      <div className="columns-1 sm:columns-2">
        {filterConfig.options.map((option) => (
          <FilterCheckboxItem
            key={option.value}
            checked={getIsSelected(option)}
            onSelect={() => onSelect(option)}
            label={renderLabel(option)}
          />
        ))}
      </div>
    </div>
  )
}

export default FiltersModal
