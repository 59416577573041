import { useCallback } from "react"

import {
  PatientCheckoutFormData,
  PaymentMethodDetails,
  PaymentMethodType,
} from "app/types"

import useCreateAchPaymentMethod from "./use-create-ach-payment-method"
import useCreateCardPaymentMethod from "./use-create-card-payment-method"
import useCreateSplitItPaymentMethod from "./use-create-splitit-payment-method"

/**
 * This hook provides an async function for creating the payment method for the
 * given form data and returns the payment method details.
 *
 * @param token the checkout token string
 * @returns an async function that creates the payment method details
 */
export default function useCreatePaymentMethod(token: string) {
  const createCardPaymentMethod = useCreateCardPaymentMethod()
  const createSplitItPaymentMethod = useCreateSplitItPaymentMethod(token)
  const createAchPaymentMethod = useCreateAchPaymentMethod()
  return useCallback(
    async (
      formData: PatientCheckoutFormData
    ): Promise<PaymentMethodDetails | undefined> => {
      if (!formData.payment_method || !formData.payment_method.type) {
        return
      }

      switch (formData.payment_method?.type) {
        case PaymentMethodType.CARD: {
          const card = await createCardPaymentMethod(formData)

          return {
            type: PaymentMethodType.CARD,
            card,
          }
        }
        case PaymentMethodType.SPLITIT: {
          const splitit = await createSplitItPaymentMethod(formData)

          return {
            type: PaymentMethodType.SPLITIT,
            splitit,
          }
        }
        case PaymentMethodType.ACH: {
          const ach = await createAchPaymentMethod(formData)

          return {
            type: PaymentMethodType.ACH,
            ach,
          }
        }
      }
    },
    [
      createCardPaymentMethod,
      createSplitItPaymentMethod,
      createAchPaymentMethod,
    ]
  )
}
