import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import { Link } from "react-router-dom"

import {
  Grid,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useMediaQuery,
} from "@material-ui/core"
import { CircularProgress } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

import { UserPaths } from "app/Routes"
import AddUserIcon from "app/assets/icons/add-user.svg"
import CogIconGray from "app/assets/images/cog-icon-gray.svg"
import Logo from "app/components/Logo"
import PageToolbar from "app/components/PageToolbar"
import BodyText from "app/components/design-system/BodyText"
import DesignSystemButton from "app/components/design-system/Button"
import TeamMemberModal from "app/components/modals/TeamMemberModal"
import { VENDOR_PHYSICIAN_AUTHORIZATION_LABEL } from "app/constants"
import * as Actions from "app/store/actions"
import { colors, primaryColor } from "app/theme"

import SideNav from "./SideNav"

const Wrapper = styled("div")({
  background: colors.coolGray[50],
  border: `1px solid ${colors.coolGray[200]}`,
  minHeight: "100vh",
  padding: 24,
})

const TitleWrapper = styled("div")({
  display: "flex",
  alignItems: "center",
  marginBottom: 6,
})

const StyledLink = styled(Link)(({ isMobile }) => ({
  marginLeft: isMobile ? 0 : 4,
  color: primaryColor,
  fontSize: "13.5px",
}))

const BlockSeperator = styled("div")({
  margin: 24,
})

const StyledTable = styled(Table)({
  border: `1px solid ${colors.coolGray[200]}`,
})

const StyledTableCell = styled(TableCell)({
  width: "33%",
})

const StyledTableHead = styled(TableHead)({
  background: colors.blueGray[50],
})

const StyledMessageWrapper = styled("div")(({ isMobile }) => ({
  display: "flex",
  flexDirection: isMobile ? "column" : "row",
}))

const StyledDesignSystemButton = styled(DesignSystemButton)(({ isMobile }) => ({
  marginTop: isMobile ? 10 : "auto",
  alignItems: "center",
  float: isMobile ? "left" : "right",
}))

const useStyles = makeStyles((theme) => ({
  resendTextMobile: {
    display: "block",
  },
  resendText: {
    display: "inline-block",
    borderLeft: "1.5px solid #2B455F4D",
    paddingLeft: "9px",
    marginLeft: "9px",
    "&:hover": {
      textDecoration: "underline",
      cursor: "pointer",
    },
  },
  loader: {
    width: "13px !important",
    height: "13px !important",
    marginLeft: "3px",
  },
  vendorPhysicianAuthorizationEnabledContainer: {
    alignItems: "center",
    display: "flex",
    "& > img": {
      height: 15,
      marginRight: 5,
      width: 15,
    },
  },
}))

function Team() {
  const classes = useStyles()
  const dispatch = useDispatch()

  const [teamMemberModalIsOpen, setTeamMemberModalIsOpen] = useState(false)
  const [teamMemberLoading, setTeamMemberLoading] = useState(null)

  const teamMembers = useSelector(({ teamMember }) => teamMember)

  const practitioner = useSelector(({ practitioner }) => practitioner)

  useEffect(() => {
    dispatch(Actions.getTeamMembers())
  }, [])

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"))

  const resendClinicInvite = async (userId, userEmail) => {
    if (!teamMemberLoading) {
      setTeamMemberLoading(userId)
      await dispatch(Actions.reinviteTeamMember(userId, userEmail))
      setTeamMemberLoading(null)
    }
  }

  return (
    <>
      {isMobile && (
        <PageToolbar
          breadcrumbs={[
            {
              to: UserPaths.SETTINGS,
              icon: CogIconGray,
              label: "Settings",
            },

            {
              label: "Team Members",
            },
          ]}
        />
      )}
      <Grid container>
        {!isMobile && (
          <Grid item xs={12} md={3}>
            <SideNav />
          </Grid>
        )}
        <Grid item xs={12} md={9}>
          {!isMobile && (
            <PageToolbar
              title="Team Members"
              greyBackground={true}
              fullBorder={true}
            />
          )}
          <Wrapper>
            <Grid container justify="space-between" alignItems="center">
              <Grid item xs={12} md={8}>
                <TitleWrapper>
                  <BodyText weight="semibold" size="lg">
                    {practitioner?.clinic?.name} Team Members
                  </BodyText>
                </TitleWrapper>
                <BodyText>
                  These are all of the practitioners and staff members at{" "}
                  {practitioner?.clinic?.name}. Each team member can see every
                  patient at the clinic and place orders.
                </BodyText>
              </Grid>
              <Grid item xs={12} md={4}>
                <StyledDesignSystemButton
                  color="secondary"
                  startIcon={AddUserIcon}
                  onClick={() => setTeamMemberModalIsOpen(true)}
                  isMobile={isMobile}
                >
                  Invite a Team Member
                </StyledDesignSystemButton>
              </Grid>
            </Grid>

            <BlockSeperator />

            {teamMembers?.length && practitioner.id && (
              <TableContainer component={Paper}>
                <StyledTable aria-label="team">
                  <StyledTableHead>
                    <TableRow>
                      <StyledTableCell>Team Member </StyledTableCell>
                      <StyledTableCell>Role</StyledTableCell>
                      <StyledTableCell>Status</StyledTableCell>
                    </TableRow>
                  </StyledTableHead>
                  <TableBody>
                    {teamMembers.map((teamMember) => (
                      <TableRow key={teamMember.id}>
                        <TableCell>
                          <BodyText weight="semibold">
                            {teamMember.user.full_name}
                          </BodyText>
                          <BodyText>{teamMember.user.email}</BodyText>
                        </TableCell>
                        <TableCell>
                          <BodyText weight="semibold">
                            {teamMember.is_clinic_staff
                              ? "Clinic Staff"
                              : "Practitioner"}
                          </BodyText>
                          {teamMember.vendor_physician_authorization_enabled && (
                            <div
                              className={
                                classes.vendorPhysicianAuthorizationEnabledContainer
                              }
                            >
                              <Logo />
                              <BodyText size="xs">
                                {VENDOR_PHYSICIAN_AUTHORIZATION_LABEL} Approved
                              </BodyText>
                            </div>
                          )}
                        </TableCell>
                        <TableCell>
                          {
                            // We still want to render the TableCell for the practitioner's row so the border renders,
                            // but we don't want the activation status information.
                            teamMember.id !== practitioner.id && (
                              <>
                                <BodyText
                                  weight="semibold"
                                  style={{
                                    display: "inline-block",
                                  }}
                                >
                                  {teamMember.user.is_verified
                                    ? "Account Created"
                                    : "Account Pending"}
                                </BodyText>
                                {!teamMember.user.is_verified && (
                                  <BodyText
                                    weight="semibold"
                                    color="primary"
                                    className={
                                      isMobile
                                        ? classes.resendTextMobile
                                        : classes.resendText
                                    }
                                    onClick={() =>
                                      resendClinicInvite(
                                        teamMember.user.id,
                                        teamMember.user.email
                                      )
                                    }
                                  >
                                    Resend{" "}
                                    {teamMemberLoading ===
                                      teamMember.user.id && (
                                      <CircularProgress
                                        className={classes.loader}
                                      />
                                    )}
                                  </BodyText>
                                )}
                              </>
                            )
                          }
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </StyledTable>
              </TableContainer>
            )}

            <BlockSeperator />

            <StyledMessageWrapper isMobile={isMobile}>
              <BodyText size="sm">
                Want to update or remove a team member?
              </BodyText>
              <StyledLink
                onClick={() => {
                  window.Intercom("showNewMessage")
                }}
                isMobile={isMobile}
              >
                Message our team.
              </StyledLink>
            </StyledMessageWrapper>
          </Wrapper>
        </Grid>
      </Grid>
      <TeamMemberModal
        open={teamMemberModalIsOpen}
        onClose={() => setTeamMemberModalIsOpen(false)}
      />
    </>
  )
}

export default Team
