import { createContext, Dispatch } from "react"

import { CheckoutDraftState } from "app/types"

import * as Actions from "../store/actions/draft.actions"
import { initialState } from "../store/reducers/draft.reducer"

const CheckoutDraftContext = createContext<
  [CheckoutDraftState, Dispatch<Actions.CheckoutDraftUpdateAction>]
>([initialState, () => {}])

export default CheckoutDraftContext
