import { useDispatch } from "react-redux"

import {
  createLabResultFromOrderedResult,
  createLabResultsFromFiles,
  invokePipelineForLabResult,
} from "../../lab-result-api"
import {
  addStoredUploadJobsForPatient,
  StoredUploadJob,
} from "./use-stored-upload-jobs"

export interface ResultsUploadProps {
  userId: string
  patientId?: string
}

export const useResultsUpload = (props: ResultsUploadProps) => {
  const dispatch = useDispatch()
  const uploadJobsId = props.patientId ?? props.userId ?? ""

  const uploadAndInvokeLabResults = async (
    fileArrays: File[][],
    clinicIdsToShareWith?: string[]
  ) => {
    const labResults = await createLabResultsFromFiles({
      ...props,
      fileArrays,
      dispatch,
    })

    // if no lab results were created, nothing we can do. Return early
    if (!labResults) return

    let uploadJobs: StoredUploadJob[] = []

    await Promise.all(
      labResults.map(async (labResult) =>
        invokePipelineForLabResult(labResult, dispatch).then((statusId) => {
          if (statusId) {
            uploadJobs.push({
              statusId,
              name: labResult.attributes.original_file_name,
              clinicIdsToShareWith,
            })
          }
        })
      )
    )

    // Add the upload jobs to local storage so we can show their progress elsewhere
    addStoredUploadJobsForPatient(uploadJobsId, uploadJobs)

    return { labResults, uploadJobs }
  }

  const createAndInvokeFromOrderedResult = async (orderedResultId: string) => {
    const labResult = await createLabResultFromOrderedResult({
      ...props,
      orderedResultId,
      dispatch,
    })

    // if lab result not created, nothing we can do. Return early
    if (!labResult) return

    // filter out any undefined jobIds - invokePipeline should handle those
    const statusId = await invokePipelineForLabResult(labResult, dispatch)

    if (statusId) {
      addStoredUploadJobsForPatient(uploadJobsId, [
        { statusId, name: labResult.attributes.original_file_name },
      ])
    }

    return { statusId }
  }

  return {
    uploadAndInvokeLabResults,
    createAndInvokeFromOrderedResult,
  }
}
