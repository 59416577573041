import useCachedCollection from "app/swr/hooks/use-cached-collection"
import useCollectionSWR from "app/swr/hooks/use-collection-swr"
import { DashboardKit } from "types/dashboard/dashboard-kit"

export default function useDashboardKits({ orderId }: { orderId: string }) {
  const { data, ...kitsSwr } = useCollectionSWR(
    `/dashboard/orders/${orderId}/kits/`,
    {
      include: [
        "active_results_delay_alert",
        "lab_company",
        "latest_ordered_result",
        "ordered_tests",
        "specimen_issue",
      ],
    },
    {
      revalidateIfStale: true,
      revalidateOnMount: true,
      revalidateOnFocus: true,
      revalidateOnReconnect: true,
    }
  )

  const kits = useCachedCollection<DashboardKit>(data)

  return {
    ...kitsSwr,
    data: kits,
  }
}
