import { useEffect, useState } from "react"

import { useDebounce } from "react-use"

import { ReactComponent as SearchIcon } from "app/assets/images/search-icon-blue.svg"
import {
  SCROLL_CONTAINER_PADDING,
  COLUMN_PADDING,
} from "app/main/comparison/constants"
import { colors, primaryColor, shadows, textPrimaryColor } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"

// Note: Adapted these styles from `src/app/components/LabTests/Search.tsx`.
// May be worth breaking out a common search input if we need to adapt this elsewhere.
const useStyles = makeAppStyles((theme) => ({
  searchContainer: {
    position: "sticky",
    left: COLUMN_PADDING,
    width: "100%",
    maxWidth: `calc(100vw - ${
      SCROLL_CONTAINER_PADDING * 2 + COLUMN_PADDING + COLUMN_PADDING / 2
    }px)`,
    background: "white",
    border: `2px solid ${colors.blueGray[300]}`,
    borderRadius: 8,
    boxShadow: shadows.default,
    padding: theme.spacing(1.5, 1.25),
    transition: "0.08s border-color, 0.16s box-shadow",
    display: "flex",
    alignItems: "center",
    borderColor: primaryColor,
    "-webkit-appearance": "none !important",

    "&:focus-within": {
      // Deliberately not from the design system as this shadow is custom
      boxShadow: "0px 0px 4px #0075CD",
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: `calc(100vw - ${SCROLL_CONTAINER_PADDING + COLUMN_PADDING}px)`,
    },
  },
  searchIconContainer: {
    marginRight: 6,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& > svg": {
      fill: colors.blueGray[400],
      width: 16,
      height: 16,
    },
  },
  searchInputContainer: {
    flex: 1,
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    gap: theme.spacing(1.5),
    minHeight: 34,
  },
  searchInput: {
    flex: 1,
    color: textPrimaryColor,
    fontSize: 16,
    lineHeight: 1.25,

    [theme.breakpoints.up("sm")]: {
      fontSize: "inherit",
    },

    "&::placeholder": {
      color: colors.blueGray[500],
      fontWeight: theme.typography.fontWeightMedium,
    },
  },
}))

interface ComparisonBiomarkerSearchInputProps {
  className?: string
  onSearch: (value: string) => void
  search: string
}

export default function ComparisonBiomarkerSearchInput({
  onSearch,
  search,
}: ComparisonBiomarkerSearchInputProps) {
  const classes = useStyles()
  const [value, setValue] = useState(search)

  useEffect(() => {
    search !== value && setValue(search)
  }, [search])

  useDebounce(
    () => {
      search !== value && onSearch(value)
    },
    250,
    [value]
  )

  return (
    <div className={classes.searchContainer} tabIndex={-1}>
      <div className={classes.searchIconContainer}>
        <SearchIcon viewBox="0 0 17 18" />
      </div>
      <div className={classes.searchInputContainer}>
        <input
          aria-label="Search for a Biomarker"
          className={classes.searchInput}
          onChange={(event) => setValue(event.target.value)}
          placeholder="Search for a Biomarker"
          value={value}
          autoComplete="off"
          autoCorrect="off"
          autoCapitalize="off"
          spellCheck="false"
        />
      </div>
    </div>
  )
}
