import { useMemo } from "react"

import { VENDOR_PHYSICIAN_AUTHORIZATION_LABEL } from "app/constants"

import { MarkAsComplete } from "../MarkAsCompleteButton"
import LicenseAndPhysicianServicesTask from "./LicenseAndPhysicianServicesTask"
import { OnboardingTaskItemProps } from "./OnboardingTaskItem"

const LicenseCombined: React.FC<OnboardingTaskItemProps> = ({
  practitioner,
  ...props
}) => {
  const showMarkComplete = useMemo(() => {
    return (
      practitioner?.vendor_physician_authorization_enabled ||
      (practitioner?.has_signature && practitioner?.has_valid_license)
    )
  }, [practitioner])

  return (
    <LicenseAndPhysicianServicesTask
      {...props}
      practitioner={practitioner}
      subheader={`To place an order you'll either need to upload a copy of your license or add ${VENDOR_PHYSICIAN_AUTHORIZATION_LABEL} to your account.`}
      subCardContent={
        <div className="flex flex-col items-center mb-4 bg-blue-50">
          {showMarkComplete && (
            <MarkAsComplete
              onClick={() => props.markTaskAsComplete(props.task.key)}
              style={{ marginTop: 10 }}
            />
          )}
        </div>
      }
    />
  )
}

export default LicenseCombined
