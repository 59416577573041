import { styled } from "@material-ui/core"

import ShippingBox from "app/assets/icons/shipping-box-dark.svg"
import AlertTriangleRed from "app/assets/images/alert-triangle-red.svg"
import Tooltip from "app/components/Tooltip"
import BodyText from "app/components/design-system/BodyText"
import Underline from "app/components/design-system/Underline"
import { LabCompanyAlert, LabTest } from "app/types"

const BodyContainer = styled("div")({
  marginTop: 14,
  display: "flex",
  flexDirection: "row",
})

const IconImage = styled("img")({
  width: 14.35,
})

interface Props {
  shippingDaysMin: LabTest["shipping_days_min"]
  shippingDaysMax: LabTest["shipping_days_max"]
  activeShippingAlert?: LabCompanyAlert
}

const ShippingTimeSection = ({
  shippingDaysMin,
  shippingDaysMax,
  activeShippingAlert,
}: Props) => {
  if (!shippingDaysMin || !shippingDaysMax) {
    return null
  }

  let body = (
    <>
      <IconImage src={ShippingBox} alt="shipping time" />
      <BodyText className="pl-1.5" weight="semibold">
        {shippingDaysMin}-{shippingDaysMax} Business Days
      </BodyText>
    </>
  )

  if (activeShippingAlert) {
    body = (
      <Tooltip
        interactive
        title={
          <BodyText>{`${activeShippingAlert.title}. ${activeShippingAlert.subtitle}`}</BodyText>
        }
        placement="bottom"
        arrow
      >
        <Underline>
          {body}
          <img src={AlertTriangleRed} alt="Shipping delay" className="ml-1" />
        </Underline>
      </Tooltip>
    )
  }

  return (
    <>
      <BodyText size="sm">Shipping Time</BodyText>
      <BodyContainer>{body}</BodyContainer>
    </>
  )
}

export default ShippingTimeSection
