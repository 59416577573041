import { ReactComponent as ArrowTopRightIcon } from "app/assets/icons/arrow-top-right.svg"
import Button from "app/components/design-system/Button"
import InfoTextTooltip from "app/components/design-system/InfoTextTooltip"
import formatDateForHuman from "app/utils/format-date-for-human"

interface KitShipmentTrackingDescriptionProps {
  estimated_arrival_date?: string | null
  shipment_tracking_link?: string | null
  lab_company_notifies_sample_at_lab?: boolean
  lab_company_short_name?: string
  is_recollection?: boolean
}

export default function KitShipmentTrackingDescription({
  estimated_arrival_date,
  shipment_tracking_link,
  lab_company_notifies_sample_at_lab,
  lab_company_short_name,
  is_recollection,
}: KitShipmentTrackingDescriptionProps) {
  const estimationElement: React.ReactNode = estimated_arrival_date ? (
    <>
      {`${
        is_recollection ? "The recollection kit" : "The kit"
      } is estimated to arrive on ${formatDateForHuman(
        estimated_arrival_date
      )}. `}
    </>
  ) : null

  const linkElement: React.ReactNode = shipment_tracking_link ? (
    <Button
      color="text"
      className="text-base15 pb-1 mx-4"
      onClick={() => window.open(shipment_tracking_link as string, "_blank")}
      endIcon={<ArrowTopRightIcon viewBox="0 0 12 12" />}
    >
      {"Track shipment"}
    </Button>
  ) : null

  const nextUpdateElement: React.ReactNode = lab_company_short_name ? (
    lab_company_notifies_sample_at_lab ? (
      <div>
        {`${lab_company_short_name}'s next update will be Sample at Lab.`}
      </div>
    ) : (
      <div>
        {`${lab_company_short_name}'s next update will be Results In.`}

        <InfoTextTooltip disableTouchListener>
          {`${lab_company_short_name} does not update Rupa when they receive a patient's sample.`}
        </InfoTextTooltip>
      </div>
    )
  ) : null

  return (
    <div className="flex flex-col gap-1">
      <div className="flex flex-row gap-2">
        {estimationElement && <span>{estimationElement}</span>}
        {linkElement}
      </div>
      {nextUpdateElement}
    </div>
  )
}
