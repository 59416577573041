import { useEffect } from "react"
import * as React from "react"

import clsx from "clsx"

import { Container, Typography, makeStyles } from "@material-ui/core"

import { VENDOR_PHYSICIAN_AUTHORIZATION_LABEL } from "app/constants"
import IdentityVerificationButton from "app/main/identity-verification/components/IdentityVerificationButton"
import usePostCheckoutIdentityVerification from "app/main/patient-checkout/hooks/use-post-checkout-identity-verification"
import { shadows } from "app/theme"
import { PatientPortalOrder } from "app/types"

import NavBar from "./NavBar"

const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: 680,
  },
  panel: {
    padding: "24px",
    marginTop: "60px",
    marginBottom: "60px",
    backgroundColor: "#ffffff",
    boxShadow: shadows.md,
    borderRadius: "8px",
    [theme.breakpoints.up("md")]: {
      padding: "50px",
    },
  },
  message: {
    marginTop: theme.spacing(2),
    textAlign: "center",
  },
  buttonWrapper: {
    marginTop: theme.spacing(3),
    width: "100%",
    textAlign: "center",
  },
}))

interface PostCheckoutIdentityVerificationLayoutProps {
  children: React.ReactNode
  order: PatientPortalOrder
}

const PostCheckoutIdentityVerificationLayout = ({
  children,
  order,
}: PostCheckoutIdentityVerificationLayoutProps) => {
  const classes = useStyles()
  return (
    <div>
      <NavBar patient={order.patient} />
      <Container className={clsx("fs-unmask", classes.container)}>
        <div className={classes.panel}>{children}</div>
      </Container>
    </div>
  )
}

export interface PostCheckoutIdentityVerificationProps {
  checkoutToken: string
  onSubmit: () => void
  order: PatientPortalOrder
}

export default function PostCheckoutIdentityVerification({
  checkoutToken,
  onSubmit,
  order,
}: PostCheckoutIdentityVerificationProps) {
  const classes = useStyles()
  const { error, loading, loadingError, onClick, submitted, verifying } =
    usePostCheckoutIdentityVerification(checkoutToken)

  useEffect(() => {
    if (submitted) {
      onSubmit()
    }
  }, [submitted])

  return (
    <PostCheckoutIdentityVerificationLayout order={order}>
      <Typography align="center" variant="h4" color="textPrimary">
        One final step, We promise! ⚡
      </Typography>
      <Typography
        align="center"
        color="textPrimary"
        className={classes.message}
      >
        This order was placed with {VENDOR_PHYSICIAN_AUTHORIZATION_LABEL}.
        You'll need to verify your identity at the next step before the order is
        sent to the signing physician for approval.
        <br />
        <br />
        You'll be notified within 1 business day whether the order has been
        approved or denied, and your card will only be charged if the order is
        approved.
      </Typography>

      <div className={classes.buttonWrapper}>
        <IdentityVerificationButton
          error={error}
          loading={loading}
          loadingError={loadingError}
          onClick={onClick}
          verifying={verifying}
        />
      </div>
    </PostCheckoutIdentityVerificationLayout>
  )
}
