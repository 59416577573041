import { useFormContext, useWatch } from "react-hook-form"

import { Grid, Typography } from "@material-ui/core"

import {
  ControlledSelectField,
  ControlledTextField,
  FieldNames,
} from "app/main/patient-checkout/fields"
import { US_STATES } from "app/main/patient-checkout/utils/usStates"
import { ControlledCheckboxInput } from "app/patient-portal/fields"

const SplitItBillingAddressForm = () => {
  const { control } = useFormContext()
  const watchIsBillingSameAsShipping = useWatch({
    control,
    defaultValue: true,
    name: FieldNames.SPLITIT_IS_BILLING_SAME_AS_SHIPPING,
  })

  return (
    <Grid container spacing={2}>
      <Grid container item>
        <Grid item xs={12}>
          <ControlledCheckboxInput
            defaultValue={true}
            label={
              <Typography color="textPrimary" component="span">
                Billing address is the same as shipping.
              </Typography>
            }
            name={FieldNames.SPLITIT_IS_BILLING_SAME_AS_SHIPPING}
            shouldUnregister
          />
        </Grid>
      </Grid>

      {!watchIsBillingSameAsShipping && (
        <>
          <Grid container item>
            <Grid item xs={12}>
              <ControlledTextField
                name={FieldNames.SPLITIT_BILLING_STREET}
                placeholder="Street Address"
                required
                shouldUnregister
              />
            </Grid>
          </Grid>
          <Grid container item>
            <Grid item xs={12}>
              <ControlledTextField
                name={FieldNames.SPLITIT_BILLING_SECONDARY_STREET}
                placeholder="2nd Street Address"
                shouldUnregister
              />
            </Grid>
          </Grid>

          <Grid container item spacing={2}>
            <Grid item xs={12} sm={6} md={4}>
              <ControlledTextField
                name={FieldNames.SPLITIT_BILLING_CITY}
                placeholder="City"
                required
                shouldUnregister
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <ControlledSelectField
                name={FieldNames.SPLITIT_BILLING_STATE}
                placeholder="State"
                required
                shouldUnregister
              >
                {Object.entries(US_STATES).map(([code, name]) => (
                  <option key={code} value={code}>
                    {name}
                  </option>
                ))}
              </ControlledSelectField>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <ControlledTextField
                name={FieldNames.SPLITIT_BILLING_ZIPCODE}
                placeholder="Zip Code"
                required
                shouldUnregister
              />
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  )
}

export default SplitItBillingAddressForm
