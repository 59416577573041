import { ReactComponent as ResultsSummaryDocumentIcon } from "app/assets/images/documents/results-summary-document-icon.svg"
import useEventCallback from "app/hooks/use-event-callback"
import { useLoadAndDownloadResultsSummary } from "app/results-summary/hooks/use-download-results-summary"
import useResultsSummaryModal from "app/results-summary/hooks/use-results-summary-modal"
import useCachedResource from "app/swr/hooks/use-cached-resource"
import { Document } from "types/patient-with-documents"
import { ResultsInterpretation } from "types/results-interpretation"

import DocumentCardActionsMenu from "./DocumentCardActionsMenu"
import PatientDocumentCard from "./PatientDocumentCard"
import useDocumentsDescriptors from "./hooks/use-documents-descriptors"

interface Props {
  document: Document
  inPatientPortal: boolean
  onDelete: (document: Document) => void
}

const ResultsInterpretationDocumentCard: React.FC<Props> = ({
  document,
  inPatientPortal,
  onDelete,
}) => {
  const { download, isDownloading } = useLoadAndDownloadResultsSummary(
    document.relationships.results_interpretation!.data
  )

  const resultsInterpretation = useCachedResource<ResultsInterpretation>(
    document.relationships?.results_interpretation?.data
  )

  const canDownload = document.meta.can_read
  const canEdit =
    document.meta.can_write &&
    resultsInterpretation?.relationships.order.data.id
  const canDelete = document.meta.can_delete

  const onClick = useEventCallback(() => {
    if (canEdit && (!inPatientPortal || !canDownload)) {
      onEdit()
    } else if (canDownload) {
      download()
    }
  })

  const editModal = useResultsSummaryModal()
  const onEdit = useEventCallback(() => {
    if (canEdit) {
      editModal.show({
        orderId: resultsInterpretation!.relationships.order.data.id,
      })
    }
  })

  const { title, subtitle } = useDocumentsDescriptors(document)

  return (
    <PatientDocumentCard
      onClick={onClick}
      Icon={(className) => <ResultsSummaryDocumentIcon className={className} />}
      title={title}
      subTitle={subtitle}
      actions={
        <DocumentCardActionsMenu
          onDelete={canDelete ? () => onDelete(document) : undefined}
          onDownload={canDownload ? download : undefined}
          isDownloading={isDownloading}
          onEdit={canEdit ? onEdit : undefined}
        />
      }
    />
  )
}

export default ResultsInterpretationDocumentCard
