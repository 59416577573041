export const PdfDivider: React.FC = () => {
  return <div className="w-full bg-slate-200 h-[1px] mt-6 mb-6"></div>
}

export const PdfSectionTitle: React.FC<{
  title: string
  icon: JSX.Element
}> = ({ title, icon }) => {
  return (
    <div className="flex align-middle items-center mb-1">
      {icon}
      <div className="text-navy text-md font-semibold ml-2">{title}</div>
    </div>
  )
}
