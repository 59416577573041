import { useMemo, useState } from "react"

import { useFormContext } from "react-hook-form"

import {
  Box,
  Link,
  makeStyles,
  styled,
  Typography,
  useMediaQuery,
} from "@material-ui/core"

import Tooltip from "app/components/Tooltip"
import BodyText from "app/components/design-system/BodyText"
import Button from "app/components/design-system/Button"
import {
  ORDER_LINE_ITEM_TYPE,
  VENDOR_PHYSICIAN_AUTHORIZATION_LABEL,
} from "app/constants"
import {
  hasDHAPhlebTests,
  hasLabcorpPhlebTests,
} from "app/dataServices/labTestDataService"
import { isLineItemActive } from "app/dataServices/orderDataService"
import useFeatureFlag, { FeatureFlag } from "app/hooks/use-feature-flag"
import useQuery from "app/hooks/use-query"
import {
  PatientCheckoutTrackingEvents,
  trackPatientCheckoutEvent,
} from "app/services/segment"
import { colors, navy, shadows } from "app/theme"
import {
  PatientCheckoutFormData,
  PatientPortalOrder,
  PHLEBOTOMY_PROVIDERS,
} from "app/types"
import { getOrderPatientLabel, getOrderTypeLabel } from "app/utils/order-utils"

import useUpdateLineItems from "./hooks/use-update-line-items"
import useUpdateOrder from "./hooks/use-update-order"
import CancelOrderModal from "./sections/CancelOrderModal"
import Consent from "./sections/Consent"
import Insurance from "./sections/Insurance"
import { OrderDetails } from "./sections/OrderDetails"
import PatientPayment from "./sections/PatientPayment"
import { PersonalInfo } from "./sections/PersonalInfo"
import { Shipping } from "./sections/Shipping"
import { BloodDraw } from "./sections/blood-draw/BloodDraw"
import { OrderRequestStatus } from "./store/actions/orders.actions"
import {
  getEnabledOrderedTests,
  isPaymentRequired,
  orderedTestRequiresPhleb,
} from "./utils/checkout-utils"

interface PatientCheckoutFormProps {
  checkoutToken: string
  isSubmitPending: boolean
  onSubmit: (data: PatientCheckoutFormData) => Promise<void>
  order: PatientPortalOrder
  orderRequestStatus: string
  orderInsuredCompanies: string[]
  hasBRLTests: boolean
  willTestsBeShipped: boolean
}

/**
 * The primary component responsible for rendering the patient checkout form,
 * and managing the form state. This component delegates to it's parent for
 * handling the submission of the form with the `onSubmit` callback prop.
 */
const PatientCheckoutForm = ({
  checkoutToken,
  isSubmitPending,
  onSubmit,
  order,
  orderRequestStatus,
  orderInsuredCompanies,
  hasBRLTests,
  willTestsBeShipped,
}: PatientCheckoutFormProps) => {
  const [isMedicareCheckEnabled] = useFeatureFlag(
    FeatureFlag.isMedicareCheckEnabled
  )
  const query = useQuery()
  const [beastCoastEnabledFlag] = useFeatureFlag(FeatureFlag.BeastCoast)
  const [axlePhlebotomyEnabledFlag] = useFeatureFlag(FeatureFlag.AxlePhlebotomy)
  const beastCoastEnabled =
    beastCoastEnabledFlag || query.get("beast_coast") === "1"
  const methods = useFormContext<PatientCheckoutFormData>()
  const isWideView = useMediaQuery<any>((theme) => theme.breakpoints.up("md"))
  const classes = useClasses({ isWideView })
  const { isUpdatingOrder, updateOrder } = useUpdateOrder()
  const {
    updatingOrderedTestIds,
    isUpdatingLineItems,
    enableLineItems,
    disableLineItems,
  } = useUpdateLineItems()

  const [cancelModalOpenWithOptions, setCancelModalOpenWithOptions] = useState<{
    cancellationReason?: string
  } | null>(null)

  const isPhlebotomyRequired = useMemo(() => {
    return order.ordered_tests.some(orderedTestRequiresPhleb)
  }, [])

  const allowAxle = axlePhlebotomyEnabledFlag && isPaymentRequired(order)

  const isDhaIncluded = hasDHAPhlebTests(
    order.ordered_tests.map((ordered_test) => ordered_test.lab_test)
  )

  const isLabcorpPhlebotomyRequired = hasLabcorpPhlebTests(
    order.ordered_tests.map((ordered_test) => ordered_test.lab_test)
  )

  // TODO: Deprecate this, and don't allow DHA and Labcorp on the same order at the same time.
  const isDhaPhlebIncludedAndLabCorpPhlebNotIncluded =
    isDhaIncluded && !isLabcorpPhlebotomyRequired

  const showInsuranceToggle = useMemo(() => {
    return order.insurance_enabled_for?.length > 0
  }, [order.insurance_enabled_for])

  const [isBloodDrawSelectionDirty, setIsBloodDrawSelectionDirty] =
    useState(false)

  const [zipCode, setZipCode] = useState<string | null>(null)

  const requiredNumberOfPhlebotomyProviders = useMemo(() => {
    let numberOfProviders = 0
    if (order.lab_company_names_blood_draw_not_included.length) {
      numberOfProviders += 1
    }
    if (
      order.phlebotomy_providers
        .map((item) => item.provider_name)
        .includes(PHLEBOTOMY_PROVIDERS.LABCORP)
    ) {
      numberOfProviders += 1
    }
    if (
      order.phlebotomy_providers
        .map((item) => item.provider_name)
        .includes(PHLEBOTOMY_PROVIDERS.QUEST)
    ) {
      numberOfProviders += 1
    }
    return numberOfProviders
  }, [
    order.lab_company_names_blood_draw_not_included,
    order.phlebotomy_providers,
  ])

  const submissionDisabledReason = useMemo(() => {
    const hasActiveOrderedTests = order.line_items
      .filter((lineItem) => lineItem.type === ORDER_LINE_ITEM_TYPE.LAB_TEST)
      .some(isLineItemActive)

    const insuranceFieldsNotFilledOut =
      orderInsuredCompanies?.length > 0 &&
      methods.getFieldState("order_insurance").invalid

    if (order.is_demo) {
      return "This order can't be paid for because it is a demo order."
    }

    if (isPhlebotomyRequired) {
      if (!zipCode) {
        return "Must provide your zip code"
      } else if (
        order.phlebotomy_providers?.length !==
        requiredNumberOfPhlebotomyProviders
      ) {
        return "Must select a phlebotomy option"
      } else if (
        order.lab_company_names_blood_draw_not_included.length &&
        isBloodDrawSelectionDirty
      ) {
        return "Must confirm phlebotomy option"
      }
    }

    if (!hasActiveOrderedTests) {
      return "This order can't be paid for because it does not have any tests."
    }

    if (
      isMedicareCheckEnabled &&
      order.patient_has_medicare &&
      !order.is_medicare_verified
    ) {
      return "This order can't be submitted because your Medicare information is not verified. Please verify your Medicare information above."
    }

    if (
      beastCoastEnabled &&
      getEnabledOrderedTests(order).some(
        (test) => !test.ordering_rights_status.allowed
      )
    ) {
      return "This order can't be submitted because there are ordering issues with some of your tests. Please review the ordering issues above."
    }

    if (insuranceFieldsNotFilledOut) {
      return "Please enter your insurance information above."
    }

    return ""
  }, [
    order,
    methods,
    beastCoastEnabled,
    orderInsuredCompanies,
    isMedicareCheckEnabled,
    hasBRLTests,
    isBloodDrawSelectionDirty,
    zipCode,
  ])

  const handleSubmit = (ev) => {
    // Prevent submission if there is a submissionDisabledReason.
    // This can be triggered by the patient hitting the Enter key.
    if (submissionDisabledReason) {
      ev.preventDefault()
    }
    trackPatientCheckoutEvent(
      PatientCheckoutTrackingEvents.PATIENT_CHECKOUT_REVIEW,
      order
    )
    methods.handleSubmit(onSubmit)(ev)
  }

  const onCancelBecauseOfOrderingIssues = (reason: string) => {
    setCancelModalOpenWithOptions({ cancellationReason: reason })
  }

  return (
    <form className={classes.form} onSubmit={handleSubmit}>
      <div>
        {order.practitioner.clinic_branding_enabled && (
          <Header className={classes.header} order={order} />
        )}

        {!isWideView && (
          <>
            {order.requires_vendor_physician_authorization && (
              <PhysicianServicesExplanation
                className={classes.sectionPhysicianServicesExplanation}
              />
            )}
            <OrderDetails
              checkoutToken={checkoutToken}
              className={classes.orderDetailsNarrowView}
              order={order}
              setCancelModalOpenWithOptions={setCancelModalOpenWithOptions}
              isOrderBeingUpdated={
                orderRequestStatus === OrderRequestStatus.UPDATING
              }
              updatingOrderedTestIds={updatingOrderedTestIds}
              enableLineItems={enableLineItems}
              disableLineItems={disableLineItems}
              isUpdatingLineItems={isUpdatingLineItems}
              isUpdatingOrder={isUpdatingOrder}
            />
          </>
        )}

        <div className={classes.formSections}>
          {order.requires_vendor_physician_authorization && isWideView && (
            <PhysicianServicesExplanation
              className={classes.sectionPhysicianServicesExplanation}
            />
          )}
          <PersonalInfo
            className={classes.sectionPersonalInfo}
            subtitle="Please make sure this information matches the person collecting the sample."
            title={`${getOrderPatientLabel(
              order.requires_vendor_physician_authorization
            )}'s Information`}
            isStorefrontOrder={Boolean(order.storefront_id)}
            order={order}
            updateOrder={updateOrder}
          />
          <Shipping
            className={classes.sectionPersonalInfo}
            title={
              willTestsBeShipped
                ? "Shipping Information"
                : "Address Information"
            }
            showZipcodeFieldForBloodDraw={
              order.flags.CHECK_SCARLET_ELIGIBILITY === true
            }
            setCancelModalOpenWithOptions={setCancelModalOpenWithOptions}
            onSetZipCode={setZipCode}
            order={order}
            isUpdatingOrder={isUpdatingOrder}
            updateOrder={updateOrder}
            willTestsByShipped={willTestsBeShipped}
            onCancel={onCancelBecauseOfOrderingIssues}
          />

          {isPhlebotomyRequired && (
            <BloodDraw
              patientBirthday={order.patient.birthday}
              zipCode={zipCode}
              checkoutToken={checkoutToken}
              isDhaPhlebIncludedAndLabCorpPhlebNotIncluded={
                isDhaPhlebIncludedAndLabCorpPhlebNotIncluded
              }
              phlebProviders={order.phlebotomy_providers.map(
                (pp) => pp.provider_name
              )}
              labCompanyNamesBloodDrawNotIncluded={
                order.lab_company_names_blood_draw_not_included
              }
              requiredNumberOfPhlebotomyProviders={
                requiredNumberOfPhlebotomyProviders
              }
              onBloodDrawModified={() => setIsBloodDrawSelectionDirty(true)}
              onBloodDrawConfirmed={() => setIsBloodDrawSelectionDirty(false)}
              allowAxle={allowAxle}
              isPractitionerPay={order.is_practitioner_paying}
            />
          )}

          <Consent order={order} className={classes.sectionConsent} />

          {(isPaymentRequired(order) || showInsuranceToggle) && (
            <PatientPayment
              order={order}
              className={classes.sectionPayment}
              isUpdatingOrder={isUpdatingOrder}
              updateOrder={updateOrder}
              orderRequestStatus={orderRequestStatus}
              checkoutToken={checkoutToken}
              orderInsuredCompanies={orderInsuredCompanies}
            />
          )}

          <Insurance
            className={classes.sectionInsurance}
            isWideView={isWideView}
            order={order}
            updateOrder={updateOrder}
            isUpdatingOrder={isUpdatingOrder}
          />
        </div>

        <SubmitButton
          className={classes.submitButton}
          disabledReason={submissionDisabledReason}
          isSubmitPending={
            isSubmitPending || isUpdatingOrder || isUpdatingLineItems
          }
          wrapperClassName={classes.submitButtonWrapper}
        />
      </div>

      {isWideView && (
        <OrderDetails
          checkoutToken={checkoutToken}
          className={classes.orderDetailsWideView}
          order={order}
          setCancelModalOpenWithOptions={setCancelModalOpenWithOptions}
          isOrderBeingUpdated={
            orderRequestStatus === OrderRequestStatus.UPDATING
          }
          updatingOrderedTestIds={updatingOrderedTestIds}
          enableLineItems={enableLineItems}
          disableLineItems={disableLineItems}
          isUpdatingLineItems={isUpdatingLineItems}
          isUpdatingOrder={isUpdatingOrder}
        />
      )}

      <CancelOrderModal
        checkoutToken={checkoutToken}
        order={order}
        openWithOptions={cancelModalOpenWithOptions}
        onClose={() => setCancelModalOpenWithOptions(null)}
      />
    </form>
  )
}

const useClasses = makeStyles((theme) => {
  return {
    form: {
      display: "flex",
      width: "100%",
      flexDirection: "row",
      gap: 38,
    },
    formSections: {
      display: "flex",
      flexDirection: "column",
      gap: 38,
      marginTop: "13px",
    },
    header: {
      marginTop: "32px",
    },
    orderDetailsNarrowView: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
      padding: theme.spacing(3),
      backgroundColor: "white",
      boxShadow: shadows.default,
      border: `1px solid ${colors.blueGray[200]}`,
      borderRadius: 8,
    },
    orderDetailsWideView: {
      marginTop: theme.spacing(1.5),
      minWidth: 460,
      maxWidth: 500,
      padding: theme.spacing(3),
      flex: "1 1 auto",
      backgroundColor: "white",
      boxShadow: shadows.default,
      border: `1px solid ${colors.blueGray[200]}`,
      borderRadius: 8,
    },
    sectionConsent: {
      padding: theme.spacing(3),
      backgroundColor: "white",
      boxShadow: shadows.default,
      border: `1px solid ${colors.blueGray[200]}`,
      borderRadius: 8,
    },
    sectionPayment: {
      padding: theme.spacing(3),
      backgroundColor: "white",
      boxShadow: shadows.default,
      border: `1px solid ${colors.blueGray[200]}`,
      borderRadius: 8,
    },
    sectionPersonalInfo: {
      padding: "24px 24px 20px",
      backgroundColor: "white",
      boxShadow: shadows.default,
      border: `1px solid ${colors.blueGray[200]}`,
      borderRadius: 8,
    },
    sectionInsurance: {
      padding: theme.spacing(3),
      backgroundColor: "white",
      boxShadow: shadows.default,
      border: `1px solid ${colors.blueGray[200]}`,
      borderRadius: 8,
    },
    submitButton: {
      minWidth: ({ isWideView }: { isWideView: boolean }) =>
        isWideView ? "300px" : "200px",
      width: ({ isWideView }: { isWideView: boolean }) =>
        isWideView ? "auto" : "100%",
    },
    submitButtonWrapper: {
      display: "flex",
      flexDirection: ({ isWideView }: { isWideView: boolean }) =>
        isWideView ? "row" : "column-reverse",
      justifyContent: ({ isWideView }: { isWideView: boolean }) =>
        isWideView ? "space-between" : "flex-start",
      alignItems: "center",
      margin: "32px 0",
    },
    sectionPhysicianServicesExplanation: {
      display: ({ isWideView }: { isWideView: boolean }) =>
        isWideView ? "flex" : "block",
      padding: ({ isWideView }: { isWideView: boolean }) =>
        isWideView ? theme.spacing(1.5) : theme.spacing(3),
      backgroundColor: "white",
      boxShadow: shadows.default,
      border: `1px solid ${colors.blueGray[200]}`,
      borderRadius: 8,
      color: navy,
      "& > span": {
        display: ({ isWideView }: { isWideView: boolean }) =>
          isWideView ? "flex" : "block",
        paddingBottom: ({ isWideView }: { isWideView: boolean }) =>
          isWideView ? 0 : 9,
        paddingRight: 14,
      },
    },
  }
})

const Header = ({ className, order }) => {
  const patient = order?.patient
  const practitionerUser =
    order && order.practitioner && order.practitioner.user

  const patientBlurb = patient?.first_name && ` for ${patient.first_name}`

  const practitionerName = `${
    practitionerUser && practitionerUser.first_name
  } ${practitionerUser && practitionerUser.last_name}`

  const orderTitle = order.labshop_name
    ? `Order from ${order.labshop_name}`
    : order.requires_vendor_physician_authorization
    ? `${getOrderTypeLabel(
        true
      )} ${patientBlurb} created by ${practitionerName}`
    : `Order ${patientBlurb} by ${practitionerName}`

  return (
    <div className={className}>
      <Typography
        variant="h1"
        color="textPrimary"
        style={{ fontSize: 24 }}
        className="fs-exclude font-normal"
      >
        {orderTitle}
      </Typography>

      {order?.notes_to_patient ? (
        <NotesToPatient>
          <NotesToPatientTitle weight="semibold">
            Note from {order?.practitioner?.titled_full_name}:
          </NotesToPatientTitle>
          <NotesToPatientDescription italic>
            {order?.notes_to_patient}
          </NotesToPatientDescription>
        </NotesToPatient>
      ) : null}
    </div>
  )
}

const NotesToPatient = styled("div")(({ theme }) => ({
  marginTop: 24,
  marginBottom: 14,
  display: "flex",
  flexDirection: "column",
}))

const NotesToPatientTitle = styled(BodyText)({
  marginBottom: 4,
})

const NotesToPatientDescription = styled(BodyText)({
  textAlign: "left",
})

const SubmitButton = ({
  className,
  disabledReason,
  isSubmitPending,
  wrapperClassName,
}) => {
  const continueToReviewButton = (
    <Button
      className={className}
      color="primary"
      disabled={Boolean(disabledReason)}
      loading={isSubmitPending}
      size="large"
      variant="contained"
      type="submit"
    >
      {"Continue to Review"}
    </Button>
  )

  return (
    <div className={wrapperClassName}>
      <Typography
        color="textPrimary"
        className="text-center mt-1 sm:text-left sm:mt-1.5 mr-1.5 pt-4 sm:pt-0"
      >
        Questions? Contact{" "}
        {/* The default style applied in FuseLayout is more specific than anything we can */}
        {/* apply via tailwind or material, so we are forced to use an extra parent component. */}
        <Box component="span" className="underline inline">
          <Link
            href="mailto:support@rupahealth.com"
            className="font-semibold underline"
            color="textPrimary"
          >
            support@rupahealth.com
          </Link>
        </Box>
      </Typography>

      {disabledReason ? (
        <Tooltip title={disabledReason} placement="top" arrow>
          <div>{continueToReviewButton}</div>
        </Tooltip>
      ) : (
        continueToReviewButton
      )}
    </div>
  )
}

// Component for explaining how authorization works for Phys Services
const PhysicianServicesExplanation = ({ className }: { className: string }) => {
  return (
    <div className={className}>
      <span>📋</span>
      This lab testing will be ordered by an independent licensed physician who
      will reach out to you if a critical result is found. Tests placed using{" "}
      {VENDOR_PHYSICIAN_AUTHORIZATION_LABEL} are cash-pay only and not eligible
      for insurance reimbursement.
    </div>
  )
}

export default PatientCheckoutForm
