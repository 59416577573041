import { useMemo } from "react"

import {
  ColumnDef,
  ColumnFiltersState,
  ExpandedState,
  OnChangeFn,
  PaginationState,
  getCoreRowModel,
  getFilteredRowModel,
  useReactTable,
} from "@tanstack/react-table"

import { LabCompanyBiomarkerRange } from "types/lab-company-biomarker-range"

export interface GlobalFilterState {
  labCompanyKey: string
  hideUnsetRanges: boolean
}

declare module "@tanstack/react-table" {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface TableMeta<TData extends unknown> {
    count?: number
    isLoading: boolean
    isValidating: boolean
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface ColumnMeta<TData extends unknown, TValue> {
    /**
     * Styles to be applied to each cell in the column, including both data and header cells.
     *
     */
    cellStyle?: React.CSSProperties

    /**
     * Styles to be applied to each data cell in the column.
     */
    dataCellStyle?: React.CSSProperties

    /**
     * Styles to be applied to each header cell in the column.
     */
    headerCellStyle?: React.CSSProperties
  }
}

export interface UseTableProps {
  columns: ColumnDef<LabCompanyBiomarkerRange, any>[]
  columnFilters: ColumnFiltersState
  count?: number
  globalFilter: GlobalFilterState
  data: LabCompanyBiomarkerRange[]
  isLoading: boolean
  isValidating: boolean
  onColumnFiltersChange: OnChangeFn<ColumnFiltersState>
  onGlobalFilterChange: OnChangeFn<GlobalFilterState>
  onPaginationChange: OnChangeFn<PaginationState>
  pagination: PaginationState
  expanded: ExpandedState
  handleExpandedChange: OnChangeFn<ExpandedState>
}

export default function useTable({
  columns,
  columnFilters,
  count,
  globalFilter,
  data,
  isLoading,
  isValidating,
  onColumnFiltersChange,
  onGlobalFilterChange,
  onPaginationChange,
  pagination,
  expanded,
  handleExpandedChange,
}: UseTableProps) {
  const meta = useMemo(
    () => ({
      count,
      isLoading,
      isValidating,
    }),
    [isLoading, isValidating, count]
  )

  const pageCount = count ? Math.ceil(count / pagination.pageSize) : -1

  return useReactTable({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getRowId(row) {
      return row.id
    },
    meta,
    onColumnFiltersChange,
    onGlobalFilterChange,
    state: {
      columnFilters,
      globalFilter,
      pagination,
      expanded,
    },
    onExpandedChange: handleExpandedChange,
    manualPagination: true,
    manualFiltering: true,
    onPaginationChange,
    pageCount,
  })
}
