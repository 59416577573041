import QueryToken from "./QueryToken"
import { QueryParam } from "./types"

export interface QueryTokenListProps {
  blurToken: (index: number) => void
  focusToken: (index: number) => void
  focusNextToken: (index: number) => void
  focusPrevToken: (index: number) => void
  queryTokens: QueryParam[]
  removeToken: (token: QueryParam) => void
  tokenCursor: number
}

export default function QueryTokenList({
  blurToken,
  focusToken,
  focusNextToken,
  focusPrevToken,
  queryTokens,
  removeToken,
  tokenCursor,
}: QueryTokenListProps) {
  return (
    <>
      {queryTokens.map((token, tokenIndex) => {
        return (
          <QueryToken
            key={`${token.queryName}_${token.value}`}
            focused={tokenCursor === tokenIndex}
            label={token.label || token.value}
            focusNext={() => focusNextToken(tokenIndex)}
            focusPrev={() => focusPrevToken(tokenIndex)}
            onBlur={() => blurToken(tokenIndex)}
            onFocus={() => focusToken(tokenIndex)}
            onRemove={() => removeToken(token)}
            type={token.queryName}
            nonRemovable={token.nonRemovable}
          />
        )
      })}
    </>
  )
}
