import React, { useCallback, useEffect, useMemo } from "react"

import { Card, CardContent, Collapse } from "@material-ui/core"
import { Theme, makeStyles } from "@material-ui/core/styles"
import ExpandLessIcon from "@material-ui/icons/ExpandLess"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"

import { ReactComponent as CheckIcon } from "app/assets/icons/check-circle.svg"
import { ReactComponent as CircleIcon } from "app/assets/icons/empty-circle.svg"
import { colors, primaryColor, white } from "app/theme"

import { useOnboardingContext } from "./OnboardingProvider"
import { OnboardingTaskKey } from "./types"

interface StyleProps {
  isOpen: boolean
  isTaskComplete: boolean
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  card: (props) => ({
    backgroundColor: props.isOpen ? colors.blue[50] : white,
    border: "1px solid",
    borderColor: props.isOpen ? primaryColor : colors.blueGray[200],
    borderRadius: "8px",
    width: "100%",
    minWidth: "600px",
    boxShadow: "none",
    [theme.breakpoints.down("sm")]: {
      minWidth: "unset",
    },
  }),
  header: (props) => ({
    backgroundColor: props.isOpen ? "#EFF6FF" : "#FFFFFF",
    borderRadius: "8px",
    padding: "16px",
    display: "flex",
    alignItems: "center",
    cursor: props.isTaskComplete ? "default" : "pointer",
  }),
  taskContainer: {
    display: "flex",
    backgroundColor: "white",
    borderRadius: 8,
    padding: 24,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-around",
    flexGrow: 1,
    gap: 15,
  },
  circleIcon: {
    width: "32px",
    height: "20px",
    flexShrink: 0,
    fontWeight: 700,
    fontSize: "18.8852px",
    lineHeight: "140%",
    order: 0,
  },
  checkIcon: {
    flexShrink: 0,
  },
  textContainer: {
    display: "flex",
    alignItems: "center",
  },
  headerText: {
    fontWeight: 600,
    fontSize: "15px",
    lineHeight: "135%",
    display: "flex",
    alignItems: "center",
    color: "#2B455F",
    marginLeft: "10px",
    flexGrow: 0,
  },
  timeTag: (props) => ({
    backgroundColor: props.isOpen ? white : colors.blueGray[100],
    borderRadius: "12px",
    color: colors.blueGray[500],
    padding: "4px 8px",
    marginLeft: "10px",
    fontSize: "12px",
    minWidth: "60px",
    textAlign: "center",
  }),
  expandIcon: {
    marginLeft: "auto",
  },
  openIcon: {
    color: primaryColor,
  },
  subheaderContainer: {
    display: "flex",
    alignItems: "left",
    justifyContent: "left",
    padding: "0px 16px 16px 16px",
  },
  subheaderText: {
    fontWeight: 400,
    fontSize: "15px",
    lineHeight: "135%",
    color: colors.blueGray[500],
  },
  completedTask: {
    opacity: 0.4,
  },
  content: {
    display: "flex",
    flexDirection: "column",
    maxWidth: "98%",
    minHeight: "200px",
    margin: "auto",
    transition: "all 0.3s ease",
    padding: "0px 16px 16px 16px",
  },
}))

export interface TaskContainerProps {
  taskKey?: OnboardingTaskKey
  header: string
  children?: React.ReactNode
  isTaskComplete: boolean
  subheader?: string
  subCardContent?: React.ReactNode
  timeTag?: string
  openAfterComplete?: boolean
}

const TaskContainer: React.FC<TaskContainerProps> = ({
  taskKey,
  header,
  children,
  isTaskComplete,
  subheader,
  subCardContent,
  timeTag,
  openAfterComplete = false,
}) => {
  const { openCard, setOpenCard } = useOnboardingContext()

  const isOpen = useMemo(
    () => !!openCard && openCard === taskKey,
    [openCard, taskKey]
  )

  const setIsOpen = useCallback(
    (val: boolean) => {
      if (!taskKey) return
      setOpenCard(val ? taskKey : undefined)
    },
    [taskKey, setOpenCard]
  )

  const classes = useStyles({ isOpen, isTaskComplete })

  useEffect(() => {
    if (isTaskComplete && isOpen) {
      setIsOpen(false)
    }
  }, [isTaskComplete])

  const toggleCollapse = () => {
    if (!isTaskComplete || openAfterComplete) {
      setIsOpen(!isOpen)
    }
  }

  return (
    <Card className={classes.card}>
      <div className={classes.header} onClick={toggleCollapse}>
        <div className={classes.textContainer}>
          {isTaskComplete ? (
            <CheckIcon
              className={classes.checkIcon}
              width={18}
              height={18}
              viewBox="0 0 14 14"
              fill={colors.emerald[500]}
            />
          ) : (
            <CircleIcon
              className={classes.circleIcon}
              fill={isOpen ? primaryColor : colors.blueGray[200]}
            />
          )}
          <div
            className={
              isTaskComplete
                ? `${classes.headerText} ${classes.completedTask}`
                : classes.headerText
            }
          >
            {header}
          </div>
          {timeTag && <div className={classes.timeTag}>{timeTag}</div>}
        </div>
        {isOpen ? (
          <ExpandLessIcon
            className={(isTaskComplete && !openAfterComplete
              ? `${classes.expandIcon} ${classes.completedTask}`
              : classes.expandIcon
            ).concat(" ", classes.openIcon)}
          />
        ) : (
          <ExpandMoreIcon
            className={
              isTaskComplete
                ? `${classes.expandIcon} ${classes.completedTask}`
                : classes.expandIcon
            }
          />
        )}
      </div>
      <Collapse in={isOpen}>
        {subheader && (
          <div className={classes.subheaderContainer}>
            <div className={classes.subheaderText}>{subheader}</div>
          </div>
        )}
        {children && (
          <CardContent className={classes.content}>
            <div className={classes.taskContainer}>{children}</div>
          </CardContent>
        )}
        {subCardContent}
      </Collapse>
    </Card>
  )
}

export default TaskContainer
