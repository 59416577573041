import React from "react"

import { Button } from "@rupahealth/design"

import useUserResultModal from "../../user-result-modal/UserResultModal"
import { Step1UploadProps } from "./Step1Upload"

type Props = Pick<Step1UploadProps, "patientId" | "onClose"> & {
  handleUploadClick: (e: React.MouseEvent) => void
}

export const Step1UploadInitial: React.FC<Props> = ({
  patientId,
  onClose,
  handleUploadClick,
}) => {
  const userResultModal = useUserResultModal()

  return (
    <div className="flex flex-col items-center gap-[9px] w-full transition-opacity duration-500">
      <span>Upload PDFs or screenshots of your lab test reports.</span>
      <Button variant="primary" className="w-full" onClick={handleUploadClick}>
        Select Files
      </Button>
      {/* For now, we only allow manual uploads in the practitioner portal (where patientId is present) */}
      {patientId && (
        <>
          <span className="text-slate-500">or</span>
          <Button
            variant="ghost"
            className="text-primary p-0 m-0 h-auto w-full hover:bg-transparent"
            onClick={(e) => {
              userResultModal.show({ patientId })
              onClose()
              e.stopPropagation()
            }}
          >
            Enter Results Manually
          </Button>
        </>
      )}
    </div>
  )
}
