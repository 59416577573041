import { KeyboardEvent } from "react"

import isHotkey from "is-hotkey"
import { Editor } from "slate"

import { toggleMark } from "app/components/RichTextEditor/utils/mark"

const HOTKEYS = {
  "mod+b": "bold",
  "mod+i": "italic",
  "mod+u": "underline",
  "mod+k": "hyperlink",
}

export function handleHotKeyDown(
  editor: Editor,
  event: KeyboardEvent<HTMLDivElement>
) {
  for (const hotkey in HOTKEYS) {
    if (isHotkey(hotkey, event as any)) {
      event.preventDefault()
      const mark = HOTKEYS[hotkey]
      toggleMark(editor, mark)
    }
  }
}
