import { useMemo } from "react"

import { AxiosError } from "axios"
import useSWR, { KeyedMutator, SWRConfiguration, useSWRConfig } from "swr"

import useEventCallback from "app/hooks/use-event-callback"
import useHandleApiError from "app/hooks/use-handle-api-error"
import { writeToCache } from "app/swr/helpers/swr"
import {
  ResourceCollection,
  ResourceCollectionResponse,
  ResourceErrorResponse,
  ResourceIdentifier,
  ResourceRequestCacheKey,
  ResourceRequestConfiguration,
} from "app/swr/types"
import resourceRequest from "app/swr/utils/resource-request"

export type CollectionSWRConfiguration = SWRConfiguration & {
  /**
   * Whether or not to engage `handleApiError` function when the fetcher fails. Defaults to true.
   */
  shouldHandleApiError?: boolean
}

export interface UseCollectionSwrReturn<RC extends ResourceCollection> {
  error: AxiosError<ResourceErrorResponse> | undefined
  mutate: KeyedMutator<RC | undefined>
  isValidating: boolean
  isLoading: boolean
  data: RC["data"]
  meta: RC["meta"]
}

/**
 * Fetches a resource collection at the given url using the SWR pattern.
 *
 * Useful for fetching data for a collection of resource object, for example:
 *
 * ```typescript
 * const { data } = useCollectionSWR("/lab_tests")
 * ```
 *
 * Supports non-standard endpoint urls as long as the response conforms to JSON:API specification, for example:
 *
 * ```typescript
 * const { data } = useCollectionSWR("/storefront/123/products")
 * ```
 *
 * Supports the "include" parameter first-class to support fetching related resources efficiently, for example:
 *
 * ```typescript
 * const { data: labTestRefs } = useCollectionSWR("/lab_tests", { include: ["lab_company"] })
 * const labTests = useCachedCollection(labTestRefs)
 *
 * // Access a lab company from the cache.
 * const { data: labCompany } = useCachedResource<LabCompany>(labTests?.[0].relationships.lab_company.data)
 * ```
 *
 * Supports custom parameters if needed for certain scenarios, for example:
 *
 * ```typescript
 * const { data } = useCollectionSWR("/lab_tests", { params: { q: "search" }})
 * ```
 *
 * Supports providing an access token to be included as an Authorization header bearer token, for example:
 *
 * ```typescript
 * const { data } = useCollectionSWR<LabTest>("/lab_tests/123", { accessToken: myJwtToken })
 * ```
 *
 * @param url the JSON:API endpont url
 * @param requestOptions the request configuration and options
 * @param collectionSwrConfig the SWR configuration
 * @returns the swr response with the resource collection
 */
export default function useCollectionSWR<
  RC extends ResourceCollection = ResourceCollection
>(
  url?: string | null | false,
  requestOptions: Omit<ResourceRequestConfiguration, "url"> = {},
  collectionSwrConfig: CollectionSWRConfiguration = {}
): UseCollectionSwrReturn<RC> {
  const globalConfig = useSWRConfig()
  const { params, include, method, ...options } = requestOptions
  const requestKey = useMemo(
    () =>
      url
        ? {
            url,
            params,
            include,
            method,
          }
        : null,
    [url, params, include, method]
  )
  const { shouldHandleApiError = true, ...swrHookConfig } = collectionSwrConfig
  const handleApiError = useHandleApiError()

  const { data, ...swr } = useSWR<
    RC | undefined,
    AxiosError<ResourceErrorResponse>,
    ResourceRequestCacheKey | null
  >(
    requestKey,
    useEventCallback(async (requestKey) => {
      const {
        data = [],
        included = [],
        meta,
      } = await resourceRequest<ResourceCollectionResponse>({
        ...requestKey,
        ...options,
      })

      // Write to the resource cache with any new resources.
      await writeToCache(globalConfig, ...data, ...included)

      const identifiers: ResourceIdentifier[] = data.map((resource) => ({
        type: resource.type,
        id: resource.id,
      }))

      return {
        data: identifiers,
        meta,
      } as RC
    }),
    {
      ...swrHookConfig,
      onError(err, _key, _config) {
        if (shouldHandleApiError) {
          handleApiError(err)
        }

        swrHookConfig?.onError?.(err, _key, _config)
      },
    }
  )

  return {
    ...swr,
    data: (data?.data || []) as RC["data"],
    meta: data?.meta as RC["meta"],
  }
}
