import { useEffect, useState } from "react"

import { debounce } from "lodash"

export function useDropdownHeight(
  buttonRef: React.RefObject<HTMLButtonElement>
) {
  const [buttonHeight, setButtonHeight] = useState(0)

  useEffect(() => {
    const updateDropdownHeight = debounce(() => {
      if (!buttonRef.current) {
        return
      }

      const buttonRect = buttonRef.current?.getBoundingClientRect()
      if (!buttonRect) return

      // 29 = 24px margin + the 5px margin the button gives to the dropdown
      const distanceToBottom = window.innerHeight - buttonRect.bottom - 29
      // Use a minimum height so we don't render too small a dropdown
      const dropdownHeight = Math.max(distanceToBottom, 200)

      setButtonHeight(dropdownHeight)
    }, 200)

    // Ensure we calculate the height on first load
    updateDropdownHeight()

    window.addEventListener("resize", updateDropdownHeight)
    document.addEventListener("scroll", updateDropdownHeight, true)

    return () => {
      window.removeEventListener("resize", updateDropdownHeight)
      document.removeEventListener("scroll", updateDropdownHeight, true)
    }
  }, [buttonRef.current])

  return buttonHeight
}
