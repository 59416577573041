import clsx from "clsx"

import { ReactComponent as Checklist } from "app/assets/icons/clipboard-checklist-blue.svg"
import BodyText from "app/components/design-system/BodyText"
import BloodLabDashboardsBiomarkerStatusIndicator from "app/main/blood-lab-dashboards/BloodLabDashboardsPatientPreview/statuses/BloodLabDashboardsBiomarkerStatusIndicator"
import { BiomarkerStatus } from "app/patient-portal/blood-lab-dashboard/constants"
import {
  getBiomarkerStatus,
  parseStringValue,
} from "app/patient-portal/blood-lab-dashboard/utils"
import useCachedCollection from "app/swr/hooks/use-cached-collection"
import useCachedResource from "app/swr/hooks/use-cached-resource"
import { colors } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"
import { Biomarker } from "types/biomarker"
import { BiomarkerCustomDescription } from "types/biomarker-custom-description"
import { DiscreteResult } from "types/discrete-result"
import { LabCompanyBiomarkerRange } from "types/lab-company-biomarker-range"
import { OrderedResult } from "types/ordered-result"

import NumericBiomarkerGraphic from "../NumericBiomarkerGraphic/NumericBiomarkerGraphic"
import BloodLabDashboardsBiomarkerCard from "./BloodLabDashboardsBiomarkerCard"

const useStyles = makeAppStyles((theme) => ({
  biomarkerValue: {
    fontSize: 17,
    fontWeight: 600,
    marginLeft: 5,
  },
  biomarkerUnit: {
    fontSize: 11.45,
    fontWeight: 600,
    color: colors.blueGray[400],
    marginLeft: 1,
    marginTop: 5,
  },
  semiBold: {
    fontWeight: 600,
  },
  checklistIcon: {
    height: 16,
    width: 16,
    marginRight: 2,
  },
  detailedInfoContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "15px",
  },
  redValueColor: {
    color: colors.red[900],
  },
  yellowValueColor: {
    color: colors.yellow[900],
  },
  greenValueColor: {
    color: colors.emerald[900],
  },
  redStatusColor: {
    color: colors.red[700],
  },
  yellowStatusColor: {
    color: colors.yellow[700],
  },
  greenStatusColor: {
    color: colors.emerald[700],
  },
}))

export default function BloodLabDashboardsBiomarkerCardFromDiscreteResult({
  discreteResult,
  showHighLowDescriptions,
  useOptimalRanges,
  biomarkerCustomDescription,
}: {
  discreteResult: DiscreteResult
  showHighLowDescriptions?: boolean
  useOptimalRanges?: boolean
  biomarkerCustomDescription?: BiomarkerCustomDescription
}) {
  const classes = useStyles()

  const biomarker = useCachedResource<Biomarker>(
    discreteResult.relationships.biomarker.data
  )

  const orderedResult = useCachedResource<OrderedResult>(
    discreteResult.relationships.ordered_result.data
  )

  const labCompanyBiomarkerRanges =
    useCachedCollection<LabCompanyBiomarkerRange>(
      biomarker?.relationships.lab_company_biomarker_ranges.data
    )

  const biomarkerOptimalRange = labCompanyBiomarkerRanges.filter(
    (optimalRange) =>
      optimalRange.relationships.lab_company.data.id ===
      orderedResult?.relationships.lab_company.data.id
  )?.[0]

  const biomarkerStatus = getBiomarkerStatus(
    discreteResult,
    biomarkerOptimalRange,
    useOptimalRanges
  )

  const descriptions = {
    low: biomarkerCustomDescription?.attributes.low_description
      ? biomarkerCustomDescription?.attributes.low_description
      : biomarker?.attributes.low_description,
    high: biomarkerCustomDescription?.attributes.high_description
      ? biomarkerCustomDescription?.attributes.high_description
      : biomarker?.attributes.high_description,
    main: biomarkerCustomDescription?.attributes.description
      ? biomarkerCustomDescription?.attributes.description
      : biomarker?.attributes.description,
  }

  const normalMin = discreteResult.attributes.normal_max
    ? discreteResult.attributes.normal_max
    : biomarkerOptimalRange?.attributes?.standard_range_min
  const normalMax = discreteResult.attributes.normal_min
    ? discreteResult.attributes.normal_min
    : biomarkerOptimalRange?.attributes?.standard_range_max

  const valueStyle = {
    [BiomarkerStatus.HIGH]: classes.redValueColor,
    [BiomarkerStatus.LOW]: classes.redValueColor,
    [BiomarkerStatus.ABOVE_OPTIMAL]: classes.yellowValueColor,
    [BiomarkerStatus.BELOW_OPTIMAL]: classes.yellowValueColor,
    [BiomarkerStatus.NORMAL]: classes.greenValueColor,
    [BiomarkerStatus.OPTIMAL]: classes.greenValueColor,
  }

  const statusStyle = {
    [BiomarkerStatus.HIGH]: classes.redStatusColor,
    [BiomarkerStatus.LOW]: classes.redStatusColor,
    [BiomarkerStatus.ABOVE_OPTIMAL]: classes.yellowStatusColor,
    [BiomarkerStatus.BELOW_OPTIMAL]: classes.yellowStatusColor,
    [BiomarkerStatus.NORMAL]: classes.greenStatusColor,
    [BiomarkerStatus.OPTIMAL]: classes.greenStatusColor,
  }

  if (!biomarker) {
    return null
  }

  if ((!normalMin || normalMin === "") && (!normalMax || normalMax === "")) {
    return null
  }

  return (
    <BloodLabDashboardsBiomarkerCard
      name={biomarker?.attributes.short_name}
      valueSection={
        <>
          <BloodLabDashboardsBiomarkerStatusIndicator
            biomarkerStatus={biomarkerStatus}
          />
          <BodyText
            className={clsx(
              classes.biomarkerValue,
              biomarkerStatus ? valueStyle[biomarkerStatus] : ""
            )}
          >
            {discreteResult.attributes.value}
          </BodyText>
          <div>
            <div
              className={clsx(
                classes.biomarkerUnit,
                biomarkerStatus ? statusStyle[biomarkerStatus] : ""
              )}
            >
              {discreteResult.attributes.units}
            </div>
          </div>
        </>
      }
      graphicSection={
        <NumericBiomarkerGraphic
          normalMax={
            discreteResult.attributes.normal_max
              ? discreteResult.attributes.normal_max
              : useOptimalRanges
              ? biomarkerOptimalRange?.attributes.standard_range_max || ""
              : ""
          }
          normalMin={
            discreteResult.attributes.normal_min
              ? discreteResult.attributes.normal_min
              : useOptimalRanges
              ? biomarkerOptimalRange?.attributes.standard_range_min || ""
              : ""
          }
          optimalMax={
            biomarkerOptimalRange?.attributes.optimal_range_max &&
            useOptimalRanges
              ? biomarkerOptimalRange?.attributes.optimal_range_max
              : ""
          }
          optimalMin={
            biomarkerOptimalRange?.attributes.optimal_range_min &&
            useOptimalRanges
              ? biomarkerOptimalRange?.attributes.optimal_range_min
              : ""
          }
          value={parseStringValue(discreteResult.attributes.value)}
          status={biomarkerStatus || BiomarkerStatus.NORMAL}
        />
      }
    >
      <div>{descriptions.main}</div>
      {showHighLowDescriptions &&
        (biomarkerStatus === BiomarkerStatus.LOW ||
          biomarkerStatus === BiomarkerStatus.BELOW_OPTIMAL) && (
          <div className={classes.detailedInfoContainer}>
            <div>
              <span className={classes.semiBold}>▼</span> {descriptions.low}
            </div>
            {biomarker?.attributes.low_symptoms && (
              <div>
                <span>
                  <Checklist
                    viewBox="0 0 100 100"
                    className={classes.checklistIcon}
                  />
                </span>{" "}
                {biomarker?.attributes.low_symptoms}
              </div>
            )}
          </div>
        )}

      {showHighLowDescriptions &&
        (biomarkerStatus === BiomarkerStatus.HIGH ||
          biomarkerStatus === BiomarkerStatus.ABOVE_OPTIMAL) && (
          <div className={classes.detailedInfoContainer}>
            <div>
              <span className={classes.semiBold}>▲</span> {descriptions.high}
            </div>
            {biomarker?.attributes.high_symptoms && (
              <div>
                <span>
                  <Checklist
                    viewBox="0 0 100 100"
                    className={classes.checklistIcon}
                  />
                </span>{" "}
                {biomarker?.attributes.high_symptoms}
              </div>
            )}
          </div>
        )}
    </BloodLabDashboardsBiomarkerCard>
  )
}
