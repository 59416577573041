import {
  createContext,
  useState,
  useContext,
  useRef,
  useCallback,
  useEffect,
} from "react"

import ReactCanvasConfetti from "react-canvas-confetti"

const ConfettiContext = createContext((timeout?: number) => {})

export const useConfetti = () => {
  return useContext(ConfettiContext)
}

const canvasStyles = {
  position: "fixed" as const,
  pointerEvents: "none" as const,
  zIndex: 99999,
  width: "100%",
  height: "100%",
  top: 0,
  left: 0,
}

function getAnimationSettings() {
  return {
    startVelocity: 60,
    spread: 180,
    angle: 270,
    scalar: 1.5,
    ticks: 200,
    particleCount: 300,
    origin: {
      x: 0.5,
      y: 0,
    },
  }
}

type ConfettiInstance = {
  (options?: object): void
  reset(): void
}

export const ConfettiProvider = ({ children }) => {
  const [showConfetti, setShowConfetti] = useState(false)
  const refAnimationInstance = useRef<ConfettiInstance | null>(null)

  const getInstance = useCallback((instance: ConfettiInstance | null) => {
    refAnimationInstance.current = instance
  }, [])

  const startAnimation = () => {
    if (refAnimationInstance.current) {
      refAnimationInstance.current(getAnimationSettings())
    }
  }

  useEffect(() => {
    if (showConfetti) {
      startAnimation()
      setTimeout(() => {
        setShowConfetti(false)
      }, 5000)
    }
  }, [showConfetti, startAnimation])

  const triggerConfetti = () => {
    setShowConfetti(true)
  }

  return (
    <ConfettiContext.Provider value={triggerConfetti}>
      {children}
      {showConfetti && (
        <ReactCanvasConfetti
          reset={showConfetti}
          refConfetti={getInstance}
          style={canvasStyles}
        />
      )}
    </ConfettiContext.Provider>
  )
}
