import { createContext, useContext } from "react"

import { OnboardingTaskKey } from "./types"

interface OnboardingContextProps {
  openCard: OnboardingTaskKey | undefined
  setOpenCard: (key: OnboardingTaskKey | undefined) => void
}

export const OnboardingContext = createContext<OnboardingContextProps>(
  undefined as never
)

interface Props {
  children: React.ReactNode
  openCard: OnboardingTaskKey | undefined
  setOpenCard: (key: OnboardingTaskKey | undefined) => void
}

function OnboardingProvider({ children, ...props }: Props) {
  return (
    <OnboardingContext.Provider value={props}>
      {children}
    </OnboardingContext.Provider>
  )
}

export function useOnboardingContext() {
  return useContext(OnboardingContext)
}

export default OnboardingProvider
