import { DropDownOption } from "../types/types"

interface Props {
  option: DropDownOption | undefined
  color: string
  useDropdownLabel?: boolean
}

const OptionLabel = ({ option, color, useDropdownLabel }: Props) => {
  if (!option) return null

  return (
    <div className="flex items-center" style={{ color }}>
      {option.icon}
      <span className="mx-[10px]">
        {useDropdownLabel ? option.dropdownLabel : option.label}
      </span>
    </div>
  )
}

export default OptionLabel
