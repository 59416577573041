import { useEffect } from "react"

import { Theme, useMediaQuery } from "@material-ui/core"
import { captureException } from "@sentry/react"

import { setHideIntercom } from "app/services/intercom"

import ESignature from "../../ESignature"

interface Props {
  show?: boolean
  onComplete: () => void
  onClose: () => void
}

const CredentialModalESignature = ({ show, onComplete, onClose }: Props) => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"))

  // Override the modal pointer events to allow the e-signature iFrame to be clickable
  // See: https://github.com/radix-ui/primitives/issues/2122
  useEffect(() => {
    if (show) {
      const timer = setTimeout(() => {
        document.body.style.pointerEvents = ""
      }, 0)

      return () => clearTimeout(timer)
    } else {
      document.body.style.pointerEvents = "auto"
    }
  }, [show])

  const handleComplete = () => {
    setHideIntercom(false)
    window.Intercom("trackEvent", "esignature-completed")
    onComplete()
  }

  const handleOpen = () => {
    if (isMobile) setHideIntercom(true)
  }

  const handleClose = () => {
    setHideIntercom(false)
    onClose()
  }

  const handleFail = (error: Error) => {
    captureException(error)
    handleClose()
  }

  if (show) {
    return (
      <ESignature
        onEsignOpen={handleOpen}
        onEsignCancel={handleClose}
        onEsignClose={handleClose}
        onEsignCompleted={handleComplete}
        onEsignFail={handleFail}
      />
    )
  }
  return null
}

export default CredentialModalESignature
