import * as React from "react"

import clsx from "clsx"
import { Control, useController } from "react-hook-form"

import { colors, textPrimaryColor, primaryColor, shadows } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"

const useStyles = makeAppStyles((theme) => ({
  root: {
    width: "100%",
    display: "flex",
    flexFlow: "column nowrap",
    justifyContent: "flex-start",
  },
  label: {
    display: "inline-flex",
    flexFlow: "row nowrap",
    alignItems: "center",
    gap: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
    fontSize: 14,
    fontWeight: 600,
    color: textPrimaryColor,
    "& > svg": {
      fill: "currentColor",
    },
  },
  options: {
    display: "flex",
    flexFlow: "row wrap",
    gap: theme.spacing(1.0),
  },
  buttonOption: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(0.5),
    padding: theme.spacing(1.0, 1.5),
    fontSize: 15,
    fontWeight: 600,
    color: textPrimaryColor,
    backgroundColor: colors.blueGray[50],
    border: `1px solid ${colors.blueGray[300]}`,
    borderRadius: 6,
    boxShadow: shadows.sm,
    "&:disabled": {
      color: colors.blueGray[300],
      backgroundColor: colors.blueGray[50],
      boxShadow: "none",
      borderColor: colors.blueGray[300],
      pointerEvents: "none",
    },
  },
  buttonOptionActive: {
    color: primaryColor,
    backgroundColor: colors.blue[50],
    borderColor: primaryColor,
    "&:disabled": {
      color: colors.blueGray[400],
      backgroundColor: colors.blueGray[50],
      borderColor: colors.blueGray[400],
    },
  },
}))

export interface FoodPlanButtonSelectOption {
  icon: React.ReactNode
  label: string
  value: string
}

export interface FoodPlanButtonSelectFieldPropsProps {
  control: Control<any>
  disabled?: boolean
  label: string
  name: string
  options: FoodPlanButtonSelectOption[]
}

/**
 * The button select field component for the food plan form.
 */
export default function FoodPlanButtonSelectFieldProps({
  control,
  disabled,
  label,
  name,
  options,
}: FoodPlanButtonSelectFieldPropsProps) {
  const classes = useStyles()
  const { field } = useController({
    control,
    name,
    shouldUnregister: true,
  })

  return (
    <div className={classes.root}>
      <label className={classes.label} htmlFor={name}>
        {label}
      </label>

      <div className={classes.options}>
        {options.map((option) => (
          <button
            key={option.value}
            className={clsx(classes.buttonOption, {
              [classes.buttonOptionActive]: option.value === field.value,
            })}
            disabled={disabled}
            onClick={() => !disabled && field.onChange(option.value)}
            type="button"
          >
            {option.icon}
            {option.label}
          </button>
        ))}
      </div>
    </div>
  )
}
