import { PatientPortalOrder } from "app/patient-portal/types"
import useCachedCollection from "app/swr/hooks/use-cached-collection"
import useCollectionSWR from "app/swr/hooks/use-collection-swr"
import { ResourceCollection } from "app/swr/types"

export default function usePatientPortalUserOrders(userId?: string) {
  const { data, isLoading } = useCollectionSWR<
    ResourceCollection<PatientPortalOrder>
  >(userId ? `/patient_portal/user/${userId}/order/` : null, {
    include: [
      "kits.lab_company",
      "practitioner",
      "storefront",
      "practitioner.clinic",
    ],
  })

  const orders = useCachedCollection<PatientPortalOrder>(data)

  return {
    orders,
    isLoading,
  }
}
