import { useState } from "react"
import { useDispatch } from "react-redux"

import { useLocation } from "react-router-dom"

import { useSpecimenIssueResolveModal } from "app/components/SpecimenIssues/resolution-modal/SpecimenIssueResolveModal"
import RupaLinearProgress from "app/components/design-system/RupaLinearProgress"
import useStatusComponents from "app/components/hooks/use-status-components"
import { STATUS_CONFIGURATION_TYPES } from "app/components/hooks/use-status-components/consts"
import statusConfigurations from "app/components/hooks/use-status-components/status-configurations/patient-orders"
import useAppSelector from "app/hooks/useAppSelector"
import { OrderedTest, Patient, PatientOrdersOrder } from "app/types"

interface Props {
  order: PatientOrdersOrder
  orderedTest: OrderedTest
  patient: Patient
}

const PatientOrdersOrderedTestProgress: React.FC<Props> = ({
  orderedTest,
  order,
  patient,
}) => {
  const dispatch = useDispatch()
  const modal = useSpecimenIssueResolveModal()
  const { pathname } = useLocation()
  const practitioner = useAppSelector(({ practitioner }) => practitioner)
  const [specimenIssueShowMore, setSpecimenIssueShowMore] = useState(false)

  const { lastUpdatedAt, progressPercentage, subTitle, title } =
    useStatusComponents({
      type: STATUS_CONFIGURATION_TYPES.PATIENT_ORDERS,
      statusConfigurations,
      orderedTest,
      order,
      patient,
      labCompany: orderedTest.lab_test.lab_company,
      specimenIssue: orderedTest.specimen_issue,
      specimenIssueShowMore,
      setSpecimenIssueShowMore,
      specimenIssueResolveModal: modal,
      pathname,
      currentPractitioner: practitioner,
      dispatch,
    })

  return (
    <div className="flex flex-col py-4">
      <p className="test-sm">{lastUpdatedAt}</p>
      <div className="my-[5px]">
        <div className="font-semibold text-base">{title}</div>
        <RupaLinearProgress
          progressPercentage={progressPercentage}
          aria-label="Order status progress"
        />
        <div className="flex items-center gap-1">{subTitle}</div>
      </div>
    </div>
  )
}

export default PatientOrdersOrderedTestProgress
