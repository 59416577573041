import { createContext } from "react"

import { DashboardOrderStatuses } from "../hooks/use-dashboard-order-statuses"

export interface TableRendererContextType {
  /**
   * Revalidates orders within the table. This is useful to downstream actions that may want to force a validation of the page data.
   *
   * @returns a callback that causes the table to revalidate orders
   */
  revalidateOrders: () => void
  orderStatuses?: DashboardOrderStatuses
  unreadResults: {
    count: number
    revalidate: () => void
  }
}

const TableRendererContext = createContext<TableRendererContextType>({
  revalidateOrders: () => {
    throw new Error("TableRendererContext not initialized")
  },
  unreadResults: {
    count: 0,
    revalidate: () => {
      throw new Error("TableRendererContext not initialized")
    },
  },
})

export default TableRendererContext
