import { useEffect } from "react"
import { useDispatch } from "react-redux"

import { FuseSplashScreen } from "@fuse"
import { useHistory } from "react-router-dom"

import createAuth0Client from "@auth0/auth0-spa-js"
import * as Sentry from "@sentry/react"

import { GuestPaths } from "app/Routes"
import authService from "app/auth/services/simplejwt-auth-service"
import { buildAuth0Config } from "app/settings"
import { getApiBaseUrl, getBaseUrl } from "app/utils"

function Callback(props) {
  const dispatch = useDispatch()
  const history = useHistory()

  useEffect(() => {
    async function handleCallback() {
      try {
        const auth0Config = buildAuth0Config(getBaseUrl(), getApiBaseUrl())
        const auth0 = await createAuth0Client(auth0Config)
        await auth0.handleRedirectCallback()
        const token = await auth0.getTokenSilently()

        await authService.loginWithAuth0Token(token)
      } catch (error) {
        Sentry.captureException(error)
        history.push(GuestPaths.LOGIN)
      }
    }

    handleCallback()
  }, [dispatch])

  return <FuseSplashScreen />
}

export default Callback
