import { ReactNode } from "react"

import {
  Checkbox,
  CheckboxProps,
  FormControl,
  FormControlLabel,
  FormHelperText,
} from "@material-ui/core"

import makeAppStyles from "app/utils/makeAppStyles"

export type CheckboxInputProps = Omit<CheckboxProps, "value"> & {
  error?: boolean
  helperText?: ReactNode
  label?: ReactNode
  value?: boolean
}

const useStyles = makeAppStyles({
  container: {
    padding: "0",
  },
  label: {
    display: "flex",
    alignItems: "flex-start",
  },
  checkbox: {
    padding: "0 9px",
  },
})

const CheckboxInput = ({
  error,
  helperText,
  label,
  hidden,
  ...checkboxInputProps
}: CheckboxInputProps) => {
  const styles = useStyles()

  const checkbox = (
    <Checkbox
      checked={checkboxInputProps.value}
      color="primary"
      hidden={hidden}
      className={styles.checkbox}
      {...checkboxInputProps}
    />
  )

  return (
    <FormControl error={error} className={styles.container}>
      <FormControlLabel
        className={styles.label}
        control={checkbox}
        label={label}
      />
      {error && helperText && !hidden && (
        <FormHelperText>{helperText}</FormHelperText>
      )}
    </FormControl>
  )
}

export default CheckboxInput
