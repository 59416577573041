import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import {
  styled,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  useMediaQuery,
} from "@material-ui/core"

import { UserPaths } from "app/Routes"
import AddNotesBlueIcon from "app/assets/icons/add-notes-blue.svg"
import CogIconGray from "app/assets/images/cog-icon-gray.svg"
import PageToolbar from "app/components/PageToolbar"
import BodyText from "app/components/design-system/BodyText"
import DesignSystemButton from "app/components/design-system/Button"
import SnippetModal from "app/components/modals/SnippetModal"
import useFeatureFlag, { FeatureFlag } from "app/hooks/use-feature-flag"
import * as Actions from "app/store/actions"
import { colors } from "app/theme"

import SavedTextSnippetsContainer from "./SavedTextSnippets/SavedTextSnippetsContainer"
import SideNav from "./SideNav"

const Wrapper = styled("div")({
  background: colors.coolGray[50],
  border: `1px solid ${colors.coolGray[200]}`,
  minHeight: "100vh",
  padding: 24,
})

const TitleWrapper = styled("div")({
  display: "flex",
  alignItems: "center",
  marginBottom: 6,
})

const BlockSeperator = styled("div")({
  margin: 24,
})

const StyledTable = styled(Table)({
  border: `1px solid ${colors.coolGray[200]}`,
})

const StyledTableCell = styled(TableCell)({
  display: "flex",
  justifyContent: "space-between",
})

const StyledTableHead = styled(TableHead)({
  background: colors.blueGray[50],
})

const StyledDesignSystemButton = styled(DesignSystemButton)(({ isMobile }) => ({
  marginTop: isMobile ? 10 : "auto",
  alignItems: "center",
  float: isMobile ? "left" : "right",
}))

const EmptySection = styled("div")({
  display: "flex",
  background: "white",
  justifyContent: "center",
  alignItems: "center",
  border: `2px dashed ${colors.blueGray[200]}`,
  padding: "32px 64px",
  borderRadius: 8,
})

function Snippets() {
  const dispatch = useDispatch()

  const [snippetModalIsOpen, setSnippetModalIsOpen] = useState(false)
  const [snippetOpenInEditModal, setSnippetOpenInEditModal] = useState(null)

  const snippets = useSelector(({ snippet }) => snippet)

  const [isSnippetsRefactorEnabled] = useFeatureFlag(
    FeatureFlag.RefactoredSnippets
  )

  useEffect(() => {
    dispatch(Actions.getSnippets())
  }, [dispatch])

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"))

  const handleOpenSnippetEditModal = (snippet) => {
    setSnippetOpenInEditModal(snippet)
    setSnippetModalIsOpen(true)
  }

  const handleCloseSnippetModal = () => {
    setSnippetOpenInEditModal(null)
    setSnippetModalIsOpen(false)
  }

  if (isSnippetsRefactorEnabled) {
    return <SavedTextSnippetsContainer />
  }

  return (
    <>
      {isMobile && (
        <PageToolbar
          breadcrumbs={[
            {
              to: UserPaths.SETTINGS,
              icon: CogIconGray,
              label: "Settings",
            },

            {
              label: "Saved Text Snippets",
            },
          ]}
        />
      )}
      <Grid container>
        {!isMobile && (
          <Grid item xs={12} md={3}>
            <SideNav />
          </Grid>
        )}
        <Grid item xs={12} md={9}>
          {!isMobile && (
            <PageToolbar
              title="Saved Text Snippets"
              greyBackground={true}
              fullBorder={true}
            />
          )}
          <Wrapper>
            <Grid container justify="space-between" alignItems="center">
              <Grid item xs={12} md={8}>
                <TitleWrapper>
                  <BodyText weight="semibold" size="lg">
                    Snippets
                  </BodyText>
                </TitleWrapper>
                <BodyText>
                  Create reusable notes to your patients that can be easily
                  added when creating an order.
                </BodyText>
              </Grid>
              <Grid item xs={12} md={4}>
                <StyledDesignSystemButton
                  color="secondary"
                  startIcon={AddNotesBlueIcon}
                  onClick={() => setSnippetModalIsOpen(true)}
                  isMobile={isMobile}
                >
                  Create Snippet
                </StyledDesignSystemButton>
              </Grid>
            </Grid>

            <BlockSeperator />

            {snippets?.length ? (
              <TableContainer component={Paper}>
                <StyledTable aria-label="snippets">
                  <StyledTableHead>
                    <TableRow>
                      <StyledTableCell>Snippets </StyledTableCell>
                    </TableRow>
                  </StyledTableHead>
                  <TableBody>
                    {snippets.map((snippet) => (
                      <TableRow key={snippet.id}>
                        <StyledTableCell>
                          <BodyText weight="semibold">{snippet.title}</BodyText>
                          <StyledDesignSystemButton
                            color="text"
                            onClick={() => handleOpenSnippetEditModal(snippet)}
                          >
                            Edit Snippet
                          </StyledDesignSystemButton>
                        </StyledTableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </StyledTable>
              </TableContainer>
            ) : (
              <Grid item xs={12} md={12}>
                <EmptySection>
                  <BodyText align="center">
                    No Snippets Have Been Created.
                  </BodyText>
                </EmptySection>
              </Grid>
            )}
          </Wrapper>
        </Grid>
      </Grid>
      <SnippetModal
        snippet={snippetOpenInEditModal}
        open={snippetModalIsOpen}
        onClose={handleCloseSnippetModal}
      />
    </>
  )
}

export default Snippets
