import { useEffect, useState } from "react"

import RupaHealthLogo from "app/assets/images/logos/rupa-health-logo-white.svg"
import Loading from "app/components/Loading"
import { buildLoginUrl, buildPatientPortalUrl } from "app/patient-portal/util"
import { navy } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"

import { ConfirmationSection } from "./Sections/ConfirmationSection"
import { ScheduleSection } from "./Sections/ScheduleSection"
import useBookingData from "./hooks/use-booking-data"
import useFromLocation from "./hooks/use-from-location"
import usePhlebotomyBookingToken from "./hooks/use-phlebotomy-booking-token"

const useStyles = makeAppStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    overflow: "hidden",
  },
  header: {
    width: "100%",
    height: 80,
    overflow: "hidden",
    backgroundColor: navy,
    marginBottom: 30,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  logo: {
    cursor: "pointer",
  },
}))

export const ScheduleBloodDraw = () => {
  const classes = useStyles()

  const [showConfirmationPage, setShowConfirmationPage] = useState<
    null | boolean
  >(null)

  const token = usePhlebotomyBookingToken()
  const fromLocation = useFromLocation()
  const { data, error, isLoading } = useBookingData(token)

  const next = buildPatientPortalUrl(
    data?.order.patient.id.toString(),
    data?.order.id
  )
  const loginUrl = buildLoginUrl(
    "patient",
    data?.order.patient.user.email,
    next
  )

  useEffect(() => {
    // Decide to show confirmation page only on first load of data
    if (data?.order?.id && showConfirmationPage === null)
      if (data?.order?.axle_health_visits?.length) {
        setShowConfirmationPage(true)
      } else {
        setShowConfirmationPage(false)
      }
  }, [data])

  let content = <></>
  if (isLoading) {
    content = <Loading ariaLabel="loading-scheduling" />
  } else if (error) {
    content = <div>Error: {error?.message}</div>
  } else if (showConfirmationPage === true) {
    content = (
      <ConfirmationSection
        data={data}
        loginUrl={loginUrl}
        fromLocation={fromLocation}
      />
    )
  } else if (showConfirmationPage === false) {
    content = (
      <ScheduleSection
        data={data}
        loginUrl={loginUrl}
        fromLocation={fromLocation}
      />
    )
  } else {
    content = <Loading ariaLabel="loading-scheduling" />
  }
  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <img
          src={RupaHealthLogo}
          className={classes.logo}
          alt="Rupa health logo"
          onClick={() => {
            if (loginUrl) {
              window.location.href = loginUrl
            } else {
              window.location.href = "/"
            }
          }}
        ></img>
      </div>
      {content}
    </div>
  )
}
