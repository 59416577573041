import { useState } from "react"

import { PractitionerProfilePublishingStatus } from "app/constants.typed"
import useEventCallback from "app/hooks/use-event-callback"
import useResourceSWR from "app/swr/hooks/use-resource-swr"
import resourceRequest from "app/swr/utils/resource-request"
import setIn from "app/utils/set-in"

import { PractitionerProfile } from "../types"

export default function usePractitionerProfile() {
  const {
    data: practitionerProfile,
    mutate: mutatePractitionerProfile,
    error: practitionerProfileError,
    isLoading: isPractitionerProfileLoading,
    isValidating: isValidatingPractitionerProfile,
  } = useResourceSWR<PractitionerProfile>(
    "/practitioner_profiles/",
    {
      include: ["application", "specialties", "conditions_treated", "services"],
      method: "POST",
    },
    {
      revalidateOnMount: true,
      revalidateOnFocus: true,
      revalidateOnReconnect: true,
    }
  )
  const [isPatching, setIsPatching] = useState(false)

  const publishProfile = useEventCallback(async () => {
    if (!practitionerProfile) {
      return
    }
    try {
      setIsPatching(true)
      await mutatePractitionerProfile(
        resourceRequest<PractitionerProfile>({
          url: `/practitioner_profiles/${practitionerProfile.id}/publish/`,
          method: "POST",
        }),
        {
          optimisticData: (currentData) => {
            if (currentData) {
              return setIn(
                currentData,
                "attributes.publishing_status",
                PractitionerProfilePublishingStatus.PUBLISHED
              ) as PractitionerProfile
            }
            return {} as PractitionerProfile
          },
          rollbackOnError: true,
          throwOnError: false,
        }
      )
    } catch (error) {}
    setIsPatching(false)
  })

  const pauseProfile = async () => {
    if (!practitionerProfile) {
      return
    }
    try {
      setIsPatching(true)
      await mutatePractitionerProfile(
        resourceRequest<PractitionerProfile>({
          url: `/practitioner_profiles/${practitionerProfile.id}/unpublish/`,
          method: "POST",
        }),
        {
          optimisticData: (currentData) => {
            if (currentData) {
              return setIn(
                currentData,
                "attributes.publishing_status",
                PractitionerProfilePublishingStatus.PAUSED
              ) as PractitionerProfile
            }
            return {} as PractitionerProfile
          },
          rollbackOnError: true,
          throwOnError: false,
        }
      )
    } catch (error) {}
    setIsPatching(false)
  }

  return {
    practitionerProfile,
    practitionerProfileError,
    isPractitionerProfileLoading,
    isValidatingPractitionerProfile,
    isPatching,
    mutatePractitionerProfile,
    publishProfile,
    pauseProfile,
  }
}

export type UsePractitionerProfile = ReturnType<typeof usePractitionerProfile>
