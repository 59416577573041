import { Button } from "@rupahealth/design"

import { ReactComponent as AddNotesBlueIcon } from "app/assets/icons/add-notes-blue.svg"

interface Props {
  title: string
  onClick: () => void
}

const NewSnippetButton = ({ title, onClick }: Props) => {
  return (
    <Button
      variant="outline-primary"
      className="flex font-title items-center w-full md:w-auto"
      onClick={onClick}
    >
      <AddNotesBlueIcon className="mr-2" />
      {title}
    </Button>
  )
}

export default NewSnippetButton
