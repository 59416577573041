import { useController, useFormContext } from "react-hook-form"

import { SwitchProps } from "@material-ui/core"

import { StyledSwitch } from "app/main/settings/components/StyledSwitch"

export type ControlledSwitchFieldProps = Omit<
  SwitchProps,
  "onChange" | "value" | "defaultValue"
> & {
  defaultValue?: boolean
  name: string
  shouldUnregister?: boolean
}

const ControlledSwitchField = ({
  defaultValue,
  name,
  shouldUnregister,
  ...switchProps
}: ControlledSwitchFieldProps) => {
  const { control } = useFormContext()

  const {
    field: { ref, value, ...controlProps },
  } = useController({
    control,
    defaultValue: defaultValue || false,
    name: `${name}` as const,
    shouldUnregister,
  })
  return (
    <StyledSwitch
      inputRef={ref}
      {...controlProps}
      {...switchProps}
      checked={value}
    />
  )
}

export default ControlledSwitchField
