import { useState } from "react"

import { useSWRConfig } from "swr"

import { Theme } from "@material-ui/core"

import { ReactComponent as AirplaneBlueIcon } from "app/assets/images/airplane-blue.svg"
import Button from "app/components/design-system/Button"
import useEventCallback from "app/hooks/use-event-callback"
import useHandleApiError from "app/hooks/use-handle-api-error"
import useHandleApiSuccess from "app/hooks/use-handle-api-success"
import { PatientOrderEvent } from "app/main/patient-orders/constants"
import { trackPractitionerDashboardAction } from "app/services/segment.typed"
import { writeToCache } from "app/swr/helpers/swr"
import { ResourceResponse } from "app/swr/types"
import resourceRequest from "app/swr/utils/resource-request"
import makeAppStyles from "app/utils/makeAppStyles"
import { DashboardKit } from "types/dashboard/dashboard-kit"

const useStyles = makeAppStyles((theme: Theme) => ({
  button: {
    maxWidth: 250,
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      maxWidth: "100%",
    },
  },
}))

interface Props {
  kitId: string
  orderId: string
  practitionerId: string
  patientName: string
  onSendResultsToPatient: () => void
}

const BloodLabDashboardsSendToPatientButton = ({
  kitId,
  orderId,
  practitionerId,
  patientName,
  onSendResultsToPatient,
}: Props) => {
  const classes = useStyles()

  const [loading, setLoading] = useState(false)

  const sendResultsToPatient = useSendResultsToPatient({
    kitId,
    orderId,
    practitionerId,
    patientName,
  })

  return (
    <Button
      className={classes.button}
      color="primary"
      shadow="sm"
      aria-label="Email Results to Patient"
      onClick={async () => {
        setLoading(true)
        await sendResultsToPatient()
        onSendResultsToPatient()
        setLoading(false)
      }}
      startIcon={<AirplaneBlueIcon fill="#ffffff" />}
      loading={loading}
    >
      Email Results to {patientName}
    </Button>
  )
}

export default BloodLabDashboardsSendToPatientButton

export function useSendResultsToPatient({
  kitId,
  orderId,
  practitionerId,
  patientName,
}: {
  kitId: string
  orderId: string
  practitionerId: string
  patientName: string
}) {
  const globalConfig = useSWRConfig()
  const handleApiError = useHandleApiError()
  const handleApiSuccess = useHandleApiSuccess()

  return useEventCallback(async () => {
    try {
      const { data, included = [] } = await resourceRequest<
        ResourceResponse<DashboardKit>
      >({
        url: `/dashboard/orders/${orderId}/kits/${kitId}/send_patient_results/`,
        method: "POST",
        include: ["order"],
      })
      handleApiSuccess(`Results were securely emailed to ${patientName}`, 10000)

      // Update the cache with the new data
      await writeToCache(globalConfig, data, ...included)

      trackPractitionerDashboardAction(
        PatientOrderEvent.SEND_RESULTS,
        practitionerId as string
      )
    } catch (error) {
      handleApiError(error)
    }
  })
}
