import { memo } from "react"

import {
  DateRange,
  DateRangePicker,
  DefinedRange,
} from "materialui-daterange-picker"

import { Theme, makeStyles } from "@material-ui/core"

import { colors, navy, primaryColor } from "app/theme"

// **A note on the styles below**
//
// The materialui-daterange-picker library makes custom styling difficult.
// Everytime a component within the library is rendered, the classnames are
// dynamically generated with unique IDs. This makes it difficult to target
// specific elements within the library. The solution below is to use the
// `& element[class*="prefix-of-class-"]` selector to target the elements.
const styles = (theme: Theme) => ({
  container: {
    display: "flex",
    '& div[class*="materialui-daterange-picker-makeStyles-dateRangeBackdrop-"]':
      {
        display: "none !important",
      },
    '& div[class*="materialui-daterange-picker-makeStyles-dateRangePickerContainer-"]':
      {
        width: "100%",
      },
    '& div[class*="materialui-daterange-picker-makeStyles-dateRangePicker-"]': {
      width: "100%",
    },
  },
  dateRangeContainer: {
    width: "max-content",
    '& div[class*="materialui-daterange-picker-makeStyles-dateRangePicker-"]': {
      padding: "12px 30px",
      width: "100%",
    },
    '& div[class*="materialui-daterange-picker-MuiPaper-elevation5-"]': {
      boxShadow: "none",
    },
    '& div[class*="materialui-daterange-picker-makeStyles-header-"]': {
      // Design does not contain this header, no other way to hide it
      display: "none",
    },
    '& button[class*="materialui-daterange-picker-makeStyles-filled-"]': {
      background: primaryColor,
      paddingTop: 5,
      paddingBottom: 5,
    },
    '& button[class*="materialui-daterange-picker-makeStyles-filled-"]:hover': {
      background: primaryColor,
      color: "white",
    },
    '& div[class*="materialui-daterange-picker-makeStyles-highlighted-"]': {
      background: colors.blue[50],
      marginBottom: 3,
    },
    '& div[class*="materialui-daterange-picker-makeStyles-highlighted-"]:last-of-type':
      {
        borderRadius: "0 20px 20px 0",
      },
    '& div[class*="materialui-daterange-picker-makeStyles-highlighted-"]:first-of-type':
      {
        borderRadius: "20px 0 0 20px",
      },
    '& p[class*="materialui-daterange-picker-makeStyles-buttonText-"]': {
      fontSize: 14,
      color: navy,
    },
    '& button[class*="materialui-daterange-picker-makeStyles-filled-"] p[class*="materialui-daterange-picker-makeStyles-buttonText-"]':
      {
        color: "white",
      },
    '& span[class*="materialui-daterange-picker-MuiTypography-colorTextSecondary-"]':
      {
        fontSize: 14,
        color: navy,
      },
    '& button[class*="materialui-daterange-picker-makeStyles-outlined-"]': {
      border: `1px solid ${primaryColor}`,
    },
    '& hr[class*="materialui-daterange-picker-MuiDivider-root-"]': {
      display: "none",
    },
    '& div[class*="materialui-daterange-picker-MuiSelect-select-"]': {
      fontSize: 15,
      paddingRight: 28,
      minHeight: 0,
      color: navy,
    },
    '& li[class*="materialui-daterange-picker-MuiMenuItem-root-"]': {
      fontSize: 15,
      color: navy,
    },
    '& svg[class*="materialui-daterange-picker-MuiSvgIcon-root-"]': {
      fontSize: 26,
      color: primaryColor,
    },
    '& svg[class*="materialui-daterange-picker-MuiSelect-icon-"]': {
      top: "calc(50% - 15px)",
    },
    '& div[class*="materialui-daterange-picker-makeStyles-buttonContainer-"]': {
      marginBottom: 3,
    },
    '& div[class*="materialui-daterange-picker-makeStyles-divider-"]': {
      borderLeft: "none",
    },
    '& div[class*="materialui-daterange-picker-makeStyles-root-"]:last-of-type':
      {
        borderLeft: `1px solid ${colors.gray[200]}`,
        [theme.breakpoints.down("sm")]: {
          borderLeft: "none",
        },
      },
    '& span[class*="materialui-daterange-picker-MuiTypography-displayBlock-"]':
      {
        fontSize: 15,
        color: navy,
        padding: "10px 16px",
        width: 160,
      },
    '& p[class*="materialui-daterange-picker-MuiTypography-colorTextSecondary-"]':
      {
        color: colors.blueGray[300],
      },
    '& div[class*="materialui-daterange-picker-MuiListItem-root-"]': {
      padding: 0,
    },
    '& div[class*="materialui-daterange-picker-MuiGrid-wrap-xs-nowrap-"] > div[class*="materialui-daterange-picker-MuiGrid-root-"]:first-of-type':
      {
        width: "100%",
      },
    '& div[class*="materialui-daterange-picker-MuiGrid-wrap-xs-nowrap-"] > div[class*="materialui-daterange-picker-MuiGrid-root-"]:last-of-type':
      {
        order: -1,
        borderRight: `1px solid ${colors.gray[200]}`,
        padding: "4px 16px",
        [theme.breakpoints.down("sm")]: {
          display: "none",
        },
      },
    '& div[class*="materialui-daterange-picker-MuiPaper-root-"]:first-of-type':
      {
        borderRadius: 8,
      },
    '& div[class*="materialui-daterange-picker-MuiGrid-container-"]': {
      [theme.breakpoints.down("sm")]: {
        flexWrap: "wrap",
      },
      '& div[class*="materialui-daterange-picker-makeStyles-daysContainer-"]': {
        [theme.breakpoints.down("sm")]: {
          paddingLeft: 10,
          paddingRight: 10,
          marginBottom: 0,
        },
      },
      '& div[class*="materialui-daterange-picker-makeStyles-weekDaysContainer-"]':
        {
          [theme.breakpoints.down("sm")]: {
            paddingLeft: 20,
          },
        },
    },
  },
  pickerContainer: {
    display: "flex",
    width: "100%",
  },
})
const useStyles = makeStyles(styles)

interface Props {
  minDate: Date
  maxDate: Date
  definedRanges: DefinedRange[]
  handleDateRangeChange: (dateRange: DateRange) => void
}

const RupaDateRangePicker = ({
  minDate,
  maxDate,
  definedRanges,
  handleDateRangeChange,
}: Props) => {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <div className={classes.pickerContainer}>
        <DateRangePicker
          open={true}
          // Don't need this since it is always open and we don't want to close it
          toggle={() => null}
          onChange={handleDateRangeChange}
          wrapperClassName={classes.dateRangeContainer}
          definedRanges={definedRanges}
          minDate={minDate}
          maxDate={maxDate}
        />
      </div>
    </div>
  )
}

export default memo(RupaDateRangePicker)
