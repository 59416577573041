import { useState } from "react"

import useEventCallback from "app/hooks/use-event-callback"
import { ResourceResponse } from "app/swr/types"
import resourceRequest from "app/swr/utils/resource-request"

import { PractitionerProfileApplication } from "../types"

export default function useSubmitProfileApplication() {
  const [isPatching, setIsPatching] = useState(false)
  const submitProfileApplication = useEventCallback(async () => {
    setIsPatching(true)
    try {
      await resourceRequest<ResourceResponse<PractitionerProfileApplication>>({
        url: `/practitioner_profile_applications/`,
        method: "POST",
        data: {
          data: {
            type: "PractitionerProfileApplication",
          },
        },
      })
    } catch (error) {}
    setIsPatching(false)
  })

  return {
    isPatching,
    submitProfileApplication,
  }
}
