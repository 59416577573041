import { useMemo } from "react"

import { IconButton } from "@material-ui/core"
import {
  FirstPage,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  LastPage,
} from "@material-ui/icons"

import BodyText from "app/components/design-system/BodyText"

const ROWS_PER_PAGE = 25

interface Props {
  page: number
  totalNumResults: number
  onChangePage: (page: number) => void
}

const TablePagination = ({ page, totalNumResults, onChangePage }: Props) => {
  const pageRange = useMemo(() => {
    const start = (page - 1) * ROWS_PER_PAGE + 1
    let end = page * ROWS_PER_PAGE

    if (end > totalNumResults) {
      end = totalNumResults
    }

    if (start === end) {
      return start
    }

    return `${start}-${end}`
  }, [page])

  return (
    <div className="flex gap-2 items-center">
      <BodyText>
        {pageRange} of {totalNumResults}
      </BodyText>
      <div>
        <IconButton
          aria-label="First page"
          color="inherit"
          disabled={page === 1}
          onClick={() => onChangePage(1)}
          title="First page"
        >
          <FirstPage />
        </IconButton>

        <IconButton
          aria-label="Previous page"
          color="inherit"
          disabled={page === 1}
          onClick={() => onChangePage(page - 1)}
          title="Previous page"
        >
          <KeyboardArrowLeft />
        </IconButton>

        <IconButton
          aria-label="Next page"
          color="inherit"
          disabled={page >= Math.ceil(totalNumResults / ROWS_PER_PAGE)}
          onClick={() => onChangePage(page + 1)}
          title="Next page"
        >
          <KeyboardArrowRight />
        </IconButton>

        <IconButton
          aria-label="Last page"
          color="inherit"
          disabled={page >= Math.ceil(totalNumResults / ROWS_PER_PAGE)}
          onClick={() =>
            onChangePage(
              Math.max(1, Math.ceil(totalNumResults / ROWS_PER_PAGE))
            )
          }
          title="Last page"
        >
          <LastPage />
        </IconButton>
      </div>
    </div>
  )
}

export default TablePagination
