import { useEffect } from "react"

import { FuseSplashScreen } from "@fuse"
import { useHistory } from "react-router-dom"

import { handlePromotionalCouponLink } from "app/utils/cookie-util"

export default function CouponPromo(props) {
  const history = useHistory()

  useEffect(
    () => {
      const urlParams = new URLSearchParams(window?.location?.search)

      // Used only for promotional coupon links from Rupa educational classes
      // If the user uses this promo link and has never signed in to Rupa, they
      // will be automatically redirected to signup (this is ONLY with promo links)
      handlePromotionalCouponLink(urlParams, history)
    },
    // only happens on mount, i.e. on load
    []
  )

  return <FuseSplashScreen />
}
