import { useForm } from "react-hook-form"
import { z } from "zod"

import { zodResolver } from "@hookform/resolvers/zod"

import { PatientSignupFormData } from "app/types"

export enum PatientSignupFormFields {
  FIRST_NAME = "first_name",
  LAST_NAME = "last_name",
  EMAIL = "email",
  PASSWORD = "password",
  CONFIRM_PASSWORD = "confirm_password",
  VERIFICATION_CODE = "verification_code",
  PATIENT_PORTAL_TOKEN = "patient_portal_token",
  GIVEN_PATIENT_MARKETING_CONSENT = "given_patient_marketing_consent",
  TERMS_OF_SERVICE = "terms_of_service",
}

export const PatientSignupAttributesSchema = z.object({
  email: z
    .string()
    .nonempty("Must provide your email address")
    .email("Must provide a valid email address"),
  password: z
    .string()
    .nonempty("Must provide a password")
    .min(8, "Password does not meet requirements"),
  confirm_password: z.string().nonempty("Must confirm a password"),
  verification_code: z.string().optional(),
  patient_portal_token: z.string().optional(),
  given_patient_marketing_consent: z.boolean(),
  terms_of_service: z.boolean(),
})

const NameSchema = z.object({
  first_name: z.string().nonempty("Must provide your first name"),
  last_name: z.string().nonempty("Must provide your last name"),
})

export default function usePatientSignupForm(
  defaultEmail?: string,
  patientPortalToken?: string
) {
  let schema = PatientSignupAttributesSchema
  // if no patient portal token, then we need to collect first and last name
  if (!patientPortalToken) {
    schema = schema.merge(NameSchema)
  }
  const methods = useForm<PatientSignupFormData>({
    resolver: zodResolver(schema),
    mode: "onBlur",
    defaultValues: {
      first_name: "",
      last_name: "",
      email: defaultEmail ?? "",
      verification_code: "",
      patient_portal_token: patientPortalToken ?? "",
      given_patient_marketing_consent: true,
      terms_of_service: false,
      password: "",
      confirm_password: "",
    },
  })

  return {
    methods,
  }
}
