import { Divider } from "@material-ui/core"

import OrderingAccessPill from "app/components/OrderingAccess/OrderingAccessPill"
import SpecialtyPill from "app/components/OrderingAccess/SpecialtyPill"
import BodyText from "app/components/design-system/BodyText"
import { shadows } from "app/theme"
import { LabCompany, PractitionerListItem } from "app/types"
import makeAppStyles from "app/utils/makeAppStyles"

const useStyles = makeAppStyles({
  container: {
    background: "white",
    borderRadius: 8,
    boxShadow: shadows.md,
    display: "flex",
    flexFlow: "column",
    width: "90%",
    minHeight: 180,
    padding: 14,

    "&:not(:last-child)": {
      marginBottom: 16,
    },
  },
  titleRow: {
    alignItems: "center",
    display: "flex",
    flexFlow: "row",
    padding: 5,
  },
  specialtiesRow: {
    alignItems: "flex-start",
    display: "flex",
    flexFlow: "column",
    padding: 5,
  },
  accessRow: {
    padding: 5,
  },
  labCompanyName: {
    marginRight: 107,
  },
  divider: {
    margin: "7px 0",
  },
  logo: {
    marginLeft: "auto",
    maxWidth: 150,
  },
})

interface Props {
  hasOrderingAccess: boolean
  labCompany: LabCompany
  selectedPractitionerListItem?: PractitionerListItem
}

function LabOrderingAccessListItem(props: Props) {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <div className={classes.titleRow}>
        <BodyText className={classes.labCompanyName} weight="semibold">
          {props.labCompany.name}
        </BodyText>
        {props.labCompany.logo && (
          <div className={classes.logo}>
            <img src={props.labCompany.logo} alt={props.labCompany.name} />
          </div>
        )}
      </div>
      <Divider className={classes.divider} />
      <div className={classes.specialtiesRow}>
        <BodyText weight="regular" style={{ paddingBottom: 7 }}>
          Specialties
        </BodyText>
        {props.labCompany.specialties.map((specialty) => {
          return <SpecialtyPill key={props.labCompany.id} label={specialty} />
        })}
      </div>
      <div className={classes.accessRow}>
        <BodyText weight="regular" style={{ paddingBottom: 7 }}>
          Ordering Access
        </BodyText>
        <OrderingAccessPill
          hasOrderingAccess={props.hasOrderingAccess}
          labCompany={props.labCompany}
          selectedPractitionerListItem={props.selectedPractitionerListItem}
        />
      </div>
    </div>
  )
}

export default LabOrderingAccessListItem
