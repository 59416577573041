import useBindActionCreators from "app/hooks/use-bind-action-creators"

import {
  addComparedTest,
  closeComparisonModal,
  closeSearchModal,
  fetchTestsFailure,
  fetchTestsRequest,
  fetchTestsSuccess,
  openComparisonModal,
  openSearchModal,
  removeComparedTest,
  setComparedTests,
} from "../store/comparison.actions"
import useComparisonDispatch from "./use-comparison-dispatch"

const actions = {
  addComparedTest,
  closeComparisonModal,
  closeSearchModal,
  fetchTestsFailure,
  fetchTestsRequest,
  fetchTestsSuccess,
  openComparisonModal,
  openSearchModal,
  removeComparedTest,
  setComparedTests,
}

/**
 * Hook used to get dispatch-bound actions for managing the comparison state.
 *
 * @example
 * ```
 * const { addComparedTest } = useComparisonActions()
 *
 * function onClickAdd() {
 *    addComparedTest(labTest.id, labTest)
 * }
 * ```
 *
 * @returns the comparison actions wrapped by dispatch
 */
export default function useComparisonActions() {
  const dispatch = useComparisonDispatch()
  return useBindActionCreators(dispatch, actions)
}
