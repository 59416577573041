import { useCallback, useMemo } from "react"

import { isEmpty, uniqBy } from "lodash"

import useStatuses from "app/hooks/statuses/use-statuses"
import { useLocalStorage } from "app/hooks/use-local-storage"
import map from "lodash/map"
import { loadState, saveState } from "services/savedState"
import { JobStatus } from "types/job-status"

// the prefix used to store upload jobs in local storage
const KEY_PREFIX = "practitionerUploadJobs"

const uploadJobsKey = (patientId: string) => `${KEY_PREFIX}.${patientId}`

// shape of the thing that's stored in local storage
export interface StoredUploadJob {
  statusId: string
  name?: string
  clinicIdsToShareWith?: string[]
}

export type StoredUploadJobStatus = JobStatus & StoredUploadJob

interface Props {
  patientId: string
}

export const useStoredUploadJobs = ({ patientId }: Props) => {
  const [savedState, setSavedState] = useLocalStorage<StoredUploadJob[]>(
    uploadJobsKey(patientId),
    []
  )

  // Fetch the statuses for the stored upload jobs
  const { jobStatuses } = useStatuses(map(savedState, "statusId"), true)

  // Utility function to remove stored upload jobs
  const removeStoredUploadJobs = useCallback(
    (statusIdsToRemove: string[]) => {
      setSavedState(
        savedState.filter(
          ({ statusId }) => !statusIdsToRemove.includes(statusId)
        )
      )
    },
    [savedState, savedState, patientId]
  )

  // Combine the stored upload jobs with their statuses
  const storedJobStatuses = useMemo(() => {
    return savedState.reduce((acc, job) => {
      const status = jobStatuses.find((status) => status.id === job.statusId)

      if (status && !isEmpty(status?.properties)) {
        acc.push({ ...job, ...status })
      }
      return acc
    }, [] as StoredUploadJobStatus[])
  }, [jobStatuses, savedState])

  return { storedJobStatuses, removeStoredUploadJobs }
}

// Helper to add stored upload jobs for a patient
export const addStoredUploadJobsForPatient = (
  patientId: string,
  jobsToAdd: StoredUploadJob[],
  currentState?: StoredUploadJob[]
) => {
  const key = uploadJobsKey(patientId)
  if (!currentState) {
    currentState = loadState<StoredUploadJob[]>(key)
  }

  const newState = uniqBy([...(currentState || []), ...jobsToAdd], "statusId")
  saveState(key, newState)
}
