import { memo, useCallback, useMemo, useState } from "react"
import { useDispatch } from "react-redux"

import clsx from "clsx"

import { IconButton, Typography } from "@material-ui/core"
import CloseIcon from "@material-ui/icons/Close"
import FavoriteIcon from "@material-ui/icons/Favorite"
import { Skeleton } from "@material-ui/lab"

import { ReactComponent as CheckIcon } from "app/assets/icons/blue-checkmark.svg"
import { isUserGuest } from "app/auth/util"
import Tooltip from "app/components/Tooltip"
import Button from "app/components/design-system/Button"
import { getUnavailableReason } from "app/dataServices/orderingRights"
import useFeatureFlag, { FeatureFlag } from "app/hooks/use-feature-flag"
import useAppSelector from "app/hooks/useAppSelector"
import { ComparisonColumnProps } from "app/main/comparison/components/ComparisonLabTests/components/ComparisonRow"
import useComparisonActions from "app/main/comparison/hooks/use-comparison-actions"
import useComparisonCallToActionContext from "app/main/comparison/hooks/use-comparison-call-to-action-context"
import { ComparisonCallToActionTypes } from "app/main/comparison/types"
import * as Actions from "app/store/actions"
import { toggleFavoriteTest } from "app/store/actions"
import { selectPractitioner } from "app/store/selectors/practitioner.selectors"
import { colors, favoritedColor, primaryColor, shadows } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"
import { getPractitionerToEvalOrderingRights } from "app/utils/practitioner-utils"

const useComparedLabTestStyles = makeAppStyles((theme) => ({
  container: {
    // tack on additional width to compensate for negative margin
    width: `calc(100% + ${theme.spacing(1.75)}px)`,
    // apply negative margin to compensate for extra padding
    margin: theme.spacing(0, -1.75),
    padding: theme.spacing(1.5, 1.75),
    height: 150,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    gap: theme.spacing(1.0),
    border: `1px solid ${colors.blueGray[200]}`,
    borderRadius: 9,
    background: "linear-gradient(180deg, #FFFFFF 0%, #F4F5F7 100%)",
    boxShadow: shadows.default,
    "&[data-is-guest=true]": {
      height: 100,
    },
  },
  header: {
    flex: "1 1 auto",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    gap: theme.spacing(0.5),
  },
  title: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(0.5),
  },
  name: {
    display: "-webkit-box",
    "-webkit-line-clamp": 2,
    "-webkit-box-orient": "vertical",
    overflow: "hidden",
  },
  nameSkeleton: {
    backgroundColor: colors.blueGray[400],
    borderRadius: 3,
  },
  nameText: {
    fontSize: 17,
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: 1.35,
  },
  labCompany: {
    display: "-webkit-box",
    "-webkit-line-clamp": 1,
    "-webkit-box-orient": "vertical",
    overflow: "hidden",
  },
  labCompanySkeleton: {
    backgroundColor: colors.blueGray[300],
    borderRadius: 3,
  },
  labCompanyText: {
    fontSize: 14,
    fontWeight: theme.typography.fontWeightRegular,
    lineHeight: 1.35,
  },
  remove: {},
  removeButton: {
    height: 24,
    width: 24,
    backgroundColor: colors.blueGray[100],
    "&:hover": {
      backgroundColor: colors.blueGray[200],
    },
    "&:active": {
      backgroundColor: colors.blueGray[300],
    },
  },
  removeIcon: {
    height: 20,
    width: 20,
  },
  actions: {
    flex: "0 1 auto",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    gap: theme.spacing(1.25),
  },
  favoriteButton: {
    flex: "0 1 40px",
    width: 40,
    height: 40,
    backgroundColor: "white",
    border: `1px solid ${colors.blueGray[300]}`,
    borderRadius: 6,
    boxShadow: shadows.sm,
    "&:disabled": {
      backgroundColor: colors.blueGray[100],
    },
  },
  favoriteIcon: {
    color: colors.blueGray[200],
    "$favoriteButton[data-is-favorited=true] &": {
      color: favoritedColor,
    },
  },
  callToActionButton: {
    flex: "1 1 auto",
    height: 40,
    display: "flex",
    alignItems: "center",
    whiteSpace: "nowrap",
    "& svg": {
      fill: "currentColor",
      marginTop: -3,
    },
  },
  addToCartButton: {
    "&[data-is-in-cart=true]": {
      background: colors.lightBlue[50],
      border: `1px solid ${primaryColor}`,
    },
  },
}))

interface ComparedLabTestChildProps {
  classes: ReturnType<typeof useComparedLabTestStyles>
  labTest: ComparisonColumnProps["labTest"]
}

function useOrderingRights(labTest?: ComparedLabTestChildProps["labTest"]) {
  const dispatch = useDispatch()
  const [showOverrideOption] = useFeatureFlag(
    FeatureFlag.OrderAccessOverrideHover
  )
  const practitioner = useAppSelector(selectPractitioner)
  const order = useAppSelector(({ orders }) => orders.orders.order)
  const signingPractitioner =
    order?.signing_practitioner ||
    getPractitionerToEvalOrderingRights(practitioner)

  const overrideAccessAction = (includeClinicState: boolean) => {
    if (labTest && showOverrideOption) {
      dispatch(
        Actions.setLabTestForOverride(
          labTest.lab_company,
          includeClinicState ? practitioner?.clinic?.state : undefined
        )
      )
    }
  }

  const unavailableReason = useMemo(() => {
    if (!labTest) {
      return ""
    }

    return getUnavailableReason({
      labTest,
      signingPractitioner,
      orderingPractitioner: practitioner,
      overrideAccessAction,
      showOverrideOption,
    })
  }, [labTest, practitioner, signingPractitioner])

  return unavailableReason
}

const ComparedLabTestName = ({
  classes,
  labTest,
}: ComparedLabTestChildProps) => (
  <div className={classes.name}>
    {!labTest ? (
      <Skeleton
        className={classes.nameSkeleton}
        variant="rect"
        animation="wave"
        height={17}
        width={100}
      />
    ) : (
      <Typography
        className={classes.nameText}
        color="textPrimary"
        title={labTest.name}
      >
        {labTest.name}
      </Typography>
    )}
  </div>
)

const ComparedLabTestCompany = ({
  classes,
  labTest,
}: ComparedLabTestChildProps) => (
  <div className={classes.labCompany}>
    {!labTest ? (
      <Skeleton
        className={classes.labCompanySkeleton}
        variant="rect"
        animation="wave"
        height={14}
        width={140}
      />
    ) : (
      <Typography
        className={classes.labCompanyText}
        color="textPrimary"
        title={labTest.lab_company.name}
      >
        {labTest.lab_company.name}
      </Typography>
    )}
  </div>
)

const ComparedLabTestFavoriteButton = ({
  classes,
  labTest,
}: ComparedLabTestChildProps) => {
  const [isPending, setIsPending] = useState(false)
  const dispatch = useDispatch()
  const practitioner = useAppSelector((state) => state.practitioner)
  const isFavorited =
    (labTest && practitioner?.favorite_lab_test_id_set?.has(labTest.id)) ||
    false

  const handleClick = useCallback(async () => {
    if (isPending) {
      return
    }

    setIsPending(true)
    await dispatch(toggleFavoriteTest(labTest))
    setIsPending(false)
  }, [labTest])

  return (
    <IconButton
      aria-label="Favorite Test"
      className={classes.favoriteButton}
      data-is-favorited={isPending ? !isFavorited : isFavorited}
      disabled={isPending}
      onClick={handleClick}
      type="button"
    >
      <FavoriteIcon className={classes.favoriteIcon} fontSize="small" />
    </IconButton>
  )
}

const ComparedLabTestAddToCartButton = ({
  classes,
  disabled,
  labTest,
  onClick,
}: ComparedLabTestChildProps & { onClick: () => void; disabled: boolean }) => {
  const order = useAppSelector(({ orders }) => orders.orders.order)
  const isInCart = labTest && labTest.id in order.lab_tests_by_id
  const addText = labTest?.rupa_price
    ? `Add to Cart — $${labTest.rupa_price}`
    : "Add to Cart"
  return (
    <Button
      className={clsx(classes.callToActionButton, classes.addToCartButton)}
      color={isInCart ? "secondary" : "primary"}
      data-is-in-cart={isInCart}
      disabled={!labTest || disabled}
      onClick={onClick}
      startIcon={isInCart ? <CheckIcon viewBox="0 0 17 17" /> : undefined}
      type="button"
    >
      {isInCart ? "In Cart" : addText}
    </Button>
  )
}

const ComparedLabTestStartAnOrderButton = ({
  classes,
  disabled,
  labTest,
  onClick,
}: ComparedLabTestChildProps & { onClick: () => void; disabled: boolean }) => {
  const startOrderText = labTest?.rupa_price
    ? `Start Order — $${labTest.rupa_price}`
    : "Start Order"
  return (
    <Button
      className={classes.callToActionButton}
      color="primary"
      disabled={!labTest || disabled}
      onClick={onClick}
      type="button"
    >
      {startOrderText}
    </Button>
  )
}

const ComparedLabTestCallToActionButton = ({
  classes,
  labTest,
}: ComparedLabTestChildProps) => {
  const { callToAction, onCallToAction } = useComparisonCallToActionContext()
  const unavailableReason = useOrderingRights(labTest)
  const [isTooltipVisible, setIsTooltipVisible] = useState(false)

  function handleClick() {
    labTest && onCallToAction(labTest)
  }

  let children
  switch (callToAction) {
    case ComparisonCallToActionTypes.ADD_TO_CART: {
      children = (
        <ComparedLabTestAddToCartButton
          classes={classes}
          disabled={Boolean(unavailableReason)}
          labTest={labTest}
          onClick={handleClick}
        />
      )
      break
    }
    case ComparisonCallToActionTypes.START_ORDER:
    default: {
      children = (
        <ComparedLabTestStartAnOrderButton
          classes={classes}
          disabled={Boolean(unavailableReason)}
          labTest={labTest}
          onClick={handleClick}
        />
      )
    }
  }

  if (!unavailableReason) {
    return children
  }

  return (
    <Tooltip
      title={isTooltipVisible ? unavailableReason : ""}
      placement="top"
      arrow
      interactive
    >
      <div onMouseOver={() => setIsTooltipVisible(true)}>{children}</div>
    </Tooltip>
  )
}

const ComparedLabTestComponent = ({
  actions,
  labTest,
  labTestId,
}: ComparisonColumnProps & {
  actions: ReturnType<typeof useComparisonActions>
}) => {
  const classes = useComparedLabTestStyles()
  const user = useAppSelector(({ auth }) => auth.user)
  const isGuest = isUserGuest(user)

  return (
    <div className={classes.container} data-is-guest={isGuest}>
      <div className={classes.header}>
        <div className={classes.title}>
          <ComparedLabTestName classes={classes} labTest={labTest} />
          <ComparedLabTestCompany classes={classes} labTest={labTest} />
        </div>
        <div className={classes.remove}>
          <IconButton
            aria-label="close"
            className={classes.removeButton}
            disabled={!labTestId}
            onClick={() => actions.removeComparedTest(labTestId)}
            size="small"
          >
            <CloseIcon className={classes.removeIcon} />
          </IconButton>
        </div>
      </div>
      {!isGuest && (
        <div className={classes.actions}>
          <ComparedLabTestFavoriteButton classes={classes} labTest={labTest} />
          <ComparedLabTestCallToActionButton
            classes={classes}
            labTest={labTest}
          />
        </div>
      )}
    </div>
  )
}

/**
 * Memoized internal component used to ensure context updates do not cause unnecessary re-renders.
 */
const MemoizedComparedLabTest = memo(ComparedLabTestComponent)

export default function ComparedLabTest(props: ComparisonColumnProps) {
  const actions = useComparisonActions()
  return <MemoizedComparedLabTest {...props} actions={actions} />
}
