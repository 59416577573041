import { useMemo, useState } from "react"
import { useDispatch } from "react-redux"

import { makeStyles, styled } from "@material-ui/core"

import DisabledEllipse from "app/assets/icons/gray-ellipse.svg"
import EnabledEllipse from "app/assets/icons/green-ellipse.svg"
import InfoIcon from "app/assets/icons/info-blue-circle.svg"
import Tooltip from "app/components/Tooltip"
import BodyText from "app/components/design-system/BodyText"
import { getDisabledOrderingAccessReasonForLabCompany } from "app/dataServices/labTestDataService"
import useFeatureFlag, { FeatureFlag } from "app/hooks/use-feature-flag"
import * as Actions from "app/store/actions"
import { colors } from "app/theme"
import { LabCompany, PractitionerListItem } from "app/types"

const useStyles = makeStyles(() => ({
  orderingAccessPillIcon: {
    marginLeft: 7,
    marginTop: 6,
  },
  pillEllipse: {
    marginRight: 7,
  },
}))

const OrderingAccessPillContainer = styled("div")({
  alignItems: "center",
  backgroundColor: (props: { isPillEnabled?: boolean }) =>
    props.isPillEnabled ? colors.emerald[50] : colors.blueGray[100],
  borderRadius: 20,
  color: (props: { isPillEnabled?: boolean }) =>
    props.isPillEnabled ? colors.emerald[700] : colors.blueGray[500],
  display: "inline-flex",
  fontSize: 13,
  fontWeight: 600,
  justifyContent: "center",
  margin: 3,
  padding: "6px 13px",
  maxHeight: 23,
  whiteSpace: "nowrap",
})

interface Props {
  hasOrderingAccess: boolean
  labCompany: LabCompany
  selectedPractitionerListItem?: PractitionerListItem
}

const ORDERING_ACCESS_STATUS_TEXT = {
  ENABLED: "Enabled",
  ENABLED_PHYS_SERVICES: "Enabled via Physician Services",
  DISABLED: "Disabled",
}

export default function OrderingAccessPill(props: Props) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [hideTooltip, setHideTooltip] = useState(false)

  const [isOrderingAccessOverrideHoverEnabled] = useFeatureFlag(
    FeatureFlag.OrderAccessOverrideHover
  )

  const overrideTestAction = (unavailableState?: string) => {
    if (isOrderingAccessOverrideHoverEnabled) {
      dispatch(
        Actions.setLabTestForOverride(props.labCompany, unavailableState)
      )
      setHideTooltip(true)
    }
  }

  const disabledReason = useMemo(() => {
    return getDisabledOrderingAccessReasonForLabCompany(
      props.hasOrderingAccess,
      isOrderingAccessOverrideHoverEnabled,
      props.labCompany,
      props.selectedPractitionerListItem,
      overrideTestAction
    )
  }, [
    props.hasOrderingAccess,
    isOrderingAccessOverrideHoverEnabled,
    props.labCompany,
    props.selectedPractitionerListItem,
  ])

  const handleHiddenTooltip = () => {
    if (hideTooltip) {
      setHideTooltip(false)
    }
  }

  const isEnabledViaOwnLicense = props.hasOrderingAccess
  const isEnabledViaPhysServices =
    props.selectedPractitionerListItem?.vendor_physician_authorization_enabled
  const canOrder = isEnabledViaOwnLicense || isEnabledViaPhysServices

  return (
    <OrderingAccessPillContainer isPillEnabled={canOrder}>
      {canOrder ? (
        <>
          <img
            className={classes.pillEllipse}
            src={EnabledEllipse}
            alt="access enabled"
          />
          <BodyText size="sm" weight="semibold">
            {isEnabledViaOwnLicense
              ? ORDERING_ACCESS_STATUS_TEXT.ENABLED
              : ORDERING_ACCESS_STATUS_TEXT.ENABLED_PHYS_SERVICES}
          </BodyText>
        </>
      ) : (
        <>
          <img
            className={classes.pillEllipse}
            src={DisabledEllipse}
            alt="access disabled"
          />
          <BodyText size="sm" weight="semibold">
            {ORDERING_ACCESS_STATUS_TEXT.DISABLED}
          </BodyText>
        </>
      )}
      {!canOrder && (
        <>
          <Tooltip
            title={hideTooltip ? "" : disabledReason}
            placement="top"
            arrow
            disableHoverListener={!disabledReason}
            interactive
          >
            <div onMouseOver={handleHiddenTooltip}>
              <img
                className={classes.orderingAccessPillIcon}
                src={InfoIcon}
                alt="info"
              />
            </div>
          </Tooltip>
        </>
      )}
    </OrderingAccessPillContainer>
  )
}
