import { forwardRef } from "react"

import { Skeleton } from "@material-ui/lab"

import { ReactComponent as AddWhiteIcon } from "app/assets/icons/add-white.svg"
import Button from "app/components/design-system/Button"
import NumericBiomarkerGraphic from "app/results-summary/components/ResultsSummaryEditor/components/BiomarkerRenderer/NumericBiomarkerGraphic"
import { colors, primaryColor } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"

const useStyles = makeAppStyles((theme) => ({
  root: {
    width: "100%",
    display: "flex",
    flexFlow: "column nowrap",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: colors.blueGray[100],
    padding: theme.spacing(3.0, 4.5),
    gap: theme.spacing(1.5),
    borderRadius: 6,
  },
  addButton: {
    display: "flex",
    alignItems: "center",
    "& .MuiButton-startIcon": {
      margin: theme.spacing(0.0, 0.5, 0.0, 0.0),
    },
  },
  addIcon: {
    height: 16,
    width: 16,
    padding: 3,
    backgroundColor: primaryColor,
    borderRadius: "50%",
    verticalAlign: "middle",
  },
  optional: {
    paddingLeft: theme.spacing(0.5),
    color: colors.blueGray[500],
  },
  contentPlaceholder: {
    maxWidth: 460,
    width: "100%",
    height: 29,
    display: "flex",
    flexFlow: "row nowrap",
    padding: theme.spacing(0.75),
    gap: theme.spacing(0.75),
    backgroundColor: colors.blueGray[200],
    borderRadius: 6,
  },
  contentPlaceholderGroup: {
    display: "flex",
    flexFlow: "column nowrap",
    gap: theme.spacing(0.5),
  },
  biomarkerPlaceholder: {
    maxWidth: 260,
    width: "100%",
  },
  taskPlaceholder: {
    maxWidth: 200,
    width: "100%",
    display: "flex",
    flexFlow: "row nowrap",
    alignItems: "center",
    gap: theme.spacing(1.0),
  },
  taskPlaceholderGroup: {
    display: "flex",
    flexFlow: "column nowrap",
    gap: theme.spacing(0.5),
  },
  skeleton: {
    borderRadius: 6,
    backgroundColor: colors.blueGray[300],
  },
  skeletonLight: {
    borderRadius: 6,
    backgroundColor: colors.blueGray[200],
  },
  taskSkeletonCircle: {
    display: "flex",
    flexFlow: "row nowrap",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
    backgroundColor: colors.blueGray[200],
  },
  taskSkeletonCircleInner: {
    // Necessary to override default skeleton visibility when a child of a skeleton.
    visibility: "visible",
    borderRadius: "50%",
    backgroundColor: colors.blue[300],
  },
}))

const ContentPlaceholder = () => {
  const classes = useStyles()
  return (
    <div className={classes.contentPlaceholder}>
      <Skeleton
        animation={false}
        className={classes.skeleton}
        height={8}
        width={8}
        variant="rect"
      />

      <div className={classes.contentPlaceholderGroup}>
        <Skeleton
          animation={false}
          className={classes.skeleton}
          height={8}
          width={363}
          variant="rect"
        />

        <Skeleton
          animation={false}
          className={classes.skeleton}
          height={8}
          width={209}
          variant="rect"
        />
      </div>
    </div>
  )
}

const BiomarkerPlaceholder = () => {
  const classes = useStyles()
  return (
    <div className={classes.biomarkerPlaceholder}>
      <NumericBiomarkerGraphic
        normalMax="100"
        normalMin="0"
        placeholder
        value="112"
      />
    </div>
  )
}

const TaskPlaceholder = () => {
  const classes = useStyles()
  return (
    <div className={classes.taskPlaceholder}>
      <Skeleton
        animation={false}
        className={classes.taskSkeletonCircle}
        height={36}
        width={36}
        variant="circle"
      >
        <Skeleton
          animation={false}
          className={classes.taskSkeletonCircleInner}
          height={12}
          width={12}
          variant="circle"
        />
      </Skeleton>
      <div className={classes.taskPlaceholderGroup}>
        <Skeleton
          animation={false}
          className={classes.skeleton}
          height={10}
          width={50}
          variant="rect"
        />

        <Skeleton
          animation={false}
          className={classes.skeletonLight}
          height={10}
          width={100}
          variant="rect"
        />
      </div>
    </div>
  )
}

export interface EditorFormPlaceholderProps {
  addLabel: string
  loading?: boolean
  onClick?: () => void
  type: "biomarker" | "content" | "task"
}

export default forwardRef(function EditorFormPlaceholder(
  {
    addLabel,
    loading = false,
    type,
    ...triggerProps
  }: EditorFormPlaceholderProps,
  ref: any
) {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <Button
        {...triggerProps}
        className={classes.addButton}
        color="text"
        loading={loading}
        startIcon={
          <AddWhiteIcon className={classes.addIcon} viewBox="0 0 15 16" />
        }
        type="button"
        ref={ref}
      >
        {addLabel}
        <span className={classes.optional}>{"(Optional)"}</span>
      </Button>

      {(() => {
        switch (type) {
          case "biomarker":
            return <BiomarkerPlaceholder />
          case "content":
            return <ContentPlaceholder />
          case "task":
            return <TaskPlaceholder />
        }
      })()}
    </div>
  )
})
