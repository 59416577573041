import { makeStyles } from "@material-ui/core/styles"

import Button from "app/components/design-system/Button"
import { colors, navy } from "app/theme"
import { PatientSignupError, PatientSignupErrors } from "app/types"

import { ControlledTextField } from "../patient-checkout/fields"
import { PatientSignupFormFields } from "./use-patient-signup-form"

const useStyles = makeStyles((theme) => ({
  root: {
    gap: 20,
    display: "flex",
    flexDirection: "column",
  },
  inputField: {
    width: "100%",
    marginBottom: 8,
  },
  submitButton: {
    width: "100%",
  },
  instructions: {
    color: navy,
    fontSize: 15,
    fontWeight: 400,
    textAlign: "center",
  },
  resendCode: {
    fontSize: 17,
  },
  invalidCode: {
    color: colors.red[600],
    fontSize: 15,
  },
}))

export default function VerificationcodeForm({
  email,
  submitDisabled,
  onSubmit,
  loading,
  onResend,
  signupError,
}: {
  email: string
  submitDisabled: boolean
  onSubmit: () => void
  loading: boolean
  onResend: () => void
  signupError: PatientSignupError | null
}) {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <p className={classes.instructions}>
        Just one more step! We sent a verification code to {email}. Please enter
        it below.
      </p>
      <ControlledTextField
        label="Verification code"
        name={PatientSignupFormFields.VERIFICATION_CODE}
        className={classes.inputField}
        required
      />
      <Button
        color="primary"
        size="medium"
        aria-label="CONTINUE"
        disabled={submitDisabled}
        type="button"
        className={classes.submitButton}
        onClick={onSubmit}
        loading={loading}
      >
        Continue
      </Button>
      <Button color="text" className={classes.resendCode} onClick={onResend}>
        Resend Code
      </Button>
      {signupError?.code === PatientSignupErrors.INVALID_VERIFICATION_CODE && (
        <p className={classes.invalidCode}>
          Invalid Code! If you need a new code, try resending.
        </p>
      )}
    </div>
  )
}
