import { createContext, useContext, useEffect, useMemo, useState } from "react"

import useAppSelector from "app/hooks/useAppSelector"
import { Practitioner } from "app/types"
import find from "lodash/find"
import sortBy from "lodash/sortBy"

import {
  LabCompanyOrderingAccess,
  PRAC_OPTION_GROUPS,
  PracSelectOption,
} from "./types"
import useLabCompanyOrderingAccessSWR from "./use-lab-company-ordering-access-swr"

// return data of the provider
interface LabOrderingAccessContextProps {
  practitionerOptions: PracSelectOption[]
  selectedPractitioner: PracSelectOption | undefined
  setSelectedPractitionerId: (value: string) => void
  labCompanies: LabCompanyOrderingAccess[]
  isLoading: boolean
  isValidating: boolean
}

const LabOrderingAccessContext = createContext<LabOrderingAccessContextProps>(
  undefined as never
)

interface Props {
  practitioner: Practitioner
  children: React.ReactNode
}

function LabOrderingAccessProvider({ practitioner, children }: Props) {
  const teamMembers = useAppSelector(({ teamMember }) => teamMember)

  const [selectedPractitionerId, setSelectedPractitionerId] = useState<
    string | undefined
  >()

  const availablePractitioners = useMemo(() => {
    return [
      // Current practitioner is the only one that may be updated via a mutation,
      // so we include it in the list and exclude it from the list of team members
      practitioner,
      ...Object.values(teamMembers).filter(
        (tm) => !tm.is_clinic_staff && tm.id !== practitioner.id
      ),
    ]
  }, [teamMembers, practitioner])

  const practitionerOptions: PracSelectOption[] = useMemo(() => {
    const pracOptions = availablePractitioners.map((tm) => ({
      label: tm.titled_full_name,
      group:
        tm.id === practitioner.id
          ? PRAC_OPTION_GROUPS.CURRENT_USER
          : PRAC_OPTION_GROUPS.TEAM_MEMBER,
      ...tm,
    }))

    return sortBy(pracOptions, ["group", "label"])
  }, [availablePractitioners, practitioner])

  const selectedPractitioner = useMemo(() => {
    return find(practitionerOptions, { id: selectedPractitionerId })
  }, [selectedPractitionerId, practitionerOptions])

  useEffect(() => {
    if (selectedPractitionerId) return

    let defaultPractitionerId: string | undefined = undefined
    if (!practitioner.is_clinic_staff) {
      defaultPractitionerId = practitioner.id
    } else if (practitionerOptions.length > 0) {
      defaultPractitionerId = practitionerOptions[0].id
    }
    setSelectedPractitionerId(defaultPractitionerId)
  }, [selectedPractitionerId, practitioner, practitionerOptions])

  const { data, isLoading, isValidating } = useLabCompanyOrderingAccessSWR({
    signingPractitionerId: selectedPractitionerId,
  })

  return (
    <LabOrderingAccessContext.Provider
      value={{
        practitionerOptions,
        selectedPractitioner,
        setSelectedPractitionerId,
        labCompanies: data || [],
        isLoading,
        isValidating,
      }}
    >
      {children}
    </LabOrderingAccessContext.Provider>
  )
}

export function useLabOrderingAccess() {
  return useContext(LabOrderingAccessContext)
}

export default LabOrderingAccessProvider
