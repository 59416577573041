import { useContext, useMemo, useState } from "react"

import { useLocation } from "react-router-dom"

import { useSpecimenIssueResolveModal } from "app/components/SpecimenIssues/resolution-modal/SpecimenIssueResolveModal"
import RupaLinearProgress from "app/components/design-system/RupaLinearProgress"
import useStatusComponents from "app/components/hooks/use-status-components"
import { STATUS_CONFIGURATION_TYPES } from "app/components/hooks/use-status-components/consts"
import statusConfigurations from "app/components/hooks/use-status-components/status-configurations/dashboard"
import TableRendererContext from "app/dashboard/contexts/TableRendererContext"
import useAppSelector from "app/hooks/useAppSelector"
import useCachedCollection from "app/swr/hooks/use-cached-collection"
import useCachedResource from "app/swr/hooks/use-cached-resource"
import { DashboardAlert } from "types/dashboard/dashboard-alert"
import { DashboardKit } from "types/dashboard/dashboard-kit"
import { DashboardLabCompany } from "types/dashboard/dashboard-lab-company"
import { DashboardOrder } from "types/dashboard/dashboard-order"
import { DashboardOrderedResult } from "types/dashboard/dashboard-ordered-result"
import { DashboardOrderedTest } from "types/dashboard/dashboard-ordered-test"
import { DashboardPatient } from "types/dashboard/dashboard-patient"
import { SpecimenIssueNormalized } from "types/specimen-issue"

interface Props {
  kit: DashboardKit
  order: DashboardOrder
}

const TableRowDetailStatus: React.FC<Props> = ({ kit, order }) => {
  // The status components can't use hooks b/c they're rendered using React antipatterns.
  // So we need to load cached data here and pass it in
  const labCompany = useCachedResource<DashboardLabCompany>(
    kit.relationships.lab_company.data
  )
  const cachedSpecimenIssue = useCachedResource<SpecimenIssueNormalized>(
    kit.relationships.specimen_issue?.data
  )
  const specimenIssue = useMemo(() => {
    return cachedSpecimenIssue
      ? { ...cachedSpecimenIssue.attributes, id: cachedSpecimenIssue.id }
      : undefined
  }, [cachedSpecimenIssue])
  const patient = useCachedResource<DashboardPatient>(
    order.relationships.patient.data
  )
  const latestOrderedResult = useCachedResource<DashboardOrderedResult>(
    kit.relationships.latest_ordered_result?.data
  )
  const resultsDelayAlert = useCachedResource<DashboardAlert>(
    kit.relationships.active_results_delay_alert?.data
  )
  const orderedTests = useCachedCollection<DashboardOrderedTest>(
    kit.relationships.ordered_tests?.data
  )
  const { orderStatuses } = useContext(TableRendererContext)
  const [specimenIssueShowMore, setSpecimenIssueShowMore] = useState(false)

  const revalidateOrderStatuses = async () => {
    await orderStatuses?.mutate()
  }

  const specimenIssueModal = useSpecimenIssueResolveModal()
  const { pathname } = useLocation()
  const currentPractitioner = useAppSelector(({ practitioner }) => practitioner)

  const { lastUpdatedAt, progressPercentage, subTitle, title } =
    useStatusComponents({
      type: STATUS_CONFIGURATION_TYPES.DASHBOARD,
      statusConfigurations,
      kit,
      order,
      orderedTests,
      labCompany,
      patient,
      specimenIssue,
      orderedResult: latestOrderedResult,
      labCompanyAlert: resultsDelayAlert,
      specimenIssueShowMore,
      setSpecimenIssueShowMore,
      revalidateOrderStatuses,
      specimenIssueResolveModal: specimenIssueModal,
      pathname,
      currentPractitioner,
    })

  return (
    <div className="flex flex-col gap-1 break-words">
      <p className="text-sm">{lastUpdatedAt}</p>
      <div>
        <div className="text-lg17 font-semibold">{title}</div>
        <RupaLinearProgress
          aria-label="Order status progress"
          progressPercentage={progressPercentage}
        />
        <div className="flex items-center gap-1">{subTitle}</div>
      </div>
    </div>
  )
}

export default TableRowDetailStatus
