import { useMemo } from "react"

import { UseFormReturn } from "react-hook-form"

import { faCircleQuestion } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import BodyText from "app/components/design-system/BodyText"
import ControlledDatePicker from "app/components/forms/ControlledDatePicker"
import {
  ControlledAutoCompleteField,
  ControlledSelectField,
  FieldLabel,
} from "app/main/patient-checkout/fields"
import useInputFormStyles from "app/main/results-upload/hooks/use-input-form-styles"
import { LabCompany, LabTestType } from "app/types"
import formatDateForHuman from "app/utils/format-date-for-human"

import { UserResultFormData } from "./form-helpers"

interface Props {
  availableLabCompanies: LabCompany[] | null
  availableLabTestTypes: LabTestType[]
  labCompanyFieldValue?: string
  methods: UseFormReturn<UserResultFormData>
  isReadOnly?: boolean
}

export const UserResultFormBaseSection = ({
  availableLabCompanies,
  availableLabTestTypes,
  methods,
  isReadOnly = false,
}: Props) => {
  const classes = useInputFormStyles()

  const labCompanyOptions = useMemo(() => {
    return availableLabCompanies?.map((lc) => lc.results_name) ?? []
  }, [availableLabCompanies])

  const collectionDate = methods.getValues("collection_date")

  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 gap-5">
      {isReadOnly ? (
        <div className="w-full mt-2">
          <FieldLabel className="font-normal" label="Sample Collection Date" />
          <div className="font-semibold">
            {formatDateForHuman(collectionDate)}
          </div>
        </div>
      ) : (
        <ControlledDatePicker
          name="collection_date"
          calendarProps={{
            fromYear: new Date().getFullYear() - 20,
            toYear: new Date().getFullYear() + 1,
            captionLayout: "dropdown",
          }}
          fieldLabelProps={{ label: "Sample Collection Date", required: true }}
          align="start"
          modal
        />
      )}

      {isReadOnly ? (
        <div className="w-full mt-2">
          <FieldLabel className="font-normal" label="Fasted" />
          <div className="font-semibold">
            {methods.getValues("fasting_required")}
          </div>
        </div>
      ) : (
        <ControlledSelectField label="Fasted" name="fasting_required">
          <option value={"yes"}>Yes</option>
          <option value={"no"}>No</option>
        </ControlledSelectField>
      )}

      {isReadOnly ? (
        <div className="w-full mt-2">
          <FieldLabel className="font-normal" label="Lab Company" />
          <div className="font-semibold">
            {methods.getValues("lab_company")}
          </div>
        </div>
      ) : (
        <ControlledAutoCompleteField
          name="lab_company"
          label="Lab Company"
          options={labCompanyOptions}
          valueFromOption={(option) => option}
          optionFromValue={(value, _options) => value}
          getOptionLabel={(option) => option}
          // getOptionLabel={getLabCompanyOptionLabel}
          className="w-full"
          inputClassName={classes.customInput}
          showErrors={true}
          openOnFocus={false}
          placeholder="Enter Lab Company"
          includeInputTextInOptions
          dropdownOnlyWhileTyping
          freeSolo={true}
        />
      )}

      {isReadOnly ? (
        <div className="w-full mt-2">
          <FieldLabel
            className="font-normal"
            label="Sample Type"
            infoText={
              <InfoTextTooltipContent content="Markers collected at different times are tracked together only if they share identical sample types. This ensures their values are directly comparable." />
            }
            alternateIcon={
              <FontAwesomeIcon
                className="text-primary"
                icon={faCircleQuestion}
              />
            }
          />
          <div className="font-semibold">
            {
              availableLabTestTypes.find(
                (labTestType) =>
                  labTestType.id === methods.getValues("sample_type_id")
              )?.name
            }
          </div>
        </div>
      ) : (
        <ControlledSelectField
          label="Sample Type"
          infoText={
            <InfoTextTooltipContent content="Markers collected at different times are tracked together only if they share identical sample types. This ensures their values are directly comparable." />
          }
          alternateIcon={
            <FontAwesomeIcon className="text-primary" icon={faCircleQuestion} />
          }
          name="sample_type_id"
          shouldUnregister
        >
          {availableLabTestTypes.map((labTestType) => (
            <option key={labTestType.id} value={labTestType.id}>
              {labTestType.name}
            </option>
          ))}
        </ControlledSelectField>
      )}
    </div>
  )
}

const InfoTextTooltipContent = ({ content }: { content: string }) => {
  return (
    <div className="flex flex-col gap-1 items-center justify-center">
      <BodyText size="xs">{content}</BodyText>
    </div>
  )
}
