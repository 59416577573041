import { useState, useMemo, useEffect } from "react"
import { useDispatch } from "react-redux"

import { isAxiosError } from "axios"
import { FormProvider, useWatch } from "react-hook-form"
import { useSearchParam } from "react-use"

import { API } from "app/api"
import {
  PATIENT_PORTAL_SOURCE,
  PATIENT_PORTAL_TRACKING_EVENTS,
  trackPatientPortalEvent,
} from "app/services/segment"
import { PatientSignupError, PatientSignupFormData } from "app/types"
import { handleApiError } from "app/utils"

import PatientSignupForm from "./PatientSignupForm"
import VerificationcodeForm from "./VerificationCodeForm"
import usePatientSignupForm, {
  PatientSignupFormFields,
} from "./use-patient-signup-form"

export default function PatientSignup({
  defaultEmail,
  defaultPatientPortalToken,
  defaultNext,
}: {
  defaultEmail?: string
  defaultPatientPortalToken?: string | null
  defaultNext?: string
}) {
  const dispatch = useDispatch()

  const emailParamRaw = useSearchParam("email")
  const patientPortalTokenParamRaw = useSearchParam("patient-portal-token")
  // If the email param is an alias, we don't want to use it
  // This was a bug that was fixed, but email links still exist with the aliased email before that the fix
  const aliasEmail = emailParamRaw?.includes("###alias###")
  const emailParam = aliasEmail ? null : emailParamRaw
  // only use the patient portal token if it is not an alias
  const patientPortalTokenParam = aliasEmail ? null : patientPortalTokenParamRaw

  const patientPortalToken = useMemo(() => {
    return defaultPatientPortalToken ?? patientPortalTokenParam ?? undefined
  }, [patientPortalTokenParam, defaultPatientPortalToken])

  const email = useMemo(() => {
    return defaultEmail ?? emailParam ?? undefined
  }, [emailParam, defaultEmail])

  const { methods } = usePatientSignupForm(email, patientPortalToken)
  const {
    getValues,
    setValue,
    control,
    formState: { isValid },
  } = methods

  const watchPassword = useWatch<PatientSignupFormData>({
    control,
    name: PatientSignupFormFields.PASSWORD,
  })

  const watchConfirmPassword = useWatch({
    control,
    name: PatientSignupFormFields.CONFIRM_PASSWORD,
  })

  const watchTermsOfService = useWatch({
    control,
    name: PatientSignupFormFields.TERMS_OF_SERVICE,
  })

  const watchVerificationCode = useWatch({
    control,
    name: PatientSignupFormFields.VERIFICATION_CODE,
  })

  const [loading, setLoading] = useState(false)
  const [signupError, setSignupError] = useState<PatientSignupError | null>(
    null
  )
  const [requiresVerificationCode, setRequiresVerificationCode] =
    useState(false)
  const [submitVerificationDisabled, setSubmitVerificationDisabled] =
    useState(true)
  const [showPasswordRequirements, setShowPasswordRequirements] =
    useState(false)

  // use State to manage custom errors because react-hook-form custom errors
  // do not always propagate reliably
  const [acceptedTos, setAcceptedTos] = useState(false)
  const [passwordMatchError, setPasswordMatchError] = useState(false)

  const nextParam = useSearchParam("next")
  const next = useMemo(() => {
    return defaultNext ?? nextParam
  }, [nextParam, defaultNext])

  // Custom validation for password match
  // using built in zod refine would only evaluate when entire form was
  // completed, including the terms of service checkbox
  useEffect(() => {
    if (watchPassword && watchConfirmPassword) {
      setPasswordMatchError(watchPassword !== watchConfirmPassword)
    }
  }, [watchPassword, watchConfirmPassword])

  // Custom Validation for terms of service since zod checkbox onBlur behavior
  // would only evaluated schema once it was deselected
  useEffect(() => {
    setAcceptedTos(watchTermsOfService)
  }, [watchTermsOfService])

  useEffect(() => {
    setSubmitVerificationDisabled(!watchVerificationCode)
  }, [watchVerificationCode])

  useEffect(() => {
    setShowPasswordRequirements(
      typeof watchPassword === "string" &&
        watchPassword.length > 0 &&
        watchPassword.length < 8
    )
  }, [watchPassword])

  const getPatientPortalTrackingSource = () => {
    if (defaultPatientPortalToken) {
      return PATIENT_PORTAL_SOURCE.POST_PATIENT_CHECKOUT
    } else if (patientPortalTokenParam) {
      return PATIENT_PORTAL_SOURCE.EMAIL
    }
    return PATIENT_PORTAL_SOURCE.WEBSITE
  }

  useEffect(() => {
    trackPatientPortalEvent(
      PATIENT_PORTAL_TRACKING_EVENTS.SIGN_UP_PAGE_VIEWED,
      { source: getPatientPortalTrackingSource() }
    )
  }, [])

  const sendSignupRequest = async () => {
    const values = getValues()
    return await API.Auth.patientSignup({
      first_name: values.first_name,
      last_name: values.last_name,
      email: values.email,
      password: values.password,
      verification_code: values.verification_code,
      patient_portal_token: values.patient_portal_token,
      given_patient_marketing_consent: values.given_patient_marketing_consent,
      next: next,
    })
  }

  const onSubmitForm = async () => {
    setLoading(true)

    try {
      const response = await sendSignupRequest()

      setLoading(false)
      setSignupError(null)

      if (response.status === 201) {
        trackPatientPortalEvent(
          PATIENT_PORTAL_TRACKING_EVENTS.SIGN_UP_PAGE_SUCCESS,
          { source: getPatientPortalTrackingSource() }
        )
        // Redirect to magic link
        window.location.replace(response.data.magic_link)
        return
      } else if (response.status === 200) {
        setRequiresVerificationCode(true)
      }
    } catch (error: any) {
      setLoading(false)

      const isRequestError = isAxiosError(error)
      if (isRequestError && error.response?.status === 400) {
        setSignupError(error.response.data.errors[0])
      } else {
        dispatch(handleApiError(error))
      }
    }
  }

  const onResendVerificationCode = async () => {
    // set verification code to empty string and send request again
    // to trigger a new verification code
    // unsure why typescript is throwing an error here, so ignoring it
    // @ts-ignore
    setValue(PatientSignupFormFields.VERIFICATION_CODE, "")
    await onSubmitForm()
  }

  return (
    <FormProvider {...methods}>
      <form>
        {!requiresVerificationCode ? (
          <PatientSignupForm
            onSubmitForm={onSubmitForm}
            submitDisabled={!isValid || loading || !acceptedTos}
            loading={loading}
            passwordMatchError={passwordMatchError}
            signupError={signupError}
            email={getValues().email}
            showPasswordRequirements={showPasswordRequirements}
            hasPatientPortalToken={!!patientPortalToken}
            next={next}
            hasDefaultEmail={!!email}
          />
        ) : (
          <VerificationcodeForm
            submitDisabled={submitVerificationDisabled}
            loading={loading}
            email={getValues().email}
            onSubmit={onSubmitForm}
            onResend={onResendVerificationCode}
            signupError={signupError}
          />
        )}
      </form>
    </FormProvider>
  )
}
