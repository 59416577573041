import { useEffect, useState } from "react"

import { Link as RouterLink } from "react-router-dom"

import { Link, makeStyles } from "@material-ui/core"

import { UserPaths } from "app/Routes"
import Tooltip from "app/components/Tooltip"
import BodyText from "app/components/design-system/BodyText"
import useFeatureFlag, { FeatureFlag } from "app/hooks/use-feature-flag"
import useAppSelector from "app/hooks/useAppSelector"
import { colors } from "app/theme"
import { CustomFeeLineItem, Order, StripeConnectAccount } from "app/types"

import CustomFeeCard from "./CustomFeeCard"

interface Props {
  stripeConnectAccounts?: StripeConnectAccount[]
  order: Order
}

const useStyles = makeStyles({
  disabledLink: {
    color: colors.blueGray[400],
    cursor: "default",
  },
  tooltipText: {
    textAlign: "center",
  },
  settingsLink: {
    fontWeight: 600,
    cursor: "pointer",
  },
})

const CustomFees = ({ stripeConnectAccounts, order }: Props) => {
  const classes = useStyles()

  const [customFeeLineItem, setCustomFeeLineItem] = useState<
    CustomFeeLineItem | undefined
  >()
  const [showEmptyState, setShowEmptyState] = useState<boolean>(false)
  const [tooltipText, setTooltipText] = useState<string>("")
  const [openWithEdit, setOpenWithEdit] = useState<boolean>(false)
  const [addNewCustomFeeLineItem, setAddNewCustomFeeLineItem] =
    useState<boolean>(false)

  const practitioner = useAppSelector(({ practitioner }) => practitioner)

  const [showCustomFeeText] = useFeatureFlag(FeatureFlag.CustomFeeCheckoutText)

  const hasStripeAccount =
    stripeConnectAccounts && Boolean(stripeConnectAccounts.length)
  const isStripeAccountDisabled =
    hasStripeAccount && stripeConnectAccounts[0].status === "Disabled"
  const isStripeAccountOnboarding =
    hasStripeAccount && stripeConnectAccounts[0].status === "Onboarding"

  // Listen to order and update custom fees as it changes
  useEffect(() => {
    if (!hasStripeAccount && showCustomFeeText) {
      setCustomFeeLineItem(undefined)
      setShowEmptyState(true)
      setTooltipText(
        "You can add your own custom fees to orders by turning this on in Settings."
      )
      return
    } else if (isStripeAccountDisabled && showCustomFeeText) {
      setCustomFeeLineItem(undefined)
      setShowEmptyState(true)
      setTooltipText(
        "Your stripe account is currently disabled. Turn it on in Settings."
      )
      return
    } else if (isStripeAccountOnboarding) {
      setCustomFeeLineItem(undefined)
      setShowEmptyState(true)
      setTooltipText("Your stripe account is currently being verified.")
      return
    } else if (practitioner?.clinic?.is_international_clinic) {
      setCustomFeeLineItem(undefined)
      setShowEmptyState(true)
      setTooltipText(
        "You cannot add custom fees to your order as an international clinic."
      )
      return
    } else {
      setTooltipText("")
    }
    // If prac pay is on, custom fees cannot be added
    if (order.is_practitioner_paying) {
      setCustomFeeLineItem(undefined)
      setShowEmptyState(false)
      // If no custom fees in order, allow user to create one
    } else if (!order.custom_fee_line_items.length) {
      setCustomFeeLineItem(undefined)
      setShowEmptyState(true)
      // Already have created a custom fee line item - show it
    } else {
      setAddNewCustomFeeLineItem(false)
      setCustomFeeLineItem(order.custom_fee_line_items[0])
    }
  }, [order])

  // Handler function to create a custom fee line item via editor
  const handleCreateCustomFee = async () => {
    setOpenWithEdit(true)
    setAddNewCustomFeeLineItem(true)
  }

  const stripeAccountOnboardingTooltipText = (
    <div className={classes.tooltipText}>
      <BodyText size="base">{tooltipText}</BodyText>
      <RouterLink
        to={UserPaths.BILLING_AND_CUSTOM_FEES}
        className={classes.settingsLink}
      >
        Go to Settings.
      </RouterLink>
    </div>
  )

  if ((customFeeLineItem || addNewCustomFeeLineItem) && hasStripeAccount) {
    return (
      <CustomFeeCard
        customFeeLineItem={customFeeLineItem}
        stripeConnectAccount={stripeConnectAccounts[0]}
        order={order}
        openWithEdit={openWithEdit}
      />
    )
  } else if (showEmptyState) {
    return (
      <>
        {tooltipText !== "" ? (
          <Tooltip
            interactive
            title={stripeAccountOnboardingTooltipText}
            placement="top"
            arrow
          >
            <span className={classes.disabledLink}>+ Add Your Own Fee</span>
          </Tooltip>
        ) : (
          <Link
            style={{ fontWeight: 600, cursor: "pointer" }}
            onClick={handleCreateCustomFee}
          >
            + Add Your Own Fee
          </Link>
        )}
      </>
    )
  } else {
    return <></>
  }
}

export default CustomFees
