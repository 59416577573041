import BodyText from "app/components/design-system/BodyText"
import DesignSystemButton from "app/components/design-system/Button"

const PatientPortalFooterSupport = () => {
  return (
    <div className="w-full flex flex-col items-center justify-center px-15 py-8 gap-5">
      <BodyText size="md" weight="semibold">
        Need help with something else?
      </BodyText>
      <DesignSystemButton
        color="secondary"
        href="https://support.rupahealth.com"
        target="_blank"
        size="small"
      >
        Visit Our Support Center
      </DesignSystemButton>
    </div>
  )
}

export default PatientPortalFooterSupport
