import { useSlate } from "slate-react"

import Button, {
  ButtonProps,
} from "app/components/RichTextEditor/components/Button"
import Icon, { IconProps } from "app/components/RichTextEditor/components/Icon"
import {
  isMarkActive,
  toggleMark,
} from "app/components/RichTextEditor/utils/mark"
import { CustomTextMarks } from "custom-types/slate"

export type MarkButtonProps = {
  format: CustomTextMarks
  icon: IconProps["name"]
} & Omit<ButtonProps, "active" | "onMouseDown">

export default function MarkButton({
  format,
  icon,
  ...props
}: MarkButtonProps) {
  const editor = useSlate()
  return (
    <Button
      {...props}
      active={isMarkActive(editor, format)}
      onMouseDown={(event) => {
        if (props.disabled) {
          return
        }

        event.preventDefault()
        toggleMark(editor, format)
      }}
    >
      <Icon name={icon} />
    </Button>
  )
}
