import { colors } from "@material-ui/core"

import { ReactComponent as PanelBuilderIcon } from "app/assets/icons/panel-builder.svg"
import makeAppStyles from "app/utils/makeAppStyles"

const useStyles = makeAppStyles((theme) => ({
  container: {
    height: 251,
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  content: {
    height: 127,
    width: "50%",
    textAlign: "center",
  },
  icon: {},
  iconContainer: {
    width: 81,
    height: 97,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "0 auto",
  },
  text: {
    color: colors.blueGrey[500],
    fontSize: 15,
    fontWeight: 600,
  },
}))

export default function PanelsEmptyState({ text }: { text: string }) {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <div className={classes.iconContainer}>
          <PanelBuilderIcon className={classes.icon} />
        </div>
        <div className={classes.text}>{text}</div>
      </div>
    </div>
  )
}
