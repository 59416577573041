import { Resource } from "app/swr/types"

type BannerPortalType = "patient" | "practitioner"

export enum BannerDisplayType {
  LAB_COMPANY_RESULTS_ALERT = "lab_company_results_alert",
  LAB_COMPANY_SHIPPING_ALERT = "lab_company_shipping_alert",
  DASHBOARD_BANNER = "dashboard_banner",
  SPECIMEN_ISSUE_ALERT = "specimen_issue_alert",
}
export const ALERT_DISPLAY_TYPES = [
  BannerDisplayType.LAB_COMPANY_RESULTS_ALERT,
  BannerDisplayType.LAB_COMPANY_SHIPPING_ALERT,
  BannerDisplayType.SPECIMEN_ISSUE_ALERT,
]
export enum BannerType {
  BACKEND_BANNER = "banner",
  FRONTEND_BANNER = "frontend_banner",
}

export interface Banner extends Resource {
  type: BannerType.BACKEND_BANNER
  attributes: {
    id: string
    title: string
    subtitle: string
    date_active: string
    date_expires: string
    portal_types: BannerPortalType[]
    display_type: BannerDisplayType
    link_url?: string
    link_text?: string
  }
}

// Used to display banners that are created on the frontend, not fetched from the API
export interface FrontendBanner {
  type: BannerType.FRONTEND_BANNER
  attributes: {
    title: string
    subtitle: string
    display_type: BannerDisplayType
    link_url?: string
    link_text?: string
  }
}

export type AnyBanner = Banner | FrontendBanner

export const SpecimenIssueBanner: FrontendBanner = {
  type: BannerType.FRONTEND_BANNER,
  attributes: {
    title: "Specimen Issue",
    subtitle:
      "One or more of your tests have specimen issues and require action.",
    display_type: BannerDisplayType.SPECIMEN_ISSUE_ALERT,
  },
}

export interface BannerLink {
  text: string
  url?: string
  onClick?: () => void
  target?: string
}
