import { Typography, Link, makeStyles } from "@material-ui/core"

import BodyText from "app/components/design-system/BodyText"
import { orderHasActiveBioReferenceTests } from "app/dataServices/orderDataService"
import { ControlledCheckboxInput } from "app/patient-portal/fields"
import { colors } from "app/theme"

import { FieldNames } from "../fields"
import RupaConsentLabel from "./RupaConsentLabel"

const Consent = ({ order, className }) => {
  const classes = useClasses()

  const hasBRLTests = orderHasActiveBioReferenceTests(order)

  const maybeBrlConsent = hasBRLTests ? (
    <ControlledCheckboxInput
      color="primary"
      defaultValue={false}
      inputProps={{
        "data-testid": "consent-checkbox-brl",
      }}
      label={
        <div>
          <BodyText size="sm" className={classes.consentText}>
            I hereby authorize and consent to Rupa (a) using and disclosing my
            information, including protected health information, related to my
            laboratory test(s) with my healthcare provider(s) and
            laboratory(ies) performing laboratory testing ordered by my
            healthcare provider; (b) the tracking and reporting of Scarlet
            specimen collection services, if applicable; and (c) using and
            disclosing the information as permitted by law. I acknowledge that I
            have reviewed Rupa’s{" "}
            <Link
              className={classes.termsLink}
              href="https://www.rupahealth.com/privacy-policy"
              target="_blank"
            >
              Privacy Policy
            </Link>
            .
          </BodyText>
          <BodyText
            size="sm"
            weight="semibold"
            className={classes.consentTextAsterisk}
          >
            {" *"}
          </BodyText>
        </div>
      }
      name={FieldNames.BRL_CONSENT}
    />
  ) : null

  return (
    <div className={className}>
      <Typography variant="h4" color="textPrimary" style={{ fontSize: 24 }}>
        Consent
      </Typography>
      <div className={classes.checkboxContainer}>
        <ControlledCheckboxInput
          color="primary"
          defaultValue={false}
          inputProps={{
            "data-testid": "consent-checkbox",
          }}
          label={
            <RupaConsentLabel
              clinicName={order?.practitioner?.clinic_name}
              requiresPhysicianServices={
                order?.requires_vendor_physician_authorization
              }
            />
          }
          name={FieldNames.CONSENT}
        />
      </div>
      {maybeBrlConsent}
    </div>
  )
}

const useClasses = makeStyles((theme) => ({
  termsLink: {
    color: theme.palette.text.primary,
    textDecoration: "underline !important",
  },
  consentText: {
    display: "inline",
  },
  consentTextAsterisk: {
    display: "inline",
    color: colors.red[600],
  },
  checkboxContainer: {
    margin: "8px 0px",
  },
}))

export default Consent
