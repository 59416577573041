import { Link, makeStyles } from "@material-ui/core"

import BodyText from "app/components/design-system/BodyText"
import { VENDOR_PHYSICIAN_AUTHORIZATION_LABEL } from "app/constants"
import { colors } from "app/theme"

const useClasses = makeStyles((theme) => ({
  termsLink: {
    color: theme.palette.text.primary,
    textDecoration: "underline !important",
  },
  consentText: {
    display: "inline",
  },
  consentTextAsterisk: {
    display: "inline",
    color: colors.red[600],
  },
}))

const RupaConsentLabel = ({
  requiresPhysicianServices,
  clinicName,
}: {
  requiresPhysicianServices: boolean
  clinicName: string
}) => {
  const classes = useClasses()

  return (
    <div>
      <BodyText size="sm" className={classes.consentText}>
        You consent to the Rupa Team handling lab work.{" "}
        {requiresPhysicianServices
          ? `By using Rupa ${VENDOR_PHYSICIAN_AUTHORIZATION_LABEL}, your results will
                  be sent to an independent licensed physician for critical marker review, and to ${clinicName}.`
          : ""}{" "}
        You understand that Rupa does not provide medical advice. You agree to
        Rupa Health's{" "}
        <Link
          className={classes.termsLink}
          href="https://www.rupahealth.com/terms-and-conditions"
          target="_blank"
        >
          Terms & Conditions
        </Link>
        .{" "}
      </BodyText>
      <BodyText
        size="sm"
        weight="semibold"
        className={classes.consentTextAsterisk}
      >
        {" *"}
      </BodyText>
    </div>
  )
}

export default RupaConsentLabel
