import { ReactNode } from "react"

import { Collapse, InputLabel, Radio, makeStyles } from "@material-ui/core"

import { colors } from "app/theme"
import { PaymentMethodType } from "app/types"

interface PaymentMethodBaseProps {
  active: boolean
  className?: string
  children: ReactNode
  header: ReactNode
  title: string
  type: PaymentMethodType
  disabled: boolean
}

const PaymentMethodBase = ({
  active,
  className,
  children,
  header,
  title,
  type,
  disabled,
}: PaymentMethodBaseProps) => {
  const classes = useStyles()
  const id = `payment-method-${type}`
  return (
    <div className={className}>
      <div className={classes.headerWrapper}>
        <Radio
          aria-label={title}
          className={classes.headerRadio}
          color="primary"
          id={id}
          size="small"
          value={type}
          disabled={disabled}
        />
        <InputLabel htmlFor={id} className={classes.headerLabel}>
          {header}
        </InputLabel>
      </div>
      <Collapse in={active}>{children}</Collapse>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  headerWrapper: {
    display: "flex",
    alignItems: "center",
    backgroundColor: theme.palette.common.white,
    borderBottomWidth: 1,
    borderColor: colors.gray[200],
    padding: theme.spacing(0.8, 0.8),
  },
  headerRadio: {
    alignSelf: "flex-start",
    [theme.breakpoints.up("sm")]: {
      alignSelf: "center",
    },
  },
  headerLabel: {
    width: "100%",
    padding: theme.spacing(0.3, 0.7),
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
}))

export default PaymentMethodBase
