import { useCallback, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import { useMediaQuery, Theme, makeStyles } from "@material-ui/core"
import Grid from "@material-ui/core/Grid"

import { UserPaths } from "app/Routes"
import { API } from "app/api"
import { ReactComponent as BankIconBlue } from "app/assets/bank-nav-icon-blue.svg"
import { ReactComponent as CreateReportTable } from "app/assets/icons/transaction-reports/create-report-table.svg"
import CogIconGray from "app/assets/images/cog-icon-gray.svg"
import { ReactComponent as PlayIcon } from "app/assets/images/play-blue.svg"
import PageToolbar from "app/components/PageToolbar"
import BodyText from "app/components/design-system/BodyText"
import DesignSystemButton from "app/components/design-system/Button"
import { CUSTOM_FEE_TYPE } from "app/constants"
import useFeatureFlag, { FeatureFlag } from "app/hooks/use-feature-flag"
import useAppSelector from "app/hooks/useAppSelector"
import CustomFeesSettings from "app/main/settings/CustomFees/CustomFeesSettings"
import Separator from "app/main/settings/components/Separator"
import { trackEventWithProperties } from "app/services/segment.typed"
import {
  getCustomFees,
  getStripeConnectAccounts,
  updatePractitioner,
} from "app/store/actions"
import { colors } from "app/theme"
import {
  ClinicFeatures,
  CustomFee,
  Practitioner,
  RootState,
  StripeConnectAccount,
} from "app/types"
import { handleApiError } from "app/utils"

import SideNav from "../SideNav"
import CartPreferences from "./CartPreferences"
import DownloadTransactionReportModal from "./DownloadTransactionReportModal"
import { BillingAndCustomFeesEvent } from "./constants"

const styles = (theme: Theme) => ({
  contentContainer: {
    background: colors.coolGray[50],
    border: `1px solid ${colors.coolGray[200]}`,
    minHeight: "100vh",
    padding: 24,
  },
  transactionReportContainer: {
    width: "100%",
    display: "flex",
    "flex-direction": "row",
    justifyContent: "space-between",
    gap: 62,
    [theme.breakpoints.down("sm")]: {
      "flex-direction": "column",
      gap: 24,
    },
  },
  transactionReportTitle: {
    display: "flex",
    "flex-direction": "column",
    gap: 4,
  },
  downloadReportButton: {
    width: "max-content",
    justifyContent: "flex-end",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      justifyContent: "center",
    },
  },
  payoutsContainer: {
    width: "100%",
    display: "flex",
    "flex-direction": "row",
    gap: 62,
    marginTop: 16,
    [theme.breakpoints.down("sm")]: {
      "flex-direction": "column",
      gap: 24,
    },
  },
  payoutsTitle: {
    display: "flex",
    "flex-direction": "column",
    gap: 4,
    width: "100%",
  },
  payoutsButtons: {
    display: "flex",
    "flex-direction": "row",
    gap: 19,
    alignItems: "center",
    justifyContent: "flex-end",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      "flex-direction": "column",
      gap: 24,
    },
  },
  managePayoutsButtonContainer: {
    width: "max-content",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      // Added so that intercom doesn't overlap with the button
      marginBottom: 80,
    },
  },
})
const useStyles = makeStyles(styles)

const STRIPE_PAYOUT_TUTORIAL_URL =
  "https://www.rupahealth.com/videos/navigate-your-stripe-dashboard"

const BillingAndCustomFees = () => {
  const classes = useStyles()
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"))

  const dispatch = useDispatch()
  const defaultPractitionerPayFeatureEnabled = useFeatureFlag(
    FeatureFlag.DefaultPractitionerPay
  )

  const [payoutLoading, setPayoutLoading] = useState(false)
  const [
    downloadTransactionReportModalOpen,
    setDownloadTransactionReportModalOpen,
  ] = useState(false)

  const practitioner = useSelector<RootState, Practitioner>(
    ({ practitioner }) => practitioner
  )
  const stripeConnectAccount = useSelector<
    RootState,
    StripeConnectAccount | undefined
  >((state) => {
    const customFees = state.customFees
    if (
      Array.isArray(customFees.stripeConnectAccounts) &&
      customFees.stripeConnectAccounts.length
    ) {
      return customFees.stripeConnectAccounts[0]
    }
  })
  // This is the default custom fee for the clinic. If present and activated, this custom fee will be applied to all orders.
  const customFee = useSelector<RootState, CustomFee | undefined>(
    ({ customFees }) => {
      if (
        Array.isArray(customFees.customFees) &&
        customFees.customFees.length
      ) {
        // Existing clinic custom fees would have an empty type. Newly created clinic fees will be CUSTOM_FEE_TYPE.WHOLE_ORDER,
        // and storefront product custom fees will be CUSTOM_FEE_TYPE.LABSHOP_PRODUCT
        const clinicCustomFees = customFees.customFees.filter(
          (fee) => !fee.type || fee.type === CUSTOM_FEE_TYPE.WHOLE_ORDER
        )
        return clinicCustomFees[0]
      }
    }
  )
  const customFeesEnabledForClinic = useAppSelector(
    (state) => state?.practitioner?.clinic?.custom_fees_enabled
  )

  const showPayoutButton =
    customFeesEnabledForClinic && Boolean(stripeConnectAccount)

  useEffect(() => {
    dispatch(getStripeConnectAccounts())
    trackEventWithProperties(
      BillingAndCustomFeesEvent.VIEW_BILLING_AND_CUSTOM_FEES,
      {
        practitionerId: practitioner.id,
      }
    )
  }, [])

  useEffect(() => {
    if (stripeConnectAccount) {
      dispatch(getCustomFees(stripeConnectAccount.id))
    }
  }, [stripeConnectAccount])

  const toggleCustomFees = useCallback(
    (value) => {
      if (value !== practitioner.clinic.custom_fees_enabled) {
        dispatch(
          updatePractitioner({
            clinic: {
              custom_fees_enabled: value,
            },
          })
        )
      }
    },
    [practitioner]
  )

  const toggleDefaultPractitionerPayEnabled = useCallback(
    (enabled) => {
      if (enabled) {
        dispatch(
          updatePractitioner({
            clinic: {
              clinic_features: practitioner?.clinic.clinic_features.concat([
                ClinicFeatures.DEFAULT_PRACTITIONER_PAY,
              ]),
            },
          })
        )
      } else {
        dispatch(
          updatePractitioner({
            clinic: {
              clinic_features: practitioner?.clinic.clinic_features.filter(
                (feature) => feature !== ClinicFeatures.DEFAULT_PRACTITIONER_PAY
              ),
            },
          })
        )
      }
    },
    [practitioner]
  )

  const onPayoutClick = async () => {
    if (payoutLoading || !stripeConnectAccount) {
      return
    }

    try {
      setPayoutLoading(true)

      const response = await API.StripeConnectAccount.dashboardLink(
        stripeConnectAccount.id
      )
      window.location.href = response.data.link
    } catch (error) {
      dispatch(handleApiError(error))
    } finally {
      setPayoutLoading(false)
    }
  }

  const onDownloadReportModalBtnClick = () => {
    trackEventWithProperties(
      BillingAndCustomFeesEvent.TRANSACTION_REPORT_CLICK_OPEN_MODAL,
      {
        practitionerId: practitioner.id,
      }
    )
    setDownloadTransactionReportModalOpen(true)
  }

  const defaultPractitionerPayEnabled =
    practitioner?.clinic?.clinic_features.includes(
      ClinicFeatures.DEFAULT_PRACTITIONER_PAY
    )

  const defaultPractitionerPayDisabledReason = customFeesEnabledForClinic
    ? "You cannot set Practitioner-Pay as the default while custom fees are enabled."
    : undefined

  return (
    <>
      {isMobile && (
        <PageToolbar
          breadcrumbs={[
            {
              to: UserPaths.SETTINGS,
              icon: CogIconGray,
              label: "Settings",
            },
            {
              label: "Billing & Custom Fees",
            },
          ]}
          title="Billing & Custom Fees"
        />
      )}
      <Grid container>
        {!isMobile && (
          <Grid item xs={12} md={3}>
            <SideNav />
          </Grid>
        )}
        <Grid item xs={12} md={9}>
          {!isMobile && (
            <PageToolbar
              title={"Billing & Custom Fees"}
              greyBackground={true}
              fullBorder={true}
            />
          )}
          <div className={classes.contentContainer}>
            <div className={classes.transactionReportContainer}>
              <div className={classes.transactionReportTitle}>
                <BodyText size="lg" weight="semibold">
                  Download Transaction Report
                </BodyText>
                <BodyText>
                  Download your transaction history with Rupa. This report will
                  include your order history, payer information, and custom fees
                  (if applicable).
                </BodyText>
              </div>
              <div>
                <DesignSystemButton
                  color="secondary"
                  startIcon={<CreateReportTable viewBox="0 0 20 15" />}
                  variant="text"
                  className={classes.downloadReportButton}
                  onClick={onDownloadReportModalBtnClick}
                >
                  Download Report
                </DesignSystemButton>
              </div>
            </div>

            <Separator />

            {defaultPractitionerPayFeatureEnabled && (
              <div>
                <CartPreferences
                  defaultPracPayEnabled={defaultPractitionerPayEnabled}
                  onToggled={toggleDefaultPractitionerPayEnabled}
                  disabledReason={defaultPractitionerPayDisabledReason}
                  loading={!Boolean(practitioner?.clinic)}
                />
                <Separator />
              </div>
            )}

            <CustomFeesSettings
              clinic={practitioner?.clinic}
              customFee={customFee}
              stripeConnectAccount={stripeConnectAccount}
              toggleCustomFees={toggleCustomFees}
              title="Custom Fees"
              disabledReason={practitioner?.clinic?.custom_fees_disabled_reason}
            />

            {showPayoutButton && (
              <div className={classes.payoutsContainer}>
                <div className={classes.payoutsTitle}>
                  <BodyText weight="semibold">Payouts</BodyText>
                  <BodyText>
                    Track your Custom Fee transactions and payouts.
                  </BodyText>
                </div>

                <div className={classes.payoutsButtons}>
                  <div style={{ width: isMobile ? "100%" : "max-content" }}>
                    <DesignSystemButton
                      color="text"
                      startIcon={<PlayIcon viewBox="-4 -10 24 34" />}
                      variant="text"
                      className={classes.downloadReportButton}
                      onClick={() => {
                        trackEventWithProperties(
                          BillingAndCustomFeesEvent.VIEW_STRIPE_TUTORIAL,
                          { practitionerId: practitioner.id }
                        )
                        window.open(STRIPE_PAYOUT_TUTORIAL_URL, "_blank")
                      }}
                    >
                      Watch Tutorial
                    </DesignSystemButton>
                  </div>

                  <div className={classes.managePayoutsButtonContainer}>
                    <DesignSystemButton
                      color="secondary"
                      startIcon={<BankIconBlue viewBox="0 0 16 17" />}
                      variant="text"
                      className={classes.downloadReportButton}
                      loading={payoutLoading}
                      onClick={onPayoutClick}
                    >
                      Manage Payouts
                    </DesignSystemButton>
                  </div>
                </div>
              </div>
            )}
          </div>
        </Grid>
      </Grid>
      <DownloadTransactionReportModal
        open={downloadTransactionReportModalOpen}
        title={"Download Transaction Report"}
        onClose={() => setDownloadTransactionReportModalOpen(false)}
      />
    </>
  )
}

export default BillingAndCustomFees
