import { useState } from "react"
import { useDispatch } from "react-redux"

import {
  faStarOfLife,
  faCreditCard,
  faCalendarXmark,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button, Checkbox, Label } from "@rupahealth/design"

import { Dialog } from "app/components/modals"
import { VENDOR_PHYSICIAN_AUTHORIZATION_LABEL } from "app/constants"
import { PRACTITIONER_SETTINGS } from "app/constants"
import { updatePractitioner } from "app/store/actions"
import { Practitioner } from "app/types"
import makeAppStyles from "app/utils/makeAppStyles"
import { getOrderTypeLabel } from "app/utils/order-utils"

import { StyledFormControlLabel } from "../settings/components/StyledFormControlLabel"
import { StyledSwitch } from "../settings/components/StyledSwitch"

const useStyles = makeAppStyles((theme) => ({
  dialog: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 12,
    [theme.breakpoints.down("xs")]: {
      maxWidth: "100%",
      margin: 10,
      width: "100vw",
    },
  },
}))

export const PhysicianServicesConfirmationModal = ({
  open,
  onClose,
  onConfirm,
  practitioner,
}: {
  open: boolean
  onClose: () => void
  onConfirm: () => void
  practitioner: Practitioner
}) => {
  const dispatch = useDispatch()

  const classes = useStyles()

  const [dontShowAgainChecked, setDontShowAgainChecked] = useState(false)
  const [defaultPhysicianServices, setDefaultPhysicianServices] = useState(
    practitioner.default_physician_authorization
  )

  const handleCheckedChange = (isChecked: boolean) => {
    setDontShowAgainChecked(isChecked)
  }

  async function handleConfirm() {
    const updated_settings = practitioner.practitioner_settings
    if (dontShowAgainChecked) {
      updated_settings.push(
        PRACTITIONER_SETTINGS.DONT_SHOW_PHYSICIAN_SERVICES_ORDER_CONFIRMATION_MODAL
      )
    }
    dispatch(
      updatePractitioner({
        practitioner_settings: [...new Set(updated_settings)],
        default_physician_authorization: defaultPhysicianServices,
      })
    )

    onConfirm()
  }

  const orderTypeLabel = getOrderTypeLabel(true).toLowerCase()

  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="physician-services-confirmation-modal"
        open={open}
        classes={{ paper: classes.dialog }}
        maxWidth="lg"
      >
        <div className="flex flex-col bg-slate-50 items-center pt-10 pb-10 p-2 gap-2 sm:p-10 max-w-lg rounded-lg m-1 overflow-scroll">
          <div className="text-center text-rupa-navy font-josefin-sans text-xl font-semibold leading-normal mb-3">
            You’re sending this {orderTypeLabel} using{" "}
            {VENDOR_PHYSICIAN_AUTHORIZATION_LABEL}.
          </div>
          <div className="bg-white p-4 flex flex-col gap-4 rounded-md">
            <ListItem
              icon={
                <FontAwesomeIcon
                  icon={faStarOfLife}
                  className="text-primary-300 text-2xl"
                />
              }
              text={`This ${orderTypeLabel} will be sent to a Signing Physician to
                  review and order for the client. In the case of a critical
                  result, the Signing Physician will reach out to the client
                  directly.`}
            />
            <Separator />
            <ListItem
              icon={
                <FontAwesomeIcon
                  icon={faCreditCard}
                  className="text-amber-400 text-2xl"
                />
              }
              text={`Once this has been paid, you won’t be able to edit this
                  ${orderTypeLabel} or refund the ${VENDOR_PHYSICIAN_AUTHORIZATION_LABEL} fee.`}
            />
            <Separator />
            <ListItem
              icon={
                <FontAwesomeIcon
                  icon={faCalendarXmark}
                  className="text-amber-400 text-2xl"
                />
              }
              text="1:1 consultations with lab partners are not available when using this service. This order is not eligible for insurance reimbursement."
            />
          </div>
          <div className="bg-white p-4 flex flex-col gap-4 rounded-md mt-4">
            <StyledFormControlLabel
              control={
                <StyledSwitch
                  name="default_physician_authorization"
                  checked={defaultPhysicianServices}
                  onChange={() =>
                    setDefaultPhysicianServices(!defaultPhysicianServices)
                  }
                />
              }
              label={
                <>
                  <div className="font-normal text-rupa-navy text-md ml-2">
                    Use the {VENDOR_PHYSICIAN_AUTHORIZATION_LABEL} Signing
                    Physician as the default for new{" "}
                    {orderTypeLabel.toLowerCase()}s.
                  </div>
                </>
              }
              className="m-0 items-center"
            />
          </div>
          <div className="flex items-center space-x-2">
            <Checkbox
              id="terms"
              variant="default"
              onCheckedChange={handleCheckedChange}
            />
            <Label
              htmlFor="terms"
              className="font-normal text-rupa-navy mt-4 mb-4 text-md"
            >
              Don’t show me this again!
            </Label>
          </div>
          <Button variant="primary" onClick={handleConfirm} fullWidth={true}>
            Confirm & Send {getOrderTypeLabel(true)}
          </Button>
          <Button variant="outline" onClick={onClose} fullWidth={true}>
            Return to Cart
          </Button>
        </div>
      </Dialog>
    </>
  )
}

const ListItem = ({ icon, text }: { icon: React.ReactNode; text: string }) => {
  return (
    <div className="flex flex-col sm:flex-row gap-y-3 sm:gap-x-4 sm:gap-y-0 items-center">
      <div className="bg-slate-50 rounded w-14 min-w-max h-14 flex justify-center items-center">
        {icon}
      </div>
      <div className="flex-1 text-center sm:text-left">{text}</div>
    </div>
  )
}

const Separator = () => {
  return <div className="border-t border-slate-100"></div>
}
