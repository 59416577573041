import NewPatientModal from "app/components/modals/NewPatientModal"
import useAppSelector from "app/hooks/useAppSelector"

export interface FoodPlanNewPatientModalProps {
  initialName?: string
  open: boolean
  onClose: () => void
  onSubmit: (patientId: string) => void
}

export default function FoodPlanNewPatientModal({
  initialName,
  open,
  onClose,
  onSubmit,
}) {
  const practitioner = useAppSelector(({ practitioner }) => practitioner)
  return (
    <NewPatientModal
      customOnSubmit={onSubmit}
      messages={{
        customSubmitText: "Start Food Plan",
        patientTerm: "Client",
      }}
      name={initialName}
      open={open}
      onClose={onClose}
      shouldHideCreatePatientButton
      practitioner={practitioner}
    />
  )
}
