import { useFormContext, useFormState } from "react-hook-form"

import RupaLoadingButton, { RupaLoadingButtonProps } from "../RupaLoadingButton"

interface Props extends Omit<RupaLoadingButtonProps, "children"> {
  children?: React.ReactNode
}

const ControlledSubmitButton: React.FC<Props> = ({
  children = "Submit",
  variant = "primary",
  wrapperClassName = "w-full",
  className = "w-full",
  type = "submit",
  loading,
  disabled,
  ...props
}) => {
  const { control } = useFormContext()
  const { isSubmitting, isValid, submitCount } = useFormState({ control })

  return (
    <RupaLoadingButton
      variant={variant}
      wrapperClassName={wrapperClassName}
      className={className}
      type={type}
      loading={isSubmitting || loading}
      disabled={(!isValid && submitCount > 0) || disabled}
      {...props}
    >
      {children}
    </RupaLoadingButton>
  )
}

export default ControlledSubmitButton
