import React from "react"

import { Button, cn } from "@rupahealth/design"

import Tooltip from "app/components/Tooltip"

import { OrderPaymentDisplayType, getButtonDisplayInfo } from "./helpers"
import useDownloadOrderPayment from "./use-download-order-payment"

interface Props {
  payment: OrderPaymentDisplayType
}

const PaymentDownloadButton: React.FC<Props> = ({ payment }) => {
  const onClick = useDownloadOrderPayment(payment)
  const { Icon, text } = getButtonDisplayInfo(payment.type)

  return (
    <Tooltip title={TooltipContent(payment)} arrow placement="bottom">
      <span>
        <Button
          type="button"
          variant="ghost"
          className={cn(
            "flex items-center text-[15px] gap-[6px] py-2 px-3 h-auto text-primary rounded-md",
            "hover:text-primary enabled:hover:bg-primary enabled:hover:bg-opacity-20",
            "disabled:cursor-default disabled:text-gray-500 disabled:opacity-100 disabled:pointer-events-none"
          )}
          disabled={payment?.disabled}
          onClick={onClick}
        >
          <Icon fill="currentColor" /> {text}
        </Button>
      </span>
    </Tooltip>
  )
}

export function TooltipContent({
  tooltipTitle: title,
  tooltipSubtitle: subtitle,
}: OrderPaymentDisplayType) {
  if (!title && !subtitle) {
    return ""
  }
  return (
    <div className="text-center">
      {title && <div className="font-bold">{title}</div>}
      {subtitle && <div>{subtitle}</div>}
    </div>
  )
}

export default PaymentDownloadButton
