import { useCallback, useContext } from "react"

import { CheckoutDraftState } from "app/types"

import CheckoutDraftContext from "../contexts/CheckoutDraftContext"
import * as Actions from "../store/actions/draft.actions"

export interface CheckoutDraftMutators {
  /**
   * Updates the draft value of a single field with the given name.
   */
  updateValue: <T extends keyof CheckoutDraftState["values"]>(
    name: T,
    value: CheckoutDraftState["values"][T]
  ) => void
  /**
   * Updates the draft values.
   */
  updateValues: (values: CheckoutDraftState["values"]) => void
}

export type UseCheckoutDraft = CheckoutDraftState & CheckoutDraftMutators

/**
 * Provides the current checkout draft state and mutators.
 *
 * @returns the checkout draft state and mutators
 * @example
 *
 * const { updateValue, values } = useCheckoutDraft()
 *
 * function onChange(nextValue) {
 *   updateValue("notes_to_patient", nextValue)
 * }
 *
 * values.notes_to_patient
 */
export default function useCheckoutDraft() {
  const [state, dispatch] = useContext(CheckoutDraftContext)

  const updateValues = useCallback((values: CheckoutDraftState["values"]) => {
    dispatch(Actions.updateValuesAction(values))
  }, [])

  const updateValue = useCallback(
    <T extends keyof CheckoutDraftState["values"]>(
      name: T,
      value: CheckoutDraftState["values"][T]
    ) => {
      updateValues({ [name]: value })
    },
    [updateValues]
  )

  return {
    ...state,
    updateValues,
    updateValue,
  }
}
