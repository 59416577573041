import { useState } from "react"

import BodyText from "app/components/design-system/BodyText"

import NewSnippetButton from "./NewSnippetButton"
import useSnippetFormModal from "./hooks/use-snippet-form-modal"
import useSnippets from "./hooks/use-snippets"

interface Props {
  title: string
  text: JSX.Element | string
  newSnippetButtonText: string
  settingsPill: JSX.Element
  snippetType: string
}

const SavedTextSnippetsSectionTitle = ({
  title,
  text,
  newSnippetButtonText,
  settingsPill,
  snippetType,
}: Props) => {
  const { createSnippet } = useSnippets({
    snippetType: snippetType,
    page: 1,
  })

  const [isSnippetCreateLoading, setIsSnippetCreateLoading] = useState(false)

  const snippetFormModal = useSnippetFormModal()

  const handleSnippetCreate = async (snippetData: any) => {
    setIsSnippetCreateLoading(true)

    await createSnippet(snippetData)

    snippetFormModal.remove()

    setIsSnippetCreateLoading(false)
  }

  return (
    <div className="flex flex-col md:flex-row justify-between gap-4 md:gap-[62px] items-center">
      <div>
        <div className="flex flex-col lg:flex-row gap-1 lg:gap-2 items-start lg:items-center mb-1">
          <BodyText size="lg" weight="semibold">
            {title}
          </BodyText>
          {settingsPill}
        </div>
        <BodyText>{text}</BodyText>
      </div>
      <NewSnippetButton
        title={newSnippetButtonText}
        onClick={() =>
          snippetFormModal.show({
            title: `Create ${
              snippetType === "results_summary"
                ? "Results Summary"
                : "Order Instruction"
            } Snippet`,
            isSaveLoading: isSnippetCreateLoading,
            handleSave: handleSnippetCreate,
            onClose: snippetFormModal.remove,
            useRichTextEditor: snippetType === "results_summary",
          })
        }
      />
    </div>
  )
}

export default SavedTextSnippetsSectionTitle
