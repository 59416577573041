import { useEffect, useRef, useState } from "react"

import { faPlus } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button } from "@rupahealth/design"

import { Biomarker } from "types/biomarker"

import BiomarkerSearchPopover from "./BiomarkerSearchPopover"

interface Props {
  existingBiomarkerIds: string[]
  onBiomarkerSelect: (biomarker: Biomarker) => void
  disabled?: boolean
}

const ResultUploadManualEntryAddBiomarkerResultButton = ({
  existingBiomarkerIds,
  onBiomarkerSelect,
  disabled,
}: Props) => {
  const buttonRef = useRef<any>()

  const [searchIsOpen, setSearchIsOpen] = useState(false)
  const [widthOfToggleContent, setWidthOfToggleContent] = useState("auto")

  // Calculate width of the the button in order to get the popover to be the same width
  // as the button. Only need to do this on load since the search popover will replace
  // the button when it is open, and will no longer have a reference to calculate its width.
  useEffect(() => {
    setWidthOfToggleContent(buttonRef.current?.offsetWidth || "auto")
  }, [])

  const handleBiomarkerSelect = (biomarkerCursor: Biomarker) => {
    onBiomarkerSelect(biomarkerCursor)
    setSearchIsOpen(false)
  }

  return searchIsOpen ? (
    <BiomarkerSearchPopover
      onBiomarkerSelect={handleBiomarkerSelect}
      existingBiomarkerIds={existingBiomarkerIds}
      widthOfToggleContent={widthOfToggleContent}
      actionText="Add"
      toggleSearch={setSearchIsOpen}
    />
  ) : (
    <div ref={buttonRef} className={"flex flex-col items-start"}>
      <Button
        variant="ghost"
        className="text-primary hover:text-primary py-0 px-0 h-auto w-fit"
        onClick={() => setSearchIsOpen(true)}
        disabled={disabled}
      >
        <FontAwesomeIcon className="text-primary mr-2" icon={faPlus} />
        Add Biomarker
      </Button>
    </div>
  )
}

export default ResultUploadManualEntryAddBiomarkerResultButton
