import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"

import { Box, CircularProgress, Grid } from "@material-ui/core"

import { API } from "app/api"
import PromotionsCouponsTable from "app/components/Promotions/PromotionsCouponsTable"
import BodyText from "app/components/design-system/BodyText"
import DesignSystemButton from "app/components/design-system/Button"
import AddCouponModal from "app/components/modals/AddCouponModal"
import useFeatureFlag, { FeatureFlag } from "app/hooks/use-feature-flag"
import { colors, navy } from "app/theme"
import { Coupon } from "app/types"
import { handleApiError } from "app/utils"
import makeAppStyles from "app/utils/makeAppStyles"

const useStyles = makeAppStyles((theme) => ({
  wrapper: {
    background: "white",
    borderRadius: 8,
    display: "flex",
    flexFlow: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "42px 0px",
    border: `2px dashed ${colors.blueGray[300]}`,
    marginTop: "24px",
  },
  bodyText: {
    color: navy,
  },
}))

const Coupons = () => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const [isActiveCouponsLoading, setIsActiveCouponsLoading] = useState(true)
  const [isStaleCouponsLoading, setIsStaleCouponsLoading] = useState(true)
  const [showAddCouponModal, setShowAddCouponModal] = useState<boolean>(false)
  const [activeCoupons, setActiveCoupons] = useState<Coupon[]>([])
  const [disabledCoupons, setDisabledCoupons] = useState<Coupon[]>([])
  const [isPromotionsAddCouponCodeButtonEnabled] = useFeatureFlag(
    FeatureFlag.isPromotionsAddCouponCodeButtonEnabled
  )

  useEffect(() => {
    // Fetch active coupons
    fetchCoupons(false)
    // Fetch used or stale coupons
    fetchCoupons(true)
  }, [])

  const fetchCoupons = async (getUsedOrStale: boolean) => {
    try {
      const response = await API.Coupon.getList({
        show_prev_used: getUsedOrStale,
      })

      if (getUsedOrStale) {
        setDisabledCoupons(response.data.results)
        setIsStaleCouponsLoading(false)
      } else {
        setActiveCoupons(response.data.results)
        setIsActiveCouponsLoading(false)
      }
    } catch (error) {
      dispatch(handleApiError(error))
    }
  }

  const closeAndRefreshCoupons = () => {
    try {
      fetchCoupons(false)
      setShowAddCouponModal(false)
    } catch (error) {
      dispatch(handleApiError(error))
    }
  }

  if (isActiveCouponsLoading || isStaleCouponsLoading) {
    return (
      <div className="flex w-100 h-100 justify-center p-8">
        <CircularProgress aria-label="Loading Coupons" />
      </div>
    )
  }

  return (
    <>
      {isPromotionsAddCouponCodeButtonEnabled && (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box display="flex" justifyContent="flex-end">
              <DesignSystemButton
                color="secondary"
                onClick={() => setShowAddCouponModal(true)}
              >
                Add Coupon Code
              </DesignSystemButton>
            </Box>
          </Grid>
        </Grid>
      )}

      {!activeCoupons.length && !disabledCoupons.length && (
        <div className={classes.wrapper}>
          <BodyText className={classes.bodyText}>
            No coupons have been added yet.
          </BodyText>
        </div>
      )}

      {activeCoupons.length ? (
        <>
          <BodyText weight="semibold" size="lg">
            Available Coupons
          </BodyText>
          <PromotionsCouponsTable
            coupons={activeCoupons}
            areCouponsAvailable={true}
          />
        </>
      ) : null}
      {disabledCoupons.length ? (
        <>
          <br></br>
          <BodyText weight="semibold" size="lg">
            Expired, Used, or Unavailable Coupons
          </BodyText>
          <PromotionsCouponsTable
            coupons={disabledCoupons}
            areCouponsAvailable={false}
          />
        </>
      ) : null}
      <AddCouponModal
        open={showAddCouponModal}
        onClose={closeAndRefreshCoupons}
      ></AddCouponModal>
    </>
  )
}

export default Coupons
