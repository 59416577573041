import { isEmpty } from "lodash"

import Button from "app/components/design-system/Button"
import { useAcknowledgeUnreadResult } from "app/dashboard/components/TableRowDetailRenderer/actions/DownloadResultsDropdownMenuAction"
import useEventCallback from "app/hooks/use-event-callback"
import makeAppStyles from "app/utils/makeAppStyles"

const useStyles = makeAppStyles((theme) => ({
  button: {
    height: 45,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
}))

export const BloodLabDashboardsDownloadButton = ({
  color,
  downloadableResultUrls,
  orderedResultId,
}: {
  color: "primary" | "secondary"
  downloadableResultUrls: string[]
  orderedResultId: string
}) => {
  const classes = useStyles()

  const acknowledgeUnreadResult = useAcknowledgeUnreadResult()
  const hasResultsToDownload = !isEmpty(downloadableResultUrls)

  const onDownloadResults = useEventCallback(async () => {
    if (!hasResultsToDownload) {
      return
    }

    downloadableResultUrls.forEach((url, index) => {
      window.open(url, `result_${index}`)
    })
    acknowledgeUnreadResult(orderedResultId, false)
  })

  return (
    <Button
      className={classes.button}
      color={color}
      shadow="sm"
      aria-label="Download"
      onClick={onDownloadResults}
    >
      Download Original Report
    </Button>
  )
}
