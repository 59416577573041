import BodyText from "app/components/design-system/BodyText"
import { Theme } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"

const styles = (theme: Theme) => ({
  testContentsItem: {
    width: "25%",
    "box-sizing": "border-box",
    marginTop: 49,
    paddingLeft: 22.5,
    paddingRight: 22.5,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginTop: 57,
    },
  },
  testContentsItemTitle: {
    "text-transform": "uppercase",
    fontWeight: 600,
    fontSize: 13,
    marginBottom: 10,
  },
})

const useStyles = makeAppStyles(styles)

interface Props {
  icon: any
  title: string
  content: any
  overrideStyle?: any
}

const AboutThisTestColumn = ({
  icon,
  title,
  content,
  overrideStyle,
}: Props) => {
  const classes = useStyles()

  return (
    <div className={classes.testContentsItem} style={overrideStyle}>
      <BodyText className={classes.testContentsItemTitle}>
        <span style={{ marginRight: 6, position: "relative", top: 3 }}>
          {icon}
        </span>
        <span>{title}</span>
      </BodyText>
      {content}
    </div>
  )
}

export default AboutThisTestColumn
