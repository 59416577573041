import { useState } from "react"

import { Dialog, Grid, makeStyles, Theme, TextField } from "@material-ui/core"
import MuiDialogContent from "@material-ui/core/DialogContent"
import MuiDialogTitle from "@material-ui/core/DialogTitle"
import IconButton from "@material-ui/core/IconButton"
import CloseIcon from "@material-ui/icons/Close"

import BodyText from "app/components/design-system/BodyText"
import DesignSystemButton from "app/components/design-system/Button"
import DisplayText from "app/components/design-system/DisplayText"
import { ModalActions } from "app/components/modals"
import { colors, navy } from "app/theme"

const styles = (theme: Theme) => ({
  dialogPaper: {
    [theme.breakpoints.up("md")]: {
      minWidth: 400,
    },
    margin: 15,
    borderRadius: 7,
  },
  titleAndInfoContainer: {
    display: "flex",
    "flex-direction": "column",
    "flex-wrap": "wrap",
    marginRight: 24,
  },
  closeButton: {
    color: navy,
    position: "relative" as "relative",
    top: -5,
    right: 0,
    marginRight: -12,
  },
  styledDialogTitle: {
    display: "flex",
    "flex-direction": "row",
    justifyContent: "space-between",
    "flex-wrap": "nowrap",
    backgroundColor: "white",
    borderBottomWidth: 1,
    borderColor: colors.blueGray[200],
    alignItems: "center",
    padding: "15px 24px 8px 24px",
  },
  styledContent: {
    padding: theme.spacing(2),
    backgroundColor: colors.coolGray[100],
  },
  disconnectField: {
    background: "white",
  },
})

const useStyles = makeStyles(styles)

interface DisconnectEMRProps {
  open: boolean
  onClose: () => void
  disconnectingEmr: any
}

export default function DisconnectEMRModal({
  open,
  onClose,
  disconnectingEmr,
}: DisconnectEMRProps) {
  const classes = useStyles(styles)
  const [disconnectingReason, setDisconnectingReason] = useState("")
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="emr-instruction-modal"
      classes={{
        paper: classes.dialogPaper,
      }}
    >
      <TitleSection
        title={`Disconnect ${disconnectingEmr.name}`}
        onClose={onClose}
      />
      <MuiDialogContent className={classes.styledContent}>
        <Grid container direction="row" spacing={2}>
          <Grid item xs={12}>
            <BodyText size="sm" weight="regular">
              Are you sure you want to disconnect {disconnectingEmr.name}?
            </BodyText>
            <BodyText size="sm" weight="regular">
              Results will no longer be sent from Rupa.
            </BodyText>
          </Grid>
        </Grid>
        <Grid container direction="row" spacing={2}>
          <Grid item xs={12}>
            <BodyText size="sm" weight="semibold">
              Why would you like to disconnect? (Optional)
            </BodyText>
          </Grid>
          <Grid item xs={12}>
            <TextField
              onChange={(e) => setDisconnectingReason(e.target.value)}
              variant="outlined"
              fullWidth
              className={classes.disconnectField}
              multiline
              rows={5}
              value={disconnectingReason}
            />
          </Grid>
        </Grid>
      </MuiDialogContent>
      <ModalActions>
        <DesignSystemButton
          onClick={() => disconnectingEmr.disconnectFn(disconnectingReason)}
          color="destructiveSecondary"
        >
          {`Disconnect ${disconnectingEmr.name}`}
        </DesignSystemButton>
      </ModalActions>
    </Dialog>
  )
}

const TitleSection = ({ title, onClose }) => {
  const classes = useStyles(styles)
  const closeButton = onClose && (
    <IconButton
      aria-label="close"
      onClick={onClose}
      key="close-button"
      className={classes.closeButton}
    >
      <CloseIcon />
    </IconButton>
  )

  return (
    <MuiDialogTitle disableTypography className={classes.styledDialogTitle}>
      <div className={classes.titleAndInfoContainer}>
        <DisplayText weight="semibold" size="lg">
          {title}
        </DisplayText>
      </div>
      {closeButton}
    </MuiDialogTitle>
  )
}
