import * as React from "react"
import { useState } from "react"

import axios from "axios"
import useSWR from "swr"

import { Button, InputAdornment, TextField } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

import { ReactComponent as MailIcon } from "app/assets/icons/envelope/task-mail.svg"
import { ReactComponent as HandWaveIcon } from "app/assets/icons/hand-wave.svg"
import { ReactComponent as CheckmarkIcon } from "app/assets/icons/white-checkmark.svg"
import { trackPOCAdded } from "app/services/segment"
import resourceRequest from "app/swr/utils/resource-request"
import { colors } from "app/theme"
import { Practitioner } from "app/types"
import { getApiBaseUrl } from "app/utils"

import TaskContainer from "../TaskContainer"
import taskStyles from "../TaskStyles"
import { OnboardingTaskItemProps } from "./OnboardingTaskItem"

const useStyles = makeStyles((theme) => ({
  inputText: {
    width: "312px",
    "& .MuiInputBase-root": {
      height: "38px",
    },
    border: "1px solid",
    borderColor: colors.blueGray[300],
    boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.05)",
    borderRadius: "6px",
  },
  submitButton: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: 8,
    height: "38px",
    minWidth: "38px",
    marginLeft: 7,
    "&:hover": {
      backgroundColor: "#3390E5",
    },
  },
  currentUserContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  currentUserButton: {
    fontSize: "15px",
    marginBottom: 10,
    fontWeight: theme.typography.fontWeightMedium,
    lineHeight: "135%",
    color: theme.palette.primary.main,
  },
}))

const PointOfContact: React.FC<OnboardingTaskItemProps> = ({
  task,
  onTaskComplete,
}) => {
  const taskClasses = taskStyles()
  const classes = useStyles()

  const [pocValue, setPOCValue] = useState("")

  const { data: loggedInPractitioner } = useSWR(
    "/api/practitioner",
    async (url) => {
      const response = await axios.get(getApiBaseUrl() + url)
      return response.data as Practitioner
    }
  )
  const clinicId = loggedInPractitioner?.clinic.id

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    await resourceRequest({
      method: "PATCH",
      url: "/clinics/" + clinicId + "/",
      data: {
        data: {
          type: "clinic",
          id: clinicId,
          attributes: {
            onboarding_point_of_contact: pocValue,
          },
        },
      },
    })
    onTaskComplete(task.key)
    trackPOCAdded()
  }

  return (
    <TaskContainer
      taskKey={task.key}
      header="Who should we connect with to transition your clinic to Rupa?"
      isTaskComplete={task.complete}
      timeTag={"30 secs"}
    >
      <HandWaveIcon style={{ scale: 0.8, marginTop: 20 }} />
      <div className={taskClasses.taskTextContainer}>
        <div className={taskClasses.taskText}>
          Let us know who we should connect with to help your clinic get set up.
        </div>
      </div>
      <div>
        <form onSubmit={handleSubmit}>
          <TextField
            id="email-input"
            variant="outlined"
            value={pocValue}
            onChange={(e) => setPOCValue(e.target.value)}
            className={classes.inputText}
            placeholder="Email"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <MailIcon />
                </InputAdornment>
              ),
            }}
          />
          {pocValue && (
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className={classes.submitButton}
            >
              <CheckmarkIcon />
            </Button>
          )}
        </form>
      </div>
      <div className={classes.currentUserContainer}>
        <Button
          className={classes.currentUserButton}
          onClick={() => setPOCValue(loggedInPractitioner?.user.email || "")}
        >
          I'm the best person!
        </Button>
      </div>
    </TaskContainer>
  )
}

export default PointOfContact
