import { Theme } from "@material-ui/core"
import { Skeleton } from "@material-ui/lab"

import makeAppStyles from "app/utils/makeAppStyles"

const useStyles = makeAppStyles((theme: Theme) => ({
  loadingContainer: {
    width: "100%",
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
  },
  skeleton: {
    borderRadius: 12,
  },
  skeletonContainer: {
    display: "flex",
    gap: 24,
    width: "100%",
    flexDirection: "column",
    alignItems: "center",
  },
}))

const PatientPortalPatientOrdersLoadingState = () => {
  const classes = useStyles()
  return (
    <div className={classes.loadingContainer}>
      <div className={classes.skeletonContainer}>
        <Skeleton
          variant="rect"
          animation="wave"
          height={40}
          width={"100%"}
          className={classes.skeleton}
        />
        <Skeleton
          variant="rect"
          animation="wave"
          height={162}
          width={"100%"}
          className={classes.skeleton}
        />
        <Skeleton
          variant="rect"
          animation="wave"
          height={162}
          width={"100%"}
          className={classes.skeleton}
        />
        <Skeleton
          variant="rect"
          animation="wave"
          height={162}
          width={"100%"}
          className={classes.skeleton}
        />
      </div>
    </div>
  )
}

export default PatientPortalPatientOrdersLoadingState
