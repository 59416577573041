import { colors } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"
import { LabTest } from "types/lab-test"

import ComboGroupAddOns from "./ComboGroupAddOns"
import ComboGroupDetails from "./ComboGroupDetails"
import ComboGroupLabTests from "./ComboGroupLabTests"

const useStyles = makeAppStyles((theme) => ({
  container: {
    backgroundColor: colors.blueGray[100],
    padding: 20,
    display: "flex",
    flexDirection: "column",
    gap: 20,
    overflow: "auto",
  },
}))

export interface ComboGroupModalBodyProps {
  targetLabTest?: LabTest
  availableTargets?: LabTest[]
  optionLabTests?: LabTest[]
  selectedOptionLabTestIds: string[]
  selectedAddOnIds: string[]
  onAddComboTest: (id: string) => void
  onRemoveComboTest: (id: string) => void
  onAddAddOn: (id: string) => void
  onRemoveAddOn: (id: string) => void
  isLoading: boolean
}

export default function ComboGroupModalBody({
  targetLabTest,
  availableTargets,
  optionLabTests,
  selectedOptionLabTestIds,
  selectedAddOnIds,
  onAddComboTest,
  onRemoveComboTest,
  onAddAddOn,
  onRemoveAddOn,
  isLoading,
}: ComboGroupModalBodyProps) {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <ComboGroupDetails
        targetLabTest={targetLabTest}
        availableTargets={availableTargets}
        isLoading={isLoading}
      />
      <ComboGroupLabTests
        selectedOptionLabTestIds={selectedOptionLabTestIds}
        optionLabTests={optionLabTests}
        onAdd={onAddComboTest}
        onRemove={onRemoveComboTest}
        isLoading={isLoading}
      />
      <ComboGroupAddOns
        targetLabTest={targetLabTest}
        selectedLabTestIds={selectedAddOnIds}
        onAdd={onAddAddOn}
        onRemove={onRemoveAddOn}
        isLoading={isLoading}
      />
    </div>
  )
}
