import axios from "axios"
import _ from "lodash"

import { ORDER_TERMINOLOGY } from "app/constants"
import { getApiBaseUrl, handleApiError, handleApiSuccess } from "app/utils"

import { fetchPractitionerBundles } from "./practitionerBundles.actions"
import { fetchPractitionerEmrIntegrations } from "./practitionerEmrIntegrations.actions"

export const GET_PRACTITIONER = "[APP] GET PRACTITIONER"
export const UPDATE_PERSONAL_SETTINGS = "[APP] UPDATE PERSONAL SETTINGS"
export const ADD_FAVORITE_TEST = "[ORDER] ADD FAVORITE TEST"
export const REMOVE_FAVORITE_TEST = "[ORDER] REMOVE FAVORITE TEST"
export const ADD_FAVORITE_BUNDLE = "[ORDER] ADD FAVORITE BUNDLE"
export const REMOVE_FAVORITE_BUNDLE = "[ORDER] REMOVE FAVORITE BUNDLE"

export function getPractitioner(params) {
  return async (dispatch, getState) => {
    try {
      const requestPromise = axios.get(getApiBaseUrl() + `/api/practitioner/`, {
        params: params,
      })

      // in parallel, begin fetching practitioner bundles and practitioner emr integrations
      dispatch(fetchPractitionerBundles())
      dispatch(fetchPractitionerEmrIntegrations())

      const response = await requestPromise

      dispatch({
        type: GET_PRACTITIONER,
        payload: response.data,
      })
    } catch (error) {
      dispatch(handleApiError(error))
    }
  }
}

export function updatePersonalSettings(
  attributesToUpdate,
  isNotificationEnabled = false,
  notificationBody = "Your personal settings have been successfully updated"
) {
  return (dispatch, getState) => {
    const { practitioner } = getState()
    const request = axios.patch(
      getApiBaseUrl() + `/api/practitioner/${practitioner.id}`,
      { ...attributesToUpdate }
    )

    return request
      .then((response) => {
        if (isNotificationEnabled) {
          dispatch(handleApiSuccess(notificationBody))
        }

        // start_draft_text is a computed property, so apply the logic to correct this value without refreshing the prac from the back end.
        if ("default_physician_authorization" in attributesToUpdate) {
          attributesToUpdate["order_terminology"] = attributesToUpdate[
            "default_physician_authorization"
          ]
            ? ORDER_TERMINOLOGY.CART
            : ORDER_TERMINOLOGY.ORDER
          attributesToUpdate["use_physician_services_terminology"] =
            attributesToUpdate["default_physician_authorization"]
        }

        dispatch({
          type: UPDATE_PERSONAL_SETTINGS,
          payload: {
            ...attributesToUpdate,
          },
        })
      })
      .catch((error) => dispatch(handleApiError(error)))
  }
}

export function updatePractitionerDontHandleErrors(
  update,
  notifySuccess = false,
  successMessage = "Update successful"
) {
  return (dispatch, getState) => {
    return executeUpdatePractitionerRequest(
      update,
      dispatch,
      getState(),
      notifySuccess,
      successMessage
    )
  }
}

export function updatePractitioner(
  update,
  notifySuccess = false,
  successMessage = "Update successful"
) {
  return (dispatch, getState) => {
    return executeUpdatePractitionerRequest(
      update,
      dispatch,
      getState(),
      notifySuccess,
      successMessage
    ).catch((error) => dispatch(handleApiError(error)))
  }
}

function executeUpdatePractitionerRequest(
  update,
  dispatch,
  state,
  notifySuccess = false,
  successMessage = "Update successful"
) {
  const practitionerUpdate = { ...state.practitioner, ...update }

  const request = axios.put(
    getApiBaseUrl() + `/api/practitioner/${practitionerUpdate.id}`,
    {
      ...practitionerUpdate,
      favorite_bundles: [
        ...practitionerUpdate.favorite_bundles.map((f) => f.id),
      ],
      favorite_lab_tests: practitionerUpdate.favorite_lab_test_ids,
      primary_practitioner_type: practitionerUpdate.primary_practitioner_type
        ? practitionerUpdate.primary_practitioner_type.id
        : null,
      other_practitioner_type: [
        ...practitionerUpdate.other_practitioner_type.map((f) => f.id),
      ],
    }
  )

  return request.then((response) => {
    // the update method of the practitioner serializer does not return the full, serialized
    // version of the practitioner. So we need to iterate through the existing practitioner in memory
    // and update the attributes that are different from the server response.
    for (const [key, value] of Object.entries(response.data)) {
      if (
        practitionerUpdate.hasOwnProperty(key) &&
        practitionerUpdate[key] !== value &&
        // skip "favorites" as the PUT returns the set of IDs rather than the serialized objects
        key !== "favorite_lab_tests" &&
        key !== "favorite_bundles" &&
        key !== "primary_practitioner_type"
      ) {
        practitionerUpdate[key] = value
      }
    }

    if (notifySuccess) {
      dispatch(handleApiSuccess(successMessage))
    }
    dispatch({
      type: GET_PRACTITIONER,
      payload: {
        ...practitionerUpdate,
        dashboard_cards: response.data.dashboard_cards,
        npi_number: response.data.npi_number,
      },
    })
  })
}

export function addFavoriteTest(practitioner, lab_test) {
  let favoriteLabTests = practitioner?.favorite_lab_test_ids?.length
    ? [...practitioner.favorite_lab_test_ids, lab_test.id]
    : [lab_test.id]

  const request = axios.patch(
    getApiBaseUrl() + `/api/practitioner/${practitioner.id}`,
    {
      favorite_lab_tests: favoriteLabTests,
    }
  )

  return (dispatch) =>
    request
      .then((response) =>
        dispatch({
          type: ADD_FAVORITE_TEST,
          payload: lab_test,
        })
      )
      .catch((error) => dispatch(handleApiError(error)))
}

export function removeFavoriteTest(practitioner, lab_test) {
  const request = axios.patch(
    getApiBaseUrl() + `/api/practitioner/${practitioner.id}`,
    {
      favorite_lab_tests: _.without(
        practitioner.favorite_lab_test_ids,
        lab_test.id
      ),
    }
  )

  return (dispatch) =>
    request
      .then((response) =>
        dispatch({
          type: REMOVE_FAVORITE_TEST,
          payload: lab_test,
        })
      )
      .catch((error) => dispatch(handleApiError(error)))
}

export function toggleFavoriteTest(labTest) {
  return async (dispatch, getState) => {
    const practitioner = getState().practitioner
    if (!practitioner) {
      return
    }

    if (practitioner.favorite_lab_test_id_set?.has(labTest.id)) {
      return await dispatch(removeFavoriteTest(practitioner, labTest))
    } else {
      return await dispatch(addFavoriteTest(practitioner, labTest))
    }
  }
}

/**
 * Used in OverrideAccessModal.tsx to set the state of the practitioner after the
 * register lab company endpoint call.
 *
 * @param {Practitioner} practitioner Practitioner data to update
 */
export function updatePractitionerState(practitioner) {
  return (dispatch, getState) => {
    dispatch({
      type: GET_PRACTITIONER,
      payload: practitioner,
    })
  }
}

export function addFavoriteBundle(practitioner, bundle) {
  return async (dispatch) => {
    const favoriteBundles = practitioner?.favorite_bundles?.length
      ? [...practitioner.favorite_bundles.map((f) => f.id), bundle.id]
      : [bundle.id]

    try {
      await axios.patch(
        getApiBaseUrl() + `/api/practitioner/${practitioner.id}`,
        {
          favorite_bundles: favoriteBundles,
        }
      )

      dispatch({
        type: ADD_FAVORITE_BUNDLE,
        payload: bundle,
      })
      await dispatch(fetchPractitionerBundles())
    } catch (error) {
      dispatch(handleApiError(error))
    }
  }
}

export function removeFavoriteBundle(practitioner, bundle) {
  return async (dispatch) => {
    try {
      await axios.patch(
        getApiBaseUrl() + `/api/practitioner/${practitioner.id}`,
        {
          favorite_bundles: _.without(
            practitioner.favorite_bundles.map((f) => f.id),
            bundle.id
          ),
        }
      )

      dispatch({
        type: REMOVE_FAVORITE_BUNDLE,
        payload: bundle,
      })
      await dispatch(fetchPractitionerBundles())
    } catch (error) {
      dispatch(handleApiError(error))
    }
  }
}
