import { makeStyles, styled } from "@material-ui/core"

import Markdown from "app/components/Markdown"
import BodyText from "app/components/design-system/BodyText"
import PatientPortalBanner from "app/patient-portal/order/components/PatientPortalBanner"
import PatientPortalFastingBanner from "app/patient-portal/order/components/PatientPortalFastingBanner"
import { colors, maroon, navy, textPrimaryColor } from "app/theme"
import { PatientInstructionScheduleBloodDraw } from "app/types"
import { getRequisitionLabCompanyName } from "app/utils/order-status-utils"

import ScheduleMobileBloodDraw from "../ScheduleMobileBloodDraw"
import ClinicRecommendation from "./ClinicRecommendation"
import LabCompanyRecommendation from "./LabCompanyRecommendation"
import ScheduleEmpireDetails from "./ScheduleEmpireDetails"
import { ScheduleLabcorpDetails } from "./ScheduleLabcorpDetails"
import { ScheduleQuestDetails } from "./ScheduleQuestDetails"

interface Props {
  patientInstruction: PatientInstructionScheduleBloodDraw
}

const useStyles = makeStyles((theme) => ({
  markdownMessage: {
    color: navy,
    fontWeight: 600,
    paddingInlineStart: 0,
  },
  fastingCopy: {
    color: maroon,
    fontWeight: 600,
    paddingBottom: 2,
    paddingRight: 30,
  },
  fastingContainer: {
    marginBottom: 25,
  },
  headerDivider: {
    marginBottom: 20,
  },
}))

const Text = styled(BodyText)({
  borderTop: `1px solid ${colors.blueGray[200]}`,
  paddingTop: 22,
  marginTop: 22,
})

const Footer = styled(BodyText)({
  paddingTop: 22,
  "& a": {
    color: textPrimaryColor,
    textDecoration: "underline !important",
    fontWeight: 600,
  },
})

const ScheduleBloodDraw = ({ patientInstruction }: Props) => {
  const classes = useStyles()

  const requiresFasting = Boolean(patientInstruction.requires_fasting)

  const companiesToWarnAboutWaitingForRequisition = [
    patientInstruction.labcorp_scheduling_data,
    patientInstruction.quest_scheduling_data,
    patientInstruction.empire_scheduling_data,
  ].reduce((acc, sd) => {
    const labCompanyKey = sd?.selected_order_fulfillment_company
    if (labCompanyKey && !sd?.requisition_form_url) {
      return [...acc, getRequisitionLabCompanyName(labCompanyKey)]
    }
    return acc
  }, [] as string[])

  const showHeader =
    Boolean(patientInstruction?.intro_title) ||
    Boolean(patientInstruction?.intro_copy) ||
    patientInstruction.rupa_recommended_phlebotomists.length > 0 ||
    patientInstruction.clinic_recommended_phlebotomists.length > 0 ||
    Boolean(patientInstruction.post_custom_recommendations_copy) ||
    patientInstruction.lab_company_recommended_phlebotomists.length > 0 ||
    Boolean(patientInstruction.quest_scheduling_data)

  return (
    <div>
      <div className={classes.fastingContainer}>
        <PatientPortalFastingBanner
          fastingDurationHours={patientInstruction.fasting_duration}
          requiresFasting={requiresFasting}
        />
      </div>
      {companiesToWarnAboutWaitingForRequisition.length > 0 && (
        <PatientPortalBanner
          icon={null}
          borderColor={colors.blueGray[50]}
          backgroundColor={colors.blueGray[50]}
          iconBackgroundColor={colors.blueGray[100]}
          withBackground={true}
          containerClass="mb-4"
        >
          <BodyText
            size="sm"
            weight="semibold"
            style={{ color: colors.lightBlue[900] }}
          >
            Wait for an email from Rupa with a link to schedule your draw & your
            requisition form before going to a{" "}
            {companiesToWarnAboutWaitingForRequisition.join(" or ")} location
          </BodyText>
        </PatientPortalBanner>
      )}
      {patientInstruction.axle_scheduling_data ? (
        <ScheduleMobileBloodDraw
          axleSchedulingData={patientInstruction.axle_scheduling_data}
        />
      ) : (
        <ScheduleBloodDrawDefault patientInstruction={patientInstruction} />
      )}

      {patientInstruction.labcorp_scheduling_data && (
        <ScheduleLabcorpDetails
          requiresFasting={requiresFasting}
          data={patientInstruction.labcorp_scheduling_data}
          showHeader={showHeader}
        />
      )}
      {patientInstruction.quest_scheduling_data && (
        <ScheduleQuestDetails
          data={patientInstruction.quest_scheduling_data}
          showHeader={showHeader}
        />
      )}
      {patientInstruction.empire_scheduling_data && (
        <ScheduleEmpireDetails
          data={patientInstruction.empire_scheduling_data}
          showHeader={showHeader}
        />
      )}
      {patientInstruction.end_copy &&
        !patientInstruction?.axle_scheduling_data && (
          <Text weight="semibold">
            <Markdown className={classes.markdownMessage}>
              {patientInstruction.end_copy}
            </Markdown>
          </Text>
        )}
      {patientInstruction.footer_copy &&
        !patientInstruction?.axle_scheduling_data && (
          <Footer size="xs">
            <Markdown className={classes.markdownMessage}>
              {patientInstruction.footer_copy}
            </Markdown>
          </Footer>
        )}
    </div>
  )
}

export default ScheduleBloodDraw

const ScheduleBloodDrawDefault = ({ patientInstruction }: Props) => {
  const classes = useStyles()

  return (
    <div>
      {patientInstruction?.intro_title && (
        <BodyText weight="semibold" size="md">
          {patientInstruction.intro_title}
        </BodyText>
      )}

      {patientInstruction?.intro_copy && (
        <BodyText weight="semibold">
          <Markdown className={classes.markdownMessage}>
            {patientInstruction.intro_copy}
          </Markdown>
        </BodyText>
      )}

      {patientInstruction.rupa_recommended_phlebotomists.map(
        (clinicRecommendedPhlebotomist, index) => (
          <ClinicRecommendation
            key={clinicRecommendedPhlebotomist.name}
            clinicRecommendation={clinicRecommendedPhlebotomist}
            title={
              index === 0
                ? patientInstruction.rupa_recommended_phlebotomists_title
                : ""
            }
          />
        )
      )}
      {patientInstruction.clinic_recommended_phlebotomists.map(
        (clinicRecommendedPhlebotomist, index) => (
          <ClinicRecommendation
            key={clinicRecommendedPhlebotomist.name}
            clinicRecommendation={clinicRecommendedPhlebotomist}
            title={
              index === 0
                ? patientInstruction.clinic_recommended_phlebotomists_title
                : ""
            }
          />
        )
      )}
      {patientInstruction.post_custom_recommendations_copy && (
        <Text weight="semibold">
          {patientInstruction.post_custom_recommendations_copy}
        </Text>
      )}
      {patientInstruction.lab_company_recommended_phlebotomists.map(
        (labCompanyRecommendedPhlebotomist) => (
          <LabCompanyRecommendation
            key={labCompanyRecommendedPhlebotomist.title}
            labCompanyRecommendation={labCompanyRecommendedPhlebotomist}
          />
        )
      )}
    </div>
  )
}
