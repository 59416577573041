import { navy } from "app/theme"
import { PHLEBOTOMY_PROVIDERS, PhlebotomyProviderName } from "app/types"
import makeAppStyles from "app/utils/makeAppStyles"

import { SelectProvider } from "./SelectProvider"
import {
  FILLER_BODY_TEXT,
  LABCORP_BODY_TEXT,
  QUEST_BODY_TEXT,
} from "./constants"

const useStyles = makeAppStyles((theme) => ({
  body: {
    marginTop: 8,
    color: navy,
    fontFamily: "Open Sans",
    fontSize: "15px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "135%",
  },
  semiBold: {
    fontWeight: 600,
  },
  selectOption: {
    marginTop: 8,
    marginBottom: 16,
  },
}))

function numberToWord(number) {
  const words = [
    "zero",
    "one",
    "two",
    "three",
    "four",
    "five",
    "six",
    "seven",
    "eight",
    "nine",
  ]
  return words[number]
}

interface BloodDrawCombinedProps {
  zipCode: string | null
  phlebProviders: PhlebotomyProviderName[]
  labCompanyNamesBloodDrawNotIncluded: string[]
  requiredNumberOfPhlebotomyProviders: number
  availableProviders: PhlebotomyProviderName[]
  onBloodDrawModified: () => void
  onBloodDrawConfirmed: () => void
  isPractitionerPay: boolean
}

export const CombinedBloodDraw = ({
  zipCode,
  phlebProviders,
  labCompanyNamesBloodDrawNotIncluded,
  requiredNumberOfPhlebotomyProviders: numProviders,
  availableProviders,
  onBloodDrawModified,
  onBloodDrawConfirmed,
  isPractitionerPay,
}: BloodDrawCombinedProps) => {
  const classes = useStyles()

  return (
    <div className={classes.body}>
      <div>
        {`This order requires ${numberToWord(numProviders)} ${
          numProviders > 1 ? "separate blood draws" : "blood draw"
        }.`}
      </div>
      {phlebProviders.includes(PHLEBOTOMY_PROVIDERS.LABCORP) && (
        <div>
          <br />
          <div className={classes.semiBold}>Blood Draw at Labcorp</div>
          <div>
            {isPractitionerPay
              ? LABCORP_BODY_TEXT.isPractitionerPay
              : LABCORP_BODY_TEXT.nonPractitionerPay}
          </div>
        </div>
      )}
      {phlebProviders.includes(PHLEBOTOMY_PROVIDERS.QUEST) && (
        <div>
          <br />
          <div className={classes.semiBold}>Blood Draw at Quest</div>
          <div>{QUEST_BODY_TEXT}</div>
        </div>
      )}
      {labCompanyNamesBloodDrawNotIncluded.length > 0 && (
        <div>
          <br />
          <div className={classes.semiBold}>
            {labCompanyNamesBloodDrawNotIncluded.join(", ")} Blood Draw
          </div>
          {!zipCode && <div>{FILLER_BODY_TEXT}</div>}
          {zipCode && !(availableProviders.length > 1) && (
            <div>
              Complete your other tests at a separate blood draw location. We'll
              email you instructions on how to find a location. There will be a
              separate fee, paid directly to the Phlebotomist (typically between
              $30-100).
            </div>
          )}
          {zipCode && availableProviders.length > 1 && (
            <div>
              <div className={classes.selectOption}>
                Select an option below:
              </div>
              <SelectProvider
                phlebProviders={phlebProviders}
                availableProviders={availableProviders}
                onFormIsModified={onBloodDrawModified}
                onFormIsConfirmed={onBloodDrawConfirmed}
              />
            </div>
          )}
        </div>
      )}
    </div>
  )
}
