import { CellContext } from "@tanstack/react-table"

import { ORDER_STATUS } from "app/constants.typed"
import makeAppStyles from "app/utils/makeAppStyles"
import { DashboardOrder } from "types/dashboard/dashboard-order"

import columnHelper from "../column-helper"
import DetailsButton from "./DetailsButton"
import ESignatureRequiredButton from "./ESignatureRequiredButton"
import EditDraftButton from "./EditDraftButton"
import VerifyAccountButton from "./VerifyAccountButton"

const useStyles = makeAppStyles(() => ({
  buttonRoot: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },
  button: {
    width: 140,
    whiteSpace: "nowrap",
  },
}))

function renderActionButton(
  props: CellContext<DashboardOrder, unknown> & { className: string }
) {
  switch (props.row.original.attributes.cached_status) {
    case ORDER_STATUS.DRAFT:
      return <EditDraftButton {...props} />
    default:
      return <DetailsButton {...props} />
    case ORDER_STATUS.BLOCKED_MISSING_SIGNATURE:
      return <ESignatureRequiredButton {...props} />
    case ORDER_STATUS.BLOCKED_PRACTITIONER_ACCOUNT_UNVERIFIED:
      return <VerifyAccountButton {...props} />
  }
}

const ActionsCell = (props: CellContext<DashboardOrder, unknown>) => {
  const classes = useStyles()
  return (
    <div className={classes.buttonRoot}>
      {renderActionButton({
        ...props,
        className: classes.button,
      })}
    </div>
  )
}

const actionsColumn = columnHelper.display({
  id: "actions",
  cell: (info) => <ActionsCell {...info} />,
  header: () => null,
  size: 220,
  maxSize: 220,
  minSize: 220,
  meta: {
    stickyOnRowHover: "right",
  },
})

export default actionsColumn
