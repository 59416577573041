import { FC } from "react"

import useAppSelector from "app/hooks/useAppSelector"
import { isProduction } from "app/settings"

const UniversityLiveClass: FC = () => {
  const userID = useAppSelector(({ auth }) =>
    isProduction ? encodeURIComponent(String(auth.user.id)) : ""
  )

  return (
    <iframe
      title="Rupa University Live Class"
      src={`https://embed.rupahealth.com/university/live?user_id=${userID}`}
      allowFullScreen
      style={{
        overflow: "hidden",
        height: "100%",
        width: "100%",
        border: "none",
      }}
    />
  )
}

export default UniversityLiveClass
