class UserPreferenceService {
  SHOW_CATALOG_GETTING_STARTED_BANNER = "showCatalogNavGettingStartedBanner"
  BLOOD_DRAW_MODAL_IS_ENABLED = "bloodDrawModalIsEnabled"

  showCatalogGettingStartedBanner = (): Boolean => {
    const localStorageItem = localStorage.getItem(
      this.SHOW_CATALOG_GETTING_STARTED_BANNER
    )
    return localStorageItem === "true" || localStorageItem === null
  }

  bloodDrawModalEnabled = (): Boolean => {
    const localStorageItem = localStorage.getItem(
      this.BLOOD_DRAW_MODAL_IS_ENABLED
    )
    return localStorageItem === "true" || localStorageItem === null
  }

  setShowCatalogGettingStartedBanner = (showBanner: string): void => {
    localStorage.setItem(this.SHOW_CATALOG_GETTING_STARTED_BANNER, showBanner)
  }

  setBloodDrawModalEnabled = (isEnabled: string): void => {
    localStorage.setItem(this.BLOOD_DRAW_MODAL_IS_ENABLED, isEnabled)
  }
}

const instance = new UserPreferenceService()
export default instance
