import { useCallback, useEffect, useState } from "react"

import clsx from "clsx"
import { HotKeys } from "react-hotkeys"

import { Divider } from "@material-ui/core"
import ChevronRightIcon from "@material-ui/icons/ChevronRight"
import PersonAddOutlinedIcon from "@material-ui/icons/PersonAddOutlined"

import FoodPlanCard from "app/food-plans/components/FoodPlanCard"
import FoodPlanNewPatientModal from "app/food-plans/components/FoodPlanClientSelectionCard/FoodPlanNewPatientModal"
import FoodPlanPatientList from "app/food-plans/components/FoodPlanClientSelectionCard/FoodPlanPatientList"
import FoodPlanPatientSearchInput from "app/food-plans/components/FoodPlanClientSelectionCard/FoodPlanPatientSearchInput"
import useStartFoodPlan from "app/food-plans/hooks/use-start-food-plan"
import useCollectionSWR from "app/swr/hooks/use-collection-swr"

export default function FoodPlanClientSelectionCard() {
  const [searchText, setSearchText] = useState("")
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [isNewPatientModalOpen, setIsNewPatientModalOpen] = useState(false)

  const startFoodPlan = useStartFoodPlan()

  const { data: matches, isValidating } = useCollectionSWR(
    // Only query when we have search text
    searchText ? "/patients" : null,
    {
      include: ["user"],
      params: {
        "filter[search]": searchText,
        "page[limit]": "50",
      },
    },
    {
      keepPreviousData: true,
      revalidateIfStale: true,
      revalidateOnMount: true,
    }
  )

  useEffect(() => {
    // Reset selected index anytime the search text changes
    setSelectedIndex(0)
  }, [searchText])

  function openNewPatientModal() {
    setIsNewPatientModalOpen(true)
  }

  async function startFoodPlanForSelected() {
    const selected = matches[selectedIndex]
    if (selected) {
      await startFoodPlan(selected.id)
    } else {
      openNewPatientModal()
    }
  }

  const moveDown = useCallback(() => {
    if (selectedIndex < matches.length) {
      setSelectedIndex(selectedIndex + 1)
    }
  }, [selectedIndex, matches.length])

  const moveUp = useCallback(() => {
    if (selectedIndex > 0) {
      setSelectedIndex(selectedIndex - 1)
    }
  }, [selectedIndex])

  const handlers = {
    SELECT_PATIENT: startFoodPlanForSelected,
    ADD_PATIENT: openNewPatientModal,
    MOVE_UP: moveUp,
    MOVE_DOWN: moveDown,
  }

  const keyMap = {
    SELECT_PATIENT: "enter",
    ADD_PATIENT: "ctrl+n",
    MOVE_UP: "up",
    MOVE_DOWN: "down",
  }

  const handleKeyDown = (e) => {
    // Up arrow
    if (e.keyCode === 38) {
      moveUp()
      // Down Arrow
    } else if (e.keyCode === 40) {
      moveDown()
      // Enter Key
    } else if (e.keyCode === 13) {
      startFoodPlanForSelected()
      // Ctrl+n
    } else if (e.ctrlKey && e.keyCode === 78) {
      openNewPatientModal()
    }
  }

  return (
    <HotKeys handlers={handlers} keyMap={keyMap}>
      <FoodPlanCard>
        <FoodPlanPatientSearchInput
          isValidating={isValidating}
          onKeyDown={handleKeyDown}
          onSearchTextChange={setSearchText}
          searchText={searchText}
        />

        {matches && matches.length > 0 && (
          <FoodPlanPatientList
            matches={matches}
            onSelectPatient={startFoodPlan}
            searchText={searchText}
            selectedIndex={selectedIndex}
            setSelectedIndex={setSelectedIndex}
          />
        )}

        {searchText && (
          <>
            <Divider />
            <div
              className={clsx(
                "py-2 pl-7",
                (selectedIndex === matches.length ||
                  (searchText && matches.length === 0)) &&
                  "bg-blue-50"
              )}
            >
              <div
                className="cursor-pointer text-grey-800 font-semibold text-sm flex items-center fs-exclude"
                onClick={openNewPatientModal}
                role="button"
                tabIndex={0}
              >
                <PersonAddOutlinedIcon className="mr-2 text-xl" />
                Add {searchText} as a New Client
                <ChevronRightIcon />
              </div>
            </div>
          </>
        )}
      </FoodPlanCard>

      {isNewPatientModalOpen && (
        <FoodPlanNewPatientModal
          initialName={searchText}
          open={isNewPatientModalOpen}
          onClose={() => setIsNewPatientModalOpen(false)}
          onSubmit={(patientId: string) => {
            setIsNewPatientModalOpen(false)

            startFoodPlan(patientId)
          }}
        />
      )}
    </HotKeys>
  )
}
