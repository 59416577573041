import _ from "lodash"

import * as Actions from "../actions"

export function withComputedPractitionerProps(practitioner) {
  /*
   * Decorates a practitioner with dynamically computed properties. These
   * properties are helper props that make data access easier. Here
   * we're creating maps to make it quicker to determine if the
   * practitioner has a given favorite test.
   */
  return {
    ...practitioner,
    favorite_lab_test_id_set: new Set(practitioner.favorite_lab_test_ids),
  }
}

// TODO: The initial state should not be an empty object
// before it's fetched. It makes code prone to error if takes
// the reasonable assumption that it'll be undefined before
// fetched.
const practitionerReducer = function (state = {}, action) {
  switch (action.type) {
    case Actions.GET_PRACTITIONER: {
      return withComputedPractitionerProps(action.payload)
    }
    case Actions.ADD_FAVORITE_TEST: {
      return withComputedPractitionerProps({
        ...state,
        favorite_lab_test_ids: [
          ...state.favorite_lab_test_ids,
          action.payload.id,
        ],
      })
    }
    case Actions.REMOVE_FAVORITE_TEST: {
      return withComputedPractitionerProps({
        ...state,
        favorite_lab_test_ids: _.without(
          state.favorite_lab_test_ids,
          action.payload.id
        ),
      })
    }
    case Actions.ADD_FAVORITE_BUNDLE: {
      return withComputedPractitionerProps({
        ...state,
        favorite_bundles: [...state.favorite_bundles, action.payload],
      })
    }
    case Actions.REMOVE_FAVORITE_BUNDLE: {
      return withComputedPractitionerProps({
        ...state,
        favorite_bundles: _.reject(state.favorite_bundles, {
          id: action.payload.id,
        }),
      })
    }
    case Actions.UPDATE_PERSONAL_SETTINGS: {
      return withComputedPractitionerProps({
        ...state,
        // use nullish coalescing operator because `action.payload` will only include one attribute at a time, but we do not know which one.
        allow_in_office_kits:
          action.payload.allow_in_office_kits ?? state.allow_in_office_kits,
        patient_results_release_automatically:
          action.payload.patient_results_release_automatically ??
          state.patient_results_release_automatically,
        default_physician_authorization:
          action.payload.default_physician_authorization ??
          state.default_physician_authorization,
        vendor_physician_authorization_enabled:
          action.payload.vendor_physician_authorization_enabled ??
          state.vendor_physician_authorization_enabled,
        order_terminology:
          action.payload.order_terminology ?? state.order_terminology,
        use_physician_services_terminology:
          action.payload.use_physician_services_terminology ??
          state.use_physician_services_terminology,
        physician_services_enable_method:
          action.payload.physician_services_enable_method ??
          state.physician_services_enable_method,
      })
    }
    default: {
      return state
    }
  }
}

export default practitionerReducer
