import { Control, useController } from "react-hook-form"

import RichTextEditor from "app/components/RichTextEditor"

import FieldDecoration, { FieldDecorationProps } from "./FieldDecoration"

export interface RichTextEditorFieldProps
  extends Omit<FieldDecorationProps, "error"> {
  control: Control<any>
  disabled?: boolean
  name: string
  placeholder?: string
  readOnly?: boolean
}

export default function RichTextEditorField({
  control,
  disabled,
  label,
  labelIcon,
  name,
  placeholder,
  readOnly,
  required,
}: RichTextEditorFieldProps) {
  const {
    field,
    fieldState: { error },
  } = useController({
    control,
    name,
    shouldUnregister: true,
  })
  return (
    <FieldDecoration
      error={error}
      label={label}
      labelIcon={labelIcon}
      required={required}
    >
      <RichTextEditor
        aria-invalid={error ? "true" : "false"}
        disabled={disabled}
        onBlur={field.onBlur}
        onChange={field.onChange}
        placeholder={placeholder}
        readOnly={readOnly}
        value={field.value}
      />
    </FieldDecoration>
  )
}
