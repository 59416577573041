import { CircularProgress, Typography } from "@material-ui/core"

import { ReactComponent as DownloadIcon } from "app/assets/icons/download-icon.svg"
import { ReactComponent as PersonCheckIcon } from "app/assets/icons/person-check.svg"
import { ReactComponent as FoodPlanIntroImage } from "app/assets/images/food-plan-intro.svg"
import Button from "app/components/design-system/Button"
import useDownloadFoodPlan from "app/food-plans/hooks/use-download-food-plan"
import useAppSelector from "app/hooks/useAppSelector"
import { colors, textPrimaryColor } from "app/theme"
import formatDateForHuman from "app/utils/format-date-for-human"
import makeAppStyles from "app/utils/makeAppStyles"
import { FoodPlanVersion } from "types/food-plan-version"

const useStyles = makeAppStyles((theme) => ({
  root: {
    width: "100%",
    padding: theme.spacing(3.0, 2.0, 1.5),
    display: "flex",
    flexFlow: "column nowrap",
    gap: theme.spacing(3.0),
  },
  heading: {
    width: "100%",
    display: "flex",
    flexFlow: "row wrap",
    gap: theme.spacing(2.0),
  },
  labels: {
    flex: "1 1 auto",
    display: "flex",
    flexFlow: "row nowrap",
    gap: theme.spacing(2.0),
  },
  labelFoodPlan: {
    fontSize: 15,
    fontWeight: 600,
    marginBottom: -2,
    color: textPrimaryColor,
    lineHeight: 1.35,
  },
  labelEditedDate: {
    fontSize: 14,
    fontWeight: 400,
    color: textPrimaryColor,
    lineHeight: 1.35,
  },
  actions: {
    flex: "0 1 auto",
    display: "flex",
    flexFlow: "row wrap",
    justifyContent: "flex-end",
    alignItems: "center",
    gap: theme.spacing(1.0),
  },
  disclaimer: {
    width: "100%",
    display: "inline-flex",
    alignItems: "center",
    gap: theme.spacing(1.5),
    padding: theme.spacing(1.0, 2.0),
    backgroundColor: colors.blue[50],
    borderRadius: 6,
    color: colors.blueGray[500],
    fontSize: 14,
  },
}))

export interface FoodPlanEditorCardHeaderProps {
  downloadDisabled?: boolean
  foodPlanVersion: FoodPlanVersion
  saveDisabled?: boolean
}

export default function FoodPlanEditorCardHeader({
  downloadDisabled,
  foodPlanVersion,
  saveDisabled,
}: FoodPlanEditorCardHeaderProps) {
  const classes = useStyles()

  const legacyPatient = useAppSelector(({ patient }) => patient.patient)
  const { downloadFoodPlan, isDownloadingFoodPlan } = useDownloadFoodPlan()

  return (
    <div className={classes.root}>
      <div className={classes.heading}>
        <div className={classes.labels}>
          <FoodPlanIntroImage height={40} width={40} viewBox="0 0 66 66" />

          <div>
            <Typography className={classes.labelFoodPlan} variant="h6">
              {legacyPatient.first_name}'s Food Plan
            </Typography>
            <Typography
              className={classes.labelEditedDate}
              component="span"
              variant="body1"
            >
              Edited:{" "}
              {formatDateForHuman(
                foodPlanVersion.attributes.updated_at ||
                  foodPlanVersion.attributes.created_at
              )}
            </Typography>
          </div>
        </div>

        <div className={classes.actions}>
          <Button
            aria-label="Download PDF"
            color="secondary"
            disabled={downloadDisabled || isDownloadingFoodPlan}
            startIcon={
              isDownloadingFoodPlan ? (
                <CircularProgress color="inherit" size={15} />
              ) : (
                <DownloadIcon fill="currentColor" viewBox="0 0 13 16" />
              )
            }
            onClick={() =>
              downloadFoodPlan(foodPlanVersion.relationships.food_plan.data.id)
            }
            type="button"
          >
            Download PDF
          </Button>

          <Button
            aria-label="Save & Close"
            color="secondary"
            disabled={saveDisabled}
            type="submit"
          >
            Save & Close
          </Button>
        </div>
      </div>

      <div className={classes.disclaimer}>
        <PersonCheckIcon
          fill="currentColor"
          height={16}
          width={16}
          viewBox="0 0 17 13"
        />
        {
          " Please take a moment to review the draft and make any necessary adjustments."
        }
      </div>
    </div>
  )
}
