import { isEmpty } from "lodash"
import { FormProvider } from "react-hook-form"

import NiceModal, { muiDialog, useModal } from "@ebay/nice-modal-react"
import { CircularProgress, makeStyles } from "@material-ui/core"
import CloseIcon from "@material-ui/icons/Close"
import { Button } from "@rupahealth/design"

import BodyText from "app/components/design-system/BodyText"
import DisplayText from "app/components/design-system/DisplayText"
import { Dialog } from "app/components/modals"

import TextField from "../../RupaBloodDashboards/modals/TextField"
import useSnippetForm from "../hooks/use-snippet-form"
import RichTextEditorField from "./RichTextEditorField"

interface SnippetFormModalProps {
  snippet?: any
  title: string
  isSaveLoading: boolean
  isDeleteLoading?: boolean
  useRichTextEditor: boolean
  onClose: () => void
  handleDelete?: (id: number) => Promise<void>
  handleSave: (data: any, id?: number) => Promise<void>
}

const styles = () => ({
  dialogPaper: {
    margin: 15,
    borderRadius: 12,
  },
})

const useStyles = makeStyles(styles)

const SnippetFormModal = ({
  snippet,
  title,
  isSaveLoading,
  isDeleteLoading,
  useRichTextEditor,
  onClose,
  handleDelete,
  handleSave,
}: SnippetFormModalProps) => {
  const classes = useStyles(styles)

  const modal = useModal()
  const muiDialogControl = muiDialog(modal)

  const methods = useSnippetForm({
    title: snippet?.title,
    text: snippet?.text,
    rich_text: snippet?.rich_text,
    useRichText: useRichTextEditor,
  })

  console.log(methods.formState.errors)

  return (
    <>
      <Dialog
        {...muiDialogControl}
        disableEnforceFocus
        disableEscapeKeyDown
        fullWidth
        maxWidth="sm"
        className={"fs-unmask"}
        classes={{
          paper: classes.dialogPaper,
        }}
      >
        <TitleSection title={title} onClose={onClose} />
        <div className=" bg-slate-100">
          <FormProvider {...methods}>
            <form>
              <div className="flex flex-col p-[22.5px] gap-5 items-stretch w-full">
                <div className="flex flex-col gap-1">
                  <BodyText weight="semibold" size="sm">
                    Snippet Title <span className="text-destructive">*</span>
                  </BodyText>
                  <BodyText size="sm">
                    A title helps you keep track of your snippet. It's not shown
                    to your client.
                  </BodyText>
                  <TextField
                    control={methods.control}
                    label="Snippet Title"
                    name="title"
                  />
                  {methods.formState.errors?.title && (
                    <BodyText size="xs" className="text-destructive">
                      {methods.formState.errors.title.message}
                    </BodyText>
                  )}
                </div>

                <div className="flex flex-col gap-[6px]">
                  <BodyText weight="semibold" size="sm">
                    Snippet Content <span className="text-destructive">*</span>
                  </BodyText>
                  {useRichTextEditor ? (
                    <RichTextEditorField
                      control={methods.control}
                      name="rich_text"
                      required
                    />
                  ) : (
                    <TextField
                      control={methods.control}
                      label="Snippet Content"
                      name="text"
                      variant="textarea"
                      required
                    />
                  )}
                  {methods.formState.errors?.text && (
                    <BodyText size="xs" className="text-destructive">
                      {methods.formState.errors.text.message}
                    </BodyText>
                  )}
                  {methods.formState.errors?.rich_text && (
                    <BodyText size="xs" className="text-destructive">
                      {methods.formState.errors.rich_text.message}
                    </BodyText>
                  )}
                </div>
              </div>
            </form>
          </FormProvider>
        </div>
        <div className="m-0 px-5 py-4 flex justify-stretch sm:justify-end gap-[5.5px]">
          {handleDelete && (
            <Button
              className="w-full sm:w-auto"
              variant="outline-destructive"
              onClick={() => handleDelete(snippet?.id)}
              disabled={isDeleteLoading || isSaveLoading}
            >
              Delete Snippet{" "}
              {isDeleteLoading && (
                <CircularProgress size={15} className="text-destructive ml-2" />
              )}
            </Button>
          )}
          <Button
            variant="primary"
            className="w-full sm:w-auto"
            onClick={() => handleSave(methods.getValues(), snippet?.id)}
            disabled={
              isSaveLoading ||
              isDeleteLoading ||
              !isEmpty(methods.formState.errors)
            }
          >
            Save Snippet{" "}
            {isSaveLoading && (
              <CircularProgress size={15} className="text-white ml-2" />
            )}
          </Button>
        </div>
      </Dialog>
    </>
  )
}

const TitleSection = ({ title, onClose }) => {
  const closeButton = onClose && (
    <div
      className="text-body absolute sm:relative top-[10px] right-5 sm:right-0 mr-[-12px] cursor-pointer"
      aria-label="close"
      onClick={onClose}
      key="close-button"
    >
      <CloseIcon />
    </div>
  )

  return (
    <div className="flex flex-row justify-between flex-wrap sm:flex-nowrap bg-white border-b border-slate-200 items-center py-4 px-6">
      <div className="flex flex-col flex-wrap mr-6">
        <DisplayText weight="semibold" size="lg">
          {title}
        </DisplayText>
      </div>
      {closeButton}
    </div>
  )
}

const SnippetFormNiceModal =
  NiceModal.create<SnippetFormModalProps>(SnippetFormModal)

export default SnippetFormNiceModal
