import axios from "axios"
import useSWR from "swr"

import { getApiBaseUrl } from "app/utils"

import { OnboardingTask } from "../types"

interface OnboardingResponse {
  tasks: OnboardingTask[]
  show_onboarding: boolean
}

export default function useOnboardingSWR() {
  return useSWR("/api/onboarding/", async (url) => {
    const response = await axios.get<OnboardingResponse>(getApiBaseUrl() + url)
    return response.data
  })
}
