import { LabTestBundleType } from "app/types"

export function getBundleTypeLabel(bundle_type: LabTestBundleType) {
  switch (bundle_type) {
    case LabTestBundleType.PANEL:
      return "Blood Panel"
    case LabTestBundleType.BASIC:
    default:
      return "Bundle"
  }
}
