import { useEffect, useRef, useState } from "react"

import clsx from "clsx"

import { TextField, OutlinedTextFieldProps } from "@material-ui/core"

import useEventCallback from "app/hooks/use-event-callback"
import useInputStyles from "app/main/patient-checkout/fields/use-input-styles"

export type MaskedDateInputProps = Omit<
  OutlinedTextFieldProps,
  "onChange" | "type" | "variant"
> & {
  onChange: (formattedDate: string) => void
}

/**
 * Regex used as a mask for matching "MM/dd/yyyy"
 */
const DATE_MASK_REGEX = /(\d{0,2})(\d{0,2})(\d{0,4})/

/**
 * Masks the date string value into the "MM/dd/yyyy" format.
 * Used by the input field to ensure the input matches the expected format.
 *
 * @param value the date string to mask
 * @returns the masked value
 */
export function maskDateValue(value: string) {
  // Replace non-numeric, non-slash characters and match against the mask.
  const match = value.replace(/[^\d]/g, "").match(DATE_MASK_REGEX)

  if (!match) {
    return ""
  }

  const [, mm, dd, yyyy] = match
  const numSeps = value.replace(/[^/]/g, "").length

  let maskedValue = ""

  // Add month
  if (mm) {
    maskedValue += mm
  }

  // Add day
  if (dd) {
    maskedValue += `/${dd}`
  } else if (mm.length === 2 && numSeps > 0) {
    maskedValue += "/"
  }

  // Add year
  if (yyyy) {
    maskedValue += `/${yyyy}`
  } else if (dd.length === 2 && numSeps > 1) {
    maskedValue += "/"
  }

  return maskedValue
}

export default function MaskedDateInput({
  InputProps,
  className,
  inputRef,
  onChange,
  value,
  ...fieldProps
}: MaskedDateInputProps) {
  const localRef = useRef<HTMLInputElement | null>(null)
  const inputClasses = useInputStyles()
  const [cursor, setCursor] = useState<number | null>(null)

  const handleChange: OutlinedTextFieldProps["onChange"] = useEventCallback(
    (e) => {
      const value = e.target.value
      const maskedValue = maskDateValue(value)

      const selectionStart = e.target.selectionStart || 0
      // Track the new cursor location.
      const nextCursor = selectionStart + (maskedValue.length - value.length)

      setCursor(nextCursor)
      onChange(maskedValue)
    }
  )

  /**
   * Ensure the cursor updates properly after changes. Otherwise, our inserts can
   * cause the cursor to move to the end unexpectedly.
   */
  useEffect(() => {
    const input = localRef.current
    if (input && document.activeElement === input) {
      input.setSelectionRange(cursor, cursor)
    }
  }, [cursor, value])

  return (
    <TextField
      InputProps={{
        classes: { ...InputProps?.classes, ...inputClasses },
        ...InputProps,
      }}
      className={clsx(className, "fs-exclude")}
      fullWidth
      onChange={handleChange}
      inputRef={(ref) => {
        // Capture a local ref to ensure we track the cursor.
        localRef.current = ref

        if (inputRef) {
          if (typeof inputRef === "function") {
            inputRef(ref)
          } else {
            // @ts-ignore: This inputRef could be a read-only ref, and we must ignore the type error.
            inputRef.current = ref
          }
        }
      }}
      type="text"
      variant="outlined"
      value={value}
      {...fieldProps}
    />
  )
}
