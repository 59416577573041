import { useEffect, useState } from "react"

import _ from "lodash"
import { useFormContext, useWatch } from "react-hook-form"
import { useDebounce } from "react-use"

import { Grid, Typography } from "@material-ui/core"

import { getPrimaryStateOrderingIssue } from "app/dataServices/orderingRights"
import useFeatureFlag, { FeatureFlag } from "app/hooks/use-feature-flag"
import useQuery from "app/hooks/use-query"
import { PatientPortalOrder } from "app/types"

import {
  ControlledSelectField,
  ControlledTextField,
  FieldNames,
} from "../fields"
import { getEnabledOrderedTests } from "../utils/checkout-utils"
import { US_STATES } from "../utils/usStates"
import ScarletResponse from "./ScarletResponse"
import ShippingStateOrderingWarning from "./ShippingStateOrderingWarning"

interface Props {
  className?: string
  title: string
  setCancelModalOpenWithOptions: (options: {
    cancellationReason?: string
  }) => void
  order: PatientPortalOrder
  showZipcodeFieldForBloodDraw: boolean
  isUpdatingOrder: boolean
  updateOrder: (order: Partial<PatientPortalOrder>) => void
  willTestsByShipped: boolean
  onCancel: (reason: string) => void
  onSetZipCode: (zipCode: string | null) => void
}

export function Shipping({
  className,
  title,
  setCancelModalOpenWithOptions,
  order,
  showZipcodeFieldForBloodDraw = false,
  isUpdatingOrder,
  updateOrder,
  willTestsByShipped,
  onCancel,
  onSetZipCode,
}: Props) {
  const { control } = useFormContext()
  const query = useQuery()
  const [beastCoastEnabledFlag] = useFeatureFlag(FeatureFlag.BeastCoast)
  const beastCoastEnabled =
    beastCoastEnabledFlag || query.get("beast_coast") === "1"

  const shippingZipCode = useWatch({
    control,
    name: FieldNames.SHIPPING_ZIPCODE,
  })

  useEffect(() => {
    if (/^[0-9]{5}$/.test(shippingZipCode)) {
      onSetZipCode(shippingZipCode)
    } else {
      onSetZipCode(null)
    }
  }, [shippingZipCode, onSetZipCode])

  const watchZipcodeForBloodDrawIsNotEmpty = useWatch({
    control,
    name: FieldNames.SCARLET_BLOOD_DRAW_ZIPCODE,
  })

  const [validatedZipcode, setValidatedZipcode] = useState(null)
  useEffect(() => {
    if (/^[0-9]{5}$/.test(watchZipcodeForBloodDrawIsNotEmpty)) {
      return setValidatedZipcode(watchZipcodeForBloodDrawIsNotEmpty)
    }
  }, [watchZipcodeForBloodDrawIsNotEmpty])

  const scarletResponse = validatedZipcode ? (
    <Grid item md={12} lg={8}>
      <ScarletResponse
        zipcode={validatedZipcode}
        setCancelModalOpenWithOptions={setCancelModalOpenWithOptions}
      />
    </Grid>
  ) : null

  const watchShippingStateSelection = useWatch({
    control,
    name: FieldNames.SHIPPING_STATE,
  })

  const watchSampleCollectionStateSelection = useWatch({
    control,
    name: FieldNames.SAMPLE_COLLECTION_STATE,
  })

  useDebounce(
    () => {
      if (
        watchShippingStateSelection &&
        Boolean(order) &&
        !order.is_practitioner_paying && // only update billing_state if this is patient pay
        order.billing_state !== watchShippingStateSelection
      ) {
        updateOrder({ billing_state: watchShippingStateSelection })
      }
    },
    300,
    [watchShippingStateSelection, Boolean(order)]
  )

  useDebounce(
    () => {
      if (
        watchSampleCollectionStateSelection &&
        Boolean(order) &&
        order.sample_collection_state !== watchSampleCollectionStateSelection
      ) {
        updateOrder({
          sample_collection_state: watchSampleCollectionStateSelection,
        })
      }
    },
    300,
    [watchSampleCollectionStateSelection, Boolean(order)]
  )

  const zipcodeFieldForBloodDraw = showZipcodeFieldForBloodDraw ? (
    <Grid container item spacing={2}>
      <Grid item md={12} lg={4}>
        <ControlledTextField
          label="Zip code for blood draw"
          name={FieldNames.SCARLET_BLOOD_DRAW_ZIPCODE}
          required
          inputProps={{
            maxLength: 5,
          }}
        />
      </Grid>
      {scarletResponse}
    </Grid>
  ) : null

  // new ordering rights
  const enabledOrderedTests = getEnabledOrderedTests(order)
  const errorOrderingIssues = enabledOrderedTests.flatMap(
    (test) => test.ordering_rights_status.error_ordering_issues
  )
  const { shippingStateError, primaryStateOrderingIssue } =
    getPrimaryStateOrderingIssue(errorOrderingIssues)
  const orderedTestHasWaiver = _.some(
    enabledOrderedTests,
    (test) => test.requires_waiver
  )
  // If there is a blocking error without the sample collection state set, then that error cannot be overcome by a waiver
  const errorCannotBeOvercomeByWaiver =
    shippingStateError && !order.sample_collection_state
  const showSampleCollectionState =
    orderedTestHasWaiver &&
    !order.is_labshops_order &&
    !errorCannotBeOvercomeByWaiver

  return (
    <div className={className} aria-label="Shipping Information">
      <Typography variant="h4" color="textPrimary" style={{ fontSize: 24 }}>
        {title}
      </Typography>
      <div className="mt-3">
        <Grid container spacing={2}>
          <Grid container item>
            <Grid item xs={12}>
              <ControlledTextField
                label={
                  willTestsByShipped
                    ? "Street address you'd like the lab kits shipped to"
                    : "Street address"
                }
                placeholder="Address line 1"
                name={FieldNames.SHIPPING_STREET}
                required
              />
              <ControlledTextField
                style={{ marginTop: 8 }}
                placeholder="Address line 2"
                name={FieldNames.STREET_SECONDARY}
              />
            </Grid>
          </Grid>

          <Grid container item spacing={2}>
            <Grid item xs={12} sm={4}>
              <ControlledTextField
                label="City"
                name={FieldNames.SHIPPING_CITY}
                required
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <ControlledSelectField
                label="State"
                name={FieldNames.SHIPPING_STATE}
                disabled={isUpdatingOrder}
                required
              >
                {Object.entries(US_STATES).map(([code, name]) => (
                  <option key={code} value={code}>
                    {name}
                  </option>
                ))}
              </ControlledSelectField>
            </Grid>
            <Grid item xs={12} sm={4}>
              <ControlledTextField
                label="Zip code"
                name={FieldNames.SHIPPING_ZIPCODE}
                inputProps={{
                  maxLength: 5,
                }}
                required
              />
            </Grid>
          </Grid>
          <Grid container item spacing={2}>
            {showSampleCollectionState && (
              <Grid item xs={12} sm={4}>
                <ControlledSelectField
                  label="Sample Collection State"
                  name={FieldNames.SAMPLE_COLLECTION_STATE}
                  disabled={isUpdatingOrder}
                  required
                >
                  {Object.entries(US_STATES).map(([code, name]) => (
                    <option key={code} value={code}>
                      {name}
                    </option>
                  ))}
                </ControlledSelectField>
              </Grid>
            )}
            {beastCoastEnabled && (
              <Grid item xs={12} sm={showSampleCollectionState ? 8 : 12}>
                <ShippingStateOrderingWarning
                  error={shippingStateError}
                  primaryOrderingIssue={primaryStateOrderingIssue}
                  onCancel={onCancel}
                  pracName={order.practitioner.titled_full_name}
                  shippingState={order.shipping_state}
                  sampleCollectionState={order.sample_collection_state}
                  isLabshopsOrder={order.is_labshops_order}
                />
              </Grid>
            )}
          </Grid>
          {zipcodeFieldForBloodDraw}
        </Grid>
      </div>
    </div>
  )
}
