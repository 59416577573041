import {
  Link,
  Dialog,
  Paper,
  Grid,
  makeStyles,
  Theme,
  useMediaQuery,
} from "@material-ui/core"
import MuiDialogContent from "@material-ui/core/DialogContent"
import MuiDialogTitle from "@material-ui/core/DialogTitle"
import IconButton from "@material-ui/core/IconButton"
import CloseIcon from "@material-ui/icons/Close"

import ConnectionIcon from "app/assets/icons/connection.svg"
import BodyText from "app/components/design-system/BodyText"
import DesignSystemButton from "app/components/design-system/Button"
import DisplayText from "app/components/design-system/DisplayText"
import { colors, navy } from "app/theme"

const styles = (theme: Theme) => ({
  dialogPaper: {
    [theme.breakpoints.up("md")]: {
      minWidth: 800,
    },
    margin: 15,
    borderRadius: 7,
  },
  dialogContent: {
    padding: 12,
    backgroundColor: colors.coolGray[100],
  },
  closeButton: {
    color: navy,
    position: "relative" as "relative",
    top: -5,
    right: 0,
    marginRight: -12,
  },
  titleAndInfoContainer: {
    display: "flex",
    "flex-direction": "column",
    "flex-wrap": "wrap",
    marginRight: 24,
  },
  styledContent: {
    padding: theme.spacing(2),
    backgroundColor: colors.coolGray[100],
    minHeight: 320,
  },
  styledDialogTitle: {
    display: "flex",
    "flex-direction": "row",
    justifyContent: "space-between",
    "flex-wrap": "nowrap",
    backgroundColor: "white",
    borderBottomWidth: 1,
    borderColor: colors.blueGray[200],
    alignItems: "center",
    padding: "15px 24px 8px 24px",
  },
  bannerStyling: {
    backgroundColor: colors.yellow[50],
    marginTop: "20px",
    display: "flex",
    padding: "8px 12px",
    marginBottom: "19px",
    alignItems: "center",
    justifyContent: "left",
    border: (props) => `2px solid ${colors.yellow[400]}`,
    borderLeft: (props) => `8px solid ${colors.yellow[400]}`,
    borderRadius: 6,
  },
  emrContainer: {
    minHeight: 240,
    padding: theme.spacing(3),
    marginBottom: theme.spacing(2),
    boxShadow: "0px 1px 2px rgb(0 0 0 / 7%)",
  },
  emrItemContainer: {
    padding: theme.spacing(2),
    minHeight: 190,
    boxShadow:
      "0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)",
    border: "1px solid #E2E8F0",
  },
  fullWidthButton: {
    width: "100%",
  },
})

const useStyles = makeStyles(styles)
interface ConnectionsModalProps {
  emrIntegrations: any[]
  open: boolean
  onClose: () => void
  selectEmr: (emr: any) => void
}

export default function EMRConnectionsModal({
  emrIntegrations,
  open,
  onClose,
  selectEmr,
}: ConnectionsModalProps) {
  const classes = useStyles(styles)

  return (
    <Dialog
      onClose={onClose}
      open={open}
      aria-labelledby="emr-connections-modal"
      className={"fs-unmask"}
      classes={{
        paper: classes.dialogPaper,
      }}
    >
      <TitleSection title="EHRs and Integrations" onClose={onClose} />
      <MuiDialogContent className={classes.styledContent}>
        <Grid
          item
          container
          direction="column"
          justify="space-evenly"
          alignItems="stretch"
          xs={12}
          className={classes.emrContainer}
          component={Paper}
        >
          <Grid
            container
            direction="row"
            spacing={2}
            justify="flex-start"
            alignItems="stretch"
          >
            {emrIntegrations.map((integration) => {
              if (!integration.active && !integration.requested) {
                return (
                  <EMRItemContainer
                    key={`${integration.name}-key`}
                    emr={integration}
                    selectEmr={selectEmr}
                  />
                )
              } else {
                return <div key={`${integration.name}-key`}></div>
              }
            })}
          </Grid>
        </Grid>
        <Grid item container direction="row">
          <Grid item xs={12}>
            <BodyText size="sm" weight="regular">
              Don&apos;t see your EHR? Let them know you&apos;d like them to
              integrate! And please also{" "}
              <Link
                href="https://www.rupahealth.com/ehr-waitlist"
                target="_blank"
              >
                let us know here
              </Link>
              .
            </BodyText>
          </Grid>
        </Grid>
      </MuiDialogContent>
    </Dialog>
  )
}

const EMRItemContainer = ({ emr, selectEmr }: any) => {
  const classes = useStyles(styles)
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"))
  return (
    <Grid item xs={12} md={4} container direction={isMobile ? "column" : "row"}>
      <Grid
        item
        xs={12}
        container
        direction="column"
        justify="space-around"
        component={Paper}
        className={classes.emrItemContainer}
      >
        <Grid item>
          <img src={emr.logo} alt={emr.altText} className={emr.logoClass} />
        </Grid>
        <Grid item>
          <BodyText>{emr.description}</BodyText>
        </Grid>
        <Grid item>
          <DesignSystemButton
            className={classes.fullWidthButton}
            startIcon={ConnectionIcon}
            onClick={() => selectEmr(emr)}
            color="secondary"
          >
            Connect EHR
          </DesignSystemButton>
        </Grid>
      </Grid>
    </Grid>
  )
}

const TitleSection = ({ title, onClose }) => {
  const classes = useStyles(styles)
  const closeButton = onClose && (
    <IconButton
      aria-label="close"
      onClick={onClose}
      key="close-button"
      className={classes.closeButton}
    >
      <CloseIcon />
    </IconButton>
  )

  return (
    <MuiDialogTitle disableTypography className={classes.styledDialogTitle}>
      <div className={classes.titleAndInfoContainer}>
        <DisplayText weight="semibold" size="lg">
          {title}
        </DisplayText>
      </div>
      {closeButton}
    </MuiDialogTitle>
  )
}
