import clsx from "clsx"

import { colors, navy } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"

import { BiomarkerStatus } from "../../../../patient-portal/blood-lab-dashboard/constants"

const useStyles = makeAppStyles(() => ({
  rangeBar: {
    width: "100%",
    height: 6,
    position: "absolute",
    bottom: 0,
    left: 0,
    display: "flex",
    gap: 2,
    alignSelf: "stretch",
  },
  subRangeBar: {
    width: "100%",
    background: colors.emerald[500],
    borderRadius: 100,
  },
  belowNormalBar: {
    background: colors.rose[500],
  },
  belowOptimalBar: {
    background: colors.yellow[400],
  },
  optimalBar: {
    background: colors.emerald[500],
  },
  noRangeBar: {
    background: navy,
  },
  aboveOptimalBar: {
    background: colors.yellow[400],
  },
  aboveNormalBar: {
    background: colors.rose[500],
  },
  inactiveBar: {
    opacity: 0.1,
  },
  detailsContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-end",
  },
  detailsBtn: {
    height: "fit-content",
  },
}))

interface Props {
  standardRangeLow?: string
  optimalRangeLow?: string
  optimalRangeHigh?: string
  standardRangeHigh?: string
  biomarkerStatus?: BiomarkerStatus
  showOutOfRangeGeneric?: boolean
}

const BloodLabDashboardsBodySystemStatusBar = ({
  standardRangeLow,
  optimalRangeLow,
  optimalRangeHigh,
  standardRangeHigh,
  biomarkerStatus,
  showOutOfRangeGeneric,
}: Props) => {
  const classes = useStyles()

  const standardRangesPresent = standardRangeLow || standardRangeHigh

  const canShowStandardRangeLow =
    standardRangeLow && biomarkerStatus && !showOutOfRangeGeneric
  const canShowOptimalRangeLow =
    optimalRangeLow &&
    biomarkerStatus &&
    standardRangesPresent &&
    !showOutOfRangeGeneric
  const canShowOptimalRangeHigh =
    optimalRangeHigh &&
    biomarkerStatus &&
    standardRangesPresent &&
    !showOutOfRangeGeneric
  const canShowStandardRangeHigh =
    standardRangeHigh && biomarkerStatus && !showOutOfRangeGeneric

  return (
    <div className={classes.rangeBar}>
      {showOutOfRangeGeneric && (
        <div
          className={clsx(classes.subRangeBar, classes.belowNormalBar)}
        ></div>
      )}
      {canShowStandardRangeLow && (
        <div
          className={clsx(
            classes.subRangeBar,
            classes.belowNormalBar,
            biomarkerStatus !== BiomarkerStatus.LOW
              ? classes.inactiveBar
              : undefined
          )}
        ></div>
      )}
      {canShowOptimalRangeLow && (
        <div
          className={clsx(
            classes.subRangeBar,
            classes.belowOptimalBar,
            biomarkerStatus !== BiomarkerStatus.BELOW_OPTIMAL
              ? classes.inactiveBar
              : undefined
          )}
        ></div>
      )}
      {!showOutOfRangeGeneric && (
        <div
          className={clsx(
            classes.subRangeBar,
            biomarkerStatus ? classes.optimalBar : classes.noRangeBar,
            biomarkerStatus !== BiomarkerStatus.OPTIMAL &&
              biomarkerStatus !== BiomarkerStatus.NORMAL
              ? classes.inactiveBar
              : undefined
          )}
        ></div>
      )}
      {canShowOptimalRangeHigh && (
        <div
          className={clsx(
            classes.subRangeBar,
            classes.aboveOptimalBar,
            biomarkerStatus !== BiomarkerStatus.ABOVE_OPTIMAL
              ? classes.inactiveBar
              : undefined
          )}
        ></div>
      )}
      {canShowStandardRangeHigh && (
        <div
          className={clsx(
            classes.subRangeBar,
            classes.aboveNormalBar,
            biomarkerStatus !== BiomarkerStatus.HIGH
              ? classes.inactiveBar
              : undefined
          )}
        ></div>
      )}
    </div>
  )
}

export default BloodLabDashboardsBodySystemStatusBar
