import { useHistory } from "react-router-dom"

import { Link as MuiLink, Theme } from "@material-ui/core"

import { UserPaths } from "app/Routes"
import BodyText from "app/components/design-system/BodyText"
import DesignSystemButton from "app/components/design-system/Button"
import RupaLogoIcon from "app/components/svgs/RupaLogoIcon"
import { VENDOR_PHYSICIAN_AUTHORIZATION_LABEL } from "app/constants"
import useCheckoutDraft from "app/main/checkout/hooks/use-checkout-draft"
import { colors } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"

const useStyles = makeAppStyles((theme: Theme) => ({
  rupaLogo: {
    marginLeft: 0,
    marginRight: 5,
    margin: "auto",
    width: 16,
    height: 16,
  },
  separator: {
    height: 1,
    backgroundColor: colors.blueGray[300],
    margin: "15px 0px",
  },
  styledLink: {
    fontWeight: theme.typography.fontWeightMedium,
    "&:hover": {
      cursor: "pointer",
    },
  },
  title: {
    marginBottom: 4,
    display: "flex",
    "align-items": "center",
  },
  usePhysicianAuthorizationButton: {
    margin: "auto",
    marginTop: 10,
    width: "100%",
  },
  bodyTextStyle: {
    textAlign: "left",
  },
}))

interface PhysicianAuthorizationTooltipProps {
  physicianAuthorizationEnabled: boolean
  labTestVendorPhysAuthApproved: boolean
  isPracDifferentFromActivePrac: boolean
}

/**
 * Tooltip for giving information about Physician Authorization
 * @param physicianAuthorizationEnabled - If Physician Authorization is enabled for user
 * @param labTestVendorPhysAuthApproved - Whether the lab test tooltip is for is vendor phys auth approved
 * @param isPracDifferentFromActivePrac - Whether the ordering practitioner is different from the active practitioner
 */
const PhysicianAuthorizationTooltip = ({
  physicianAuthorizationEnabled,
  labTestVendorPhysAuthApproved,
  isPracDifferentFromActivePrac,
}: PhysicianAuthorizationTooltipProps) => {
  const classes = useStyles()

  // Do no show tooltip if phys auth flags are not
  // enabled or the test is not approved for phys auth
  if (!labTestVendorPhysAuthApproved) {
    return null
  }

  return (
    <>
      <hr className={classes.separator}></hr>
      {physicianAuthorizationEnabled ? (
        <PhysicianAuthorizationTooltipUse />
      ) : (
        <PhysicianAuthorizationTooltipEnable
          isPracDifferentFromActivePrac={isPracDifferentFromActivePrac}
        />
      )}
    </>
  )
}

/**
 * Tooltip for letting the user turn on Physician Authorization.
 * Requires Physician Authorization to be available for this user.
 */
const PhysicianAuthorizationTooltipUse = () => {
  const classes = useStyles()
  const { values, updateValues } = useCheckoutDraft()

  return (
    <>
      <BodyText className={classes.bodyTextStyle}>
        Enable {VENDOR_PHYSICIAN_AUTHORIZATION_LABEL} to request a signing
        physician to order your clients lab testing. The signing physician is
        responsible for reviewing, ordering, and checking lab results for
        critical values.
      </BodyText>
      <DesignSystemButton
        color="secondary"
        className={classes.usePhysicianAuthorizationButton}
        disabled={values.requires_vendor_physician_authorization}
        onClick={() => {
          updateValues({
            signing_practitioner: null,
            requires_vendor_physician_authorization: true,
          })
        }}
      >
        Use {VENDOR_PHYSICIAN_AUTHORIZATION_LABEL}
      </DesignSystemButton>
    </>
  )
}

interface PhysicianAuthorizationTooltipEnableProps {
  isPracDifferentFromActivePrac: boolean
}

/**
 * Tooltip for letting the user know to apply for Physician Authorization
 * @param physicianAuthorizationApplicationStatus - Application status of user
 * @param isPracDifferentFromActivePrac - Whether the ordering practitioner is different from the active practitioner
 */
const PhysicianAuthorizationTooltipEnable = ({
  isPracDifferentFromActivePrac,
}: PhysicianAuthorizationTooltipEnableProps) => {
  const classes = useStyles()
  const history = useHistory()

  let enableLink = (
    <MuiLink
      className={classes.styledLink}
      color="primary"
      onClick={() => history.push(UserPaths.ACCOUNT)}
    >
      {`Add ${VENDOR_PHYSICIAN_AUTHORIZATION_LABEL} to your account`}
    </MuiLink>
  )

  if (isPracDifferentFromActivePrac) {
    enableLink = (
      <div style={{ display: "inline" }}>
        <BodyText style={{ display: "inline" }}>
          Please have the selected practitioner{" "}
        </BodyText>
        <MuiLink
          className={classes.styledLink}
          color="primary"
          onClick={() => history.push(UserPaths.ACCOUNT)}
        >
          {` add ${VENDOR_PHYSICIAN_AUTHORIZATION_LABEL} to your account in Settings`}
        </MuiLink>
      </div>
    )
  }

  return (
    <>
      <BodyText weight="semibold" size="base" className={classes.title}>
        <RupaLogoIcon className={classes.rupaLogo} />
        {VENDOR_PHYSICIAN_AUTHORIZATION_LABEL}
      </BodyText>
      <BodyText>
        With {VENDOR_PHYSICIAN_AUTHORIZATION_LABEL}, you can request a signing
        physician to order your clients lab testing. The signing physician is
        responsible for reviewing, ordering, and checking lab results for
        critical values. {enableLink}
      </BodyText>
    </>
  )
}

export default PhysicianAuthorizationTooltip
