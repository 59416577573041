import BodyText from "app/components/design-system/BodyText"
import { PatientPortalOrder, PatientPortalOrderLineItem } from "app/types"
import makeAppStyles from "app/utils/makeAppStyles"

import { LabTestLineItem } from "../../LabTestLineItem"
import { CompanyPanel } from "../types"
import PanelLineItem from "./PanelLineItem"

interface ShippedLineItemsProps {
  panels: CompanyPanel[]
  individualShippedLineItems: PatientPortalOrderLineItem[]
  showPrices: boolean
  order: PatientPortalOrder
  isEditing: boolean
  updatingOrderedTestIds: string[]
  isUpdatingOrder: boolean
  enableLineItems: (orderedTestIds: string[]) => void
  disableLineItems: (orderedTestIds: string[]) => void
}

const useStyles = makeAppStyles(() => ({
  infoWrapper: {
    display: "flex",
    alignItems: "center",
    marginBottom: 16,
  },
}))

const ShippedLineItems = ({
  panels,
  individualShippedLineItems,
  order,
  showPrices,
  ...props
}: ShippedLineItemsProps) => {
  const classes = useStyles()
  return (
    <div className="mt-6">
      <div className={classes.infoWrapper}>
        <BodyText weight="semibold">We will ship these tests to you:</BodyText>
      </div>
      {panels.map((panel) => (
        <PanelLineItem
          key={panel.groupKey}
          panel={panel}
          order={order}
          showPrices={showPrices}
          {...props}
        />
      ))}
      {individualShippedLineItems.map((lineItem) => (
        <LabTestLineItem
          key={lineItem.id}
          lineItem={lineItem}
          hideDiscount={Boolean(order.storefront_id)}
          isLabshopsOrder={order.is_labshops_order}
          showPrice={showPrices}
          {...props}
        />
      ))}
    </div>
  )
}
export default ShippedLineItems
