import { ReactElement } from "react"

import { makeStyles, Theme } from "@material-ui/core"

import { ReactComponent as ArrowTopRight } from "app/assets/icons/arrow-top-right.svg"
import { ReactComponent as FiltersIcon } from "app/assets/icons/filters-inactive.svg"
import AlertsNotice from "app/assets/images/alerts-info.svg"
import AlertsResultsDelay from "app/assets/images/alerts-results-delay.svg"
import AlertsShippingDelay from "app/assets/images/alerts-shipping-delay.svg"
import BodyText from "app/components/design-system/BodyText"
import { cancelRed, colors, primaryColor } from "app/theme"
import { AlertTypes } from "app/types"

/*
 * NOTE: This is a legacy component. Please use Banner from designsystem instead.
 */

interface Props {
  title: string
  subtitle: string
  type: AlertTypes
  linkUrl?: string
  linkOnClick?: () => void
  linkText?: string
  linkTarget?: string
}

const typeToInfo: {
  [key in AlertTypes]: {
    icon: string
    linkIcon: ReactElement
    backgroundColor: string
    textColor: string
  }
} = {
  [AlertTypes.NOTICE]: {
    icon: AlertsNotice,
    backgroundColor: colors.lightBlue[50],
    textColor: primaryColor,
    linkIcon: <ArrowTopRight />,
  },
  [AlertTypes.SHIPPING_DELAY]: {
    icon: AlertsShippingDelay,
    backgroundColor: colors.rose[50],
    textColor: cancelRed,
    linkIcon: <FiltersIcon fill={primaryColor} />,
  },
  [AlertTypes.RESULTS_DELAY]: {
    icon: AlertsResultsDelay,
    backgroundColor: colors.rose[50],
    textColor: cancelRed,
    linkIcon: <FiltersIcon fill={primaryColor} />,
  },
}

const useStyles = makeStyles<
  Theme,
  {
    textColor: string
    backgroundColor: string
  }
>((theme) => ({
  container: {
    display: "flex",
    flex: 1,
    padding: "12px 0",
    gap: 6,
    alignItems: "flex-start",

    flexFlow: "column",
    [theme.breakpoints.up("sm")]: {
      flexFlow: "row",
      alignItems: "center",
    },
  },
  pill: {
    borderRadius: 100,
    padding: "1px 8px",
    background: (props) => props.backgroundColor,
    display: "flex",
    alignItems: "center",
    marginBottom: 6,

    [theme.breakpoints.up("sm")]: {
      marginBottom: 0,
    },
  },
  pillIcon: {
    marginRight: 6,
  },
  pillText: {
    color: (props) => props.textColor,
  },
}))

const Alert = ({
  title,
  subtitle,
  type,
  linkUrl,
  linkText,
  linkOnClick,
  linkTarget = "_blank",
}: Props) => {
  const { icon, linkIcon, textColor, backgroundColor } = typeToInfo[type]
  const { container, pill, pillIcon, pillText } = useStyles({
    backgroundColor,
    textColor,
  })

  return (
    <div className={container}>
      <div className={pill}>
        <img src={icon} className={pillIcon} alt="" />
        <BodyText className={pillText} weight="semibold" size="md">
          {title}
        </BodyText>
      </div>
      <BodyText size="md" weight="semibold">
        {subtitle}
      </BodyText>
      {linkText &&
        (linkUrl || linkOnClick) &&
        (linkUrl ? (
          <a
            href={linkUrl}
            target={linkTarget}
            rel="noreferrer"
            className="inline-flex items-center gap-1"
          >
            <BodyText weight="semibold" size="md" className="text-primary">
              {linkText}
            </BodyText>
            {linkIcon}
          </a>
        ) : (
          <button
            onClick={linkOnClick}
            className="inline-flex items-center gap-1"
          >
            <BodyText weight="semibold" size="md" className="text-primary">
              {linkText}
            </BodyText>
            {linkIcon}
          </button>
        ))}
    </div>
  )
}

export default Alert
