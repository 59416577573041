import * as React from "react"

import { find } from "lodash"

import InfoTextTooltip from "app/components/design-system/InfoTextTooltip"
import RupaLogoIcon from "app/components/svgs/RupaLogoIcon"
import {
  VENDOR_PHYSICIAN_AUTHORIZATION_LABEL,
  PHYSICIAN_SERVICES_ENABLE_METHODS,
} from "app/constants"
import useFeatureFlag, { FeatureFlag } from "app/hooks/use-feature-flag"
import { textPrimaryColor } from "app/theme"
import { DefaultPractitionerOption } from "app/types"
import makeAppStyles from "app/utils/makeAppStyles"

import PractitionerSelect from "./PractitionerSelect"

const useSelectItemStyles = makeAppStyles({
  physicianAuthorizationMessage: {
    display: "flex",
    gap: 4,
    alignItems: "center",
    marginTop: 4,
    fontSize: 13,
    fontWeight: 400,
    lineHeight: 1.35,
  },
  blockedText: {
    marginTop: 4,
    fontSize: 13,
    fontStyle: "italic",
    fontWeight: 400,
  },
})

export interface OrderingPractitionerSelectItemProps {
  option: DefaultPractitionerOption
}

export const OrderingPractitionerSelectItem = ({
  option,
}: OrderingPractitionerSelectItemProps) => {
  const classes = useSelectItemStyles()

  const [physicianServicesBackfillPhase2Enabled] = useFeatureFlag(
    FeatureFlag.PhysicianServicesBackfillPhase2
  )

  const physicianServicesStatus = physicianServicesBackfillPhase2Enabled
    ? option.practitioner.physician_services_enable_method ===
      PHYSICIAN_SERVICES_ENABLE_METHODS.OPT_IN
      ? "Enabled"
      : "Pending"
    : "Enabled"

  return (
    <PractitionerSelect.Item value={option.practitioner.id}>
      <div>
        {option.practitioner.user.full_name}

        {option.practitioner.vendor_physician_authorization_enabled && (
          <div className={classes.physicianAuthorizationMessage}>
            <RupaLogoIcon height={14} width={14} />
            {VENDOR_PHYSICIAN_AUTHORIZATION_LABEL} {physicianServicesStatus}
          </div>
        )}

        {option.blocked && (
          <div className={classes.blockedText}>
            {Boolean(option.practitioner.signing_practitioner)
              ? `${option.practitioner.user.full_name}'s Signing Practitioner is not licensed to order some tests in this order.`
              : "Not licensed to order some tests in this order."}
          </div>
        )}
      </div>
    </PractitionerSelect.Item>
  )
}

const useSelectStyles = makeAppStyles({
  label: {
    display: "flex",
    alignItems: "center",
    gap: 4,
    fontSize: 11,
    fontWeight: 400,
    lineHeight: 1.5,
    color: textPrimaryColor,
    marginBottom: 4,
  },
  infoText: {
    display: "inline-flex",
    "& svg": {
      width: 13,
      height: 13,
    },
  },
  fullWidth: {
    width: "100%",
  },
})

export interface OrderingPractitionerSelectProps {
  infoText: string
  label: React.ReactNode
  onSelect: (option?: DefaultPractitionerOption) => void
  options: DefaultPractitionerOption[]
  value?: DefaultPractitionerOption
  disabled?: boolean
}

export default function OrderingPractitionerSelect({
  infoText,
  label,
  onSelect,
  options,
  value,
  disabled = false,
}: OrderingPractitionerSelectProps) {
  const classes = useSelectStyles()
  return (
    <div className={classes.fullWidth}>
      <label className={classes.label} id="ordering-practitioner-label">
        {label}
        {infoText && (
          <InfoTextTooltip className={classes.infoText}>
            {infoText}
          </InfoTextTooltip>
        )}
      </label>
      <PractitionerSelect
        aria-labelledby="ordering-practitioner-label"
        displayValue={value?.practitioner.user.full_name}
        onValueChange={(nextValue) => {
          const nextOption = find(
            options,
            (option) => option.practitioner.id === nextValue
          )
          onSelect(nextOption ? nextOption : undefined)
        }}
        readOnly={(Boolean(value) && options.length < 1) || disabled}
        value={value?.practitioner.id}
      >
        {options.map((option) => (
          <OrderingPractitionerSelectItem
            key={option.practitioner.id}
            option={option}
          />
        ))}
      </PractitionerSelect>
    </div>
  )
}
