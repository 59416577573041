import { OrderIntent, useResourceSelector } from "app/hooks/use-resource"

export const useGetCartLabTests = (cart: OrderIntent | null) => {
  const storefrontProductIds =
    cart?.relationships?.storefront_products?.data.map(({ id }) => id) ?? []

  const products = useResourceSelector((entities) =>
    storefrontProductIds.map((id) => entities["storefront_product"]?.[id])
  )

  const productBundleIds = products
    ?.map((product) => product?.relationships.bundle.data?.id)
    .filter((id) => !!id) as string[]

  const bundles = useResourceSelector((entities) =>
    productBundleIds.map((id) => entities["lab_test_bundle"]?.[id])
  )

  const labTestIds = products
    ?.map((product) => product?.relationships.lab_test.data?.id)
    .filter((id) => !!id) as string[]

  bundles.forEach((bundle) => {
    const bundleLabTests = bundle?.relationships.lab_tests?.data || []
    labTestIds.push(...bundleLabTests.map(({ id }) => id).filter((id) => !!id))
  })

  const labTests = useResourceSelector((entities) =>
    labTestIds.map((id) => entities["lab_test"]?.[id])
  )

  return labTests
}
