import React, { useCallback, useMemo, useState } from "react"

import NiceModal, { useModal } from "@ebay/nice-modal-react"
import { Dialog, DialogContent, cn } from "@rupahealth/design"

import SuccessImage from "app/assets/images/hedgehog.jpg"
import { SpecimenIssueActionUnion } from "app/constants.typed"
import { usePreloadImagesOnDemand } from "app/hooks/use-preload-image"
import useAppSelector from "app/hooks/useAppSelector"
import { trackEventWithProperties } from "app/services/segment.typed"
import { Patient } from "app/types"
import {
  SpecimenIssue,
  SpecimenIssueEvents,
  SpecimenIssueNormalized,
} from "types/specimen-issue"

import useResolveSpecimenIssue from "../use-resolve-specimen-issue"
import ModalContentStep1ChooseAction from "./ModalContentStep1ChooseAction"
import ModalContentStep2Confirm from "./ModalContentStep2Confirm"

export interface SpecimenIssueResolveModalProps {
  specimenIssue: SpecimenIssue
  testDisplayName: string
  patient: Pick<Patient, "first_name"> | undefined
  onSuccess?: (data: SpecimenIssueNormalized | undefined) => Promise<void>
}

const SpecimenIssueResolveModal: React.FC<SpecimenIssueResolveModalProps> = ({
  specimenIssue,
  onSuccess,
  ...props
}) => {
  const modal = useModal()
  const practitioner = useAppSelector(({ practitioner }) => practitioner)
  const [actionChosen, setActionChosen] = useState<
    SpecimenIssueActionUnion | undefined
  >()
  const [submitting, setSubmitting] = useState(false)

  // preload image for step2 when an action is selected
  const { loaded: successImageLoaded, preload } = usePreloadImagesOnDemand([
    SuccessImage,
  ])

  // If an action was chosen and the image is not yet loaded, we show a
  // loading state on the chosen action until image is loaded
  const actionLoading = useMemo(() => {
    return successImageLoaded ? undefined : actionChosen
  }, [actionChosen, successImageLoaded])

  const setAction = useCallback(
    (action: SpecimenIssueActionUnion | undefined) => {
      if (action && !successImageLoaded) preload()
      setActionChosen(action)
    },
    [successImageLoaded, preload]
  )

  const onActionSelect = useCallback(
    (action: SpecimenIssueActionUnion | undefined) => {
      setAction(action)
      trackEventWithProperties(
        SpecimenIssueEvents.SPECIMEN_ISSUE_ACTION_SELECTED,
        {
          practitionerId: practitioner?.id,
          specimenIssueId: specimenIssue.id,
          actionChosen: action,
        }
      )
    },
    [setAction, specimenIssue.id, practitioner?.id]
  )

  const onGoBack = () => {
    setActionChosen(undefined)
    trackEventWithProperties(
      SpecimenIssueEvents.SPECIMEN_ISSUE_SUCCESS_MODAL_ACTION,
      {
        practitionerId: practitioner?.id,
        specimenIssueId: specimenIssue.id,
        actionChosen,
        button: "back",
      }
    )
  }

  const onOpenChange = (open: boolean) => {
    if (!open) {
      setActionChosen(undefined)
      modal.hide()
    }
  }

  const resolveSpecimenIssue = useResolveSpecimenIssue()
  const onSubmit = async () => {
    if (!actionChosen) return

    trackEventWithProperties(
      SpecimenIssueEvents.SPECIMEN_ISSUE_SUCCESS_MODAL_ACTION,
      {
        practitionerId: practitioner?.id,
        specimenIssueId: specimenIssue.id,
        actionChosen,
        button: "confirm",
      }
    )

    setSubmitting(true)
    const response = await resolveSpecimenIssue({
      specimenIssueId: specimenIssue.id,
      actionChosen,
    })
    await onSuccess?.(response)
    setSubmitting(false)
    onOpenChange(false)
  }

  return (
    <Dialog open={modal.visible} onOpenChange={onOpenChange}>
      <DialogContent
        className={cn(
          "bg-slate-100 p-0 max-h-[90vh] overflow-y-auto max-w-[90vw] sm:max-w-lg border-[4px]",
          { "[&>button]:hidden": actionChosen } // hide the close button on step 2
        )}
      >
        {actionChosen && successImageLoaded ? (
          <ModalContentStep2Confirm
            specimenIssue={specimenIssue}
            image={SuccessImage}
            actionChosen={actionChosen}
            submitting={submitting}
            onSubmit={onSubmit}
            onGoBack={onGoBack}
            {...props}
          />
        ) : (
          <ModalContentStep1ChooseAction
            onActionSelect={onActionSelect}
            actionLoading={actionLoading}
            specimenIssue={specimenIssue}
            {...props}
          />
        )}
      </DialogContent>
    </Dialog>
  )
}

const SpecimenIssueResolveNiceModal =
  NiceModal.create<SpecimenIssueResolveModalProps>((props) => {
    return <SpecimenIssueResolveModal {...props} />
  })

export function useSpecimenIssueResolveModal() {
  return useModal(SpecimenIssueResolveNiceModal)
}
