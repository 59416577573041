import {
  Accordion as MuiAccordion,
  AccordionDetails,
  AccordionSummary as MuiAccordionSummary,
  styled,
  withStyles,
} from "@material-ui/core"

import WhiteCloseIcon from "app/assets/icons/white-cross-thin.svg"
import CollectSateWaiverImage from "app/assets/images/patient-instructions/collect-state-waiver.svg"
import FillOutReqFormsImage from "app/assets/images/patient-instructions/fill-out-req-forms.svg"
import PrintReqFormsImage from "app/assets/images/patient-instructions/print-req-forms.svg"
import ScheduleBloodDrawImage from "app/assets/images/patient-instructions/schedule-blood-draw.svg"
import LabTestCompletionInstructionsImage from "app/assets/images/patient-instructions/test-completion-instructions.svg"
import BodyText from "app/components/design-system/BodyText"
import DisplayText from "app/components/design-system/DisplayText"
import { PatientPortalPatient } from "app/patient-portal/types"
import { colors, maroon, primaryColor } from "app/theme"
import {
  PatientInstruction as PatientInstructionType,
  PatientInstructionTypes,
} from "app/types"

import FillOutReqForms from "./FillOutReqForms"
import LabTestCompletionInstructions from "./LabTestCompletionInstructions"
import PrintReqForms from "./PrintReqForms"
import ScheduleBloodDraw from "./ScheduleBloodDraw"
import StateWaiverInstructions from "./StateWaiverInstructions"

interface Props {
  patientInstruction: PatientInstructionType
  expanded: boolean
  onExpand: () => void
  patient: PatientPortalPatient
}

const Accordion = withStyles({
  root: {
    boxShadow: "none",
    borderRadius: 8,
    border: "1px solid white",
    background: "rgba(255, 255, 255, 0.8)",
    transition: "0.24s border, 0.24s background",

    // Reset Mui's accordion styles
    margin: 0,
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: 0,
    },
  },
  expanded: {
    margin: 0,
    border: `1px solid ${primaryColor}`,
    background: colors.blue[50],
  },
})(MuiAccordion)

const AccordionSummary = withStyles((theme) => ({
  content: {
    margin: "16px 0",

    [theme.breakpoints.up("sm")]: {
      margin: "20px 0",
    },
  },
  root: {
    padding: "0 12px",

    [theme.breakpoints.up("sm")]: {
      padding: "0 20px",
    },
  },
}))(MuiAccordionSummary)

const CloseButton = styled("div")({
  background: primaryColor,
  height: 35,
  width: 35,
  borderRadius: 100,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexShrink: 0,
})

const CloseIcon = styled("img")({
  transition: "0.48s transform",
  width: 10,
})

const StepTextColor: { [type in PatientInstructionTypes]: string } = {
  [PatientInstructionTypes.LAB_TEST_COMPLETION_INSTRUCTIONS]: colors.teal[600],
  [PatientInstructionTypes.PRINT_REQ_FORMS]: primaryColor,
  [PatientInstructionTypes.FILL_OUT_REQ_FORMS]: colors.violet[500],
  [PatientInstructionTypes.SCHEDULE_BLOOD_DRAW]: maroon,
  [PatientInstructionTypes.WAIVER_FOR_STATE_COLLECTION]: colors.orange[500],
}

const StepIconPath: { [type in PatientInstructionTypes]: string } = {
  [PatientInstructionTypes.LAB_TEST_COMPLETION_INSTRUCTIONS]:
    LabTestCompletionInstructionsImage,
  [PatientInstructionTypes.PRINT_REQ_FORMS]: PrintReqFormsImage,
  [PatientInstructionTypes.FILL_OUT_REQ_FORMS]: FillOutReqFormsImage,
  [PatientInstructionTypes.SCHEDULE_BLOOD_DRAW]: ScheduleBloodDrawImage,
  [PatientInstructionTypes.WAIVER_FOR_STATE_COLLECTION]: CollectSateWaiverImage,
}

const StepText = styled(({ patientInstruction, ...rest }) => (
  <BodyText {...rest} />
))({
  color: ({
    patientInstruction,
  }: {
    patientInstruction: PatientInstructionType
  }) => StepTextColor[patientInstruction.type],
  marginBottom: 4,
})

function PatientInstruction({
  patientInstruction,
  expanded,
  onExpand,
  patient,
}: Props) {
  const { type, title, step } = patientInstruction

  return (
    <Accordion expanded={expanded} onChange={onExpand}>
      <AccordionSummary aria-controls={type}>
        <div className="flex items-center w-full gap-3">
          <img src={StepIconPath[type]} alt="" />
          <div className="grow">
            <StepText weight="semibold" patientInstruction={patientInstruction}>
              {step}
            </StepText>
            <DisplayText weight="semibold" size="lg">
              {title}
            </DisplayText>
          </div>
          <CloseButton>
            <CloseIcon
              src={WhiteCloseIcon}
              alt=""
              style={{
                transform: `rotate(-${expanded ? 0 : 135}deg)`,
              }}
            />
          </CloseButton>
        </div>
      </AccordionSummary>
      <AccordionDetails className="px-5">
        <div className="bg-white rounded-xl p-4 w-full">
          {patientInstruction.type ===
            PatientInstructionTypes.LAB_TEST_COMPLETION_INSTRUCTIONS && (
            <LabTestCompletionInstructions
              patientInstruction={patientInstruction}
            />
          )}
          {patientInstruction.type ===
            PatientInstructionTypes.WAIVER_FOR_STATE_COLLECTION && (
            <StateWaiverInstructions patientInstruction={patientInstruction} />
          )}
          {patientInstruction.type ===
            PatientInstructionTypes.FILL_OUT_REQ_FORMS && (
            <FillOutReqForms
              patientInstruction={patientInstruction}
              patient={patient}
            />
          )}
          {patientInstruction.type ===
            PatientInstructionTypes.PRINT_REQ_FORMS && (
            <PrintReqForms patientInstruction={patientInstruction} />
          )}
          {patientInstruction.type ===
            PatientInstructionTypes.SCHEDULE_BLOOD_DRAW && (
            <ScheduleBloodDraw patientInstruction={patientInstruction} />
          )}
        </div>
      </AccordionDetails>
    </Accordion>
  )
}

export default PatientInstruction
