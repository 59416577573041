import { useCallback } from "react"

import {
  AchPaymentMethodDetails,
  PatientCheckoutFormData,
  PaymentMethodType,
} from "app/types"

import { CheckoutPaymentMethodError } from "../errors"
import useAchFields from "./use-ach-fields"

/**
 * Hook that generates a callback to creates a payment method using ACH transfer with Stripe
 * @returns callback that returns AchPaymentMethodDetails
 */
export default function useCreateAchPaymentMethod() {
  const { accountId, publicToken, last4, accountName, bankName } =
    useAchFields()
  return useCallback(
    async (
      formData: PatientCheckoutFormData
    ): Promise<AchPaymentMethodDetails> => {
      if (!formData.payment_method) {
        throw new CheckoutPaymentMethodError(
          "Unable to process payment. No payment_method found. Please contact support@rupahealth.com for assistance.",
          PaymentMethodType.ACH
        )
      }

      if (!accountId || !publicToken || !accountName || !bankName || !last4) {
        throw new CheckoutPaymentMethodError(
          "Please connect your bank account.",
          PaymentMethodType.ACH
        )
      }

      return {
        account_id: accountId,
        bank_token: publicToken,
        account_name: accountName,
        bank_name: bankName,
        last4: last4,
      }
    },
    [accountId, publicToken, accountName, bankName, last4]
  )
}
