import * as React from "react"

import * as Sentry from "@sentry/react"

import { ReactComponent as ErrorPageIcon } from "app/assets/icons/error-page.svg"
import Button from "app/components/design-system/Button"
import DisplayText from "app/components/design-system/DisplayText"
import useAppSelector from "app/hooks/useAppSelector"
import { isDebug } from "app/settings"
import makeAppStyles from "app/utils/makeAppStyles"

import { isUserGuest } from "./auth/util"

const useFallbackStyles = makeAppStyles((theme) => ({
  fallbackContainer: {
    maxWidth: 530,
    width: "100%",
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginLeft: "auto",
    marginRight: "auto",
    gap: theme.spacing(2.0),
    padding: theme.spacing(2.0),
  },
  errorIcon: {
    maxWidth: "100%",
    width: 320,
    height: "auto",
  },
  actions: {
    display: "flex",
    gap: theme.spacing(1.5),
  },
}))

const ErrorBoundaryFallback = ({ eventId }) => {
  const classes = useFallbackStyles()
  const user = useAppSelector(({ auth }) => auth.user)
  return (
    <div className={classes.fallbackContainer}>
      <ErrorPageIcon className={classes.errorIcon} viewBox="0 0 790 513" />
      <DisplayText size="2xl" variant="h1">
        Uh-oh!
      </DisplayText>
      <DisplayText align="center" size="base" variant="body1">
        There was an error in loading this page, and our team has been notified.
        If you'd like to help, tell us what happened below.
      </DisplayText>
      {Boolean(eventId) && (
        <DisplayText align="center" size="base" variant="body1">
          Event ID: {eventId}
        </DisplayText>
      )}
      <div className={classes.actions}>
        <Button color="primary" onClick={() => window.location.reload()}>
          Reload the Page
        </Button>
        {!isDebug && (
          <Button
            color="secondary"
            onClick={() =>
              Sentry.showReportDialog({
                eventId,
                user: !isUserGuest(user)
                  ? {
                      name: `${user.first_name} ${user.last_name}`,
                      email: user.email,
                    }
                  : {},
              })
            }
          >
            Report Feedback
          </Button>
        )}
      </div>
    </div>
  )
}

export default function AppErrorBoundary({
  children,
}: {
  children: React.ReactNode
}) {
  return (
    <Sentry.ErrorBoundary
      fallback={({ eventId }) => <ErrorBoundaryFallback eventId={eventId} />}
      beforeCapture={(scope) => {
        scope.setLevel("fatal")
      }}
    >
      {children}
    </Sentry.ErrorBoundary>
  )
}
