import { useMemo } from "react"

import clsx from "clsx"

import { Theme } from "@material-ui/core"

import useBloodLabDashboardsBloodReportResultDetailModal from "app/main/blood-lab-dashboards/hooks/use-blood-lab-dashboards-blood-report-result-modal"
import makeAppStyles from "app/utils/makeAppStyles"

import {
  MAX_DATA_COLUMNS,
  STICKY_TABLE_COLUMN_WIDTH,
  STICKY_TABLE_COLUMN_WIDTH_MOBILE,
} from "../constants/constants"
import {
  ResultsOverTimeBiomarkerData,
  ResultsOverTimeDateGrouping,
  ResultsOverTimeResultData,
} from "../types/types"
import { calculateTableCellWidth } from "../util"
import BiomarkerInfoColumn from "./BiomarkerInfoColumn"
import EmptyResultCard from "./EmptyResultCard"
import ResultCard from "./ResultCard"

const useStyles = makeAppStyles<{ numResultColumns: number }>(
  (theme: Theme) => ({
    biomarkerRow: {
      "&:last-child > td:first-child": {
        borderBottom: 0,
      },
    },
    stickyCol: {
      left: 0,
      position: "absolute",
      top: "auto",
      width: STICKY_TABLE_COLUMN_WIDTH,
      textAlign: "left !important" as any,
      boxShadow: ({ numResultColumns }) =>
        numResultColumns <= MAX_DATA_COLUMNS
          ? "none"
          : "20px 0 20px -12px rgba(0, 0, 0, 0.07)",
      [theme.breakpoints.down(640)]: {
        width: STICKY_TABLE_COLUMN_WIDTH_MOBILE,
      },
    },
  })
)

interface Props {
  biomarker: ResultsOverTimeBiomarkerData
  availableDates: string[]
  groupedBy: ResultsOverTimeDateGrouping
  clinicName?: string
  showSampleTypes: boolean
  tableWidth?: number
  showHighLowDescriptions: boolean
}

const BiomarkerResultRow = ({
  biomarker,
  availableDates,
  groupedBy,
  clinicName,
  showSampleTypes,
  tableWidth,
  showHighLowDescriptions,
}: Props) => {
  const classes = useStyles({ numResultColumns: availableDates.length })

  // Data from the API on a per-biomarker basis is not guaranteed to contain
  // a result for every date across all biomarkers. This function fills in
  // missing dates with null values so that the table can be rendered correctly.
  const fillMissingResultDates = () => {
    const newResults: (ResultsOverTimeResultData | null)[] = []

    availableDates.forEach((date) => {
      const resultWithinDate = biomarker.results.find((result) => {
        const resultDate = new Date(result.created_at)
        const resultDateYear = resultDate.getFullYear()
        const resultDateMonth = resultDate.getMonth()
        const resultDateDay = resultDate.getDate()

        const currentDate = new Date(date)
        const currentDateYear = currentDate.getFullYear()
        const currentDateMonth = currentDate.getMonth()
        const currentDateDay = currentDate.getDate()

        if (groupedBy === ResultsOverTimeDateGrouping.MONTH) {
          if (
            resultDateYear === currentDateYear &&
            resultDateMonth === currentDateMonth
          ) {
            return true
          } else {
            return false
          }
        } else {
          // If not grouped by month it is grouped by day
          if (
            resultDateYear === currentDateYear &&
            resultDateMonth === currentDateMonth &&
            resultDateDay === currentDateDay
          ) {
            return true
          } else {
            return false
          }
        }
      })

      if (resultWithinDate) {
        newResults.push(resultWithinDate)
      } else {
        newResults.push(null)
      }
    })

    return newResults
  }

  const filledBiomarkerResults = useMemo(() => {
    return fillMissingResultDates()
  }, [availableDates])

  const cellWidth = useMemo(() => {
    return calculateTableCellWidth(availableDates.length, tableWidth)
  }, [availableDates, tableWidth])

  const biomarkerDetailModal =
    useBloodLabDashboardsBloodReportResultDetailModal()

  return (
    <tr
      className={clsx(
        "h-[160px] sm:h-[90px] border-b border-b-slate-100 last:border-b-0",
        classes.biomarkerRow
      )}
    >
      <td
        className={clsx(
          classes.stickyCol,
          "pr-2 h-[160px] sm:h-[90px] flex flex-row justify-center items-center sm:justify-end border-b border-b-slate-100"
        )}
        onClick={() =>
          biomarkerDetailModal.show({
            biomarkerId: biomarker.id,
            biomarkerShortName: biomarker.short_name,
            biomarkerLongName: biomarker.long_name,
            biomarkerDescription: biomarker.description,
            biomarkerShortDescription: biomarker.short_description,
            biomarkerLowDescription: biomarker.low_description,
            biomarkerHighDescription: biomarker.high_description,
            biomarkerLowSymptoms: biomarker.low_symptoms,
            biomarkerHighSymptoms: biomarker.high_symptoms,
            optimalRangeMin: biomarker.results[0]?.optimal_range_min,
            optimalRangeMax: biomarker.results[0]?.optimal_range_max,
            standardRangeMin: biomarker.results[0]?.standard_range_min,
            standardRangeMax: biomarker.results[0]?.standard_range_max,
            value: biomarker.results[0].display_value || undefined,
            alternateValue: biomarker.results[0].alternate_value,
            unit: biomarker.results[0]?.unit,
            status: biomarker.results[0].status,
            isRangeValue: biomarker.results[0].is_range_value,
            resultsOverTime: biomarker.results.slice(0, MAX_DATA_COLUMNS),
            resultsGroupedBy: groupedBy,
            clinicName: clinicName || "Practitioner's Clinic",
            sampleTypes: biomarker.sample_types,
            activeResultDate: biomarker.results[0]?.created_at,
            labCompanyName: biomarker.results[0].lab_company_name,
            onClose: () => biomarkerDetailModal.hide(),
            showHighLowDescriptions,
          })
        }
      >
        <BiomarkerInfoColumn
          biomarker={biomarker}
          groupedBy={groupedBy}
          availableDates={availableDates}
          showSampleTypes={showSampleTypes}
        />
      </td>
      {filledBiomarkerResults.map((result, index) => {
        return (
          <td
            key={index}
            className={`px-2`}
            style={{
              minWidth: `${cellWidth}px`,
            }}
          >
            {result ? (
              <ResultCard
                result={result}
                clinicName={clinicName}
                sourceBiomarker={biomarker}
                groupedBy={groupedBy}
                showHighLowDescriptions={showHighLowDescriptions}
              />
            ) : (
              <EmptyResultCard />
            )}
          </td>
        )
      })}
    </tr>
  )
}

export default BiomarkerResultRow
