import PageToolbar from "app/components/PageToolbar"
import FoodPlanClientSelectionCard from "app/food-plans/components/FoodPlanClientSelectionCard"
import FoodPlanGenerateConfigurationCard from "app/food-plans/components/FoodPlanGenerateConfigurationCard"
import FoodPlanIntroCard from "app/food-plans/components/FoodPlanIntroCard"
import FoodPlanLayout from "app/food-plans/components/FoodPlanLayout"
import RecentFoodPlansDropdownMenu from "app/food-plans/components/RecentFoodPlansDropdownMenu"

export default function FoodPlans() {
  return (
    <>
      <PageToolbar title="Food Plans">
        <RecentFoodPlansDropdownMenu />
      </PageToolbar>

      <FoodPlanLayout
        main={<FoodPlanIntroCard />}
        sidebar={
          <>
            <FoodPlanClientSelectionCard />

            <FoodPlanGenerateConfigurationCard muted />
          </>
        }
      />
    </>
  )
}
