import React from "react"

export default function PatientPortalBackground({
  children,
  image,
}: React.PropsWithChildren<{
  image: string
}>) {
  return (
    <div
      className={"fixed inset-0 bg-cover bg-no-repeat"}
      style={{
        backgroundImage: `url('${image}')`,
      }}
    />
  )
}
