import { MutableRefObject, useEffect, useRef, useState } from "react"

import clsx from "clsx"

import {
  SCROLL_CONTAINER_PADDING,
  SECTION_PADDING,
} from "app/main/comparison/constants"
import useAnyComparedLabTestHasProperty from "app/main/comparison/hooks/use-any-compared-lab-test-has-property"
import { colors, shadows } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"

import ComparisonLabTestDetails from "./components/ComparisonLabTestDetails"
import ComparisonLabTestList from "./components/ComparisonLabTestList"

const useStyles = makeAppStyles((theme) => ({
  scrollContainer: {
    position: "relative",
    width: "100%",
    height: "100%",
    overflow: "auto",
  },
  innerContainer: {
    display: "table",
    minWidth: "100%",
    padding: `0 ${SCROLL_CONTAINER_PADDING}px ${SCROLL_CONTAINER_PADDING}px ${SCROLL_CONTAINER_PADDING}px`,
    [theme.breakpoints.down("sm")]: {
      padding: `0 0 ${SCROLL_CONTAINER_PADDING}px 0`,
    },
  },
  labTestsSection: {
    position: "sticky",
    top: 0,
    padding: SECTION_PADDING,
    marginBottom: theme.spacing(2.5),
    backgroundColor: colors.blueGray[150],
    boxShadow: shadows.sm,
    // included bottom border radius only to ensure the bottom overflow is visible but not the top
    borderRadius: "0 0 6px 6px",
    zIndex: 1,
    transition: "all 400ms ease",
    // included to provide the white background w/ a full border-radius
    "&:before": {
      content: '""',
      position: "absolute",
      inset: 0,
      backgroundColor: "white",
      borderRadius: 6,
      zIndex: -1,
    },
    [theme.breakpoints.down("sm")]: {
      padding: SECTION_PADDING / 1.5,
    },
  },
  labTestsSectionSticky: {
    boxShadow: shadows.xl,
  },
}))

/**
 * Uses an IntersectionObserver to detect stickiness.
 *
 * @param rootRef the parent container ref
 * @param stickyRef the sticky element ref
 * @returns whether or not the root element is intersecting with the sticky element
 */
function useIsSticky(
  rootRef: MutableRefObject<HTMLDivElement | null>,
  stickyRef: MutableRefObject<HTMLDivElement | null>
) {
  const [isSticky, setIsSticky] = useState(false)

  useEffect(() => {
    const stickyElement = stickyRef.current
    if (!stickyElement) {
      return
    }

    const observer = new IntersectionObserver(
      (records) => {
        setIsSticky(!records[0].isIntersecting)
      },
      {
        threshold: 0,
        root: rootRef.current,
        rootMargin: "0px 0px -100% 0px",
      }
    )

    observer.observe(stickyElement)

    return function () {
      observer.unobserve(stickyElement)
    }
  }, [])

  return isSticky
}

export default function ComparisonLabTests() {
  const classes = useStyles()
  const hasAnyLabTestData = useAnyComparedLabTestHasProperty("id")
  const innerContainerRef = useRef<HTMLDivElement>(null)
  const labTestsRef = useRef<HTMLDivElement>(null)
  const isLabTestsSticky = useIsSticky(innerContainerRef, labTestsRef)
  return (
    <div className={classes.scrollContainer}>
      <div className={classes.innerContainer} ref={innerContainerRef}>
        <div
          className={clsx(classes.labTestsSection, {
            [classes.labTestsSectionSticky]: isLabTestsSticky,
          })}
          ref={labTestsRef}
        >
          <ComparisonLabTestList />
        </div>
        {hasAnyLabTestData && <ComparisonLabTestDetails />}
      </div>
    </div>
  )
}
