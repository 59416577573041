import * as React from "react"

import * as DropdownMenu from "@radix-ui/react-dropdown-menu"

import BodyText from "app/components/design-system/BodyText"
import { colors, primaryColor, shadows } from "app/theme"
import interleaveChildren from "app/utils/interleave-children"
import makeAppStyles from "app/utils/makeAppStyles"

import ActionButton, { ActionButtonProps } from "./ActionButton"

const useStyles = makeAppStyles((theme) => ({
  content: {
    width: 250,
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(0, 0.5),
    background: "white",
    borderRadius: 6,
    border: `1px solid ${primaryColor}`,
    boxShadow: shadows.lg,
    marginTop: 5,
    overflowY: "auto",
    zIndex: 1300,
  },
  item: {
    display: "flex",
    alignItems: "center",
    padding: "14px 19px",
    cursor: "pointer",
    fontSize: 15,
    color: primaryColor,
    gap: theme.spacing(1.0),

    "&:focus, &:hover": {
      "& .checkbox": {
        borderColor: primaryColor,
      },
    },
  },
  itemLabel: {
    whiteSpace: "nowrap",
  },
  separator: {
    margin: theme.spacing(0, "auto"),
    background: colors.blueGray[200],
    height: 1,
    width: "85%",
  },
}))

export interface ActionDropdownMenuItemProps {
  disabled?: boolean
  icon: NonNullable<React.ReactNode>
  label: string
  onSelect: DropdownMenu.MenuItemProps["onSelect"]
}

export function ActionDropdownMenuItem({
  disabled,
  icon,
  label,
  onSelect,
}: ActionDropdownMenuItemProps) {
  const classes = useStyles()
  return (
    <DropdownMenu.Item
      className={classes.item}
      disabled={disabled}
      onSelect={onSelect}
    >
      {icon}
      <BodyText className={classes.itemLabel} color="primary" weight="semibold">
        {label}
      </BodyText>
    </DropdownMenu.Item>
  )
}

export type ActionDropdownMenuButtonProps = Pick<
  ActionButtonProps,
  "disabled" | "tooltipTitle"
> & {
  buttonProps?: Omit<
    ActionButtonProps,
    "children" | "disabled" | "tooltipTitle"
  >
  children: NonNullable<React.ReactNode>
  icon: NonNullable<React.ReactNode>
}

export default function ActionDropdownMenuButton({
  buttonProps,
  children,
  disabled,
  icon,
  tooltipTitle,
}: ActionDropdownMenuButtonProps) {
  const classes = useStyles()
  return (
    <DropdownMenu.Root modal={false}>
      <DropdownMenu.Trigger asChild>
        <ActionButton
          disabled={disabled}
          tooltipTitle={tooltipTitle}
          {...buttonProps}
        >
          {icon}
        </ActionButton>
      </DropdownMenu.Trigger>

      <DropdownMenu.Portal>
        <DropdownMenu.Content
          align="end"
          className={classes.content}
          onCloseAutoFocus={(e) => {
            e.preventDefault()
          }}
          sideOffset={5}
        >
          {interleaveChildren(children, () => (
            <DropdownMenu.Separator className={classes.separator} />
          ))}
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  )
}
