import { useDispatch } from "react-redux"

import { ORDER_LINE_ITEM_TYPE } from "app/constants"
import useFeatureFlag, { FeatureFlag } from "app/hooks/use-feature-flag"
import useLabCompanyAccounts from "app/main/settings/LabCompanyAccountConnections/hooks/use-lab-company-accounts"
import { LabTest, Order, OrderedTest } from "app/types"
import { getSigningPractitioner } from "app/utils/practitioner-utils"

import {
  COMBO_GROUP_CARD_KEY_PREFIX,
  INDIVIDUAL_CARD_KEY_PREFIX,
  LAB_COMPANY_PANELS,
  getPanelTitle,
  groupLineItems,
} from "../../dataServices/labTestDataService"
import { LabTestItem } from "./LabTestItem"
import PanelCard from "./PanelCard"
import * as Actions from "./store/actions/orders.actions"

export function OrderedTestList({
  order,
  edit,
  openModalWithLabTest,
  setKitActivationIdSelectedFor,
}: {
  edit: boolean
  order: Order
  openModalWithLabTest: (labTest: LabTest) => void
  setKitActivationIdSelectedFor: (setter: (prev: string[]) => string[]) => void
}) {
  const dispatch = useDispatch()

  const [isComboGroupsEnabled] = useFeatureFlag(FeatureFlag.ComboGroupsEnabled)

  const { isLoadingLabCompanyAccounts, companiesWithConfirmedAccounts } =
    useLabCompanyAccounts()

  const lineItemGroups = groupLineItems(order.line_items, isComboGroupsEnabled)

  const orderSigningPractitioner =
    order.signing_practitioner || getSigningPractitioner(order.practitioner)

  const onRemoveTest = (orderedTest: OrderedTest) => {
    dispatch(Actions.removeTest(orderedTest))
  }

  const onTestInstantRequisitionChange = (
    orderedTest: OrderedTest,
    instantRequisition: boolean
  ) => {
    dispatch(
      Actions.updateTest({
        ...orderedTest,
        instant_requisition: instantRequisition,
      })
    )
  }

  const onRemovePanel = (orderedTests: OrderedTest[]) => {
    dispatch(Actions.removePanel(order.id, orderedTests))
  }

  const onPanelInstantRequisitionChange = (
    orderedTests: OrderedTest[],
    instantRequisition: boolean
  ) => {
    dispatch(Actions.updatePanel(order.id, orderedTests, instantRequisition))
  }

  return (
    <div data-testid="ordered-test-list">
      {Object.entries(lineItemGroups).map(([groupKey, lineItems]) => {
        // Tests not grouped by anything
        if (groupKey.startsWith(INDIVIDUAL_CARD_KEY_PREFIX)) {
          return lineItems.flatMap((lineItem) =>
            lineItem.type === ORDER_LINE_ITEM_TYPE.LAB_TEST ? (
              <LabTestItem
                key={lineItem.ordered_test.lab_test.id}
                order={order}
                orderedTest={lineItem.ordered_test}
                labTest={lineItem.ordered_test.lab_test}
                edit={edit}
                status={order.status}
                title={lineItem.title}
                cost={lineItem.cost}
                discountedCost={lineItem.discounted_cost}
                labCompany={lineItem?.ordered_test?.lab_test?.lab_company}
                onRemoveTest={() => onRemoveTest(lineItem.ordered_test)}
                onInstantRequisitionChange={(enabled) =>
                  onTestInstantRequisitionChange(lineItem.ordered_test, enabled)
                }
                orderSigningPractitioner={orderSigningPractitioner}
                setKitActivationIdSelectedFor={setKitActivationIdSelectedFor}
              />
            ) : (
              []
            )
          )
        }

        // Blood panels
        if (LAB_COMPANY_PANELS.includes(groupKey)) {
          // Find the lab company from any of the line items in the group
          const panelLabCompany = lineItems.find(
            (lineItem) => lineItem?.ordered_test?.lab_test?.lab_company
          )?.ordered_test?.lab_test?.lab_company

          // It's unusual (impossible at the time of writing) for a group to not have a
          // `LabTest` line item, but we still need to check.
          if (!panelLabCompany) {
            return null
          }

          let panelTitle = getPanelTitle(groupKey)

          if (!panelLabCompany.only_in_house_phlebotomy) {
            panelTitle = `${panelTitle} (1 kit)`
          }

          return (
            <PanelCard
              key={groupKey}
              order={order}
              lineItems={lineItems}
              panelName={panelTitle}
              labCompany={panelLabCompany}
              status={order.status}
              edit={edit}
              onRemoveTest={onRemoveTest}
              onRemovePanel={onRemovePanel}
              onInstantRequisitionChange={(enabled) =>
                onPanelInstantRequisitionChange(
                  lineItems.flatMap((lineItem) => lineItem.ordered_test || []),
                  enabled
                )
              }
              orderSigningPractitioner={orderSigningPractitioner}
              isLoadingLabCompanyAccounts={isLoadingLabCompanyAccounts}
              companiesWithConfirmedAccounts={companiesWithConfirmedAccounts}
              setKitActivationIdSelectedFor={setKitActivationIdSelectedFor}
            />
          )
        }

        // Combo groups
        if (groupKey.startsWith(COMBO_GROUP_CARD_KEY_PREFIX)) {
          const comboGroupId = groupKey.split(
            `${COMBO_GROUP_CARD_KEY_PREFIX}_`
          )[1]
          const parentLineItem = lineItems.find(
            (lineItem) =>
              lineItem?.ordered_test?.lab_test?.combo_group === comboGroupId
          )

          if (!parentLineItem || !parentLineItem.ordered_test) {
            return null
          }

          return (
            <PanelCard
              key={groupKey}
              order={order}
              lineItems={lineItems}
              panelName={parentLineItem.title}
              labCompany={parentLineItem.ordered_test.lab_test.lab_company}
              status={order.status}
              edit={edit}
              onRemoveTest={onRemoveTest}
              onRemovePanel={onRemovePanel}
              onInstantRequisitionChange={(enabled) =>
                onPanelInstantRequisitionChange(
                  lineItems.flatMap((lineItem) => lineItem.ordered_test || []),
                  enabled
                )
              }
              parentOrderedTest={parentLineItem.ordered_test}
              parentLabTest={parentLineItem.ordered_test.lab_test}
              openModalWithLabTest={openModalWithLabTest}
              orderSigningPractitioner={orderSigningPractitioner}
              comboGroupId={comboGroupId}
              comboGroupOptions={
                parentLineItem.ordered_test.combo_group_options
              }
              setKitActivationIdSelectedFor={setKitActivationIdSelectedFor}
            />
          )
        }

        // Tests with add-ons
        const parentLabTestId = groupKey
        const parentLineItem = lineItems.find(
          (lineItem) => lineItem?.ordered_test?.lab_test?.id === parentLabTestId
        )

        if (!parentLineItem || !parentLineItem.ordered_test) {
          return null
        }

        return (
          <PanelCard
            key={parentLabTestId}
            order={order}
            lineItems={lineItems}
            panelName={parentLineItem.title}
            labCompany={parentLineItem.ordered_test.lab_test.lab_company}
            status={order.status}
            edit={edit}
            onRemoveTest={onRemoveTest}
            onRemovePanel={onRemovePanel}
            onInstantRequisitionChange={(enabled) =>
              onPanelInstantRequisitionChange(
                lineItems.flatMap((lineItem) => lineItem.ordered_test || []),
                enabled
              )
            }
            parentLabTest={parentLineItem.ordered_test.lab_test}
            openModalWithLabTest={openModalWithLabTest}
            orderSigningPractitioner={orderSigningPractitioner}
            setKitActivationIdSelectedFor={setKitActivationIdSelectedFor}
          />
        )
      })}
    </div>
  )
}
