import { useEffect, useState, useRef } from "react"

import moment from "moment"
import { Link } from "react-router-dom"

import { useMediaQuery, Theme } from "@material-ui/core"
import IconButton from "@material-ui/core/IconButton"

import { UserPaths } from "app/Routes/paths"
import { API } from "app/api"
import { ReactComponent as FoodPlanEdit } from "app/assets/icons/food-plan-edit.svg"
import { ReactComponent as FoodPlanWhite } from "app/assets/icons/food-plan-white.svg"
import { ReactComponent as CrossIcon } from "app/assets/icons/gray-cross.svg"
import { ReactComponent as ProfileBlack } from "app/assets/icons/profile-black.svg"
import { ReactComponent as EyeIcon } from "app/assets/icons/rupa-blood-dashboards/eye.svg"
import { ReactComponent as ScheduleDark } from "app/assets/icons/schedule-dark.svg"
import { ReactComponent as RightCaretIcon } from "app/assets/images/right-caret-primary.svg"
import { ReactComponent as BloodReportExample } from "app/assets/images/rupa-blood-dashboard/small-preview.svg"
import Tooltip from "app/components/Tooltip"
import BodyText from "app/components/design-system/BodyText"
import useEventCallback from "app/hooks/use-event-callback"
import useFeatureFlag, { FeatureFlag } from "app/hooks/use-feature-flag"
import useHandleApiError from "app/hooks/use-handle-api-error"
import ResultsPdfPrintButton from "app/main/blood-lab-dashboards/BloodLabDashboardsPdfExport/ResultsPdfPrintButton"
import { ResultsPdfPayload } from "app/main/blood-lab-dashboards/BloodLabDashboardsPdfExport/use-bundle-results-pdf-payload"
import { StyledSwitch } from "app/main/settings/components/StyledSwitch"
import useResultsSummaryModal from "app/results-summary/hooks/use-results-summary-modal"
import { colors, navy, primaryColor } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"
import { OrderedResult } from "types/ordered-result"
import { Patient, PatientSettings } from "types/patient"

import { BloodLabDashboardsDownloadButton } from "./BloodLabDashboardsDownloadButton"

const useStyles = makeAppStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "row",
    marginLeft: 22.5,
    marginRight: 22.5,
    marginTop: 20,
    marginBottom: 20,
    alignItems: "center",
    flexWrap: "wrap",
  },
  rowList: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    columnGap: 13,
    rowGap: 5,
    marginBottom: 5,
  },
  titleText: {
    color: navy,
    fontFamily: "Josefin Sans",
    fontSize: "19px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "150%",
  },
  exampleImage: {
    marginRight: 15,
  },
  buttonContainer: {
    marginLeft: 70,
    marginRight: 20,
    [theme.breakpoints.down("sm")]: {
      flexGrow: 1,
      width: "100%",
      marginLeft: 0,
      marginRight: 0,
      marginTop: 10,
    },
  },
  link: {
    color: primaryColor,
    cursor: "pointer",
    fontWeight: 600,
    display: "flex",
    alignItems: "center",
    whiteSpace: "nowrap",
    [theme.breakpoints.down("xs")]: {
      flexGrow: 1,
      width: "100%",
    },
  },
  icon: {
    marginRight: 3,
  },
  basicText: {
    display: "flex",
    alignItems: "center",
    color: navy,
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "135%",
    whiteSpace: "nowrap",
    [theme.breakpoints.down("xs")]: {
      flexGrow: 1,
      width: "100%",
    },
  },
  informationSection: {
    flex: 1,
  },
  tooltip: {
    textAlign: "left",
  },
  separator: {
    background: colors.blueGray[200],
    height: 1,
    width: "100%",
    marginTop: 10,
    marginBottom: 10,
  },
  switchSection: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  tooltipLink: {
    color: primaryColor,
    cursor: "pointer",
    fontWeight: 600,
  },
  semiBold: {
    fontWeight: 600,
  },
  rightCaret: {
    marginLeft: 3,
  },
  closeButton: {
    [theme.breakpoints.down("sm")]: {
      position: "absolute",
      top: 10,
      right: 10,
    },
  },
}))

export default function BloodLabDashboardsModalHeader({
  onClose,
  downloadableResultUrls,
  orderId,
  patient,
  orderedResult,
  onUpdatePatient,
  showCustomizePatientDashboard,
  pdfPayload,
}: {
  onClose: () => void
  downloadableResultUrls: string[]
  orderId: string
  patient?: Patient
  orderedResult?: OrderedResult
  onUpdatePatient?: () => void
  showCustomizePatientDashboard: boolean
  pdfPayload?: ResultsPdfPayload
}) {
  const classes = useStyles()
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"))
  const resultsSummaryModal = useResultsSummaryModal()
  const [customizeTooltipOpen, setCustomizeTooltipOpen] = useState(false)
  const anchorRef = useRef<HTMLDivElement | null>(null)

  const [isBloodLabDashboardsSnapshotsEnabled] = useFeatureFlag(
    FeatureFlag.RupaBloodReportSnapshots
  )
  const [bloodLabDashboardsPdfPrintEnabled] = useFeatureFlag(
    FeatureFlag.BloodLabDashboardsPdfPrint
  )

  const snapshot =
    orderedResult?.relationships.blood_lab_dashboard_report_snapshots.data?.[0]

  // For handling detecting clicks outside of the customize tooltip to close it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (anchorRef.current && !anchorRef.current.contains(event.target)) {
        setCustomizeTooltipOpen(false)
      }
    }

    document.addEventListener("click", handleClickOutside)

    return () => {
      document.removeEventListener("click", handleClickOutside)
    }
  }, [])

  const handleApiError = useHandleApiError()

  const [patientHighLowEnabled, setPatientHighLowEnabled] = useState(false)

  useEffect(() => {
    if (patient === undefined) return

    setPatientHighLowEnabled(
      patient?.attributes.patient_settings.includes(
        PatientSettings.INCLUDE_HIGH_LOW_DESCRIPTIONS_ON_BLOOD_REPORTS
      )
    )
  }, [patient])

  const onOpenResultsSummary = useEventCallback(() => {
    onClose()
    resultsSummaryModal.show({
      orderId: orderId,
    })
  })

  const patientFullName =
    patient?.attributes.first_name + " " + patient?.attributes.last_name

  function calculateAge(birthday: string | null | undefined) {
    if (birthday === undefined || birthday === null) return ""
    const birthdayDate = new Date(birthday)
    const today = new Date()

    let age = today.getFullYear() - birthdayDate.getFullYear()
    const monthDifference = today.getMonth() - birthdayDate.getMonth()

    if (
      monthDifference < 0 ||
      (monthDifference === 0 && today.getDate() < birthdayDate.getDate())
    ) {
      age--
    }

    return age
  }

  function getBiologicalSex(biologicalSex: string | null | undefined) {
    if (biologicalSex === "M") return "Male"
    if (biologicalSex === "F") return "Female"
    return biologicalSex
  }

  function togglePatientHighLowDescriptions() {
    let newPatientSettings = patient?.attributes.patient_settings
    if (patientHighLowEnabled) {
      newPatientSettings = patient?.attributes.patient_settings.filter(
        (s) =>
          s !== PatientSettings.INCLUDE_HIGH_LOW_DESCRIPTIONS_ON_BLOOD_REPORTS
      )
    } else {
      newPatientSettings = patient?.attributes.patient_settings.concat([
        PatientSettings.INCLUDE_HIGH_LOW_DESCRIPTIONS_ON_BLOOD_REPORTS,
      ])
    }
    setPatientHighLowEnabled(!patientHighLowEnabled)

    API.Patient.patch(patient?.id, {
      patient_settings: newPatientSettings,
    })
      .catch((e) => {
        //reset
        setPatientHighLowEnabled(!patientHighLowEnabled)
        handleApiError(e)
      })
      .then(() => {
        onUpdatePatient?.()
      })
  }

  return (
    <div className={classes.container}>
      {!isMobile && (
        <BloodReportExample
          className={classes.exampleImage}
        ></BloodReportExample>
      )}
      <div className={classes.informationSection}>
        <div className={classes.titleText}>{patientFullName}'s Lab Results</div>
        <div className={classes.rowList}>
          {orderedResult?.attributes.date_samples_observed && (
            <div className={classes.basicText}>
              <ScheduleDark
                className={classes.icon}
                fill={colors.blueGray[500]}
              />
              Collected{" "}
              {moment(orderedResult?.attributes.date_samples_observed).format(
                "MMMM D, YYYY"
              )}
            </div>
          )}
          <div className={classes.basicText}>
            <ProfileBlack className={classes.icon} />
            {patient?.attributes.biological_sex &&
            patient?.attributes.biological_sex !== "None"
              ? `${getBiologicalSex(patient?.attributes.biological_sex)}`
              : ""}
            {patient?.attributes.birthday
              ? ` • ${calculateAge(patient?.attributes.birthday)}`
              : ""}
          </div>
          {orderedResult?.attributes.requires_fasting && (
            <div className={classes.basicText}>
              <FoodPlanWhite
                fill={colors.blueGray[500]}
                className={classes.icon}
              />
              Fasted
            </div>
          )}
        </div>
        <div className={classes.rowList}>
          {orderedResult && showCustomizePatientDashboard && (
            <Link
              className={classes.link}
              to={
                isBloodLabDashboardsSnapshotsEnabled && snapshot
                  ? `/blood-lab-dashboard-snapshot/${snapshot?.id}/patient-preview`
                  : `/blood-lab-dashboard/${orderedResult?.id}/patient-preview`
              }
              target="_blank"
              rel="noopener noreferrer"
            >
              <EyeIcon className={classes.icon} />
              View as {patient?.attributes.first_name || "Patient"}
            </Link>
          )}
          {showCustomizePatientDashboard && (
            <Tooltip
              open={customizeTooltipOpen}
              interactive
              arrow
              title={
                <div className={classes.tooltip}>
                  <div className={classes.switchSection}>
                    <div>
                      <BodyText weight="semibold">
                        Include High/Low Descriptions on{" "}
                        {patient?.attributes.first_name}'s Dashboard
                      </BodyText>
                      <BodyText weight="regular">
                        A high or low interpretation is included when a marker
                        is out of range.
                      </BodyText>
                    </div>
                    <div>
                      <StyledSwitch
                        onChange={togglePatientHighLowDescriptions}
                        checked={patientHighLowEnabled}
                      ></StyledSwitch>
                    </div>
                  </div>
                  <div className={classes.separator}></div>
                  <div>
                    <BodyText weight="regular">
                      Want to customize ranges and descriptions individually for{" "}
                      {patient?.attributes.first_name || "your patient"}?
                    </BodyText>
                    <div
                      className={classes.tooltipLink}
                      onClick={onOpenResultsSummary}
                    >
                      Create a results summary
                      <RightCaretIcon
                        className={classes.rightCaret}
                        fill={primaryColor}
                      />
                    </div>
                  </div>
                  <div className={classes.separator}></div>
                  <span>
                    <BodyText>
                      <span className={classes.semiBold}>Optimal ranges</span>{" "}
                      and{" "}
                      <span className={classes.semiBold}>
                        high/low descriptions
                      </span>{" "}
                      can be customized for each biomarker in{" "}
                      <Link
                        className={classes.tooltipLink}
                        onClick={() => {
                          onClose()
                        }}
                        to={UserPaths.RUPA_BLOOD_DASHBOARDS}
                      >
                        Settings
                        <RightCaretIcon
                          fill={primaryColor}
                          className={classes.rightCaret}
                        />
                      </Link>
                    </BodyText>
                  </span>
                </div>
              }
            >
              <div
                className={classes.link}
                ref={anchorRef}
                onClick={() => {
                  setCustomizeTooltipOpen(!customizeTooltipOpen)
                }}
              >
                <FoodPlanEdit className={classes.icon} />
                Customize
              </div>
            </Tooltip>
          )}
          {pdfPayload && bloodLabDashboardsPdfPrintEnabled && (
            <ResultsPdfPrintButton payload={pdfPayload} />
          )}
        </div>
      </div>
      <div className={classes.buttonContainer}>
        <BloodLabDashboardsDownloadButton
          color={"secondary"}
          downloadableResultUrls={downloadableResultUrls}
          orderedResultId={orderedResult?.id ? orderedResult?.id : ""}
        />
      </div>
      <div>
        <IconButton
          aria-label="close"
          onClick={onClose}
          className={classes.closeButton}
        >
          <CrossIcon />
        </IconButton>
      </div>
    </div>
  )
}
