import { useMemo } from "react"

import useCachedCollection from "app/swr/hooks/use-cached-collection"
import useCollectionSWR from "app/swr/hooks/use-collection-swr"
import { ResourceCollection } from "app/swr/types"
import { OptionType, PractitionerType } from "app/types"
import sortBy from "lodash/sortBy"
import { PractitionerTypeNormalized } from "types/practitioner-type"

export type PractitionerTypeOption = OptionType & PractitionerType

export default function usePractitionerTypes() {
  const { data, ...swr } = useCollectionSWR<ResourceCollection>(
    "practitioner_types/"
  )
  const practitionerTypes =
    useCachedCollection<PractitionerTypeNormalized>(data)
  return { data: practitionerTypes, ...swr }
}

export function usePractitionerTypeOptions(
  additionalOptions?: PractitionerType[]
) {
  const swr = usePractitionerTypes()

  const practitionerTypeOptions = useMemo(() => {
    let optionsMap: { [key: string]: PractitionerTypeOption } = {}

    if (swr.data) {
      swr.data.forEach((pt) => {
        const id = pt.id.toString()
        optionsMap[id] = {
          id,
          value: id,
          label: pt.attributes.display_name ?? pt.attributes.name,
          ...pt.attributes,
        }
      })
    }

    if (additionalOptions) {
      additionalOptions.forEach((pt) => {
        const id = pt.id.toString()
        optionsMap[id] = {
          value: id,
          label: pt.display_name ?? pt.name,
          ...pt,
        }
      })
    }

    return sortBy(Object.values(optionsMap), "label")
  }, [swr.data])

  return { practitionerTypeOptions, ...swr }
}
