import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Theme,
} from "@material-ui/core"
import IconButton from "@material-ui/core/IconButton"
import CloseIcon from "@material-ui/icons/Close"

import BodyText from "app/components/design-system/BodyText"
import DesignSystemButton from "app/components/design-system/Button"
import { colors, navy, white } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"

const styles = makeAppStyles((theme: Theme) => ({
  closeButton: {
    color: navy,
  },
  continueButtonContainer: {
    padding: "16px 24px",
  },
  continueButton: {
    marginRight: "13px",
  },
  dialogPaper: {
    margin: 15,
    borderRadius: 7,
    width: "800px",
    [theme.breakpoints.down("sm")]: {
      width: "96%",
      height: "100%",
      margin: 0,
    },
  },
  modalPage: {
    display: "flex",
    flexFlow: "column",
    alignItems: "stretch",
    overflow: "auto",
    height: "100%",
    background: white,
  },
  modalContent: {
    display: "flex",
    padding: "22.5px",
    flexDirection: "column",
    background: colors.coolGray[100],
  },
  testList: {
    marginTop: "3px",
    paddingLeft: "16px",
    "& > li:before": {
      margin: "0 5px 0 -15px",
    },
  },
  modalHeader: {
    "& h2": {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
    },
  },
}))

export const FastingDisableModal = ({
  labTests,
  showModal,
  isToggling,
  onExit,
  onContinue,
}) => {
  const classes = styles()

  if (!showModal) {
    return null
  }

  const disableFasting = () => {
    console.log("Disabling fasting")
    onContinue()
  }

  return (
    <Dialog
      aria-labelledby="fasting-disable-checkbox"
      classes={{ paper: classes.dialogPaper }}
      open={showModal}
      onClose={onExit}
    >
      <div className={classes.modalPage}>
        <DialogTitle className={classes.modalHeader}>
          <span>Are you sure?</span>
          <IconButton
            aria-label="close"
            onClick={onExit}
            className={classes.closeButton}
            key="close-button"
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className={classes.modalContent}>
          <BodyText weight="semibold">
            Based on our information, the following tests require fasting:
          </BodyText>
          <ul className={classes.testList}>
            {labTests.map((test) => (
              <li key={test.id}>{test.title}</li>
            ))}
          </ul>

          <BodyText weight="semibold">
            <br />
            To get the most accurate results, we recommend keeping “Require
            Fasting” checked or removing the test from your cart.
          </BodyText>
        </DialogContent>
        <DialogActions className={classes.continueButtonContainer}>
          <DesignSystemButton
            className={classes.continueButton}
            onClick={onExit}
            color="noaction"
            type="noaction"
            disabled={isToggling}
          >
            Go Back
          </DesignSystemButton>
          <DesignSystemButton
            variant="contained"
            onClick={disableFasting}
            color="destructiveSecondary"
            type="destructiveSecondary"
            loading={isToggling}
          >
            Don’t Require Fasting
          </DesignSystemButton>
        </DialogActions>
      </div>
    </Dialog>
  )
}
