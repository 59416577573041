import { AppThunkAction, LabCompany, LabTestListItemCompany } from "app/types"

export const SET_OVERRIDE_LAB_TEST = "SET OVERRIDE LAB TEST"
export const RESET_OVERRIDE_LAB_TEST = "RESET OVERRIDE LAB TEST"

export interface SetOverrideLabTestAction {
  type: typeof SET_OVERRIDE_LAB_TEST
  payload: {
    labCompany: LabCompany | null
    unavailableState?: string | undefined
  }
}

export interface ResetOverrideLabTestAction {
  type: typeof RESET_OVERRIDE_LAB_TEST
  payload: {}
}

export type ActionTypes = SetOverrideLabTestAction | ResetOverrideLabTestAction

/**
 * Set data for the overrideaccessmodal to display and send api request.
 *
 * @param labCompany Lab Company to override access to
 * @param unavailableState State that is unavailable
 * @returns
 */
export function setLabTestForOverride(
  labCompany: LabCompany | LabTestListItemCompany,
  unavailableState?: string
): AppThunkAction<void> {
  return (dispatch) => {
    dispatch({
      type: SET_OVERRIDE_LAB_TEST,
      payload: {
        labCompany,
        unavailableState,
      },
    })
  }
}

/**
 * Reset data to hide the overrideaccessmodal.
 */
export function resetLabTestForOverride(): AppThunkAction<void> {
  return (dispatch) => {
    dispatch({
      type: RESET_OVERRIDE_LAB_TEST,
      payload: {},
    })
  }
}
