import ArrowTopRight from "app/assets/icons/arrow-top-right.svg"
import { ReactComponent as FastingIcon } from "app/assets/images/patient-instructions/fasting.svg"
import BodyText from "app/components/design-system/BodyText"
import { colors, primaryColor, navy } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"

import PatientPortalBanner from "./PatientPortalBanner"

const useStyles = makeAppStyles<{ iconColor: string }>((theme) => ({
  text: {
    color: navy,
    fontFamily: '"Open Sans", sans-serif',
    fontSize: "15px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "135%",
    paddingRight: 50,
  },
  bookingLink: {
    color: primaryColor,
  },
  icon: (props) => ({
    marginBottom: 2,

    "& path": {
      fill: props.iconColor,
    },
  }),
}))

export interface PatientPortalBookingBannerProps {
  fastingDurationHours?: number | null
  requiresFasting?: boolean
  withBackground?: boolean
  instructionsOnClick?: () => void
}

export default function PatientPortalFastingBanner({
  fastingDurationHours,
  requiresFasting = true,
  withBackground = true,
  instructionsOnClick,
}: PatientPortalBookingBannerProps) {
  const classes = useStyles({
    iconColor: requiresFasting ? colors.pink[600] : navy,
  })

  const instructionsLink = instructionsOnClick ? (
    <div
      role="button"
      onClick={instructionsOnClick}
      className="text-primary inline-flex items-center"
    >
      See Instructions
      <img className="ml-1 w-2.5 h-2.5" src={ArrowTopRight} alt="" />
    </div>
  ) : null

  if (!requiresFasting) {
    return (
      <PatientPortalBanner
        icon={<FastingIcon className={classes.icon} />}
        borderColor={colors.blueGray[50]}
        backgroundColor={colors.blueGray[50]}
        iconBackgroundColor={colors.blueGray[100]}
        withBackground={withBackground}
      >
        <BodyText size={withBackground ? "sm" : "xs"} weight="semibold">
          {instructionsOnClick ? (
            <>
              Fasting is <strong>not required</strong> for this test!{" "}
              {instructionsLink}
            </>
          ) : (
            <>You’re not required to fast before your blood draw.</>
          )}
        </BodyText>
      </PatientPortalBanner>
    )
  }

  return (
    <PatientPortalBanner
      icon={<FastingIcon className={classes.icon} fill={colors.pink[600]} />}
      borderColor={colors.pink[50]}
      backgroundColor={colors.pink[50]}
      iconBackgroundColor={colors.pink[100]}
      withBackground={withBackground}
    >
      <BodyText size={withBackground ? "sm" : "xs"} weight="semibold">
        Fasting is required! Fast for{" "}
        {fastingDurationHours ? fastingDurationHours : "12"} hours prior to
        collecting your sample. {instructionsLink}
      </BodyText>
    </PatientPortalBanner>
  )
}
