import { FieldError } from "react-hook-form"

import { FormHelperText } from "@material-ui/core"
import { PaymentMethod } from "@stripe/stripe-js"

import BodyText from "app/components/design-system/BodyText"
import makeAppStyles from "app/utils/makeAppStyles"

const useStyles = makeAppStyles((theme) => ({
  text: {
    display: "inline-flex",
    flexDirection: "column",
    gap: theme.spacing(1.0),
  },
}))

export default function PaymentCollectionStripeCardReadOnly({
  amountFormatted,
  emptyMessage,
  error,
  paymentMethodData,
}: {
  amountFormatted: string
  emptyMessage: string
  error?: FieldError
  paymentMethodData?: PaymentMethod
}) {
  const classes = useStyles()
  if (!paymentMethodData?.card) {
    return (
      <BodyText className={classes.text} size="sm">
        {emptyMessage}
        {error && <FormHelperText error>{error.message}</FormHelperText>}
      </BodyText>
    )
  }
  return (
    <BodyText className={classes.text} size="sm">
      {`${paymentMethodData.card.brand.toUpperCase()} **** **** **** ${
        paymentMethodData.card.last4
      } will be charged ${amountFormatted}`}
      {error && <FormHelperText error>{error.message}</FormHelperText>}
    </BodyText>
  )
}
