import { useState } from "react"

import clsx from "clsx"

import { useMediaQuery, Theme } from "@material-ui/core"

import { ReactComponent as DownArrow } from "app/assets/icons/carets/down-caret-dark-blue.svg"
import BodyText from "app/components/design-system/BodyText"
import useCachedResource from "app/swr/hooks/use-cached-resource"
import { primaryColor, colors } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"
import { Biomarker } from "types/biomarker"
import { BiomarkerCustomDescription } from "types/biomarker-custom-description"

import BloodLabDashboardsBiomarkerCard from "./BloodLabDashboardsBiomarkerCard"

const useStyles = makeAppStyles((theme) => ({
  valueSection: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginTop: 10,
    },
  },
  graphicSection: {
    width: "100",
    color: colors.blueGray[400],
    textAlign: "center",
    fontFamily: "Open Sans",
    fontSize: "13px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "130%",
  },
  detailsLink: {
    color: primaryColor,
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      marginLeft: 15,
    },
  },
  arrow: {
    marginLeft: 5,
    transition: "transform 0.2s ease-in-out",
  },
  arrowFlipped: {
    transform: "rotate(180deg)",
  },
  biomarkerValue: {
    fontSize: 17,
    fontWeight: 600,
    marginLeft: 5,
  },
}))

export default function BloodLabDashboardsResultsMissingBiomarkerCard({
  biomarkerId,
  biomarkerCustomDescription,
}: {
  biomarkerId: string
  biomarkerCustomDescription?: BiomarkerCustomDescription
}) {
  const classes = useStyles()
  const [isExpanded, setIsExpanded] = useState(false)
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"))

  const detailsSection = (
    <div
      className={classes.detailsLink}
      onClick={() => setIsExpanded(!isExpanded)}
    >
      Details
      <DownArrow
        className={clsx(classes.arrow, isExpanded ? classes.arrowFlipped : "")}
        fill={primaryColor}
      />
    </div>
  )

  const biomarker = useCachedResource<Biomarker>({
    type: "biomarker",
    id: biomarkerId,
  })

  const description = biomarkerCustomDescription?.attributes.description
    ? biomarkerCustomDescription?.attributes.description
    : biomarker?.attributes.description

  if (!biomarker) {
    return null
  }

  return (
    <BloodLabDashboardsBiomarkerCard
      name={biomarker?.attributes.short_name}
      valueSection={
        <div className={classes.valueSection}>
          <BodyText className={classes.biomarkerValue}>--</BodyText>
          {isMobile && detailsSection}
        </div>
      }
      graphicSection={
        <div className={classes.graphicSection}>RESULT NOT IN YET</div>
      }
    >
      {description}
    </BloodLabDashboardsBiomarkerCard>
  )
}
