import { makeStyles } from "@material-ui/core"

import { ReactComponent as AddressArrow } from "app/assets/icons/address-arrow.svg"
import { ReactComponent as CalendarIcon } from "app/assets/icons/calendar.svg"
import { ReactComponent as ClockOutline } from "app/assets/icons/clock-outline.svg"
import { ReactComponent as UpRightArrowIcon } from "app/assets/icons/up-right-arrow.svg"
import BodyText from "app/components/design-system/BodyText"
import { PHLEBOTOMY_BOOKING_FROM_LOCATIONS } from "app/main/schedule-blood-draw/constants"
import { attachFromLocationToUrl } from "app/main/schedule-blood-draw/utils"
import PatientPortalBookingBanner from "app/patient-portal/order/components/PatientPortalBookingBanner"
import {
  trackPhlebotomyBookingEvent,
  PHLEBOTOMY_BOOKING_EVENTS,
} from "app/services/segment"
import { colors, navy, primaryColor } from "app/theme"
import { AxleSchedulingData } from "app/types"
import { PHLEBOTOMY_PROVIDERS } from "app/types"

interface Props {
  axleSchedulingData: AxleSchedulingData
}

const useStyles = makeStyles({
  container: {
    width: "100%",
    paddingBottom: 13,
  },
  introCopy: {
    paddingBottom: 22,
  },
  markdownMessage: {
    color: navy,
    fontWeight: 600,
  },
  title: {
    paddingTop: 13,
    paddingBottom: 16,
  },
  subtitle: {
    fontFamily: "Open Sans",
    fontSize: "15px",
    marginBottom: 16,
  },
  appointmentDetailsText: {
    fontFamily: "Open Sans",
    fontSize: "15px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "135%",
    display: "flex",
    alignItems: "center",
  },
  appointmentDetailsSection: {
    gap: 9,
    display: "flex",
    flexDirection: "column",
    marginBottom: 48,
  },
  separator: {
    width: "100%",
    height: 1,
    backgroundColor: colors.blueGray[200],
    marginTop: 16,
    marginBottom: 16,
  },
  rescheduleText: {
    fontFamily: "Open Sans",
    fontSize: "15px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "135%",
  },
  disclaimerText: {
    color: colors.blueGray[500],
    fontFamily: "Open Sans",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "135%",
  },
  link: {
    color: primaryColor,
    fontWeight: 600,
  },
  icon: {
    marginRight: 8,
  },
  emailLink: {
    color: navy,
    textDecoration: "underline",
  },
})

const ScheduleMobileBloodDraw = ({ axleSchedulingData }: Props) => {
  const classes = useStyles()

  if (axleSchedulingData.phlebotomy_booking_link) {
    return (
      <div>
        <PatientPortalBookingBanner
          bookingUrl={attachFromLocationToUrl(
            axleSchedulingData.phlebotomy_booking_link,
            PHLEBOTOMY_BOOKING_FROM_LOCATIONS.PATIENT_PORTAL_INSTRUCTIONS
          )}
        />
      </div>
    )
  }

  const faqLink = (
    <a
      className={classes.link}
      href="https://support.rupahealth.com/en/articles/8599332-your-axle-health-blood-draw-faq"
      target="_blank"
      rel="noopener noreferrer"
    >
      Visit mobile blood draw FAQ <UpRightArrowIcon />
    </a>
  )

  const appointmentStartUtc = axleSchedulingData?.visit_start
    ? new Date(axleSchedulingData?.visit_start)
    : null
  const appointmentEndUtc = axleSchedulingData?.visit_end
    ? new Date(axleSchedulingData?.visit_end)
    : null

  if (axleSchedulingData?.visit_status === "canceled") {
    return (
      <div>
        <BodyText weight="semibold" size="lg" className={classes.title}>
          Your mobile blood draw appointment is paid for but has been canceled
        </BodyText>
        <div>
          Need to reschedule your blood draw? Please reach out to{" "}
          <a
            role="button"
            className={classes.emailLink}
            href="mailto: support@rupahealth.com"
          >
            support@rupahealth.com
          </a>
          .
        </div>
        <br />
        {faqLink}
      </div>
    )
  }

  return (
    <div>
      <BodyText weight="semibold" size="lg" className={classes.title}>
        Mobile Blood Draw Appointment Details
      </BodyText>
      <div className={classes.subtitle}>
        Your mobile blood draw appointment:
      </div>
      <div className={classes.appointmentDetailsSection}>
        {axleSchedulingData?.address && (
          <BodyText size="md" className={classes.appointmentDetailsText}>
            <AddressArrow
              fill={colors.blueGray[500]}
              className={classes.icon}
            />
            {axleSchedulingData.address}
          </BodyText>
        )}
        {appointmentStartUtc && (
          <BodyText size="md" className={classes.appointmentDetailsText}>
            <CalendarIcon
              fill={colors.blueGray[500]}
              className={classes.icon}
            />
            {appointmentStartUtc.toLocaleDateString("en-us", {
              weekday: "long",
              year: "numeric",
              month: "short",
              day: "numeric",
            })}
          </BodyText>
        )}
        {appointmentStartUtc && appointmentEndUtc && (
          <BodyText size="md" className={classes.appointmentDetailsText}>
            <ClockOutline
              fill={colors.blueGray[500]}
              className={classes.icon}
            />
            {appointmentStartUtc.toLocaleTimeString("en-us", {
              hour: "numeric",
              minute: "numeric",
              hour12: true,
            })}{" "}
            -{" "}
            {appointmentEndUtc.toLocaleTimeString("en-us", {
              hour: "numeric",
              minute: "numeric",
              hour12: true,
            })}
          </BodyText>
        )}
      </div>
      <div className={classes.rescheduleText}>
        Need to reschedule or cancel?{" "}
        <a
          className={classes.link}
          href={axleSchedulingData?.axle_reschedule_link}
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => {
            trackPhlebotomyBookingEvent(
              PHLEBOTOMY_BOOKING_EVENTS.PHLEBOTOMY_RESCHEDULE_APPOINTMENT,
              { provider: PHLEBOTOMY_PROVIDERS.AXLE }
            )
          }}
        >
          Use this link
        </a>
      </div>
      <br></br>
      <div className={classes.disclaimerText}>
        Please note, we offer free cancellation and reschedules up to 24 hours
        before your appointment time. There are no refunds within 24 hours of
        your appointment time.
        <br></br>
        <br></br>
        {faqLink}
      </div>

      <div className={classes.separator}></div>
      <div>
        Need to draw blood at another location or have other concerns? Please
        reach out to support@rupahealth.com.
      </div>
    </div>
  )
}

export default ScheduleMobileBloodDraw
