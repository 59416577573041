import { ReactComponent as GreenCheck } from "app/assets/icons/green-check.svg"
import { ReactComponent as DownArrowIcon } from "app/assets/icons/rupa-blood-dashboards/down-arrow.svg"
import { ReactComponent as UpArrowIcon } from "app/assets/icons/rupa-blood-dashboards/up-arrow.svg"
import { colors } from "app/theme"

import { BiomarkerStatus } from "../../../../patient-portal/blood-lab-dashboard/constants"

interface Props {
  biomarkerStatus?: BiomarkerStatus | null
}

const BloodLabDashboardsBiomarkerStatusIndicator = ({
  biomarkerStatus,
}: Props) => {
  if (biomarkerStatus === BiomarkerStatus.BELOW_OPTIMAL) {
    return <DownArrowIcon fill={colors.lime[600]} />
  } else if (biomarkerStatus === BiomarkerStatus.ABOVE_OPTIMAL) {
    return <UpArrowIcon fill={colors.lime[600]} />
  } else if (biomarkerStatus === BiomarkerStatus.HIGH) {
    return <UpArrowIcon fill={colors.red[500]} />
  } else if (biomarkerStatus === BiomarkerStatus.LOW) {
    return <DownArrowIcon fill={colors.red[500]} />
  } else if (
    biomarkerStatus === BiomarkerStatus.OPTIMAL ||
    biomarkerStatus === BiomarkerStatus.NORMAL
  ) {
    return <span>{<GreenCheck />}</span>
  } else {
    return <></>
  }
}

export default BloodLabDashboardsBiomarkerStatusIndicator
