import { useEffect } from "react"

import { useHistory, useLocation } from "react-router-dom"
import { usePrevious } from "react-use"

import { AnyLimitedLabTest } from "app/types"

interface UseDetailModalProps {
  labTests: AnyLimitedLabTest[] | null
  labTestInModal: AnyLimitedLabTest | null
  setLabTestInModal: (test: AnyLimitedLabTest) => void
  syncWithUrl?: boolean
}

const useDetailModal = ({
  labTests,
  labTestInModal,
  setLabTestInModal,
  syncWithUrl = false,
}: UseDetailModalProps) => {
  const history = useHistory()
  const location = useLocation()

  const previouslySelectedLabTest = usePrevious(labTestInModal)

  // Open the details modal when the URL contains the `lab-test-id` query parameter
  useEffect(() => {
    if (!syncWithUrl) {
      return
    }

    const params = new URLSearchParams(location.search)
    const labTestID = params.get("lab-test-id")

    if (labTests && labTestID && !labTestInModal) {
      const labTest = labTests.find(({ id }) => id === labTestID)

      if (labTest) {
        setLabTestInModal(labTest)
      }
    }
  }, [location.search, labTests])

  useEffect(() => {
    if (!syncWithUrl) {
      return
    }

    const params = new URLSearchParams(location.search)
    const labTestID = params.get("lab-test-id")
    if (labTestInModal && !labTestID) {
      params.append("lab-test-id", labTestInModal.id)
      history.replace({
        search: "?".concat(params.toString()),
      })
    } else if (!labTestInModal && previouslySelectedLabTest && labTestID) {
      params.delete("lab-test-id")
      history.replace({
        search: "?".concat(params.toString()),
      })
    }
  }, [labTestInModal, previouslySelectedLabTest])
}

export default useDetailModal
