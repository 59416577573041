import { memo, useMemo, useState } from "react"

import clsx from "clsx"
import { lowerCase } from "lodash"

import { ReactComponent as CrossCircleIcon } from "app/assets/icons/circular-cross.svg"
import { ReactComponent as CheckCircleIcon } from "app/assets/images/circular-check-filled.svg"
import { COLUMN_PADDING, COLUMN_WIDTH } from "app/main/comparison/constants"
import useAllBiomarkersForComparedLabTests from "app/main/comparison/hooks/use-all-biomarkers-for-compared-lab-tests"
import useLabTestIds from "app/main/comparison/hooks/use-lab-test-ids"
import { cancelRed, colors, textPrimaryColor } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"

import ComparisonBiomarkersSearchInput from "./ComparisonBiomarkersSearchInput"

const useStyles = makeAppStyles((theme) => ({
  searchGridContainer: {
    display: "flex",
    flexDirection: "column",
    gap: COLUMN_PADDING,
  },
  searchGridRows: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1.0),
  },
  searchGridRow: {
    display: "flex",
    flexFlow: "row nowrap",
  },
  searchGridRowHidden: {
    display: "none",
  },
  searchGridColumn: {
    width: COLUMN_WIDTH,
    padding: `0 ${COLUMN_PADDING}px`,
    display: "flex",
    alignItems: "center",
    gap: 5,
    fontSize: 15,
    fontWeight: theme.typography.fontWeightRegular,
    lineHeight: 1.35,
    "& > svg": {
      minWidth: 15,
      width: 15,
      height: 15,
    },
  },
  searchGridColumnIncluded: {
    color: textPrimaryColor,
    "& > svg": {
      fill: colors.green[500],
    },
  },
  searchGridColumnNotIncluded: {
    color: colors.blueGray[400],
    textDecorationLine: "line-through",
    "& > svg": {
      fill: cancelRed,
    },
  },
  searchGridEmptyContainer: {
    padding: `0 ${COLUMN_PADDING}px`,
    fontSize: 15,
    fontWeight: theme.typography.fontWeightRegular,
    lineHeight: 1.35,
    color: colors.blueGray[500],
  },
}))

interface ComparisonBiomarkerGridColumnProps {
  biomarker: ReturnType<
    typeof useAllBiomarkersForComparedLabTests
  >[number]["biomarker"]
  classes: ReturnType<typeof useStyles>
}

const ComparisonBiomarkerIncluded = memo(
  ({ biomarker, classes }: ComparisonBiomarkerGridColumnProps) => {
    return (
      <div
        className={clsx(
          classes.searchGridColumn,
          classes.searchGridColumnIncluded
        )}
      >
        <CheckCircleIcon viewBox="0 0 15 16" />
        <span>{biomarker.long_name}</span>
      </div>
    )
  }
)

const ComparisonBiomarkerNotIncluded = memo(
  ({ biomarker, classes }: ComparisonBiomarkerGridColumnProps) => {
    return (
      <div
        className={clsx(
          classes.searchGridColumn,
          classes.searchGridColumnNotIncluded
        )}
      >
        <CrossCircleIcon viewBox="0 0 23 23" />
        <span>{biomarker.long_name}</span>
      </div>
    )
  }
)

type ComparisonBiomarkerGridRowProps = ReturnType<
  typeof useAllBiomarkersForComparedLabTests
>[number] & {
  classes: ReturnType<typeof useStyles>
  comparedLabTestIds: string[]
  isVisible: boolean
}

const ComparisonBiomarkerGridRow = memo(
  ({
    biomarker,
    classes,
    comparedLabTestIds,
    includedLabTestIds,
    isVisible,
  }: ComparisonBiomarkerGridRowProps) => {
    return (
      <div
        className={clsx(classes.searchGridRow, {
          [classes.searchGridRowHidden]: !isVisible,
        })}
      >
        {comparedLabTestIds.map((comparedLabTestId) =>
          includedLabTestIds.includes(comparedLabTestId) ? (
            <ComparisonBiomarkerIncluded
              key={comparedLabTestId}
              biomarker={biomarker}
              classes={classes}
            />
          ) : (
            <ComparisonBiomarkerNotIncluded
              key={comparedLabTestId}
              biomarker={biomarker}
              classes={classes}
            />
          )
        )}
      </div>
    )
  }
)

const ComparisonBiomarkersSearchGridEmpty = ({
  classes,
  search,
}: {
  classes: ReturnType<typeof useStyles>
  search: string
}) => {
  return (
    <div className={classes.searchGridEmptyContainer}>
      {search
        ? `No biomarkers found for "${search}". Try searching something different.`
        : "No biomarkers found."}
    </div>
  )
}

export default function ComparisonBiomarkersSearchGrid() {
  const classes = useStyles()
  const comparedLabTestIds = useLabTestIds()
  const [search, setSearch] = useState("")
  const allBiomarkers = useAllBiomarkersForComparedLabTests()
  const visibleBiomarkers = useMemo(() => {
    const lowerCaseSearch = lowerCase(search)
    return allBiomarkers.filter(
      ({ biomarker }) =>
        lowerCase(biomarker.long_name).includes(lowerCaseSearch) ||
        lowerCase(biomarker.short_name).includes(lowerCaseSearch)
    )
  }, [allBiomarkers, search])
  return (
    <div className={classes.searchGridContainer}>
      <ComparisonBiomarkersSearchInput onSearch={setSearch} search={search} />
      <div className={classes.searchGridRows}>
        {visibleBiomarkers.length === 0 && (
          <ComparisonBiomarkersSearchGridEmpty
            classes={classes}
            search={search}
          />
        )}
        {allBiomarkers.map((biomarkerData) => (
          <ComparisonBiomarkerGridRow
            key={biomarkerData.biomarker.id}
            classes={classes}
            comparedLabTestIds={comparedLabTestIds}
            isVisible={visibleBiomarkers.includes(biomarkerData)}
            {...biomarkerData}
          />
        ))}
      </div>
    </div>
  )
}
