import { useState } from "react"
import * as React from "react"

import { Card, Collapse, Grid } from "@material-ui/core"
import { Theme, makeStyles } from "@material-ui/core/styles"

import { ReactComponent as DownCaretIcon } from "app/assets/icons/carets/down-caret.svg"
import { ReactComponent as RightCaretIcon } from "app/assets/icons/carets/right-caret.svg"
import BodyText from "app/components/design-system/BodyText"
import { ControlledCheckboxInput } from "app/patient-portal/fields/components"
import useCachedCollection from "app/swr/hooks/use-cached-collection"
import { ResourceIdentifier } from "app/swr/types"
import { colors, primaryColor, white } from "app/theme"

import { WebflowFieldOption } from "../types"

interface StyleProps {
  hasSelected: boolean
  isCollapsed: boolean
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  card: {
    backgroundColor: white,
    border: "1px solid",
    borderColor: colors.blueGray[300],
    borderRadius: "8px",
    width: "100%",
    minWidth: "600px",
    boxShadow: "none",
    [theme.breakpoints.down("sm")]: {
      minWidth: "unset",
    },
  },
  header: {
    backgroundColor: white,
    borderRadius: "8px",
    padding: "16px",
    display: "flex",
    alignItems: "center",
  },
  textContainer: {
    display: "flex",
    alignItems: "center",
  },
  headerText: (props) => ({
    fontWeight: 600,
    fontSize: "15px",
    lineHeight: "135%",
    display: "flex",
    alignItems: "center",
    color:
      props.hasSelected && props.isCollapsed
        ? primaryColor
        : colors.blueGray[300],
    marginLeft: "10px",
    flexGrow: 0,
  }),
  checkbox: {
    padding: "4px 8px",
  },
  expandIcon: {
    marginLeft: "auto",
  },
  openIcon: {
    color: primaryColor,
  },
}))

interface MultiCheckboxDropdownProps {
  displayField: string
  choices: ResourceIdentifier[]
  fieldName: string
  numberSelected: number
}

const MultiCheckboxDropdown: React.FC<MultiCheckboxDropdownProps> = ({
  displayField,
  choices,
  fieldName,
  numberSelected,
}) => {
  const [isCollapsed, setIsCollapsed] = useState(true)
  const options = useCachedCollection<WebflowFieldOption>(choices)

  const classes = useStyles({ hasSelected: numberSelected > 0, isCollapsed })

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed)
  }

  return (
    <Card className={classes.card}>
      <div className={classes.header} onClick={toggleCollapse}>
        <div className={classes.textContainer}>
          <div className={classes.headerText}>
            {numberSelected > 0 && isCollapsed
              ? `${numberSelected} selected`
              : `Select ${displayField}`}
          </div>
        </div>
        {isCollapsed ? (
          <RightCaretIcon
            className={classes.expandIcon}
            fill={primaryColor}
            width={14}
            height={14}
          />
        ) : (
          <DownCaretIcon
            className={classes.expandIcon + " " + classes.openIcon}
            fill={primaryColor}
            width={14}
            height={14}
          />
        )}
      </div>
      <Collapse in={!isCollapsed}>
        <div style={{ padding: "15px" }}>
          <Grid container>
            {options.map((webflowOption) => (
              <Grid item xs={6} key={webflowOption.id}>
                <ControlledCheckboxInput
                  defaultValue={false}
                  label={
                    <BodyText color="textPrimary" size="base">
                      {webflowOption?.attributes?.field_name}
                    </BodyText>
                  }
                  name={`${fieldName}-${webflowOption?.id}`}
                />
              </Grid>
            ))}
          </Grid>
        </div>
      </Collapse>
    </Card>
  )
}

export default MultiCheckboxDropdown
