import history from "@history"
import axios from "axios"

import { API } from "app/api"
import {
  LimitOffsetPaginatedResponse,
  Patient,
  PatientListPatient,
} from "app/types"
import { getApiBaseUrl, handleApiError } from "app/utils"

export const GET_PATIENTS = "[DASHBOARD] GET PATIENTS"
export const ADD_PATIENT = "[DASHBOARD] ADD PATIENT"

type GetPatientsAction = { type: typeof GET_PATIENTS; payload: Patient[] }
type AddPatientAction = { type: typeof ADD_PATIENT; payload: Patient }

export type DashboardActionType = GetPatientsAction | AddPatientAction

export function getPatients() {
  const request = axios.get<LimitOffsetPaginatedResponse<PatientListPatient>>(
    getApiBaseUrl() + `/api/patient/`,
    {
      params: {
        offset: 0,
        limit: 10000,
      },
    }
  )

  return (dispatch) =>
    request
      .then((response) =>
        dispatch({
          type: GET_PATIENTS,
          payload: response.data.results,
        })
      )
      .catch((error) => dispatch(handleApiError(error)))
}

export class DuplicateEmailError extends Error {
  constructor(message) {
    super(message)
    this.name = "DuplicateEmailError"
  }
}

export function createPatient({
  firstName,
  lastName,
  email,
  confirmDuplicateEmail,
  startNewOrder,
  shippingState,
}: {
  firstName: string
  lastName: string
  email: string
  confirmDuplicateEmail: boolean
  startNewOrder: boolean
  shippingState?: string
}) {
  const request = API.Patient.create({
    first_name: firstName,
    last_name: lastName,
    email,
    confirm_duplicate_email: confirmDuplicateEmail,
  })

  return async (dispatch, getState) =>
    request
      .then((response) => {
        dispatch({
          type: ADD_PATIENT,
          payload: response.data,
        })
        if (startNewOrder) {
          if (shippingState) {
            history.push(
              `/checkout/create/${response.data.id}/${shippingState}`
            )
          } else {
            history.push(`/checkout/create/${response.data.id}/`)
          }
        }
        return response.data
      })
      .catch((error) => {
        const response = error.response
        const data = response && response.data

        if (data && data.code === "duplicate_email") {
          throw new DuplicateEmailError(
            "Patient with that email already exists"
          )
        } else {
          throw error
        }
      })
}
