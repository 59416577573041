import { useEffect } from "react"

import { ORDERING_RIGHTS_ACTIONS } from "app/constants"
import { labTestLocation } from "app/types"

// Hook to allow custom modifiers for labTestQuery in different locations
export default function useLabTestQueryModifiers(location, onSetQueryParams) {
  useEffect(() => {
    if (location === labTestLocation.COMPARISON) {
      onSetQueryParams([
        {
          queryName: "allow_comparisons",
          value: "true",
        },
        {
          queryName: "action",
          value: ORDERING_RIGHTS_ACTIONS.COMPARE,
        },
      ])
    } else if (location === labTestLocation.CATALOG) {
      onSetQueryParams([
        {
          queryName: "action",
          value: ORDERING_RIGHTS_ACTIONS.CATALOG,
        },
      ])
    }
  }, [location])
}
