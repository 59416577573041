import { useReducer, useMemo, Dispatch } from "react"
import * as React from "react"

import { createContext } from "use-context-selector"

import { FetcherResponse, JSONAPIError } from "./make-fetcher"
import { Resource } from "./types"

export default function makeProvider<ValidResource extends Resource>() {
  type ResourceEntities = {
    [R in ValidResource as R["type"]]?: {
      [id: string]: R
    }
  }

  interface EntityMetadata {
    id: string
    type: string
  }

  interface ResourceRequests {
    // TODO: Only works for fetch requests, and we will need to rethink for mutations
    [url: string]: SuccessfulRequest | PendingRequest | FailedRequest
  }

  interface SuccessfulListRequest {
    status: "success"
    type: "list"
    entities: EntityMetadata[]
  }
  interface SuccessfulObjectRequest {
    status: "success"
    type: "object"
    entity: EntityMetadata
  }

  type SuccessfulRequest = SuccessfulObjectRequest | SuccessfulListRequest

  interface PendingRequest {
    status: "pending"
  }

  interface FailedRequest {
    status: "error"
    error: JSONAPIError
  }

  interface ResourceState {
    requests: ResourceRequests
    entities: ResourceEntities
  }

  // ACTIONS

  interface Action<ActionType extends string = string> {
    type: ActionType
    payload: { key: string }
  }
  type FetchResourcePendingAction = Action<"fetch_resource_pending">
  type FetchResourceSuccessAction = Action<"fetch_resource_success"> & {
    payload: Action["payload"] & FetcherResponse<ValidResource>
  }
  type UpdateResourceCacheSuccessAction =
    Action<"update_resource_cache_success"> & {
      payload: Action["payload"] & FetcherResponse<ValidResource>
    }
  type FetchResourceErrorAction = Action<"fetch_resource_error"> & {
    payload: Action["payload"] & { error: JSONAPIError }
  }
  type Actions =
    | FetchResourcePendingAction
    | FetchResourceSuccessAction
    | FetchResourceErrorAction
    | UpdateResourceCacheSuccessAction

  // REDUCER

  const defaultState: ResourceState = {
    requests: {},
    entities: {},
  }

  function reducer(state: ResourceState, action: Actions): ResourceState {
    switch (action.type) {
      case "fetch_resource_pending": {
        return {
          ...state,
          requests: {
            ...state.requests,
            [action.payload.key]: {
              status: "pending",
            },
          },
        }
      }
      case "fetch_resource_success": {
        const { data, included, key } = action.payload
        const nextState = { ...state }

        if (Array.isArray(data)) {
          nextState.requests = {
            ...state.requests,
            [key]: {
              status: "success",
              type: "list",
              entities: data.map(({ id, type }) => ({ id, type })),
            },
          }
          nextState.entities = data.reduce((entities, entity) => {
            return {
              ...entities,
              [entity.type]: { ...entities[entity.type], [entity.id]: data },
            }
          }, nextState.entities)
        } else {
          nextState.requests = {
            ...state.requests,
            [key]: {
              status: "success",
              type: "object",
              entity: { id: data.id, type: data.type },
            },
          }
          nextState.entities = {
            ...state.entities,
            [data.type]: {
              ...state.entities[data.type],
              [data.id]: data,
            },
          }
        }

        if (included) {
          nextState.entities = included.reduce(
            (nextEntities, includedResource) => {
              if (!includedResource.type || !includedResource.id) {
                return nextEntities
              }

              return {
                ...nextEntities,
                [includedResource.type]: {
                  ...nextEntities[includedResource.type],
                  [includedResource.id]: includedResource,
                },
              }
            },
            nextState.entities
          )
        }

        return nextState
      }
      case "fetch_resource_error": {
        return {
          ...state,
          requests: {
            ...state.requests,
            [action.payload.key]: {
              status: "error",
              error: action.payload.error,
            },
          },
        }
      }
      case "update_resource_cache_success": {
        const { data, included, key } = action.payload

        const nextState = { ...state }

        if (Array.isArray(data)) {
          nextState.requests = {
            ...state.requests,
            [key]: {
              status: "success",
              type: "list",
              entities: data.map(({ id, type }) => ({ id, type })),
            },
          }
          nextState.entities = data.reduce((entities, entity) => {
            return {
              ...entities,
              [entity.type]: { ...entities[entity.type], [entity.id]: data },
            }
          }, nextState.entities)
        } else {
          nextState.requests = {
            ...state.requests,
            [key]: {
              status: "success",
              type: "object",
              entity: { id: data.id, type: data.type },
            },
          }
          nextState.entities = {
            ...state.entities,
            [data.type]: {
              ...state.entities[data.type],
              [data.id]: data,
            },
          }
        }

        if (included) {
          nextState.entities = included.reduce(
            (nextEntities, includedResource) => {
              if (!includedResource.type || !includedResource.id) {
                return nextEntities
              }

              return {
                ...nextEntities,
                [includedResource.type]: {
                  ...nextEntities[includedResource.type],
                  [includedResource.id]: includedResource,
                },
              }
            },
            nextState.entities
          )
        }

        return nextState
      }
      default:
        return state
    }
  }

  type ResourceContextShape = {
    dispatch: Dispatch<Actions>
  } & ReturnType<typeof reducer>

  const ResourceContext = createContext<ResourceContextShape>({
    ...defaultState,
    dispatch: () => {},
  })

  // PROVIDER

  interface ResourceProviderProps {
    children?: React.ReactNode
    initialState?: ResourceState
  }

  function ResourceProvider({
    children,
    initialState = defaultState,
  }: ResourceProviderProps) {
    const [state, dispatch] = useReducer(reducer, initialState)

    const context = useMemo(
      () => ({
        ...state,
        dispatch,
      }),
      [state]
    )

    return (
      <ResourceContext.Provider value={context}>
        {children}
      </ResourceContext.Provider>
    )
  }

  return { defaultState, ResourceContext, ResourceProvider }
}
