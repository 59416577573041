import { isEqual } from "lodash"

import { CircularProgress } from "@material-ui/core"

import { textPrimaryColor } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"

import { AnyBiomarkerResourceIdentifier } from "../../types"
import AddBiomarkerSearchOption from "./AddBiomarkerSearchOption"

const useStyles = makeAppStyles((theme) => ({
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(0.25),
  },
  header: {
    display: "flex",
    flexFlow: "row nowrap",
    maxHeight: 26,
    height: 26,
  },
  headerTitle: {
    flex: 1,
    color: textPrimaryColor,
    fontSize: 11,
    fontWeight: 600,
  },
}))

export default function AddBiomarkerSearchBiomarkerOptions({
  cursor,
  identifiers,
  isCreating,
  moveCursorTo,
  onSelect,
}: {
  cursor?: AnyBiomarkerResourceIdentifier
  identifiers: AnyBiomarkerResourceIdentifier[]
  isCreating: boolean
  moveCursorTo: (identifier: AnyBiomarkerResourceIdentifier) => void
  onSelect: (identifier: AnyBiomarkerResourceIdentifier) => void
}) {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <span className={classes.headerTitle}>Markers in Results</span>

        {isCreating && <CircularProgress size={16} />}
      </div>

      {identifiers.map((identifier) => (
        <AddBiomarkerSearchOption
          key={identifier.id}
          highlighted={isEqual(identifier, cursor)}
          identifier={identifier}
          isCreating={isCreating}
          onClick={() => !isCreating && onSelect(identifier)}
          onHover={() => !isCreating && moveCursorTo(identifier)}
        />
      ))}
    </div>
  )
}
