import { MouseEvent } from "react"

import { Location } from "history"
import { Link } from "react-router-dom"

import { useMediaQuery, Theme } from "@material-ui/core"

import { ReactComponent as CheckIcon } from "app/assets/icons/check-circle.svg"
import { ReactComponent as ReturnIcon } from "app/assets/icons/return.svg"
import FilterIcon from "app/components/search/FilterIcon"
import { colors, navy } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"

const useAutoSuggestActionStyles = makeAppStyles<{
  highlighted: boolean
}>((theme) => ({
  action: {
    position: "relative",
    width: "100%",
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0.75, 1.125),
    marginTop: theme.spacing(1.375),
    borderRadius: 6,
    backgroundColor: ({ highlighted }) =>
      highlighted ? colors.blueGray[100] : "white",
    color: navy,
    fontSize: 15,
    fontWeight: 600,
    textAlign: "left",
  },
  checkIcon: {
    marginLeft: theme.spacing(1),
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  selectReturn: {
    alignItems: "center",
    position: "absolute",
    top: "50%",
    right: 9,
    transform: "translateY(-50%)",
    justifyContent: "center",
    color: colors.blueGray[400],
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: ({ highlighted }) => (highlighted ? "flex" : "none"),
    },
  },
  addedMessage: {
    alignItems: "center",
    position: "absolute",
    top: "50%",
    right: 9,
    transform: "translateY(-50%)",
    justifyContent: "center",
    color: navy,
  },
  selectReturnIcon: {
    marginLeft: theme.spacing(1),
  },
}))

export interface AutoSuggestActionProps {
  label: string
  highlighted: boolean
  icon: string
  onHover: () => void
  onSelect: () => void
  to?: Location<unknown>
  addedMessage?: string
}

export default function AutoSuggestAction({
  label,
  highlighted,
  icon,
  onHover,
  onSelect,
  to,
  addedMessage,
}: AutoSuggestActionProps) {
  const classes = useAutoSuggestActionStyles({ highlighted })

  function handleClick(e: MouseEvent<HTMLButtonElement | HTMLAnchorElement>) {
    e.preventDefault()

    onSelect()
  }

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("xs"))

  const content = (
    <>
      <FilterIcon className={classes.icon} alt={label} type={icon} />
      {label}
      <div className={classes.selectReturn}>
        {!Boolean(addedMessage) && (
          <>
            Select
            <ReturnIcon className={classes.selectReturnIcon} title={label} />
          </>
        )}
      </div>
      {Boolean(addedMessage) && (
        <div className={classes.addedMessage}>
          {!isMobile && addedMessage}
          <CheckIcon
            className={classes.checkIcon}
            width={12}
            height={12}
            viewBox="0 0 14 14"
            fill={navy}
          />
        </div>
      )}
    </>
  )

  if (to) {
    return (
      <Link
        className={classes.action}
        to={to}
        onClick={handleClick}
        onMouseMove={onHover}
      >
        {content}
      </Link>
    )
  }

  return (
    <button
      className={classes.action}
      onClick={handleClick}
      onMouseMove={onHover}
      type="button"
    >
      {content}
    </button>
  )
}
