import { useState } from "react"

import { makeStyles } from "@material-ui/core"

import { UserPaths } from "app/Routes/paths"
import HouseGrayIcon from "app/assets/icons/house-gray.svg"
import PageToolbar from "app/components/PageToolbar"
import FeedbackButton from "app/components/design-system/FeedbackButton"
import { EditPatientModal } from "app/main/patient-orders/EditPatientModal"
import PatientDetails from "app/main/patient-orders/PatientDetails"
import { INTERCOM_FOOD_PLANS_FEEDBACK_ID } from "app/settings"
import { Patient } from "app/types"

const useStyles = makeStyles({
  surveyButton: {
    marginLeft: 20,
  },
})

export interface PatientFoodPlanHeaderProps {
  patient: Patient
}

export default function PatientFoodPlanHeader({
  patient,
}: PatientFoodPlanHeaderProps) {
  const [editPatientModalOpen, setEditPatientModalOpen] = useState(false)
  const classes = useStyles()

  return (
    <>
      <EditPatientModal
        patient={patient}
        onClose={() => setEditPatientModalOpen(false)}
        open={editPatientModalOpen}
      />
      <PageToolbar
        title={`${patient.full_name}`}
        subtitle={
          <PatientDetails
            patient={patient}
            onEditClick={() => setEditPatientModalOpen(true)}
          />
        }
        breadcrumbs={[
          {
            to: UserPaths.ROOT,
            icon: HouseGrayIcon,
            label: "Dashboard",
          },
          {
            to: UserPaths.PATIENT_DETAIL.replace(":patientId", patient.id),
            label: `${patient.full_name}`,
          },
          {
            label: "Food Plan",
          },
        ]}
        breadcrumbActionsSlot={
          <div className={classes.surveyButton}>
            <FeedbackButton
              surveyId={INTERCOM_FOOD_PLANS_FEEDBACK_ID}
              text="Share Food Plans Feedback"
            />
          </div>
        }
      />
    </>
  )
}
