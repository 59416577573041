import classNames from "classnames"

import { Grid } from "@material-ui/core"

import BodyText from "app/components/design-system/BodyText"
import { colors, white } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"

const useStyles = makeAppStyles((theme) => ({
  cardContainer: {
    padding: "5px 10px 10px 0",
  },
  accountCard: {
    width: "100%",
    minHeight: "154px",
    background: white,
    borderRadius: 8,
  },
  confirmedAccountCard: {
    border: `1px solid ${colors.blueGray[200]}`,
    boxShadow:
      "0px 1px 2px 0px rgba(0, 0, 0, 0.06), 0px 1px 3px 0px rgba(0, 0, 0, 0.10)",
  },
  emptyAccountCard: {
    border: `2px dashed ${colors.blueGray[300]}`,
  },
  accountTitleRow: {
    margin: "24px 24px 0",
    display: "flex",
    justifyContent: "space-between",
  },
  companyName: {
    fontSize: 15,
  },
}))

const AccountCardWrapper = ({
  company,
  isConfirmed,
  actionElement,
  children,
  useFullWidth = false,
}) => {
  const classes = useStyles()
  return (
    <Grid
      item
      xs={12}
      md={useFullWidth ? 12 : 6}
      className={classes.cardContainer}
    >
      <div
        className={classNames(classes.accountCard, {
          [classes.confirmedAccountCard]: isConfirmed,
          [classes.emptyAccountCard]: !isConfirmed,
        })}
      >
        <div className={classes.accountTitleRow}>
          <BodyText weight="semibold" className={classes.companyName}>
            {company.display}
          </BodyText>
          {actionElement}
        </div>
        {children}
      </div>
    </Grid>
  )
}

export default AccountCardWrapper
