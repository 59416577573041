import { useCallback, useState } from "react"

import { CircularProgress } from "@material-ui/core"
import { Button } from "@rupahealth/design"

import TrendsLogo from "app/assets/images/results-over-time/trends-empty.png"
import RupaCheckbox from "app/components/RupaCheckbox"
import useQuery from "app/hooks/use-query"
import uniq from "lodash/uniq"

import {
  ResultsUploadProps,
  useResultsUpload,
} from "../hooks/use-results-upload"
import { ResultsUploadFileGroups } from "./ResultsUploadForm"
import { Step2PatientPortalShareAccess } from "./Step2PatientPortalShareAccess"

interface Props extends ResultsUploadProps {
  onClose: (noConfirm?: boolean) => void
  goBack: () => void
  fileGroups: ResultsUploadFileGroups
}

export const Step2Confirm: React.FC<Props> = ({
  onClose,
  goBack,
  fileGroups,
  userId,
  patientId,
}) => {
  const { uploadAndInvokeLabResults } = useResultsUpload({ userId, patientId })

  const [clinicsToShareWith, setClinicsToShareWith] = useState<string[]>([])
  const [confirmChecked, setConfirmChecked] = useState(false)
  const [submitting, setSubmitting] = useState(false)

  const query = useQuery()
  const upload_requested_by = query.get("upload_requested_by")

  const onToggleClinic = useCallback(
    (clinicId: string) => {
      setClinicsToShareWith((prev: string[]) => {
        if (prev.includes(clinicId)) {
          return prev.filter((id) => id !== clinicId)
        } else {
          return [...prev, clinicId]
        }
      })
    },
    [setClinicsToShareWith]
  )

  const onSubmit = async () => {
    setSubmitting(true)

    const fileArrays = Object.values(fileGroups).map((fileOrGroup) => {
      return fileOrGroup instanceof File
        ? [fileOrGroup]
        : Object.values(fileOrGroup)
    })

    let clinicIdsToShareWith = clinicsToShareWith
    if (upload_requested_by) {
      clinicIdsToShareWith.push(upload_requested_by)
    }

    await uploadAndInvokeLabResults(fileArrays, uniq(clinicIdsToShareWith))

    // Give ResultsInProgress UX time to show the new pill
    setTimeout(() => {
      setSubmitting(false)
      onClose(true)
    }, 1000)
  }

  return (
    <div
      className="p-4 sm:py-12 sm:px-16 rounded-xl flex flex-col items-center gap-5"
      style={{
        background:
          "linear-gradient(184.56deg, #E2F4FC 26.04%, #FFFFFF 90.73%)",
      }}
    >
      <div className="flex flex-col items-center">
        <div className="w-44">
          <img src={TrendsLogo} alt="trends logo" />
        </div>
        {userId && !patientId && (
          <Step2PatientPortalShareAccess
            className="w-full mb-3 bg-white rounded-lg border border-slate-200 shadow-sm py-3 px-5 flex flex-col gap-2"
            userId={userId}
            clinicsToShareWith={clinicsToShareWith}
            onToggleClinic={onToggleClinic}
            disabled={submitting}
            uploadRequestedByClinicId={upload_requested_by}
          />
        )}
        <div className="w-full bg-white rounded-lg border border-slate-200 shadow-sm py-3 px-5 flex flex-col gap-2">
          <div className="font-semibold">
            We do our best to automatically extract data from the original
            report.
          </div>
          <hr className="h-[1px] bg-slate-200 w-full" />
          <div
            className="group cursor-pointer flex items-center gap-2"
            onClick={() => setConfirmChecked((prev) => !prev)}
          >
            <RupaCheckbox
              checked={confirmChecked}
              disabled={submitting}
              className="rounded-[4px] text-xs p-[2px] pt-[1px]"
            />

            <div>
              I will verify that all values are accurate.{" "}
              <span className="text-destructive">*</span>
            </div>
          </div>
        </div>
      </div>
      <div className="px-1.5 w-full">
        {submitting ? (
          <Button
            variant="outline-primary"
            className="w-full rounded-[55px] border-primary bg-light-blue-50 gap-2 cursor-default"
          >
            <CircularProgress size={16} thickness={5} /> Uploading Files...
          </Button>
        ) : (
          <div className="flex gap-2">
            <Button
              variant="outline-primary"
              className="w-full"
              onClick={goBack}
            >
              Back
            </Button>
            <Button
              variant="primary"
              className="w-full"
              disabled={!confirmChecked}
              onClick={onSubmit}
            >
              Import Results
            </Button>
          </div>
        )}
      </div>
    </div>
  )
}
