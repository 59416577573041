import clsx from "clsx"

import Collapse from "@material-ui/core/Collapse"
import Divider from "@material-ui/core/Divider"
import Typography from "@material-ui/core/Typography"
import KeyboardReturnIcon from "@material-ui/icons/KeyboardReturn"
import PersonOutlineIcon from "@material-ui/icons/PersonOutline"

import useCachedResource from "app/swr/hooks/use-cached-resource"
import { ResourceIdentifier } from "app/swr/types"
import { Patient } from "types/patient"

const FoodPlanPatientListItem = ({
  isSelected,
  match,
  onClick,
  onMouseEnter,
}: {
  isSelected: boolean
  match: ResourceIdentifier
  onClick: () => void
  onMouseEnter: () => void
}) => {
  const patient = useCachedResource<Patient>(match)
  if (!patient) {
    return null
  }

  return (
    <div
      className={clsx(
        "flex items-center justify-between",
        "rounded w-full text-sm",
        "px-2 py-1.5 fs-exclude",
        "cursor-pointer",
        isSelected && "bg-gray-100"
      )}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
    >
      <div className="flex items-center">
        <PersonOutlineIcon className="mr-1 text-lg fs-exclude" />
        {patient.attributes.full_name}
      </div>
      {isSelected && (
        <div className="flex items-center text-xs text-gray-600">
          Select{" "}
          <KeyboardReturnIcon className="text-gray-100 bg-gray-500 text-sm ml-2 rounded w-5" />
        </div>
      )}
    </div>
  )
}

export interface FoodPlanPatientListProps {
  matches: ResourceIdentifier[]
  onSelectPatient: (patientId: string) => void
  searchText: string
  selectedIndex: number
  setSelectedIndex: (index: number) => void
}

export default function FoodPlanPatientList({
  matches = [],
  onSelectPatient,
  searchText,
  selectedIndex,
  setSelectedIndex,
}: FoodPlanPatientListProps) {
  return (
    <Collapse in={searchText.length > 0}>
      <div>
        <Divider />
        <div className="pt-6 px-4">
          <Typography
            variant="body1"
            className="text-sm font-500 text-gray-700 mb-1"
          >
            Existing Clients
          </Typography>
          {searchText && matches.length === 0 && (
            <div className="flex items-center justify-center w-full border-dashed border-gray-300 border-sm text-gray-700 text-lg p-4">
              No clients match this search.
            </div>
          )}
          {searchText &&
            matches.map((match, index) => {
              return (
                <FoodPlanPatientListItem
                  key={match.id}
                  isSelected={selectedIndex === index}
                  match={match}
                  onClick={() => onSelectPatient(match.id)}
                  onMouseEnter={() => setSelectedIndex(index)}
                />
              )
            })}
        </div>
      </div>
    </Collapse>
  )
}
