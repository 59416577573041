import { useEffect } from "react"

import { faArrowDownToSquare } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button, ButtonProps, cn } from "@rupahealth/design"

import useFeatureFlag from "app/hooks/use-feature-flag"
import useSetQueryParam from "app/hooks/use-set-query-param"
import useResultsUploadModal from "app/main/results-upload-v2/results-upload-modal/ResultsUploadModal"
import { FeatureFlag } from "app/providers/FeatureFlagProvider"

interface Props extends Omit<ButtonProps, "onClick" | "children"> {
  userId: string
  label?: string
}

export const PatientPortalResultsUploadButton: React.FC<Props> = ({
  userId,
  variant = null,
  className,
  label = "Import Lab Results",
  ...buttonProps
}) => {
  const { searchParams, setQueryParam } = useSetQueryParam()
  const [patientPortalResultsUploadEnabled, flagLoading] = useFeatureFlag(
    FeatureFlag.PatientPortalResultsUploads
  )
  const resultsUploadModal = useResultsUploadModal()
  const onClick = () =>
    resultsUploadModal.show({
      userId,
      onClose: () => setQueryParam("upload_requested_by", undefined),
    })

  // If the upload query param is present, open the modal
  useEffect(() => {
    if (searchParams.get("upload_requested_by")) {
      onClick()
    }
  }, [searchParams])

  if (flagLoading || !patientPortalResultsUploadEnabled) {
    return null
  }

  return (
    <Button
      onClick={onClick}
      variant={variant}
      className={cn(
        "w-full h-auto text-[15px] text-primary fill-primary py-1 px-2",
        variant === null ? "hover:bg-slate-200" : "py-2.5 px-4",
        { "text-white fill-white": variant === "primary" },
        className
      )}
      {...buttonProps}
    >
      <FontAwesomeIcon className="mr-2" icon={faArrowDownToSquare} />
      {label}
    </Button>
  )
}
