import { useHistory } from "react-router"

import { CellContext } from "@tanstack/table-core"

import Button from "app/components/design-system/Button"
import { ORDER_STATUS } from "app/constants.typed"
import useEventCallback from "app/hooks/use-event-callback"
import { DashboardOrder } from "types/dashboard/dashboard-order"

export default function DetailsButton({
  className,
  row,
}: CellContext<DashboardOrder, unknown> & { className: string }) {
  const history = useHistory()

  const handleClick = useEventCallback((e) => {
    e.preventDefault()

    let querystring = ""

    if (row.original.attributes.cached_status !== ORDER_STATUS.SCHEDULED) {
      querystring = `?orderId=${row.original.id}`
    }

    history.push(
      `/patient/${row.original.relationships.patient.data.id}${querystring}`
    )
  })

  return (
    <Button
      className={className}
      color="secondary"
      type="button"
      onClick={handleClick}
    >
      Details
    </Button>
  )
}
