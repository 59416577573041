import _ from "lodash"

import { ORDER_LINE_ITEM_TYPE } from "app/constants"

import * as Actions from "../actions"

const initialState = {
  patient: {},
  order: {},
  practitioners: [],
}

export function withComputedOrderProps(order) {
  /*
   * Decorates an order with dynamically computed properties. These
   * properties are helper props that make data access easier. Here
   * we're creating maps to make it quicker to determine if an order
   * contains a given lab test.
   *
   * Should this be provided by the API? Probably, but as we don't have
   * a way to normalize data within the response (JSON:API style), crafting
   * these maps on the API would involve duplicating all lab tests twice.
   * We already senda lot of data so we're doing it client-side for now.
   */

  const orderedTestEntries = order.ordered_tests.map((orderedTest) => [
    orderedTest.lab_test.id,
    orderedTest.lab_test,
  ])

  const uninsuredLabTestsByOrderedTestIds =
    order.associated_uninsured_tests_by_ordered_test_id ?? {}

  const uninsuredLabTestEntries = Object.values(
    uninsuredLabTestsByOrderedTestIds
  ).map((uninsuredLabTest) => [uninsuredLabTest.id, uninsuredLabTest])

  const allLabTestEntries = [...orderedTestEntries, ...uninsuredLabTestEntries]

  const orderedTestByLabTestEntries = order.ordered_tests.map((orderedTest) => [
    orderedTest.lab_test.id,
    orderedTest,
  ])

  const ordered_tests_by_lab_test_id = Object.fromEntries(
    orderedTestByLabTestEntries
  )

  Object.entries(uninsuredLabTestsByOrderedTestIds).forEach(
    ([orderedTestId, uninsuredLabTest]) => {
      const orderedTest = order.ordered_tests.find(
        (ot) => ot.id === orderedTestId
      )
      if (orderedTest) {
        ordered_tests_by_lab_test_id[uninsuredLabTest.id] = orderedTest
      }
    }
  )

  return {
    ...order,
    lab_tests_by_id: Object.fromEntries(allLabTestEntries),
    ordered_tests_by_lab_test_id,
  }
}

export default function orders(state = initialState, action) {
  switch (action.type) {
    case Actions.GET_ORDER: {
      return {
        ...state,
        order: withComputedOrderProps(action.payload),
      }
    }
    case Actions.GET_PATIENT:
    case Actions.UPDATE_PATIENT: {
      return {
        ...state,
        patient: action.payload,
      }
    }
    case Actions.GET_PRACTITIONERS: {
      return {
        ...state,
        practitioners: action.payload,
      }
    }
    case Actions.UPDATE_ORDER: {
      return {
        ...state,
        order: withComputedOrderProps(action.payload),
      }
    }
    case Actions.ADD_TEST: {
      const { optimisticIdToRemove, ...newOrderedTest } = action.payload
      const nextOrderedTests = [
        ..._.reject(
          state.order.ordered_tests,
          (ordered_test) =>
            !!optimisticIdToRemove && ordered_test.id === optimisticIdToRemove
        ),
        newOrderedTest,
      ]
      const nextLineItems = [
        {
          id: newOrderedTest.id,
          title: newOrderedTest.lab_test.name,
          type: ORDER_LINE_ITEM_TYPE.LAB_TEST,
          ordered_test: newOrderedTest,
          cost: undefined,
          discounted_cost: undefined,
          optimistic: true,
        },
        ..._.reject(
          state.order.line_items,
          (line_item) =>
            !!optimisticIdToRemove &&
            line_item.type === ORDER_LINE_ITEM_TYPE.LAB_TEST &&
            line_item.ordered_test.id === optimisticIdToRemove
        ),
      ]
      return {
        ...state,
        order: withComputedOrderProps({
          ...state.order,
          ordered_tests: nextOrderedTests,
          line_items: nextLineItems,
        }),
      }
    }
    case Actions.UPDATE_TEST:
      return {
        ...state,
        order: withComputedOrderProps({
          ...state.order,
          ordered_tests: [
            ..._.reject(state.order.ordered_tests, {
              id: action.payload.id,
            }),
            action.payload,
          ],
        }),
      }
    case Actions.MARK_TEST_TO_ADD_TO_NEXT_ORDER:
      return {
        ...state,
        marked_for_next_order: {
          lab_test: action.payload.lab_test,
          additionalData: action.payload.additionalData,
        },
      }
    case Actions.UNMARK_TEST_TO_ADD_TO_NEXT_ORDER:
      const markedTest = state.marked_for_next_order
      let marked_for_next_order = markedTest
      if (action.payload.lab_test === markedTest.lab_test) {
        marked_for_next_order = null
      }
      return {
        ...state,
        marked_for_next_order,
      }
    case Actions.REMOVE_TEST: {
      return {
        ...state,
        order: withComputedOrderProps({
          ...state.order,
          ordered_tests: _.reject(state.order.ordered_tests, {
            id: action.payload.id,
          }),
          line_items: _.reject(
            state.order.line_items,
            (line_item) =>
              line_item.type === ORDER_LINE_ITEM_TYPE.LAB_TEST &&
              line_item.ordered_test.id === action.payload.id
          ),
        }),
      }
    }
    case Actions.UPDATE_PANEL:
      return {
        ...state,
        order: withComputedOrderProps({
          ...state.order,
          ordered_tests: [
            ..._.reject(state.order.ordered_tests, (orderedTest) =>
              _.includes(
                action.payload.map((newOrderedTest) => newOrderedTest.id),
                orderedTest.id
              )
            ),
            ...action.payload,
          ],
        }),
      }
    case Actions.REMOVE_PANEL: {
      return {
        ...state,
        order: withComputedOrderProps({
          ...state.order,
          ordered_tests: _.reject(state.order.ordered_tests, (orderedTest) =>
            _.includes(
              action.payload.map((deletedOrderedTest) => deletedOrderedTest.id),
              orderedTest.id
            )
          ),
        }),
      }
    }
    case Actions.ADD_COUPON:
      return {
        ...state,
        order: withComputedOrderProps({
          ...state.order,
          coupons: [...state.order.coupons, action.payload],
        }),
      }
    case Actions.REMOVE_COUPON: {
      return {
        ...state,
        order: withComputedOrderProps({
          ...state.order,
          coupons: _.reject(state.order.coupons, {
            id: action.payload.id,
          }),
        }),
      }
    }
    case Actions.CLEAR_ORDER: {
      return {
        ...state,
        order: {},
        patient: {},
      }
    }
    case Actions.UPDATE_CUSTOM_FEE_LINE_ITEM:
      return {
        ...state,
        order: withComputedOrderProps({
          ...state.order,
          custom_fee_line_items: [action.payload],
        }),
      }
    default: {
      return state
    }
  }
}
