import { flatten, sum } from "lodash"

import { ORDER_LINE_ITEM_TYPE } from "app/constants"
import {
  groupLineItems,
  INDIVIDUAL_CARD_KEY_PREFIX,
  LAB_COMPANY_PANELS,
} from "app/dataServices/labTestDataService"
import {
  Order,
  OrderLineItem,
  PatientPortalOrder,
  PatientPortalOrderLineItem,
} from "app/types"
import { formatDollars } from "app/utils"

interface GenovaTest {
  name: string
  // The amount if would cost without insurance
  rupaPrice: string
  // The absolute max a patient could pay. Calculated based on Genova's unique calculations.
  maxCost: string
  // The absolute max a patient could pay, excluding the initial insurance price.
  potentialExtraCost: string
}

export interface GenovaExplanation {
  genovaTests: GenovaTest[]
  totalInitialInsurancePrice: string
  totalMaxCost: string
  totalPotentialExtraCost: string
}

export default function useGenovaInsuranceExplanation<
  O extends Order | PatientPortalOrder
>(order: O): GenovaExplanation | null {
  if (
    !order.use_insurance ||
    order.is_practitioner_paying ||
    order.patient_has_medicare
  ) {
    return null
  }

  let totalInitialInsurancePrice = 0
  let totalPotentialExtraCost = 0
  let totalMaxCost = 0

  const orderedTestLineItems = order.line_items.flatMap(
    (lineItem: OrderLineItem | PatientPortalOrderLineItem) =>
      lineItem.type === ORDER_LINE_ITEM_TYPE.LAB_TEST ? lineItem : []
  )

  const genovaTests: GenovaTest[] = flatten(
    Object.entries(groupLineItems(orderedTestLineItems)).map(
      ([groupKey, lineItems]) => {
        // TODO as soon as possible: Replace all this with service-provided test groups.

        // Individual tests
        if (groupKey.startsWith(INDIVIDUAL_CARD_KEY_PREFIX)) {
          return lineItems.map((lineItem) => ({
            name: lineItem.ordered_test.lab_test.name,
            lineItems: [lineItem],
          }))
        }

        // Panel
        if (LAB_COMPANY_PANELS.includes(groupKey)) {
          const onlyInHousePhlebotomy =
            lineItems[0]?.ordered_test.lab_test.lab_company
              .only_in_house_phlebotomy
          const panelTitle = `${groupKey} Panel${
            onlyInHousePhlebotomy ? "" : " (1 kit)"
          }`

          return {
            name: panelTitle,
            lineItems,
          }
        }

        // Parents/add-ons
        const parentLabTestId = groupKey
        const parentLineItem = lineItems.find(
          (lineItem) => lineItem.ordered_test.lab_test.id === parentLabTestId
        )

        return {
          name: `${parentLineItem?.ordered_test.lab_test.name} (with add-ons)`,
          lineItems,
        }
      }
    )
  )
    .filter(({ lineItems }) => {
      // We assume that the first line item is representative of the group regarding insurance
      const lineItem = lineItems[0]

      const isUsingInsurance =
        lineItem.ordered_test.lab_test.initial_insurance_price !== null

      return isUsingInsurance
    })
    .map(({ name, lineItems }) => {
      const rupaPrice = sum(
        lineItems.map((lineItem) => {
          const labTest = lineItem.ordered_test.lab_test

          return parseFloat(labTest.rupa_price)
        })
      )
      const initialInsurancePrice = sum(
        lineItems.map((lineItem) =>
          parseFloat(lineItem.ordered_test.lab_test.initial_insurance_price)
        )
      )
      const maxCost = sum(
        lineItems.map((lineItem) => {
          const labTest = lineItem.ordered_test.lab_test

          return Math.max(
            parseFloat(labTest.insurance_max_out_of_pocket_price),
            parseFloat(labTest.rupa_price)
          )
        })
      )

      /* Genova will charge the patient according to this logic
       *
       * If the insurance covers *some* of the test, they could be charged up
       * to what Genova calls "Discounted full balance", which is what we call
       * `insurance_max_out_of_pocket`.
       *
       * If the insurance covers $0, the patient is charged up to the retail price
       * of the test. Genova calls this "Cash price" and we call it `rupa_price`.
       *
       * We take the max of these two to ensure we communicate the absolute max a
       * patient could be charged.
       */
      const potentialExtraCost = maxCost - initialInsurancePrice

      totalPotentialExtraCost += potentialExtraCost
      totalInitialInsurancePrice += initialInsurancePrice
      totalMaxCost += maxCost

      return {
        name,
        potentialExtraCost: formatDollars(potentialExtraCost),
        maxCost: formatDollars(maxCost),
        rupaPrice: formatDollars(rupaPrice),
      }
    })

  return {
    genovaTests,
    totalPotentialExtraCost: formatDollars(totalPotentialExtraCost),
    totalMaxCost: formatDollars(totalMaxCost),
    totalInitialInsurancePrice: formatDollars(totalInitialInsurancePrice),
  }
}
