import * as React from "react"

import clsx from "clsx"
import { NavLink } from "react-router-dom"

import BodyText from "app/components/design-system/BodyText"
import { colors } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"

const usePageBreadcrumbsStyles = makeAppStyles((theme) => ({
  breadcrumbContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: theme.spacing(2, 2),
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(2, 3),
    },
  },
  breadcrumbIcon: {
    marginRight: 8,
  },
  breadcrumbLabel: {
    color: colors.blueGray[500],
  },
  breadcrumbLabelLink: {
    transition: "0.16s color",

    "&:hover": {
      color: colors.blueGray[700],
    },
  },
  breadcrumbDivider: {
    color: colors.blueGray[300],
    margin: "0 16px",
  },
  breadcrumbContent: {
    display: "inline-flex",
  },
  requestLabTestBtn: {
    alignSelf: "flex-end",
  },
  btnIcon: {
    position: "relative",
    top: "2px",
  },
}))

export interface PageBreadcrumb {
  to?: string
  label: string
  icon?: string
  onClick?: () => void
}

export interface PageBreadcrumbsProps {
  actionsSlot?: React.ReactNode
  breadcrumbs?: PageBreadcrumb[]
  className?: string
  divider?: any
}

export default function PageBreadcrumbs({
  actionsSlot,
  breadcrumbs,
  className,
  divider,
}: PageBreadcrumbsProps) {
  const classes = usePageBreadcrumbsStyles()

  return (
    <div className={clsx(classes.breadcrumbContainer, className)}>
      {breadcrumbs && (
        <div className={classes.breadcrumbContent}>
          {breadcrumbs.map((breadcrumb, index) => {
            const content = (
              <>
                {breadcrumb.icon && (
                  <div className={classes.breadcrumbIcon}>
                    <img src={breadcrumb.icon} alt="" />
                  </div>
                )}
                <BodyText
                  className={clsx(
                    classes.breadcrumbLabel,
                    breadcrumb.to ? classes.breadcrumbLabelLink : null
                  )}
                  weight="semibold"
                  onClick={breadcrumb?.onClick}
                >
                  {breadcrumb.label}
                </BodyText>
              </>
            )

            const link = breadcrumb.to ? (
              <NavLink className="flex" to={breadcrumb.to}>
                {content}
              </NavLink>
            ) : (
              <div className="flex">{content}</div>
            )

            return (
              <React.Fragment key={breadcrumb.label}>
                {link}
                {index !== breadcrumbs.length - 1 && (
                  <div className={classes.breadcrumbDivider}>
                    {divider || "/"}
                  </div>
                )}
              </React.Fragment>
            )
          })}
        </div>
      )}

      {actionsSlot}
    </div>
  )
}
