import { useCallback } from "react"

import { ReactComponent as MessageBubbleOutlinedPrimary } from "app/assets/icons/message-bubble-outlined-primary.svg"
import { ReactComponent as MessageBubbleOutlinedWhite } from "app/assets/icons/message-bubble-outlined-white.svg"
import DesignSystemButton, {
  ButtonProps,
} from "app/components/design-system/Button"

export default function MessageSupport({
  messageSupportButtonColor = "secondary",
}: {
  messageSupportButtonColor?: ButtonProps["color"]
}) {
  const open = useCallback(() => {
    window.Intercom("show")
  }, [])

  return (
    <DesignSystemButton
      color={messageSupportButtonColor}
      startIcon={
        messageSupportButtonColor === "primary" ? (
          <MessageBubbleOutlinedWhite />
        ) : (
          <MessageBubbleOutlinedPrimary />
        )
      }
      onClick={open}
    >
      Message Support
    </DesignSystemButton>
  )
}
