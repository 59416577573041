import Loading from "app/components/Loading"
import PatientFoodPlanContent from "app/food-plans/components/PatientFoodPlanContent"
import PatientFoodPlanHeader from "app/food-plans/components/PatientFoodPlanHeader"
import usePatientFoodPlanResources from "app/food-plans/hooks/use-patient-food-plan-resources"
import reducer from "app/main/patient-orders/store/reducers"
import withReducer from "app/store/withReducer"

export default withReducer(
  "patient",
  reducer
)(function PatientFoodPlan() {
  const {
    feed,
    foodPlanVersion,
    isRestarting,
    isStartingGenerate,
    legacyPatient,
    onPatch,
    onRestart,
    onStartGenerate,
    onStartCancel,
  } = usePatientFoodPlanResources()

  if (!legacyPatient.user) {
    return <Loading ariaLabel="Loading patient" />
  }

  return (
    <>
      <PatientFoodPlanHeader patient={legacyPatient} />

      <PatientFoodPlanContent
        feed={feed}
        foodPlanVersion={foodPlanVersion}
        isRestarting={isRestarting}
        isStartingGenerate={isStartingGenerate}
        onPatch={onPatch}
        onRestart={onRestart}
        onStartGenerate={onStartGenerate}
        onStartCancel={onStartCancel}
      />
    </>
  )
})
