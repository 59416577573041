import useResourceSWR from "app/swr/hooks/use-resource-swr"
import { Order } from "types/order"
import { ResultsInterpretation } from "types/results-interpretation"

import useInitializeInterpretation from "./use-initialize-interpretation"

export default function useResultsSummaryResources(orderId: string) {
  const orderSwr = useResourceSWR<Order>(
    `/orders/${orderId}/`,
    {
      include: [
        "patient.user",
        "ordered_results.lab_company",
        "ordered_results.kit",
      ],
    },
    {
      fallbackIdentifier: {
        type: "order",
        id: orderId,
      },
      revalidateOnMount: true,
    }
  )
  const { data: order } = orderSwr
  const { isInitializing, initializationError, outOfRangeBiomarkers } =
    useInitializeInterpretation(orderSwr)

  const interpretationIdentifier =
    order?.relationships.results_interpretation?.data
  const interpretationUrl = interpretationIdentifier
    ? `/results_interpretations/${interpretationIdentifier.id}/`
    : null

  const interpretationSwr = useResourceSWR<ResultsInterpretation>(
    interpretationUrl,
    {
      include: ["biomarkers", "tasks", "biomarkers.discrete_result"],
    },
    {
      fallbackIdentifier: interpretationIdentifier,
      revalidateOnMount: true,
    }
  )

  return {
    initializationError,
    interpretationSwr,
    isInitializing,
    orderSwr,
    outOfRangeBiomarkers,
  }
}

export type UseResultsVisualizerResourcesHook = ReturnType<
  typeof useResultsSummaryResources
>
