import { CircularProgressbar, buildStyles } from "react-circular-progressbar"

import "react-circular-progressbar/dist/styles.css"

import useOnboardingSWR from "./hooks/use-onboarding-swr"

export function OnboardingMenuIcon() {
  const { data: onboardingState } = useOnboardingSWR()

  const customizationPercentageComplete = onboardingState?.tasks
    ? (100 *
        onboardingState.tasks.filter(
          (task) =>
            task.section === "customization" && task.available && task.complete
        ).length) /
      onboardingState.tasks.filter(
        (task) => task.section === "customization" && task.available
      ).length
    : 0

  const requiredPercentageComplete = onboardingState?.tasks
    ? (100 *
        (onboardingState.tasks.filter(
          (task) =>
            task.available && task.complete && task.section === "required"
        ).length +
          1)) /
      (onboardingState.tasks.filter(
        (task) => task.available && task.section === "required"
      ).length +
        1)
    : 0

  // The required tasks should account for 90% of the progress bar
  const percentage =
    0.9 * requiredPercentageComplete + 0.1 * customizationPercentageComplete

  return (
    <div style={{ lineHeight: 0 }}>
      <CircularProgressbar
        strokeWidth={15}
        value={percentage}
        styles={buildStyles({
          pathTransitionDuration: 0.5,
          pathColor: "#5EEAD4",
          trailColor: "#64788A",
          backgroundColor: "#64788A",
        })}
      />
    </div>
  )
}
