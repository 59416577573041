import { useEffect } from "react"

import { ReactComponent as InitalsIcon } from "app/assets/icons/initials.svg"
import SignatureAndLicenseModalButton, {
  signatureAndLicenseButtonTitle,
} from "app/components/modals/SignatureAndLicenseModal/SignatureAndLicenseModalButton"

import TaskContainer from "../TaskContainer"
import { TaskStepComplete } from "../TaskStepComplete"
import taskStyles from "../TaskStyles"
import { OnboardingTaskItemProps } from "./OnboardingTaskItem"

const ExistingLicense: React.FC<OnboardingTaskItemProps> = ({
  task,
  onTaskComplete,
  practitioner,
  refreshPractitioner,
}) => {
  const taskClasses = taskStyles()
  const isLicenseComplete = practitioner?.has_valid_license
  const isSignatureComplete = practitioner?.has_signature

  useEffect(() => {
    if (!task.complete && isLicenseComplete && isSignatureComplete) {
      onTaskComplete(task.key)
    }
  }, [isLicenseComplete, isSignatureComplete])

  return (
    <TaskContainer
      taskKey={task.key}
      isTaskComplete={task.complete}
      header="Share Your E-Signature & Upload License"
      timeTag="1 min"
    >
      <InitalsIcon className={taskClasses.initialsIcon} />
      <div className={taskClasses.taskTextContainer}>
        <div className={taskClasses.taskText}>
          Order for clients under your own license. Your information will be on
          your patient's requisitions and results.
        </div>
      </div>
      {isLicenseComplete && isSignatureComplete ? (
        <TaskStepComplete taskText="Uploaded License & E-Signature" />
      ) : (
        <>
          {isLicenseComplete ? (
            <TaskStepComplete taskText="Uploaded License" />
          ) : isSignatureComplete ? (
            <TaskStepComplete taskText="Uploaded E-Signature" />
          ) : null}
          <SignatureAndLicenseModalButton
            location={task.key}
            onClose={() => refreshPractitioner()}
            autoClose
          >
            {signatureAndLicenseButtonTitle(practitioner)}
          </SignatureAndLicenseModalButton>
        </>
      )}
    </TaskContainer>
  )
}

export default ExistingLicense
