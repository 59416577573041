import React, { useMemo } from "react"

import { Link } from "@material-ui/core"
import { cn } from "@rupahealth/design"

import { UserPaths } from "app/Routes"
import { ReactComponent as GlobeShieldIcon } from "app/assets/icons/globe-shield-small.svg"
import AlertBanner from "app/components/design-system/banners/AlertBanner"
import InfoBanner from "app/components/design-system/banners/InfoBanner"
import { getStateName } from "app/main/patient-checkout/utils/usStates"
import { Practitioner } from "app/types"

import { useLabOrderingAccess } from "./LabOrderingAccessDataProvider"
import {
  getPracSelectOptionPossessiveName,
  practitionerTypeName,
} from "./helpers"
import { PRAC_OPTION_GROUPS } from "./types"

interface Props {
  practitioner: Practitioner
}

const LabOrderingAccessBanner: React.FC<Props> = ({ practitioner }) => {
  const { selectedPractitioner } = useLabOrderingAccess()

  const { isKnownType, ...innerProps } = useMemo(() => {
    return {
      isKnownType: Boolean(
        selectedPractitioner?.primary_practitioner_type?.known_type
      ),
      practitionerTypeName: practitionerTypeName(
        selectedPractitioner?.primary_practitioner_type
      ),
      clinicStateName:
        getStateName(practitioner.clinic.state) ?? "an Unknown State",
      possessiveName: getPracSelectOptionPossessiveName(selectedPractitioner),
      npiDescriptor: selectedPractitioner?.npi_number ? "with" : "without",
    }
  }, [practitioner, selectedPractitioner])

  if (isKnownType) {
    return <KnownPracTypeBanner practitioner={practitioner} {...innerProps} />
  }
  return <UnKnownPracTypeBanner practitioner={practitioner} {...innerProps} />
}

interface InnerProps {
  practitioner: Practitioner
  practitionerTypeName: string
  clinicStateName: string
  possessiveName: string
  npiDescriptor: string
}

const UnKnownPracTypeBanner: React.FC<InnerProps> = ({
  practitioner,
  practitionerTypeName,
  possessiveName,
}) => {
  return (
    <AlertBanner
      title={`We don't have ordering access information for ${possessiveName} practitioner type "${practitionerTypeName}".`}
      body={
        <div>
          Check out{" "}
          <Link
            href={
              "https://www.rupahealth.com/post/functional-lab-test-ordering-rights-by-state"
            }
          >
            <span className="text-primary underline">this resource</span>{" "}
          </Link>
          on the credentials accepted by each lab.{" "}
          <UpdateInSettingsOrMessageUs practitioner={practitioner} />
        </div>
      }
    />
  )
}

const KnownPracTypeBanner: React.FC<InnerProps> = ({
  practitioner,
  practitionerTypeName,
  clinicStateName,
  possessiveName,
  npiDescriptor,
}) => {
  return (
    <InfoBanner
      body={
        <div className="flex items-center gap-[10px]">
          <span className="rounded-[4px] bg-slate-100 p-[6px]">
            <GlobeShieldIcon className="block m-auto h-full" />
          </span>
          <span className="font-sans">
            Hi {practitioner.user.first_name}! As a{" "}
            <strong>{practitionerTypeName}</strong> in{" "}
            <strong>{clinicStateName}</strong>,{" "}
            <strong>{npiDescriptor} an NPI</strong>, this is {possessiveName}{" "}
            lab access.
            <UpdateInSettingsOrMessageUs practitioner={practitioner} />
          </span>
        </div>
      }
    />
  )
}

const UpdateInSettingsOrMessageUs = ({
  practitioner,
}: {
  practitioner: Practitioner
}) => {
  const { selectedPractitioner } = useLabOrderingAccess()
  if (selectedPractitioner?.group !== PRAC_OPTION_GROUPS.CURRENT_USER) {
    return null
  }

  if (Boolean(practitioner.first_order_date)) {
    return <ToUpdateYourInfoMessageUs />
  }

  return <UpdateInSettings />
}

const UpdateInSettings = () => {
  return (
    <span>
      {" "}
      You can update your info{" "}
      <Link href={UserPaths.ACCOUNT}>
        <span className="text-body underline">in settings</span>
      </Link>
      .
    </span>
  )
}

export const ToUpdateYourInfoMessageUs = ({
  linkClassName,
}: {
  linkClassName?: string
}) => {
  return (
    <span>
      {" "}
      To update your info,{" "}
      <span
        className={cn("font-bold text-primary cursor-pointer", linkClassName)}
        onClick={() => window.Intercom("showNewMessage")}
      >
        Message us
      </span>
      .
    </span>
  )
}

export default LabOrderingAccessBanner
