import { useState } from "react"
import { useDispatch } from "react-redux"

import { generatePath, useHistory } from "react-router-dom"

import { styled } from "@material-ui/core"

import { UserPaths } from "app/Routes"
import { API } from "app/api"
import { ReactComponent as ReorderIcon } from "app/assets/icons/reorder.svg"
import DesignSystemButton from "app/components/design-system/Button"
import { lightGrayBlue } from "app/theme"
import { handleApiError } from "app/utils"

import usePatientOrderStyles from "./hooks/usePatientOrderStyles"

const StyledDesignSystemButton = styled(DesignSystemButton)(() => ({
  alignItems: "center",
  padding: "12px",
  border: `1px solid ${lightGrayBlue}`,
}))

export function OrderAgainButton({ order }) {
  const dispatch = useDispatch()
  const history = useHistory()

  const sharedStyles = usePatientOrderStyles()

  const [isLoading, setIsLoading] = useState(false)

  const orderAgain = async () => {
    setIsLoading(true)
    try {
      const response = await API.Order.orderAgain({ order_id: order.id })
      setIsLoading(false)
      history.push(
        generatePath(UserPaths.ORDER_CHECKOUT, { orderId: response.data.id })
      )
    } catch (error) {
      setIsLoading(false)
      dispatch(handleApiError(error))
    }
  }

  return (
    <StyledDesignSystemButton
      color="secondary"
      shadow="default"
      startIcon={<ReorderIcon viewBox="0 0 14 16" />}
      onClick={orderAgain}
      loading={isLoading}
      size="xsmall"
      className={sharedStyles.button}
    >
      Order Again
    </StyledDesignSystemButton>
  )
}
