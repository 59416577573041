import { useMemo } from "react"

import { ColumnFiltersState, PaginationState } from "@tanstack/react-table"

import useCachedCollection from "app/swr/hooks/use-cached-collection"
import useCollectionSWR from "app/swr/hooks/use-collection-swr"
import { DashboardOrder } from "types/dashboard/dashboard-order"

import { GlobalFilterState } from "../components/TableRenderer"
import {
  convertColumnFiltersToParams,
  convertGlobalFilterToParams,
  convertPaginationToParams,
} from "../utils"

export interface UseDashboardOrdersProps {
  columnFilters: ColumnFiltersState
  globalFilter: GlobalFilterState
  pagination: PaginationState
}

export default function useDashboardOrders({
  columnFilters,
  globalFilter,
  pagination,
}: UseDashboardOrdersProps) {
  const columnFilterParams = useMemo(
    () => convertColumnFiltersToParams(columnFilters),
    [columnFilters]
  )

  const globalFilterParams = useMemo(
    () => convertGlobalFilterToParams(globalFilter),
    [globalFilter]
  )

  const paginationParams = useMemo(
    () => convertPaginationToParams(pagination),
    [pagination]
  )

  // TODO: type the paginated response
  const { data, ...ordersSwr } = useCollectionSWR(
    globalFilter.tab ? "/dashboard/orders/" : null,
    {
      include: ["patient", "practitioner.clinic"],
      params: {
        ...columnFilterParams,
        ...globalFilterParams,
        ...paginationParams,
      },
    },
    {
      // Ensures that if you quickly switch tabs it will reload the data
      dedupingInterval: 100,
      revalidateIfStale: true,
      revalidateOnFocus: true,
      revalidateOnMount: true,
      revalidateOnReconnect: true,
    }
  )

  const orders = useCachedCollection<DashboardOrder>(data)

  return {
    ...ordersSwr,
    data: orders,
  }
}
