import { Table } from "@tanstack/react-table"

import { LabCompanyBiomarkerRange } from "types/lab-company-biomarker-range"

import TableTabsRenderer from "./TableTabsRenderer"

interface Tab {
  label: string
  id: string
}

export interface TabGroup {
  tabs: Tab[]
}

export default function OptimalRangesTabs({
  table,
  availableLabCompanyTabs,
  handleDeleteClick,
  handleToggleHideUnsetRangesClick,
}: {
  table: Table<LabCompanyBiomarkerRange>
  availableLabCompanyTabs: Tab[]
  handleDeleteClick: (labCompanyKey: string) => void
  handleToggleHideUnsetRangesClick: () => void
}) {
  return (
    <TableTabsRenderer
      availablLabCompanyTabs={availableLabCompanyTabs}
      table={table}
      handleDeleteClick={handleDeleteClick}
      handleToggleHideUnsetRangesClick={handleToggleHideUnsetRangesClick}
    />
  )
}
