import { memo } from "react"

import { Theme } from "@material-ui/core"

import DragHandleIcon from "app/assets/images/storefront/drag-handle.svg"
import BodyText from "app/components/design-system/BodyText"
import { StorefrontSectionType } from "app/hooks/use-resource"
import makeAppStyles from "app/utils/makeAppStyles"

const styles = (theme: Theme) => ({
  sectionContainer: {
    width: "100%",
    background: "#fff",
    padding: "18px 15px",
    borderRadius: 8,
    boxShadow:
      "0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)",
    display: "flex",
    "flex-direction": "column",
    gap: 16,
    height: "100%",
  },
  sectionHeader: {
    width: "100%",
    display: "flex",
    "flex-direction": "row",
    gap: 10,
  },
})

const useStyles = makeAppStyles(styles)

interface Props {
  section?: StorefrontSectionType
  children: any
}

const UnsortableSection = memo(({ section, ...props }: Props) => {
  const classes = useStyles()

  return (
    <div className={classes.sectionContainer}>
      {!section?.attributes.is_transparent && (
        <div className={classes.sectionHeader}>
          <div>
            <img src={DragHandleIcon} alt="drag handle" />
          </div>
          <div>
            <BodyText weight="semibold">{section?.attributes.name}</BodyText>
            <BodyText size="xs">{section?.attributes.description}</BodyText>
          </div>
        </div>
      )}

      {props.children}
    </div>
  )
})

export default UnsortableSection
