import { ReactComponent as SampleTypeIcon } from "app/assets/icons/sample-type.svg"
import SampleTypeChip from "app/components/design-system/SampleTypeChip"
import {
  ComparisonRow,
  ComparisonColumnProps,
  EmptyComparisonValue,
} from "app/main/comparison/components/ComparisonLabTests/components"
import makeAppStyles from "app/utils/makeAppStyles"

const useSampleTypeColumnStyles = makeAppStyles((theme) => ({
  sampleTypeWrapper: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    gap: theme.spacing(0.5),
  },
}))

const SampleTypeColumn = ({ labTest }: ComparisonColumnProps) => {
  const classes = useSampleTypeColumnStyles()
  return (
    <div className={classes.sampleTypeWrapper}>
      {labTest?.lab_test_types?.map((sampleType) => (
        <SampleTypeChip
          key={sampleType.name}
          name={sampleType.name.toLowerCase()}
        />
      )) || <EmptyComparisonValue />}
    </div>
  )
}

export default function ComparisonSampleTypeRow() {
  return (
    <ComparisonRow
      Column={SampleTypeColumn}
      headerIcon={<SampleTypeIcon viewBox="0 0 12 12" />}
      headerLabel="Sample Type"
    />
  )
}
