import { Grid } from "@material-ui/core"

import { ORDER_STATUS } from "app/constants"
import * as PatientOrderActions from "app/main/patient-orders/store/actions"
import { colors } from "app/theme"
import {
  OrderedTest,
  PanelLabTest,
  Patient,
  PatientOrdersOrder,
} from "app/types"
import makeAppStyles from "app/utils/makeAppStyles"

import PatientOrdersOrderedTestName from "./PatientOrdersOrderedTestName"
import PatientOrdersOrderedTestOrderActions from "./PatientOrdersOrderedTestOrderActions"
import PatientOrdersOrderedTestPanelTooltip from "./PatientOrdersOrderedTestPanelTooltip"
import PatientOrdersOrderedTestProgress from "./PatientOrdersOrderedTestProgress"
import PatientOrdersOrderedTestSampleType from "./PatientOrdersOrderedTestSampleType"

const useStyles = makeAppStyles<{}>((theme) => ({
  gridContainer: {
    paddingTop: theme.spacing(2.0, 0),
    paddingBottom: theme.spacing(2.0, 0),
    borderTopWidth: "1px",
    borderColor: colors.blueGray[200],
    borderOpacity: "1",
  },
}))

interface Props {
  order: PatientOrdersOrder
  orderedTest: OrderedTest
  patient: Patient
  displayName: string
  panelName?: string
  panelLabTests?: PanelLabTest[]
}

function PatientOrdersOrderedTest({
  order,
  orderedTest,
  patient,
  displayName,
  panelLabTests,
}: Props) {
  const styles = useStyles()

  return (
    <div className="flex flex-row items-center">
      <div className="h-2 w-2 -ml-3 mr-2">
        {orderedTest.is_ordered_test_result_unread ? (
          <div className="w-full h-full rounded-3xl bg-emerald-500"></div>
        ) : null}
      </div>
      <Grid container alignItems="center" className={styles.gridContainer}>
        <Grid item xs={12} md={2}>
          <div>
            <PatientOrdersOrderedTestName
              orderedTest={orderedTest}
              displayName={displayName}
              size={"md"}
            />
            {panelLabTests && (
              <div className="mt-1">
                <PatientOrdersOrderedTestPanelTooltip
                  panelLabTests={panelLabTests}
                />
              </div>
            )}
          </div>
        </Grid>
        <Grid item xs={12} md={2}>
          <div className="mt-2 sm:mt-0 md:ml-2">
            <PatientOrdersOrderedTestSampleType orderedTest={orderedTest} />
          </div>
        </Grid>
        <Grid item xs={12} md={order.status !== ORDER_STATUS.DRAFT ? 5 : 8}>
          <PatientOrdersOrderedTestProgress
            orderedTest={orderedTest}
            order={order}
            patient={patient}
          />
        </Grid>
        {order.status !== ORDER_STATUS.DRAFT && (
          <Grid item xs={6} md={3} className="mb-1">
            <PatientOrdersOrderedTestOrderActions
              orderedTest={orderedTest}
              order={order}
              patient={patient}
              markOrderedTestResultReviewed={
                PatientOrderActions.markOrderedTestResultReviewed
              }
              acknowledgeUnreadResult={
                PatientOrderActions.acknowledgeUnreadResult
              }
            />
          </Grid>
        )}
      </Grid>
    </div>
  )
}

export default PatientOrdersOrderedTest
