import { ThemeOptions, Theme as MuiTheme } from "@material-ui/core"
import { Shadows } from "@material-ui/core/styles/shadows"

// Product Color Styles
export const primaryColor = "#0075CD"
export const secondaryColor = "#1295ab"
export const navy = "#2B455F"
export const maroon = "#C3476B"
export const darkBlue = "#D5E4FF" // deep-100
export const red = "#E85B59"
export const textPrimaryColor = navy
export const modalOverlayColor = "rgba(238, 241, 244, 0.85)"
export const progressBarGradient1 = "#11D3D7"
export const progressBarGradient2 = "#2892E2"
export const lightGrayPure = "#FBFBFB"
export const lightGrayBlue = "#D4DCE4"
export const darkGray = "#3C3C43"
export const lightDisabledColor = "#BFBCBC1F"
export const lightRupaTeal = "rgba(74, 168, 188, 0.1)"
export const rupaTeal = "#4aa8bc"
export const rupaCyan = "#00abce"
export const physAuthBannerColor = "#319CBE"

export const progressBar = {
  backgroundColor: "#DEF0F3",
  barColor: secondaryColor,
}

// These are the new Tailwind colors and used as part of the new design system.
// Replacing these in tailwind.js will require a lot of color updates in the app, so for now we'll just use these
// going forwards for all color needs.
export const colors = {
  rose: {
    50: "#fff1f2",
    100: "#ffe4e6",
    200: "#fecdd3",
    300: "#fda4af",
    400: "#fb7185",
    500: "#f43f5e",
    600: "#e11d48",
    700: "#be123c",
    800: "#9f1239",
    900: "#881337",
  },
  pink: {
    50: "#fdf2f8",
    100: "#fce7f3",
    200: "#fbcfe8",
    300: "#f9a8d4",
    400: "#f472b6",
    500: "#ec4899",
    600: "#db2777",
    700: "#be185d",
    800: "#9d174d",
    900: "#831843",
  },
  fuchsia: {
    50: "#fdf4ff",
    100: "#fae8ff",
    200: "#f5d0fe",
    300: "#f0abfc",
    400: "#e879f9",
    500: "#d946ef",
    600: "#c026d3",
    700: "#a21caf",
    800: "#86198f",
    900: "#701a75",
  },
  purple: {
    50: "#faf5ff",
    100: "#f3e8ff",
    200: "#e9d5ff",
    300: "#d8b4fe",
    400: "#c084fc",
    500: "#a855f7",
    600: "#9333ea",
    700: "#7e22ce",
    800: "#6b21a8",
    900: "#581c87",
  },
  violet: {
    50: "#f5f3ff",
    100: "#ede9fe",
    200: "#ddd6fe",
    300: "#c4b5fd",
    400: "#a78bfa",
    500: "#8b5cf6",
    600: "#7c3aed",
    700: "#6d28d9",
    800: "#5b21b6",
    900: "#4c1d95",
  },
  indigo: {
    50: "#eef2ff",
    100: "#e0e7ff",
    200: "#c7d2fe",
    300: "#a5b4fc",
    400: "#818cf8",
    500: "#6366f1",
    600: "#4f46e5",
    700: "#4338ca",
    800: "#3730a3",
    900: "#312e81",
  },
  blue: {
    50: "#eff6ff",
    100: "#dbeafe",
    200: "#bfdbfe",
    300: "#93c5fd",
    400: "#60a5fa",
    500: "#3b82f6",
    600: "#2563eb",
    700: "#1d4ed8",
    800: "#1e40af",
    900: "#1e3a8a",
  },
  lightBlue: {
    50: "#f0f9ff",
    100: "#e0f2fe",
    200: "#bae6fd",
    300: "#7dd3fc",
    400: "#38bdf8",
    500: "#0ea5e9",
    600: "#0284c7",
    700: "#0369a1",
    800: "#075985",
    900: "#0c4a6e",
  },
  cyan: {
    50: "#ecfeff",
    100: "#cffafe",
    200: "#a5f3fc",
    300: "#67e8f9",
    400: "#22d3ee",
    500: "#06b6d4",
    600: "#0891b2",
    700: "#0e7490",
    800: "#155e75",
    900: "#164e63",
  },
  teal: {
    50: "#f0fdfa",
    100: "#ccfbf1",
    200: "#99f6e4",
    300: "#5eead4",
    400: "#2dd4bf",
    500: "#14b8a6",
    600: "#0d9488",
    700: "#0f766e",
    800: "#115e59",
    900: "#134e4a",
  },
  emerald: {
    50: "#ecfdf5",
    100: "#d1fae5",
    200: "#a7f3d0",
    300: "#6ee7b7",
    400: "#34d399",
    500: "#10b981",
    600: "#059669",
    700: "#047857",
    800: "#065f46",
    900: "#064e3b",
  },
  green: {
    50: "#f0fdf4",
    100: "#dcfce7",
    200: "#bbf7d0",
    300: "#86efac",
    400: "#4ade80",
    500: "#22c55e",
    600: "#16a34a",
    700: "#15803d",
    800: "#166534",
    900: "#14532d",
  },
  lime: {
    50: "#f7fee7",
    100: "#ecfccb",
    200: "#d9f99d",
    300: "#bef264",
    400: "#a3e635",
    500: "#84cc16",
    600: "#65a30d",
    700: "#4d7c0f",
    800: "#3f6212",
    900: "#365314",
  },
  yellow: {
    50: "#fefce8",
    100: "#fef9c3",
    200: "#fef08a",
    300: "#fde047",
    400: "#facc15",
    500: "#eab308",
    600: "#ca8a04",
    700: "#a16207",
    800: "#854d0e",
    900: "#713f12",
  },
  amber: {
    50: "#fffbeb",
    100: "#fef3c7",
    200: "#fde68a",
    300: "#fcd34d",
    400: "#fbbf24",
    500: "#f59e0b",
    600: "#d97706",
    700: "#b45309",
    800: "#92400e",
    900: "#78350f",
  },
  orange: {
    50: "#fff7ed",
    100: "#ffedd5",
    200: "#fed7aa",
    300: "#fdba74",
    400: "#fb923c",
    500: "#f97316",
    600: "#ea580c",
    700: "#c2410c",
    800: "#9a3412",
    900: "#7c2d12",
  },
  red: {
    50: "#fef2f2",
    100: "#fee2e2",
    200: "#fecaca",
    300: "#fca5a5",
    400: "#f87171",
    500: "#ef4444",
    600: "#dc2626",
    700: "#b91c1c",
    800: "#991b1b",
    900: "#7f1d1d",
  },
  warmGray: {
    50: "#fafaf9",
    100: "#f5f5f4",
    200: "#e7e5e4",
    300: "#d6d3d1",
    400: "#a8a29e",
    500: "#78716c",
    600: "#57534e",
    700: "#44403c",
    800: "#292524",
    900: "#1c1917",
  },
  trueGray: {
    50: "#fafafa",
    100: "#f5f5f5",
    200: "#e5e5e5",
    300: "#d4d4d4",
    400: "#a3a3a3",
    500: "#737373",
    600: "#525252",
    700: "#404040",
    800: "#262626",
    900: "#171717",
  },
  gray: {
    50: "#fafafa",
    100: "#f4f4f5",
    200: "#e4e4e7",
    300: "#d4d4d8",
    400: "#a1a1aa",
    500: "#71717a",
    600: "#52525b",
    700: "#3f3f46",
    800: "#27272a",
    900: "#18181b",
  },
  coolGray: {
    50: "#f9fafb",
    100: "#f3f4f6",
    200: "#e5e7eb",
    300: "#d1d5db",
    400: "#9ca3af",
    500: "#6b7280",
    600: "#4b5563",
    700: "#374151",
    800: "#1f2937",
    900: "#111827",
  },
  blueGray: {
    50: "#f8fafc",
    100: "#f1f5f9",
    150: "#EAEFF5",
    200: "#e2e8f0",
    300: "#cbd5e1",
    400: "#94a3b8",
    500: "#64748b",
    600: "#475569",
    700: "#334155",
    800: "#1e293b",
    900: "#0f172a",
  },
}

// Semantic Color Styles
export const cancelRed = colors.red[600]
export const rupaRed = "#E85B59"
export const alertYellow = colors.yellow[400]
export const buttonSuccessGreen = colors.emerald[500]
export const disabledGray = colors.gray[200]
export const favoritedColor = "#F61F76"
export const dashboardHighlight = "#F6FCFF"
export const white = "#FFFFFF"

// DEPRECATED - These old named color constants shouldn't be used anymore.
export const veryLightBlue = "#d4dce4"
export const blueGray = "#B9CDDA"
export const lightGray = "#f9f9f9"
export const veryLightGray = "#fcfcfc"

// Fonts
export const titleFontFamily = '"Josefin Sans", "Open Sans", sans-serif'
export const titleFontFamilyImportant = `${titleFontFamily} !important`
export const bodyFontFamily = '"Open Sans", sans-serif'
export const bodyFontFamilyImportant = `${bodyFontFamily} !important`

// Shadows
export const shadows = {
  sm: "0 1px 2px 0 rgba(0, 0, 0, 0.05)",
  default: "0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)",
  md: "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)",
  lg: "0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)",
  xl: "0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)",
  "2xl": "0 25px 50px -12px rgba(0, 0, 0, 0.25)",
  "3xl": "0 35px 60px -15px rgba(0, 0, 0, 0.3)",
  inset: "inset 0px 3px 9px rgba(43, 69, 95, 0.06)",
}

// Gradients
export const gradients = {
  lightGrayToGray:
    "linear-gradient(180deg, rgba(255, 255, 255, 0.52) 0%, #F1F5F9 100%)",
}

export const userNavBarWidth = 266
export const userNavBarCollapsedWidth = 75
export const userNavBarMobileHeight = 62
export const settingsNavBarLabelWidth = 242
export const settingsNavBarLabelWidthTablet = 220

const theme: ThemeOptions = {
  palette: {
    type: "light" as "light",
    primary: {
      light: "#28a9cf",
      main: primaryColor,
      dark: "#306c92",
      contrastText: "#fff",
    },
    secondary: {
      light: "#d7ffea",
      main: "#b2f8db",
      dark: "#85b7a1",
      contrastText: "#1a1a1a",
    },
    text: {
      primary: textPrimaryColor,
      secondary: "#4a5568",
    },
    error: {
      main: cancelRed,
    },
  },
  typography: {
    htmlFontSize: 16,
    body1: {
      fontSize: "14px",
    },
    body2: {
      fontSize: "14px",
    },
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
    fontFamily: bodyFontFamily,
    h1: {
      fontFamily: titleFontFamily,
    },
    h2: {
      fontFamily: titleFontFamily,
    },
    h3: {
      fontFamily: titleFontFamily,
    },
    h4: {
      fontFamily: titleFontFamily,
    },
    h5: {
      fontFamily: titleFontFamily,
    },
    h6: {
      fontFamily: titleFontFamily,
    },
    button: {
      fontFamily: '"Open Sans", sans-serif',
      textTransform: "none" as "none",
    },
  },
  overrides: {
    MuiInputLabel: {
      root: {
        "&$disabled": {
          color: "#4a5568",
        },
      },
    },
    MuiBackdrop: {
      root: {
        backgroundColor: "rgba(238, 241, 244, 0.85)",
      },
    },
    MuiInputBase: {
      input: {
        fontSize: "16px",
        "&$disabled": {
          cursor: "not-allowed",
        },
      },
    },
    MuiFormHelperText: {
      contained: {
        margin: "0 !important",
      },
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1325,
      xl: 1550,
    },
  },
  shadows: [
    "none",
    "0px 2px 1px -1px rgba(0,0,0,0.15),0px 1px 1px 0px rgba(0,0,0,0.08),0px 1px 3px 0px rgba(0,0,0,0.05)",
    "0px 3px 1px -2px rgba(0,0,0,0.15),0px 2px 2px 0px rgba(0,0,0,0.08),0px 1px 5px 0px rgba(0,0,0,0.05)",
    "0px 3px 3px -2px rgba(0,0,0,0.15),0px 3px 4px 0px rgba(0,0,0,0.08),0px 1px 8px 0px rgba(0,0,0,0.05)",
    "0px 2px 4px -1px rgba(0,0,0,0.15),0px 4px 5px 0px rgba(0,0,0,0.08),0px 1px 10px 0px rgba(0,0,0,0.05)",
    "0px 3px 5px -1px rgba(0,0,0,0.15),0px 5px 8px 0px rgba(0,0,0,0.08),0px 1px 14px 0px rgba(0,0,0,0.05)",
    "0px 3px 5px -1px rgba(0,0,0,0.15),0px 6px 10px 0px rgba(0,0,0,0.08),0px 1px 18px 0px rgba(0,0,0,0.05)",
    "0px 4px 5px -2px rgba(0,0,0,0.15),0px 7px 10px 1px rgba(0,0,0,0.08),0px 2px 16px 1px rgba(0,0,0,0.05)",
    "0px 5px 5px -3px rgba(0,0,0,0.15),0px 8px 10px 1px rgba(0,0,0,0.08),0px 3px 14px 2px rgba(0,0,0,0.05)",
    "0px 5px 6px -3px rgba(0,0,0,0.15),0px 9px 12px 1px rgba(0,0,0,0.08),0px 3px 16px 2px rgba(0,0,0,0.05)",
    "0px 6px 6px -3px rgba(0,0,0,0.15),0px 10px 14px 1px rgba(0,0,0,0.08),0px 4px 18px 3px rgba(0,0,0,0.05)",
    "0px 6px 7px -4px rgba(0,0,0,0.15),0px 11px 15px 1px rgba(0,0,0,0.08),0px 4px 20px 3px rgba(0,0,0,0.05)",
    "0px 7px 8px -4px rgba(0,0,0,0.15),0px 12px 17px 2px rgba(0,0,0,0.08),0px 5px 22px 4px rgba(0,0,0,0.05)",
    "0px 7px 8px -4px rgba(0,0,0,0.15),0px 13px 19px 2px rgba(0,0,0,0.08),0px 5px 24px 4px rgba(0,0,0,0.05)",
    "0px 7px 9px -4px rgba(0,0,0,0.15),0px 14px 21px 2px rgba(0,0,0,0.08),0px 5px 26px 4px rgba(0,0,0,0.05)",
    "0px 8px 9px -5px rgba(0,0,0,0.15),0px 15px 22px 2px rgba(0,0,0,0.08),0px 6px 28px 5px rgba(0,0,0,0.05)",
    "0px 8px 10px -5px rgba(0,0,0,0.15),0px 16px 24px 2px rgba(0,0,0,0.08),0px 6px 30px 5px rgba(0,0,0,0.05)",
    "0px 8px 11px -5px rgba(0,0,0,0.15),0px 17px 26px 2px rgba(0,0,0,0.08),0px 6px 32px 5px rgba(0,0,0,0.05)",
    "0px 9px 11px -5px rgba(0,0,0,0.15),0px 18px 28px 2px rgba(0,0,0,0.08),0px 7px 34px 6px rgba(0,0,0,0.05)",
    "0px 9px 12px -6px rgba(0,0,0,0.15),0px 19px 29px 2px rgba(0,0,0,0.08),0px 7px 36px 6px rgba(0,0,0,0.05)",
    "0px 10px 13px -6px rgba(0,0,0,0.15),0px 20px 31px 3px rgba(0,0,0,0.08),0px 8px 38px 7px rgba(0,0,0,0.05)",
    "0px 10px 13px -6px rgba(0,0,0,0.15),0px 21px 33px 3px rgba(0,0,0,0.08),0px 8px 40px 7px rgba(0,0,0,0.05)",
    "0px 10px 14px -6px rgba(0,0,0,0.15),0px 22px 35px 3px rgba(0,0,0,0.08),0px 8px 42px 7px rgba(0,0,0,0.05)",
    "0px 11px 14px -7px rgba(0,0,0,0.15),0px 23px 36px 3px rgba(0,0,0,0.08),0px 9px 44px 8px rgba(0,0,0,0.05)",
    "0px 11px 15px -7px rgba(0,0,0,0.15),0px 24px 38px 3px rgba(0,0,0,0.08),0px 9px 46px 8px rgba(0,0,0,0.05)",
  ] as Shadows,
}

export type Theme = MuiTheme & typeof theme

export default theme
