import NiceModal, { muiDialog, useModal } from "@ebay/nice-modal-react"
import { makeStyles, useMediaQuery, styled, Theme } from "@material-ui/core"
import MuiDialogActions from "@material-ui/core/DialogActions"
import MuiDialogContent from "@material-ui/core/DialogContent"
import MuiDialogTitle from "@material-ui/core/DialogTitle"
import IconButton from "@material-ui/core/IconButton"
import CloseIcon from "@material-ui/icons/Close"

import BodyText from "app/components/design-system/BodyText"
import DesignSystemButton from "app/components/design-system/Button"
import DisplayText from "app/components/design-system/DisplayText"
import { Dialog } from "app/components/modals"
import { navy, colors } from "app/theme"

interface StyledContentProps {
  isMobile: boolean
}

interface ConfirmationModalProps {
  title: string
  message: string | JSX.Element
  backButtonTitle: string
  confirmButtonTitle: string
  confirmButtonColor: string
  onClose: () => void
  loading: boolean
  handleConfirm: () => void
  showBackButton?: boolean
  maxWidth?: "xs" | "sm" | "md" | "lg" | "xl"
  fullWidthButtons?: boolean
}

const styles = () => ({
  dialogPaper: {
    margin: 15,
    borderRadius: 12,
  },
})

const useStyles = makeStyles(styles)

const Container = styled("div")(({ theme }) => ({
  display: "flex",
  flexFlow: "column",
  alignItems: "stretch",
  width: "100%",
  position: "relative",

  [theme.breakpoints.up("md")]: {
    flexFlow: "row",
  },

  // As the checkout stretches the entire width of the screen, we need
  // an extra breakpoint for the lab test cards.
  "& .labTestCardGridItem": {
    "@media (min-width: 2000px)": {
      flexBasis: "20%",
      width: "20%",
    },
  },
}))

const StyledContent = styled(MuiDialogContent)(
  ({ isMobile }: StyledContentProps) => ({
    padding: isMobile ? 12 : 22.5,
    backgroundColor: colors.coolGray[50],
  })
)

const StyledMuiDialogTitle = styled(MuiDialogTitle)(
  ({ isMobile }: StyledContentProps) => ({
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    flexWrap: isMobile ? "wrap" : "nowrap",
    backgroundColor: "white",
    borderBottomWidth: 1,
    borderColor: colors.blueGray[200],
    alignItems: "center",
    padding: "8px 24px",
  })
)

const ActionsContainer = styled(MuiDialogActions)({
  margin: 0,
  padding: "15px 22.5px",
})

const TitleAndInfoContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  flexWrap: "wrap",
  marginRight: 24,
})

const CloseButton = styled(IconButton)(({ isMobile }: StyledContentProps) => ({
  color: navy,
  position: isMobile ? "absolute" : "relative",
  top: 1,
  right: isMobile ? 12 : 0,
  marginRight: -12,
}))

/**
 * Confirmation Modal
 * @param open - The modal will be open if this is true
 * @param title - The title of the modal
 * @param message - The message shown in the modal
 * @param backButtonTitle - The back button title
 * @param confirmButtonTitle - The confirm button title
 * @param confirmButtonColor - destructive | primary | secondary - The color of the confirm button
 * @param onClose - The action which needs to be done when the modal closes
 * @param loading - The loader state
 * @param handleConfirm - The action which needs to be done on confirm
 */
const ConfirmationModal = ({
  title = "Are You Sure?",
  message = "",
  backButtonTitle = "Go back",
  confirmButtonTitle = "Delete",
  confirmButtonColor = "destructivePrimary",
  onClose,
  loading,
  handleConfirm,
  showBackButton = true,
  maxWidth = "sm",
  fullWidthButtons = false,
}: ConfirmationModalProps) => {
  const classes = useStyles(styles)

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"))
  const modal = useModal()
  const muiDialogControl = muiDialog(modal)

  return (
    <>
      <Dialog
        {...muiDialogControl}
        disableEnforceFocus
        disableEscapeKeyDown
        fullWidth
        maxWidth={maxWidth}
        className={"fs-unmask"}
        classes={{
          paper: classes.dialogPaper,
        }}
      >
        <TitleSection title={title} onClose={onClose} isMobile={isMobile} />
        <StyledContent isMobile={isMobile}>
          <Container>
            {typeof message === "string" ? (
              <BodyText>{message}</BodyText>
            ) : (
              message
            )}
          </Container>
        </StyledContent>
        <ActionsContainer>
          {showBackButton && (
            <DesignSystemButton
              color="noaction"
              onClick={onClose}
              fullWidth={fullWidthButtons}
            >
              {backButtonTitle}
            </DesignSystemButton>
          )}
          <DesignSystemButton
            color={confirmButtonColor}
            loading={loading}
            onClick={handleConfirm}
            fullWidth={fullWidthButtons}
          >
            {confirmButtonTitle}
          </DesignSystemButton>
        </ActionsContainer>
      </Dialog>
    </>
  )
}

const TitleSection = ({ title, onClose, isMobile }) => {
  const closeButton = onClose && (
    <CloseButton
      aria-label="close"
      onClick={onClose}
      key="close-button"
      isMobile={isMobile}
    >
      <CloseIcon />
    </CloseButton>
  )

  return (
    <StyledMuiDialogTitle disableTypography isMobile={isMobile}>
      <TitleAndInfoContainer>
        <DisplayText weight="semibold" size="lg">
          {title}
        </DisplayText>
      </TitleAndInfoContainer>
      {closeButton}
    </StyledMuiDialogTitle>
  )
}

const ConfirmationModalNiceModal =
  NiceModal.create<ConfirmationModalProps>(ConfirmationModal)

export default ConfirmationModalNiceModal
