import {
  styled,
  Table,
  TableCell,
  TableHead,
  TableContainer,
  TableRow,
  Paper,
  TableBody,
} from "@material-ui/core"

import { colors } from "app/theme"
import { LabCompany, PractitionerListItem } from "app/types"

import LabOrderingAccessTableRow from "./LabOrderingAccessTableRow"

const Wrapper = styled("div")({
  background: colors.coolGray[50],
  padding: "0 24px",
  marginBottom: 20,
})

const StyledTable = styled(Table)({
  border: `1px solid ${colors.coolGray[200]}`,
})

const StyledTableCell = styled(TableCell)({
  color: colors.blueGray[500],
  maxHeight: 74,
  width: "28%",
})

const StyledTableHead = styled(TableHead)({
  maxHeight: 37,
  background: colors.blueGray[100],
})

interface Props {
  labCompanies: LabCompany[] | null
  allowedLabCompanies: LabCompany[]
  selectedPractitionerListItem?: PractitionerListItem
}

function LabOrderingAccessTable(props: Props) {
  return (
    <Wrapper>
      <br></br>
      <TableContainer component={Paper}>
        <StyledTable aria-label="lab-ordering-access">
          <StyledTableHead>
            <TableRow>
              <StyledTableCell>LAB COMPANY</StyledTableCell>
              <StyledTableCell></StyledTableCell>
              <StyledTableCell>SPECIALTIES</StyledTableCell>
              <StyledTableCell>ORDERING ACCESS</StyledTableCell>
            </TableRow>
          </StyledTableHead>
          <TableBody>
            {props.labCompanies?.map((labCompany) => (
              <LabOrderingAccessTableRow
                hasOrderingAccess={props.allowedLabCompanies.includes(
                  labCompany
                )}
                key={labCompany.name}
                labCompany={labCompany}
                selectedPractitionerListItem={
                  props.selectedPractitionerListItem
                }
              />
            ))}
          </TableBody>
        </StyledTable>
      </TableContainer>
    </Wrapper>
  )
}

export default LabOrderingAccessTable
