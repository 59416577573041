import { faUserCheck } from "@fortawesome/free-solid-svg-icons"
import { styled } from "@material-ui/core"

import ArrowTopRight from "app/assets/icons/arrow-top-right.svg"
import BodyText from "app/components/design-system/BodyText"
import PatientCheckoutBanner from "app/main/patient-checkout/PatientCheckoutBanner"
import { PatientPortalPatient } from "app/patient-portal/types"
import { colors, primaryColor } from "app/theme"
import { PatientInstructionFillOutReqForm } from "app/types"

interface Props {
  patientInstruction: PatientInstructionFillOutReqForm
  patient: PatientPortalPatient
}

const SampleReqFormForCompany = styled("div")({
  display: "flex",
  flexDirection: "column",

  "&:not(:first-child)": {
    borderTop: `1px solid ${colors.blueGray[200]}`,
    paddingTop: 20,
  },
})

const SampleReqURL = styled("a")({
  color: primaryColor,
  marginTop: 5,
  fontWeight: 600,
})

const PrintReqIcon = styled("img")({
  marginLeft: 4,
})

const Instructions = styled(BodyText)({
  marginTop: 3,
})

const FillOutReqForms = ({ patientInstruction, patient }: Props) => (
  <div className="w-full flex flex-col gap-5">
    <PatientCheckoutBanner
      text={`Please use ${patient.attributes.full_name} as the patient’s name on the requisition so we can keep track of your order!`}
      icon={faUserCheck}
      type="warning"
      className="mb-2"
    />
    <div>
      {patientInstruction.sample_form_for_companies.map(
        (sampleFormForCompany) => (
          <SampleReqFormForCompany key={sampleFormForCompany.title}>
            <BodyText weight="semibold" size="md">
              {sampleFormForCompany.title}
            </BodyText>
            {sampleFormForCompany.instructions && (
              <Instructions weight="regular" size="base">
                {sampleFormForCompany.instructions}
              </Instructions>
            )}
            {sampleFormForCompany.sample_requisition_link && (
              <SampleReqURL
                href={sampleFormForCompany.sample_requisition_link}
                target="_blank"
              >
                See Instructions on How to Fill Out Your Requisition
                <PrintReqIcon src={ArrowTopRight} alt="" />
              </SampleReqURL>
            )}
          </SampleReqFormForCompany>
        )
      )}
    </div>
  </div>
)

export default FillOutReqForms
