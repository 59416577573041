import { cn } from "@rupahealth/design"

import AlertIcon from "app/assets/images/alert-icon.png"

interface Props {
  title?: React.ReactNode
  body?: React.ReactNode
  className?: string
}

const AlertBanner: React.FC<Props> = ({ title, body, className }) => {
  return (
    <div
      className={cn(
        "border border-yellow-300 bg-yellow-50 rounded-lg shadow-sm text-body",
        className
      )}
    >
      <div className="p-3">
        <div className="flex flex-col sm:flex-row gap-4 sm:gap-0 items-center justify-between">
          <div className="flex items-center gap-3">
            <span className="rounded-lg p-2 bg-yellow-200 w-10 h-10 text-center content-center">
              <img src={AlertIcon} className="w-5 h-5" alt="alert" />
            </span>
            <div className="flex flex-col">
              {!!title && <div className="font-semibold">{title}</div>}
              {body}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AlertBanner
