import { cn } from "@rupahealth/design"

import BodyText from "app/components/design-system/BodyText"

import { ResultsOverTimeDateGrouping } from "../types/types"

interface Props {
  currTimeSeriesGrouping: ResultsOverTimeDateGrouping
  handleSetTimeSeriesGrouping: (grouping: ResultsOverTimeDateGrouping) => void
}

const TimeSeriesGroupingToggle = ({
  currTimeSeriesGrouping,
  handleSetTimeSeriesGrouping,
}: Props) => {
  return (
    <div className="flex w-full lg:w-[250px] h-[40px] p-1 items-center rounded-lg bg-slate-200 gap-1">
      <div
        className={cn(
          "flex",
          "flex-col",
          "justify-center",
          "items-center",
          "gap-2",
          "flex-1",
          "self-stretch",
          "rounded-md",
          "cursor-pointer",
          {
            "bg-white":
              currTimeSeriesGrouping === ResultsOverTimeDateGrouping.DAY,
            "shadow-sm":
              currTimeSeriesGrouping === ResultsOverTimeDateGrouping.DAY,
          }
        )}
        onClick={() => {
          handleSetTimeSeriesGrouping(ResultsOverTimeDateGrouping.DAY)
        }}
      >
        <BodyText
          size="sm"
          weight={
            currTimeSeriesGrouping === ResultsOverTimeDateGrouping.DAY
              ? "semibold"
              : "regular"
          }
        >
          Day
        </BodyText>
      </div>
      <div
        className={cn(
          "flex",
          "flex-col",
          "justify-center",
          "items-center",
          "gap-2",
          "flex-1",
          "self-stretch",
          "rounded-md",
          "cursor-pointer",
          {
            "bg-white":
              currTimeSeriesGrouping === ResultsOverTimeDateGrouping.MONTH,
            "shadow-sm":
              currTimeSeriesGrouping === ResultsOverTimeDateGrouping.MONTH,
          }
        )}
        onClick={() => {
          handleSetTimeSeriesGrouping(ResultsOverTimeDateGrouping.MONTH)
        }}
      >
        <BodyText
          size="sm"
          weight={
            currTimeSeriesGrouping === ResultsOverTimeDateGrouping.MONTH
              ? "semibold"
              : "regular"
          }
        >
          Month
        </BodyText>
      </div>
    </div>
  )
}

export default TimeSeriesGroupingToggle
