import { Typography, Link, Container, styled } from "@material-ui/core"

import ArrowRight from "app/assets/icons/arrow-right.svg"
import CatalogBannerBackground from "app/assets/images/catalog-banner.svg"
import { colors, navy } from "app/theme"

const BannerContainer = styled("div")({
  background: navy,
  width: "100%",
})

function CatalogBanner() {
  return (
    <BannerContainer>
      <Container
        maxWidth="xl"
        className="h-40 sm:h-48 flex flex-col justify-center w-full overflow-hidden"
      >
        <img
          src={CatalogBannerBackground}
          alt="Example Lab Tests"
          className="absolute inset-y-0 right-0 max-h-48 hidden md:block z-0 md:-mr-20 lg:mr-0"
        />
        <Typography
          className="text-white font-semibold text-xl sm:text-2xl z-10"
          component="h2"
        >
          Order from 30+ labs in 20 seconds
        </Typography>
        <Typography className="text-white mt-1 sm:mt-3 max-w-md font-light text-base sm:text-lg z-10">
          We make ordering quick and painless — and best of all, it's free for
          practitioners.
          <Link
            className="font-semibold text-teal-300 mt-2 sm:mt-0 sm:ml-2 block sm:inline"
            href="https://www.rupahealth.com/sign-up?utm_source=catalog&utm_medium=banner&utm_campaign=signup-to-see-pricing"
            style={{ color: colors.teal[300] }}
          >
            Sign up free
            <img src={ArrowRight} alt="" className="ml-2" />
          </Link>
        </Typography>
      </Container>
    </BannerContainer>
  )
}

export default CatalogBanner
