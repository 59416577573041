import _ from "lodash"

import {
  INSURANCE_LAB_COMPANIES,
  LAB_COMPANY_KEY,
  MEDICARE_LAB_COMPANIES,
  ORDER_STATUS,
} from "app/constants"
import {
  AnyOrder,
  Order,
  OrderedTest,
  OrderLineItem,
  Patient,
  PatientPortalOrderedTest,
  PatientPortalOrderLineItem,
} from "app/types"

export function canUseInsurance(order) {
  return _.some(
    order.ordered_tests,
    (ordered_test) =>
      (INSURANCE_LAB_COMPANIES.includes(
        ordered_test.lab_test.lab_company.key
      ) ||
        MEDICARE_LAB_COMPANIES.includes(
          ordered_test.lab_test.lab_company.key
        )) &&
      (!!ordered_test.lab_test.initial_insurance_price ||
        !!ordered_test.lab_test.medicare_price)
  )
}

export function labCompaniesUsingInsurance(order) {
  if (!order.use_insurance && order.insurance_enabled_for?.length < 1) {
    return []
  }

  return order.ordered_tests
    .filter(
      (orderedTest) =>
        orderedTest.lab_test.initial_insurance_price !== null ||
        orderedTest.lab_test.medicare_price !== null ||
        orderedTest.lab_test.lab_company.key in order.insurance_enabled_for
    )
    .map((orderedTest) => orderedTest.lab_test.lab_company)
}

/**
 * Checks if order is blocked because of missing signature
 * @param order
 * @returns {boolean}
 */
export function orderBlockedMissingSignature(order) {
  return order.status === ORDER_STATUS.BLOCKED_MISSING_SIGNATURE ||
    (order.status === ORDER_STATUS.DRAFT &&
      order.practitioner &&
      !order.practitioner.has_signature)
    ? true
    : false
}

/**
 * Checks if the order is eligible for order-again
 * @param order
 * @returns {boolean}
 */
export function canOrderAgain(order) {
  return [
    ORDER_STATUS.PENDING_SHIPPING_INFO,
    ORDER_STATUS.AWAITING_SHIPMENT,
    ORDER_STATUS.IN_PROGRESS,
    ORDER_STATUS.COMPLETE,
    ORDER_STATUS.RESULTS_REVIEWED,
  ].some((status) => status === order.status)
}

/**
 * Checks if the order has any instant requisition tests
 * @param order
 * @returns {boolean}
 */
export function hasInstantRequisitionTests(order) {
  return order.ordered_tests.some(
    (orderedTest) => orderedTest.instant_requisition === true
  )
}

export function isOrderedTestForCompany(
  orderedTest: OrderedTest | PatientPortalOrderedTest,
  labCompanyKey: string
) {
  return orderedTest.lab_test.lab_company.key === labCompanyKey
}

export function isLineItemActive(
  lineItem: OrderLineItem | PatientPortalOrderLineItem
) {
  return !lineItem.date_disabled
}

/**
 * Checks if order has any tests with the given lab company
 */
export function orderHasTestWithCompany(
  order: AnyOrder,
  labCompanyKey: string
) {
  return order.ordered_tests.some((orderedTest) =>
    isOrderedTestForCompany(orderedTest, labCompanyKey)
  )
}

/**
 * Checks if an order has active tests for the given lab company.
 */
export function orderHasActiveOrderedTestsForLabCompany(
  order: AnyOrder,
  labCompanyKey: string
): boolean {
  return order.line_items.some(
    (lineItem) =>
      // Return true if the line item is for the given lab company AND
      // is active (date_disabled is falsy)
      lineItem.ordered_test &&
      isOrderedTestForCompany(lineItem.ordered_test, labCompanyKey) &&
      isLineItemActive(lineItem)
  )
}

/**
 * Determine if the order has active BRL tests.
 */
export function orderHasActiveBioReferenceTests(order: AnyOrder): boolean {
  if (!order?.flags?.CHECK_SCARLET_ELIGIBILITY) {
    return false
  }
  return orderHasActiveOrderedTestsForLabCompany(
    order,
    LAB_COMPANY_KEY.BIOREFERENCE
  )
}

export enum InstantRequisitionDisabledReason {
  UNPAID,
  NO_DEMOGRAPHIC_INFO,
  NO_CONSENT,
}

/**
 * Determines if an instant requisition are available for an Order.
 * The order must be:
 * - paid for
 * - we have collected the patient's demographic info `patient.has_order_info`
 * - the patient has given consent
 */

export function requisitionDisabledForOrder(
  order: Pick<Order, "date_paid">,
  patient: Pick<Patient, "has_order_info" | "given_consent">
): [boolean, InstantRequisitionDisabledReason?] {
  if (!order.date_paid) {
    return [true, InstantRequisitionDisabledReason.UNPAID]
  }

  if (!patient.has_order_info) {
    return [true, InstantRequisitionDisabledReason.NO_DEMOGRAPHIC_INFO]
  }

  if (!patient.given_consent) {
    return [true, InstantRequisitionDisabledReason.NO_CONSENT]
  }

  return [false]
}

/**
 * Returns true if this is an order that requires an email/text notification be sent to the patient.
 * @param order
 * @param patient
 * @returns {*|boolean}
 */
export function patientNotificationRequired(order, patient) {
  return !(order.is_practitioner_paying && patient.has_order_info)
}
