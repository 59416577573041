import * as React from "react"

import { makeStyles, Theme } from "@material-ui/core"
import MuiDialogTitle from "@material-ui/core/DialogTitle"

import rupaHealthLogo from "app/assets/images/logos/rupa-logo-mark.svg"
import DisplayText from "app/components/design-system/DisplayText"
import { VENDOR_PHYSICIAN_AUTHORIZATION_LABEL } from "app/constants"
import { colors } from "app/theme"

const styles = (theme: Theme) => ({
  contentContainer: {
    "text-align": "center",
  },
  logo: {
    marginRight: 8,
    marginBottom: -6,
    margin: "auto",
    width: "32",
    height: "auto",
  },
  modalPage: {
    display: "flex",
    flexFlow: "column",
    alignItems: "stretch",
    background: colors.blueGray[50],
    overflow: "auto",
    height: "100%",
  },
  topText: {
    marginTop: 48,
    marginBottom: 16,
  },
})

const useStyles = makeStyles(styles)

interface Props {
  content: React.ReactNode
}

/**
 * Standard wrapper to be used around Physician Authorization modal steps
 * @param content - Content to be used within the modal
 */
const ModalWrapper = ({ content }: Props) => {
  const classes = useStyles(styles)

  return (
    <div className={classes.modalPage}>
      <div className={classes.contentContainer}>
        <MuiDialogTitle disableTypography>
          <div>
            <DisplayText
              weight="semibold"
              size="2xl"
              className={classes.topText}
            >
              <img className={classes.logo} src={rupaHealthLogo} alt="" />
              {VENDOR_PHYSICIAN_AUTHORIZATION_LABEL}
            </DisplayText>
          </div>
        </MuiDialogTitle>
        {content}
      </div>
    </div>
  )
}

export default ModalWrapper
