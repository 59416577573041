import { makeStyles } from "@material-ui/core/styles"

import { ReactComponent as OrderCollapsed } from "app/assets/icons/dashboard-order-collapsed.svg"
import { ReactComponent as OrderExpanded } from "app/assets/icons/dashboard-order-expanded.svg"
import { colors } from "app/theme"

import { ORDER_STATUS } from "../../../constants"
import { TableCellProps } from "./types"

const useStyles = makeStyles((theme) => ({
  detailPanelContainer: {
    display: "flex",
    gap: 10,
    alignItems: "center",
  },
  icon: {
    width: "100%",
    height: "100%",
    padding: "0",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #CBD5E1",
    boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.07)",
    borderRadius: "4px",
  },
  iconContainer: {
    width: "16px",
    height: "16px",
    backgroundColor: "white",
  },
  notificationContainer: {
    width: 12,
    height: 12,
    background: colors.emerald[500],
    borderRadius: 20,
  },
  notificationContainerPlaceholder: {
    width: 12,
    height: 12,
  },
}))

export default function DetailPanelIcon({
  order,
  showNotificationIndicator,
  showNotificationIndicatorPlaceholder,
}: TableCellProps) {
  const classes = useStyles()
  return (
    <div className={classes.detailPanelContainer}>
      {showNotificationIndicator && (
        <div className={classes.notificationContainer} />
      )}
      {showNotificationIndicatorPlaceholder && !showNotificationIndicator && (
        <div className={classes.notificationContainerPlaceholder} />
      )}
      {order?.status !== ORDER_STATUS.DRAFT && (
        <div className={classes.iconContainer}>
          <div className={classes.icon}>
            {Boolean(order.tableData.showDetailPanel) ? (
              <OrderExpanded />
            ) : (
              <OrderCollapsed />
            )}
          </div>
        </div>
      )}
    </div>
  )
}
