import { useMemo } from "react"

import { CircularProgress } from "@material-ui/core"

import { shadows } from "app/theme"
import { Practitioner } from "app/types"
import makeAppStyles from "app/utils/makeAppStyles"

import { useOrderHistory } from "./OrderHistoryDataProvider"
import { OrderHistoryTable } from "./OrderHistoryTable"

const useOrderListStyles = makeAppStyles((theme) => ({
  container: {
    borderRadius: 8,
    overflow: "hidden",
    background: "white",
    boxShadow: shadows.default,
  },
}))

interface OrderListProps {
  practitioner: Practitioner
}
const OrderHistoryTab = ({ practitioner }: OrderListProps) => {
  const { container } = useOrderListStyles()
  const { ordersLoading, ordersValidating } = useOrderHistory()

  // If we re-render OrderTable before we are done validating, we will get a very slow re-render
  const ordersReady = useMemo(() => {
    return !ordersLoading && !ordersValidating
  }, [ordersLoading, ordersValidating])

  return (
    <div className={container}>
      {ordersReady ? (
        <OrderHistoryTable practitioner={practitioner} />
      ) : (
        <div className="flex w-100 justify-center p-10">
          <CircularProgress aria-label="Loading orders" />
        </div>
      )}
    </div>
  )
}

export default OrderHistoryTab
