import { useMemo } from "react"

import { cn } from "@rupahealth/design"
import {
  CellContext,
  ColumnDef,
  HeaderContext,
  createColumnHelper,
} from "@tanstack/react-table"

import { ReactComponent as CircleCheckIcon } from "app/assets/icons/circular/circle-check.svg"
import { ReactComponent as CircleQuestionIcon } from "app/assets/icons/circular/circle-question.svg"
import { ReactComponent as CircleXIcon } from "app/assets/icons/circular/circle-x.svg"
import { ReactComponent as NPIMissingIcon } from "app/assets/icons/minimal-globe-small.svg"
import Tooltip from "app/components/Tooltip"
import { PractitionerType } from "app/types"

import { useLabOrderingAccess } from "../LabOrderingAccessDataProvider"
import PhysicianServicesInfoTooltip from "../components/PhysicianServicesInfoTooltip"
import {
  getPracSelectOptionPossessiveName,
  practitionerTypeName,
} from "../helpers"
import { LabCompanyOrderingAccess, PRAC_OPTION_GROUPS } from "../types"

const columnHelper = createColumnHelper<LabCompanyOrderingAccess>()

// Lab Company Name
export const LabCompanyNameColumn: ColumnDef<LabCompanyOrderingAccess, any> =
  columnHelper.display({
    id: "lab_company_name",
    header: "LAB COMPANY",
    cell: ({ row }) => (
      <span className="font-semibold text-base15">{row.original.name}</span>
    ),
    meta: { cellStyle: { paddingLeft: "19px" } },
  })

// Lab Company Logo
export const LabCompanyLogoColumn: ColumnDef<LabCompanyOrderingAccess, any> =
  columnHelper.display({
    id: "lab_company_logo",
    cell: ({ row: { original } }) =>
      original.logo && (
        <img
          className="h-[30px] w-auto object-contain max-w-[200px] align-middle"
          src={original.logo}
          alt={original.name}
        />
      ),
  })

// Signing Practitioner's Credential
const CredentialCell = ({
  row: { original: item },
}: CellContext<LabCompanyOrderingAccess, any>) => {
  const status = item.signing_practitioner_ordering_rights_status
  const { selectedPractitioner } = useLabOrderingAccess()

  const isKnownType =
    !!selectedPractitioner?.primary_practitioner_type?.known_type

  const { npiRequired, hasDeniedAccount } = useMemo(() => {
    const errorIssueKeys = status.error_ordering_issues.map((s) => s.key)
    return {
      npiRequired: errorIssueKeys.includes("LAB_COMPANY_NPI_REQUIRED"),
      hasDeniedAccount: errorIssueKeys.includes("LAB_COMPANY_ACCOUNT_DENIED"),
    }
  }, [status])

  return (
    <div className="flex items-center">
      <div className="flex-1" />
      {status.allowed ? (
        <AllowedIcon />
      ) : isKnownType || hasDeniedAccount ? (
        <NotAllowedIcon />
      ) : (
        <UnknownPractitionerTypePill
          labCompanyName={item.name}
          practitionerType={selectedPractitioner?.primary_practitioner_type}
        />
      )}
      <div className="flex-1 text-left">
        {npiRequired && isKnownType && !hasDeniedAccount && <NPIMissingPill />}
      </div>
    </div>
  )
}

const CredentialHeader = (
  _info: HeaderContext<LabCompanyOrderingAccess, any>
) => {
  const { selectedPractitioner } = useLabOrderingAccess()
  const possessiveName = getPracSelectOptionPossessiveName(selectedPractitioner)
  return `${possessiveName} CREDENTIAL`.toUpperCase()
}

export const CredentialColumn: ColumnDef<LabCompanyOrderingAccess, any> =
  columnHelper.display({
    id: "credential_access",
    header: CredentialHeader,
    cell: CredentialCell,
    meta: { cellStyle: { textAlign: "center" } },
  })

// Physician Services Column
const PhysicianServicesCell = ({
  row: { original: item },
}: CellContext<LabCompanyOrderingAccess, any>) => {
  const { selectedPractitioner } = useLabOrderingAccess()

  if (item.physician_authorization_ordering_rights_status.allowed) {
    return (
      <AllowedIcon
        muted={!selectedPractitioner?.vendor_physician_authorization_enabled}
      />
    )
  }
  return (
    <NotAllowedIcon
      muted={!selectedPractitioner?.vendor_physician_authorization_enabled}
    />
  )
}

const PhysicianServicesHeader = (
  _info: HeaderContext<LabCompanyOrderingAccess, any>
) => {
  const { selectedPractitioner } = useLabOrderingAccess()

  const showTurnOnButton = useMemo(() => {
    return (
      selectedPractitioner?.group === PRAC_OPTION_GROUPS.CURRENT_USER &&
      !selectedPractitioner.vendor_physician_authorization_enabled
    )
  }, [selectedPractitioner])

  return (
    <div className="flex items-center justify-center gap-[10px]">
      <span>RUPA SIGNING PHYSICIAN</span>
      <PhysicianServicesInfoTooltip
        showTurnOnButton={showTurnOnButton}
        physicianAuthorizationEnabled={
          selectedPractitioner?.vendor_physician_authorization_enabled
        }
        returnToDescriptor="Ordering Access"
      />
    </div>
  )
}

export const PhysicianServicesColumn: ColumnDef<LabCompanyOrderingAccess, any> =
  columnHelper.display({
    id: "physician_services_access",
    header: PhysicianServicesHeader,
    cell: PhysicianServicesCell,
    meta: { cellStyle: { textAlign: "center" } },
  })

// Helper Components
interface IconProps {
  muted?: boolean
}

const AllowedIcon = ({ muted }: IconProps) => (
  <CircleCheckIcon
    className={cn("fill-emerald-500", { "opacity-40": muted })}
  />
)
const NotAllowedIcon = ({ muted }: IconProps) => (
  <CircleXIcon className={cn("fill-red-500", { "opacity-40": muted })} />
)

const NPIMissingPill = () => (
  <div className="inline-block ml-[10px] rounded-[46px] bg-slate-100 px-2 py-[3px]">
    <span className="flex gap-[6px] items-center text-slate-500 text-[13px] font-semibold whitespace-nowrap">
      <NPIMissingIcon fill="currentColor" className="shrink-0" /> NPI Required
    </span>
  </div>
)

interface UnknownPractitionerTypePillProps {
  labCompanyName: string
  practitionerType: PractitionerType | undefined
}

const UnknownPractitionerTypePill: React.FC<
  UnknownPractitionerTypePillProps
> = ({ labCompanyName, practitionerType }) => {
  return (
    <div className="inline-block rounded-[46px] bg-amber-50 px-2 py-[3px]">
      <span className="flex gap-[6px] items-center text-amber-700 text-[13px] font-semibold whitespace-nowrap">
        <span>Unknown</span>
        <Tooltip
          arrow
          interactive
          title={
            <span>
              {`${labCompanyName} typically does not allow practitioners certified as "${practitionerTypeName(
                practitionerType
              )}" to order. Please `}
              <span
                className="font-bold text-primary cursor-pointer"
                onClick={() => window.Intercom("showNewMessage")}
              >
                message our team
              </span>
              {` if you think this is not accurate for you.`}
            </span>
          }
        >
          <CircleQuestionIcon
            className="fill-primary shrink-0"
            height={17}
            width={15}
          />
        </Tooltip>
      </span>
    </div>
  )
}
