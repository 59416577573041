import { OnboardingTaskKey } from "app/main/onboarding/types"
import { OptionType } from "app/types"

// used for event tracking
export type CredentialUploadLocation =
  | OnboardingTaskKey
  | "dashboard_sign_now_button"
  | "labshop_sign_now_button"
  | "order_confirmation_modal"
  | "order_sidebar"
  | "banner"
  | "personal_preferences"
  | "signature_page"
  | "onboarding"

// maps to PractitionerLicenseType in backend
export enum LicenseType {
  license = "US Medical License",
  certificate = "Certificate",
}

export const licenseTitleName = (licenseType: LicenseType | undefined) => {
  switch (licenseType) {
    case LicenseType.license:
      return "license"
    case LicenseType.certificate:
      return "certification"
    default:
      return "credential"
  }
}

export const LICENSE_TYPES: OptionType<LicenseType>[] = [
  {
    value: LicenseType.license,
    label: "Medical License",
    description: "State issued professional license.",
  },
  {
    value: LicenseType.certificate,
    label: "Certificate",
    description:
      "Certification earned through a professional or educational organization.",
  },
]
