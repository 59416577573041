import * as React from "react"

import { makeStyles, Theme } from "@material-ui/core"

import CircularCheckFilled from "app/assets/images/circular-check-filled-success.svg"

const styles = (theme: Theme) => ({
  agreeToTerms: {
    marginTop: "25px",
    marginBottom: "5px",
    paddingLeft: "16px",
  },
  continueButton: {
    margin: "15px 0px",
    width: 500,
    [theme.breakpoints.down("sm")]: {
      width: "96%",
    },
  },
  greenCheckbox: {
    marginRight: 10,
    alignSelf: "start",
  },
  thingsToKnowItems: {
    margin: "0px 10px 15px",
    display: "flex",
    "text-align": "left",
  },
  stateRestrictionsText: {
    marginTop: 5,
    marginBottom: 30,
  },
})

const useStyles = makeStyles(styles)

interface ItemProps {
  text: React.ReactNode
  showCheckbox?: boolean
  containerStyles?: React.CSSProperties
}

/**
 * Things to know list item in modal
 * @param text - Text to put in item
 */
const ClipboardItem = ({
  text,
  showCheckbox = true,
  containerStyles,
}: ItemProps) => {
  const classes = useStyles(styles)
  return (
    <div className={classes.thingsToKnowItems} style={containerStyles}>
      {showCheckbox && (
        <img
          src={CircularCheckFilled}
          className={classes.greenCheckbox}
          alt=""
        />
      )}
      {text}
    </div>
  )
}

export default ClipboardItem
