import React, { useEffect } from "react"
import { useDispatch } from "react-redux"

import PageToolbar, { PageToolbarProps } from "app/components/PageToolbar"
import useAppSelector from "app/hooks/useAppSelector"
import * as Actions from "app/store/actions"
import { selectPractitioner } from "app/store/selectors/practitioner.selectors"

import LabOrderingAccessBanner from "./LabOrderingAccessBanner"
import LabOrderingAccessProvider from "./LabOrderingAccessDataProvider"
import LabOrderingAccessReferenceGuideBanner from "./LabOrderingAccessReferenceGuideBanner"
import LabOrderingAccessSigningPractitionerSelect from "./LabOrderingAccessSigningPractitionerSelect"
import LabOrderingAccessTable from "./table/LabOrderingAccessTable"

interface Props {
  toolbarProps?: Omit<PageToolbarProps, "children">
  toolbarChildren?: React.ReactNode

  // children will have access to the LabOrderingAccessContext, so they can
  // see which practitioner is selected
  children?: React.ReactNode
}

const LabOrderingAccessPageV2: React.FC<Props> = ({
  toolbarProps,
  toolbarChildren,
  children,
}) => {
  const practitioner = useAppSelector(selectPractitioner)

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(Actions.getTeamMembers())
  }, [dispatch])

  if (!practitioner) {
    return null
  }

  return (
    <LabOrderingAccessProvider practitioner={practitioner}>
      <PageToolbar title="Lab Ordering Access" {...toolbarProps}>
        <LabOrderingAccessSigningPractitionerSelect />
        {toolbarChildren}
      </PageToolbar>
      <div className="overflow-auto bg-gray-50 p-3 sm:p-6 flex">
        <div className="table-row flex-grow">
          <div className="flex flex-col gap-6 sm:gap-9">
            <LabOrderingAccessBanner practitioner={practitioner} />
            <LabOrderingAccessTable />
            <hr className="h-[1px] bg-slate-200 w-full" />
            <LabOrderingAccessReferenceGuideBanner />
          </div>
        </div>
      </div>
      {children}
    </LabOrderingAccessProvider>
  )
}

export default LabOrderingAccessPageV2
