import useFeatureFlag, { FeatureFlag } from "app/hooks/use-feature-flag"

import OrderingAccess from "./LabOrderingAccess"
import LabOrderingAccessPageV2 from "./lab-ordering-access-v2/LabOrderingAccessPageV2"

const OrderingAccessPage: React.FC = () => {
  const [orderingAccessV2, featureFlagLoading] = useFeatureFlag(
    FeatureFlag.LabCompanyOrderingAccessV2
  )

  if (featureFlagLoading) {
    return null
  }

  if (orderingAccessV2) {
    return <LabOrderingAccessPageV2 />
  }

  return <OrderingAccess />
}

export default OrderingAccessPage
