import * as React from "react"
import { useDispatch } from "react-redux"

import { generatePath, useHistory } from "react-router-dom"
import { useMount } from "react-use"

import { makeStyles } from "@material-ui/core"

import { UserPaths } from "app/Routes"
import ScheduleDarkIcon from "app/assets//icons/schedule-dark.svg"
import EditIcon from "app/assets/images/edit.svg"
import Tooltip from "app/components/Tooltip"
import BodyText from "app/components/design-system/BodyText"
import DesignSystemButton from "app/components/design-system/Button"
import useAppSelector from "app/hooks/useAppSelector"
import CHECKOUT_STATE from "app/main/checkout/CheckoutState"
import { navy, shadows } from "app/theme"
import { Patient } from "app/types"
import { formatDate } from "app/utils"

import * as Actions from "./store/actions"

const useStyles = makeStyles((theme) => ({
  order: {
    background: "#fff",
    borderRadius: 12,
    boxShadow: shadows.default,
    marginBottom: 24,

    [theme.breakpoints.up("md")]: {
      width: "auto",
    },
  },
  orderContainer: {
    padding: "18px 24px",
  },
  orderContainerInner: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    flexDirection: "column",

    [theme.breakpoints.up("xs")]: {
      flexDirection: "row",
    },
  },
  testNamesContainer: {
    display: "flex",
    marginBottom: 10,
    flexDirection: "column",

    [theme.breakpoints.up("xs")]: {
      marginBottom: "auto",
    },

    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
    },
  },
  testDateAndActionContainer: {
    display: "flex",
    flexDirection: "column",

    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
    },
  },
  scheduledDate: {
    display: "flex",
    marginRight: 24,
  },
  title: {
    marginBottom: 16,
  },
  seeAllTests: {
    borderBottom: `1px dotted ${navy}`,
    marginLeft: 0,
    maxWidth: 90,

    [theme.breakpoints.up("sm")]: {
      marginLeft: 5,
      maxWidth: "auto",
    },
  },
  editOrRescheduleButton: {
    maxWidth: 160,
    marginTop: 10,

    [theme.breakpoints.up("sm")]: {
      maxWidth: "auto",
      marginTop: "auto",
    },
  },
  icon: {
    marginRight: 5,
  },
  testNameItem: {
    paddingLeft: 20,
  },
}))

export default function ScheduledOrders({ patient }: { patient: Patient }) {
  const history = useHistory()
  const dispatch = useDispatch()
  const styles = useStyles()

  const scheduledOrdersPage = useAppSelector(
    ({ patient }) => patient.scheduledOrders
  )

  // Load the orders on mount
  useMount(() => {
    dispatch(
      Actions.getScheduledOrders({
        patientId: patient.id,
      })
    )
  })

  if (!scheduledOrdersPage) {
    return null
  }

  const { results: orders } = scheduledOrdersPage

  if (orders.length === 0) {
    return null
  }

  return (
    <div>
      <BodyText className={styles.title} weight="semibold" size="md">
        Scheduled Orders
      </BodyText>
      {orders.map((order) => {
        let testNames = order.ordered_tests
          .map((orderedTest) => orderedTest.lab_test.name)
          .join(", ")

        let seeAllTests: React.ReactNode = null

        if (testNames.length > 35) {
          testNames = testNames.substring(0, 32) + "..."
          seeAllTests = (
            <Tooltip
              arrow
              placement="bottom"
              title={
                <ul className={styles.testNameItem}>
                  {order.ordered_tests.map((orderedTest) => (
                    <li>
                      <BodyText size="sm">{orderedTest.lab_test.name}</BodyText>
                    </li>
                  ))}
                </ul>
              }
            >
              <div>
                <BodyText className={styles.seeAllTests}>
                  See All Tests
                </BodyText>
              </div>
            </Tooltip>
          )
        }

        return (
          <div key={order.id} className={styles.order}>
            <div className={styles.orderContainer}>
              <div className={styles.orderContainerInner}>
                <div className={styles.testNamesContainer}>
                  <BodyText weight="semibold">{testNames}</BodyText>
                  {seeAllTests}
                </div>
                <div className={styles.testDateAndActionContainer}>
                  <div className={styles.scheduledDate}>
                    <img
                      className={styles.icon}
                      src={ScheduleDarkIcon}
                      alt="schedule-orders"
                    />
                    <BodyText size="sm">{`Scheduled to reorder ${formatDate(
                      order.date_scheduled
                    )}`}</BodyText>
                  </div>
                  <DesignSystemButton
                    color="text"
                    startIcon={EditIcon}
                    onClick={() =>
                      history.push(
                        generatePath(UserPaths.ORDER_CHECKOUT, {
                          orderId: order.id,
                          checkoutState: CHECKOUT_STATE.EDIT,
                        })
                      )
                    }
                    className={styles.editOrRescheduleButton}
                  >
                    Edit or Reschedule
                  </DesignSystemButton>
                </div>
              </div>
            </div>
          </div>
        )
      })}
    </div>
  )
}
