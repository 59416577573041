import axios from "axios"

import { getApiBaseUrl, handleApiError } from "app/utils"

export const GET_USER = "[APP] GET USER"
export const PROFILE_PASSWORD_UPDATE_COMPLETE =
  "[PROFILE] PASSWORD UPDATE COMPLETE"

export function passwordUpdateComplete() {
  return async (dispatch, getState) =>
    dispatch({
      type: PROFILE_PASSWORD_UPDATE_COMPLETE,
      payload: {},
    })
}

export function getUserData(userId) {
  return async (dispatch, getState) => {
    try {
      const response = await axios.get(getApiBaseUrl() + `/api/user/`)
      return dispatch({
        type: GET_USER,
        payload: response.data,
      })
    } catch (error) {
      return dispatch(handleApiError(error))
    }
  }
}
