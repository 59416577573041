import { CircularProgress } from "@material-ui/core"

import makeAppStyles from "app/utils/makeAppStyles"

const useStyles = makeAppStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginLeft: "auto",
    marginRight: "auto",
    padding: theme.spacing(4.0),
  },
}))

export default function EditorLoadingPlaceholder() {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <CircularProgress size={32} />
    </div>
  )
}
