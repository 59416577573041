import { Typography } from "@material-ui/core"

import { ReactComponent as VideoIcon } from "app/assets/icons/video.svg"
import { ReactComponent as FoodPlanIntroImage } from "app/assets/images/food-plan-intro.svg"
import BetaChip from "app/components/design-system/BetaChip"
import Button from "app/components/design-system/Button"
import FoodPlanCard from "app/food-plans/components/FoodPlanCard"
import useVideoModal from "app/hooks/use-video-modal"
import { colors, textPrimaryColor } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"

const useStyles = makeAppStyles((theme) => ({
  rootOverrides: {
    minHeight: 700,
    justifyContent: "center",
    alignItems: "center",
  },
  content: {
    maxWidth: 400,
    display: "flex",
    flexFlow: "column nowrap",
    justifyContent: "center",
    alignItems: "center",
    gap: theme.spacing(0.5),
  },
  title: {
    fontWeight: 600,
    fontSize: 17,
    color: textPrimaryColor,
    textAlign: "center",
  },
  subtitle: {
    fontWeight: 400,
    fontSize: 15,
    color: textPrimaryColor,
    textAlign: "center",
  },
  tutorialButton: {
    marginTop: theme.spacing(1.0),
  },
}))

export default function FoodPlanIntroCard() {
  const classes = useStyles()
  const tutorialModal = useVideoModal()

  return (
    <FoodPlanCard className={classes.rootOverrides}>
      <div className={classes.content}>
        <FoodPlanIntroImage viewBox="0 0 66 66" />

        <Typography className={classes.title} variant="body1">
          Generate a Food Plan Draft with Rupa AI&nbsp;
          <BetaChip
            backgroundColor={colors.teal[100]}
            color={colors.teal[700]}
          />
        </Typography>
        <Typography className={classes.subtitle} variant="body1">
          Save time and streamline meal planning with Rupa AI, our HIPAA
          compliant assistant.
        </Typography>

        <Button
          className={classes.tutorialButton}
          color="text"
          onClick={() =>
            tutorialModal.show({
              src: "https://player.vimeo.com/video/819311605?h:643078ab17&color=4AA8BC&title=0&byline=0&portrait=0",
              title: "An Overview of Food Plans",
            })
          }
          startIcon={<VideoIcon viewBox="0 0 19 15" />}
          type="button"
        >
          Watch Tutorial
        </Button>
      </div>
    </FoodPlanCard>
  )
}
