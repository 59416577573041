import { styled } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

import BodyText from "app/components/design-system/BodyText"
import { PatientInstructionStateWaiver } from "app/types"

const useStyles = makeStyles({
  kitItem: {
    marginLeft: 10,
  },
  bodyText: {
    marginBottom: 6,
  },
})

interface Props {
  patientInstruction: PatientInstructionStateWaiver
}

const Container = styled("div")({
  width: "100%",
})

const StateWaiverInstructions = ({ patientInstruction }: Props) => {
  const classes = useStyles()

  return (
    <Container>
      <BodyText className={classes.bodyText}>
        The following kits have an included waiver inside that needs to be
        signed:
      </BodyText>
      {patientInstruction.kit_names.map((kit_name) => {
        return (
          <BodyText weight={"semibold"} className={classes.kitItem}>
            - {kit_name}
          </BodyText>
        )
      })}
    </Container>
  )
}
export default StateWaiverInstructions
