import { useState } from "react"

import { FormProvider, UseFormReturn } from "react-hook-form"
import { Link } from "react-router-dom"
import { Descendant } from "slate"
import { z } from "zod"

import { Box, makeStyles, Link as MaterialLink } from "@material-ui/core"

import { UserPaths } from "app/Routes"
import { getInitialValue } from "app/components/RichTextEditor"
import Tooltip from "app/components/Tooltip"
import BodyText from "app/components/design-system/BodyText"
import { FormFieldLabel } from "app/components/forms/fields"
import usePhysicianServicesOptInModal from "app/components/modals/PhysicianServicesOptInModal/use-physician-services-opt-in-modal"
import PhotoAutoUpload from "app/components/upload/PhotoAutoUpload"
import { VENDOR_PHYSICIAN_AUTHORIZATION_LABEL } from "app/constants"
import useFeatureFlag, { FeatureFlag } from "app/hooks/use-feature-flag"
import { StorefrontType } from "app/hooks/use-resource"
import useAppSelector from "app/hooks/useAppSelector"
import OrderingPractitionerSelect from "app/main/checkout/OrderingPractitionerSelect"
import { ControlledTextField } from "app/main/patient-checkout/fields"
import ControlledSwitchField from "app/main/patient-checkout/fields/ControlledSwitchField"
import { StyledFormControlLabel } from "app/main/settings/components/StyledFormControlLabel"
import { colors, Theme } from "app/theme"
import { DefaultPractitionerOption } from "app/types"

import { INTERCOM_REQUEST_PRAC_LICENSE_LABSHOP_MESSAGE } from "../constants"
import { CreateEcommerceFormSchema } from "./CreateEcommerceFormSchema"
import StorefrontRichTextEditor from "./StorefrontRichTextEditor"

const styles = (theme: Theme) => ({
  container: {
    display: "flex",
    "flex-direction": "row",
    [theme.breakpoints.down("md")]: {
      "flex-direction": "column",
    },
    width: "100%",
  },
  fieldSet: {
    width: "100%",
    marginRight: theme.spacing(2),
    display: "flex",
    "flex-direction": "column",
    marginBottom: theme.spacing(2),
  },
  label: {
    marginTop: theme.spacing(2),
  },
  customFeeDeleteBtnContainer: {
    display: "flex",
    "flex-direction": "column",
    alignItems: "flex-end",
  },
  customFeeDeleteBtn: {
    marginRight: 16,
    [theme.breakpoints.down("md")]: {
      marginRight: 0,
    },
  },
  customFeeDeleteIcon: {
    height: "fit-content",
    position: "relative" as any,
    top: "66px",
    [theme.breakpoints.down("md")]: {
      alignSelf: "end",
      top: "0px",
    },
  },
  errorText: {
    color: colors.red[600],
    fontSize: 12,
    display: "block",
    marginTop: 4,
  },
  applyButton: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  link: {
    fontWeight: 600,
    cursor: "pointer",
  },
  linkText: {
    color: colors.blue[500],
  },
  settingsSwitchesContainer: {
    marginTop: 40,
  },
})

const useStyles = makeStyles(styles)

const EcommerceFormSchema = CreateEcommerceFormSchema()
interface Props {
  storefront: StorefrontType
  orderingPractitionerOptions: DefaultPractitionerOption[]
  methods: UseFormReturn<z.infer<typeof EcommerceFormSchema>>
  onRemoveLogo: () => void
  onAddLogo: () => void
  selectedOrderingPhysician: DefaultPractitionerOption | undefined
  onSelectedOrderingPhysician: (
    selection: DefaultPractitionerOption | undefined
  ) => void
  usePractitionerLicense: boolean
  onChangeDraftDescriptionRichtext: (value: Descendant[]) => void
}

const EcommerceForm = ({
  storefront,
  orderingPractitionerOptions,
  methods,
  onRemoveLogo,
  onAddLogo,
  onChangeDraftDescriptionRichtext,
  selectedOrderingPhysician,
  onSelectedOrderingPhysician,
  usePractitionerLicense,
}: Props) => {
  const classes = useStyles(styles)

  const [practitionerLicenseFlagEnabled] = useFeatureFlag(
    FeatureFlag.LabshopsUsePractitionerLicense
  )
  const [internationalClinicsEnabled] = useFeatureFlag(
    FeatureFlag.InternationalClinics
  )

  const practitioner = useAppSelector(({ practitioner }) => practitioner)
  const [finishedOptIn, setFinishedOptIn] = useState(false)
  const [logoUrl, setLogoUrl] = useState(storefront.attributes.draft_logo)

  const emrIntegrations = useAppSelector(
    ({ emrIntegrations }) => emrIntegrations.results
  )

  const canShowPractitionerLicenseApplicationCopy =
    !internationalClinicsEnabled || !practitioner.clinic.is_international_clinic

  const existingEmrIntegration = emrIntegrations.some((x) => x.active)

  const isOptInAvailable =
    !practitioner.is_clinic_staff &&
    !finishedOptIn &&
    !practitioner.vendor_physician_authorization_enabled

  const userIsSelectedOrderingPractitioner =
    selectedOrderingPhysician?.practitioner.id === practitioner?.id

  const physicianServicesOptInModal = usePhysicianServicesOptInModal()

  const onEnablePhysicianServicesClick = () => {
    physicianServicesOptInModal.show({
      defaultPhysAuthOn: false,
      returnToDescriptor: "Labshop",
      onClose: () => {
        physicianServicesOptInModal.remove()
        setFinishedOptIn(!!finishedOptIn)
      },
    })
  }

  const physicianServicesCTA =
    userIsSelectedOrderingPractitioner && isOptInAvailable ? (
      <span
        className={"font-semibold text-primary cursor-pointer"}
        onClick={onEnablePhysicianServicesClick}
      >
        Add {VENDOR_PHYSICIAN_AUTHORIZATION_LABEL} to your account.
      </span>
    ) : (
      <span>
        They can turn this on in{" "}
        <Link to={UserPaths.ACCOUNT} className={classes.linkText}>
          Settings in their Rupa account
        </Link>
        .
      </span>
    )

  const physicianServicesOrderingPracCopy = (
    <>
      <BodyText size="sm" style={{ marginBottom: 4 }}>
        All orders that come through your LabShop link will be signed by an
        authorizing physician. Choose the practitioner on your team who will
        appear in the client's checkout as the person who created the Labshop.{" "}
        {isOptInAvailable && userIsSelectedOrderingPractitioner && (
          <>
            <MaterialLink
              className={classes.link}
              onClick={onEnablePhysicianServicesClick}
            >
              Add {VENDOR_PHYSICIAN_AUTHORIZATION_LABEL} here
            </MaterialLink>
            {
              ", or select another practitioner from your clinic who already has it enabled."
            }
          </>
        )}
      </BodyText>
      <Box>
        <BodyText size="sm" style={{ marginTop: 10, marginBottom: 7 }}>
          Questions about {VENDOR_PHYSICIAN_AUTHORIZATION_LABEL} for LabShops?{" "}
          <MaterialLink
            href="http://help.rupahealth.com/en/articles/7120859-what-does-physician-services-mean-for-my-labshop"
            target="_blank"
            style={{ fontWeight: 600 }}
          >
            Learn more here
          </MaterialLink>
          .
        </BodyText>
      </Box>
    </>
  )

  const practitionerLicenseOrderingPracCopy = (
    <BodyText size="sm" style={{ marginBottom: 4 }}>
      All LabShop orders will be placed under the designated ordering
      practitioner selected below. After a client checks out, the ordering
      practitioner will receive an email to approve or deny the individual order
      on their license. If you have questions or need to change the ordering
      practitioner,{" "}
      <MaterialLink
        onClick={() => window.Intercom("showNewMessage")}
        style={{ cursor: "pointer", fontWeight: 600 }}
      >
        message us here
      </MaterialLink>
      .
    </BodyText>
  )

  return (
    <FormProvider {...methods}>
      <form>
        <Box className={classes.label}>
          <FormFieldLabel label="Logo" required={true} />
          <BodyText size="sm" style={{ marginBottom: 4 }}>
            Upload your practice or program logo for your storefront.
          </BodyText>
        </Box>

        <PhotoAutoUpload
          uploadUrl={`/api/normalized/storefronts/${storefront.id}/upload/`}
          logoUrl={logoUrl}
          onLogoUploadSuccess={(response) => {
            methods.setValue("draft_logo", response.data.attributes.draft_logo)
            setLogoUrl(response.data.attributes.draft_logo)
            onAddLogo()
          }}
          onRemoveLogo={() => {
            setLogoUrl(null)
            onRemoveLogo()
          }}
        />

        <Box className={classes.label}>
          <FormFieldLabel label="Store Title" required={true} />
        </Box>

        <Box className={classes.container}>
          <ControlledTextField
            className={classes.fieldSet}
            name="draft_name"
            placeholder="Your name or program name"
            defaultValue=""
            required={true}
          />
        </Box>

        <Box className={classes.label}>
          <FormFieldLabel
            // Clarify for Labshops using rich text that this is only for the og sharing tags
            label={
              storefront.attributes.use_richtext
                ? "Social Media Description"
                : "Description"
            }
            required={true}
          />
        </Box>
        <Box className={classes.container}>
          <ControlledTextField
            className={classes.fieldSet}
            name="draft_description"
            placeholder={
              storefront.attributes.use_richtext
                ? "Use this space to include a description that will be displayed on social media platforms."
                : "Use this space to give your clients information about the tests"
            }
            defaultValue=""
            required={true}
            multiline
            rows={3}
            inputProps={{
              style: {
                padding: 0,
                lineHeight: "130%",
              },
            }}
          />
        </Box>

        {storefront.attributes.use_richtext && (
          <>
            <Box className={classes.label}>
              <FormFieldLabel label="Description" required={true} />
            </Box>
            <StorefrontRichTextEditor
              onChange={onChangeDraftDescriptionRichtext}
              value={
                storefront.attributes.draft_description_richtext
                  ? storefront.attributes.draft_description_richtext
                  : getInitialValue("")
              }
              placeholder="Use this space to give your clients information about the tests"
            />
          </>
        )}

        <Box className={classes.label}>
          <FormFieldLabel
            label="Embed Video (Optional but encouraged)"
            required={false}
          />
        </Box>

        <Box className={classes.container}>
          <ControlledTextField
            className={classes.fieldSet}
            name="draft_video_link"
            placeholder="Youtube or Vimeo URL"
            defaultValue=""
            required={false}
          />
        </Box>

        <Box className={classes.label}>
          <FormFieldLabel label="Labshop Owner" required={true} />

          {usePractitionerLicense
            ? practitionerLicenseOrderingPracCopy
            : physicianServicesOrderingPracCopy}
        </Box>

        <Box className={classes.container}>
          <OrderingPractitionerSelect
            infoText={``}
            label={``}
            onSelect={(value) => onSelectedOrderingPhysician(value)}
            options={orderingPractitionerOptions}
            value={selectedOrderingPhysician}
            disabled={
              practitionerLicenseFlagEnabled &&
              storefront.attributes.use_practitioner_license
            }
          />
        </Box>
        {!selectedOrderingPhysician?.practitioner
          .vendor_physician_authorization_enabled &&
          !usePractitionerLicense && (
            <Box>
              <span className={classes.errorText}>
                Please select a practitioner who has{" "}
                {VENDOR_PHYSICIAN_AUTHORIZATION_LABEL} turned on.{" "}
                {physicianServicesCTA}
              </span>
            </Box>
          )}
        {!usePractitionerLicense && canShowPractitionerLicenseApplicationCopy && (
          <Box>
            <BodyText size="sm" style={{ marginTop: 10 }}>
              To create a LabShop under your own or a clinic team member's
              license,{" "}
              <MaterialLink
                onClick={() =>
                  window.Intercom(
                    "showNewMessage",
                    INTERCOM_REQUEST_PRAC_LICENSE_LABSHOP_MESSAGE
                  )
                }
                style={{ cursor: "pointer", fontWeight: 600 }}
              >
                send us a message
              </MaterialLink>{" "}
              to get started.
            </BodyText>
          </Box>
        )}

        <Box className={classes.settingsSwitchesContainer}>
          <FormFieldLabel label="Preferences" required={false} />
          <StyledFormControlLabel
            control={
              <ControlledSwitchField name="draft_send_notification_emails" />
            }
            label={
              <div>
                <BodyText size="sm" weight="semibold">
                  Email new order and results notifications
                </BodyText>
                <BodyText size="sm">
                  The ordering practitioner will get an email when a new order
                  is paid for, or results come in for your LabShop orders.
                </BodyText>
              </div>
            }
            style={{ marginTop: 19 }}
          />
          <StyledFormControlLabel
            control={
              <Tooltip
                arrow
                interactive
                title={
                  !existingEmrIntegration ? (
                    <BodyText>
                      Looks like you don't have an EHR connected in{" "}
                      <Link to={UserPaths.CLINIC} className={classes.linkText}>
                        Settings.
                      </Link>
                    </BodyText>
                  ) : (
                    ""
                  )
                }
              >
                <span>
                  <ControlledSwitchField
                    name="draft_send_to_ehr"
                    disabled={!existingEmrIntegration}
                  />
                </span>
              </Tooltip>
            }
            label={
              <div>
                <BodyText size="sm" weight="semibold">
                  Send results from this LabShop to my EHR
                </BodyText>
                <BodyText size="sm">
                  Automatically receive results in your EHR.
                </BodyText>
              </div>
            }
          />
        </Box>
      </form>
    </FormProvider>
  )
}

export default EcommerceForm
