import * as React from "react"

import { Descendant, Element, Text } from "slate"

const LeafNodeRenderer = ({ node }: { node: Text }) => {
  let children: React.ReactNode = node.text

  if ("bold" in node && node.bold) {
    children = <strong>{children}</strong>
  }

  if ("italic" in node && node.italic) {
    children = <em>{children}</em>
  }

  if ("underline" in node && node.underline) {
    children = <u>{children}</u>
  }

  if ("hyperlink" in node && node.hyperlink) {
    children = (
      <a target="_blank" rel="noreferrer" href={node.hyperlink}>
        {children}
      </a>
    )
  }

  return <span>{children}</span>
}

const ElementRenderer = ({ node }: { node: Element }) => {
  const children = node.children.map((child, index) => (
    <NodeRenderer key={index} node={child} />
  ))

  switch (node.type) {
    case "bulleted-list":
      return <ul>{children}</ul>
    case "list-item":
      return <li>{children}</li>
    case "numbered-list":
      return <ol>{children}</ol>
    case "table":
      return <table>{children}</table>
    case "table-row":
      return <tr>{children}</tr>
    case "table-cell":
      return <td>{children}</td>
    case "table-cell-header":
      return <th>{children}</th>
    case "paragraph":
    default:
      return <p>{children}</p>
  }
}

const NodeRenderer = ({ node }: { node: Descendant }) => {
  if (Text.isText(node)) {
    return <LeafNodeRenderer node={node} />
  }

  return <ElementRenderer node={node} />
}

export default function RichTextRenderer({
  children,
}: {
  children: Descendant[]
}) {
  return (
    <>
      {children.map((node, index) => (
        <NodeRenderer key={index} node={node} />
      ))}
    </>
  )
}
