import Loading from "app/components/Loading"
import FoodPlanLayout from "app/food-plans/components/FoodPlanLayout"
import PatientFoodPlanMain from "app/food-plans/components/PatientFoodPlanContent/PatientFoodPlanMain"
import PatientFoodPlanSidebar from "app/food-plans/components/PatientFoodPlanContent/PatientFoodPlanSidebar"
import { UsePatientFoodPlanResources } from "app/food-plans/hooks/use-patient-food-plan-resources"
import {
  FoodPlanVersion,
  FoodPlanVersionGenerateFeed,
} from "types/food-plan-version"

export interface PatientFoodPlanContentProps {
  feed?: FoodPlanVersionGenerateFeed
  foodPlanVersion?: FoodPlanVersion
  isRestarting?: boolean
  isStartingGenerate?: boolean
  onPatch: UsePatientFoodPlanResources["onPatch"]
  onRestart: UsePatientFoodPlanResources["onRestart"]
  onStartGenerate: UsePatientFoodPlanResources["onStartGenerate"]
  onStartCancel: UsePatientFoodPlanResources["onStartCancel"]
}

export default function PatientFoodPlanContent({
  feed,
  foodPlanVersion,
  isRestarting,
  isStartingGenerate,
  onPatch,
  onRestart,
  onStartGenerate,
  onStartCancel,
}: PatientFoodPlanContentProps) {
  if (!foodPlanVersion) {
    return <Loading ariaLabel="Loading food plan" />
  }

  return (
    <FoodPlanLayout
      key={foodPlanVersion.id}
      main={
        <PatientFoodPlanMain
          key={foodPlanVersion.id}
          feed={feed}
          foodPlanVersion={foodPlanVersion}
          isRestarting={isRestarting}
          isStartingGenerate={isStartingGenerate}
          onPatch={onPatch}
        />
      }
      sidebar={
        <PatientFoodPlanSidebar
          key={foodPlanVersion.id}
          foodPlanVersion={foodPlanVersion}
          isRestarting={isRestarting}
          isStartingGenerate={isStartingGenerate}
          onPatch={onPatch}
          onRestart={onRestart}
          onStartGenerate={onStartGenerate}
          onStartCancel={onStartCancel}
        />
      }
    />
  )
}
