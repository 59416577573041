import * as Actions from "../actions"

const snippetReducer = function (state = {}, action) {
  switch (action.type) {
    case Actions.GET_SNIPPETS: {
      return action.payload
    }
    default: {
      return state
    }
  }
}

export default snippetReducer
