import { PatientOrdersOrder, Practitioner } from "app/types"

import { TabOptions } from "./OrderListTabs"

export const PAGE_SIZE = 25

/**
 * BAD BAD BAD
 *
 * Hacky workaround for Way2Well to help avoid performance issues until we rewrite the dashboard.
 *
 * @param clinic
 * @returns
 */
export function getPageSize(clinicId: any) {
  return clinicId === "cli_waq0N6j" ? 10 : 25
}

export interface TableCellProps {
  order: RowData
  showNotificationIndicator?: boolean
  showNotificationIndicatorPlaceholder?: boolean
}

export interface OrderListProps {
  practitioner: Practitioner
}

export type UserAppliedFilters = {
  applied?: boolean
  practitioner_id?: string
  storefronts?: string
  storefront_ids?: string
  tab_status?: TabOptions
}

export type ColumnData = {
  field: string
  headerStyle?: { width?: string }
  render: (order: RowData) => JSX.Element
  cellStyle?: {
    paddingLeft?: number
    paddingRight?: number
    width?: string
    minWidth?: string
    backgroundColor?: string
    backgroundImage?: string
    "&:hover"?: any
  }
  filterCellStyle?: {
    paddingLeft?: number
    paddingRight?: number
    width?: string
  }
  disableClick?: boolean
  filterComponent?: ({
    columnDef,
    onFilterChanged,
  }: {
    columnDef: any
    onFilterChanged: any
  }) => JSX.Element
  width?: number
  filtering?: boolean
}

type TableData = {
  id: number
  filterValue: string
  showDetailPanel: () => {}
}

export type RowData = PatientOrdersOrder & {
  tableData: TableData
  isEmpty?: boolean
}
