import { isNil } from "lodash"

import { StorefrontLabTestType } from "app/hooks/use-resource"
import { EmptyComparisonValue } from "app/main/comparison/components/ComparisonLabTests/components"
import { pluralizeDays } from "app/main/comparison/utils"

interface Props {
  labTest: StorefrontLabTestType
}

const CollectionDaysColumn = ({ labTest }: Props) => {
  const { collection_days_max, collection_days_min } = labTest.attributes
  const hasMaxCollectionDays = !isNil(collection_days_max)
  const hasMinCollectionDays = !isNil(collection_days_min)
  if (!hasMaxCollectionDays && !hasMinCollectionDays) {
    return <EmptyComparisonValue />
  }
  let collectionDaysText: string = ""
  if (hasMaxCollectionDays && hasMinCollectionDays) {
    if (collection_days_max !== collection_days_min) {
      collectionDaysText = `${collection_days_min} - ${collection_days_max} days`
    } else {
      collectionDaysText = `${collection_days_min} ${pluralizeDays(
        collection_days_min
      )}`
    }
  } else if (hasMaxCollectionDays) {
    collectionDaysText = `${collection_days_max} ${pluralizeDays(
      collection_days_max
    )}`
  } else if (hasMinCollectionDays) {
    collectionDaysText = `${collection_days_min} ${pluralizeDays(
      collection_days_min
    )}`
  }
  return <>{collectionDaysText}</>
}

export default CollectionDaysColumn
