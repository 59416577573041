import { isEmpty, startCase } from "lodash"
import moment from "moment"

import { ReactComponent as ScheduleIcon } from "app/assets/icons/schedule-dark.svg"
import {
  ComparisonRow,
  ComparisonColumnProps,
  EmptyComparisonValue,
} from "app/main/comparison/components/ComparisonLabTests/components"
import useAnyComparedLabTestHasProperty from "app/main/comparison/hooks/use-any-compared-lab-test-has-property"

const PreparationStartsColumn = ({ labTest }: ComparisonColumnProps) => {
  if (!labTest || isEmpty(labTest.preparation_starts)) {
    return <EmptyComparisonValue />
  }
  const duration = moment.duration(labTest.preparation_starts)
  if (duration.asMilliseconds() === 0) {
    return <>{"No Test Prep is Required"}</>
  }
  const formattedDuration = startCase(
    duration.humanize({ h: 24, d: 7, w: 4, m: 12 })
  )
  return <>{`Up to ${formattedDuration} Before Collection`}</>
}

export default function ComparisonPreparationStartsRow() {
  const hasPreparationStarts =
    useAnyComparedLabTestHasProperty("preparation_starts")
  if (!hasPreparationStarts) {
    return null
  }
  return (
    <ComparisonRow
      Column={PreparationStartsColumn}
      headerIcon={<ScheduleIcon viewBox="0 0 15 14" />}
      headerLabel="Test Preparation Starts"
    />
  )
}
