import { useRef, useState } from "react"
import { useDispatch } from "react-redux"

import axios from "axios"
import { useHistory } from "react-router-dom"
import { useDebounce } from "react-use"

import { API } from "app/api"
import { Query } from "app/components/LabTests/types"
import { trackLabTestSearch } from "app/services/segment"
import { LabTestListItem, PageNumberPaginatedResponse } from "app/types"
import { handleApiError } from "app/utils"

async function fetchLabTestList(
  query: Query,
  history: any,
  abortController?: AbortController
): Promise<PageNumberPaginatedResponse<LabTestListItem>> {
  const searchParams = new URLSearchParams(
    query.map((param) => [param.queryName, param.value])
  )

  if (window.location.search.includes("lab-test-id")) {
    const params = new URLSearchParams(window.location.search)
    const labTestID = params.get("lab-test-id")

    if (labTestID) {
      searchParams.append("lab-test-id", labTestID)
    }
  }

  const querystring = searchParams.toString()
  const response = await API.LabTest.getList(querystring, abortController)

  // Id was converted from integer to hashid
  const labTestHashId = response.data["lab_test_id"]
  if (labTestHashId) {
    searchParams.set("lab-test-id", labTestHashId)
    history.replace({
      search: "?".concat(searchParams.toString()),
    })
  }

  // We want to know results from search query as well
  const flatLabTestResutsIds = response.data.results.map(
    (lab_test) => lab_test.id
  )
  searchParams.set("lab_test_results", flatLabTestResutsIds)

  trackLabTestSearch(searchParams)

  return response.data
}

/**
 * Hook used to fetch a page of lab test results for the given query.
 *
 * @param query the lab test query data
 * @returns the lab test page results
 */
export default function useLabTestsPage(
  query: Query | null,
  shouldSetPending: boolean = true
) {
  const dispatch = useDispatch()
  const history = useHistory()
  const [data, setData] = useState<
    PageNumberPaginatedResponse<LabTestListItem>
  >({
    count: 0,
    next: null,
    previous: null,
    results: null,
    page: 1,
  })
  const [isPending, setIsPending] = useState(true)
  // This reference is used to manage aborting concurrent ongoing searches.
  const abortControllerRef = useRef<AbortController>()

  useDebounce(
    () => {
      // If there is a current abort controller, that means there is an outstanding search that has not finished yet.
      // We want to abort that search so only the most recent search is active.
      if (abortControllerRef.current) {
        abortControllerRef.current.abort()
      }
      abortControllerRef.current = new AbortController()

      async function load() {
        if (!query) {
          return
        }

        if (shouldSetPending) {
          setIsPending(true)
        }
        try {
          setData(
            await fetchLabTestList(query, history, abortControllerRef.current)
          )
        } catch (error) {
          // If the error is due to the request being aborted, then return before we `setIsPending` to false
          // because the other request is still loading.
          if (axios.isCancel(error)) {
            return
          }
          dispatch(handleApiError(error))
        }
        setIsPending(false)
      }

      load()

      // If we change pages and request is still ongoing, this will abort the request.
      return () => {
        abortControllerRef.current?.abort()
      }
    },
    100,
    [query]
  )

  return {
    ...data,
    isPending,
  }
}
