import ComparisonSearchLabTests from "app/main/comparison/components/ComparisonSearchLabTests"

import ComparisonSearchModalContent from "./ComparisonSearchModalContent"
import ComparisonSearchModalDialog from "./ComparisonSearchModalDialog"
import ComparisonSearchModalTitle from "./ComparisonSearchModalTitle"

export default function ComparisonSearchModal() {
  return (
    <ComparisonSearchModalDialog>
      <ComparisonSearchModalTitle />
      <ComparisonSearchModalContent>
        <ComparisonSearchLabTests />
      </ComparisonSearchModalContent>
    </ComparisonSearchModalDialog>
  )
}
