import { useMemo } from "react"

import { KeyedMutator } from "swr"

import NiceModal, { useModal } from "@ebay/nice-modal-react"
import { faArrowRight } from "@fortawesome/free-solid-svg-icons"
import { faXmark } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Dialog } from "@material-ui/core"
import { Button } from "@rupahealth/design"

import BannerBackground from "app/assets/images/explore-ordering-access-banner-background.svg"
import { useLabOrderingAccess } from "app/components/OrderingAccess/lab-ordering-access-v2/LabOrderingAccessDataProvider"
import LabOrderingAccessPageV2 from "app/components/OrderingAccess/lab-ordering-access-v2/LabOrderingAccessPageV2"
import RupaLoadingButton from "app/components/RupaLoadingButton"
import useCredentialTracking, {
  CredentialEvents,
} from "app/components/modals/SignatureAndLicenseModal/SignatureAndLicenseModalV2/use-credential-tracking"
import useFeatureFlag from "app/hooks/use-feature-flag"
import useAppSelector from "app/hooks/useAppSelector"
import { FeatureFlag } from "app/providers/FeatureFlagProvider"
import { selectPractitioner } from "app/store/selectors/practitioner.selectors"
import { Practitioner } from "app/types"
import { openInNewTab } from "app/utils/link-utils"

import { getSigningPractitionerLocation } from "../catalog/utils"

interface Props {
  practitioner: Practitioner | undefined
  refreshPractitioner: KeyedMutator<Practitioner>
}

const ExploreOrderingAccessBanner: React.FC<Props> = ({
  practitioner,
  ...props
}) => {
  const [credentialUploadV2Enabled] = useFeatureFlag(
    FeatureFlag.CredentialUploadV2
  )
  const [catalogSigningPractitionerFilterEnabled] = useFeatureFlag(
    FeatureFlag.CatalogSigningPractitionerFilter
  )
  const [orderingAccessTableV2Enabled] = useFeatureFlag(
    FeatureFlag.LabCompanyOrderingAccessV2
  )

  const showBanner = useMemo(() => {
    // TODO: Remove flag checks with PROD-1898
    if (
      !(
        credentialUploadV2Enabled &&
        catalogSigningPractitionerFilterEnabled &&
        orderingAccessTableV2Enabled
      )
    ) {
      return false
    }

    // Only show the banner once they have uploaded a license
    return practitioner?.has_license
  }, [practitioner?.has_license, credentialUploadV2Enabled])

  if (!showBanner) return null

  return (
    <ExploreOrderingAccessBannerInner practitioner={practitioner} {...props} />
  )
}

const ExploreOrderingAccessBannerInner: React.FC<Props> = (props) => {
  const modal = useOrderingAccessModal()
  const trackEvent = useCredentialTracking({ ...props, location: "onboarding" })

  return (
    <div
      className="group bg-cover bg-center w-full py-4 px-5 rounded-lg border border-slate-200 cursor-pointer"
      style={{ backgroundImage: `url(${BannerBackground})` }}
      onClick={() => {
        trackEvent(CredentialEvents.EXPLORE_ACCESS_BANNER_CLICKED)
        modal.show(props)
      }}
    >
      <div className="text-primary text-lg17 font-semibold group-hover:underline max-w-[50%]">
        Explore your lab ordering{" "}
        <span className="whitespace-nowrap">
          access <FontAwesomeIcon icon={faArrowRight} />
        </span>
      </div>
    </div>
  )
}

const OrderingAccessModal: React.FC<Props> = ({
  practitioner,
  refreshPractitioner,
}) => {
  const modal = useModal()
  const onOpenChange = (open: boolean) => {
    if (!open) modal.remove()
  }
  const appPractitioner = useAppSelector(selectPractitioner)
  const onClose = () => {
    if (
      appPractitioner?.vendor_physician_authorization_enabled &&
      !practitioner?.vendor_physician_authorization_enabled
    ) {
      refreshPractitioner()
    }
    onOpenChange(false)
  }

  return (
    <Dialog
      open={modal.visible}
      onClose={onClose}
      classes={{
        paper:
          "max-w-[1100px] w-full bg-white shadow-2xl border-[6px] rounded-xl border-white p-0 text-body overflow-scroll",
      }}
      disableEnforceFocus // allows intercom to work while modal is open
    >
      <div className="-mt-[6px]">
        <LabOrderingAccessPageV2
          toolbarProps={{
            topContainerStyle: {
              backgroundColor: "white",
              paddingRight: "32px",
            },
          }}
          toolbarChildren={
            <FontAwesomeIcon
              icon={faXmark}
              className="absolute top-0 md:top-3 right-4 text-2xl cursor-pointer"
              onClick={onClose}
            />
          }
        >
          <OrderingAccessModalFooter
            onClose={onClose}
            practitioner={practitioner}
          />
        </LabOrderingAccessPageV2>
      </div>
    </Dialog>
  )
}

interface FooterProps {
  practitioner: Practitioner | undefined
  onClose: () => void
}

const OrderingAccessModalFooter: React.FC<FooterProps> = ({
  practitioner,
  onClose,
}) => {
  const { selectedPractitioner } = useLabOrderingAccess()
  const trackEvent = useCredentialTracking({
    practitioner,
    location: "onboarding",
  })

  const onClickFilterCatalog = useMemo(() => {
    if (!selectedPractitioner) return undefined

    const location = getSigningPractitionerLocation(selectedPractitioner)
    const url = location.pathname + location.search

    return () => {
      trackEvent(CredentialEvents.EXPLORE_ACCESS_SEE_CATALOG_CLICKED, {
        viewAccessAsPractitionerId: selectedPractitioner.id,
      })
      openInNewTab(url)
    }
  }, [selectedPractitioner])

  return (
    <div className="sticky -bottom-[2px] flex flex-wrap sm:flex-nowrap gap-4 border-t border-slate-200 bg-white pt-4 pb-3 px-5 text-base15 font-semibold">
      <Button variant="outline" className="w-full" onClick={onClose}>
        Close
      </Button>
      <RupaLoadingButton
        variant="primary"
        className="w-full"
        wrapperClassName="w-full"
        loading={!onClickFilterCatalog}
        disabled={!onClickFilterCatalog}
        onClick={onClickFilterCatalog}
      >
        {selectedPractitioner &&
          `Filter Catalog for ${
            selectedPractitioner.user.first_name ??
            selectedPractitioner.titled_full_name
          }'s Ordering Access`}
      </RupaLoadingButton>
    </div>
  )
}

const NiceOrderingAccessModal = NiceModal.create<Props>((props) => (
  <OrderingAccessModal {...props} />
))

function useOrderingAccessModal() {
  return useModal(NiceOrderingAccessModal)
}

export default ExploreOrderingAccessBanner
