import { Theme } from "@material-ui/core"

import BodyText from "app/components/design-system/BodyText"
import makeAppStyles from "app/utils/makeAppStyles"

const styles = (theme: Theme) => ({
  container: {
    display: "flex",
    flexFlow: "column nowrap",
    gap: 12,
    alignItems: "center",
  },
})
const useStyles = makeAppStyles(styles)

interface Props {
  icon: any
  title: string
  description: string | JSX.Element
}

const EmptyBundleSection = ({ icon, title, description }: Props) => {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      {icon}
      <BodyText size="lg" weight="semibold">
        {title}
      </BodyText>
      <BodyText size="lg">{description}</BodyText>
    </div>
  )
}

export default EmptyBundleSection
