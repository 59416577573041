import { Control, useController } from "react-hook-form"

import { Theme } from "@material-ui/core"
import { cn } from "@rupahealth/design"

import RichTextEditor from "app/components/RichTextEditor"
import makeAppStyles from "app/utils/makeAppStyles"

export interface RichTextEditorFieldProps {
  control: Control<any>
  disabled?: boolean
  name: string
  placeholder?: string
  readOnly?: boolean
  required?: boolean
}

const useStyles = makeAppStyles((theme: Theme) => ({
  root: {
    position: "relative",
    width: "100%",
  },
  content: {
    position: "relative",
    display: "block",
    width: "100%",
    padding: 0,
    borderRadius: 0,
  },
  toolbar: {
    border: `1px solid #cbd5e1`,
    borderBottom: "none",
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6,
    left: 0,
    padding: theme.spacing(1.0, 3.0, 0.0),
    background: "white",
  },
  editable: {
    border: `1px solid #cbd5e1`,
    fontSize: 16,
    width: "100%",
    minHeight: "120px",
    padding: theme.spacing(2.0),
    paddingRight: theme.spacing(5.0),
    paddingBottom: theme.spacing(4.0),
    backgroundColor: "white",
    borderBottomLeftRadius: 6,
    borderBottomRightRadius: 6,
    "& p + p, & ul + p, & ol + p": {
      marginTop: theme.spacing(1.0),
    },
    // Style the placeholder text so it doesn't overflow.
    "& [data-slate-placeholder=true]": {
      paddingRight: theme.spacing(4.0),
    },
  },
  editableFocused: {},
}))

export default function RichTextEditorField({
  control,
  disabled,
  name,
  placeholder,
  readOnly,
  required,
}: RichTextEditorFieldProps) {
  const classes = useStyles()

  const {
    field,
    fieldState: { error },
  } = useController({
    control,
    name,
    shouldUnregister: true,
  })

  return (
    <RichTextEditor
      classes={{
        content: cn(
          classes.content,
          error && "border border-destructive rounded-md"
        ),
        editable: classes.editable,
        editableFocused: classes.editableFocused,
        toolbar: classes.toolbar,
      }}
      aria-invalid={error ? "true" : "false"}
      disabled={disabled}
      onBlur={field.onBlur}
      onChange={field.onChange}
      placeholder={placeholder}
      readOnly={readOnly}
      value={field.value}
    />
  )
}
