import { range } from "lodash"

import { Skeleton } from "@material-ui/lab"
import * as Accordion from "@radix-ui/react-accordion"

import { ReactComponent as WhiteCloseIcon } from "app/assets/icons/white-cross-thin.svg"
import Markdown from "app/components/Markdown"
import BodyText from "app/components/design-system/BodyText"
import DisplayText from "app/components/design-system/DisplayText"
import Card from "app/patient-portal/Card"
import PatientPortalFooterSupport from "app/patient-portal/footer/PatientPortalFooterSupport"
import { PatientPortalFaq } from "app/patient-portal/types"

export default function PatientPortalFAQs({
  faqs,
}: {
  faqs?: PatientPortalFaq[]
}) {
  return (
    <Card>
      <DisplayText size="lg" weight="semibold">
        Frequently Asked Questions
      </DisplayText>
      {faqs ? (
        <Accordion.Root
          type="single"
          collapsible
          className="flex flex-col gap-3"
        >
          {faqs.map(({ question, answer }) => (
            <Accordion.Item
              value={question}
              className="rounded-xl bg-white/80 border border-white transition-colors hover:border-primary hover:bg-blue-50"
              key={question}
            >
              <Accordion.Header>
                <Accordion.Trigger className="flex items-center justify-between group w-full py-4 px-4 gap-4 text-left sm:py-6">
                  <BodyText size="base" weight="bold" className="font-semibold">
                    {question}
                  </BodyText>
                  <div className="bg-primary rounded-full w-8 h-8 shrink-0 flex items-center justify-center">
                    <WhiteCloseIcon className="-rotate-45 group-aria-expanded:rotate-90 transition-transform h-3 w-3" />
                  </div>
                </Accordion.Trigger>
              </Accordion.Header>
              <Accordion.Content className="overflow-hidden px-4 transition-all data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down">
                <div className="pb-6">
                  <Markdown padded={false}>{answer}</Markdown>
                </div>
              </Accordion.Content>
            </Accordion.Item>
          ))}
        </Accordion.Root>
      ) : (
        <div className="flex flex-col gap-3">
          {range(10).map((number) => (
            <Skeleton
              variant="rect"
              animation="wave"
              className="rounded-xl bg-white/80 h-20 block"
              key={number}
            />
          ))}
        </div>
      )}
      <PatientPortalFooterSupport />
    </Card>
  )
}
