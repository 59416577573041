import * as React from "react"

import clsx from "clsx"

import { makeStyles } from "@material-ui/core/styles"

import LoginImage from "app/assets/images/login.svg"
import RupaHealthLogo from "app/assets/images/logos/rupa-health-logo.svg"
import DisplayText from "app/components/design-system/DisplayText"
import { navy } from "app/theme"

const useStyles = makeStyles((theme) => ({
  root: {
    background: `no-repeat url(${LoginImage}), ${navy}`,
    flex: 1,
    display: "flex",
    justifyContent: "center",
    backgroundSize: "auto",
    [theme.breakpoints.up("md")]: {
      backgroundSize: "100% auto",
    },
  },
  content: {
    display: "flex",
    flexFlow: "column",
    alignItems: "center",
    maxWidth: 514,
    width: "100%",
    margin: "50px 0px",
    [theme.breakpoints.up("sm")]: {
      margin: "100px 0px",
    },
  },
  title: {
    fontSize: 32,
    textAlign: "center",

    [theme.breakpoints.up("sm")]: {
      fontSize: 40,
    },
  },
  subtitle: {
    fontSize: 14,
    textAlign: "center",
    color: navy,
    padding: "10px 30px",
    fontWeight: 400,
    fontFamily: "Open Sans",

    [theme.breakpoints.up("sm")]: {
      fontSize: 17,
      maxWidth: 450,
    },
  },
  logo: {
    width: 200,
    marginBottom: 16,
  },
}))

export default function LandingPage({
  title,
  subtitle,
  children,
}: {
  title: string
  subtitle?: string
  children: React.ReactNode
}) {
  const classes = useStyles()

  return (
    <div className={clsx(classes.root, "fs-unmask")}>
      <div className={classes.content}>
        <img
          className={classes.logo}
          src={RupaHealthLogo}
          alt="Rupa Health Labs"
        />

        <DisplayText size="2xl" className={classes.title}>
          {title}
        </DisplayText>
        {subtitle && <div className={classes.subtitle}>{subtitle}</div>}
        {children}
      </div>
    </div>
  )
}
