import { useEffect } from "react"
import { useDispatch } from "react-redux"

import { isEmpty } from "lodash"
import { usePrevious } from "react-use"

import OnboardingSurveyModal from "app/components/modals/OnboardingSurveyModal"
import TaskBasedOnboardingSurveyModal from "app/components/modals/TaskBasedOnboardingSurveyModal"
import useFeatureFlag from "app/hooks/use-feature-flag"
import useAppSelector from "app/hooks/useAppSelector"
import { FeatureFlag } from "app/providers/FeatureFlagProvider"
import { OnboardingStep } from "app/types"

import useOnboardingSWR from "../onboarding/hooks/use-onboarding-swr"
import * as Actions from "./store/actions"

interface Props {
  refreshPractitioner?: () => void
}

const DashboardOnboarding: React.FC<Props> = ({ refreshPractitioner }) => {
  const dispatch = useDispatch()
  const { surveyModalOpen, surveyModalHasShown, onboardingStep } =
    useAppSelector(
      ({ onboarding, dashboard }) =>
        onboarding?.onboarding ??
        dashboard?.onboarding ?? {
          surveyModalOpen: false,
          surveyModalHasShown: false,
          onboardingStep: undefined,
        }
    )

  const { mutate } = useOnboardingSWR()

  const practitioner = useAppSelector(({ practitioner }) => practitioner)

  const [taskBasedOnboarding, taskBasedOnboardingLoading] = useFeatureFlag(
    FeatureFlag.TaskBasedOnboarding
  )

  useEffect(() => {
    if (
      onboardingStep === OnboardingStep.SURVEY &&
      !surveyModalOpen &&
      !surveyModalHasShown
    ) {
      dispatch(
        Actions.startOnboardingSurveyIfRequired(
          practitioner,
          taskBasedOnboarding
        )
      )
    }
  }, [dispatch, practitioner, onboardingStep, taskBasedOnboarding])

  const previousSurveyModalOpen = usePrevious(surveyModalOpen)
  useEffect(() => {
    if (!surveyModalOpen && previousSurveyModalOpen) {
      mutate()
      refreshPractitioner?.()
    }
  }, [surveyModalOpen, previousSurveyModalOpen, mutate, refreshPractitioner])

  if (taskBasedOnboardingLoading) {
    return null
  }

  return (
    <div>
      {taskBasedOnboarding && !isEmpty(practitioner) ? (
        <TaskBasedOnboardingSurveyModal
          open={surveyModalOpen}
          practitioner={practitioner}
        />
      ) : (
        <div>
          {!isEmpty(practitioner) && (
            <OnboardingSurveyModal
              open={surveyModalOpen}
              practitioner={practitioner}
            />
          )}
        </div>
      )}
    </div>
  )
}

export default DashboardOnboarding
