import { Patient } from "app/types"

import * as Actions from "../actions/dashboard.actions"

export interface DashboardState {
  patients: Patient[]
}
export const dashboardInitialState: DashboardState = { patients: [] }

function dashboardReducer(
  state = dashboardInitialState,
  action: Actions.DashboardActionType
): DashboardState {
  switch (action.type) {
    case Actions.GET_PATIENTS: {
      return {
        ...state,
        patients: action.payload,
      }
    }
    case Actions.ADD_PATIENT: {
      return { ...state, patients: [...state.patients, action.payload] }
    }
    default: {
      return state
    }
  }
}

export default dashboardReducer
