import { colors, dashboardHighlight, shadows } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"

const useTableStyles = makeAppStyles((theme) => ({
  container: {
    border: `1px solid ${colors.coolGray[200]}`,
    borderRadius: 8,
    overflow: "auto",
    background: "white",
    boxShadow: shadows.default,
  },
  table: {
    tableLayout: "auto",
  },
  tableHeaderRow: {
    color: theme?.palette?.text?.secondary,
    backgroundColor: colors.blueGray[50],
  },
  tableDataRow: {
    backgroundColor: "white",
    height: 63,
  },
  tableDataRowCanExpand: {
    cursor: "pointer",
  },
  tableDataRowIsExpanded: {
    backgroundColor: dashboardHighlight,
  },
  tableCell: {
    padding: theme.spacing(2),
    wordWrap: "break-word",
  },
  tableEmptyRow: {
    height: 164,
  },
  tableEmptyRowIcon: {
    fill: colors.blueGray[500],
    marginBottom: theme.spacing(1.0),
  },
  tableEmptyRowText: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "white",
    gap: theme.spacing(1.0),
    color: colors.blueGray[500],
  },
  tablePaginationRow: {
    color: theme?.palette?.text?.secondary,
    backgroundColor: colors.blueGray[50],
  },
  emptyText: {
    maxWidth: 550,
  },
  inputsContainer: {
    display: "flex",
    gap: 8,
  },
  label: {
    fontSize: 12,
    color: colors.blueGray[500],
    lineHeight: "130%",
    letterSpacing: "0.5px",
    fontWeight: 600,
  },
}))

export default useTableStyles
