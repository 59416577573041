import { useState } from "react"
import { useDispatch } from "react-redux"

import useEventCallback from "app/hooks/use-event-callback"
import useFeatureFlag, { FeatureFlag } from "app/hooks/use-feature-flag"
import { useCheckoutTokenFromLocation } from "app/main/patient-checkout/hooks"
import * as actions from "app/main/patient-checkout/store/actions"
import { handleApiError } from "app/utils"

export default function useUpdateOrder() {
  const [isUpdatingOrder, setIsUpdatingOrder] = useState(false)
  const dispatch = useDispatch()
  const token = useCheckoutTokenFromLocation()
  const [isCreditCardFeeCheckoutEnabled] = useFeatureFlag(
    FeatureFlag.CreditCardFeeCheckoutEnabled
  )
  const [isMedicareCheckEnabled] = useFeatureFlag(
    FeatureFlag.isMedicareCheckEnabled
  )
  const [isBeastCoastEnabled] = useFeatureFlag(FeatureFlag.BeastCoast)

  const updateOrder = useEventCallback(
    async ({
      billing_state,
      selected_payment_method,
      is_medicare_used,
      medicare_form_data,
      sample_collection_state,
      phlebotomy_providers,
      birthday,
    }) => {
      if (
        !isCreditCardFeeCheckoutEnabled &&
        !isMedicareCheckEnabled &&
        !isBeastCoastEnabled
      ) {
        return
      }

      setIsUpdatingOrder(true)

      try {
        await dispatch(
          actions.updateOrder({
            token,
            billing_state,
            selected_payment_method,
            is_medicare_used,
            medicare_form_data,
            sample_collection_state,
            phlebotomy_providers,
            birthday,
          })
        )
      } catch (error) {
        dispatch(handleApiError(error))
      } finally {
        setIsUpdatingOrder(false)
      }
    }
  )

  return {
    isUpdatingOrder,
    updateOrder,
  }
}
