import refiner from "refiner-js"

import { refinerProject, environment } from "app/settings"

export const SURVEYS = {
  PRODUCT_TOUR_FEEDBACK: "Product tour feedback",
  WHITE_GLOVE: "White glove",
  LAB_TEST_DETAILS_MORE_INFO_SURVEY: "Lab test details more info survey",
  ONBOARDING_ONE_ON_ONE_PROMPT: "Onboarding 1:1 Prompt",
}

const SURVEY_IDS = {
  [SURVEYS.PRODUCT_TOUR_FEEDBACK]: {
    Dev: "04ccd6f0-6682-11eb-ac2d-618a9c94c3a2",
    Staging: "0fb18bd0-6682-11eb-a6ca-71c70757664e",
    Production: "59e7af40-657c-11eb-9d90-15f25c0aa743",
  },
  [SURVEYS.WHITE_GLOVE]: {
    Dev: "092f9410-6682-11eb-944c-71f80320430f",
    Staging: "1319cf80-6682-11eb-b803-71e3d52135a9",
    Production: "65927460-643a-11eb-af11-7badf51658aa",
  },
  [SURVEYS.LAB_TEST_DETAILS_MORE_INFO_SURVEY]: {
    Dev: "be1a7e30-86b5-11eb-80f1-e19270d34295",
    Staging: "987d6890-8681-11eb-98c5-43fe530b59af",
    Production: "b8a310d0-86b5-11eb-ad14-6d1876b97842",
  },
  [SURVEYS.ONBOARDING_ONE_ON_ONE_PROMPT]: {
    Dev: "b20773c0-3cef-11ec-b8a9-83f964f618cb",
    Staging: "b88620f0-3cef-11ec-97b5-79de699c59eb",
    Production: "c6f33af0-3b4c-11ec-b7e4-b11c52e93225",
  },
}

const feedbackId = SURVEY_IDS[SURVEYS.PRODUCT_TOUR_FEEDBACK][environment]

export function initialize() {
  refiner("setProject", refinerProject)

  // When the user completes/dismisses the product feedback form, immediately ask them
  // if they want a white glove call.
  refiner("onClose", (formId) => {
    if (formId === feedbackId) {
      showForm(SURVEYS.WHITE_GLOVE)
    }
  })
}

export function identify(user) {
  refiner("identifyUser", {
    id: user.id,
    email: user.email,
    name: `${user.first_name} ${user.last_name}`,
  })
}

export function showForm(survey) {
  const formId = SURVEY_IDS[survey][environment]
  refiner("showForm", formId)
}
