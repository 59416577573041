import { makeStyles } from "@material-ui/core/styles"

import { colors, titleFontFamily, primaryColor, white } from "app/theme"

const taskStyles = makeStyles((theme) => ({
  taskTextContainer: {
    display: "flex",
    textAlign: "center",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "90%",
    maxWidth: "500px",
  },
  taskText: {
    fontFamily: theme.typography.fontFamily,
    color: colors.blueGray[500],
    fontWeight: 600,
    padding: "0 16px",
  },
  divider: {
    margin: "0 16px",
    width: "90%",
    backgroundColor: "#ebedef",
    height: 1,
  },
  buttonText: {
    fontFamily: titleFontFamily,
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: "15px",
    lineHeight: "135%",
    color: primaryColor,
  },
  taskButton: {
    boxSizing: "border-box",
    marginBottom: 10,
    padding: "8px 19px",
    background: white,
    border: `1px solid ${colors.blueGray[300]}`,
    boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.07)",
    borderRadius: "8px",
    "&:hover": {
      backgroundColor: "#0000000a",
    },
  },
  initialsIcon: {
    height: 55,
    scale: "1.5",
    marginTop: 10,
  },
  physServicesIcon: {
    marginTop: 10,
    scale: "1.1",
  },
  bloodDropIcon: {
    marginTop: 30,
    marginBottom: 10,
    scale: "1.9",
    fill: colors.blueGray[300],
  },
  emailIcon: {
    fill: colors.blueGray[300],
    marginTop: 20,
  },
  subHeaderContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginBottom: 15,
    backgroundColor: colors.blue[50],
  },
}))

export default taskStyles
