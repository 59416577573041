import useComparisonState from "./use-comparison-state"

/**
 * Helper hook used for selecting the lab test data with the given id.
 *
 * @param labTestId the ID of the lab test to retrieve
 * @returns the lab test, if it exists, and the loading status
 */
export default function useLabTestById(labTestId: string) {
  const { isLoading, labTestById } = useComparisonState()
  const labTest = labTestById[labTestId]
  return {
    isLoading,
    labTest,
    labTestId,
  }
}
