export const LateResultsMessage = ({
  daysSinceResultsExpected,
  resultsDelayReason,
  labCompanyName,
}: {
  daysSinceResultsExpected: number
  resultsDelayReason?: string
  labCompanyName?: string
}) => {
  return (
    <div className="flex flex-col gap-1">
      <p className="font-semibold">{`⚠️ Results were expected ${daysSinceResultsExpected} ${
        daysSinceResultsExpected === 1 ? "day" : "days"
      } ago.`}</p>
      <p>
        {resultsDelayReason ||
          `We're following up with ${
            labCompanyName || "the lab"
          }! Check back here soon.`}
      </p>
    </div>
  )
}
