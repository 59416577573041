import { colors, navy } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"

import { BiomarkerCustomDescriptionStatuses } from "../constants"

interface Props {
  status: BiomarkerCustomDescriptionStatuses
}

const useStyles = makeAppStyles<{ status: BiomarkerCustomDescriptionStatuses }>(
  () => ({
    statusContainer: {
      display: "flex",
      padding: "3px 10px",
      borderRadius: 25,
      alignItems: "center",
      gap: 10,
      width: "fit-content",
      background: (props) =>
        props.status === BiomarkerCustomDescriptionStatuses.DEFAULT
          ? colors.blueGray[100]
          : colors.teal[50],
    },
    statusIndicator: {
      width: "7px",
      height: "7px",
      borderRadius: "7px",
    },
    statusText: {
      fontSize: 15,
      color: navy,
    },
  })
)

const BioamrkerCustomDescriptionStatus = ({ status }: Props) => {
  const classes = useStyles({ status })

  return (
    <div className={classes.statusContainer}>
      <div
        className={classes.statusIndicator}
        style={{
          background:
            status === "Default" ? colors.blueGray[300] : colors.teal[500],
        }}
      ></div>
      <div
        className={classes.statusText}
        style={{ fontWeight: status === "Default" ? 400 : 600 }}
      >
        {status}
      </div>
    </div>
  )
}

export default BioamrkerCustomDescriptionStatus
