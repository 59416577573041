import axios, { AxiosResponse } from "axios"
import { isFunction, isNil, omit } from "lodash"

import {
  ResourceResponse,
  ResourceCollectionResponse,
  ResourceRequestConfiguration,
} from "app/swr/types"
import { getApiBaseUrl } from "app/utils"

/**
 * Gets the headers for the given request configuration.
 *
 * @param config the request configuration
 * @returns the headers
 */
async function getHeaders(config: ResourceRequestConfiguration) {
  const headers = {
    Accept: "application/vnd.api+json",
    "Content-Type": "application/vnd.api+json",
  }

  if (config.accessToken) {
    let accessToken: string | undefined

    if (isFunction(config.accessToken)) {
      accessToken = await config.accessToken()
    } else {
      accessToken = config.accessToken
    }

    if (accessToken) {
      headers["authorization"] = `Bearer ${accessToken}`
    } else {
      headers["authorization"] = null
    }
  }

  return headers
}

/**
 * Parses the request url and params for the given resource request configuration.
 *
 * @param config the resource endpoint
 * @returns the request url and params
 */
function parseUrl(config: ResourceRequestConfiguration) {
  const [pathname, search] = config.url.split("?")
  const searchParams = new URLSearchParams(search)

  if (config.params) {
    Object.entries(config.params).forEach(([key, valueOrValues]) => {
      if (Array.isArray(valueOrValues)) {
        valueOrValues.forEach((value) => searchParams.append(key, value))
      } else if (!isNil(valueOrValues)) {
        searchParams.set(key, valueOrValues)
      }
    })
  }

  if (config.include) {
    searchParams.set("include", config.include.join(","))
  }

  return {
    url: pathname,
    params: searchParams,
  }
}

/**
 * Executes requests for JSON:API resource endpoints.
 *
 * @param config the request configuration
 * @returns the resource response
 */
export default async function resourceRequest<
  R extends ResourceResponse | ResourceCollectionResponse | object | Array<any>,
  D = any
>(config: ResourceRequestConfiguration<D>) {
  const { method = "get" } = config
  const headers = await getHeaders(config)
  const { params, url } = parseUrl(config)

  const response = await axios.request<R, AxiosResponse<R>, D>({
    baseURL: getApiBaseUrl() + "/api/normalized/",
    ...omit(config, "method", "url", "headers", "params", "withCredentials"),
    method,
    url,
    headers,
    params,
    withCredentials: true,
  })

  return response.data
}
