import { useState } from "react"

import { some } from "lodash"

import { Collapse, Typography } from "@material-ui/core"
import Skeleton from "@material-ui/lab/Skeleton"
import { cn } from "@rupahealth/design"

import BodyText from "app/components/design-system/BodyText"
import { colors, navy } from "app/theme"
import { PatientPortalOrderLineItemLabTest } from "app/types"
import { PatientPortalOrder } from "app/types"
import { formatDollars } from "app/utils"
import makeAppStyles from "app/utils/makeAppStyles"

import {
  LabTestDetails,
  LabTestDetailsButton,
  LabTestType,
} from "../../LabTestLineItem"
import { PhlebotomyRequired } from "./PhlebotomyRequired"
import { RemoveLineItemButton } from "./RemoveLIneItemButton"
import { TestLineItemContainer } from "./TestLineItemContainer"

interface ComboGroupLineItemProps {
  comboGroup: PatientPortalOrderLineItemLabTest
  showPrices: boolean
  order: PatientPortalOrder
  isEditing: boolean
  updatingOrderedTestIds: string[]
  isUpdatingOrder: boolean
  enableLineItems: (orderedTestIds: string[]) => void
  disableLineItems: (orderedTestIds: string[]) => void
}

const useStyles = makeAppStyles({
  comboGroupContainer: {
    padding: "16px 18px",
  },
  comboGroupTitle: {
    fontWeight: 700,
    color: navy,
  },
  companyNameContainer: {
    color: navy,
    marginBottom: 21,
  },
  comboGroupRowContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    flexDirection: "row",
  },
  bottomRowContainer: {
    marginTop: 25,
    marginBottom: 10,
  },
  comboGroupCardPricePlaceholder: {
    backgroundColor: colors.blueGray[200],
    borderRadius: 12,
    marginLeft: "auto",
    height: 14,
    marginTop: 5,
    marginBottom: 5,
    width: 60,
  },
  priceText: {
    color: navy,
  },
  comboGroupOptionRow: {
    width: "100%",
    background: colors.blueGray[100],
    padding: "4px 10px",
    marginBottom: 6,
    borderRadius: 4,
  },
})

const ComboGroupLineItem = ({
  comboGroup,
  showPrices,
  order,
  isEditing,
  updatingOrderedTestIds,
  isUpdatingOrder,
  enableLineItems,
  disableLineItems,
}: ComboGroupLineItemProps) => {
  const classes = useStyles()
  const [detailsExpanded, setDetailsExpanded] = useState(false)

  const totalRupaCost = comboGroup.discounted_cost || comboGroup.cost
  const totalMsrpCost = comboGroup.cost ? parseFloat(comboGroup.cost) : 0

  const phlebotomyRequired = some(
    comboGroup.ordered_test.lab_test.lab_test_types.map(
      (labTestType) => labTestType.phlebotomy_required
    )
  )

  const onDetailsClick = () => {
    setDetailsExpanded(!detailsExpanded)
  }

  const disabled = Boolean(comboGroup.date_disabled)

  return (
    <TestLineItemContainer
      lineItem={comboGroup}
      isEditing={isEditing}
      disabled={disabled}
      ariaLabel={comboGroup.ordered_test.lab_test.name}
    >
      <div className={classes.comboGroupContainer}>
        <div className={classes.comboGroupRowContainer}>
          <div
            className={cn("flex flex-col justify-center items-start mt-2", {
              "opacity-disabled": disabled,
            })}
          >
            <div className={classes.comboGroupTitle}>{comboGroup.title}</div>
            <div className={classes.companyNameContainer}>
              {comboGroup.ordered_test.lab_test.lab_company.name}
            </div>
          </div>
          <div className="flex flex-col flex-start items-end mt-2">
            <div className={cn({ "opacity-disabled": disabled })}>
              <ComboGroupCardPrice
                isPriced={showPrices}
                discountedCost={totalRupaCost}
                cost={totalMsrpCost}
              />
            </div>
            {isEditing && (
              <RemoveLineItemButton
                removing={!disabled}
                disabled={isUpdatingOrder}
                orderedTestIds={[String(comboGroup.ordered_test?.id)]}
                enableLineItems={enableLineItems}
                disableLineItems={disableLineItems}
                updatingOrderedTestIds={updatingOrderedTestIds}
              />
            )}
          </div>
        </div>

        <div className={cn({ "opacity-disabled": disabled })}>
          {comboGroup.ordered_test.combo_group_options?.map(
            (comboGroupOption) => (
              <div
                className={classes.comboGroupOptionRow}
                key={comboGroupOption.id}
              >
                <BodyText weight="bold">
                  {comboGroupOption.name || comboGroupOption.lab_test.name}
                </BodyText>
              </div>
            )
          )}
        </div>

        <div
          className={cn(
            classes.comboGroupRowContainer,
            classes.bottomRowContainer,
            { "opacity-disabled": disabled }
          )}
        >
          <div>
            <LabTestType labTest={comboGroup.ordered_test.lab_test} />

            {phlebotomyRequired && (
              <PhlebotomyRequired
                labCompanyKey={comboGroup.ordered_test.lab_test.lab_company.key}
              />
            )}
          </div>

          <LabTestDetailsButton
            expanded={detailsExpanded}
            onClick={onDetailsClick}
          />
        </div>
      </div>

      <Collapse in={detailsExpanded}>
        <LabTestDetails orderedTest={comboGroup.ordered_test} />
      </Collapse>
    </TestLineItemContainer>
  )
}

const ComboGroupCardPrice = ({ cost, discountedCost, isPriced }) => {
  const classes = useStyles()

  return isPriced ? (
    <Typography
      className={cn("font-bold text-base15", classes.priceText)}
      align="right"
    >
      {discountedCost < cost ? (
        <>
          <span className="text-gray-500 line-through">
            {formatDollars(cost)}
          </span>
          <span className="ml-2">{formatDollars(discountedCost)}</span>
        </>
      ) : (
        <span className="ml-2">{formatDollars(discountedCost)}</span>
      )}
    </Typography>
  ) : (
    <Skeleton
      className={classes.comboGroupCardPricePlaceholder}
      variant="rect"
      animation="wave"
    />
  )
}

export default ComboGroupLineItem
