import { LAB_COMPANY_KEY } from "app/constants"

// Vibrant
const VIBRANT_IS_LEAVING_WARNING =
  "Vibrant America & Wellness are leaving Rupa on January 31st. Orders paid until then will be processed as normal! Tests paid after this date will be refunded & canceled."
export const VIBRANT_LEFT_WARNING =
  "Vibrant America & Wellness have left platforms like Rupa."

export function labTestIsVibrant(labTest: any) {
  return [
    LAB_COMPANY_KEY.VIBRANT_AMERICA,
    LAB_COMPANY_KEY.VIBRANT_WELLNESS,
  ].includes(labTest?.lab_company?.key)
}

export function anyLabTestIsVibrant(lab_tests: any) {
  return lab_tests?.some((lab_test: any) => labTestIsVibrant(lab_test))
}

export function getDefaultVibrantWarning(isVibrantDisabled) {
  if (isVibrantDisabled) {
    return VIBRANT_LEFT_WARNING
  } else {
    return VIBRANT_IS_LEAVING_WARNING
  }
}

// Vibrant warnings for bundle cards
export function getVibrantBundleCardWarning(
  isVibrantDisabled: boolean
): string {
  if (isVibrantDisabled) {
    return "Vibrant unavailable"
  } else {
    return "Expires Jan 31st"
  }
}

export function getVibrantBundleCardWarningTooltip(isVibrantDisabled: boolean) {
  if (isVibrantDisabled) {
    return VIBRANT_LEFT_WARNING
  } else {
    return "Vibrant America & Wellness are leaving Rupa on Jan 31st."
  }
}
