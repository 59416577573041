import useEventCallback from "app/hooks/use-event-callback"
import useHandleApiError from "app/hooks/use-handle-api-error"
import useMutateResource from "app/swr/hooks/use-mutate-resource"
import { ResourceIdentifier, ResourceResponse } from "app/swr/types"
import resourceRequest from "app/swr/utils/resource-request"
import { ResultsInterpretationTask } from "types/results-interpretation"

export type TaskEditableAttributes = Omit<
  ResultsInterpretationTask["attributes"],
  "content_instructions"
> & {
  // Workaround for an issue with handling recursive object types in react-hook-form. See https://github.com/react-hook-form/react-hook-form/discussions/7764.
  content_instructions: any
}

export default function usePatchInterpretationTask(
  identifier: ResourceIdentifier
) {
  const handleApiError = useHandleApiError()
  const mutateResource = useMutateResource()
  return useEventCallback(async (attributes: TaskEditableAttributes) => {
    const sendPatchRequest = async () => {
      try {
        const { data } = await resourceRequest<
          ResourceResponse<ResultsInterpretationTask>
        >({
          method: "patch",
          url: `/results_interpretation_tasks/${identifier.id}/`,
          data: {
            data: {
              ...identifier,
              attributes,
            },
          },
        })

        return data
      } catch (error) {
        handleApiError(error)

        // re-throw
        throw error
      }
    }

    return mutateResource<ResultsInterpretationTask>(
      identifier,
      sendPatchRequest,
      {
        optimisticData(currentData) {
          if (!currentData) {
            // should never be reached, but type error without
            return undefined as any
          }

          return {
            ...currentData,
            attributes: {
              ...currentData.attributes,
              ...attributes,
            },
          }
        },
        rollbackOnError: true,
      }
    )
  })
}
