import { useEffect, useRef } from "react"

import { debounce, isEqual, pick } from "lodash"
import { FieldErrors, useForm } from "react-hook-form"

export type FoodPlanEditorFormValues = {
  // Workaround for an issue with handling recursive object types in react-hook-form. See https://github.com/react-hook-form/react-hook-form/discussions/7764.
  user_output: any
}

export interface UseFoodPlanEditorFormProps {
  defaultValues?: FoodPlanEditorFormValues
  onPatch?: (values: FoodPlanEditorFormValues) => Promise<void>
}

export default function useFoodPlanEditorForm({
  defaultValues = {
    user_output: [],
  },
  onPatch,
}: UseFoodPlanEditorFormProps) {
  const defaultValuesRef = useRef<FoodPlanEditorFormValues>(defaultValues)
  defaultValuesRef.current = defaultValues = pick(defaultValues, [
    "user_output",
  ])

  const methods = useForm<FoodPlanEditorFormValues>({
    defaultValues,
    shouldUnregister: true,
  })

  const { handleSubmit, watch } = methods

  /**
   * Debounce the form submission on change to avoid sending too many requests to the server.
   */
  useEffect(() => {
    if (!onPatch) {
      return
    }

    /**
     * On valid form data, send the data to the server.
     */
    const onValid = (values: FoodPlanEditorFormValues) => {
      // Avoid awaiting patch promise to avoid blocking the UI.
      onPatch(values)
    }

    /**
     * On invalid form data, only send the valid data to the server.
     * @param values the data received during the change event
     */
    const onInvalid =
      (values: FoodPlanEditorFormValues) =>
      (errors: FieldErrors<FoodPlanEditorFormValues>) => {
        const validData = Object.fromEntries(
          Object.entries(values).filter(([key]) => !errors[key])
        ) as FoodPlanEditorFormValues

        onValid(validData)
      }

    const debounceOnSubmit = debounce(
      (values) => handleSubmit(onValid, onInvalid(values))(),
      400
    )
    const subscription = watch((values) => {
      if (!isEqual(values, defaultValuesRef.current)) {
        return debounceOnSubmit(values)
      } else {
        debounceOnSubmit?.cancel()
      }
    })

    return () => {
      // Rather than cancel, we flush, as this ensures the save still goes through.
      debounceOnSubmit?.flush()

      subscription?.unsubscribe()
    }
  }, [handleSubmit, watch])

  return methods
}
