import { KIT_STATUS } from "app/constants.typed"

import { StatusConfiguration } from "../../types"
import { StatusConfigurationProps } from "../../types/dashboard-types"
import { PROGRESS_PERCENTAGES } from "../consts"

const SubTitle = ({ patient }: StatusConfigurationProps) => {
  return (
    <span>
      {`The authorizing physician is reviewing these tests for ${patient?.attributes.full_name}.`}
    </span>
  )
}

const pendingPhysServicesAuthStatusConfiguration: StatusConfiguration<StatusConfigurationProps> =
  {
    when: { kitStatus: KIT_STATUS.PENDING_PHYSICIAN_AUTHORIZATION },
    progressPercentage: PROGRESS_PERCENTAGES.PENDING_PHYSICIAN_AUTHORIZATION,
    title: "Pending Authorizing Physician Review",
    subTitle: SubTitle,
  }

export default pendingPhysServicesAuthStatusConfiguration
