import { Dispatch, SetStateAction } from "react"

import { STATUS_CONFIGURATION_TYPES } from "app/components/hooks/use-status-components/consts"
import {
  PatientPortalLabCompany,
  PatientPortalLabCompanyAlert,
  PatientPortalOrder,
  PatientPortalOrderedTest,
  PatientPortalPractitioner,
} from "app/patient-portal/types"
import { SpecimenIssueNormalized } from "types/specimen-issue"

import { BaseStatusConfigurationProps } from "./types"

export interface SpecimenIssueProps {
  specimenIssue: SpecimenIssueNormalized | undefined
  specimenIssueShowMore: boolean
  setSpecimenIssueShowMore: Dispatch<SetStateAction<boolean>>
}

export interface StatusConfigurationProps extends BaseStatusConfigurationProps {
  type: typeof STATUS_CONFIGURATION_TYPES.PATIENT_PORTAL
  orderedTest: PatientPortalOrderedTest
  order: PatientPortalOrder | undefined
  labCompany: PatientPortalLabCompany | undefined
  practitioner: PatientPortalPractitioner | undefined
  specimenIssue: SpecimenIssueNormalized | undefined
  specimenIssueShowMore: boolean
  setSpecimenIssueShowMore: Dispatch<SetStateAction<boolean>>
  labCompanyAlert: PatientPortalLabCompanyAlert | undefined
}

export function isPatientPortalProps(
  props: BaseStatusConfigurationProps
): props is StatusConfigurationProps {
  return props.type === STATUS_CONFIGURATION_TYPES.PATIENT_PORTAL
}
