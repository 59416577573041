import { SearchSuggestion } from "app/types"

import * as Actions from "./autosuggest.actions"

/**
 * List of the suggestion types we support in the order we want to render them.
 * Each list of suggestions within each type group will be ordered by relevancy.
 */
export const FILTER_SUGGESTION_TYPES = [
  "lab_test_type",
  "lab_company",
  "health_category",
  "biomarker",
]

export const SEARCH_TEXT_PLACEHOLDER = "search_placeholder" // Placeholder item that shows the current search text instead of a fetched suggestion

/**
 * List of the suggestion types we use as search text suggestions.
 */
export const SEARCH_TEXT_SUGGESTION_TYPES = [
  "search_term",
  SEARCH_TEXT_PLACEHOLDER,
]

export interface SearchSuggestionsState {
  loading: boolean
  typing: boolean
  error?: Error
  filterSuggestionTypes: string[]
  filterSuggestionsByType: {
    [type: string]: SearchSuggestion[]
  }
  rawSuggestions: SearchSuggestion[]
  searchTextSuggestions: SearchSuggestion[]
  suggestionsCount: number
}

export const initialState: SearchSuggestionsState = {
  loading: true,
  typing: false,
  error: undefined,
  filterSuggestionTypes: [],
  filterSuggestionsByType: {},
  rawSuggestions: [],
  searchTextSuggestions: [],
  suggestionsCount: 0,
}

export default function autoSuggest(
  state: SearchSuggestionsState = initialState,
  action:
    | Actions.StartTypingAction
    | Actions.EndTypingAction
    | Actions.RequestSuggestionsAction
    | Actions.ReceiveSuggestionsAction
    | Actions.FailedSuggestionsAction
): SearchSuggestionsState {
  switch (action.type) {
    case Actions.START_TYPING: {
      return {
        ...state,
        typing: true,
      }
    }
    case Actions.END_TYPING: {
      return {
        ...state,
        typing: false,
      }
    }
    case Actions.REQUEST_SUGGESTIONS: {
      return {
        ...state,
        loading: true,
        error: undefined,
      }
    }
    case Actions.FAILED_SUGGESTIONS: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    }
    case Actions.RECEIVE_SUGGESTIONS: {
      const { results: suggestions } = action.payload

      const nextState = suggestions.reduce(
        (result, suggestion) => {
          result.suggestionsCount++
          result.rawSuggestions.push(suggestion)

          if (FILTER_SUGGESTION_TYPES.includes(suggestion.type)) {
            if (!result.filterSuggestionTypes.includes(suggestion.type)) {
              result.filterSuggestionTypes.push(suggestion.type)
            }

            result.filterSuggestionsByType[suggestion.type] = [
              ...(result.filterSuggestionsByType[suggestion.type] || []),
              suggestion,
            ]
          } else if (SEARCH_TEXT_SUGGESTION_TYPES.includes(suggestion.type)) {
            result.searchTextSuggestions.push(suggestion)
          }

          return result
        },
        {
          ...state,
          loading: false,
          error: undefined,
          filterSuggestionTypes: [],
          filterSuggestionsByType: {},
          rawSuggestions: [],
          searchTextSuggestions: [],
          suggestionsCount: 0,
        } as SearchSuggestionsState
      )

      // sort suggestion types by fixed sort order
      nextState.filterSuggestionTypes = FILTER_SUGGESTION_TYPES.filter((type) =>
        nextState.filterSuggestionTypes.includes(type)
      )

      return nextState
    }
  }
}
