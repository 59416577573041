import { ReactElement } from "react"

import { Button } from "@rupahealth/design"

import BodyText from "app/components/design-system/BodyText"
import { BannerLink as BannerLinkType } from "app/main/banner/types"

interface Props {
  link?: BannerLinkType
  icon: ReactElement
}

const BannerLink = ({ link, icon }: Props) => {
  // Render a text link if text and url are provided
  if (link?.text && link?.url) {
    return (
      <a
        href={link.url}
        target={link.target}
        rel="noreferrer"
        className="inline-flex items-center gap-1"
      >
        <BodyText weight="semibold" size="md" className="text-primary">
          {link.text}
        </BodyText>
        {icon}
      </a>
    )
  }

  // Render a button onClick link if text and onClick are provided
  if (link?.text && link?.onClick) {
    return (
      <Button
        type="button"
        variant="ghost"
        className="flex items-center gap-[6px] py-1 px-2 h-auto rounded-md"
        onClick={link.onClick}
      >
        <BodyText weight="semibold" size="md" className="text-primary">
          {link.text}
        </BodyText>
        {icon}
      </Button>
    )
  }

  // No link to render.
  return null
}

export default BannerLink
