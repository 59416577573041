import { useHistory, useParams } from "react-router-dom"

import { UserPaths } from "app/Routes/paths"
import FoodPlanCard from "app/food-plans/components/FoodPlanCard"
import FoodPlanEditorCardHeader from "app/food-plans/components/FoodPlanEditorCard/FoodPlanEditorCardHeader"
import FoodPlanRichTextEditorField from "app/food-plans/components/FoodPlanRichTextEditorField"
import useFoodPlanEditorForm, {
  FoodPlanEditorFormValues,
} from "app/food-plans/hooks/use-food-plan-editor-form"
import useEventCallback from "app/hooks/use-event-callback"
import makeAppStyles from "app/utils/makeAppStyles"
import {
  FoodPlanVersion,
  FoodPlanVersionGenerateFeed,
} from "types/food-plan-version"

const useStyles = makeAppStyles((theme) => ({
  root: {
    padding: theme.spacing(0.0),
    gap: 0,
  },
}))

export interface FoodPlanEditorCardProps {
  feed?: FoodPlanVersionGenerateFeed
  foodPlanVersion: FoodPlanVersion
  isGenerating?: boolean
  isRestarting?: boolean
  isStartingGenerate?: boolean
  onPatch?: (values: FoodPlanEditorFormValues) => Promise<void>
}

export function FoodPlanEditorCard({
  feed,
  foodPlanVersion,
  isGenerating,
  isRestarting,
  isStartingGenerate,
  onPatch,
}: FoodPlanEditorCardProps) {
  const classes = useStyles()
  const history = useHistory()
  const { patientId } = useParams<{ patientId: string }>()

  const methods = useFoodPlanEditorForm({
    defaultValues: foodPlanVersion.attributes,
    onPatch,
  })

  const isDisabled = isStartingGenerate || isGenerating || isRestarting

  /**
   * Submit handler for the "Save & Close" button.
   */
  const onSubmit = useEventCallback(
    async (values: FoodPlanEditorFormValues) => {
      if (!onPatch) {
        return
      }

      await onPatch(values)

      // Redirect to the patient detail page.
      history.push(UserPaths.PATIENT_DETAIL.replace(":patientId", patientId))
    }
  )

  return (
    <form onSubmit={methods.handleSubmit(onSubmit)}>
      <FoodPlanCard className={classes.root}>
        <FoodPlanEditorCardHeader
          downloadDisabled={methods.formState.isSubmitting || isDisabled}
          foodPlanVersion={foodPlanVersion}
          saveDisabled={methods.formState.isSubmitting || isDisabled}
        />

        <FoodPlanRichTextEditorField
          control={methods.control}
          disabled={isDisabled}
          feedContent={feed?.content}
          loading={isStartingGenerate || isGenerating}
          name="user_output"
          placeholder="Generated food plan will appear here."
          readOnly={isDisabled}
        />
      </FoodPlanCard>
    </form>
  )
}

export default function FoodPlanEditorCardWithKey(
  props: FoodPlanEditorCardProps
) {
  return (
    <FoodPlanEditorCard
      key={props.feed?.state || props.foodPlanVersion.id}
      {...props}
    />
  )
}
