import { useEffect, useMemo } from "react"

import { orderBy } from "lodash"
import { useHistory } from "react-router-dom"

import { Divider, Grid, Theme, useMediaQuery } from "@material-ui/core"

import AddWhiteIcon from "app/assets/icons/add-white.svg"
import CheckIcon from "app/assets/icons/blue-checkmark.svg"
import LabCompanyIcon from "app/assets/icons/lab-company.svg"
import { ReactComponent as CarrotRightIcon } from "app/assets/icons/labshops/carrot-right.svg"
import GlobeIcon from "app/assets/icons/labshops/globe.svg"
import PersonIcon from "app/assets/icons/labshops/person.svg"
import BackArrowIcon from "app/assets/images/storefront/back-arrow.svg"
import SampleTypePill from "app/components/SampleTypePill"
import BodyText from "app/components/design-system/BodyText"
import DesignSystemButton from "app/components/design-system/Button"
import useFeatureFlag, { FeatureFlag } from "app/hooks/use-feature-flag"
import {
  OrderIntent,
  StorefrontLabTestType,
  StorefrontLabTestTypeType,
  StorefrontSectionType,
  StorefrontType,
  useResourceByIdSelector,
  useResourceSelector,
} from "app/hooks/use-resource"
import { NJ_NY_RI_RESTRICTIONS_URL } from "app/main/settings/constants"
import { colors, navy, rupaTeal, titleFontFamily } from "app/theme"
import { formatDollars } from "app/utils"
import makeAppStyles from "app/utils/makeAppStyles"

import { calculateTotalTestPricing } from "../utils"
import { CardOptions, SelectedCatalogProduct } from "./PublicStorefront"
import AboutThisTest from "./components/AboutThisTest/AboutThisTest"
import HowItWorksCard from "./components/HowItWorksCard"
import HowItWorksCardMobile from "./components/HowItWorksCardMobile"
import OtherTests from "./components/OtherTests"
import ProductImage from "./components/ProductImage"
import StorefrontSupport from "./components/StorefrontSupport"

interface Props {
  storefront: StorefrontType
  cart: OrderIntent | null
  handleAddRemoveCart: (
    id: string,
    type: CardOptions,
    requestBody: any,
    alreadyAdded: boolean
  ) => void
  selectedProduct: SelectedCatalogProduct
}

const styles = (theme: Theme) => ({
  container: {
    width: "100%",
    display: "flex",
    "flex-direction": "column",
    background: "white",
    justifyContent: "center",
    alignItems: "center",
    "overflow-x": "hidden",
  },
  contentWrapper: {
    width: "90%",
    maxWidth: 1150,
    padding: "55px 0",
  },
  breadcrumbs: {
    color: colors.blueGray[400],
    lineHeight: "135%",
  },
  labShopBreadcrumb: {
    "&:hover": {
      textDecoration: "underline",
      cursor: "pointer",
    },
  },
  productOverviewContainer: {
    display: "flex",
    "flex-direction": "row",
    gap: 65,
    alignItems: "center",
    marginTop: 13,
    [theme.breakpoints.down("sm")]: {
      "flex-direction": "column",
      gap: 27,
    },
  },
  genericImageContainer: {
    display: "flex",
    "flex-flow": "row nowrap",
    "align-items": "center",
    "justify-content": "center",
    "text-align": "center",
    perspective: 1608,
    width: 700,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  labTestLogo: {
    position: "absolute" as any,
    height: "100%",
    transform:
      "rotateX(50deg) rotateY(0deg) rotateZ(33deg) translateX(46%) translate(-75%, 16%)",
    objectFit: "contain" as any,
    "max-width": "49%",
  },
  productOverviewText: {
    display: "flex",
    "flex-direction": "column",
    gap: 20,
    width: "100%",
  },
  productTitle: {
    fontSize: 30,
    fontFamily: titleFontFamily,
  },
  productInfoContainer: {
    display: "flex",
    "flex-direction": "row",
    justifyContent: "space-between",
    marginTop: 10,
    gap: 10,
  },
  sampleTypes: {
    display: "flex",
    gap: 4,
    "flex-wrap": "wrap",
    justifyContent: "end",
  },
  labCompanyName: {
    "text-transform": "uppercase",
    color: colors.blueGray[500],
    fontWeight: 600,
    lineHeight: "130%",
  },
  productPrice: {
    color: rupaTeal,
    fontSize: 24,
    fontWeight: 700,
    lineHeight: "140%",
    marginRight: 12,
  },
  productPriceDescription: {
    color: navy,
    fontSize: 14,
    lineHeight: "135%",
  },
  lightText: {
    color: colors.blueGray[500],
    lineHeight: "130%",
    fontSize: 13,
    marginLeft: 6,
  },
  howItWorksContainer: {
    background: colors.blueGray[50],
    width: "100%",
    display: "flex",
    "flex-direction": "column",
    justifyContent: "center",
    alignItems: "center",
  },
  aboutThisTestContainer: {
    width: "100%",
    display: "flex",
    "flex-direction": "column",
    justifyContent: "center",
    alignItems: "center",
    borderBottom: `1px solid ${colors.blueGray[200]}`,
  },
  genericContainer: {
    width: "100%",
    display: "flex",
    "flex-direction": "column",
    justifyContent: "center",
    alignItems: "center",
  },
  centered: {
    "text-align": "center",
  },
  productMinorInfo: {
    display: "flex",
    "flex-direction": "row",
    [theme.breakpoints.down("sm")]: {
      "flex-direction": "column",
      gap: 10,
    },
  },
  minorInfoText: {
    display: "flex",
    "flex-direction": "row",
    alignItems: "flex-start",
  },
  labTestColumn: {
    marginTop: 5,
  },
})

const useStyles = makeAppStyles(styles)

const PublicStorefrontSingleProduct = ({
  storefront,
  cart,
  handleAddRemoveCart,
  selectedProduct,
}: Props) => {
  const classes = useStyles()

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"))

  const history = useHistory()

  const [hidePhysServicesPricing] = useFeatureFlag(
    FeatureFlag.HidePhysicianServicesPricing
  )
  const [practitionerLicenseFlagEnabled] = useFeatureFlag(
    FeatureFlag.LabshopsUsePractitionerLicense
  )
  const usePractitionerLicense =
    practitionerLicenseFlagEnabled &&
    storefront.attributes.use_practitioner_license

  const clinic = useResourceByIdSelector(
    "clinic",
    storefront?.relationships.clinic?.data.id
  )
  const product = useResourceByIdSelector(
    "storefront_product",
    selectedProduct.type === "product" ? selectedProduct.id : ""
  )
  const labTest = useResourceByIdSelector(
    "lab_test",
    product ? product.relationships.lab_test.data?.id : selectedProduct.id
  )
  const bundle = useResourceByIdSelector(
    "lab_test_bundle",
    product ? product.relationships.bundle.data?.id : selectedProduct.id
  )

  const labTests = useResourceSelector(
    (entities) =>
      bundle?.relationships.lab_tests.data.map(
        ({ id }) => entities?.lab_test?.[id]
      ) || []
  ) as StorefrontLabTestType[]

  const labCompany = useResourceByIdSelector(
    "lab_company",
    labTest?.relationships.lab_company.data?.id
  )

  const labTestTypes: (StorefrontLabTestTypeType | undefined)[] =
    useResourceSelector((entities) => {
      if (!labTest?.relationships.lab_test_types) {
        return []
      }

      return labTest?.relationships?.lab_test_types.data.map((ref) => {
        return entities?.lab_test_type?.[ref.id]
      })
    })

  const customFee = useResourceByIdSelector(
    "custom_fee",
    product?.relationships.custom_fee?.data?.id
  )

  const sections: (StorefrontSectionType | undefined)[] = useResourceSelector(
    (entities) => {
      if (!storefront?.relationships.storefront_sections) {
        return []
      }

      return storefront?.relationships?.storefront_sections.data.map((ref) => {
        return entities?.storefront_section?.[ref.id]
      })
    }
  )

  const currentSection = sections.find((section) =>
    section?.relationships.storefront_products.data.find(
      (p) => p.id === product?.id
    )
  )

  const allProducts = useResourceSelector((entities) => {
    const productRelationships = sections?.flatMap(
      (s) => s?.relationships.storefront_products.data
    )
    if (!productRelationships.length) {
      return []
    }

    return productRelationships.reduce((result: any[], ref) => {
      if (ref) {
        result.push(entities?.storefront_product?.[ref.id])
      }
      return result
    }, [])
  })

  const productName = labTest
    ? labTest.attributes.name
    : bundle?.attributes.name
  const productDescription = labTest
    ? labTest?.attributes.patient_description
      ? labTest?.attributes.patient_description
      : labTest?.attributes.details
    : ""

  let totalTestPrice = 0
  if (bundle) {
    totalTestPrice = calculateTotalTestPricing(
      hidePhysServicesPricing || usePractitionerLicense,
      labTests,
      customFee
    )
  } else if (labTest) {
    totalTestPrice = calculateTotalTestPricing(
      hidePhysServicesPricing || usePractitionerLicense,
      [labTest],
      customFee
    )
  }

  const productType = product
    ? CardOptions.PRODUCT
    : labTest
    ? CardOptions.LAB_TEST
    : CardOptions.BUNDLE

  const handleBackToLabShop = () => {
    history.push({
      search: "",
    })
  }

  const onAddRemoveCart = async (type: CardOptions, id: string) => {
    const relationship = "storefront_products"
    const cartItemType = "storefront_product"

    const existingRelationships =
      cart?.relationships?.[relationship]?.data ||
      new Array<{ id: string; type: string }>()

    const alreadyAdded = existingRelationships.some(
      (data) => data.id === id && data.type === cartItemType
    )

    // If the resource is all ready added, filter it out, otherwise add to the
    // existing relationship
    const newRelationship = alreadyAdded
      ? existingRelationships.filter(
          (data) => data.id !== id && data.type === cartItemType
        )
      : [...existingRelationships, { id, type: cartItemType }]

    const requestBody = {
      data: {
        id: cart?.id,
        type: "order_intent",
        ...cart,
        relationships: {
          ...cart?.relationships,
          storefront_products: { data: newRelationship },
        },
      },
    }

    handleAddRemoveCart(id, type, requestBody, alreadyAdded)
  }

  let relationship = ""
  if (selectedProduct.type === "product") {
    relationship = "storefront_products"
  } else if (selectedProduct.type === "labTest") {
    relationship = "lab_tests"
  } else {
    relationship = "bundles"
  }

  const inCart = useMemo(() => {
    return Boolean(
      cart?.relationships?.[relationship]?.data.some(
        (data) => data.id === selectedProduct.id
      )
    )
  }, [cart?.relationships?.[relationship]?.data, selectedProduct.id])

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
  }, [selectedProduct])

  const showSmallImage = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  )

  const sortedLabTests = orderBy(
    labTests,
    (labTest) => labTest.attributes.name,
    ["asc"]
  )

  if (!labTest && !bundle) {
    history.replace({ search: "" })

    // We need to render empty and instead render the catalog list (in parent)
    return <></>
  }

  return (
    <div className={classes.container}>
      <div className={classes.contentWrapper}>
        <div className={classes.breadcrumbs}>
          <span
            className={classes.labShopBreadcrumb}
            onClick={handleBackToLabShop}
          >
            {storefront.attributes.name}
          </span>
          <CarrotRightIcon
            fill={colors.blueGray[400]}
            style={{
              marginLeft: 9,
              marginRight: 9,
              position: "relative",
              top: 1,
            }}
          />
          <span>{productName}</span>
        </div>
        <div className={classes.productOverviewContainer}>
          <ProductImage
            productId={selectedProduct.id}
            width={showSmallImage ? "100%" : "700px"}
            labshopLogo={storefront.attributes.logo}
          />

          <div className={classes.productOverviewText}>
            <div>
              <BodyText className={classes.productTitle} weight="semibold">
                {productName}
              </BodyText>
              <div className={classes.productInfoContainer}>
                <BodyText className={classes.labCompanyName}>
                  {labTest && (
                    <img
                      src={LabCompanyIcon}
                      alt="lab company icon"
                      style={{ marginRight: 6 }}
                    />
                  )}
                  {labTest ? labCompany?.attributes.name : "Custom Bundle"}
                </BodyText>
                <div className={classes.sampleTypes}>
                  {labTestTypes.map((ltt, idx) => (
                    <SampleTypePill
                      key={ltt?.id || idx}
                      name={ltt?.attributes.name}
                      style={{ marginLeft: 0, width: "fit-content" }}
                    />
                  ))}
                </div>
              </div>
            </div>

            <Divider />

            <div>
              <BodyText>{productDescription}</BodyText>
            </div>

            {Boolean(sortedLabTests.length) && (
              <div>
                <BodyText>
                  <span style={{ fontWeight: 600 }}>
                    This bundle includes:{" "}
                  </span>
                  <Grid container xs={12} md={12} alignItems="baseline">
                    <Grid
                      container
                      spacing={1}
                      xs={12}
                      md={6}
                      className={classes.labTestColumn}
                    >
                      {sortedLabTests
                        .slice(0, Math.ceil(sortedLabTests.length / 2))
                        .map((labTest) => (
                          <Grid item xs={12} md={12}>
                            <li>{labTest.attributes.name}</li>
                          </Grid>
                        ))}
                    </Grid>
                    <Grid
                      container
                      spacing={1}
                      xs={12}
                      md={6}
                      className={classes.labTestColumn}
                    >
                      {sortedLabTests
                        .slice(
                          Math.ceil(sortedLabTests.length / 2),
                          sortedLabTests.length
                        )
                        .map((labTest) => (
                          <Grid item xs={12} md={12}>
                            <li>{labTest.attributes.name}</li>
                          </Grid>
                        ))}
                    </Grid>
                  </Grid>
                </BodyText>
              </div>
            )}

            {product?.attributes.notes && (
              <div>
                <BodyText>
                  <span style={{ fontWeight: 600 }}>
                    From {clinic?.attributes.name}:{" "}
                  </span>
                  {product?.attributes.notes}
                </BodyText>
              </div>
            )}

            <div>
              <span className={classes.productPrice}>
                {formatDollars(totalTestPrice)}
              </span>
              {customFee && (
                <span className={classes.productPriceDescription}>
                  Price includes {customFee.attributes.name} going to{" "}
                  {clinic?.attributes.name}.
                </span>
              )}
            </div>

            <div>
              <DesignSystemButton
                color={inCart ? "secondary" : "primary"}
                className={classes.addToCartBtn}
                startIcon={inCart ? CheckIcon : AddWhiteIcon}
                onClick={() => onAddRemoveCart(productType, selectedProduct.id)}
                fullWidth={isMobile}
              >
                {inCart ? "In Cart" : "Add to Cart"}
              </DesignSystemButton>
            </div>

            <div className={classes.productMinorInfo}>
              {labTest && labTest.attributes.min_age >= 18 && (
                <div
                  className={classes.minorInfoText}
                  style={{ marginRight: 10 }}
                >
                  <img
                    src={PersonIcon}
                    style={{ position: "relative", top: 1 }}
                    alt="person icon"
                  />
                  <div className={classes.lightText}>Ages 18+</div>
                </div>
              )}

              <div className={classes.minorInfoText}>
                <img
                  src={GlobeIcon}
                  style={{ position: "relative", top: 2 }}
                  alt="globe icon"
                />
                <div className={classes.lightText}>
                  Rupa only sells tests within the United States. Some testing
                  is not available in NY, NJ or RI.{" "}
                  <a
                    href={NJ_NY_RI_RESTRICTIONS_URL}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Learn more here
                  </a>
                  .
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={classes.howItWorksContainer}>
        <div className={classes.contentWrapper}>
          {isMobile ? <HowItWorksCardMobile /> : <HowItWorksCard />}
        </div>
      </div>

      {labTest && (
        <div className={classes.aboutThisTestContainer}>
          <div className={classes.contentWrapper}>
            <AboutThisTest labTest={labTest} labCompany={labCompany} />
          </div>
        </div>
      )}

      <div className={classes.genericContainer}>
        <div className={classes.contentWrapper}>
          <OtherTests
            cart={cart}
            currentProductId={selectedProduct.id}
            onAddTest={onAddRemoveCart}
            currentSection={currentSection}
            allProducts={allProducts}
            labshopLogo={storefront.attributes.logo}
            hidePhysServicesPricing={
              hidePhysServicesPricing || usePractitionerLicense
            }
          />
        </div>
      </div>

      <div className={classes.genericContainer}>
        <div className={classes.contentWrapper}>
          <div className={classes.centered}>
            <DesignSystemButton
              color="text"
              onClick={handleBackToLabShop}
              startIcon={BackArrowIcon}
            >
              Return to LabShop
            </DesignSystemButton>
          </div>
        </div>
      </div>

      <div className={classes.genericContainer} id="faq">
        <div className={classes.contentWrapper}>
          <StorefrontSupport />
        </div>
      </div>
    </div>
  )
}

export default PublicStorefrontSingleProduct
