import { useEffect, useState } from "react"

import { InputAdornment, TextField } from "@material-ui/core"
import { Skeleton } from "@material-ui/lab"

import OutOfRangeIconInactive from "app/assets/icons/rupa-blood-dashboards/out-of-range-inactive.svg"
import { ReactComponent as SearchIcon } from "app/assets/icons/search-icon.svg"
import BodyText from "app/components/design-system/BodyText"
import { colors, navy } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"
import { BodySystem } from "types/body-system"

const useStyles = makeAppStyles(() => ({
  container: {
    display: "flex",
    flexDirection: "column",
  },
  textInput: {
    marginTop: 0,
    "& .MuiOutlinedInput-input": {
      fontSize: 14,
      background: "white",
      borderRadius: "6px",
    },
    "& .MuiOutlinedInput-input::placeholder": {
      color: colors.blueGray[500],
    },
    "& .MuiOutlinedInput-adornedStart": {
      background: "white",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: "6px",
      border: `1px solid ${colors.blueGray[200]}`,
      fontSize: 14,
    },
  },
  subheading: {
    color: colors.blueGray[400],
    lineHeight: "130%",
    marginTop: 19,
    marginBottom: 7,
  },
  sidebarItemContainer: {
    width: "100%",
    borderRadius: "8px",
    border: `2px solid rgba(0, 0, 0, 0)`,
    padding: 1.5,
    display: "flex",
    justifyContent: "center",
    "&:hover": {
      cursor: "pointer",
      background: colors.coolGray[200],
    },
  },
  sidebarItemContent: {
    display: "flex",
    background: "none",
    color: navy,
    padding: "8px 12px",
    borderRadius: "6px",
    width: "100%",
    alignItems: "center",
    gap: 10,
  },
  icon: {
    width: 17,
  },
  text: {
    color: navy,
    lineHeight: "initial",
  },
  sidebarItemContainerActive: {
    width: "100%",
    borderRadius: "8px",
    border: `2px solid ${navy}`,
    padding: 1.5,
    display: "flex",
    justifyContent: "center",
    "&:hover": {
      cursor: "pointer",
    },
  },
  sidebarItemContentActive: {
    display: "flex",
    background: navy,
    color: "white",
    padding: "8px 12px",
    borderRadius: "6px",
    width: "100%",
    alignItems: "center",
    gap: 10,
  },
  activeText: {
    color: "white",
    lineHeight: "initial",
  },
  loadingContainer: {
    display: "flex",
    flexDirection: "column",
    gap: 12,
  },
  loadingLinks: {
    display: "flex",
    flexDirection: "column",
    gap: 7,
  },
}))

interface Props {
  setSearchValue: (value: string) => void
  bodySystems: BodySystem[]
  abnormalResultsPresent: boolean
  isLoading: boolean
}

const BloodLabDashboardsPatientSidebar = ({
  setSearchValue,
  bodySystems,
  abnormalResultsPresent,
  isLoading,
}: Props) => {
  const classes = useStyles()

  const [hideOutOfRange, setHideOutOfRange] = useState(false)

  const scrollToSection = (tab: string) => {
    if (tab === "") {
      tab = "outOfRange"
    }

    const section = document.querySelector(`#${tab}`)

    if (section) {
      section.scrollIntoView({ behavior: "smooth", block: "start" })
    }
  }

  const handleSidebarTabClick = (tab: string) => {
    scrollToSection(tab)
  }

  const handleSearch = (value: string) => {
    setSearchValue(value)

    if (value !== "") {
      setHideOutOfRange(true)
    } else if (
      // TODO: Come back to this at a later date to see if it should be reintroduced - https://app.asana.com/0/1205298136487588/1205933524690249/f
      // Boolean(outOfOptimalRangeResults?.length) ||
      abnormalResultsPresent
    ) {
      setHideOutOfRange(false)
    }
  }

  useEffect(() => {
    if (
      // TODO: Come back to this at a later date to see if it should be reintroduced - https://app.asana.com/0/1205298136487588/1205933524690249/f
      // Boolean(outOfOptimalRangeResults?.length) ||
      abnormalResultsPresent
    ) {
      setHideOutOfRange(false)
    } else {
      setHideOutOfRange(true)
    }
  }, [isLoading])

  if (isLoading) {
    return (
      <div className={classes.loadingContainer}>
        <Skeleton animation="wave" height={50} width="100%" />

        <div className={classes.loadingLinks}>
          <Skeleton animation="wave" height={32} width="100%" variant="text" />
          <Skeleton animation="wave" height={32} width="100%" variant="text" />
          <Skeleton animation="wave" height={32} width="100%" variant="text" />
        </div>
      </div>
    )
  }

  return (
    <div className={classes.container}>
      <TextField
        variant="outlined"
        placeholder="Search for a marker"
        className={classes.textInput}
        margin="dense"
        fullWidth
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon width={16} height={16} fill={colors.blueGray[500]} />
            </InputAdornment>
          ),
        }}
        onChange={(e) => handleSearch(e.target.value)}
      ></TextField>

      {!hideOutOfRange && (
        <>
          <BodyText size="xs" weight="bold" className={classes.subheading}>
            SUMMARY
          </BodyText>
          {abnormalResultsPresent && (
            <SidebarItem
              icon={OutOfRangeIconInactive}
              title="Abnormal"
              onClick={() => handleSidebarTabClick("")}
            />
          )}
          {/* TODO: Come back to this at a later date to see if it should be reintroduced - https://app.asana.com/0/1205298136487588/1205933524690249/f */}
          {/* {Boolean(outOfOptimalRangeResults.length) && (
            <SidebarItem
              icon={OutOfRangeIconInactive}
              title="Outside Optimal Range"
              onClick={() => handleSidebarTabClick("outside-optimal-range")}
            />
          )} */}
        </>
      )}

      <BodyText size="xs" weight="bold" className={classes.subheading}>
        BODY SYSTEMS
      </BodyText>
      {bodySystems.map((bodySystem) => (
        <SidebarItem
          key={bodySystem.id}
          icon={bodySystem.attributes.logo || ""}
          title={bodySystem.attributes.name}
          onClick={() => handleSidebarTabClick(bodySystem.id)}
        />
      ))}
    </div>
  )
}

const SidebarItem = ({ icon, title, onClick }) => {
  const classes = useStyles()
  return (
    <div className={classes.sidebarItemContainer} onClick={onClick}>
      <div className={classes.sidebarItemContent}>
        <img src={icon} alt="body system icon" className={classes.icon} />
        <BodyText size="sm" className={classes.text}>
          {title}
        </BodyText>
      </div>
    </div>
  )
}

export default BloodLabDashboardsPatientSidebar
