import { ReactComponent as CalendarBlueIcon } from "app/assets/images/calendar-blue.svg"
import { ORDER_STATUS } from "app/constants.typed"
import useEventCallback from "app/hooks/use-event-callback"
import { PatientOrderEvent } from "app/main/patient-orders/constants"
import { trackPractitionerDashboardAction } from "app/services/segment.typed"
import { DashboardKit } from "types/dashboard/dashboard-kit"
import { DashboardOrder } from "types/dashboard/dashboard-order"

import ActionButton from "./ActionButton"

export interface ScheduleClinicConsultActionProps {
  kit: DashboardKit
  order: DashboardOrder
}

export default function ScheduleClinicConsultAction({
  kit,
  order,
}: ScheduleClinicConsultActionProps) {
  const disabled =
    order.attributes.cached_status === ORDER_STATUS.CANCELED ||
    !!kit.attributes.clinical_consult_unavailable_reason ||
    !kit.attributes.clinical_consult_url

  const onClick = useEventCallback(async () => {
    if (disabled) {
      return
    }

    window.open(kit.attributes.clinical_consult_url, "_blank")

    trackPractitionerDashboardAction(
      PatientOrderEvent.SCHEDULE_CONSULT,
      order.relationships.practitioner?.data.id as string
    )
  })

  return (
    <ActionButton
      disabled={disabled}
      onClick={onClick}
      tooltipTitle={
        disabled ? (
          <>
            {"Schedule a Clinical Consult"}
            {kit.attributes.clinical_consult_unavailable_reason && (
              <>
                <br />
                {kit.attributes.clinical_consult_unavailable_reason}
              </>
            )}
          </>
        ) : (
          "Schedule a Clinical Consult"
        )
      }
    >
      <CalendarBlueIcon
        fill="currentColor"
        height="100%"
        width="100%"
        viewBox="0 0 21 18"
      />
    </ActionButton>
  )
}
