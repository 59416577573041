import { useEffect, useState } from "react"

import classNames from "classnames"
import moment from "moment"
import sanitizeHtml from "sanitize-html"

import { Grid, makeStyles } from "@material-ui/core"

import { ReactComponent as KitIcon } from "app/assets/icons/kit-icon.svg"
import { ReactComponent as PaperPlaneIcon } from "app/assets/icons/paper-plane-grey.svg"
import { ReactComponent as OutlinedPaperPlaneIcon } from "app/assets/icons/paper-plane-outline.svg"
import { ReactComponent as CalendarIcon } from "app/assets/images/calendar-icon.svg"
import { ReactComponent as DownloadIcon } from "app/assets/images/download-icon.svg"
import BodyText from "app/components/design-system/BodyText"
import DesignSystemButton from "app/components/design-system/Button"
import { ORDER_STATUS } from "app/constants"
import { isDebug } from "app/settings"
import { colors, shadows } from "app/theme"
import {
  Order,
  OrderEvent,
  OrderEventActor,
  OrderEventKey,
  OrderEventType,
} from "app/types"

interface Props {
  order: Order
  event: OrderEvent
  onResend: (event: OrderEvent, eventType: OrderEventType) => void
}

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 19,
    paddingBottom: 19,
    borderBottom: `1px solid ${colors.blueGray[200]}`,
    "&:nth-last-child(1)": {
      borderBottom: "none",
    },
  },
  dateText: {
    display: "flex",
    alignItems: "center",
    maxWidth: "165px",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
      minWidth: "100%",
    },
  },
  messageText: {
    display: "flex",
    alignItems: "center",
    width: "auto",
    marginLeft: 40,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginLeft: 0,
    },
  },
  eventPill: {
    display: "inline-flex",
    background: colors.blueGray[200],
    borderRadius: "83px",
    padding: "4px 13px",
    marginRight: 8,
    marginTop: 9,
  },
  largeMargin: {
    marginRight: 10,
  },
  largeMarginTop: {
    marginTop: 3,
  },
  buttonContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "right",
    minWidth: "300px",
    maxWidth: "300px",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
      minWidth: "100%",
    },
  },
  buttonStyle: {
    display: "flex",
    alignItems: "self-end",
    boxShadow: shadows.sm,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  contentColumn: {
    width: "auto",
    marginLeft: 40,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginLeft: 0,
    },
  },
  inlineBlockText: {
    display: "inline-block",
    marginRight: 4,
  },
}))

// Statuses of an order that are "pending" - ie nothing has been shipped or ordered
const PENDING_STATUSES = [
  ORDER_STATUS.SCHEDULED,
  ORDER_STATUS.BLOCKED_MISSING_SIGNATURE,
  ORDER_STATUS.BLOCKED_PRACTITIONER_ACCOUNT_UNVERIFIED,
  ORDER_STATUS.PENDING_PAYMENT,
  ORDER_STATUS.PENDING_PHYSICIAN_AUTHORIZATION,
  ORDER_STATUS.PENDING_SHIPPING_INFO,
]

export default function OrderEventNew({ order, event, onResend }: Props) {
  const classes = useStyles()

  const [latestFollowUpDate, setLatestFollowUpDate] = useState<Date>()

  // Used for sanitization of urls within the dangerouslySetInnerHTML prop
  const allowedSchemes = ["https"].concat(isDebug ? ["localhost"] : [])

  let eventType = OrderEventType.EVENT
  let eventAction
  if (event.metadata.action === "send_kit") {
    eventType = OrderEventType.KIT
  } else if (event.metadata.action === "send_results") {
    eventType = OrderEventType.RESULTS
  } else if (event.metadata.action === "send_email") {
    eventType = OrderEventType.EMAIL
  } else if (event.metadata.action === "send_receipt") {
    eventType = OrderEventType.RECEIPT
  } else if (event.key === OrderEventKey.ORDER_PAID_PRACTITIONER) {
    eventType = OrderEventType.DOWNLOAD
    eventAction = "Download"
  } else if (event.webhook_event !== null) {
    eventType = OrderEventType.WEBHOOK
    eventAction = "Resend to EHR"
  }

  if (!eventAction) {
    eventAction = `Resend ${eventType}`
  }

  // Check if results have been released to the patient; if they have we can resend them
  const hasResultBeenReleasedToPatient = (): boolean => {
    const orderedTestId = event.metadata.ordered_test_id
    const orderedTest = order.ordered_tests.find(
      (ot) => ot.id === orderedTestId
    )

    if (orderedTest && orderedTest.date_results_sent_to_patient) {
      return true
    }

    return false
  }

  // Determine whether or not to show the resend CTA button
  let showEventResendButton = true
  if (
    eventType === OrderEventType.KIT &&
    (order.status === ORDER_STATUS.COMPLETE ||
      order.status === ORDER_STATUS.CANCELED)
  ) {
    showEventResendButton = false
  } else if (
    (event.key === OrderEventKey.WELCOME_EMAIL_AND_INVOICE ||
      event.key === OrderEventKey.WELCOME_EMAIL_AND_CONSENT_FORM_EMAIL_SENT) &&
    !PENDING_STATUSES.some((pendingStatus) => pendingStatus === order.status)
  ) {
    showEventResendButton = false
  } else if (
    (event.key === OrderEventKey.KIT_SENT_TO_PATIENT ||
      event.key === OrderEventKey.ORDER_CHEATSHEET_SENT) &&
    order.total_completed_ordered_tests > 0
  ) {
    showEventResendButton = false
  } else if (
    event.key === OrderEventKey.RESULTS_SENT_TO_PATIENT &&
    !hasResultBeenReleasedToPatient()
  ) {
    showEventResendButton = false
  }

  const getLatestFollowUpDate = (): Date => {
    let latestDate

    if (event.metadata.next_follow_up) {
      latestDate = new Date(event.metadata.next_follow_up)
    }

    event.children?.forEach((childEvent) => {
      if (childEvent.metadata.next_follow_up) {
        latestDate = new Date(childEvent.metadata.next_follow_up)
      }
    })

    return latestDate
  }
  useEffect(() => {
    if (
      (event.key === OrderEventKey.WELCOME_EMAIL_AND_INVOICE ||
        event.key ===
          OrderEventKey.WELCOME_EMAIL_AND_CONSENT_FORM_EMAIL_SENT) &&
      !latestFollowUpDate
    ) {
      setLatestFollowUpDate(getLatestFollowUpDate())
    }
  }, [])

  let eventText = (
    <BodyText
      className={classes.inlineBlockText}
      dangerouslySetInnerHTML={{
        __html: sanitizeHtml(event.text, {
          allowedSchemes: allowedSchemes,
        }),
      }}
      weight="semibold"
    ></BodyText>
  )

  if (
    eventType === OrderEventType.WEBHOOK &&
    event.webhook_event.status === "error"
  ) {
    eventText = (
      <BodyText className={classes.inlineBlockText} weight="semibold">
        {`There was an issue sending ${event.lab_test_names} results to ${event.oauth_application_name}.`}
      </BodyText>
    )
  }

  return (
    <Grid container spacing={2} className={classes.root}>
      <Grid item sm className={classes.dateText}>
        <div className="flex items-center">
          <CalendarIcon
            viewBox="0 0 16 16"
            className={classNames([
              classes.largeMargin,
              classes.largeMarginTop,
            ])}
            width={16}
            height={16}
          />
          <BodyText weight="semibold">
            {moment(event?.display_date).format("MMMM DD, YYYY")}
          </BodyText>
        </div>
      </Grid>
      <Grid
        item
        sm
        className={
          !event.children?.length ? classes.messageText : classes.contentColumn
        }
      >
        <div>
          {eventText}
          {latestFollowUpDate && (
            <BodyText weight="regular" className={classes.inlineBlockText}>
              {`The next reminder will be automatically sent on ${moment(
                latestFollowUpDate
              ).format("MMMM D")}.`}
            </BodyText>
          )}
        </div>
        <div>
          {event.children?.map((childEvent) => (
            <div className={classes.eventPill} key={childEvent.id}>
              {eventType === OrderEventType.EMAIL ? (
                <PaperPlaneIcon
                  viewBox="0 0 15 15"
                  className={classNames([
                    classes.largeMargin,
                    classes.largeMarginTop,
                  ])}
                  width={16}
                />
              ) : (
                <KitIcon
                  viewBox="0 0 15 15"
                  className={classNames([
                    classes.largeMargin,
                    classes.largeMarginTop,
                  ])}
                  width={16}
                />
              )}
              <BodyText size="sm">{`${eventType} ${
                childEvent.actor === OrderEventActor.PRACTITIONER
                  ? "manually"
                  : "automatically"
              } resent on ${moment(childEvent?.display_date).format(
                "MMMM DD, YYYY"
              )}${
                eventType === OrderEventType.KIT &&
                childEvent.metadata.reason_for_resend
                  ? ` – ${childEvent.metadata.reason_for_resend}`
                  : ""
              }`}</BodyText>
            </div>
          ))}
        </div>
      </Grid>
      {eventType !== OrderEventType.EVENT && showEventResendButton && (
        <Grid item sm className={classes.buttonContainer}>
          <DesignSystemButton
            color="secondary"
            size="small"
            className={classes.buttonStyle}
            onClick={() => onResend(event, eventType)}
          >
            {eventType === OrderEventType.DOWNLOAD ? (
              <DownloadIcon
                viewBox="0 0 16 16"
                className={classes.largeMargin}
              />
            ) : (
              <OutlinedPaperPlaneIcon
                viewBox="0 0 16 16"
                className={classes.largeMargin}
              />
            )}
            <span>{eventAction}</span>
          </DesignSystemButton>
        </Grid>
      )}
    </Grid>
  )
}
