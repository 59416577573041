import { API } from "app/api"
import { handleApiError } from "app/utils"

export const GET_PAYMENT_CARDS_REQUEST =
  "[PAYMENT CARD] GET PAYMENT CARDS REQUEST"
export const GET_PAYMENT_CARDS_SUCCESS =
  "[PAYMENT CARD] GET PAYMENT CARDS SUCCESS"
export const GET_PAYMENT_CARDS_FAILURE =
  "[PAYMENT CARD] GET PAYMENT CARDS FAILURE"

export const ADD_PAYMENT_CARD = "[PAYMENT CARD] ADD PAYMENT CARD"
export const UPDATE_PAYMENT_CARD = "[PAYMENT CARD] UPDATE PAYMENT CARD"
export const DELETE_PAYMENT_CARD = "[PAYMENT CARD] DELETE PAYMENT CARD"
export const SET_USE_CARD_CHECKED = "[PAYMENT CARD] SET USE CARD CHECKED"

export function getPaymentCards() {
  return (dispatch) => {
    dispatch({ type: GET_PAYMENT_CARDS_REQUEST })

    API.PaymentCard.getList()
      .then((response) =>
        dispatch({
          type: GET_PAYMENT_CARDS_SUCCESS,
          payload: response.data,
        })
      )
      .catch((error) => {
        dispatch({ type: GET_PAYMENT_CARDS_FAILURE })
        dispatch(handleApiError(error))
      })
  }
}

export function addCard(paymentMethodId, isSharedWithClinic = false) {
  return async (dispatch, getState) => {
    try {
      const response = await API.PaymentCard.post(
        paymentMethodId,
        isSharedWithClinic
      )

      dispatch({
        type: ADD_PAYMENT_CARD,
        payload: response.data,
      })

      return response.data
    } catch (error) {
      dispatch(handleApiError(error))

      // rethrow error to catch above
      throw error
    }
  }
}

export function deleteCard(id) {
  return async (dispatch, getState) => {
    try {
      await API.PaymentCard.delete(id)

      dispatch({
        type: DELETE_PAYMENT_CARD,
        payload: id,
      })
    } catch (error) {
      dispatch(handleApiError(error))
    }
  }
}

export function setUseCardChecked(checked) {
  return (dispatch) => {
    dispatch({
      type: SET_USE_CARD_CHECKED,
      payload: checked,
    })
  }
}

export function shareCard(id, is_shared_with_clinic) {
  return async (dispatch, getState) => {
    try {
      const response = await API.PaymentCard.patch(id, {
        is_shared_with_clinic,
      })

      dispatch({
        type: UPDATE_PAYMENT_CARD,
        payload: response.data,
      })
    } catch (error) {
      dispatch(handleApiError(error))
    }
  }
}
