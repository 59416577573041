import { useState } from "react"

import {
  makeStyles,
  Grid,
  Divider,
  useMediaQuery,
  Theme,
} from "@material-ui/core"

import { ReactComponent as GlobeIcon } from "app/assets/icons/minimal-globe.svg"
import PractitionerWithStethoscope from "app/assets/icons/practitioner-stethoscope.svg"
import FindAPractitionerPreview from "app/assets/images/find-a-practitioner/find-a-practitioner-preview.svg"
import Loading from "app/components/Loading"
import BodyText from "app/components/design-system/BodyText"
import { VENDOR_PHYSICIAN_AUTHORIZATION_LABEL } from "app/constants"
import {
  PractitionerProfileApplicationStatus,
  PractitionerProfilePublishingStatus,
} from "app/constants.typed"
import useAppSelector from "app/hooks/useAppSelector"
import { rupaTeal, colors, navy, primaryColor } from "app/theme"

import { PractitionerHighlightedServices } from "./PractitionerHighlightedServices"
import PractitionerProfileForm from "./PractitionerProfileForm"
import ProfileToolbar from "./ProfileToolbar"
import { PauseProfileButton } from "./form-components/PauseProfileButton"
import ProfileStatusCard from "./header-components/ProfileStatusCard"
import usePractitionerProfile from "./hooks/use-practitioner-profile"
import useProfileApplication from "./hooks/use-profile-application"

const useStyles = makeStyles((theme) => ({
  container: {
    padding: "27px 32px",
  },
  headerContainer: {
    display: "flex",
    "flex-direction": "row",
    width: "100%",
    alignItems: "center",
    marginBottom: 37,
    [theme.breakpoints.down("md")]: {
      marginBottom: theme.spacing(2),
    },
  },
  iconContainer: {
    marginRight: 10,
    minWidth: 50,
  },
  description: {
    maxWidth: 430,
  },
  contentContainer: {
    marginTop: 45,
    [theme.breakpoints.down("md")]: {
      marginTop: theme.spacing(2),
    },
  },
  clockIcon: {
    height: "100%",
    marginBottom: "auto",
    marginRight: "4px",
  },
  reviewStatusContainer: {
    display: "flex",
    padding: "14px 13px",
    height: "72px",
    background: colors.lightBlue[50],
    borderRadius: "8px",
    border: `1px solid ${rupaTeal}`,
  },
  reviewTitle: {
    marginBottom: 4,
    color: rupaTeal,
  },
  reviewDescription: {
    color: rupaTeal,
  },
  previewImage: {
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      maxHeight: 300,
    },
  },
  sectionDivider: {
    marginTop: 34,
    marginBottom: 34,
  },
  staffContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "white",
    borderRadius: "8px",
    gap: "10px",
    padding: "38px 15px",
  },
  textContainer: {
    justifyContent: "center",
    textAlign: "center",
    maxWidth: "50%",
    color: navy,
    [theme.breakpoints.down("xs")]: {
      maxWidth: "100%",
    },
    "& u,a": {
      color: primaryColor,
    },
  },
  bodyText: {
    marginBottom: 30,
  },
  globeIcon: {
    marginBottom: 15,
  },
  showOnMdUp: {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
}))

function PractitionerProfile() {
  const classes = useStyles()
  const practitioner = useAppSelector(({ practitioner }) => practitioner)
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"))

  const {
    practitionerProfile,
    isPractitionerProfileLoading,
    isPatching,
    mutatePractitionerProfile,
    pauseProfile,
  } = usePractitionerProfile()

  const [isMutatingProfile, setIsMutatingProfile] = useState(false) // workaround for not being available via swr hook

  const profileApplicationId =
    practitionerProfile?.relationships?.application?.data?.id

  const { profileApplication } = useProfileApplication(profileApplicationId)

  if (
    practitioner.is_clinic_staff === null ||
    practitioner.is_clinic_staff === undefined
  ) {
    return null
  }

  const tutorialOnly =
    practitioner.is_clinic_staff ||
    (!practitioner.has_valid_license &&
      !practitioner.vendor_physician_authorization_enabled)

  const noLicense =
    !practitioner.has_valid_license &&
    !practitioner.vendor_physician_authorization_enabled

  const isStaff = practitioner.is_clinic_staff

  const staffPage = (
    <div className={classes.staffContainer}>
      <div className={classes.textContainer}>
        <GlobeIcon className={classes.globeIcon} />
        <BodyText weight="semibold" className={classes.bodyText}>
          Rupa's{" "}
          <u>
            <a href="https://www.rupahealth.com/find-a-practitioner">
              Find a Practitioner
            </a>
          </u>{" "}
          directory connects patients with qualified practitioners to support
          their wellness journey.
        </BodyText>
        <BodyText>
          Right now clinic staff members can't sign up on behalf of
          practitioners. Please share this page with your practitioner!
        </BodyText>
      </div>
    </div>
  )

  const noLicensePage = (
    <div className={classes.staffContainer}>
      <div className={classes.textContainer}>
        <GlobeIcon className={classes.globeIcon} />
        <BodyText weight="semibold" className={classes.bodyText}>
          Rupa's{" "}
          <u>
            <a href="https://www.rupahealth.com/find-a-practitioner">
              Find a Practitioner
            </a>
          </u>{" "}
          directory connects patients with qualified practitioners to support
          their wellness journey.
        </BodyText>
        <BodyText>
          Please{" "}
          <u>
            <a href="/onboarding">
              upload a copy of your license or enable for
              {""}
              {VENDOR_PHYSICIAN_AUTHORIZATION_LABEL}
            </a>
          </u>{" "}
          in order to create your Find a Practitioner page.
        </BodyText>
      </div>
    </div>
  )

  const applicationIsApproved =
    profileApplication?.attributes.status ===
    PractitionerProfileApplicationStatus.APPROVED

  const showPauseButton =
    applicationIsApproved &&
    (practitionerProfile?.attributes.publishing_status ===
      PractitionerProfilePublishingStatus.PUBLISHED ||
      practitionerProfile?.attributes.publishing_status ===
        PractitionerProfilePublishingStatus.DIRTY)

  return (
    <Grid container>
      <>
        <Grid item xs={12} md={12}>
          <ProfileToolbar
            profile={practitionerProfile}
            mutateProfile={mutatePractitionerProfile}
            isPatchingProfile={
              isPatching || isPractitionerProfileLoading || isMutatingProfile
            }
            application={profileApplication}
            tutorialOnly={tutorialOnly}
          />
          <div className={classes.container}>
            {isStaff ? (
              staffPage
            ) : noLicense ? (
              noLicensePage
            ) : (
              <>
                <Grid container>
                  <Grid
                    item
                    xs={12}
                    md={7}
                    style={{ paddingRight: isMobile ? 0 : 20 }}
                  >
                    <div className={classes.headerContainer}>
                      <div className={classes.iconContainer}>
                        <img
                          src={PractitionerWithStethoscope}
                          alt="practitioner with stethoscope"
                        />
                      </div>
                      <div>
                        <BodyText size="lg" weight="semibold">
                          Customize Your Profile
                        </BodyText>
                        <BodyText size="sm" className={classes.description}>
                          Personalize your practitioner profile to highlight
                          your expertise and services, making it easier for
                          clients to find the right match.
                        </BodyText>
                      </div>
                    </div>
                  </Grid>
                  {!!profileApplicationId ? (
                    <ProfileStatusCard
                      profile={practitionerProfile}
                      application={profileApplication}
                    />
                  ) : null}
                </Grid>
                <Divider />
                <Grid container className={classes.contentContainer}>
                  {isPractitionerProfileLoading ? (
                    <Loading ariaLabel="practitioner profile loading" />
                  ) : (
                    <>
                      <Grid
                        item
                        xs={12}
                        lg={7}
                        style={{ paddingRight: isMobile ? 0 : 67 }}
                      >
                        <PractitionerProfileForm
                          profile={practitionerProfile}
                          mutateProfile={mutatePractitionerProfile}
                          setIsMutatingProfile={setIsMutatingProfile}
                        />
                      </Grid>
                      <Grid item xs={12} md={5} className={classes.showOnMdUp}>
                        <img
                          src={FindAPractitionerPreview}
                          alt="find a practitioner preview"
                          className={classes.previewImage}
                        />
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <Divider className={classes.sectionDivider} />
                        <PractitionerHighlightedServices
                          mutateProfile={mutatePractitionerProfile}
                        />
                      </Grid>
                      {showPauseButton && (
                        <Grid item xs={12} md={7}>
                          <PauseProfileButton
                            pauseProfile={pauseProfile}
                            isPausing={isPatching}
                          />
                        </Grid>
                      )}
                    </>
                  )}
                </Grid>
              </>
            )}
          </div>
        </Grid>
      </>
    </Grid>
  )
}

export default PractitionerProfile
