import { faSquareQuestion } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button, cn } from "@rupahealth/design"

import useTrendsAboutOptimalRangesModal from "../hooks/use-trends-about-optimal-ranges-modal"

const TrendsAboutOptimalRangesButton = ({
  optimalRanges,
  practitionerView = false,
}: {
  optimalRanges: string[]
  practitionerView?: boolean
}) => {
  const trendsAboutOptimalRangesModal = useTrendsAboutOptimalRangesModal()

  return (
    <Button
      variant={null}
      className={cn(
        "w-full h-auto text-[15px] text-primary fill-primary py-1 px-2",
        practitionerView ? "hover:bg-slate-100" : "hover:bg-slate-200"
      )}
      onClick={() => {
        trendsAboutOptimalRangesModal.show({
          onClose: () => trendsAboutOptimalRangesModal.remove(),
          optimalRanges: optimalRanges,
          practitionerView: practitionerView,
        })
      }}
    >
      <FontAwesomeIcon className="mr-2" icon={faSquareQuestion} />
      Legend
    </Button>
  )
}

export default TrendsAboutOptimalRangesButton
