import { useState } from "react"

import { isEmpty } from "lodash"
import { useMount } from "react-use"

import { Button, Collapse } from "@material-ui/core"
import Grid from "@material-ui/core/Grid"
import Link from "@material-ui/core/Link"
import Typography from "@material-ui/core/Typography"
import { cn } from "@rupahealth/design"

import barChartIcon from "app/assets/icons/bar-chart.svg"
import boxIcon from "app/assets/icons/box.svg"
import AlertTriangleRed from "app/assets/images/alert-triangle-red.svg"
import expandCaretIcon from "app/assets/images/expand-caret.svg"
import SampleTypePill from "app/components/SampleTypePill"
import Tooltip from "app/components/Tooltip"
import BodyText from "app/components/design-system/BodyText"
import Underline from "app/components/design-system/Underline"
import { PatientPortalOrder, PatientPortalOrderLineItem } from "app/types"
import { formatDollars } from "app/utils"

import { RemoveLineItemButton } from "./sections/order-details/RemoveLIneItemButton"
import { TestLineItemContainer } from "./sections/order-details/TestLineItemContainer"

export function LabTestLineItem({
  lineItem,
  showPrice,
  hideDiscount,
  isLabshopsOrder,
  isEditing,
  updatingOrderedTestIds,
  isUpdatingOrder,
  enableLineItems,
  disableLineItems,
}: {
  lineItem: PatientPortalOrderLineItem
  showPrice: boolean
  hideDiscount: boolean
  isLabshopsOrder: PatientPortalOrder["is_labshops_order"]
  isEditing: boolean
  updatingOrderedTestIds: string[]
  isUpdatingOrder: boolean
  enableLineItems: (orderedTestIds: string[]) => void
  disableLineItems: (orderedTestIds: string[]) => void
}) {
  const [expanded, setExpanded] = useState(false)

  const orderedTest = lineItem.ordered_test
  const labTest = orderedTest?.lab_test

  const onDetailsClick = (event) => {
    setExpanded(!expanded)
  }

  // Keep the tab expanded on mount if there is a shipping alert
  useMount(() => {
    if (labTest?.active_shipping_alert && !expanded) {
      setExpanded(true)
    }
  })

  if (!labTest) {
    return null
  }

  const disabled = Boolean(lineItem.date_disabled)

  return (
    <TestLineItemContainer
      lineItem={lineItem}
      isEditing={isEditing}
      disabled={disabled}
      ariaLabel={labTest?.name}
    >
      <Grid container item className="px-5 pt-5">
        <Grid item xs={8} className={cn({ "opacity-disabled": disabled })}>
          <LabTestName
            name={lineItem.title}
            labCompany={labTest.lab_company.name}
          />
        </Grid>
        <Grid item xs={4}>
          {showPrice && (
            <div className={cn({ "opacity-disabled": disabled })}>
              <LabTestPrice
                cost={
                  hideDiscount && lineItem.discounted_cost
                    ? lineItem.discounted_cost
                    : lineItem.cost
                }
                discountedCost={hideDiscount ? null : lineItem.discounted_cost}
              />
            </div>
          )}
          {isEditing && (
            <div className="flex flex-col justify-center items-end mt-0">
              <RemoveLineItemButton
                removing={!disabled}
                disabled={isUpdatingOrder}
                orderedTestIds={[String(lineItem.ordered_test?.id)]}
                enableLineItems={enableLineItems}
                disableLineItems={disableLineItems}
                updatingOrderedTestIds={updatingOrderedTestIds}
              />
            </div>
          )}
        </Grid>
      </Grid>

      <Grid
        container
        item
        className={cn("px-5 pb-5 mt-4", { "opacity-disabled": disabled })}
        alignItems="flex-end"
      >
        <Grid item xs={8}>
          <LabTestType labTest={labTest} />
        </Grid>
        <Grid item xs={4}>
          {(!isEmpty(labTest.details) ||
            !isEmpty(labTest.url) ||
            !isEmpty(labTest.rupa_url)) && (
            <LabTestDetailsButton
              expanded={expanded}
              onClick={onDetailsClick}
            />
          )}
        </Grid>
      </Grid>

      <Collapse in={expanded}>
        <LabTestDetails orderedTest={orderedTest} />
      </Collapse>
    </TestLineItemContainer>
  )
}

function LabTestPrice({ cost, discountedCost }) {
  if (discountedCost) {
    return (
      <div className="flex flex-row justify-center items-end">
        <Typography className="text-gray-400 line-through font-bold text-base15 ml-1.5">
          {formatDollars(cost)}
        </Typography>
        <Typography className="text-gray-800 font-bold text-base15 ml-1.5">
          {formatDollars(discountedCost)}
        </Typography>
      </div>
    )
  }

  return (
    <div className="flex justify-end items-center">
      <Typography className="text-gray-800 font-bold text-base15 ml-1.5">
        {formatDollars(cost)}
      </Typography>
    </div>
  )
}

function LabTestName({ name, labCompany, disabled = false }) {
  return (
    <div className="flex flex-col">
      <Typography
        className={cn(
          "text-gray-800 font-bold text-base15",
          disabled ? "line-through" : ""
        )}
      >
        {name}
      </Typography>
      <Typography className="text-gray-700 font-semibold text-base15">
        {labCompany}
      </Typography>
    </div>
  )
}

export function LabTestType({ labTest }) {
  return (
    <div className="flex flex-col items-start">
      <div className="flex">
        {labTest.lab_test_types.map((type) => {
          return (
            <SampleTypePill
              name={type.name}
              key={type.name}
              style={{
                marginLeft: 0,
                marginRight: 2,
              }}
            />
          )
        })}
      </div>
    </div>
  )
}

export function LabTestDetailsButton({ expanded, onClick }) {
  return (
    <div className="flex items-center justify-end">
      <Button color="primary" onClick={onClick} style={{ padding: 0 }}>
        Details
        <img
          src={expandCaretIcon}
          className="ml-1"
          alt={""}
          style={{ transform: `rotate(${expanded ? "0deg" : "180deg"})` }}
        />
      </Button>
    </div>
  )
}

export function LabTestDetails({ orderedTest }) {
  const labTest = orderedTest.lab_test

  const shippingMin = orderedTest.shipping_days_min
  const shippingMax = orderedTest.shipping_days_max
  const estimatedDaysForResult = labTest.estimated_days_for_results
  const activeShippingAlert = labTest.active_shipping_alert
  const detailsUrl = labTest.rupa_url || labTest.url
  const hasPatientDescription =
    labTest.patient_description && labTest.patient_description.length > 0

  let shippingTime = (
    <div className="flex flex-row items-center">
      <img alt="" src={boxIcon} className="mr-3" />
      <Typography className="font-semibold text-sm">{`${shippingMin}-${shippingMax} Business Days`}</Typography>
    </div>
  )

  if (activeShippingAlert) {
    shippingTime = (
      <Tooltip
        interactive
        title={
          <BodyText>
            {activeShippingAlert.patient_facing_issue_description}
          </BodyText>
        }
        placement="bottom"
        arrow
      >
        <Underline>
          {shippingTime}
          <img src={AlertTriangleRed} alt="Results delay" className="ml-1" />
        </Underline>
      </Tooltip>
    )
  }

  return (
    <div
      className="rounded-b-lg p-5 border-gray-300 border-t"
      style={{ backgroundColor: "#F9FAFB" }}
    >
      <div className="flex flex-row mb-4">
        {Boolean(shippingMin) && Boolean(shippingMax) && (
          <div className="flex-1 mr-2">
            <Typography className="text-xs mb-2">Shipping</Typography>
            {shippingTime}
          </div>
        )}
        {Boolean(estimatedDaysForResult) && (
          <div className="flex-1">
            <Typography className="text-xs">Avg. Sample Processing</Typography>
            <div className="flex flex-row items-center mt-2">
              <img alt="" src={barChartIcon} className="mr-3" />
              <Typography className="font-semibold text-sm">{`${estimatedDaysForResult} Business Days`}</Typography>
            </div>
          </div>
        )}
      </div>
      {hasPatientDescription ? labTest.patient_description : labTest.details}
      {detailsUrl && (
        <Link
          href={detailsUrl}
          target="_blank"
          className="ml-1"
          style={{ textDecoration: "none" }}
        >
          More Information →
        </Link>
      )}
    </div>
  )
}
