import useEventCallback from "app/hooks/use-event-callback"
import useHandleApiError from "app/hooks/use-handle-api-error"
import { UseResultsVisualizerResourcesHook } from "app/results-summary/hooks/use-results-summary-resources"
import { sendPatchInterpretation } from "app/results-summary/utils/api"
import { ResultsInterpretationPatch } from "types/results-interpretation"

export default function usePatchInterpretation({
  identifier,
  mutate,
}: UseResultsVisualizerResourcesHook["interpretationSwr"]) {
  const handleApiError = useHandleApiError()
  return useEventCallback(async (patch: ResultsInterpretationPatch) => {
    if (!identifier) {
      return
    }

    const sendPatchRequest = async () => {
      try {
        return sendPatchInterpretation(identifier, patch)
      } catch (error) {
        handleApiError(error)

        // re-throw
        throw error
      }
    }

    return mutate(sendPatchRequest, {
      revalidate: false,
    })
  })
}
