import { cn } from "@rupahealth/design"

import BodyText from "app/components/design-system/BodyText"

const ResultsUploadStatusBanner = ({
  containerClassName,
  textColor,
  icon,
  title,
  text,
}) => {
  return (
    <div className={cn("flex gap-[8px] w-full rounded-lg", containerClassName)}>
      {icon}
      <div className="flex flex-col">
        {title && (
          <BodyText weight="semibold" className={textColor}>
            {title}
          </BodyText>
        )}
        <BodyText className={textColor}>{text}</BodyText>
      </div>
    </div>
  )
}

export default ResultsUploadStatusBanner
