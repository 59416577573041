import { styled } from "@material-ui/core"

import { colors } from "app/theme"

const Underline = styled("div")({
  display: "inline-flex",
  alignItems: "center",
  borderBottom: `2px dotted ${colors.blueGray[400]}`,
})

export default Underline
