import { Marker } from "react-map-gl"

import { makeStyles } from "@material-ui/core"

import { ReactComponent as MapMarker } from "app/assets/icons/map-marker.svg"
import MapPhlebBoth from "app/assets/icons/map-phleb-both.svg"
import MapPhlebClinic from "app/assets/icons/map-phleb-clinic.svg"
import MapPhlebMobile from "app/assets/icons/map-phleb-mobile.svg"
import { colors, maroon } from "app/theme"
import "mapbox-gl/dist/mapbox-gl.css"

export const markerTypeMapping = {
  Clinic: [maroon, MapPhlebClinic],
  Mobile: [colors["teal"][500], MapPhlebMobile],
  "Clinic, Mobile": [colors["indigo"][500], MapPhlebBoth],
}

interface PhlebotomistMapMarkerProps {
  latitude: number
  longitude: number
  onClick: () => void
  type: string
}

/**
 * Map marker to be placed on Mapbox map for the searchable phlebotomy map
 *
 * This component is meant to be rendered within the PhlebotomistMap
 *
 * @component
 */
const PhlebotomistMapMarker = ({
  latitude,
  longitude,
  onClick,
  type,
}: PhlebotomistMapMarkerProps) => {
  const classes = useStyles()

  return (
    <>
      <Marker
        latitude={latitude}
        longitude={longitude}
        offsetLeft={-20}
        offsetTop={-55}
        draggable={false}
        onClick={onClick}
      >
        <MapMarker
          className={classes.styledMapMarker}
          fill={markerTypeMapping[type][0]}
        />
        <img
          src={markerTypeMapping[type][1]}
          alt=""
          className={classes.styledMapMarkerTypeImage}
        />
      </Marker>
    </>
  )
}

const useStyles = makeStyles((theme) => ({
  styledMapMarker: {
    cursor: "pointer",
    userDrag: "none",
    width: 40,
  },
  styledMapMarkerTypeImage: {
    position: "absolute",
    width: 17,
    top: 9.5,
    left: 9.5,
    cursor: "pointer",
    userDrag: "none",
  },
}))

export default PhlebotomistMapMarker
