import _ from "lodash"

export function hasInstantRequisitions(order) {
  return _.some(
    order && order.ordered_tests,
    (ordered_test) => ordered_test.instant_requisition
  )
}

export function onlyInstantRequisitions(order) {
  return _.every(
    order && order.ordered_tests,
    (ordered_test) => ordered_test.instant_requisition
  )
}
