// Safely open a new window with the given URL
export const openInNewTab = (url: string): void => {
  const newWindow = window.open(url)
  if (newWindow) newWindow.opener = null
}

export const onClickUrl =
  (url: string | undefined): (() => void) | undefined =>
  () =>
    url ? openInNewTab(url) : undefined
