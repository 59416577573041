import axios from "axios"
import { filter, some, isEmpty, uniq, sum } from "lodash"

import { ORDER_LINE_ITEM_TYPE } from "app/constants"
import {
  AnyOrderLineItem,
  Coupon,
  LabTest,
  Order,
  OrderedTest,
  OrderLineItem,
  PatientOrdersOrder,
  PatientPortalOrder,
  PatientPortalOrderLineItem,
  ResendOrderEventBody,
} from "app/types"
import { getApiBaseUrl } from "app/utils"

export function checkHasHandfilledRequisitionForms(
  order: Order | PatientOrdersOrder
): boolean {
  return (
    Boolean(order.practitioner?.clinic.allow_handfilled_requisition_forms) &&
    some(order.ordered_tests, (ordered_test) => {
      return (
        ordered_test.instant_requisition &&
        ordered_test.lab_test.has_handfilled_requisition_forms
      )
    })
  )
}

export function getHandfilledRequisitionFormOrderedTests(
  order: Order | PatientOrdersOrder
): Array<OrderedTest> {
  if (!order.practitioner?.clinic.allow_handfilled_requisition_forms) {
    return []
  }
  return filter(order.ordered_tests, (ordered_test) => {
    return (
      ordered_test.instant_requisition &&
      ordered_test.lab_test.has_handfilled_requisition_forms
    )
  })
}

export function checkHasAvailableRequisitionForms(
  order: Order | PatientOrdersOrder
): boolean {
  return some(order.ordered_tests, (ordered_test) => {
    return (
      ordered_test.instant_requisition &&
      !ordered_test.lab_test.has_handfilled_requisition_forms
    )
  })
}

export function getNextStepsForHandfilledRequisitions(
  order: Order | PatientOrdersOrder
): Array<String> {
  const steps: Array<String> = []

  const handfilledOrderedTests = getHandfilledRequisitionFormOrderedTests(order)

  if (!isEmpty(handfilledOrderedTests)) {
    steps.push(
      ...uniq(
        handfilledOrderedTests.map(
          (ordered_test) =>
            `Fill out the ${ordered_test.lab_test.lab_company.short_name} requisition in the kit`
        )
      )
    )

    if (checkHasAvailableRequisitionForms(order)) {
      steps.push("Print remaining pre-filled requisitions")
    }
  }

  return steps
}

export function isValidSelfOrderOnlyCoupon(
  order: Order | PatientOrdersOrder,
  coupon: Coupon
): boolean {
  if (coupon.is_practitioner_self_order_only) {
    if (order.patient.user.email === order.practitioner?.user.email) {
      return true
    }

    return false
  }

  return true
}

/**
 * Returns the label that describes the type of Order.
 *
 * Used to ensure messaging includes "Cart" for physician authorization orders.
 *
 * @param requires_vendor_physician_authorization whether the order requires vendor physician authorization
 * @returns the type label
 */
export function getOrderTypeLabel(
  requires_vendor_physician_authorization: boolean = false
) {
  return requires_vendor_physician_authorization ? "Cart" : "Order"
}

/**
 * Returns the label that describes the patient of an order
 *
 * Used to ensure messaging includes "Client" for physician authorization orders instead of "Patient"
 *
 * @param requires_vendor_physician_authorization whether the order requires vendor physician authorization
 * @returns the type label
 */
export function getOrderPatientLabel(
  requires_vendor_physician_authorization: boolean = false
) {
  return requires_vendor_physician_authorization ? "Client" : "Patient"
}

interface MinAgeAndWarning {
  minAge: number
  minAgeWarning: string
}

/**
 * Returns the line item with the maximum lab test age restriction.
 * Only check line items with ordered tests.
 *
 * @param lineItems the line items to check
 * @returns OrderLineItem with the highest age restriction
 */
export function getLineItemWithMaxAgeRestriction(
  lineItems: AnyOrderLineItem[]
): AnyOrderLineItem | undefined {
  return lineItems
    .flatMap(
      // Only check line items with ordered tests which are not disabled
      (lineItem: OrderLineItem | PatientPortalOrderLineItem) =>
        lineItem.type === ORDER_LINE_ITEM_TYPE.LAB_TEST &&
        Boolean(lineItem.ordered_test?.lab_test?.min_age) &&
        !lineItem.date_disabled
          ? lineItem
          : []
    )
    .sort(
      (lineItem, otherLineItem) =>
        (otherLineItem.ordered_test.lab_test.min_age || 0) -
        (lineItem.ordered_test.lab_test.min_age || 0)
    )[0]
}

/**
 * Returns the minimum age for the ordered test, as well as the info message.
 *
 * @param order the order to check
 * @param labTest the lab test to check
 */
export function getMinimumAgeAndWarning(
  order?: Order | PatientPortalOrder,
  labTest?: LabTest
): MinAgeAndWarning {
  const labTestMinAge = labTest?.min_age || 0
  const patientLabel = getOrderPatientLabel(
    order?.requires_vendor_physician_authorization
  )

  if (!Boolean(labTestMinAge)) {
    return { minAge: 0, minAgeWarning: "" }
  }

  return {
    minAge: labTestMinAge,
    minAgeWarning: `${patientLabel}s must be ${labTestMinAge} years or older in order to complete this test.`,
  }
}

/**
 * Provides a sum of the total cost of the given line items.
 * @param lineItems the line items to add
 * @returns the sum of the line item costs
 */
const sumLineItemCost = (
  lineItems: (OrderLineItem | PatientPortalOrderLineItem)[]
) => {
  return sum(lineItems.map((lineItem) => parseFloat(lineItem.cost)))
}

/**
 * Get the Vendor phys auth fee and the Rupa Physician Auth Fee for a given order.
 * @param order the order to pull fees from
 * @returns an object with the vendor fee, rupa fee, and the total
 */
export function getPhysicianAuthorizationFee<
  O extends Order | PatientPortalOrder
>(order: O) {
  const physServicesLineItemTypes = [
    ORDER_LINE_ITEM_TYPE.PHYSICIAN_SERVICES_VENDOR_FEE,
    ORDER_LINE_ITEM_TYPE.PHYSICIAN_SERVICES_RUPA_FEE,
    ORDER_LINE_ITEM_TYPE.PHYSICIAN_SERVICES_KIT_RUPA_FEE,
    ORDER_LINE_ITEM_TYPE.PHYSICIAN_SERVICES_PANEL_RUPA_FEE,
    ORDER_LINE_ITEM_TYPE.PHYSICIAN_SERVICES_PROMOTIONAL_RUPA_FEE,
  ]
  const physServicesLineItems: O["line_items"] = []

  physServicesLineItemTypes.forEach((type) => {
    physServicesLineItems.push(
      ...order.line_items.flatMap((lineItem) =>
        lineItem.type === type ? lineItem : []
      )
    )
  })

  return sumLineItemCost(physServicesLineItems)
}

/**
 * Send API POST to resend the order event passed.
 *
 * @param eventId ID of order event
 * @param body    POST body (if kit resend)
 *
 * @returns Promise of API response
 */
export function resendOrderEvent(
  eventId: string,
  body?: ResendOrderEventBody
): Promise<any> {
  return axios.post(
    getApiBaseUrl() + `/api/order/event/${eventId}/resend/`,
    body
  )
}
