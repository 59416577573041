import { unstable_serialize } from "swr"

import { ResourceIdentifier, ResourceRequestCacheKey } from "app/swr/types"

/**
 * Gets the resource cache key for the given resource identifier.
 *
 * @param identifier the resource identifier
 * @returns the string resource cache key
 */
export function getResourceCacheKey(identifier: undefined): undefined
export function getResourceCacheKey(identifier: ResourceIdentifier): string
export function getResourceCacheKey(
  identifier?: ResourceIdentifier
): string | undefined
export function getResourceCacheKey(identifier?: ResourceIdentifier) {
  if (!identifier) {
    return undefined
  }
  const { type, id } = identifier
  return unstable_serialize({ type, id }) as string
}

/**
 * Gets the resource request cache key for the given resource request configuration.
 *
 * @param url the url
 * @param config the other request configuration cache key properties
 * @returns the request cache key or null
 */
export function getResourceRequestCacheKey(
  url?: string | null | false,
  config: Omit<ResourceRequestCacheKey, "url"> = {}
): ResourceRequestCacheKey | null {
  const { params, include, method } = config
  return url
    ? {
        url: url as string,
        params,
        include,
        method,
      }
    : null
}
