import { useMemo } from "react"

import { uniqBy } from "lodash"

import { ClinicSettings } from "app/main/settings/RupaBloodDashboards/constants"
import {
  PatientPortalClinic,
  PatientPortalLabTest,
  PatientPortalOrder,
  PatientPortalOrderedTest,
  PatientPortalPatient,
  PatientPortalPractitioner,
} from "app/patient-portal/types"
import useCachedCollection from "app/swr/hooks/use-cached-collection"
import useCachedResource from "app/swr/hooks/use-cached-resource"
import useCollectionSWR from "app/swr/hooks/use-collection-swr"
import { ResourceCollection } from "app/swr/types"
import { Biomarker } from "types/biomarker"
import { BodySystem } from "types/body-system"
import { DiscreteResult } from "types/discrete-result"
import { LabCompanyBiomarkerRange } from "types/lab-company-biomarker-range"
import { OrderedResult } from "types/ordered-result"

import {
  sortDiscreteValues,
  isDiscreteResultAbnormal,
  isDiscreteResultOutOfOptimalRange,
} from "../utils"
import usePatientPortalOptimalRanges from "./use-patient-portal-optimal-ranges"

export default function useDiscreteResults(orderedResultId: string) {
  const discreteResultsUrl = `/ordered_results/${orderedResultId}/patient_portal_discrete_results/`

  const { data: discreteResults, isLoading: isDiscreteResultsLoading } =
    useCollectionSWR<ResourceCollection<DiscreteResult>>(discreteResultsUrl, {
      ...{},
      include: [
        "biomarker.lab_company_biomarker_ranges",
        "biomarker.body_systems",
        "biomarker.custom_descriptions",
        "ordered_result.order.practitioner.clinic",
        "ordered_result.order.patient",
        "ordered_result.ordered_tests.lab_test.biomarkers",
      ],
    })

  const cachedDiscreteResults =
    useCachedCollection<DiscreteResult>(discreteResults)

  const discreteResultsWithBiomarkers = cachedDiscreteResults.filter(
    (discreteResult) => discreteResult.relationships.biomarker.data
  )

  const biomarkers = useCachedCollection<Biomarker>(
    cachedDiscreteResults.map((discreteResult) => {
      return discreteResult.relationships.biomarker.data
    })
  )

  const uniqueBodySystemIdentifiers = uniqBy(
    biomarkers.flatMap(
      (biomarker) => biomarker.relationships.body_systems.data
    ),
    "id"
  )
  const bodySystems = useCachedCollection<BodySystem>(
    uniqueBodySystemIdentifiers
  )

  const sortedBodySystems = useMemo(() => {
    return [...bodySystems].sort(
      (a, b) => (a.attributes.sort_order || 0) - (b.attributes.sort_order || 0)
    )
  }, [bodySystems])

  const orderedResult = useCachedResource<OrderedResult>({
    type: "patient_portal_ordered_result",
    id: orderedResultId,
  })

  const order = useCachedResource<PatientPortalOrder>(
    orderedResult?.relationships.order.data
  )

  const patient = useCachedResource<PatientPortalPatient>(
    order?.relationships.patient.data
  )

  const practitioner = useCachedResource<PatientPortalPractitioner>(
    order?.relationships.practitioner.data
  )

  const clinic = useCachedResource<PatientPortalClinic>(
    practitioner?.relationships.clinic.data
  )

  const orderedTests = useCachedCollection<PatientPortalOrderedTest>(
    orderedResult?.relationships.ordered_tests.data
  )

  const labTests = useCachedCollection<PatientPortalLabTest>(
    orderedTests.flatMap((orderedTest) => {
      return orderedTest.relationships.lab_test.data
    })
  )

  const discreteResultsBiomarkers = useCachedCollection<Biomarker>(
    cachedDiscreteResults.map((discreteResult) => {
      return discreteResult.relationships.biomarker.data
    })
  )

  const { optimalRanges, isLoading: isOptimalrangesLoading } =
    usePatientPortalOptimalRanges(
      orderedResult?.relationships.lab_company.data.id,
      practitioner?.id
    )

  const getAbnormalDiscreteResults = () => {
    const results = uniqBy(
      discreteResultsWithBiomarkers.filter((discreteResult) => {
        const matchingOptimalRange = optimalRanges.find(
          (optimalRange) =>
            optimalRange?.relationships?.biomarker.data.id ===
            discreteResult.relationships.biomarker.data?.id
        )

        const optimalRangesSettingTurnedOn = Boolean(
          clinic?.attributes.clinic_settings.includes(
            ClinicSettings.USE_OPTIMAL_RANGES_FOR_BLOOD_REPORTS
          )
        )

        return isDiscreteResultAbnormal(
          discreteResult,
          optimalRangesSettingTurnedOn,
          matchingOptimalRange
        )
      }),
      function (item) {
        return item.relationships.biomarker.data?.id
      }
    )

    return results.sort((a, b) =>
      sortDiscreteValues(
        a,
        b,
        getMatchingOptimalRangeByBiomarkerId(
          a.relationships.biomarker.data?.id
        ),
        getMatchingOptimalRangeByBiomarkerId(b.relationships.biomarker.data?.id)
      )
    )
  }

  const getOutOfOptimalRangeDiscreteResults = () => {
    const results = uniqBy(
      discreteResultsWithBiomarkers.filter((discreteResult) => {
        const optimalRangesSettingTurnedOn =
          clinic?.attributes.clinic_settings.includes(
            ClinicSettings.USE_OPTIMAL_RANGES_FOR_BLOOD_REPORTS
          )

        // Then check optimal ranges
        if (!optimalRangesSettingTurnedOn) {
          return false
        }

        const matchingOptimalRange = optimalRanges.find(
          (optimalRange) =>
            optimalRange?.relationships?.biomarker.data.id ===
            discreteResult.relationships.biomarker.data?.id
        )

        // If the discrete result is abnormal, then it is not out of optimal range
        if (
          isDiscreteResultAbnormal(
            discreteResult,
            optimalRangesSettingTurnedOn,
            matchingOptimalRange
          )
        ) {
          return false
        }

        return isDiscreteResultOutOfOptimalRange(
          discreteResult,
          matchingOptimalRange
        )
      }),
      function (item) {
        return item.relationships.biomarker.data?.id
      }
    )

    return results.sort((a, b) =>
      sortDiscreteValues(
        a,
        b,
        getMatchingOptimalRangeByBiomarkerId(
          a.relationships.biomarker.data?.id
        ),
        getMatchingOptimalRangeByBiomarkerId(b.relationships.biomarker.data?.id)
      )
    )
  }

  const getMatchingDiscreteResultByBiomarkerId = (
    biomarkerId
  ): DiscreteResult | undefined => {
    return cachedDiscreteResults.find(
      (discreteResult) =>
        discreteResult.relationships.biomarker.data?.id === biomarkerId
    )
  }

  const getMatchingOptimalRangeByBiomarkerId = (
    biomarkerId
  ): LabCompanyBiomarkerRange | undefined => {
    return optimalRanges.find(
      (optimalRange) =>
        optimalRange.relationships.biomarker.data?.id === biomarkerId
    )
  }

  const missingBiomarkers = useCachedCollection<Biomarker>(
    labTests
      .flatMap((labTest) => {
        return labTest.relationships.biomarkers.data
      })
      .filter((biomarker) => {
        return !discreteResultsBiomarkers.find((filteredBiomarker) => {
          return filteredBiomarker?.id === biomarker?.id
        })
      })
  )

  return {
    discreteResults: discreteResultsWithBiomarkers,
    getOutOfOptimalRangeDiscreteResults,
    getAbnormalDiscreteResults,
    optimalRanges,
    biomarkers,
    bodySystems: sortedBodySystems,
    isLoading: isDiscreteResultsLoading || isOptimalrangesLoading,
    getMatchingDiscreteResultByBiomarkerId,
    getMatchingOptimalRangeByBiomarkerId,
    orderedResult,
    patient,
    practitioner,
    clinic,
    missingBiomarkers,
  }
}
