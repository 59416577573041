import { Link } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

import BodyText from "app/components/design-system/BodyText"
import useFeatureFlag from "app/hooks/use-feature-flag"
import { FeatureFlag } from "app/providers/FeatureFlagProvider"
import { colors } from "app/theme"

import WarningContainer from "./WarningContainer"
import { getDefaultVibrantWarning } from "./utils"

const useStyles = makeStyles((theme) => ({
  warning: {
    color: colors.amber[900],
  },
  learnMore: {
    color: colors.amber[900],
  },
}))

export default function VibrantLeavingWarning() {
  const classes = useStyles()
  const [isVibrantDisabled] = useFeatureFlag(FeatureFlag.VibrantDisabled)
  const warningText = getDefaultVibrantWarning(isVibrantDisabled)

  return (
    <WarningContainer>
      <BodyText size="sm" className={classes.warning}>
        {warningText}{" "}
        <Link
          className={classes.warning}
          href="https://www.rupahealth.com/page/vibrant-rupa-partnership-ending-feb-2024"
          target="_blank"
          underline="always"
          role="button"
        >
          Learn more
        </Link>
        .
      </BodyText>
    </WarningContainer>
  )
}
