import { Theme } from "@material-ui/core"

import CheckIconGreen from "app/assets/icons/rupa-blood-dashboards/check-icon-green.svg"
import DashboardPreview from "app/assets/images/rupa-blood-dashboard/app-preview.png"
import BannerBackground from "app/assets/images/rupa-blood-dashboard/banner-background.svg"
import BodyText from "app/components/design-system/BodyText"
import useFeatureFlag, { FeatureFlag } from "app/hooks/use-feature-flag"
import { colors } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"

const useStyles = makeAppStyles((theme: Theme) => ({
  banner: {
    width: "100%",
    borderRadius: 8,
    border: `1px solid ${colors.blueGray[200]}`,
    padding: "24px 24px 0 24px",
    boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.07)",
    background: `url(${BannerBackground})`,
    display: "flex",
    justifyContent: "space-between",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    gap: 10,
    backgroundColor: "#fff",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "center",
    },
  },
  bannerContent: {
    display: "flex",
    flexDirection: "column",
    gap: 18,
    paddingBottom: 24,
    justifyContent: "center",
  },
  checkList: {
    listStyleImage: `url(${CheckIconGreen})`,
    lineHeight: "34px",
    paddingLeft: 25,
  },
  checkListItem: {
    paddingLeft: 10,
  },
  checkListItemContent: {
    position: "relative",
    top: "-4px",
  },
  dashboardPreviewImg: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    minWidth: 350,
  },
}))

const RupaBloodDashboardsBanner = () => {
  const classes = useStyles()

  const [labcorpEnabledForBloodLabsDashboard] = useFeatureFlag(
    FeatureFlag.LabcorpEnabledForBloodLabsDashboard
  )

  return (
    <div className={classes.banner}>
      <div className={classes.bannerContent}>
        <BodyText color={"textPrimary"}>
          Create a simple and beautiful experience around blood labs for your
          clients.
        </BodyText>
        <div>
          <ul className={classes.checkList}>
            <li className={classes.checkListItem}>
              <BodyText
                size="sm"
                weight="semibold"
                className={classes.checkListItemContent}
                color={"textPrimary"}
              >
                Simple and beautiful visualizations of blood results.
              </BodyText>
            </li>
            <li className={classes.checkListItem}>
              <BodyText
                size="sm"
                weight="semibold"
                className={classes.checkListItemContent}
                color={"textPrimary"}
              >
                Set custom optimal ranges.
              </BodyText>
            </li>
            <li className={classes.checkListItem}>
              <BodyText
                size="sm"
                weight="semibold"
                className={classes.checkListItemContent}
                color={"textPrimary"}
              >
                Customize automatic descriptions and interpretations.
              </BodyText>
            </li>
          </ul>
        </div>

        <BodyText size="sm" color={"textPrimary"}>
          With Access Medical Labs
          {labcorpEnabledForBloodLabsDashboard ? ", Labcorp," : ""} & Boston
          Heart.
        </BodyText>
      </div>
      <div className={classes.dashboardPreviewImg}>
        <img src={DashboardPreview} alt="Rupa Blood Dashboard" width="350px" />
      </div>
    </div>
  )
}

export default RupaBloodDashboardsBanner
