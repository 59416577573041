import { Theme } from "@material-ui/core"

import BodyText from "app/components/design-system/BodyText"
import { OrderIntent } from "app/hooks/use-resource"
import makeAppStyles from "app/utils/makeAppStyles"

import { CardOptions } from "../PublicStorefront"
import StorefrontCards from "./StorefrontCards"

interface Props {
  section: any
  cart: OrderIntent | null
  onAddToCart: (type: CardOptions, id: string) => void
  labshopLogo: string | null
  hidePhysServicesPricing: boolean
}

const styles = (theme: Theme) => ({
  card: {
    background: "white",
    padding: "23px 27px",
    borderRadius: 12,
    boxShadow:
      "0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)",
    display: "flex",
    "flex-direction": "column",
    gap: 23,
    width: "100%",
  },
  transparentCard: {
    padding: "0 27px",
  },
  productsContainer: {
    display: "flex",
    "flex-direction": "row",
    gap: 21,
    width: "100%",
  },
})

const useStyles = makeAppStyles(styles)

const StorefrontSection = ({
  section,
  cart,
  onAddToCart,
  labshopLogo,
  hidePhysServicesPricing,
}: Props) => {
  const classes = useStyles()

  const { is_transparent, name, description } = section.attributes
  const sectionHasProducts = Boolean(
    section?.relationships.storefront_products.data.length
  )
  return sectionHasProducts || name || description ? (
    <div className={!is_transparent ? classes.card : classes.transparentCard}>
      {!is_transparent && (
        <div>
          <BodyText size="md" weight="semibold">
            {name}
          </BodyText>
          <BodyText>{description}</BodyText>
        </div>
      )}

      <div className={classes.productsContainer}>
        <StorefrontCards
          cart={cart}
          storefrontProductRefIds={section?.relationships.storefront_products.data.map(
            (st) => st.id
          )}
          onAddToCart={onAddToCart}
          showTestingAvailabilityText={is_transparent}
          labshopLogo={labshopLogo}
          hidePhysServicesPricing={hidePhysServicesPricing}
        />
      </div>
    </div>
  ) : null
}

export default StorefrontSection
