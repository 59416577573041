import { Link, MenuItem, Select } from "@material-ui/core"
import Typography from "@material-ui/core/Typography"
import { makeStyles, styled } from "@material-ui/core/styles"

import { UserPaths } from "app/Routes"
import RightCaretIcon from "app/assets/images/right-caret.svg"
import BodyText from "app/components/design-system/BodyText"
import {
  isOrderedTestForCompany,
  orderBlockedMissingSignature,
} from "app/dataServices/orderDataService"

import Tooltip from "../../components/Tooltip"
import {
  LAB_COMPANY_KEY,
  LABS_REQUIRING_CONFIRMED_ACCOUNT_FOR_IOK_ORDERS,
  LABS_REQUIRING_CONFIRMED_ACCOUNT_WITH_ID_FOR_IOK_ORDERS,
} from "../../constants"
import {
  hasLabAccountConfirmedWithAccountId,
  isLabAccountConfirmed,
} from "../../dataServices/labCompanyAccountDataService"
import { colors, primaryColor } from "../../theme"

const StyledDeliveryArea = styled("div")({
  marginTop: 16,
  paddingTop: 8,
  display: "flex",
  borderTop: `1px solid ${colors.gray[300]}`,
  justifyContent: "space-between",
  alignItems: "baseline",
})

const StyledText = styled(BodyText)(({ requiresPhysicianAuthorization }) => ({
  paddingBottom: "1px",
  borderStyle: "dotted",
  borderColor: colors.blueGray[300],
  borderBottomWidth: requiresPhysicianAuthorization ? 0 : "2px",
}))

export function DeliveryMethod({
  disabled,
  order,
  orderedTests,
  supportsInstantRequisition,
  orderSigningPractitioner,
  onInstantRequisitionChange,
  requiresStockedKitForOrderedTest,
  hasInStockInOfficeKits,
}) {
  const selectClasses = useStyles()

  // This is null when loading
  if (!orderSigningPractitioner) {
    return null
  }

  const orderedTest = orderedTests[0]
  const patientFirstName = order.patient.first_name

  const instantRequisitionEnabled = !!orderedTest.instant_requisition

  const isLabTestAllowedHandfilledReqForms =
    orderedTest.lab_test.has_instant_requisition &&
    orderedTest.lab_test.has_handfilled_requisition_forms

  let labAccountRequiredButNotConfirmed = false
  let labAccountConfirmedWithIdRequired = false

  // We only allow in-office kits for those that already have a lab company account that is Confirmed
  if (
    LABS_REQUIRING_CONFIRMED_ACCOUNT_FOR_IOK_ORDERS.includes(
      orderedTest.lab_test.lab_company.key
    ) ||
    isLabTestAllowedHandfilledReqForms
  ) {
    labAccountRequiredButNotConfirmed = !isLabAccountConfirmed(
      orderSigningPractitioner.lab_company_accounts,
      orderedTest.lab_test.lab_company.key
    )
  }

  // Require confirmed lab company account with account id for certain labs
  if (
    LABS_REQUIRING_CONFIRMED_ACCOUNT_WITH_ID_FOR_IOK_ORDERS.includes(
      orderedTest.lab_test.lab_company.key
    ) &&
    !hasLabAccountConfirmedWithAccountId(
      orderSigningPractitioner.lab_company_accounts,
      orderedTest.lab_test.lab_company.key
    )
  ) {
    labAccountConfirmedWithIdRequired = true
  }

  const instantReqLink = `https://www.rupahealth.com/in-office-kits?email=${encodeURIComponent(
    orderSigningPractitioner.user.email
  )}&lab-company-id=${orderedTest.lab_test.lab_company.id}`

  const isOrderBlockedMissingSignature = orderBlockedMissingSignature(order)

  const instantRequisitionTooltip = isOrderBlockedMissingSignature ? (
    <BodyText size="xs">
      You can only use in-office kits after we have your license and
      e-signature. Submit them{" "}
      <Link
        href={UserPaths.SIGNATURE}
        target="_blank"
        className="whitespace-nowrap"
      >
        here
        <img className="ml-1 align-middle" src={RightCaretIcon} alt="" />
      </Link>
    </BodyText>
  ) : requiresStockedKitForOrderedTest ? (
    <BodyText size="xs" className="flex flex-col items-center">
      This kit will be drop-shipped to {patientFirstName}. If you want to hand
      the kit to {patientFirstName} in person, you'll need to order the kit
      through Rupa!{" "}
      <Link
        href={UserPaths.IN_OFFICE_KITS}
        target="_blank"
        className="whitespace-nowrap"
      >
        Request Supplies
        <img className="ml-1 align-middle" src={RightCaretIcon} alt="" />
      </Link>
    </BodyText>
  ) : (
    <BodyText size="xs" className="flex flex-col items-center">
      This kit will be drop-shipped to {patientFirstName}. If you want to hand
      the kit to {patientFirstName} in person, you'll need to request access to
      use In-Office Kits.{" "}
      <Link href={instantReqLink} target="_blank" className="whitespace-nowrap">
        Learn more or get set up
        <img className="ml-1 align-middle" src={RightCaretIcon} alt="" />
      </Link>
    </BodyText>
  )

  // Only allow an ordered test to have in-office delivery if the clinic has been approved for hand-filled req forms
  const isClinicBlockedHandfillReqForms =
    !orderSigningPractitioner?.clinic?.allow_handfilled_requisition_forms &&
    isLabTestAllowedHandfilledReqForms

  const instantRequisitionOptionBlocked =
    labAccountRequiredButNotConfirmed ||
    isOrderBlockedMissingSignature ||
    isClinicBlockedHandfillReqForms ||
    labAccountConfirmedWithIdRequired ||
    (requiresStockedKitForOrderedTest && !hasInStockInOfficeKits)

  const deliveryMethodSelectInput = (
    <Select
      value={instantRequisitionEnabled}
      disabled={disabled || instantRequisitionOptionBlocked}
      onChange={(event) => onInstantRequisitionChange(event.target.value)}
      variant="outlined"
      classes={selectClasses}
      className="font-semibold"
    >
      <MenuItem value={false}>Drop-Ship Kit</MenuItem>
      <MenuItem value={true}>In-Office Kit</MenuItem>
    </Select>
  )

  const deliveryMethodSelect = instantRequisitionOptionBlocked ? (
    <Tooltip
      placement="bottom"
      arrow
      interactive
      title={instantRequisitionTooltip}
    >
      {deliveryMethodSelectInput}
    </Tooltip>
  ) : (
    deliveryMethodSelectInput
  )

  // This logic can be replaced later by passing some feild from serializer
  const isBRLTest = isOrderedTestForCompany(
    orderedTest,
    LAB_COMPANY_KEY.BIOREFERENCE
  )

  const isDirectToPatient =
    orderedTest.lab_test.lab_company.only_in_house_phlebotomy

  const unavailableTestsMessage =
    getOrderedTestsWithoutInstantReqMessage(orderedTests)

  const hideDeliveryMethod =
    orderedTest.lab_test.lab_company.key === LAB_COMPANY_KEY.EMPIRE_CITY_LABS

  const deliveryTooltip = isBRLTest ? (
    <div>
      <BodyText size="xs" weight="semibold">
        Direct via Scarlet
      </BodyText>
      <BodyText size="xs">
        Scarlet will coordinate the hand-delivery of tests for each appointment;
        no shipping required
      </BodyText>
    </div>
  ) : isDirectToPatient ? (
    <div>
      <BodyText size="xs" weight="semibold">
        Direct via {orderedTest.lab_test.lab_company.short_name}
      </BodyText>
      <BodyText size="xs">
        We'll send your patient instructions to schedule a blood draw
        appointment with {orderedTest.lab_test.lab_company.short_name}.
      </BodyText>
    </div>
  ) : (
    <div>
      <BodyText size="xs" weight="semibold" className="mb-2">
        Drop-Ship Kit
      </BodyText>
      <BodyText size="xs">
        Kit will be drop-shipped from the lab directly to {patientFirstName}.
      </BodyText>
      <Typography className="mt-4 text-sm13 font-semibold">
        In-Office Kit
        {!supportsInstantRequisition && (
          <Typography className="italic inline text-gray-600 font-semibold">
            {" "}
            Unavailable
          </Typography>
        )}
        {unavailableTestsMessage && (
          <BodyText size="xs" className="mb-3" key={orderedTest.id}>
            {unavailableTestsMessage} currently available to hand out in-office.{" "}
            <Link
              href="https://www.rupahealth.com/in-office-kits"
              target="_blank"
              className="whitespace-nowrap"
            >
              Learn more about In Office Kits
              <img className="ml-1 align-middle" src={RightCaretIcon} alt="" />
            </Link>
          </BodyText>
        )}
      </Typography>
      {supportsInstantRequisition && (
        <BodyText size="xs" className="flex flex-col items-center mx-auto">
          You’ll get the requisition form automatically, so you can hand the kit
          to {patientFirstName} in-person.
          <Link
            href={instantReqLink}
            target="_blank"
            className="whitespace-nowrap"
          >
            Learn more
            <img className="ml-1 align-middle" src={RightCaretIcon} alt="" />
          </Link>
        </BodyText>
      )}
    </div>
  )

  return (
    <StyledDeliveryArea>
      {!hideDeliveryMethod && (
        <Tooltip
          placement="bottom"
          arrow
          interactive
          disableHoverListener={order.requires_physician_authorization}
          title={deliveryTooltip}
        >
          <div>
            <StyledText
              size="xs"
              weight="semibold"
              requiresPhysicianAuthorization={
                order.requires_physician_authorization
              }
            >
              Delivery Method
            </StyledText>
          </div>
        </Tooltip>
      )}
      {(supportsInstantRequisition || isLabTestAllowedHandfilledReqForms) &&
      !order.requires_physician_authorization ? (
        deliveryMethodSelect
      ) : isBRLTest ? (
        <BodyText size="xs" weight="semibold">
          Direct via Scarlet
        </BodyText>
      ) : isDirectToPatient ? (
        <BodyText size="xs" weight="semibold">
          Direct via {orderedTest.lab_test.lab_company.short_name}
        </BodyText>
      ) : (
        <BodyText size="xs" weight="semibold">
          Drop-Ship Kit
        </BodyText>
      )}
    </StyledDeliveryArea>
  )
}

const getOrderedTestsWithoutInstantReqMessage = (orderedTests) => {
  let orderedTestsWithoutInstantReq = orderedTests.filter(
    (orderedTest) => !orderedTest.lab_test.has_instant_requisition
  )
  let unavailableTestsMessage = null

  if (orderedTestsWithoutInstantReq.length > 2) {
    const lastTest = orderedTestsWithoutInstantReq.pop()
    const testNames = orderedTestsWithoutInstantReq
      .map((orderedTest) => orderedTest.lab_test.name)
      .join(", ")
    unavailableTestsMessage = `${testNames}, and ${lastTest.lab_test.name} are not`
  } else if (orderedTestsWithoutInstantReq.length === 2) {
    const lastTest = orderedTestsWithoutInstantReq.pop()
    const testNames = orderedTestsWithoutInstantReq
      .map((orderedTest) => orderedTest.lab_test.name)
      .join(", ")
    unavailableTestsMessage = `${testNames} and ${lastTest.lab_test.name} are not`
  } else if (orderedTestsWithoutInstantReq.length === 1) {
    const lastTest = orderedTestsWithoutInstantReq.pop()
    unavailableTestsMessage = `${lastTest.lab_test.name} is not`
  }

  return unavailableTestsMessage
}

const useStyles = makeStyles(() => ({
  outlined: {
    background: "white",
    padding: "4px 8px",
  },
  icon: {
    color: primaryColor,
  },
}))
