import { StorefrontType, useResourceByIdSelector } from "app/hooks/use-resource"

import { CardOptions } from "../PublicStorefront"
import { BundleCartCard } from "./BundleCartCard"
import { LabTestCartCard } from "./LabTestCartCard"

interface Props {
  storefrontProductId: string
  handleRemoveCart: (id: string) => unknown
  storefront: StorefrontType | null
}

const CartItemCard = ({
  storefrontProductId,
  handleRemoveCart,
  storefront,
}: Props) => {
  const product = useResourceByIdSelector(
    "storefront_product",
    storefrontProductId
  )
  const productType = product?.relationships.lab_test?.data
    ? CardOptions.LAB_TEST
    : CardOptions.BUNDLE

  // TODO: deprecate passing in the labTestId and bundleId
  return productType === CardOptions.LAB_TEST ? (
    <LabTestCartCard
      storefrontProductId={storefrontProductId}
      onRemove={() => handleRemoveCart(storefrontProductId)}
      storefront={storefront}
    />
  ) : (
    <BundleCartCard
      storefrontProductId={storefrontProductId}
      onRemove={() => handleRemoveCart(storefrontProductId)}
      storefront={storefront}
    />
  )
}

export default CartItemCard
