import { useEffect, useMemo, useState } from "react"

import { CircularProgress } from "@material-ui/core"
import { CellContext } from "@tanstack/react-table"
import { ColumnFiltersState } from "@tanstack/react-table"

import { ReactComponent as GreenCheckmarkCircle } from "app/assets/icons/check-circle.svg"
import { ReactComponent as GoBackBlueIcon } from "app/assets/icons/rupa-blood-dashboards/go-back-blue.svg"
import BodyText from "app/components/design-system/BodyText"
import DesignSystemButton from "app/components/design-system/Button"
import useCachedResource from "app/swr/hooks/use-cached-resource"
import { colors, navy } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"
import { LabCompany } from "types/lab-company"
import { LabCompanyBiomarkerRange } from "types/lab-company-biomarker-range"

import { OptimalRangeSource } from "../../constants"
import useOptimalRanges from "../hooks/use-optimal-ranges"
import useOptimalRangesForm from "../hooks/use-optimal-ranges-form"
import TextField from "./TextField"
import columnHelper from "./column-helper"

const useStyles = makeAppStyles(() => ({
  inputsContainer: {
    display: "flex",
    gap: 8,
  },
  inputField: {
    marginTop: 4,
    "& .MuiOutlinedInput-root": {
      padding: "4px 7px",
      background: colors.blueGray[100],
      borderRadius: "6px",
    },
    "& .MuiOutlinedInput-input": {
      padding: 0,
    },
  },
  label: {
    fontSize: 12,
    color: colors.blueGray[500],
    lineHeight: "130%",
    letterSpacing: "0.5px",
    fontWeight: 600,
  },
  bottomContainer: {
    display: "flex",
    gap: 8,
    alignItems: "center",
    paddingTop: 6,
  },
  successMessage: {
    fontSize: 13,
    marginTop: 8,
    color: navy,
  },
  errorText: {
    marginTop: 6,
  },
}))

const TWO_MINUTES_IN_MILLISECONDS = 2 * 60 * 1000

const ResetToDefaultButton = ({ onResetToDefault }) => {
  return (
    <DesignSystemButton
      color="text"
      onClick={onResetToDefault}
      startIcon={<GoBackBlueIcon />}
      loading={false}
    >
      Reset to Default
    </DesignSystemButton>
  )
}

const OUT_OF_STANDARD_RANGE_INFO_TEXT =
  "Optimal ranges should typically be narrower than the lab’s standard range. If this optimal range is outside the standard range for your client's specific result, we’ll only show the standard range."

const OptimalRangeCell = ({
  table,
  row,
  column,
}: CellContext<
  LabCompanyBiomarkerRange,
  LabCompanyBiomarkerRange["attributes"]
>) => {
  const optimalRange = useCachedResource<LabCompanyBiomarkerRange>({
    id: row.id,
    type: "lab_company_biomarker_range",
  })!
  const labCompany = useCachedResource<LabCompany>(
    optimalRange.relationships.lab_company.data
  )!

  const [showOutOfStandardRangeText, setShowOutOfStandardRangeText] =
    useState(false)
  const [showSuccessMessage, setShowSuccessMessage] = useState(false)

  const standardRangeMin = optimalRange.attributes.standard_range_min
    ? parseFloat(optimalRange.attributes.standard_range_min)
    : null
  const standardRangeMax = optimalRange.attributes.standard_range_max
    ? parseFloat(optimalRange.attributes.standard_range_max)
    : null

  const { resetOptimalRange } = useOptimalRanges({
    pagination: table.getState().pagination,
    columnFilters: table.getState().columnFilters as ColumnFiltersState,
    labCompanyKey: labCompany.attributes.key,
    hideUnsetRanges: table.getState().globalFilter.hideUnsetRanges,
  })

  const {
    methods: { control, formState, watch },
    isPatchLoading,
    isCreateLoading,
    isUserTyping,
    generalFormError,
  } = useOptimalRangesForm(
    table.getState().pagination,
    table.getState().columnFilters as ColumnFiltersState,
    optimalRange,
    labCompany.attributes.key,
    table.getState().globalFilter.hideUnsetRanges
  )

  const classes = useStyles()

  const isStateLoading = useMemo(() => {
    return isPatchLoading || isCreateLoading || isUserTyping
  }, [isPatchLoading, isCreateLoading, isUserTyping])

  const optimalRangeMin = watch("optimal_range_min")
  const optimalRangeMax = watch("optimal_range_max")

  useEffect(() => {
    const optimalRangeMinNumeric = optimalRangeMin
      ? parseFloat(optimalRangeMin)
      : null
    const optimalRangeMaxNumeric = optimalRangeMax
      ? parseFloat(optimalRangeMax)
      : null

    if (optimalRangeMinNumeric) {
      if (standardRangeMin && optimalRangeMinNumeric < standardRangeMin) {
        setShowOutOfStandardRangeText(true)
        return
      } else if (
        standardRangeMax &&
        optimalRangeMinNumeric > standardRangeMax
      ) {
        setShowOutOfStandardRangeText(true)
        return
      }
    }

    if (optimalRangeMaxNumeric) {
      if (standardRangeMin && optimalRangeMaxNumeric < standardRangeMin) {
        setShowOutOfStandardRangeText(true)
        return
      } else if (
        standardRangeMax &&
        optimalRangeMaxNumeric > standardRangeMax
      ) {
        setShowOutOfStandardRangeText(true)
        return
      }
    }

    setShowOutOfStandardRangeText(false)
  }, [optimalRangeMin, optimalRangeMax])

  useEffect(() => {
    // When an optimal range is updated we should show a success message for 2 minutes
    // indicating that end users will not see updated optimal range values
    // for two minutes.
    const updatedAt = new Date(optimalRange.attributes.updated_at)
    const now = new Date()
    const timeDiff = now.getTime() - updatedAt.getTime()
    const timeDiffToTwoMins = Math.abs(timeDiff - TWO_MINUTES_IN_MILLISECONDS)

    if (timeDiffToTwoMins <= TWO_MINUTES_IN_MILLISECONDS) {
      setShowSuccessMessage(true)
      setTimeout(() => {
        setShowSuccessMessage(false)
      }, timeDiffToTwoMins)
    }
  }, [optimalRange])

  return (
    <>
      <div className={classes.inputsContainer}>
        <div>
          <div className={classes.label}>OPTIMAL MIN.</div>

          <TextField
            control={control}
            name="optimal_range_min"
            label="Optimal Range Min"
            placeholder="None"
            disabled={false}
          />
        </div>
        <div>
          <div className={classes.label}>OPTIMAL MAX.</div>

          <TextField
            control={control}
            name="optimal_range_max"
            label="Optimal Range Max"
            placeholder="None"
            disabled={false}
          />
        </div>
      </div>
      <BodyText
        className={classes.errorText}
        color="error"
        size="xs"
        weight="semibold"
      >
        {formState.errors?.optimal_range_max?.message ||
          formState.errors?.optimal_range_min?.message ||
          generalFormError}
      </BodyText>
      {showOutOfStandardRangeText && (
        <BodyText size="xs" weight="regular">
          {OUT_OF_STANDARD_RANGE_INFO_TEXT}
        </BodyText>
      )}
      {showSuccessMessage && (
        <div className={classes.successMessage}>
          <GreenCheckmarkCircle
            fill={colors.emerald[500]}
            width={11.5}
            height={11.5}
          />{" "}
          This update will be shown on Blood Lab Dashboards in 2 minutes.
        </div>
      )}
      <div className={classes.bottomContainer}>
        {optimalRange.attributes.source === OptimalRangeSource.CLINIC && (
          <ResetToDefaultButton
            onResetToDefault={() => resetOptimalRange(optimalRange.id)}
          />
        )}
        {isStateLoading && <CircularProgress size={14} />}
      </div>
    </>
  )
}

export const optimalRangeColumn = columnHelper.accessor("attributes", {
  cell: (info) => <OptimalRangeCell {...info} />,
  header: (info) => (
    <BodyText size="sm" weight="semibold">
      Optimal Range
    </BodyText>
  ),
  id: "optimalRange",
  size: 200,
  minSize: 200,
})
