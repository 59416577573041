import { CircularProgress } from "@material-ui/core"
import { Button } from "@rupahealth/design"

import BodyText from "app/components/design-system/BodyText"
import { PractitionerSnippet } from "types/practitioner-snippet"

import TablePagination from "./TablePagination"

interface Props {
  snippets?: PractitionerSnippet[]
  page: number
  totalNumResults: number
  isValidating: boolean
  onEditClick: (snippet: PractitionerSnippet) => void
  onChangePage: (page: number) => void
}

const SnippetsTable = ({
  snippets,
  page,
  totalNumResults,
  isValidating,
  onEditClick,
  onChangePage,
}: Props) => {
  return (
    <div className="rounded-md border border-b-[2px] border-slate-200 overflow-hidden">
      <table className="table-auto border-spacing-0  w-full shadow border-separate">
        <thead className="bg-slate-50 ">
          <tr>
            <th className="text-left py-[14px] px-[22px] border-b border-slate-300 flex items-center gap-2">
              <BodyText weight="semibold">Snippets</BodyText>{" "}
              {isValidating && <CircularProgress size={15} />}
            </th>
            <th className="py-[14px] px-[22px] border-b border-slate-300"></th>
          </tr>
        </thead>
        <tbody className="bg-white">
          {snippets?.map((snippet) => (
            <tr key={snippet.id}>
              <td className="px-[22px] py-3 border-b border-slate-200">
                <BodyText weight="semibold">{snippet.title}</BodyText>
              </td>

              <td className="text-right py-[20px] pr-[22px] border-b border-slate-200">
                <Button
                  variant="ghost"
                  className="text-primary p-0 h-auto"
                  onClick={() => onEditClick(snippet)}
                >
                  Edit Snippet
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
        <tfoot className="bg-slate-50">
          <tr>
            <td className="px-[22px] py-0" colSpan={2}>
              <div className="w-full flex justify-end">
                <TablePagination
                  page={page}
                  totalNumResults={totalNumResults}
                  onChangePage={onChangePage}
                />
              </div>
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  )
}

export default SnippetsTable
