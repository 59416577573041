import clsx from "clsx"

import RupaLogoIcon from "app/components/svgs/RupaLogoIcon"
import { VENDOR_PHYSICIAN_AUTHORIZATION_LABEL } from "app/constants"
import makeAppStyles from "app/utils/makeAppStyles"

const useStyles = makeAppStyles({
  wrapper: {
    display: "inline-flex",
    alignItems: "center",
    flexWrap: "nowrap",
    gap: 5,
    fontWeight: 600,
    lineHeight: 1,
    "& svg": {
      // adjustment for the Josefin font
      marginTop: -3,
    },
  },
})

export interface PatientOrdersPhysicianAuthorizationMessageProps {
  className?: string
}

export default function PatientOrdersPhysicianAuthorizationMessage({
  className,
}: PatientOrdersPhysicianAuthorizationMessageProps) {
  const classes = useStyles()
  return (
    <div className={clsx(classes.wrapper, className)}>
      <RupaLogoIcon height={15} width={15} />
      <span>{VENDOR_PHYSICIAN_AUTHORIZATION_LABEL}</span>
    </div>
  )
}
