import { Link } from "@material-ui/core"

import { ReactComponent as QuestionIcon } from "app/assets/icons/question-marks/question-mark-filled.svg"
import Tooltip from "app/components/Tooltip"
import BodyText from "app/components/design-system/BodyText"
import { primaryColor } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"

const useStyles = makeAppStyles((theme) => ({
  editButton: {
    margin: 0,
    padding: 0,
  },
  actionText: {
    color: primaryColor,
    fontSize: 15,
  },
  styledLink: {
    color: primaryColor,
    fontWeight: 600,
    fontSize: 14,
    "&:hover": {
      cursor: "pointer",
    },
  },
}))
const EditAccountTooltip = ({ companyName }) => {
  const classes = useStyles()

  const intercomBtn = (
    <>
      <Link
        className={classes.styledLink}
        onClick={() => {
          window.Intercom(
            "showNewMessage",
            `Hi! I'd like to make the following update to my ${companyName} account: `
          )
        }}
      >
        Message our team
      </Link>{" "}
    </>
  )
  return (
    <Tooltip
      interactive
      arrow
      title={
        <div>
          <BodyText size="sm">
            {intercomBtn}
            to edit your account. Please note: updating your account information
            will delay verification.
          </BodyText>
        </div>
      }
    >
      <button className={classes.editButton}>
        <BodyText weight="semibold" size="sm" className={classes.actionText}>
          <QuestionIcon style={{ marginRight: "4px" }} />
          Help
        </BodyText>
      </button>
    </Tooltip>
  )
}

export default EditAccountTooltip
