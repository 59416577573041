import { faVials } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import BodyText from "app/components/design-system/BodyText"

const OrderCartSidebarEmptyState = () => {
  return (
    <div
      className="w-full flex gap-[6px] rounded-lg border border-slate-100 bg-slate-50 py-[19px] px-[42px] justify-center items-center"
      style={{ boxShadow: "0px 2px 2.9px 0px rgba(43, 69, 95, 0.08) inset" }}
    >
      <FontAwesomeIcon
        className="ml-1 text-base text-slate-500"
        icon={faVials}
      />
      <BodyText className="text-slate-500">
        Add a test to cart to get started.
      </BodyText>
    </div>
  )
}

export default OrderCartSidebarEmptyState
