import { useEffect, useMemo, useRef, useState } from "react"

import { Control, useController } from "react-hook-form"

import { Collapse, Theme, makeStyles } from "@material-ui/core"
import ExpandLessIcon from "@material-ui/icons/ExpandLess"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"

import GrowingTextArea from "app/components/design-system/GrowingTextArea"
import Input from "app/components/design-system/Input"
import { colors, primaryColor, textPrimaryColor } from "app/theme"

import FoodPlanTextCheckbox from "./FoodPlanCheckboxField"

interface StyleProps {
  open: boolean
  disabled?: boolean
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  root: ({ open, disabled }) => ({
    width: "100%",
    display: "flex",
    flexFlow: "column nowrap",
    justifyContent: "flex-start",
    border: disabled
      ? `1px solid ${colors.blueGray[300]}`
      : open
      ? `1px solid ${primaryColor}`
      : `1px solid ${colors.blueGray[200]}`,
    borderRadius: 6,
    padding: 8,
    backgroundColor: disabled ? colors.blueGray[50] : "inherit",
    pointerEvents: disabled ? "none" : "inherit",
  }),
  label: {
    display: "inline-flex",
    flexFlow: "row nowrap",
    alignItems: "center",
    gap: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
    fontSize: 14,
    fontWeight: 600,
    color: textPrimaryColor,
    "& > svg": {
      fill: "currentColor",
    },
  },
  inputContainer: {
    "&:last-child": {
      marginTop: 8,
    },
  },
  collapseContainer: {
    paddingTop: 8,
  },
  header: {
    cursor: "pointer",
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    flexDirection: "row",
    fontSize: 14,
    fontWeight: 600,
    color: textPrimaryColor,
    alignItems: "center",
  },
  icon: ({ disabled }) => ({
    color: disabled ? colors.blueGray[400] : primaryColor,
    backgroundColor: disabled ? colors.blueGray[100] : colors.lightBlue[50],
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 8,
    padding: theme.spacing(0.5),
  }),
  textAreaWrap: {
    width: "100%",
    padding: theme.spacing(1.25, 1.75),
    fontSize: 15,
    fontWeight: 600,
    lineHeight: 1.35,
    color: textPrimaryColor,
    backgroundColor: colors.coolGray[50],
    border: `1px solid ${colors.blueGray[300]}`,
    borderRadius: 6,
    "&[data-disabled=true]": {
      color: colors.blueGray[500],
    },
    "&:focus-within": {
      borderColor: primaryColor,
      boxShadow: `0px 0px 3.1099px ${primaryColor}`,
    },
  },
  textArea: {
    width: "100%",
    backgroundColor: "inherit",
    font: "inherit",
    fontSize: "inherit",
    fontWeight: "inherit",
    lineHeight: "inherit",
    color: "inherit",
    "&::placeholder": {
      color: colors.blueGray[500],
      fontWeight: 600,
    },
  },
}))

export interface FoodPlanCalorieIntakeFieldProps {
  control: Control<any>
  disabled?: boolean
}
/**
 * The component to intake the calories and nutrients
 */
export default function FoodPlanCalorieIntakeField({
  control,
  disabled,
}: FoodPlanCalorieIntakeFieldProps) {
  const [open, setOpen] = useState(false)
  const classes = useStyles({ open, disabled })
  const dailyCalorieRef = useRef<HTMLInputElement | null>(null)
  const dailyNutrientRef = useRef<HTMLTextAreaElement | null>(null)

  const { field: dailyCalories } = useController({
    control,
    name: "daily_calories",
    defaultValue: undefined,
    shouldUnregister: true,
  })
  const { field: dailyNutrients } = useController({
    control,
    name: "daily_nutrients",
    shouldUnregister: true,
  })

  const { field: includeIngredients } = useController({
    control,
    name: "include_ingredients",
    shouldUnregister: true,
  })

  const selectedValues = useMemo(
    () => Boolean(dailyCalories?.value || dailyNutrients?.value?.length),
    [dailyCalories.value, dailyNutrients.value]
  )

  useEffect(() => {
    includeIngredients.onChange(selectedValues)
  }, [selectedValues])

  return (
    <>
      <div className={classes.root}>
        <div onClick={() => setOpen(!open)} className={classes.header}>
          <p>Calorie & Nutrient Intake{open}</p>

          <div className={classes.icon}>
            {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </div>
        </div>

        <Collapse in={open}>
          <div className={classes.collapseContainer}>
            <div className={classes.inputContainer}>
              <label className={classes.label} htmlFor={"daily_calories"}>
                Daily Calories
              </label>
              <div className={classes.textAreaWrap} data-disabled={disabled}>
                <Input
                  type="number"
                  className={classes.textArea}
                  disabled={disabled}
                  placeholder="E.g. 1300"
                  {...dailyCalories}
                  onChange={(e) =>
                    dailyCalories.onChange(parseInt(e.target.value))
                  }
                  value={dailyCalories.value || 0}
                  ref={(elem) => {
                    dailyCalorieRef.current = elem

                    dailyCalories.ref(elem)
                  }}
                />
              </div>
            </div>
            <div className={classes.inputContainer}>
              <label className={classes.label} htmlFor={"daily_nutrients"}>
                Daily Nutrients
              </label>
              <div className={classes.textAreaWrap} data-disabled={disabled}>
                <GrowingTextArea
                  className={classes.textArea}
                  disabled={disabled}
                  placeholder="E.g. 3g omega 3s and 100g protein"
                  rows={2}
                  {...dailyNutrients}
                  ref={(elem) => {
                    dailyNutrientRef.current = elem

                    dailyNutrients.ref(elem)
                  }}
                />
              </div>
            </div>
          </div>
        </Collapse>
      </div>
      <FoodPlanTextCheckbox
        label="Include Ingredients"
        name="include_ingredients"
        tooltip="Ingredients are required when including calories or nutrients."
        control={control}
        disabled={disabled || selectedValues}
      />
    </>
  )
}
