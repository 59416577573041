import * as React from "react"

import { DialogContent } from "@material-ui/core"

import { colors } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"

const useStyles = makeAppStyles((theme) => ({
  content: {
    height: "100%",
    padding: 0,
    backgroundColor: colors.blueGray[150],
  },
}))

export default function ComparisonSearchModalContent({
  children,
}: {
  children: React.ReactNode
}) {
  const classes = useStyles()
  return <DialogContent className={classes.content}>{children}</DialogContent>
}
