import { useDispatch } from "react-redux"

import classNames from "classnames"
import clsx from "clsx"
import { isEmpty } from "lodash"
import { useHistory } from "react-router-dom"

import { makeStyles } from "@material-ui/core/styles"

import { resendRegistrationEmail } from "app/auth/store/actions/user.actions"
import Tooltip from "app/components/Tooltip"
import BodyText from "app/components/design-system/BodyText"
import DesignSystemButton from "app/components/design-system/Button"
import SignatureAndLicenseModalButton from "app/components/modals/SignatureAndLicenseModal/SignatureAndLicenseModalButton"
import useSendActivationLinks from "app/dashboard/components/TableRenderer/columns/actionsColumn/use-send-activation-links"
import useAppSelector from "app/hooks/useAppSelector"
import { primaryColor } from "app/theme"

import { ORDER_STATUS } from "../../../constants"
import ESignatureTooltip from "../../dashboard/OrderList/ESignatureTooltip"
import { getOrders } from "../store/actions/ecommerce.actions"
import { TableCellProps, getPageSize } from "./types"

const useStyles = makeStyles((theme) => ({
  button: {
    width: 140,
  },
  textCenter: {
    textAlign: "center",
  },
  textPrimaryColor: {
    color: primaryColor,
  },
}))

export default function ViewOrderTableCell({ order }: TableCellProps) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const currentPractitioner = useAppSelector(({ practitioner }) => practitioner)
  const onClickResendVerificationEmail = () => {
    dispatch(
      resendRegistrationEmail({ email: currentPractitioner?.user?.email })
    )
  }

  const onCloseSignatureAndLicenseModal = (fullVerificationComplete) => {
    if (fullVerificationComplete) {
      // Reload orders when the practitioner is fully verified
      dispatch(
        getOrders(
          undefined,
          undefined,
          getPageSize(currentPractitioner?.clinic?.id)
        )
      )
    }
  }
  const history = useHistory()

  const { isSending: loading, sendActivationLinks } = useSendActivationLinks()

  if (order.status === ORDER_STATUS.DRAFT) {
    return (
      <div className="bg-white">
        <DesignSystemButton
          color="secondary"
          className={classes.button}
          onClick={() => history.push(`/checkout/${order.id}`)}
        >
          Edit Draft
        </DesignSystemButton>
      </div>
    )
  }

  if (order.status === ORDER_STATUS.BLOCKED_MISSING_SIGNATURE) {
    // DEPRECATED: In favor of **/actionsColumn/ESignatureSignNowButton.tsx
    const signingPractitionerIsCurrentPractitioner =
      order.signing_practitioner.id === currentPractitioner.id
    const currentPractitionerIsVerified = Boolean(
      currentPractitioner.user.is_verified
    )
    const canSignForOrder =
      signingPractitionerIsCurrentPractitioner && currentPractitionerIsVerified

    const signNowButton = (
      <ESignatureTooltip
        canSignForOrder={canSignForOrder}
        currentPractitioner={currentPractitioner}
        loading={loading}
        onClickResendVerificationEmail={onClickResendVerificationEmail}
        sendActivationLinks={() =>
          sendActivationLinks(order.id, order.signing_practitioner?.user.email)
        }
        signingPractitioner={
          order.signing_practitioner
            ? {
                id: order.signing_practitioner.id,
                email: order.signing_practitioner.user.email,
                titledName: order.signing_practitioner.titled_full_name,
              }
            : undefined
        }
      >
        <SignatureAndLicenseModalButton
          location="labshop_sign_now_button"
          disabled={!canSignForOrder || isEmpty(currentPractitioner)}
          onClose={onCloseSignatureAndLicenseModal}
          returnLinkText="Return to Dashboard"
          className={classNames(classes.button)}
        >
          Sign Now
        </SignatureAndLicenseModalButton>
      </ESignatureTooltip>
    )

    return (
      // Hellosign flow
      <div className="bg-white">{signNowButton}</div>
    )
  } else if (
    order.status === ORDER_STATUS.BLOCKED_PRACTITIONER_ACCOUNT_UNVERIFIED
  ) {
    // DEPRECATED: In favor of **/actionsColumn/VerifyAccountButton.tsx
    const verifyAccountTooltip = order?.practitioner?.user ? (
      <>
        <BodyText size="xs" className={classes.textCenter}>
          {order?.practitioner
            ? `${order.practitioner.titled_full_name} needs to log in to Rupa and verify their account.`
            : ""}
        </BodyText>
        <DesignSystemButton
          color="text"
          loading={loading}
          onClick={() =>
            sendActivationLinks(order.id, order.practitioner?.user.email)
          }
        >
          <BodyText
            size="xs"
            weight="semibold"
            className={clsx(classes.textCenter, classes.textPrimaryColor)}
          >
            {`Send ${order.practitioner.user.email} a link to log in`}
          </BodyText>
        </DesignSystemButton>
      </>
    ) : (
      ""
    )

    return (
      <Tooltip interactive arrow title={verifyAccountTooltip}>
        <div className="bg-white">
          <DesignSystemButton
            color="secondary"
            disabled={true}
            variant="text"
            className={classNames(classes.button)}
          >
            Verify Account
          </DesignSystemButton>
        </div>
      </Tooltip>
    )
  }

  return (
    <div className="bg-white">
      <DesignSystemButton
        color="secondary"
        className={classNames(classes.button)}
        onClick={() => {
          let querystring = ""

          if (order.status !== ORDER_STATUS.SCHEDULED) {
            querystring = `?orderId=${order.id}`
          }

          history.push(`/patient/${order.patient.id}${querystring}`)
        }}
      >
        Details
      </DesignSystemButton>
    </div>
  )
}
