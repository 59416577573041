import { colors } from "app/theme"
import interleave from "app/utils/interleave"
import makeAppStyles from "app/utils/makeAppStyles"
import { BiomarkerCustomDescription } from "types/biomarker-custom-description"
import { DiscreteResult } from "types/discrete-result"

import BloodLabDashboardsBiomarkerCardFromDiscreteResult from "./BloodLabDashboardsBiomarkerCardFromDiscreteResult"
import BloodLabDashboardsResultsMissingBiomarkerCard from "./BloodLabDashboardsResultsMissingBiomarkerCard"

const useStyles = makeAppStyles((theme) => ({
  container: {},
  groupedSection: {
    backgroundColor: "white",
    borderRadius: 12,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  separator: {
    background: colors.blueGray[50],
    height: 2,
    width: "calc(100% - 44px)",
  },
}))

export default function BloodLabDashboardsBiomarkerGrouping({
  discreteResults,
  showHighLowDescriptions,
  useOptimalRanges,
  biomarkerCustomDescriptionsMapping,
  biomarkersIdsWithoutResults,
}: {
  discreteResults: DiscreteResult[]
  showHighLowDescriptions?: boolean
  useOptimalRanges?: boolean
  biomarkerCustomDescriptionsMapping?: Record<
    string,
    BiomarkerCustomDescription
  >
  biomarkersIdsWithoutResults?: string[]
}) {
  const classes = useStyles()

  if (
    (!discreteResults || discreteResults.length === 0) &&
    (!biomarkersIdsWithoutResults || biomarkersIdsWithoutResults.length === 0)
  ) {
    return null
  }

  return (
    <div className={classes.groupedSection}>
      {interleave(
        discreteResults.map((discreteResult) => (
          <BloodLabDashboardsBiomarkerCardFromDiscreteResult
            discreteResult={discreteResult}
            showHighLowDescriptions={showHighLowDescriptions}
            useOptimalRanges={useOptimalRanges}
            biomarkerCustomDescription={
              biomarkerCustomDescriptionsMapping?.[
                discreteResult.relationships.biomarker.data?.id
              ]
            }
          />
        )),
        <div className={classes.separator}></div>
      )}
      {discreteResults.length && biomarkersIdsWithoutResults?.length ? (
        <div className={classes.separator}></div>
      ) : null}
      {biomarkersIdsWithoutResults &&
        interleave(
          biomarkersIdsWithoutResults.map((biomarkerId) => (
            <BloodLabDashboardsResultsMissingBiomarkerCard
              biomarkerId={biomarkerId}
              biomarkerCustomDescription={
                biomarkerCustomDescriptionsMapping?.[biomarkerId]
              }
            />
          )),
          <div className={classes.separator}></div>
        )}
    </div>
  )
}
