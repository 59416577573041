import { useCallback } from "react"

import { round } from "lodash"
import { FieldPath, FormProvider } from "react-hook-form"

import Button from "app/components/design-system/Button"
import PaymentCollectionConsent from "app/main/payment-collection/components/PaymentCollectionConsent"
import PaymentCollectionPaymentMethod from "app/main/payment-collection/components/PaymentCollectionPaymentMethod"
import PaymentCollectionPaymentMethodSecondary from "app/main/payment-collection/components/PaymentCollectionPaymentMethodSecondary"
import usePaymentCollectionForm from "app/main/payment-collection/hooks/use-payment-collection-form"
import usePaymentCollectionSubmitCallback, {
  PaymentCollectionSubmitError,
} from "app/main/payment-collection/hooks/use-payment-collection-submit-callback"
import {
  PaymentCollectionConfiguration,
  PaymentCollectionFormData,
  PaymentCollectionSubmitResult,
} from "app/main/payment-collection/types"
import { forEachError } from "app/utils/for-each-error"
import makeAppStyles from "app/utils/makeAppStyles"

const useStyles = makeAppStyles((theme) => ({
  container: {
    flex: "1 1 auto",
    order: 2,
    display: "flex",
    flexFlow: "column nowrap",
    gap: theme.spacing(3.0),
    [theme.breakpoints.up("md")]: {
      order: 1,
    },
  },
  submitButton: {
    alignSelf: "flex-end",
  },
}))

export interface PaymentCollectionFormProps {
  config: PaymentCollectionConfiguration
  onSubmitSuccess: (result: PaymentCollectionSubmitResult) => void
  token: string
}

export default function PaymentCollectionForm({
  config,
  onSubmitSuccess,
  token,
}: PaymentCollectionFormProps) {
  const classes = useStyles()
  const submitCallback = usePaymentCollectionSubmitCallback(token)
  const methods = usePaymentCollectionForm(config)
  const { isSubmitting, isValid } = methods.formState

  const onSubmit = useCallback(
    async (formData: PaymentCollectionFormData) => {
      try {
        onSubmitSuccess(await submitCallback(formData))
      } catch (error: any) {
        if (error instanceof PaymentCollectionSubmitError) {
          forEachError(error.validationErrors, (name, message) => {
            methods.setError(name as FieldPath<PaymentCollectionFormData>, {
              type: "server",
              message,
            })
          })
        }
      }
    },
    [methods.setError, submitCallback]
  )

  const total_in_cents = round(+config.pricing.order_pricing.total_price * 100)
  return (
    <FormProvider {...methods}>
      <form
        className={classes.container}
        onSubmit={methods.handleSubmit(onSubmit)}
      >
        <PaymentCollectionConsent config={config} />

        <PaymentCollectionPaymentMethod
          amount={
            config.is_split_payment && config.amount_to_split
              ? config.amount_to_split
              : total_in_cents
          }
        />

        {config.is_split_payment && config.amount_to_split && (
          <PaymentCollectionPaymentMethodSecondary
            amount={total_in_cents - config.amount_to_split}
          />
        )}

        <Button
          className={classes.submitButton}
          color="primary"
          disabled={!isValid}
          loading={isSubmitting}
          size="large"
          variant="contained"
          type="submit"
        >
          {"Submit"}
        </Button>
      </form>
    </FormProvider>
  )
}
