import { useMemo } from "react"

import {
  ColumnDef,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table"

import { BiomarkerCustomDescription } from "types/biomarker-custom-description"

declare module "@tanstack/react-table" {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface TableMeta<TData extends unknown> {
    count?: number
    isLoading: boolean
    isValidating: boolean
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface ColumnMeta<TData extends unknown, TValue> {
    /**
     * Styles to be applied to each cell in the column, including both data and header cells.
     *
     */
    cellStyle?: React.CSSProperties

    /**
     * Styles to be applied to each data cell in the column.
     */
    dataCellStyle?: React.CSSProperties

    /**
     * Styles to be applied to each header cell in the column.
     */
    headerCellStyle?: React.CSSProperties
  }
}

export interface UseTableProps {
  columns: ColumnDef<BiomarkerCustomDescription, any>[]
  data: BiomarkerCustomDescription[]
  isLoading: boolean
  isValidating: boolean
}

export default function useTable({
  columns,
  data,
  isLoading,
  isValidating,
}: UseTableProps) {
  const meta = useMemo(
    () => ({
      isLoading,
      isValidating,
    }),
    [isLoading, isValidating]
  )

  return useReactTable({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
    getRowId(row) {
      return row.id
    },
    meta,
  })
}
