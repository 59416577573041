import { useContext } from "react"

import { Grid } from "@material-ui/core"

import BodyText from "app/components/design-system/BodyText"
import { LAB_COMPANY_KEY } from "app/constants"
import {
  FeatureFlag,
  FeatureFlagContext,
} from "app/providers/FeatureFlagProvider"
import makeAppStyles from "app/utils/makeAppStyles"

import Settings from "../Settings"
import SettingsPageWrapper from "../SettingsPageWrapper"
import AccountCardContainer from "./account-card/AccountCardContainer"
import useLabCompanyAccounts from "./hooks/use-lab-company-accounts"
import { DisplayLabCompany } from "./types"

const styles = makeAppStyles({
  pageContainer: {
    margin: 24,
    minHeight: "100vh",
  },
  pageHeader: {
    marginBottom: 24,
  },
})

const ConnectedAccounts = () => {
  const pageLabel = "Insurance Order Labs"
  const classes = styles()
  const [featureFlags] = useContext(FeatureFlagContext)
  const isConnectedAccountSettingsPageEnabled =
    featureFlags[FeatureFlag.ConnectedAccountSettingsPage]
  const isQuestEnabled =
    featureFlags[FeatureFlag.ConnectedAccountSettingsPageQuest]

  const displayCompanies: DisplayLabCompany[] = [
    { key: LAB_COMPANY_KEY.HEALTH_GORILLA_LABCORP, display: "Labcorp" },
    ...(isQuestEnabled
      ? [{ key: LAB_COMPANY_KEY.HEALTH_GORILLA_QUEST, display: "Quest" }]
      : []),
  ]

  const {
    labCompanyAccounts,
    isLoadingLabCompanyAccounts,
    mutateLabCompanyAccounts,
    companiesWithAccounts,
  } = useLabCompanyAccounts()

  if (!isConnectedAccountSettingsPageEnabled) {
    return <Settings />
  }

  return (
    <>
      <SettingsPageWrapper pageLabel={pageLabel}>
        <div className={classes.pageContainer}>
          <Grid
            container
            justify="space-between"
            alignItems="center"
            className="items-start"
          >
            <Grid item xs={12} className={classes.pageHeader}>
              <BodyText weight="semibold" size="lg">
                Connected Accounts
              </BodyText>
            </Grid>
            {displayCompanies.map((company) => {
              return (
                <AccountCardContainer
                  key={company.key}
                  displayCompany={company}
                  accounts={labCompanyAccounts}
                  isLoadingLabCompanyAccounts={isLoadingLabCompanyAccounts}
                  mutateLabCompanyAccounts={mutateLabCompanyAccounts}
                  labCompaniesWithAccounts={companiesWithAccounts}
                />
              )
            })}
          </Grid>
        </div>
      </SettingsPageWrapper>
    </>
  )
}

export default ConnectedAccounts
