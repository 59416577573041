import * as Actions from "../actions"

const initialState = {
  id: null,
  has_patients: false,
  is_provider: false,
  is_staff: false,
  email: "",
  first_name: "",
  last_name: "",
  phone_number: "",
  force_password_change: false,
  data: {
    photoURL: "",
  },
  shouldShowEmailVerifiedMessage: false,
  shouldShowInitialPasswordSetMessage: false,
  // TODO: May be able to remove if we do not need webflow cookie
  role: "guest",
}

const user = function (state = initialState, action) {
  switch (action.type) {
    case Actions.SET_USER_DATA: {
      return {
        ...state,
        ...action.payload,
      }
    }
    case Actions.REMOVE_USER_DATA: {
      return {
        ...initialState,
      }
    }
    case Actions.USER_LOGGED_OUT: {
      return initialState
    }
    case Actions.USER_VERIFIED_EMAIL: {
      return {
        ...state,
        shouldShowVerifiedEmailMessage: true,
      }
    }
    case Actions.USER_SET_INITIAL_PASSWORD: {
      return {
        ...state,
        shouldShowInitialPasswordSetMessage: true,
      }
    }
    case Actions.USER_SHOWN_INITIAL_PASSWORD_SET_MESSAGE: {
      return {
        ...state,
        shouldShowInitialPasswordSetMessage: false,
      }
    }
    case Actions.USER_SET_PASSWORD: {
      return {
        ...state,
        shouldShowEmailVerifiedMessage: true,
      }
    }
    case Actions.USER_SHOWN_EMAIL_VERIFIED_MESSAGE: {
      return {
        ...state,
        shouldShowEmailVerifiedMessage: false,
      }
    }
    default: {
      return state
    }
  }
}

export default user
