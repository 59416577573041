import { useEffect } from "react"

import * as Sentry from "@sentry/react"

import useScript from "app/hooks/use-script"
import { facebookAppId } from "app/settings"

class FacebookSDKLoadFailedError extends Error {
  constructor(message) {
    super(message)
    this.name = "FacebookSDKLoadFailedError"
  }
}

export default function useFacebookSDK() {
  const status = useScript("https://connect.facebook.net/en_US/sdk.js", {
    crossOrigin: "anonymous",
  })

  useEffect(() => {
    if (status === "ready") {
      window.fbAsyncInit = function () {
        " "
      }
      window.FB.init({
        appId: facebookAppId,
        autoLogAppEvents: true,
        xfbml: true,
        version: "v11.0",
      })
    }

    if (status === "error") {
      Sentry.captureException(
        new FacebookSDKLoadFailedError("Failed to load Facebook SDK")
      )
    }
  }, [status])

  return status
}
