import { API } from "app/api"
import { InsuranceToggleDirection } from "app/main/checkout/orderSidebar/insurance/InsuranceToggle"
import { PatientInsurance } from "app/types"
import { handleApiError } from "app/utils"

export const GET_ORDER = "[PATIENT CHECKOUT] GET ORDER"
export const UPDATE_ORDER = "[PATIENT CHECKOUT] UPDATE ORDER"
export const SET_LOADING = "[PATIENT CHECKOUT] SET LOADING"
export const SET_UPDATING_ORDER = "[PATIENT CHECKOUT] SET UPDATING ORDER"
export const SET_ERROR_CODE = "[PATIENT CHECKOUT] SET ERROR CODE"
export const SET_TOGGLE_ERROR = "[PATIENT CHECKOUT] SET TOGGLE ERROR"

export enum OrderRequestStatus {
  LOADING = "loading",
  UPDATING = "updating",
  ERROR = "error",
  UPDATE_FAILED = "update_failed",
  SUCCESS = "success",
}

export function getOrder(token: string) {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING })
    try {
      const response = await API.PatientCheckout.get(token)
      dispatch({
        type: GET_ORDER,
        payload: response.data,
      })

      return response.data
    } catch (error: any) {
      const errorCode = error?.response?.data?.code ?? ""

      dispatch({ type: SET_ERROR_CODE, payload: errorCode })
    }
  }
}

export function updateOrder({
  token,
  zipcode,
  billing_state,
  selected_payment_method,
  is_medicare_used,
  medicare_form_data,
  sample_collection_state,
  phlebotomy_providers,
  birthday,
}: {
  token: string
  zipcode?: string
  billing_state?: string
  selected_payment_method?: string
  is_medicare_used?: boolean
  medicare_form_data?: Partial<PatientInsurance>
  sample_collection_state?: string
  phlebotomy_providers?: string[]
  birthday?: string
}) {
  return async (dispatch) => {
    const response = await API.PatientCheckout.update(token, {
      zipcode,
      billing_state,
      selected_payment_method,
      is_medicare_used,
      medicare_form_data,
      sample_collection_state,
      phlebotomy_providers,
      birthday,
    })
    dispatch({
      type: UPDATE_ORDER,
      payload: response.data,
    })

    return response.data
  }
}

export function disableLineItems(token, orderedTestIds) {
  return async (dispatch) => {
    const response = await API.PatientCheckout.disableLineItems(
      token,
      orderedTestIds
    )
    dispatch({
      type: UPDATE_ORDER,
      payload: response.data,
    })
    return response.data
  }
}

export function enableLineItems(token, orderedTestIds) {
  return async (dispatch) => {
    const response = await API.PatientCheckout.enableLineItems(
      token,
      orderedTestIds
    )
    dispatch({
      type: UPDATE_ORDER,
      payload: response.data,
    })
    return response.data
  }
}

export function toggleInsurance(orderId, direction, companyKey, token) {
  const request = API.PatientCheckout.toggleInsurance(
    orderId,
    direction,
    companyKey,
    token
  )

  return async (dispatch) => {
    await dispatch({
      type: SET_UPDATING_ORDER,
      payload: {
        payInsuranceSelected: direction === InsuranceToggleDirection.INSURED,
      },
    })
    try {
      const response = await request
      await dispatch({
        type: UPDATE_ORDER,
        payload: response.data,
      })
    } catch (error: any) {
      const errorCode = error?.response?.data?.code ?? ""
      dispatch({ type: SET_TOGGLE_ERROR, payload: errorCode })
      dispatch(handleApiError(error))
    }
  }
}
