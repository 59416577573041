import { colors, navy, shadows } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"

const useStyles = makeAppStyles(() => ({
  customTextInput: {
    "& .MuiInput-underline:before": {
      borderBottom: "none",
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
      borderBottom: "none",
    },
    "& .MuiInputBase-root": {
      border: `1px solid ${colors.blueGray[200]}`,
      background: "white",
      boxShadow: shadows.sm,
      borderRadius: 5,
      paddingLeft: 10,
      height: 38,
      "&:hover": {
        border: `1px solid ${navy}`,
      },
    },
    "& .MuiInputBase-input": {
      padding: "4px 10px 4px 0px",
      fontSize: 15,
    },
  },
  customTextInputBold: {
    "& .MuiInputBase-input": {
      fontWeight: "bold",
    },
  },
  customInput: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: `1px solid ${colors.blueGray[300]}`,
    },
    "& .MuiInputBase-root": {
      height: 38,
      background: "white",
      boxShadow: shadows.sm,
      borderRadius: 5,
      width: "100%",
    },
    "& .MuiInputBase-input": {
      padding: "5px 10px",
      fontSize: 15,
    },
    "& .MuiSelect-select:focus": {
      background: colors.blueGray[50],
    },
    width: "100%",
  },
  errorInput: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: `1px solid ${colors.red[600]}`,
    },
  },
}))

const useInputFormStyles = () => {
  const classes = useStyles()

  return classes
}

export default useInputFormStyles
