import { useState } from "react"

import clsx from "clsx"
import { useForm, FormProvider } from "react-hook-form"

import { zodResolver } from "@hookform/resolvers/zod"
import { Divider, Grid } from "@material-ui/core"

import DesignSystemButton from "app/components/design-system/Button"
import { ModalActions, ModalContent } from "app/components/modals"
import { colors } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"

import HookFormTextField from "./HookFormTextField"
import { AddAccountSchema } from "./add-account-schema"

const useStyles = makeAppStyles((theme) => ({
  formContainer: {
    padding: "8px",
  },
  divider: {
    padding: "24px 8px",
  },
  button: {
    marginLeft: theme.spacing(2),
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
  disabledButton: {
    backgroundColor: colors.coolGray[400],
    border: `0px`,
  },
}))

interface AddAccountFormProps {
  companyName: string
  defaultFormValues: any
  onSubmit: (inp: any) => Promise<void>
  onClose: () => void
}

const AddAccountForm = ({
  companyName,
  defaultFormValues,
  onSubmit,
  onClose,
}: AddAccountFormProps) => {
  const classes = useStyles()
  const formSchema = AddAccountSchema()
  const formMethods = useForm({
    defaultValues: defaultFormValues,
    resolver: zodResolver(formSchema),
    mode: "onChange",
  })

  const [isSubmitting, setIsSubmitting] = useState(false)

  const handleSubmit = async () => {
    setIsSubmitting(true)
    await onSubmit(formMethods.getValues())
    setIsSubmitting(false)
  }

  return (
    <>
      <ModalContent>
        <FormProvider {...formMethods}>
          <form onSubmit={formMethods.handleSubmit(handleSubmit)}>
            <Grid container className={classes.formContainer}>
              <HookFormTextField
                label="Practice Name"
                fieldName="practiceName"
              />
              <HookFormTextField
                label="Street Address"
                fieldName="practiceStreetAddress"
              />
              <HookFormTextField
                label="City"
                fieldName="practiceCity"
                gridSize={4}
              />
              <HookFormTextField
                label="State"
                fieldName="practiceState"
                gridSize={4}
              />
              <HookFormTextField
                label="Zip Code"
                fieldName="practiceZip"
                gridSize={4}
              />
              <HookFormTextField
                label="Clinic Phone"
                fieldName="clinicPhoneNumber"
              />
              <HookFormTextField
                label="Clinic Website"
                fieldName="clinicWebsite"
                required={false}
              />
              <HookFormTextField
                label="Practice NPI"
                fieldName="practiceNPI"
                required={false}
              />
              <Grid item xs={12} className={classes.divider}>
                <Divider />
              </Grid>
              <HookFormTextField
                label="Provider Name"
                fieldName="providerName"
              />
              <HookFormTextField label="Provider NPI" fieldName="providerNPI" />
              <HookFormTextField
                label={`${companyName} Account Representative`}
                fieldName="accountRep"
              />
              <HookFormTextField
                label={`${companyName} Account Representative Email Address`}
                fieldName="accountRepEmail"
              />
              <HookFormTextField
                label={`${companyName} Account Number`}
                fieldName="accountNumber"
              />
            </Grid>
          </form>
        </FormProvider>
      </ModalContent>
      <ModalActions>
        <div className={classes.buttonContainer}>
          <DesignSystemButton
            color="secondary"
            onClick={onClose}
            className={classes.button}
          >
            Cancel
          </DesignSystemButton>
          <DesignSystemButton
            onClick={handleSubmit}
            className={clsx(
              classes.button,
              !formMethods.formState.isValid && classes.disabledButton
            )}
            disabled={!formMethods.formState.isValid}
            loading={isSubmitting}
          >
            Connect Account
          </DesignSystemButton>
        </div>
      </ModalActions>
    </>
  )
}

export default AddAccountForm
