import { useCallback } from "react"

import { CircularProgress } from "@material-ui/core"
import { cn } from "@rupahealth/design"

import RupaCheckbox from "app/components/RupaCheckbox"
import useUserClinicAccessConfigurations from "app/patient-portal/lab-results-access/hooks/use-user-clinic-access-configurations"
import useCachedResource from "app/swr/hooks/use-cached-resource"
import { UserClinicAccessSharingStatus } from "app/types"
import { Clinic } from "types/clinic"
import { UserClinicAccessConfiguration } from "types/user-clinic-access-configuration"

interface Props {
  userId: string
  clinicsToShareWith: string[]
  onToggleClinic: (clinicId: string) => void
  disabled?: boolean
  className?: string
  uploadRequestedByClinicId?: string | null
}

export const Step2PatientPortalShareAccess: React.FC<Props> = ({
  userId,
  clinicsToShareWith,
  onToggleClinic,
  uploadRequestedByClinicId,
  disabled,
  className,
}) => {
  const { userClinicAccessConfigurations, isLoading } =
    useUserClinicAccessConfigurations(userId)

  const clinicIsChecked = useCallback(
    (clinicId: string) => {
      return (
        clinicsToShareWith.includes(clinicId) ||
        uploadRequestedByClinicId === clinicId
      )
    },
    [clinicsToShareWith, uploadRequestedByClinicId]
  )

  const clinicIsDisabled = useCallback(
    (clinicId: string) => {
      return disabled || uploadRequestedByClinicId === clinicId
    },
    [disabled, uploadRequestedByClinicId]
  )

  if (isLoading) {
    return <CircularProgress />
  } else if (userClinicAccessConfigurations.length === 0) {
    return null
  }

  return (
    <div className={className}>
      <div className="font-semibold">
        Share these results with my practitioner at:
      </div>
      <hr className="h-[1px] bg-slate-200 w-full" />
      {userClinicAccessConfigurations.map((userClinicAccessConfig, idx) => (
        <ClinicAccessConfigCheckbox
          key={`clinic-access-config-${idx}`}
          userClinicAccessConfig={userClinicAccessConfig}
          getIsChecked={clinicIsChecked}
          getIsDisabled={clinicIsDisabled}
          onToggleClinic={onToggleClinic}
        />
      ))}
    </div>
  )
}

interface ClinicCheckboxProps
  extends Pick<Props, "onToggleClinic" | "disabled"> {
  userClinicAccessConfig: UserClinicAccessConfiguration
  getIsChecked: (clinicId: string) => boolean
  getIsDisabled: (clinicId: string) => boolean
}

const ClinicAccessConfigCheckbox: React.FC<ClinicCheckboxProps> = ({
  userClinicAccessConfig,
  getIsChecked,
  getIsDisabled,
  onToggleClinic,
}) => {
  const clinic = useCachedResource<Clinic>(
    userClinicAccessConfig.relationships.clinic.data
  )
  if (!clinic) return null

  const disabledAndChecked = [
    UserClinicAccessSharingStatus.SPECIFIC_AND_FUTURE,
    UserClinicAccessSharingStatus.ALL,
  ].includes(userClinicAccessConfig.attributes.sharing_status)

  const checked = disabledAndChecked || getIsChecked(clinic.id)
  const isDisabled = disabledAndChecked || getIsDisabled(clinic.id)

  const onClick = () => {
    if (isDisabled) return
    onToggleClinic(clinic.id)
  }

  return (
    <div
      className={cn("group flex items-center gap-2", {
        "cursor-pointer": !isDisabled,
      })}
      onClick={onClick}
    >
      <RupaCheckbox
        checked={checked}
        disabled={isDisabled}
        className="rounded-[4px] text-xs p-[2px] pt-[1px]"
      />

      <div>{clinic.attributes.name}</div>
    </div>
  )
}
