import { SpecimenIssueActionUnion } from "app/constants.typed"
import useEventCallback from "app/hooks/use-event-callback"
import useHandleApiError from "app/hooks/use-handle-api-error"
import { getIdentifier } from "app/swr/helpers/resource"
import useMutateResource from "app/swr/hooks/use-mutate-resource"
import { Resource, ResourceResponse } from "app/swr/types"
import resourceRequest from "app/swr/utils/resource-request"
import { SpecimenIssueNormalized } from "types/specimen-issue"

interface Props {
  specimenIssueId: string
  actionChosen: SpecimenIssueActionUnion
}
const useResolveSpecimenIssue = () => {
  const mutateResource = useMutateResource()
  const handleApiError = useHandleApiError()
  const updateItemCache = async (item: Resource) => {
    return mutateResource(getIdentifier(item), item)
  }

  return useEventCallback(async ({ specimenIssueId, actionChosen }: Props) => {
    try {
      const patchRequest = resourceRequest<
        ResourceResponse<SpecimenIssueNormalized>
      >({
        method: "PATCH",
        url: `/specimen_issue/${specimenIssueId}/`,
        include: ["ordered_test.kit.order"],
        data: {
          data: {
            id: specimenIssueId,
            type: "specimen_issue",
            attributes: { action_chosen: actionChosen },
          },
        },
      })

      const { data, included } = await patchRequest
      await Promise.all([data, ...(included || [])].map(updateItemCache))

      return data
    } catch (error) {
      handleApiError(error)
    }
  })
}

export default useResolveSpecimenIssue
