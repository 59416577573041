import { Dispatch, SetStateAction } from "react"

import { Dispatch as ReduxDispatch } from "redux"

import { STATUS_CONFIGURATION_TYPES } from "app/components/hooks/use-status-components/consts"
import {
  LabCompany,
  OrderedTest,
  Patient,
  PatientOrdersOrder,
  Practitioner,
} from "app/types"
import { SpecimenIssue } from "types/specimen-issue"

import { BaseStatusConfigurationProps } from "./types"

export interface SpecimenIssueProps {
  specimenIssue: SpecimenIssue | undefined
  specimenIssueShowMore: boolean
  setSpecimenIssueShowMore: Dispatch<SetStateAction<boolean>>
}

export interface StatusConfigurationProps extends BaseStatusConfigurationProps {
  type: typeof STATUS_CONFIGURATION_TYPES.PATIENT_ORDERS
  orderedTest: OrderedTest
  order: PatientOrdersOrder
  patient: Patient
  labCompany: LabCompany
  specimenIssue: SpecimenIssue | undefined
  specimenIssueShowMore: boolean
  setSpecimenIssueShowMore: Dispatch<SetStateAction<boolean>>
  specimenIssueResolveModal: any
  pathname: string
  currentPractitioner: Practitioner
  dispatch: ReduxDispatch<any>
  requisitionAvailable?: boolean
}

export function isPatientOrdersProps(
  props: BaseStatusConfigurationProps
): props is StatusConfigurationProps {
  return props.type === STATUS_CONFIGURATION_TYPES.PATIENT_ORDERS
}
