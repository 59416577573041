import { useState } from "react"
import { useDispatch } from "react-redux"

import useEventCallback from "app/hooks/use-event-callback"
import { useCheckoutTokenFromLocation } from "app/main/patient-checkout/hooks"
import * as actions from "app/main/patient-checkout/store/actions"
import { handleApiError } from "app/utils"

export default function useUpdateLineItems() {
  const [isUpdatingLineItems, setIsUpdatingLineItems] = useState(false)
  const [updatingOrderedTestIds, setUpdatingOrderedTestIds] = useState<
    string[]
  >([])
  const dispatch = useDispatch()
  const token = useCheckoutTokenFromLocation()

  const disableLineItems = useEventCallback(async (orderedTestIds) => {
    setUpdatingOrderedTestIds(orderedTestIds)
    setIsUpdatingLineItems(true)

    try {
      await dispatch(actions.disableLineItems(token, orderedTestIds))
    } catch (error) {
      dispatch(handleApiError(error))
    } finally {
      setUpdatingOrderedTestIds([])
      setIsUpdatingLineItems(false)
    }
  })

  const enableLineItems = useEventCallback(async (orderedTestIds) => {
    setUpdatingOrderedTestIds(orderedTestIds)
    setIsUpdatingLineItems(true)

    try {
      await dispatch(actions.enableLineItems(token, orderedTestIds))
    } catch (error) {
      dispatch(handleApiError(error))
    } finally {
      setUpdatingOrderedTestIds([])
      setIsUpdatingLineItems(false)
    }
  })

  return {
    isUpdatingLineItems,
    updatingOrderedTestIds,
    disableLineItems,
    enableLineItems,
  }
}
