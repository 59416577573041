import { PhlebotomistFeature } from "app/types"

export const CLEAR_FEATURES = "[PHLEBOTOMIST MAP] CLEAR_FEATURES"
export const RECEIVE_COLLECTION = "[PHLEBOTOMIST MAP] RECEIVE_COLLECTION"
export const SET_SELECTED_FEATURE_ID =
  "[PHLEBOTOMIST MAP] SET_SELECTED_FEATURE_ID"

export function receiveCollection(collection: PhlebotomistFeature[]) {
  return { type: RECEIVE_COLLECTION, payload: collection }
}

export function clearFeatures() {
  return { type: CLEAR_FEATURES }
}

export function setSelectedFeatureId(selectedFeatureId?: string) {
  return { type: SET_SELECTED_FEATURE_ID, payload: selectedFeatureId }
}
