import BodyText from "app/components/design-system/BodyText"

interface Props {
  tableType: string
}

const EmptySnippetsTable = ({ tableType }: Props) => {
  return (
    <div className="w-full bg-white py-[42px] border-[2px] border-dashed border-slate-300 rounded-md text-center">
      <BodyText>No {tableType} Snippets have been created yet.</BodyText>
    </div>
  )
}

export default EmptySnippetsTable
