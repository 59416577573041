import * as React from "react"

import { colors } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"

const useStyles = makeAppStyles((theme) => ({
  root: {
    background: colors.coolGray[50],
    display: "flex",
    flexFlow: "column nowrap",
    padding: theme.spacing(3.5),
    height: "100%",
    width: "100%",
    gap: theme.spacing(3.0),
    [theme.breakpoints.up("md")]: {
      flexFlow: "row nowrap",
      justifyContent: "center",
    },
  },
  sidebar: {
    order: 1,
    display: "flex",
    flexFlow: "column nowrap",
    gap: theme.spacing(1.5),
    [theme.breakpoints.up("md")]: {
      flex: "1 1 auto",
      maxWidth: 360,
    },
  },
  main: {
    order: 2,
    [theme.breakpoints.up("md")]: {
      flex: "2 2 auto",
      maxWidth: 900,
    },
  },
}))

export interface FoodPlanLayoutProps {
  main: React.ReactNode
  sidebar: React.ReactNode
}

export default function FoodPlanLayout({ main, sidebar }: FoodPlanLayoutProps) {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <div className={classes.sidebar}>{sidebar}</div>

      <div className={classes.main}>{main}</div>
    </div>
  )
}
