import * as React from "react"

import { TableCell, TableRow } from "@material-ui/core"
import { Skeleton } from "@material-ui/lab"

import BodyText from "app/components/design-system/BodyText"
import { useTableRowContext } from "app/dashboard/contexts/TableRowProvider"
import { colors, dashboardHighlight } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"

import { TableRowDetailContext } from "../TableRenderer"
import TableRowDetailKitActions from "./TableRowDetailKitActions"
import TableRowDetailKitName from "./TableRowDetailKitName"
import TableRowDetailStatus from "./TableRowDetailKitStatus"

const useRowDetailStyles = makeAppStyles((theme) => ({
  kitRow: {
    backgroundColor: dashboardHighlight,
    height: 120,
  },
  kitRowNameCell: {
    paddingLeft: 0,
  },
  kitRowActionPlaceholder: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: theme.spacing(1.0),
  },
  kitRowActionPlaceholderButton: {
    borderRadius: 6,
  },
  kitEmptyRow: {
    backgroundColor: dashboardHighlight,
    height: 80,
  },
  kitEmptyRowText: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: theme.spacing(1.0),
    color: colors.blueGray[500],
  },
}))

const TableRowDetailRenderer: React.FC = () => {
  const { data, isLoading, row: parent } = useTableRowContext()
  return (
    <>
      {isLoading ? (
        <TableRowDetailPlaceholderRows parent={parent} />
      ) : data?.length ? (
        data?.map((kit) => (
          <TableRowDetailRow
            key={kit.id}
            columnCount={parent.getVisibleCells().length}
            nameSlot={<TableRowDetailKitName kit={kit} />}
            statusSlot={
              <TableRowDetailStatus kit={kit} order={parent.original} />
            }
            actionsSlot={
              <TableRowDetailKitActions kit={kit} order={parent.original} />
            }
          />
        ))
      ) : (
        <TableRowDetailEmptyRow parent={parent} />
      )}
    </>
  )
}

const TableRowDetailRow = ({
  columnCount,
  nameSlot,
  statusSlot,
  actionsSlot,
}: {
  columnCount: number
  nameSlot: React.ReactNode
  statusSlot: React.ReactNode
  actionsSlot: React.ReactNode
}) => {
  const classes = useRowDetailStyles()
  return (
    <TableRow className={classes.kitRow}>
      <TableCell />
      <TableCell className={classes.kitRowNameCell}>{nameSlot}</TableCell>
      <TableCell colSpan={columnCount - 3}>{statusSlot}</TableCell>
      <TableCell>{actionsSlot}</TableCell>
    </TableRow>
  )
}

const TableRowDetailPlaceholderRows = ({
  parent,
}: {
  parent: TableRowDetailContext["row"]
}) => {
  const classes = useRowDetailStyles()
  return (
    <>
      {parent.original.relationships.kits.data.map((identifier) => (
        <TableRowDetailRow
          key={identifier.id}
          columnCount={parent.getVisibleCells().length}
          nameSlot={
            <>
              <Skeleton
                animation="wave"
                height={32}
                width="75%"
                variant="text"
              />
              <Skeleton
                animation="wave"
                height={24}
                width="40%"
                variant="text"
              />
              <Skeleton
                animation="wave"
                height={28}
                width={60}
                variant="text"
                style={{ borderRadius: 8 }}
              />
            </>
          }
          statusSlot={
            <>
              <Skeleton
                animation="wave"
                height={20}
                width={120}
                variant="text"
              />
              <Skeleton
                animation="wave"
                height={24}
                width="20%"
                variant="text"
              />
              <Skeleton
                animation="wave"
                height={18}
                width="100%"
                variant="text"
              />
            </>
          }
          actionsSlot={
            <div className={classes.kitRowActionPlaceholder}>
              <Skeleton
                animation="wave"
                className={classes.kitRowActionPlaceholderButton}
                height={36}
                width={36}
                variant="rect"
              />
              <Skeleton
                animation="wave"
                className={classes.kitRowActionPlaceholderButton}
                height={36}
                width={36}
                variant="rect"
              />
              <Skeleton
                animation="wave"
                className={classes.kitRowActionPlaceholderButton}
                height={36}
                width={36}
                variant="rect"
              />
              <Skeleton
                animation="wave"
                className={classes.kitRowActionPlaceholderButton}
                height={36}
                width={36}
                variant="rect"
              />
            </div>
          }
        />
      ))}
    </>
  )
}

const TableRowDetailEmptyRow = ({
  parent,
}: {
  parent: TableRowDetailContext["row"]
}) => {
  const classes = useRowDetailStyles()
  return (
    <TableRow className={classes.kitEmptyRow}>
      <TableCell colSpan={parent.getVisibleCells().length}>
        <div className={classes.kitEmptyRowText}>
          <BodyText color="inherit" weight="semibold" size="sm">
            Unable to find any tests for this order.
          </BodyText>
        </div>
      </TableCell>
    </TableRow>
  )
}

export default TableRowDetailRenderer
