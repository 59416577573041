import { forwardRef, ComponentProps } from "react"

import { ReactComponent as CustomTaskTypeIcon } from "app/assets/icons/results-summary/custom-task-type.svg"
import { ReactComponent as LabTestingTaskTypeIcon } from "app/assets/icons/results-summary/lab-testing-task-type.svg"
import { ReactComponent as MedicationTaskTypeIcon } from "app/assets/icons/results-summary/medication-task-type.svg"
import { ReactComponent as MovementMindfulnessSleepTaskTypeIcon } from "app/assets/icons/results-summary/movement-mindfulness-sleep-task-type.svg"
import { ReactComponent as NutitionTaskTypeIcon } from "app/assets/icons/results-summary/nutrition-task-type.svg"
import { ReactComponent as SupplementTaskTypeIcon } from "app/assets/icons/results-summary/supplement-task-type.svg"
import { TaskTypesLiteral } from "types/results-interpretation"

export type TaskIconProps = ComponentProps<typeof CustomTaskTypeIcon> & {
  taskType: TaskTypesLiteral
}

export default forwardRef<SVGSVGElement, TaskIconProps>(function TaskIcon(
  { taskType, ...props },
  ref
) {
  switch (taskType) {
    case "lab_testing":
      return <LabTestingTaskTypeIcon {...props} viewBox="0 0 11 12" ref={ref} />
    case "medication":
      return <MedicationTaskTypeIcon {...props} viewBox="0 0 9 14" ref={ref} />
    case "movement_mindfulness_sleep":
      return (
        <MovementMindfulnessSleepTaskTypeIcon
          {...props}
          viewBox="0 0 9 12"
          ref={ref}
        />
      )
    case "nutrition":
      return <NutitionTaskTypeIcon {...props} viewBox="0 0 8 13" ref={ref} />
    case "supplement":
      return <SupplementTaskTypeIcon {...props} viewBox="0 0 14 12" ref={ref} />
    case "custom":
    default:
      return <CustomTaskTypeIcon {...props} viewBox="0 0 12 12" ref={ref} />
  }
})
