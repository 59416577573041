import { useMemo, useState } from "react"

import Grow from "@material-ui/core/Grow"
import IconButton from "@material-ui/core/IconButton"
import Typography from "@material-ui/core/Typography"
import { fade } from "@material-ui/core/styles"
import { Skeleton } from "@material-ui/lab"

import CrossIcon from "app/assets/icons/light-blue-cross-circle.svg"
import makeAppStyles from "app/utils/makeAppStyles"

import { colors, navy } from "../../theme"

const REMOVE_TIMEOUT_DURATION = 250

const useStyles = makeAppStyles({
  panelItemPricePlaceholder: {
    backgroundColor: colors.blueGray[200],
    borderRadius: 12,
    marginLeft: "auto",
    height: 12,
    marginTop: 5,
    marginBottom: 5,
    width: 50,
  },
})

interface Props {
  title: string
  cost?: number | string
  discountedCost?: number | string
  onRemove?: () => void
  showPricing?: boolean
}

export function PanelItem({
  title,
  cost,
  discountedCost,
  onRemove,
  showPricing = true,
}: Props) {
  const [visible, setVisible] = useState(true)

  const handleRemove = useMemo(() => {
    if (!onRemove) {
      return null
    }

    return () => {
      setVisible(false)
      setTimeout(() => onRemove(), REMOVE_TIMEOUT_DURATION)
    }
  }, [onRemove])

  return (
    <Grow
      in={visible}
      style={{ transformOrigin: "50% 0 0", marginBottom: 4 }}
      timeout={REMOVE_TIMEOUT_DURATION}
    >
      <div
        className="flex items-start rounded bg-white px-2 py-2"
        style={{ border: `1px solid ${fade(navy, 0.1)}` }}
      >
        <Typography className="text-sm font-bold text-gray-800 flex-grow">
          {title}
        </Typography>

        {showPricing && (
          <PanelItemPrice cost={cost} discountedCost={discountedCost} />
        )}
        {handleRemove && (
          <IconButton className="ml-2 p-0 mt-1" onClick={handleRemove}>
            <img src={CrossIcon} alt="Remove" />
          </IconButton>
        )}
      </div>
    </Grow>
  )
}

const PanelItemPrice = ({ cost, discountedCost }) => {
  const classes = useStyles()
  return cost ? (
    <Typography className="font-bold text-base15" align="right">
      {discountedCost ? (
        <>
          <span className="text-gray-500 line-through">${cost}</span>
          <span className="ml-2">${discountedCost}</span>
        </>
      ) : (
        <span className="ml-2">${cost}</span>
      )}
    </Typography>
  ) : (
    <Skeleton
      className={classes.panelItemPricePlaceholder}
      variant="rect"
      animation="wave"
    />
  )
}
