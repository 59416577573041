import { forwardRef, PropsWithChildren } from "react"

import clsx from "clsx"

import { colors } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"

const useStyles = makeAppStyles((theme) => ({
  toolbar: {
    display: "flex",
    flexFlow: "row nowrap",
    gap: theme.spacing(2.0),
    borderBottom: `1px solid ${colors.blueGray[300]}`,
    "& > *": {
      flex: "0 1 auto",
    },
  },
}))

export default forwardRef<
  any,
  PropsWithChildren<{ className?: string; [key: string]: unknown }>
>(function Toolbar({ children, className, ...props }, ref) {
  const classes = useStyles()
  return (
    <div {...props} className={clsx(className, classes.toolbar)} ref={ref}>
      {children}
    </div>
  )
})
