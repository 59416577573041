import { useContext } from "react"

import ComparisonCallToActionContext from "../contexts/ComparisonCallToActionContext"

/**
 * Hook that returns the current call to action context
 *
 * @returns the call to action
 */
export default function useComparisonCallToActionContext() {
  return useContext(ComparisonCallToActionContext)
}
