import BodyText from "app/components/design-system/BodyText"

import { BiomarkerCustomDescriptionStatuses } from "../../constants"
import BioamrkerCustomDescriptionStatus from "../BiomarkerCustomDescriptionStatus"
import columnHelper from "./column-helper"

export const biomarkerHighDescriptionStateColumn = columnHelper.accessor(
  "attributes.high_description",
  {
    cell: (info) => (
      <BioamrkerCustomDescriptionStatus
        status={
          info.getValue()
            ? BiomarkerCustomDescriptionStatuses.EDITED
            : BiomarkerCustomDescriptionStatuses.DEFAULT
        }
      />
    ),
    header: (info) => (
      <BodyText size="sm" weight="semibold">
        ▲ High Description
      </BodyText>
    ),
    id: "biomarkerHighDescriptionState",
    size: 200,
    minSize: 200,
  }
)
