import { Link } from "react-router-dom"

import { Button, Dialog, Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

import OrderSuccessImage from "app/assets/images/order-success.svg"
import { ORDER_STATUS, ORDER_NOTIFICATION_METHOD } from "app/constants"
import { formatDate } from "app/utils"
import { getOrderTypeLabel } from "app/utils/order-utils"

const useStyles = makeStyles((theme) => ({}))

export function OrderEditConfirmationModal({ order, open, onClose }) {
  const classes = useStyles({})
  const patientName = order?.patient?.first_name

  const textMessageCopy =
    order.notification_method === ORDER_NOTIFICATION_METHOD.EMAIL_AND_TEXT
      ? " and text message"
      : ""

  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="order-edit-confirmation-title"
      open={open}
      className={classes.root}
      fullWidth
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth="md"
    >
      <div className="flex flex-col items-center p-12">
        <img
          src={OrderSuccessImage}
          alt={`${getOrderTypeLabel(
            order.requires_vendor_physician_authorization
          )} Successful`}
        />
        <Typography color="textPrimary" variant="h5" className="mt-8 mb-2">
          {order.status === ORDER_STATUS.SCHEDULED
            ? `${getOrderTypeLabel(
                order.requires_vendor_physician_authorization
              )} Scheduled Successfully!`
            : "Thank You"}
        </Typography>

        <Typography
          color="textPrimary"
          className="fs-exclude max-w-lg text-center whitespace-pre-wrap"
          id="order-edit-confirmation-title"
        >
          {order.status === ORDER_STATUS.SCHEDULED
            ? `${patientName} will receive an email${textMessageCopy} with the next steps on ${formatDate(
                order.date_scheduled
              )}.`
            : `We sent ${patientName} an email with the updated ${getOrderTypeLabel(
                order.requires_vendor_physician_authorization
              ).toLowerCase()}.`}
        </Typography>

        <Link to="/dashboard" style={{ textDecoration: "none" }}>
          <Button
            variant="contained"
            color="primary"
            className="py-4 px-16 mt-12"
            onClick={onClose}
          >
            Return to Dashboard
          </Button>
        </Link>
      </div>
    </Dialog>
  )
}
