import { useMemo, useEffect } from "react"

import { useFormContext } from "react-hook-form"

import { Box, Link, Typography, makeStyles } from "@material-ui/core"

import BodyText from "app/components/design-system/BodyText"
import { orderHasActiveBioReferenceTests } from "app/dataServices/orderDataService"
import useFeatureFlag, { FeatureFlag } from "app/hooks/use-feature-flag"
import { PatientPortalOrder } from "app/types"

import { FieldNames } from "../fields"
import { isPaymentRequired } from "../utils/checkout-utils"
import InsuranceToggle from "./insurance-payment/InsuranceToggle"
import PaymentMethods from "./payment-methods/PaymentMethods"

const useStyles = makeStyles({
  BRLPaymentMessage: {
    opacity: 0.75,
    marginTop: 25,
    marginBottom: 25,
  },
})

interface PatientPaymentProps {
  checkoutToken: string
  order: PatientPortalOrder
  className?: string
  isUpdatingOrder: boolean
  updateOrder: (string) => {}
  orderRequestStatus: string
  orderInsuredCompanies: string[]
}

const PatientPayment = ({
  checkoutToken,
  order,
  className,
  isUpdatingOrder,
  updateOrder,
  orderRequestStatus,
  orderInsuredCompanies,
}: PatientPaymentProps) => {
  const classes = useStyles()

  const [splititDisabled] = useFeatureFlag(FeatureFlag.SplitItDisabled)

  const { setValue } = useFormContext()

  const clearPaymentMethod = () => {
    setValue(FieldNames.PAYMENT_METHOD, undefined)
  }

  const showInsuranceToggle = useMemo(() => {
    return order.insurance_enabled_for?.length > 0
  }, [order.insurance_enabled_for])

  // Clear the payment method if the order doesn't require payment
  useEffect(() => {
    if (!isPaymentRequired(order)) {
      clearPaymentMethod()
    }
  }, [order])

  const hasBRLTests = orderHasActiveBioReferenceTests(order)

  return (
    <div className={className}>
      <Typography color="textPrimary" style={{ fontSize: 24 }} variant="h4">
        Payment
      </Typography>
      {splititDisabled && <LegacyPaymentPlanPrompt />}
      {showInsuranceToggle && (
        <InsuranceToggle
          order={order}
          orderRequestStatus={orderRequestStatus}
          checkoutToken={checkoutToken}
          orderInsuredCompanies={orderInsuredCompanies}
        />
      )}
      {isPaymentRequired(order) && (
        <PaymentMethods
          order={order}
          isUpdatingOrder={isUpdatingOrder}
          updateOrder={updateOrder}
        />
      )}
      {hasBRLTests && (
        <BodyText size="sm" className={classes.BRLPaymentMessage}>
          Payment for BioReference Services is collected by Rupa and will be
          remitted to BioReference.
        </BodyText>
      )}
    </div>
  )
}

const LegacyPaymentPlanPrompt = () => (
  <Typography color="textPrimary" className="my-4">
    <span className="opacity-75">
      If you’d like, we can split up this payment over a span of 3 months on a
      payment plan. Reach out to us at
    </span>{" "}
    {/* The default style applied in FuseLayout is more specific than anything we can */}
    {/* apply via tailwind or material, so we are forced to use an extra parent component. */}
    <Box component="span" className="underline inline">
      <Link
        color="textPrimary"
        className="underline font-semibold"
        href="mailto:support@rupahealth.com"
      >
        support@rupahealth.com
      </Link>
    </Box>
    <span className="opacity-75"> so we can set this up.</span>
  </Typography>
)

export default PatientPayment
