/** Helper util for handling cookies across the app */
import * as H from "history"

import { GuestPaths } from "app/Routes/paths"
import { marketingSite } from "app/settings"

export interface Cookie {
  key: string
  value: string | boolean
  maxAge?: number
}

const domainMapping = {
  development: "",
  production: " domain=rupahealth.com; samesite=strict; secure;",
  test: "",
}

/**
 * Set a cookie
 *
 * @param key Key of the cookie to store
 * @param value Value of the cookie to store
 * @param maxAge Max age of cookie to stay with user
 */
export const setCookie = (cookie: Cookie): void => {
  const domainString = domainMapping[process.env.NODE_ENV]

  cookie.maxAge = cookie.maxAge || 604800 // default expiration 1 week
  document.cookie = `${cookie.key}=${cookie.value};${domainString} max-age=${cookie.maxAge}`
}

/**
 * Get a cookie based on its key value
 *
 * @param key Key used to lookup cookie
 * @returns Value of cookie (if it exists)
 */
export const getCookie = (key: string): Cookie | null => {
  const cookieString = document.cookie
    .split("; ")
    .find((row) => row.startsWith(`${key}=`))

  if (cookieString && cookieString !== `${key}=`) {
    return {
      key,
      value: cookieString.includes(";")
        ? cookieString.substring(
            cookieString.indexOf("=") + 1,
            cookieString.lastIndexOf(";")
          )
        : cookieString.split("=")[1],
      maxAge: undefined,
    }
  } else {
    return null
  }
}

/**
 * Remove the cookie from the user
 *
 * This sets the cookie value to "" and expiration to 1970. This
 * should force the browser to clear the cookie out.
 *
 * @param key Key of cookie to remove
 */
export const removeCookie = (key: string): void => {
  const domainString = domainMapping[process.env.NODE_ENV]
  document.cookie = `${key}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; ${domainString}`
}

/**
 * Grabs the coupon code from the promotional link query param
 * and sets cookie value.
 *
 * If the user has never logged in before, redirect them to sign up.
 *
 * @param originalUrlParams Query params of url
 */
export const handlePromotionalCouponLink = (
  originalUrlParams: URLSearchParams,
  history: H.History
) => {
  const couponCode = originalUrlParams.get("coupon")
  if (couponCode) {
    setCookie({
      key: "promotional_coupon_code",
      value: couponCode.toUpperCase(),
    })

    const hasLoggedInBefore = getCookie("has_logged_in")?.value === "true"

    if (!Boolean(hasLoggedInBefore)) {
      // External url, can't use history
      window.location.href = originalUrlParams.get("conference")
        ? `${marketingSite}/conferences`
        : `${marketingSite}/sign-up`
    } else {
      history.push(GuestPaths.LOGIN)
    }
  }
}
