import { Banner } from "app/main/banner/types"
import useCachedCollection from "app/swr/hooks/use-cached-collection"
import useCollectionSWR from "app/swr/hooks/use-collection-swr"
import { ResourceCollection } from "app/swr/types"

export default function useBanners() {
  const { data, mutate } = useCollectionSWR<ResourceCollection<Banner>>(
    "banners/?filter[portal_types]=practitioner",
    {},
    {
      revalidateOnMount: true,
      revalidateOnFocus: true,
      revalidateOnReconnect: true,
    }
  )
  return { mutate, banners: useCachedCollection<Banner>(data) }
}
