import DisplayText from "app/components/design-system/DisplayText"
import useFeatureFlag from "app/hooks/use-feature-flag"
import { PHLEBOTOMY_BOOKING_FROM_LOCATIONS } from "app/main/schedule-blood-draw/constants"
import { attachFromLocationToUrl } from "app/main/schedule-blood-draw/utils"
import Card from "app/patient-portal/Card"
import { PatientPortalOrder } from "app/patient-portal/types"
import { FeatureFlag } from "app/providers/FeatureFlagProvider"
import { colors } from "app/theme"
import formatDateForHuman from "app/utils/format-date-for-human"
import makeAppStyles from "app/utils/makeAppStyles"

import DownloadResultsInterpretationButton from "./DownloadResultsInterpretationButton"
import OrderPaymentButtons from "./OrderPayments/OrderPaymentButtons"
import PatientPortalBookingBanner from "./PatientPortalBookingBanner"
import PatientPortalOrderKitListKit from "./PatientPortalOrderKitListKit"

const useStyles = makeAppStyles((theme) => ({
  cardHeader: {
    display: "flex",
    flexFlow: "column nowrap",
    alignItems: "center",
    [theme.breakpoints.up("sm")]: {
      flexFlow: "row nowrap",
      justifyContent: "space-between",
    },
    gap: 5,
    height: 40,
  },
  cardHeaderTitle: {
    marginTop: -2,
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: 30,
    lineHeight: 1.4,
    flexGrow: 100,
  },
  card: {
    display: "flex",
    flexFlow: "column nowrap",
    gap: theme.spacing(3.75),
    padding: 20,
    background: "rgba(255, 255, 255, 0.8)",
    borderRadius: 12,
    border: "1px solid white",
  },
  kitDivider: {
    backgroundColor: colors.blueGray[200],
  },
}))

export interface PatientPortalOrderKitListProps {
  order: PatientPortalOrder
}

export default function PatientPortalOrderKitList({
  order,
}: PatientPortalOrderKitListProps) {
  const classes = useStyles()
  const [isResultsInterpretationEnabled, isResultsInterpretationLoading] =
    useFeatureFlag(FeatureFlag.ResultsVisualizer)

  return (
    <Card>
      <header className="flex flex-col sm:gap-5 sm:h-12 sm:flex-row sm:justify-between sm:items-center">
        <DisplayText size="lg" weight="semibold">
          Ordered {formatDateForHuman(order.attributes.date_patient_checkout)}
        </DisplayText>

        <OrderPaymentButtons order={order} />
        {isResultsInterpretationEnabled && !isResultsInterpretationLoading && (
          <DownloadResultsInterpretationButton order={order} />
        )}
      </header>
      {order.attributes.phlebotomy_booking_url && (
        <PatientPortalBookingBanner
          bookingUrl={attachFromLocationToUrl(
            order.attributes.phlebotomy_booking_url,
            PHLEBOTOMY_BOOKING_FROM_LOCATIONS.PATIENT_PORTAL_BANNER
          )}
        />
      )}
      {order.relationships.kits.data.map((kitRef) => (
        <div className={classes.card} key={kitRef.id}>
          <PatientPortalOrderKitListKit kitId={kitRef.id} />
        </div>
      ))}
    </Card>
  )
}
