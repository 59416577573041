import { useEffect } from "react"

import { useLocation } from "react-router-dom"

/**
 * By default browser maintains the scroll when we switch pages.
 * This will scroll the window up on every navigation.
 * More details :- https://reactrouter.com/web/guides/scroll-restoration
 */
export default function ScrollToTop() {
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return null
}
