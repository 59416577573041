import { colors } from "app/theme"
import interleave from "app/utils/interleave"
import makeAppStyles from "app/utils/makeAppStyles"
import { BiomarkerCustomDescription } from "types/biomarker-custom-description"
import { BloodReportResult } from "types/blood-report-result"

import BloodLabDashboardsResultsMissingBiomarkerCard from "../BloodLabDashboardsModal/BloodLabDashboardsResultsMissingBiomarkerCard"
import BloodLabDashboardsSnapshotBiomarkerCardFromDiscreteResult from "./BloodLabDashboardsSnapshotBiomarkerCard"

const useStyles = makeAppStyles((theme) => ({
  groupedSection: {
    backgroundColor: "white",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  separator: {
    background: colors.blueGray[50],
    height: 2,
    width: "calc(100% - 44px)",
  },
}))

export default function BloodLabDashboardsSnapshotBiomarkerGrouping({
  bloodReportResults,
  showHighLowDescriptions,
  biomarkerCustomDescriptionsMapping,
  biomarkersIdsWithoutResults,
}: {
  bloodReportResults: BloodReportResult[]
  showHighLowDescriptions?: boolean
  biomarkerCustomDescriptionsMapping?: Record<
    string,
    BiomarkerCustomDescription
  >
  biomarkersIdsWithoutResults?: string[]
}) {
  const classes = useStyles()

  if (
    !Boolean(bloodReportResults?.length) &&
    (!biomarkersIdsWithoutResults || biomarkersIdsWithoutResults.length === 0)
  ) {
    return null
  }

  return (
    <div className={classes.groupedSection}>
      {interleave(
        bloodReportResults.map((bloodReportResult) => (
          <BloodLabDashboardsSnapshotBiomarkerCardFromDiscreteResult
            bloodReportResult={bloodReportResult}
            showHighLowDescriptions={showHighLowDescriptions}
            biomarkerCustomDescription={
              biomarkerCustomDescriptionsMapping?.[
                bloodReportResult.relationships.biomarker.data?.id
              ]
            }
          />
        )),
        <div className={classes.separator}></div>
      )}
      {bloodReportResults.length && biomarkersIdsWithoutResults?.length ? (
        <div className={classes.separator}></div>
      ) : null}
      {biomarkersIdsWithoutResults &&
        interleave(
          biomarkersIdsWithoutResults.map((biomarkerId) => (
            <BloodLabDashboardsResultsMissingBiomarkerCard
              biomarkerId={biomarkerId}
              biomarkerCustomDescription={
                biomarkerCustomDescriptionsMapping?.[biomarkerId]
              }
            />
          )),
          <div className={classes.separator}></div>
        )}
    </div>
  )
}
