export const BIOMARKER_RESULT_UNITS = [
  "mL",
  "L",
  "mg",
  "g",
  "ng",
  "µg",
  "IU",
  "IU/L",
  "mmol/L",
  "nmol/L",
  "µmol/L",
  "mg/dL",
  "g/dL",
  "pg/mL",
  "mEq/L",
  "%",
  "pg",
  "fL",
  "pmol/L",
  "µIU/mL",
  "pg/dL",
  "ng/dL",
  "nmol/mL",
  "µmol/mL",
  "nmol/mg",
  "mIU/mL",
  "ng/mL",
  "µg/dL",
  "µg/mL",
  "ratio",
]

export const OTHER_LAB_COMPANY_VALUE = "other"
export const OTHER_LAB_COMPANY_NAME = "Other"

export const FAILED_PROCESSING_COPY =
  "Oops! There was a problem scanning this PDF file. Unfortunately, we weren’t able to extract the values for you. We'll investigate! For now, please input the values manually."
