import { Link, Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

import BodyText from "app/components/design-system/BodyText"
import Button from "app/components/design-system/Button"
import { ControlledCheckboxInput } from "app/patient-portal/fields"
import { buildLoginUrl } from "app/patient-portal/util"
import { marketingSite } from "app/settings"
import { colors, navy, primaryColor } from "app/theme"
import { PatientSignupError } from "app/types"

import { ControlledTextField } from "../patient-checkout/fields"
import PatientSignupErrorComponent from "./PatientSignupError"
import { PatientSignupFormFields } from "./use-patient-signup-form"

const useStyles = makeStyles((theme) => ({
  fields: {
    marginBottom: 20,
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: 8,
    "& .MuiFormHelperText-root.Mui-error": {
      paddingTop: 5,
    },
  },
  inputField: {
    width: "100%",
    marginBottom: 8,
  },
  submitButton: {
    width: "100%",
    fontSize: 19,
  },
  signinRow: {
    display: "flex",
    alignItems: "baseline",
    marginTop: 15,
    justifyContent: "center",
    flexWrap: "wrap",
  },
  linkButton: {
    "&.MuiButton-root": {
      minWidth: 40,
      padding: "0 4px",
    },
  },
  consentText: {
    fontWeight: 400,
  },
  termsLink: {
    color: primaryColor,
  },
  termsLabel: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "baseline",
    columnGap: 4,
  },
  validationError: {
    paddingTop: 5,
    color: colors.red[600],
    fontSize: 13,
  },
  passwordRequirements: {
    fontSize: 13,
    paddingInlineStart: 20,
    color: navy,
  },
}))

export default function PatientSignupForm({
  onSubmitForm,
  submitDisabled,
  loading,
  passwordMatchError,
  signupError,
  email,
  showPasswordRequirements,
  hasPatientPortalToken,
  next,
  hasDefaultEmail,
}: {
  onSubmitForm: () => void
  submitDisabled: boolean
  loading: boolean
  passwordMatchError: boolean
  signupError: PatientSignupError | null
  email: string
  showPasswordRequirements: boolean
  hasPatientPortalToken: boolean
  next: string | null
  hasDefaultEmail: boolean
}) {
  const classes = useStyles()

  return (
    <div>
      {signupError && (
        <PatientSignupErrorComponent signupError={signupError} email={email} />
      )}
      <div className={classes.fields}>
        {/* Do not display name fields if a patient portal token is present, which means the names are already defined */}
        {!hasPatientPortalToken && (
          <ControlledTextField
            label="First Name"
            name={PatientSignupFormFields.FIRST_NAME}
            className={classes.inputField}
            required
          />
        )}
        {!hasPatientPortalToken && (
          <ControlledTextField
            label="Last Name"
            name={PatientSignupFormFields.LAST_NAME}
            className={classes.inputField}
            required
          />
        )}
        <ControlledTextField
          label="Email"
          name={PatientSignupFormFields.EMAIL}
          required
          disabled={hasDefaultEmail}
          className={classes.inputField}
          infoText={
            hasDefaultEmail
              ? "This is the email linked to your Rupa order!"
              : "If you have an existing order, please use the same email that the order was placed with."
          }
        />
        <div>
          <ControlledTextField
            label="Password"
            inputProps={{ type: "password" }}
            name={PatientSignupFormFields.PASSWORD}
            required
            className={classes.inputField}
          />
          {showPasswordRequirements && (
            <ul className={classes.passwordRequirements}>
              <li>Must be at least 8 characters</li>
              <li>Cannot be easy to guess (e.g. your name, 1234, password)</li>
            </ul>
          )}
        </div>
        <div>
          <ControlledTextField
            label="Confirm Password"
            inputProps={{ type: "password" }}
            name={PatientSignupFormFields.CONFIRM_PASSWORD}
            showErrors={false}
            required
          />
          {passwordMatchError && (
            <p className={classes.validationError}>Passwords must match</p>
          )}
        </div>
        <div />
        <ControlledCheckboxInput
          color="primary"
          defaultValue={false}
          name={PatientSignupFormFields.GIVEN_PATIENT_MARKETING_CONSENT}
          label={
            <BodyText size="sm" className={classes.consentText}>
              Keep me updated about Rupa's latest patient services through
              email.
            </BodyText>
          }
        />
        <ControlledCheckboxInput
          color="primary"
          defaultValue={false}
          name={PatientSignupFormFields.TERMS_OF_SERVICE}
          label={
            <div className={classes.termsLabel}>
              <BodyText size="sm" className={classes.consentText}>
                I agree to the Rupa Health
              </BodyText>
              <Link
                className={classes.termsLink}
                href="https://www.rupahealth.com/privacy-policy"
                target="_blank"
              >
                Privacy Policy
              </Link>
              <BodyText size="sm" className={classes.consentText}>
                &
              </BodyText>
              <Link
                className={classes.termsLink}
                href="https://www.rupahealth.com/terms-and-conditions"
                target="_blank"
              >
                Terms & Conditions.
              </Link>
              <Typography color="error" className="font-semibold inline">
                {" *"}
              </Typography>
            </div>
          }
        />
      </div>
      <Button
        color="primary"
        size="medium"
        aria-label="CREATE ACCOUNT"
        disabled={submitDisabled}
        type="button"
        className={classes.submitButton}
        onClick={onSubmitForm}
        loading={loading}
      >
        Create Account
      </Button>
      <div className={classes.signinRow}>
        {hasPatientPortalToken ? (
          <>
            <BodyText>Already have an account?</BodyText>
            <Button
              color="text"
              href={buildLoginUrl("patient", email, next ?? undefined)}
              className={classes.linkButton}
            >
              Sign in.
            </Button>
          </>
        ) : (
          <>
            <BodyText>Are you a practitioner?</BodyText>
            <Button
              color="text"
              href={buildLoginUrl()}
              className={classes.linkButton}
            >
              Sign in
            </Button>
            <BodyText>or</BodyText>
            <Button
              color="text"
              href={marketingSite + "/sign-up"}
              className={classes.linkButton}
            >
              create an account.
            </Button>
          </>
        )}
      </div>
    </div>
  )
}
