import { useState } from "react"

import clsx from "clsx"

import { InputAdornment, TextField } from "@material-ui/core"
import { Skeleton } from "@material-ui/lab"

import { ReactComponent as BodySystemIcon } from "app/assets/icons/rupa-blood-dashboards/body-system.svg"
import { ReactComponent as SearchIcon } from "app/assets/icons/search-icon.svg"
import BodyText from "app/components/design-system/BodyText"
import { Theme, colors, navy, shadows } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"
import { BodySystem } from "types/body-system"

const useStyles = makeAppStyles((theme: Theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    gap: 7,
    [theme.breakpoints.down("sm")]: {
      gap: 11,
    },
  },
  textInput: {
    "& .MuiOutlinedInput-input": {
      fontSize: 14,
      background: "white",
      padding: "10px 14px",
    },
    "& .MuiOutlinedInput-input::placeholder": {
      color: colors.blueGray[500],
    },
    "& .MuiOutlinedInput-adornedStart": {
      background: "white",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: "6px",
      border: `1px solid ${colors.blueGray[200]}`,
      fontSize: 14,
    },
  },
  subheading: {
    color: colors.blueGray[400],
    lineHeight: "130%",
    marginTop: 7,
  },
  sidebarItemContainer: {
    width: "100%",
    borderRadius: "8px",
    border: `2px solid rgba(0, 0, 0, 0)`,
    padding: 1.5,
    display: "flex",
    justifyContent: "center",
    "&:hover": {
      cursor: "pointer",
      background: colors.coolGray[200],
    },
  },
  sidebarItemContent: {
    display: "flex",
    background: "none",
    color: navy,
    padding: "8px 12px",
    borderRadius: "6px",
    width: "100%",
    alignItems: "center",
    gap: 10,
  },
  text: {
    color: navy,
    lineHeight: "initial",
  },
  icon: {
    width: 17,
  },
  sidebarItemContainerActive: {
    width: "100%",
    borderRadius: "8px",
    border: `2px solid ${navy}`,
    padding: 1.5,
    display: "flex",
    justifyContent: "center",
    "&:hover": {
      cursor: "pointer",
    },
  },
  sidebarItemContentActive: {
    display: "flex",
    background: navy,
    color: "white",
    padding: "8px 12px",
    borderRadius: "6px",
    width: "100%",
    alignItems: "center",
    gap: 10,
  },
  activeText: {
    color: "white",
    lineHeight: "initial",
  },
  searchContainer: {
    display: "flex",
    gap: 11,
    width: "100%",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      gap: 7,
    },
  },
  sidebarToggleBtn: {
    width: 165,
    background: "white",
    borderRadius: 6,
    border: `1px solid ${colors.blueGray[300]}`,
    boxShadow: shadows.sm,
    fontSize: 14,
    padding: "7px 11px",
    display: "flex",
    alignItems: "center",
    gap: 10,
    color: navy,
    justifyContent: "center",
    cursor: "pointer",
    "&:hover": {
      background: colors.blueGray[100],
      boxShadow: "0px 2px 3px 0px rgba(0, 0, 0, 0.08) inset",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  active: {
    background: colors.blueGray[100],
    boxShadow: "0px 2px 3px 0px rgba(0, 0, 0, 0.08) inset",
  },
  sidebarItemsContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(5, 1fr)",
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "repeat(3, 1fr)",
    },
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "repeat(1, 1fr)",
    },
    gridColumnGap: 7,
    gridRowGap: 7,
    background: "white",
    padding: 12,
    borderRadius: 6,
    boxShadow: shadows.sm,
    height: "auto",
  },
  sidebarItemsContainerClosed: {
    height: "0px",
    display: "none",
  },
}))

interface Props {
  setSearchValue: (value: string) => void
  bodySystems: BodySystem[]
  isLoading: boolean
}

const BloodLabDashboardsPatientSidebarMobile = ({
  setSearchValue,
  bodySystems,
  isLoading,
}: Props) => {
  const classes = useStyles()

  const [isButtonActive, setIsButtonActive] = useState(false)

  const scrollToSection = (tab: string) => {
    if (tab === "") {
      tab = "outOfRange"
    }

    const section = document.querySelector(`#${tab}`)

    if (section) {
      section.scrollIntoView({ behavior: "smooth", block: "start" })
    }
  }

  if (isLoading) {
    return <Skeleton animation="wave" height={50} width="100%" />
  }

  return (
    <div className={classes.container}>
      <div className={classes.searchContainer}>
        <TextField
          variant="outlined"
          placeholder="Search for a marker"
          className={classes.textInput}
          margin="dense"
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon
                  width={16}
                  height={16}
                  fill={colors.blueGray[500]}
                />
              </InputAdornment>
            ),
          }}
          onChange={(e) => setSearchValue(e.target.value)}
        />
        <div
          className={clsx(
            classes.sidebarToggleBtn,
            isButtonActive ? classes.active : undefined
          )}
          onClick={() => setIsButtonActive(!isButtonActive)}
        >
          <BodySystemIcon /> Body Systems
        </div>
      </div>

      <div
        className={clsx(
          classes.sidebarItemsContainer,
          !isButtonActive ? classes.sidebarItemsContainerClosed : undefined
        )}
      >
        {isButtonActive &&
          bodySystems.map((bodySystem) => (
            <SidebarItem
              key={bodySystem.id}
              icon={bodySystem.attributes.logo || ""}
              title={bodySystem.attributes.name}
              // Mobile does not show active tabs
              isActive={false}
              onClick={() => scrollToSection(bodySystem.id)}
            />
          ))}
      </div>
    </div>
  )
}

const SidebarItem = ({ icon, title, isActive, onClick }) => {
  const classes = useStyles()
  return (
    <div
      className={
        isActive
          ? classes.sidebarItemContainerActive
          : classes.sidebarItemContainer
      }
      onClick={onClick}
    >
      <div
        className={
          isActive
            ? classes.sidebarItemContentActive
            : classes.sidebarItemContent
        }
      >
        <img src={icon} alt="body system icon" className={classes.icon} />
        <BodyText
          size="sm"
          className={isActive ? classes.activeText : classes.text}
        >
          {title}
        </BodyText>
      </div>
    </div>
  )
}

export default BloodLabDashboardsPatientSidebarMobile
