import * as React from "react"

import {
  withStyles,
  Switch as MaterialSwitch,
  SwitchProps,
} from "@material-ui/core"
import { ClassNameMap } from "@material-ui/styles"

import Tooltip from "app/components/Tooltip"
import { colors, primaryColor } from "app/theme"

const Switch: React.FC<
  {
    classes: ClassNameMap
    tooltiptext?: JSX.Element | null
  } & SwitchProps
> = ({ classes, ...props }) => (
  // Conditional tooltip for in-office delivery switch
  <Tooltip
    arrow
    interactive
    placement="bottom"
    title={props?.tooltiptext == null ? "" : props?.tooltiptext}
  >
    <div>
      <MaterialSwitch
        focusVisibleClassName={classes.focusVisible}
        disableRipple
        classes={{
          root: classes.root,
          switchBase: classes.switchBase,
          thumb: classes.thumb,
          track: classes.track,
          checked: classes.checked,
        }}
        {...props}
      />
    </div>
  </Tooltip>
)

export const StyledSwitch = withStyles((theme) => ({
  root: {
    width: 37,
    height: 20,
    padding: 0,
    margin: "0px 10px",
  },
  switchBase: {
    padding: 1,
    "&$checked": {
      transform: "translateX(16px)",
      color: theme.palette.common.white,
      "& + $track": {
        backgroundColor: primaryColor,
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: "white",
      border: "6px solid white",
    },
  },
  thumb: {
    width: 18,
    height: 18,
    backgroundColor: "white",
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${colors.coolGray[400]}`,
    backgroundColor: colors.coolGray[400],
    transition: theme.transitions.create(["background-color", "border"]),
  },
  checked: {},
  focusVisible: {},
}))(Switch)
