import { Banner as BannerResourceType } from "app/main/banner/types"
import useResourceSWR from "app/swr/hooks/use-resource-swr"
import { ResourceResponse } from "app/swr/types"
import resourceRequest from "app/swr/utils/resource-request"

const useDismissBanner = (
  banner: BannerResourceType,
  dismissCallback: () => void
) => {
  const { mutate } = useResourceSWR<BannerResourceType>(
    `/banners/${banner.id}/`
  )

  async function dismissBanner() {
    await mutate(
      async () => {
        const { data } = await resourceRequest<
          ResourceResponse<BannerResourceType>
        >({
          method: "PATCH",
          url: `/banners/${banner.id}/`,
          data: {
            data: {
              id: banner.id,
              type: "banner",
              attributes: {
                dismiss: true,
              },
            },
          },
        })

        return data
      },
      {
        revalidate: false,
      }
    )
  }
  return async () => {
    await dismissBanner()
    dismissCallback()
  }
}

export default useDismissBanner
