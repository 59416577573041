import { useState } from "react"

import { Button, Card, Grid, makeStyles } from "@material-ui/core"
import AddIcon from "@material-ui/icons/Add"

import BodyText from "app/components/design-system/BodyText"
import { ServiceModal } from "app/main/find-a-practitioner/ServiceModal"

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    marginTop: 34,
    border: `dashed 3px ${theme.palette.primary.main}`,
    borderRadius: 8,
    maxWidth: 275,
    minWidth: 275,
    minHeight: 201,
    display: "flex",
  },
  container: {
    padding: theme.spacing(3),
    height: "100%",
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    flex: 1,
  },
  gridItem: {
    flex: 1,
  },
  primaryMain: {
    color: theme.palette.primary.main,
  },
}))

export const NewPractitionerServicesItem = ({
  connectionTypeOptions,
  serviceTypeOptions,
  mutateServices,
  mutateProfile,
}) => {
  const classes = useStyles()
  const [open, setOpen] = useState(false)

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <Card className={classes.cardContainer}>
      <div className={classes.container}>
        <Grid
          container
          direction="column"
          justify="center"
          alignItems="center"
          className={classes.gridItem}
        >
          <Grid item>
            <Button
              onClick={() => {
                setOpen(!open)
              }}
              className={classes.primaryMain}
              variant="text"
              startIcon={<AddIcon />}
            >
              <BodyText
                size="base"
                weight="semibold"
                className={classes.primaryMain}
              >
                Highlight Service
              </BodyText>
            </Button>
          </Grid>
          <Grid item>
            <BodyText size="sm" align="center">
              1:1 consultations, video visits, LabShops, and more.
            </BodyText>
          </Grid>
        </Grid>
      </div>
      <ServiceModal
        open={open}
        handleClose={handleClose}
        service={undefined}
        connectionTypeOptions={connectionTypeOptions}
        serviceTypeOptions={serviceTypeOptions}
        mutateServices={mutateServices}
        mutateProfile={mutateProfile}
      />
    </Card>
  )
}
