/*
 * Using font.widthOfTextAtSize transform text adding \n to break lines
 * if width of text is bigger than the width passed as parameter
 * and return the text with the line breaks
 * @param text: string
 * @param width: number
 * @param font: font
 * @param fontSize: number
 * @returns string
 */

export const wrapText = (
  text: string,
  width: Number,
  font,
  fontSize: Number
) => {
  const words = text.split(" ")
  let line = ""
  let lines: string[] = []
  for (let n = 0; n < words.length; n++) {
    const testLine = line + words[n] + " "
    const testWidth = font.widthOfTextAtSize(testLine, fontSize)
    if (testWidth > width) {
      lines.push(line)
      line = words[n] + " "
    } else {
      line = testLine
    }
  }
  lines.push(line)
  return lines
}
