import { default as BloodDrop } from "app/assets/icons/blood.svg"
import { EMPIRE_BLOOD_DRAW_INFO } from "app/constants"

import {
  BaseCheckoutWarning,
  CheckoutWarningSeverities,
} from "./BaseCheckoutWarning"

export enum StaticCheckoutWarningTypes {
  MULTIPLE_BLOOD_LABS = "MULTIPLE_BLOOD_LABS",
  ORDER_WITH_LTA_QUEST_TEST = "ORDER_WITH_LTA_QUEST_TEST",
  MIXED_AML_ORDER = "MIXED_AML_ORDER",
  MIXED_AML_ORDER_LABSHOP = "MIXED_AML_ORDER_LABSHOP",
  MIXED_EMPIRE_AND_LABCORP_ORDER = "MIXED_EMPIRE_AND_LABCORP_ORDER",
  MIXED_EMPIRE_AND_QUEST_ORDER = "MIXED_EMPIRE_AND_QUEST_ORDER",
  DUPLICATE_BIOMARKERS = "DUPLICATE_BIOMARKERS",
  EMPIRE_DRAW_WARNING = "EMPIRE_DRAW_WARNING",
  HEALTH_GORILLA_LABCORP_AND_MEDICARE = "HEALTH_GORILLA_LABCORP_AND_MEDICARE",
  FIRST_ORDER_WITH_LAB_COMPANY_TURNAROUND_TIME_WARNING = "FIRST_ORDER_WITH_LAB_COMPANY_TURNAROUND_TIME_WARNING",
  LABCORP_PHYSICIAN_SERVICES = "LABCORP_PHYSICIAN_SERVICES",
  AML_MIXED_DROPSHIP_IOK = "AML_MIXED_DROPSHIP_IOK",
  MIXED_DHA_LABCORP_ORDER = "MIXED_DHA_LABCORP_ORDER",
}

interface StaticCheckoutWarningContent {
  title?: string
  message: React.ReactNode
  severity: CheckoutWarningSeverities
  icon?: JSX.Element
  collapsible?: boolean
}

export const CHECKOUT_WARNINGS: Record<
  StaticCheckoutWarningTypes,
  StaticCheckoutWarningContent
> = {
  [StaticCheckoutWarningTypes.MULTIPLE_BLOOD_LABS]: {
    title: "Blood Tests From Multiple Lab Companies",
    message:
      "You are including blood tests from multiple lab companies. Your patient will need separate blood draws for each and get different results PDFs for these labs.",
    severity: CheckoutWarningSeverities.WARNING,
  },

  [StaticCheckoutWarningTypes.ORDER_WITH_LTA_QUEST_TEST]: {
    title: "Quest tests cannot be completed in NY, NJ, RI, CT or MA",
    message:
      "You are including tests from Quest Diagnostics which cannot be completed in NY, NJ, RI, CT or MA. Please remove these tests from your order if your patient is located in one of these 5 states.",
    severity: CheckoutWarningSeverities.ERROR,
  },

  [StaticCheckoutWarningTypes.MIXED_AML_ORDER]: {
    title: "Tests from Access Medical and Labcorp",
    message:
      "You are including tests from both Access Medical Labs and Labcorp. Please remove tests from one of these lab companies to continue.",
    severity: CheckoutWarningSeverities.ERROR,
  },
  [StaticCheckoutWarningTypes.MIXED_AML_ORDER_LABSHOP]: {
    title: "Tests from Access Medical and Labcorp",
    message:
      "This order contains blood tests from two different labs, and would require two separate blood draws. Please remove tests from one of these lab companies to continue.",
    severity: CheckoutWarningSeverities.ERROR,
  },
  [StaticCheckoutWarningTypes.MIXED_EMPIRE_AND_LABCORP_ORDER]: {
    title: "Tests from Empire and Labcorp",
    message:
      "You are including tests from both Empire City Labs and Labcorp. Please remove tests from one of these lab companies to continue.",
    severity: CheckoutWarningSeverities.ERROR,
  },
  [StaticCheckoutWarningTypes.MIXED_EMPIRE_AND_QUEST_ORDER]: {
    title: "Tests from Empire and National Blood Lab",
    message:
      "You are including tests from both Empire City Labs and National Blood Lab. Please remove tests from one of these lab companies to continue.",
    severity: CheckoutWarningSeverities.ERROR,
  },
  [StaticCheckoutWarningTypes.EMPIRE_DRAW_WARNING]: {
    icon: <img src={BloodDrop} alt="warning-icon" height={20} />,
    message: EMPIRE_BLOOD_DRAW_INFO,
    severity: CheckoutWarningSeverities.INFO,
    collapsible: false,
  },
  [StaticCheckoutWarningTypes.DUPLICATE_BIOMARKERS]: {
    title: "Duplicate Biomarkers",
    message:
      "Heads up -- You have duplicate biomarkers in the cart from the following lab tests:",
    severity: CheckoutWarningSeverities.WARNING,
  },
  [StaticCheckoutWarningTypes.HEALTH_GORILLA_LABCORP_AND_MEDICARE]: {
    title: "Labcorp and Medicare Order",
    message:
      "You cannot mix Labcorp insurance orders with insurance orders from other companies at this time. Please place separate orders for the tests.",
    severity: CheckoutWarningSeverities.ERROR,
  },
  [StaticCheckoutWarningTypes.FIRST_ORDER_WITH_LAB_COMPANY_TURNAROUND_TIME_WARNING]:
    {
      title: "First Order with Lab Company",
      message: "",
      severity: CheckoutWarningSeverities.HIGHLIGHT,
    },
  [StaticCheckoutWarningTypes.LABCORP_PHYSICIAN_SERVICES]: {
    title: "Labcorp Requisition via Physician Services",
    message:
      "The Labcorp requisition will take a few hours to generate, pending Authorizing Physician approval. We'll email your client the form once it's ready for their blood draw.",
    severity: CheckoutWarningSeverities.HIGHLIGHT,
    collapsible: false,
  },
  [StaticCheckoutWarningTypes.AML_MIXED_DROPSHIP_IOK]: {
    title: "Mixed Dropship and IOK Order",
    message:
      "You are including tests drawn in-office and shipped tests from Access Medical Labs. Please place separate orders for these in-office and shipped tests.",
    severity: CheckoutWarningSeverities.ERROR,
  },
  [StaticCheckoutWarningTypes.MIXED_DHA_LABCORP_ORDER]: {
    title: "Mixed DHA and Labcorp Order",
    message:
      "You are including phlebotomy tests from both DHA and LabCorp. Please remove phlebotomy tests from one of these lab companies to continue.",
    severity: CheckoutWarningSeverities.ERROR,
  },
}

/**
 * Renders a static (hard-coded) checkout warning to the user.
 *
 * @param {StaticCheckoutWarningTypes} type - The type of warning to display.
 */
export const StaticCheckoutWarning = ({
  type,
}: {
  type: StaticCheckoutWarningTypes
}) => {
  const checkoutWarning = CHECKOUT_WARNINGS[type]
  if (!checkoutWarning) {
    return null
  }

  const { title, message, severity, icon, collapsible } = checkoutWarning

  return (
    <BaseCheckoutWarning
      title={title}
      severity={severity}
      icon={icon}
      collapsible={collapsible}
    >
      {message}
    </BaseCheckoutWarning>
  )
}
