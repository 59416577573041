import { CircularProgressbar, buildStyles } from "react-circular-progressbar"

import { colors, lightGrayBlue } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"

const useStyles = makeAppStyles((theme) => ({
  container: {
    display: "flex",
    alignItems: "center",
    padding: "11px 20px",
    borderRadius: "6px",
    border: `1px solid ${colors.blueGray[300]}`,
    background: colors.blueGray[50],
    boxShadow:
      "0px 1px 2px 0px rgba(0, 0, 0, 0.06), 0px 1px 3px 0px rgba(0, 0, 0, 0.10)",
    fontFamily: "Open Sans",
    fontSize: "15px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "135%",
  },
  progressBar: {
    width: 20,
    height: 20,
    marginRight: 10,
    display: "flex",
    alignItems: "center",
  },
}))

export default function BloodReportsBiomarkerCountOverview({
  biomarkerCount,
  biomarkerTotal,
}: {
  biomarkerCount: number
  biomarkerTotal: number
}) {
  const classes = useStyles()
  return (
    <div className={classes.container}>
      <div className={classes.progressBar}>
        <CircularProgressbar
          strokeWidth={20}
          value={(biomarkerCount / biomarkerTotal) * 100}
          styles={buildStyles({
            pathTransitionDuration: 0.5,
            pathColor: colors.cyan[500],
            trailColor: lightGrayBlue,
            backgroundColor: "red",
          })}
        />
      </div>
      <div>
        {biomarkerCount}/{biomarkerTotal} biomarker results are in.
      </div>
    </div>
  )
}
