import { Location } from "history"

import { SearchSuggestion } from "app/types"

import AutoSuggestAction from "./AutoSuggestAction"
import AutoSuggestGrouping from "./AutoSuggestGrouping"

const SEARCH_SUGGESTION_TYPE_LABELS = {
  biomarker: "Biomarker",
  health_category: "Health Category",
  lab_company: "Lab Company",
  lab_test: "Lab Test",
  lab_test_type: "Sample Type",
}

export interface AutoSuggestSuggestionGroupProps {
  getSuggestionLocation?: (suggestion: SearchSuggestion) => Location<unknown>
  highlightedSuggestion?: SearchSuggestion
  separator?: boolean
  moveCursorToSuggestion: (suggestion: SearchSuggestion) => void
  selectSuggestion: (suggestion: SearchSuggestion) => void
  suggestions: SearchSuggestion[]
  type: string
}

export default function AutoSuggestSuggestionGroup({
  getSuggestionLocation,
  highlightedSuggestion,
  separator,
  moveCursorToSuggestion,
  selectSuggestion,
  suggestions,
  type,
}: AutoSuggestSuggestionGroupProps) {
  const typeLabel = SEARCH_SUGGESTION_TYPE_LABELS[type]

  return (
    <AutoSuggestGrouping label={typeLabel} separator={separator}>
      {suggestions.map((suggestion) => {
        return (
          <AutoSuggestAction
            key={`${type}_${suggestion.object_id}`}
            label={suggestion.label}
            highlighted={highlightedSuggestion === suggestion}
            icon={suggestion.type}
            onHover={() => moveCursorToSuggestion(suggestion)}
            onSelect={() => selectSuggestion(suggestion)}
            to={
              getSuggestionLocation
                ? getSuggestionLocation(suggestion)
                : undefined
            }
          />
        )
      })}
    </AutoSuggestGrouping>
  )
}
