import { useEffect } from "react"

import { debounce } from "lodash"
import { FieldErrors, useForm } from "react-hook-form"
import { z } from "zod"

import { zodResolver } from "@hookform/resolvers/zod"

import usePatchInterpretationBiomarker, {
  BiomarkerEditableAttributes,
} from "app/results-summary/hooks/use-patch-interpretation-biomarker"
import { getIdentifier } from "app/swr/helpers/resource"
import { ResultsInterpretationBiomarker } from "types/results-interpretation"

/**
 * Checks whether a string is a number or an empty string.
 *
 * @param value the value to check
 * @returns whether the value is a number or an empty string
 */
function isNumericString(value: string) {
  if (value === "") {
    return true
  }

  return !isNaN(Number(value))
}

/**
 * Checks whether the normal max is greater than the normal min.
 *
 * @param attributes the attributes to check
 * @returns whether the max is greater than the min
 */
function isMaxGreaterThanMin(attributes: Partial<BiomarkerEditableAttributes>) {
  if (attributes.normal_max === "" || attributes.normal_min === "") {
    return true
  }

  return Number(attributes.normal_max) > Number(attributes.normal_min)
}

/**
 * The schema for the biomarker attributes that can be edited.
 */
export const BiomarkerEditableAttributesSchema = z
  .object({
    description: z.string(),
    name: z.string(),
    normal_max: z.string().refine(isNumericString, "Must be a number"),
    normal_min: z.string().refine(isNumericString, "Must be a number"),
    units: z.string(),
    value: z
      .string()
      .min(1, "Required")
      .refine(isNumericString, "Must be a number"),
  })
  .superRefine((val, ctx) => {
    if (!isMaxGreaterThanMin(val)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Must be greater than Normal Max",
        path: ["normal_min"],
      })

      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Must be greater than Normal Min",
        path: ["normal_max"],
      })
    }
  })

/**
 * Sets up a form hook to edit a biomarker. The form is debounced to avoid sending too many requests to the server.
 *
 * @param biomarker the biomarker to edit
 * @returns a form hook to edit the biomarker
 */
export default function useBiomarkerEditorForm(
  biomarker: ResultsInterpretationBiomarker
) {
  const patchInterpretationBiomarker = usePatchInterpretationBiomarker(
    getIdentifier(biomarker)
  )
  const methods = useForm<BiomarkerEditableAttributes>({
    criteriaMode: "all",
    defaultValues: biomarker.attributes,
    mode: "onSubmit",
    resolver: zodResolver(BiomarkerEditableAttributesSchema),
    reValidateMode: "onSubmit",
    shouldFocusError: false,
    shouldUnregister: true,
  })

  const { handleSubmit, watch } = methods

  /**
   * Debounce the form submission on change to avoid sending too many requests to the server.
   */
  useEffect(() => {
    /**
     * On valid form data, send the data to the server.
     */
    const onValid = patchInterpretationBiomarker

    /**
     * On invalid form data, only send the valid data to the server.
     * @param data the data received during the change event
     */
    const onInvalid =
      (data: BiomarkerEditableAttributes) =>
      (errors: FieldErrors<BiomarkerEditableAttributes>) => {
        const validData = Object.fromEntries(
          Object.entries(data).filter(([key]) => !errors[key])
        ) as BiomarkerEditableAttributes

        patchInterpretationBiomarker(validData)
      }

    const debounceOnSubmit = debounce(
      (data) => handleSubmit(onValid, onInvalid(data))(),
      600
    )
    const subscription = watch(debounceOnSubmit)

    return () => {
      // Rather than cancel, we flush, as this ensures the save still goes through.
      debounceOnSubmit.flush()

      subscription.unsubscribe()
    }
  }, [handleSubmit, watch])

  return methods
}
