import { Skeleton } from "@rupahealth/design"

const ResultsUploadManualEntryLoadingState = () => {
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 gap-5">
      <div className="flex flex-col gap-2">
        <Skeleton className="w-[60%] h-6" />
        <div className="flex gap-1 w-full">
          <Skeleton className="w-[33%] h-10" />
          <Skeleton className="w-[33%] h-10" />
          <Skeleton className="w-[33%] h-10" />
        </div>
      </div>
      <div className="flex flex-col gap-2">
        <Skeleton className="w-[60%] h-6" />
        <Skeleton className="w-full h-10" />
      </div>
      <div className="flex flex-col gap-2">
        <Skeleton className="w-[60%] h-6" />
        <Skeleton className="w-full h-10" />
      </div>
      <div className="flex flex-col gap-2">
        <Skeleton className="w-[60%] h-6" />
        <Skeleton className="w-full h-10" />
      </div>
    </div>
  )
}

export default ResultsUploadManualEntryLoadingState
