import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import { Divider } from "@material-ui/core"
import Button from "@material-ui/core/Button"

import { ReactComponent as TeamMembersIcon } from "app/assets/icons/onboarding/invite-team-people.svg"
import Loading from "app/components/Loading"
import { TeamMemberListSection } from "app/components/modals/ClinicInviteModal"
import TeamMemberModal from "app/components/modals/TeamMemberModal"
import * as Actions from "app/store/actions"
import { colors } from "app/theme"
import { RootState } from "app/types"

import { MarkAsComplete } from "../MarkAsCompleteButton"
import TaskContainer from "../TaskContainer"
import taskStyles from "../TaskStyles"
import { OnboardingTaskItemProps } from "./OnboardingTaskItem"

const InviteTeam: React.FC<OnboardingTaskItemProps> = ({
  task,
  markTaskAsComplete,
}) => {
  const taskClasses = taskStyles()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(Actions.getTeamMembers())
  }, [])

  const [teamMembers, setTeamMembers] = useState<any[]>([])
  const [invitingTeamMember, setInvitingTeamMember] = useState(false)
  const [teamMembersLoading, setTeamMembersLoading] = useState(true)

  const teamMembersState = useSelector(
    ({ teamMember }: RootState) => teamMember
  )

  const onAddTeamMember = (teamMember) => {
    // Avoid adding duplicate team members to the list
    if (
      teamMembers.find((teamMembers) => teamMembers.email === teamMember.email)
    ) {
      setInvitingTeamMember(false)
      return
    }

    setTeamMembers((prevTeamMembers) => [...prevTeamMembers, teamMember])
    setInvitingTeamMember(false)
  }

  useEffect(() => {
    // This state changes from an {} to [] post load
    if (Array.isArray(teamMembersState)) {
      setTeamMembers(
        teamMembersState.map((member) => ({
          ...member.user,
          primary_practitioner_type: member.primary_practitioner_type?.name,
        }))
      )
      setTeamMembersLoading(false)
    }
  }, [teamMembersState])

  const subCardContent = (
    <div className={taskClasses.subHeaderContainer}>
      <MarkAsComplete onClick={() => markTaskAsComplete(task.key)} />
    </div>
  )

  return (
    <TaskContainer
      taskKey={task.key}
      header="Invite Your Team"
      isTaskComplete={task.complete}
      timeTag={"2 mins"}
      openAfterComplete
      subCardContent={subCardContent}
    >
      <TeamMembersIcon fill={colors.blueGray[300]} style={{ marginTop: 20 }} />
      <div className={taskClasses.taskTextContainer}>
        <div className={taskClasses.taskText}>
          Who else orders labs or should have access to your clinic?
        </div>
        <div className={taskClasses.taskText} style={{ fontWeight: 400 }}>
          Invite your clinical or admin team members so they can access your
          patients, orders, and results.
        </div>
      </div>
      {teamMembersLoading && <Loading ariaLabel="team members" />}
      {!!teamMembers.length && <Divider className={taskClasses.divider} />}
      <div>
        <TeamMemberListSection teamMembersList={teamMembers} />
      </div>
      {!!teamMembers.length && <Divider className={taskClasses.divider} />}
      <Button
        variant="contained"
        color="primary"
        className={taskClasses.taskButton}
        style={{ marginBottom: 0 }}
        onClick={() => setInvitingTeamMember(true)}
      >
        <span className={taskClasses.buttonText}>Invite a Team Member</span>
      </Button>
      <TeamMemberModal
        open={invitingTeamMember}
        onClose={() => setInvitingTeamMember(false)}
        onAddTeamMember={onAddTeamMember}
      />
    </TaskContainer>
  )
}

export default InviteTeam
