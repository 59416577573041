import { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

import axios from "axios"
import { useHistory } from "react-router-dom"
import { useMount } from "react-use"
import useSWR from "swr"

import { makeStyles, useMediaQuery, Grid, Theme } from "@material-ui/core"

import { UserPaths } from "app/Routes"
import { ReactComponent as BankIconBlue } from "app/assets/bank-nav-icon-blue.svg"
import StoreIconGrey from "app/assets/icons/ecommerce-store-grey.svg"
import PageToolbar from "app/components/PageToolbar"
import DesignSystemButton from "app/components/design-system/Button"
import { StorefrontType } from "app/hooks/use-resource/types"
import useAppSelector from "app/hooks/useAppSelector"
import withReducer from "app/store/withReducer"
import { RootState, StripeConnectAccount } from "app/types"
import { handleApiError } from "app/utils"
import { getApiBaseUrl } from "app/utils"

import { API } from "../../api"
import EcommerceAnalyticsEmbed from "./components/EcommerceAnalyticsEmbed"
import SideNav from "./components/SideNav"
import ecommerceReducer from "./store/reducers/ecommerce.reducer"

const useStyles = makeStyles((theme) => ({
  container: {
    overflowY: "scroll" as any,
    height: "calc(100vh - 3px)",
  },
  payoutButton: {
    width: "max-content",
    justifyContent: "flex-end",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      justifyContent: "center",
    },
  },
}))

function EcommerceAnalytics() {
  const history = useHistory()
  const classes = useStyles()
  const [storefronts, setStorefronts] = useState<StorefrontType[]>([])
  const [showSidebarOnMobile, setShowSidebarOnMobile] = useState(false)
  const [analyticsURL, setAnalyticsURL] = useState("")
  const [payoutLoading, setPayoutLoading] = useState(false)

  const dispatch = useDispatch()

  const customFeesEnabledForClinic = useAppSelector(
    (state) => state?.practitioner?.clinic?.custom_fees_enabled
  )

  const stripeConnectAccount = useSelector<
    RootState,
    StripeConnectAccount | undefined
  >((state) => {
    const customFees = state.customFees
    if (
      Array.isArray(customFees.stripeConnectAccounts) &&
      customFees.stripeConnectAccounts.length
    ) {
      return customFees.stripeConnectAccounts[0]
    }
  })

  const { data: analytics } = useSWR(
    "/api/normalized/storefronts/analytics/",
    async (url) => {
      const response = await axios.get(getApiBaseUrl() + url)
      return response.data
    },
    { refreshInterval: 1000 * 60 * 9, revalidateOnMount: true }
  )

  useEffect(() => {
    if (analytics && analytics.data.url) {
      setAnalyticsURL(analytics.data.url)
    }
  }, [analytics])

  useMount(async () => {
    const response = await API.Storefront.retrieveStorefronts()
    const data = response.data
    setStorefronts(data.data)
  })

  const createNewLabShop = async () => {
    const response = await API.Storefront.createStorefront()
    setStorefronts([...storefronts, response.data.data])
  }

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"))

  const handleSelectedStorefront = (storefrontId: string) => {
    setShowSidebarOnMobile(false)
    history.push(`/store?storefrontId=${storefrontId}`)
  }

  const onPayoutClick = async () => {
    if (payoutLoading || !stripeConnectAccount) {
      return
    }

    try {
      setPayoutLoading(true)

      const response = await API.StripeConnectAccount.dashboardLink(
        stripeConnectAccount.id
      )
      window.location.href = response.data.link
    } catch (error) {
      dispatch(handleApiError(error))
    } finally {
      setPayoutLoading(false)
    }
  }

  return (
    <Grid container>
      <>
        <Grid item xs={12} md={3}>
          {isMobile && (
            <>
              <PageToolbar
                breadcrumbs={[
                  {
                    to: UserPaths.ECOMMERCE_STORE_ANALYTICS,
                    icon: StoreIconGrey,
                    label: "LabShop",
                    onClick: () => setShowSidebarOnMobile(true),
                  },
                  {
                    label: "LabShop Analytics",
                  },
                ]}
              >
                {customFeesEnabledForClinic && (
                  <DesignSystemButton
                    color="secondary"
                    startIcon={<BankIconBlue viewBox="0 0 16 17" />}
                    variant="text"
                    className={classes.payoutButton}
                    onClick={onPayoutClick}
                  >
                    Manage Payouts
                  </DesignSystemButton>
                )}
              </PageToolbar>
              {showSidebarOnMobile && (
                <SideNav
                  storefronts={storefronts}
                  createNewLabShop={createNewLabShop}
                  selectedStorefrontId={""}
                  selectStorefront={handleSelectedStorefront}
                  isMobile={isMobile}
                />
              )}
            </>
          )}
          {!isMobile && (
            <>
              <Grid item xs={12} md={12}>
                <PageToolbar title="LabShop" fullBorder={true} />
              </Grid>
              <SideNav
                storefronts={storefronts}
                createNewLabShop={createNewLabShop}
                selectedStorefrontId={""}
                selectStorefront={handleSelectedStorefront}
                isMobile={isMobile}
              />
            </>
          )}
        </Grid>
        <Grid item xs={12} md={9} className={classes.container}>
          <PageToolbar
            title="LabShop Analytics"
            fullBorder={true}
            topContainerStyle={{ paddingTop: "17px", paddingBottom: "16px" }}
          >
            {customFeesEnabledForClinic && (
              <DesignSystemButton
                color="secondary"
                startIcon={<BankIconBlue viewBox="0 0 16 17" />}
                variant="text"
                className={classes.payoutButton}
                onClick={onPayoutClick}
              >
                Manage Payouts
              </DesignSystemButton>
            )}
          </PageToolbar>
          {storefronts.length > 0 && (
            <EcommerceAnalyticsEmbed analyticsURL={analyticsURL} />
          )}
        </Grid>
      </>
    </Grid>
  )
}

export default withReducer("ecommerce", ecommerceReducer)(EcommerceAnalytics)
