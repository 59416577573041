import { useEffect, useState } from "react"

import { FuseSplashScreen } from "@fuse"

import NiceModal from "@ebay/nice-modal-react"
// Import Stripe here to allow Stripe anti-fraud detection on all parts of the site
// https://github.com/stripe/stripe-js
import "@stripe/stripe-js"

import AppErrorBoundary from "app/AppErrorBoundary"
import MaterialUIProviders from "app/MaterialUIProviders"
import Routes from "app/Routes"
import useSimpleJWTAuth from "app/auth/hooks/use-simplejwt-auth"
import IntercomProvider from "app/components/IntercomProvider"
import PhysicianServicesOptInModalListener from "app/components/PhysicianServicesOptInModalListener"
import ScrollToTop from "app/components/ScrollToTop"
import useFrontendReload from "app/hooks/use-frontend-reload"
import { ResourceProvider } from "app/hooks/use-resource"
import useWebflowAuthCookie from "app/hooks/useWebflowAuthCookie"
import { FeatureFlagProvider } from "app/providers/FeatureFlagProvider"
import { FeaturePromotionProvider } from "app/providers/FeaturePromotionProvider"

import { ConfettiProvider } from "./main/onboarding/confetti/confetti-provider"

const App = () => {
  const [hasInitialized, setHasInitialized] = useState(false)

  const isSimpleJWTAuthInitialized = useSimpleJWTAuth()
  const isFrontendReloadInitialized = useFrontendReload()

  useEffect(() => {
    if (isSimpleJWTAuthInitialized && isFrontendReloadInitialized) {
      setHasInitialized(true)
    }
  }, [isSimpleJWTAuthInitialized, isFrontendReloadInitialized])

  useWebflowAuthCookie()

  if (!hasInitialized) {
    return <FuseSplashScreen />
  }

  return (
    <MaterialUIProviders>
      <AppErrorBoundary>
        <FeatureFlagProvider>
          <FeaturePromotionProvider>
            <IntercomProvider />
            <ScrollToTop />
            <PhysicianServicesOptInModalListener />
            <ResourceProvider>
              <NiceModal.Provider>
                <ConfettiProvider>
                  <Routes />
                </ConfettiProvider>
              </NiceModal.Provider>
            </ResourceProvider>
          </FeaturePromotionProvider>
        </FeatureFlagProvider>
      </AppErrorBoundary>
    </MaterialUIProviders>
  )
}

export default App
