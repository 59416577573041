import { useEffect, useState } from "react"
import * as React from "react"

import { Collapse } from "@material-ui/core"

import { ReactComponent as TriangleIcon } from "app/assets/icons/triangle.svg"
import Button from "app/components/design-system/Button"
import makeAppStyles from "app/utils/makeAppStyles"

interface WarningProps {
  children: React.ReactNode
  header: React.ReactNode
  onExpand?: () => null | void
}

const useStyles = makeAppStyles(() => ({
  button: {
    width: "100%",
  },
  header: {
    width: "100%",
  },
  icon: {
    margin: "auto",
    marginRight: "5px",
    marginTop: "9px",
  },
  body: {
    paddingLeft: "12.77px",
    width: "100%",
  },
}))

const ProcessingAndOrderingCollapse = ({
  children,
  header,
  onExpand,
}: WarningProps) => {
  const [expanded, setExpanded] = useState(false)
  const styles = useStyles()

  const onClick = () => {
    setExpanded((prevExpanded) => !prevExpanded)
  }

  useEffect(() => {
    if (expanded && onExpand) {
      onExpand()
    }
  }, [expanded])

  return (
    <div>
      <Button
        fullWidth
        size={"large"}
        className={styles.button}
        color="text"
        onClick={onClick}
        data-cy="detailsBtn"
      >
        <TriangleIcon
          className={styles.icon}
          style={{
            transform: `rotate(${expanded ? "0deg" : "270deg"})`,
          }}
        />
        <div className={styles.header}>{header}</div>
      </Button>
      <Collapse in={expanded}>
        <div className={styles.body}>{children}</div>
      </Collapse>
    </div>
  )
}

export default ProcessingAndOrderingCollapse
