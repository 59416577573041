/**
 * These are legacy (v1) patient portal paths that were back when patient portal was patient based.
 */
export enum PatientPortalPatientPaths {
  ROOT = "/patient_portal",
  ORDERS = "/patient_portal/patients/:patientId/orders",
  ORDER = "/patient_portal/patients/:patientId/orders/:orderId",
  SUPPORT = "/patient_portal/patients/:patientId/support",
  PATIENTS = "/patient_portal/patients",
  BLOOD_LABS = "/patient_portal/patients/:patientId/orders/:orderId/blood_lab_dashboard/:orderedResultId",
  BLOOD_LABS_SNAPSHOT = "/patient_portal/patients/:patientId/orders/:orderId/blood_lab_dashboard_snapshot/:snapshotId",
}
