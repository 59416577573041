import { useEffect, useState } from "react"

import { find, indexOf } from "lodash"

import useEventCallback from "app/hooks/use-event-callback"
import { AnyBiomarkerResourceIdentifier } from "app/results-summary/components/ResultsSummaryEditor/types"

/**
 * Hook used for tracking a cursor across a set of identifiers for the biomarkers search.
 * The cursor indicates the currently highlighted biomarker so that pressing "Enter" will
 * cause that biomarker to be the source of the new interpreation biomarker.
 *
 * @param identifiers the set of identifiers to track
 * @returns the cursor and movement controls
 */
export default function useBiomarkerCursorTracking(
  identifiers: AnyBiomarkerResourceIdentifier[]
) {
  const defaultIdentifier = identifiers.length ? identifiers[0] : undefined
  const [biomarkerCursor, setBiomarkerCursor] = useState(defaultIdentifier)

  useEffect(() => {
    if (!biomarkerCursor) {
      setBiomarkerCursor(defaultIdentifier)
    }
  }, [biomarkerCursor, defaultIdentifier])

  useEffect(() => {
    if (!find(identifiers, biomarkerCursor)) {
      setBiomarkerCursor(defaultIdentifier)
    }
  }, [biomarkerCursor, identifiers])

  const moveBiomarkerCursorTo = useEventCallback(
    (identifier: AnyBiomarkerResourceIdentifier) => {
      setBiomarkerCursor(identifier)
    }
  )

  const moveBiomarkerCursorUp = useEventCallback(() => {
    if (!identifiers.length) {
      return
    }

    const currentIndex = indexOf(identifiers, biomarkerCursor)

    const nextIndex =
      currentIndex === 0 ? identifiers.length - 1 : currentIndex - 1
    const nextBiomarkerCursor = identifiers[nextIndex]
    setBiomarkerCursor(nextBiomarkerCursor)
  })

  const moveBiomarkerCursorDown = useEventCallback(() => {
    if (!identifiers.length) {
      return
    }

    const currentIndex = indexOf(identifiers, biomarkerCursor)

    const nextIndex =
      currentIndex === identifiers.length - 1 ? 0 : currentIndex + 1
    const nextBiomarkerCursor = identifiers[nextIndex]
    setBiomarkerCursor(nextBiomarkerCursor)
  })

  return {
    biomarkerCursor,
    moveBiomarkerCursorDown,
    moveBiomarkerCursorTo,
    moveBiomarkerCursorUp,
  }
}
