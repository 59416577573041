import * as Actions from "app/main/patient-checkout/store/actions"
import { SplitItState } from "app/types"

const initialState: SplitItState = {
  data: null,
  error: null,
  isInitiating: true,
}

const splititReducer = function (
  state: SplitItState = initialState,
  action
): SplitItState {
  switch (action.type) {
    case Actions.START_INITIATE_SPLITIT: {
      return {
        ...state,
        isInitiating: true,
      }
    }
    case Actions.SUCCESS_INITIATE_SPLITIT: {
      return {
        ...state,
        error: null,
        isInitiating: false,
        data: action.payload,
      }
    }
    case Actions.FAILURE_INITIATE_SPLITIT: {
      return {
        ...state,
        isInitiating: false,
        error: action.payload,
      }
    }
    case Actions.RECEIVE_SPLITIT_UPDATE: {
      return {
        ...state,
        data: action.payload,
      }
    }
    default: {
      return state
    }
  }
}

export default splititReducer
