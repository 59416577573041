import { useCallback, useEffect, useRef, useState } from "react"
import { useDispatch } from "react-redux"

import _, { debounce } from "lodash"

import { makeStyles } from "@material-ui/core"
import { CSSProperties } from "@material-ui/core/styles/withStyles"

import LeftCaret from "app/assets/images/left-caret-white.svg"
import RightCaret from "app/assets/images/right-caret-white.svg"
import useResizeObserver from "app/hooks/use-resize-observer"
import { updatePractitioner } from "app/store/actions"
import { colors, primaryColor } from "app/theme"
import { Practitioner } from "app/types"

import DashboardCard from "./DashboardCard"

interface Props {
  practitioner: Practitioner
}

const makeGradientStyle = (direction: "left" | "right"): CSSProperties => ({
  position: "absolute",
  right: direction === "right" ? 0 : "unset",
  left: direction === "left" ? 0 : "unset",
  top: 0,
  height: "100%",
  width: 140,
  background: `linear-gradient(${
    direction === "left" ? "-90deg" : "90deg"
  }, rgba(249, 250, 251, 0) 0%, ${colors.blueGray[100]} 80%)`,
  zIndex: 2,
})

const makeScrollButtonStyle = (direction: "left" | "right"): CSSProperties => ({
  borderRadius: 100,
  background: primaryColor,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: 58,
  width: 58,
  position: "absolute",
  right: direction === "right" ? 48 : "unset",
  left: direction === "left" ? 48 : "unset",
  top: "50%",
  marginTop: -24,
  transition: "0.24s opacity",
  zIndex: 3,
})

const useStyle = makeStyles((theme) => ({
  container: {
    width: "100%",
    maxWidth: "100%",
    position: "relative",
  },
  scrollContainer: {
    display: "flex",
    // Allow card shadows to overflow
    padding: "16px 0",
    margin: "-16px 0",
    // Hide scrollbar
    scrollbarWidth: "none",
    "-ms-overflow-style": "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    overflowX: "scroll",
  },
  row: {
    display: "flex",
    alignItems: "center",
    paddingTop: 16,
    paddingRight: 80,

    [theme.breakpoints.up("sm")]: {
      paddingTop: 24,
    },
  },
  scrollButtonLeft: makeScrollButtonStyle("left"),
  scrollButtonRight: makeScrollButtonStyle("right"),
  gradientLeft: makeGradientStyle("left"),
  gradientRight: makeGradientStyle("right"),
  scrollButtonIcon: {
    width: 10,
  },
}))

const DashboardCards = ({ practitioner }: Props) => {
  const scrollRef = useRef<HTMLDivElement>(null)
  const dispatch = useDispatch()
  const [scrollButtonsVisible, setScrollButtonsVisible] = useState(false)
  const [canScrollRight, setCanScrollRight] = useState(false)
  const [canScrollLeft, setCanScrollLeft] = useState(false)

  // We cache the updated dismiss cards here because it takes time for
  // the practitioner object to be updated. This meant dismissing multiple
  // cards in quick succession would fail.
  const [dismissedCards, setDismissedCards] = useState(
    practitioner.dismissed_dashboard_cards
  )

  const {
    container,
    scrollContainer,
    row,
    scrollButtonLeft,
    scrollButtonRight,
    gradientRight,
    gradientLeft,
    scrollButtonIcon,
  } = useStyle()

  const { width } = useResizeObserver<HTMLDivElement>({ ref: scrollRef })

  // Handle clicking the scroll right button
  const handleScrollRight = useCallback(() => {
    if (!scrollRef || !scrollRef.current) {
      return null
    }

    scrollRef.current.scrollBy({
      left: 400,
      behavior: "smooth",
    })
  }, [])

  // Handle clicking the scroll left button
  const handleScrollLeft = useCallback(() => {
    if (!scrollRef || !scrollRef.current) {
      return null
    }

    scrollRef.current.scrollBy({
      left: -400,
      behavior: "smooth",
    })
  }, [])

  // Handle scroll container resizing due to browser window resizing
  useEffect(() => {
    if (!scrollRef || !scrollRef.current) {
      return
    }

    const overflowed =
      scrollRef.current.scrollWidth > scrollRef.current.clientWidth
    setCanScrollRight(overflowed)
  }, [width])

  // Handle user manually scrolling
  const onScroll = useCallback(
    debounce(() => {
      if (!scrollRef || !scrollRef.current) {
        return
      }

      const newCanScrollRight =
        scrollRef.current.scrollLeft <
        scrollRef.current.scrollWidth - scrollRef.current.clientWidth
      const newCanScrollLeft = scrollRef.current.scrollLeft > 0

      if (canScrollRight !== newCanScrollRight) {
        setCanScrollRight(newCanScrollRight)
      }

      if (canScrollLeft !== newCanScrollLeft) {
        setCanScrollLeft(newCanScrollLeft)
      }
    }, 30),
    [
      scrollRef,
      canScrollRight,
      setCanScrollRight,
      canScrollLeft,
      setCanScrollLeft,
    ]
  )

  const handleDismissCard = useCallback(
    (name: string) => {
      const updatedDismissedCards = _.uniq([...dismissedCards, name])
      setDismissedCards(updatedDismissedCards)

      dispatch(
        updatePractitioner({
          dismissed_dashboard_cards: updatedDismissedCards,
        })
      )
    },
    [dismissedCards]
  )

  if (practitioner.dashboard_cards.length === 0) {
    return null
  }

  return (
    <div
      className={container}
      onMouseEnter={() => setScrollButtonsVisible(true)}
      onMouseLeave={() => setScrollButtonsVisible(false)}
    >
      <div className={scrollContainer} ref={scrollRef} onScroll={onScroll}>
        <div className={row}>
          {practitioner.dashboard_cards.map((name) => (
            <DashboardCard
              key={name}
              name={name}
              practitioner={practitioner}
              handleDismissCard={handleDismissCard}
            />
          ))}
        </div>
      </div>
      {canScrollLeft && <div className={gradientLeft} />}
      <div className={gradientRight} />
      <button
        className={scrollButtonLeft}
        onClick={handleScrollLeft}
        style={{
          opacity: scrollButtonsVisible && canScrollLeft ? 100 : 0,
          pointerEvents:
            scrollButtonsVisible && canScrollLeft ? "auto" : "none",
        }}
        aria-label="Scroll support cards left"
      >
        <img className={scrollButtonIcon} src={LeftCaret} alt="" />
      </button>
      <button
        className={scrollButtonRight}
        onClick={handleScrollRight}
        style={{
          opacity: scrollButtonsVisible && canScrollRight ? 100 : 0,
          pointerEvents:
            scrollButtonsVisible && canScrollRight ? "auto" : "none",
        }}
        aria-label="Scroll support cards right"
      >
        <img className={scrollButtonIcon} src={RightCaret} alt="" />
      </button>
    </div>
  )
}
export default DashboardCards
