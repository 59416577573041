import { Fragment } from "react"

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core"
import { Skeleton } from "@material-ui/lab"
import { Table as TanstackTable, flexRender } from "@tanstack/react-table"

import BodyText from "app/components/design-system/BodyText"

import { LabCompanyOrderingAccess } from "../types"

interface Props {
  table: TanstackTable<any>
}

const LabOrderingAccessTableRenderer: React.FC<Props> = ({ table }) => {
  return (
    <div className="border border-slate-200 rounded-lg bg-white shadow-sm">
      <Table className="table-auto">
        <TableHead className="bg-slate-100 py-[10px]">
          <TableHeaderRow table={table} />
        </TableHead>
        <TableBody>
          {table.options.meta?.isLoading ? (
            <TablePlaceholderRows table={table} />
          ) : table.getRowModel().rows.length > 0 ? (
            <TableDataRows table={table} />
          ) : (
            <TableEmptyRow table={table} />
          )}
        </TableBody>
      </Table>
    </div>
  )
}

const TableDataRows = ({
  table,
}: {
  table: TanstackTable<LabCompanyOrderingAccess>
}) => {
  return (
    <>
      {table.getRowModel().rows.map((row) => (
        <Fragment key={row.id}>
          <TableRow className="bg-white">
            {row.getVisibleCells().map((cell, idx) => (
              <TableCell
                key={`${cell.id}-cell-${idx}`}
                className="break-words py-[14px]"
                style={{
                  ...cell.column.columnDef.meta?.cellStyle,
                  ...cell.column.columnDef.meta?.dataCellStyle,
                }}
              >
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </TableCell>
            ))}
          </TableRow>
        </Fragment>
      ))}
    </>
  )
}

const TableHeaderRow = ({
  table,
}: {
  table: TanstackTable<LabCompanyOrderingAccess>
}) => {
  return (
    <>
      {table.getHeaderGroups().map((headerGroup) => (
        <TableRow key={headerGroup.id}>
          {headerGroup.headers.map((header) => (
            <TableCell
              key={header.id}
              className="break-words text-slate-500 text-sm13 font-semibold py-[10px]"
              colSpan={header.colSpan}
              style={{
                ...header.column.columnDef.meta?.cellStyle,
                ...header.column.columnDef.meta?.headerCellStyle,
                maxWidth: header.column.columnDef.maxSize,
                minWidth: header.column.columnDef.minSize,
                width: header.getSize(),
              }}
            >
              {header.isPlaceholder
                ? null
                : flexRender(
                    header.column.columnDef.header,
                    header.getContext()
                  )}
            </TableCell>
          ))}
        </TableRow>
      ))}
    </>
  )
}

const TableEmptyRow = ({
  table,
}: {
  table: TanstackTable<LabCompanyOrderingAccess>
}) => {
  return (
    <TableRow>
      <TableCell
        align="center"
        className="px-2 py-8 break-words"
        colSpan={table.getAllColumns().length}
      >
        <div className="flex flex-col content-center items-center bg-white gap-[5px] mx-auto md:w-2/3 lg:w-1/2">
          <BodyText className="font-semibold text-base15">
            Lab Ordering Access
          </BodyText>
          <BodyText className="text-sm">
            It looks like no-one in your Clinic has Ordering Access.
          </BodyText>
        </div>
      </TableCell>
    </TableRow>
  )
}

const TablePlaceholderRows = ({
  table,
}: {
  table: TanstackTable<LabCompanyOrderingAccess>
}) => {
  return (
    <>
      {[...Array(25).keys()].map((rowIdx) => (
        <TableRow key={`skeleton-row-${rowIdx}`} className="bg-white">
          {table.getAllColumns().map((column, colIdx) => (
            <TableCell
              key={`skeleton-cell-${rowIdx}-${colIdx}`}
              className="py-[14px]"
              style={{
                ...column.columnDef.meta?.cellStyle,
                ...column.columnDef.meta?.dataCellStyle,
              }}
            >
              {CellSkeleton(colIdx)}
            </TableCell>
          ))}
        </TableRow>
      ))}
    </>
  )
}

const CellSkeleton = (colIdx: number) => {
  switch (colIdx) {
    case 0:
      return (
        <Skeleton
          animation="wave"
          component="div"
          height={24}
          width="75%"
          variant="text"
          className="min-w-[150px]"
        />
      )
    case 1:
      return (
        <Skeleton
          animation="wave"
          component="div"
          className="rounded-2xl min-w-[50px]"
          height={30}
          width="75%"
          variant="rect"
        />
      )
    case 2:
      return (
        <div className="flex items-center justify-center gap-[6px]">
          <Skeleton
            animation="wave"
            component="div"
            height={20}
            width={20}
            variant="circle"
          />
          <Skeleton
            animation="wave"
            component="div"
            className="rounded-lg min-w-[50px]"
            height={24}
            width="50%"
            variant="text"
          />
        </div>
      )
    case 3:
      return (
        <div className="flex justify-center">
          <Skeleton
            animation="wave"
            component="div"
            height={20}
            width={20}
            variant="circle"
          />
        </div>
      )
  }
}

export default LabOrderingAccessTableRenderer
