import { OnboardingStep } from "app/types"

import * as Actions from "../actions/onboarding.actions"

export interface OnboardingState {
  surveyModalOpen: boolean
  surveyModalHasShown: boolean
  onboardingStep: OnboardingStep
}

const initialState: OnboardingState = {
  surveyModalOpen: false,
  surveyModalHasShown: false,
  onboardingStep: OnboardingStep.SURVEY,
}

function onboardingReducer(
  state = initialState,
  action: Actions.OnboardingActionType
): OnboardingState {
  switch (action.type) {
    case Actions.SHOW_SURVEY_MODAL: {
      return {
        ...state,
        surveyModalOpen: true,
        surveyModalHasShown: true,
      }
    }
    case Actions.FINISH_SURVEY_STEP: {
      return {
        ...state,
        onboardingStep: OnboardingStep.COMPLETE,
        surveyModalOpen: false,
      }
    }
    default: {
      return state
    }
  }
}

export default onboardingReducer
