import * as React from "react"

import { colors, navy } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"

const useAutoSuggestGroupingStyles = makeAppStyles((theme) => ({
  group: {
    width: "100%",
  },
  label: {
    color: navy,
    lineHeight: "1.5em",
    fontSize: 13,
    fontWeight: 600,
  },
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    width: "100%",
  },
  separator: {
    height: "1.22px",
    width: "100%",
    backgroundColor: colors.coolGray[200],
    marginTop: theme.spacing(1.375),
    marginBottom: theme.spacing(1.375),
  },
}))

export interface AutoSuggestGroupingProps {
  children: React.ReactNode
  label: React.ReactNode
  separator?: boolean
}

export default function AutoSuggestGrouping({
  children,
  label,
  separator = false,
}: AutoSuggestGroupingProps) {
  const classes = useAutoSuggestGroupingStyles()
  return (
    <div className={classes.group}>
      <div className={classes.label}>{label}</div>

      <div className={classes.content}>{children}</div>

      {separator && <hr className={classes.separator} />}
    </div>
  )
}
