import { clamp, isEmpty } from "lodash"

import { Skeleton } from "@material-ui/lab"

import { colors, navy } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"

const gradients = {
  abnormal: {
    active: "linear-gradient(90deg, #F5D350 0%, #F87471 100%)",
    inactive:
      "linear-gradient(0deg, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7)), linear-gradient(90deg, #F5D350 0%, #F87471 100%)",
  },
  normal: {
    active: "linear-gradient(90deg, #ABD36D 0%, #38D398 50%, #B3D36A 100%)",
    inactive:
      "linear-gradient(0deg, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7)), linear-gradient(90deg, #ABD36D 0%, #38D398 50%, #B3D36A 100%)",
  },
}

const RANGE_HEIGHT = 9

const useStyles = makeAppStyles((theme) => ({
  root: {
    width: "100%",
    display: "flex",
    flexFlow: "column nowrap",
    alignItems: "center",
    gap: theme.spacing(0.75),
  },
  status: {
    fontSize: 12,
    fontWeight: 700,
    textTransform: "uppercase",
  },
  ranges: {
    position: "relative",
    width: "100%",
    display: "flex",
    flexFlow: "row nowrap",
    gap: theme.spacing(0.5),
  },
  lowRange: {
    flex: 1,
    height: RANGE_HEIGHT,
    borderRadius: 2.4,
    background: gradients.abnormal.inactive,
    transform: "matrix(-1, 0, 0, 1, 0, 0)",
    "&[data-active='true']": {
      background: gradients.abnormal.active,
    },
  },
  normalRange: {
    flex: 2,
    "&[data-no-min='true'], &[data-no-max='true']": {
      flex: 3,
    },
    height: RANGE_HEIGHT,
    borderRadius: 2.4,
    background: gradients.normal.inactive,
    "&[data-active='true']": {
      background: gradients.normal.active,
    },
  },
  highRange: {
    flex: 1,
    height: RANGE_HEIGHT,
    borderRadius: 2.4,
    background: gradients.abnormal.inactive,
    "&[data-active='true']": {
      background: gradients.abnormal.active,
    },
  },
  marker: {
    zIndex: 1,
    position: "absolute",
    top: -1.5,
    marginLeft: -4.5,
    width: 12.5,
    height: 12.5,
    backgroundColor: navy,
    borderRadius: "50%",
    border: "2.5px solid white",
  },
  limits: {
    width: "100%",
    display: "flex",
    flexFlow: "row nowrap",
    fontSize: 14,
    fontWeight: 600,
    color: colors.blueGray[400],
  },
  lowLimit: {
    flex: 1,
    textAlign: "center",
  },
  highLimit: {
    flex: 1,
    textAlign: "center",
  },
  skeleton: {
    display: "inline-flex",
    borderRadius: 6,
    backgroundColor: colors.blueGray[300],
  },
}))

function getNumericStatus(value: string, normalMin: string, normalMax: string) {
  const valueNumber = Number(value)
  const hasNormalMin = !isEmpty(normalMin)
  const hasNormalMax = !isEmpty(normalMax)
  const normalMinNumber = hasNormalMin ? Number(normalMin) : valueNumber
  const normalMaxNumber = hasNormalMax ? Number(normalMax) : valueNumber

  if (valueNumber < normalMinNumber) {
    return "low"
  } else if (valueNumber > normalMaxNumber) {
    return "high"
  } else {
    return "normal"
  }
}

function getNumericRatio(value: string, normalMin: string, normalMax: string) {
  const valueNumber = Number(value)
  const hasNormalMin = !isEmpty(normalMin)
  const hasNormalMax = !isEmpty(normalMax)
  const normalMinNumber = hasNormalMin ? Number(normalMin) : valueNumber
  const normalMaxNumber = hasNormalMax ? Number(normalMax) : valueNumber

  if (hasNormalMin && hasNormalMax) {
    const spread = normalMaxNumber - normalMinNumber
    return clamp(
      (0.5 * valueNumber) / spread - (0.5 * normalMinNumber) / spread + 0.25,
      0.0,
      1.0
    )
  } else if (hasNormalMin) {
    return clamp(valueNumber / normalMinNumber - 0.75, 0.0, 1.0)
  } else if (hasNormalMax) {
    return clamp(valueNumber / normalMaxNumber - 0.25, 0.0, 1.0)
  } else {
    return 0.5
  }
}

export default function NumericBiomarkerGraphic({
  includeLabels = true,
  normalMax,
  normalMin,
  placeholder = false,
  value,
}: {
  includeLabels?: boolean
  normalMax: string
  normalMin: string
  placeholder?: boolean
  value: string
}) {
  const classes = useStyles()
  const hasNormalMin = !isEmpty(normalMin)
  const hasNormalMax = !isEmpty(normalMax)
  if (!hasNormalMin && !hasNormalMax) {
    // No pointer in rendered a graphic if there is not range of values.
    return null
  }

  const status = getNumericStatus(value, normalMin, normalMax)
  const ratio = getNumericRatio(value, normalMin, normalMax)
  const markerPosition = ratio * 100 + "%"
  return (
    <div className={classes.root}>
      {includeLabels && (
        <div className={classes.status}>
          {placeholder ? (
            <Skeleton
              animation={false}
              className={classes.skeleton}
              height={8}
              width={100}
              variant="rect"
            />
          ) : (
            (() => {
              switch (status) {
                case "low":
                  return "Below Normal"
                case "high":
                  return "Above Normal"
                case "normal":
                  return "Normal"
              }
            })()
          )}
        </div>
      )}

      <div className={classes.ranges}>
        <div className={classes.marker} style={{ left: markerPosition }} />

        {hasNormalMin && (
          <div className={classes.lowRange} data-active={status === "low"} />
        )}

        <div
          className={classes.normalRange}
          data-active={status === "normal"}
          data-no-min={!hasNormalMin}
          data-no-max={!hasNormalMax}
        />

        {hasNormalMax && (
          <div className={classes.highRange} data-active={status === "high"} />
        )}
      </div>

      {includeLabels && (
        <div className={classes.limits}>
          <div className={classes.lowLimit}>
            {hasNormalMin &&
              (placeholder ? (
                <Skeleton
                  animation={false}
                  className={classes.skeleton}
                  height={8}
                  width={24}
                  variant="rect"
                />
              ) : (
                normalMin
              ))}
          </div>

          <div className={classes.highLimit}>
            {hasNormalMax &&
              (placeholder ? (
                <Skeleton
                  animation={false}
                  className={classes.skeleton}
                  height={8}
                  width={24}
                  variant="rect"
                />
              ) : (
                normalMax
              ))}
          </div>
        </div>
      )}
    </div>
  )
}
