// import Slide from '@mui/material/Slide'
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import { useHistory } from "react-router-dom"

import {
  Divider,
  Icon,
  IconButton,
  Slide,
  Snackbar,
  SnackbarContent,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Theme,
  makeStyles,
} from "@material-ui/core"

import couponTagIcon from "app/assets/icons/coupon-tag-teal.svg"
import DesignSystemButton from "app/components/design-system/Button"
import * as Actions from "app/main/dashboard/store/actions"
import { colors, lightRupaTeal, rupaTeal, textPrimaryColor } from "app/theme"
import { Coupon, Practitioner } from "app/types"
import { getCookie, removeCookie } from "app/utils/cookie-util"

import BodyText from "../design-system/BodyText"

interface StyleProps {}

interface Props {
  open: boolean
  coupon: Coupon | undefined
  practitioner: Practitioner
  startNewOrder: () => void
  closeSlideout: () => void
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  snackbar: {
    [theme.breakpoints.up("md")]: {
      left: 294,
      width: "362px",
    },
    [theme.breakpoints.down("md")]: {
      left: 90,
    },
    [theme.breakpoints.down("xs")]: {
      left: 7,
    },
  },
  snackbarContent: {
    padding: "15px",
    backgroundColor: "#fff",
    border: `4px solid ${rupaTeal}`,
    color: textPrimaryColor,
    borderRadius: 12,
    minWidth: "100%",
  },
  snackbarMessage: {
    width: "100%",
  },
  couponTagContainer: {
    padding: 14.5,
    background: lightRupaTeal,
    display: "inline-block",
    width: "45px",
    height: "45px",
    borderRadius: 8,
    "&.disabled": {
      backgroundColor: colors.blueGray[50],
    },
  },
  styledTableRow: {
    "&:not(:last-of-type)": {
      borderBottom: `1px solid ${colors.blueGray[200]}`,
    },
  },
  bodyTableCell: {
    borderBottom: "none",
    padding: "9px 0px 22px 20px",
    width: "max-content",
    "&:first-of-type": {
      paddingLeft: "0px",
    },
  },
  snackbarTitle: {
    color: rupaTeal,
    marginBottom: 0,
  },
  ctaBtn: {
    minWidth: "100%",
    marginTop: 12,
  },
  closeBtn: {
    position: "absolute",
    right: 3,
    top: 7,
    color: colors.blueGray[400],
  },
  divider: {
    width: "100%",
  },
}))

function SlideTransition(props) {
  return (
    <Slide {...props} direction="right" easing="easeInOutQuad" timeout={600} />
  )
}

export default function NewCouponSlideOutModal({
  open,
  coupon,
  startNewOrder,
  practitioner,
  closeSlideout,
}: Props) {
  const history = useHistory()
  const styles = useStyles()
  const dispatch = useDispatch()

  const [showSnackbar, setShowSnackbar] = useState(false)
  const [loading, setLoading] = useState(false)

  const setCodeForSessionStorage = () => {
    const promoCouponCodeCookie = getCookie("promotional_coupon_code")

    if (promoCouponCodeCookie) {
      window.sessionStorage.setItem(
        "coupon_code_for_checkout",
        promoCouponCodeCookie.value.toString()
      )
    }
  }

  const handleClose = (event, reason) => {
    if (reason !== "clickaway") {
      removeCookie("promotional_coupon_code")
      closeSlideout()
    }
  }

  useEffect(() => {
    setShowSnackbar(open)
  }, [open])

  // During the product tour, Hide the existing list of patients for that clinic, So
  // that the staff/practitioner will not select an existing patient. (Selecting an
  // existing patient will prevent the tour from happening.)
  const patients = useSelector(
    ({ dashboard }: any) => dashboard.dashboard.patients
  )

  const handleClick = () => {
    setLoading(true)
    setCodeForSessionStorage()
    removeCookie("promotional_coupon_code")

    if (practitioner?.user && coupon?.is_practitioner_self_order_only) {
      const matchingPatient = patients.find(
        (patient) => patient.user.email === practitioner.user.email
      )
      if (matchingPatient) {
        history.push(`/checkout/create/${matchingPatient.id}`)
      } else {
        dispatch(
          Actions.createPatient({
            firstName: practitioner.user.first_name,
            lastName: practitioner.user.last_name,
            email: practitioner.user.email,
            confirmDuplicateEmail: false,
            startNewOrder: true,
          })
        )
      }
    } else {
      startNewOrder()
    }

    closeSlideout()
  }

  const snackbarContent = (
    <>
      <BodyText size="base" weight="semibold" className={styles.snackbarTitle}>
        Coupon Available
      </BodyText>
      <IconButton
        key="close"
        aria-label="Close"
        color="inherit"
        className={styles.closeBtn}
        onClick={(e) => handleClose(e, "iconClick")}
        data-cy="closeNewCouponSlideout"
      >
        <Icon>close</Icon>
      </IconButton>
      <Table>
        <colgroup>
          <col width="47px" />
          <col width="auto" />
          <col width="auto" />
        </colgroup>
        <TableBody>
          <TableRow className={styles.styledTableRow}>
            <TableCell className={styles.bodyTableCell} size="small">
              <div className={styles.couponTagContainer}>
                <img src={couponTagIcon} alt="tag" />
              </div>
            </TableCell>
            <TableCell className={styles.bodyTableCell} align="left">
              <BodyText weight="semibold" size="sm">
                {coupon?.practitioner_title}
              </BodyText>
              <BodyText size="sm" style={{ marginTop: 3 }}>
                {coupon?.practitioner_subtitle}
              </BodyText>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <Divider className={styles.divider} />
      <DesignSystemButton
        color="primary"
        loading={loading}
        variant="text"
        onClick={handleClick}
        className={styles.ctaBtn}
        data-cy="couponStartOrder"
      >
        Start an Order
      </DesignSystemButton>
    </>
  )
  return (
    <Snackbar
      open={showSnackbar}
      onClose={handleClose}
      TransitionComponent={SlideTransition}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      classes={{ root: styles.snackbar }}
    >
      <SnackbarContent
        message={snackbarContent}
        action={null}
        classes={{
          root: styles.snackbarContent,
          message: styles.snackbarMessage,
        }}
      />
    </Snackbar>
  )
}
