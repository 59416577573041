import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableFooter,
  Link,
} from "@material-ui/core"
import { Skeleton } from "@material-ui/lab"
import { Row, Table as TanstackTable, flexRender } from "@tanstack/react-table"

import BodyText from "app/components/design-system/BodyText"
import TablePaginationRenderer from "app/dashboard/components/TablePaginationRenderer"
import { LabCompanyBiomarkerRange } from "types/lab-company-biomarker-range"

import TableDataRow from "./TableDataRow"
import useTableStyles from "./hooks/use-table-styles"

export interface TableRowDetailContext {
  row: Row<any>
}

export interface TableRendererProps {
  table: TanstackTable<any>
  renderTabs: (info: any) => React.ReactElement
}

export default function TableRenderer({
  table,
  renderTabs,
}: TableRendererProps) {
  const classes = useTableStyles()

  return (
    <>
      {renderTabs({ table })}
      <div className={classes.container}>
        <Table className={classes.table}>
          <TableHead>
            <TableHeaderRow table={table} />
          </TableHead>

          <TableBody>
            {table.options.meta?.isLoading ? (
              <TablePlaceholderRows table={table} />
            ) : table.getRowModel().rows.length > 0 ? (
              <TableDataRows table={table} />
            ) : (
              <TableEmptyRow table={table} />
            )}
          </TableBody>

          <TableFooter>
            <TableRow className={classes.tablePaginationRow}>
              <TablePaginationRenderer table={table} />
            </TableRow>
          </TableFooter>
        </Table>
      </div>
    </>
  )
}

const TableHeaderRow = ({
  table,
}: {
  table: TanstackTable<LabCompanyBiomarkerRange>
}) => {
  const classes = useTableStyles()
  return (
    <>
      {table.getHeaderGroups().map((headerGroup) => (
        <TableRow key={headerGroup.id} className={classes.tableHeaderRow}>
          {headerGroup.headers.map((header) => (
            <TableCell
              key={header.id}
              className={classes.tableCell}
              colSpan={header.colSpan}
              style={{
                ...header.column.columnDef.meta?.cellStyle,
                ...header.column.columnDef.meta?.headerCellStyle,
                maxWidth: header.column.columnDef.maxSize,
                minWidth: header.column.columnDef.minSize,
                width: header.getSize(),
              }}
            >
              {header.isPlaceholder
                ? null
                : flexRender(
                    header.column.columnDef.header,
                    header.getContext()
                  )}
            </TableCell>
          ))}
        </TableRow>
      ))}
    </>
  )
}

const TablePlaceholderRows = ({
  table,
}: {
  table: TanstackTable<LabCompanyBiomarkerRange>
}) => {
  const classes = useTableStyles()
  return (
    <>
      {[0, 1, 2, 3, 4].map((index) => (
        <TableRow key={index} className={classes.tableDataRow}>
          {table.getAllColumns().map((column) => (
            <TableCell
              key={column.id}
              className={classes.tableCell}
              style={{
                ...column.columnDef.meta?.cellStyle,
                ...column.columnDef.meta?.dataCellStyle,
              }}
            >
              {Boolean(column.accessorFn) ? (
                <Skeleton
                  animation="wave"
                  component="div"
                  height={24}
                  width="75%"
                  variant="text"
                />
              ) : null}
            </TableCell>
          ))}
        </TableRow>
      ))}
    </>
  )
}

const TableDataRows = ({
  table,
}: {
  table: TanstackTable<LabCompanyBiomarkerRange>
}) => {
  return (
    <>
      {table.getRowModel().rows.map((row) => (
        <TableDataRow key={row.id} table={table} row={row} />
      ))}
    </>
  )
}

const TableEmptyRow = ({
  table,
}: {
  table: TanstackTable<LabCompanyBiomarkerRange>
}) => {
  const classes = useTableStyles()

  return (
    <TableRow className={classes.tableEmptyRow}>
      <TableCell
        align="center"
        className={classes.tableCell}
        colSpan={table.getAllColumns().length}
      >
        <div className={classes.tableEmptyRowText}>
          <BodyText className={classes.emptyText}>
            We couldn't find a marker that matched your search.{" "}
            <Link
              className="cursor-pointer"
              onClick={() => window.Intercom("showNewMessage")}
            >
              Let us know
            </Link>{" "}
            if you'd like us to customize an optimal range for a specific
            patient demographic or a marker not shown here!
          </BodyText>
        </div>
      </TableCell>
    </TableRow>
  )
}
