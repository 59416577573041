import { useController, useFormContext } from "react-hook-form"

import MaskedDateField, { MaskedDateFieldProps } from "./MaskedDateField"

export type ControlledMaskedDateFieldProps = Omit<
  MaskedDateFieldProps,
  "error" | "helperText" | "onBlur" | "onChange" | "value"
> & {
  defaultValue?: string
  name: string
  required?: boolean
  shouldUnregister?: boolean
}

export default function ControlledMaskedDateField({
  defaultValue,
  name,
  required,
  shouldUnregister,
  ...maskedDateFieldProps
}: ControlledMaskedDateFieldProps) {
  const { control } = useFormContext()
  const {
    field: { ref, ...controlProps },
    fieldState: { error },
  } = useController({
    control,
    defaultValue: defaultValue || "",
    name: `${name}` as const,
    shouldUnregister,
  })
  return (
    <MaskedDateField
      error={Boolean(error)}
      helperText={error?.message}
      inputRef={ref}
      required={required}
      {...maskedDateFieldProps}
      {...controlProps}
    />
  )
}
