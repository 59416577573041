import * as React from "react"
import { useState } from "react"

import {
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Theme,
  styled,
  useMediaQuery,
} from "@material-ui/core"

import { colors, primaryColor, shadows, titleFontFamily } from "app/theme"

interface StyledMenuProps {
  isXsMobile?: boolean
}
const StyledMenu = styled(({ isXsMobile, ...other }) => <Menu {...other} />)({
  "& .MuiMenu-paper": {
    border: `1px solid ${colors.blueGray[300]}`,
    borderRadius: 6,
    shadow: shadows.lg,
    marginTop: 5,
    marginLeft: ({ isXsMobile }: StyledMenuProps) => {
      return isXsMobile ? "0" : "-15"
    },
  },
  "& .MuiMenu-list": {
    padding: 0,
  },
  "& .MuiButtonBase-root.Mui-disabled": {
    pointerEvents: "all",
  },
})

const StyledMenuItem = styled((props) => <MenuItem {...props} />)({
  marginLeft: 10,
  marginRight: 10,
  borderBottom: `1px solid ${colors.blueGray[200]}`,
  "&:last-child": {
    borderBottom: "none",
  },
  backgroundColor: "white !important",
})

const StyledListItemText = styled<typeof ListItemText>(ListItemText)({
  color: primaryColor,
  fontSize: 15,
  fontFamily: titleFontFamily,
  "& .MuiListItemText-primary": {
    fontWeight: 600,
  },
})

const StyledListItemIcon = styled<typeof ListItemIcon>(ListItemIcon)({
  minWidth: 0,
})

const IconImage = styled<any>("img")({
  width: 16,
})

export function DropdownMenuItem({
  text,
  onClick,
  disabled,
  icon,
}: {
  text: string
  onClick: any
  disabled?: boolean
  icon?: string
}) {
  return (
    <StyledMenuItem disableGutters={true} onClick={onClick} disabled={disabled}>
      <StyledListItemText primary={text} />
      {icon && (
        <StyledListItemIcon>
          <IconImage src={icon} />
        </StyledListItemIcon>
      )}
    </StyledMenuItem>
  )
}

export function DropdownMenu({
  base,
  children,
}: {
  base: JSX.Element
  children: any
}) {
  const [anchorEl, setAnchorEl] = useState<Element | null>(null)
  const isXsMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("xs")
  )

  const handleClick = (event: React.MouseEvent) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = (): void => {
    setAnchorEl(null)
  }

  const styledMenuPaperProps = isXsMobile
    ? {
        style: {
          right: 42,
        },
      }
    : {}
  return (
    <>
      <div onClick={handleClick}>{base}</div>
      <StyledMenu
        PaperProps={styledMenuPaperProps}
        isXsMobile={isXsMobile}
        onClose={handleClose}
        elevation={0}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: isXsMobile ? "left" : "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: isXsMobile ? "left" : "center",
        }}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
      >
        {children}
      </StyledMenu>
    </>
  )
}
