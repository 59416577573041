import * as React from "react"

import { makeStyles } from "@material-ui/core"

import ArrowRightAlt from "app/assets/icons/arrow-right-alt.svg"
import DesignSystemButton from "app/components/design-system/Button"
import { colors, primaryColor } from "app/theme"

import BodyText from "./design-system/BodyText"
import DisplayText from "./design-system/DisplayText"

const useStyles = makeStyles({
  container: {
    background: "white",
    borderRadius: 8,
    display: "flex",
    flexFlow: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "90px 30px",
    border: `2px dashed ${colors.blueGray[300]}`,
  },
  titleStyle: {
    marginBottom: 24,
  },
  subtitleStyle: {
    maxWidth: 600,
    textAlign: "center",
  },
  imageStyle: {
    maxWidth: 440,
    width: "100%",
  },
  buttonStyle: {
    marginBottom: 50,
    borderColor: primaryColor,
    borderWidth: "3px",
  },
  arrowStyle: {
    position: "relative",
    top: "-3px",
    marginLeft: 14,
  },
})

interface Props {
  title: string
  subtitle: React.ReactNode
  image: string
  showActionBtn?: boolean
  actionBtnText?: string
  actionBtnEvent?: React.MouseEventHandler<HTMLButtonElement>
}

const PageEmptyState = ({
  title,
  subtitle,
  image,
  showActionBtn,
  actionBtnText,
  actionBtnEvent,
}: Props) => {
  const {
    container,
    titleStyle,
    subtitleStyle,
    imageStyle,
    buttonStyle,
    arrowStyle,
  } = useStyles()

  return (
    <div className={container}>
      <DisplayText weight="semibold" size="xl" className={titleStyle}>
        {title}
      </DisplayText>
      <BodyText
        size="md"
        className={subtitleStyle}
        style={{ marginBottom: showActionBtn ? 21 : 60 }}
      >
        {subtitle}
      </BodyText>
      {showActionBtn && (
        <DesignSystemButton
          color="secondary"
          size="medium"
          className={buttonStyle}
          onClick={actionBtnEvent}
        >
          {actionBtnText}
          <img src={ArrowRightAlt} alt="ArrowRightAlt" className={arrowStyle} />
        </DesignSystemButton>
      )}
      <img src={image} className={imageStyle} alt="" />
    </div>
  )
}

export default PageEmptyState
