import { combineReducers } from "redux"

import message from "./message.reducer"
import navbar from "./navbar.reducer"

const fuseReducers = combineReducers({
  navbar,
  message,
})

export default fuseReducers
