import { useMemo, useState } from "react"

import { uniq } from "lodash"
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Line,
  LineChart,
  ReferenceArea,
  ReferenceLine,
  ResponsiveContainer,
} from "recharts"

import { BiomarkerStatus } from "app/patient-portal/blood-lab-dashboard/constants"
import { colors, primaryColor } from "app/theme"

import {
  getColorFromGradient,
  getRatioBetweenPoints,
} from "../blood-lab-dashboards/NumericBiomarkerGraphic/utils"
import { getBiomarkerStatusResultsOverTime } from "../blood-lab-dashboards/utils"
import {
  RESULT_STATUS_TO_CHART_COLOR,
  STATUS_GRADIENT_MAP,
} from "../patient-orders/trends/constants/constants"
import {
  ResultsOverTimeDateGrouping,
  ResultsOverTimeResultChartData,
  ResultsOverTimeResultData,
} from "../patient-orders/trends/types/types"
import { convertTimestampForHeader } from "../patient-orders/trends/util"
import CustomTooltip from "./chart-components/CustomTooltip"
import {
  calculateInbetweenLinearGradientColor,
  getMinAndMaxChartValues,
  parseStringValueToFloat,
  renderDot,
  roundNumber,
} from "./util"

const CHART_HEIGHT_PX = 250
const CHART_WIDTH_PX = 800
const CHART_PADDING_Y = 40
const WIDTH_OF_Y_AXIS = 70
// The offset of the tooltip in the chart in the x-direction
const TOOLTIP_OFFSET = -100
// The height of the tooltip in pixels
const HEIGHT_OF_TOOLTIP = 165
const REFERENCE_AREA_BACKGROUND = colors.green[50]
const REFERENCE_AREA_BACKGROUND_OPTIMAL_RANGE = colors.green[100]
const TOOLTIP_POSITION_CORRECTION_FACTOR = -0.25
// The offset of the start of ticks for the graph in pixels
const CHART_TICKS_OFFSET = 50

interface Props {
  onTimepointClick: (biomarkerId?: string) => void
  groupedBy: ResultsOverTimeDateGrouping
  resultData: ResultsOverTimeResultData[]
  activeDotLabel?: string
  clinicName: string
}

const ResultsOverTimeChart = ({
  onTimepointClick,
  groupedBy,
  resultData,
  activeDotLabel,
  clinicName,
}: Props) => {
  const [activeDot, setActiveDot] = useState<string | undefined>(activeDotLabel)
  const [hoveredDot, setHoveredDot] = useState<string | undefined>(undefined)

  const getActiveDotCoords = () => {
    if (hoveredDot) {
      const dataPoint = chartData.find((d) => d.created_at === hoveredDot)

      if (dataPoint && !Number.isNaN(dataPoint.value)) {
        const yMax = ticks[ticks.length - 1]
        const yMin = ticks[0]
        const drawableHeight = CHART_HEIGHT_PX - 2 * CHART_PADDING_Y
        const valueRange = yMax - yMin
        const scaleFactor = drawableHeight / valueRange

        let position =
          (yMax - (dataPoint?.value ?? 0)) * scaleFactor + CHART_PADDING_Y

        // Correction factor to adjust for scale of recharts tooltip
        const correctionFactor = TOOLTIP_POSITION_CORRECTION_FACTOR
        position += (position - CHART_HEIGHT_PX / 2) * correctionFactor

        const positionWithTooltipAdjustment = position - HEIGHT_OF_TOOLTIP

        return positionWithTooltipAdjustment
      }
    }

    return undefined
  }

  const getActiveDotData = (activeDotLabel) => {
    const matchedDataPoint = chartData.find(
      (d) => d.created_at === activeDotLabel
    )
    if (matchedDataPoint && !Number.isNaN(matchedDataPoint.value)) {
      const labCompanyName = matchedDataPoint.labCompanyName
        ? matchedDataPoint.labCompanyName
        : ""

      return {
        value: matchedDataPoint.value,
        display_value: matchedDataPoint.display_value,
        name: matchedDataPoint.created_at,
        status: matchedDataPoint.status,
        unit: matchedDataPoint.unit,
        subText: matchedDataPoint.id
          ? `${labCompanyName} test imported by ${clinicName}`
          : `From ${labCompanyName} test`,
        standardRangeMin: matchedDataPoint.standardRangeMin,
        standardRangeMax: matchedDataPoint.standardRangeMax,
        optimalRangeMin: matchedDataPoint.optimalRangeMin,
        optimalRangeMax: matchedDataPoint.optimalRangeMax,
      }
    }

    return undefined
  }

  const generateHorizontalCoordinates = ({ yAxis }) => {
    // Get the scale function from the yAxis which converts a data value to a pixel value
    const scale = yAxis.scale

    // Calculate the pixel positions for the ticks using the scale function
    // The scale function will take into account the domain and range of the yAxis
    // Adjust for the top padding
    const coordinates = ticks.map(
      (tick) =>
        CHART_HEIGHT_PX - scale(tick) - CHART_PADDING_Y * 2 + CHART_TICKS_OFFSET
    )

    return coordinates
  }

  const generateChartData = () => {
    const newResults: ResultsOverTimeResultChartData[] = []

    const startingIndex = resultData.length - 1

    let xIndex = 0

    // Iterate backwards as first result is most recent
    for (let index = startingIndex; index >= 0; index--) {
      const result = resultData[index]

      if (result.value) {
        newResults.push({
          id: result.id,
          value: parseStringValueToFloat(result.value),
          display_value: result.display_value || "",
          alternateValue: result.alternate_value,
          created_at: result.created_at,
          x: xIndex,
          status: getBiomarkerStatusResultsOverTime(result),
          unit: result.unit,
          labCompanyName: result.lab_company_results_name,
          standardRangeMin: parseStringValueToFloat(result.standard_range_min),
          standardRangeMax: parseStringValueToFloat(result.standard_range_max),
          optimalRangeMin: parseStringValueToFloat(result.optimal_range_min),
          optimalRangeMax: parseStringValueToFloat(result.optimal_range_max),
        })
      }

      xIndex++
    }

    return newResults
  }

  const formatDateTick = (tick: string) => {
    return convertTimestampForHeader(tick, groupedBy)
  }

  const generateLinearGradient = () => {
    let linearGradients: JSX.IntrinsicElements["stop"][] = []
    let statuses: (BiomarkerStatus | undefined)[] = []
    const percentageGap = 100 / (chartData.length - 1)

    for (let i = 0; i < chartData.length; i++) {
      const result = chartData[i]
      const stringVal = !Number.isNaN(result.value)
        ? result.value.toString()
        : ""
      const stringStandardMin = !Number.isNaN(result.standardRangeMin)
        ? result.standardRangeMin.toString()
        : ""
      const stringStandardMax = !Number.isNaN(result.standardRangeMax)
        ? result.standardRangeMax.toString()
        : ""
      const stringOptimalMin = !Number.isNaN(result.optimalRangeMin)
        ? result.optimalRangeMin.toString()
        : ""
      const stringOptimalMax = !Number.isNaN(result.optimalRangeMax)
        ? result.optimalRangeMax.toString()
        : ""

      const status = result.status

      const ratio = getRatioBetweenPoints(
        stringVal.toString(),
        stringStandardMin.toString(),
        stringStandardMax.toString(),
        stringOptimalMin.toString(),
        stringOptimalMax.toString()
      )

      const color = status
        ? getColorFromGradient(STATUS_GRADIENT_MAP[status], ratio * 100)
        : colors.blueGray[500]

      const currOffset = i * percentageGap

      linearGradients.push(
        <stop offset={`${currOffset}%`} stop-color={color} />
      )

      if (statuses.length > 0) {
        const prevStatus = statuses[statuses.length - 1]
        const halfwayPercentageGap = currOffset - percentageGap / 2

        const inBetweenColor =
          status && prevStatus
            ? calculateInbetweenLinearGradientColor(status, prevStatus)
            : undefined

        if (inBetweenColor) {
          linearGradients.splice(
            linearGradients.length - 1,
            0,
            <stop
              offset={`${halfwayPercentageGap}%`}
              stop-color={inBetweenColor}
            />
          )
        }
      }

      statuses.push(status)
    }

    return linearGradients
  }

  const areAllResultValuesTheSame = () => {
    for (let i = 0; i <= chartData.length - 2; i++) {
      if (chartData[i].value !== chartData[i + 1].value) {
        return false
      }
    }

    return true
  }

  const getLineColor = (
    chartData: ResultsOverTimeResultChartData | undefined
  ) => {
    const status = chartData?.status
    if (status) {
      return RESULT_STATUS_TO_CHART_COLOR[status]
    } else {
      return colors.blueGray[500]
    }
  }

  const chartData: ResultsOverTimeResultChartData[] = useMemo(() => {
    return generateChartData()
  }, [resultData])

  const ticks = useMemo(() => {
    const numTicksToGenerate = 3
    const [minChartRangeValue, maxChartRangeValue] =
      getMinAndMaxChartValues(chartData)

    if (maxChartRangeValue === minChartRangeValue) {
      const offset = (maxChartRangeValue / 3) * 1.5
      return Array.from({ length: numTicksToGenerate }, (_, i) =>
        roundNumber((i + 1) * offset)
      )
    } else {
      let offset = ((maxChartRangeValue - minChartRangeValue) / 3) * 1.5

      if (minChartRangeValue - offset < 0) {
        offset = (maxChartRangeValue / 3) * 1.5
        return [0, roundNumber(offset), roundNumber(offset * 2)]
      } else {
        return [
          roundNumber(minChartRangeValue - offset),
          roundNumber(minChartRangeValue + offset),
          roundNumber(maxChartRangeValue + offset),
        ]
      }
    }
  }, [chartData])

  // If results belong to multiple lab companies, we should ignore their standard range
  // and not show it on the graph
  const hasMultipleLabCompanies = useMemo(() => {
    const firstLabCompanyId = resultData[0]?.lab_company_id

    return resultData.some(
      (result) => result.lab_company_id !== firstLabCompanyId
    )
  }, [resultData])

  const [standardRangeMin, standardRangeMax, optimalRangeMin, optimalRangeMax] =
    useMemo(() => {
      // This code block is for the scenario where the standard range is not within the first result
      // but other results do have a standard range
      let sharedStandardMin = resultData[0]?.standard_range_min
      let sharedStandardMax = resultData[0]?.standard_range_max

      if (
        !hasMultipleLabCompanies &&
        !sharedStandardMin &&
        !sharedStandardMax
      ) {
        for (const result of resultData) {
          if (result.standard_range_min || result.standard_range_max) {
            sharedStandardMin = result.standard_range_min
            sharedStandardMax = result.standard_range_max
            break
          }
        }
      }

      return [
        parseStringValueToFloat(sharedStandardMin),
        parseStringValueToFloat(sharedStandardMax),
        parseStringValueToFloat(resultData[0]?.optimal_range_min),
        parseStringValueToFloat(resultData[0]?.optimal_range_max),
      ]
    }, [resultData])

  const showStandardRangeArea = useMemo(() => {
    return standardRangeMin || standardRangeMax
  }, [standardRangeMin, standardRangeMax])

  const showOptimalRangeArea = useMemo(() => {
    return showStandardRangeArea && (optimalRangeMin || optimalRangeMax)
  }, [optimalRangeMin, optimalRangeMax, showStandardRangeArea])

  const isActiveDotInChart = useMemo(() => {
    return chartData.some((d) => d.created_at === activeDot)
  }, [activeDot])

  const yAxisUnit = useMemo(() => {
    // If the chart has multiple units, we still show the Y axis, but remove the
    // units. This was a request from product. It's probable that we'll need a better
    // solution for managing results with differing units for a single biomarker.
    const hasMultipleUnits = uniq(chartData.map((d) => d.unit)).length > 1
    if (hasMultipleUnits) {
      return ""
    }

    return chartData[0]?.unit ?? ""
  }, [chartData])

  return (
    <ResponsiveContainer width="100%" height={CHART_HEIGHT_PX}>
      <LineChart
        width={CHART_WIDTH_PX}
        height={CHART_HEIGHT_PX}
        data={chartData}
        onClick={(e) => {
          onTimepointClick(e?.activePayload?.[0]?.payload?.id)
          setActiveDot(e?.activeLabel)
        }}
        onMouseMove={(e) => {
          setHoveredDot(e?.activeLabel)
        }}
      >
        <CartesianGrid
          strokeDasharray="3 3"
          vertical={false}
          horizontalCoordinatesGenerator={generateHorizontalCoordinates}
        />
        <XAxis
          axisLine={{ stroke: colors.blueGray[100], strokeWidth: 1 }}
          tickLine={false}
          dataKey="created_at"
          domain={["dataMin - 1", "dataMax + 1"]}
          interval={0}
          tick={{ fill: colors.blueGray[500], fontSize: 12, fontWeight: 600 }}
          tickFormatter={formatDateTick}
          padding={{ left: 40, right: 40 }}
        />
        <YAxis
          tickLine={false}
          orientation="right"
          unit={yAxisUnit}
          axisLine={false}
          domain={[`dataMin`, `dataMax`]}
          width={WIDTH_OF_Y_AXIS}
          padding={{ top: CHART_PADDING_Y, bottom: CHART_PADDING_Y }}
          ticks={ticks}
          tick={{ fill: colors.blueGray[500], fontSize: 12, fontWeight: 600 }}
        />

        {!hasMultipleLabCompanies && (
          <>
            {showStandardRangeArea && (
              <ReferenceArea
                y1={standardRangeMin || ticks[0]}
                y2={standardRangeMax || ticks[ticks.length - 1]}
                strokeOpacity={0}
                fill={REFERENCE_AREA_BACKGROUND}
                fillOpacity={1}
              />
            )}
            {showOptimalRangeArea && (
              <ReferenceArea
                y1={optimalRangeMin || standardRangeMin || ticks[0]}
                y2={
                  optimalRangeMax || standardRangeMax || ticks[ticks.length - 1]
                }
                strokeOpacity={0}
                fill={REFERENCE_AREA_BACKGROUND_OPTIMAL_RANGE}
                fillOpacity={1}
              />
            )}
          </>
        )}

        {isActiveDotInChart && (
          <ReferenceLine
            x={activeDot}
            ifOverflow="extendDomain"
            stroke={primaryColor}
            label=""
          />
        )}

        <Tooltip
          offset={TOOLTIP_OFFSET}
          allowEscapeViewBox={{ x: true, y: true }}
          position={{ y: getActiveDotCoords() }}
          isAnimationActive={false}
          content={<CustomTooltip data={getActiveDotData(hoveredDot)} />}
        />

        <defs>
          <linearGradient id="range" x1="0%" y1="0%" x2="100%" y2="0%">
            {generateLinearGradient()}
          </linearGradient>
        </defs>

        <Line
          type="monotone"
          strokeWidth={3}
          dataKey="value"
          stroke={
            // If all result values are the same we should not use the gradient
            // as it renders a clear line. Instead, just render a solid colors
            areAllResultValuesTheSame()
              ? getLineColor(chartData[0])
              : "url(#range)"
          }
          isAnimationActive={false}
          dot={(props) =>
            renderDot({
              ...props,
              activeDot,
            })
          }
          activeDot={(props) =>
            renderDot({
              ...props,
              activeDot,
              hover: true,
            })
          }
        />
      </LineChart>
    </ResponsiveContainer>
  )
}

export default ResultsOverTimeChart
