const TrendsDisclaimer = () => {
  return (
    <div className="text-slate-500 text-xs">
      This visualization is intended for informational purposes only and should
      not be used as a substitute for professional medical advice, diagnosis, or
      treatment. The original lab report (the “Report”) is the official
      documentation of your results; please always refer back to the Report in
      Documents. Rupa is not liable for any discrepancies between the Report and
      this visualization.
    </div>
  )
}

export default TrendsDisclaimer
