import { FuseSplashScreen } from "@fuse"
import { Redirect } from "react-router-dom"
import { useSearchParam } from "react-use"

import { GuestPaths } from "app/Routes"

import useAccountExists from "./use-account-exists"

export default function PatientPortalCallback() {
  const patientPortalToken = useSearchParam("patient-portal-token")
  const { data, error } = useAccountExists(patientPortalToken)

  const loginRedirect = (
    <Redirect
      to={{ pathname: GuestPaths.LOGIN, search: window.location.search }}
    />
  )
  const patientSignupRedirect = (
    <Redirect
      to={{
        pathname: GuestPaths.PATIENT_SIGNUP,
        search: window.location.search,
      }}
    />
  )

  if (!patientPortalToken) {
    return loginRedirect
  }

  if (error) {
    return loginRedirect
  }

  if (data && data.account_exists) {
    return loginRedirect
  }

  if (data && !data.account_exists) {
    return patientSignupRedirect
  }

  return <FuseSplashScreen />
}
