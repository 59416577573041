import { makeStyles, Grid } from "@material-ui/core"
import PauseIcon from "@material-ui/icons/Pause"

import Clock from "app/assets/icons/clock-modern.svg"
import BodyText from "app/components/design-system/BodyText"
import {
  PractitionerProfileApplicationStatus,
  PractitionerProfilePublishingStatus,
} from "app/constants.typed"
import { rupaTeal, colors } from "app/theme"

const useStyles = makeStyles((theme) => ({
  statusContainer: {
    display: "flex",
    padding: "14px 13px",
    height: "72px",
    borderRadius: "8px",
  },
  reviewStatusContainer: {
    background: colors.lightBlue[50],
    border: `1px solid ${rupaTeal}`,
  },
  pausedStatusContainer: {
    background: colors.amber[50],
    border: `1px solid ${colors.amber[500]}`,
  },
  statusIcon: {
    height: "100%",
    marginBottom: "auto",
    marginRight: "4px",
  },
  reviewTitle: {
    marginBottom: 4,
    color: rupaTeal,
  },
  reviewDescription: {
    color: rupaTeal,
  },
  pausedTitle: {
    marginBottom: 4,
    color: colors.amber[800],
  },
  pausedDescription: {
    color: colors.amber[800],
  },
}))

function ProfileStatusCard({ profile, application }) {
  const classes = useStyles()

  const profileUnderReviewComponent = (
    <div
      className={`${classes.reviewStatusContainer} ${classes.statusContainer}`}
    >
      <div className={classes.statusIcon}>
        <img src={Clock} alt="clock" />
      </div>
      <div>
        <BodyText size="sm" weight="semibold" className={classes.reviewTitle}>
          Profile Under Review
        </BodyText>
        <BodyText size="sm" className={classes.reviewDescription}>
          Find a Practitioner is currently in beta. We'll let you know when your
          page is approved!
        </BodyText>
      </div>
    </div>
  )

  const profilePausedComponent = (
    <div
      className={`${classes.pausedStatusContainer} ${classes.statusContainer}`}
    >
      <PauseIcon className={classes.statusIcon} />
      <div>
        <BodyText size="sm" weight="semibold" className={classes.pausedTitle}>
          Paused
        </BodyText>
        <BodyText size="sm" className={classes.pausedDescription}>
          You paused your profile page. Click the Publish button to set it live
          again.
        </BodyText>
      </div>
    </div>
  )

  if (
    application?.attributes?.status ===
    PractitionerProfileApplicationStatus.PENDING
  ) {
    return (
      <Grid item xs={12} md={5}>
        {profileUnderReviewComponent}
      </Grid>
    )
  }

  if (
    profile?.attributes?.publishing_status ===
    PractitionerProfilePublishingStatus.PAUSED
  ) {
    return (
      <Grid item xs={12} md={5}>
        {profilePausedComponent}
      </Grid>
    )
  }

  return null
}

export default ProfileStatusCard
