import axios from "axios"
import useSWR, { KeyedMutator } from "swr"

import { OrderStatusesState } from "app/main/dashboard/constants"
import { getApiBaseUrl } from "app/utils"

export interface DashboardOrderStatuses {
  data: OrderStatusesState | undefined
  mutate: KeyedMutator<OrderStatusesState>
  error: any
  isLoading: boolean
}

// API call to get some metadata about the dashboard orders to help determine what we should
// show, e.g. which tabs to show, whether to show a specimen issue banner, etc.
export default function useDashboardOrderStatuses(): DashboardOrderStatuses {
  const { data, mutate, error, isLoading } = useSWR<OrderStatusesState>(
    "/dashboard-order-statuses/",
    {
      async fetcher(url) {
        const response = await axios.request<OrderStatusesState>({
          baseURL: getApiBaseUrl() + "/api/",
          url,
          method: "get",
        })

        return response.data
      },
      revalidateIfStale: true,
      revalidateOnMount: true,
      revalidateOnFocus: true,
      revalidateOnReconnect: true,
    }
  )

  return { data, mutate, error, isLoading }
}
