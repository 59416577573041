import { useState } from "react"

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  withStyles,
} from "@material-ui/core"
import { cn } from "@rupahealth/design"

import expandPanelIcon from "app/assets/images/expand-panel-icon.svg"

/**
 * The severity of a checkout warning.
 *
 * @remarks
 * This is used to determine the color of the warning's border and text.
 * * ERROR: red
 * * WARNING: yellow
 * * HIGHLIGHT: blue
 * * INFO: slate (grey)
 */
export enum CheckoutWarningSeverities {
  ERROR = "ERROR",
  WARNING = "WARNING",
  HIGHLIGHT = "HIGHLIGHT",
  INFO = "INFO",
}

const WarningAccordionSummary = withStyles({
  root: {
    minHeight: "0 !important",
    padding: "0 !important",
    margin: "9px 16px !important",
  },
  content: { margin: "0 !important" },
  expandIcon: { padding: "0 !important", margin: "0 !important" },
})(AccordionSummary)

interface CheckoutWarningProps {
  collapsible?: boolean
  severity: CheckoutWarningSeverities
  icon?: React.ReactNode
  title?: string
  children?: React.ReactNode
}

/**
 * Renders a warning message to the user, for use in the checkout process.
 * Generally, it should not be used directly, instead use a StaticCheckoutWarning or another custom checkout warning component to abstract the details.
 */
export const BaseCheckoutWarning = ({
  collapsible = true,
  severity,
  icon,
  title,
  children,
}: CheckoutWarningProps) => {
  const [expanded, setExpanded] = useState(!Boolean(collapsible))
  const handleChange = (event, isExpanded) => {
    if (collapsible) {
      setExpanded(isExpanded)
    }
  }

  return (
    <div
      className={cn("my-[18px] rounded-md shadow-sm", {
        "border-2 border-l-8": [
          CheckoutWarningSeverities.ERROR,
          CheckoutWarningSeverities.WARNING,
          CheckoutWarningSeverities.HIGHLIGHT,
        ].includes(severity),
        "border-rose-800": severity === CheckoutWarningSeverities.ERROR,
        "border-yellow-400": severity === CheckoutWarningSeverities.WARNING,
        "border-primary": severity === CheckoutWarningSeverities.HIGHLIGHT,
        "border border-slate-200": severity === CheckoutWarningSeverities.INFO,
      })}
    >
      <Accordion
        expanded={expanded}
        onChange={handleChange}
        square={true}
        className="rounded-md overflow-hidden"
      >
        {(title || collapsible) && (
          <WarningAccordionSummary
            expandIcon={
              !collapsible ? (
                <></>
              ) : (
                <img
                  src={expandPanelIcon}
                  alt="Expand Panel Icon"
                  width={19}
                  height={19}
                />
              )
            }
          >
            <div
              className={cn("text-body text-sm font-semibold", {
                "text-rose-800": severity === CheckoutWarningSeverities.ERROR,
                "text-yellow-900":
                  severity === CheckoutWarningSeverities.WARNING,
              })}
            >
              {title}
            </div>
          </WarningAccordionSummary>
        )}
        <AccordionDetails className="p-0 flex flex-row">
          {icon && (
            <div className="basis-10 shrink-0 flex items-center justify-center bg-red-50 rounded-l-md">
              {icon}
            </div>
          )}
          <div
            className={cn("text-body text-sm px-4 pb-2", {
              "text-rose-800": severity === CheckoutWarningSeverities.ERROR,
              "text-yellow-900": severity === CheckoutWarningSeverities.WARNING,
              "pt-2": !title,
            })}
          >
            {children}
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  )
}
