import { isNil } from "lodash"

import { ReactComponent as CollectionDayIcon } from "app/assets/icons/collection-day.svg"
import {
  ComparisonRow,
  ComparisonColumnProps,
  EmptyComparisonValue,
} from "app/main/comparison/components/ComparisonLabTests/components"
import useAnyComparedLabTestHasProperty from "app/main/comparison/hooks/use-any-compared-lab-test-has-property"
import { pluralizeDays } from "app/main/comparison/utils"

const CollectionDaysColumn = ({ labTest }: ComparisonColumnProps) => {
  if (!labTest) {
    return <EmptyComparisonValue />
  }
  const { collection_days_max, collection_days_min } = labTest
  const hasMax = !isNil(collection_days_max)
  const hasMin = !isNil(collection_days_min)
  if (!hasMax && !hasMin) {
    return <EmptyComparisonValue />
  }

  let collectionDaysText: string = ""
  if (hasMax && hasMin) {
    if (collection_days_max !== collection_days_min) {
      collectionDaysText = `${collection_days_min} - ${collection_days_max} days`
    } else {
      collectionDaysText = `${collection_days_min} ${pluralizeDays(
        collection_days_min
      )}`
    }
  } else if (hasMax) {
    collectionDaysText = `${collection_days_max} ${pluralizeDays(
      collection_days_max
    )}`
  } else if (hasMin) {
    collectionDaysText = `${collection_days_min} ${pluralizeDays(
      collection_days_min
    )}`
  }
  return <>{collectionDaysText}</>
}

export default function ComparisonCollectionDaysRow() {
  const hasCollectionDays = useAnyComparedLabTestHasProperty(
    "collection_days_max",
    "collection_days_min"
  )
  if (!hasCollectionDays) {
    return null
  }
  return (
    <ComparisonRow
      Column={CollectionDaysColumn}
      headerIcon={<CollectionDayIcon viewBox="0 0 17 13" />}
      headerLabel="Number of Collection Days"
    />
  )
}
