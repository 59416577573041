import * as React from "react"

import { Box, makeStyles } from "@material-ui/core"

import BodyText from "app/components/design-system/BodyText"
import { colors, Theme } from "app/theme"
import { StripeConnectAccount } from "app/types"

type StripeConnectAccountBadgeProps = {
  stripeConnectAccount?: StripeConnectAccount
}

const StripeConnectAccountBadge: React.FC<StripeConnectAccountBadgeProps> = ({
  stripeConnectAccount,
}) => {
  const styles = (theme: Theme) => ({
    statusBadge: {
      backgroundColor: colors.blueGray[100],
      border: "2px solid white",
      borderRadius: 25,
      color: colors.gray[800],
      fontSize: 14,
      marginTop: theme.spacing(2),
      paddingBottom: theme.spacing(1),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      paddingTop: theme.spacing(1),
      width: "fit-content",
      display: "flex",
      "flex-direction": "row",
      justifyContent: "space-between",
      alignItems: "center",
    },
    statusIndicator: {
      borderRadius: "100%",
      width: 10,
      height: 10,
      marginRight: theme.spacing(1),
    },
  })

  const useStyles = makeStyles(styles)
  const classes = useStyles(styles)

  const getStatus = (status: StripeConnectAccount["status"] = "Onboarding") => {
    switch (status) {
      case "Active":
        return {
          backgroundColor: "#10B981",
          message: "Your Stripe account is active.",
        }
      case "Onboarding":
        return {
          backgroundColor: "#F59E0B",
          message: "Your Stripe account is being verified.",
        }
      case "Disabled":
        return {
          backgroundColor: "#DC2626",
          message: "Your Stripe account is disabled.",
        }
    }
  }

  const { backgroundColor, message } = getStatus(stripeConnectAccount?.status)

  return (
    <Box className={classes.statusBadge}>
      <Box className={classes.statusIndicator} style={{ backgroundColor }} />
      <BodyText>{message}</BodyText>
    </Box>
  )
}

export default StripeConnectAccountBadge
