import { useState } from "react"

import useInterval from "./use-interval"
import useQuery from "./use-query"

const useIntercomAutoLaunch = () => {
  /**
   * If the "chat" query parameter is set, automatically open Intercom.
   * This is used to manage redirects from the deprecated "conversations" URL.
   */
  const query = useQuery()
  const showChat = query.get("chat") === "1"
  const [intercomLaunched, setIntercomLaunched] = useState(false)
  useInterval(
    () => {
      if (window.Intercom) {
        setIntercomLaunched(true)
        window.Intercom("show")
      }
    },
    showChat && !intercomLaunched ? 250 : null
  )
}

export default useIntercomAutoLaunch
