import { ReactComponent as ProcessingMethodsIcon } from "app/assets/icons/processing-methods.svg"
import {
  ComparisonRow,
  ComparisonColumnProps,
  EmptyComparisonValue,
} from "app/main/comparison/components/ComparisonLabTests/components"
import useAnyComparedLabTestHasProperty from "app/main/comparison/hooks/use-any-compared-lab-test-has-property"

const ProcessingMethodsColumn = ({ labTest }: ComparisonColumnProps) => {
  if (!labTest?.processing_methods?.length) {
    return <EmptyComparisonValue />
  }
  const processing_methods = labTest.processing_methods
  return <>{processing_methods.join(", ")}</>
}

export default function ComparisonProcessingMethodsRow() {
  const hasProcessingMethods =
    useAnyComparedLabTestHasProperty("processing_methods")
  if (!hasProcessingMethods) {
    return null
  }
  return (
    <ComparisonRow
      Column={ProcessingMethodsColumn}
      headerIcon={<ProcessingMethodsIcon viewBox="0 0 90 90" />}
      headerLabel="Methods Used for Processing"
    />
  )
}
