import { styled } from "@material-ui/core"

import ResultsGraph from "app/assets/icons/graph-dark.svg"
import BodyText from "app/components/design-system/BodyText"
import { LabTest } from "app/types"

const BodyContainer = styled("div")({
  marginTop: 14,
  display: "flex",
  flexDirection: "row",
})

const StyledIconImage = styled("img")({
  width: 18.81,
})

interface Props {
  estimatedDaysForResults: LabTest["estimated_days_for_results"]
}

const ProcessingTimeSection = ({ estimatedDaysForResults }: Props) => (
  <>
    <BodyText size="sm">Avg. Sample Processing Time</BodyText>
    <BodyContainer>
      <StyledIconImage src={ResultsGraph} alt="processing" />
      <BodyText className="pl-1.5" weight="semibold">
        {estimatedDaysForResults} Business Days
      </BodyText>
    </BodyContainer>
  </>
)

export default ProcessingTimeSection
