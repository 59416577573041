import { isEmpty, startCase } from "lodash"
import moment from "moment"

import { StorefrontLabTestType } from "app/hooks/use-resource"
import { EmptyComparisonValue } from "app/main/comparison/components/ComparisonLabTests/components"

interface Props {
  labTest: StorefrontLabTestType
}

const PreparationTimeColumn = ({ labTest }: Props) => {
  if (!labTest || isEmpty(labTest.attributes.preparation_starts)) {
    return <EmptyComparisonValue />
  } else {
    const duration = moment.duration(labTest.attributes.preparation_starts)
    if (duration.asMilliseconds() === 0) {
      return <>{"No Test Prep is Required"}</>
    }
    const formattedDuration = startCase(
      duration.humanize({ h: 24, d: 7, w: 4, m: 12 })
    )
    return <>{`Up to ${formattedDuration} Before Collection`}</>
  }
}

export default PreparationTimeColumn
