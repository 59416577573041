import { useForm } from "react-hook-form"
import { z } from "zod"

import { zodResolver } from "@hookform/resolvers/zod"

const SnippetFormSchema = z.object({
  title: z.string().nonempty("Please set a title."),
  text: z.string().nonempty("Please set snippet content."),
  rich_text: z
    .array(
      z.object({
        type: z.string(),
        children: z.array(
          z.object({
            text: z.string().optional(),
            type: z.string().optional(),
            children: z.any().optional(),
          })
        ),
      })
    )
    .refine(
      (value) => {
        // This indicates that the user has not entered any content in the richtext field
        return (
          JSON.stringify(value[0]) !==
            JSON.stringify({ type: "paragraph", children: [{ text: "" }] }) ||
          value.length > 1
        )
      },
      {
        message: "Please set snippet content.",
      }
    ),
})

interface SnippetData {
  title: string
  text: string
  rich_text: any[]
  useRichText: boolean
}

const useSnippetForm = ({
  title,
  text,
  rich_text,
  useRichText,
}: SnippetData) => {
  let defaultValues = {
    title,
    text,
    rich_text,
  }

  if (useRichText && !rich_text?.length) {
    defaultValues.rich_text = [{ type: "paragraph", children: [{ text: "" }] }]
  }

  return useForm<SnippetData>({
    defaultValues,
    mode: "onChange",
    resolver: zodResolver(SnippetFormSchema),
  })
}

export default useSnippetForm
