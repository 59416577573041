import { useState } from "react"
import { useDispatch } from "react-redux"

import axios from "axios"

import { getApiBaseUrl, handleApiError } from "app/utils"

const useCreateSnapshot = () => {
  const dispatch = useDispatch()

  const [isLoading, setIsLoading] = useState(false)

  const createSnapshotFromUserResult = async (userResultId: string) => {
    try {
      setIsLoading(true)
      await axios.post(
        getApiBaseUrl() +
          `/api/normalized/blood_lab_dashboard_report_snapshots/`,
        {
          data: {
            type: "blood_lab_dashboard_report_snapshot",
            relationships: {
              user_results: {
                data: [
                  {
                    type: "user_result",
                    id: userResultId,
                  },
                ],
              },
            },
          },
        },
        { headers: { "Content-Type": "application/vnd.api+json" } }
      )
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      dispatch(handleApiError(error))
    }
  }

  return { createSnapshotFromUserResult, isLoading }
}

export default useCreateSnapshot
