import IframeResizer from "iframe-resizer-react"

import makeAppStyles from "app/utils/makeAppStyles"

const useStyles = makeAppStyles((theme) => ({
  iframeContainer: {
    marginTop: theme.spacing(4),
  },
}))

function PromotionsIframe() {
  const classes = useStyles()
  return (
    <div className={classes.iframeContainer}>
      <IframeResizer
        src="https://embed.rupahealth.com/promos"
        scrolling={false}
        style={{
          width: 1,
          minWidth: "100%",
          // helps ensure the initial load in desktop is close to the final size
          minHeight: 272,
        }}
      />
    </div>
  )
}

export default PromotionsIframe
