import { useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import { find } from "lodash"
import { useHistory } from "react-router-dom"

import {
  Button,
  CircularProgress,
  Dialog,
  IconButton,
  Typography,
} from "@material-ui/core"
import { makeStyles, styled } from "@material-ui/core/styles"

import CrossCircleButtonIcon from "app/assets/buttons/cross-circle.svg"
import EnteringDetailsIcon from "app/assets/icons/entering-details-blue.svg"
import { checkHasHandfilledRequisitionForms } from "app/utils/order-utils"

import { API } from "../../api"
import useInterval from "../../hooks/use-interval"
import { navy } from "../../theme"
import { handleApiError } from "../../utils"

const useStyles = makeStyles((theme) => ({}))

export function PendingOrderModal({ patient, orderId, open, onClose }) {
  const history = useHistory()
  const classes = useStyles({})
  const dispatch = useDispatch()
  const patientFirstName = patient?.first_name
  const order = useSelector(({ patient }) =>
    find(patient.orders, { id: orderId })
  )
  const hasHandfilledRequisitionForms = useMemo(
    () => Boolean(order) && checkHasHandfilledRequisitionForms(order),
    [order]
  )
  const [waitingForReq, setWaitingForReq] = useState(false)

  const pollPatientCheckoutComplete = async () => {
    if (open) {
      try {
        const response = await API.Order.getCheckoutStatus(orderId)
        if (response.data.order_paid) {
          if (
            response.data.wait_for_requisition &&
            !response.data.requisition_ready
          ) {
            setWaitingForReq(true)
          } else {
            history.push(`/patient/${patient.id}/requisitions/${orderId}`)
          }
        }
      } catch (error) {
        dispatch(handleApiError(error))
      }
    }
  }

  useInterval(() => {
    pollPatientCheckoutComplete()
  }, 5000)

  useEffect(() => {
    pollPatientCheckoutComplete()
  }, [])

  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="awaiting-patient-payment-title"
      open={open}
      className={classes.root}
      fullWidth
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth="sm"
    >
      <div className="flex flex-col items-center pb-16">
        <div className="flex flex-row-reverse w-full">
          <IconButton aria-label="close" onClick={onClose}>
            <img src={CrossCircleButtonIcon} alt="Cross" />
          </IconButton>
        </div>
        <img
          src={EnteringDetailsIcon}
          alt="A pencil hovering over a form"
          className="mt-12"
        />
        <div className="flex items-center mt-8 mb-9">
          <Typography
            color="textPrimary"
            variant="h5"
            className="mr-4 font-semibold fs-exclude"
            id="awaiting-patient-payment-title"
          >
            {waitingForReq
              ? "Requesting requisition from lab"
              : `Waiting for ${patientFirstName}'s Payment`}
          </Typography>
          <CircularProgress
            style={{ color: navy }}
            aria-label={`Waiting for ${patientFirstName}'s Payment`}
          />
        </div>

        <Typography
          color="textPrimary"
          align="center"
          className="text-base15 max-w-xs fs-exclude"
        >
          {waitingForReq
            ? "Ordering Lab Tests and Generating Requisitions!"
            : `Ask ${patientFirstName} to check their email and fill out the consent form and pay for the order.`}
        </Typography>

        {hasHandfilledRequisitionForms && (
          <StyledPatientCheckoutButton orderId={orderId}>
            {`Add ${patientFirstName}’s Payment Details on Their Behalf`}
          </StyledPatientCheckoutButton>
        )}
      </div>
    </Dialog>
  )
}

const PatientCheckoutButton = ({ orderId, ...passthroughProps }) => {
  const dispatch = useDispatch()
  const [patientCheckoutUrl, setPatientCheckoutUrl] = useState()

  useEffect(() => {
    async function fetchPatientCheckoutUrl() {
      try {
        const response = await API.Order.getPatientCheckoutUrl(orderId)
        setPatientCheckoutUrl(response.data?.url)
      } catch (error) {
        dispatch(handleApiError(error))
      }
    }

    fetchPatientCheckoutUrl()
  }, [orderId])

  return (
    <Button
      color="primary"
      disabled={!Boolean(patientCheckoutUrl)}
      href={patientCheckoutUrl}
      role="button"
      target="_blank"
      variant="text"
      {...passthroughProps}
    />
  )
}

const StyledPatientCheckoutButton = styled(PatientCheckoutButton)(
  ({ theme }) => ({
    marginTop: theme.spacing(2),
  })
)
