import { CellContext } from "@tanstack/react-table"

import OrderStatus from "app/components/OrderStatus"
import DashboardStatusFilter from "app/main/dashboard/components/DashboardStatusFilter"
import { DashboardOrder } from "types/dashboard/dashboard-order"

import columnHelper from "./column-helper"

type OrderAttributes = DashboardOrder["attributes"]

const StatusCell = ({
  getValue,
  row: {
    original: { attributes: order },
  },
}: CellContext<DashboardOrder, OrderAttributes["cached_status"]>) => {
  const status = getValue()

  return (
    <OrderStatus
      status={status}
      total_ordered_tests={order.total_ordered_tests}
      total_completed_ordered_tests={order.total_completed_ordered_tests}
      specimen_issue_status={order.specimen_issue_status}
    />
  )
}

export const STATUS_COLUMN_ID = "status"

const statusColumn = columnHelper.accessor("attributes.cached_status", {
  id: STATUS_COLUMN_ID,
  cell: (info) => <StatusCell {...info} />,
  header: (info) => (
    <DashboardStatusFilter
      onFilterChange={info.column.setFilterValue}
      value={info.column.getFilterValue() as string}
    />
  ),
  minSize: 180,
  size: 180,
})

export default statusColumn
