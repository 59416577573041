import { useMemo } from "react"

import { useHistory, useLocation } from "react-router-dom"

export default function useSetQueryParam() {
  const history = useHistory()
  const location = useLocation()

  const searchParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  )

  const setQueryParam = (key: string, value: string | undefined) => {
    searchParams.delete(key)

    if (value) {
      searchParams.set(key, value)
    }

    history.replace({
      ...location,
      search: searchParams.toString(),
    })
  }
  return { searchParams, setQueryParam }
}
