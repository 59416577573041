import { ReactComponent as SuppliesIcon } from "app/assets/icons/supplies.svg"
import BodyText from "app/components/design-system/BodyText"
import { colors } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"

const useStyles = makeAppStyles((theme) => ({
  noOrdersContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "stretch",
    height: 200,
  },
  noOrdersText: {
    marginTop: 16,
    color: colors.blueGray[500],
    fontWeight: 600,
  },
}))

interface Props {
  noOrders: boolean
}

const EmptyOrderTable = ({ noOrders }: Props) => {
  const classes = useStyles()
  const text = noOrders
    ? "Looks like you haven't ordered supplies yet!"
    : "Looks like there aren't any orders that match the filters applied!"
  return (
    <div className={classes.noOrdersContainer}>
      <SuppliesIcon />
      <BodyText className={classes.noOrdersText}>{text}</BodyText>
    </div>
  )
}

export default EmptyOrderTable
