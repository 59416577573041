import { useEffect, useState } from "react"

import ScreenIcon from "app/assets/icons/screen.svg"
import { ReactComponent as VideoIcon } from "app/assets/icons/video.svg"
import Loading from "app/components/Loading"
import PageToolbar from "app/components/PageToolbar"
import BetaChip from "app/components/design-system/BetaChip"
import DesignSystemButton from "app/components/design-system/Button"
import {
  PractitionerProfileApplicationStatus,
  PractitionerProfilePublishingStatus,
} from "app/constants.typed"
import useVideoModal from "app/hooks/use-video-modal"
import { rupaTeal } from "app/theme"

import PractitionerProfilePublishButton from "./PractitionerProfilePublishButton"
import { PractitionerProfile } from "./types"

interface Props {
  profile: PractitionerProfile | undefined
  mutateProfile: (mutator, options) => Promise<any>
  isPatchingProfile: boolean
  application: any
  tutorialOnly: boolean
}

const ProfileToolbar = ({
  profile,
  mutateProfile,
  isPatchingProfile,
  application,
  tutorialOnly,
}: Props) => {
  const handleVisitYourProfileClick = () => {
    if (!profile?.attributes.slug) {
      return
    }

    if (
      window.location.href.includes("staging") ||
      window.location.href.includes("localhost") ||
      window.location.href.includes("pr-ecs")
    ) {
      window.open(
        `https://rupa-health-staging.webflow.io/practitioners/${profile?.attributes.slug}`,
        "_blank"
      )
    } else {
      window.open(
        `https://rupahealth.com/practitioners/${profile?.attributes.slug}`,
        "_blank"
      )
    }
  }

  const applicationIsApproved =
    application?.attributes.status ===
    PractitionerProfileApplicationStatus.APPROVED

  const profileHasBeenPublished =
    profile?.attributes.publishing_status ===
      PractitionerProfilePublishingStatus.PUBLISHED ||
    profile?.attributes.publishing_status ===
      PractitionerProfilePublishingStatus.DIRTY

  const tutorialModal = useVideoModal()

  const titleComponent = (
    <>
      Find a Practitioner&nbsp;&nbsp;
      <BetaChip backgroundColor="rgba(89, 195, 255, 0.15)" color={rupaTeal} />
    </>
  )

  const tutorialComponent = (
    <DesignSystemButton
      color="text"
      onClick={() =>
        tutorialModal.show({
          src: "https://player.vimeo.com/video/834172452?h=87d2912c20&color=4AA8BC&title=0&byline=0&portrait=0",
          title: "Find a Practitioner Tutorial",
        })
      }
      startIcon={<VideoIcon viewBox="0 0 19 15" />}
      type="button"
    >
      Tutorial
    </DesignSystemButton>
  )

  const [showSpinner, updateShowSpinner] = useState(false)
  useEffect(() => {
    if (isPatchingProfile) {
      updateShowSpinner(true)
      setTimeout(() => {
        updateShowSpinner(false)
      }, 1500)
    }
  }, [isPatchingProfile])

  return (
    <PageToolbar
      title={titleComponent}
      fullBorder={true}
      topContainerStyle={{ paddingTop: "17px", paddingBottom: "16px" }}
    >
      {showSpinner && <Loading ariaLabel="autosaving profile changes" />}
      {tutorialComponent}
      {!tutorialOnly && (
        <>
          {applicationIsApproved && profileHasBeenPublished && (
            <DesignSystemButton
              color="noaction"
              startIcon={ScreenIcon}
              onClick={handleVisitYourProfileClick}
            >
              Visit Your Profile
            </DesignSystemButton>
          )}
          <PractitionerProfilePublishButton
            profile={profile}
            mutateProfile={mutateProfile}
            profileApplication={application}
          />
        </>
      )}
    </PageToolbar>
  )
}

export default ProfileToolbar
