import { ReactComponent as LinearChartIcon } from "app/assets/icons/results-summary/linear-chart.svg"
import useEventCallback from "app/hooks/use-event-callback"
import useResultsSummaryModal from "app/results-summary/hooks/use-results-summary-modal"
import { DashboardKit } from "types/dashboard/dashboard-kit"
import { DashboardOrder } from "types/dashboard/dashboard-order"

import { ActionDropdownMenuItem } from "./ActionDropdownMenuButton"

export interface ResultsSummaryDropdownMenuActionProps {
  kit: DashboardKit
  order: DashboardOrder
}

export default function ResultsSummaryDropdownMenuAction({
  order,
}: ResultsSummaryDropdownMenuActionProps) {
  const modal = useResultsSummaryModal()

  const onSelect = useEventCallback(() => {
    modal.show({
      orderId: order.id,
    })
  })

  return (
    <ActionDropdownMenuItem
      icon={
        <LinearChartIcon
          fill="currentColor"
          height={15}
          width={15}
          viewBox="0 0 17 15"
        />
      }
      label="Create Results Summary"
      onSelect={onSelect}
    />
  )
}
