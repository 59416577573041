import { useDispatch } from "react-redux"

import Loading from "app/components/Loading"
import { COMPANY_KEY_INSURANCE_TOGGLE_PAIRS } from "app/constants"
import * as Actions from "app/main/checkout/store/actions/orders.actions"
import { StyledSwitch } from "app/main/settings/components/StyledSwitch"
import { Order } from "app/types"
import makeAppStyles from "app/utils/makeAppStyles"
import { LabCompany } from "types/lab-company"

export enum InsuranceToggleDirection {
  INSURED = "INSURED",
  UNINSURED = "UNINSURED",
}

interface Props {
  order: Order
  companyKey: string
  insuranceToggledOn: boolean
  setIsToggling: (boolean) => void
  setInsuranceToggledOn: (boolean) => void
  isLoadingLabCompanyAccounts?: boolean
  companiesWithConfirmedAccounts?: LabCompany[]
}

const useStyles = makeAppStyles({
  insuranceToggle: {
    display: "flex",
    alignItems: "center",
    marginTop: 16,
  },
})

function InsuranceToggle({
  order,
  companyKey,
  insuranceToggledOn,
  setIsToggling,
  setInsuranceToggledOn,
  isLoadingLabCompanyAccounts,
  companiesWithConfirmedAccounts,
}: Props) {
  const dispatch: any = useDispatch()
  const classes = useStyles()

  const toggleInsurance = async (direction) => {
    setIsToggling(true)
    await dispatch(Actions.toggleInsurance(order.id, direction, companyKey))
    setInsuranceToggledOn(direction === InsuranceToggleDirection.INSURED)
    setIsToggling(false)
  }

  const handleToggle = async () => {
    if (!insuranceToggledOn) {
      toggleInsurance(InsuranceToggleDirection.INSURED)
    } else {
      toggleInsurance(InsuranceToggleDirection.UNINSURED)
    }
  }

  const relevantInsuranceAccountCompanyKey =
    COMPANY_KEY_INSURANCE_TOGGLE_PAIRS.find(
      (pair) =>
        pair.uninsuredCompanyKey === companyKey ||
        pair.insuredCompanyKey === companyKey
    )?.insuredCompanyKey
  const hasConfirmedInsuranceAccount = companiesWithConfirmedAccounts?.find(
    (company) => {
      return company.attributes.key === relevantInsuranceAccountCompanyKey
    }
  )

  if (isLoadingLabCompanyAccounts) {
    return <Loading ariaLabel="loading-relevant-account-info" />
  }

  if (!hasConfirmedInsuranceAccount) {
    return null
  }

  return (
    <div className={classes.insuranceToggle}>
      <StyledSwitch checked={insuranceToggledOn} onChange={handleToggle} />
      Give option to use patient insurance
    </div>
  )
}
export default InsuranceToggle
