import { faUsers } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import ResultSummarySnippetsTable from "./ResultSummarySnippetsTable"
import SavedTextSnippetsSectionTitle from "./SavedTextSnippetsSectionTitle"
import SettingPill from "./SettingPill"

const ResultSummarySnippetsContainer = () => {
  return (
    <div className="flex flex-col gap-6">
      <SavedTextSnippetsSectionTitle
        title="Result Summary"
        text={
          <>
            Snippets can be re-used in the introduction and next steps open text
            areas in <span className="font-semibold">Results Summaries</span>.
          </>
        }
        newSnippetButtonText="New Result Summary Snippet"
        settingsPill={
          <SettingPill
            title="Clinic-Wide Setting"
            icon={
              <FontAwesomeIcon
                className="text-sky-600 text-xs"
                icon={faUsers}
              />
            }
          />
        }
        snippetType="results_summary"
      />

      <ResultSummarySnippetsTable />
    </div>
  )
}

export default ResultSummarySnippetsContainer
