import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"

import * as DropdownMenu from "@radix-ui/react-dropdown-menu"

import { API } from "app/api"
import { ReactComponent as AddBlueIcon } from "app/assets/icons/add-blue.svg"
import { ReactComponent as AddGreyIcon } from "app/assets/icons/add-grey.svg"
import Tooltip from "app/components/Tooltip"
import BodyText from "app/components/design-system/BodyText"
import DesignSystemButton from "app/components/design-system/Button"
import useFeatureFlag, { FeatureFlag } from "app/hooks/use-feature-flag"
import useAppSelector from "app/hooks/useAppSelector"
import { colors, primaryColor, shadows } from "app/theme"
import { LabCompany } from "app/types"
import { handleApiError } from "app/utils"
import interleaveChildren from "app/utils/interleave-children"
import makeAppStyles from "app/utils/makeAppStyles"

import {
  LAB_COMPANY_KEY_OPTIONS,
  LAB_COMPANY_KEY_OPTIONS_WITHOUT_LABCORP,
} from "../constants"

const useStyles = makeAppStyles((theme) => ({
  content: {
    width: 250,
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(0, 0.5),
    background: "white",
    borderRadius: 6,
    border: `1px solid ${primaryColor}`,
    boxShadow: shadows.lg,
    marginTop: 5,
    overflowY: "auto",
    zIndex: 1300,
  },
  item: {
    display: "flex",
    alignItems: "center",
    padding: "14px 19px",
    cursor: "pointer",
    fontSize: 15,
    color: primaryColor,
    gap: theme.spacing(1.0),

    "&:focus, &:hover": {
      "& .checkbox": {
        borderColor: primaryColor,
      },
    },
  },
  itemLabel: {
    whiteSpace: "nowrap",
  },
  separator: {
    margin: theme.spacing(0, "auto"),
    background: colors.blueGray[200],
    height: 1,
    width: "85%",
  },
}))

const MenuItem = ({ labCompanyLabel, onSelect }) => {
  const classes = useStyles()
  return (
    <DropdownMenu.Item className={classes.item} onSelect={onSelect}>
      <BodyText color="primary" weight="semibold">
        {labCompanyLabel}
      </BodyText>
    </DropdownMenu.Item>
  )
}

const OptimalRangesLabCompanyDropdown = ({ keysAlreadyPresent, onSelect }) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const [labcorpEnabledForBloodLabsDashboard] = useFeatureFlag(
    FeatureFlag.LabcorpEnabledForBloodLabsDashboard
  )

  const [labCompanyOptions, setLabCompanyOptions] = useState<LabCompany[]>([])

  const labCompanyKeyOptions = labcorpEnabledForBloodLabsDashboard
    ? LAB_COMPANY_KEY_OPTIONS
    : LAB_COMPANY_KEY_OPTIONS_WITHOUT_LABCORP

  useEffect(() => {
    async function load() {
      try {
        const response = await API.LabCompany.getList({
          include_importable: true,
        })
        const allLabCompanies = response.data.results ?? []
        setLabCompanyOptions(
          allLabCompanies?.filter((labCompany: LabCompany) =>
            labCompanyKeyOptions.includes(labCompany.key)
          ) || []
        )
      } catch (error) {
        dispatch(handleApiError(error))
      }
    }
    load()
  }, [])

  const practitioner = useAppSelector(({ practitioner }) => practitioner)
  const clinic = practitioner?.clinic

  return keysAlreadyPresent.length !== labCompanyKeyOptions.length ? (
    <DropdownMenu.Root modal={false}>
      <Tooltip
        title={
          !clinic?.clinic_has_practitioner_with_uploaded_license
            ? "Clinics must have a licensed medical practitioner on their team to customize descriptions."
            : ""
        }
        placement="top"
        arrow
      >
        <DropdownMenu.Trigger
          disabled={!clinic?.clinic_has_practitioner_with_uploaded_license}
        >
          <span>
            <DesignSystemButton
              color="text"
              startIcon={
                clinic?.clinic_has_practitioner_with_uploaded_license ? (
                  <AddBlueIcon />
                ) : (
                  <AddGreyIcon />
                )
              }
              loading={false}
              disabled={!clinic?.clinic_has_practitioner_with_uploaded_license}
            >
              Customize Ranges for a Lab Company
            </DesignSystemButton>
          </span>
        </DropdownMenu.Trigger>
      </Tooltip>

      <DropdownMenu.Portal>
        <DropdownMenu.Content
          align="end"
          className={classes.content}
          onCloseAutoFocus={(e) => {
            e.preventDefault()
          }}
          sideOffset={5}
        >
          {interleaveChildren(
            labCompanyOptions
              .filter((option) => !keysAlreadyPresent.includes(option.key))
              .map((option) => (
                <MenuItem
                  key={option.key}
                  labCompanyLabel={option.results_name}
                  onSelect={() => onSelect(option)}
                />
              )),
            () => (
              <DropdownMenu.Separator className={classes.separator} />
            )
          )}
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  ) : (
    <></>
  )
}

export default OptimalRangesLabCompanyDropdown
