import { cn } from "@rupahealth/design"

interface Props {
  on: boolean
}

const OnOffPill: React.FC<Props> = ({ on }) => {
  return (
    <span
      className={cn("rounded-3xl px-[6px]", {
        "bg-emerald-200 text-emerald-600": on,
        "bg-slate-200 text-slate-500": !on,
      })}
    >
      {on ? "ON" : "OFF"}
    </span>
  )
}

export default OnOffPill
