import { useCallback } from "react"
import { useDispatch } from "react-redux"

import { has } from "lodash"

import { PatientCheckoutFormData, PaymentMethodType } from "app/types"

import { CheckoutValidationError } from "../errors"
import * as Actions from "../store/actions"

/**
 * Post-submit failure handler used for updating state after a failed checkout submission.
 *
 * @param checkoutToken the checkout token
 * @returns a function that executes post-submit failure behavior
 */
export default function usePostSubmitFailure(checkoutToken: string) {
  const dispatch = useDispatch()
  return useCallback(
    (error: any, formData: PatientCheckoutFormData) => {
      if (error instanceof CheckoutValidationError) {
        const isSplitItPay =
          formData.payment_method?.type === PaymentMethodType.SPLITIT
        const isSplitItError = has(
          error.validationErrors,
          "payment_method.type"
        )

        // in the event there is an error with splitit pay, reinitiate a fresh installment plan to start with a fresh slate
        if (isSplitItPay && isSplitItError) {
          dispatch(Actions.initiateSplitIt(checkoutToken))
        }
      }
    },
    [checkoutToken]
  )
}
