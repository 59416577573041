import IframeResizer from "iframe-resizer-react"

import { makeStyles } from "@material-ui/core/styles"

import { colors, shadows } from "app/theme"

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    border: `1px solid ${colors.coolGray[200]}`,
    minHeight: 1100,
    borderRadius: 8,
    overflow: "hidden",
    background: "white",
    margin: 16,
    boxShadow: shadows.default,
    [theme.breakpoints.up("sm")]: {
      margin: 24,
    },
  },
}))

type EcommerceAnalyticsEmbedProps = {
  analyticsURL: string
}
export default function EcommerceAnalyticsEmbed({
  analyticsURL,
}: EcommerceAnalyticsEmbedProps) {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <IframeResizer src={analyticsURL} style={{ width: "100%" }} />
    </div>
  )
}
