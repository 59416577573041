import { ReactComponent as CheckMarkCogIcon } from "app/assets/icons/check-mark-cog.svg"
import {
  ComparisonRow,
  ComparisonColumnProps,
  EmptyComparisonValue,
} from "app/main/comparison/components/ComparisonLabTests/components"
import useAnyComparedLabTestHasProperty from "app/main/comparison/hooks/use-any-compared-lab-test-has-property"

const CertificationsColumn = ({ labTest }: ComparisonColumnProps) => {
  if (!labTest?.lab_company?.certifications?.length) {
    return <EmptyComparisonValue />
  }
  const certifications = labTest.lab_company.certifications
  return <>{certifications.join(", ")}</>
}

export default function ComparisonCertificationsRow() {
  const hasCertifications = useAnyComparedLabTestHasProperty(
    "lab_company.certifications"
  )
  if (!hasCertifications) {
    return null
  }
  return (
    <ComparisonRow
      Column={CertificationsColumn}
      headerIcon={<CheckMarkCogIcon viewBox="0 0 17 18" />}
      headerLabel="Certifications"
    />
  )
}
