import BodyText from "app/components/design-system/BodyText"
import { OrderedTest } from "app/types"

export default function PatientOrdersOrderedTestName({
  orderedTest,
  displayName,
  size,
}: {
  orderedTest: OrderedTest
  displayName: string
  size: "base" | "md"
}) {
  const { lab_test } = orderedTest
  return (
    <div>
      <BodyText size={size} weight="semibold">
        {displayName}
      </BodyText>
      <BodyText>{lab_test.lab_company.name}</BodyText>
    </div>
  )
}
