import { Button } from "@rupahealth/design"

import { ReactComponent as EnvelopeIcon } from "app/assets/icons/envelope/email-icon.svg"
import { primaryColor } from "app/theme"
import { Patient } from "app/types"

import useRequestPastResultsModal from "../modals/RequestPastResultsModal"

interface Props {
  patient: Patient
  buttonText?: string
  onClick?: () => void
}

const RequestPastResultsButton = ({ patient, buttonText, onClick }: Props) => {
  const requestPastResultsModal = useRequestPastResultsModal()

  return (
    <Button
      variant={null}
      className="w-full h-auto text-[15px] text-primary fill-primary hover:bg-slate-100 py-1 px-2"
      onClick={() => {
        requestPastResultsModal.show({
          patient,
          onClose: () => {
            requestPastResultsModal.remove()
          },
        })
        if (onClick) {
          onClick()
        }
      }}
    >
      <EnvelopeIcon className="mr-2" fill={primaryColor} />
      {buttonText ?? "Request Past Results"}
    </Button>
  )
}

export default RequestPastResultsButton
