import { useEffect, useMemo } from "react"

import { Order } from "app/types"

/**
 * Returns a set of hooks to refresh ordering rights
 * @param order
 * @param storefrontId
 * @returns useRefreshOrderingRightsBlocking: a hook that is invoked when the order is updated
 *  in a way that the ordering rights should be refreshed and user actions should be blocked
 * useRefreshOrderingRightsBackground: a hook that is invoked when the order is updated
 *   in a way that the ordering rights should be refreshed but user actions should not be blocked
 */
export default function useRefreshOrderingRights({
  order,
  storefrontId,
}: {
  order?: Order
  storefrontId?: string
}) {
  const selectedLabTestIdsJoined = useMemo(() => {
    if (!order || !order.lab_tests_by_id) {
      return ""
    }
    return Object.keys(order.lab_tests_by_id).join("-")
    // total_price is used as the dependency because lab_tests_by_id, ordered_tests, and line_items are all optimistically updated.
    // if we used any of those as the dependency, then the hook would be fired before the order was actually updated in the backend,
    // so the ordering rights code would use old values.
    // total price is not optimistically updated, so it is a good proxy for when the order is actually updated in the backend.
  }, [order?.total_price])

  const useRefreshOrderingRightsBlocking = (effect: () => void) => {
    useEffect(() => {
      if (!order && !storefrontId) {
        return
      }
      effect()
    }, [
      order?.shipping_state,
      order?.signing_practitioner,
      order?.requires_vendor_physician_authorization,
      order?.clinic?.state,
      order?.clinic?.country,
      storefrontId,
    ])
  }

  const useRefreshOrderingRightsBackground = (effect: () => void) => {
    useEffect(() => {
      if (!order) {
        return
      }
      effect()
    }, [selectedLabTestIdsJoined, order?.is_practitioner_paying])
  }

  return {
    useRefreshOrderingRightsBlocking,
    useRefreshOrderingRightsBackground,
  }
}
