import { ReactComponent as TestTubesIcon } from "app/assets/icons/patient-portal/test-tubes.svg"
import BodyText from "app/components/design-system/BodyText"
import { KIT_GROUP_TYPE } from "app/constants.typed"
import useCachedResource from "app/swr/hooks/use-cached-resource"
import { colors } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"

import { PatientPortalKit, PatientPortalLabCompany } from "../types"

const useStyles = makeAppStyles((theme) => ({
  container: {
    display: "flex",
    gap: 6,
    width: "100%",
  },
  icon: {
    position: "relative",
    top: 4,
  },
  kitContent: {
    display: "flex",
    flexDirection: "column",
  },
  subtext: {
    color: colors.blueGray[500],
  },
}))

interface Props {
  kit: PatientPortalKit
}

const PatientPortalPatientOrderCardKit = ({ kit }: Props) => {
  const classes = useStyles()

  const labCompany = useCachedResource<PatientPortalLabCompany>(
    kit.relationships.lab_company.data
  )

  return (
    <div className={classes.container}>
      <div>
        <TestTubesIcon
          className={classes.icon}
          width={15}
          height={16}
          viewBox="0 0 15 16"
        />
      </div>
      <div className={classes.kitContent}>
        <BodyText size="base" weight="semibold">
          {kit.attributes.group_type === KIT_GROUP_TYPE.PANEL
            ? "Custom Blood Panel"
            : kit.attributes.name}
        </BodyText>
        <BodyText size="base" weight="regular" className={classes.subtext}>
          {labCompany?.attributes.name}
        </BodyText>
      </div>
    </div>
  )
}

export default PatientPortalPatientOrderCardKit
