import { useEffect, useState } from "react"
import * as React from "react"

import clsx from "clsx"
import { Link, useLocation } from "react-router-dom"

import { Button, makeStyles, Theme } from "@material-ui/core"
import Menu from "@material-ui/core/Menu"
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown"

import { AllowAnyPaths, GuestPaths } from "app/Routes"
import rupaHealthLogo from "app/assets/images/logos/rupa-health-logo.svg"
import { marketingSite } from "app/settings"
import { navy, titleFontFamily, userNavBarMobileHeight } from "app/theme"

import HamburgerMenuIcon from "./HamburgerMenuIcon"

const useStyles = makeStyles<Theme, { mobileMenuToggled?: boolean }>(
  (theme) => ({
    navbar: {
      height: userNavBarMobileHeight,
      zIndex: 30,
    },
    content: {
      background: theme.palette.common.white,
      boxShadow: "0px 4px 7px rgba(0, 0, 0, 0.2)",
      padding: "0 24px",
      transition: "height 0.24s",
      height: (props) =>
        props.mobileMenuToggled ? "100vh" : userNavBarMobileHeight,
      overflow: "hidden",

      [theme.breakpoints.up("md")]: {
        display: "flex",
        flexFlow: "row",
        alignItems: "center",
        justifyContent: "space-between",
      },
    },
    header: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      height: userNavBarMobileHeight,
    },
    items: {
      display: "flex",
      flexFlow: "column",

      [theme.breakpoints.up("md")]: {
        flexFlow: "row",
        alignItems: "center",
      },
    },
    signUpButton: {
      color: "white",
      fontFamily: titleFontFamily,
      fontSize: 16,
      backgroundColor: "rgb(232, 91, 89);",
      minWidth: 90,
      height: 36,
      borderRadius: 4,
      padding: "11px 17px 9px",
      marginBottom: 16,

      [theme.breakpoints.up("md")]: {
        marginBottom: 0,
      },

      "&:hover": {
        boxShadow: "0 6px 8px -4px rgba(0, 0, 0, 0.43)",
        transform: "translate(0px, -2px)",
        backgroundColor: "rgb(232, 91, 89)",
        transition: "all 200ms ease",
      },
    },
    navButton: {
      fontFamily: titleFontFamily,
      fontSize: 16,
      fontWeight: 400,
      marginRight: 15,
      opacity: 0.7,
      marginBottom: 16,

      [theme.breakpoints.up("md")]: {
        marginBottom: 0,
      },

      "&:hover": {
        opacity: 1,
      },
    },
    dropdownLink: {
      textDecoration: "none",
      padding: "12px 16px",
      fontFamily: titleFontFamily,
      fontSize: 16,
      fontWeight: 400,
      marginRight: 15,
      opacity: 0.7,
      color: navy,
      alignSelf: "center",

      "&:hover": {
        opacity: 1,
      },
    },
    divider: {
      margin: "0 16px",
      backgroundColor: "#ebedef",
      height: 1,
    },
    logo: {
      width: 210,
    },
    mobileMenuButton: {
      width: 24,

      [theme.breakpoints.up("md")]: {
        display: "none",
      },
    },
  })
)

export default function GuestNavBar() {
  const [mobileMenuToggled, setMobileMenuToggled] = useState(false)
  const classes = useStyles({ mobileMenuToggled })
  const location = useLocation()

  // Close the mobile menu when changing location
  useEffect(() => {
    if (mobileMenuToggled) {
      setMobileMenuToggled(false)
    }
  }, [location.pathname])

  return (
    <div className={classes.navbar}>
      <div className={classes.content}>
        <div className={classes.header}>
          <a href={marketingSite}>
            <img
              className={classes.logo}
              src={rupaHealthLogo}
              alt="Rupa Health Labs"
            />
          </a>
          <button
            aria-label="Open menu"
            onClick={() => setMobileMenuToggled(!mobileMenuToggled)}
            className={clsx(
              classes.mobileMenuButton,
              "navbar__mobile-menu-button"
            )}
          >
            <HamburgerMenuIcon open={mobileMenuToggled} color={navy} />
          </button>
        </div>
        <div className={classes.items}>
          <Button
            className={classes.navButton}
            component={Link}
            to={AllowAnyPaths.DISCOVER_LABS}
          >
            Discover Labs
          </Button>

          <ResourcesMenu />

          <Button className={classes.navButton} href={`${marketingSite}/blog`}>
            Blog
          </Button>

          <Button
            className={classes.navButton}
            component={Link}
            to={GuestPaths.LOGIN}
          >
            Log in
          </Button>
          <Button
            variant="contained"
            className={classes.signUpButton}
            href={`${marketingSite}/sign-up`}
          >
            Sign up free
          </Button>
        </div>
      </div>
    </div>
  )
}

const ResourcesMenu = () => {
  const classes = useStyles({})
  const [anchorEl, setAnchorEl] = React.useState(null)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <Button className={classes.navButton} onClick={handleClick}>
        Resources
        <KeyboardArrowDownIcon />
      </Button>
      <Menu
        elevation={2}
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        MenuListProps={{
          style: {
            background: "white",
            borderTop: "none",
          },
        }}
        style={{ borderTopLeftRadius: 0, borderTopRightRadius: 0 }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <div className="flex flex-col">
          <a className={classes.dropdownLink} href={`${marketingSite}/faq`}>
            FAQ
          </a>
          <div className={classes.divider} />

          <a
            className={classes.dropdownLink}
            href={`${marketingSite}/patient-experience`}
          >
            Patient Experience
          </a>
          <div className={classes.divider} />

          <a
            className={classes.dropdownLink}
            href={`${marketingSite}/for-patients`}
          >
            Rupa for Patients
          </a>
          <div className={classes.divider} />

          <a
            className={classes.dropdownLink}
            href={`${marketingSite}/walkthroughs`}
          >
            All Walkthroughs
          </a>
        </div>
      </Menu>
    </>
  )
}
