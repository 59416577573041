import { useEffect, useMemo, useState } from "react"

import { identity } from "lodash"

import { ColumnDef, ExpandedState } from "@tanstack/react-table"

import useEventCallback from "app/hooks/use-event-callback"
import MissingSignatureOrLicenseBanner from "app/main/dashboard/MissingSignatureOrLicenseBanner"
import { Practitioner } from "app/types"
import { DashboardOrder } from "types/dashboard/dashboard-order"

import TableRendererContext from "../contexts/TableRendererContext"
import useDashboardDefaultTab from "../hooks/use-dashboard-default-tab"
import useDashboardOrderStatuses, {
  DashboardOrderStatuses,
} from "../hooks/use-dashboard-order-statuses"
import useDashboardOrders from "../hooks/use-dashboard-orders"
import useDashboardQueryState from "../hooks/use-dashboard-query-state"
import useDashboardUnreadResultsCount from "../hooks/use-dashboard-unread-results-count"
import DashboardOrdersTableTabs from "./DashboardOrdersTableTabs"
import TableRenderer, {
  PRACTITIONER_COLUMN_ID,
  actionsColumn,
  patientColumn,
  practitionerColumn,
  statusColumn,
  updatedAtColumn,
  useTable,
  utilityColumn,
  utilityNotificationColumn,
} from "./TableRenderer"
import TableRowDetailRenderer from "./TableRowDetailRenderer"

/**
 * If the default tab is found and the global filter tab is not set, set the global filter tab to the default tab.
 */
function useSetupDefaultTab(
  queryState: ReturnType<typeof useDashboardQueryState>,
  orderStatuses: DashboardOrderStatuses
) {
  const defaultTab = useDashboardDefaultTab(orderStatuses)

  useEffect(() => {
    if (defaultTab && !queryState.globalFilter.tab) {
      queryState.onGlobalFilterChange((globalFilter) => ({
        ...globalFilter,
        tab: defaultTab,
      }))
    }
  }, [defaultTab, !queryState.globalFilter.tab])
}

/**
 * If the practitioner is not a clinic staff member, default the practitioner on mount or when the practitioner changes.
 */
function useSetupDefaultPractitionerFilter(
  queryState: ReturnType<typeof useDashboardQueryState>,
  practitioner: Practitioner
) {
  const shouldDefaultPractitioner =
    practitioner &&
    // Only default if the practitioner is not a clinic staff member
    !practitioner.is_clinic_staff &&
    // Only default if the clinic has multiple ordering practitioners
    practitioner.clinic.multiple_ordering_practitioners

  useEffect(() => {
    if (shouldDefaultPractitioner) {
      queryState.onColumnFiltersChange((columnFilters) => {
        if (
          !columnFilters.find((filter) => filter.id === PRACTITIONER_COLUMN_ID)
        ) {
          return [
            ...columnFilters,
            { id: PRACTITIONER_COLUMN_ID, value: practitioner.id },
          ]
        }

        return columnFilters
      })
    }
  }, [shouldDefaultPractitioner])
}

interface Props {
  practitioner: Practitioner
}

const DashboardOrdersTable: React.FC<Props> = ({ practitioner }) => {
  const [expanded, onExpandedChange] = useState<ExpandedState>({})
  const queryState = useDashboardQueryState()
  const {
    columnFilters,
    globalFilter,
    pagination,
    onColumnFiltersChange,
    onGlobalFilterChange,
    onPaginationChange,
  } = queryState

  const orderStatuses = useDashboardOrderStatuses()
  useSetupDefaultTab(queryState, orderStatuses)
  useSetupDefaultPractitionerFilter(queryState, practitioner)

  const { data, isLoading, isValidating, meta, mutate } = useDashboardOrders({
    columnFilters,
    globalFilter,
    pagination,
  })

  const showPractitionerColumn = useMemo(() => {
    return (
      // Only if the practitioner is not a clinic staff member
      practitioner.is_clinic_staff ||
      // Or if the clinic has multiple ordering practitioners
      practitioner.clinic?.multiple_ordering_practitioners ||
      // Or if the practitioner column has been filtered
      columnFilters.find((filter) => filter.id === PRACTITIONER_COLUMN_ID)
    )
  }, [practitioner, columnFilters])

  const hasUnreadNotifications = data.some(
    (order) => order.attributes.has_unread_notification
  )
  const columns = useMemo(() => {
    return [
      hasUnreadNotifications ? utilityNotificationColumn : utilityColumn,
      patientColumn,
      showPractitionerColumn ? practitionerColumn : null,
      statusColumn,
      updatedAtColumn,
      actionsColumn,
    ].filter(identity) as ColumnDef<DashboardOrder, any>[]
  }, [hasUnreadNotifications, showPractitionerColumn])

  const table = useTable({
    columns,
    columnFilters,
    count: meta?.pagination?.count,
    globalFilter,
    data,
    expanded,
    isLoading,
    isValidating,
    onColumnFiltersChange,
    onExpandedChange,
    onGlobalFilterChange,
    onPaginationChange,
    pagination,
  })

  const unreadResultsCountSwr = useDashboardUnreadResultsCount(columnFilters)

  const revalidateOrders = useEventCallback(() => {
    mutate()
  })

  const revalidateUnreadResultsCount = useEventCallback(() => {
    unreadResultsCountSwr.mutate()
  })

  const contextValue = useMemo(
    () => ({
      revalidateOrders,
      orderStatuses,
      unreadResults: {
        count: unreadResultsCountSwr.data || 0,
        revalidate: revalidateUnreadResultsCount,
      },
    }),
    [revalidateOrders, unreadResultsCountSwr.data, revalidateUnreadResultsCount]
  )

  return (
    <>
      <MissingSignatureOrLicenseBanner onModalClose={mutate} />
      <TableRendererContext.Provider value={contextValue}>
        <TableRenderer
          RowDetail={<TableRowDetailRenderer />}
          renderTabs={(props) => (
            <DashboardOrdersTableTabs
              {...props}
              orderStatusesState={orderStatuses.data}
            />
          )}
          table={table}
        />
      </TableRendererContext.Provider>
    </>
  )
}

export default DashboardOrdersTable
