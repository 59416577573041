import { useState } from "react"

import { useParams } from "react-router-dom"

import useFeatureFlag, { FeatureFlag } from "app/hooks/use-feature-flag"
import Trends from "app/main/patient-orders/trends/Trends"
import TrendsAboutOptimalRangesButton from "app/main/patient-orders/trends/components/TrendsAboutOptimalRangesButton"

import { PatientPortalTabCard } from "../components/PatientPortalTabCard"
import { TrendsEmpty } from "./TrendsEmpty"

const PatientPortalUserTrends = () => {
  const [patientPortalTrendsEnabled] = useFeatureFlag(
    FeatureFlag.PatientPortalTrends
  )
  const [isPatientPortalResultsUploadEnabled] = useFeatureFlag(
    FeatureFlag.PatientPortalResultsUploads
  )

  const { userId } = useParams<{ userId: string }>()

  const [isLoading, setIsLoading] = useState(false)
  const [optimalRanges, setOptimalRanges] = useState<string[]>([])

  if (!patientPortalTrendsEnabled) {
    return <></>
  }

  return (
    <PatientPortalTabCard
      title="Trends"
      loading={isLoading}
      showResultsUploadIfEnabled
      actionButtons={[
        <TrendsAboutOptimalRangesButton
          optimalRanges={optimalRanges}
          practitionerView={false}
        />,
      ]}
    >
      <Trends
        userId={userId}
        onLoading={setIsLoading}
        onOptimalRanges={setOptimalRanges}
        showHighLowDescriptions={true}
        className="bg-white p-3 pl-5 rounded-lg"
        emptyChildren={
          <TrendsEmpty
            userId={userId}
            resultsUploadEnabled={isPatientPortalResultsUploadEnabled}
          />
        }
      />
    </PatientPortalTabCard>
  )
}

export default PatientPortalUserTrends
