import { makeStyles, Theme } from "@material-ui/core"

import DesignSystemButton from "app/components/design-system/Button"
import ClipboardContainer from "app/components/modals/PhysicianServicesOptInModal/ClipboardContainer"
import { VENDOR_PHYSICIAN_AUTHORIZATION_LABEL } from "app/constants"
import { navy } from "app/theme"

const styles = (theme: Theme) => ({
  continueButton: {
    marginTop: "32px",
    marginBottom: "50px",
    width: 500,
    fontSize: 18,
    fontWeight: 400,
    [theme.breakpoints.down("sm")]: {
      width: "96%",
    },
  },
  emoji: {
    fontSize: 56,
    marginBottom: 16,
  },
  mainText: {
    color: navy,
    fontFamily: "'Josefin Sans'", // Note the single quotes inside the string for font family
    fontSize: 19, // px is assumed by default in JSS for numerical values
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "150%", // Line height is specified as a string
    marginBottom: 50,
  },
})

const useStyles = makeStyles(styles)

interface Props {
  onSuccess: () => void
  returnToDescriptor: string
}

/**
 * What is step for physician services opt in modal
 * @param onSuccess - Action to take on step submit
 */
const WhatIsStep = ({ onSuccess, returnToDescriptor }: Props) => {
  const classes = useStyles(styles)

  return (
    <div>
      <ClipboardContainer title={"You're All Set"}>
        <div className={classes.emoji}>🎉</div>
        <div className={classes.mainText}>
          {VENDOR_PHYSICIAN_AUTHORIZATION_LABEL} is enabled!
        </div>
      </ClipboardContainer>
      <DesignSystemButton
        color="primary"
        onClick={onSuccess}
        className={classes.continueButton}
      >
        Return to {returnToDescriptor}
      </DesignSystemButton>
      <br></br>
    </div>
  )
}

export default WhatIsStep
