import { useMemo } from "react"

import { ReactComponent as BiomarkerIcon } from "app/assets/icons/biomarker.svg"
import { ReactComponent as ProcessingTimeIcon } from "app/assets/icons/graph-dark.svg"
import { ReactComponent as PhlebotomyIcon } from "app/assets/icons/phlebotomists.svg"
import { ReactComponent as LabTestIcon } from "app/assets/icons/test-tube.svg"
import { EMPIRE_BLOOD_DRAW_INFO, LAB_COMPANY_KEY } from "app/constants"
import useCachedCollection from "app/swr/hooks/use-cached-collection"
import { colors } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"
import { Biomarker, BiomarkerIdentifier } from "types/biomarker"

import { EXTERNAL_PHLEBOTOMY_COPY } from "../../constants"
import { Panel } from "../../types"
import { getBiomarkerDisplayName } from "../../utils"
import PanelDetailsSection from "./PanelDetailsSection"
import PanelDetailsSectionColumn from "./PanelDetailsSectionColumn"

const useStyles = makeAppStyles((theme) => ({
  container: {
    minHeight: 100,
    borderTop: `1px solid ${colors.blueGray[200]}`,
    width: "100%",
    backgroundColor: colors.blueGray[50],
    display: "flex",
    flexWrap: "wrap",
  },
  biomarkerIcon: {
    marginTop: 1,
    width: 13,
    height: 13,
    marginRight: 4,
  },
  phlebotomyIcon: {
    width: 13,
    height: 13,
  },
  labIcon: {
    marginTop: 2,
    width: 13,
    height: 13,
    marginRight: 4,
  },
  sampleIcon: {
    marginTop: 4,
    width: 18,
    height: 13,
    marginRight: 4,
  },
  wideDetails: {
    [theme.breakpoints.down("md")]: {
      width: 364,
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  narrowDetails: {
    width: 364,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  sectionColumnsContainer: {
    display: "flex",
    flexWrap: "wrap",
  },
}))

export default function PanelDetails({
  panel,
  combinedSelectedBiomarkerIdentifiers,
}: {
  panel?: Panel
  combinedSelectedBiomarkerIdentifiers: BiomarkerIdentifier[]
}) {
  const classes = useStyles()

  const selectedBiomarkers = useCachedCollection<Biomarker>(
    combinedSelectedBiomarkerIdentifiers
  )

  // For each selected biomarker see if it is included in the panel
  // Also sort by name
  const biomarkersInformation = useMemo(() => {
    return selectedBiomarkers
      .map((biomarker) => {
        const returned_biomarker = panel?.biomarkers.find(
          (panel_biomarker) => panel_biomarker.hash_id === biomarker.id
        )
        return {
          name: getBiomarkerDisplayName(biomarker),
          included: Boolean(returned_biomarker),
          sample_types: returned_biomarker?.sample_types,
        }
      })
      .sort((a, b) =>
        a.name.localeCompare(b.name, "en", { sensitivity: "base" })
      )
  }, [selectedBiomarkers])

  const phlebotomyText = useMemo(() => {
    if (panel?.lab_company.key === LAB_COMPANY_KEY.EMPIRE_CITY_LABS) {
      return EMPIRE_BLOOD_DRAW_INFO
    }
    if (panel?.pricing.phlebotomy_fee_included) {
      return <>{`$${panel?.pricing.price_phlebotomy_fees} (Paid to Rupa)`}</>
    }
    return <>{EXTERNAL_PHLEBOTOMY_COPY}</>
  }, [panel])

  // Split biomarker data into columns depending on the number of biomarkers
  const [biomarkersInformationColumn1, biomarkersInformationColumn2] =
    useMemo(() => {
      if (!biomarkersInformation.length) {
        return [[], []]
      } else if (biomarkersInformation.length < 10) {
        return [biomarkersInformation, []]
      } else if (biomarkersInformation.length < 20) {
        return [
          biomarkersInformation.slice(0, 10),
          biomarkersInformation.slice(10),
        ]
      } else {
        // Split list 50/50
        return [
          biomarkersInformation.slice(
            0,
            Math.ceil(biomarkersInformation.length / 2)
          ),
          biomarkersInformation.slice(
            Math.ceil(biomarkersInformation.length / 2)
          ),
        ]
      }
    }, [biomarkersInformation])

  if (!panel) {
    return null
  }

  return (
    <div className={classes.container}>
      <PanelDetailsSection
        className={classes.wideDetails}
        title="Biomarkers"
        icon={
          <BiomarkerIcon
            viewBox="0 0 12 12"
            fill={colors.blueGray[500]}
            className={classes.biomarkerIcon}
          />
        }
      >
        <div className={classes.sectionColumnsContainer}>
          <PanelDetailsSectionColumn
            sectionItems={biomarkersInformationColumn1}
          />
          <PanelDetailsSectionColumn
            sectionItems={biomarkersInformationColumn2}
          />
        </div>
      </PanelDetailsSection>

      <PanelDetailsSection
        className={classes.narrowDetails}
        title="Phlebotomy"
        icon={<PhlebotomyIcon className={classes.phlebotomyIcon} />}
      >
        {phlebotomyText}
      </PanelDetailsSection>

      <PanelDetailsSection
        className={classes.wideDetails}
        title="Lab Tests"
        icon={<LabTestIcon viewBox="0 0 15 15" className={classes.labIcon} />}
      >
        <div className={classes.sectionColumnsContainer}>
          <PanelDetailsSectionColumn
            sectionItems={panel.lab_tests.map((test) => ({
              name: test.name,
            }))}
          />
          <PanelDetailsSectionColumn sectionItems={[]} />
        </div>
      </PanelDetailsSection>

      <PanelDetailsSection
        className={classes.narrowDetails}
        title="Avg. Sample Processing Time"
        icon={
          <ProcessingTimeIcon
            viewBox="0 0 18 18"
            className={classes.sampleIcon}
          />
        }
      >
        {panel.lab_company.estimated_days_for_results &&
          `${panel.lab_company.estimated_days_for_results} days`}
      </PanelDetailsSection>
    </div>
  )
}
