import BodyText from "app/components/design-system/BodyText"
import makeAppStyles from "app/utils/makeAppStyles"

const useStyles = makeAppStyles(() => ({
  nameContainer: {
    display: "flex",
    alignItems: "center",
    minWidth: 100,
  },
}))

export default function LabCompanyNameTableCell({ name }) {
  const classes = useStyles()
  return (
    <div className={classes.nameContainer}>
      <BodyText weight="semibold">{name}</BodyText>
    </div>
  )
}
