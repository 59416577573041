import { useEffect, useState } from "react"

import MaterialTable from "material-table"

import TrackArrowRight from "app/assets/icons/track-arrow.svg"
import BodyText from "app/components/design-system/BodyText"
import Button from "app/components/design-system/Button"
import { IOKOrderStatusUnion, IOK_ORDER_STATUS } from "app/constants.typed"
import { trackIOKDetailsClicked } from "app/services/segment"
import useCachedCollection from "app/swr/hooks/use-cached-collection"
import theme, { colors, navy } from "app/theme"
import { Practitioner } from "app/types"
import makeAppStyles from "app/utils/makeAppStyles"
import keys from "lodash/keys"

import DateTableCell from "../components/DateTableCell"
import EmptyOrderTable from "../components/EmptyOrderTable"
import LabCompanyNameTableCell from "../components/LabCompanyNameTableCell"
import StatusTableCell from "../components/StatusTableCell"
import SystemButtonTableCell from "../components/SystemButtonTableCell"
import { Filter } from "../components/filter"
import {
  InOfficeKitLabCompanyType,
  InOfficeKitOrder,
  PAGE_SIZE,
} from "../types"
import { OrderDetailsDialog } from "./OrderDetailsDialog"
import { useOrderHistory } from "./OrderHistoryDataProvider"
import ReorderModalButton from "./ReorderModalButton"

const useStyles = makeAppStyles(() => ({
  cellButtonContainer: {
    display: "flex",
    alignItems: "center",
    gap: 10,
  },
  shipmentTrackingBtn: {
    fontSize: 15,
    paddingBottom: 3,
    paddingLeft: 2,
  },
}))

interface Props {
  practitioner: Practitioner
}

export const OrderHistoryTable = ({ practitioner }: Props) => {
  const classes = useStyles()
  const { orders } = useOrderHistory()

  const [filteredOrders, setFilteredOrders] =
    useState<InOfficeKitOrder[]>(orders)
  const [labFilter, setLabFilter] = useState<InOfficeKitLabCompanyType | null>(
    null
  )
  const [statusFilter, setStatusFilter] = useState<string | null>(null)
  const [selectedOrder, setSelectedOrder] = useState<
    InOfficeKitOrder | undefined
  >(undefined)

  const uniqueLabCompanyIds = [
    ...orders
      ?.reduce((map, order) => {
        const labCompany = order.relationships.in_office_kit_lab_company.data
        return map.set(labCompany.id, labCompany)
      }, new Map())
      .values(),
  ]

  const uniqueLabCompanies =
    useCachedCollection<InOfficeKitLabCompanyType>(uniqueLabCompanyIds)

  // Some statuses are internal only and map to the same status in the UI
  const statusMap = {
    "Order Received": ["order_received", "pending_lab_account"],
    "Pending Shipment": ["pending_shipment"],
    Shipped: ["shipped", "delivered"],
  }

  function getStatusKey(value: string): IOKOrderStatusUnion {
    for (let key in statusMap) {
      if (statusMap[key].includes(value)) {
        return key as IOKOrderStatusUnion
      }
    }
    return IOK_ORDER_STATUS.DRAFT
  }

  const columns = [
    {
      field: "attributes.in_office_kit_lab_company.lab_company_name",
      render: (filteredOrder: InOfficeKitOrder) => {
        const labCompany = uniqueLabCompanies.find(
          (labCompany) =>
            labCompany.id ===
            filteredOrder.relationships.in_office_kit_lab_company.data.id
        )
        return (
          <LabCompanyNameTableCell
            name={labCompany?.attributes.lab_company_name}
          />
        )
      },
      cellStyle: {
        paddingLeft: 32,
        width: `calc(100% / ${4})`,
      },
      filterComponent: (props) => (
        <Filter<InOfficeKitLabCompanyType>
          options={uniqueLabCompanies}
          onChange={(event, value) => setLabFilter(value)}
          value={labFilter}
          placeholder="Lab Company"
          getOptionLabel={(option) => option.attributes.lab_company_name}
        />
      ),
    },
    {
      field: "attributes.status",
      render: (filteredOrder: InOfficeKitOrder) => {
        return (
          <>
            <StatusTableCell
              status={getStatusKey(filteredOrder.attributes.status)}
            />
            {filteredOrder.attributes.tracking_number && (
              <Button
                color="text"
                className={classes.shipmentTrackingBtn}
                onClick={() =>
                  window.open(
                    filteredOrder.attributes.tracking_number,
                    "_blank"
                  )
                }
                endIcon={TrackArrowRight}
              ></Button>
            )}
          </>
        )
      },
      filterComponent: (props) => (
        <Filter<string>
          options={keys(statusMap)}
          onChange={(event, value) => setStatusFilter(value)}
          value={statusFilter}
          placeholder="Status"
          selectIcon={true}
          getOptionLabel={(option) => option}
        />
      ),
      cellStyle: {
        width: `calc(100% / ${4})`,
      },
    },
    {
      field: "attributes.date_ordered_by_practitioner",
      render: (filteredOrder: InOfficeKitOrder) => {
        return (
          <DateTableCell
            date={filteredOrder.attributes.date_ordered_by_practitioner}
          />
        )
      },
      filterComponent: () => <BodyText weight="regular">Ordered On</BodyText>,
      cellStyle: {
        width: `calc(100% / ${4})`,
      },
    },
    {
      field: "",
      render: (order: InOfficeKitOrder) => {
        return (
          <div className={classes.cellButtonContainer}>
            <SystemButtonTableCell
              text="Details"
              onClick={() => {
                setSelectedOrder(order)
                trackIOKDetailsClicked(practitioner.id)
              }}
              style={{ color: navy }}
            />
            <ReorderModalButton order={order} />
          </div>
        )
      },
      filtering: false,
      cellStyle: {
        backgroundImage: "none",
        width: `calc(100% / ${4})`,
        maxWidth: 300,
      },
    },
  ]

  // TODO: useMemo for filteredOrders
  useEffect(() => {
    const filteredOrders = orders.filter((order) => {
      const labCompany = uniqueLabCompanies.find(
        (labComp) =>
          labComp.id === order.relationships.in_office_kit_lab_company.data.id
      )
      return (
        (!labFilter ||
          labCompany?.attributes.lab_company_name ===
            labFilter?.attributes?.lab_company_name) &&
        (!statusFilter ||
          statusMap[statusFilter].includes(order.attributes.status))
      )
    })
    setFilteredOrders(filteredOrders)
  }, [statusFilter, labFilter, orders])

  return (
    <div className="rounded-lg overflow-hidden bg-white shadow border border-slate-200 [&_td]:border-slate-200">
      {orders.length === 0 ? (
        <EmptyOrderTable noOrders={true} />
      ) : (
        <MaterialTable
          aria-label="List of Orders"
          columns={columns}
          data={filteredOrders}
          options={{
            toolbar: false,
            filtering: true,
            initialPage: 0,
            pageSize: PAGE_SIZE,
            pageSizeOptions: [],
            overflowY: "hidden",
            sorting: false,
            headerStyle: {
              display: "none",
            },
            filterCellStyle: {
              color: theme?.palette?.text?.secondary,
              backgroundColor: colors.blueGray[50],
            },
            draggable: false,
            rowStyle: {
              backgroundColor: "white",
              height: 45,
            },
          }}
          localization={{
            body: {
              emptyDataSourceMessage: <EmptyOrderTable noOrders={false} />,
            },
            pagination: {
              firstAriaLabel: "First page",
              previousAriaLabel: "Previous page",
              nextAriaLabel: "Next page",
              lastAriaLabel: "Last page",
            },
          }}
        />
      )}
      <OrderDetailsDialog
        order={selectedOrder}
        setSelectedOrder={setSelectedOrder}
      />
    </div>
  )
}
