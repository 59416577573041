import { AllowAnyPaths } from "app/Routes"
import { HealthCategory, Practitioner, SearchSuggestion } from "app/types"

export function getSearchLocation(search?: string) {
  const params = new URLSearchParams()
  if (search) {
    params.append("search", search)
  }
  return {
    pathname: AllowAnyPaths.DISCOVER_LABS_SEARCH,
    search: `?${params.toString()}`,
  }
}

export function getSuggestionLocation(suggestion: SearchSuggestion) {
  const params = new URLSearchParams([
    [suggestion.type, `${suggestion.object_id}|${suggestion.label}`],
  ])
  return {
    pathname: AllowAnyPaths.DISCOVER_LABS_SEARCH,
    search: `?${params.toString()}`,
  }
}

export function getHealthCategoryLocation(category: HealthCategory) {
  const params = new URLSearchParams([
    ["health_category", `${category.id}|${category.name}`],
  ])
  return {
    pathname: AllowAnyPaths.DISCOVER_LABS_SEARCH,
    search: `?${params.toString()}`,
  }
}

export function getSigningPractitionerLocation(
  practitioner: Pick<Practitioner, "id" | "titled_full_name">
) {
  const params = new URLSearchParams([
    [
      "signing_practitioner",
      // use same format as the filter serializer
      `${practitioner.id}|${practitioner.titled_full_name}`,
    ],
  ])
  return {
    pathname: AllowAnyPaths.DISCOVER_LABS_SEARCH,
    search: `?${params.toString()}`,
  }
}
