import { DialogTitle } from "@material-ui/core"
import { useMediaQuery } from "@material-ui/core"

import { ReactComponent as CircleArrowRightIcon } from "app/assets/icons/circle-arrow-right.svg"
import Button from "app/components/design-system/Button"
import DisplayText from "app/components/design-system/DisplayText"
import useComparisonActions from "app/main/comparison/hooks/use-comparison-actions"
import { colors, Theme } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"

const useStyles = makeAppStyles((theme) => ({
  dialogTitle: {
    display: "flex",
    flexFlow: "row nowrap",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "white",
    borderBottomWidth: 1,
    borderColor: colors.blueGray[200],
    padding: theme.spacing(2.0, 2.75),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1.5, 2.0),
    },
  },
  titleText: {
    flex: "1 1 auto",
    display: "inline-flex",
    alignItems: "center",
    minHeight: 40,
    marginTop: 3,
  },
  closeButton: {
    position: "absolute",
    top: 28,
    right: theme.spacing(2.75),
    [theme.breakpoints.down("sm")]: {
      top: 24,
      right: theme.spacing(2.0),
    },
    "& .MuiButton-endIcon": {
      marginRight: 0,
    },
    "& svg": {
      fill: "currentColor",
    },
  },
}))

export default function ComparisonSearchModalTitle() {
  const classes = useStyles()
  const actions = useComparisonActions()
  const isMobile = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"))
  return (
    <DialogTitle
      className={classes.dialogTitle}
      disableTypography
      id="comparison-search-modal-title"
    >
      <DisplayText className={classes.titleText} weight="semibold" size="lg">
        {isMobile ? "Search to Compare" : "Search for a Lab Test to Compare"}
      </DisplayText>
      <Button
        aria-label="close"
        color="text"
        className={classes.closeButton}
        endIcon={<CircleArrowRightIcon viewBox="0 0 20 19" />}
        onClick={actions.closeSearchModal}
        size="medium"
      >
        See Comparison
      </Button>
    </DialogTitle>
  )
}
