import { useMemo } from "react"

import { LAB_TEST_TYPE_KEY } from "app/constants.typed"
import useAppSelector from "app/hooks/useAppSelector"
import { LabTestType } from "app/types"

const BLOOD_SAMPLE_TYPE_NAME = "Blood"
const BLOOD_SAMPLE_TYPES = new Set([
  "Self-Drawn Blood",
  "Microsample",
  "Red Blood Cells",
  "Microtainer",
  "Plasma",
  "Serum",
  "Blood Spot",
  "Whole Blood",
])

const GROUPING_ORDER = [
  "Blood",
  "Urine",
  "Saliva",
  "Stool",
  "Hair",
  "Breath",
  "Swab",
]

const useResultUploadLabTestTypes = (): LabTestType[] => {
  const labTestTypes = useAppSelector(({ labTestType }) => labTestType.list)

  // Group all the blood LabTestTypes into a single `Blood` category, leaving
  // the rest ungrouped. We do this as most practitioner won't know which
  // subtype of blood sample their results are for.
  const groupedAndSortedLabTestTypes = useMemo(() => {
    if (!labTestTypes) {
      return []
    }

    // We still need to provide an actual ID for the LabTestType that is set when
    // the user selects the `Blood` option. We default to Whole Blood as that's the
    // most common blood sample type.
    //
    // TODO: When we refactor blood lab dashboards, and specifically refactoring the
    // `BloodReportResult` model to support real FKs to LabTestType (vs an ArrayField
    // of the LabTestType names), we should replace this hardcoded blood grouping with
    // a simple `group` on `LabTestType`. The grouping logic can then be shared more
    // easily between the backend and the frontend.
    const wholeBloodId = labTestTypes.find(
      (testType) => testType.key === LAB_TEST_TYPE_KEY.WHOLE_BLOOD
    )?.id

    const grouped = labTestTypes.reduce((groups, test) => {
      if (BLOOD_SAMPLE_TYPES.has(test.name)) {
        // Creates a virtual LabTestType for the `Blood` group.
        groups[BLOOD_SAMPLE_TYPE_NAME] = groups[BLOOD_SAMPLE_TYPE_NAME] || {
          // Use Whole Blood as the actual LabTestType or whichever happens to be
          // first in the case there isn't Whole Blood (which should never happen
          // outside test envs).
          id: wholeBloodId || test.id,
          name: BLOOD_SAMPLE_TYPE_NAME,
          key: test.key,
          phlebotomy_required: test.phlebotomy_required,
        }
      } else {
        groups[test.name] = test
      }
      return groups
    }, {})

    // Sort the grouped types according to GROUPING_ORDER. This ordering was given to us by
    // product and may need to change. It also has the affect of filtering the list of lab
    // test types to display (meaning it excludes things like `dust`). That might also change.
    const sortedLabTestTypes = GROUPING_ORDER.map(
      (name) => grouped[name]
    ).filter(Boolean)

    return sortedLabTestTypes
  }, [labTestTypes])

  return groupedAndSortedLabTestTypes
}

export default useResultUploadLabTestTypes
