import React from "react"

import { Checkbox, CheckboxProps, cn } from "@rupahealth/design"

const RupaCheckbox: React.FC<CheckboxProps> = ({
  checked,
  className,
  ...props
}) => {
  return (
    <Checkbox
      checked={checked}
      className={cn(
        "hover:border-primary group-hover:border-primary",
        {
          "border-slate-200": !checked,
          "bg-primary border-primary": checked,
        },
        className
      )}
      {...props}
    />
  )
}

export default RupaCheckbox
