import { forwardRef, ComponentProps } from "react"

import { IconButton } from "@material-ui/core"
import MuiTablePagination from "@material-ui/core/TablePagination"
import MuiTablePaginationActions from "@material-ui/core/TablePagination/TablePaginationActions"
import {
  FirstPage,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  LastPage,
} from "@material-ui/icons"
import { Table } from "@tanstack/react-table"

import useEventCallback from "app/hooks/use-event-callback"
import { DashboardOrder } from "types/dashboard/dashboard-order"

const TablePaginationActions = forwardRef<
  HTMLDivElement,
  ComponentProps<typeof MuiTablePaginationActions>
>(
  (
    {
      count,
      onChangePage,
      page,
      rowsPerPage,
      // Ignore these props because we don't want to pass them to the buttons
      backIconButtonProps: _backIconButtonProps,
      nextIconButtonProps: _nextIconButtonProps,
      ...other
    },
    ref
  ) => {
    const handleFirstPageButtonClick = useEventCallback((event) => {
      onChangePage(event, 0)
    })
    const handleBackButtonClick = useEventCallback((event) => {
      onChangePage(event, page - 1)
    })
    const handleNextButtonClick = useEventCallback((event) => {
      onChangePage(event, page + 1)
    })
    const handleLastPageButtonClick = useEventCallback((event) => {
      onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1))
    })
    return (
      <div ref={ref} {...other}>
        <IconButton
          aria-label="First page"
          color="inherit"
          disabled={page === 0}
          onClick={handleFirstPageButtonClick}
          title="First page"
        >
          <FirstPage />
        </IconButton>

        <IconButton
          aria-label="Previous page"
          color="inherit"
          disabled={page === 0}
          onClick={handleBackButtonClick}
          title="Previous page"
        >
          <KeyboardArrowLeft />
        </IconButton>

        <IconButton
          aria-label="Next page"
          color="inherit"
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          onClick={handleNextButtonClick}
          title="Next page"
        >
          <KeyboardArrowRight />
        </IconButton>

        <IconButton
          aria-label="Last page"
          color="inherit"
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          onClick={handleLastPageButtonClick}
          title="Last page"
        >
          <LastPage />
        </IconButton>
      </div>
    )
  }
)

export interface TablePaginationRendererProps {
  table: Table<DashboardOrder>
}

export default function TablePaginationRenderer({
  table,
}: TablePaginationRendererProps) {
  return (
    <MuiTablePagination
      ActionsComponent={TablePaginationActions}
      count={table.options.meta?.count ?? -1}
      onChangePage={(_event, pageIndex) => table.setPageIndex(pageIndex)}
      page={table.getState().pagination.pageIndex}
      rowsPerPage={table.getState().pagination.pageSize}
      rowsPerPageOptions={[]}
    />
  )
}
