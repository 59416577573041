import makeAppStyles from "app/utils/makeAppStyles"

import TooltipNavItem from "./TooltipNavItem"
import { NavItem } from "./constants"

const styles = () => ({
  container: {
    display: "flex",
    flexFlow: "column nowrap",
    gap: 4,
  },
})
const useStyles = makeAppStyles(styles)

interface Props {
  children: NavItem[]
}

const TooltipNavParent = ({ children }: Props) => {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      {children.map((child) => (
        <TooltipNavItem item={child} key={child.id} />
      ))}
    </div>
  )
}

export default TooltipNavParent
