import { RootState } from "app/types"

export function selectPhlebotomistFeatures(state: RootState) {
  return state.phlebotomistMap.featureIds.map(
    (id) => state.phlebotomistMap.featureById[id]
  )
}

export function selectSelectedPhlebotomistFeature(state: RootState) {
  return state.phlebotomistMap.selectedFeatureId
    ? state.phlebotomistMap.featureById[state.phlebotomistMap.selectedFeatureId]
    : undefined
}
