import { makeStyles } from "@material-ui/core/styles"

import { colors, shadows } from "app/theme"

import LandingPage from "./LandingPage"
import PatientSignup from "./PatientSignup"

const useStyles = makeStyles((theme) => ({
  form: {
    border: `1px solid ${colors.blueGray[200]}`,
    borderRadius: 6,
    background: "white",
    padding: "12px 24px 24px 24px",
    marginTop: 12,
    display: "flex",
    flexFlow: "column",
    alignItems: "center",
    boxShadow: shadows.md,
    width: 316,
    gap: 20,

    [theme.breakpoints.up("sm")]: {
      padding: "24px 42px 42px 42px",
      minWidth: 512,
    },
  },
}))

export default function ExternalPatientSignup() {
  const classes = useStyles()

  return (
    <LandingPage
      title="Create your patient account."
      subtitle="Sign up to get secure access to your test results, sample updates, & more!"
    >
      <div className={classes.form}>
        <PatientSignup />
      </div>
    </LandingPage>
  )
}
