import clsx from "clsx"

const Panel = (props) => (
  <div
    style={{ boxShadow: "0px 3px 8px rgba(0, 0, 0, 0.1)" }}
    {...props}
    className={clsx("rounded-8 bg-white", props.className)}
  />
)

export default Panel
