import { Button } from "@rupahealth/design"

import usePhysicianServicesOptInModal from "app/components/modals/PhysicianServicesOptInModal/use-physician-services-opt-in-modal"
import { VENDOR_PHYSICIAN_AUTHORIZATION_LABEL } from "app/constants"
import { getOrderTypeLabel } from "app/utils/order-utils"

import DashboardBanner from "./DashboardBanner"

export default function PhysicianServicesTermsBanner({
  practitionerHasPhysicianServicesLabshop,
}: {
  practitionerHasPhysicianServicesLabshop: boolean
}) {
  const physicianServicesOptInModal = usePhysicianServicesOptInModal()

  const onClick = () => {
    physicianServicesOptInModal.show({
      updatedTerms: true,
      disableClose: false,
      returnToDescriptor: "Dashboard",
      practitionerHasPhysicianServicesLabshop:
        practitionerHasPhysicianServicesLabshop,
      onClose: () => {
        physicianServicesOptInModal.remove()
      },
    })
  }

  return (
    <DashboardBanner highlightColor="#FFD53E">
      <div className="flex flex-col md:flex-row justify-between items-center gap-y-3">
        <div className="max-w-6xl mr-6">
          <div className="font-semibold mb-1 text-base">
            Updated Terms for {VENDOR_PHYSICIAN_AUTHORIZATION_LABEL} 🛎️
          </div>
          <div>
            By using {VENDOR_PHYSICIAN_AUTHORIZATION_LABEL}, your clients can
            gain access to testing on Rupa through a Signing Physician. The
            Signing Physician will review the results for any critical values.{" "}
            <span className="font-semibold">
              To continue using this service for your {getOrderTypeLabel(true)}
              s, please review and confirm our updated terms!{" "}
              <a
                href="https://www.rupahealth.com/physician-services"
                className="text-primary font-normal"
              >
                Learn more.
              </a>
            </span>
          </div>
        </div>
        <Button
          onClick={onClick}
          variant="outline-primary"
          className="w-full md:w-auto p-6"
        >
          Review Terms
        </Button>
      </div>
    </DashboardBanner>
  )
}
