import { useCallback, useState } from "react"

import { DeepPartial } from "global"
import { MutatorOptions, useSWRConfig } from "swr"

import useHandleApiError from "app/hooks/use-handle-api-error"

import { mergeResources, writeToCache } from "../helpers/swr"
import useMutateResource from "../hooks/use-mutate-resource"
import { Resource, ResourceResponse } from "../types"
import resourceRequest from "../utils/resource-request"

/**
 * Utility hook for patching a resource.
 * - Manages cache updates
 * - Includes default error handling
 * - Provides loading state
 */

interface UpdateResorceProps<R extends Resource, D = DeepPartial<R>> {
  // Path to the resource. Can be a string or a function that receives the resource and returns a string
  // Example: "lab_tests" or (resource) => `/lab_tests/${resource.id}/`
  // In the future, we could infer the path from the resource type or vice versa
  path: string | ((resource: R) => string)

  // Passed to the patch request
  include?: string[]

  // Custom function to get the new cache data. If not provided, default is to merge the response with cacheData
  getNewCacheData?: (props: { requestData: D; cacheData: R; response: R }) => R

  // Other options to pass to the mutateResource function
  mutatorOptions?: MutatorOptions<R>

  // Fires when the update is successful
  onSuccess?: (resource: R) => void
  // Replaces the default error toast
  onError?: (error: any) => void
  // Fires whether the update is successful or errors
  onSettled?: () => void
}

export function useUpdateResource<R extends Resource, D = DeepPartial<R>>({
  path,
  include = [],
  mutatorOptions,
  getNewCacheData,
  onError,
  onSuccess,
  onSettled,
}: UpdateResorceProps<R, D>) {
  const handleApiError = useHandleApiError()
  const mutateResource = useMutateResource()
  const globalConfig = useSWRConfig()
  const [isUpdating, setIsUpdating] = useState(false)

  const getUrl = useCallback(
    (resource: R) => {
      if (typeof path === "function") return path(resource)
      return `/${path}/${resource.id}/`
    },
    [path]
  )

  const patchRequest = useCallback(
    async (resource: R, requestData: D) =>
      resourceRequest<ResourceResponse<R>>({
        method: "patch",
        url: getUrl(resource),
        data: { data: requestData },
        include,
      }),
    [getUrl, include]
  )

  const updateResource = useCallback(
    async (resource: R, requestData: D) => {
      try {
        setIsUpdating(true)

        const result = await mutateResource<R>(
          resource,
          async (cacheData) => {
            if (!cacheData) return cacheData

            const { data: response, included } = await patchRequest(
              resource,
              requestData
            )

            await writeToCache(globalConfig, ...(included ?? []))

            return (
              getNewCacheData?.({ requestData, cacheData, response }) ??
              mergeResources(cacheData, response)
            )
          },
          mutatorOptions
        )

        if (result) {
          onSuccess?.(result)
        }
      } catch (error) {
        if (onError) {
          onError(error)
        } else {
          handleApiError(error)
        }
      } finally {
        setIsUpdating(false)
        onSettled?.()
      }
    },
    [
      mutateResource,
      patchRequest,
      getNewCacheData,
      onSuccess,
      onError,
      onSettled,
      handleApiError,
    ]
  )
  return { updateResource, isUpdating }
}
