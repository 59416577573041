import { faUser } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import useAppSelector from "app/hooks/useAppSelector"
import { getOrderTypeLabel, getOrderPatientLabel } from "app/utils/order-utils"

import NotesToClientsSnippetsTable from "./NotesToClientsSnippetsTable"
import SavedTextSnippetsSectionTitle from "./SavedTextSnippetsSectionTitle"
import SettingPill from "./SettingPill"

const NotesToClientsSnippetsContainer = () => {
  const practitioner = useAppSelector(({ practitioner }) => practitioner)

  return (
    <div className="flex flex-col gap-6">
      <SavedTextSnippetsSectionTitle
        title={`Notes to ${getOrderPatientLabel(
          practitioner?.use_physician_services_terminology
        )}`}
        text={
          <>
            Snippets can be re-used in the notes to your clients that can be
            easily added when{" "}
            <span className="font-semibold">
              creating a{" "}
              {getOrderTypeLabel(
                practitioner?.use_physician_services_terminology
              )}
            </span>
            .
          </>
        }
        newSnippetButtonText="New Instruction Snippet"
        settingsPill={
          <SettingPill
            title="Personal Setting"
            icon={
              <FontAwesomeIcon className="text-sky-600 text-xs" icon={faUser} />
            }
          />
        }
        snippetType="notes_to_patient"
      />
      <NotesToClientsSnippetsTable />
    </div>
  )
}

export default NotesToClientsSnippetsContainer
