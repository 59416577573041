import { useRef, useState } from "react"

import { isEmpty, trim } from "lodash"
import { Control, useController } from "react-hook-form"

import { lighten } from "@material-ui/core"

import GrowingTextArea from "app/components/design-system/GrowingTextArea"
import useEventCallback from "app/hooks/use-event-callback"
import { colors, primaryColor, rupaCyan, textPrimaryColor } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"

const useStyles = makeAppStyles((theme) => ({
  root: {
    width: "100%",
    display: "flex",
    flexFlow: "column nowrap",
    justifyContent: "flex-start",
  },
  label: {
    display: "inline-flex",
    flexFlow: "row nowrap",
    alignItems: "center",
    gap: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
    fontSize: 14,
    fontWeight: 600,
    color: textPrimaryColor,
    "& > svg": {
      fill: "currentColor",
    },
  },
  textAreaWrap: {
    width: "100%",
    padding: theme.spacing(1.25, 1.75),
    fontSize: 15,
    fontWeight: 600,
    lineHeight: 1.35,
    color: textPrimaryColor,
    backgroundColor: colors.coolGray[50],
    border: `1px solid ${colors.blueGray[300]}`,
    borderRadius: 6,
    "&[data-disabled=true]": {
      color: colors.blueGray[500],
    },
    "&:focus-within": {
      borderColor: primaryColor,
      boxShadow: `0px 0px 3.1099px ${primaryColor}`,
    },
  },
  textArea: {
    width: "100%",
    backgroundColor: "inherit",
    font: "inherit",
    fontSize: "inherit",
    fontWeight: "inherit",
    lineHeight: "inherit",
    color: "inherit",
    "&::placeholder": {
      color: colors.blueGray[500],
      fontWeight: 600,
    },
  },
  shortcuts: {
    marginTop: theme.spacing(0.5),
    display: "flex",
    flexFlow: "row wrap",
    alignItems: "center",
    gap: theme.spacing(1.25),
  },
  shortcut: {
    padding: theme.spacing(0.75, 1.25),
    color: rupaCyan,
    border: `1px solid ${rupaCyan}`,
    borderRadius: 6,
    fontSize: 14,
    fontWeight: 600,
    lineHeight: 1.35,
    whiteSpace: "nowrap",
    "&:hover, &:focus": {
      backgroundColor: lighten(rupaCyan, 0.9),
    },
    "&:active": {
      backgroundColor: lighten(rupaCyan, 0.8),
    },
    "&:disabled": {
      color: colors.blueGray[300],
      borderColor: colors.blueGray[300],
      backgroundColor: colors.blueGray[50],
      pointerEvents: "none",
    },
  },
}))

export interface FoodPlanTextAreaFieldProps {
  autoFocus?: boolean
  control: Control<any>
  disabled?: boolean
  label?: string
  name: string
  placeholder?: string
  rows?: number
  shortcuts?: string[]
}

/**
 * The text area field component for the food plan form.
 */
export default function FoodPlanTextAreaField({
  autoFocus,
  control,
  disabled,
  label,
  name,
  placeholder,
  rows = 3,
  shortcuts,
}: FoodPlanTextAreaFieldProps) {
  const classes = useStyles()
  const ref = useRef<HTMLTextAreaElement | null>(null)

  const { field } = useController({
    control,
    name,
    shouldUnregister: true,
  })

  const [usedShortcuts, setUsedShortcuts] = useState<string[]>([])

  const onSelectShortcut = useEventCallback((shortcut: string) => {
    if (disabled) {
      return
    }

    setUsedShortcuts((prev) => [...prev, shortcut])

    field.onChange(
      !isEmpty(trim(field.value))
        ? `${field.value}\n${shortcut}`
        : `${field.value}${shortcut}`
    )

    ref.current?.focus()
  })

  const visibleShortcuts = shortcuts?.filter(
    (shortcut) => !usedShortcuts.includes(shortcut)
  )

  return (
    <div className={classes.root}>
      {label && (
        <label className={classes.label} htmlFor={name}>
          {label}
        </label>
      )}
      <div className={classes.textAreaWrap} data-disabled={disabled}>
        <GrowingTextArea
          autoFocus={autoFocus}
          className={classes.textArea}
          disabled={disabled}
          placeholder={placeholder}
          rows={rows}
          {...field}
          ref={(elem) => {
            ref.current = elem

            field.ref(elem)
          }}
        />

        {!isEmpty(visibleShortcuts) && (
          <div className={classes.shortcuts}>
            {visibleShortcuts?.map((shortcut) => (
              <button
                key={shortcut}
                className={classes.shortcut}
                disabled={disabled}
                onClick={() => onSelectShortcut(shortcut)}
                type="button"
              >
                {shortcut}
              </button>
            ))}
          </div>
        )}
      </div>
    </div>
  )
}
