import clsx from "clsx"

import { CellContext } from "@tanstack/react-table"

import { colors } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"
import { DashboardOrder } from "types/dashboard/dashboard-order"

import columnHelper from "./column-helper"
import { UtilityCell, UtilityHeader } from "./utilityColumn"

const useStyles = makeAppStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: theme.spacing(0.5),
  },
  notificationRoot: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  notification: {
    width: "12px",
    height: "12px",
  },
  notificationActive: {
    background: colors.emerald[500],
    borderRadius: 20,
  },
}))

const UnreadNotificationCell = ({
  row,
}: CellContext<DashboardOrder, unknown>) => {
  const classes = useStyles()
  return (
    <div className={classes.notificationRoot}>
      <div
        className={clsx(classes.notification, {
          [classes.notificationActive]:
            row.original.attributes.has_unread_notification,
        })}
      />
    </div>
  )
}

const UtilityNotificationCell = (
  props: CellContext<DashboardOrder, unknown>
) => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <UnreadNotificationCell {...props} />
      <UtilityCell {...props} />
    </div>
  )
}

const utilityNotificationColumn = columnHelper.display({
  cell: (info) => <UtilityNotificationCell {...info} />,
  header: (info) => <UtilityHeader {...info} />,
  id: "utility_notification",
  size: 42,
  maxSize: 42,
  minSize: 42,
  meta: {
    cellStyle: {
      padding: 0,
    },
  },
})

export default utilityNotificationColumn
