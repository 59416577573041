import { useEffect, useState } from "react"

import { API } from "app/api"
import PhotoAutoUpload from "app/components/upload/PhotoAutoUpload"

import { PractitionerProfile } from "./types"

interface Props {
  profile: PractitionerProfile
  onClearProfilePhoto: () => void
  onUploadProfilePhoto: () => void
  mutateProfile: () => void
}

export default function UploadProfilePhoto({
  profile,
  onClearProfilePhoto,
  onUploadProfilePhoto,
  mutateProfile,
}: Props) {
  const [photoUrl, setPhotoUrl] = useState(profile.attributes.profile_photo)
  const handleUploadSuccess = (response): void => {
    if (response?.data?.attributes?.profile_photo) {
      setPhotoUrl(response.data.attributes.profile_photo)
      onUploadProfilePhoto()
      mutateProfile()
    }
  }

  useEffect(() => {
    if (profile) {
      setPhotoUrl(profile.attributes.profile_photo)
    }
  }, [profile.attributes.profile_photo])

  const onRemovePhoto = async () => {
    setPhotoUrl(undefined)

    if (profile) {
      await API.PractitionerProfile.deletePhoto(profile.id)
      onClearProfilePhoto()
      mutateProfile()
    }
  }

  return (
    <PhotoAutoUpload
      uploadUrl={`/api/normalized/practitioner_profiles/${profile.id}/photo/`}
      logoUrl={photoUrl ?? null}
      onLogoUploadSuccess={handleUploadSuccess}
      onRemoveLogo={onRemovePhoto}
      subject="photo"
    />
  )
}
