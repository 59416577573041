export default function DashboardBanner({
  children,
  highlightColor,
}: {
  children: React.ReactNode
  highlightColor?: string
}) {
  const gradientStyle = {
    backgroundImage: `linear-gradient(90deg, ${highlightColor} -12.23%, rgba(238, 241, 245, 0.33) 102.02%, rgba(255, 255, 255, 0.00) 160.59%)`,
  }

  return (
    <div className="mt-6 rounded-md bg-white border border-solid border-slate-300 shadow-sm">
      <div style={gradientStyle} className="h-2 rounded-t-md"></div>
      <div className="p-6">{children}</div>
    </div>
  )
}
