import axios from "axios"
import useSWR from "swr"

import { getApiBaseUrl } from "app/utils"

import { LabCompanyOrderingAccessResponse } from "./types"

interface Props {
  signingPractitionerId?: string
}

export default function useLabCompanyOrderingAccessSWR({
  signingPractitionerId,
}: Props) {
  const key = signingPractitionerId
    ? [
        `/lab_company_ordering_access/${signingPractitionerId}/`,
        signingPractitionerId,
      ]
    : null

  const {
    data,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    error: _error,
    isLoading,
    isValidating,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    mutate: _mutate,
  } = useSWR<LabCompanyOrderingAccessResponse>(key, {
    async fetcher(key) {
      const url = key[0]

      const response = await axios.request<LabCompanyOrderingAccessResponse>({
        baseURL: getApiBaseUrl() + "/api/",
        url,
        method: "get",
      })

      return response.data
    },
    revalidateIfStale: true,
    revalidateOnMount: true,
    revalidateOnFocus: true,
    revalidateOnReconnect: true,
  })

  return {
    data,
    isLoading,
    isValidating,
  }
}
