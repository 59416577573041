import NiceModal, { muiDialog, useModal } from "@ebay/nice-modal-react"
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@material-ui/core"
import CloseIcon from "@material-ui/icons/Close"

import { colors, textPrimaryColor } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"

const useStyles = makeAppStyles((theme) => ({
  dialogContent: {
    width: "100%",
    height: 0,
    position: "relative",
    paddingTop: 0,
    paddingLeft: 0,
    paddingRight: 0,
    /**
     * aspect ratio  | padding-bottom value
     * --------------|----------------------
     *     16:9      |       56.25%
     *     4:3       |       75%
     *     3:2       |       66.66%
     *     8:5       |       62.5% <--- this one
     */
    paddingBottom: "62%",
    backgroundColor: "black",
    "& > iframe": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
    },
  },
  dialogTitle: {
    display: "flex",
    flexFlow: "row nowrap",
    justifyContent: "space-between",
    alignItems: "center",
    gap: 4,
    backgroundColor: "white",
    borderBottomWidth: 1,
    borderColor: colors.blueGray[200],
    padding: theme.spacing(1.5, 2.0),
  },
  titleText: {
    display: "inline-flex",
    alignItems: "center",
    marginTop: 6,
  },
  closeButton: {
    position: "absolute",
    top: theme.spacing(1.0),
    right: theme.spacing(0.5),
    color: textPrimaryColor,
    [theme.breakpoints.down("sm")]: {
      top: theme.spacing(1.25),
      right: 0,
    },
  },
}))

interface VideoModalProps {
  src: string
  title: string
}

export default NiceModal.create(function VideoModal({
  src,
  title,
}: VideoModalProps) {
  const classes = useStyles()
  const modal = useModal()
  const muiDialogControl = muiDialog(modal)

  return (
    <Dialog
      {...muiDialogControl}
      disableEnforceFocus
      disableEscapeKeyDown
      transitionDuration={500}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle className={classes.dialogTitle}>
        <span className={classes.titleText}>{title}</span>

        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={() => muiDialogControl.onClose()}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <iframe
          src={src}
          width="640"
          height="400"
          frameBorder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowFullScreen
          title="Watch Tutorial"
        />
      </DialogContent>
    </Dialog>
  )
})
