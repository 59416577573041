import { Skeleton } from "@rupahealth/design"

import BodyText from "app/components/design-system/BodyText"

const SnippetsTableLoader = () => {
  return (
    <div className="rounded-md border border-b-[2px] border-slate-200 overflow-hidden">
      <table className="table-auto border-spacing-0  w-full shadow border-separate">
        <thead className="bg-slate-50 ">
          <tr>
            <th className="text-left py-[14px] px-[22px] border-b border-slate-300 flex items-center gap-[2px]">
              <BodyText weight="semibold">Snippets</BodyText>
            </th>
            <th className="py-[14px] px-[22px] border-b border-slate-300"></th>
          </tr>
        </thead>
        <tbody className="bg-white">
          <tr>
            <td className="px-[22px] py-3 border-b border-slate-200">
              <Skeleton className="h-3 w-[200px]" />
            </td>

            <td className="flex justify-end py-3 pr-[22px] border-b border-slate-200">
              <Skeleton className="h-3 w-[100px]" />
            </td>
          </tr>
          <tr>
            <td className="px-[22px] py-3 border-b border-slate-200">
              <Skeleton className="h-3 w-[200px]" />
            </td>

            <td className="flex justify-end py-[20px] pr-[22px] border-b border-slate-200">
              <Skeleton className="h-3 w-[100px]" />
            </td>
          </tr>
        </tbody>
        <tfoot className="bg-slate-50">
          <tr>
            <td className="px-[22px] py-6"></td>
            <td className="px-[22px] py-6"></td>
          </tr>
        </tfoot>
      </table>
    </div>
  )
}

export default SnippetsTableLoader
