import useSWR from "swr"

import resourceRequest from "app/swr/utils/resource-request"
import {
  FoodPlanVersion,
  FoodPlanVersionGenerateFeed,
} from "types/food-plan-version"

export interface UseFoodPlanGenerateFeedProps {
  foodPlanVersion?: FoodPlanVersion
  onFinish?: () => void
}

export default function useFoodPlanGenerateFeed({
  foodPlanVersion,
  onFinish,
}: UseFoodPlanGenerateFeedProps) {
  const shouldFetch =
    foodPlanVersion &&
    ["ready", "processing"].includes(foodPlanVersion.attributes.state)
  const { data: feed, ...rest } = useSWR<FoodPlanVersionGenerateFeed>(
    shouldFetch ? `/food_plan_versions/${foodPlanVersion.id}/feed/` : null,
    {
      async fetcher(url) {
        const resourceResponse: any = await resourceRequest({
          method: "get",
          url,
        })

        return resourceResponse.data
      },
      dedupingInterval: 100,
      refreshInterval(latestData) {
        switch (latestData?.state) {
          case "ready":
            return 1000
          case "processing":
            return 100
          case "complete":
          case "failed":
          default:
            return 0
        }
      },
      onSuccess(data, _key, _config) {
        if (["complete", "failed"].includes(data.state)) {
          onFinish?.()
        }
      },
    }
  )

  return {
    feed,
    ...rest,
  }
}

export type UseFoodPlanGenerateFeed = ReturnType<typeof useFoodPlanGenerateFeed>
