import { PropsWithChildren } from "react"

import * as Popover from "@radix-ui/react-popover"

import { shadows } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"

const useStyles = makeAppStyles((theme) => ({
  addBiomarkerSearchContent: {
    maxHeight: 300,
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: theme.spacing(1.25),
    scrollPaddingBottom: `${theme.spacing(4.5)}px`,
    scrollPaddingTop: `${theme.spacing(4.5)}px`,
    zIndex: theme.zIndex.modal,
    overflow: "auto",
    backgroundColor: "white",
    border: "0.82px solid #dbdfe6",
    borderRadius: 6,
    boxShadow: shadows.xl,
  },
}))

export default function AddBiomarkerSearchPopoverContent({
  contentRef,
  children,
  width,
  zIndex,
}: PropsWithChildren<{
  contentRef: any
  width: number | string
  zIndex?: number
}>) {
  const classes = useStyles()

  return (
    <Popover.Portal>
      <Popover.Content
        className={classes.addBiomarkerSearchContent}
        style={{
          width: width,
          maxWidth: width,
          zIndex,
        }}
        onOpenAutoFocus={(e) => e.preventDefault()}
        onCloseAutoFocus={(e) => e.preventDefault()}
        // The following two handlers ensure the click outside handler does not cause
        // this popover to close when rendered within a portal or modal.
        onPointerDownCapture={(e) => {
          const target = e.target as HTMLElement
          const targetIsContent = contentRef.current?.contains(target)

          if (targetIsContent) {
            e.preventDefault()
          }
        }}
        onPointerDownOutside={(e) => {
          const target = e.target as HTMLElement
          const targetIsContent = contentRef.current?.contains(target)

          if (targetIsContent) {
            e.preventDefault()
          }
        }}
        sideOffset={5}
        ref={contentRef}
      >
        {children}
      </Popover.Content>
    </Popover.Portal>
  )
}
