import BloodIcon from "app/assets/icons/blood.svg"
import BodyText from "app/components/design-system/BodyText"
import { colors, navy, white, shadows } from "app/theme"
import { PatientPortalOrder } from "app/types"
import { formatDollars } from "app/utils"
import makeAppStyles from "app/utils/makeAppStyles"

import { calculateAxlePricesFromLineItems } from "./utils"

interface MobileBloodDrawIndicatorProps {
  order: PatientPortalOrder
}

const useStyles = makeAppStyles(() => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    marginTop: "20px",
    marginBottom: "20px",
    borderRadius: "8px",
    border: `1px solid ${colors.blueGray[200]}`,
    background: white,
    boxShadow: shadows.default,
    padding: 20,
    paddingTop: 22,
    paddingBottom: 22,
    color: navy,
    textAlign: "right",
    fontFamily: "Open Sans",
    fontSize: "15px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "normal",
  },
  kitContainerLine: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingLeft: "25px",
    width: "100%",
  },
  headerLine: {
    display: "flex",
    width: "100%",
  },
  icon: {
    marginRight: 8,
    height: 20,
  },
  text: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
  },
}))

const MobileBloodDrawIndicator = ({ order }: MobileBloodDrawIndicatorProps) => {
  const classes = useStyles()

  const {
    totalAxlePrice,
    axleBaseFee,
    axleBaseTitle,
    additionalAxleKitsCount,
    additionalAxleKitsTitle,
    additionalAxleKitFees,
  } = calculateAxlePricesFromLineItems(order.line_items)

  if (!axleBaseFee) {
    return <></>
  }

  return (
    <div className={classes.container}>
      <div className={classes.headerLine}>
        <img className={classes.icon} src={BloodIcon} alt="" />
        <div className={classes.text}>
          <div>Mobile Blood Draw</div>{" "}
          <div>{formatDollars(totalAxlePrice)}</div>
        </div>
      </div>
      {Boolean(additionalAxleKitsCount) && (
        <>
          <br />
          <div className={classes.kitContainerLine}>
            <BodyText>{axleBaseTitle}</BodyText>
            <BodyText weight="semibold" className="ml-2">
              {formatDollars(axleBaseFee)}
            </BodyText>
          </div>
          <div className={classes.kitContainerLine}>
            <BodyText>{additionalAxleKitsTitle}</BodyText>
            <BodyText weight="semibold" className="ml-2">
              {formatDollars(additionalAxleKitFees)}
            </BodyText>
          </div>
        </>
      )}
    </div>
  )
}
export default MobileBloodDrawIndicator
