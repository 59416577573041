import { isEmpty, isNil } from "lodash"

import {
  StorefrontLabCompanyType,
  StorefrontLabTestType,
} from "app/hooks/use-resource"
import { EmptyComparisonValue } from "app/main/comparison/components/ComparisonLabTests/components"
import { pluralizeDays } from "app/main/comparison/utils"

interface Props {
  labTest: StorefrontLabTestType
  labCompany: StorefrontLabCompanyType | undefined
}

const ShippingTimeColumn = ({ labTest, labCompany }: Props) => {
  const { shipping_days_max, shipping_days_min } = labTest.attributes
  const shipping_carrier = labCompany
    ? labCompany?.attributes.shipping_carrier
    : ""
  const hasMax = !isNil(shipping_days_max)
  const hasMin = !isNil(shipping_days_min)
  const hasCarrier = !isEmpty(shipping_carrier)
  let shippingTimeText = ""

  if (!hasMax && !hasMin && !hasCarrier) {
    return <EmptyComparisonValue />
  }

  if (hasMax && hasMin) {
    if (shipping_days_max !== shipping_days_min) {
      shippingTimeText = `${shipping_days_min} - ${shipping_days_max} days`
    } else {
      shippingTimeText = `${shipping_days_min} ${pluralizeDays(
        shipping_days_min
      )}`
    }
  } else if (hasMax) {
    shippingTimeText = `${shipping_days_max} ${pluralizeDays(
      shipping_days_max
    )}`
  } else if (hasMin) {
    shippingTimeText = `${shipping_days_min} ${pluralizeDays(
      shipping_days_min
    )}`
  } else {
    shippingTimeText = "Varies"
  }

  return (
    <>
      {hasCarrier
        ? `${shippingTimeText}, ${shipping_carrier}`
        : shippingTimeText}
    </>
  )
}

export default ShippingTimeColumn
