import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import _ from "lodash"
import { Redirect } from "react-router-dom"

import { UserPaths } from "app/Routes"
import ActionRequiredImage from "app/assets/images/action-required.jpg"
import SignatureSuccessImage from "app/assets/images/signature-success.jpg"
import { resendRegistrationEmail } from "app/auth/store/actions"
import DesignSystemButton from "app/components/design-system/Button"
import ActionRequiredModal from "app/components/modals/ActionRequiredModal"
import SignatureAndLicenseModalButton, {
  signatureAndLicenseButtonTitle,
} from "app/components/modals/SignatureAndLicenseModal/SignatureAndLicenseModalButton"
import Dashboard from "app/main/dashboard/Dashboard"
import { RootState } from "app/types"

function Signature() {
  const [verificationComplete, setVerificationComplete] = useState(false)
  const [returnToDashboard, setReturnToDashboard] = useState(false)
  const practitioner = useSelector(
    ({ practitioner }: RootState) => practitioner
  )

  const signatureNeeded = !practitioner.has_signature
  const licenseNeeded = !practitioner.has_license

  const dispatch = useDispatch()

  const resendEmailVerification = () => {
    dispatch(resendRegistrationEmail({ email: practitioner.user.email }))
  }

  const needsEmailVerification = practitioner.user?.is_verified === false

  const needsVerification =
    (signatureNeeded || licenseNeeded) && !verificationComplete

  let image = SignatureSuccessImage
  let imageAlt = "Success"

  if (needsVerification || needsEmailVerification) {
    image = ActionRequiredImage
    imageAlt = "Action Required"
  }

  const title =
    needsVerification || needsEmailVerification
      ? "Verification Required"
      : "Verification Complete!"

  const subtitle = needsEmailVerification
    ? "Please verify your email address first."
    : needsVerification
    ? signatureNeeded && licenseNeeded
      ? "Please provide your e-signature and practitioner license."
      : licenseNeeded
      ? "Please provide your practitioner license."
      : "Please provide your e-signature."
    : "We've submitted any orders which were blocked by the missing signature."

  const onClickReturnToDashboard = () => {
    setReturnToDashboard(true)
  }

  const actionButton = needsEmailVerification ? (
    <DesignSystemButton
      color="primary"
      variant="text"
      onClick={resendEmailVerification}
    >
      Verify Email Address
    </DesignSystemButton>
  ) : needsVerification ? (
    <SignatureAndLicenseModalButton
      location="signature_page"
      variant="primary"
      onClose={setVerificationComplete}
      autoClose
    >
      {signatureAndLicenseButtonTitle(practitioner)}
    </SignatureAndLicenseModalButton>
  ) : (
    <DesignSystemButton color="primary" onClick={onClickReturnToDashboard}>
      Return to Dashboard
    </DesignSystemButton>
  )
  const practitionerLoaded = !_.isEmpty(practitioner)

  return (
    <>
      {returnToDashboard && <Redirect to={UserPaths.DASHBOARD} />}
      {practitionerLoaded && (
        <ActionRequiredModal
          testId="verification-required-modal"
          open
          image={image}
          imageAlt={imageAlt}
          button={actionButton}
          title={title}
          subtitle={subtitle}
        />
      )}
      <Dashboard />
    </>
  )
}

export default Signature
