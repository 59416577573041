import { createContext, useContext } from "react"

import { KeyedMutator } from "swr"

import useCachedCollection from "app/swr/hooks/use-cached-collection"
import useCollectionSWR from "app/swr/hooks/use-collection-swr"
import { ResourceCollection } from "app/swr/types"

import { InOfficeKitTabId } from "../InOfficeKitsTabs"
import { InOfficeKitOrder } from "../types"

// Data provider for the Supply Order History tab and Start Order button
// Doesn't load the data until the tab is active
interface OrderHistoryContextProps {
  orders: InOfficeKitOrder[]
  mutateOrders: KeyedMutator<ResourceCollection<InOfficeKitOrder> | undefined>
  ordersLoading: boolean
  ordersValidating: boolean
}

const OrderHistoryContext = createContext<OrderHistoryContextProps>(
  undefined as never
)

interface Props {
  children: React.ReactNode
  activeTab: string | null
}

function OrderHistoryDataProvider({ children, activeTab }: Props) {
  const {
    data: orderIdentifiers,
    mutate: mutateOrders,
    isLoading: ordersLoading,
    isValidating: ordersValidating,
  } = useCollectionSWR<ResourceCollection<InOfficeKitOrder>>(
    activeTab === InOfficeKitTabId.ORDER_HISTORY
      ? "/in_office_kit_order/"
      : null,
    {
      include: ["in_office_kit_lab_company"],
      params: {
        "filter[status.in]":
          "shipped,pending_shipment,order_received,pending_lab_account",
      },
    }
  )
  const orders = useCachedCollection<InOfficeKitOrder>(orderIdentifiers)

  return (
    <OrderHistoryContext.Provider
      value={{
        orders,
        mutateOrders,
        ordersLoading,
        ordersValidating,
      }}
    >
      {children}
    </OrderHistoryContext.Provider>
  )
}

export function useOrderHistory() {
  return useContext(OrderHistoryContext)
}

export default OrderHistoryDataProvider
