import Button from "@material-ui/core/Button"
import { makeStyles } from "@material-ui/core/styles"

import { ReactComponent as BlueCheck } from "app/assets/icons/blue-checkmark.svg"
import { primaryColor } from "app/theme"

const useStyles = makeStyles((theme) => ({
  markAsCompleteContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  markAsCompleteButton: {
    fontSize: "15px",
    fontWeight: theme.typography.fontWeightMedium,
    lineHeight: "135%",
    color: primaryColor,
  },
  markAsCompleteCheck: {
    marginRight: 2,
    position: "relative",
    top: 2,
    height: "20px",
    transform: "scale(0.7)",
  },
}))

export const MarkAsComplete = ({ onClick, style = {} }) => {
  const classes = useStyles()
  return (
    <div className={classes.markAsCompleteContainer} style={{ ...style }}>
      <Button className={classes.markAsCompleteButton} onClick={onClick}>
        <BlueCheck className={classes.markAsCompleteCheck} />
        Mark as Complete
      </Button>
    </div>
  )
}
