import clsx from "clsx"

import { Skeleton } from "@material-ui/lab"

import { ReactComponent as PhlebotomyIcon } from "app/assets/icons/phlebotomists-red.svg"
import { ReactComponent as PriceTagIcon } from "app/assets/icons/price-tag-dark.svg"
import BodyText from "app/components/design-system/BodyText"
import InfoTextTooltip from "app/components/design-system/InfoTextTooltip"
import { QUEST_COMPANY_KEYS } from "app/constants"
import { primaryColor } from "app/theme"
import { colors } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"
import { BiomarkerIdentifier } from "types/biomarker"

import { Panel } from "../types"

const useStyles = makeAppStyles((theme) => ({
  priceContainer: {
    width: 125,
    [theme.breakpoints.down("sm")]: {
      width: 100,
    },
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
  },
  priceTagIcon: {
    marginRight: 4,
    marginTop: 4.5,
    width: 12,
    height: 12,
  },
  infoIcon: {
    width: 12,
    height: 12,
    marginLeft: 4,
    marginTop: 4.5,
    display: "flex",
    alignItems: "center",
    fontWeight: 100,
  },
  tooltipText: {
    textAlign: "left",
    fontWeight: 400,
  },
  tooltipTextSection: {
    marginBottom: 8,
    width: "100%",
    display: "flex",
  },
  tooltipTextSectionCost: {
    textAlign: "right",
    flex: 1,
  },
  phlebotomyFeeText: {
    textAlign: "center",
    fontWeight: 400,
    fontSize: 13,
    whiteSpace: "nowrap",
  },
  phlebotomyIcon: {
    marginRight: 4,
    marginLeft: 4,
  },
  separator: {
    borderBottom: `1px solid ${colors.blueGray[200]}`,
    marginBottom: 8,
    width: "100%",
  },
}))

export default function PanelItemPricing({
  panel,
  combinedSelectedBiomarkerIdentifiers,
}: {
  panel?: Panel
  combinedSelectedBiomarkerIdentifiers: BiomarkerIdentifier[]
}) {
  const classes = useStyles()

  // Return skeleton if panel is not loaded yet
  if (!panel) {
    return <Skeleton className={classes.priceContainer} />
  }

  const shippingFee = panel.pricing.additional_fees.find(
    (fee) => fee.name === "Shipping"
  )

  const otherAdditionalFees = panel.pricing.additional_fees.filter(
    (fee) => fee.name !== "Shipping"
  )

  const showPricingWithinTooltip = !QUEST_COMPANY_KEYS.includes(
    panel.lab_company.key
  )

  return (
    <div className={classes.priceContainer}>
      <div className={classes.priceContainer}>
        <PriceTagIcon viewBox="0 0 17 17" className={classes.priceTagIcon} />
        <BodyText weight="semibold">${panel.pricing.price}</BodyText>
        <InfoTextTooltip iconFill={primaryColor} className={classes.infoIcon}>
          <div className={classes.tooltipText}>
            <BodyText className={classes.tooltipTextSection}>
              This is the price including all fees{" "}
              {!panel?.pricing.phlebotomy_fee_included &&
                "(except phlebotomy) "}
              for this basic blood panel from {panel.lab_company.name}. It
              includes {panel.biomarkers.length} of the{" "}
              {combinedSelectedBiomarkerIdentifiers.length} biomarkers you
              selected.
            </BodyText>
            {showPricingWithinTooltip && (
              <>
                <div className={classes.separator} />
                <TooltipListItem
                  label="Test Price without Fees"
                  price={panel.pricing.price_before_fees}
                />
                {panel?.pricing.phlebotomy_fee_included && (
                  <TooltipListItem
                    label="Included Phlebotomy"
                    price={panel.pricing.price_phlebotomy_fees}
                  />
                )}
                {otherAdditionalFees.map((fee) => (
                  <TooltipListItem
                    key={fee.name}
                    label={fee.name}
                    price={fee.price}
                  />
                ))}
                <TooltipListItem
                  label="Shipping Fee"
                  price={shippingFee?.price ? shippingFee?.price : "0.00"}
                />
                <div className={classes.separator} />
                <TooltipListItem
                  label="Total Test Price"
                  price={panel.pricing.price}
                  weight={"semibold"}
                />
              </>
            )}
          </div>
        </InfoTextTooltip>
      </div>
      <span className={clsx(classes.priceContainer, classes.phlebotomyFeeText)}>
        {panel?.pricing.phlebotomy_fee_included ? (
          <span>
            Includes
            <PhlebotomyIcon className={classes.phlebotomyIcon} />
            fee
          </span>
        ) : (
          <span>
            <PhlebotomyIcon className={classes.phlebotomyIcon} />
            fee not included
          </span>
        )}
      </span>
    </div>
  )
}

function TooltipListItem({
  label,
  price,
  weight,
}: {
  label: string
  price?: string | number
  weight?: "regular" | "semibold"
}) {
  const classes = useStyles()

  return (
    <BodyText weight={weight} className={classes.tooltipTextSection}>
      <span>{label}:</span>
      {price && (
        <span className={classes.tooltipTextSectionCost}>${price}</span>
      )}
    </BodyText>
  )
}
