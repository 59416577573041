import * as reduxModule from "redux"
import { applyMiddleware, compose, createStore, Store, Reducer } from "redux"
import thunk from "redux-thunk"

import { isProduction } from "app/settings"

import createReducer from "./reducers"

/*
Fix for Firefox redux dev tools extension
https://github.com/zalmoxisus/redux-devtools-instrument/pull/19#issuecomment-400637274
 */
reduxModule.__DO_NOT_USE__ActionTypes.REPLACE = "@@redux/INIT"

const composeEnhancers =
  !isProduction &&
  typeof window === "object" &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
      })
    : compose

export interface RupaStore extends Store {
  asyncReducers: { [key: string]: Reducer<any, any> }
  injectReducer: (key: string, reducer: Reducer<any, any>) => void
}

export default function configureStore(
  preloadedState?: {},
  additionalReducers?: { [key: string]: Reducer<any, any> }
): RupaStore {
  const enhancer = composeEnhancers(applyMiddleware(thunk))

  const store = createStore(
    createReducer(additionalReducers),
    preloadedState,
    enhancer
  ) as RupaStore

  // keep track of asynchronously injected reducers
  store.asyncReducers = {}

  // add function to asynchronously inject reducers
  store.injectReducer = (key, reducer) => {
    if (store.asyncReducers[key]) {
      return
    }
    store.asyncReducers[key] = reducer
    store.replaceReducer(
      createReducer({ ...additionalReducers, ...store.asyncReducers })
    )
  }

  return store
}
