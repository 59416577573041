import React, { useState } from "react"

import { faArrowDownToSquare } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import RupaLoadingButton, {
  RupaLoadingButtonProps,
} from "app/components/RupaLoadingButton"
import useFeatureFlag from "app/hooks/use-feature-flag"
import useAppSelector from "app/hooks/useAppSelector"
import useResultsUploadPdfModal from "app/main/results-upload/modals/ResultsUploadPdfModal"
import { FeatureFlag } from "app/providers/FeatureFlagProvider"

import useResultsUploadModal from "./results-upload-modal/ResultsUploadModal"
import { useResultsUpload } from "./results-upload-modal/hooks/use-results-upload"

/**
 * This Button shows modals conditionally while we are in an in-between state with feature flags
 * TODO: PROD-2332 flag removal
 */

interface Props extends Omit<RupaLoadingButtonProps, "loading" | "children"> {
  patientId: string
  existingUserResultId?: string
  existingOrderedResultId?: string
  buttonText?: string
  children?: React.ReactNode
  onClick?: () => void
  onClose?: () => void
}

export const ResultsUploadButton: React.FC<Props> = ({
  patientId,
  existingUserResultId,
  existingOrderedResultId,
  buttonText = "Import Results",
  children,
  onClick,
  onClose,
  ...buttonProps
}) => {
  const [loading, setLoading] = useState(false)
  const [simultaneousUploadsEnabled, flagLoading] = useFeatureFlag(
    FeatureFlag.SimultaneousResultsUploads
  )
  const resultsUploadModal = useResultsUploadModal()
  const patient = useAppSelector(({ patient }) => patient.patient)
  const { createAndInvokeFromOrderedResult } = useResultsUpload({
    patientId,
    userId: patient?.user.id,
  })

  const resultsUploadModalLegacy = useResultsUploadPdfModal()

  const showLegacyModal = () => {
    resultsUploadModalLegacy.show({
      onClose: () => {
        resultsUploadModalLegacy.remove()
        onClose?.()
      },
      patientId,
      existingUserResultId,
      existingOrderedResultId: existingUserResultId
        ? undefined
        : existingOrderedResultId,
    })
  }

  const uploadExistingOrderedResult = async (orderedResultId: string) => {
    setLoading(true)
    await createAndInvokeFromOrderedResult(orderedResultId)
    setLoading(false)
  }

  const showModalOrCreateUserResult = async () => {
    if (simultaneousUploadsEnabled) {
      if (existingUserResultId) {
        showLegacyModal()
      } else if (existingOrderedResultId) {
        uploadExistingOrderedResult(existingOrderedResultId)
        return
      } else {
        resultsUploadModal.show({ patientId, userId: patient?.user.id })
        return
      }
    }

    // Fallthrough to legacy modal
    showLegacyModal()
  }

  const onButtonClick = async () => {
    if (flagLoading || loading) return

    showModalOrCreateUserResult()
    onClick?.()
  }

  return (
    <RupaLoadingButton
      variant={null}
      className="w-full h-auto text-[15px] text-primary fill-primary hover:bg-slate-100 py-1 px-2"
      disabled={flagLoading}
      onClick={onButtonClick}
      loading={loading}
      {...buttonProps}
    >
      {children ?? (
        <>
          <FontAwesomeIcon className="mr-2" icon={faArrowDownToSquare} />{" "}
          {buttonText}
        </>
      )}
    </RupaLoadingButton>
  )
}
