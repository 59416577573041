import { useState } from "react"

import { matches } from "lodash"
import { useDebounce } from "react-use"

import useEventCallback from "app/hooks/use-event-callback"
import usePatchInterpretation from "app/results-summary/hooks/use-patch-interpretation"
import { UseResultsVisualizerResourcesHook } from "app/results-summary/hooks/use-results-summary-resources"
import { ResultsInterpretationPatch } from "types/results-interpretation"

/**
 * The timeout of the patch request debounce.
 */
const DEBOUNCE_TIMEOUT = 600

/**
 * The max error count per draft used to avoid infinite loops.
 */
const MAX_ERROR_PER_DRAFT = 3

/**
 * Hook used to provide a debounced draft state for patching the interpretation.
 *
 * @param interpretationSwr the interpretation swr
 * @returns the draft controls
 */
export default function useInterpretationDraft(
  interpretationSwr: UseResultsVisualizerResourcesHook["interpretationSwr"]
) {
  const [errorCount, setErrorCount] = useState(0)
  const [isPatching, setIsPatching] = useState(false)
  const [draft, setDraft] = useState<ResultsInterpretationPatch>({})
  const isDirty = !matches(draft)(interpretationSwr.data)

  const updateDraft = useEventCallback(
    (nextPatch: ResultsInterpretationPatch) => {
      // Clear error count when a change is made, allowing another chance to patch.
      setErrorCount(0)

      setDraft((prevPatch) => ({
        attributes: {
          ...prevPatch.attributes,
          ...nextPatch.attributes,
        },
        relationships: {
          ...prevPatch.relationships,
          ...nextPatch.relationships,
        },
      }))
    }
  )

  const patchInterpretation = usePatchInterpretation(interpretationSwr)

  useDebounce(
    () => {
      async function sendPatchRequest() {
        if (!isDirty || isPatching || errorCount >= MAX_ERROR_PER_DRAFT) {
          return
        }

        setIsPatching(true)

        try {
          await patchInterpretation(draft)

          setErrorCount(0)

          // TODO: show "Saved" message https://app.asana.com/0/1203575966458036/1203793102520701/f
        } catch {
          setErrorCount((count) => count + 1)
        } finally {
          setIsPatching(false)
        }
      }

      sendPatchRequest()
    },
    DEBOUNCE_TIMEOUT,
    [draft, isDirty, isPatching]
  )

  return {
    isDirty,
    isPatching,
    updateDraft,
  }
}

export type UseInterpretationDraftHook = ReturnType<
  typeof useInterpretationDraft
>
