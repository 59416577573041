import * as React from "react"

import moment from "moment"

import { Theme, useMediaQuery } from "@material-ui/core"
import { Skeleton } from "@material-ui/lab"

import { ReactComponent as FastingIcon } from "app/assets/icons/rupa-blood-dashboards/fasted.svg"
import { ReactComponent as PatientUserIcon } from "app/assets/icons/rupa-blood-dashboards/patient-user-icon.svg"
import { ReactComponent as PractitionerUserIcon } from "app/assets/icons/rupa-blood-dashboards/practitioner-user-icon.svg"
import { ReactComponent as QuestionRoundedSquareIcon } from "app/assets/icons/rupa-blood-dashboards/question-rounded-square.svg"
import { ReactComponent as CalendarIcon } from "app/assets/icons/schedule-dark.svg"
import { ReactComponent as DownloadCircleIcon } from "app/assets/images/download-blue.svg"
import BodyText from "app/components/design-system/BodyText"
import DesignSystemButton from "app/components/design-system/Button"
import useFeatureFlag, { FeatureFlag } from "app/hooks/use-feature-flag"
import BloodLabDashboardsMissingWarning from "app/main/blood-lab-dashboards/BloodLabDashboardsPatientPreview/BloodLabDashboardsMissingWarning"
import ResultsPdfPrintButton from "app/main/blood-lab-dashboards/BloodLabDashboardsPdfExport/ResultsPdfPrintButton"
import { ResultsPdfPayload } from "app/main/blood-lab-dashboards/BloodLabDashboardsPdfExport/use-bundle-results-pdf-payload"
import {
  bloodLabDashboardEvents,
  patientDownloadOriginalReportSource,
} from "app/patient-portal/blood-lab-dashboard/constants"
import useAboutBloodLabsDashboardsModal from "app/patient-portal/blood-lab-dashboard/modals/use-about-blood-labs-dashboards-modal"
import { PatientPortalPatient } from "app/patient-portal/types"
import { trackEventWithProperties } from "app/services/segment.typed"
import { colors, navy } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"
import { Biomarker } from "types/biomarker"
import { OrderedResult } from "types/ordered-result"
import { Patient } from "types/patient"

const useStyles = makeAppStyles<{ clinicLogo?: string }>((theme: Theme) => ({
  container: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: 20,
  },
  patientTitle: {
    fontSize: 45,
    fontWeight: 700,
    color: navy,
    lineHeight: "135%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-end",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column-reverse",
      gap: 20,
      alignItems: "flex-start",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 22,
    },
  },
  subHeadingContainer: {
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      gap: 20,
    },
  },
  subHeadingDetails: {
    display: "flex",
    gap: 16,
    flexWrap: "wrap",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      gap: 16,
    },
  },
  subHeadingActions: {
    display: "flex",
    gap: 12,
    alignItems: "center",
    flexWrap: "wrap",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "flex-start",
      gap: 16,
      "& .MuiButton-startIcon": {
        display: "flex",
        width: 25,
        justifyContent: "center",
      },
    },
  },
  subHeadingDetail: {
    display: "flex",
    gap: 2,
    alignItems: "center",
  },
  actionButtonIcon: {
    position: "relative",
    top: -1,
    left: 3,
  },
  verticalDivider: {
    width: 1,
    height: 26,
    background: colors.blueGray[300],
  },
  loadingContainer: {
    display: "flex",
    flexDirection: "column",
    gap: 14,
  },
  logo: (props) => ({
    width: 224,
    height: 68,
    backgroundImage: `url(${props.clinicLogo})`,
    mixBlendMode: "darken",
    backgroundColor: "transparent",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    backgroundPositionX: "right",
    [theme.breakpoints.down("md")]: {
      backgroundPositionX: "left",
    },
  }),
  calendarIcon: {
    marginRight: 5,
    marginLeft: 5,
    marginTop: 5.5,
    marginBottom: 5.5,
  },
  actionButton: {
    height: 25,
  },
}))

interface SubHeadingDetailProps {
  icon: React.ReactNode
  title: string
}

const SubHeadingDetail = ({ icon, title }: SubHeadingDetailProps) => {
  const classes = useStyles({})
  return (
    <div className={classes.subHeadingDetail}>
      {icon}
      <BodyText size="sm">{title}</BodyText>
    </div>
  )
}

interface Props {
  orderedResult?: OrderedResult
  patient?: Patient | PatientPortalPatient
  clinicLogoUrl?: string
  practitionerTitledName: string
  missingBiomarkers: Biomarker[]
  isLoading: boolean
  isPatientPreview?: boolean
  pdfPayload?: ResultsPdfPayload
}

const BloodLabDashboardsPatientHero = ({
  orderedResult,
  patient,
  missingBiomarkers,
  clinicLogoUrl,
  practitionerTitledName,
  isLoading,
  isPatientPreview,
  pdfPayload,
}: Props) => {
  const aboutBloodDashboardsModal = useAboutBloodLabsDashboardsModal()

  const [bloodLabDashboardsPdfPrintEnabled] = useFeatureFlag(
    FeatureFlag.BloodLabDashboardsPdfPrint
  )

  const classes = useStyles({
    clinicLogo: clinicLogoUrl || "",
  })

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"))

  const onDownloadOriginalReport = () => {
    if (!orderedResult?.attributes.result_file) return

    if (!isPatientPreview) {
      trackEventWithProperties(
        bloodLabDashboardEvents.PATIENT_DOWNLOAD_ORIGINAL_REPORT,
        {
          source: patientDownloadOriginalReportSource.BLOOD_LAB_DASHBOARD,
          patient: patient?.id,
          orderedResult: orderedResult?.id,
        }
      )
    }

    window.open(orderedResult?.attributes?.result_file, "_blank")
  }

  if (isLoading) {
    return (
      <div className={classes.loadingContainer}>
        <Skeleton animation="wave" height={80} width="30%" />

        <Skeleton animation="wave" height={30} width="45%" />
      </div>
    )
  }

  return (
    <div className={classes.container}>
      <div className={classes.patientTitle}>
        <div>{patient?.attributes.first_name}'s Blood Labs</div>
        {clinicLogoUrl && <div className={classes.logo}></div>}
      </div>
      <div className={classes.subHeadingContainer}>
        <div className={classes.subHeadingDetails}>
          <SubHeadingDetail
            icon={<PatientUserIcon />}
            title={`For ${patient?.attributes.full_name}`}
          />
          <SubHeadingDetail
            icon={<PractitionerUserIcon />}
            title={`By ${practitionerTitledName}`}
          />
          {orderedResult?.attributes.date_samples_observed && (
            <SubHeadingDetail
              icon={
                <CalendarIcon
                  className={classes.calendarIcon}
                  viewBox="0 0 15 16"
                />
              }
              title={`Collected ${moment(
                orderedResult?.attributes.date_samples_observed
              ).format("MMMM D, YYYY")}`}
            />
          )}
          {orderedResult?.attributes.requires_fasting && (
            <SubHeadingDetail icon={<FastingIcon />} title="Fasted" />
          )}
        </div>
        <div className={classes.subHeadingActions}>
          <DesignSystemButton
            color="text"
            className={classes.actionButton}
            onClick={() =>
              aboutBloodDashboardsModal.show({
                title: "About Blood Lab Dashboards",
                showAboutTheDashboardSection: true,
                onClose: () => aboutBloodDashboardsModal.hide(),
              })
            }
            startIcon={
              <QuestionRoundedSquareIcon className={classes.actionButtonIcon} />
            }
            loading={false}
          >
            About This Dashboard
          </DesignSystemButton>

          {bloodLabDashboardsPdfPrintEnabled && pdfPayload && (
            <>
              {!isMobile && <div className={classes.verticalDivider} />}
              <div className={isMobile ? "ml-1" : ""}>
                <ResultsPdfPrintButton payload={pdfPayload} wide={isMobile} />
              </div>
            </>
          )}
          {!isMobile && <div className={classes.verticalDivider} />}
          <DesignSystemButton
            color="text"
            className={classes.actionButton}
            onClick={onDownloadOriginalReport}
            startIcon={
              <DownloadCircleIcon
                className={classes.actionButtonIcon}
                viewBox="0 -2 18 19"
              />
            }
            loading={false}
          >
            Original Report
          </DesignSystemButton>
        </div>
      </div>

      {Boolean(missingBiomarkers.length) && (
        <BloodLabDashboardsMissingWarning
          biomarkers={missingBiomarkers}
          resultFile={orderedResult?.attributes.result_file || ""}
        />
      )}
    </div>
  )
}

export default BloodLabDashboardsPatientHero
