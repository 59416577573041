import PatientFoodPlanListToolbarItem from "app/food-plans/components/PatientFoodPlanListToolbar/FoodPlanListItem"
import withIsFoodPlansFeature from "app/food-plans/components/withIsFoodPlansFeature"
import useDownloadFoodPlan from "app/food-plans/hooks/use-download-food-plan"
import useRedirectToEditFoodPlan from "app/food-plans/hooks/use-redirect-to-edit-food-plan"
import useEventCallback from "app/hooks/use-event-callback"
import useHandleApiError from "app/hooks/use-handle-api-error"
import useAppSelector from "app/hooks/useAppSelector"
import useCollectionSWR from "app/swr/hooks/use-collection-swr"
import { ResourceIdentifier } from "app/swr/types"
import resourceRequest from "app/swr/utils/resource-request"
import { colors } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"

const useStyles = makeAppStyles((theme) => ({
  root: {
    width: "100%",
    height: 80,
    overflow: "auto",
    display: "flex",
    flexFlow: "row nowrap",
    gap: theme.spacing(1.5),
    padding: theme.spacing(1.5, 3.0),
    borderTop: `1px solid ${colors.coolGray[200]}`,
  },
}))

function usePatientFoodPlanCollection() {
  const handleApiError = useHandleApiError()
  const legacyPatient = useAppSelector(({ patient }) => patient.patient)

  const { data: foodPlanIdentifiers, mutate: mutateCollection } =
    useCollectionSWR(
      legacyPatient ? "/food_plans/" : null,
      {
        include: ["latest_version"],
        params: {
          patient__id: legacyPatient.id,
        },
      },
      {
        revalidateOnMount: true,
      }
    )

  const onDeleteFoodPlan = useEventCallback(async (foodPlanId: string) => {
    try {
      await mutateCollection(
        async (previousData) => {
          if (!previousData) {
            return previousData
          }

          await resourceRequest({
            url: `/food_plans/${foodPlanId}/`,
            method: "delete",
          })

          return {
            data: previousData.data.filter(
              (identifier: ResourceIdentifier) => identifier.id !== foodPlanId
            ),
            meta: {
              count: previousData.meta?.count - 1,
            },
          }
        },
        {
          revalidate: false,
          throwOnError: true,
        }
      )
    } catch (error) {
      handleApiError(error)
    }
  })

  const { downloadFoodPlan, isDownloadingFoodPlan } = useDownloadFoodPlan()

  const redirectToEditFoodPlan = useRedirectToEditFoodPlan()
  const onEditFoodPlan = useEventCallback((foodPlanId: string) => {
    redirectToEditFoodPlan(legacyPatient.id, foodPlanId)
  })

  return {
    foodPlanIdentifiers,
    isDownloadingFoodPlan,
    onDeleteFoodPlan,
    onDownloadFoodPlan: downloadFoodPlan,
    onEditFoodPlan,
  }
}

export default withIsFoodPlansFeature(function PatientFoodPlanListToolbar() {
  const classes = useStyles()
  const {
    foodPlanIdentifiers,
    isDownloadingFoodPlan,
    onDeleteFoodPlan,
    onDownloadFoodPlan,
    onEditFoodPlan,
  } = usePatientFoodPlanCollection()

  if (!foodPlanIdentifiers?.length) {
    return null
  }

  return (
    <div className={classes.root}>
      {foodPlanIdentifiers.map((identifier) => (
        <PatientFoodPlanListToolbarItem
          key={identifier.id}
          identifier={identifier}
          isDownloading={isDownloadingFoodPlan}
          onDelete={() => onDeleteFoodPlan(identifier.id)}
          onDownload={() => onDownloadFoodPlan(identifier.id)}
          onEdit={() => onEditFoodPlan(identifier.id)}
        />
      ))}
    </div>
  )
})
