import React from "react"

import moment from "moment"

import { ReactComponent as FoodPlanWhite } from "app/assets/icons/food-plan-white.svg"
import { ReactComponent as LabCompanyIcon } from "app/assets/icons/lab-company.svg"
import { ReactComponent as PractitionerUserIcon } from "app/assets/icons/rupa-blood-dashboards/practitioner-user-icon.svg"
import { ReactComponent as ScheduleDark } from "app/assets/icons/schedule-dark.svg"
import { PatientPortalPractitioner } from "app/patient-portal/types"
import { navy } from "app/theme"
import { Practitioner } from "types/practitioner"

export const PdfHeader: React.FC<{
  practitioner?: Practitioner | PatientPortalPractitioner
  sampleCollectionDate?: string | null
  isFasted?: boolean
  labCompanyName?: string
  patientFirstName?: string
  clinicLogoUrl?: string
}> = ({
  practitioner,
  sampleCollectionDate,
  isFasted,
  labCompanyName,
  patientFirstName,
  clinicLogoUrl,
}) => {
  return (
    <div className="mr-2">
      <PdfHeaderTopSection
        patientFirstName={patientFirstName}
        clinicLogoUrl={clinicLogoUrl}
      />
      <PdfHeaderInfoSection
        practitioner={practitioner}
        sampleCollectionDate={sampleCollectionDate}
        isFasted={isFasted}
        labCompanyName={labCompanyName}
      />
    </div>
  )
}

const InfoItem = ({ children }) => {
  return (
    <div className="flex items-center text-navy font-sans font-normal leading-[135%] whitespace-nowrap mr-4">
      {children}
    </div>
  )
}

const PdfHeaderInfoSection: React.FC<{
  practitioner?: Practitioner | PatientPortalPractitioner
  sampleCollectionDate?: string | null
  isFasted?: boolean
  labCompanyName?: string
}> = ({ practitioner, sampleCollectionDate, isFasted, labCompanyName }) => {
  return (
    <div className="flex text-sm">
      {practitioner && practitioner.attributes.titled_full_name && (
        <InfoItem>
          <PractitionerUserIcon fill={navy} />
          By {practitioner.attributes.titled_full_name}
        </InfoItem>
      )}
      {sampleCollectionDate && (
        <InfoItem>
          <ScheduleDark className={"mr-1"} fill={navy} />
          Collected {moment(sampleCollectionDate).format("MMMM D, YYYY")}
        </InfoItem>
      )}
      {isFasted && (
        <InfoItem>
          <FoodPlanWhite fill={navy} className={"mr-1"} />
          Fasted
        </InfoItem>
      )}
      {labCompanyName && (
        <InfoItem>
          <LabCompanyIcon fill={navy} className={"mr-1"} />
          {labCompanyName}
        </InfoItem>
      )}
    </div>
  )
}

const PdfHeaderTopSection: React.FC<{
  patientFirstName?: string
  clinicLogoUrl?: string
}> = ({ patientFirstName, clinicLogoUrl }) => {
  return (
    <div className="flex items-center">
      <div className="text-navy font-inter text-3xl font-bold leading-[51.3px] tracking-[-1px]">
        {patientFirstName ? `${patientFirstName}'s Lab Results` : "Lab Results"}
      </div>
      {clinicLogoUrl && (
        <img
          src={clinicLogoUrl}
          alt="Clinic Logo"
          className="ml-auto h-14 w-auto object-contain max-w-[200px]"
        />
      )}
    </div>
  )
}
