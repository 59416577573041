import { useMediaQuery } from "@material-ui/core"

import Button from "app/components/design-system/Button"
import DisplayText from "app/components/design-system/DisplayText"
import { colors, navy, Theme } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"

const styles = (theme: Theme) => ({
  supportCenterContainer: {
    background: colors.blueGray[50],
    borderRadius: 12,
    display: "flex",
    "flex-direction": "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "40px 16px",
    width: "100%",
  },
  visitSupportCenterButton: {
    margin: "24px 0 0 0",
  },
  emailUsLink: {
    color: navy,
    textDecoration: "underline !important",
    fontWeight: 600,
  },
})

const useStyles = makeAppStyles(styles)

const StorefrontSupport = () => {
  const classes = useStyles()
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"))

  return (
    <div className={classes.supportCenterContainer}>
      <DisplayText size="xl">Have any questions?</DisplayText>
      <Button
        className={classes.visitSupportCenterButton}
        href="mailto:support@rupahealth.com"
        color="secondary"
        target="_blank"
        size="medium"
        fullWidth={isMobile}
      >
        Email Us
      </Button>
    </div>
  )
}

export default StorefrontSupport
