import { useMemo } from "react"

import { styled, Dialog, DialogTitle, Typography } from "@material-ui/core"
import IconButton from "@material-ui/core/IconButton"

import CrossIcon from "app/assets/icons/blue-cross.svg"
import Button from "app/components/design-system/Button"
import { ModalContent } from "app/components/modals"
import { Order } from "app/types"
import { checkHasAvailableRequisitionForms } from "app/utils/order-utils"

import HandfilledRequisitionNextSteps from "./HandfilledRequisitionNextSteps"

export interface ViewHandfilledRequisitionsModalProps {
  loadInstantRequisitions: () => Promise<void>
  open: boolean
  onClose: () => void
  order: Order
}

/**
 * Renders a modal for viewing the next steps when handling an order with hand-filled requisition forms.
 */
const ViewHandfilledRequisitionsModal = ({
  loadInstantRequisitions,
  open,
  onClose,
  order,
}: ViewHandfilledRequisitionsModalProps) => {
  const hasAvailableRequisitionForms = useMemo(() => {
    return checkHasAvailableRequisitionForms(order)
  }, [order])
  return (
    <Dialog
      aria-labelledby="view-print-requisitions-title"
      maxWidth={false}
      open={open}
      onClose={onClose}
      PaperProps={{
        style: {
          maxWidth: 383,
          margin: 15,
          borderRadius: 7,
        },
      }}
    >
      <DialogHeader
        id="view-print-requisitions-title"
        onClose={onClose}
        title="Some Labs Don't Support Automatic Requisitions"
      />
      <StyledModalContent>
        <HandfilledRequisitionNextSteps order={order} />
        {hasAvailableRequisitionForms && (
          <ViewAvailableRequisitionsButton onClick={loadInstantRequisitions}>
            View Available Requisitions
          </ViewAvailableRequisitionsButton>
        )}
      </StyledModalContent>
    </Dialog>
  )
}

const DialogHeader = ({ onClose, title, ...passthroughProps }) => (
  <StyledDialogTitle
    className="fs-unmask"
    disableTypography
    {...passthroughProps}
  >
    <DialogTitleHeading variant="h4">{title}</DialogTitleHeading>
    {onClose ? (
      <CloseButton aria-label="close" onClick={onClose}>
        <img src={CrossIcon} alt="Cross" />
      </CloseButton>
    ) : null}
  </StyledDialogTitle>
)

const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  alignItems: "center",
  display: "flex",
  paddingTop: theme.spacing(3),
  paddingBottom: theme.spacing(2),
  paddingRight: theme.spacing(1),
}))

const DialogTitleHeading = styled(Typography)(({ theme }) => ({
  fontSize: 19,
  fontWeight: 600,
  flexGrow: 1,
  lineHeight: 1.5,
  paddingRight: theme.spacing(3),
}))

const CloseButton = styled(IconButton)(({ theme }) => ({
  marginTop: -theme.spacing(4),
  padding: theme.spacing(2),
}))

const StyledModalContent = styled(ModalContent)(({ theme }) => ({
  backgroundColor: "white",
  borderTop: "none",
  padding: theme.spacing(3),
  paddingTop: 0,
}))

const ViewAvailableRequisitionsButton = styled((props) => (
  <Button color="primary" size="small" {...props} />
))(({ theme }) => ({
  marginTop: theme.spacing(3),
}))

export default ViewHandfilledRequisitionsModal
