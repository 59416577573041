import OrderStatus from "app/components/OrderStatus"
import { IOKOrderStatusUnion } from "app/constants.typed"

interface Props {
  status: IOKOrderStatusUnion
}
export default function StatusTableCell({ status }: Props) {
  return (
    <OrderStatus
      status={status}
      total_completed_ordered_tests={0}
      total_ordered_tests={1}
    />
  )
}
