import FieldLabel, { FieldLabelProps } from "./FieldLabel"
import SelectInput, { SelectInputProps } from "./SelectInput"

export type SelectFieldProps = SelectInputProps &
  FieldLabelProps & {
    labelClassName?: string
    inputClassName?: string
    alternateIcon?: React.ReactNode
  }

const SelectField = ({
  className,
  infoText,
  inputProps,
  inputClassName,
  label,
  labelClassName,
  required,
  alternateIcon,
  beforeLabelIcon,
  ...selectInputProps
}: SelectFieldProps) => {
  return (
    <div className={className}>
      {Boolean(label) && (
        <FieldLabel
          className={labelClassName}
          label={label}
          infoText={infoText}
          required={required}
          alternateIcon={alternateIcon}
          beforeLabelIcon={beforeLabelIcon}
        />
      )}
      <SelectInput
        className={inputClassName}
        inputProps={{
          "aria-label": typeof label === "string" ? label : undefined,
          ...inputProps,
        }}
        required={required}
        {...selectInputProps}
      />
    </div>
  )
}

export default SelectField
