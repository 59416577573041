import { useMediaQuery, Theme } from "@material-ui/core"

import { UserPaths } from "app/Routes"
import CogIconGray from "app/assets/images/cog-icon-gray.svg"
import PageToolbar from "app/components/PageToolbar"
import useFeatureFlag, { FeatureFlag } from "app/hooks/use-feature-flag"

import SideNav from "../SideNav"
import NotesToClientsSnippetsContainer from "./NotesToClientsSnippetsContainer"
import ResultSummarySnippetsContainer from "./ResultSummarySnippetsContainer"

const SavedTextSnippetsContainer = () => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down(768))

  const [isResultSummarySnippetsEnabled] = useFeatureFlag(
    FeatureFlag.RefactoredSnippets
  )

  return (
    <>
      {isMobile && (
        <PageToolbar
          breadcrumbs={[
            {
              to: UserPaths.SETTINGS,
              icon: CogIconGray,
              label: "Settings",
            },

            {
              label: "Saved Text Snippets",
            },
          ]}
        />
      )}
      <div className="flex w-full flex-wrap">
        {!isMobile && (
          <div className="flex-grow-0 max-w-[25%] basis-[25%]">
            <SideNav />
          </div>
        )}
        <div className="flex-grow-0 md:max-w-[75%] md:basis-[75%] bg-gray-50">
          {!isMobile && (
            <PageToolbar
              title="Saved Text Snippets"
              greyBackground={true}
              fullBorder={true}
            />
          )}

          <div className="w-full p-6 flex flex-col gap-6 min-h-screen">
            <NotesToClientsSnippetsContainer />
            {isResultSummarySnippetsEnabled && (
              <>
                <div className="w-full h-[1px] bg-slate-200"></div>
                <ResultSummarySnippetsContainer />
              </>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default SavedTextSnippetsContainer
