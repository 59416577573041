export const CONNECTION_TYPE_WEBSITE_URL = "connection_type_website_url"
export const CONNECTION_TYPE_EMAIL = "connection_type_email"
export const CONNECTION_TYPE_PHONE_NUMBER = "connection_type_phone_number"
export const INSURANCE_INSURANCE_ACCEPTED = "insurance_insurance_accepted"

export type ConnectionType =
  | typeof CONNECTION_TYPE_WEBSITE_URL
  | typeof CONNECTION_TYPE_EMAIL
  | typeof CONNECTION_TYPE_PHONE_NUMBER

export type InsuranceType = typeof INSURANCE_INSURANCE_ACCEPTED

export const getConnectionType = (
  optionId,
  connectionTypeOptions
): ConnectionType | null => {
  const connectionTypeOption = connectionTypeOptions.find(
    (option) => option.id === optionId
  )

  let type: ConnectionType | null = null
  const fieldKey = connectionTypeOption?.attributes?.key

  if (!fieldKey) {
    return type
  }

  return fieldKey
}

const URL_REGEX =
  /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/

export const urlPassesRegex = (url: string): boolean => {
  return !!url && URL_REGEX.test(url.toLowerCase())
}

const EMAIL_REGEX = /^[\w.%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/

export const emailPassesRegex = (email: string): boolean => {
  return !!email && EMAIL_REGEX.test(email.toLowerCase())
}
