import { useMemo, useRef, useState } from "react"

import { cn } from "@rupahealth/design"

import { labTestLocation } from "app/types"
import partition from "lodash/partition"

import Filter from "./Filter"
import FilterButton from "./FilterButton"
import FiltersModal from "./FiltersModal"
import useFilterConfig from "./hooks/use-filter-config"
import { useVisibleFilters } from "./hooks/use-visible-filters"
import { FiltersMap, OnResetFilter, OnSelectFilterOption } from "./types"

interface Props {
  // A map of the filters from the filter name to the selected options
  // for the filter.
  filtersMap: FiltersMap
  location: labTestLocation
  onSelectFilterOption: OnSelectFilterOption
  onResetFilter: OnResetFilter
}

const Filters = ({
  filtersMap,
  location,
  onSelectFilterOption,
  onResetFilter,
}: Props) => {
  const filtersContainerRef = useRef<HTMLDivElement>(null)

  const [isFiltersModalOpen, setIsFiltersModalOpen] = useState(false)
  const filterConfigs = useFilterConfig(location)
  const filterNames = useMemo(() => {
    return new Set(filterConfigs?.map((f) => f.filterName) ?? [])
  }, [filterConfigs])

  const visibleFilters = useVisibleFilters(filterNames, filtersContainerRef)
  const [visibleConfigs, hiddenConfigs] = useMemo(() => {
    return partition(filterConfigs, (f) => visibleFilters.has(f.filterName))
  }, [filterConfigs, visibleFilters])

  const hiddenFiltersCount = useMemo(() => {
    return hiddenConfigs.reduce(
      (acc, config) => acc + filtersMap[config.filterName].size,
      0
    )
  }, [hiddenConfigs, filtersMap])

  if (!filterConfigs) {
    return null
  }

  return (
    <div className="flex items-center gap-2" ref={filtersContainerRef}>
      {visibleConfigs.map((filterConfig) => (
        <div key={filterConfig.filterName} className="flex-1">
          <Filter
            filterConfig={filterConfig}
            filtersMap={filtersMap}
            onResetFilter={onResetFilter}
            onSelectFilterOption={onSelectFilterOption}
          />
        </div>
      ))}

      {!!hiddenConfigs.length && (
        <div className={cn({ "flex-1": !visibleConfigs.length })}>
          <FilterButton
            // We don't want `All` to be rendered when there's no count (which
            // we do want for the other filter buttons), so we pass undefined.
            count={hiddenFiltersCount ? hiddenFiltersCount : undefined}
            showChevron={false}
            className="justify-center px-4 gap-2"
            label={visibleConfigs.length ? "More Filters" : "Filters"}
            filterName="moreFilters"
            onClick={() => setIsFiltersModalOpen(true)}
          />
        </div>
      )}

      <FiltersModal
        isOpen={isFiltersModalOpen}
        onClose={() => setIsFiltersModalOpen(false)}
        filtersMap={filtersMap}
        filterConfigs={filterConfigs}
        onResetFilter={onResetFilter}
        onSelectFilterOption={onSelectFilterOption}
      />
    </div>
  )
}

export default Filters
