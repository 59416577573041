import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { useDispatch } from "react-redux"

import { groupBy, sortBy } from "lodash"
import { ErrorBoundary } from "react-error-boundary"
import ReactMapGL, {
  NavigationControl,
  FlyToInterpolator,
  MapRef,
} from "react-map-gl"
import Geocoder from "react-map-gl-geocoder"
import { useDebounce } from "react-use"

import geoViewport from "@mapbox/geo-viewport"
import { makeStyles, useMediaQuery } from "@material-ui/core"

import { API } from "app/api"
import useAppSelector from "app/hooks/useAppSelector"
import PhlebotomistMapError from "app/main/phlebotomy-map/PhlebotomistMapError"
import PhlebotomistMapListView from "app/main/phlebotomy-map/PhlebotomistMapListView"
import PhlebotomistMapPopup from "app/main/phlebotomy-map/PhlebotomistMapPopup"
import PhlebotomistMapSearchMarker from "app/main/phlebotomy-map/PhlebotomistMapSearchMarker"
import { MAPBOX_TOKEN } from "app/settings"
import withReducer from "app/store/withReducer"
import { PhlebotomistFeature } from "app/types"
import { handleApiError } from "app/utils"
import "mapbox-gl/dist/mapbox-gl.css"

import MemoizedPhlebotomistMapMarkers from "./PhlebotomistMapMarkers"
import { PhelMapLocationType } from "./constants"
import * as Actions from "./phlebotomist-map.actions"
import reducer from "./phlebotomist-map.reducer"
import { selectSelectedPhlebotomistFeature } from "./phlebotomist-map.selectors"

const MAPBOX_TILESET_SIZE = 512
const MAX_ZOOM = 16
const MIN_ZOOM = 3
const DEFAULT_ZOOM_LEVEL = 11

const DEFAULT_VIEWPORT = {
  width: 1000,
  height: 1000,
  latitude: 37.8,
  longitude: -122.4,
  zoom: DEFAULT_ZOOM_LEVEL,
  bearing: 0,
  pitch: 0,
  transitionDuration: 1000,
  transitionInterpolator: new FlyToInterpolator(),
  transitionEasing: easeInOutCubic,
}

// Easing function sourced from https://easings.net/#easeInOutCubic
function easeInOutCubic(x: number): number {
  return x < 0.5 ? 4 * x * x * x : 1 - Math.pow(-2 * x + 2, 3) / 2
}

function setStartingViewport(practitioner) {
  let initial_viewport = DEFAULT_VIEWPORT
  if (practitioner.clinic?.location) {
    initial_viewport.latitude = Number(practitioner.clinic.location.latitude)
    initial_viewport.longitude = Number(practitioner.clinic.location.longitude)
  }
  return initial_viewport
}

function getCursor({ isHovering, isDragging }) {
  return isDragging ? "grabbing" : isHovering ? "pointer" : "default"
}

const useStyles = makeStyles((theme) => ({
  navControlStyle: {
    right: 10,
    bottom: 10,
    top: "auto",
    [theme.breakpoints.up("sm")]: {
      top: 10,
      bottom: "auto",
    },
  },
}))

/**
 * Mapbox map for displaying phlebotomist location data
 *
 * @component
 */
const PhlebotomistMap = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const practitioner = useAppSelector(({ practitioner }) => practitioner)
  const mapRef = useRef<MapRef>(null)
  const geocoderContainerRef = useRef<HTMLDivElement>(null)
  const selectedFeature = useAppSelector(selectSelectedPhlebotomistFeature)

  const [viewport, setViewport] = useState<typeof DEFAULT_VIEWPORT>(() =>
    setStartingViewport(practitioner)
  )
  const [currentSearch, setCurrentSearch] = useState<typeof DEFAULT_VIEWPORT>()
  const [isWaitingOnSearchResults, setIsWaitingOnSearchResults] =
    useState(false)
  const [isTransitioning, setIsTransitioning] = useState(false)
  const [isListOpen, setIsListOpen] = useState(true)
  const [searchQuery, setSearchQuery] = useState("")
  const [listPhlebs, setListPhlebs] = useState<PhlebotomistFeature[]>([])
  const [filteredMarkers, setFilteredMarkers] = useState<PhlebotomistFeature[]>(
    []
  )

  const flyToLocation = (longitude: number, latitude: number) => {
    setViewport((prevViewport) => ({
      ...prevViewport,
      longitude: longitude,
      latitude: latitude,
      zoom: DEFAULT_ZOOM_LEVEL,
      transitionDuration: 1000,
      transitionInterpolator: new FlyToInterpolator(),
      transitionEasing: easeInOutCubic,
    }))
  }

  const filterForPerformance = (
    features: PhlebotomistFeature[]
  ): PhlebotomistFeature[] => {
    // Only show the closest 250 phlebotomists. As users scroll, the closer ones will render. Backend returns these already sorted
    const newFeatures: PhlebotomistFeature[] = features.slice(0, 250)

    if (
      selectedFeature &&
      newFeatures.findIndex(
        (f) => f.properties.id === selectedFeature.properties.id
      ) < 0
    ) {
      newFeatures.push(selectedFeature)
    }

    return newFeatures
  }

  async function fetchPhlebotomistCollection(params?: {
    xmin: number
    ymin: number
    xmax: number
    ymax: number
    x?: number
    y?: number
    limit?: number
  }) {
    let data = {
      params,
    }
    try {
      const response = await API.Phlebotomist.getLocations(data)
      setIsWaitingOnSearchResults(false)

      const filteredFeatures = filterForPerformance(response.data.features)

      setFilteredMarkers(filteredFeatures)
      dispatch(Actions.receiveCollection(response.data.features))
      setListPhlebs(filterMobilePhlebsForList(response.data.features))
    } catch (error) {
      setIsWaitingOnSearchResults(false)
      dispatch(handleApiError(error))
    }
  }

  /**
   * Debounce effect used to fetch the next feature collection once as the viewport changes.
   */
  useDebounce(
    () => {
      if (!isTransitioning) {
        const size = Math.max(viewport.width, viewport.height)
        const [xmin, ymin, xmax, ymax] = geoViewport.bounds(
          [viewport.longitude, viewport.latitude],
          viewport.zoom,
          [size, size],
          MAPBOX_TILESET_SIZE
        )

        fetchPhlebotomistCollection({
          x: viewport?.longitude,
          y: viewport?.latitude,
          xmin,
          ymin,
          xmax,
          ymax,
        })
      }
    },
    400,
    [isTransitioning, viewport]
  )

  useEffect(() => {
    if (currentSearch) {
      dispatch(Actions.clearFeatures())
      dispatch(Actions.setSelectedFeatureId(undefined))
      setIsListOpen(true)
      setIsWaitingOnSearchResults(true)

      flyToLocation(currentSearch.longitude, currentSearch.latitude)
    }
  }, [currentSearch])

  const isMobile = useMediaQuery<any>((theme) => theme.breakpoints.down("sm"))

  useEffect(() => {
    if (selectedFeature) {
      if (isMobile) {
        // in mobile, we collapse the list upon feature selection
        setIsListOpen(false)
      }

      flyToLocation(
        selectedFeature.geometry.coordinates[0],
        selectedFeature.geometry.coordinates[1]
      )
    }
  }, [selectedFeature?.properties.id])

  // Order it from largest to smallest lat so lower points always render on top of higher points
  const featuresSortedByLatitude = useMemo(() => {
    return sortBy(filteredMarkers, [
      (feature) => -feature.geometry.coordinates[1],
    ])
  }, [filteredMarkers])

  const onMapViewportChange = useCallback((nextViewport) => {
    setViewport(nextViewport)
  }, [])

  const onGeocoderViewportChange = useCallback((nextViewport) => {
    nextViewport.zoom = DEFAULT_ZOOM_LEVEL
    setCurrentSearch(nextViewport)
  }, [])

  const onGeocoderClear = useCallback(() => {
    setCurrentSearch(undefined)
  }, [])

  const onGeocoderResult = useCallback((result) => {
    setSearchQuery(result.result.place_name)
  }, [])

  const onListItemClick = useCallback(
    (id: string) => {
      if (id === selectedFeature?.properties.id) {
        dispatch(Actions.setSelectedFeatureId(undefined))
      } else {
        dispatch(Actions.setSelectedFeatureId(id))
      }
    },
    [selectedFeature]
  )

  const onListToggle = useCallback(() => {
    setIsListOpen((prevIsListOpen) => !prevIsListOpen)
  }, [])

  /**
   * We only want to show the closest mobile phlebotomist on the map because the
   * mobile phlebotomist pins are tied only to zip codes that they service
   */
  const filterMobilePhlebsForList = (
    phlebs: PhlebotomistFeature[]
  ): PhlebotomistFeature[] => {
    let phlebsForList: PhlebotomistFeature[] = []

    const mobilePhlebs = phlebs.filter(
      (phleb) => phleb.properties.location_types === PhelMapLocationType.MOBILE
    )
    const mobilePhlebsGroupedByName = groupBy(
      mobilePhlebs,
      (feature: PhlebotomistFeature) => feature.properties.name
    )

    const nonMobilePhlebs = phlebs.filter(
      (phleb) => phleb.properties.location_types !== PhelMapLocationType.MOBILE
    )

    for (const name in mobilePhlebsGroupedByName) {
      phlebsForList.push(mobilePhlebsGroupedByName[name][0])
    }

    phlebsForList.push(...nonMobilePhlebs)

    return phlebsForList
  }

  const handleMarkerClick = (feature) => {
    dispatch(Actions.setSelectedFeatureId(feature.properties.id))
  }

  return (
    <ErrorBoundary FallbackComponent={PhlebotomistMapError}>
      <ReactMapGL
        {...viewport}
        ref={mapRef}
        getCursor={getCursor}
        width="100%"
        height="100%"
        mapboxApiAccessToken={MAPBOX_TOKEN}
        mapStyle="mapbox://styles/mapbox/streets-v11"
        maxZoom={MAX_ZOOM}
        minZoom={MIN_ZOOM}
        scrollZoom={false}
        dragRotate={false}
        touchRotate={false}
        onViewportChange={onMapViewportChange}
        onTransitionStart={() => setIsTransitioning(true)}
        onTransitionEnd={() => setIsTransitioning(false)}
      >
        {currentSearch && (
          <PhlebotomistMapSearchMarker
            latitude={currentSearch.latitude}
            longitude={currentSearch.longitude}
          />
        )}
        <MemoizedPhlebotomistMapMarkers
          features={featuresSortedByLatitude}
          onClick={handleMarkerClick}
        />
        <NavigationControl
          className={classes.navControlStyle}
          showCompass={false}
        />
        {selectedFeature && (
          <PhlebotomistMapPopup
            isMobile={isMobile}
            phlebotomistFeature={selectedFeature}
            onClose={() => dispatch(Actions.setSelectedFeatureId(undefined))}
          />
        )}
        <Geocoder
          countries="US"
          containerRef={geocoderContainerRef}
          mapboxApiAccessToken={MAPBOX_TOKEN}
          mapRef={mapRef}
          marker={false}
          onClear={onGeocoderClear}
          onResult={onGeocoderResult}
          onViewportChange={onGeocoderViewportChange}
        />
      </ReactMapGL>
      <PhlebotomistMapListView
        features={listPhlebs}
        geocoderContainerRef={geocoderContainerRef}
        hasCurrentSearch={Boolean(currentSearch)}
        isWaitingOnSearchResults={isWaitingOnSearchResults}
        isListOpen={isListOpen}
        onItemClick={onListItemClick}
        onListToggle={onListToggle}
        searchQuery={searchQuery}
        selectedFeature={selectedFeature}
      />
    </ErrorBoundary>
  )
}

export default withReducer("phlebotomistMap", reducer)(PhlebotomistMap)
