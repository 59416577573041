import * as React from "react"

import { makeStyles, styled } from "@material-ui/core"
import MuiDialogContent from "@material-ui/core/DialogContent"

import CheckIcon from "app/assets/images/circular-check-filled-success.svg"
import ChecklistHeader from "app/assets/images/custom-fee-onboarding-header.svg"
import BodyText from "app/components/design-system/BodyText"
import DesignSystemButton from "app/components/design-system/Button"
import { ModalActions } from "app/components/modals"
import { colors, Theme } from "app/theme"

import CustomFeesOnboardingModalTitleSection from "./CustomFeesOnboardingModalTitleSection"

type CustomFeesOnboardingModalStepOneProps = {
  onClose: () => void
  nextStep: () => void
  isClinicLevelSetup: boolean
}

const CustomFeesOnboardingModalStepOne: React.FC<
  CustomFeesOnboardingModalStepOneProps
> = ({ onClose, nextStep, isClinicLevelSetup }) => {
  const styles = (theme: Theme) => ({
    styledContent: {
      padding: theme.spacing(2),
      backgroundColor: colors.blueGray[50],
      display: "flex",
      justifyContent: "center",
    },
    styledAction: {
      backgroundColor: colors.blueGray[50],
      paddingTop: 24,
      paddingBottom: 42,
      paddingLeft: theme.spacing(7),
      paddingRight: theme.spacing(7),
    },
    continueButton: {
      width: "100%",
    },
  })

  const useStyles = makeStyles(styles)
  const classes = useStyles(styles)

  const commonInstructions = [
    "You will be paid out through Stripe every Friday to your bank account or a debit card once all results are in for those orders",
    "The cost to you is 5% of the Custom Fee per payout.",
  ]

  const instructions = isClinicLevelSetup
    ? [
        "You'll be able to set a default name and amount for the custom fee.",
        "All staff and practitioners in your clinic will see this added to orders automatically, and will be able to adjust its name and amount each time they place an order.",
        "Your patients will see this on checkout as a separate line item.",
        ...commonInstructions,
      ]
    : [
        "You'll be able to set a custom fee name and amount for each test you add to your LabShop.",
        "Your clients will see this on checkout as a separate line item.",
        ...commonInstructions,
      ]

  const nextActionText = isClinicLevelSetup
    ? "Continue"
    : "Continue to Connect Your Bank Account"

  return (
    <>
      <CustomFeesOnboardingModalTitleSection
        title="Include Your Own Fee in Your Orders"
        onClose={onClose}
      />
      <MuiDialogContent className={classes.styledContent}>
        <Checklist instructions={instructions} />
      </MuiDialogContent>
      <ModalActions className={classes.styledAction}>
        <DesignSystemButton
          className={classes.continueButton}
          onClick={nextStep}
          color="primary"
        >
          {nextActionText}
        </DesignSystemButton>
      </ModalActions>
    </>
  )
}

const IconImage = styled("img")({
  width: 30,
  marginRight: 11,
  alignSelf: "start",
})

const Checklist: React.FC<{ instructions: string[] }> = ({ instructions }) => {
  const styles = (theme: Theme) => ({
    checklistContainer: {
      backgroundColor: "white",
      "overflow-y": "auto",
      padding: "30px 20px",
      borderRadius: 16,
      border: "16px solid #E2E8F0",
      maxWidth: 500,
      boxShadow:
        "0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);",
    },
    styledAction: {
      backgroundColor: colors.blueGray[50],
      paddingTop: 24,
      paddingBottom: 42,
      paddingLeft: theme.spacing(7),
      paddingRight: theme.spacing(7),
    },
    continueButton: {
      width: "100%",
    },
    instructionText: {
      fontWeight: 400,
      marginBottom: 26,
      flex: 1,
    },
    instructionRow: {
      marginLeft: 20,
      marginRight: 20,
      display: "flex",
      "flex-direction": "row",
    },
    checklistHeader: {
      display: "flex",
      justifyContent: "center",
      marginTop: -60,
      marginBottom: 10,
      // There's a type error that "absolute" is not a valid "position" property...
      position: "absolute" as unknown as undefined,
      width: "100%",
      left: "0px",
    },
    checklistHeaderText: {
      color: "white",
      maxWidth: "fit-content",
      backgroundColor: colors.blueGray[500],
      alignText: "center",
      borderRadius: 5,
      padding: 16,
    },
  })

  const useStyles = makeStyles(styles)
  const classes = useStyles(styles)

  return (
    <div className={classes.checklistContainer}>
      <div className={classes.checklistHeader}>
        <img src={ChecklistHeader} alt="Things to know" />
      </div>
      {instructions.map((text, index) => (
        <div key={index} className={classes.instructionRow}>
          <IconImage src={CheckIcon} alt="company" />
          <BodyText size="md" className={classes.instructionText}>
            {text}
          </BodyText>
        </div>
      ))}
    </div>
  )
}

export default CustomFeesOnboardingModalStepOne
