import { useEffect, useReducer } from "react"

import { useDebounce } from "react-use"

import { API } from "app/api"

import * as Actions from "../state/autosuggest.actions"
import autoSuggestReducer, {
  initialState,
  SearchSuggestionsState,
} from "../state/autosuggest.reducer"

export type { SearchSuggestionsState }

export default function useSearchSuggestions(
  search?: string,
  options: { debounceDelay?: number; limit?: number } = {}
): SearchSuggestionsState {
  const { debounceDelay = 150, limit = 15 } = options
  const [state, dispatch] = useReducer(autoSuggestReducer, initialState)

  useEffect(() => {
    dispatch(Actions.startTyping())
  }, [search])

  useDebounce(
    async () => {
      dispatch(Actions.endTyping())

      if (!search) {
        return
      }

      dispatch(Actions.requestSuggestions())

      try {
        const response = await API.SearchSuggestions.get(search, limit)

        dispatch(Actions.receiveSuggestions(response.data))
      } catch (error) {
        dispatch(Actions.failedSuggestions(error as Error))
      }
    },
    debounceDelay,
    [search]
  )

  return state
}
