import { useState } from "react"

import { Collapse, Divider } from "@material-ui/core"

import { ReactComponent as BookIcon } from "app/assets/icons/patient-checkout/book.svg"
import { ReactComponent as CheckedShield } from "app/assets/icons/settings/checked-shield.svg"
import expandCaretIcon from "app/assets/images/expand-caret.svg"
import {
  lightRupaTeal,
  navy,
  primaryColor,
  secondaryColor,
  white,
} from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"

import CallInsuranceInfoModal from "./CallInsuranceInfoModal"

const useStyles = makeAppStyles({
  yourTestExplanationContainer: {
    borderRadius: "6px",
    border: `1px solid ${secondaryColor}`,
    backgroundColor: lightRupaTeal,
    display: "flex",
    flexDirection: "column",
    padding: "12px 16px",
    margin: "18px 0",
    color: navy,
  },
  yourTestExplanationTitle: {
    fontWeight: 600,
    marginBottom: "10px",
  },
  expandText: {
    color: primaryColor,
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  seeInstructionsText: {
    color: primaryColor,
    cursor: "pointer",
  },
  caveatDivider: {
    margin: "10px 0",
  },
  topExplanationParagraph: {
    marginTop: "13px",
  },
  bottomExplanationParagraph: {
    display: "flex",
  },
  presentIDExplanationContainer: {
    borderRadius: "6px",
    border: `2px solid ${secondaryColor}`,
    backgroundColor: white,
    margin: "16px 0",
    color: navy,
    fontWeight: 600,
    display: "flex",
    alignItems: "stretch",
    justifyContent: "center",
  },
  shieldIcon: {
    marginTop: "2px",
    minWidth: "17px",
    marginRight: "4px",
  },
  presentIDExplanationIconContainer: {
    backgroundColor: secondaryColor,
    display: "flex",
    alignItems: "center",
  },
  bookIcon: {
    margin: "9px",
  },
  presentIdExplanationText: {
    padding: "12px 16px",
  },
})

const ToggleInsuranceWarnings = ({ orderInsuredCompanies }) => {
  const classes = useStyles()

  const [isExplanationCollapsed, setIsExplanationCollapsed] = useState(true)
  const [showInsuranceModal, setShowInsuranceModal] = useState(false)

  const yourTestExplanationTitle = `Your tests from ${orderInsuredCompanies.join(
    " and "
  )} will be run through your insurance. ${orderInsuredCompanies.join(
    " or "
  )} will reach out to you directly if there is any remaining balance. Please note that insurance coverage for specific medical services is subject to terms and conditions set forth by your insurance carrier or policy.`
  const correspondingCashPayPartnerNames = orderInsuredCompanies.map(
    (company) => {
      return company === "Labcorp" ? "Labcorp by AML" : "National Blood Lab"
    }
  )
  const yourTestExplanationParagraph = `It is your responsibility to review and understand your insurance policy and coverage limitations. We do not make any representations or guarantees regarding insurance coverage for a particular service or test. If your ordered tests are not fully covered by your medical insurance carrier, you may receive a bill for a bigger amount than our discounted cash prices from ${correspondingCashPayPartnerNames.join(
    " or "
  )}. You are being charged a processing fee for the tests upon checkout, which is not reimbursable by your insurance provider.`

  const toggleInsuranceModal = () => {
    setShowInsuranceModal(!showInsuranceModal)
  }
  const callYourInsuranceFirstExplanation = (
    <>
      <CheckedShield className={classes.shieldIcon} />
      <div>
        To determine coverage and eligibility for{" "}
        {orderInsuredCompanies.join(" and ")} tests, we recommend you call your
        insurance company ahead of time.{" "}
        <span
          className={classes.seeInstructionsText}
          onClick={toggleInsuranceModal}
        >
          See instructions
        </span>
        <CallInsuranceInfoModal
          open={showInsuranceModal}
          onClose={toggleInsuranceModal}
        />
      </div>
    </>
  )

  const presentIDExplanation = `You must present your insurance ID card at the ${orderInsuredCompanies.join(
    " and "
  )} draw site${
    orderInsuredCompanies.length > 0 ? "s" : null
  }. Be sure to locate it before your appointment.`

  return (
    <>
      <div className={classes.yourTestExplanationContainer}>
        <div className={classes.yourTestExplanationTitle}>
          {yourTestExplanationTitle}
        </div>
        <div
          className={classes.expandText}
          onClick={() => setIsExplanationCollapsed(!isExplanationCollapsed)}
        >
          {isExplanationCollapsed ? "Read more" : "See less"}
          <img
            src={expandCaretIcon}
            className="ml-1"
            alt={""}
            style={{
              transform: `rotate(${
                isExplanationCollapsed ? "180deg" : "0deg"
              })`,
            }}
          />
        </div>
        <Collapse in={!isExplanationCollapsed}>
          <div className={classes.topExplanationParagraph}>
            {yourTestExplanationParagraph}
          </div>
          <Divider className={classes.caveatDivider} />
          <div className={classes.bottomExplanationParagraph}>
            {callYourInsuranceFirstExplanation}
          </div>
        </Collapse>
      </div>
      <div className={classes.presentIDExplanationContainer}>
        <div className={classes.presentIDExplanationIconContainer}>
          <BookIcon className={classes.bookIcon} />
        </div>
        <div className={classes.presentIdExplanationText}>
          {presentIDExplanation}
        </div>
      </div>
    </>
  )
}

export default ToggleInsuranceWarnings
