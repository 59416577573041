import { useState, useCallback, useEffect } from "react"
import { useDispatch } from "react-redux"

import { Button as MuiButton } from "@material-ui/core"

import { ReactComponent as MarkReviewed } from "app/assets/icons/mark-as-reviewed.svg"
import { ReactComponent as MarkedReviewed } from "app/assets/icons/marked-as-reviewed.svg"
import { NavyTooltip } from "app/components/NavyTooltip"
import { colors, shadows } from "app/theme"
import {
  PatientOrdersOrder as PatientOrdersOrderType,
  OrderedTest,
} from "app/types"
import makeAppStyles from "app/utils/makeAppStyles"

export const useStyles = makeAppStyles((theme) => ({
  actionButton: {
    height: 36,
    width: 36,
    border: `1px solid ${colors.blueGray[300]}`,
    borderRadius: 6,
    padding: "5px 8px",
    minWidth: 0,
    marginRight: 8,
    backgroundColor: "white",
    boxShadow: shadows.sm,
    "&:disabled": {
      opacity: 0.5,
    },

    [theme.breakpoints.up("md")]: {
      marginLeft: 8,
      marginRight: "auto",
    },
  },
  icon: {
    width: "100%",
    height: "100%",
  },
}))

const PatientOrdersOrderedTestMarkReviewedAction = ({
  order,
  orderedTest,
  markOrderedTestResultReviewed,
}: {
  order: PatientOrdersOrderType
  orderedTest: OrderedTest
  markOrderedTestResultReviewed: Function
}) => {
  const styles = useStyles()
  const dispatch = useDispatch()

  const [dateReviewed, setDateReviewed] = useState(
    orderedTest?.latest_ordered_result?.date_result_reviewed
  )

  const setDateResultReviewed = useCallback(() => {
    dispatch(markOrderedTestResultReviewed(orderedTest, order))
  }, [orderedTest, order])

  useEffect(() => {
    setDateReviewed(orderedTest?.latest_ordered_result?.date_result_reviewed)
  }, [orderedTest.latest_ordered_result])

  return (
    <NavyTooltip
      arrow
      placement="top"
      title={
        Boolean(dateReviewed)
          ? "Unmark result as reviewed"
          : "Mark result as reviewed"
      }
    >
      <div>
        <MuiButton
          onClick={setDateResultReviewed}
          key="mark-reviewed-button"
          className={styles.actionButton}
          disabled={!Boolean(orderedTest?.latest_ordered_result)}
          data-cy="mark-result-reviewed-button"
        >
          {Boolean(dateReviewed) ? (
            <MarkedReviewed
              title="mark-reviewed-icon"
              className={styles.icon}
              data-cy="mark-result-reviewed-button-marked"
            />
          ) : (
            <MarkReviewed
              title="mark-reviewed-icon"
              className={styles.icon}
              data-cy="mark-result-reviewed-button-unmarked"
            />
          )}
        </MuiButton>
      </div>
    </NavyTooltip>
  )
}

export default PatientOrdersOrderedTestMarkReviewedAction
