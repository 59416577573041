import * as React from "react"

import { CircularProgress } from "@material-ui/core"
import InputBase from "@material-ui/core/InputBase"
import SearchIcon from "@material-ui/icons/Search"

import useInitialFocus from "app/hooks/use-initial-focus"
import { colors, primaryColor, textPrimaryColor } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"

const useStyles = makeAppStyles((theme) => ({
  // Note: Ported styles over from StartNewOrder.js.
  searchBox: {
    position: "relative",
    borderRadius: 6,
    marginLeft: 0,
    flex: 1,
    [theme.breakpoints.up("sm")]: {
      marginLeft: 0,
      width: "auto",
    },
  },
  searchLabel: {
    color: textPrimaryColor,
    fontWeight: 600,
    fontSize: 14,
    marginBottom: 4,
  },
  // Note: Ported styles over from StartNewOrder.js.
  searchIcon: {
    color: "#BEC1CB",
    "& .MuiSvgIcon-root": {
      fontSize: "20px",
    },
    width: 16,
    height: 16,
    left: 8,
    top: "calc(50% + 2px)",
    position: "absolute",
    pointerEvents: "none",
    zIndex: 99,
  },
  searchProgress: {
    position: "absolute",
    right: 8,
    top: "50%",
    pointerEvents: "none",
    zIndex: 99,
  },
  // Note: Ported styles over from StartNewOrder.js.
  inputRoot: {
    width: "100%",
    color: "inherit",
  },
  // Note: Ported styles over from StartNewOrder.js.
  inputInput: {
    borderRadius: 6,
    color: "#4A5568",
    fontWeight: 400,
    border: `1px solid ${colors.blueGray[300]}`,
    backgroundColor: colors.coolGray[50],
    padding: theme.spacing(1, 1, 1, 4),
    transition: theme.transitions.create("width"),
    width: "100%",
    "&:focus": {
      borderColor: primaryColor,
      boxShadow: `0px 0px 3.1099px ${primaryColor}`,
    },
  },
}))

export interface FoodPlanPatientSearchInputProps {
  isValidating: boolean
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>
  onSearchTextChange: (value: string) => void
  searchText: string
}

export default function FoodPlanPatientSearchInput({
  isValidating,
  onKeyDown,
  onSearchTextChange,
  searchText,
}: FoodPlanPatientSearchInputProps) {
  const classes = useStyles()

  const inputRef = React.useRef<HTMLInputElement>(null)

  useInitialFocus(inputRef)

  return (
    <div className={classes.searchBox}>
      <div className={classes.searchLabel}>Client</div>
      <div className={classes.searchIcon}>
        <SearchIcon />
      </div>
      <InputBase
        onChange={(e) => onSearchTextChange(e.target.value)}
        value={searchText}
        onKeyDown={onKeyDown}
        placeholder="Client's Name"
        className="fs-exclude"
        classes={{
          root: classes.inputRoot,
          input: classes.inputInput,
        }}
        inputProps={{ "aria-label": "search", ref: inputRef }}
      />
      {isValidating && (
        <CircularProgress className={classes.searchProgress} size={16} />
      )}
    </div>
  )
}
