import { AxiosInstance } from "axios"

import { compareVersions } from "app/utils"

export class SimpleJWTAuthService {
  private version: string | null

  constructor({ version }: { version: string | null }) {
    this.version = version
  }

  /**
   * Attaches axios interceptors to automatically  detect version in responses and reload the page if it has changed.
   * @param apiAxios the axios instance to attach interceptors to
   * @returns a function that can be called to remove the interceptors
   */
  attachInterceptors(apiAxios: AxiosInstance): () => void {
    /**
     * Interceptor that refreshes page if the version has changed.
     */
    const responseInterceptor = apiAxios.interceptors.response.use(
      (response) => {
        // Check if the frontend-version header has increased since the last request.
        // If it has, then reload the page to ensure the latest version of the website is loaded.
        const newVersion = response.headers["frontend-version"]

        if (this.version && compareVersions(newVersion, this.version)) {
          console.log("New version detected, reloading")
          window.location.reload()
        }

        this.version = newVersion

        return response
      },
      async (error) => {
        return Promise.reject(error)
      }
    )

    return () => {
      apiAxios.interceptors.request.eject(responseInterceptor)
    }
  }
}

const reloadService = new SimpleJWTAuthService({ version: null })

export default reloadService
