import * as Actions from "app/main/patient-checkout/store/actions"
import { OrderRequestStatus } from "app/main/patient-checkout/store/actions/orders.actions"

const initialState = {
  errorCode: undefined,
  status: OrderRequestStatus.LOADING,
  order: {},
  payInsuranceSelected: false,
}

const ordersReducer = function (state = initialState, action) {
  switch (action.type) {
    case Actions.SET_LOADING:
      return {
        ...state,
        status: OrderRequestStatus.LOADING,
      }
    case Actions.SET_UPDATING_ORDER:
      return {
        ...state,
        status: OrderRequestStatus.UPDATING,
      }
    case Actions.GET_ORDER:
    case Actions.UPDATE_ORDER: {
      return {
        ...state,
        order: action.payload,
        status: OrderRequestStatus.SUCCESS,
      }
    }
    case Actions.SET_ERROR_CODE: {
      return {
        ...state,
        errorCode: action.payload,
        status: OrderRequestStatus.ERROR,
      }
    }
    case Actions.SET_TOGGLE_ERROR: {
      return {
        ...state,
        errorCode: action.payload,
        status: OrderRequestStatus.UPDATE_FAILED,
      }
    }
    default: {
      return state
    }
  }
}

export default ordersReducer
