import { Button } from "@rupahealth/design"

import { PhysicianServicesOptInModalProps } from "app/components/modals/PhysicianServicesOptInModal/PhysicianServicesOptInModal"
import usePhysicianServicesOptInModal from "app/components/modals/PhysicianServicesOptInModal/use-physician-services-opt-in-modal"

const TurnOnPhysicianServicesButton: React.FC<
  PhysicianServicesOptInModalProps
> = ({ onClose, ...props }) => {
  const physicianServicesOptInModal = usePhysicianServicesOptInModal()

  const onClick = () => {
    // TODO: PROD-1662 track event
    physicianServicesOptInModal.show({
      onClose: (args) => {
        onClose?.(args)
        physicianServicesOptInModal.remove()
      },
      ...props,
    })
  }

  return (
    <Button
      type="button"
      variant="primary"
      className="mt-[10px] w-full py-[10px] px-[19px] font-semibold font-sans"
      onClick={onClick}
    >
      Turn On
    </Button>
  )
}

export default TurnOnPhysicianServicesButton
