import { isNil } from "lodash"

import { ReactComponent as CrossCircleIcon } from "app/assets/icons/circular-cross.svg"
import { ReactComponent as ShippingBoxIcon } from "app/assets/icons/shipping-box-dark.svg"
import { ReactComponent as CheckCircleIcon } from "app/assets/images/circular-check-filled.svg"
import {
  ComparisonRow,
  ComparisonColumnProps,
  EmptyComparisonValue,
} from "app/main/comparison/components/ComparisonLabTests/components"
import useAnyComparedLabTestHasProperty from "app/main/comparison/hooks/use-any-compared-lab-test-has-property"
import { cancelRed, colors } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"

const useColumnStyles = makeAppStyles({
  trackingMessage: {
    display: "flex",
    flexFlow: "row nowrap",
    alignItems: "center",
    gap: 4,
    width: "100%",
  },
})

const ShipmentTrackingNumberColumn = ({ labTest }: ComparisonColumnProps) => {
  const classes = useColumnStyles()
  if (!labTest || isNil(labTest.lab_company.has_shipment_tracking_number)) {
    return <EmptyComparisonValue />
  }
  if (labTest?.lab_company.has_shipment_tracking_number) {
    return (
      <div className={classes.trackingMessage}>
        <CheckCircleIcon
          fill={colors.green[500]}
          width={21}
          height={21}
          viewBox="0 0 15 16"
        />
        {"Provides Tracking Number"}
      </div>
    )
  }
  return (
    <div className={classes.trackingMessage}>
      <CrossCircleIcon
        fill={cancelRed}
        width={21}
        height={21}
        viewBox="0 0 23 23"
      />
      {"Doesn't Provide Tracking Number"}
    </div>
  )
}

export default function ComparisonShipmentTrackingNumberRow() {
  const hasShipmentTrackingNumber = useAnyComparedLabTestHasProperty(
    "lab_company.has_shipment_tracking_number"
  )
  if (!hasShipmentTrackingNumber) {
    return null
  }
  return (
    <ComparisonRow
      Column={ShipmentTrackingNumberColumn}
      headerIcon={<ShippingBoxIcon viewBox="0 0 15 16" />}
      headerLabel="Shipment Tracking"
    />
  )
}
