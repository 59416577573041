import { Grid, Typography } from "@material-ui/core"

import HookFormTextField from "app/main/settings/LabCompanyAccountConnections/account-card/add-account-modal/HookFormTextField"
import { colors, navy } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"

const useStyles = makeAppStyles({
  formContainer: {
    borderRadius: "7px",
    border: `1px solid ${colors.blueGray[200]}`,
    padding: "9px",
    marginTop: "6px",
  },
  formTitle: {
    color: navy,
    fontWeight: 400,
    fontSize: "19px",
    marginTop: "30px",
  },
})

const InsuranceInformationPrompt = () => {
  const classes = useStyles()

  return (
    <>
      <Typography
        color="textPrimary"
        variant="h5"
        className={classes.formTitle}
      >
        Your Insurance Information
      </Typography>
      <Grid container className={classes.formContainer}>
        <HookFormTextField
          label="Subscriber’s first name"
          fieldName="order_insurance.subscribers_first_name"
          fieldPadding="11px"
        />
        <HookFormTextField
          label="Subscriber’s last name"
          fieldName="order_insurance.subscribers_last_name"
          fieldPadding="11px"
        />
        <HookFormTextField
          label="Insurance provider"
          fieldName="order_insurance.provider"
          fieldPadding="11px"
        />
        <HookFormTextField
          label="Policy ID number"
          fieldName="order_insurance.subscribers_id_number"
          fieldPadding="11px"
        />
        <HookFormTextField
          label="Group number"
          fieldName="order_insurance.group_number"
          required={false}
          fieldPadding="11px"
        />
        <HookFormTextField
          label="Relationship to beneficiary"
          fieldName="order_insurance.subscribers_relationship"
          fieldPadding="11px"
        />
      </Grid>
    </>
  )
}

export default InsuranceInformationPrompt
