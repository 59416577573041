import { createContext, useContext } from "react"

import { TableRowDetailContext } from "../components/TableRenderer"
import useDashboardKits from "../hooks/use-dashboard-kits"

interface TableRowContextProps
  extends TableRowDetailContext,
    ReturnType<typeof useDashboardKits> {}

const TableRowContext = createContext<TableRowContextProps>(undefined as never)

interface Props extends TableRowDetailContext {
  children: React.ReactNode
}

function TableRowProvider({ row, children }: Props) {
  const kitCollection = useDashboardKits({ orderId: row.original.id })

  return (
    <TableRowContext.Provider value={{ ...kitCollection, row }}>
      {children}
    </TableRowContext.Provider>
  )
}

export function useTableRowContext() {
  return useContext(TableRowContext)
}

export default TableRowProvider
