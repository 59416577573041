import { ComponentProps } from "react"
import * as React from "react"

import clsx from "clsx"

import { CircularProgress } from "@material-ui/core"

import { ReactComponent as TrashCanIcon } from "app/assets/icons/trash-can.svg"
import { ReactComponent as VerticalDragHandle } from "app/assets/images/results-interpretation/vertical-drag-handle.svg"
import RichTextEditorField from "app/results-summary/components/ResultsSummaryEditor/components/RichTextEditorField"
import TaskIcon from "app/results-summary/components/ResultsSummaryEditor/components/TaskIcon"
import TextField from "app/results-summary/components/ResultsSummaryEditor/components/TextField"
import { getTaskTypeLabel } from "app/results-summary/components/ResultsSummaryEditor/utils"
import useDeleteInterpretationTask from "app/results-summary/hooks/use-delete-interpretation-task"
import useTaskEditorForm from "app/results-summary/hooks/use-task-editor-form"
import useCachedResource from "app/swr/hooks/use-cached-resource"
import { ResourceIdentifier } from "app/swr/types"
import { colors, primaryColor, shadows } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"
import { ResultsInterpretationTask } from "types/results-interpretation"

const useStyles = makeAppStyles((theme) => ({
  root: {
    width: "100%",
    display: "flex",
    flexFlow: "row nowrap",
    gap: theme.spacing(1.0),
    padding: theme.spacing(1.0),
    border: `1px solid transparent`,
    backgroundColor: "white",
    borderRadius: 6,
  },
  rootDragging: {
    borderColor: primaryColor,
    boxShadow: shadows.xl,
  },
  dragHandle: {
    flex: "0 0 16px",
    cursor: "grab !important",
    alignSelf: "flex-start",
    "$rootDragging &": {
      cursor: "grabbing !important",
    },
  },
  fields: {
    flex: "1 1 auto",
    display: "flex",
    flexFlow: "column nowrap",
    gap: theme.spacing(1.5),
  },
  fieldRow: {
    width: "100%",
    display: "flex",
    flexFlow: "row wrap",
    gap: theme.spacing(1.75),
  },
  fieldCol: {
    flex: 1,
    display: "flex",
  },
  actions: {
    flex: "0 0 16px",
    display: "flex",
    flexFlow: "column nowrap",
    gap: theme.spacing(1.0),
  },
  deleteButton: {
    display: "flex",
    alignItems: "center",
  },
  deleteIcon: {
    width: 16,
    height: 16,
    fill: colors.blueGray[400],
  },
}))

interface TaskEditorFormProps {
  dragHandleProps?: ComponentProps<"button">
  overlay?: boolean
  style?: React.CSSProperties
  task: ResultsInterpretationTask
}

const TaskEditorForm = React.forwardRef<HTMLFormElement, TaskEditorFormProps>(
  ({ dragHandleProps, overlay, style, task }, ref) => {
    const classes = useStyles()
    const { control } = useTaskEditorForm(task)
    const { deleteTask, isDeleting } = useDeleteInterpretationTask(task)

    return (
      <form
        className={clsx(classes.root, { [classes.rootDragging]: overlay })}
        ref={ref}
        style={style}
      >
        <button
          className={classes.dragHandle}
          {...dragHandleProps}
          type="button"
        >
          <VerticalDragHandle viewBox="0 0 15 19" />
        </button>

        <div className={classes.fields}>
          <div className={classes.fieldRow}>
            <div className={classes.fieldCol}>
              <TextField
                control={control}
                disabled={overlay}
                label={getTaskTypeLabel(task.attributes.task_type)}
                labelIcon={<TaskIcon taskType={task.attributes.task_type} />}
                name="name"
                placeholder={`${getTaskTypeLabel(
                  task.attributes.task_type
                )} Name`}
                required
              />
            </div>
          </div>

          <div className={classes.fieldRow}>
            <div className={classes.fieldCol}>
              <RichTextEditorField
                control={control}
                label="Instructions"
                name="content_instructions"
                placeholder="Write any instructions here"
                readOnly={overlay}
              />
            </div>
          </div>
        </div>

        <div className={classes.actions}>
          {isDeleting ? (
            <CircularProgress size={16} />
          ) : (
            <button
              className={classes.deleteButton}
              onClick={deleteTask}
              type="button"
            >
              <TrashCanIcon
                className={classes.deleteIcon}
                viewBox="0 0 15 17"
              />
            </button>
          )}
        </div>
      </form>
    )
  }
)

export interface TaskEditorProps {
  dragHandleProps?: ComponentProps<"button">
  identifier: ResourceIdentifier
  overlay?: boolean
  style?: React.CSSProperties
}

export default React.forwardRef<HTMLFormElement, TaskEditorProps>(
  function TaskEditor({ identifier, ...props }, ref) {
    const task = useCachedResource<ResultsInterpretationTask>(identifier)

    if (!task) {
      return null
    }

    return <TaskEditorForm task={task} {...props} ref={ref} />
  }
)
