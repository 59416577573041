import { CSSProperties } from "@material-ui/core/styles/withStyles"
import { cn } from "@rupahealth/design"

import BodyText from "app/components/design-system/BodyText"

interface Props {
  children: React.ReactNode
  className?: string
  style?: React.CSSProperties
  labelClass?: string
  labelStyle?: CSSProperties
}

const StatusPill: React.FC<Props> = ({
  children,
  className,
  style,
  labelClass,
  labelStyle,
}) => {
  return (
    <div
      className={cn("rounded-[40px] py-[2px] px-3 inline-block", className)}
      style={style}
    >
      <BodyText
        size="sm"
        weight="semibold"
        className={cn("flex items-center justify-center gap-[6px]", labelClass)}
        style={labelStyle}
      >
        {children}
      </BodyText>
    </div>
  )
}

export default StatusPill
