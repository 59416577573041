import * as React from "react"

import Button from "app/components/design-system/Button"
import makeAppStyles from "app/utils/makeAppStyles"

const useStyles = makeAppStyles((theme) => ({
  editorFormSection: {
    display: "flex",
    flexFlow: "column nowrap",
    gap: theme.spacing(1.0),
  },
  editorFormSectionHeader: {
    display: "flex",
    flexFlow: "row nowrap",
    alignItems: "flex-start",
    justifyContent: "space-between",
    gap: theme.spacing(1.0),
  },
  editToggleButton: {
    minWidth: "auto",
  },
}))

export interface FormSectionProps {
  children: React.ReactNode
  disableEditButton?: boolean
  isEditing: boolean
  onToggleEditing: () => void
  showEditButton: boolean
  title: React.ReactNode
}

export default function FormSection({
  children,
  disableEditButton = false,
  isEditing,
  onToggleEditing,
  showEditButton = true,
  title,
}: FormSectionProps) {
  const classes = useStyles()
  return (
    <div className={classes.editorFormSection}>
      <div className={classes.editorFormSectionHeader}>
        {title}

        {showEditButton && (
          <Button
            className={classes.editToggleButton}
            color="text"
            disabled={disableEditButton}
            disableRipple
            onClick={() => onToggleEditing()}
            size="small"
            type="button"
          >
            {isEditing ? "Done" : "Edit"}
          </Button>
        )}
      </div>

      {children}
    </div>
  )
}
