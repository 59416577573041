import moment from "moment"

import { makeStyles } from "@material-ui/core/styles"

import BodyText from "app/components/design-system/BodyText"

import { TableCellProps } from "./types"

const useStyles = makeStyles((theme) => ({
  container: {
    whiteSpace: "nowrap",
  },
}))

export default function UpdatedAtTableCell({ order }: TableCellProps) {
  const classes = useStyles()
  return (
    <BodyText className={classes.container}>
      {moment(order.updated_at).format("LL")}
    </BodyText>
  )
}
