import { useState } from "react"
import * as React from "react"

import { Divider, makeStyles } from "@material-ui/core"

import AddBlueCircleIcon from "app/assets/icons/add-blue-circle.svg"
import MinusBlueCircleIcon from "app/assets/icons/minus-blue-circle.svg"
import patientCheckoutScreenshot from "app/assets/images/custom_fees_patient_checkout.png"
import Button from "app/components/design-system/Button"

type CustomFeesPatientCheckoutExampleSectionProps = {
  open: boolean
}

const CustomFeesPatientCheckoutExampleSection: React.FC<
  CustomFeesPatientCheckoutExampleSectionProps
> = () => {
  const [open, setOpen] = useState(false)
  const styles = () => ({
    screenshot: {
      marginTop: 14,
      borderRadius: 11,
    },
    divider: {
      marginTop: 14,
      marginBottom: 20,
    },
  })

  const useStyles = makeStyles(styles)
  const classes = useStyles(styles)

  return (
    <>
      <Button
        color="text"
        endIcon={open ? MinusBlueCircleIcon : AddBlueCircleIcon}
        onClick={() => setOpen(!open)}
      >
        See what your patients see in checkout
      </Button>

      <img
        src={patientCheckoutScreenshot}
        className={classes.screenshot}
        width="100%"
        alt="Example of what a custom fee looks like during patient checkout."
        hidden={!open}
      />
      <Divider className={classes.divider} />
    </>
  )
}

export default CustomFeesPatientCheckoutExampleSection
