import FieldLabel, {
  FieldLabelProps,
} from "app/main/patient-checkout/fields/FieldLabel"

import MaskedDateInput, { MaskedDateInputProps } from "./MaskedDateInput"

export type MaskedDateFieldProps = MaskedDateInputProps &
  FieldLabelProps & {
    labelClassName?: string
    inputClassName?: string
  }

export default function MaskedDateField({
  InputProps,
  className,
  infoText,
  inputClassName,
  inputProps,
  label,
  labelClassName,
  required,
  ...maskedDateInputProps
}: MaskedDateFieldProps) {
  return (
    <div className={className}>
      {Boolean(label) && (
        <FieldLabel
          className={labelClassName}
          label={label}
          infoText={infoText}
          required={required}
        />
      )}
      <MaskedDateInput
        className={inputClassName}
        InputProps={{
          ...InputProps,
          inputProps: {
            "aria-label": typeof label === "string" ? label : undefined,
            ...inputProps,
            ...InputProps?.inputProps,
          },
        }}
        required={required}
        {...maskedDateInputProps}
      />
    </div>
  )
}
