import { Fragment, useEffect, useMemo } from "react"

import { TableRow, TableCell } from "@material-ui/core"
import { cn } from "@rupahealth/design"
import {
  CoreCell,
  Row,
  Table as TanstackTable,
  flexRender,
} from "@tanstack/react-table"

import useCachedResource from "app/swr/hooks/use-cached-resource"
import { Biomarker } from "types/biomarker"
import { LabCompanyBiomarkerRange } from "types/lab-company-biomarker-range"

import DemographicOptimalRangeRows from "./DemographicOptimalRangeRows"
import useOptimalRanges from "./hooks/use-optimal-ranges"
import useTableStyles from "./hooks/use-table-styles"

interface GroupedDemographicRanges {
  [key: string]: LabCompanyBiomarkerRange[]
}

const TableDataRow = ({
  table,
  row,
}: {
  table: TanstackTable<LabCompanyBiomarkerRange>
  row: Row<LabCompanyBiomarkerRange>
}) => {
  const classes = useTableStyles()

  const biomakerNameCell = row.getAllCells()[0] as CoreCell<
    LabCompanyBiomarkerRange,
    LabCompanyBiomarkerRange["relationships"]["biomarker"]
  >
  const biomarkerData = biomakerNameCell.getValue().data

  const biomarker = useCachedResource<Biomarker>(biomarkerData)

  const {
    optimalRanges: demographicRanges,
    isLoading: demographicRangesLoading,
    mutateOptimalRanges,
  } = useOptimalRanges({
    labCompanyKey: table.getState().globalFilter.labCompanyKey,
    biomarkerId: biomarker?.id,
    onlyDemographicRanges: true,
  })

  useEffect(() => {
    mutateOptimalRanges()
  }, [])

  const groupedRanges: GroupedDemographicRanges = useMemo(() => {
    return demographicRanges.reduce((groupedDemographics, optimalRange) => {
      let demographicKey = "Any Sex Assigned at Birth"
      if (optimalRange.attributes.biological_sex) {
        demographicKey = optimalRange.attributes.biological_sex
      }

      if (!groupedDemographics[demographicKey]) {
        groupedDemographics[demographicKey] = []
      }

      groupedDemographics[demographicKey].push(optimalRange)

      return groupedDemographics
    }, {})
  }, [demographicRanges])

  return (
    <Fragment>
      <TableRow
        className={cn(
          classes.tableDataRow,
          row.getIsExpanded() ? "bg-slate-50" : ""
        )}
      >
        {row.getVisibleCells().map((cell) => (
          <TableCell
            key={cell.id}
            className={cn(
              classes.tableCell,
              row.getIsExpanded() ? "border-none" : ""
            )}
            style={{
              ...cell.column.columnDef.meta?.cellStyle,
              ...cell.column.columnDef.meta?.dataCellStyle,
            }}
          >
            {flexRender(cell.column.columnDef.cell, {
              ...cell.getContext(),
              demographicRanges,
              demographicRangesLoading,
            })}
          </TableCell>
        ))}
      </TableRow>

      {row.getIsExpanded() &&
        Boolean(demographicRanges?.length) &&
        Object.keys(groupedRanges).map((groupedRangeKey) => (
          <DemographicOptimalRangeRows
            key={groupedRangeKey}
            groupedRangeKey={groupedRangeKey}
            groupedRanges={groupedRanges}
            numDemographicRanges={demographicRanges.length}
          />
        ))}
    </Fragment>
  )
}

export default TableDataRow
