import { isPossibleNumber } from "libphonenumber-js"
import { some } from "lodash"
import { z } from "zod"

import { LAB_COMPANY_KEY, PO_BOX_REGEX } from "app/constants"
import {
  orderHasActiveBioReferenceTests,
  orderHasActiveOrderedTestsForLabCompany,
} from "app/dataServices/orderDataService"
import { PatientPortalOrder, PaymentMethodType } from "app/types"
import { getAge } from "app/utils"
import {
  getLineItemWithMaxAgeRestriction,
  getMinimumAgeAndWarning,
  getOrderPatientLabel,
} from "app/utils/order-utils"

import {
  BLOCKED_SHIPPINGS_STATES,
  GENERAL_BLOCKED_SHIPPINGS_STATES,
  PRACTITIONER_PAY_BLOCKED_SHIPPING_STATES,
  QUEST_BLOCKED_SHIPPINGS_STATES,
  US_STATES,
} from "../utils/usStates"

const SplitItSchema = z.object({
  billing_address: z
    .object({
      street_1: z.string().nonempty("Must provide your billing street address"),
      street_2: z.string().optional().nullable(),
      city: z.string().nonempty("Must provide your billing city"),
      state: z.string().nonempty("Must provide your billing state"),
      zipcode: z.string().nonempty("Must provide your billing zip code"),
    })
    .optional(),
  is_billing_same_as_shipping: z.boolean().optional(),
  is_terms_accepted: z
    .boolean({
      required_error: "Must accept SplitIt terms to proceed",
    })
    .refine((val) => val === true, "Must accept SplitIt terms to proceed"),
})

const InsuranceSchema = z.object({
  claims_city: z.string().nonempty("Must provide a city"),
  claims_state: z.string().nonempty("Must provide a state"),
  claims_street: z.string().nonempty("Must provide a street address"),
  claims_zipcode: z.string().nonempty("Must provide a valid zip code"),
  group_number: z.string().nonempty("Must provide a group number"),
  provider: z.string().nonempty("Must select an insurance provider"),
  subscribers_birthday: z.date({
    invalid_type_error: "Must provide a valid date of birth",
    required_error: "Must provide a date of birth",
  }),
  subscribers_first_name: z.string().nonempty("Must provide a first name"),
  subscribers_id_number: z.string().nonempty("Must provide an ID number"),
  subscribers_last_name: z.string().nonempty("Must provide a last name"),
  subscribers_relationship: z
    .string()
    .nonempty("Must provide your relationship to the subscriber"),
})

const ScarletSchema = z.object({
  blood_draw_zipcode: z
    .string()
    .length(5)
    .regex(new RegExp("[0-9]+"), "Must provide a valid US zip code")
    .nonempty("Must provide a valid US zip code"),
  is_eligible_for_scarlet: z.boolean().refine((arg) => Boolean(arg), {
    message: "Scarlet does not service your area for blood draws",
  }),
})

const BaseSchema = z.object({
  birthday: z.date({
    invalid_type_error: "Must provide a valid date of birth",
    required_error: "Must provide your date of birth",
  }),
  consent: z
    .boolean({
      required_error: "Must provide your consent to proceed",
    })
    .refine((val) => val === true, "Must provide your consent to proceed"),
  email: z
    .string()
    .nonempty("Must provide your email address")
    .email("Must provide a valid email address"),
  first_name: z.string().min(2).nonempty("Must provide your first name"),
  biological_sex: z.string().nonempty("Must provide your sex"),
  last_name: z.string().min(2).nonempty("Must provide your last name"),
  payment_method: z
    .object({
      type: z.nativeEnum(PaymentMethodType, {
        required_error: "Must select a payment method",
      }),
      splitit: SplitItSchema.optional(),
    })
    .optional()
    .refine(
      (payment_method) => {
        if (payment_method !== undefined) {
          if (payment_method.type === PaymentMethodType.SPLITIT) {
            return payment_method.splitit !== undefined
          }
        }
        return true
      },
      { path: ["splitit"], message: "Must provide SplitIt details" }
    ),
  payment_method_id: z.string().optional(),
  phone_number: z
    .string()
    .nonempty("Must provide your phone number")
    .refine(
      (val) => isPossibleNumber(val, "US"),
      "Please enter a valid US phone number"
    ),
})

const HasScarletSchema = z.object({
  scarlet: ScarletSchema,
})

const HasInsuranceSchema = z.object({
  insurance: InsuranceSchema,
})

const HasMedicareSchema = z.object({
  insurance: InsuranceSchema.omit({
    claims_city: true,
    claims_state: true,
    claims_street: true,
    claims_zipcode: true,
    group_number: true,
  }),
})

export const OrderPatientInsuranceSchema = z.object({
  order_insurance: z.object({
    subscribers_first_name: z.string().nonempty("This field is required."),
    subscribers_last_name: z.string().nonempty("This field is required."),
    provider: z.string().nonempty("This field is required."),
    subscribers_id_number: z.string().nonempty("This field is required."),
    group_number: z.string().optional(),
    subscribers_relationship: z.string().nonempty("This field is required."),
  }),
})

export default function createSchema(
  order: PatientPortalOrder,
  beastCoastEnabled: Boolean,
  hasHealthGorillaInsurance: Boolean
) {
  let Schema: any = BaseSchema

  const lineItemWithMaxAgeRestriction = getLineItemWithMaxAgeRestriction(
    order.line_items
  )

  const { minAge } = getMinimumAgeAndWarning(
    order,
    lineItemWithMaxAgeRestriction?.ordered_test?.lab_test
  )

  function updateWithInsuranceSchema() {
    if (!order.use_insurance) {
      return
    }

    if (order.patient_has_medicare) {
      Schema = Schema.merge(HasMedicareSchema)
      return
    }

    Schema = Schema.merge(HasInsuranceSchema)
  }

  if (orderHasActiveBioReferenceTests(order)) {
    Schema = Schema.extend({
      brl_consent: z
        .boolean({
          required_error: "Must provide your consent to proceed",
        })
        .refine((val) => val === true, "Must provide your consent to proceed"),
    })
  }

  if (
    orderHasActiveOrderedTestsForLabCompany(order, LAB_COMPANY_KEY.LTA_QUEST)
  ) {
    Schema = Schema.merge(
      z.object({
        default_shipping_address: z.object({
          state: z
            .string()
            .nonempty("Must provide your state")
            .refine(
              (state) =>
                ![
                  ...BLOCKED_SHIPPINGS_STATES,
                  ...QUEST_BLOCKED_SHIPPINGS_STATES,
                ].includes(state),
              (state) => ({
                message: QUEST_BLOCKED_SHIPPINGS_STATES.includes(state)
                  ? `Quest testing cannot be completed in ${US_STATES[state]}.`
                  : `We are currently unable to ship to ${US_STATES[state]}`,
              })
            ),
        }),
      })
    )
  }

  const hasIgeneXTests = some(
    order.ordered_tests.map(
      (orderedTest) => orderedTest.lab_test.lab_company.key
    ),
    (key) => key === LAB_COMPANY_KEY.IGENEX
  )
  const patientLabel = getOrderPatientLabel(
    order.requires_vendor_physician_authorization
  )
  Schema = Schema.extend({
    birthday: z
      .date({
        invalid_type_error: "Must provide a valid date of birth",
        required_error: "Must provide your date of birth",
      })
      .refine(
        (birthday) => new Date(birthday) <= new Date(),
        "Date of birth cannot be in the future"
      )
      .refine(
        (birthday) => !minAge || getAge(birthday) >= minAge,
        // NOTE: if this is updated, be sure to update the MIN_AGE_ERROR_REGEX
        // in ControlledDateField.jsx
        `${patientLabel} must be ${minAge} or older to receive some of the tests in this order.`
      )
      // Enforce IgeneX tests cannot be ordered for patients 65 or older for phys service orders.
      // TODO https://app.asana.com/0/1160663284050137/1204605541469993/f: enforce max age requirement in less hacky way.
      .refine(
        (birthday) =>
          !hasIgeneXTests ||
          !order.requires_vendor_physician_authorization ||
          getAge(birthday) < 65,
        `Some of the testing in your order is not enabled for those 65 and older.`
      ),
  })

  if (order.flags.CHECK_SCARLET_ELIGIBILITY) {
    Schema = Schema.merge(HasScarletSchema)
  }

  Schema = Schema.extend({
    default_shipping_address: z.object({
      city: z.string().nonempty("Must provide your city"),
      state: z
        .string()
        .nonempty("Must provide your state")
        .refine(
          (state) =>
            !order.is_practitioner_paying ||
            !PRACTITIONER_PAY_BLOCKED_SHIPPING_STATES.includes(state),
          (state) => ({
            message: `We are currently unable to ship practitioner-pay orders to ${US_STATES[state]}.`,
          })
        )
        .refine(
          (state) => !GENERAL_BLOCKED_SHIPPINGS_STATES.includes(state),
          (state) => ({
            message: `We are currently unable to ship to ${US_STATES[state]}.`,
          })
        ),
      street_1: z
        .string()
        .nonempty("Must provide your street address")
        .refine(
          (street_1) => !PO_BOX_REGEX.test(street_1),
          "We cannot ship to PO Boxes"
        ),
      street_2: z
        .string()
        .nullable()
        .refine(
          (street_2) => (street_2 ? !PO_BOX_REGEX.test(street_2) : true),
          "We cannot ship to PO Boxes"
        )
        .optional(),
      zipcode: z
        .string()
        .nonempty("Must provide your zip code")
        .refine(
          (zipcode) => !PO_BOX_REGEX.test(zipcode),
          "We cannot ship to PO Boxes"
        ),
    }),
  })

  if (hasHealthGorillaInsurance) {
    Schema = Schema.merge(OrderPatientInsuranceSchema)
  }

  updateWithInsuranceSchema()
  return Schema
}
