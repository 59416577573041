import Typography from "@material-ui/core/Typography"

import barChartIcon from "app/assets/icons/bar-chart.svg"
import boxIcon from "app/assets/icons/box.svg"
import AlertTriangleRed from "app/assets/images/alert-triangle-red.svg"
import Tooltip from "app/components/Tooltip"
import BodyText from "app/components/design-system/BodyText"
import Underline from "app/components/design-system/Underline"
import { colors } from "app/theme"
import { PatientPortalOrderedTest } from "app/types"

interface PanelDetailsProps {
  orderedTest: PatientPortalOrderedTest
}

function PanelDetails({ orderedTest }: PanelDetailsProps) {
  const labTest = orderedTest.lab_test

  const shippingMin = orderedTest.shipping_days_min
  const shippingMax = orderedTest.shipping_days_max
  const estimatedDaysForResult = labTest.estimated_days_for_results
  const activeShippingAlert = labTest.active_shipping_alert

  let shippingTime = (
    <div className="flex flex-row items-center">
      <img alt="" src={boxIcon} className="mr-3" />
      <Typography className="font-semibold text-sm">{`${shippingMin}-${shippingMax} Business Days`}</Typography>
    </div>
  )

  const panelDescription = `This is a ${
    labTest.lab_company.short_name
  } panel selected for you by your practitioner. ${
    orderedTest.sample_collection_coordinator === "LAB"
      ? "Please bring your requisition with you to a draw site to complete your testing!"
      : ""
  }`

  if (activeShippingAlert) {
    shippingTime = (
      <Tooltip
        interactive
        title={
          <BodyText>
            {activeShippingAlert.patient_facing_issue_description}
          </BodyText>
        }
        placement="bottom"
        arrow
      >
        <Underline>
          {shippingTime}
          <img src={AlertTriangleRed} alt="Results delay" className="ml-1" />
        </Underline>
      </Tooltip>
    )
  }

  return (
    <div
      className="rounded-b-lg p-5 border-gray-300 border-t"
      style={{ backgroundColor: colors.coolGray[50] }}
    >
      <div className="flex flex-row mb-4">
        {Boolean(shippingMin) && Boolean(shippingMax) && (
          <div className="flex-1 mr-2">
            <Typography className="text-xs mb-2">Shipping</Typography>
            {shippingTime}
          </div>
        )}
        {Boolean(estimatedDaysForResult) && (
          <div className="flex-1">
            <Typography className="text-xs">Avg. Sample Processing</Typography>
            <div className="flex flex-row items-center mt-2">
              <img alt="" src={barChartIcon} className="mr-3" />
              <Typography className="font-semibold text-sm">{`${estimatedDaysForResult} Business Days`}</Typography>
            </div>
          </div>
        )}
      </div>
      {panelDescription}
    </div>
  )
}

export default PanelDetails
