import * as React from "react"

import { Control, useController } from "react-hook-form"

import FieldDecoration, {
  FieldDecorationProps,
} from "app/results-summary/components/ResultsSummaryEditor/components/FieldDecoration"
import { colors, textPrimaryColor } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"

const useStyles = makeAppStyles((theme) => ({
  input: {
    width: "100%",
    padding: theme.spacing(0.5, 1.0),
    fontSize: 15,
    fontWeight: 600,
    lineHeight: 1.35,
    backgroundColor: colors.blueGray[100],
    color: textPrimaryColor,
    borderRadius: 6,
    "&:disabled": {
      color: colors.blueGray[500],
    },
    "&::placeholder": {
      color: colors.blueGray[500],
      fontWeight: 600,
    },
  },
}))

/**
 * A text input variant.
 */
const TextInputVariant = React.forwardRef<
  HTMLInputElement,
  React.ComponentPropsWithoutRef<"input">
>((props, ref) => {
  return (
    <input
      ref={ref}
      type="text"
      {...props}
      autoComplete="off"
      autoCorrect="off"
      autoCapitalize="off"
      spellCheck="false"
    />
  )
})

/**
 * A text area input variant.
 */
const TextAreaInputVariant = React.forwardRef<
  HTMLTextAreaElement,
  React.ComponentPropsWithoutRef<"textarea">
>((props, ref) => {
  return <textarea ref={ref} rows={3} {...props} />
})

/**
 * Renders a text input or text area field based on the variant prop.
 */
const InputVariant = React.forwardRef(function renderInput(
  {
    variant,
    ...props
  }: (
    | React.ComponentPropsWithoutRef<"input">
    | React.ComponentPropsWithoutRef<"textarea">
  ) & {
    variant: TextFieldProps["variant"]
  },
  ref: React.ForwardedRef<HTMLInputElement | HTMLTextAreaElement>
) {
  switch (variant) {
    case "textarea": {
      return (
        <TextAreaInputVariant
          {...(props as React.ComponentPropsWithoutRef<"textarea">)}
          ref={ref as React.ForwardedRef<HTMLTextAreaElement>}
        />
      )
    }
    case "text":
    default: {
      return (
        <TextInputVariant
          {...(props as React.ComponentPropsWithoutRef<"input">)}
          ref={ref as React.ForwardedRef<HTMLInputElement>}
        />
      )
    }
  }
})

export interface BaseFieldProps extends Omit<FieldDecorationProps, "error"> {
  control: Control<any>
  disabled?: boolean
  name: string
  placeholder?: string
}

/**
 * The props for the text field variant.
 */
export interface TextVariantFieldProps extends BaseFieldProps {
  variant?: "text"
}

/**
 * The props for the text area field variant.
 */
export interface TextAreaVariantFieldProps extends BaseFieldProps {
  variant: "textarea"
}

/**
 * The props for the text field.
 */
export type TextFieldProps = TextVariantFieldProps | TextAreaVariantFieldProps

/**
 * The text field component. Renders a text input or text area field based on the variant prop.
 * Includes rendering of a label and error message.
 */
export default function TextField({
  control,
  disabled,
  label,
  labelIcon,
  name,
  placeholder,
  required,
  variant = "text",
}: TextFieldProps) {
  const classes = useStyles()
  const {
    field,
    fieldState: { error },
  } = useController({
    control,
    name,
    shouldUnregister: true,
  })
  return (
    <FieldDecoration
      error={error}
      label={label}
      labelIcon={labelIcon}
      required={required}
    >
      <InputVariant
        aria-invalid={error ? "true" : "false"}
        className={classes.input}
        disabled={disabled}
        placeholder={placeholder}
        variant={variant}
        {...field}
      />
    </FieldDecoration>
  )
}
