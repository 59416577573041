import { useController, useFormContext, useWatch } from "react-hook-form"

import PaymentCollectionStripeCard from "app/main/payment-collection/components/PaymentCollectionStripeCard"
import { PaymentCollectionFormData } from "app/main/payment-collection/types"
import { formatDollars } from "app/utils"

export interface PaymentCollectionPaymentMethodProps {
  amount: number
}

export default function PaymentCollectionPaymentMethod({
  amount,
}: PaymentCollectionPaymentMethodProps) {
  const { control, resetField, setError } =
    useFormContext<PaymentCollectionFormData>()
  const {
    field: { onBlur, onChange, value: paymentMethod },
    fieldState: { error, isTouched },
  } = useController({
    control: control,
    name: "payment_method",
  })
  const consent = useWatch({
    control: control,
    name: "consent",
  })
  const active = consent && !paymentMethod
  const clearable = !active && Boolean(paymentMethod)
  const amountFormatted = formatDollars(amount / 100.0)
  return (
    <PaymentCollectionStripeCard
      active={active}
      amountFormatted={amountFormatted}
      clearable={clearable}
      emptyMessage={`Provide consent before entering card information to be charged ${amountFormatted}`}
      error={isTouched ? error : undefined}
      onClear={() =>
        resetField("payment_method", { keepError: true, keepTouched: true })
      }
      onError={(stripeError) =>
        setError("payment_method", {
          message: stripeError.message,
        })
      }
      onSubmit={(stripePaymentMethod) => {
        onBlur()
        onChange(stripePaymentMethod.id)
      }}
      title="Payment Method"
    />
  )
}
