import { Grid } from "@material-ui/core"

import SideNav from "./SideNav"

function Settings() {
  return (
    <Grid container>
      <Grid item xs={12} md={12}>
        <SideNav />
      </Grid>
    </Grid>
  )
}

export default Settings
