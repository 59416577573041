import { Skeleton } from "@material-ui/lab"

import { colors } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"

interface Props {
  width?: number
  color?: string
}

const useStyles = makeAppStyles<Props>({
  container: {
    height: 8,
    width: (props) => props.width,
    background: (props) => props.color,
    borderRadius: 40,
    marginRight: 6,
    marginBottom: 6,
  },
})

const PendingPlaceholder = ({
  width = 68,
  color = colors.blueGray[300],
}: Props) => {
  const styles = useStyles({ width, color })

  return (
    <Skeleton className={styles.container} variant="rect" animation="wave" />
  )
}

export default PendingPlaceholder
