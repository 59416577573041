import { useEffect } from "react"

const useInitialFocus = (ref) => {
  useEffect(() => {
    if (ref && ref.current) {
      ref.current.focus()
    }
  }, [ref])
}

export default useInitialFocus
