import { useState } from "react"

import useEventCallback from "app/hooks/use-event-callback"
import useHandleApiError from "app/hooks/use-handle-api-error"
import { AnyBiomarkerResourceIdentifier } from "app/results-summary/components/ResultsSummaryEditor/types"
import useMutateResource from "app/swr/hooks/use-mutate-resource"
import { ResourceIdentifier, ResourceResponse } from "app/swr/types"
import resourceRequest from "app/swr/utils/resource-request"
import setIn from "app/utils/set-in"
import {
  ResultsInterpretation,
  ResultsInterpretationBiomarker,
  ResultsInterpretationBiomarkerCreate,
} from "types/results-interpretation"

/**
 * Returns a callback that is used to create an interpretation biomarker and update the resource cache with the result.
 *
 * @returns the callback to create an intepretation biomarker
 */
function useCreateInterpretationBiomarkerResource() {
  const mutateResource = useMutateResource()
  const handleApiError = useHandleApiError()
  return useEventCallback(
    async (payload: ResultsInterpretationBiomarkerCreate) => {
      const sendCreateRequest = async () => {
        try {
          const { data } = await resourceRequest<
            ResourceResponse<ResultsInterpretationBiomarker>,
            { data: ResultsInterpretationBiomarkerCreate }
          >({
            method: "post",
            url: `/results_interpretation_biomarkers/`,
            data: {
              data: payload,
            },
          })

          return data
        } catch (error) {
          handleApiError(error)

          // re-throw
          throw error
        }
      }

      const biomarker = await sendCreateRequest()
      const identifier = {
        type: biomarker.type,
        id: biomarker.id,
      } as ResourceIdentifier

      // Add biomarker to cache
      await mutateResource<ResultsInterpretationBiomarker>(
        identifier,
        biomarker
      )

      // Update cached interpretation with new biomarker
      await mutateResource<ResultsInterpretation>(
        biomarker.relationships.interpretation.data,
        (currentVisualization) => {
          if (!currentVisualization) {
            // should never be reached, but type error without
            return undefined as any
          }

          const { biomarkers } = currentVisualization.relationships

          return setIn(currentVisualization, "relationships.biomarkers", {
            data: [...biomarkers.data, identifier],
            meta: {
              ...biomarkers.meta,
              count: biomarkers.meta.count + 1,
            },
          })
        }
      )
    }
  )
}

/**
 * Used to create a biomarker from a biomarker identifier. This is used when the user selects a biomarker from the search results.
 *
 * @param interpretation the interpretation to create the biomarker for
 * @param onSuccess a callback to run when the biomarker is successfully created
 * @returns a boolean tracking whether the biomarker is being created, and a function to create a biomarker from a biomarker identifier
 */
export default function useCreateInterpretationBiomarker(
  interpretation: ResultsInterpretation | undefined,
  onSuccess?: () => void
) {
  const [isCreating, setIsCreating] = useState(false)
  const createInterpretationBiomarkerResource =
    useCreateInterpretationBiomarkerResource()

  /**
   * Create a new biomarker for the interpretation. This is used when the user selects a biomarker from the search results.
   */
  const createInterpretationBiomarker = useEventCallback(
    async (identifier: AnyBiomarkerResourceIdentifier) => {
      if (isCreating || !interpretation) {
        return
      }

      try {
        setIsCreating(true)

        const payload: ResultsInterpretationBiomarkerCreate = {
          type: "results_interpretation_biomarker",
          relationships: {
            interpretation: {
              data: { type: interpretation.type, id: interpretation.id },
            },
          },
        }

        switch (identifier.type) {
          case "ordered_biomarker": {
            payload.relationships.biomarker = {
              data: identifier,
            }
            break
          }
          case "discrete_result": {
            payload.relationships.discrete_result = {
              data: identifier,
            }
            break
          }
        }

        await createInterpretationBiomarkerResource(payload)

        onSuccess?.()
      } finally {
        setIsCreating(false)
      }
    }
  )

  return {
    createInterpretationBiomarker,
    isCreating,
  }
}
