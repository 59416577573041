import { useMemo } from "react"

import { uniqBy } from "lodash"

import { ClinicSettings } from "app/main/settings/RupaBloodDashboards/constants"
import {
  isDiscreteResultAbnormal,
  isDiscreteResultOutOfOptimalRange,
  sortDiscreteValues,
} from "app/patient-portal/blood-lab-dashboard/utils"
import useCachedCollection from "app/swr/hooks/use-cached-collection"
import useCachedResource from "app/swr/hooks/use-cached-resource"
import useCollectionSWR from "app/swr/hooks/use-collection-swr"
import { ResourceCollection } from "app/swr/types"
import { Biomarker } from "types/biomarker"
import { BodySystem } from "types/body-system"
import { Clinic } from "types/clinic"
import { DiscreteResult } from "types/discrete-result"
import { LabCompanyBiomarkerRange } from "types/lab-company-biomarker-range"
import { LabTest } from "types/lab-test"
import { Order } from "types/order"
import { OrderedResult } from "types/ordered-result"
import { OrderedTest } from "types/ordered-test"
import { Patient } from "types/patient"
import { Practitioner } from "types/practitioner"

export default function useDiscreteResults(
  orderedResultId: string,
  search?: string
) {
  const {
    data: discreteResults,
    isLoading: isDiscreteResultsLoading,
    mutate,
    error,
  } = useCollectionSWR<ResourceCollection<DiscreteResult>>(
    `/ordered_results/${orderedResultId}/discrete_results/`,
    {
      include: [
        "biomarker.lab_company_biomarker_ranges",
        "biomarker.body_systems",
        "biomarker.custom_descriptions",
        "ordered_result.ordered_tests.lab_test.biomarkers",
        "ordered_result.order.patient",
        "ordered_result.order.practitioner.clinic",
        "ordered_result.lab_company",
        "ordered_result.kit",
      ],
    }
  )

  const cachedDiscreteResults =
    useCachedCollection<DiscreteResult>(discreteResults)

  const filteredDiscreteResults = cachedDiscreteResults.filter(
    (discreteResult) => {
      return (
        discreteResult.attributes.name
          .toLowerCase()
          .includes(search?.toLowerCase() ?? "") &&
        discreteResult.relationships.biomarker.data
      )
    }
  )

  const filteredBiomarkers = useCachedCollection<Biomarker>(
    filteredDiscreteResults.map((discreteResult) => {
      return discreteResult.relationships.biomarker.data
    })
  )

  const discreteResultsBiomarkers = useCachedCollection<Biomarker>(
    cachedDiscreteResults.map((discreteResult) => {
      return discreteResult.relationships.biomarker.data
    })
  )

  const uniqueBodySystemIdentifiers = uniqBy(
    filteredBiomarkers.flatMap(
      (biomarker) => biomarker.relationships.body_systems.data
    ),
    "id"
  )
  const bodySystems = useCachedCollection<BodySystem>(
    uniqueBodySystemIdentifiers
  )

  const sortedBodySystems = useMemo(() => {
    return [...bodySystems].sort(
      (a, b) => (a.attributes.sort_order || 0) - (b.attributes.sort_order || 0)
    )
  }, [bodySystems])

  const orderedResult = useCachedResource<OrderedResult>({
    type: "ordered_result",
    id: orderedResultId,
  })

  const orderedTests = useCachedCollection<OrderedTest>(
    orderedResult?.relationships.ordered_tests.data
  )

  const labTests = useCachedCollection<LabTest>(
    orderedTests.flatMap((orderedTest) => {
      return orderedTest.relationships.lab_test.data
    })
  )

  const order = useCachedResource<Order>(
    orderedResult?.relationships.order?.data
  )

  const patient = useCachedResource<Patient>(order?.relationships.patient.data)

  const practitioner = useCachedResource<Practitioner>(
    order?.relationships.practitioner.data
  )

  const clinic = useCachedResource<Clinic>(
    practitioner?.relationships.clinic.data
  )

  const totalBiomarkers = useCachedCollection<Biomarker>(
    labTests.flatMap((labTest) => {
      return labTest.relationships.biomarkers.data
    })
  )

  const missingBiomarkers = useCachedCollection<Biomarker>(
    labTests
      .flatMap((labTest) => {
        return labTest.relationships.biomarkers.data
      })
      .filter((biomarker) => {
        return !discreteResultsBiomarkers.find((filteredBiomarker) => {
          return filteredBiomarker.id === biomarker.id
        })
      })
  )

  const labCompanyBiomarkerRanges =
    useCachedCollection<LabCompanyBiomarkerRange>(
      discreteResultsBiomarkers.flatMap((biomarker) => {
        return biomarker.relationships.lab_company_biomarker_ranges.data
      })
    )

  const getAbnormalDiscreteResults = () => {
    const results = uniqBy(
      filteredDiscreteResults.filter((discreteResult) => {
        const matchingOptimalRange = getOptimalRangeByBiomarkerId(
          discreteResult.relationships.biomarker.data?.id
        )

        // Filter out any items that don't have a normal range or standard strange defined
        if (
          !filterDiscreteResultsWithoutNormalRange(
            discreteResult,
            matchingOptimalRange
          )
        ) {
          return false
        }

        const optimalRangesSettingTurnedOn = Boolean(
          clinic?.attributes.clinic_settings.includes(
            ClinicSettings.USE_OPTIMAL_RANGES_FOR_BLOOD_REPORTS
          )
        )

        return isDiscreteResultAbnormal(
          discreteResult,
          optimalRangesSettingTurnedOn,
          matchingOptimalRange
        )
      }),
      function (item) {
        return item.relationships.biomarker.data?.id
      }
    )

    return results.sort((a, b) =>
      sortDiscreteValues(
        a,
        b,
        getOptimalRangeByBiomarkerId(a.relationships.biomarker.data?.id),
        getOptimalRangeByBiomarkerId(b.relationships.biomarker.data?.id)
      )
    )
  }

  const getOutOfOptimalRangeDiscreteResults = () => {
    const optimalRangesSettingTurnedOn = Boolean(
      clinic?.attributes.clinic_settings.includes(
        ClinicSettings.USE_OPTIMAL_RANGES_FOR_BLOOD_REPORTS
      )
    )

    if (!optimalRangesSettingTurnedOn) {
      return []
    }

    return uniqBy(
      filteredDiscreteResults.filter((discreteResult) => {
        const matchingOptimalRange = getOptimalRangeByBiomarkerId(
          discreteResult.relationships.biomarker.data?.id
        )

        // Filter out any items that don't have a normal range or standard strange defined
        if (
          !filterDiscreteResultsWithoutNormalRange(
            discreteResult,
            matchingOptimalRange
          )
        ) {
          return false
        }

        // If the discrete result is abnormal, then it is not out of optimal range
        if (
          isDiscreteResultAbnormal(
            discreteResult,
            optimalRangesSettingTurnedOn,
            matchingOptimalRange
          )
        ) {
          return false
        }

        return isDiscreteResultOutOfOptimalRange(
          discreteResult,
          matchingOptimalRange
        )
      }),
      function (item) {
        return item.relationships.biomarker.data?.id
      }
    ).sort((a, b) =>
      sortDiscreteValues(
        a,
        b,
        getOptimalRangeByBiomarkerId(a.relationships.biomarker.data?.id),
        getOptimalRangeByBiomarkerId(b.relationships.biomarker.data?.id)
      )
    )
  }

  const getMatchingDiscreteResultByBiomarkerId = (
    biomarkerId?: string
  ): DiscreteResult | undefined => {
    return filteredDiscreteResults.find((discreteResult) => {
      return discreteResult.relationships.biomarker.data?.id === biomarkerId
    })
  }
  const filterDiscreteResultsWithoutNormalRange = (
    discreteResult: DiscreteResult,
    matchingOptimalRange: LabCompanyBiomarkerRange | undefined
  ) => {
    // Filter out any items that don't have a normal range or standard strange defined
    if (
      (!discreteResult.attributes.normal_min ||
        discreteResult.attributes.normal_min === null) &&
      (!discreteResult.attributes.normal_max ||
        discreteResult.attributes.normal_max === null) &&
      (!matchingOptimalRange?.attributes.standard_range_min ||
        matchingOptimalRange?.attributes.standard_range_min === null) &&
      (!matchingOptimalRange?.attributes.standard_range_max ||
        matchingOptimalRange?.attributes.standard_range_max === null)
    ) {
      return false
    }
    return true
  }

  const getDiscreteResultsByBodySystem = (
    bodySystem: BodySystem
  ): DiscreteResult[] => {
    const discreteResults = bodySystem.relationships.biomarkers.data
      .map((biomarker) => {
        return getMatchingDiscreteResultByBiomarkerId(biomarker.id)
      })
      .filter((discreteResult) => {
        if (!discreteResult) {
          return false
        }
        const matchingOptimalRange = getOptimalRangeByBiomarkerId(
          discreteResult.relationships.biomarker.data?.id
        )
        return filterDiscreteResultsWithoutNormalRange(
          discreteResult,
          matchingOptimalRange
        )
      }) as DiscreteResult[]
    return discreteResults.sort((a, b) =>
      sortDiscreteValues(
        a,
        b,
        getOptimalRangeByBiomarkerId(a.relationships.biomarker.data?.id),
        getOptimalRangeByBiomarkerId(b.relationships.biomarker.data?.id)
      )
    )
  }

  const getDiscreteResultsByLabTest = (
    labTest: LabTest,
    bodySystemId?: string
  ): DiscreteResult[] => {
    const discreteResults = labTest.relationships.biomarkers.data
      .map((biomarker) => {
        return getMatchingDiscreteResultByBiomarkerId(biomarker.id)
      })
      .filter((discreteResult) => {
        if (!discreteResult) {
          return false
        }

        if (bodySystemId && bodySystemId !== "all") {
          const filteredBodySystems = bodySystems.filter(
            (bodySystem) => bodySystemId === bodySystem.id
          )
          const biomarkerIds = filteredBodySystems.flatMap((bodySystem) =>
            bodySystem.relationships.biomarkers.data.map(
              (biomarker) => biomarker.id
            )
          )
          if (
            !biomarkerIds.includes(
              discreteResult.relationships.biomarker?.data?.id
            )
          ) {
            return false
          }
        }

        const matchingOptimalRange = getOptimalRangeByBiomarkerId(
          discreteResult.relationships.biomarker.data?.id
        )
        return filterDiscreteResultsWithoutNormalRange(
          discreteResult,
          matchingOptimalRange
        )
      }) as DiscreteResult[]

    return discreteResults.sort((a, b) =>
      sortDiscreteValues(
        a,
        b,
        getOptimalRangeByBiomarkerId(a.relationships.biomarker.data?.id),
        getOptimalRangeByBiomarkerId(b.relationships.biomarker.data?.id)
      )
    )
  }

  const getOptimalRangeByBiomarkerId = (biomarkerId?: string) => {
    return labCompanyBiomarkerRanges.find((optimalRange) => {
      return (
        optimalRange.relationships.biomarker.data?.id === biomarkerId &&
        orderedResult?.relationships.lab_company.data.id ===
          optimalRange.relationships.lab_company.data?.id
      )
    })
  }

  return {
    discreteResults: filteredDiscreteResults,
    discreteResultsBiomarkers,
    missingBiomarkers,
    totalBiomarkers,
    labTests,
    bodySystems: sortedBodySystems,
    isLoading: isDiscreteResultsLoading,
    orderedResult,
    patient,
    practitioner,
    clinic,
    labCompanyBiomarkerRanges,
    error,
    getDiscreteResultsByBodySystem,
    getDiscreteResultsByLabTest,
    getOutOfOptimalRangeDiscreteResults,
    getAbnormalDiscreteResults,
    getMatchingDiscreteResultByBiomarkerId,
    getMatchingOptimalRangeByBiomarkerId: getOptimalRangeByBiomarkerId,
    mutate,
  }
}
