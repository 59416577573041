import { Grid, styled } from "@material-ui/core"

import PuzzleDarkIcon from "app/assets/icons/puzzle-dark.svg"
import BodyText from "app/components/design-system/BodyText"
import LabTestCard from "app/main/checkout/LabTestCard"
import { colors } from "app/theme"
import {
  AnyLabTest,
  LabTest,
  LabTestAddon,
  labTestLocation,
  Practitioner,
} from "app/types"

const StyledGridContainer = styled(Grid)({
  padding: "0px 25px",
})

const StyledIconImage20 = styled("img")({
  width: 20,
})

const BodyContainer = styled("div")({
  marginTop: 14,
  display: "flex",
  flexDirection: "row",
})
const StyledAddonName = styled("p")({
  paddingLeft: "6px",
  alignItems: "center",
  fontWeight: 600,
})

const SectionContainer = styled("div")({
  paddingTop: 18,
  paddingBottom: 18,
  borderBottom: `1px solid ${colors.blueGray[200]}`,
  display: "flex",
  flexDirection: "column",
  alignItems: "start",
  width: "100%",
  height: "100%",
})

interface Props {
  labTest: LabTest
  onAdd: (labTest: AnyLabTest) => void
  onRemove: (labTest: AnyLabTest) => void
  selectedLabTests: { [labTestId: string]: AnyLabTest }
  practitioner: Practitioner
  onToggleFavorite: (labTest: AnyLabTest) => void
  setAddOnOpenInModal: (labTest: LabTestAddon | null) => void
  location?: labTestLocation
  createBundleClick?: () => void
}

const AddOnSection = ({
  labTest,
  onAdd,
  onRemove,
  selectedLabTests,
  practitioner,
  onToggleFavorite,
  setAddOnOpenInModal,
  location,
  createBundleClick,
}: Props) => {
  return (
    <StyledGridContainer container aria-label="Addons">
      <Grid item xs={12}>
        <SectionContainer>
          <BodyText size="sm">Available Add-Ons</BodyText>
          <BodyContainer>
            <StyledIconImage20 src={PuzzleDarkIcon} alt="Addons" />
            <StyledAddonName>For {labTest.name}</StyledAddonName>
          </BodyContainer>
          <Grid container spacing={2} className="mt-2">
            {labTest.add_ons.map((labTestAddon) => (
              <Grid
                item
                xs={12}
                sm={6}
                lg={4}
                className="pl-8"
                key={labTestAddon.id}
              >
                <LabTestCard
                  practitioner={practitioner}
                  signingPractitioner={practitioner}
                  selectedLabTests={selectedLabTests}
                  labTest={labTestAddon}
                  isFavorite={practitioner.favorite_lab_test_id_set?.has(
                    labTestAddon.id
                  )}
                  onAdd={() => onAdd(labTestAddon)}
                  onRemove={() => onRemove(labTestAddon)}
                  onToggleFavorite={() => onToggleFavorite(labTestAddon)}
                  openModal={() => {
                    setAddOnOpenInModal(labTestAddon)
                  }}
                  modalPage={location}
                />
              </Grid>
            ))}
          </Grid>
        </SectionContainer>
      </Grid>
    </StyledGridContainer>
  )
}

export default AddOnSection
