import useEventCallback from "app/hooks/use-event-callback"
import useCachedCollection from "app/swr/hooks/use-cached-collection"
import useCollectionSWR from "app/swr/hooks/use-collection-swr"
import { ResourceCollection, ResourceResponse } from "app/swr/types"
import resourceRequest from "app/swr/utils/resource-request"
import { handleApiError } from "app/utils"
import { ResultVisualizationNotification } from "types/result-visualization-notification"

export default function useResultVisualizationNotifications(patientId: string) {
  const { data, error, isLoading, isValidating, mutate } = useCollectionSWR<
    ResourceCollection<ResultVisualizationNotification>
  >(
    `/result_visualization_notifications/`,
    {
      params: {
        "filter[patient.id]": patientId,
      },
    },
    {
      revalidateIfStale: true,
      revalidateOnMount: true,
      revalidateOnReconnect: true,
    }
  )

  const notifications =
    useCachedCollection<ResultVisualizationNotification>(data)

  const acknowledgeNotification = useEventCallback(
    async (notificationId: string) => {
      try {
        await mutate(
          async (previousData) => {
            if (!previousData) {
              return previousData
            }

            await resourceRequest<
              ResourceResponse<ResultVisualizationNotification>
            >({
              method: "post",
              url: `/result_visualization_notifications/${notificationId}/acknowledge/`,
            })

            const nextData = previousData.data.filter(
              (notification) => notification.id !== notificationId
            )

            const nextMeta = {
              ...previousData.meta,
              count: previousData.meta ? previousData.meta.count - 1 : 0,
            }

            return { data: nextData, meta: nextMeta }
          },
          {
            revalidate: true,
            rollbackOnError: false,
            throwOnError: true,
          }
        )
      } catch (error) {
        handleApiError(error)
      }
    }
  )

  const dismissNotification = useEventCallback(
    async (notificationId: string) => {
      try {
        await mutate(
          async (previousData) => {
            if (!previousData) {
              return previousData
            }

            await resourceRequest<
              ResourceResponse<ResultVisualizationNotification>
            >({
              method: "post",
              url: `/result_visualization_notifications/${notificationId}/dismiss/`,
            })

            const nextData = previousData.data.filter(
              (notification) => notification.id !== notificationId
            )

            const nextMeta = {
              ...previousData.meta,
              count: previousData.meta ? previousData.meta.count - 1 : 0,
            }

            return { data: nextData, meta: nextMeta }
          },
          {
            revalidate: true,
            rollbackOnError: false,
            throwOnError: true,
          }
        )
      } catch (error) {
        handleApiError(error)
      }
    }
  )

  return {
    notifications,
    error,
    isLoading,
    isValidating,
    acknowledgeNotification,
    dismissNotification,
  }
}
