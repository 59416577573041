/**
 * Generic component for displaying "Action Required" modals.
 *
 * This component can be previewed in Storybook (Modals -> ActionRequiredModal)
 */
import clsx from "clsx"

import { makeStyles, Theme, Typography } from "@material-ui/core"

import { Dialog } from "app/components/modals"
import usePreloadImages from "app/hooks/use-preload-image"
import { colors, shadows } from "app/theme"

interface StyleProps {}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  actionButton: {
    display: "flex",
    justifyContent: "center",
    fontSize: "15px",
    marginTop: "24px",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  title: {
    marginBottom: "8px",
    textAlign: "center",
  },
  subtitle: {
    maxWidth: "512px",
    textAlign: "center",
    whiteSpace: "pre-wrap",
  },
  modal: {
    borderRadius: 12,
    border: "6px solid white",
    boxShadow: shadows["2xl"],
    maxWidth: 524,
    width: "100%",
    backgroundColor: colors.blueGray[50],
  },
  content: {
    padding: "32px 24px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  image: {
    height: 180,
    borderBottom: `1px solid ${colors.blueGray[200]}`,
    width: "100%",

    [theme.breakpoints.up("xs")]: {
      height: 280,
    },
  },
}))

export interface Props {
  button: any
  image: string
  imageAlt: string
  onClose?: () => void
  open: boolean
  subtitle: string
  testId?: string
  title: string
}

function ActionRequiredModal({
  button,
  image,
  imageAlt,
  onClose,
  open,
  subtitle,
  testId = "",
  title,
}: Props) {
  const styles = useStyles()

  // Preload the image before opening the modal. This stops any jankiness when the image is remote.
  const imagePreloaded = usePreloadImages([image])
  const modalIsOpen = open && imagePreloaded

  return (
    <Dialog
      data-testid={testId}
      aria-describedby="description"
      aria-labelledby="title"
      disableBackdropClick
      disableEscapeKeyDown
      onClose={onClose}
      open={modalIsOpen}
      classes={{
        paper: styles.modal,
      }}
    >
      <div className={styles.container}>
        <img alt={imageAlt} src={image} className={styles.image} />
        <div className={styles.content}>
          <Typography
            className={styles.title}
            color="textPrimary"
            id="title"
            variant="h5"
          >
            {title}
          </Typography>
          <Typography
            className={clsx("fs-exclude", styles.subtitle)}
            color="textPrimary"
            id="description"
          >
            {subtitle}
          </Typography>

          <div className={styles.actionButton}>{button}</div>
        </div>
      </div>
    </Dialog>
  )
}

export default ActionRequiredModal
