import SampleTypePill from "app/components/SampleTypePill"
import { OrderedTest } from "app/types"
import makeAppStyles from "app/utils/makeAppStyles"

const useStyles = makeAppStyles((theme) => ({
  sampleTypeWrapper: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "left",
  },
}))

export default function PatientOrdersOrderedTestSampleType({
  orderedTest,
}: {
  orderedTest: OrderedTest
}) {
  const styles = useStyles()
  const { lab_test } = orderedTest
  return (
    <div className={styles.sampleTypeWrapper}>
      {lab_test.lab_test_types &&
        lab_test.lab_test_types.map((lab_test_type) => {
          const testType = lab_test_type.name.toLowerCase()
          return (
            <SampleTypePill
              name={testType}
              key={testType}
              style={{
                marginLeft: 0,
                marginRight: 2,
              }}
            />
          )
        })}
    </div>
  )
}
