import { IconDefinition } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { cn } from "@rupahealth/design"

import BodyText from "app/components/design-system/BodyText"

interface Props {
  icon: IconDefinition
  text: string
  type: "info" | "warning"
  className?: string
}

const typeToColors: { [type in Props["type"]]: { bg: string; text: string } } =
  {
    info: {
      bg: "bg-sky-50",
      text: "text-navy",
    },
    warning: {
      bg: "bg-yellow-50",
      text: "text-amber-900",
    },
  }

function PatientCheckoutBanner({ icon, text, type, className }: Props) {
  const { bg: bgColor, text: textColor } = typeToColors[type]

  return (
    <div
      className={cn("flex py-2.5 px-4 rounded-lg gap-2.5", bgColor, className)}
    >
      <FontAwesomeIcon className={cn(textColor, "py-1")} icon={icon} />
      <BodyText weight="semibold" className={textColor}>
        {text}
      </BodyText>
    </div>
  )
}

export default PatientCheckoutBanner
