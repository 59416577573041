import * as moment from "moment-timezone"

/**
 * To ensure that the date is displayed correctly for the users local timezone
 * convert the date string to a timezone aware date. This prevents off by one date issues.
 * @param naiveDate
 */
export function loadDate(naiveDate) {
  return moment(naiveDate).toDate()
}
