import { FormHelperText, Grid, Typography, makeStyles } from "@material-ui/core"
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
} from "@stripe/react-stripe-js"

import PaymentMethodBase from "app/main/patient-checkout/sections/payment-methods/PaymentMethodBase"
import { lightGray } from "app/theme"
import { PaymentMethodType } from "app/types"

interface CardPaymentMethodProps {
  active: boolean
  paymentMethodError?: string
  disabled: boolean
}

const CardPaymentMethod = ({
  active,
  paymentMethodError,
  disabled,
}: CardPaymentMethodProps) => {
  const classes = useStyles()
  return (
    <PaymentMethodBase
      active={active}
      className={classes.wrapper}
      header={
        <div className={classes.headerWrapper}>
          <Typography
            className={classes.headerTitle}
            color="primary"
            component="span"
          >
            Pay in full now
          </Typography>{" "}
          <Typography
            className={classes.headerSubtitle}
            color="textPrimary"
            component="span"
          >
            (Credit, Debit, HSA and FSA accepted)
          </Typography>
        </div>
      }
      title="Pay in full now"
      type={PaymentMethodType.CARD}
      disabled={disabled}
    >
      <AddStripeCard paymentMethodError={paymentMethodError} />
    </PaymentMethodBase>
  )
}

const AddStripeCard = ({ paymentMethodError }) => {
  const inputClassNames = "rounded border-1 border-gray-300 p-2 bg-white"

  return (
    <div style={{ backgroundColor: lightGray }} className="px-6 py-3">
      <Grid container spacing={1}>
        <Grid item xs={12} md={6} data-testid="stripe-card-number">
          <CardNumberElement
            className={inputClassNames}
            options={{ placeholder: "Card Number" }}
          />
        </Grid>

        <Grid item xs={6} md={3} data-testid="stripe-card-exp">
          <CardExpiryElement
            className={inputClassNames}
            options={{ placeholder: "MM / YY" }}
          />
        </Grid>

        <Grid item xs={6} md={3} data-testid="stripe-card-cvc">
          <CardCvcElement
            className={inputClassNames}
            options={{ placeholder: "CVC" }}
          />
        </Grid>
      </Grid>

      {paymentMethodError && (
        <FormHelperText error>{paymentMethodError}</FormHelperText>
      )}
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  wrapper: {
    borderTopLeftRadius: "8px",
    borderTopRightRadius: "8px",
  },
  headerWrapper: {
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
    },
  },
  headerTitle: {
    fontWeight: theme.typography.fontWeightMedium,
  },
  headerSubtitle: {
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(0.4),
    },
  },
}))

export default CardPaymentMethod
