import { colors } from "@material-ui/core"
import * as DropdownMenu from "@radix-ui/react-dropdown-menu"

import TaskIcon from "app/results-summary/components/ResultsSummaryEditor/components/TaskIcon"
import { getTaskTypeLabel } from "app/results-summary/components/ResultsSummaryEditor/utils"
import { primaryColor } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"
import { TaskTypesLiteral, TASK_TYPES } from "types/results-interpretation"

const useStyles = makeAppStyles((theme) => ({
  option: {
    height: 32,
    width: "100%",
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1.0),
    padding: theme.spacing(0.75, 1.25),
    borderRadius: 6,
    fontSize: 14,
    fontWeight: 600,
    color: primaryColor,
    cursor: "pointer",
    "&:focus": {
      backgroundColor: colors.blue[50],
    },
  },
}))

export interface AddTaskOptionsProps {
  onSelect: (taskType: TaskTypesLiteral) => void
}

export default function AddTaskOptions({ onSelect }: AddTaskOptionsProps) {
  const classes = useStyles()
  return (
    <>
      {TASK_TYPES.map((taskType) => (
        <DropdownMenu.Item
          key={taskType}
          className={classes.option}
          onSelect={() => onSelect(taskType)}
        >
          <TaskIcon taskType={taskType} />
          {getTaskTypeLabel(taskType)}
        </DropdownMenu.Item>
      ))}
    </>
  )
}
