import createAuth0Client from "@auth0/auth0-spa-js"
import { makeStyles } from "@material-ui/core"

import officeBlockIcon from "app/assets/icons/office-block-light-blue.svg"
import Button from "app/components/design-system/Button"
import { buildAuth0Config } from "app/settings"
import { getApiBaseUrl, getBaseUrl } from "app/utils"

const useStyles = makeStyles({
  button: {
    width: "100%",
  },
})

function Auth0LoginTab() {
  const { button } = useStyles()

  async function showDialog() {
    const auth0Config = buildAuth0Config(getBaseUrl(), getApiBaseUrl())
    const auth0 = await createAuth0Client(auth0Config)
    await auth0.loginWithRedirect()
  }

  return (
    <Button
      color="secondary"
      onClick={showDialog}
      icon={officeBlockIcon}
      className={button}
    >
      Sign In With Enterprise Account
    </Button>
  )
}

export default Auth0LoginTab
