import { useState } from "react"

import RupaLinearProgress from "app/components/design-system/RupaLinearProgress"
import useStatusComponents from "app/components/hooks/use-status-components"
import { STATUS_CONFIGURATION_TYPES } from "app/components/hooks/use-status-components/consts"
import statusConfigurations from "app/components/hooks/use-status-components/status-configurations/patient-portal"
import { LAB_COMPANY_ALERT_TYPES } from "app/constants"
import {
  PatientPortalLabCompany,
  PatientPortalLabCompanyAlert,
  PatientPortalOrder,
  PatientPortalOrderedTest,
  PatientPortalPractitioner,
} from "app/patient-portal/types"
import useCachedCollection from "app/swr/hooks/use-cached-collection"
import useCachedResource from "app/swr/hooks/use-cached-resource"
import { SpecimenIssueNormalized } from "types/specimen-issue"

export default function PatientPortalOrderKitListKitTestStatus({
  labCompany,
  orderedTest,
}: {
  labCompany: PatientPortalLabCompany
  orderedTest: PatientPortalOrderedTest
}) {
  const order = useCachedResource<PatientPortalOrder>({
    type: "patient_portal_order",
    id: orderedTest.relationships.order.data.id,
  })
  const specimenIssue = useCachedResource<SpecimenIssueNormalized>(
    orderedTest.relationships.specimen_issue?.data
  )
  const practitioner = useCachedResource<PatientPortalPractitioner>(
    order?.relationships.practitioner.data
  )
  const labCompanyAlerts = useCachedCollection<PatientPortalLabCompanyAlert>(
    orderedTest.relationships.active_alerts?.data
  )
  const resultsDelayAlert = labCompanyAlerts.find(
    (alert) =>
      alert.attributes.alert_type === LAB_COMPANY_ALERT_TYPES.RESULTS_DELAY
  )
  const [specimenIssueShowMore, setSpecimenIssueShowMore] = useState(false)

  const { lastUpdatedAt, progressPercentage, subTitle, title } =
    useStatusComponents({
      type: STATUS_CONFIGURATION_TYPES.PATIENT_PORTAL,
      statusConfigurations,
      orderedTest,
      order,
      practitioner,
      labCompany,
      specimenIssue,
      specimenIssueShowMore,
      setSpecimenIssueShowMore,
      labCompanyAlert: resultsDelayAlert,
    })

  if (!order) return null

  return (
    <div className="flex flex-col gap-1 break-words">
      <p className="text-sm">{lastUpdatedAt}</p>
      <div>
        <div className="text-lg17 font-semibold">{title}</div>
        <RupaLinearProgress
          aria-label="Order status progress"
          progressPercentage={progressPercentage}
        />
        <div className="flex items-center gap-1">{subTitle}</div>
      </div>
    </div>
  )
}
