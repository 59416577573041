import { KIT_STATUS, ORDER_STATUS } from "app/constants.typed"
import formatDateForHuman from "app/utils/format-date-for-human"

import { StatusConfiguration } from "../../types"
import { StatusConfigurationProps } from "../../types/dashboard-types"
import { KitStatuses } from "../../types/types"
import { PROGRESS_PERCENTAGES } from "../consts"

const SubTitle = ({ kit }: StatusConfigurationProps) =>
  kit.attributes.date_canceled
    ? `This kit was canceled on ${formatDateForHuman(
        kit.attributes.date_canceled
      )}`
    : ""

const Title = ({ order }: StatusConfigurationProps) => {
  return order.attributes.cached_status === ORDER_STATUS.CANCELED
    ? "Order Canceled"
    : "Test Canceled"
}

const LastUpdatedAt = ({ kit }: StatusConfigurationProps) => {
  return kit.attributes.date_canceled
    ? formatDateForHuman(kit.attributes.date_canceled)
    : ""
}

const canceledStatusConfiguration: StatusConfiguration<StatusConfigurationProps> =
  {
    when: ({ orderStatus, kitStatus }: KitStatuses) =>
      orderStatus === ORDER_STATUS.CANCELED ||
      kitStatus === KIT_STATUS.CANCELED,
    progressPercentage: PROGRESS_PERCENTAGES.CANCELED,
    title: Title,
    subTitle: SubTitle,
    lastUpdatedAt: LastUpdatedAt,
  }

export default canceledStatusConfiguration
