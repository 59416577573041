import { PaymentMethodType } from "app/types"

export default class CheckoutPaymentMethodError extends Error {
  public type?: PaymentMethodType

  constructor(message: string, type?: PaymentMethodType) {
    super(message)
    this.name = "CheckoutPaymentMethodError"
    this.type = type

    Object.setPrototypeOf(this, CheckoutPaymentMethodError.prototype)
  }
}
