import { memo } from "react"

import PhlebotomistMapMarker from "app/main/phlebotomy-map/PhlebotomistMapMarker"
import { PhlebotomistFeature } from "app/types"

interface PhlebomistMapMarkersProps {
  features: PhlebotomistFeature[]
  onClick: (feature: PhlebotomistFeature) => void
}

const PhlebotomistMapMarkers = ({
  features,
  onClick,
}: PhlebomistMapMarkersProps) => {
  return (
    <>
      {features.map((feature) => {
        return (
          <PhlebotomistMapMarker
            key={feature.properties.id}
            latitude={feature.geometry.coordinates[1]}
            longitude={feature.geometry.coordinates[0]}
            onClick={() => onClick(feature)}
            type={feature.properties.location_types}
          />
        )
      })}
    </>
  )
}

/**
 * Use React.memo as a container for markers for improved rendering performance.
 *
 * React.memo only checks for prop changes on the component, reducing the number of redraws
 *
 * Read more here: https://reactjs.org/docs/react-api.html#reactmemo
 */
const MemoizedPhlebotomistMapMarkers = memo(PhlebotomistMapMarkers)

export default MemoizedPhlebotomistMapMarkers
