import * as React from "react"

import { Theme } from "@material-ui/core"
import MuiDialogTitle from "@material-ui/core/DialogTitle"
import IconButton from "@material-ui/core/IconButton"
import CloseIcon from "@material-ui/icons/Close"

import DesignSystemButton from "app/components/design-system/Button"
import DisplayText from "app/components/design-system/DisplayText"
import { Dialog } from "app/components/modals"
import ClipboardItem from "app/components/modals/ClipboardModal/ClipboardItem"
import ClipboardContainer from "app/components/modals/PhysicianServicesOptInModal/ClipboardContainer"
import { colors, navy } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"

const styles = makeAppStyles((theme: Theme) => ({
  closeButton: {
    color: navy,
    position: "absolute" as "absolute",
    top: 0,
    right: 0,
    width: 40,
    height: 40,
  },
  continueButtonContainer: {
    padding: "16px 24px",
  },
  continueButton: {
    width: 532,
    marginBottom: 30,
  },
  dialogPaper: {
    margin: 15,
    borderRadius: 7,
    width: "800px",
    [theme.breakpoints.down("sm")]: {
      width: "96%",
      height: "100%",
      margin: 0,
    },
  },
  contentContainer: {
    "text-align": "center",
    padding: 5,
    background: colors.blueGray[100],
  },
  logo: {
    marginRight: 8,
    marginBottom: -6,
    margin: "auto",
    width: "32",
    height: "auto",
  },
  modalPage: {
    display: "flex",
    flexFlow: "column",
    alignItems: "stretch",
    background: colors.blueGray[50],
    overflow: "auto",
    height: "100%",
  },
  topText: {
    marginTop: 20,
  },
}))

interface ClipboardModalProps {
  modalTitle: string
  clipboardText: string
  items?: React.ReactNode[]
  sections?: {
    title: string
    items: string[]
  }[]
  showModal: boolean
  onExit: () => void
  onContinue: () => void
  buttonText?: string
  ariaLabel?: string
  showCloseButton?: boolean
}

export const ClipboardModal = ({
  modalTitle,
  clipboardText,
  items,
  showModal,
  onExit,
  onContinue,
  buttonText = "Continue",
  ariaLabel = "lab-test-bundle-title",
  showCloseButton = true,
}: ClipboardModalProps) => {
  const classes = styles()

  if (!showModal) {
    return null
  }

  const modalContent = (
    <>
      <ClipboardContainer title={clipboardText}>
        {items &&
          items.map((item, index) => {
            return (
              <ClipboardItem
                key={index}
                text={item}
                containerStyles={
                  index === items.length - 1 ? { marginBottom: 40 } : {}
                }
              />
            )
          })}
      </ClipboardContainer>
      <div className={classes.continueButtonContainer}>
        <DesignSystemButton
          className={classes.continueButton}
          color="primary"
          onClick={onContinue}
        >
          {buttonText}
        </DesignSystemButton>
      </div>
    </>
  )

  return (
    <Dialog
      onClose={onExit}
      aria-labelledby={ariaLabel}
      open={showModal}
      className="fs-unmask"
      classes={{ paper: classes.dialogPaper }}
      maxWidth="md"
    >
      {showCloseButton && (
        <IconButton
          aria-label="close"
          onClick={onExit}
          className={classes.closeButton}
          key="close-button"
        >
          <CloseIcon />
        </IconButton>
      )}
      <div className={classes.modalPage}>
        <div className={classes.contentContainer}>
          <MuiDialogTitle disableTypography>
            <div>
              <DisplayText
                weight="semibold"
                size="2xl"
                className={classes.topText}
              >
                {modalTitle}
              </DisplayText>
            </div>
          </MuiDialogTitle>
          {modalContent}
        </div>
      </div>
    </Dialog>
  )
}
