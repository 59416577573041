import { useMemo } from "react"

import clsx from "clsx"

import { Theme } from "@material-ui/core"
import { Skeleton } from "@rupahealth/design"

import makeAppStyles from "app/utils/makeAppStyles"

import {
  STICKY_TABLE_COLUMN_WIDTH,
  STICKY_TABLE_COLUMN_WIDTH_MOBILE,
} from "../constants/constants"
import { calculateTableCellWidth } from "../util"

const useStyles = makeAppStyles((theme: Theme) => ({
  stickyCol: {
    left: 0,
    position: "absolute",
    top: "auto",
    width: STICKY_TABLE_COLUMN_WIDTH,
    textAlign: "left !important" as any,
    boxShadow: "none",
    [theme.breakpoints.down(640)]: {
      width: STICKY_TABLE_COLUMN_WIDTH_MOBILE,
    },
  },
}))

const BiomarkerResultRowSkeleton = ({ availableDates, tableWidth }) => {
  const classes = useStyles()

  const cellWidth = useMemo(() => {
    return calculateTableCellWidth(availableDates.length, tableWidth)
  }, [availableDates, tableWidth])

  return (
    <tr>
      <td className={clsx(classes.stickyCol, "pr-2 py-8 md:py-3")}>
        <Skeleton className="w-full h-12" />
      </td>
      {Boolean(availableDates.length) ? (
        availableDates.map((date) => (
          <td
            key={date}
            className={`py-8 md:py-3 px-2`}
            style={{
              width: `${cellWidth}%`,
              minWidth: `${cellWidth}%`,
            }}
          >
            <Skeleton className="w-full h-12" />
          </td>
        ))
      ) : (
        // If there are no available dates it means that we are reloading all available data.
        // In this scenario, we do not know the number of date columns to render, so we default
        // to 3 columns.
        <>
          <td
            className={`py-8 md:py-3 px-2`}
            style={{
              minWidth: "33%",
            }}
          >
            <Skeleton className="w-full h-12" />
          </td>
          <td
            className={`py-8 md:py-3 px-2`}
            style={{
              minWidth: "33%",
            }}
          >
            <Skeleton className="w-full h-12" />
          </td>
          <td
            className={`py-8 md:py-3 px-2`}
            style={{
              minWidth: "33%",
            }}
          >
            <Skeleton className="w-full h-12" />
          </td>
        </>
      )}
    </tr>
  )
}

export default BiomarkerResultRowSkeleton
