import { createContext } from "react"

import { AchConnectionStatus } from "app/types"

export interface AchFieldsContextShape {
  publicToken?: string
  accountId?: string
  last4?: string
  bankName?: string
  accountName?: string
  updateValues?: (values: object) => void
  status?:
    | AchConnectionStatus.COMPLETE
    | AchConnectionStatus.INCOMPLETE
    | AchConnectionStatus.NOT_STARTED
  openPlaid?: () => void
}

export default createContext<AchFieldsContextShape>({})
