import { useEffect } from "react"

import usePhysicianServicesOptInModal from "app/components/modals/PhysicianServicesOptInModal/use-physician-services-opt-in-modal"
import { PHYSICIAN_SERVICES_ENABLE_METHODS } from "app/constants"
import useFeatureFlag, { FeatureFlag } from "app/hooks/use-feature-flag"
import useAppSelector from "app/hooks/useAppSelector"

function usePhysicianServicesOptInModalListener() {
  const [physicianServicesBackfillPhase2Enabled] = useFeatureFlag(
    FeatureFlag.PhysicianServicesBackfillPhase2
  )
  const physicianServicesOptInModal = usePhysicianServicesOptInModal()

  const practitioner = useAppSelector(({ practitioner }) => practitioner)

  useEffect(() => {
    if (
      physicianServicesBackfillPhase2Enabled &&
      practitioner &&
      practitioner.vendor_physician_authorization_enabled &&
      practitioner.physician_services_enable_method !==
        PHYSICIAN_SERVICES_ENABLE_METHODS.OPT_IN
    ) {
      physicianServicesOptInModal.show({
        updatedTerms: true,
        disableClose: true,
        returnToDescriptor: "Dashboard",
        practitionerHasPhysicianServicesLabshop:
          practitioner.has_published_physician_services_labshop,
        onClose: () => {
          physicianServicesOptInModal.remove()
        },
      })
    }
  }, [physicianServicesBackfillPhase2Enabled, practitioner])
}

export default function PhysicianServicesOptInModalListener() {
  usePhysicianServicesOptInModalListener()
  return null
}
