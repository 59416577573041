import { useEffect, useState } from "react"

import axios, { AxiosError } from "axios"
import { set } from "lodash"
import { MutatorCallback } from "swr"

import { getInitialValue } from "app/components/RichTextEditor"
import useEventCallback from "app/hooks/use-event-callback"
import useCachedResource from "app/swr/hooks/use-cached-resource"
import {
  ResourceErrorResponse,
  ResourceIdentifier,
  ResourceResponse,
} from "app/swr/types"
import resourceRequest from "app/swr/utils/resource-request"
import { Order } from "types/order"
import { Patient } from "types/patient"
import { ResultsInterpretation } from "types/results-interpretation"

export default function useInitializeInterpretation({
  data: order,
  mutate: mutateOrder,
}: {
  data: Order | undefined
  mutate: (mutator: MutatorCallback<Order>, mutatorOptions: any) => void
}) {
  const [isInitializing, setIsInitializing] = useState(false)
  const [outOfRangeIdentifiers, setOutOfRangeIdentifiers] = useState<
    ResourceIdentifier[]
  >([])
  const [initializationError, setInitializationError] =
    useState<AxiosError<ResourceErrorResponse>>()
  const patient = useCachedResource<Patient>(order?.relationships.patient.data)

  const hasOrder = Boolean(order)
  const hasPatient = Boolean(patient)
  const interpretationIdentifier =
    order?.relationships.results_interpretation.data

  const initializeInterpretation = useEventCallback(async () => {
    if (isInitializing || !order || !hasPatient) {
      // Only one ongoing initialization, and only if we have an order.
      return
    }

    setIsInitializing(true)

    try {
      const { data } = await resourceRequest<
        ResourceResponse<ResultsInterpretation>
      >({
        method: "post",
        url: "/results_interpretations/",
        data: {
          data: {
            type: "results_interpretation",
            attributes: {
              content_intro: getInitialValue(
                `Hi ${patient?.attributes.first_name}! I've highlighted your labs that are out of range here, and your next steps that we discussed.`
              ),
              content_next_steps: getInitialValue(
                "Based on your results, I recommend we start with..."
              ),
              content_symptoms: getInitialValue(),
              title_intro: `${patient?.attributes.first_name}'s Labs`,
              title_next_steps: "Next Steps",
            },
            relationships: {
              order: {
                data: { type: "order", id: order.id },
              },
            },
          },
        },
      })

      const identifier = { type: data.type, id: data.id }

      mutateOrder(
        (prevOrder = order) =>
          set<Order>(
            prevOrder,
            "relationships.results_interpretation.data",
            identifier
          ),
        { revalidate: false }
      )

      setOutOfRangeIdentifiers(data.relationships.biomarkers.data)

      window.Intercom("trackEvent", "interpret-results")
    } catch (error: any) {
      if (axios.isAxiosError(error)) {
        setInitializationError(error)
      }

      throw error
    } finally {
      setIsInitializing(false)
    }
  })

  useEffect(() => {
    if (hasOrder && hasPatient && !interpretationIdentifier) {
      initializeInterpretation()
    }
  }, [hasOrder, hasPatient, interpretationIdentifier])

  return {
    initializationError,
    isInitializing,
    outOfRangeBiomarkers: {
      outOfRangeIdentifiers,
      setOutOfRangeIdentifiers,
    },
  }
}
