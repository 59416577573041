import React, { useMemo } from "react"

import { Grid } from "@material-ui/core"

import InfoBanner from "app/components/design-system/banners/InfoBanner"
import { SpecimenIssueActionUnion } from "app/constants.typed"

import { useSortedSpecimenIssueActions } from "../helpers"
import SpecimenIssueActionItem from "./SpecimenIssueActionItem"
import { SpecimenIssueResolveModalProps } from "./SpecimenIssueResolveModal"

interface Props
  extends Pick<
    SpecimenIssueResolveModalProps,
    "specimenIssue" | "testDisplayName" | "patient"
  > {
  actionLoading: SpecimenIssueActionUnion | undefined
  onActionSelect: (action: SpecimenIssueActionUnion) => void
}

const ModalContentStep1ChooseAction: React.FC<Props> = ({
  specimenIssue,
  testDisplayName,
  patient,
  actionLoading,
  onActionSelect,
}) => {
  const title = useMemo(() => {
    if (patient?.first_name) {
      return `Resolve ${patient.first_name}'s Specimen Issue`
    }
    return "Resolve Specimen Issue"
  }, [patient?.first_name])
  const actions = useSortedSpecimenIssueActions(specimenIssue.available_actions)

  return (
    <div className="pt-[21.5px] pb-[34px] px-6 overflow-x-hidden text-body text-base15">
      <div className="font-title space-y-[3px] mb-4">
        <p className="text-xl19 font-semibold">{title}</p>
        <p>{testDisplayName}</p>
      </div>
      <InfoBanner
        body={
          <p>
            <span
              dangerouslySetInnerHTML={{
                __html: specimenIssue.description.replace(/\n/g, "<br>"),
              }}
            />
          </p>
        }
      />
      <div className="border-t border-t-slate-200 my-4" />
      <div className="-m-[5px]">
        <Grid container>
          {actions.map((action) => (
            <Grid key={action} item xs={12} sm={6} className="p-[5px]">
              <SpecimenIssueActionItem
                action={action}
                onSelect={onActionSelect}
                loading={action === actionLoading}
              />
            </Grid>
          ))}
        </Grid>
      </div>
      <div className="border-t border-t-slate-200 my-4" />
      <div className="flex flex-col">
        <p className="text-base text-center text-slate-500">
          Partial refunds may be provided if there's a phlebotomy issue.
        </p>
      </div>
    </div>
  )
}

export default ModalContentStep1ChooseAction
