import clsx from "clsx"

import { makeStyles } from "@material-ui/core"

import { UserPaths } from "app/Routes"
import AddBlueIcon from "app/assets/icons/add-blue.svg"
import CogIconActive from "app/assets/images/cog-icon-blue2.svg"
import CogIcon from "app/assets/images/cog-icon-gray.svg"
import OrderIconActive from "app/assets/images/stacked-profile-blue2.svg"
import OrderIcon from "app/assets/images/stacked-profile-gray.svg"
import BarGraphIconActive from "app/assets/images/storefront/bar-graph-active.svg"
import BarGraphIcon from "app/assets/images/storefront/bar-graph.svg"
import NavBarItem from "app/components/NavBar/NavBarItem"
import PageToolbar from "app/components/PageToolbar"
import DesignSystemButton from "app/components/design-system/Button"
import { StorefrontStatus } from "app/constants.typed"
import { StorefrontType } from "app/hooks/use-resource"
import { colors, navy } from "app/theme"

interface Props {
  storefronts: StorefrontType[]
  createNewLabShop: () => void
  selectedStorefrontId: string
  selectStorefront: (storefrontId: string) => void
  isMobile: boolean
}

const useStyles = makeStyles((theme) => ({
  wrapper: {
    background: "white",
    border: `1px solid ${colors.coolGray[200]}`,
    height: "100vh",
    overflowY: "scroll",
    position: "fixed",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      top: 62,
      zIndex: 9998,
    },
    [theme.breakpoints.only("md")]: {
      width: "calc(25% - 19px)",
    },
    width: "calc(25% - 66px)",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    "-ms-overflow-style": "none" /* IE and Edge */,
    "scrollbar-width": "none" /* Firefox */,
  },
  storeSetupContainer: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
  storefrontOn: {
    padding: "2px 8px",
    background: colors.emerald[100],
    color: colors.emerald[700],
    fontWeight: "bold",
    border: "1px solid white",
    borderRadius: 49,
    fontSize: 13,
    height: "fit-content",
  },
  storefrontOff: {
    padding: "2px 8px",
    background: colors.blueGray[200],
    color: colors.blueGray[700],
    fontWeight: "bold",
    border: "1px solid white",
    borderRadius: 49,
    fontSize: 13,
    height: "fit-content",
  },
  newLabShopBtn: {
    fontSize: 17,
    marginLeft: 20,
    marginTop: 13,
  },
  storeNavContainer: {
    width: "calc(100% - 20)",
    display: "flex",
    "flex-direction": "row",
    margin: 10,
    padding: 12,
    fontSize: 17,
    gap: 16,
    borderRadius: 9,
    "&.active": {
      background: colors.blueGray[100],
    },
    "&:hover": {
      background: colors.blueGray[100],
      cursor: "pointer",
    },
  },
  navIcon: {},
}))

export default function SideNav({
  storefronts,
  createNewLabShop,
  selectedStorefrontId,
  selectStorefront,
  isMobile,
}: Props) {
  const styles = useStyles()

  return (
    <>
      <div className={styles.wrapper}>
        {isMobile && <PageToolbar title="LabShop" fullBorder={true} />}
        <NavBarItem
          to={UserPaths.ECOMMERCE_STORE_ORDERS}
          icon={OrderIcon}
          activeIcon={OrderIconActive}
          isInsideSettings={true}
          isFullWidth={true}
        >
          Orders
        </NavBarItem>
        {storefronts.length > 0 && (
          <NavBarItem
            to={UserPaths.ECOMMERCE_STORE_ANALYTICS}
            icon={BarGraphIcon}
            activeIcon={BarGraphIconActive}
            isInsideSettings={true}
            isFullWidth={true}
          >
            Analytics
          </NavBarItem>
        )}
        {storefronts.map((storefront) => (
          <div
            className={clsx({
              [styles.storeNavContainer]: true,
              active: storefront.id === selectedStorefrontId,
            })}
            onClick={() => selectStorefront(storefront.id)}
            key={storefront.id}
          >
            <div className={styles.navIcon}>
              <img
                src={
                  storefront.id === selectedStorefrontId
                    ? CogIconActive
                    : CogIcon
                }
                alt="Cog icon"
                style={{ position: "relative", top: 2 }}
              />
            </div>
            <div className={styles.storeSetupContainer}>
              <div style={{ color: navy }}>
                {storefront.attributes.name
                  ? storefront.attributes.name
                  : storefront.attributes.draft_name
                  ? storefront.attributes.draft_name
                  : "LabShop"}
              </div>
              <div
                className={
                  storefront.attributes.status === StorefrontStatus.Published
                    ? styles.storefrontOn
                    : styles.storefrontOff
                }
              >
                {storefront.attributes.status === StorefrontStatus.Published
                  ? "ON"
                  : "OFF"}
              </div>
            </div>
          </div>
        ))}
        <div>
          <DesignSystemButton
            color="text"
            onClick={createNewLabShop}
            startIcon={
              <img
                src={AddBlueIcon}
                alt="add blue icon"
                style={{ position: "relative", top: "-2px" }}
              />
            }
            loading={false}
            className={styles.newLabShopBtn}
          >
            New LabShop
          </DesignSystemButton>
        </div>
      </div>
    </>
  )
}
