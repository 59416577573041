import RupaLoadingButton from "app/components/RupaLoadingButton"
import { capitalizeFirstLetter } from "app/utils"

import { useCredentialModalContext } from "./CredentialModalProvider"
import { CredentialIcon } from "./components/CredentialIcon"
import { licenseTitleName } from "./types"

const Step3Signature: React.FC = () => {
  const {
    licenseType,
    signatureComplete,
    isSigning,
    setIsSigning,
    isSubmitting,
    closeModal,
  } = useCredentialModalContext()

  return (
    <div className="bg-slate-50">
      <div className="p-5 pt-8">
        <div className="bg-white p-4 sm:py-8 md:px-16 lg:px-20 rounded-xl flex flex-col items-center gap-6 text-body text-center">
          <CredentialIcon licenseType={licenseType} />
          <div>
            <p className="font-semibold text-lg17 mb-1">
              {capitalizeFirstLetter(licenseTitleName(licenseType))} Uploaded!
            </p>
            {!signatureComplete && (
              <p className="text-base15">
                One more step! Please submit your e-signature.
              </p>
            )}
          </div>
          <RupaLoadingButton
            variant="primary"
            className="w-full"
            wrapperClassName="w-full"
            onClick={signatureComplete ? closeModal : () => setIsSigning(true)}
            loading={isSigning || isSubmitting}
          >
            {signatureComplete ? "Complete" : "Submit E-Signature"}
          </RupaLoadingButton>
        </div>
      </div>
    </div>
  )
}

export default Step3Signature
