import { sum } from "lodash"

import { PHYSICIAN_SERVICES_FEE_AMOUNT } from "app/constants"
import {
  StorefrontCustomFeeType,
  StorefrontLabTestType,
} from "app/hooks/use-resource"
import { formatDollars } from "app/utils"

export const calculateTotalTestPricing = (
  hidePhysServicesPricing: boolean,
  labTests?: (StorefrontLabTestType | undefined)[],
  customFee?: StorefrontCustomFeeType
): number => {
  let totalTestPrice = 0
  totalTestPrice += sum(
    labTests?.map((lt) => (lt ? parseFloat(lt?.attributes.rupa_price) : 0))
  )

  if (!hidePhysServicesPricing) {
    totalTestPrice += PHYSICIAN_SERVICES_FEE_AMOUNT
  }

  if (customFee) {
    totalTestPrice += customFee.attributes.price / 100
  }

  return totalTestPrice
}

export const generatePricingTooltipText = (
  hidePhysServicesPricing: boolean,
  customFee?: StorefrontCustomFeeType
) => {
  let pricingTooltipText = `This price includes a $${PHYSICIAN_SERVICES_FEE_AMOUNT}/kit Physician Services fee${
    customFee
      ? ` and your ${formatDollars(customFee.attributes.price / 100)} ${
          customFee.attributes.name
        }`
      : ""
  }`
  if (hidePhysServicesPricing) {
    if (customFee) {
      pricingTooltipText = `This price includes your ${formatDollars(
        customFee.attributes.price / 100
      )} ${customFee.attributes.name}`
    } else {
      pricingTooltipText = ""
    }
  }

  return pricingTooltipText
}
