import useScript, { ScriptStatus } from "app/hooks/use-script"
import { SPLITIT_URL } from "app/settings"

/**
 * Loads the SplitIt SDK and returns it once loaded.
 *
 * @returns the SplitItSDK or null if not loaded
 */
export default function useSplitItSDK(ready: boolean = true): ScriptStatus {
  return useScript(ready ? SPLITIT_URL : undefined)
}
