import { generatePath } from "react-router-dom"

import { UserPaths } from "app/Routes"
import HouseGrayIcon from "app/assets/icons/house-gray.svg"
import PageToolbar from "app/components/PageToolbar"
import useFeatureFlag from "app/hooks/use-feature-flag"
import { StateSelect } from "app/main/checkout/CheckoutPageStateSelect"
import { FeatureFlag } from "app/providers/FeatureFlagProvider"
import { Patient } from "app/types"
import { getOrderTypeLabel } from "app/utils/order-utils"

import useCheckoutDraft from "./hooks/use-checkout-draft"

interface CheckoutPageToolbarProviderProps {
  patient: Patient
  orderId: string
}

/**
 * Toolbar for the checkout page
 * @param patient - Patient information
 */
export function CheckoutPageToolbar({
  patient,
  orderId,
}: CheckoutPageToolbarProviderProps) {
  const { values } = useCheckoutDraft()

  const [isBeastCoastEnabled] = useFeatureFlag(FeatureFlag.BeastCoast)

  return (
    <PageToolbar
      title={
        <>
          {`${getOrderTypeLabel(
            values.requires_vendor_physician_authorization
          )} for ${patient.full_name}`}
        </>
      }
      titleActionsSlot={
        isBeastCoastEnabled ? <StateSelect orderId={orderId} /> : null
      }
      breadcrumbs={[
        {
          to: UserPaths.ROOT,
          icon: HouseGrayIcon,
          label: "Dashboard",
        },
        {
          to: generatePath(UserPaths.PATIENT_DETAIL, {
            patientId: patient.id,
          }),
          label: `${patient.full_name}`,
        },
        {
          label: `New ${getOrderTypeLabel(
            values.requires_vendor_physician_authorization
          )}`,
        },
      ]}
    />
  )
}
