import { Theme } from "@material-ui/core"

import GenericBundleBoxImage from "app/assets/images/storefront/bundle.jpg"
import GenericLabTestImage from "app/assets/images/storefront/generic-box.jpg"
import { useResourceByIdSelector } from "app/hooks/use-resource"
import makeAppStyles from "app/utils/makeAppStyles"

interface Props {
  productId: string
  width: string | undefined
  labshopLogo: string | null
}

const styles = (theme: Theme) => ({
  imageContainer: {
    display: "flex",
    "flex-flow": "row nowrap",
    "align-items": "center",
    "justify-content": "center",
    "text-align": "center",
    perspective: 1608,
  },
  centeredLogo: {
    position: "absolute" as any,
    height: "56%",
    transform:
      "rotateX(50deg) rotateY(1deg) rotateZ(31deg) translateX(46%) translate(-73%, -40%)",
    objectFit: "contain" as any,
    width: "50%",
    "max-width": "50%",
    "mix-blend-mode": "darken",
    "& img": {
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      margin: "auto",
      width: "auto",
      height: "auto",
    },
  },
})

const useStyles = makeAppStyles(styles)

export default function ProductImage({ productId, width, labshopLogo }: Props) {
  const classes = useStyles()

  const product = useResourceByIdSelector("storefront_product", productId)
  const labTest = useResourceByIdSelector(
    "lab_test",
    product?.relationships.lab_test.data?.id
  )
  const labCompany = useResourceByIdSelector(
    "lab_company",
    labTest?.relationships.lab_company.data?.id
  )

  const isLabTestProduct = Boolean(product?.relationships.lab_test.data?.id)

  let BundleLogo = (
    <img
      src={GenericBundleBoxImage}
      style={{ borderRadius: 24 }}
      alt="bundle box"
    />
  )

  if (!isLabTestProduct && labshopLogo) {
    BundleLogo = (
      <>
        <img
          src={GenericLabTestImage}
          style={{ borderRadius: 24 }}
          alt="generic lab test box"
        />
        <div className={classes.centeredLogo}>
          <img src={labshopLogo} alt="bundle logo" width="100%" />
        </div>
      </>
    )
  }

  return (
    <div className={classes.imageContainer} style={{ width, borderRadius: 8 }}>
      {isLabTestProduct ? (
        <>
          <img
            src={GenericLabTestImage}
            style={{ borderRadius: 24 }}
            alt="generic lab test box"
          />
          <div className={classes.centeredLogo}>
            <img
              src={labCompany?.attributes.logo}
              alt="lab company logo"
              width="100%"
            />
          </div>
        </>
      ) : (
        BundleLogo
      )}
    </div>
  )
}
