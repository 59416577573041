import fuzzysort from "fuzzysort"

export const fuzzySearch = <T>(
  query: string,
  items: T[],
  keys: string[]
): T[] => {
  if (!query) {
    return items
  }

  const results = fuzzysort.go(query, items, {
    keys,
  })

  return results.map((result) => result.obj)
}
