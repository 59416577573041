import React, { useEffect, useState } from "react"

import NiceModal, { muiDialog, useModal } from "@ebay/nice-modal-react"
import { Dialog, FadeProps, Grow, IconButton } from "@material-ui/core"
import MuiDialogContent from "@material-ui/core/DialogContent"
import CloseIcon from "@material-ui/icons/Close"
import { cn } from "@rupahealth/design"

import BodyText from "app/components/design-system/BodyText"
import useConfirmationModal from "app/components/modals/generic/hooks/use-confirmation-modal"
import { shadows } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"

import useCreateUserResult from "../hooks/use-create-user-result"
import useLabResult from "../hooks/use-lab-result"
import UserResultProvider, {
  useUserResultContext,
} from "../providers/UserResultDataProvider"
import ResultsUploadPdfAllSetCard from "./pdf-upload/ResultsUploadPdfAllSetCard"
import ResultsUploadPdfProcessingCard from "./pdf-upload/ResultsUploadPdfProcessingCard"
import ResultsUploadPdfUploadCard from "./pdf-upload/ResultsUploadPdfUploadCard"
import ResultsUploadSideBySideEntry from "./pdf-upload/ResultsUploadSideBySideEntry"

interface Props {
  patientId: string
  existingOrderedResultId?: string
  existingFileUrl?: string
  existingUserResultId?: string
  isReadOnly?: boolean
  onClose: () => void
}
// TODO: PROD-2332 remove this file

/**
 * Wrapper for the modal that provides the data context for the user result
 */
const ResultsUploadPdfModal = ({
  existingUserResultId,
  isReadOnly,
  ...innerProps
}: Props) => {
  const [userResultId, setUserResultId] = useState<string | undefined>(
    existingUserResultId
  )

  return (
    <UserResultProvider userResultId={userResultId}>
      <ResultsUploadPdfModalInner
        {...innerProps}
        setUserResultId={setUserResultId}
        isReadOnly={isReadOnly}
      />
    </UserResultProvider>
  )
}

interface InnerProps extends Omit<Props, "existingUserResultId"> {
  isReadOnly?: boolean
  setUserResultId(userResultId: string | undefined): void
}

const GrowTransition = React.forwardRef<unknown, FadeProps>(function Transition(
  props,
  ref
) {
  return <Grow ref={ref} {...props} timeout={500} />
})

const useStyles = makeAppStyles<{ showAllSetCard: boolean }>(() => ({
  dialogPaper: {
    margin: 15,
    borderRadius: 12,
    border: "4px solid white",
    boxShadow: shadows["2xl"],
    width: (props) => (props.showAllSetCard ? 580 : "100%"),
    transition: "all 500ms",
  },
}))

const ResultsUploadPdfModalInner = ({
  patientId,
  existingOrderedResultId,
  setUserResultId,
  existingFileUrl,
  isReadOnly,
  onClose,
}: InnerProps) => {
  const [userFile, setUserFile] = useState<File | undefined>(undefined)
  const [fileUploaded, setFileUploaded] = useState(false)
  const [showEditView, setShowEditView] = useState(false)
  const [showAllSetCard, setShowAllSetCard] = useState(false)
  const [showProcessingError, setShowProcessingError] = useState(false)
  const [invocationJobId, setInvocationJobId] = useState<string | undefined>(
    undefined
  )

  const classes = useStyles({ showAllSetCard })

  const {
    uploadLabResult,
    createLabResultFromOrderedResult,
    invokeLabResultPipeline,
  } = useLabResult()

  const { userResult } = useUserResultContext()

  const { createUserResult } = useCreateUserResult()

  const confirmationModal = useConfirmationModal()

  const modal = useModal()
  const muiDialogControl = muiDialog(modal)

  // If results already exist, this user result has been processed!
  useEffect(() => {
    if (userResult) {
      setShowEditView(true)
    }
  }, [userResult])

  useEffect(() => {
    const createAndStartLabResultProcessing = async () => {
      if (existingOrderedResultId) {
        setFileUploaded(true)
        const labResultId = await createLabResultFromOrderedResult(
          existingOrderedResultId,
          patientId
        )

        if (labResultId) {
          const invocationId = await invokeLabResultPipeline(labResultId)
          setInvocationJobId(invocationId)
        }
      }
    }

    if (existingOrderedResultId) {
      createAndStartLabResultProcessing()
    }
  }, [existingOrderedResultId])

  const handleUploadComplete = async (file: File) => {
    setUserFile(file)
    setFileUploaded(true)
    const labResultId = await uploadLabResult(file, patientId)

    if (labResultId) {
      const invocationId = await invokeLabResultPipeline(labResultId)
      setInvocationJobId(invocationId)
    }
  }

  const handleProcessingComplete = (processedUserResultId: string) => {
    setUserResultId(processedUserResultId)
    setShowEditView(true)
  }

  const handleProcessingFailed = async () => {
    const userResultId = await createUserResult({
      patientId,
      pdf_file: userFile,
      attributes: { file_type: "pdf" },
    })
    setUserResultId(userResultId)
    setShowProcessingError(true)
    setShowEditView(true)
  }

  const handleSnapshotCreate = () => {
    setShowAllSetCard(true)
    setShowEditView(false)
  }

  const confirmClose = () => {
    confirmationModal.show({
      title: "Are You Sure?",
      message: (
        <BodyText>If you exit now, your results won't be saved.</BodyText>
      ),
      backButtonTitle: "Back to Review",
      confirmButtonTitle: "Exit Without Saving",
      confirmButtonColor: "destructiveSecondary",
      onClose: confirmationModal.remove,
      handleConfirm: async () => {
        onClose()
        confirmationModal.remove()
      },
      loading: false,
      maxWidth: "xs",
      fullWidthButtons: true,
    })
  }

  return (
    <>
      <Dialog
        {...muiDialogControl}
        disableEnforceFocus
        disableEscapeKeyDown
        fullWidth
        maxWidth={showEditView || existingFileUrl ? "xl" : "sm"}
        className={"fs-unmask"}
        classes={{
          paper: classes.dialogPaper,
        }}
        TransitionComponent={GrowTransition}
      >
        <MuiDialogContent className="sm:p-[22.5px] bg-blue-gray-100 flex flex-col p-3">
          <div className="flex justify-between mb-[18px]">
            <BodyText size="lg" weight="semibold" className="font-title">
              Import Lab Results
            </BodyText>

            <IconButton
              aria-label="close"
              onClick={showAllSetCard || !fileUploaded ? onClose : confirmClose}
              key="close-button"
              className="text-body p-0 m-0"
            >
              <CloseIcon />
            </IconButton>
          </div>

          {(showEditView || existingFileUrl) && !showAllSetCard ? (
            <ResultsUploadSideBySideEntry
              pdfFile={userFile}
              pdfFileUrl={existingFileUrl}
              showProcessingError={showProcessingError}
              onSnapshotCreate={handleSnapshotCreate}
              isReadOnly={isReadOnly}
            />
          ) : showAllSetCard ? (
            <ResultsUploadPdfAllSetCard onClose={onClose} />
          ) : (
            <div className="relative">
              <div
                className={cn(
                  "w-full transition-opacity duration-500 delay-100",
                  userFile || fileUploaded
                    ? "opacity-0 absolute overflow-hidden"
                    : "opacity-100 h-auto"
                )}
              >
                <ResultsUploadPdfUploadCard
                  patientId={patientId}
                  onUploadComplete={handleUploadComplete}
                  onClose={onClose}
                />
              </div>
              <div
                className={cn(
                  "w-full transition-opacity duration-500 ",
                  userFile || fileUploaded
                    ? "opacity-100 h-auto"
                    : "opacity-0 h-0 overflow-hidden"
                )}
              >
                <ResultsUploadPdfProcessingCard
                  playAnimation={fileUploaded}
                  handleProcessingComplete={handleProcessingComplete}
                  handleProcessingFailed={handleProcessingFailed}
                  invocationJobId={invocationJobId}
                />
              </div>
            </div>
          )}
        </MuiDialogContent>
      </Dialog>
    </>
  )
}

const ResultsUploadPdfNiceModal = NiceModal.create<Props>(ResultsUploadPdfModal)

export default function useResultsUploadPdfModal() {
  return useModal(ResultsUploadPdfNiceModal)
}
