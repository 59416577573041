// We want our breakpoints to based on the container width, not the overall
// screensize. This isn't possible in CSS yet.
//
// Maybe one day: https://caniuse.com/css-container-queries
export const BREAKPOINTS = {
  xs: 480,
  sm: 640,
  md: 920,
  lg: 1060,
}

export const SORTED_BREAKPOINTS_DESC = Object.values(BREAKPOINTS).sort((a, b) =>
  a > b ? -1 : 1
)

export const PHYSICIAN_SERVICES_FILTER_VALUE = "physician_services"
