import { combineReducers } from "redux"

import bankAccounts from "./bankAccounts.reducer"
import customFees from "./customFees.reducer"
import orders from "./orders.reducer"
import paymentCards from "./paymentCards.reducer"

const reducer = combineReducers({
  orders,
  paymentCards,
  bankAccounts,
  customFees,
})

export default reducer
