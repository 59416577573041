import RupaButton from "app/components/design-system/Button"
import makeAppStyles from "app/utils/makeAppStyles"

const useStyles = makeAppStyles((theme) => ({
  buttonContainer: {
    display: "flex",
    gap: 7,
  },
}))

interface Props {
  orderApprovalId: string
  handleOrderApproveBtnClick: (approvalId: string) => void
  handleOrderDenyBtnClick: (approvalId: string) => void
}

export default function ApproveDenyOrderTableCell({
  orderApprovalId,
  handleOrderApproveBtnClick,
  handleOrderDenyBtnClick,
}: Props) {
  const classes = useStyles()

  const orderApproveBtnClick = (e: any) => {
    e.stopPropagation()
    handleOrderApproveBtnClick(orderApprovalId)
  }

  const orderDenyBtnClick = (e: any) => {
    e.stopPropagation()
    handleOrderDenyBtnClick(orderApprovalId)
  }

  return (
    <div className={classes.buttonContainer}>
      <RupaButton color="success" onClick={orderApproveBtnClick}>
        Approve
      </RupaButton>
      <RupaButton color="destructivePrimary" onClick={orderDenyBtnClick}>
        Deny
      </RupaButton>
    </div>
  )
}
