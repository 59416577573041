import AddIcon from "app/assets/icons/add-blue.svg"
import AddIconGrey from "app/assets/icons/add-grey.svg"
import CheckIcon from "app/assets/icons/blue-checkmark.svg"
import SampleTypePill from "app/components/SampleTypePill"
import Tooltip from "app/components/Tooltip"
import BodyText from "app/components/design-system/BodyText"
import Button from "app/components/design-system/Button"
import useCachedCollection from "app/swr/hooks/use-cached-collection"
import useCachedResource from "app/swr/hooks/use-cached-resource"
import { colors, primaryColor } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"
import { LabCompany } from "types/lab-company"
import { LabTest } from "types/lab-test"
import { LabTestType } from "types/lab-test-type"

import useComboGroupLabTestModal from "../hooks/use-combo-group-lab-test-modal"

const useStyles = makeAppStyles<{ isInCombo: boolean }>({
  container: {
    backgroundColor: "white",
    padding: "20px 0px",
    display: "flex",
    flexDirection: "row",
    gap: 20,
    justifyContent: "space-between",
    alignItems: "center",
    flexWrap: "wrap",
  },
  actions: {
    display: "flex",
    flexDirection: "row",
    gap: 10,
    flexWrap: "wrap",
    justifyContent: "center",
  },
  labTestAndCompanyCell: {
    paddingLeft: 0,
  },
  labTestAndCompany: {
    flex: "0 1 20%",
  },
  actionButton: {
    width: 170,
    flexGrow: 2,
    "& button": {
      display: "flex",
      background: (props) => (props.isInCombo ? colors.lightBlue[50] : "white"),
      border: (props) =>
        `1px solid ${props.isInCombo ? primaryColor : colors.blueGray[300]}`,
      alignItems: "center",
      width: "100%",
    },
  },
  actionIcon: {
    marginTop: -3,
    width: 12,
  },
  detailsButton: {
    flexGrow: 2,
  },
  labTestTypes: {
    display: "flex",
    flexFlow: "row wrap",
    flex: "0 1 20%",
    gap: 5,
    alignItems: "center",
  },
})

export interface ComboGroupLabTestRowProps {
  labTest: LabTest
  selected: boolean
  onAdd: (id: string) => void
  onRemove: (id: string) => void
  disabled: boolean
  disabledReason: string
}

export default function ComboGroupLabTestRow({
  labTest,
  selected,
  onAdd,
  onRemove,
  disabled,
  disabledReason,
}: ComboGroupLabTestRowProps) {
  const classes = useStyles({ isInCombo: selected })
  const detailsModal = useComboGroupLabTestModal()

  const labCompany = useCachedResource<LabCompany>(
    labTest.relationships.lab_company.data
  )

  const labTestTypes = useCachedCollection<LabTestType>(
    labTest.relationships.lab_test_types.data
  )

  const onClick = () => {
    if (selected) {
      onRemove(labTest.id)
    } else {
      onAdd(labTest.id)
    }
  }

  const onDetailsClick = () => {
    detailsModal.show({
      labTestId: labTest.id,
      selected: selected,
      onAdd: onAdd,
      onRemove: onRemove,
    })
  }

  return (
    <div className={classes.container}>
      <div className={classes.labTestAndCompany}>
        <BodyText size="sm" weight="semibold">
          {labTest.attributes.name}
        </BodyText>
        <BodyText size="sm">{labCompany?.attributes.name}</BodyText>
      </div>
      <div className={classes.labTestTypes}>
        {labTestTypes.map((labTestType) => (
          <SampleTypePill
            name={labTestType.attributes.name}
            key={labTestType.attributes.name}
          />
        ))}
      </div>

      <BodyText size="sm" weight="semibold">
        ${labTest.attributes.rupa_price}
      </BodyText>
      <div className={classes.actions}>
        <Button
          aria-label="Details"
          color="secondary"
          onClick={onDetailsClick}
          size="small"
          className={classes.detailsButton}
        >
          Details
        </Button>
        <div className={classes.actionButton}>
          <Tooltip
            title={disabledReason}
            placement="bottom"
            arrow
            disableHoverListener={!disabledReason}
          >
            <div>
              <Button
                aria-label="Add to Combo"
                onClick={onClick}
                size="small"
                color="secondary"
                startIcon={
                  <img
                    src={
                      selected ? CheckIcon : disabled ? AddIconGrey : AddIcon
                    }
                    className={classes.actionIcon}
                    alt=""
                  />
                }
                disabled={disabled}
              >
                {selected ? "In Combo" : "Add to Combo"}
              </Button>
            </div>
          </Tooltip>
        </div>
      </div>
    </div>
  )
}
