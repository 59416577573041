import { makeStyles } from "@material-ui/core"

import { colors, primaryColor } from "app/theme"

import BodyText from "./design-system/BodyText"

interface CardWithIconProps {
  icon: string
  title: string
  text: JSX.Element
}

const useStyles = makeStyles((theme) => ({
  cardWrapper: {
    display: "flex",
    background: "white",
    border: `1px solid ${colors.blueGray[200]}`,
    padding: 14,
    borderRadius: 6,
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
      flexDirection: "column",
    },
  },
  imgWrapper: {
    minWidth: "64px",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "9px",
      flexBasis: "64px",
      width: "64px",
    },
  },
  textWrapper: {
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "0px",
    },
    paddingLeft: "13px",
  },
  createLink: {
    fontWeight: 600,
    color: primaryColor,
  },
}))

const CardWithIcon = ({ icon, title, text }: CardWithIconProps) => {
  const classes = useStyles()
  return (
    <div className={classes.cardWrapper}>
      <div className={classes.imgWrapper}>
        <img src={icon} alt="Blood drop" height="100%" />
      </div>
      <div className={classes.textWrapper}>
        <BodyText weight="semibold" size="md" style={{ marginBottom: 6 }}>
          {title}
        </BodyText>

        {text}
      </div>
    </div>
  )
}

export default CardWithIcon
