import { ORDER_STATUS } from "app/constants.typed"

import { StatusConfiguration } from "../types"
import { UnionStatusConfigurationProps } from "../types/types"
import { PROGRESS_PERCENTAGES } from "./consts"

const draftStatusConfiguration: StatusConfiguration<UnionStatusConfigurationProps> =
  {
    when: { orderStatus: ORDER_STATUS.DRAFT },
    progressPercentage: PROGRESS_PERCENTAGES.DRAFT,
    title: "Order is a Draft",
  }

export default draftStatusConfiguration
