import { useMemo, useRef } from "react"

import { useHistory } from "react-router-dom"

import { ButtonGroup } from "@material-ui/core"

import { ReactComponent as DownloadIcon } from "app/assets/images/download-blue.svg"
import Tooltip from "app/components/Tooltip"
import Button from "app/components/design-system/Button"
import useFeatureFlag, { FeatureFlag } from "app/hooks/use-feature-flag"
import {
  PatientPortalKit,
  PatientPortalOrder,
  PatientPortalOrderedTest,
  PatientPortalPatient,
} from "app/patient-portal/types"
import { buildPatientPortalUserUrl } from "app/patient-portal/util"
import useCachedResource from "app/swr/hooks/use-cached-resource"
import makeAppStyles from "app/utils/makeAppStyles"
import { OrderedResult } from "types/ordered-result"

import BloodDashboardsActionButtonDropdownMenu from "./BloodDashboardsActionButtonDropdownMenu"
import PatientPortalOrderKitListKitTestAction from "./PatientPortalOrderKitListKitTestAction"

const useStyles = makeAppStyles((theme) => ({
  button: {
    width: "100%",
    minWidth: "auto",
    "&:.MuiButtonGroup-groupedContainedPrimary:not(:last-child)": {
      borderColor: "none",
    },
    "&.MuiButtonGroup-groupedContainedHorizontal:not(:last-child).Mui-disabled.MuiButtonGroup-groupedContainedPrimary:not(:last-child)":
      {
        borderColor: "rgba(0,0,0,0)",
      },
  },
  buttonGroup: {
    width: 140,
    minWidth: "none",
    "&:.MuiButtonGroup-groupedContainedPrimary:not(:last-child)": {
      borderColor: "none",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  "&:.MuiButtonGroup-groupedContainedPrimary:not(:last-child)": {
    borderColor: "none",
  },
}))

interface Props {
  kit: PatientPortalKit
  orderedTest: PatientPortalOrderedTest
}

const BloodDashboardsActionButton = ({ kit, orderedTest }: Props) => {
  const classes = useStyles()
  const available = Boolean(orderedTest.attributes.lab_results)

  const [isBloodLabDashboardSnapshotEnabled] = useFeatureFlag(
    FeatureFlag.RupaBloodReportSnapshots
  )

  const buildPatientPortalUrlFunction = buildPatientPortalUserUrl

  const order = useCachedResource<PatientPortalOrder>(
    orderedTest.relationships.order.data
  )
  const patient = useCachedResource<PatientPortalPatient>(
    order?.relationships.patient.data
  )
  const latestOrderedResult = kit.relationships.ordered_results.data[0]
  const cachedLatestOrderedResult =
    useCachedResource<OrderedResult>(latestOrderedResult)
  const hasSnapshot = Boolean(
    cachedLatestOrderedResult?.relationships
      ?.blood_lab_dashboard_report_snapshots?.data?.length
  )

  const anchorRef = useRef(null)

  const history = useHistory()

  const patientPortalOnClick = (orderedResult: OrderedResult) => {
    if (
      isBloodLabDashboardSnapshotEnabled &&
      orderedResult?.relationships?.blood_lab_dashboard_report_snapshots?.data
        ?.length > 0
    ) {
      history.push(
        buildPatientPortalUrlFunction(
          patient?.relationships.user.data.id,
          orderedTest.relationships.order.data.id,
          undefined,
          orderedResult.relationships?.blood_lab_dashboard_report_snapshots
            ?.data?.[0]?.id
        )
      )
    } else {
      history.push(
        buildPatientPortalUrlFunction(
          patient?.relationships.user.data.id,
          orderedTest.relationships.order.data.id,
          orderedResult.id
        )
      )
    }
  }

  const onClick = useMemo(() => {
    if (cachedLatestOrderedResult) {
      return () => patientPortalOnClick(cachedLatestOrderedResult)
    }
    return undefined
  }, [cachedLatestOrderedResult, latestOrderedResult])

  const buttonNode = (
    <ButtonGroup
      variant="contained"
      color="primary"
      ref={anchorRef}
      aria-label="split button"
      className={classes.buttonGroup}
    >
      <Button
        className={classes.button}
        color="primary"
        disabled={!available || !onClick}
        onClick={onClick}
        size="small"
        startIcon={<DownloadIcon fill="currentColor" viewBox="0 0 18 17" />}
        type="button"
      >
        Results
      </Button>
      <BloodDashboardsActionButtonDropdownMenu
        kit={kit}
        orderedTest={orderedTest}
        disabled={!available}
        onNavigateToBloodLabDashboard={onClick}
      />
    </ButtonGroup>
  )

  if (available) {
    // If there is no snapshot, we should just return the download pdf button.
    if (hasSnapshot) {
      return buttonNode
    } else {
      return (
        <PatientPortalOrderKitListKitTestAction orderedTest={orderedTest} />
      )
    }
  }

  return (
    <Tooltip
      arrow
      interactive
      placement="bottom"
      title="Results have not been released by your practitioner."
    >
      {/* Needs a span to apply the hover listener to. */}
      <span>{buttonNode}</span>
    </Tooltip>
  )
}

export default BloodDashboardsActionButton
