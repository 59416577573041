import { Status } from "app/main/blood-lab-dashboards/NumericBiomarkerGraphic/constants"
import {
  getNumericStatus,
  getRatioBetweenPoints,
} from "app/main/blood-lab-dashboards/NumericBiomarkerGraphic/utils"
import { BloodReportResult } from "types/blood-report-result"
import { DiscreteResult } from "types/discrete-result"
import { LabCompanyBiomarkerRange } from "types/lab-company-biomarker-range"

import { BiomarkerStatus } from "./constants"

/**
 * Get the status of the biomarker based on the value and the ranges.
 *
 * @param biomarker Biomarker object
 * @returns Status of biomarker based on value and ranges
 */
export const getBiomarkerStatus = (
  discreteResult: DiscreteResult | undefined,
  optimalRange: LabCompanyBiomarkerRange | undefined,
  useOptimalRange: boolean = true
): BiomarkerStatus | undefined => {
  const value = discreteResult?.attributes.value
    ? _parseStringValueToFloat(discreteResult?.attributes.value)
    : null
  const standardRangeHigh = discreteResult?.attributes.normal_max
    ? parseFloat(discreteResult.attributes.normal_max)
    : useOptimalRange && optimalRange?.attributes.standard_range_max
    ? parseFloat(optimalRange.attributes.standard_range_max)
    : null
  const standardRangeLow = discreteResult?.attributes.normal_min
    ? parseFloat(discreteResult.attributes.normal_min)
    : useOptimalRange && optimalRange?.attributes.standard_range_min
    ? parseFloat(optimalRange.attributes.standard_range_min)
    : null
  const optimalRangeHigh =
    useOptimalRange && optimalRange?.attributes.optimal_range_max
      ? parseFloat(optimalRange.attributes.optimal_range_max)
      : null
  const optimalRangeLow =
    useOptimalRange && optimalRange?.attributes.optimal_range_min
      ? parseFloat(optimalRange.attributes.optimal_range_min)
      : null

  if (value === null) {
    return undefined
  }

  if (
    !standardRangeHigh &&
    !standardRangeLow &&
    !optimalRangeHigh &&
    !optimalRangeLow
  ) {
    return undefined
  }

  if (standardRangeLow !== null && value < standardRangeLow) {
    return BiomarkerStatus.LOW
  } else if (standardRangeHigh !== null && value > standardRangeHigh) {
    return BiomarkerStatus.HIGH
  } else if (
    useOptimalRange &&
    optimalRangeLow !== null &&
    value < optimalRangeLow
  ) {
    return BiomarkerStatus.BELOW_OPTIMAL
  } else if (
    useOptimalRange &&
    optimalRangeHigh !== null &&
    value > optimalRangeHigh
  ) {
    return BiomarkerStatus.ABOVE_OPTIMAL
  } else {
    if (
      (useOptimalRange && optimalRangeLow !== null) ||
      optimalRangeHigh !== null
    ) {
      return BiomarkerStatus.OPTIMAL
    } else {
      return BiomarkerStatus.NORMAL
    }
  }
}

/**
 * Represents the priority order of the discrete result status values.
 * This is used to sort the discrete results to prioritize out-of-range biomarkers first.
 */
const STATUS_PRIORITY = {
  [BiomarkerStatus.OPTIMAL]: 0,
  [BiomarkerStatus.NORMAL]: 0,
  [BiomarkerStatus.BELOW_OPTIMAL]: 1,
  [BiomarkerStatus.ABOVE_OPTIMAL]: 1,
  [BiomarkerStatus.LOW]: 2,
  [BiomarkerStatus.HIGH]: 2,
}

export const sortDiscreteValues = (
  a: DiscreteResult,
  b: DiscreteResult,
  aOptimalRange?: LabCompanyBiomarkerRange,
  bOptimalRange?: LabCompanyBiomarkerRange
) => {
  const aValue =
    a.attributes.value_type === "numeric"
      ? a.attributes.value
      : parseStringValue(a.attributes.value)
  const bValue =
    b.attributes.value_type === "numeric"
      ? b.attributes.value
      : parseStringValue(b.attributes.value)
  const aStatus = getNumericStatus(
    aValue,
    a.attributes.normal_min
      ? a.attributes.normal_min
      : aOptimalRange?.attributes.standard_range_min || "",
    a.attributes.normal_max
      ? a.attributes.normal_max
      : aOptimalRange?.attributes.standard_range_max || "",
    aOptimalRange?.attributes.optimal_range_min || "",
    aOptimalRange?.attributes.optimal_range_max || ""
  )
  const aRatio = getRatioBetweenPoints(
    aValue,
    a.attributes.normal_min
      ? a.attributes.normal_min
      : aOptimalRange?.attributes.standard_range_min || "",
    a.attributes.normal_max
      ? a.attributes.normal_max
      : aOptimalRange?.attributes.standard_range_max || "",
    aOptimalRange?.attributes.optimal_range_min || "",
    aOptimalRange?.attributes.optimal_range_max || ""
  )

  const bRatio = getRatioBetweenPoints(
    bValue,
    b.attributes.normal_min
      ? b.attributes.normal_min
      : bOptimalRange?.attributes.standard_range_min || "",
    b.attributes.normal_max
      ? b.attributes.normal_max
      : bOptimalRange?.attributes.standard_range_max || "",
    bOptimalRange?.attributes.optimal_range_min || "",
    bOptimalRange?.attributes.optimal_range_max || ""
  )
  const bStatus = getNumericStatus(
    bValue,
    b.attributes.normal_min
      ? b.attributes.normal_min
      : bOptimalRange?.attributes.standard_range_min || "",
    b.attributes.normal_max
      ? b.attributes.normal_max
      : bOptimalRange?.attributes.standard_range_max || "",
    bOptimalRange?.attributes.optimal_range_min || "",
    bOptimalRange?.attributes.optimal_range_max || ""
  )

  const aStatusPriority = aStatus ? STATUS_PRIORITY[aStatus] : 0
  const bStatusPriority = bStatus ? STATUS_PRIORITY[bStatus] : 0

  // Moved hered because it is only used by this function.
  // We will delete this when we remove legacy discrete result code
  function numericStatusToBiomarkerStatus(status: Status): BiomarkerStatus {
    const statusMap = {
      [Status.Low]: BiomarkerStatus.LOW,
      [Status.BelowOptimal]: BiomarkerStatus.BELOW_OPTIMAL,
      [Status.Normal]: BiomarkerStatus.NORMAL,
      [Status.Optimal]: BiomarkerStatus.OPTIMAL,
      [Status.AboveOptimal]: BiomarkerStatus.ABOVE_OPTIMAL,
      [Status.High]: BiomarkerStatus.HIGH,
    }

    return statusMap[status]
  }

  if (aStatusPriority === bStatusPriority) {
    if (!aStatus) {
      return 0
    }

    if (!bStatus) {
      return 1
    }

    // If the status is the same, sort by the normalized ratio so that more severe values show first.
    return (
      normalizeRatio(numericStatusToBiomarkerStatus(bStatus), bRatio) -
      normalizeRatio(numericStatusToBiomarkerStatus(aStatus), aRatio)
    )
  }

  // Otherwise, sort the highest priority first.
  return bStatusPriority - aStatusPriority
}

export const sortBloodReportResultValues = (
  a: BloodReportResult,
  b: BloodReportResult
) => {
  const aValue = parseStringValue(a.attributes.value)
  const bValue = parseStringValue(b.attributes.value)
  const aStatus = a.attributes.status
  const aRatio = getRatioBetweenPoints(
    aValue,
    a.attributes.standard_range_min,
    a.attributes.standard_range_max,
    a.attributes.optimal_range_min,
    a.attributes.optimal_range_max
  )

  const bRatio = getRatioBetweenPoints(
    bValue,
    b.attributes.standard_range_min,
    b.attributes.standard_range_max,
    b.attributes.optimal_range_min,
    b.attributes.optimal_range_max
  )
  const bStatus = b.attributes.status

  const aStatusPriority = aStatus ? STATUS_PRIORITY[aStatus] : 0
  const bStatusPriority = bStatus ? STATUS_PRIORITY[bStatus] : 0

  if (aStatusPriority === bStatusPriority) {
    if (!aStatus) {
      return 0
    }

    if (!bStatus) {
      return 1
    }

    // If the status is the same, sort by the normalized ratio so that more severe values show first.
    return normalizeRatio(bStatus, bRatio) - normalizeRatio(aStatus, aRatio)
  }

  // Otherwise, sort the highest priority first.
  return bStatusPriority - aStatusPriority
}

/**
 * Normalizes the ratio so that we can properly sort the discrete results.
 * Ratios with a higher variance, i.e. severity, will get sorted first.
 *
 * @param status the status of the discrete result
 * @param ratio the ratio of the discrete result
 * @returns the normalized ratio for comparison
 */
function normalizeRatio(status: BiomarkerStatus, ratio: number) {
  if (
    (status && status === BiomarkerStatus.BELOW_OPTIMAL) ||
    status === BiomarkerStatus.LOW
  ) {
    // Invert the ratio so that the lowest values show first.
    return 1 - ratio
  }

  if (
    (status && status === BiomarkerStatus.NORMAL) ||
    status === BiomarkerStatus.OPTIMAL
  ) {
    // Normalize the ratio so that the highest variance values show first.
    return Math.abs(0.5 - ratio) * 2
  }

  // Otherwise, return the ratio as-is so that the highest values show first.
  return ratio
}

export const isDiscreteResultAbnormal = (
  discreteResult: DiscreteResult,
  optimalRangesSettingTurnedOn: boolean,
  optimalRange?: LabCompanyBiomarkerRange
): boolean => {
  const normalMin = discreteResult?.attributes.normal_min
    ? discreteResult?.attributes.normal_min
    : optimalRangesSettingTurnedOn
    ? optimalRange?.attributes.standard_range_min || ""
    : ""
  const normalMax = discreteResult?.attributes.normal_max
    ? discreteResult?.attributes.normal_max
    : optimalRangesSettingTurnedOn
    ? optimalRange?.attributes.standard_range_max || ""
    : ""

  const value = _parseStringValueToFloat(discreteResult.attributes.value)

  // Check standard ranges first
  if (
    (normalMin && value < parseFloat(normalMin)) ||
    (normalMax && value > parseFloat(normalMax))
  ) {
    return true
  }

  return false
}

export const parseStringValue = (value?: string | null): string => {
  if (!value) return ""

  const regex = /[-+]?\d*\.?\d+/
  const matches = value.match(regex)

  return matches ? matches[0] : ""
}

const _parseStringValueToFloat = (value?: string): number => {
  if (!value) return parseFloat("") // returning NaN if no value

  const regex = /[-+]?\d*\.?\d+/
  const matches = value.match(regex)

  return matches ? parseFloat(matches[0]) : parseFloat("") // returning NaN if no matches
}

export const isDiscreteResultOutOfOptimalRange = (
  discreteResult: DiscreteResult,
  optimalRange?: LabCompanyBiomarkerRange
): boolean => {
  const value = _parseStringValueToFloat(discreteResult.attributes.value)

  const isDiscreteResultBelowOptimalRange = Boolean(
    optimalRange?.attributes.optimal_range_min &&
      value < parseFloat(optimalRange.attributes.optimal_range_min)
  )
  const isDiscreteResultAboveOptimalRange = Boolean(
    optimalRange?.attributes.optimal_range_max &&
      value > parseFloat(optimalRange.attributes.optimal_range_max)
  )

  return isDiscreteResultBelowOptimalRange || isDiscreteResultAboveOptimalRange
}
