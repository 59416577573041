import { styled } from "@material-ui/core"

import DropperIcon from "app/assets/icons/dropper-dark.svg"
import SampleTypePill from "app/components/SampleTypePill"
import BodyText from "app/components/design-system/BodyText"

const BodyContainer = styled("div")({
  marginTop: 14,
  display: "flex",
  flexDirection: "row",
})

const PillContainer = styled("div")({
  display: "flex",
  flexWrap: "wrap",
})
const IconImage = styled("img")({
  width: 17.42,
  marginRight: 3,
  marginTop: 3,
})

interface Props {
  types: string[]
}

const TypeSection = ({ types }: Props) => (
  <>
    <BodyText size="sm">Sample Type</BodyText>
    <BodyContainer>
      <IconImage src={DropperIcon} alt="sample type" />
      <PillContainer>
        {types.map((type) => (
          <SampleTypePill name={type.toLowerCase()} key={type} />
        ))}
      </PillContainer>
    </BodyContainer>
  </>
)

export default TypeSection
