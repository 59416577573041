import { useCallback, useRef, useState } from "react"

import { generatePath, useHistory } from "react-router-dom"
import { useMount } from "react-use"

import { UserPaths } from "app/Routes"
import { ORDER_STATUS } from "app/constants"
import useFeatureFlag, { FeatureFlag } from "app/hooks/use-feature-flag"
import useQuery from "app/hooks/use-query"
import useAppSelector from "app/hooks/useAppSelector"
import { primaryColor, shadows } from "app/theme"
import { PatientOrdersOrder as PatientOrdersOrderType } from "app/types"
import makeAppStyles from "app/utils/makeAppStyles"

import CHECKOUT_STATE from "../checkout/CheckoutState"
import OrderCustomFee from "./OrderCustomFee"
import { OrderEvents } from "./OrderEvents"
import PatientOrdersOrderHeader from "./PatientOrdersOrderHeader"
import PatientOrdersOrderedTestList from "./PatientOrdersOrderedTest/PatientOrdersOrderedTestList"

const ORDER_STATUSES_TO_START_EXPANDED: (string | number)[] = [
  ORDER_STATUS.PENDING_PAYMENT,
  ORDER_STATUS.PENDING_SHIPPING_INFO,
  ORDER_STATUS.PENDING_PHYSICIAN_AUTHORIZATION,
  ORDER_STATUS.AWAITING_SHIPMENT,
  ORDER_STATUS.IN_PROGRESS,
]

const useStyles = makeAppStyles<{ isSelected: boolean }>((theme) => ({
  order: {
    background: "#fff",
    borderRadius: 12,
    marginBottom: 24,
    boxShadow: shadows.default,

    border: "1px solid rgba(0, 0, 0, 0)",
    borderColor: (props) => (props.isSelected ? primaryColor : ""),
    transition: "border-color 0.48s",

    [theme.breakpoints.up("md")]: {
      width: "auto",
    },
  },
}))

const PatientOrdersOrder = ({
  order,
  orderIndex,
}: {
  order: PatientOrdersOrderType
  orderIndex: number
}) => {
  const [showGroupedOrders] = useFeatureFlag(
    FeatureFlag.GroupedOrderEventsAndReminders
  )
  const history = useHistory()
  const query = useQuery()
  const isViewingThisOrder = query.get("orderId") === order.id
  // Highlight the border if selected
  const [isSelected, setIsSelected] = useState(isViewingThisOrder)
  const containerRef = useRef<HTMLDivElement>(null)
  const styles = useStyles({ isSelected })

  const patient = useAppSelector(({ patient }) => patient.patient)

  const handleEditOrder = useCallback(() => {
    if (order.status === ORDER_STATUS.DRAFT) {
      history.push(
        generatePath(UserPaths.ORDER_CHECKOUT, { orderId: order.id })
      )
    } else if (
      [ORDER_STATUS.PENDING_PAYMENT, ORDER_STATUS.SCHEDULED].some(
        (status) => status === order.status
      )
    ) {
      history.push(
        generatePath(UserPaths.ORDER_CHECKOUT, {
          orderId: order.id,
          checkoutState: CHECKOUT_STATE.EDIT,
        })
      )
    }
  }, [history, order])

  // Scroll to the order if this order is being viewed (the user clicked Order Details on the dashboard)
  useMount(() => {
    if (isViewingThisOrder && containerRef.current) {
      // Wait 500ms before scrolling so that the page has had time to load.
      // Without this there'd be some UI jank.
      window.setTimeout(() => {
        if (containerRef.current) {
          // 175 is the height of the page header
          const offsetTop = containerRef.current.offsetTop - 175
          window.scrollTo({
            top: offsetTop,
            behavior: "smooth",
          })
        }
      }, 500)

      // Remove border highlight after 4 seconds
      window.setTimeout(() => {
        setIsSelected(false)
      }, 3000)
    }
  })

  // Depending on order events version, expand order events on different terms
  let startOrderEventsExpanded = false
  if (
    !showGroupedOrders &&
    ORDER_STATUSES_TO_START_EXPANDED.some((status) => status === order.status)
  ) {
    startOrderEventsExpanded = true
  } else if (showGroupedOrders && orderIndex === 0) {
    startOrderEventsExpanded = true
  }

  const customFeeLineItems = order.custom_fee_line_items

  return (
    <div
      className={styles.order}
      key={order.id}
      id={order.id}
      ref={containerRef}
    >
      <div className="py-4 px-6">
        <PatientOrdersOrderHeader onEditOrder={handleEditOrder} order={order} />

        {Boolean(customFeeLineItems.length) &&
          customFeeLineItems.map((customFeeLineItem) => (
            <OrderCustomFee
              orderId={order.id}
              customFeeLineItem={customFeeLineItem}
              patientFullName={order.patient.full_name}
            />
          ))}
        <PatientOrdersOrderedTestList order={order} patient={patient} />

        <OrderEvents
          order={order}
          patient={patient}
          startExpanded={startOrderEventsExpanded}
        />
      </div>
    </div>
  )
}

export default PatientOrdersOrder
