import RichTextRenderer from "app/components/RichTextRenderer"
import BodyText from "app/components/design-system/BodyText"
import useCachedResource from "app/swr/hooks/use-cached-resource"
import { ResourceIdentifier } from "app/swr/types"
import { colors, primaryColor } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"
import { ResultsInterpretationTask } from "types/results-interpretation"

import TaskIcon from "./TaskIcon"

const useStyles = makeAppStyles((theme) => ({
  root: {
    width: "100%",
    display: "flex",
    flexFlow: "row nowrap",
    padding: theme.spacing(1.5, 2.0),
    gap: theme.spacing(1.5),
    borderRadius: 6,
    backgroundColor: colors.blueGray[100],
  },
  iconGroup: {
    flex: "0 1 39px",
  },
  iconCircle: {
    width: 39,
    height: 39,
    backgroundColor: "white",
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  icon: {
    width: 20,
    height: 20,
    fill: primaryColor,
  },
  detailsGroup: {
    flex: "1 1 auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
}))

export default function TaskRenderer({
  identifier,
}: {
  identifier: ResourceIdentifier
}) {
  const classes = useStyles()
  const task = useCachedResource<ResultsInterpretationTask>(identifier)

  if (!task) {
    return null
  }

  const emptyInstructions =
    task.attributes.content_instructions.length === 1 &&
    task.attributes.content_instructions[0]["children"].length === 1 &&
    task.attributes.content_instructions[0]["children"][0] === { text: "" }

  return (
    <div className={classes.root}>
      <div className={classes.iconGroup}>
        <div className={classes.iconCircle}>
          <TaskIcon
            className={classes.icon}
            taskType={task.attributes.task_type}
          />
        </div>
      </div>

      <div className={classes.detailsGroup}>
        <BodyText color="textPrimary" weight="semibold">
          {task.attributes.name}
        </BodyText>
        {!emptyInstructions && (
          <RichTextRenderer>
            {task.attributes.content_instructions}
          </RichTextRenderer>
        )}
      </div>
    </div>
  )
}
