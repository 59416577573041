import { TableCell, TableRow } from "@material-ui/core"

import OrderingAccessPill from "app/components/OrderingAccess/OrderingAccessPill"
import SpecialtyPill from "app/components/OrderingAccess/SpecialtyPill"
import BodyText from "app/components/design-system/BodyText"
import { LabCompany, PractitionerListItem } from "app/types"
import makeAppStyles from "app/utils/makeAppStyles"

const useStyles = makeAppStyles((theme) => ({
  logo: {
    maxHeight: 30,
    maxWidth: "75%",
  },
}))

interface Props {
  hasOrderingAccess: boolean
  labCompany: LabCompany
  selectedPractitionerListItem?: PractitionerListItem
}

function LabOrderingAccessTableRow(props: Props) {
  const classes = useStyles()

  return (
    <TableRow>
      <TableCell>
        <BodyText weight="semibold">{props.labCompany.name}</BodyText>
      </TableCell>
      <TableCell>
        {props.labCompany.logo && (
          <img
            className={classes.logo}
            src={props.labCompany.logo}
            alt={props.labCompany.name}
          />
        )}
      </TableCell>
      <TableCell>
        {props.labCompany.specialties.map((specialty) => (
          <SpecialtyPill key={props.labCompany.id} label={specialty} />
        ))}
      </TableCell>
      <TableCell>
        <OrderingAccessPill
          hasOrderingAccess={props.hasOrderingAccess}
          labCompany={props.labCompany}
          selectedPractitionerListItem={props.selectedPractitionerListItem}
        />
      </TableCell>
    </TableRow>
  )
}

export default LabOrderingAccessTableRow
