export interface ComboGroupConfigurationPayload {
  lab_test: string
  combo_group: string
  combo_group_options: string[]
  add_ons: string[]
}

export enum ComboGroupEvents {
  COMBO_GROUP_LAB_TEST_ADD_TO_CART_CLICKED = "combo_group_lab_test_add_to_cart_clicked",
  COMBO_GROUP_LAB_TEST_START_ORDER_CLICKED = "combo_group_lab_test_start_order_clicked",
  COMBO_GROUP_LAB_TEST_DETAILS_CLICKED = "combo_group_lab_test_details_clicked",
  COMBO_GROUP_ADD_TO_COMBO_CLICKED = "combo_group_add_to_combo_clicked",
  COMBO_GROUP_REMOVE_FROM_COMBO_CLICKED = "combo_group_remove_from_combo_clicked",
  COMBO_GROUP_ADD_TO_CART_FROM_MODAL_CLICKED = "combo_group_add_to_cart_from_modal_clicked",
  COMBO_GROUP_EDIT_COMBO_CLICKED = "combo_group_edit_combo_clicked",
}

export enum ComboGroupLabTestTypes {
  COMBO_GROUP_OPTION = "combo_group_option",
  ADD_ON = "add_on",
}

export enum ComboGroupAddToCartEventType {
  CREATE = "create",
  UPDATE = "update",
}
