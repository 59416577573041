import { colors } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"
import { BiomarkerCustomDescription } from "types/biomarker-custom-description"
import { DiscreteResult } from "types/discrete-result"

import BloodLabDashboardsBiomarkerGrouping from "./BloodLabDashboardsBiomarkerGrouping"

const useStyles = makeAppStyles((theme) => ({
  container: {
    borderRadius: 6,
    border: `1px solid ${colors.orange[500]}`,
    boxShadow:
      "0px 1px 2px 0px rgba(0, 0, 0, 0.06), 0px 1px 3px 0px rgba(0, 0, 0, 0.10)",
  },
  header: {
    backgroundColor: colors.orange[50],
    color: colors.orange[800],
    padding: "11px 20px",
    borderRadius: 6,
    fontFamily: "Open Sans",
    fontSize: "15px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "135%",
  },
}))

export default function BloodLabDashboardsAbnormalSection({
  discreteResults,
  showHighLowDescriptions,
  useOptimalRanges,
  biomarkerCustomDescriptionsMapping,
}: {
  discreteResults: DiscreteResult[]
  showHighLowDescriptions?: boolean
  useOptimalRanges?: boolean
  biomarkerCustomDescriptionsMapping?: Record<
    string,
    BiomarkerCustomDescription
  >
}) {
  const classes = useStyles()
  return (
    <div className={classes.container}>
      <div className={classes.header}>Abnormal</div>
      <div>
        <BloodLabDashboardsBiomarkerGrouping
          discreteResults={discreteResults}
          showHighLowDescriptions={showHighLowDescriptions}
          useOptimalRanges={useOptimalRanges}
          biomarkerCustomDescriptionsMapping={
            biomarkerCustomDescriptionsMapping
          }
        />
      </div>
    </div>
  )
}
