import { FormProvider, useForm, useWatch } from "react-hook-form"
import { z } from "zod"

import { zodResolver } from "@hookform/resolvers/zod"
import { Divider, Grid, makeStyles, Theme } from "@material-ui/core"
import MuiDialogContent from "@material-ui/core/DialogContent"
import MuiDialogTitle from "@material-ui/core/DialogTitle"
import IconButton from "@material-ui/core/IconButton"
import CloseIcon from "@material-ui/icons/Close"

import BodyText from "app/components/design-system/BodyText"
import DesignSystemButton from "app/components/design-system/Button"
import DisplayText from "app/components/design-system/DisplayText"
import { ModalActions, Dialog } from "app/components/modals"
import {
  ControlledSelectField,
  ControlledTextField,
} from "app/main/patient-checkout/fields"
import { US_STATES } from "app/main/patient-checkout/utils/usStates"
import { colors, navy } from "app/theme"
import { Patient, ResendOrderEventBody } from "app/types"

const styles = (theme: Theme) => ({
  dialogPaper: {
    [theme.breakpoints.up("md")]: {
      minWidth: 445,
    },
    margin: 15,
    borderRadius: 7,
  },
  titleAndInfoContainer: {
    display: "flex",
    "flex-direction": "column",
    "flex-wrap": "wrap",
    marginRight: 24,
  },
  closeButton: {
    color: navy,
    position: "relative" as "relative",
    top: -5,
    right: 0,
    marginRight: -12,
  },
  styledDialogTitle: {
    display: "flex",
    "flex-direction": "row",
    justifyContent: "space-between",
    "flex-wrap": "nowrap",
    backgroundColor: "white",
    borderBottomWidth: 1,
    borderColor: colors.blueGray[200],
    alignItems: "center",
    padding: "15px 24px 8px 24px",
  },
  styledContent: {
    padding: theme.spacing(2),
    backgroundColor: colors.coolGray[100],
  },
  divider: {
    width: "100%",
  },
})

const useStyles = makeStyles(styles)

interface InstructionsModalProps {
  open: boolean
  onClose: () => void
  labTestName: string
  patient: Patient
  resendFn: (body: ResendOrderEventBody) => void
}

interface SendNewKitModalFormData {
  street_1: string
  street_2?: string
  city: string
  state: string
  zipcode: string
  reason_for_resend: string
  other_reason_message?: string
}

const SendNewKitFormSchema = z.object({
  street: z.string(),
  street_2: z.string().optional(),
  city: z.string(),
  state: z.string().length(2),
  zipcode: z.string().length(5),
  reason_for_resend: z.string(),
  other_reason_message: z.string().optional(),
})

const REASONS_FOR_RESEND = [
  "Patient Address Change",
  "Damage/Incomplete Kit",
  "Expired Kit",
  "Lost Kit",
  "Sample Lost",
  "Sample Collection Error",
  "Other",
]

export default function SendNewKitModal({
  open,
  onClose,
  labTestName,
  patient,
  resendFn,
}: InstructionsModalProps) {
  const classes = useStyles(styles)
  const defaultValues: SendNewKitModalFormData = {
    street_1: patient.default_shipping_address?.street_1 || "",
    street_2: patient.default_shipping_address?.street_2 || "",
    city: patient.default_shipping_address?.city || "",
    state: patient.default_shipping_address?.state || "",
    zipcode: patient.default_shipping_address?.zipcode || "",
    reason_for_resend: "Lost Kit",
    other_reason_message: "",
  }
  const methods = useForm<SendNewKitModalFormData>({
    defaultValues,
    resolver: zodResolver(SendNewKitFormSchema),
    mode: "onChange",
  })

  const reasonForResendField = useWatch({
    control: methods.control,
    name: "reason_for_resend",
  })

  const didPatientAddressChange = (values: any): boolean => {
    const address = patient.default_shipping_address
    if (!address) {
      // If no address is previously set, return true as it has changed
      return true
    }
    return (
      values.street_1 !== address.street_1 ||
      values.street_2 !== address.street_2 ||
      values.city !== address.city ||
      values.state !== address.state ||
      values.zipcode !== address.zipcode
    )
  }

  const generateResend = () => {
    const values = methods.getValues()

    const body: ResendOrderEventBody = {
      patient_address: null,
      reason_for_resend: values.reason_for_resend,
    }

    if (values.reason_for_resend === "Other") {
      body.other_reason_message = values.other_reason_message
    }

    if (didPatientAddressChange(values)) {
      body.patient_address = {
        street_1: values.street_1,
        street_2: values.street_2,
        city: values.city,
        state: values.state,
        zipcode: values.zipcode,
      }
    }

    resendFn(body)
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="send-new-kit-modal"
      classes={{
        paper: classes.dialogPaper,
      }}
    >
      <TitleSection title={`Resend ${labTestName}`} onClose={onClose} />
      <MuiDialogContent className={classes.styledContent}>
        <Grid container direction="row" spacing={2}>
          <FormProvider {...methods}>
            <Grid item xs={12}>
              <BodyText size="sm" weight="semibold">
                {`${patient.first_name}'s Address`}
              </BodyText>
            </Grid>
            <Grid item xs={12}>
              <ControlledTextField
                aria-label="Street Address"
                name="street"
                placeholder="Street Address Line 1"
                fullWidth
                inputProps={{
                  style: {
                    fontSize: 15,
                  },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <ControlledTextField
                aria-label="Street Address Line 2"
                name="street_2"
                placeholder="Street Address Line 2"
                fullWidth
                inputProps={{
                  style: {
                    fontSize: 15,
                  },
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <ControlledTextField
                aria-label="City"
                name="city"
                placeholder="City"
                fullWidth
                inputProps={{
                  style: {
                    fontSize: 15,
                  },
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <ControlledSelectField
                aria-label="State"
                name="state"
                placeholder="State"
                required
                fullWidth
                inputProps={{
                  style: {
                    fontSize: 15,
                  },
                }}
              >
                {Object.entries(US_STATES).map(([code, name]) => (
                  <option key={code} value={code}>
                    {name}
                  </option>
                ))}
              </ControlledSelectField>
            </Grid>
            <Grid item xs={12}>
              <ControlledTextField
                aria-label="Zipcode"
                name="zipcode"
                placeholder="Zipcode"
                fullWidth
                inputProps={{
                  style: {
                    fontSize: 15,
                  },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Divider className={classes.divider} />
            </Grid>

            <Grid item xs={12}>
              <BodyText size="sm" weight="semibold">
                {`Reason for resending ${labTestName}`}
              </BodyText>
            </Grid>
            <Grid item xs={12}>
              <ControlledSelectField
                aria-label="Reason for Resend"
                name="reason_for_resend"
                required
                fullWidth
                inputProps={{
                  style: {
                    fontSize: 15,
                  },
                }}
              >
                {REASONS_FOR_RESEND.map((reason) => (
                  <option key={reason} value={reason}>
                    {reason}
                  </option>
                ))}
              </ControlledSelectField>
            </Grid>
            {reasonForResendField === "Other" && (
              <Grid item xs={12}>
                <ControlledTextField
                  aria-label="Other reason message"
                  name="other_reason_message"
                  placeholder="Reason for resending kit"
                  multiline
                  rows={4}
                  inputProps={{
                    style: {
                      padding: 0,
                      fontSize: 15,
                    },
                  }}
                  required
                  fullWidth
                />
              </Grid>
            )}
          </FormProvider>
        </Grid>
      </MuiDialogContent>
      <ModalActions>
        <DesignSystemButton
          disabled={!methods.formState.isValid}
          onClick={generateResend}
          color="primary"
        >
          {`Resend Kit to ${patient.first_name}`}
        </DesignSystemButton>
      </ModalActions>
    </Dialog>
  )
}

const TitleSection = ({ title, onClose }) => {
  const classes = useStyles(styles)
  const closeButton = onClose && (
    <IconButton
      aria-label="close"
      onClick={onClose}
      key="close-button"
      className={classes.closeButton}
    >
      <CloseIcon />
    </IconButton>
  )

  return (
    <MuiDialogTitle disableTypography className={classes.styledDialogTitle}>
      <div className={classes.titleAndInfoContainer}>
        <DisplayText weight="semibold" size="lg">
          {title}
        </DisplayText>
        <BodyText size="base">
          There will be no additional charge for any resent kits due to an issue
          with the original test kit.
        </BodyText>
      </div>
      {closeButton}
    </MuiDialogTitle>
  )
}
