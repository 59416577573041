import { useContext } from "react"

import { isEmpty, snakeCase } from "lodash"

import { ReactComponent as DownloadBlueIcon } from "app/assets/images/download-blue.svg"
import TableRendererContext from "app/dashboard/contexts/TableRendererContext"
import useEventCallback from "app/hooks/use-event-callback"
import useHandleApiError from "app/hooks/use-handle-api-error"
import useCachedResource from "app/swr/hooks/use-cached-resource"
import useMutateResource from "app/swr/hooks/use-mutate-resource"
import { ResourceResponse } from "app/swr/types"
import resourceRequest from "app/swr/utils/resource-request"
import { DashboardKit } from "types/dashboard/dashboard-kit"
import { DashboardOrder } from "types/dashboard/dashboard-order"
import { DashboardOrderedResult } from "types/dashboard/dashboard-ordered-result"
import { OrderedResult } from "types/ordered-result"

import { ActionDropdownMenuItem } from "./ActionDropdownMenuButton"

export interface DownloadResultsDropdownMenuActionProps {
  kit: DashboardKit
  order: DashboardOrder
}

export default function DownloadResultsDropdownMenuAction({
  kit,
}: DownloadResultsDropdownMenuActionProps) {
  const acknowledgeUnreadResult = useAcknowledgeUnreadResult()
  const latestOrderedResult = useCachedResource<DashboardOrderedResult>(
    kit.relationships.latest_ordered_result?.data
  )

  const { downloadable_result_urls } = kit.attributes
  const hasResultsToDownload = !isEmpty(downloadable_result_urls)

  const onSelect = useEventCallback(async () => {
    if (!hasResultsToDownload) {
      return
    }

    downloadable_result_urls.forEach((url, index) => {
      window.open(
        url,
        `${snakeCase(kit.attributes.name.toLowerCase())}_result_${index}`
      )
    })

    if (latestOrderedResult?.attributes.has_unread_notification) {
      // Let this promise run asynchronously.
      acknowledgeUnreadResult(latestOrderedResult.id)
    }
  })

  return (
    <ActionDropdownMenuItem
      disabled={!hasResultsToDownload}
      icon={<DownloadBlueIcon fill="currentColor" viewBox="0 0 18 18" />}
      label="Download Results"
      onSelect={onSelect}
    />
  )
}

export function useAcknowledgeUnreadResult() {
  const { unreadResults, revalidateOrders } = useContext(TableRendererContext)
  const mutateResource = useMutateResource()
  const handleApiError = useHandleApiError()
  return useEventCallback(
    async (orderedResultId: string, shouldRevalidate: boolean = true) => {
      try {
        const request = resourceRequest<ResourceResponse<OrderedResult>>({
          method: "patch",
          url: `/ordered_results/${orderedResultId}/`,
          data: {
            data: {
              type: "ordered_result",
              id: orderedResultId,
              attributes: { acknowledge_unread_result: true },
            },
          },
        })

        await mutateResource<DashboardOrderedResult>(
          {
            type: "dashboard_ordered_result",
            id: orderedResultId,
          },
          async (cachedData) => {
            if (!cachedData) {
              return cachedData
            }

            await request

            return {
              ...cachedData,
              attributes: {
                ...cachedData.attributes,
                has_unread_notification: false,
              },
              relationships: {
                ...cachedData.relationships,
              },
            }
          },
          {
            throwOnError: true,
          }
        )

        // There are some scenarios where the TableContext is not initialized (blood labs) - we should not revalidate in those cases
        if (shouldRevalidate) {
          // Revalidate unread results as the count may have changed
          unreadResults.revalidate()

          // Revalidate current page of orders to be sure we have the latest data without unread notifications
          revalidateOrders()
        }
      } catch (error) {
        handleApiError(error)
      }
    }
  )
}
