import NiceModal, { muiDialog, useModal } from "@ebay/nice-modal-react"
import { Dialog, DialogContent } from "@material-ui/core"

import { colors } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"

import { ConfirmationSection } from "./Sections/ConfirmationSection"
import { BookingData } from "./hooks/use-booking-data"

const useStyles = makeAppStyles((theme) => ({
  dialog: {
    border: "6px solid var(--White, #FFF)",
    borderRadius: 12,
    maxWidth: "100%",
    [theme.breakpoints.down("xs")]: {
      maxWidth: "100%",
      margin: 0,
      width: "100vw",
    },
  },
  content: {
    backgroundColor: colors.blueGray[50],
    maxWidth: 475,
  },
}))

const ConfirmationModal = (props: {
  data: BookingData | undefined
  loginUrl: string | undefined
  fromLocation: string | undefined
}) => {
  const modal = useModal()
  const classes = useStyles()
  const muiDialogControl = muiDialog(modal)

  return (
    <Dialog
      {...muiDialogControl}
      maxWidth="md"
      className={classes.dialog}
      classes={{ paper: classes.dialog }}
    >
      <DialogContent className={classes.content}>
        <ConfirmationSection
          data={props.data}
          loginUrl={props.loginUrl}
          fromLocation={props.fromLocation}
        />
      </DialogContent>
    </Dialog>
  )
}

const YourNiceModal = NiceModal.create(ConfirmationModal)

export default YourNiceModal
