import React from "react"

import { Link } from "react-router-dom"

import Button from "@material-ui/core/Button"

import { ReactComponent as BloodDropIcon } from "app/assets/icons/phlebotomists-dynamic.svg"

import { MarkAsComplete } from "../MarkAsCompleteButton"
import TaskContainer from "../TaskContainer"
import taskStyles from "../TaskStyles"
import { OnboardingTaskItemProps } from "./OnboardingTaskItem"

const PreferredPhlebotomoy: React.FC<OnboardingTaskItemProps> = ({
  task,
  markTaskAsComplete,
}) => {
  const taskClasses = taskStyles()

  const subCardContent = (
    <div className={taskClasses.subHeaderContainer}>
      <MarkAsComplete onClick={() => markTaskAsComplete(task.key)} />
    </div>
  )

  return (
    <TaskContainer
      taskKey={task.key}
      header="Set Up Your Phlebotomy Instructions"
      isTaskComplete={task.complete}
      timeTag={"2 mins"}
      subCardContent={subCardContent}
    >
      <BloodDropIcon className={taskClasses.bloodDropIcon} />
      <div className={taskClasses.taskTextContainer}>
        <div className={taskClasses.taskText}>
          Have specific phlebotomists you want your patients to see, or work
          with a phlebotomist in-house?
        </div>
        <div
          className={taskClasses.taskText}
          style={{ fontWeight: 400, marginTop: 10 }}
        >
          Add a preferred phlebotomist and their information will be sent to
          every patient within 25 miles who requires a blood draw.
        </div>
      </div>
      <Link to="/settings/phlebotomists" style={{ textDecoration: "none" }}>
        <Button
          variant="contained"
          color="primary"
          className={taskClasses.taskButton}
          style={{ marginBottom: 0 }}
        >
          <span className={taskClasses.buttonText}>
            Add a preferred phlebotomist
          </span>
        </Button>
      </Link>
    </TaskContainer>
  )
}

export default PreferredPhlebotomoy
