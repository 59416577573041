import MarkdownToJsx from "markdown-to-jsx"

import { styled } from "@material-ui/core"

import { primaryColor, textPrimaryColor } from "app/theme"

interface Props {
  children: string
  className?: string
  padded?: boolean
}

const StyledMarkdown = styled(({ padded, ...rest }) => (
  <MarkdownToJsx {...rest} />
))({
  color: textPrimaryColor,
  fontWeight: 400,
  paddingInlineStart: ({ padded }: { padded: boolean }) => (padded ? 24 : 0),
  "& a": {
    color: primaryColor,
  },

  "& p:not(:last-child)": {
    marginBottom: 12,
  },
})

const Markdown = ({ children, className, padded = true }: Props) => (
  <StyledMarkdown padded={padded} className={className}>
    {children}
  </StyledMarkdown>
)

export default Markdown
