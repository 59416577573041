import { useState } from "react"

import Tooltip from "app/components/Tooltip"
import BodyText from "app/components/design-system/BodyText"

import { PractitionerProfile } from "./types"

interface Props {
  profile: PractitionerProfile | undefined
  children: any
}

const fieldMap = {
  profile_photo: "Profile Photo",
  website: "Website",
  waitlist_time_for_appointment:
    "How soon could someone get an appointment with you?",
  practice_location: "Practice Location",
  zip_code: "Zip Code",
  number_of_patients_provided_care_for:
    "How many patients have you provided care for?",
  licensed_regions: "In which states can you see clients?",
  year_started_practicing: "Year started practicing",
  pricing_models: "Pricing Model",
  pricing_for_clients: "Pricing for Clients",
  insurance_accepted: "Insurance",
  specialties: "Specialties",
  conditions_treated: "Conditions Treated",
  education_credentials: "Education Credentials",
  city: "City",
  state: "State",
  has_free_initial_patient_consulation: "Free Initial Consultation",
  insurance_type: "Insurance Type",
}

const PractitionerProfileTooltip = ({ profile, children }: Props) => {
  const [isTooltipOpen, setIsTooltipOpen] = useState(false)

  const [tooltipMessage, setTooltipMessage] = useState<any>(null)

  const evaluateTooltipState = () => {
    let conditionTreatmentExplanationsAdded = false
    const hasMissingFields = !!profile?.attributes?.missing_fields.length
    if (hasMissingFields) {
      const items = profile?.attributes?.missing_fields
        .map((field) => {
          if (field.indexOf("condition_treatment_explanations.") === 0) {
            if (conditionTreatmentExplanationsAdded) {
              return null
            }

            conditionTreatmentExplanationsAdded = true
            return "How you have helped clients with each condition"
          }
          return fieldMap[field]
        })
        .filter((field) => !!field)
        .map((field) => <li key={field}>{field}</li>)

      let message = (
        <>
          <BodyText weight="bold">The following fields are required:</BodyText>
          <ul style={{ textAlign: "left" }}>{items}</ul>
        </>
      )

      // Fallback in case there is no mapping for a particular field
      if (items.length === 0) {
        message = <BodyText>Some required fields are missing.</BodyText>
      }

      setTooltipMessage(message)
    }

    setIsTooltipOpen(hasMissingFields)
  }

  return (
    <>
      <Tooltip
        arrow
        open={!!isTooltipOpen}
        disableFocusListener
        placement="bottom"
        title={tooltipMessage ?? ""}
      >
        <div
          onMouseOver={evaluateTooltipState}
          onMouseLeave={() => setIsTooltipOpen(false)}
        >
          {children}
        </div>
      </Tooltip>
    </>
  )
}

export default PractitionerProfileTooltip
