import BodyText from "app/components/design-system/BodyText"
import useFeatureFlag, { FeatureFlag } from "app/hooks/use-feature-flag"

import { StyledFormControlLabel } from "../components/StyledFormControlLabel"
import { StyledSwitch } from "../components/StyledSwitch"

interface Props {
  toggled: boolean
  onToggle: () => void
}

const RupaBloodDashboardsEnableSwitch = ({ toggled, onToggle }: Props) => {
  const [labcorpEnabledForBloodLabsDashboard] = useFeatureFlag(
    FeatureFlag.LabcorpEnabledForBloodLabsDashboard
  )

  const labCompanyText = labcorpEnabledForBloodLabsDashboard
    ? "Labcorp, Access Medical Labs, or Boston Heart"
    : "Access Medical Labs or Boston Heart"

  return (
    <div>
      <StyledFormControlLabel
        control={
          <StyledSwitch
            checked={toggled}
            onChange={onToggle}
            name="enable_rupa_blood_dashboards"
          />
        }
        label={
          <div>
            <BodyText weight="semibold">Use Rupa Blood Dashboard</BodyText>
            <BodyText>
              When you send clients their blood lab results for {labCompanyText}
              , we’ll give your clients access to their Rupa Blood Lab
              Dashboard.
            </BodyText>
          </div>
        }
      />
    </div>
  )
}

export default RupaBloodDashboardsEnableSwitch
