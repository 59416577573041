import DeleteIcon from "app/assets/icons/bundles/tooltip-nav-icons/delete-action.svg"
import ShareWithClinicIcon from "app/assets/icons/share-with-clinic.svg"
import { NavItem } from "app/components/design-system/TooltipNav/constants"
import { cancelRed, colors } from "app/theme"
import { BankAccount, PaymentCard } from "app/types"

interface BundleNavItems {
  navItems: Array<NavItem>
}

interface Props {
  paymentMethod: PaymentCard | BankAccount
  handleDelete: () => void
  handleShare: () => void
}

export function usePaymentMethodNavItems({
  paymentMethod,
  handleDelete,
  handleShare,
}: Props): BundleNavItems {
  let navItems: NavItem[] = [
    {
      id: "delete",
      icon: DeleteIcon,
      name: "Delete Payment Method",
      onClick: handleDelete,
      color: cancelRed,
      background: colors.red[50],
    },
  ]

  if (!paymentMethod.is_shared_with_clinic) {
    navItems = [
      {
        id: "share-with-clinic",
        icon: ShareWithClinicIcon,
        name: "Share with Clinic",
        onClick: handleShare,
      },
      ...navItems,
    ]
  }

  return {
    navItems,
  }
}
