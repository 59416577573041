import { RootState } from "app/types"

import * as Actions from "../actions/labCompany.actions"

const initialState: RootState["labCompany"] = {
  list: null,
}

function labCompanyReducer(
  state = initialState,
  action: Actions.GetLabCompanyListAction
): typeof initialState {
  switch (action.type) {
    case Actions.GET_LAB_COMPANY_LIST_SUCCESS: {
      return {
        ...state,
        list: action.payload,
      }
    }
    default: {
      return state
    }
  }
}

export default labCompanyReducer
