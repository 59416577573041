import { useEffect } from "react"

import { useHistory, useLocation } from "react-router-dom"

import { ReactComponent as LinearChartIcon } from "app/assets/icons/results-summary/linear-chart.svg"
import Button from "app/components/design-system/Button"
import useQuery from "app/hooks/use-query"
import { PatientPortalOrder } from "app/patient-portal/types"
import makeAppStyles from "app/utils/makeAppStyles"

const useStyles = makeAppStyles((theme) => ({
  button: {
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "auto",
    },
    background:
      "linear-gradient(122.24deg, #21CFCD -27.23%, #2885E0 106.45%), #0075CD",
    border: 0,
    borderRadius: "8px",
  },
}))

export interface DownloadResultsInterpretationButtonProps {
  order: PatientPortalOrder
}

export default function DownloadResultsInterpretationButton({
  order,
}: DownloadResultsInterpretationButtonProps) {
  const classes = useStyles()
  const history = useHistory()
  const location = useLocation()
  const query = useQuery()

  const automaticallyOpen = Boolean(query.get("open-results-interpretation"))

  useEffect(() => {
    if (automaticallyOpen && order.attributes.results_interpretation_pdf) {
      // Open PDF in new tab
      window.open(order.attributes.results_interpretation_pdf, "_blank")

      // Remove query param from URL so that it doesn't open again on a refresh
      const queryParams = new URLSearchParams(location.search)
      queryParams.delete("open-results-interpretation")
      history.replace({
        search: queryParams.toString(),
      })
    }
  }, [])

  if (!order.attributes.results_interpretation_pdf) {
    return null
  }

  return (
    <Button
      className={classes.button}
      size="small"
      href={order.attributes.results_interpretation_pdf}
      startIcon={<LinearChartIcon fill="currentColor" viewBox="0 0 18 17" />}
      target="_blank"
      type="button"
    >
      Results Summary
    </Button>
  )
}
