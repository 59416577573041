import { SearchSuggestionsResponse } from "app/types"

export const START_TYPING = "[AUTOSUGGEST] START_TYPING"
export const END_TYPING = "[AUTOSUGGEST] END_TYPING"
export const REQUEST_SUGGESTIONS = "[AUTOSUGGEST] REQUEST_SUGGESTIONS"
export const RECEIVE_SUGGESTIONS = "[AUTOSUGGEST] RECEIVE_SUGGESTIONS"
export const FAILED_SUGGESTIONS = "[AUTOSUGGEST] FAILED_SUGGESTIONS"

export interface SuggestionAction<T = string> {
  type: T
}

export type StartTypingAction = SuggestionAction<typeof START_TYPING>
export type EndTypingAction = SuggestionAction<typeof END_TYPING>
export type RequestSuggestionsAction = SuggestionAction<
  typeof REQUEST_SUGGESTIONS
>
export interface FailedSuggestionsAction
  extends SuggestionAction<typeof FAILED_SUGGESTIONS> {
  payload: Error
}
export interface ReceiveSuggestionsAction
  extends SuggestionAction<typeof RECEIVE_SUGGESTIONS> {
  payload: SearchSuggestionsResponse
}

export function startTyping(): StartTypingAction {
  return { type: START_TYPING }
}

export function endTyping(): EndTypingAction {
  return { type: END_TYPING }
}

export function requestSuggestions(): RequestSuggestionsAction {
  return {
    type: REQUEST_SUGGESTIONS,
  }
}

export function receiveSuggestions(
  response: SearchSuggestionsResponse
): ReceiveSuggestionsAction {
  return {
    type: RECEIVE_SUGGESTIONS,
    payload: response,
  }
}

export function failedSuggestions(error: Error): FailedSuggestionsAction {
  return {
    type: FAILED_SUGGESTIONS,
    payload: error,
  }
}
