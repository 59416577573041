import useEventCallback from "app/hooks/use-event-callback"
import useHandleApiError from "app/hooks/use-handle-api-error"
import useCachedCollection from "app/swr/hooks/use-cached-collection"
import useCollectionSWR from "app/swr/hooks/use-collection-swr"
import {
  ResourceCollection,
  ResourceIdentifier,
  ResourceResponse,
} from "app/swr/types"
import resourceRequest from "app/swr/utils/resource-request"
import {
  BiomarkerCustomDescription,
  BiomarkerCustomDescriptionCreate,
} from "types/biomarker-custom-description"

export default function useBiomarkerCustomDescriptions() {
  const handleApiError = useHandleApiError()

  const {
    data,
    mutate: mutateBiomarkerCustomDescriptions,
    isLoading,
  } = useCollectionSWR<ResourceCollection<BiomarkerCustomDescription>>(
    "/biomarker_custom_descriptions/",
    {
      include: ["biomarker"],
    }
  )

  const customBiomarkerDescriptions =
    useCachedCollection<BiomarkerCustomDescription>(data)

  const biomarkerIds = customBiomarkerDescriptions.map(
    (description) => description.relationships.biomarker?.data?.id
  )

  const onCreateBiomarkerCustomDescription = useEventCallback(
    async (payload: BiomarkerCustomDescriptionCreate) => {
      try {
        const { data: responseData } = await resourceRequest<
          ResourceResponse<BiomarkerCustomDescription>,
          { data: BiomarkerCustomDescriptionCreate }
        >({
          method: "post",
          url: `/biomarker_custom_descriptions/`,
          data: {
            data: payload,
          },
        })

        await mutateBiomarkerCustomDescriptions(
          async (previousData) => {
            if (!previousData) {
              return previousData
            }
            const newData = [responseData, ...previousData.data]

            return {
              data: newData,
              meta: {
                count: previousData.meta?.count + 1,
              },
            }
          },
          {
            revalidate: true,
            throwOnError: true,
          }
        )

        return responseData
      } catch (error) {
        handleApiError(error)
      }
    }
  )

  const onDeleteBiomarkerCustomDescription = useEventCallback(
    async (biomarkerCustomDescriptionId: string) => {
      try {
        await mutateBiomarkerCustomDescriptions(
          async (previousData) => {
            if (!previousData) {
              return previousData
            }

            await resourceRequest({
              url: `/biomarker_custom_descriptions/${biomarkerCustomDescriptionId}/`,
              method: "delete",
            })

            return {
              data: previousData.data.filter(
                (identifier: ResourceIdentifier) =>
                  identifier.id !== biomarkerCustomDescriptionId
              ),
              meta: {
                count: previousData.meta?.count - 1,
              },
            }
          },
          {
            revalidate: false,
            throwOnError: true,
          }
        )
      } catch (error) {
        handleApiError(error)
      }
    }
  )

  return {
    customBiomarkerDescriptions,
    biomarkerIds,
    mutateBiomarkerCustomDescriptions,
    isLoading,
    onDeleteBiomarkerCustomDescription,
    onCreateBiomarkerCustomDescription,
  }
}
