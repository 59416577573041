export enum TransactionReportInvocationStatus {
  READY = "ready",
  PROCESSING = "processing",
  SUCCESSFUL = "successful",
  FAILED = "failed",
}

export enum BillingAndCustomFeesEvent {
  VIEW_BILLING_AND_CUSTOM_FEES = "tr_view_custom_fees_billing_tab",
  TRANSACTION_REPORT_DATE_RANGE_ADJUSTED = "tr_date_range_adjusted",
  VIEW_STRIPE_TUTORIAL = "tr_view_stripe_tutorial",
  TRANASACTION_REPORT_CLICK_DOWNLOAD = "tr_click_download",
  TRANSACTION_REPORT_RECEIVED_DOWNLOAD = "tr_received_download",
  TRANSACTION_REPORT_CLICK_OPEN_MODAL = "tr_click_open_modal",
}
