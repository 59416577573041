import { CustomFee, StripeConnectAccount } from "app/types"

import * as Actions from "../actions"

const initialState = {
  stripeConnectAccounts: Array<StripeConnectAccount>(),
  customFees: Array<CustomFee>(),
}

const customFeesReducers = function (state = initialState, action) {
  switch (action.type) {
    case Actions.CREATE_STRIPE_CONNECT_ACCOUNT: {
      return {
        ...state,
        stripeConnectAccounts: [...state.stripeConnectAccounts, action.payload],
      }
    }
    case Actions.CREATE_CUSTOM_FEE: {
      return {
        ...state,
        customFees: [...state.customFees, action.payload],
      }
    }
    case Actions.UPDATE_CUSTOM_FEE: {
      return {
        ...state,
        customFees: state.customFees.map((customFee) => {
          if (customFee.id === action.payload.id) {
            return action.payload
          }

          return customFee
        }),
      }
    }
    case Actions.GET_STRIPE_CONNECT_ACCOUNTS: {
      return {
        ...state,
        stripeConnectAccounts: action.payload,
      }
    }
    case Actions.GET_CUSTOM_FEES: {
      return {
        ...state,
        customFees: action.payload,
      }
    }
    case Actions.REMOVE_CUSTOM_FEE: {
      return {
        ...state,
        customFees: action.payload,
      }
    }
    default: {
      return state
    }
  }
}

export default customFeesReducers
