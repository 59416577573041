import { LAB_COMPANY_KEY } from "app/constants"
import { OrderedTest } from "app/types"

/**
 * @deprecated in favor of backend api.apps.core.services.clinical_consult.service.py
 */
class ClinicalConsultAvailabilityConfiguration {
  orderedTest: OrderedTest
  unavailableReason: string
  constructor(orderedTest: OrderedTest) {
    this.orderedTest = orderedTest
    this.unavailableReason = ""
  }
  isAvailable() {
    return false
  }
}

/**
 * @deprecated in favor of backend api.apps.core.services.clinical_consult.service.py
 */
class DefaultClinicalConsultAvailabilityConfiguration extends ClinicalConsultAvailabilityConfiguration {
  unavailableReason = "(Available When Results Are In)"
  isAvailable() {
    return Boolean(this.orderedTest.results)
  }
}

/**
 * @deprecated in favor of backend api.apps.core.services.clinical_consult.service.py
 */
class OrderedByRupaClinicalConsultAvailabilityConfiguration extends ClinicalConsultAvailabilityConfiguration {
  unavailableReason = "(Available Once Order Has Been Placed)"
  isAvailable() {
    return (
      Boolean(this.orderedTest.date_ordered_by_rupa) ||
      Boolean(this.orderedTest.results)
    )
  }
}

/**
 * @deprecated in favor of backend api.apps.core.services.clinical_consult.service.py
 */
class SampleReceivedAtLabClinicalConsultAvailabilityConfiguration extends ClinicalConsultAvailabilityConfiguration {
  unavailableReason = "(Available When Sample Is Received At Lab)"
  isAvailable() {
    return (
      Boolean(this.orderedTest.date_sample_received_at_lab) ||
      Boolean(this.orderedTest.results)
    )
  }
}

/**
 * @deprecated in favor of backend api.apps.core.services.clinical_consult.service.py
 */
class AccessionIdAvailableConfiguration extends ClinicalConsultAvailabilityConfiguration {
  // Configuration for labs that we want to allow clinical consults as soon as the accession id is available
  // As of 3/16, most of these cases are labs that we need to have ops manually enter the accession id, which is
  //     available only after the sample is received at the lab
  unavailableReason = "(Available After Sample Is Received At Lab)"
  isAvailable() {
    return (
      Boolean(this.orderedTest.accession_id) ||
      Boolean(this.orderedTest.results)
    )
  }
}

/**
 * @deprecated in favor of backend api.apps.core.services.clinical_consult.service.py
 */
class BioreferenceClinicalConsultAvailabilityConfiguration extends ClinicalConsultAvailabilityConfiguration {
  unavailableReason =
    "(Clinical consults are not available for BioReference tests)"
  isAvailable() {
    return false
  }
}

/**
 * @deprecated in favor of backend api.apps.core.services.clinical_consult.service.py
 */
const ClinicalConsultAvailabilityConfigurationMapping = {
  [LAB_COMPANY_KEY.BIOREFERENCE]:
    BioreferenceClinicalConsultAvailabilityConfiguration,
  [LAB_COMPANY_KEY.CYREX]:
    OrderedByRupaClinicalConsultAvailabilityConfiguration,
  [LAB_COMPANY_KEY.VIBRANT_AMERICA]:
    SampleReceivedAtLabClinicalConsultAvailabilityConfiguration,
  [LAB_COMPANY_KEY.VIBRANT_WELLNESS]:
    SampleReceivedAtLabClinicalConsultAvailabilityConfiguration,
  [LAB_COMPANY_KEY.DSL]: AccessionIdAvailableConfiguration,
  [LAB_COMPANY_KEY.MOSAIC_DIAGNOSTICS]: AccessionIdAvailableConfiguration,
  [LAB_COMPANY_KEY.PRECISION_ANALYTICAL]: AccessionIdAvailableConfiguration,
}

/**
 * Returns the availability information for a given ordered test
 * @param orderedTest
 * @deprecated in favor of backend api.apps.core.services.clinical_consult.service.py
 */
export const getClinicalConsultAvailability = (orderedTest: OrderedTest) => {
  const labCompanyKey = orderedTest.lab_test.lab_company.key
  if (ClinicalConsultAvailabilityConfigurationMapping[labCompanyKey]) {
    return new ClinicalConsultAvailabilityConfigurationMapping[labCompanyKey](
      orderedTest
    )
  }
  return new DefaultClinicalConsultAvailabilityConfiguration(orderedTest)
}
