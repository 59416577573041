import { useState } from "react"
import { useDispatch } from "react-redux"

import { makeStyles, useMediaQuery, Grid, Theme, Box } from "@material-ui/core"

import BodysiteLogo from "app/assets/images/bodysite-logo.png"
import CerboLogo from "app/assets/images/cerbo-logo.png"
import ElationHealthLogo from "app/assets/images/elation-logo.svg"
import HealthieLogo from "app/assets/images/healthie-logo.svg"
import KalixLogo from "app/assets/images/kalix-logo.png"
import MyHolisticOfficeLogo from "app/assets/images/my-holistic-office-logo.svg"
import OptimalDxLogo from "app/assets/images/optimal-dx-logo.svg"
import OptimantraLogo from "app/assets/images/optimantra-logo.svg"
import PracticeBetterLogo from "app/assets/images/practice-better-logo.svg"
import PracticeQLogo from "app/assets/images/practiceq-logo.jpg"
import BodyText from "app/components/design-system/BodyText"
import DesignSystemButton from "app/components/design-system/Button"
import {
  PRACTICE_BETTER_CAPITALIZED,
  OPTIMANTRA_CAPITALIZED,
  OPTIMANTRA_TITLE_CASE,
  PRACTICE_BETTER_TITLE_CASE,
  BODYSITE_TITLE_CASE,
  BODYSITE_CAPITALIZED,
  OPTIMAL_DX_CAPITALIZED,
  OPTIMAL_DX_TITLE_CASE,
  PRACTICEQ_TITLE_CASE,
  PRACTICEQ_CAPITALIZED,
  ELATION_HEALTH_TITLE_CASE,
  ELATION_HEALTH_CAPITALIZED,
  HEALTHIE_TITLE_CASE,
  HEALTHIE_CAPITALIZED,
  MY_HOLISTIC_OFFICE_TITLE_CASE,
  MY_HOLISTIC_OFFICE_CAPITALIZED,
  KALIX_TITLE_CASE,
  KALIX_CAPITALIZED,
  CERBO_TITLE_CASE,
  CERBO_CAPITALIZED,
} from "app/constants"
import useFeatureFlag, { FeatureFlag } from "app/hooks/use-feature-flag"
import {
  PRACTICE_BETTER_URL,
  BODYSITE_URL,
  OPTIMAL_DX_URL,
  MY_HOLISTIC_OFFICE_URL,
  PRACTICEQ_URL,
  KALIX_URL,
  HEALTHIE_URL,
  CERBO_URL,
} from "app/settings"
import {
  updatePractitioner,
  fetchPractitionerEmrIntegrations,
  disconnectPractitionerEMRIntegrationAction,
  requestPractitionerEMRIntegrationAction,
} from "app/store/actions"
import { navy, primaryColor } from "app/theme"

import DisconnectEMRModal from "./DisconnectEMRModal"
import EMRConnectionsModal from "./EMRConnectionsModal"
import EMRInstructionsModal from "./EMRInstructionsModal"
import { StyledFormControlLabel } from "./StyledFormControlLabel"
import { StyledSwitch } from "./StyledSwitch"

const useStyles = makeStyles((theme: Theme) => ({
  navyText: {
    color: navy,
    paddingTop: "24px",
  },
  styledLink: {
    color: primaryColor,
    fontWeight: 600,
    fontSize: 14,
    [theme.breakpoints.up("sm")]: {
      marginLeft: 4,
    },
  },
  tutorialLink: {
    color: primaryColor,
  },
  learnMoreArrow: {
    width: 10,
  },
  requestKitsButton: {
    marginTop: 12,
  },
  fullWidth: {
    width: "100%",
  },
  practiceQLogo: {
    maxWidth: "50%",
  },
  kalixLogo: {
    width: "60%",
    marginLeft: "-12%",
  },
  healthieLogo: {
    width: "60%",
  },
  cerboLogo: {
    maxWidth: "65%",
  },
}))

interface EMRIntegration {
  active: boolean
  name: string
  logo: string
  logoClass?: string
  description: string
  instructionText: string
  connectButtonText: string
  connectFn: () => void
  disconnectFn: (disconnectingReason: string) => void
}

function EMRSettings({ emrIntegrations }) {
  const classes = useStyles()
  const [isOpen, setIsOpen] = useState(false)
  const [selectedEmr, setSelectedEmr] = useState(null)
  const [isDisconnectOpen, setIsDisconnectOpen] = useState(false)
  const [disconnectingEmr, setDisconnectingEmr] =
    useState<EMRIntegration | null>(null)
  const dispatch = useDispatch()
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"))
  const [isHolisticOfficeEnabled] = useFeatureFlag(
    FeatureFlag.isHolisticOfficeEnabled
  )

  const emrMaps: EMRIntegration[] = [
    {
      active:
        emrIntegrations.find(
          (int) => int.emr_integration === PRACTICE_BETTER_TITLE_CASE
        )?.active || false,
      name: PRACTICE_BETTER_TITLE_CASE,
      logo: PracticeBetterLogo,
      description: `Connect to order and receive all results in the “Labs” Tab within ${PRACTICE_BETTER_TITLE_CASE}.`,
      instructionText: `To connect to ${PRACTICE_BETTER_TITLE_CASE}, click the “Connect” button below. You will be asked to sign in to your Practice Better account.`,
      connectButtonText: `Connect in ${PRACTICE_BETTER_TITLE_CASE}`,
      connectFn: () => {
        setSelectedEmr(null)
        setIsOpen(false)
        window.open(PRACTICE_BETTER_URL, "_blank")
      },
      disconnectFn: async (disconnectingReason: string) => {
        await dispatch(
          disconnectPractitionerEMRIntegrationAction(
            PRACTICE_BETTER_CAPITALIZED,
            disconnectingReason
          )
        )
        await dispatch(fetchPractitionerEmrIntegrations())
        setSelectedEmr(null)
        setIsOpen(false)
        setIsDisconnectOpen(false)
        setDisconnectingEmr(null)
      },
    },
    {
      active:
        emrIntegrations.find((int) => int.emr_integration === "Optimantra")
          ?.active || false,
      name: `${OPTIMANTRA_TITLE_CASE} Results Connection`,
      logo: OptimantraLogo,
      description: `All results will arrive directly in the "Labs/Diagnostics" Tab within ${OPTIMANTRA_TITLE_CASE}.`,
      instructionText: `Please use the button below to activate your OptiMantra account. All results from your Rupa Health account will be sent to ${OPTIMANTRA_TITLE_CASE}.`,
      connectButtonText: `Connect to ${OPTIMANTRA_TITLE_CASE}`,
      connectFn: async () => {
        await dispatch(
          updatePractitioner(
            { optimantra_integration: true },
            true,
            `${OPTIMANTRA_TITLE_CASE} Results Connection enabled`
          )
        )
        await dispatch(fetchPractitionerEmrIntegrations())
        setSelectedEmr(null)
        setIsOpen(false)
      },
      disconnectFn: async (disconnectingReason: string) => {
        await dispatch(
          disconnectPractitionerEMRIntegrationAction(
            OPTIMANTRA_CAPITALIZED,
            disconnectingReason
          )
        )
        await dispatch(fetchPractitionerEmrIntegrations())
        setIsOpen(false)
        setSelectedEmr(null)
        setIsDisconnectOpen(false)
        setDisconnectingEmr(null)
      },
    },
    {
      active:
        emrIntegrations.find((int) => int.emr_integration === "Bodysite")
          ?.active || false,
      name: `${BODYSITE_TITLE_CASE} Results Connection`,
      logo: BodysiteLogo,
      description: `Connect to order and receive all results in the “Labs” Tab within ${BODYSITE_TITLE_CASE}.`,
      instructionText: `To connect to ${BODYSITE_TITLE_CASE}, click the “Connect” button below. You will be asked to sign in to your ${BODYSITE_TITLE_CASE} account.`,
      connectButtonText: `Connect in ${BODYSITE_TITLE_CASE}`,
      connectFn: () => {
        setSelectedEmr(null)
        setIsOpen(false)
        window.open(BODYSITE_URL, "_blank")
      },
      disconnectFn: async (disconnectingReason: string) => {
        await dispatch(
          disconnectPractitionerEMRIntegrationAction(
            BODYSITE_CAPITALIZED,
            disconnectingReason
          )
        )
        await dispatch(fetchPractitionerEmrIntegrations())
        setSelectedEmr(null)
        setIsOpen(false)
        setIsDisconnectOpen(false)
        setDisconnectingEmr(null)
      },
    },
    {
      active:
        emrIntegrations.find((int) => int.emr_integration === "Optimal Dx")
          ?.active || false,
      name: `${OPTIMAL_DX_TITLE_CASE} Results Connection`,
      logo: OptimalDxLogo,
      description: `Connect to receive all results in the “Labs” Tab within ${OPTIMAL_DX_TITLE_CASE}.`,
      instructionText: `To connect to ${OPTIMAL_DX_TITLE_CASE}, click the “Connect” button below. You will be asked to sign in to your ${OPTIMAL_DX_TITLE_CASE} account.`,
      connectButtonText: `Connect in ${OPTIMAL_DX_TITLE_CASE}`,
      connectFn: () => {
        setSelectedEmr(null)
        setIsOpen(false)
        window.open(OPTIMAL_DX_URL, "_blank")
      },
      disconnectFn: async (disconnectingReason: string) => {
        await dispatch(
          disconnectPractitionerEMRIntegrationAction(
            OPTIMAL_DX_CAPITALIZED,
            disconnectingReason
          )
        )
        await dispatch(fetchPractitionerEmrIntegrations())
        setSelectedEmr(null)
        setIsOpen(false)
        setIsDisconnectOpen(false)
        setDisconnectingEmr(null)
      },
    },
    {
      active:
        emrIntegrations.find((int) => int.emr_integration === "Elation Health")
          ?.active || false,
      name: `${ELATION_HEALTH_TITLE_CASE} Results Connection`,
      logo: ElationHealthLogo,
      description: `Connect to receive all results in the “Labs” Tab within ${ELATION_HEALTH_TITLE_CASE}.`,
      instructionText: `To connect to ${ELATION_HEALTH_TITLE_CASE}, click the “Connect” button below. A representative from ${ELATION_HEALTH_TITLE_CASE}'s team will contact you to confirm the integration within 3 business days.`,
      connectButtonText: `Connect to ${ELATION_HEALTH_TITLE_CASE}`,
      connectFn: async () => {
        await dispatch(
          requestPractitionerEMRIntegrationAction(ELATION_HEALTH_CAPITALIZED)
        )
        setSelectedEmr(null)
        setIsOpen(false)
        window.Intercom("trackEvent", "Elation Integration Request")
      },
      disconnectFn: async (disconnectingReason: string) => {
        await dispatch(
          disconnectPractitionerEMRIntegrationAction(
            ELATION_HEALTH_CAPITALIZED,
            disconnectingReason
          )
        )
        await dispatch(fetchPractitionerEmrIntegrations())
        setIsOpen(false)
        setSelectedEmr(null)
        setIsDisconnectOpen(false)
        setDisconnectingEmr(null)
      },
    },
    {
      active:
        emrIntegrations.find((int) => int.emr_integration === "Practiceq")
          ?.active || false,
      name: PRACTICEQ_TITLE_CASE,
      logo: PracticeQLogo,
      logoClass: classes.practiceQLogo,
      description: `Connect to order and receive all results in your dashboard and the "Lab Orders" tab within ${PRACTICEQ_TITLE_CASE}.`,
      instructionText: `To connect to ${PRACTICEQ_TITLE_CASE}, click the “Connect” button below. You will be asked to sign in to your ${PRACTICEQ_TITLE_CASE} account.`,
      connectButtonText: `Connect to ${PRACTICEQ_TITLE_CASE}`,
      connectFn: async () => {
        setSelectedEmr(null)
        setIsOpen(false)
        window.open(PRACTICEQ_URL, "_blank")
      },
      disconnectFn: async (disconnectingReason: string) => {
        await dispatch(
          disconnectPractitionerEMRIntegrationAction(
            PRACTICEQ_CAPITALIZED,
            disconnectingReason
          )
        )
        await dispatch(fetchPractitionerEmrIntegrations())
        setIsOpen(false)
        setSelectedEmr(null)
        setIsDisconnectOpen(false)
        setDisconnectingEmr(null)
      },
    },
    {
      active:
        emrIntegrations.find((int) => int.emr_integration === "Kalix")
          ?.active || false,
      name: KALIX_TITLE_CASE,
      logo: KalixLogo,
      logoClass: classes.kalixLogo,
      description: `Connect to receive all results in your clients' profiles within ${KALIX_TITLE_CASE}.`,
      instructionText: `To connect to ${KALIX_TITLE_CASE}, click the “Connect” button below. You will be asked to sign in to your ${KALIX_TITLE_CASE} account.`,
      connectButtonText: `Connect to ${KALIX_TITLE_CASE}`,
      connectFn: async () => {
        setSelectedEmr(null)
        setIsOpen(false)
        window.open(KALIX_URL, "_blank")
      },
      disconnectFn: async (disconnectingReason: string) => {
        await dispatch(
          disconnectPractitionerEMRIntegrationAction(
            KALIX_CAPITALIZED,
            disconnectingReason
          )
        )
        await dispatch(fetchPractitionerEmrIntegrations())
        setIsOpen(false)
        setSelectedEmr(null)
        setIsDisconnectOpen(false)
        setDisconnectingEmr(null)
      },
    },
    {
      active:
        emrIntegrations.find((int) => int.emr_integration === "Healthie")
          ?.active || false,
      name: HEALTHIE_TITLE_CASE,
      logo: HealthieLogo,
      logoClass: classes.healthieLogo,
      description: `Connect to place orders and receive all results in your clients' profiles within ${HEALTHIE_TITLE_CASE}.`,
      instructionText: `To connect to ${HEALTHIE_TITLE_CASE}, click the “Connect” button below. You will be asked to sign in to your ${HEALTHIE_TITLE_CASE} account.`,
      connectButtonText: `Connect to ${HEALTHIE_TITLE_CASE}`,
      connectFn: async () => {
        setSelectedEmr(null)
        setIsOpen(false)
        window.open(HEALTHIE_URL, "_blank")
      },
      disconnectFn: async (disconnectingReason: string) => {
        await dispatch(
          disconnectPractitionerEMRIntegrationAction(
            HEALTHIE_CAPITALIZED,
            disconnectingReason
          )
        )
        await dispatch(fetchPractitionerEmrIntegrations())
        setIsOpen(false)
        setSelectedEmr(null)
        setIsDisconnectOpen(false)
        setDisconnectingEmr(null)
      },
    },
    {
      active:
        emrIntegrations.find((int) => int.emr_integration === "Cerbo")
          ?.active || false,
      name: "Cerbo",
      logo: CerboLogo,
      logoClass: classes.cerboLogo,
      description: `Connect to place orders in ${CERBO_TITLE_CASE} and get results in your "Imported Labs" section in ${CERBO_TITLE_CASE}.`,
      instructionText: `Click "Connect" below to navigate to ${CERBO_TITLE_CASE}'s Integration Support Page. From there, follow steps to connect your Rupa account from directly within ${CERBO_TITLE_CASE}.`,
      connectButtonText: `Connect to ${CERBO_TITLE_CASE}`,
      connectFn: async () => {
        window.open(CERBO_URL, "_blank")
        dispatch(requestPractitionerEMRIntegrationAction(CERBO_CAPITALIZED))
        setSelectedEmr(null)
        setIsOpen(false)
      },
      disconnectFn: async (disconnectingReason: string) => {
        await dispatch(
          disconnectPractitionerEMRIntegrationAction(
            CERBO_CAPITALIZED,
            disconnectingReason
          )
        )
        await dispatch(fetchPractitionerEmrIntegrations())
        setSelectedEmr(null)
        setIsOpen(false)
        setIsDisconnectOpen(false)
        setDisconnectingEmr(null)
      },
    },
  ]

  if (isHolisticOfficeEnabled) {
    emrMaps.push({
      active:
        emrIntegrations.find(
          (int) => int.emr_integration === MY_HOLISTIC_OFFICE_TITLE_CASE
        )?.active || false,
      name: MY_HOLISTIC_OFFICE_TITLE_CASE,
      logo: MyHolisticOfficeLogo,
      description: `Connect to order and receive all results in the “Labs” Tab within ${MY_HOLISTIC_OFFICE_TITLE_CASE}.`,
      instructionText: `To connect to ${MY_HOLISTIC_OFFICE_TITLE_CASE}, click the “Connect” button below. You will be asked to sign in to your ${MY_HOLISTIC_OFFICE_TITLE_CASE} account.`,
      connectButtonText: `Connect in ${MY_HOLISTIC_OFFICE_TITLE_CASE}`,
      connectFn: () => {
        setSelectedEmr(null)
        setIsOpen(false)
        window.open(MY_HOLISTIC_OFFICE_URL, "_blank")
      },
      disconnectFn: async (disconnectingReason: string) => {
        await dispatch(
          disconnectPractitionerEMRIntegrationAction(
            MY_HOLISTIC_OFFICE_CAPITALIZED,
            disconnectingReason
          )
        )
        await dispatch(fetchPractitionerEmrIntegrations())
        setSelectedEmr(null)
        setIsOpen(false)
        setIsDisconnectOpen(false)
        setDisconnectingEmr(null)
      },
    })
  }

  return (
    <>
      <Grid container direction="row" spacing={2}>
        <Grid item md={8} xs={12}>
          <BodyText weight="semibold" size="lg">
            EHRs and Integrations
          </BodyText>
          <BodyText className={classes.navyText}>
            To automatically receive results in your Rupa account and EHR,
            enable the EHR Connection. Note that practitioners must initiate
            integrations before staff members can do so. Each member of your
            clinic must integrate their account individually.
          </BodyText>
        </Grid>
        {isMobile ? (
          <Grid item xs={12}>
            <Box display="flex" justifyContent="center">
              <DesignSystemButton
                onClick={() => setIsOpen(!isOpen)}
                color="secondary"
                className={isMobile ? classes.fullWidth : ""}
              >
                Add Integration
              </DesignSystemButton>
            </Box>
          </Grid>
        ) : (
          <Grid item md={4}>
            <Box display="flex" justifyContent="flex-end">
              <DesignSystemButton
                onClick={() => setIsOpen(!isOpen)}
                color="secondary"
              >
                Add Integration
              </DesignSystemButton>
            </Box>
          </Grid>
        )}
      </Grid>

      <Grid
        container
        justify="flex-start"
        alignItems="center"
        direction={isMobile ? "column" : "row"}
      >
        {emrMaps.map((integration) => {
          if (integration.active) {
            return (
              <Grid
                key={`${integration.name}-key`}
                item
                xs={12}
                container
                direction="row"
                spacing={2}
              >
                <Grid item>
                  <StyledFormControlLabel
                    control={
                      <StyledSwitch
                        checked={integration.active}
                        onChange={() => {
                          setDisconnectingEmr(integration)
                          setIsDisconnectOpen(true)
                        }}
                        name={integration.name}
                      />
                    }
                    label={
                      <div>
                        <BodyText weight="semibold" size="sm">
                          {" "}
                          {integration.name}{" "}
                        </BodyText>
                        <BodyText weight="regular" size="sm">
                          {integration.description}
                        </BodyText>
                      </div>
                    }
                  />
                </Grid>
              </Grid>
            )
          } else {
            return <div key={`${integration.name}-key`}></div>
          }
        })}
      </Grid>
      <EMRConnectionsModal
        open={isOpen}
        onClose={() => setIsOpen(false)}
        emrIntegrations={emrMaps}
        selectEmr={setSelectedEmr}
      />
      {selectedEmr && (
        <EMRInstructionsModal
          open={selectedEmr !== null}
          selectedEmr={selectedEmr}
          onClose={() => setSelectedEmr(null)}
        />
      )}
      {isDisconnectOpen && (
        <DisconnectEMRModal
          open={isDisconnectOpen}
          onClose={() => setIsDisconnectOpen(false)}
          disconnectingEmr={disconnectingEmr}
        />
      )}
    </>
  )
}

export default EMRSettings
