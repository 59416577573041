import _ from "lodash"

import {
  INSURANCE_LAB_COMPANIES,
  LAB_COMPANY_KEY,
  MEDICARE_LAB_COMPANIES,
} from "app/constants"
import { getSigningPractitioner } from "app/utils/practitioner-utils"

export default function useInsuranceFields(order) {
  /*
   * Returns the valid insurance options for this order.
   *
   * The logic is as follows:
   * + Practitioner is NOT approved for medicare
   *    - If the order has insurance approved test, the `Insurance` option
   *      will be `Insurance ([companies] tests only)` where [companies]
   *      is the list of lab tests which can be ordered
   *      through non-medicare insurance.
   *    - `Medicare` option should not be present even if medicare approved
   *       test exists in the order
   *
   * + Practitioner is approved for medicare
   *    - If the order has insurance approved test, the `Insurance` option
   *      will be `Insurance ([companies] tests only)` where [companies]
   *      is the list of lab tests which can be ordered
   *      through non-medicare insurance.
   *    - If the order has medicare approved test, the  `Medicare` option
   *      will be `Medicare ([companies] tests only)` where [companies]
   *      is the list of lab tests which can be ordered
   *      through medicare insurance.
   *
   * + If the `Medicare` option is going to show:
   *     - If test from  DSL is present it will only show: `Medicare ([companies] tests only)`
   *     - If no test from DSL is present it will  show: `Medicare  and Tricare ([companies] tests only)`
   *
   *  + For style, if there is only one lab company, we will not show :
   *       `Medicare / Insurance ([companies] tests only)` but just `Medicare/ Insurance`
   */
  if (!order || !order.ordered_tests || !order.ordered_tests.length) {
    return [null, null]
  }
  const orderSigningPractitioner = getSigningPractitioner(order.practitioner)

  const medicareLabCompaniesKeys = _.uniq(
    order.ordered_tests
      .filter(
        (orderedTest) =>
          MEDICARE_LAB_COMPANIES.includes(
            orderedTest.lab_test.lab_company.key
          ) && !!orderedTest.lab_test.medicare_price
      )
      .map((orderedTest) => orderedTest.lab_test.lab_company.key)
  )

  const medicareLabCompaniesNames = _.uniq(
    order.ordered_tests
      .filter(
        (orderedTest) =>
          MEDICARE_LAB_COMPANIES.includes(
            orderedTest.lab_test.lab_company.key
          ) && !!orderedTest.lab_test.medicare_price
      )
      .map((orderedTest) => orderedTest.lab_test.lab_company.name)
  )

  const insuranceLabCompanies = _.uniq(
    order.ordered_tests
      .filter(
        (orderedTest) =>
          INSURANCE_LAB_COMPANIES.includes(
            orderedTest.lab_test.lab_company.key
          ) && !!orderedTest.lab_test.initial_insurance_price
      )
      .map((orderedTest) => orderedTest.lab_test.lab_company.name)
  )

  const allCompanyKeys = _.uniq(
    order.ordered_tests.map(
      (orderedTest) => orderedTest.lab_test.lab_company.key
    )
  )

  const pracIsMedicareApproved =
    orderSigningPractitioner &&
    orderSigningPractitioner.primary_practitioner_type &&
    orderSigningPractitioner.primary_practitioner_type.has_medicare_permission

  let insuranceMenuItem
  let medicareMenuItem

  // Text copy when all tests belongs to a single lab Company
  const publicOptionCopyMedicareCompanies = [
    LAB_COMPANY_KEY.DSL,
    LAB_COMPANY_KEY.INFINITE_ALLERGY,
  ]
  const publicOptionCopyMedicare = publicOptionCopyMedicareCompanies.some(
    (comp) => medicareLabCompaniesKeys.includes(comp)
  )
    ? "Medicare"
    : "Medicare & Tricare"

  const publicOptionCopyInsurance = "Insurance (non Medicare)"

  // Practitioner is not approved for medicare
  if (!pracIsMedicareApproved) {
    if (insuranceLabCompanies.length) {
      insuranceMenuItem = `Insurance (${insuranceLabCompanies.join(
        ", "
      )} tests only)`

      if (allCompanyKeys.length === 1) {
        insuranceMenuItem = publicOptionCopyInsurance
      }
    }
  }
  // Practitioner is approved for medicare
  else {
    //  Insurance Tests Exists
    if (insuranceLabCompanies.length) {
      insuranceMenuItem = `Insurance (${insuranceLabCompanies.join(
        ", "
      )} tests only)`
      if (allCompanyKeys.length === 1) {
        insuranceMenuItem = publicOptionCopyInsurance
      }
    }
    // Medicare Tests exists
    if (medicareLabCompaniesNames.length) {
      medicareMenuItem = `Medicare (${medicareLabCompaniesNames.join(
        ", "
      )} tests only)`
      if (allCompanyKeys.length === 1) {
        medicareMenuItem = publicOptionCopyMedicare
      }
    }
  }

  return [insuranceMenuItem, medicareMenuItem]
}
