import { Theme } from "@material-ui/core"

import EmptyDnDIcon from "app/assets/images/storefront/empty-dnd.svg"
import BodyText from "app/components/design-system/BodyText"
import {
  StorefrontLabTestBundleType,
  StorefrontLabTestType,
  StorefrontSectionType,
} from "app/hooks/use-resource"
import { colors } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"

import SortableProduct from "./dnd-components/SortableProduct"

interface Props {
  section?: StorefrontSectionType
  openProductCustomizeModal: (productId: string) => void
  removeLabTest: (labTest: StorefrontLabTestType | undefined) => void
  removeBundle: (bundle: StorefrontLabTestBundleType | undefined) => void
  hidePhysServicesPricing: boolean
}

const styles = (theme: Theme) => ({
  container: {
    width: "100%",
    display: "flex",
    "flex-direction": "column",
    gap: 18,
    paddingTop: 35,
  },
  emptyContainer: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    "flex-direction": "column",
    gap: 9,
    paddingTop: 4,
  },
  productsContainer: {
    width: "100%",
    display: "flex",
    "flex-direction": "row",
    "flex-wrap": "wrap",
    gap: 16,
  },
})

const useStyles = makeAppStyles(styles)

const SectionProducts = ({
  section,
  openProductCustomizeModal,
  removeLabTest,
  removeBundle,
  hidePhysServicesPricing,
}: Props) => {
  const classes = useStyles()

  return (
    <div className={classes.productsContainer}>
      {section?.relationships.storefront_products.data.map((product, index) => (
        <SortableProduct
          key={product?.id}
          id={product?.id}
          openProductCustomizeModal={openProductCustomizeModal}
          removeLabTest={removeLabTest}
          removeBundle={removeBundle}
          hidePhysServicesPricing={hidePhysServicesPricing}
        />
      ))}
      {!Boolean(section?.relationships.storefront_products.data.length) && (
        <div
          className={classes.emptyContainer}
          style={{ marginTop: section?.attributes.is_transparent ? 30 : 0 }}
        >
          <img src={EmptyDnDIcon} alt="empty dnd zone" />
          <BodyText size="xs" style={{ color: colors.blueGray[500] }}>
            Drag lab tests into this section.
          </BodyText>
        </div>
      )}
    </div>
  )
}

export default SectionProducts
