import axios from "axios"
import _, { isEqual } from "lodash"
import { v4 as uuid } from "uuid"

import { API } from "app/api"
import { isInBostonHeartPanel } from "app/dataServices/labTestDataService"
import { canUseInsurance } from "app/dataServices/orderDataService"
import * as Actions from "app/main/checkout/store/actions/index"
import { normalizeOrderPatch } from "app/store/actions/orders.actions"
import { getApiBaseUrl, handleApiError, handleApiSuccess } from "app/utils"
import { getOrderTypeLabel } from "app/utils/order-utils"

export const GET_ORDER = "[ORDER] GET ORDER"
export const GET_PATIENT = "[ORDER] GET PATIENT"
export const UPDATE_PATIENT = "[ORDER] UPDATE PATIENT"
export const GET_PRACTITIONERS = "[ORDER] GET PRACTITIONERS"
export const UPDATE_ORDER = "[ORDER] UPDATE ORDER"
export const DELETE_ORDER = "[ORDER] DELETE ORDER"
export const ADD_TEST = "[ORDER] ADD TEST"
export const MARK_TEST_TO_ADD_TO_NEXT_ORDER =
  "[ORDER] MARK TEST TO ADD TO NEXT ORDER"
export const UNMARK_TEST_TO_ADD_TO_NEXT_ORDER =
  "[ORDER] UNMARK TEST TO ADD TO NEXT ORDER"
export const UPDATE_TEST = "[ORDER] UPDATE TEST"
export const REMOVE_TEST = "[ORDER] REMOVE TEST"
export const UPDATE_PANEL = "[ORDER] UPDATE PANEL"
export const REMOVE_PANEL = "[ORDER] REMOVE PANEL"
export const ADD_COUPON = "[ORDER] ADD COUPON"
export const REMOVE_COUPON = "[ORDER] REMOVE COUPON"

export const CLEAR_ORDER = "[ORDER] CLEAR ORDER"

export const UPDATE_CUSTOM_FEE_LINE_ITEM = "[ORDER] UPDATE CUSTOM FEE LINE ITEM"

export function getPatient(id) {
  const request = axios.get(getApiBaseUrl() + `/api/patient/${id}/`)

  return (dispatch) =>
    request
      .then((response) =>
        dispatch({
          type: GET_PATIENT,
          payload: response.data,
        })
      )
      .catch((error) => dispatch(handleApiError(error)))
}

export function updatePatient(id, patient) {
  const request = API.Patient.patch(id, patient)

  return (dispatch) =>
    request.then((response) =>
      dispatch({
        type: UPDATE_PATIENT,
        payload: response.data,
      })
    )
}

export function getPractitioners(clinicId) {
  const request = API.Clinic.getPractitioners(clinicId)

  return (dispatch) =>
    request
      .then((response) =>
        dispatch({
          type: GET_PRACTITIONERS,
          payload: response.data,
        })
      )
      .catch((error) => dispatch(handleApiError(error)))
}

type OptionsType = {
  exclude?: string[] | string
  include?: string[] | string
}

export function getOrder(id: string | undefined, options: OptionsType = {}) {
  return (dispatch, getState) => {
    let { exclude = [], include = [] } = options

    exclude = Array.isArray(exclude) ? exclude.join(",") : exclude
    include = Array.isArray(include) ? include.join(",") : include

    const params = new URLSearchParams()

    if (exclude) {
      params.set("exclude", exclude)
    }

    if (include) {
      params.set("include", include)
    }

    const querystring = params.toString()

    let url = getApiBaseUrl() + `/api/order/${id}/`
    if (querystring) {
      url = `${url}?${querystring}`
    }

    const order = getState().orders?.orders?.order
    const request = axios.get(url)

    return request
      .then(async (response) => {
        await dispatch({
          type: GET_ORDER,
          payload: {
            ...order,
            ...response.data,
          },
        })
      })
      .catch((error) => dispatch(handleApiError(error)))
  }
}

export function addTest(test, additionalData: any = {}) {
  return async (dispatch, getState) => {
    const order = getState().orders.orders.order

    // If the user has already added some boston heart tests and turned on instant requisitions then newly added
    // boston heart tests should default to having instant requisitions enabled.
    // Therefore we check for this situation and set instant_requisition as appropriate in the creation call.
    const bostonHeartPanelTests = order.ordered_tests
      ? order.ordered_tests.filter((orderedTest) =>
          isInBostonHeartPanel(orderedTest.lab_test)
        )
      : []
    const shouldOverrideInstantReqForBostonHeart =
      isInBostonHeartPanel(test) &&
      _.some(
        bostonHeartPanelTests,
        (orderedTest) => orderedTest.instant_requisition
      )
    if (shouldOverrideInstantReqForBostonHeart) {
      additionalData.instant_requisition = true
    }

    const optimisticId = uuid()

    let response

    if (test.combo_group) {
      try {
        const comboGroupAddBody = {
          order: order.id,
          date_ordered_by_practitioner: new Date(),
          combo_group: test.combo_group,
          ...additionalData,
        }
        response = await API.OrderedComboGroupTest.post(comboGroupAddBody)
      } catch (e) {
        // Remove the optimistic test if it fails to create
        dispatch({
          type: REMOVE_TEST,
          payload: {
            id: optimisticId,
          },
        })
        dispatch(handleApiError(e))
        return
      }

      dispatch({
        type: ADD_TEST,
        payload: {
          ...response.data,
          optimisticIdToRemove: optimisticId,
        },
      })
    } else {
      dispatch({
        type: ADD_TEST,
        payload: {
          id: optimisticId,
          lab_test: test,
          optimistic: true,
        },
      })
      try {
        const testAddBody = {
          order: order.id,
          lab_test: test.id,
          date_ordered_by_practitioner: new Date(),
          ...additionalData,
        }
        response = await axios.post(
          getApiBaseUrl() + `/api/orderedtest/`,
          testAddBody
        )
      } catch (e) {
        // Remove the optimistic test if it fails to create
        dispatch({
          type: REMOVE_TEST,
          payload: {
            id: optimisticId,
          },
        })
        dispatch(handleApiError(e))
        return
      }

      // Add the real ordered test that came back
      dispatch({
        type: ADD_TEST,
        payload: {
          ...response.data,
          lab_test: test,
          optimisticIdToRemove: optimisticId,
        },
      })
    }

    try {
      dispatch(refreshPricing(order.id))
    } catch (error) {
      dispatch(handleApiError(error))
    }
  }
}

export function markTestToAddToNextOrder(
  lab_test,
  // additionalData can include any additional params to be used when creating the ordered test
  additionalData = {
    instant_requisition: false,
  }
) {
  return (dispatch) => {
    dispatch({
      type: MARK_TEST_TO_ADD_TO_NEXT_ORDER,
      payload: { lab_test, additionalData },
    })
  }
}

export function unmarkTestToAddToNextOrder(test) {
  return (dispatch) => {
    dispatch({
      type: UNMARK_TEST_TO_ADD_TO_NEXT_ORDER,
      payload: {
        lab_test: test,
      },
    })
  }
}

export function updateTest(orderedTest) {
  const request = API.OrderedTest.put(orderedTest.id, {
    ...orderedTest,
    order: orderedTest.order,
    lab_test: orderedTest.lab_test.id,
  })

  return (dispatch) =>
    request
      .then(async (response) => {
        await dispatch({
          type: UPDATE_TEST,
          payload: orderedTest,
        })

        dispatch(refreshPricing(orderedTest.order))
      })
      .catch((error) => dispatch(handleApiError(error)))
}

export function updateComboGroupTest(orderedTestId, payload) {
  const request = API.OrderedComboGroupTest.patch(orderedTestId, payload)

  return (dispatch) =>
    request
      .then(async (response) => {
        await dispatch({
          type: UPDATE_TEST,
          payload: response.data,
        })
        await dispatch(refreshPricing(response.data.order))
      })
      .catch((error) => dispatch(handleApiError(error)))
}

export function removeTest(ordered_test) {
  const request = axios.delete(
    getApiBaseUrl() + `/api/orderedtest/${ordered_test.id}/`
  )

  return (dispatch, getState) =>
    request
      .then(async (response) => {
        await dispatch({
          type: REMOVE_TEST,
          payload: ordered_test,
        })

        let order = getState().orders.orders.order

        // If the order has bundles and the removed test is part of the bundle, Also remove the bundle
        if (order.lab_test_bundles.length) {
          let bundleIdsToBeRemoved: any[] = []
          order.lab_test_bundles.forEach(function (bundle) {
            bundle.lab_tests.forEach(function (labTestId) {
              if (labTestId === ordered_test.lab_test.id) {
                bundleIdsToBeRemoved.push(bundle.id)
              }
            })
          })

          order["lab_test_bundles"] = [...order.lab_test_bundles].filter(
            (bundle) => !bundleIdsToBeRemoved.includes(bundle.id)
          )

          await dispatch({
            type: UPDATE_ORDER,
            payload: order,
          })
        }

        // If the user removes all the Genova tests they had in the cart, then make sure to disable the
        // use of insurance.
        if (order.use_insurance && !canUseInsurance(order)) {
          dispatch(setUseInsurance(false, false))
        }

        dispatch(refreshPricing(order.id))
      })
      .catch((error) => dispatch(handleApiError(error)))
}

export function addBundle(order, bundle) {
  const request = API.Order.addBundle(order.id, {
    order: order.id,
    bundle: bundle.id,
  })

  return (dispatch) =>
    request
      .then(async (response) => {
        await dispatch({
          type: UPDATE_ORDER,
          payload: response.data,
        })
      })
      .catch((error) => dispatch(handleApiError(error)))
}

export function removeBundle(order, bundle) {
  const request = API.Order.removeBundle(order.id, {
    order: order.id,
    bundle: bundle.id,
  })

  return (dispatch) =>
    request
      .then(async (response) => {
        await dispatch({
          type: UPDATE_ORDER,
          payload: response.data,
        })
      })
      .catch((error) => dispatch(handleApiError(error)))
}

export function updatePanel(orderId, orderedTests, instantRequisition) {
  const orderedTestIds = orderedTests.map((orderedTest) => orderedTest.id)
  const request = API.OrderPanel.update(orderId, {
    instant_requisition: {
      enabled: instantRequisition,
      ordered_test_ids: orderedTestIds,
    },
  })

  const updatedOrderedTests = orderedTests.map((orderedTest) => ({
    ...orderedTest,
    instant_requisition: instantRequisition,
  }))

  return (dispatch) =>
    request
      .then((response) => {
        dispatch({
          type: UPDATE_PANEL,
          payload: updatedOrderedTests,
        })
        dispatch(refreshPricing(orderId))
      })
      .catch((error) => dispatch(handleApiError(error)))
}

export function toggleFastingOnPanel(orderId, orderedTests, requiresFasting) {
  const orderedTestIds = orderedTests.map((orderedTest) => orderedTest.id)
  const request = API.OrderPanel.update(orderId, {
    requires_fasting: {
      enabled: requiresFasting,
      ordered_test_ids: orderedTestIds,
    },
  })

  const updatedOrderedTests = orderedTests.map((orderedTest) => ({
    ...orderedTest,
    requires_fasting: requiresFasting,
  }))

  return (dispatch) =>
    request
      .then(() => {
        dispatch({
          type: UPDATE_PANEL,
          payload: updatedOrderedTests,
        })
        dispatch(refreshPricing(orderId))
      })
      .catch((error) => dispatch(handleApiError(error)))
}

export function removePanel(orderId, orderedTests) {
  const orderedTestIds = orderedTests.map((orderedTest) => orderedTest.id)
  const request = API.OrderPanel.delete(orderId, orderedTestIds)

  return (dispatch, getState) =>
    request
      .then((response) => {
        dispatch({
          type: REMOVE_PANEL,
          payload: orderedTests,
        })

        // If the order has bundles and the removed test is part of the bundle, Also remove the bundle
        let order = getState().orders.orders.order

        if (order.lab_test_bundles.length) {
          const removedLabTestIds = orderedTests.map(
            (orderedTest) => orderedTest.lab_test.id
          )

          let bundleIdsToBeRemoved: any = []
          order.lab_test_bundles.forEach(function (bundle) {
            bundle.lab_tests.forEach(function (labTestId) {
              if (removedLabTestIds.includes(labTestId)) {
                bundleIdsToBeRemoved.push(bundle.id)
              }
            })
          })

          order["lab_test_bundles"] = [...order.lab_test_bundles].filter(
            (bundle) => !bundleIdsToBeRemoved.includes(bundle.id)
          )

          dispatch({
            type: UPDATE_ORDER,
            payload: order,
          })
        }

        dispatch(refreshPricing(orderId))
      })
      .catch((error) => dispatch(handleApiError(error)))
}

export function toggleInsurance(orderId, direction, companyKey) {
  const request = API.Order.toggleInsurance(orderId, direction, companyKey)

  return (dispatch) =>
    request
      .then(async (response) => {
        await dispatch({
          type: UPDATE_ORDER,
          payload: response.data,
        })
        dispatch(refreshPricing(orderId))
      })
      .catch((error) => {
        dispatch(handleApiError(error))
      })
}

export function createOrderForPatient(
  patientId,
  notificationMethod,
  shippingState,
  // Query parameters to include in the POST request
  params = {}
) {
  const request = axios.post(
    getApiBaseUrl() + "/api/order/",
    {
      patient: patientId,
      notification_method: notificationMethod,
      shipping_state: shippingState ? shippingState : null,
    },
    { params }
  )

  return (dispatch) =>
    request
      .then((response) =>
        dispatch({
          type: GET_ORDER,
          payload: response.data,
        })
      )
      .catch((error) => dispatch(handleApiError(error)))
}

function getExclude(patch, order, options) {
  let {
    exclude = [
      // These fields never change as a result of a patch
      "ordered_tests",
      "coupons",
      "custom_fee_line_items",
      "order_intent",
      "lab_test_bundles",
      "clinic",
      "patient",
      "created_by",
    ],
  } = options

  if (exclude === "*") {
    return ["*"]
  }

  const changedFields = new Set()
  // Normalize the order (replace relations with their IDs), so we can
  // easily compare.
  const normalizedOrder = normalizeOrderPatch(order)

  for (const [field, newValue] of Object.entries(patch)) {
    const existingValue = normalizedOrder[field]

    if (!isEqual(existingValue, newValue)) {
      changedFields.add(field)
    }
  }

  const unchangedFields = Object.keys(patch).filter(
    (field) => !changedFields.has(field)
  )

  exclude = exclude.concat(unchangedFields)

  if (
    !(
      changedFields.has("practitioner") ||
      changedFields.has("signing_practitioner")
    )
  ) {
    exclude = exclude.concat(["practitioner", "signing_practitioner"])
  }

  if (
    !(
      (
        changedFields.has("use_insurance") ||
        changedFields.has("signing_practitioner")
      ) // If the signing practitioner changes, we need to update the line items because the ordering rights may change.
    )
  ) {
    exclude.push("line_items")
  }

  return exclude
}

/**
 * Patch the state for the current order by merging the patch with the order state and sending to the API
 *
 * @param {Partial<Order>} patch a partial of order that will be merged with the primary order for an update
 * @param {options} options for the patch. Currenly supports an` exclude` param to exclude fields from the response.
 * The wildcard `*` can be passed to exclude all fields. When a wildcard exclude is used, an include can be used too.
 */
export function patchOrder(patch, options: any = {}) {
  return async (dispatch, getState) => {
    const order = getState().orders.orders.order
    const normalizedPatch = normalizeOrderPatch(patch)
    const exclude = getExclude(normalizedPatch, order, options).join(",")
    const include = options.include

    const params = new URLSearchParams()

    if (exclude) {
      params.set("exclude", exclude)
    }

    if (include) {
      params.set("include", include)
    }

    const querystring = params.toString()

    let url = getApiBaseUrl() + `/api/order/${order.id}/`

    if (querystring) {
      url = `${url}?${querystring}`
    }

    const response = await axios.patch(url, normalizedPatch)

    return dispatch({
      type: UPDATE_ORDER,
      payload: { ...order, ...response.data },
    })
  }
}

export function refreshPricing(orderId) {
  return (dispatch) => {
    // Pricing only updates pricing related fields
    const include = [
      "line_items",
      "msrp_total",
      "processing_price",
      "coupon_discount",
      "rupa_discount",
      "subtotal",
      "total_price",
      "updated_at",
      "flags",
      "custom_fee_line_items",
      "physician_authorization_total",
      "associated_uninsured_tests_by_ordered_test_id",
    ]
    return dispatch(getOrder(orderId, { include }))
  }
}

export function deleteOrder(order) {
  return (dispatch, getState) => {
    const request = axios.delete(getApiBaseUrl() + `/api/order/${order.id}/`, {
      ...order,
    })

    return request
      .then((response) => {
        dispatch(
          handleApiSuccess(
            `${getOrderTypeLabel(
              order.requires_vendor_physician_authorization
            )} deleted`
          )
        )
        dispatch({
          type: DELETE_ORDER,
          payload: order,
        })
      })
      .catch((error) => dispatch(handleApiError(error)))
  }
}

export function addCoupon(coupon) {
  return async (dispatch, getState) => {
    const order = getState().orders.orders.order
    const orderedTests = order.ordered_tests

    if (
      coupon.lab_test &&
      !_.some(
        orderedTests,
        (orderedTest) => coupon.lab_test.id === orderedTest.lab_test.id
      )
    ) {
      await dispatch(addTest(coupon.lab_test))
    }

    const request = API.OrderCoupon.post(order.id, coupon.id)
    return request
      .then(async (response) => {
        await dispatch({
          type: ADD_COUPON,
          payload: response.data,
        })

        dispatch(refreshPricing(order.id))
      })
      .catch((error) => dispatch(handleApiError(error)))
  }
}

export function removeCoupon(coupon) {
  return (dispatch, getState) => {
    const order = getState().orders.orders.order
    return API.OrderCoupon.delete(order.id, coupon.id)
      .then(async (response) => {
        await dispatch({
          type: REMOVE_COUPON,
          payload: response.data,
        })

        dispatch(refreshPricing(order.id))
      })
      .catch((error) => dispatch(handleApiError(error)))
  }
}

export function clearOrder() {
  return (dispatch) =>
    dispatch({
      type: CLEAR_ORDER,
    })
}

export function setUseInsurance(useInsurance, patientHasMedicare) {
  return async (dispatch, getState) => {
    let updates: any = {
      use_insurance: useInsurance,
      patient_has_medicare: patientHasMedicare,
    }

    if (useInsurance) {
      // Practitioners cannot do practitioner pay for insurance orders. Therefore we must clear these fields if
      // insurance is set to be true. The checkbox for enabling/disabling practitioner pay on the order sidebar
      // will be hidden.
      updates.bank_account = null
      updates.payment_card = null
    }

    const order = getState().orders.orders.order
    await dispatch(Actions.patchOrder(updates))
    return dispatch(Actions.refreshPricing(order.id))
  }
}

/**
 * Updates the order's notification method.
 *
 * This has been broken out into a separate dispatch function so that toggling between
 * notification methods does not lag from API calls. Further, because the component
 * that dispatches this function leverages debounce, we ensure here that we are grabbing
 * the latest order state, rather than passing it from the component (which could have
 * a stale order).
 *
 * @param {string} notificationMethod  - Notification method (email or email+text)
 */
export function updateOrderNotificationMethod(notificationMethod) {
  return (dispatch) => {
    dispatch(
      patchOrder({
        notification_method: notificationMethod,
      })
    )
  }
}

export function refreshCustomFeeLineItems(
  orderId,
  customFeeLineItemId,
  customFeeData
) {
  const request = API.CustomFeeLineItem.put(
    orderId,
    customFeeLineItemId,
    customFeeData
  )

  return (dispatch) =>
    request
      .then(async (response) => {
        await dispatch({
          type: UPDATE_CUSTOM_FEE_LINE_ITEM,
          payload: response.data,
        })
        dispatch(refreshPricing(orderId))
      })
      .catch((error) => dispatch(handleApiError(error)))
}
