import { makeStyles, Theme } from "@material-ui/core"

import DropShippingTeal from "app/assets/icons/labshops/dropshipping.svg"
import FileTeal from "app/assets/icons/labshops/file.svg"
import HouseTeal from "app/assets/icons/labshops/house.svg"
import ShippingTeal from "app/assets/icons/labshops/shipping-truck.svg"
import ShoppingCartTeal from "app/assets/icons/labshops/shopping-cart.svg"
import BodyText from "app/components/design-system/BodyText"
import { colors, navy } from "app/theme"

const styles = (theme: Theme) => ({
  container: {
    display: "flex",
    justifyContent: "center",
    "flex-direction": "column",
    alignItems: "center",
    padding: "0px 0",
  },
  howItWorksProcessContainer: {
    width: "100%",
    display: "flex",
    "flex-direction": "row",
    marginTop: 25,
    gap: 18,
  },
  hotItWorksProcessObject: {
    width: "20%",
  },
  processText: {
    paddingRight: 8,
    paddingTop: 9,
  },
  processIcon: {
    width: 60,
  },
  processIconDivider: {
    height: 1,
    background: colors.blueGray[200],
    marginLeft: 2,
    marginRight: 2,
    width: "100%",
  },
})

const useStyles = makeStyles(styles)

const HowItWorksCard = () => {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <BodyText
        style={{
          color: navy,
          fontSize: 17,
          fontFamily: "Open Sans",
          fontWeight: 600,
        }}
      >
        How it Works
      </BodyText>

      <div className={classes.howItWorksProcessContainer}>
        <div className={classes.hotItWorksProcessObject}>
          <div className={classes.processIcon}>
            <img src={ShoppingCartTeal} alt="shopping cart" />
          </div>
          <div className={classes.processText}>
            <BodyText weight="semibold">Order your kit on Rupa</BodyText>
          </div>
        </div>
        <div className={classes.hotItWorksProcessObject}>
          <div className={classes.processIcon}>
            <img src={ShippingTeal} alt="shipping" />
          </div>
          <div className={classes.processText}>
            <BodyText weight="semibold">
              Your kit is shipped on the following business day
            </BodyText>
          </div>
        </div>
        <div className={classes.hotItWorksProcessObject}>
          <div className={classes.processIcon}>
            <img src={HouseTeal} alt="house" />
          </div>
          <div className={classes.processText}>
            <BodyText weight="semibold">
              Receive your test kit and follow our personalized instructions
              email
            </BodyText>
          </div>
        </div>
        <div className={classes.hotItWorksProcessObject}>
          <div className={classes.processIcon}>
            <img src={DropShippingTeal} alt="package" />
          </div>
          <div className={classes.processText}>
            <BodyText weight="semibold">
              Complete your test at home, and mail back to the lab using a
              pre-paid shipping label
            </BodyText>
          </div>
        </div>
        <div className={classes.hotItWorksProcessObject}>
          <div className={classes.processIcon}>
            <img src={FileTeal} alt="file" />
          </div>
          <div className={classes.processText}>
            <BodyText weight="semibold">
              You'll be emailed a secure link to access your results
            </BodyText>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HowItWorksCard
