import { Resource, ResourceRelationshipSingle } from "app/swr/types"

export interface Patient extends Resource {
  type: "patient"
  attributes: {
    given_consent: boolean
    has_order_info: boolean
    first_name: string
    last_name: string
    full_name: string
    phone_number?: string | null
    birthday?: string | null
    biological_sex?: string | null
    patient_settings: PatientSettings[]
  }
  relationships: {
    user: ResourceRelationshipSingle
    practitioner: ResourceRelationshipSingle
    insurance: ResourceRelationshipSingle
    default_shipping_address: ResourceRelationshipSingle
  }
}

export enum PatientSettings {
  INCLUDE_HIGH_LOW_DESCRIPTIONS_ON_BLOOD_REPORTS = "include_high_low_descriptions_on_blood_reports",
}
