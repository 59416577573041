import { makeStyles } from "@material-ui/core"

import { colors, white } from "app/theme"

export default makeStyles({
  root: {
    backgroundColor: white,
  },
  input: {
    fontSize: 16,
    padding: "10px 12px",
  },
  notchedOutline: {
    borderColor: colors.blueGray[300],
    borderRadius: 6,
  },
})
