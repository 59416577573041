import * as React from "react"
import { useRef } from "react"

import clsx from "clsx"

import * as Popover from "@radix-ui/react-popover"

import { shadows } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"

const useAutoSuggestContentStyles = makeAppStyles<{ width: number | string }>(
  (theme) => ({
    content: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      width: ({ width }) => width,
      maxWidth: ({ width }) => width,
      padding: theme.spacing(1.25),
      zIndex: theme.zIndex.modal,
      backgroundColor: "white",
      border: "0.82px solid #dbdfe6",
      borderRadius: 6,
      boxShadow: shadows.xl,
    },
  })
)

export interface AutoSuggestContentProps {
  children: React.ReactNode
  width: number | string
}

export default function AutoSuggestContent({
  children,
  width,
}: AutoSuggestContentProps) {
  const classes = useAutoSuggestContentStyles({ width })
  const ref = useRef<any>(null)
  return (
    <Popover.Portal>
      <Popover.Content
        avoidCollisions={false}
        className={clsx(classes.content, "fs-unmask")}
        onOpenAutoFocus={(e) => e.preventDefault()}
        onCloseAutoFocus={(e) => e.preventDefault()}
        // The following two handlers ensure the click outside handler does not cause
        // this popover to close when rendered within a portal or modal.
        onPointerDownCapture={(e) => {
          const target = e.target as HTMLElement
          const targetIsContent = ref.current?.contains(target)
          if (targetIsContent) {
            e.stopPropagation()
          }
        }}
        onPointerDownOutside={(e) => {
          const target = e.target as HTMLElement
          const targetIsContent = ref.current?.contains(target)
          if (targetIsContent) {
            e.stopPropagation()
          }
        }}
        sideOffset={5}
        ref={ref}
      >
        {children}
      </Popover.Content>
    </Popover.Portal>
  )
}
