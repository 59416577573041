import * as React from "react"

import { DialogContent } from "@material-ui/core"

import { SCROLL_CONTAINER_PADDING } from "app/main/comparison/constants"
import { colors } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"

const useStyles = makeAppStyles((theme) => ({
  content: {
    height: "100%",
    padding: 0,
    paddingTop: SCROLL_CONTAINER_PADDING,
    backgroundColor: colors.blueGray[150],
    [theme.breakpoints.down("sm")]: {
      paddingTop: 0,
    },
  },
}))

export default function ComparisonModalContent({
  children,
}: {
  children: React.ReactNode
}) {
  const classes = useStyles()
  return <DialogContent className={classes.content}>{children}</DialogContent>
}
