import * as React from "react"

import { useController, useFormContext } from "react-hook-form"

import { FormHelperText, Link } from "@material-ui/core"

import DateField, { DateFieldProps } from "./DateField"

export type ControlledDateFieldProps = Omit<
  DateFieldProps,
  "defaultValue" | "error" | "helperText" | "onBlur" | "onChange" | "value"
> & {
  // include "any" as the "Date" type causes issue with "error.message"
  defaultValue?: any
  name: string
  required?: boolean
  shouldUnregister?: boolean
}

const MIN_AGE_ERROR_REGEX =
  /^(Patient|Client) must be [0-9]{1,3} or older to receive some of the tests in this order.$/
const MAX_AGE_ERROR_REGEX =
  /^Some of the testing in your order is not enabled for those [0-9]{1,3} and older.$/
const CONTACT_US_MAILTO_LINK =
  "mailto:support@rupalabs.com?subject=The%20age%20requirement%20isn%E2%80%99t%20met%20for%20my%20order"

const ControlledDateField = ({
  defaultValue,
  name,
  required,
  shouldUnregister,
  ...dateFieldProps
}: ControlledDateFieldProps) => {
  const { control } = useFormContext()
  const {
    field: { ref, ...controlProps },
    fieldState: { error },
  } = useController({
    control,
    defaultValue,
    name: `${name}` as const,
    shouldUnregister,
  })

  let errorMessage: React.ReactNode = error?.message
  if (
    error?.message &&
    (MIN_AGE_ERROR_REGEX.test(error?.message) ||
      MAX_AGE_ERROR_REGEX.test(error?.message))
  ) {
    const mailtoLink = (
      <Link href={CONTACT_US_MAILTO_LINK}>Contact us for support.</Link>
    )
    const updatedErrorMessage = (
      <FormHelperText component={"span"}>
        {error.message} {mailtoLink}
      </FormHelperText>
    )

    errorMessage = updatedErrorMessage
  }

  return (
    <DateField
      error={Boolean(error)}
      helperText={errorMessage}
      inputRef={ref}
      required={required}
      {...dateFieldProps}
      {...controlProps}
    />
  )
}

export default ControlledDateField
