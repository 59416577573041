import { MarkAsComplete } from "../MarkAsCompleteButton"
import taskStyles from "../TaskStyles"
import LicenseAndPhysicianServicesTask from "./LicenseAndPhysicianServicesTask"
import { OnboardingTaskItemProps } from "./OnboardingTaskItem"

const LicenseInvited: React.FC<OnboardingTaskItemProps> = (props) => {
  const taskClasses = taskStyles()
  return (
    <LicenseAndPhysicianServicesTask
      {...props}
      subCardContent={
        <div className="flex flex-col items-center mb-4 bg-blue-50">
          <div
            className={taskClasses.taskTextContainer}
            style={{ fontWeight: 400, maxWidth: 700 }}
          >
            If you instead only want to order on behalf of another practitioner
            in your clinic you can mark this step as completed!
          </div>
          <MarkAsComplete
            onClick={() => props.markTaskAsComplete(props.task.key)}
            style={{ marginTop: 10 }}
          />
        </div>
      }
    />
  )
}

export default LicenseInvited
