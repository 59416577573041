import {
  ComparisonColumnProps,
  ComparisonRow,
} from "app/main/comparison/components/ComparisonLabTests/components"
import VibrantLeavingWarning from "app/main/warnings/VibrantLeavingWarning"
import { labTestIsVibrant } from "app/main/warnings/utils"
import makeAppStyles from "app/utils/makeAppStyles"

const useStyles = makeAppStyles((theme) => ({
  container: {
    marginBottom: -11, // hack to remove extra padding below warning resulting from the use of `gap` between rows
  },
}))

const WarningColumn = ({ labTest }: ComparisonColumnProps) => {
  return <>{labTestIsVibrant(labTest) && <VibrantLeavingWarning />}</>
}

export default function ComparisonWarningRow() {
  const classes = useStyles()
  return (
    <div className={classes.container}>
      <ComparisonRow Column={WarningColumn} />
    </div>
  )
}
