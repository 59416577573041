import { sortBy } from "lodash"

import { RootState } from "app/types"

import * as Actions from "../actions/healthCategory.actions"

const initialState: RootState["healthCategory"] = {
  sortedByName: null,
  sortedByPositionInCatalog: null,
}

function healthCategoryReducer(
  state = initialState,
  action: Actions.ActionTypes
): typeof initialState {
  switch (action.type) {
    case Actions.GET_HEALTH_CATEGORY_LIST_SUCCESS: {
      return {
        ...state,
        sortedByName: action.payload,
        sortedByPositionInCatalog: sortBy(
          action.payload,
          (hc) => hc.position_in_catalog
        ),
      }
    }
    default: {
      return state
    }
  }
}

export default healthCategoryReducer
