import { useEffect } from "react"
import { useDispatch } from "react-redux"

import { Redirect, useParams } from "react-router-dom"

import CircularProgress from "@material-ui/core/CircularProgress"
import Typography from "@material-ui/core/Typography"

import { ORDER_NOTIFICATION_METHOD } from "app/constants"
import useAppSelector from "app/hooks/useAppSelector"
import { trackStartNewOrder } from "app/services/segment"
import withReducer from "app/store/withReducer"
import { getCreatingNewText } from "app/utils"

import * as Actions from "./store/actions"
import reducer from "./store/reducers"

function CreateOrder() {
  const { patientId, shippingState } = useParams<{
    patientId: string
    shippingState: string
  }>()
  const dispatch = useDispatch()
  const {
    orders: { order, patient, marked_for_next_order },
    practitioner,
  } = useAppSelector(({ orders: { orders }, practitioner }) => ({
    orders,
    practitioner,
  }))

  const creatingNewText = getCreatingNewText(practitioner)

  useEffect(() => {
    dispatch(Actions.getPatient(patientId))
  }, [patientId, dispatch])

  useEffect(() => {
    if (patient.id && practitioner.id) {
      let params: { [key: string]: any } = {}
      if (marked_for_next_order?.additionalData?.instant_requisition) {
        params.ignore_phys_auth_default = true
      }

      dispatch(
        Actions.createOrderForPatient(
          patient.id,
          ORDER_NOTIFICATION_METHOD.EMAIL,
          shippingState,
          params
        )
      )
    }
  }, [patient.id, practitioner.id, dispatch])

  useEffect(() => {
    if (order.id && practitioner.id) {
      trackStartNewOrder(
        practitioner.id,
        practitioner.clinic?.id,
        order.id,
        order.is_practitioner_paying
      )
    }
  }, [order, practitioner])

  if (!order) {
    return <CircularProgress aria-label="Creating new order" />
  }

  return !order.id ? (
    <div className="flex flex-col justify-center items-center h-64">
      <CircularProgress aria-label="Creating new order" />
      <Typography className="mt-6 font-300 text-xl text-gray-400">
        {creatingNewText}
      </Typography>
    </div>
  ) : (
    <Redirect to={`/checkout/${order.id}${""}`} />
  )
}

export default withReducer("orders", reducer)(CreateOrder)
