import * as DropdownMenu from "@radix-ui/react-dropdown-menu"

import { ReactComponent as ArrowDropDownIcon } from "app/assets/icons/rupa-blood-dashboards/arrow-down.svg"
import { ReactComponent as BloodLabDashboardIcon } from "app/assets/icons/rupa-blood-dashboards/blood-dashboards-patient-portal.svg"
import { ReactComponent as OriginalLabReportIcon } from "app/assets/icons/rupa-blood-dashboards/original-lab-report.svg"
import BodyText from "app/components/design-system/BodyText"
import Button from "app/components/design-system/Button"
import {
  bloodLabDashboardEvents,
  patientDownloadOriginalReportSource,
} from "app/patient-portal/blood-lab-dashboard/constants"
import {
  PatientPortalKit,
  PatientPortalOrder,
  PatientPortalOrderedTest,
  PatientPortalPatient,
} from "app/patient-portal/types"
import { trackEventWithProperties } from "app/services/segment.typed"
import useCachedResource from "app/swr/hooks/use-cached-resource"
import { colors, primaryColor, shadows } from "app/theme"
import { downloadFileByUrl } from "app/utils"
import makeAppStyles from "app/utils/makeAppStyles"
import { OrderedResult } from "types/ordered-result"

const useStyles = makeAppStyles((theme) => ({
  content: {
    width: 250,
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(1, 1),
    background: "white",
    borderRadius: 6,
    border: `1px solid ${primaryColor}`,
    boxShadow: shadows.lg,
    marginTop: 5,
    overflowY: "auto",
    zIndex: 1300,
  },
  item: {
    display: "flex",
    alignItems: "center",
    padding: "6px 6px 3px",
    cursor: "pointer",
    fontSize: 15,
    color: primaryColor,
    gap: theme.spacing(1.0),

    borderRadius: 5,
    "&:focus, &:hover": {
      background: colors.blue[50],
    },
  },
  itemLabel: {
    whiteSpace: "nowrap",
  },
  separator: {
    margin: theme.spacing(0, "auto"),
    background: colors.blueGray[200],
    height: 1,
    width: "85%",
  },
  dropdownButton: {
    padding: "14px 8px 12px",
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    minWidth: "auto",
  },
  dropdownIcon: {
    position: "relative",
    top: -3,
  },
}))

interface Props {
  kit: PatientPortalKit
  orderedTest: PatientPortalOrderedTest
  disabled: boolean
  onNavigateToBloodLabDashboard: (() => void) | undefined
}

const MenuItem = ({ title, icon, onSelect }) => {
  const classes = useStyles()
  return (
    <DropdownMenu.Item className={classes.item} onSelect={onSelect}>
      <div>{icon}</div>
      <BodyText size="sm" weight="semibold">
        {title}
      </BodyText>
    </DropdownMenu.Item>
  )
}

const BloodDashboardsActionButtonDropdownMenu = ({
  kit,
  orderedTest,
  disabled,
  onNavigateToBloodLabDashboard,
}: Props) => {
  const classes = useStyles()

  const latestOrderedResult = useCachedResource<OrderedResult>(
    kit.relationships.ordered_results.data[0]
  )

  const order = useCachedResource<PatientPortalOrder>(
    orderedTest?.relationships.order.data
  )

  const patient = useCachedResource<PatientPortalPatient>(
    order?.relationships.patient.data
  )

  const onDownloadOriginalLabReport = () => {
    trackEventWithProperties(
      bloodLabDashboardEvents.PATIENT_DOWNLOAD_ORIGINAL_REPORT,
      {
        source: patientDownloadOriginalReportSource.PATIENT_PORTAL,
        patient: patient?.id,
        orderedResult: latestOrderedResult?.id,
      }
    )
    downloadFileByUrl(orderedTest.attributes.lab_results)
  }

  return (
    <DropdownMenu.Root modal={false}>
      <DropdownMenu.Trigger asChild disabled={disabled}>
        <Button color="primary" size="small" className={classes.dropdownButton}>
          <ArrowDropDownIcon className={classes.dropdownIcon} />
        </Button>
      </DropdownMenu.Trigger>

      <DropdownMenu.Portal>
        <DropdownMenu.Content
          align="end"
          className={classes.content}
          onCloseAutoFocus={(e) => {
            e.preventDefault()
          }}
          sideOffset={5}
        >
          {onNavigateToBloodLabDashboard && (
            <MenuItem
              title="Blood Lab Dashboard"
              icon={<BloodLabDashboardIcon />}
              onSelect={() => onNavigateToBloodLabDashboard()}
            />
          )}
          <MenuItem
            title="Original Lab Report"
            icon={<OriginalLabReportIcon />}
            onSelect={() => onDownloadOriginalLabReport()}
          />
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  )
}

export default BloodDashboardsActionButtonDropdownMenu
