import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"

import clsx from "clsx"

import { Button } from "@material-ui/core"

import Loading from "app/components/Loading"
import { ORDER_LINE_ITEM_TYPE } from "app/constants"
import { InsuranceToggleDirection } from "app/main/checkout/orderSidebar/insurance/InsuranceToggle"
import * as Actions from "app/main/patient-checkout/store/actions/orders.actions"
import { OrderRequestStatus } from "app/main/patient-checkout/store/actions/orders.actions"
import { colors, navy, white } from "app/theme"
import { PatientPortalOrder } from "app/types"
import makeAppStyles from "app/utils/makeAppStyles"

import { isPaymentRequired } from "../../utils/checkout-utils"
import InsuranceInformationPrompt from "./InsuranceInformationPrompt"
import ProcessingFee from "./ProcessingFee"
import ToggleInsuranceWarnings from "./ToggleInsuranceWarnings"

interface InsuranceToggleProps {
  checkoutToken: string
  order: PatientPortalOrder
  orderRequestStatus: string
  orderInsuredCompanies: string[]
}

const useStyles = makeAppStyles({
  insuranceToggleContainer: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    backgroundColor: colors.blueGray[100],
    padding: "2px",
    borderRadius: "6px",
    marginTop: "12px",
  },
  insuranceToggleButton: {
    flexGrow: 1,
    backgroundColor: "transparent",
    boxShadow: "none",
    borderRadius: "6px",
    color: navy,
  },
  activeInsuranceToggleButton: {
    backgroundColor: white,
    boxShadow: `0px 3px 8px 0px rgba(0, 32, 64, 0.15)`,
  },
})

function InsuranceToggle({
  checkoutToken,
  order,
  orderRequestStatus,
  orderInsuredCompanies,
}: InsuranceToggleProps) {
  const classes = useStyles()
  const dispatch: any = useDispatch()

  const orderHasUninsuredTests = order?.line_items?.some((lineItem) => {
    const key = lineItem.ordered_test?.lab_test?.lab_company?.key
    if (!key) {
      return false
    }

    const itemInsured = order.insurance_enabled_for.includes(key)
    return !itemInsured
  })
  const orderHasInsuredAndNonInsuredTests =
    orderInsuredCompanies?.length > 0 && orderHasUninsuredTests

  const [payInsuranceSelected, setIsPayInsuranceSelected] = useState(
    orderInsuredCompanies?.length > 0
  )
  const [isTogglingInsurance, setIsTogglingInsurance] = useState(false)

  useEffect(() => {
    // done outside of the toggle call due to not having up to date status from Redux when the toggle is clicked
    if (
      isTogglingInsurance &&
      orderRequestStatus === OrderRequestStatus.SUCCESS
    ) {
      setIsPayInsuranceSelected(!payInsuranceSelected)
      setIsTogglingInsurance(false)
    } else if (orderRequestStatus !== OrderRequestStatus.UPDATING) {
      setIsTogglingInsurance(false)
    }
  }, [orderRequestStatus])

  const handleToggleInsurance = async () => {
    setIsTogglingInsurance(true)
    const lineItemWithInsurance = order.line_items.find((lineItem) => {
      return (
        lineItem.type === ORDER_LINE_ITEM_TYPE.LAB_TEST &&
        order.insurance_enabled_for.includes(
          lineItem.ordered_test.lab_test.lab_company.key
        )
      )
    })

    if (!lineItemWithInsurance) {
      return
    }

    const labCompanyKey =
      lineItemWithInsurance?.ordered_test?.lab_test?.lab_company?.key
    if (!labCompanyKey) {
      return
    }

    dispatch(
      Actions.toggleInsurance(
        order.id,
        payInsuranceSelected
          ? InsuranceToggleDirection.UNINSURED
          : InsuranceToggleDirection.INSURED,
        labCompanyKey,
        checkoutToken
      )
    )
  }

  const insuranceButtonText = orderHasInsuredAndNonInsuredTests
    ? `Pay with Insurance for ${orderInsuredCompanies.join(" and ")} tests`
    : "Pay with Insurance"
  const cashPayButtonText = orderHasInsuredAndNonInsuredTests
    ? "Cash Pay for Entire Order"
    : "Pay Cash"

  return (
    <>
      <div className={classes.insuranceToggleContainer}>
        <Button
          onClick={handleToggleInsurance}
          variant="contained"
          className={clsx(
            classes.insuranceToggleButton,
            payInsuranceSelected && classes.activeInsuranceToggleButton
          )}
          disabled={isTogglingInsurance}
        >
          {insuranceButtonText}
        </Button>
        <Button
          onClick={handleToggleInsurance}
          variant="contained"
          className={clsx(
            classes.insuranceToggleButton,
            !payInsuranceSelected && classes.activeInsuranceToggleButton
          )}
          disabled={isTogglingInsurance}
        >
          {cashPayButtonText}
        </Button>
      </div>
      {isTogglingInsurance && <Loading ariaLabel="loading-payment-info" />}
      {!isTogglingInsurance && payInsuranceSelected && (
        <div>
          <ToggleInsuranceWarnings
            orderInsuredCompanies={orderInsuredCompanies}
          />
          <InsuranceInformationPrompt />
          {isPaymentRequired(order) && <ProcessingFee />}
        </div>
      )}
    </>
  )
}

export default InsuranceToggle
