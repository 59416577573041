import { useEffect, useState } from "react"

import { Grid } from "@material-ui/core"

import { API } from "app/api"
import { ReactComponent as SummaryPreviewLogo } from "app/assets/images/results-summary-preview-logo.svg"
import { ReactComponent as SummaryPreview } from "app/assets/images/results-summary-preview.svg"
import BodyText from "app/components/design-system/BodyText"
import PhotoAutoUpload from "app/components/upload/PhotoAutoUpload"
import { colors } from "app/theme"
import { Clinic } from "app/types"
import makeAppStyles from "app/utils/makeAppStyles"

const useStyles = makeAppStyles(() => ({
  previewContainer: {
    borderRadius: 7,
    backgroundColor: colors.gray[100],
    margin: 0,
    border: "1px solid #D1D5DB",
    display: "flex",
    flexDirection: "column-reverse",
    alignItems: "center",
  },
  preview: {
    marginTop: 20,
    position: "relative",
  },
  logoOverlay: {
    position: "absolute",
    top: 20,
    left: 56,
    maxHeight: 23,
    maxWidth: 80,
    zIndex: 1000,
  },
}))
interface Props {
  clinic?: Clinic
}

const ResultsSummaryLogoSettings = ({ clinic }: Props) => {
  const classes = useStyles()

  const [logoUrl, setLogoUrl] = useState(clinic?.logo)

  useEffect(() => {
    if (clinic) {
      setLogoUrl(clinic.logo)
    }
  }, [clinic])

  const onLogoUploadSuccess = (response) => {
    setLogoUrl(response.data.attributes.logo)
  }

  const onRemoveLogo = async () => {
    setLogoUrl(undefined)

    if (clinic) {
      await API.Clinic.deleteLogo(clinic.id)
    }
  }

  return (
    <Grid container direction="row" spacing={2}>
      <Grid item md={8} xs={12}>
        <BodyText weight="semibold" size="md">
          Your Clinic Logo (Optional)
        </BodyText>
        <BodyText>
          Upload a logo to custom-brand Results Summaries, Blood Lab Dashboards,
          and Food Plans. (minimum 400px wide)
        </BodyText>
        <PhotoAutoUpload
          uploadUrl={`/api/normalized/clinics/${clinic?.id}/logo/`}
          logoUrl={logoUrl ?? null}
          onLogoUploadSuccess={onLogoUploadSuccess}
          onRemoveLogo={onRemoveLogo}
        />
      </Grid>
      <Grid item md={4} xs={12}>
        <div className={classes.previewContainer}>
          <div className={classes.preview}>
            <SummaryPreview />
            {logoUrl ? (
              <img
                src={logoUrl}
                className={classes.logoOverlay}
                alt="Clinic Logo"
              />
            ) : (
              <SummaryPreviewLogo className={classes.logoOverlay} />
            )}
          </div>
        </div>
      </Grid>
    </Grid>
  )
}

export default ResultsSummaryLogoSettings
