import { ORDER_LINE_ITEM_TYPE } from "app/constants"
import { PatientPortalOrderLineItem } from "app/types"

function parseDollarAmount(str: string | undefined): number {
  if (!str) {
    return NaN
  }
  return parseFloat(str.replace(/[^0-9.]/gi, ""))
}

function calculateMultiplierPrefix(count: number): string {
  return count >= 2 ? `${count}x ` : ""
}

type AxleCalculatorReturnValue = {
  axleBaseFee: number
  additionalAxleKitFees: number
  additionalAxleKitsCount: number
  totalAxlePrice: number
  additionalAxleKitsTitle: string
  axleBaseTitle: string
}

// Calculate the fees and strings to display to users for all the Axle line items.
// This includes both the base Axle line item and additional line items for Axle Kit fees.
export function calculateAxlePricesFromLineItems(
  line_items: PatientPortalOrderLineItem[]
): AxleCalculatorReturnValue {
  const axleBaseLineItem = line_items.find(
    (lineItem) => lineItem.type === ORDER_LINE_ITEM_TYPE.AXLE_BLOOD_DRAW_FEE
  )

  const axleAdditionalFeeLineItems = line_items.filter(
    (lineItem) =>
      lineItem.type === ORDER_LINE_ITEM_TYPE.AXLE_BLOOD_DRAW_ADDITIONAL_KIT_FEE
  )

  const axleBaseFee = parseDollarAmount(axleBaseLineItem?.cost)
  const axleBaseTitle = axleBaseLineItem?.title ?? ""
  const additionalAxleKitFees = axleAdditionalFeeLineItems.reduce(
    (accumulator, lineItem) => accumulator + parseDollarAmount(lineItem.cost),
    0
  )

  let additionalAxleKitsTitle =
    axleAdditionalFeeLineItems.length > 0
      ? axleAdditionalFeeLineItems[0].title
      : ""
  const additionalAxleKitsCount = axleAdditionalFeeLineItems.length
  const totalAxlePrice = axleBaseFee + additionalAxleKitFees

  additionalAxleKitsTitle =
    calculateMultiplierPrefix(additionalAxleKitsCount) + additionalAxleKitsTitle

  return {
    totalAxlePrice,
    axleBaseFee,
    axleBaseTitle,
    additionalAxleKitsCount,
    additionalAxleKitsTitle,
    additionalAxleKitFees,
  }
}
