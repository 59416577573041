import { Button, withStyles } from "@material-ui/core"

import { navy } from "../theme"

/**
 * @deprecated - Use app/components/design-system/Button instead.
 */
export const SecondaryButton = withStyles((theme) => ({
  root: {
    backgroundColor: "white",
    color: navy,
    border: "1px solid #D4DCE4",
  },
}))(Button)
