import { useCallback } from "react"

import { uniq } from "lodash"

import * as Sentry from "@sentry/react"

import { API } from "app/api"
import useHandleApiError from "app/hooks/use-handle-api-error"
import { ResultsInProgressBanner } from "app/main/patient-orders/components/results-in-progress/ResultsInProgressBanner"
import { StoredUploadJobStatus } from "app/main/results-upload-v2/results-upload-modal/hooks/use-stored-upload-jobs"
import { UserClinicAccessSharingStatus } from "app/types"

import useUserClinicAccessConfigurations from "../lab-results-access/hooks/use-user-clinic-access-configurations"

interface Props {
  userId: string
}

export const PatientPortalResultsInProgressBanner: React.FC<Props> = ({
  userId,
}) => {
  const handleApiError = useHandleApiError()
  const { userClinicAccessConfigurations } =
    useUserClinicAccessConfigurations(userId)

  const onJobCompletion = useCallback(
    async (jobStatus: StoredUploadJobStatus) => {
      const userResultIds = uniq([
        ...(jobStatus.properties.user_results ?? []),
        ...jobStatus.children.flatMap((child) => {
          if (typeof child === "string") return []
          return child.properties.user_results ?? []
        }),
      ])

      if (userResultIds.length === 0) return

      const configsToShareWith = userClinicAccessConfigurations.filter(
        (config) =>
          jobStatus.clinicIdsToShareWith?.includes(
            config.relationships.clinic.data.id
          )
      )

      if (configsToShareWith.length === 0) return

      for (const config of configsToShareWith) {
        const data = {
          sharing_status:
            config.attributes.sharing_status ===
            UserClinicAccessSharingStatus.NONE
              ? UserClinicAccessSharingStatus.SPECIFIC
              : config.attributes.sharing_status,
          documents: [],
          user_results: userResultIds.map((id) => ({ id, can_read: true })),
        }
        try {
          await API.User.patchClinicDocumentAccess(
            userId,
            config.relationships.clinic.data.id,
            data
          )
        } catch (error) {
          Sentry.captureException(error)
          handleApiError(error)
        }
      }
    },
    [userClinicAccessConfigurations, handleApiError]
  )

  return (
    <ResultsInProgressBanner userId={userId} onCompletion={onJobCompletion} />
  )
}
