import { useState } from "react"

interface UseSignatureAndLicenseUpload {
  // Method for handling the verification modal close event.
  handleSignatureAndLicenseModalClose: (
    fullVerificationComplete: boolean
  ) => void
  // Method for handling the verification modal open event.
  handleSignatureAndLicenseModalOpen: () => void
  // Method for handling the "Sign Now" onClick event.
  onClickHelloSign: () => void
  // Indicates whether or not to render the signature and license modal.
  showSignatureAndLicenseUpload: boolean
  // Indicates whether or not the HelloSign iframe is loading.
  signatureClientLoading: boolean
}

/**
 * Initializes the embedded e-signature and license verification modal.
 * @param {Function} onClose The method to call when the signature and license modal is closed.
 * @returns {UseSignatureAndLicenseUpload} Object which provides the necessary integration points for the e-signature and license upload flow.
 */
const useSignatureAndLicenseUpload = (
  onClose: (fullVerificationComplete: boolean) => void
): UseSignatureAndLicenseUpload => {
  const [signatureClientLoading, setSignatureClientLoading] = useState(false)
  const [showSignatureAndLicenseUpload, setShowSignatureAndLicenseUpload] =
    useState(false)
  const onClickHelloSign = () => {
    setShowSignatureAndLicenseUpload(true)
    setSignatureClientLoading(true)
  }
  const handleSignatureAndLicenseModalOpen = () => {
    setSignatureClientLoading(false)
  }
  const handleSignatureAndLicenseModalClose = (
    fullVerificationComplete: boolean
  ) => {
    setShowSignatureAndLicenseUpload(false)
    setSignatureClientLoading(false)
    onClose(fullVerificationComplete)
  }
  return {
    handleSignatureAndLicenseModalClose,
    handleSignatureAndLicenseModalOpen,
    onClickHelloSign,
    showSignatureAndLicenseUpload,
    signatureClientLoading,
  }
}

export default useSignatureAndLicenseUpload
