import { ReactElement } from "react"

import clsx from "clsx"

import NiceModal, { muiDialog, useModal } from "@ebay/nice-modal-react"
import { Theme } from "@material-ui/core"
import MuiDialogContent from "@material-ui/core/DialogContent"
import MuiDialogTitle from "@material-ui/core/DialogTitle"
import IconButton from "@material-ui/core/IconButton"
import CloseIcon from "@material-ui/icons/Close"

import { ReactComponent as DashboardCardsIcon } from "app/assets/icons/rupa-blood-dashboards/dashboard-cards.svg"
import { ReactComponent as QuestionSquareDark } from "app/assets/icons/rupa-blood-dashboards/question-square-dark.svg"
import BodyText from "app/components/design-system/BodyText"
import DisplayText from "app/components/design-system/DisplayText"
import { Dialog } from "app/components/modals"
import { colors, navy, shadows } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"

interface AboutBloodLabsDashboardsModalProps {
  title: string
  showAboutTheDashboardSection: boolean
  onClose: () => void
}

interface CardContentProps {
  title: string
  icon: ReactElement
  [x: string]: any
}

const useStyles = makeAppStyles((theme: Theme) => ({
  dialogPaper: {
    margin: 15,
    borderRadius: 12,
    border: "4px solid white",
    boxShadow: shadows["2xl"],
    maxWidth: 800,
  },
  closeButton: {
    color: navy,
    position: "relative",
    top: 1,
    right: 0,
    marginRight: -12,
  },
  titleContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    flexWrap: "nowrap",
    backgroundColor: "white",
    alignItems: "center",
    padding: "8px 24px",
  },
  titleText: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    marginRight: 24,
  },
  content: {
    padding: 22.5,
    backgroundColor: colors.trueGray[100],
    display: "flex",
    flexDirection: "column",
    gap: 16,
    [theme.breakpoints.down("sm")]: {
      padding: 12,
    },
  },
  card: {
    backgroundColor: "white",
    borderRadius: 8,
    boxShadow: shadows.sm,
    display: "flex",
    flexDirection: "column",
    gap: 10,
    padding: 10,
    width: "100%",
  },
  cardTitle: {
    borderRadius: 8,
    background: colors.blueGray[100],
    display: "flex",
    gap: 6,
    alignItems: "center",
    padding: "6px 8px",
    width: "100%",
    fontWeight: 600,
    fontSize: 14,
    lineHeight: "130%",
  },
  cardsContainer: {
    display: "flex",
    flexDirection: "column",
    gap: 10,
  },
  optimalRangeCard: {
    background: "white",
    borderRadius: 6,
    border: `1px solid ${colors.blueGray[300]}`,
    boxShadow: shadows.sm,
    padding: 10,
    width: "100%",
    display: "flex",
    gap: 10,
    alignItems: "center",
  },
  styledBar: {
    minHeight: 56,
    height: "auto",
    width: 9,
    borderRadius: 33,
    [theme.breakpoints.down("sm")]: {
      width: 17,
    },
  },
  lowRangeStyledBar: {
    background: "linear-gradient(0deg, #F6BD59 0%, #F87671 100%)",
  },
  belowOptimalRangeStyledBar: {
    background:
      "linear-gradient(180deg, #F6BD58 0%, #F5D052 40.1%, #90C74D 100%)",
  },
  optimalRangeStyledBar: {
    background:
      "linear-gradient(0deg, #90C74D 0%, #43D495 49.48%, #90C74D 100%)",
  },
  aboveOptimalRangeStyledBar: {
    background:
      "linear-gradient(0deg, #F6BD58 0%, #F5D052 40.1%, #90C74D 100%)",
  },
  highRangeStyledBar: {
    background: "linear-gradient(180deg, #F6BD59 0%, #F87671 100%)",
  },
}))

const AboutBloodLabsDashboardsModal = ({
  title,
  showAboutTheDashboardSection,
  onClose,
}: AboutBloodLabsDashboardsModalProps) => {
  const classes = useStyles()

  const modal = useModal()
  const muiDialogControl = muiDialog(modal)

  return (
    <>
      <Dialog
        {...muiDialogControl}
        disableEnforceFocus
        disableEscapeKeyDown
        fullWidth
        maxWidth="md"
        className={"fs-unmask"}
        classes={{
          paper: classes.dialogPaper,
        }}
      >
        <TitleSection title={title} onClose={onClose} />
        <MuiDialogContent className={classes.content}>
          {showAboutTheDashboardSection && (
            <ContentCard
              title="About the Dashboard"
              icon={<DashboardCardsIcon />}
            >
              <BodyText>
                Welcome to your Blood Lab Dashboard! This interactive tool is
                designed to help you understand your lab results in a more
                comprehensive and personalized way. You can view your results
                grouped by body system, and see detailed descriptions for each
                biomarker. This dashboard is part of our commitment to empower
                you with knowledge about your health.
              </BodyText>
            </ContentCard>
          )}
          <ContentCard
            title="What are Optimal Ranges?"
            icon={<QuestionSquareDark />}
          >
            <BodyText>
              Optimal ranges aim to pinpoint the ideal level of a specific
              biomarker for peak health. Unlike "normal" lab ranges, which just
              rule out disease, optimal ranges are narrower and grounded in
              research and clinical experience. They offer a more nuanced view
              of your well-being, beyond just a "normal" or "abnormal" label.
            </BodyText>

            <div className={classes.cardsContainer}>
              <div className={classes.optimalRangeCard}>
                <div
                  className={clsx(classes.styledBar, classes.lowRangeStyledBar)}
                />
                <div>
                  <BodyText weight="semibold">Low</BodyText>
                  <BodyText size="sm">
                    Your biomarker level is below the standard range, indicating
                    a potential deficiency.
                  </BodyText>
                </div>
              </div>

              <div className={classes.optimalRangeCard}>
                <div
                  className={clsx(
                    classes.styledBar,
                    classes.belowOptimalRangeStyledBar
                  )}
                />
                <div>
                  <BodyText weight="semibold">Below Optimal</BodyText>
                  <BodyText size="sm">
                    Your biomarker level is within the lab’s standard range but
                    below the ideal level for optimal health.
                  </BodyText>
                </div>
              </div>

              <div className={classes.optimalRangeCard}>
                <div
                  className={clsx(
                    classes.styledBar,
                    classes.optimalRangeStyledBar
                  )}
                />
                <div>
                  <BodyText weight="semibold">Optimal</BodyText>
                  <BodyText size="sm">
                    Your biomarker level is within the ideal range for optimal
                    health.
                  </BodyText>
                </div>
              </div>

              <div className={classes.optimalRangeCard}>
                <div
                  className={clsx(
                    classes.styledBar,
                    classes.aboveOptimalRangeStyledBar
                  )}
                />
                <div>
                  <BodyText weight="semibold">Above Optimal</BodyText>
                  <BodyText size="sm">
                    Your biomarker level is within the lab’s standard range but
                    above the ideal level for optimal health.
                  </BodyText>
                </div>
              </div>

              <div className={classes.optimalRangeCard}>
                <div
                  className={clsx(
                    classes.styledBar,
                    classes.highRangeStyledBar
                  )}
                />
                <div>
                  <BodyText weight="semibold">High</BodyText>
                  <BodyText size="sm">
                    Your biomarker level is above the standard range, indicating
                    a potential excess.
                  </BodyText>
                </div>
              </div>
            </div>
          </ContentCard>
        </MuiDialogContent>
      </Dialog>
    </>
  )
}

const TitleSection = ({ title, onClose }) => {
  const classes = useStyles()

  const closeButton = onClose && (
    <IconButton
      aria-label="close"
      onClick={onClose}
      key="close-button"
      className={classes.closeButton}
    >
      <CloseIcon />
    </IconButton>
  )

  return (
    <MuiDialogTitle disableTypography className={classes.titleContainer}>
      <div className={classes.titleText}>
        <DisplayText weight="semibold" size="lg">
          {title}
        </DisplayText>
      </div>
      {closeButton}
    </MuiDialogTitle>
  )
}

const ContentCard = ({ title, icon, ...props }: CardContentProps) => {
  const classes = useStyles()

  return (
    <div className={classes.card}>
      <div className={classes.cardTitle}>
        {icon}
        {title}
      </div>
      {props.children}
    </div>
  )
}

const AboutBloodLabsDashboardsNiceModal =
  NiceModal.create<AboutBloodLabsDashboardsModalProps>(
    AboutBloodLabsDashboardsModal
  )

export default AboutBloodLabsDashboardsNiceModal
