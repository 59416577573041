import { DropdownMenuItem } from "app/components/DropdownMenu"
import Tooltip from "app/components/Tooltip"
import { formatDate } from "app/utils"

import { TooltipContent } from "./PaymentDownloadButton"
import { OrderPaymentDisplayType } from "./helpers"
import useDownloadOrderPayment from "./use-download-order-payment"

interface Props {
  payment: OrderPaymentDisplayType
}

const PaymentDropdownItem: React.FC<Props> = ({ payment }) => {
  const onClick = useDownloadOrderPayment(payment)

  return (
    <Tooltip title={TooltipContent(payment)} arrow placement="bottom">
      <DropdownMenuItem
        text={formatDate(payment.attributes?.created_at)}
        disabled={payment.disabled}
        onClick={onClick}
      />
    </Tooltip>
  )
}

export default PaymentDropdownItem
