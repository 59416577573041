/**
 * The UploadLicenseModal provides a modal for uploading a practitioner license.
 */
import { useState, useEffect } from "react"

import {
  Link,
  makeStyles,
  styled,
  Theme,
  Typography,
  useMediaQuery,
} from "@material-ui/core"
import MuiDialogContent from "@material-ui/core/DialogContent"
import MuiDialogTitle from "@material-ui/core/DialogTitle"
import IconButton from "@material-ui/core/IconButton"
import CloseIcon from "@material-ui/icons/Close"

import BodyText from "app/components/design-system/BodyText"
import { Dialog } from "app/components/modals"
import { navy } from "app/theme"

import UploadLicense from "./UploadLicense"

const useStyles = makeStyles(() => ({
  dialogPaper: {
    margin: 15,
    borderRadius: 7,
  },
}))

interface StyledProps {
  isXsMobile?: boolean
}
interface CloseButtonProps extends StyledProps {
  processing: boolean
}
interface StyledLinkProps {
  fullVerificationComplete: boolean
  onClick: any
}

const StyledContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
})
const StyledTitle = styled(Typography)({
  textAlign: "center",
})
const StyledDescription = styled(Typography)({
  maxWidth: "512px",
  textAlign: "center",
  whiteSpace: "pre-wrap",
})

const StyledLink = styled(
  ({ fullVerificationComplete, ...other }: StyledLinkProps) => (
    <Link {...other} />
  )
)({
  cursor: "pointer",
  visibility: ({ fullVerificationComplete }: StyledLinkProps) =>
    fullVerificationComplete ? "visible" : "hidden",
})

const CloseButton = styled(({ isXsMobile, processing, ...other }) => (
  <IconButton {...other} />
))({
  color: navy,
  visibility: ({ processing }: CloseButtonProps) =>
    processing ? "hidden" : "visible",
  position: ({ isXsMobile }: CloseButtonProps) =>
    isXsMobile ? "absolute" : "relative",
  marginTop: ({ isXsMobile }: CloseButtonProps) => (isXsMobile ? "0" : "-12px"),
  top: 1,
  right: ({ isXsMobile }: CloseButtonProps) => (isXsMobile ? 24 : 0),
  marginRight: -19,
})

const StyledMuiDialogTitle = styled(MuiDialogTitle)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  flexWrap: "nowrap",
  backgroundColor: "white",
  alignItems: "center",
  paddingBottom: "0",
})

const StyledMuiDialogContent = styled(({ isXsMobile, ...other }) => (
  <MuiDialogContent {...other} />
))({
  padding: ({ isXsMobile }: StyledProps) => {
    return isXsMobile ? "32px 24px 24px" : "0 48px 48px"
  },
})

const StyledBodyText = styled(({ ...other }) => <BodyText {...other} />)({
  textAlign: "center",
})
const ActionButtonContainer = styled("div")({
  display: "flex",
  flexGrow: 1,
  flexDirection: "column",
  alignItems: "flex-end",
})

interface Props {
  autoClose?: boolean
  fullVerificationComplete: boolean
  onClose: () => void
  onUploadComplete: () => void
  onUploadFail: () => void
  onUploadStart: () => void
  open: boolean
  returnLinkText?: string
}
export default function UploadLicenseModal({
  autoClose,
  fullVerificationComplete,
  onClose,
  onUploadComplete,
  onUploadFail,
  onUploadStart,
  open,
  returnLinkText,
}: Props) {
  const [uploadStarted, setUploadStarted] = useState(false)
  const isXsMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("xs")
  )
  const classes = useStyles()

  useEffect(() => {
    if (fullVerificationComplete && autoClose) {
      onClose()
    }
  }, [fullVerificationComplete])

  const handleClose = () => {
    onClose()
  }

  const handleUploadStart = () => {
    setUploadStarted(true)
    onUploadStart()
  }

  const handleUploadComplete = () => {
    onUploadComplete()
  }

  const handleUploadFail = () => {
    onUploadFail()
  }
  const processing = uploadStarted && !fullVerificationComplete

  return (
    <Dialog
      aria-labelledby="upload-license-title"
      classes={{ paper: classes.dialogPaper }}
      disableBackdropClick
      disableEscapeKeyDown
      fullWidth
      maxWidth="sm"
      open={open}
    >
      <StyledMuiDialogTitle disableTypography>
        <ActionButtonContainer>
          <CloseButton
            aria-label="close"
            isXsMobile={isXsMobile}
            key="close-button"
            onClick={handleClose}
            processing={processing}
          >
            <CloseIcon />
          </CloseButton>
        </ActionButtonContainer>
      </StyledMuiDialogTitle>
      <StyledMuiDialogContent isXsMobile={isXsMobile}>
        <div>
          <StyledContainer>
            <StyledTitle
              color="textPrimary"
              id="upload-license-title"
              variant="h5"
            >
              Upload a Copy of Your Professional License
            </StyledTitle>

            <StyledDescription color="textPrimary">
              You must be licensed to practice and order lab testing in your
              state.
            </StyledDescription>
          </StyledContainer>

          <UploadLicense
            fullVerificationComplete={fullVerificationComplete}
            onUploadComplete={handleUploadComplete}
            onUploadFail={handleUploadFail}
            onUploadStart={handleUploadStart}
          />
          <StyledBodyText size="sm" weight="semibold">
            <StyledLink
              fullVerificationComplete={fullVerificationComplete}
              onClick={handleClose}
            >
              {returnLinkText || ""}
            </StyledLink>
          </StyledBodyText>
        </div>
      </StyledMuiDialogContent>
    </Dialog>
  )
}
