import { faArrowRotateRight } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import IOKStartOrderModalButton from "../IOKStartOrderModalButton"
import { useIOKInventory } from "./InventoryDataProvider"
import { InventoryItem } from "./inventory-types"

interface Props {
  inventoryItem: InventoryItem
}
const ReStockKitsModalButton: React.FC<Props> = ({
  inventoryItem: {
    in_office_kit_lab_company_id,
    in_office_kit_product_id,
    most_recent_order_quantity,
  },
}) => {
  const { mutate } = useIOKInventory()
  return (
    <IOKStartOrderModalButton
      className="w-36"
      initialValues={{
        iok_lab_company: in_office_kit_lab_company_id,
        [in_office_kit_product_id]: most_recent_order_quantity,
      }}
      // For now, just invalidate the cache so it refetches the inventory
      onSuccess={mutate}
    >
      <FontAwesomeIcon icon={faArrowRotateRight} className="mr-2" />
      <span className="pt-1">Re-Stock Kits</span>
    </IOKStartOrderModalButton>
  )
}

export default ReStockKitsModalButton
