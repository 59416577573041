// Max number of bundles + lab tests that can be added to a storefront
export const MAX_NUMBER_OF_PRODUCTS = 50
// Max number of bundles + lab tests that can be added before we show the alert banner to use multiple LabShops
export const MAX_NUMBER_OF_PRODUCTS_FOR_ALERT = 40
export const MAX_CUSTOM_FEE_PRICE_LAB_SHOPS = 200000

export enum LabShopEvent {
  CREATE_NEW_LABSHOP = "LabShop_Create_New_LabShop_Click",
  OPEN_DELETE_LABSHOP_MODAL = "LabShop_Open_Delete_Store_Modal",
  CONFIRM_DELETE_LABSHOP = "LabShop_Delete_Confirmed",
  ADD_LABSHOP_SECTION = "LabShop_Section_Added",
  REMOVE_LABSHOP_SECTION = "LabShop_Section_Removed",
  ADD_LABSHOP_PRODUCT = "LabShop_Product_Added",
  REMOVE_LABSHOP_PRODUCT = "LabShop_Product_Removed",
  OPEN_CUSTOMIZE_PRODUCT = "LabShop_Open_Custmize_Product_Modal",
  PUBLISH_LABSHOP = "LabShop_Publish_Store_Click",
  PAUSE_LABSHOP = "LabShop_Pause_Store_Click",
  SAVE_LABSHOP_DRAFT = "LabShop_Save_Draft_Store_Click",
  COPY_LABSHOP_URL = "LabShop_Copy_Url_Click",
  VISIT_LABSHOP_URL = "LabShop_Visit_Store_Click",
  ADD_TO_CART = "LabShop_Client_Add_To_Cart",
}

export const INTERCOM_REQUEST_PRAC_LICENSE_LABSHOP_MESSAGE =
  "Hi! I’m interested in setting-up a LabShop that does not go through Rupa’s Physician Services. " +
  "I’d like the LabShop orders to be placed under the license of: " +
  "{insert the name of the ordering practitioner for your clinic LabShop, including yourself!}"

export enum StorefrontOrderApprovalStatus {
  PENDING = "PENDING",
  APPROVED = "APPROVED",
  DENIED = "DENIED",
}
