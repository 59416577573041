import * as React from "react"

import BodyText from "app/components/design-system/BodyText"
import { colors, primaryColor } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"

interface Props {
  icon: React.ReactNode
  title?: string
  text?: string
  actionButton: React.ReactNode
  onClick?: () => void
}

const useStyles = makeAppStyles<{ hasText: boolean }>((theme) => ({
  resourceCard: {
    borderRadius: 12,
    background: "White",
    padding: "16px",
    display: "flex",
    transition: "all 0.2s",
    width: "100%",
    gap: 16,
    borderWidth: 1,
    borderColor: "white",
    flexDirection: (props) => (props.hasText ? "column" : "row"),
    alignItems: (props) => (props.hasText ? "flex-start" : "center"),

    "&:hover": {
      background: colors.blue[50],
      borderColor: primaryColor,
      cursor: "pointer",
    },

    [theme.breakpoints.up("sm")]: {
      padding: "24px 20px",
      // Required to override the flexDirection above... (using a function somehow increases the specificity)
      flexDirection: () => "column",
      alignItems: () => "center",
      justifyContent: "space-between",
      height: "100%",
      textAlign: "center",
    },
  },
  title: {
    marginTop: 16,
  },
  lightText: {
    color: colors.blueGray[500],
    marginTop: 8,
  },
}))

const PatientPortalFooterCard = ({
  icon,
  title,
  text,
  actionButton,
  onClick,
}: Props) => {
  const classes = useStyles({ hasText: Boolean(text) })

  return (
    <div className={classes.resourceCard} onClick={onClick}>
      <div>
        <div>{icon}</div>
        {title && (
          <BodyText className={classes.title} weight="semibold">
            {title}
          </BodyText>
        )}
        {text && (
          <BodyText weight="regular" size="sm" className={classes.lightText}>
            {text}
          </BodyText>
        )}
      </div>
      {actionButton}
    </div>
  )
}

export default PatientPortalFooterCard
