import * as React from "react"

import { Control, useController } from "react-hook-form"

import { FieldDecorationProps } from "app/results-summary/components/ResultsSummaryEditor/components/FieldDecoration"
import { cancelRed, colors, textPrimaryColor } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"

const useStyles = makeAppStyles<{ error: boolean }>((theme) => ({
  input: {
    width: "100%",
    padding: "9px 13px",
    fontSize: 15,
    fontWeight: 400,
    lineHeight: 1.35,
    backgroundColor: "#fff",
    color: textPrimaryColor,
    borderRadius: 6,
    border: (props) =>
      `1px solid ${props.error ? cancelRed : colors.blueGray[300]}`,
    "&:disabled": {
      color: colors.blueGray[500],
    },
    "&::placeholder": {
      color: colors.blueGray[500],
      fontWeight: 600,
    },
  },
}))

/**
 * A text input variant.
 */
const TextInputVariant = React.forwardRef<
  HTMLInputElement,
  React.ComponentPropsWithoutRef<"input">
>((props, ref) => {
  return (
    <input
      ref={ref}
      type="number"
      {...props}
      autoComplete="off"
      autoCorrect="off"
      autoCapitalize="off"
      spellCheck="false"
    />
  )
})

/**
 * Renders a text input.
 */
const Input = React.forwardRef(function renderInput(
  props: React.ComponentPropsWithoutRef<"input">,
  ref: React.ForwardedRef<HTMLInputElement>
) {
  return (
    <TextInputVariant
      {...(props as React.ComponentPropsWithoutRef<"input">)}
      ref={ref as React.ForwardedRef<HTMLInputElement>}
    />
  )
})

export interface BaseFieldProps extends Omit<FieldDecorationProps, "error"> {
  control: Control<any>
  disabled?: boolean
  name: string
  placeholder?: string
}

/**
 * The text field component. Renders a text input with error handling.
 */
export default function TextField({
  control,
  disabled,
  name,
  placeholder,
  required,
}: BaseFieldProps) {
  const {
    field,
    fieldState: { error },
  } = useController({
    control,
    name,
    shouldUnregister: true,
  })
  const classes = useStyles({ error: Boolean(error) })

  return (
    <Input
      aria-invalid={error ? "true" : "false"}
      className={classes.input}
      disabled={disabled}
      placeholder={placeholder}
      required={required}
      {...field}
    />
  )
}
