import BloodIcon from "app/assets/icons/blood.svg"
import { checkIfOrderNeedPhlebotomist } from "app/phlebotomyUtils"
import makeAppStyles from "app/utils/makeAppStyles"

import RoundedContainer from "../RoundedContainer"

interface NeedPhlebotomistWarningProps {
  order: any
}

const useStyles = makeAppStyles(() => ({
  container: {
    display: "flex",
    alignItems: "flex-start",
    marginTop: "20px",
    marginBottom: "20px",
  },
  icon: {
    marginRight: 8,
    height: 20,
  },
}))

/* Don't show this box if we have any BRL or Labcorp phleb tests - otherwise it sounds like they need to pay the phleb fee at Labcorp as well as for non-Labcorp */
const NeedPhlebotomistWarning = ({ order }: NeedPhlebotomistWarningProps) => {
  const classes = useStyles()

  let [patientNeedsToFindPhlebotomist, testsHaveSpecificPhlebotomist] =
    checkIfOrderNeedPhlebotomist(order)

  if (patientNeedsToFindPhlebotomist && !testsHaveSpecificPhlebotomist) {
    return (
      <RoundedContainer>
        <div className={classes.container}>
          <img className={classes.icon} src={BloodIcon} alt="" />
          You’ll need a blood draw for some of your kits. You will pay this
          directly to the person who draws your blood, and it typically ranges
          from $30-$100. We’ll send you instructions on how to coordinate this
          directly with the lab.
        </div>
      </RoundedContainer>
    )
  }
  return null
}
export default NeedPhlebotomistWarning
