import { createContext } from "react"

import { ScriptStatus } from "app/hooks/use-script"

export interface FlexFieldsSetup {
  container: string
  fields: {
    number: {
      selector: string
    }
    cvv: {
      selector: string
    }
    expirationDate: {
      selector: string
    }
    style: object
  }
  errorBox: {
    selector: string
  }
  publicToken?: string
}

export interface FlexFieldsContextShape {
  /**
   * The FlexFields instance if it has been setup.
   */
  flexFields: any | null
  /**
   * Whether or not the flex fields are ready.
   */
  isReady: boolean
  /**
   * Sets up the flex fields instance and attaches to the credit card form.
   */
  setup: (config: FlexFieldsSetup) => void
  /**
   * The status of the SplitIt SDK script.
   */
  status: ScriptStatus
}

export default createContext<FlexFieldsContextShape>({
  flexFields: null,
  isReady: false,
  setup: () => {},
  status: "loading",
})
