import { useMemo, useState } from "react"

import { useHistory, useLocation } from "react-router-dom"
import { useUpdateEffect } from "react-use"

/**
 * Hook to manage state in the query params.
 *
 * @remarks
 * This hook is useful when you want to manage state in the query params.
 * This has race condition problems if you call set state for multiple of these hooks in one callback.
 *
 * You MUST return a key for every value in the query params, even if it is undefined.
 * Otherwise the query params will not be updated correctly to remove your param.
 *
 * @example
 * const [state, setState] = useQueryState(
 *   query => ({
 *     page: Number(query.page) || 1,
 *     search: query.search || '',
 *   }),
 *   state => ({
 *     // returning undefined here ensures "page" is removed
 *     page: state.page > 0 ? String(state.page) : undefined,
 *     // search is a string, and empty strings will be removed as a query param
 *     search: state.search,
 *   }),
 * )
 *
 * @param toState map the query params to the state value
 * @param toQuery map the state value to the query params
 * @returns the state and a function to update the state
 */
export default function useQueryState<S extends unknown, Q extends object>(
  toState: (query: { [key: string]: string }) => S,
  toQuery: (state: S) => Q
) {
  const history = useHistory()
  const location = useLocation()

  const query = useMemo(() => {
    const params = new URLSearchParams(location.search)
    return Object.fromEntries(params.entries())
  }, [location.search])

  const [state, setState] = useState(() => toState(query))

  useUpdateEffect(() => {
    setState(toState(query))
  }, [query])

  useUpdateEffect(() => {
    const params = new URLSearchParams(location.search)

    Object.entries(toQuery(state)).forEach(([key, value]) => {
      params.delete(key)

      if (value) {
        params.set(key, value)
      }
    })

    const nextSearch = params.toString()

    if (location.search.slice(1) !== nextSearch) {
      history.replace({
        ...location,
        search: params.toString(),
      })
    }
  }, [state])

  return [state, setState] as const
}
