import { useEffect, useState } from "react"

import NiceModal, { muiDialog, useModal } from "@ebay/nice-modal-react"
import {
  Dialog,
  DialogTitle,
  styled,
  IconButton,
  DialogContent,
  DialogActions,
  CircularProgress,
} from "@material-ui/core"
import CloseIcon from "@material-ui/icons/Close"

import GoBackBlueIcon from "app/assets/icons/rupa-blood-dashboards/go-back-blue.svg"
import BodyText from "app/components/design-system/BodyText"
import DesignSystemButton from "app/components/design-system/Button"
import useCachedResource from "app/swr/hooks/use-cached-resource"
import { colors, navy } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"
import { Biomarker } from "types/biomarker"
import { BiomarkerCustomDescription } from "types/biomarker-custom-description"

import TextField from "./TextField"
import useBiomarkerCustomDescriptionForm from "./hooks/use-biomarker-custom-description-form"

const useStyles = makeAppStyles((theme) => ({
  dialogContainer: {
    borderRadius: 12,
  },
  dialogTitle: {
    display: "flex",
    flexFlow: "row nowrap",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "white",
    borderBottomWidth: 1,
    borderColor: colors.blueGray[300],
    padding: theme.spacing(2.0),
    gap: 10,
  },
  body: {
    backgroundColor: colors.blueGray[50],
    padding: 20,
    display: "flex",
    flexDirection: "column",
    gap: 25,
  },
  inputContainer: {
    display: "flex",
    flexDirection: "column",
    gap: 11,
  },
  inputLabelContainer: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
  },
  inputLabel: {
    display: "flex",
    gap: 4,
    alignItems: "baseline",
  },
  footer: {
    padding: "15px 22.5px",
  },
  saveButton: {
    width: 103,
  },
  highLowDescriptionArrow: {
    fontSize: 14,
    fontWeight: 700,
    color: colors.orange[500],
  },
}))

const CloseButton = styled(IconButton)(({ theme }) => ({
  color: navy,
  position: "absolute",
  top: 1,
  right: 12,
  marginRight: -12,

  [theme.breakpoints.up("sm")]: {
    position: "relative",
    right: 0,
  },
}))

const ResetToDefaultButton = ({ onResetToDefault }) => {
  return (
    <DesignSystemButton
      color="text"
      onClick={onResetToDefault}
      startIcon={
        <img
          src={GoBackBlueIcon}
          alt="revert blue icon"
          style={{ position: "relative", top: "-2px" }}
        />
      }
      loading={false}
    >
      Reset to Default
    </DesignSystemButton>
  )
}

export interface ModifyBiomarkerCustomDescriptionModalProps {
  biomarkerCustomDescription: BiomarkerCustomDescription
}

const ModifyBiomarkerCustomDescriptionModal = ({
  biomarkerCustomDescription,
}: ModifyBiomarkerCustomDescriptionModalProps) => {
  const modal = useModal()
  const muiDialogControl = muiDialog(modal)
  const classes = useStyles()

  const biomarker = useCachedResource<Biomarker>(
    biomarkerCustomDescription?.relationships.biomarker.data
  )

  const {
    methods: { watch, control, getValues, setValue },
    handleSubmitForm,
    isPatchLoading,
  } = useBiomarkerCustomDescriptionForm(biomarkerCustomDescription, biomarker)

  const [formValues, setFormValues] = useState(getValues())

  useEffect(() => {
    const subscription = watch((value) => setFormValues(value))
    return () => subscription.unsubscribe()
  }, [watch])

  const handleResetToDefault = (field) => {
    setValue(field, biomarker?.attributes[field])
  }

  const submitForm = async () => {
    await handleSubmitForm(getValues())
    modal.hide()
  }

  return (
    <Dialog
      {...muiDialogControl}
      disableEnforceFocus
      disableEscapeKeyDown
      fullWidth
      maxWidth="md"
      classes={{
        paper: classes.dialogContainer,
      }}
    >
      <DialogTitle className={classes.dialogTitle} disableTypography>
        <BodyText weight="semibold" size="lg">
          Customize Biomarker Descriptions
        </BodyText>

        <CloseButton
          onClick={muiDialogControl.onClose}
          aria-label="close"
          key="close-button"
        >
          <CloseIcon />
        </CloseButton>
      </DialogTitle>
      <DialogContent className={classes.body}>
        <div className={classes.inputContainer}>
          <div className={classes.inputLabelContainer}>
            <div className={classes.inputLabel}>
              <BodyText weight="semibold" size="sm">
                General Description
              </BodyText>
              <BodyText size="sm">{biomarker?.attributes.short_name}</BodyText>
            </div>
            {formValues.description !== biomarker?.attributes.description && (
              <ResetToDefaultButton
                onResetToDefault={() => handleResetToDefault("description")}
              />
            )}
          </div>
          <TextField
            control={control}
            label="Description"
            name="description"
            placeholder="Describe the marker..."
            variant="textarea"
          />
        </div>
        <BodyText weight="semibold" size="sm">
          If your client’s biomarker value is out of range, we also include the
          following:
        </BodyText>
        <div className={classes.inputContainer}>
          <div className={classes.inputLabelContainer}>
            <div className={classes.inputLabel}>
              <BodyText weight="semibold" size="sm">
                <span className={classes.highLowDescriptionArrow}>▼</span> Low
                Description
              </BodyText>
              <BodyText size="sm">{biomarker?.attributes.short_name}</BodyText>
            </div>
            {formValues.low_description !==
              biomarker?.attributes.low_description && (
              <ResetToDefaultButton
                onResetToDefault={() => handleResetToDefault("low_description")}
              />
            )}
          </div>
          <TextField
            control={control}
            label="Description"
            name="low_description"
            placeholder="Describe the marker..."
            variant="textarea"
          />
        </div>
        <div className={classes.inputContainer}>
          <div className={classes.inputLabelContainer}>
            <div className={classes.inputLabel}>
              <BodyText weight="semibold" size="sm">
                <span className={classes.highLowDescriptionArrow}>▲</span> High
                Description
              </BodyText>
              <BodyText size="sm">{biomarker?.attributes.short_name}</BodyText>
            </div>
            {formValues.high_description !==
              biomarker?.attributes.high_description && (
              <ResetToDefaultButton
                onResetToDefault={() =>
                  handleResetToDefault("high_description")
                }
              />
            )}
          </div>
          <TextField
            control={control}
            label="Description"
            name="high_description"
            placeholder="Describe the marker..."
            variant="textarea"
          />
        </div>
      </DialogContent>
      <DialogActions className={classes.footer}>
        {isPatchLoading && <CircularProgress size={16} />}
        <DesignSystemButton
          color="primary"
          loading={false}
          onClick={submitForm}
          className={classes.saveButton}
        >
          Done
        </DesignSystemButton>
      </DialogActions>
    </Dialog>
  )
}

const ModifyBiomarkerCustomDescriptionNiceModal =
  NiceModal.create<ModifyBiomarkerCustomDescriptionModalProps>((props) => {
    return (
      <ModifyBiomarkerCustomDescriptionModal
        key={props.biomarkerCustomDescription.id}
        {...props}
      />
    )
  })

export default ModifyBiomarkerCustomDescriptionNiceModal
