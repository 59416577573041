import { useState } from "react"

import {
  Button,
  Card,
  CardContent,
  Chip,
  Grid,
  IconButton,
  makeStyles,
} from "@material-ui/core"
import ExploreIcon from "@material-ui/icons/Explore"

import DeleteIcon from "app/assets/icons/bundles/grey-delete-action.svg"
import EmailIcon from "app/assets/icons/envelope/email-teal.svg"
import PhoneIcon from "app/assets/icons/phone-teal.svg"
import BodyText from "app/components/design-system/BodyText"
import resourceRequest from "app/swr/utils/resource-request"
import { colors, rupaTeal } from "app/theme"

import { ServiceModal } from "./ServiceModal"
import { PractitionerProfileService } from "./types"
import {
  CONNECTION_TYPE_EMAIL,
  CONNECTION_TYPE_PHONE_NUMBER,
  CONNECTION_TYPE_WEBSITE_URL,
  getConnectionType,
} from "./utils"

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    marginTop: 34,
    borderRadius: 8,
    maxWidth: 275,
    minWidth: 275,
    minHeight: 201,
  },
  contentContainer: {
    paddingBottom: 16,
  },
  customizeActionButton: {
    border: `1px solid ${colors.blueGray[300]}`,
  },
  gridItem: {
    flex: 1,
  },
  serviceActionButton: {
    color: theme.palette.primary.main,
  },
  primaryMain: {
    color: theme.palette.primary.main,
  },
  deleteIconButton: {
    color: "#94A3B8",
  },
  chip: {
    backgroundColor: "#E0F2FE !important",
    color: rupaTeal,
  },
  rupaTeal: {
    color: rupaTeal,
  },
  serviceTypeLabel: {
    color: colors.blueGray[400],
    margin: "7px 0",
  },
}))

export const PractitionerServicesItem = ({
  service,
  connectionTypeOptions,
  serviceTypeOptions,
  mutateServices,
  mutateProfile,
}) => {
  const classes = useStyles()

  const connectionTypeId = service?.relationships?.how_to_connect?.data?.id
  const serviceTypeId = service?.relationships?.service_type?.data?.id

  const connectionTypeOption = connectionTypeOptions.find(
    (option) => option.id === connectionTypeId
  )

  const serviceTypeOption = serviceTypeOptions.find(
    (option) => option.id === serviceTypeId
  )

  const [open, setOpen] = useState(false)

  const handleClose = () => {
    setOpen(false)
  }

  const handleDelete = async () => {
    await resourceRequest<PractitionerProfileService>({
      url: `/practitioner_profile_services/${service.id}/`,
      method: "DELETE",
    })

    mutateServices()
    mutateProfile()
    handleClose()
  }

  const connectionType = getConnectionType(
    connectionTypeId,
    connectionTypeOptions
  )

  let connectionTypeIcon
  let connectionTypeLabel = connectionTypeOption?.attributes?.field_name
  if (connectionType === CONNECTION_TYPE_EMAIL) {
    connectionTypeIcon = <img src={EmailIcon} alt="Email Icon" />
  } else if (connectionType === CONNECTION_TYPE_WEBSITE_URL) {
    connectionTypeLabel = "Website"
    connectionTypeIcon = <ExploreIcon className={classes.rupaTeal} />
  } else if (connectionType === CONNECTION_TYPE_PHONE_NUMBER) {
    connectionTypeIcon = <img src={PhoneIcon} alt="Phone Icon" />
  }

  return (
    <Card className={classes.cardContainer}>
      <CardContent className={classes.contentContainer}>
        <Grid container direction="column" spacing={1} justify="space-around">
          <Grid item container>
            <Grid item xs={11}>
              <BodyText size="md" weight="semibold">
                {service?.attributes?.name}
              </BodyText>
              <BodyText
                className={classes.serviceTypeLabel}
                size="sm"
                weight="semibold"
              >
                {serviceTypeOption?.attributes?.field_name}
              </BodyText>
            </Grid>
            <Grid item xs={1}>
              <IconButton size="small" onClick={handleDelete}>
                <img
                  className={classes.deleteIconButton}
                  src={DeleteIcon}
                  alt="Delete Icon"
                />
              </IconButton>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <BodyText size="sm">{service?.attributes?.description}</BodyText>
          </Grid>
          <Grid item xs={12}>
            {connectionTypeIcon && (
              <Chip
                className={classes.chip}
                label={connectionTypeLabel}
                icon={connectionTypeIcon}
              />
            )}
          </Grid>
          <Grid item xs={12}>
            <Button
              className={classes.customizeActionButton}
              variant="outlined"
              fullWidth
              onClick={() => setOpen(true)}
            >
              <BodyText
                size="base"
                weight="semibold"
                className={classes.primaryMain}
              >
                Customize
              </BodyText>
            </Button>
          </Grid>
        </Grid>
      </CardContent>
      <ServiceModal
        open={open}
        handleClose={handleClose}
        service={service}
        connectionTypeOptions={connectionTypeOptions}
        serviceTypeOptions={serviceTypeOptions}
        mutateServices={mutateServices}
        mutateProfile={mutateProfile}
      />
    </Card>
  )
}
