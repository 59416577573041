import { ReactElement } from "react"

import clsx from "clsx"

import { styled, Theme } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

import RightCaretIcon from "app/assets/images/right-caret-primary.svg"
import Tooltip from "app/components/Tooltip"
import BodyText from "app/components/design-system/BodyText"
import DesignSystemButton from "app/components/design-system/Button"
import { primaryColor } from "app/theme"
import { Practitioner } from "app/types"

const TooltipCTA = styled("div")({
  marginTop: 5,
  cursor: "pointer",
})

const TooltipCaret = styled("img")({
  marginLeft: 5,
  marginBottom: "-1px",
  height: 11,
})

interface StyleProps {}
const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => ({
  buttonContainer: {
    width: 60,
  },
  tooltipSmall: {
    width: "220px",
  },
  tooltip: {},
  textCenter: {
    textAlign: "center",
  },
  textPrimaryColor: {
    color: primaryColor,
  },
}))

// Only the items needed for the signing practitioner
interface LimitedSigningPractitioner {
  id: string
  email: string
  titledName: string
}

interface Props {
  canSignForOrder: boolean
  children: ReactElement
  currentPractitioner: Practitioner
  loading: boolean
  onClickResendVerificationEmail: () => void
  sendActivationLinks: () => void
  signingPractitioner?: LimitedSigningPractitioner
  resizeButton?: boolean
}

function ESignatureTooltip({
  canSignForOrder,
  children,
  currentPractitioner,
  loading,
  onClickResendVerificationEmail,
  sendActivationLinks,
  signingPractitioner,
  resizeButton = true,
}: Props) {
  const styles = useStyles()
  const currentPractitionerIsVerified = Boolean(
    currentPractitioner.user.is_verified
  )
  const signingPractitionerIsCurrentPractitioner =
    signingPractitioner?.id === currentPractitioner.id

  const childrenWrapped = (
    <div className={resizeButton ? styles.buttonContainer : undefined}>
      {children}
    </div>
  )

  // Tooltip which prevents the current practitioner from signing until they are verified.
  if (
    signingPractitionerIsCurrentPractitioner &&
    !currentPractitionerIsVerified
  ) {
    const title = (
      <>
        <BodyText size="xs" className={styles.textCenter}>
          You'll need to verify your email address before signing.
        </BodyText>
        <TooltipCTA
          onClick={(e) => {
            // stop propagation to avoid expanding parent row
            e.preventDefault()
            e.stopPropagation()

            onClickResendVerificationEmail()
          }}
        >
          <BodyText size="xs" weight="semibold" className={styles.textCenter}>
            <DesignSystemButton color="text" loading={loading}>
              <BodyText
                size="xs"
                weight="semibold"
                className={clsx(styles.textCenter, styles.textPrimaryColor)}
              >
                Resend verification email
                <TooltipCaret src={RightCaretIcon} alt="right arrow" />
              </BodyText>
            </DesignSystemButton>
          </BodyText>
        </TooltipCTA>
      </>
    )
    return (
      <Tooltip
        interactive
        arrow
        classes={{ tooltip: styles.tooltipSmall }}
        title={title}
      >
        {childrenWrapped}
      </Tooltip>
    )
  }

  // Tooltip which allows the current practitioner to send a verification email to another
  // signing practitioner.
  if (!canSignForOrder && signingPractitioner) {
    const title = (
      <>
        <BodyText size="xs" className={styles.textCenter}>
          {`${signingPractitioner.titledName} needs to log in to Rupa and sign their documents.`}
        </BodyText>
        <DesignSystemButton
          color="text"
          onClick={(e) => {
            // stop propagation to avoid expanding parent row
            e.preventDefault()
            e.stopPropagation()

            sendActivationLinks()
          }}
          loading={loading}
        >
          <BodyText
            size="xs"
            weight="semibold"
            className={clsx(styles.textCenter, styles.textPrimaryColor)}
          >
            {`Send ${signingPractitioner.email} a link to log in`}
          </BodyText>
        </DesignSystemButton>
      </>
    )
    return (
      <Tooltip
        interactive
        arrow
        classes={{ tooltip: styles.tooltip }}
        title={title}
      >
        {childrenWrapped}
      </Tooltip>
    )
  }

  // Default - return the children component with no tooltip
  return children
}

export default ESignatureTooltip
