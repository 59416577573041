import { useRouteMatch } from "react-router-dom"

import { PatientPortalPatientPaths } from "app/Routes/paths/patient-portal-paths"
import { PatientPortalUserPaths } from "app/Routes/paths/patient-portal-user-paths"
import PatientPortalBGOrders from "app/assets/images/patient-portal/gradient2.jpeg"
import Portal from "app/components/Portal"

import PatientPortalBackground from "./PatientPortalBackground"
import PatientPortalUserNavbar from "./PatientPortalUserNavbar"

const PatientPortal = (props) => {
  // We do not want to show the navbar on blood dashboards because it has its own navbar
  const isBloodDashboardMatch = useRouteMatch([
    PatientPortalPatientPaths.BLOOD_LABS,
    PatientPortalPatientPaths.BLOOD_LABS_SNAPSHOT,
  ])
  const isBloodDashboardUserMatch = useRouteMatch([
    PatientPortalUserPaths.BLOOD_LABS,
    PatientPortalUserPaths.BLOOD_LABS_SNAPSHOT,
  ])
  if (isBloodDashboardMatch || isBloodDashboardUserMatch) {
    return (
      <div className="w-full min-h-screen bg-gray-100">{props.children}</div>
    )
  }

  return (
    <>
      <Portal id="patient-portal-background">
        <PatientPortalBackground image={PatientPortalBGOrders} />
      </Portal>
      <div className="w-full gap-1 min-h-screen p-3 flex flex-col max-w-screen-2xl self-center sm:flex-row sm:p-6 sm:gap-6">
        {
          // Used to mount the backgrounds set via <Portal>
          <div id="patient-portal-background" />
        }
        <PatientPortalUserNavbar />
        <div className="flex-1 z-10 overflow-hidden">{props.children}</div>
      </div>
    </>
  )
}

export default PatientPortal
