import { KIT_STATUS } from "app/constants.typed"

import { StatusConfiguration } from "../../types"
import { StatusConfigurationProps } from "../../types/dashboard-types"
import { PROGRESS_PERCENTAGES } from "../consts"

const submittedStatusConfiguration: StatusConfiguration<StatusConfigurationProps> =
  {
    when: { kitStatus: KIT_STATUS.SUBMITTED },
    progressPercentage: PROGRESS_PERCENTAGES.AWAITING_PATIENT_CHECKOUT,
    title: "Pending Patient Payment",
  }

export default submittedStatusConfiguration
