import { Container as MuiContainer, styled } from "@material-ui/core"

import RupaHealthLogoDark from "app/assets/images/logos/rupa-health-dark.svg"
import BodyText from "app/components/design-system/BodyText"
import DisplayText from "app/components/design-system/DisplayText"
import { colors, navy } from "app/theme"

const Container = styled("div")({
  background: navy,
})

const ContentContainer = styled(MuiContainer)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  paddingTop: 32,
  paddingBottom: 32,
  [theme.breakpoints.up("sm")]: {
    flexDirection: "row",
  },
}))

const TextContainer = styled("div")(({ theme }) => ({
  flex: 1,
  order: 1,
  padding: "0 40px",
  [theme.breakpoints.up("sm")]: {
    order: 0,
    padding: 0,
  },
}))

const Title = styled(DisplayText)(({ theme }) => ({
  color: "white",
  textAlign: "center",
  [theme.breakpoints.up("sm")]: {
    textAlign: "left",
  },
}))

const Subtitle = styled(BodyText)(({ theme }) => ({
  color: "white",
  textAlign: "center",
  [theme.breakpoints.up("sm")]: {
    textAlign: "left",
  },
}))

const Logo = styled("img")(({ theme }) => ({
  display: "block",
  maxWidth: 235,
  margin: "auto",
  height: "100%",
}))

const LogoContainer = styled("div")(({ theme }) => ({
  order: 0,
  borderBottom: `1px solid ${colors.blueGray[500]}`,
  paddingBottom: 22,
  marginBottom: 22,
  [theme.breakpoints.up("sm")]: {
    order: 1,
    borderBottom: 0,
    paddingBottom: 0,
    marginBottom: 0,
  },
}))

interface Props {
  subtitle?: string
  patient?: { first_name: string }
}

export default function NavBar({ subtitle, patient }: Props) {
  return (
    <Container>
      <ContentContainer>
        <TextContainer>
          {patient?.first_name && (
            <Title size="2xl">Welcome, {patient.first_name}!</Title>
          )}
          {subtitle && <Subtitle size="md">{subtitle}</Subtitle>}
        </TextContainer>
        <LogoContainer>
          <Logo src={RupaHealthLogoDark} alt={"Rupa Health Labs"} />
        </LogoContainer>
      </ContentContainer>
    </Container>
  )
}
