import { API } from "app/api"
import { handleApiError } from "app/utils"

export const GET_BANK_ACCOUNTS_REQUEST =
  "[BANK ACCOUNT] GET BANK ACCOUNTS REQUEST"
export const GET_BANK_ACCOUNTS_SUCCESS =
  "[BANK ACCOUNT] GET BANK ACCOUNTS SUCCESS"
export const GET_BANK_ACCOUNTS_FAILURE =
  "[BANK ACCOUNT] GET BANK ACCOUNTS FAILURE"

export const ADD_BANK_ACCOUNT_REQUEST =
  "[BANK_ACCOUNT] ADD BANK ACCOUNT REQUEST"
export const ADD_BANK_ACCOUNT_SUCCESS =
  "[BANK_ACCOUNT] ADD BANK ACCOUNT SUCCESS"
export const ADD_BANK_ACCOUNT_FAILURE =
  "[BANK_ACCOUNT] ADD BANK ACCOUNT FAILURE"

export const DELETE_BANK_ACCOUNT = "[BANK ACCOUNT] DELETE BANK ACCOUNT"

export const UPDATE_BANK_ACCOUNT = "[BANK ACCOUNT] UPDATE BANK ACCOUNT"

export function getBankAccounts() {
  return (dispatch) => {
    dispatch({ type: GET_BANK_ACCOUNTS_REQUEST })

    return API.BankAccount.getList()
      .then((response) =>
        dispatch({
          type: GET_BANK_ACCOUNTS_SUCCESS,
          payload: response.data,
        })
      )
      .catch((error) => {
        dispatch({ type: GET_BANK_ACCOUNTS_FAILURE })
        dispatch(handleApiError(error))
      })
  }
}

export function addBankAccount(plaidLinkToken, accountId) {
  return async (dispatch, getState) => {
    dispatch({ type: ADD_BANK_ACCOUNT_REQUEST })

    try {
      const response = await API.BankAccount.post(plaidLinkToken, accountId)

      dispatch({
        type: ADD_BANK_ACCOUNT_SUCCESS,
        payload: response.data,
      })
    } catch (error) {
      dispatch({ type: ADD_BANK_ACCOUNT_FAILURE })
      dispatch(handleApiError(error))

      // re-throw to catch at the next level
      throw error
    }
  }
}

export function deleteBankAccount(id) {
  return async (dispatch, getState) => {
    try {
      await API.BankAccount.delete(id)

      dispatch({
        type: DELETE_BANK_ACCOUNT,
        payload: id,
      })
    } catch (error) {
      dispatch(handleApiError(error))

      // re-throw to catch at the next level
      throw error
    }
  }
}

export function shareBankAccount(id, is_shared_with_clinic) {
  return async (dispatch, getState) => {
    try {
      const response = await API.BankAccount.patch(id, {
        is_shared_with_clinic,
      })

      dispatch({
        type: UPDATE_BANK_ACCOUNT,
        payload: response.data,
      })
    } catch (error) {
      dispatch(handleApiError(error))
    }
  }
}
