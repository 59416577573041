import { useParams } from "react-router-dom"

import { Theme } from "@material-ui/core"

import BloodLabDashboardsPatientHero from "app/main/blood-lab-dashboards/BloodLabDashboardsPatientPreview/BloodLabDashboardsPatientHero"
import BloodLabsDashboardsPatientReportContainer from "app/main/blood-lab-dashboards/BloodLabDashboardsPatientPreview/BloodLabDashboardsPatientReportContainer"
import PatientPortalBloodDashboardBackground from "app/patient-portal/PatientPortalBloodDashboardBackground"
import makeAppStyles from "app/utils/makeAppStyles"

import BloodLabDashboardNavBar from "../../../main/blood-lab-dashboards/BloodLabDashboardsPatientPreview/BloodLabDashboardNavBar"
import useDiscreteResults from "../hooks/use-discrete-results"

const HEIGHT_OF_NAVBAR = 80

const useStyles = makeAppStyles((theme: Theme) => ({
  container: {
    width: "100%",
    maxWidth: 1500,
    display: "flex",
    flexDirection: "column",
    gap: 20,
    paddingTop: HEIGHT_OF_NAVBAR + 57, // 57px is to offset the padding of the hero component
    paddingLeft: 42,
    paddingRight: 42,
    minHeight: "100vh",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 22,
      paddingRight: 22,
    },
  },
}))

const PatientPortalBloodLabDashboard = () => {
  const classes = useStyles()

  const { orderedResultId } = useParams<{
    orderedResultId: string
  }>()

  const {
    orderedResult,
    discreteResults,
    missingBiomarkers,
    biomarkers,
    getAbnormalDiscreteResults,
    getMatchingDiscreteResultByBiomarkerId,
    getMatchingOptimalRangeByBiomarkerId,
    bodySystems,
    patient,
    practitioner,
    clinic,
    isLoading,
  } = useDiscreteResults(orderedResultId)

  const abnormalDiscreteResults = getAbnormalDiscreteResults()

  return (
    <div className="fs-unmask">
      <BloodLabDashboardNavBar />
      <PatientPortalBloodDashboardBackground>
        <div className={classes.container}>
          <BloodLabDashboardsPatientHero
            orderedResult={orderedResult}
            patient={patient}
            missingBiomarkers={missingBiomarkers}
            clinicLogoUrl={clinic?.attributes.logo || ""}
            practitionerTitledName={
              practitioner?.attributes.titled_full_name || ""
            }
            isLoading={isLoading}
            isPatientPreview={false}
          />
          <BloodLabsDashboardsPatientReportContainer
            orderedResult={orderedResult}
            discreteResults={discreteResults}
            abnormalDiscreteResults={abnormalDiscreteResults}
            bodySystems={bodySystems}
            isLoading={isLoading}
            isPatientPreview={false}
            clinic={clinic}
            practitioner={practitioner}
            biomarkers={biomarkers}
            getAbnormalDiscreteResults={getAbnormalDiscreteResults}
            getMatchingDiscreteResultByBiomarkerId={
              getMatchingDiscreteResultByBiomarkerId
            }
            getMatchingOptimalRangeByBiomarkerId={
              getMatchingOptimalRangeByBiomarkerId
            }
          />
        </div>
      </PatientPortalBloodDashboardBackground>
    </div>
  )
}

export default PatientPortalBloodLabDashboard
