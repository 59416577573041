import { useCallback } from "react"

import { OptionType } from "app/types"

import { FilterConfig, FiltersMap, OnSelectFilterOption } from "../types"

interface Props {
  filterConfig: FilterConfig
  filtersMap: FiltersMap
  onSelectFilterOption: OnSelectFilterOption
}

export default function useFilterConfigUtilities({
  filterConfig,
  filtersMap,
  onSelectFilterOption,
}: Props) {
  const getIsSelected = useCallback(
    (option: OptionType) =>
      filtersMap[filterConfig.filterName]?.has(option.value),
    [filtersMap[filterConfig.filterName]]
  )

  const onSelect = useCallback(
    (option: OptionType) =>
      onSelectFilterOption(
        filterConfig.filterName,
        option,
        filterConfig.isRadio ?? false
      ),
    [filterConfig]
  )

  const renderLabel = useCallback(
    (option: OptionType) => filterConfig.renderLabel?.(option) ?? option.label,
    [filterConfig]
  )

  return {
    getIsSelected,
    onSelect,
    renderLabel,
  }
}
