import { ReactComponent as RoundedCornerRightPrimary } from "app/assets/images/rounded-corner-right-primary.svg"

import BodyText from "./BodyText"

interface Props {
  icon: React.ReactNode
  title: string
  text: string
  onClick: () => void
}

const LearnMoreBanner = ({ icon, title, text, onClick }: Props) => {
  return (
    <div
      className="relative w-full p-[10px] border border-indigo-200 rounded-lg bg-white flex justify-between hover:bg-sky-50 hover:border-primary transition duration-300 cursor-pointer"
      onClick={onClick}
    >
      <div className="flex gap-[10px] flex-col sm:flex-row">
        {icon}
        <div>
          <BodyText weight="semibold">{title}</BodyText>
          <BodyText>{text}</BodyText>
        </div>
      </div>

      <div className="absolute right-[10px]">
        <RoundedCornerRightPrimary />
      </div>
    </div>
  )
}

export default LearnMoreBanner
