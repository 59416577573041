import { PropsWithChildren } from "react"
import * as React from "react"

import clsx from "clsx"

import { colors, primaryColor } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"

const useStyles = makeAppStyles((theme) => ({
  button: {
    cursor: "pointer",
    color: colors.blueGray[500],
    "&:hover, &:active, &:focus": {
      color: colors.blueGray[700],
    },
    "&[disabled]": {
      color: colors.blueGray[300],
    },
  },
  buttonActive: {
    color: primaryColor,
    "&:hover, &:active, &:focus": {
      color: primaryColor,
    },
    "&[disabled]": {
      color: colors.blueGray[300],
    },
  },
}))

export type ButtonProps = PropsWithChildren<
  {
    active: boolean
    className?: string
  } & Omit<React.HTMLProps<HTMLButtonElement>, "ref">
>

export default React.forwardRef<any, ButtonProps>(function Button(
  { active, children, className, ...props },
  ref
) {
  const classes = useStyles()
  return (
    <span
      {...props}
      className={clsx(className, classes.button, {
        [classes.buttonActive]: active,
      })}
      ref={ref}
    >
      {children}
    </span>
  )
})
