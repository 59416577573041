import { pluralizeDays } from "app/main/comparison/utils"
import { LabCompany } from "app/types"

import {
  BaseCheckoutWarning,
  CheckoutWarningSeverities,
} from "./BaseCheckoutWarning"

type Props = {
  labCompany: LabCompany
}

/**
 * A warning indicating that there may be turnaround time due to the first order placed with a lab company.
 */
export const FirstOrderWithLabCompanyTurnaroundCheckoutWarning = ({
  labCompany,
}: Props) => {
  return (
    <BaseCheckoutWarning
      severity={CheckoutWarningSeverities.HIGHLIGHT}
      collapsible={false}
      title={"First Order with Lab Company"}
    >
      This is your first order with {labCompany.name}! We typically see a
      turnaround time of up to{" "}
      {labCompany.estimated_registration_turnaround_time_days}{" "}
      {pluralizeDays(labCompany.estimated_registration_turnaround_time_days!)}{" "}
      for practitioners new to the lab. We'll send you a message when the
      patient's kit ships. Thanks for your patience!
    </BaseCheckoutWarning>
  )
}
