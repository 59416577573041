import React, { useMemo } from "react"

import BiomarkerValueSection from "app/main/blood-lab-dashboards/BloodLabDashboardsSnapshotModal/BloodLabDashboardsSnapshotBiomarkerValueSection"
import NumericBiomarkerGraphic from "app/main/blood-lab-dashboards/NumericBiomarkerGraphic/NumericBiomarkerGraphic"
import { getValueForNumericBiomarkerGraphicFromBloodReportResult } from "app/main/blood-lab-dashboards/NumericBiomarkerGraphic/utils"
import { parseStringValueToFloat } from "app/main/blood-lab-dashboards/utils"
import useCachedResource from "app/swr/hooks/use-cached-resource"
import { Biomarker } from "types/biomarker"
import { BloodReportResult } from "types/blood-report-result"

export const PdfBiomarkerRow: React.FC<{
  bloodReportResult: BloodReportResult
}> = ({ bloodReportResult }) => {
  const biomarker = useCachedResource<Biomarker>(
    bloodReportResult.relationships.biomarker.data
  )

  const showNumericRangeBar = useMemo(() => {
    return (
      (bloodReportResult?.attributes.standard_range_min ||
        bloodReportResult?.attributes.standard_range_max) &&
      !Number.isNaN(parseStringValueToFloat(bloodReportResult.attributes.value))
    )
  }, [bloodReportResult])

  if (!biomarker) return null

  const biomarkerName =
    bloodReportResult.attributes.biomarker_short_name ===
    bloodReportResult.attributes.biomarker_long_name
      ? bloodReportResult.attributes.biomarker_short_name
      : `${bloodReportResult.attributes.biomarker_long_name} (${bloodReportResult.attributes.biomarker_short_name})`

  return (
    <div className="flex break-inside-avoid h-12">
      <div className="w-[32%] font-medium align-middle flex content-center items-center text-navy text-sm">
        {biomarkerName}
      </div>
      <div className="w-1/4 align-middle flex content-center items-center">
        {biomarker && (
          <BiomarkerValueSection
            alternateValue={bloodReportResult.attributes.alternate_value}
            value={bloodReportResult.attributes.display_value || ""}
            unit={bloodReportResult.attributes.unit || ""}
            status={bloodReportResult.attributes.status}
            small={true}
          />
        )}
      </div>
      <div className="w-[43%] align-middle flex content-center items-center">
        {showNumericRangeBar && (
          <NumericBiomarkerGraphic
            normalMax={bloodReportResult.attributes.standard_range_max}
            normalMin={bloodReportResult.attributes.standard_range_min}
            optimalMax={bloodReportResult?.attributes.optimal_range_max}
            optimalMin={bloodReportResult?.attributes.optimal_range_min}
            value={getValueForNumericBiomarkerGraphicFromBloodReportResult(
              bloodReportResult
            )}
            status={bloodReportResult?.attributes.status}
            highlightCenter={true}
            singleLabel={true}
            isCondensed={true}
            hideTooltip={true}
          />
        )}
      </div>
    </div>
  )
}
