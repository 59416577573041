import { Divider as MuiDivider } from "@material-ui/core"

import { colors } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"

const useStyles = makeAppStyles({
  divider: {
    margin: "8px 0",
    background: colors.blueGray[200],
    opacity: 0.12,
  },
})

const NavBarDivider = () => {
  const styles = useStyles()

  return <MuiDivider className={styles.divider} />
}

export default NavBarDivider
