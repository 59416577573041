import { makeStyles, Grid, Box } from "@material-ui/core"

import BodyText from "app/components/design-system/BodyText"
import { FormFieldLabel } from "app/components/forms/fields"
import useCollectionSWR from "app/swr/hooks/use-collection-swr"
import useResourceSWR from "app/swr/hooks/use-resource-swr"
import { ResourceCollection } from "app/swr/types"
import { colors } from "app/theme"

import { ControlledTextField } from "../patient-checkout/fields"
import MultiCheckboxDropdown from "./form-components/MultiCheckboxDropdown"
import { WebflowFieldOption, WebflowFieldOptionIdentifier } from "./types"

const useStyles = makeStyles((theme) => ({
  requiredMarker: {
    color: colors.red[600],
  },
  headerText: {
    marginBottom: "34px",
    [theme.breakpoints.down("md")]: {
      marginBottom: theme.spacing(0),
    },
    padding: 0,
  },
  field: {
    marginBottom: "18px",
  },
  textboxField: {
    minHeight: "92px",
  },
  textAreaField: {
    lineHeight: "1.3",
    padding: 0,
  },
}))

const ConditionDescription = ({ condition }) => {
  const classes = useStyles()
  const { data: conditionDescription } = useResourceSWR<WebflowFieldOption>(
    `/webflow_field_options/${condition.id}`
  )

  return conditionDescription ? (
    <Grid item xs={12} className={classes.field}>
      <div className="mb-1 fs-unmask">
        <FormFieldLabel
          label={
            <BodyText
              weight="semibold"
              size="base"
              style={{ float: "left", marginRight: 4 }}
            >
              How have you helped clients with{" "}
              {conditionDescription.attributes?.field_name}?
            </BodyText>
          }
          required
        />
      </div>
      <ControlledTextField
        name={"condition_treatment_explanations-" + conditionDescription.id}
        defaultValue=""
        placeholder="Briefly describe your approach and share specific examples of how you've successfully addressed this condition for your clients. This will help prospective clients understand your expertise and build trust in your services."
        required
        multiline
        rows={4}
        InputProps={{
          className: classes.textAreaField,
        }}
      />
    </Grid>
  ) : (
    <></>
  )
}

const PractitionerProfileService = ({ practitionerProfile }) => {
  const classes = useStyles()
  const { data: allSpecialties } = useCollectionSWR<
    ResourceCollection<WebflowFieldOptionIdentifier>
  >("/webflow_field_options/", {
    params: {
      "filter[field_slug]": "practitioner-specialties",
    },
  })

  const { data: allConditions } = useCollectionSWR<
    ResourceCollection<WebflowFieldOptionIdentifier>
  >("/webflow_field_options/", {
    params: {
      "filter[field_slug]": "practitioner-conditions",
    },
  })

  return (
    <Grid container>
      <Grid item xs={12}>
        <div>
          <BodyText size="lg" weight="semibold" className={classes.headerText}>
            Service
          </BodyText>
        </div>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} className={classes.field}>
          <Box>
            <FormFieldLabel
              label={
                <BodyText
                  weight="semibold"
                  size="base"
                  style={{ float: "left", marginRight: 4 }}
                >
                  What services do you offer in your practice?
                </BodyText>
              }
              required
            />
            <MultiCheckboxDropdown
              fieldName="specialties"
              displayField="services"
              choices={allSpecialties || []}
              numberSelected={
                practitionerProfile?.relationships?.specialties?.data.length
              }
            />
          </Box>
        </Grid>
        <Grid item xs={12} className={classes.field}>
          <FormFieldLabel
            label={
              <BodyText
                weight="semibold"
                size="base"
                style={{ float: "left", marginRight: 4 }}
              >
                What do you typically help your patients or clients with?
              </BodyText>
            }
            required
          />
          <MultiCheckboxDropdown
            fieldName="conditions_treated"
            displayField="conditions"
            choices={allConditions || []}
            numberSelected={
              practitionerProfile?.relationships?.conditions_treated?.data
                .length
            }
          />
        </Grid>

        {practitionerProfile?.relationships?.conditions_treated?.data?.map(
          (condition) => (
            <ConditionDescription key={condition.id} condition={condition} />
          )
        )}
      </Grid>
    </Grid>
  )
}

export default PractitionerProfileService
