import { useEffect } from "react"

import { debounce } from "lodash"
import { FieldErrors, useForm } from "react-hook-form"
import { z } from "zod"

import { zodResolver } from "@hookform/resolvers/zod"

import usePatchInterpretationTask, {
  TaskEditableAttributes,
} from "app/results-summary/hooks/use-patch-interpretation-task"
import { getIdentifier } from "app/swr/helpers/resource"
import { ResultsInterpretationTask } from "types/results-interpretation"

/**
 * The schema for the biomarker attributes that can be edited.
 */
export const TaskEditableAttributesSchema = z.object({
  name: z.string().min(1, "Required"),
  content_instructions: z.array(z.any()).optional(),
})

/**
 * Sets up a form hook to edit a biomarker. The form is debounced to avoid sending too many requests to the server.
 *
 * @param task the biomarker to edit
 * @returns a form hook to edit the biomarker
 */
export default function useTaskEditorForm(task: ResultsInterpretationTask) {
  const patchInterpretationTask = usePatchInterpretationTask(
    getIdentifier(task)
  )
  const methods = useForm<TaskEditableAttributes>({
    criteriaMode: "all",
    defaultValues: task.attributes,
    mode: "onSubmit",
    resolver: zodResolver(TaskEditableAttributesSchema),
    reValidateMode: "onSubmit",
    shouldFocusError: false,
    shouldUnregister: true,
  })

  const { handleSubmit, watch } = methods

  /**
   * Debounce the form submission on change to avoid sending too many requests to the server.
   */
  useEffect(() => {
    /**
     * On valid form data, send the data to the server.
     */
    const onValid = patchInterpretationTask

    /**
     * On invalid form data, only send the valid data to the server.
     * @param data the data received during the change event
     */
    const onInvalid =
      (data: TaskEditableAttributes) =>
      (errors: FieldErrors<TaskEditableAttributes>) => {
        const validData = Object.fromEntries(
          Object.entries(data).filter(([key]) => !errors[key])
        ) as TaskEditableAttributes

        patchInterpretationTask(validData)
      }

    const debounceOnSubmit = debounce(
      (data) => handleSubmit(onValid, onInvalid(data))(),
      600
    )
    const subscription = watch(debounceOnSubmit)

    return () => {
      // Rather than cancel, we flush, as this ensures the save still goes through.
      debounceOnSubmit.flush()

      subscription.unsubscribe()
    }
  }, [handleSubmit, watch])

  return methods
}
