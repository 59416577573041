import { useState } from "react"

import { ReactComponent as PhlebotomistsIcon } from "app/assets/icons/phlebotomists.svg"
import { ReactComponent as InfoCircleIcon } from "app/assets/images/info-circle-dark.svg"
import Tooltip from "app/components/Tooltip"
import {
  ComparisonColumnProps,
  ComparisonRow,
  EmptyComparisonValue,
} from "app/main/comparison/components/ComparisonLabTests/components"
import useAnyComparedLabTestHasProperty from "app/main/comparison/hooks/use-any-compared-lab-test-has-property"
import { getLabCompanyText } from "app/phlebotomyUtils"
import { primaryColor } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"

const useColumnStyles = makeAppStyles({
  tooltipIcon: {
    width: 15,
    height: 15,
    fill: primaryColor,
    verticalAlign: "middle",
  },
})

const PhlebotomyColumn = ({ labTest }: ComparisonColumnProps) => {
  const [isTooltipOpen, setIsTooltipOpen] = useState(false)
  const classes = useColumnStyles()
  if (!labTest) {
    return <EmptyComparisonValue />
  }
  const isPhlebotomyRequired = labTest.lab_test_types.some(
    (type) => type.phlebotomy_required
  )
  if (!isPhlebotomyRequired) {
    return <EmptyComparisonValue />
  }
  const { columnNode, tooltipNode } = getLabCompanyText(labTest.lab_company.key)
  return (
    <>
      {columnNode}{" "}
      {!!tooltipNode && (
        <Tooltip
          arrow
          interactive
          open={isTooltipOpen}
          onClose={() => setIsTooltipOpen(false)}
          onOpen={() => setIsTooltipOpen(true)}
          placement="bottom"
          title={tooltipNode}
        >
          <span>
            <InfoCircleIcon
              className={classes.tooltipIcon}
              viewBox="0 0 15 15"
            />
          </span>
        </Tooltip>
      )}
    </>
  )
}

export default function ComparisonPhlebotomyRow() {
  const hasPhlebotomyRequired = useAnyComparedLabTestHasProperty((labTest) =>
    labTest.lab_test_types.some((type) => type.phlebotomy_required)
  )
  if (!hasPhlebotomyRequired) {
    return null
  }
  return (
    <ComparisonRow
      Column={PhlebotomyColumn}
      headerIcon={<PhlebotomistsIcon viewBox="0 0 17 17" />}
      headerLabel="Phlebotomy"
    />
  )
}
