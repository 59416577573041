import { useMemo } from "react"

import useAppSelector from "app/hooks/useAppSelector"

export default function useFiltersById() {
  const healthCategories = useAppSelector(
    (state) => state.healthCategory.sortedByName
  )
  const labCompanies = useAppSelector((state) => state.labCompany.list)
  const labTestTypes = useAppSelector((state) => state.labTestType.list)

  const filtersById = useMemo(() => {
    if (!(healthCategories && labCompanies && labTestTypes)) {
      return null
    }

    return Object.fromEntries(
      [...healthCategories, ...labCompanies, ...labTestTypes].map((object) => [
        object.id,
        object.name,
      ])
    )
  }, [healthCategories, labCompanies, labTestTypes])

  return filtersById
}
