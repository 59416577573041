import BodyText from "app/components/design-system/BodyText"
import { colors } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"

const useStyles = makeAppStyles((theme) => ({
  selfOrderOnlyPill: {
    alignItems: "center",
    backgroundColor: colors.blueGray[500],
    borderRadius: 27,
    display: "inline-flex",
    fontSize: 13,
    fontWeight: 600,
    justifyContent: "center",
    marginTop: 4,
    marginRight: 3,
    maxHeight: 23,
    padding: "6px 13px",
  },
  selfOrderOnlyPillText: {
    color: "white",
  },
}))

function SelfOrderOnlyPill() {
  const classes = useStyles()

  return (
    <div className={classes.selfOrderOnlyPill}>
      <BodyText className={classes.selfOrderOnlyPillText} size="xs">
        Self-Order Only
      </BodyText>
    </div>
  )
}

export default SelfOrderOnlyPill
