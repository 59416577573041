import { ORDER_STATUS, ORDERED_TEST_STATUS } from "app/constants.typed"
import formatDateForHuman from "app/utils/format-date-for-human"

import { StatusConfiguration } from "../../types"
import { StatusConfigurationProps } from "../../types/patient-orders-types"
import { OrderedTestStatuses } from "../../types/types"
import { PROGRESS_PERCENTAGES } from "../consts"

const SubTitle = ({ orderedTest }: StatusConfigurationProps) =>
  orderedTest.date_canceled
    ? `This test was canceled on ${formatDateForHuman(
        orderedTest.date_canceled
      )}`
    : ""

const Title = ({ order }: StatusConfigurationProps) => {
  return order.status === ORDER_STATUS.CANCELED
    ? "Order Canceled"
    : "Test Canceled"
}

const LastUpdatedAt = ({ orderedTest }: StatusConfigurationProps) => {
  return orderedTest.date_canceled
    ? formatDateForHuman(orderedTest.date_canceled)
    : ""
}

const canceledStatusConfiguration: StatusConfiguration<StatusConfigurationProps> =
  {
    when: ({ orderStatus, orderedTestStatus }: OrderedTestStatuses) =>
      orderStatus === ORDER_STATUS.CANCELED ||
      orderedTestStatus === ORDERED_TEST_STATUS.CANCELED,
    progressPercentage: PROGRESS_PERCENTAGES.CANCELED,
    title: Title,
    subTitle: SubTitle,
    lastUpdatedAt: LastUpdatedAt,
  }

export default canceledStatusConfiguration
