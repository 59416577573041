import clsx from "clsx"

import { makeStyles } from "@material-ui/core"

import MailIcon from "app/assets/icons/envelope/mail.svg"
import PhoneIcon from "app/assets/icons/phone.svg"
import RightCaretIcon from "app/assets/images/right-caret.svg"
import BodyText from "app/components/design-system/BodyText"
import Button from "app/components/design-system/Button"
import { Patient } from "app/types"
import { formatPhone } from "app/utils"

interface Props {
  patient: Patient
  onEditClick: () => void
}

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexFlow: "column",

    [theme.breakpoints.up("sm")]: {
      flexFlow: "row",
    },
  },
  icon: {
    marginRight: 4,
  },
  row: {
    display: "flex",
    alignItems: "center",

    [theme.breakpoints.up("sm")]: {
      "&:first-child": {
        marginRight: 8,
      },
    },
  },
  phone: {
    display: "flex",
    alignItems: "row",
    marginRight: 4,
  },
}))

export default function PatientDetails({ patient, onEditClick }: Props) {
  const { container, icon, row, phone } = useStyles()

  return (
    <div className={clsx(container, "fs-exclude")}>
      <div className={row}>
        <img src={MailIcon} alt="Email" className={icon} />
        <BodyText size="sm" weight="semibold">
          {patient.user.email}
        </BodyText>
      </div>
      <div className={row}>
        {patient.phone_number && (
          <div className={phone}>
            <img src={PhoneIcon} alt="Phone" className="mr-1" />
            <BodyText size="sm" weight="semibold">
              {formatPhone(patient.phone_number)}
            </BodyText>
          </div>
        )}
        <Button color="text" onClick={onEditClick} endIcon={RightCaretIcon}>
          Edit
        </Button>
      </div>
    </div>
  )
}
