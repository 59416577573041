import { useModal } from "@ebay/nice-modal-react"

import useEventCallback from "app/hooks/use-event-callback"
import { BloodLabDashboardsSnapshotModal } from "app/main/blood-lab-dashboards/BloodLabDashboardsSnapshotModal/BloodLabDashboardsSnapshotModal"
import { UserResult } from "types/user-result"

export function useBloodReportModal(
  userResult?: UserResult,
  date_results_sent_to_patient?: string
) {
  const bloodReportModal = useModal(BloodLabDashboardsSnapshotModal)
  const snapshot =
    userResult?.relationships?.blood_lab_dashboard_report_snapshots?.data?.[0]

  const onModalOpen = useEventCallback(async () => {
    if (snapshot) {
      bloodReportModal.show({
        onClose: () => {
          bloodReportModal.remove()
        },
        snapshotId: snapshot.id,
        kitSentToPatient: Boolean(date_results_sent_to_patient),
      })
    }
  })
  return { onModalOpen }
}
