import { useContext } from "react"

import { Table } from "@tanstack/react-table"

import { colors } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"
import { DashboardOrder } from "types/dashboard/dashboard-order"

import TableRendererContext from "../contexts/TableRendererContext"

const useStyles = makeAppStyles(() => ({
  root: {
    display: "flex",
  },
  resultsNotificationCount: {
    background: colors.emerald[100],
    color: colors.emerald[600],
    borderRadius: 33,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "0px 12px",
    marginLeft: 8,
    fontWeight: 600,
  },
}))

export default function ResultsInTabLabel({
  table: _table,
}: {
  table: Table<DashboardOrder>
}) {
  const classes = useStyles()
  const { unreadResults } = useContext(TableRendererContext)
  return (
    <span className={classes.root}>
      Results In{" "}
      {unreadResults.count ? (
        <span
          className={classes.resultsNotificationCount}
          data-cy="unread-notification-count"
        >
          {unreadResults.count}
        </span>
      ) : null}
    </span>
  )
}
