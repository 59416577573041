import { useEffect, useMemo, useState } from "react"

import { sum, max, min } from "lodash"

import NiceModal, { muiDialog, useModal } from "@ebay/nice-modal-react"
import { Dialog } from "@material-ui/core"

import useAppSelector from "app/hooks/useAppSelector"
import {
  ComboGroupEvents,
  ComboGroupLabTestTypes,
  ComboGroupConfigurationPayload,
} from "app/main/combo-groups/constants"
import { trackEventWithProperties } from "app/services/segment.typed"
import useCachedCollection from "app/swr/hooks/use-cached-collection"
import useCachedResource from "app/swr/hooks/use-cached-resource"
import { ComboGroupOption } from "types/combo-group-option"
import { LabTest } from "types/lab-test"

import useConfirmationModal from "../../generic/hooks/use-confirmation-modal"
import useComboGroupModalResources from "../hooks/use-combo-group-modal-resources"
import ComboGroupModalBody from "./ComboGroupModalBody"
import { ComboGroupModalHeader } from "./ComboGroupModalHeader"

export interface ComboGroupConfigurationModalProps {
  updating: boolean
  comboGroupId: string
  initialTargetId: string
  initialOptionIds: string[]
  initialAddOnIds: string[]
  onAddToCart: (payload: ComboGroupConfigurationPayload) => void
}

const ComboGroupConfigurationModal = ({
  updating,
  comboGroupId,
  initialTargetId,
  initialOptionIds,
  initialAddOnIds,
  onAddToCart,
}: ComboGroupConfigurationModalProps) => {
  const modal = useModal()
  const confirmationModal = useConfirmationModal()
  const muiDialogControl = muiDialog(modal)
  const [selectedTargetId, setSelectedTargetId] = useState(initialTargetId)
  const [selectedOptionIds, setSelectedOptionIds] = useState(initialOptionIds)
  const [selectedAddOnIds, setSelectedAddOnIds] = useState(initialAddOnIds)

  const practitioner = useAppSelector(({ practitioner }) => practitioner)

  const { comboGroupSwr } = useComboGroupModalResources(comboGroupId)
  const { data: comboGroup, isLoading } = comboGroupSwr
  const currentTarget = useCachedResource<LabTest>({
    type: "lab_test",
    id: selectedTargetId,
  })

  const options = useCachedCollection<ComboGroupOption>(
    comboGroup?.relationships.options.data
  )
  const targets = useCachedCollection<LabTest>(
    comboGroup?.relationships.lab_tests.data
  )
  const availableTargets = targets.filter(
    (target) => target.attributes.is_available
  )

  const onAddComboTest = (labTestId: string) => {
    const newOption = options.find(
      (option) => option.relationships.lab_test.data.id === labTestId
    )
    setSelectedOptionIds([...selectedOptionIds, newOption!.id])
    trackEventWithProperties(
      ComboGroupEvents.COMBO_GROUP_ADD_TO_COMBO_CLICKED,
      {
        comboGroupLabTestId: initialTargetId,
        labTestId,
        practitionerId: practitioner.id,
        type: ComboGroupLabTestTypes.COMBO_GROUP_OPTION,
        comboGroupId,
      }
    )
  }

  const onRemoveComboTest = (labTestId: string) => {
    const optionToRemove = options.find(
      (option) => option.relationships.lab_test.data.id === labTestId
    )
    setSelectedOptionIds(
      selectedOptionIds.filter((id) => id !== optionToRemove!.id)
    )
    trackEventWithProperties(
      ComboGroupEvents.COMBO_GROUP_REMOVE_FROM_COMBO_CLICKED,
      {
        comboGroupLabTestId: initialTargetId,
        labTestId,
        practitionerId: practitioner.id,
        type: ComboGroupLabTestTypes.COMBO_GROUP_OPTION,
        comboGroupId,
      }
    )
  }

  const onAddAddOn = (labTestId: string) => {
    setSelectedAddOnIds([...selectedAddOnIds, labTestId])
    trackEventWithProperties(
      ComboGroupEvents.COMBO_GROUP_ADD_TO_COMBO_CLICKED,
      {
        comboGroupLabTestId: initialTargetId,
        labTestId,
        practitionerId: practitioner.id,
        type: ComboGroupLabTestTypes.ADD_ON,
        comboGroupId,
      }
    )
  }

  const onRemoveAddOn = (labTestId: string) => {
    setSelectedAddOnIds(selectedAddOnIds.filter((id) => id !== labTestId))
    trackEventWithProperties(
      ComboGroupEvents.COMBO_GROUP_REMOVE_FROM_COMBO_CLICKED,
      {
        comboGroupLabTestId: initialTargetId,
        labTestId,
        practitionerId: practitioner.id,
        type: ComboGroupLabTestTypes.ADD_ON,
        comboGroupId,
      }
    )
  }

  const matchingTarget = (numSelections: number) => {
    return availableTargets.find(
      (target) => target.attributes.combo_num_selections === numSelections
    )
  }

  useEffect(() => {
    const nextTarget = matchingTarget(selectedOptionIds.length)
    if (nextTarget) {
      setSelectedTargetId(nextTarget.id)
    }
  }, [selectedOptionIds])

  // If the new target doesn't support a selected add on, then remove it.
  useEffect(() => {
    currentTarget &&
      setSelectedAddOnIds(
        selectedAddOnIds.filter((id) =>
          currentTarget?.relationships.add_ons.data
            .map((addOn) => addOn.id)
            .includes(id)
        )
      )
  }, [currentTarget])

  const optionLabTests = useCachedCollection<LabTest>(
    options.map((option) => option.relationships.lab_test.data)
  )
  const selectedOptionLabTestIds = useMemo(() => {
    return options
      .filter((option) => selectedOptionIds.includes(option.id))
      .map((option) => option.relationships.lab_test.data.id)
  }, [selectedOptionIds, options])

  const optionsSumCost = useMemo(() => {
    return sum(
      optionLabTests
        .filter((labTest) => selectedOptionLabTestIds.includes(labTest.id))
        .map((labTest) => Number(labTest.attributes.rupa_price))
    )
  }, [selectedOptionLabTestIds, optionLabTests])

  const addOns = useCachedCollection<LabTest>(
    currentTarget?.relationships.add_ons.data
  )

  const addOnsSumCost = useMemo(() => {
    return sum(
      addOns
        .filter((labTest) => selectedAddOnIds.includes(labTest.id))
        .map((labTest) => Number(labTest.attributes.rupa_price))
    )
  }, [selectedAddOnIds, addOns])

  const validNumSelections = availableTargets.map(
    (target) => target.attributes.combo_num_selections
  )

  const validNumAddOnSelections =
    currentTarget?.attributes.combo_max_add_on_selections
  const validAddOnsConfiguration =
    !validNumAddOnSelections ||
    validNumAddOnSelections >= selectedAddOnIds.length

  const validConfiguration =
    validNumSelections.includes(selectedOptionLabTestIds.length) &&
    validAddOnsConfiguration

  const comboNumSelections = selectedOptionLabTestIds.length
  const minSelections = min(validNumSelections) ?? 1
  const maxSelections = max(validNumSelections) ?? 1

  let configurationWarningMessage = ""
  if (!validConfiguration) {
    if (comboNumSelections < minSelections) {
      configurationWarningMessage = `Add at least ${
        minSelections - comboNumSelections
      } more test${
        minSelections - comboNumSelections > 1 ? "s" : ""
      } to complete the combo.`
    } else if (comboNumSelections > maxSelections) {
      configurationWarningMessage = `Remove ${
        comboNumSelections - maxSelections
      } test${
        comboNumSelections - maxSelections > 1 ? "s" : ""
      } to create a valid combo.`
    } else if (!validAddOnsConfiguration) {
      configurationWarningMessage = `Remove ${
        selectedAddOnIds.length - validNumAddOnSelections!
      } add-on${
        selectedAddOnIds.length - validNumAddOnSelections! > 1 ? "s" : ""
      } to create a valid combo.`
    } else {
      configurationWarningMessage =
        "Add or remove tests to create a valid combo test."
    }
  }

  const handleOnAddToCart = async () => {
    const payload = {
      lab_test: selectedTargetId,
      combo_group: comboGroupId,
      combo_group_options: selectedOptionIds,
      add_ons: selectedAddOnIds,
    }
    onAddToCart(payload)
  }

  const handleOnClose = () => {
    const hasBeenModified =
      initialTargetId !== selectedTargetId ||
      JSON.stringify(initialAddOnIds.sort()) !==
        JSON.stringify(selectedAddOnIds.sort()) ||
      JSON.stringify(initialOptionIds.sort()) !==
        JSON.stringify(selectedOptionIds.sort())

    if (updating && !hasBeenModified) {
      muiDialogControl.onClose()
      return
    }

    confirmationModal.show({
      title: "Are you sure?",
      message: updating
        ? "Changes you made will not be saved, are you sure you want to exit without saving?"
        : "This combo has not been added to your cart. Are you sure you want to exit?",
      backButtonTitle: updating ? "No, Save changes" : "Continue editing",
      confirmButtonTitle: updating ? "Yes" : "Exit without adding to cart",
      confirmButtonColor: "primary",
      onClose: confirmationModal.hide,
      loading: false,
      handleConfirm: () => {
        confirmationModal.hide()
        muiDialogControl.onClose()
      },
    })
  }

  return (
    <Dialog
      {...muiDialogControl}
      disableEnforceFocus
      disableEscapeKeyDown
      fullWidth
      maxWidth="md"
    >
      <ComboGroupModalHeader
        updating={updating}
        targetLabTest={currentTarget}
        onClose={handleOnClose}
        optionsSumCost={optionsSumCost}
        addOnsSumCost={addOnsSumCost}
        validConfiguration={validConfiguration}
        configurationWarningMessage={configurationWarningMessage}
        onAddToCart={handleOnAddToCart}
        isLoading={isLoading}
        selectedOptionLabTestIds={selectedOptionLabTestIds}
        optionLabTests={optionLabTests}
        availableTargets={availableTargets}
      />
      <ComboGroupModalBody
        targetLabTest={currentTarget}
        availableTargets={availableTargets}
        optionLabTests={optionLabTests}
        selectedOptionLabTestIds={selectedOptionLabTestIds}
        selectedAddOnIds={selectedAddOnIds}
        onAddComboTest={onAddComboTest}
        onRemoveComboTest={onRemoveComboTest}
        onAddAddOn={onAddAddOn}
        onRemoveAddOn={onRemoveAddOn}
        isLoading={isLoading}
      />
    </Dialog>
  )
}

const ComboGroupConfigurationNiceModal =
  NiceModal.create<ComboGroupConfigurationModalProps>((props) => {
    return <ComboGroupConfigurationModal key={props.comboGroupId} {...props} />
  })

export default ComboGroupConfigurationNiceModal
