import { useSelector } from "react-redux"

import { makeStyles } from "@material-ui/core"

import UpRightArrow from "app/assets/icons/up-right-arrow.svg"
import DesignSystemButton from "app/components/design-system/Button"
import { physicianAuthorizationApplicationPageEvents } from "app/services/segment"
import { trackEventWithProperties } from "app/services/segment.typed"
import { RootState } from "app/types"

const styles = (theme) => ({
  learnMoreArrow: {
    width: 10,
    marginBottom: 4,
    marginLeft: 4,
  },
  learnMoreButton: {
    marginTop: "10px",
    marginBottom: "30px",
    width: 400,
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
})

const useStyles = makeStyles(styles)

interface Props {
  url: string
}

/**
 * Learn more button
 * @param url - URL to which the button will take the user when clicked
 */
const LearnMoreButton = ({ url }: Props) => {
  const classes = useStyles(styles)

  const practitioner = useSelector(
    ({ practitioner }: RootState) => practitioner
  )

  return (
    <DesignSystemButton
      className={classes.learnMoreButton}
      color="secondary"
      onClick={() => {
        trackEventWithProperties(
          physicianAuthorizationApplicationPageEvents.LEARN_MORE_CLICKED,
          {
            practitioner_id: practitioner?.id,
            clinic_id: practitioner?.clinic?.id,
          }
        )
        window.open(url, "_blank")
      }}
    >
      Learn More About How It Works
      <img
        className={classes.learnMoreArrow}
        src={UpRightArrow}
        alt="up-right-arrow"
      />
    </DesignSystemButton>
  )
}

export default LearnMoreButton
