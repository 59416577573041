import { useState } from "react"

import { Grid } from "@material-ui/core"

import BodyText from "app/components/design-system/BodyText"
import DesignSystemButton from "app/components/design-system/Button"
import { navy, colors, primaryColor } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"

import AccountCardWrapper from "../AccountCardWrapper"
import { AddAccountModal } from "../add-account-modal/AddAccountModal"

const useStyles = makeAppStyles({
  createAccountPrompt: {
    color: navy,
    marginTop: 16,
  },
  createAccountLink: {
    color: primaryColor,
  },
  noAccountText: {
    textAlign: "center",
    color: colors.coolGray[400],
  },
})

const EmptyAccount = ({ displayCompany, mutateLabCompanyAccounts }) => {
  const classes = useStyles()
  const isLabcorp = displayCompany.display.includes("Labcorp")

  const [showAddAccountModal, setShowAddAccountModal] = useState(false)

  const addButton = (
    <DesignSystemButton
      color="text"
      onClick={() => setShowAddAccountModal(true)}
    >
      + Add
    </DesignSystemButton>
  )

  return (
    <Grid item container xs={12} md={6}>
      <AccountCardWrapper
        company={displayCompany}
        isConfirmed={false}
        actionElement={addButton}
        useFullWidth={true}
      >
        <BodyText size="md" className={classes.noAccountText}>
          No account added
        </BodyText>
      </AccountCardWrapper>
      {isLabcorp && (
        <Grid item xs={12} md={12}>
          <BodyText className={classes.createAccountPrompt}>
            Don’t have a Labcorp account?&nbsp;
            <a
              className={classes.createAccountLink}
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.labcorp.com/frequently-asked-questions/provider/general/labcorp-account-services"
            >
              Create one
            </a>
          </BodyText>
        </Grid>
      )}
      <AddAccountModal
        open={showAddAccountModal}
        onClose={() => setShowAddAccountModal(false)}
        displayCompany={displayCompany}
        mutateLabCompanyAccounts={mutateLabCompanyAccounts}
      />
    </Grid>
  )
}

export default EmptyAccount
