import { useEffect, useRef } from "react"

import { debounce, isEqual, pick } from "lodash"
import { FieldErrors, useForm } from "react-hook-form"

import { FoodPlanVersion } from "types/food-plan-version"

export type FoodPlanGenerateConfigurationFormValues = Partial<
  Pick<
    FoodPlanVersion["attributes"],
    | "meal_frequency"
    | "food_plan_type"
    | "preferences"
    | "dietary_restrictions"
    | "layout"
    | "daily_nutrients"
    | "daily_calories"
    | "include_ingredients"
  >
>

export interface UseFoodPlanGenerateFormProps {
  defaultValues?: FoodPlanGenerateConfigurationFormValues
  onPatch?: (values: FoodPlanGenerateConfigurationFormValues) => Promise<void>
}

export default function useFoodPlanGenerateForm({
  defaultValues = {
    meal_frequency: "",
    food_plan_type: "",
    preferences: "",
    dietary_restrictions: "",
    layout: "table",
    daily_nutrients: "",
    daily_calories: undefined,
    include_ingredients: false,
  },
  onPatch,
}: UseFoodPlanGenerateFormProps) {
  const defaultValuesRef =
    useRef<FoodPlanGenerateConfigurationFormValues>(defaultValues)
  defaultValuesRef.current = defaultValues = pick(defaultValues, [
    "meal_frequency",
    "food_plan_type",
    "preferences",
    "dietary_restrictions",
    "layout",
    "daily_nutrients",
    "daily_calories",
    "include_ingredients",
  ])

  const methods = useForm<FoodPlanGenerateConfigurationFormValues>({
    defaultValues,
    shouldUnregister: true,
  })

  const { handleSubmit, watch } = methods

  /**
   * Debounce the form submission on change to avoid sending too many requests to the server.
   */
  useEffect(() => {
    if (!onPatch) {
      return
    }

    /**
     * On valid form data, send the data to the server.
     */
    const onValid = (values: FoodPlanGenerateConfigurationFormValues) => {
      // Avoid awaiting patch promise to avoid blocking the UI.
      onPatch(values)
    }

    /**
     * On invalid form data, only send the valid data to the server.
     * @param values the data received during the change event
     */
    const onInvalid =
      (values: FoodPlanGenerateConfigurationFormValues) =>
      (errors: FieldErrors<FoodPlanGenerateConfigurationFormValues>) => {
        const validData = Object.fromEntries(
          Object.entries(values).filter(([key]) => !errors[key])
        ) as FoodPlanGenerateConfigurationFormValues

        onValid(validData)
      }

    const debounceOnSubmit = debounce(
      (values) => handleSubmit(onValid, onInvalid(values))(),
      400
    )
    const subscription = watch((values) => {
      if (!isEqual(values, defaultValuesRef.current)) {
        return debounceOnSubmit(values)
      } else {
        debounceOnSubmit?.cancel()
      }
    })

    return () => {
      // Rather than cancel, we flush, as this ensures the save still goes through.
      debounceOnSubmit?.flush()

      subscription?.unsubscribe()
    }
  }, [handleSubmit, watch])

  return methods
}
