import DocumentListItemDropdownMenu, {
  DocumentListItemDropdownMenuProps,
} from "./DocumentListItemDropdownMenu"

const DocumentCardActionsMenu: React.FC<DocumentListItemDropdownMenuProps> = (
  props
) => {
  return (
    <div
      className="absolute top-0 right-0"
      // Prevent click from bubbling up to the list item
      onClick={(e) => e.stopPropagation()}
    >
      <DocumentListItemDropdownMenu {...props} />
    </div>
  )
}

export default DocumentCardActionsMenu
