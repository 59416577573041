import { makeStyles } from "@material-ui/core"
import Grow from "@material-ui/core/Grow"
import Paper from "@material-ui/core/Paper"
import Typography from "@material-ui/core/Typography"

import { PHYSICIAN_SERVICES_FEE_AMOUNT } from "app/constants"
import useFeatureFlag, { FeatureFlag } from "app/hooks/use-feature-flag"
import { StorefrontType, useResourceByIdSelector } from "app/hooks/use-resource"
import { primaryColor } from "app/theme"
import { formatDollars } from "app/utils"

import PriceBreakdown from "./PriceBreakdown"

type LabTestCartProps = {
  storefront: StorefrontType | null
  storefrontProductId: string | undefined
  onRemove: (id: string) => unknown
}

const useStyles = makeStyles({
  labTestTitle: {
    display: "flex",
    alignItems: "flex-start",
  },
  labTestPriceWrapper: {
    display: "flex",
    flexGrow: 1,
    justifyContent: "flex-end",
    flexDirection: "column",
    marginLeft: 5,
  },
})
export const LabTestCartCard = ({
  storefront,
  storefrontProductId,
  onRemove,
}: LabTestCartProps) => {
  const [hidePhysServicesPricing] = useFeatureFlag(
    FeatureFlag.HidePhysicianServicesPricing
  )
  const [practitionerLicenseFlagEnabled] = useFeatureFlag(
    FeatureFlag.LabshopsUsePractitionerLicense
  )
  const usePractitionerLicense = Boolean(
    practitionerLicenseFlagEnabled &&
      storefront?.attributes.use_practitioner_license
  )

  const clinic = useResourceByIdSelector(
    "clinic",
    storefront?.relationships.clinic?.data.id
  )
  const product = useResourceByIdSelector(
    "storefront_product",
    storefrontProductId
  )
  const labTest = useResourceByIdSelector(
    "lab_test",
    product?.relationships.lab_test.data?.id
  )
  const lab = useResourceByIdSelector(
    "lab_company",
    labTest?.relationships.lab_company.data.id
  )
  const customFee = useResourceByIdSelector(
    "custom_fee",
    product?.relationships.custom_fee?.data?.id
  )

  const classes = useStyles()

  return (
    <Grow in={true} style={{ transformOrigin: "50% 0 0" }} timeout={250}>
      <Paper
        className="bg-gray-100 rounded-lg flex flex-col mb-4 border-b-4 border-gray-400"
        elevation={0}
        style={{ transition: "all 250ms" }}
      >
        <div className="px-4 py-3">
          <div className="flex items-start">
            <div>
              <div className={classes.labTestTitle}>
                <Typography className="text-base15 font-bold text-gray-800">
                  {labTest?.attributes.name}
                </Typography>
              </div>
              <Typography className="text-base15 text-gray-700">
                {lab?.attributes.name}
              </Typography>
            </div>
            <div className={classes.labTestPriceWrapper}>
              <LabTestItemPrice
                rupaPrice={Number(labTest?.attributes.rupa_price)}
                customFee={customFee?.attributes.price}
                hidePhysServicesPricing={
                  hidePhysServicesPricing || usePractitionerLicense
                }
              />
              <div
                onClick={() => onRemove(storefrontProductId || "")}
                className="cursor-pointer font-semibold text-base15 text-right"
                style={{ color: primaryColor }}
              >
                Remove
              </div>
            </div>
          </div>

          <PriceBreakdown
            rupaPrice={Number(labTest?.attributes.rupa_price)}
            customFeePrice={Number(customFee?.attributes.price || 0)}
            customFeeName={customFee?.attributes.name}
            clinicName={clinic?.attributes.name}
            hidePhysServicesPricing={
              hidePhysServicesPricing || usePractitionerLicense
            }
          />
        </div>
      </Paper>
    </Grow>
  )
}

type LabTestItemPriceProps = {
  rupaPrice: number
  customFee: number | undefined
  hidePhysServicesPricing: boolean
}
const LabTestItemPrice = ({
  rupaPrice,
  customFee,
  hidePhysServicesPricing,
}: LabTestItemPriceProps) => {
  let totalPrice = rupaPrice + (customFee || 0) / 100

  if (!hidePhysServicesPricing) {
    totalPrice += PHYSICIAN_SERVICES_FEE_AMOUNT
  }

  return (
    <Typography className="font-bold text-lg" align="right">
      <span className="ml-8">{formatDollars(totalPrice)}</span>
    </Typography>
  )
}
