import { Button } from "@rupahealth/design"

import { ReactComponent as FeedbackIconBlue } from "app/assets/icons/feedback-blue.svg"
import { INTERCOM_IOK_INVENTORY_FEEDBACK_ID } from "app/settings"

const IOKShareFeedbackButton: React.FC = () => {
  return (
    <Button
      type="button"
      variant="ghost"
      className="flex items-center text-[15px] gap-[6px] py-1 px-2 h-auto text-primary hover:text-primary rounded-md"
      onClick={() =>
        window.Intercom("startSurvey", INTERCOM_IOK_INVENTORY_FEEDBACK_ID)
      }
    >
      <FeedbackIconBlue />
      Share Feedback
    </Button>
  )
}

export default IOKShareFeedbackButton
