import * as React from "react"

import * as DropdownMenu from "@radix-ui/react-dropdown-menu"

import AddTaskMenuContent from "app/results-summary/components/ResultsSummaryEditor/components/AddTask/AddTaskMenuContent"
import AddTaskOptions from "app/results-summary/components/ResultsSummaryEditor/components/AddTask/AddTaskMenuOptions"
import { TaskTypesLiteral } from "types/results-interpretation"

export interface AddTaskProps {
  isAdding: boolean
  onCreate: (taskType: TaskTypesLiteral) => void
  onToggleAdding: () => void
  trigger: React.ReactNode
}

export default function AddTask({
  isAdding,
  onCreate,
  onToggleAdding,
  trigger,
}: AddTaskProps) {
  return (
    <DropdownMenu.Root open={isAdding} onOpenChange={onToggleAdding}>
      {trigger}

      <AddTaskMenuContent>
        <AddTaskOptions onSelect={onCreate} />
      </AddTaskMenuContent>
    </DropdownMenu.Root>
  )
}
