import { useEffect } from "react"

import useDebounceState from "app/hooks/use-debounce-state"
import useCollectionSWR from "app/swr/hooks/use-collection-swr"
import { ResourceCollection } from "app/swr/types"
import { BiomarkerIdentifier } from "types/biomarker"
import { BiomarkerGroupingIdentifier } from "types/biomarker_grouping"

export interface BiomarkerSearch {
  inputValue: string | undefined
  debouncedInputValue: string | undefined
  biomarkers: BiomarkerIdentifier[]
  isLoadingBiomarkers: boolean
  biomarkerGroupings: BiomarkerGroupingIdentifier[]
  isLoadingBiomarkerGroupings: boolean
}

export default function useBiomarkerSearch(
  search?: string,
  options: { debounceDelay?: number; limit?: number } = {}
): BiomarkerSearch {
  const { debounceDelay = 150, limit = 10 } = options
  const [debouncedInputValue, inputValue, setInputValue] = useDebounceState(
    search,
    debounceDelay
  )

  useEffect(() => {
    setInputValue(search)
  }, [search])

  const { data: biomarkers, isLoading: isLoadingBiomarkers } = useCollectionSWR<
    ResourceCollection<BiomarkerIdentifier>
  >(
    search && "/biomarkers/",
    {
      params: {
        "filter[search]": debouncedInputValue,
        "page[limit]": `${limit}`,
        for_panels: "true",
      },
    },
    {
      keepPreviousData: true,
      revalidateIfStale: true,
      revalidateOnFocus: true,
    }
  )

  const { data: biomarkerGroupings, isLoading: isLoadingBiomarkerGroupings } =
    useCollectionSWR<ResourceCollection<BiomarkerGroupingIdentifier>>(
      "/biomarker_groupings/",
      {
        params: {
          "filter[search]": debouncedInputValue,
          "page[limit]": `${limit}`,
        },
        include: ["biomarkers"],
      },
      {
        keepPreviousData: true,
        revalidateIfStale: true,
        revalidateOnFocus: true,
      }
    )

  return {
    inputValue,
    debouncedInputValue,
    biomarkers,
    isLoadingBiomarkers,
    biomarkerGroupings,
    isLoadingBiomarkerGroupings,
  }
}
