import { PractitionerType } from "app/types"

import { PRAC_OPTION_GROUPS, PracSelectOption } from "./types"

export function getPracSelectOptionPossessiveName(
  option: PracSelectOption | undefined
) {
  if (!option) return ""
  if (option.group === PRAC_OPTION_GROUPS.CURRENT_USER) {
    return "your"
  }
  return `${option.label}'s`
}

export function practitionerTypeName(
  practitionerType: PractitionerType | undefined
) {
  return practitionerType?.name ?? "Unknown Practitioner Type"
}
