import { ReactElement } from "react"

import { Link } from "react-router-dom"

import { Grid, makeStyles, styled } from "@material-ui/core"
import MuiDialogContent from "@material-ui/core/DialogContent"

import { UserPaths } from "app/Routes"
import ArrowTopRightIcon from "app/assets/icons/arrow-top-right.svg"
import ChecklistHeader from "app/assets/images/storefront-onboarding-header.svg"
import StorefrontMoneyIcon from "app/assets/images/storefront/storefront-money.svg"
import StorefrontResultsIcon from "app/assets/images/storefront/storefront-results.svg"
import StorefrontSignatureIcon from "app/assets/images/storefront/storefront-signature.svg"
import StorefrontIcon from "app/assets/images/storefront/storefront-store.svg"
import StorefrontVilesIcon from "app/assets/images/storefront/storefront-viles.svg"
import BodyText from "app/components/design-system/BodyText"
import DesignSystemButton from "app/components/design-system/Button"
import useAppSelector from "app/hooks/useAppSelector"
import { colors, primaryColor, Theme } from "app/theme"

const styles = (theme: Theme) => ({
  styledContent: {
    padding: theme.spacing(2),
    display: "flex",
    justifyContent: "center",
    marginTop: 40,
  },
  styledAction: {
    backgroundColor: colors.blueGray[50],
    paddingTop: 24,
    paddingBottom: 42,
    paddingLeft: theme.spacing(7),
    paddingRight: theme.spacing(7),
  },
  continueButtonContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    paddingTop: 24,
    paddingBottom: 24,
  },
  continueButton: {
    width: 580,
  },
  link: {
    color: primaryColor,
  },
})

const useStyles = makeStyles(styles)

interface Instruction {
  text: string | ReactElement
  icon: string
  altText: string
}

interface Props {
  onContinueClick: () => void
}

export default function EcommerceWelcome({ onContinueClick }: Props) {
  const classes = useStyles(styles)

  const practitioner = useAppSelector(({ practitioner }) => practitioner)

  const instructions = [
    {
      text: "Create your own LabShop for your course participants and community to buy tests through your link.",
      icon: StorefrontIcon,
      altText: "storefront",
    },
    {
      text: "You select the specific tests you want to show to your audience.",
      icon: StorefrontVilesIcon,
      altText: "lab vials",
    },
    {
      text: "Choose to include an additional custom fee per test that is paid out to you via Stripe.",
      icon: StorefrontMoneyIcon,
      altText: "money",
    },
    {
      text: (
        <>
          All tests in your store will be ordered by a signing physician.{" "}
          {!practitioner.vendor_physician_authorization_enabled && (
            <>
              <Link
                className={classes.link}
                to={UserPaths.PHYSICIAN_SERVICES_ENABLE_MODAL}
              >
                {"Add to account to get started"}
              </Link>
              .
            </>
          )}
        </>
      ),
      icon: StorefrontSignatureIcon,
      altText: "signature",
    },
    {
      text: "Results are emailed directly to the client and can also be viewed in your store’s order tab.",
      icon: StorefrontResultsIcon,
      altText: "results",
    },
  ]

  return (
    <Grid container>
      <Grid item xs={12} md={12}>
        <MuiDialogContent className={classes.styledContent}>
          <Checklist instructions={instructions} />
        </MuiDialogContent>
        <div className={classes.continueButtonContainer}>
          <DesignSystemButton
            className={classes.continueButton}
            onClick={onContinueClick}
            color="primary"
          >
            Continue
          </DesignSystemButton>
        </div>
      </Grid>
    </Grid>
  )
}

const IconImage = styled("img")({
  width: 30,
  marginRight: 11,
  alignSelf: "start",
})

interface ChecklistProps {
  instructions: Instruction[]
}

function Checklist({ instructions }: ChecklistProps) {
  const styles = (theme: Theme) => ({
    checklistContainer: {
      backgroundColor: "white",
      "overflow-y": "auto",
      padding: "30px 20px",
      borderRadius: 16,
      border: "16px solid #E2E8F0",
      maxWidth: 600,
      boxShadow:
        "0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);",
    },
    styledAction: {
      backgroundColor: colors.blueGray[50],
      paddingTop: 24,
      paddingBottom: 42,
      paddingLeft: theme.spacing(7),
      paddingRight: theme.spacing(7),
    },
    continueButton: {
      width: "100%",
    },
    instructionText: {
      fontWeight: 400,
      alignItems: "center",
      justifyContent: "center",
      flex: 1,
    },
    instructionRow: {
      marginLeft: 20,
      marginRight: 20,
      display: "flex",
      "flex-direction": "row",
      borderBottom: `1px solid ${colors.blueGray[200]}`,
      paddingTop: "31px",
      alignItems: "center",
      paddingBottom: 26,
    },
    checklistHeader: {
      display: "flex",
      justifyContent: "center",
      marginTop: -60,
      marginBottom: 10,
      // There's a type error that "absolute" is not a valid "position" property...
      position: "absolute" as unknown as undefined,
      width: "100%",
      left: "0px",
    },
    checklistHeaderText: {
      color: "white",
      maxWidth: "fit-content",
      backgroundColor: colors.blueGray[500],
      alignText: "center",
      borderRadius: 5,
      padding: 16,
    },
    learnMoreBtn: {
      marginTop: "31px",
      width: "100%",

      "&.MuiButton-endIcon": {
        position: "relative",
        top: "-3px",
      },
    },
    checklistImage: {
      width: "60px",
      marginRight: "26px",
    },
  })

  const useStyles = makeStyles(styles)
  const classes = useStyles(styles)

  return (
    <div className={classes.checklistContainer}>
      <div className={classes.checklistHeader}>
        <img src={ChecklistHeader} alt="How it works" />
      </div>
      {instructions.map((instruction, index) => (
        <div key={index} className={classes.instructionRow}>
          <IconImage
            src={instruction.icon}
            alt={instruction.altText}
            className={classes.checklistImage}
          />
          <BodyText size="md" className={classes.instructionText}>
            {instruction.text}
          </BodyText>
        </div>
      ))}
      <DesignSystemButton
        color="secondary"
        className={classes.learnMoreBtn}
        endIcon={
          <img
            src={ArrowTopRightIcon}
            style={{ position: "relative", top: "-3px" }}
            alt="arrow right icon"
          />
        }
        onClick={() =>
          window.open("https://www.rupahealth.com/labshop", "_blank")
        }
      >
        Learn More About How It Works
      </DesignSystemButton>
    </div>
  )
}
