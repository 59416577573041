import { useEffect, useState } from "react"

import clsx from "clsx"

import { Link, Typography, fade } from "@material-ui/core"

import AlertTriangleGrayIcon from "app/assets/icons/alert-triangle-gray.svg"
import AmberAlertIcon from "app/assets/icons/amber-alert.svg"
import GreenCheckCircleIcon from "app/assets/icons/green-check-circle.svg"
import BodyText from "app/components/design-system/BodyText"
import Button from "app/components/design-system/Button"
import { LAB_COMPANY_KEY } from "app/constants"
import { labCompaniesUsingInsurance } from "app/dataServices/orderDataService"
import useFeatureFlag from "app/hooks/use-feature-flag"
import InsuranceFields from "app/main/patient-checkout/sections/InsuranceFields"
import { FeatureFlag } from "app/providers/FeatureFlagProvider"
import { colors, navy, textPrimaryColor } from "app/theme"
import { PatientPortalOrder } from "app/types"
import makeAppStyles from "app/utils/makeAppStyles"

const useStyles = makeAppStyles((theme) => ({
  medicareVerificationSuccessContainer: {
    alignItems: "center",
    backgroundColor: colors.emerald[50],
    border: `1px solid ${colors.emerald[200]}`,
    borderRadius: 6,
    display: "flex",
    height: 40,
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      height: "max-content",
    },
  },
  medicareVerificationSuccessBodyText: {
    color: `${colors.emerald[800]}`,
    fontSize: 15,
  },
  medicareVerificationSuccessIcon: {
    backgroundColor: colors.emerald[200],
    border: `5px solid ${colors.emerald[200]}`,
    borderRadius: "50%",
    marginRight: theme.spacing(1),
    marginTop: 2,
    width: 25,
  },
  medicareVerificationFailureContainer: {
    alignItems: "flex-start",
    backgroundColor: colors.amber[50],
    border: `1px solid ${colors.amber[200]}`,
    borderRadius: 6,
    display: "flex",
    flexDirection: "row",
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  medicareVerificationFailureHeader: {
    color: `${colors.amber[800]}`,
    fontSize: 15,
  },
  medicareVerificationFailureBodyText: {
    color: `${colors.amber[800]}`,
    fontSize: 14,
  },
  medicareVerificationFailureIcon: {
    backgroundColor: colors.amber[200],
    border: `5px solid ${colors.amber[200]}`,
    borderRadius: "50%",
    marginRight: theme.spacing(1),
    marginTop: 2,
    width: 25,
  },
  medicareVerificationFailureBody: {
    display: "flex",
    flexDirection: "column",
    paddingTop: theme.spacing(0.5),
    "& > * + *": {
      marginTop: theme.spacing(1),
    },
  },
  medicareVerificationFailureActionSection: {
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    "& > button": {
      marginRight: theme.spacing(2),
    },
  },
  medicareDisabledContainer: {
    alignItems: "center",
    backgroundColor: colors.blueGray[100],
    border: `1px solid ${navy}`,
    borderRadius: 6,
    display: "flex",
    height: 40,
    padding: theme.spacing(2),
    width: "75%",
    [theme.breakpoints.down("md")]: {
      height: "max-content",
    },
  },
  medicareDisabledBodyText: {
    color: navy,
    fontSize: 15,
  },
  medicareInformationHeader: {
    color: textPrimaryColor,
    fontSize: 24,
  },
}))

interface InsuranceProps {
  className?: string
  isWideView: boolean
  order: PatientPortalOrder
  updateOrder: (string) => {}
  isUpdatingOrder: boolean
}

const Insurance = ({
  className,
  isWideView,
  order,
  updateOrder,
  isUpdatingOrder,
}: InsuranceProps) => {
  const classes = useStyles()
  const isMedicareUsed = order.patient_has_medicare
  const [isMedicareDisabled, setIsMedicareDisabled] = useState<boolean>(
    !order.patient_has_medicare
  )
  const [isMedicareVerified, setIsMedicareVerified] = useState<boolean>()
  const [isMedicareCheckEnabled, isMedicareCheckLoading] = useFeatureFlag(
    FeatureFlag.isMedicareCheckEnabled
  )

  useEffect(() => {
    setIsMedicareVerified(order.is_medicare_verified)
  }, [order])

  // If the order is not using insurance, and the feature flag is not enabled,
  // or the feature flag is loading, return null.
  //
  // This ensures that we preserve the current behavior of the Insurance section
  // as it exists in general availability, when the feature flag is not enabled.
  if (
    (!order.use_insurance && !isMedicareCheckEnabled) ||
    isMedicareCheckLoading
  ) {
    return null
  }

  if (
    !order.use_insurance &&
    isMedicareDisabled &&
    isMedicareVerified === false
  ) {
    return (
      <div className={className}>
        <Typography variant="h4" className={classes.medicareInformationHeader}>
          Medicare Information
        </Typography>
        <MedicareDisabled />
      </div>
    )
  }

  if (!order.use_insurance) {
    return null
  }

  // If the feature flag is not enabled, render Insurance section as normal,
  // without the Medicare Check button.
  if (!isMedicareCheckEnabled) {
    return (
      <div className={className} aria-label="Medicare Information">
        <InsuranceHeader isMedicareUsed={isMedicareUsed} order={order} />
        <InsuranceFields
          className={clsx(className, isWideView ? "p-16 shadow rounded-8" : "")}
          isMedicareUsed={isMedicareUsed}
          order={order}
          updateOrder={updateOrder}
          isUpdatingOrder={isUpdatingOrder}
        />
      </div>
    )
  }

  return (
    <div className={className} aria-label="Medicare Information">
      <InsuranceHeader isMedicareUsed={isMedicareUsed} order={order} />
      <InsuranceFields
        className={clsx(className, isWideView ? "p-16 shadow rounded-8" : "")}
        isMedicareUsed={isMedicareUsed}
        order={order}
        updateOrder={updateOrder}
        isUpdatingOrder={isUpdatingOrder}
      />
      {isMedicareUsed && isMedicareVerified && <MedicareVerificationSuccess />}
      {isMedicareUsed && isMedicareVerified === false && (
        <MedicareVerificationFailure
          handleDisableMedicare={() => setIsMedicareDisabled(true)}
          isUpdatingOrder={isUpdatingOrder}
          updateOrder={updateOrder}
        />
      )}
    </div>
  )
}

interface InsuranceHeaderProps {
  isMedicareUsed?: boolean
  isMedicareDisabled?: boolean
  order: PatientPortalOrder
}

const InsuranceHeader = ({ order, isMedicareUsed }: InsuranceHeaderProps) => {
  const practitionerName = order.practitioner.titled_full_name
  const labsUsingInsurance = labCompaniesUsingInsurance(order)
  const isUsingDslInsurance = Boolean(
    labsUsingInsurance.find(
      (labCompany) => labCompany.key === LAB_COMPANY_KEY.DSL
    )
  )
  const isUsingGenovaInsurance = Boolean(
    labsUsingInsurance.find(
      (labCompany) => labCompany.key === LAB_COMPANY_KEY.GENOVA
    )
  )
  const isUsingInfiniteAllergyInsurance = Boolean(
    labsUsingInsurance.find(
      (labCompany) => labCompany.key === LAB_COMPANY_KEY.INFINITE_ALLERGY
    )
  )

  if (isMedicareUsed) {
    let warningBox

    if (isUsingDslInsurance || isUsingInfiniteAllergyInsurance) {
      warningBox = (
        <WarningBox>
          This test is only covered by traditional Medicare (the red, white &
          blue card), not Medicare Advantage or Medicaid. If you have one of
          these, reach out to us at{" "}
          <Link href="mailto:support@rupahealth.com">
            support@rupahealth.com
          </Link>{" "}
          and we’ll update the billing for your order.
        </WarningBox>
      )
    } else if (isUsingGenovaInsurance) {
      warningBox = (
        <WarningBox>
          Lab test pricing is different for Medicare Advantage or Medicaid. If
          you have one of these, reach out to us at{" "}
          <Link href="mailto:support@rupahealth.com">
            support@rupahealth.com
          </Link>{" "}
          and we’ll update the billing for your order.
        </WarningBox>
      )
    }

    return (
      <div>
        <Typography variant="h4" color="textPrimary" style={{ fontSize: 24 }}>
          {isMedicareUsed ? "Medicare Information" : "Insurance Information"}
        </Typography>

        <Typography color="textPrimary" className="my-4">
          <span className="opacity-75">
            {practitionerName} indicated to us that you will be using Medicare
            to cover some testing from{" "}
            {labsUsingInsurance.map((lab) => lab.name).join(" and ")}.
          </span>{" "}
        </Typography>
        {warningBox}
      </div>
    )
  }

  return (
    <div>
      <Typography variant="h4" color="textPrimary" style={{ fontSize: 24 }}>
        Insurance Information
      </Typography>

      <Typography color="textPrimary" className="my-4">
        <span className="opacity-75">
          {practitionerName} indicated to us that you will be using insurance to
          cover your testing from Genova Diagnostics.
        </span>{" "}
        <Link
          color="primary"
          target="__blank"
          href="https://support.rupahealth.com/hc/en-us/articles/360049490473"
        >
          How Insurance Works for Genova Diagnostics{" "}
        </Link>
      </Typography>

      <WarningBox>
        If you have Medicare, either as primary or secondary insurance, lab test
        pricing is different. Please reach out to us at{" "}
        <Link href="mailto:support@rupahealth.com">support@rupahealth.com</Link>{" "}
        and we’ll update the billing for your order.
      </WarningBox>
    </div>
  )
}

const WarningBox = ({ children }) => {
  return (
    <div
      className="flex items-start p-4 mb-2 rounded"
      style={{ backgroundColor: fade(navy, 0.05) }}
    >
      <img
        src={AlertTriangleGrayIcon}
        alt="Exclamation mark inside a triangle"
        className="mr-4 mt-1 w-8"
      />
      <Typography color="textPrimary" className="text-sm fs-exclude">
        {children}
      </Typography>
    </div>
  )
}

const MedicareVerificationSuccess = () => {
  const classes = useStyles()

  return (
    <div className={classes.medicareVerificationSuccessContainer}>
      <img
        src={GreenCheckCircleIcon}
        alt="Green check mark"
        className={classes.medicareVerificationSuccessIcon}
      />
      <BodyText
        className={classes.medicareVerificationSuccessBodyText}
        weight="semibold"
      >
        We verified your Medicare information.
      </BodyText>
    </div>
  )
}

interface MedicareVerificationFailureProps {
  handleDisableMedicare: () => void
  isUpdatingOrder: boolean
  updateOrder: (string) => {}
}

const MedicareVerificationFailure = ({
  handleDisableMedicare,
  isUpdatingOrder,
  updateOrder,
}: MedicareVerificationFailureProps) => {
  const classes = useStyles()

  const handleDisableMedicareButtonClick = () => {
    updateOrder({ is_medicare_used: false })
    handleDisableMedicare()
  }

  return (
    <div className={classes.medicareVerificationFailureContainer}>
      <img
        src={AmberAlertIcon}
        alt="Exclamation mark"
        className={classes.medicareVerificationFailureIcon}
      />
      <div className={classes.medicareVerificationFailureBody}>
        <BodyText
          className={classes.medicareVerificationFailureHeader}
          weight="semibold"
        >
          We couldn’t verify your information
        </BodyText>
        <BodyText className={classes.medicareVerificationFailureBodyText}>
          We were unable to verify your Medicare credentials. Please ensure that
          you’ve entered them correctly and that you’re not a member of Medicare
          Advantage or Medicaid.
        </BodyText>
        <BodyText className={classes.medicareVerificationFailureBodyText}>
          You can also choose to disable Medicare pricing for this order.
        </BodyText>
        <div className={classes.medicareVerificationFailureActionSection}>
          <Button
            color="secondary"
            disabled={isUpdatingOrder}
            loading={isUpdatingOrder}
            onClick={() => handleDisableMedicareButtonClick()}
            size="small"
          >
            Disable Medicare
          </Button>
          <BodyText className={classes.medicareVerificationFailureBodyText}>
            Or <Link href="mailto:support@rupahealth.com">contact support</Link>
            .
          </BodyText>
        </div>
      </div>
    </div>
  )
}

const MedicareDisabled = () => {
  const classes = useStyles()

  return (
    <div className={classes.medicareDisabledContainer}>
      <BodyText className={classes.medicareDisabledBodyText} weight="semibold">
        You've chosen to disable Medicare pricing for this order.
      </BodyText>
    </div>
  )
}

export default Insurance
