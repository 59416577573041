import * as React from "react"

import { useForm, FormProvider } from "react-hook-form"
import { z } from "zod"

import { zodResolver } from "@hookform/resolvers/zod"
import { InputAdornment, makeStyles } from "@material-ui/core"
import MuiDialogContent from "@material-ui/core/DialogContent"

import BodyText from "app/components/design-system/BodyText"
import DesignSystemButton from "app/components/design-system/Button"
import { FormFieldLabel } from "app/components/forms/fields"
import { ControlledTextField } from "app/main/patient-checkout/fields"
import { colors, Theme } from "app/theme"

import { CreateCustomFeeSchema } from "./CreateCustomFeeFormSchema"
import CustomFeesOnboardingModalTitleSection from "./CustomFeesOnboardingModalTitleSection"
import CustomFeesPatientCheckoutExampleSection from "./CustomFeesPatientCheckoutExampleSection"

type CustomFeesOnboardingModalStepTwoProps = {
  defaultName?: string
  defaultPrice?: number
  maxPrice?: number
  onClose: () => void
  nextStep: ({ name: string, price: number }) => void
  loading: boolean
}

const CustomFeesOnboardingModalStepTwo: React.FC<
  CustomFeesOnboardingModalStepTwoProps
> = ({
  onClose,
  nextStep,
  defaultPrice,
  loading,
  defaultName = "",
  maxPrice = 200000,
}) => {
  const styles = (theme: Theme) => ({
    styledContent: {
      paddingBottom: theme.spacing(8),
      paddingLeft: theme.spacing(15),
      paddingRight: theme.spacing(15),
      [theme.breakpoints.down("md")]: {
        paddingLeft: theme.spacing(5),
        paddingRight: theme.spacing(5),
      },
      backgroundColor: colors.blueGray[50],
      display: "flex",
      justifyContent: "center",
      "flex-direction": "column",
    },
    footnote: {
      fontSize: 13,
    },
    field: {
      marginBottom: theme.spacing(2),
    },
    completeOnboardingButton: {
      width: "100%",
      marginTop: theme.spacing(4),
    },
  })

  const useStyles = makeStyles(styles)
  const classes = useStyles(styles)

  const CustomFeeSchema = CreateCustomFeeSchema(maxPrice)

  const methods = useForm<z.infer<typeof CustomFeeSchema>>({
    defaultValues: {
      name: defaultName,
      price: defaultPrice ? defaultPrice / 100 : undefined,
    },
    resolver: zodResolver(CustomFeeSchema),
    mode: "onChange",
  })

  const { price: currentPrice } = methods.watch()

  return (
    <>
      <CustomFeesOnboardingModalTitleSection
        title="Fee Setup"
        onClose={onClose}
      />
      <MuiDialogContent className={classes.styledContent}>
        <div style={{ overflowY: "scroll" }}>
          <BodyText size="md" className={classes.field}>
            There is one fee for the entire order, and its name and amount can
            be adjusted each time you place an order. The defaults you choose
            here will be automatically applied to orders for all staff members
            and other practitioners in your clinic.
          </BodyText>

          <CustomFeesPatientCheckoutExampleSection open={true} />

          <FormProvider {...methods}>
            <FormFieldLabel label="Default Fee Name" required={true} />
            <ControlledTextField
              aria-label="Default Fee Name"
              name="name"
              placeholder="Ex. Lab interpretation fee, follow-up appointment, etc"
              defaultValue={defaultName}
              className={classes.field}
              fullWidth={true}
              required={true}
            />

            <FormFieldLabel label="Default fee amount" required={true} />
            <ControlledTextField
              aria-label="Default fee amount"
              name="price"
              placeholder="0.00"
              defaultValue={
                defaultPrice ? String(defaultPrice / 100) : undefined
              }
              className={classes.field}
              fullWidth={true}
              required={true}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" style={{ marginRight: 0 }}>
                    $
                  </InputAdornment>
                ),
              }}
            />
            {Boolean(currentPrice) && methods.formState.isValid && (
              <BodyText size="md" className={classes.footnote}>
                ${(currentPrice * 0.95).toFixed(2)} will be deposited into your
                account after fees.
              </BodyText>
            )}
          </FormProvider>
          <DesignSystemButton
            loading={loading}
            disabled={!methods.formState.isValid || loading}
            className={classes.completeOnboardingButton}
            onClick={() => nextStep(methods.getValues())}
            color="primary"
          >
            Continue to Connect Your Bank Account
          </DesignSystemButton>
        </div>
      </MuiDialogContent>
    </>
  )
}

export default CustomFeesOnboardingModalStepTwo
