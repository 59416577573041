import React from "react"

import { cn } from "@rupahealth/design"

import Loading from "./Loading"

interface Props {
  loading: boolean
  children: React.ReactNode
  className?: string
  ariaLabel?: string
}

function LoadingWrapper({
  loading,
  children,
  className,
  ariaLabel = "Loading",
}: Props) {
  return loading ? (
    <div className={cn("flex items-center justify-center", className)}>
      <Loading ariaLabel={ariaLabel} />
    </div>
  ) : (
    <>{children}</>
  )
}

export default LoadingWrapper
