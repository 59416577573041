import { useEffect, useRef, useState } from "react"

import { cn } from "@rupahealth/design"

import Tooltip from "./Tooltip"

// Component that hides overflow text and shows a tooltip only if text is overflowing
// Defaults to overflow-ellipsis, which can be overridden with className

interface Props {
  text: string
  className?: string
}

const OverflowTooltip: React.FC<Props> = ({ text, className }) => {
  const textElementRef = useRef<HTMLDivElement>(null)
  const [showTooltip, setShowTooltip] = useState(false)

  const compareSize = () => {
    if (!textElementRef.current) return
    const { scrollWidth, clientWidth } = textElementRef.current

    setShowTooltip(scrollWidth > clientWidth)
  }

  // compare once and add resize listener on mount
  useEffect(() => {
    compareSize()
    window.addEventListener("resize", compareSize)
    // remove listener on unmount
    return () => window.removeEventListener("resize", compareSize)
  }, [])

  return (
    <Tooltip
      title={text}
      disableHoverListener={!showTooltip}
      disableTouchListener={!showTooltip}
      placement="top"
      arrow
      interactive
    >
      <div
        ref={textElementRef}
        className={cn(
          "whitespace-nowrap overflow-hidden overflow-ellipsis decoration-body/50",
          className,
          { "underline decoration-dotted": showTooltip }
        )}
      >
        {text}
      </div>
    </Tooltip>
  )
}

export default OverflowTooltip
