import { useCallback, useState, RefObject } from "react"

export interface UseTokenFocusControlsProps {
  /**
   * Ref to the input element used to refocus the input upon reaching the last token.
   */
  inputRef: RefObject<HTMLInputElement>
  /**
   * The number of tokens.
   */
  length: number
}

export interface UseTokenFocusControlsHook {
  /**
   * Callback used to ensure blur events on the token properly reset the cursor.
   * Typically, called from the onBlur event handler.
   */
  blurToken: (index: number) => void
  /**
   * Callback used to shift focus from the given index to the next token on the right.
   * If the given index is the last, then focus will shift back to the input.
   */
  focusNextToken: (index: number) => void
  /**
   * Callback used to shift focus from the given index to the previous token on the left.
   * If the given index is the first, then focus will not shift.
   */
  focusPrevToken: (index: number) => void
  /**
   * Callback used to ensure focus events on the token properly reset the cursor.
   * Typically, called from the onFocus event handler.
   */
  focusToken: (index: number) => void
  /**
   * Tracks the index of the focus across a set of query tokens.
   *
   * • -1 represents the state where no token is focused
   * • 0...N-1 represents the index of the focused token
   */
  tokenCursor: number
}

export default function useTokenFocusControls({
  inputRef,
  length,
}: UseTokenFocusControlsProps): UseTokenFocusControlsHook {
  const [tokenCursor, setTokenCursor] = useState<number>(-1)
  const blurToken = useCallback(
    (index) =>
      setTokenCursor((cursor: number) => (cursor === index ? -1 : cursor)),
    []
  )
  const focusToken = useCallback((index: number) => setTokenCursor(index), [])
  const focusNextToken = useCallback(
    (index: number) => {
      if (index === length - 1) {
        setTokenCursor(-1)

        // ensure we switch focus to the input upon reaching the end
        if (inputRef.current) {
          inputRef.current.focus()
        }
        return
      }

      setTokenCursor(index + 1)
    },
    [length]
  )
  const focusPrevToken = useCallback(
    () => setTokenCursor((cursor) => Math.max(0, cursor - 1)),
    []
  )
  return {
    blurToken,
    focusNextToken,
    focusPrevToken,
    focusToken,
    tokenCursor,
  }
}
