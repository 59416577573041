import { INTERCOM_APP_ID } from "app/settings"

export function loadIntercom(user) {
  if (window.Intercom) {
    window.Intercom("boot", {
      app_id: `${INTERCOM_APP_ID}`,
      user_id: user.id,
      user_hash: user.user_hash,
      email: user.email,
      name: user.full_name,
    })
  }
  return
}

export function shutdownIntercom() {
  if (window.Intercom) {
    window.Intercom("shutdown")
  }
  return
}

export function setHideIntercom(hide: boolean) {
  window.Intercom("update", {
    hide_default_launcher: hide,
  })
}
