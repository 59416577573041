import { useMemo } from "react"

import {
  PatientPortalOrder,
  PatientPortalPayment,
} from "app/patient-portal/types"
import useCachedCollection from "app/swr/hooks/use-cached-collection"

import DropdownOrButton from "./DropdownOrButton"
import { getReceiptsToDisplay, getSuperbillsToDisplay } from "./helpers"

interface Props {
  order: PatientPortalOrder
}

const OrderPaymentButtons: React.FC<Props> = ({ order }) => {
  const payments = useCachedCollection<PatientPortalPayment>(
    order.relationships.payments.data
  )
  const paymentGroups = useMemo(
    () =>
      [getReceiptsToDisplay(payments), getSuperbillsToDisplay(payments)].filter(
        (group) => group.length > 0
      ),
    [payments]
  )

  return (
    <div className="flex items-center">
      {paymentGroups.map((payments, idx) => {
        return (
          <>
            <DropdownOrButton key={idx} payments={payments} />
            {paymentGroups.length > idx + 1 && (
              <div className="border-[.75px] h-5 border-slate-300" />
            )}
          </>
        )
      })}
    </div>
  )
}

export default OrderPaymentButtons
