import { useMemo } from "react"

import { useParams } from "react-router-dom"

import CircularProgress from "@material-ui/core/CircularProgress"

import useFeatureFlag from "app/hooks/use-feature-flag"
import { PatientOrdersTabHeader } from "app/main/patient-orders/components/PatientOrdersTabHeader"
import { ResultsMissingInfoBanner } from "app/main/patient-orders/components/results-missing-info/ResultsMissingInfoBanner"
import { FeatureFlag } from "app/providers/FeatureFlagProvider"

import Card from "../Card"
import { PatientPortalResultsInProgressBanner } from "../trends/PatientPortalResultsInProgressBanner"
import { PatientPortalResultsUploadButton } from "../trends/PatientPortalResultsUploadButton"

interface Props {
  title: React.ReactNode
  children: React.ReactNode
  loading?: boolean
  actionButtons?: React.ReactNode[]
  showResultsUploadIfEnabled?: boolean
}

export const PatientPortalTabCard: React.FC<Props> = ({
  title,
  children,
  loading,
  actionButtons = [],
  showResultsUploadIfEnabled,
}) => {
  const { userId } = useParams<{ userId: string }>()
  const [isPatientPortalResultsUploadEnabled, flagLoading] = useFeatureFlag(
    FeatureFlag.PatientPortalResultsUploads
  )

  const showResultsUpload =
    showResultsUploadIfEnabled &&
    isPatientPortalResultsUploadEnabled &&
    !flagLoading

  const actionButtonsWithResultsUpload = useMemo(
    () => [
      ...actionButtons,
      ...(showResultsUpload
        ? [<PatientPortalResultsUploadButton userId={userId} />]
        : []),
    ],
    [actionButtons, showResultsUpload]
  )

  return (
    <Card className="h-full max-w-full flex gap-[15px]">
      <PatientOrdersTabHeader
        title={
          <div className="flex items-center justify-center gap-2">
            {title}
            {loading && <CircularProgress size={18} />}
          </div>
        }
        actionButtons={actionButtonsWithResultsUpload}
      />
      {showResultsUpload && (
        <>
          <PatientPortalResultsInProgressBanner userId={userId} />
          <ResultsMissingInfoBanner userId={userId} />
        </>
      )}
      {children}
    </Card>
  )
}
