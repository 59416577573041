import { useController, useFormContext, useWatch } from "react-hook-form"

import PaymentCollectionStripeCard from "app/main/payment-collection/components/PaymentCollectionStripeCard"
import { PaymentCollectionFormData } from "app/main/payment-collection/types"

export interface PaymentCollectionPaymentMethodSecondaryProps {
  amount: number
}

export default function PaymentCollectionPaymentMethodSecondary({
  amount,
}: PaymentCollectionPaymentMethodSecondaryProps) {
  const { control, resetField, setError } =
    useFormContext<PaymentCollectionFormData>()
  const {
    field: { onBlur, onChange, value: paymentMethodSecondary },
    fieldState: { error, isTouched },
  } = useController({
    control: control,
    name: "payment_method_secondary",
  })
  const consent = useWatch({
    control: control,
    name: "consent",
  })
  const paymentMethod = useWatch({
    control: control,
    name: "payment_method",
  })
  const active = consent && Boolean(paymentMethod) && !paymentMethodSecondary
  const clearable = !active && Boolean(paymentMethodSecondary)
  const amountFormatted = `$${(amount / 100.0).toFixed(2)}`
  return (
    <PaymentCollectionStripeCard
      active={active}
      amountFormatted={amountFormatted}
      clearable={clearable}
      emptyMessage={`Provide consent and primary card information before entering secondary card information to be charged ${amountFormatted}`}
      error={isTouched ? error : undefined}
      onClear={() =>
        resetField("payment_method_secondary", {
          keepError: true,
          keepTouched: true,
        })
      }
      onError={(stripeError) =>
        setError("payment_method_secondary", {
          message: stripeError.message,
        })
      }
      onSubmit={(stripePaymentMethod) => {
        onBlur()
        onChange(stripePaymentMethod.id)
      }}
      title="Secondary Payment Method"
    />
  )
}
