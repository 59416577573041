import { CircularProgress } from "@material-ui/core"
import { Button, ButtonProps, cn } from "@rupahealth/design"

export interface RupaLoadingButtonProps extends ButtonProps {
  children: React.ReactNode
  loading?: boolean
  wrapperClassName?: string
}

const RupaLoadingButton: React.FC<RupaLoadingButtonProps> = ({
  children,
  loading,
  disabled,
  wrapperClassName,
  ...buttonProps
}) => {
  return (
    <div className={cn("relative inline-block", wrapperClassName)}>
      <Button {...buttonProps} disabled={disabled || loading}>
        {children}
      </Button>
      {loading && (
        <CircularProgress
          size={24}
          className="absolute top-1/2 left-1/2 -ml-3 -mt-3"
        />
      )}
    </div>
  )
}

export default RupaLoadingButton
