// Shared component to display allow showing and hiding of specimen issue description
import { Dispatch, SetStateAction } from "react"

import { faAngleDown, faAngleRight } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

interface Props {
  description: string
  showMore: boolean
  setShowMore: Dispatch<SetStateAction<boolean>>
}

export default function SpecimenIssueDescription({
  description,
  showMore,
  setShowMore,
}: Props) {
  const toggle = () => setShowMore((prev: boolean) => !prev)

  return (
    <>
      <span
        className="font-semibold underline cursor-pointer ml-1"
        onClick={toggle}
      >
        {showMore ? (
          <>
            Hide what happened
            <FontAwesomeIcon icon={faAngleDown} className="ml-1" />
          </>
        ) : (
          <>
            See what happened
            <FontAwesomeIcon icon={faAngleRight} className="ml-1" />
          </>
        )}
      </span>
      {showMore && (
        <p className="italic mt-1">
          <span
            dangerouslySetInnerHTML={{
              __html: description.replace(/\n/g, "<br>"),
            }}
          />
        </p>
      )}
    </>
  )
}
