import { makeStyles, Grid } from "@material-ui/core"

import BoxesIcon from "app/assets/images/boxes-collected-rounded.svg"
import BodyText from "app/components/design-system/BodyText"

import { PractitionerLabshops } from "./PractitionerLabshops"
import { PractitionerServices } from "./PractitionerServices"

const useStyles = makeStyles((theme) => ({
  iconContainer: {
    marginRight: 10,
    minWidth: 50,
  },
  headerContainer: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    alignItems: "center",
  },
}))

export const PractitionerHighlightedServices = ({ mutateProfile }) => {
  const classes = useStyles()

  return (
    <Grid container>
      <Grid item xs={12} container spacing={2}>
        <div className={classes.headerContainer}>
          <div className={classes.iconContainer}>
            <img src={BoxesIcon} alt="boxes icon" />
          </div>
          <Grid item xs={12}>
            <BodyText size="lg" weight="semibold">
              Add Highlighted Services
            </BodyText>
            <BodyText size="sm">
              Add the types of care you provide to clients, such as 1:1
              consultations, video visits, LabShops, and more.
            </BodyText>
          </Grid>
        </div>
      </Grid>
      <PractitionerLabshops mutateProfile={mutateProfile} />
      <PractitionerServices mutateProfile={mutateProfile} />
    </Grid>
  )
}
