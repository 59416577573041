import { RenderLeafProps } from "slate-react"

export default function Leaf({ attributes, children, leaf }: RenderLeafProps) {
  if ("bold" in leaf && leaf.bold) {
    children = <strong>{children}</strong>
  }

  if ("italic" in leaf && leaf.italic) {
    children = <em>{children}</em>
  }

  if ("underline" in leaf && leaf.underline) {
    children = <u>{children}</u>
  }

  if ("hyperlink" in leaf && leaf.hyperlink) {
    children = (
      <a target="_blank" rel="noreferrer" href={leaf.hyperlink}>
        {children}
      </a>
    )
  }

  return <span {...attributes}>{children}</span>
}
