import * as React from "react"
import { ComponentProps } from "react"

import clsx from "clsx"
import { isFunction } from "lodash"

import { Button, ButtonGroup } from "@material-ui/core"
import { Table } from "@tanstack/react-table"

import CloseCircleIcon from "app/assets/icons/close-circle.svg"
import { trackEventWithProperties } from "app/services/segment.typed"
import { colors, primaryColor, textPrimaryColor } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"
import { DashboardOrder } from "types/dashboard/dashboard-order"

import {
  DashboardEvents,
  GlobalFilterState,
  TabId,
} from "../TableRenderer/hooks/use-table"

const useStyles = makeAppStyles(() => ({
  root: {
    padding: "12px 18px",
    borderBottom: `1px solid ${colors.blueGray[200]}`,
    display: "flex",
  },
  tabGroup: {
    boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.07)",
    borderColor: "transparent",
    marginRight: 12,
    overflow: "hidden",
  },
  tabButton: {
    whiteSpace: "nowrap",
    borderColor: colors.blueGray[200],
    color: textPrimaryColor,
    padding: "7.5px 26px",
    fontSize: 15,
    fontWeight: 600,
    transition: "none",
    overflow: "hidden",
    "&:hover": {
      background: "rgba(0, 117, 205, 0.05)",
      boxShadow: "none",
    },
  },
  tabButtonActive: {
    background: "rgba(0, 117, 205, 0.05)",
    color: primaryColor,
  },
}))

export interface Tab {
  id: TabId
  label: React.ReactNode | ((table: Table<DashboardOrder>) => React.ReactNode)
  // If true, the tab won't be visible unless activated via the query param. Currently used
  // to show orders with active lab company alerts.
  hidden?: boolean
}

export interface TabGroup {
  tabs: Tab[]
}

export interface TableTabsRendererProps {
  groups: TabGroup[]
  table: Table<DashboardOrder>
}

export default function TableTabsRenderer({
  groups,
  table,
}: TableTabsRendererProps) {
  const classes = useStyles()
  const { tab: currentTab } = table.getState().globalFilter as GlobalFilterState
  return (
    <div className={classes.root}>
      {groups.map((group, index) => (
        <ButtonGroup
          key={index}
          className={classes.tabGroup}
          variant="outlined"
        >
          {group.tabs.map((tab) => (
            <TabButton
              key={tab.id}
              isActive={tab.id === currentTab}
              isHidden={tab.hidden}
              onClick={() => {
                trackEventWithProperties(
                  DashboardEvents.DASHBOARD_TAB_CLICKED,
                  { tabId: tab.id }
                )

                table.setGlobalFilter((globalFilter: GlobalFilterState) => ({
                  ...globalFilter,
                  tab: tab.hidden ? TabId.ALL : tab.id,
                }))
              }}
            >
              {isFunction(tab.label) ? tab.label(table) : tab.label}
            </TabButton>
          ))}
        </ButtonGroup>
      ))}
    </div>
  )
}

const TabButton = ({
  className,
  isActive,
  isHidden,
  onClick,
  children,
  ...other
}: {
  isActive: boolean
  isHidden?: boolean
  onClick: () => void
} & ComponentProps<typeof Button>) => {
  const classes = useStyles()
  return (
    <Button
      {...other}
      className={clsx(
        className,
        classes.tabButton,
        {
          [classes.tabButtonActive]: isActive,
        },
        isHidden && !isActive && "hidden"
      )}
      disableRipple
      onClick={onClick}
    >
      {children}
      {isHidden && <img className="ml-2" src={CloseCircleIcon} alt="" />}
    </Button>
  )
}
