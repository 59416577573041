import { createContext, useContext, useMemo } from "react"

import { merge } from "lodash"

import useEventCallback from "app/hooks/use-event-callback"
import useAppSelector from "app/hooks/useAppSelector"
import { getIdentifier } from "app/swr/helpers/resource"
import useCachedCollection from "app/swr/hooks/use-cached-collection"
import useCachedResource from "app/swr/hooks/use-cached-resource"
import useCollectionSWR from "app/swr/hooks/use-collection-swr"
import useMutateResource from "app/swr/hooks/use-mutate-resource"
import { ResourceResponse } from "app/swr/types"
import resourceRequest from "app/swr/utils/resource-request"
import { FeaturePromotion } from "types/feature-promotion"

export enum FeaturePromotions {
  RupaBloodReportsIntroductionModal = "RupaBloodReportsIntroductionModal",
  RupaBloodReportsIntroductionLabshopBanner = "RupaBloodReportsIntroductionLabshopBanner",
  CatalogWelcomeModal = "CatalogWelcomeModal",
}

type FeaturePromotionContextProps = { identifierByKey: {}; mutate: () => void }

export const FeaturePromotionContext =
  createContext<FeaturePromotionContextProps>({
    identifierByKey: {},
    mutate: () => {},
  })

export function FeaturePromotionProvider({ children }) {
  const user = useAppSelector((state) => state.auth.user)
  const practitioner = useAppSelector((state) => state.practitioner)
  const { data: identifiers, mutate } = useCollectionSWR(
    !!user && practitioner && Object.keys(practitioner).length !== 0
      ? `/feature_promotions/`
      : null,
    {},
    {
      revalidateOnFocus: true,
      revalidateOnMount: true,
      revalidateOnReconnect: true,
      revalidateIfStale: true,
      // Allow this to fail silently. We don't want to warn the prac if this fails to load.
      shouldHandleApiError: false,
    }
  )
  const promotions = useCachedCollection<FeaturePromotion>(identifiers)

  const identifierByKey = useMemo(
    () =>
      promotions.reduce(
        (byKey, promotion) => ({
          ...byKey,
          [promotion.attributes.key]: getIdentifier(promotion),
        }),
        {}
      ),
    [promotions]
  )

  const contextValue = useMemo(
    () => ({ identifierByKey, mutate }),
    [identifierByKey, mutate]
  )

  return (
    <FeaturePromotionContext.Provider value={contextValue}>
      {children}
    </FeaturePromotionContext.Provider>
  )
}

export function useRefreshFeaturePromotions() {
  const { mutate } = useContext(FeaturePromotionContext)
  return useEventCallback(async () => {
    mutate()
  })
}

export function useIsFeaturePromotionActive(key: string) {
  const { identifierByKey } = useContext(FeaturePromotionContext)
  const promotion = useCachedResource<FeaturePromotion>(identifierByKey[key])
  return Boolean(promotion?.attributes.show)
}

export function useFeaturePromotionAction() {
  const { identifierByKey } = useContext(FeaturePromotionContext)
  const mutateResource = useMutateResource()
  return useEventCallback(
    async (
      key: string,
      action?: { dismiss?: boolean; acknowledge?: boolean }
    ) => {
      const promotionIdentifier = identifierByKey[key]
      await mutateResource(
        promotionIdentifier,
        async (currentData) => {
          if (currentData) {
            const response = await resourceRequest<
              ResourceResponse<FeaturePromotion>
            >({
              method: "patch",
              url: `/feature_promotions/${promotionIdentifier.id}/`,
              data: {
                data: {
                  ...currentData,
                  attributes: {
                    ...currentData.attributes,
                    dismissed: action?.dismiss,
                    acknowledged: action?.acknowledge,
                  },
                },
              },
            })
            return {
              ...currentData,
              attributes: {
                ...currentData.attributes,
                dismissed: response.data.attributes.dismissed,
                acknowledged: response.data.attributes.acknowledged,
                show: false,
              },
            }
          }
        },
        {
          optimisticData: (current) =>
            merge({}, current, {
              ...current,
              attributes: {
                dismissed: action?.dismiss,
                acknowledged: action?.acknowledge,
                show: false,
              },
            }),
          rollbackOnError: true,
        }
      )
    }
  )
}

export function useFeaturePromotion(key: string) {
  const featurePromotionAction = useFeaturePromotionAction()
  return {
    isActive: useIsFeaturePromotionActive(key),
    onAcknowledge: () => featurePromotionAction(key, { acknowledge: true }),
    onDismiss: () => featurePromotionAction(key, { dismiss: true }),
  }
}
