import axios from "axios"
import useSWR from "swr"

import { getApiBaseUrl, handleApiError } from "app/utils"

import { InventoryItem } from "./inventory-types"

export default function useClinicInventory(clinicId: string | number) {
  // If this endpoint ever uses query params, the key will need to be
  // updated to include themso that SWR retriggers validation
  const key = `/clinic/${clinicId}/iok_inventory/`
  const { data, error, isLoading, isValidating, mutate } = useSWR<
    InventoryItem[] | undefined
  >(key, {
    async fetcher(key) {
      try {
        const response = await axios.request<InventoryItem[]>({
          baseURL: getApiBaseUrl() + "/api/",
          url: key,
          method: "get",
        })

        return response.data
      } catch (error) {
        handleApiError(error)
        return undefined
      }
    },
    revalidateOnMount: true,
  })
  return { data, error, isLoading, isValidating, mutate }
}
