import { useState } from "react"

import { orderBy } from "lodash"

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  styled,
} from "@material-ui/core"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import ListIcon from "@material-ui/icons/List"

import { ReactComponent as BiomarkerIcon } from "app/assets/icons/biomarker.svg"
import BodyText from "app/components/design-system/BodyText"
import { colors, primaryColor } from "app/theme"
import { Biomarker, LabTest, labTestLocation } from "app/types"

const StyledGridContainer = styled(Grid)({
  padding: "0px 25px",
})

const StyledAccordion = styled(Accordion)({
  boxShadow: "none",
  width: "100%",
  "&::before": {
    display: "none",
  },
  margin: "0px !important",
})

const SectionContainer = styled("div")({
  paddingTop: 18,
  paddingBottom: 18,
  borderBottom: "1px solid #E2E8F0",
  display: "flex",
  flexDirection: "column",
  alignItems: "start",
  width: "100%",
  height: "100%",
})

const StyledAccordionSummary = styled(AccordionSummary)({
  padding: 0,
  maxWidth: "100px",
})
const StyledAccordionDetails = styled(AccordionDetails)({
  padding: "0px 3px",
})

const StyledBiomarkerGridContainer = styled(Grid)(({ theme }) => ({
  marginTop: 12,

  [theme.breakpoints.up("sm")]: {
    marginTop: 0,
  },
}))

const StyledListIcon = styled(ListIcon)({
  color: colors.blueGray[500],
  fontSize: 24,
})

const BiomarkerName = styled("p")({
  paddingLeft: "6px",
  alignItems: "center",
  display: "flex",
})

const StyledExpandMoreIcon = styled(ExpandMoreIcon)({
  color: primaryColor,
  fontSize: 24,
})

const BiomarkerIconStyled = styled(BiomarkerIcon)({
  width: 18,
  height: 18,
  fill: colors.emerald[500],
})

const formatBiomarkers = (biomarkers: Biomarker[]) => {
  // Add name to biomarkers and order by that name
  return orderBy(
    (biomarkers = biomarkers.map((obj) => ({
      ...obj,
      name: obj.short_name ? obj.short_name : obj.long_name,
    }))),
    ["short_name"],
    ["asc"]
  )
}

interface Props {
  biomarkers: LabTest["biomarkers"]
  location: labTestLocation
}

const BiomarkersSection = ({ biomarkers, location }: Props) => {
  // The biomarkers should be open on the catalog page
  const [biomarkersAccordionOpen, setBiomarkersAccordionOpen] = useState(
    location === labTestLocation.CATALOG
  )

  const biomarkersSorted = formatBiomarkers(biomarkers)

  const biomarkersContainer = biomarkersAccordionOpen ? (
    <Grid container alignItems="baseline">
      <Grid container spacing={2}>
        {biomarkersSorted
          .slice(0, Math.ceil(biomarkersSorted.length / 2))
          .map((biomarker) => (
            <Grid
              key={biomarker.id}
              item
              xs={12}
              md={12}
              className="flex items-center"
            >
              <BiomarkerIconStyled />
              <BiomarkerName>{biomarker.name}</BiomarkerName>
            </Grid>
          ))}
      </Grid>
      <StyledBiomarkerGridContainer container spacing={2}>
        {biomarkersSorted
          .slice(
            Math.ceil(biomarkersSorted.length / 2),
            biomarkersSorted.length
          )
          .map((biomarker) => (
            <Grid
              key={biomarker.id}
              item
              xs={12}
              md={12}
              className="flex items-center"
            >
              <BiomarkerIconStyled />
              <BiomarkerName>{biomarker.name}</BiomarkerName>
            </Grid>
          ))}
      </StyledBiomarkerGridContainer>
    </Grid>
  ) : null

  return (
    <StyledGridContainer container aria-label="Biomarkers">
      <Grid item xs={12}>
        <SectionContainer>
          <BodyText size="sm">Biomarkers</BodyText>
          <StyledAccordion
            expanded={biomarkersAccordionOpen}
            onChange={() =>
              setBiomarkersAccordionOpen(!biomarkersAccordionOpen)
            }
          >
            <StyledAccordionSummary expandIcon={<StyledExpandMoreIcon />}>
              <StyledListIcon />{" "}
              <BiomarkerName>
                {" "}
                {biomarkersAccordionOpen ? "Hide" : "Show"}
              </BiomarkerName>
            </StyledAccordionSummary>
            <StyledAccordionDetails>
              {biomarkersContainer}
            </StyledAccordionDetails>
          </StyledAccordion>
        </SectionContainer>
      </Grid>
    </StyledGridContainer>
  )
}

export default BiomarkersSection
