import { useSelector } from "react-redux"

import { faAngleRight } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import BodyText from "app/components/design-system/BodyText"
import SignatureAndLicenseModalButton from "app/components/modals/SignatureAndLicenseModal/SignatureAndLicenseModalButton"
import Banner from "app/main/dashboard/Banner"
import { colors } from "app/theme"
import { RootState } from "app/types"

interface MissingSignatureOrLicenseBannerProps {
  baseDisplayText?: string
  onModalClose?: () => void
  showWithoutBlockedOrders?: boolean
}

export default function MissingSignatureOrLicenseBanner({
  baseDisplayText = "To move forward with an order, ",
  onModalClose,
  showWithoutBlockedOrders = false,
}: MissingSignatureOrLicenseBannerProps) {
  const practitioner = useSelector(
    ({ practitioner }: RootState) => practitioner
  )

  const { surveyModalOpen } = useSelector(
    ({ dashboard }: RootState) => dashboard?.onboarding || ({} as any)
  )

  const onCloseSignatureAndLicenseModal = (fullVerificationComplete) => {
    // Update practitioner verification info
    if (fullVerificationComplete) {
      onModalClose?.()
    }
  }

  const licenseMissing = !practitioner?.has_valid_license
  const signatureMissing = !practitioner?.has_signature

  const isBannerDisplayed =
    !surveyModalOpen &&
    practitioner?.user?.is_verified &&
    !practitioner?.has_full_verification_info &&
    !practitioner?.is_clinic_staff &&
    (practitioner?.has_esignature_blocked_orders ||
      !practitioner.vendor_physician_authorization_enabled ||
      showWithoutBlockedOrders) &&
    (licenseMissing || signatureMissing)

  if (!isBannerDisplayed) {
    return null
  }

  let bodyText = baseDisplayText
  let linkText: string

  if (licenseMissing && signatureMissing) {
    bodyText += "please provide your e-signature and your professional license."
    linkText = "Upload License & Sign Now"
  } else if (licenseMissing) {
    bodyText += "please provide your professional license."
    linkText = "Upload License"
  } else {
    bodyText += "please provide your e-signature."
    linkText = "Sign Now"
  }

  return (
    <div>
      <Banner
        backgroundColor={colors.yellow[50]}
        borderColor={colors.yellow[400]}
      >
        <SignatureAndLicenseModalButton
          location="banner"
          onClose={onCloseSignatureAndLicenseModal}
          autoClose
          variant="ghost"
          wrapperClassName="w-full"
          className="group shadow-none p-0 hover:bg-yellow-50 w-full justify-start"
        >
          <BodyText className="text-yellow-900">
            {bodyText}
            <span className="text-yellow-900 font-semibold group-hover:underline ml-2">
              {linkText} <FontAwesomeIcon icon={faAngleRight} />
            </span>
          </BodyText>
        </SignatureAndLicenseModalButton>
      </Banner>
    </div>
  )
}
