import { ReactNode, useEffect, useState } from "react"

import clsx from "clsx"

import {
  FormControl,
  FormHelperText,
  OutlinedInput,
  Select,
  SelectProps,
} from "@material-ui/core"

import { colors } from "app/theme"

import useInputStyles from "./use-input-styles"

export type SelectInputProps = Omit<SelectProps, "variant"> & {
  helperText?: ReactNode
  hideEmptyOption?: boolean
}

const SelectInput = ({
  children,
  className,
  error,
  helperText,
  value,
  hideEmptyOption = false,
  ...fieldProps
}: SelectInputProps) => {
  const inputClasses = useInputStyles()
  const [isPlaceholder, setIsPlaceholder] = useState(true)

  useEffect(() => {
    setIsPlaceholder(value && value !== "" ? false : true)
  }, [value])

  const placeholderStyle = {
    color: colors.blueGray[400],
  }

  return (
    <FormControl error={error} fullWidth>
      <Select
        className={clsx(className, "fs-exclude")}
        error={error}
        fullWidth
        input={
          <OutlinedInput
            classes={inputClasses}
            style={isPlaceholder ? placeholderStyle : {}}
          />
        }
        native
        variant="outlined"
        value={value}
        {...fieldProps}
      >
        {!hideEmptyOption && (
          <option value="" className="placeholder-text">
            {fieldProps.placeholder ? fieldProps.placeholder : "Select"}
          </option>
        )}
        {children}
      </Select>
      {error && helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  )
}

export default SelectInput
