import * as Actions from "../actions"

const initialState = {
  patient: {},
  orders: null,
  scheduledOrders: null,
}

const patientReducer = function (state = initialState, action) {
  switch (action.type) {
    case Actions.GET_PATIENT:
    case Actions.UPDATE_PATIENT: {
      return {
        ...state,
        patient: action.payload,
      }
    }
    case Actions.GET_ORDERS: {
      return {
        ...state,
        orders: action.payload,
      }
    }
    case Actions.CLEAR_ORDERS: {
      return {
        ...state,
        orders: null,
      }
    }
    case Actions.RESET: {
      return initialState
    }
    case Actions.UPDATE_ORDER: {
      let updatedState = { ...state }

      const updateOrder = (o) =>
        o.id === action.payload.id ? action.payload : o

      if (state.orders) {
        updatedState.orders = {
          ...updatedState.orders,
          results: state.orders.results.map(updateOrder),
        }
      }

      if (state.scheduledOrders) {
        updatedState.scheduledOrders = {
          ...updatedState.scheduledOrders,
          results: state.scheduledOrders.results.map(updateOrder),
        }
      }

      return updatedState
    }
    case Actions.GET_SCHEDULED_ORDERS: {
      return {
        ...state,
        scheduledOrders: action.payload,
      }
    }
    default: {
      return state
    }
  }
}

export default patientReducer
