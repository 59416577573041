import { useMemo, useState } from "react"

import axios from "axios"
import useSWR from "swr"

import { ReactComponent as SelectionIcon } from "app/assets/icons/selection-icon.svg"
import { primaryColor } from "app/theme"
import { getApiBaseUrl, getOrderStatusMetadata } from "app/utils"

import DashboardFilter from "../DashboardFilter"

function useDashboardStatuses({
  inputValue,
  value,
}: {
  inputValue?: string
  value?: string
}) {
  const valueStatus = useMemo(
    () => (value ? getOrderStatusMetadata(value) : undefined),
    [value]
  )

  const { data: statuses = [], isLoading } = useSWR("/dashboard/status/", {
    async fetcher(url) {
      const response = await axios.request<{ data: string[] }>({
        baseURL: getApiBaseUrl() + "/api/",
        url,
        method: "get",
      })

      return response.data.data.map(getOrderStatusMetadata)
    },
    keepPreviousData: true,
    revalidateIfStale: true,
    revalidateOnFocus: true,
  })

  return {
    isLoading,
    // Filter client-side as the labels are not present in the server and will provide unexpected filtering.
    statuses: statuses.filter(
      (status: any) =>
        !inputValue ||
        inputValue === valueStatus?.label ||
        status.label.toLowerCase().includes(inputValue.toLowerCase())
    ),
    valueStatus,
  }
}

export interface DashboardStatusFilterProps {
  onFilterChange: (value?: string) => void
  value?: string
}

export default function DashboardStatusFilter({
  onFilterChange,
  value,
}: DashboardStatusFilterProps) {
  const [inputValue, setInputValue] = useState("")
  const { isLoading, statuses, valueStatus } = useDashboardStatuses({
    inputValue,
    value,
  })

  return (
    <DashboardFilter
      getOptionLabel={(option) => option.label}
      getOptionValue={(option) => option.value}
      icon={<SelectionIcon width={16} height={16} fill={primaryColor} />}
      inputValue={inputValue}
      isLoading={isLoading}
      label="Status"
      onChange={onFilterChange}
      onInputChange={setInputValue}
      options={statuses}
      value={valueStatus}
    />
  )
}
