import {
  getCoreRowModel,
  getFilteredRowModel,
  useReactTable,
} from "@tanstack/react-table"

import { useLabOrderingAccess } from "../LabOrderingAccessDataProvider"
import {
  CredentialColumn,
  LabCompanyLogoColumn,
  LabCompanyNameColumn,
  PhysicianServicesColumn,
} from "./LabOrderingAccessTableColumns"
import LabOrderingAccessTableRenderer from "./LabOrderingAccessTableRenderer"

const LabOrderingAccessTable: React.FC = () => {
  const { labCompanies, isLoading, isValidating } = useLabOrderingAccess()

  const columns = [
    LabCompanyNameColumn,
    LabCompanyLogoColumn,
    CredentialColumn,
    PhysicianServicesColumn,
  ]

  const table = useReactTable({
    data: labCompanies,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getRowId: (row) => row.id,
    meta: { isLoading, isValidating, count: labCompanies.length },
  })

  return <LabOrderingAccessTableRenderer table={table} />
}

export default LabOrderingAccessTable
