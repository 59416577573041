import useEventCallback from "app/hooks/use-event-callback"
import useHandleApiError from "app/hooks/use-handle-api-error"
import { UseResultsVisualizerResourcesHook } from "app/results-summary/hooks/use-results-summary-resources"
import { sendPatchInterpretation } from "app/results-summary/utils/api"
import { ResourceIdentifier } from "app/swr/types"

export default function useReorderTasks({
  identifier,
  mutate,
}: UseResultsVisualizerResourcesHook["interpretationSwr"]) {
  const handleApiError = useHandleApiError()
  return useEventCallback(async (nextIdentifiers: ResourceIdentifier[]) => {
    if (!identifier) {
      return
    }

    const sendPatchRequest = async () => {
      try {
        return sendPatchInterpretation(identifier, {
          relationships: {
            tasks: {
              data: nextIdentifiers,
            },
          },
        })
      } catch (error) {
        handleApiError(error)

        // re-throw
        throw error
      }
    }

    return mutate(sendPatchRequest, {
      optimisticData(currentData) {
        if (!currentData) {
          // should never be reached, but type error without
          return undefined as any
        }

        return {
          ...currentData,
          relationships: {
            ...currentData.relationships,
            tasks: {
              ...currentData.relationships.tasks,
              data: nextIdentifiers,
            },
          },
        }
      },
      rollbackOnError: true,
      revalidate: false,
    })
  })
}
