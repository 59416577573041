import * as React from "react"
import { useState } from "react"

import clsx from "clsx"

import { faCircleInfo } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import { primaryColor } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"

const useStyles = makeAppStyles((theme) => ({
  container: {
    width: "100%",
  },
  mainCard: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "6px 24px",
    [theme.breakpoints.down("sm")]: {
      flexWrap: "wrap",
    },
  },
  nameSection: {
    width: "35%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  valueSection: {
    display: "flex",
    flexGrow: 1,
    justifyContent: "center",
    flexDirection: "row",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginTop: 10,
      alignItems: "flex-start",
      justifyContent: "flex-start",
    },
  },
  graphicSection: {
    width: "35%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginTop: 10,
    },
  },
  detailsLink: {
    color: primaryColor,
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      marginLeft: 15,
    },
  },
  arrow: {
    marginLeft: 5,
    transition: "transform 0.2s ease-in-out",
  },
  arrowFlipped: {
    transform: "rotate(180deg)",
  },
  expandedContent: {
    display: "flex",
    flexDirection: "column",
    paddingLeft: 22,
    paddingRight: 22,
    gap: "15px",
    maxHeight: 0,
    overflow: "hidden",
    transition: "max-height 0.3s ease-in-out, padding 0.3s ease-in-out",
  },
  expanded: {
    /* max height for animation */
    maxHeight: "1000px",
    paddingBottom: 22,
  },
}))

export default function BloodLabDashboardsBiomarkerCard({
  name,
  valueSection,
  graphicSection,
  children,
  isHovered,
}: {
  name: string | undefined
  valueSection: React.ReactNode
  graphicSection: React.ReactNode
  children: React.ReactNode
  isHovered?: boolean
}) {
  const classes = useStyles()
  const [isExpanded, setIsExpanded] = useState(false)

  return (
    <div className={classes.container}>
      <div className={classes.mainCard}>
        <div className={classes.nameSection}>
          <div className="font-medium leading-normal">{name} </div>
          <FontAwesomeIcon
            className={clsx(
              "fa-light text-primary cursor-pointer ml-1",
              isHovered ? "opacity-100" : "opacity-0"
            )}
            icon={faCircleInfo}
            onClick={() => setIsExpanded(!isExpanded)}
          />
        </div>
        <div className={classes.valueSection}>{valueSection}</div>
        <div className={classes.graphicSection}>{graphicSection}</div>
      </div>

      <div
        className={clsx(
          classes.expandedContent,
          isExpanded ? classes.expanded : ""
        )}
      >
        {children}
      </div>
    </div>
  )
}
