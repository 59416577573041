export const MIN_DISTANCE_FROM_TOP_FOR_STICKY_DIV = 14

export enum BiomarkerStatus {
  LOW = "low",
  BELOW_OPTIMAL = "below_optimal",
  OPTIMAL = "optimal",
  NORMAL = "normal",
  ABOVE_OPTIMAL = "above_optimal",
  HIGH = "high",
  ABNORMAL = "abnormal",
}

export interface BodySystemTab {
  id: string
  name: string
}

export const bloodLabDashboardEvents = {
  PATIENT_DOWNLOAD_ORIGINAL_REPORT: "bld_patient_download_original_report",
}

export const patientDownloadOriginalReportSource = {
  BLOOD_LAB_DASHBOARD: "blood_lab_dashboard",
  PATIENT_PORTAL: "patient_portal",
}
