import { useState } from "react"

import { find, findIndex } from "lodash"

import {
  closestCenter,
  PointerSensor,
  KeyboardSensor,
  useSensor,
  useSensors,
  DndContext,
  DragOverlay,
} from "@dnd-kit/core"
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable"
import { CSS } from "@dnd-kit/utilities"
import { Divider } from "@material-ui/core"

import useEventCallback from "app/hooks/use-event-callback"
import BiomarkerEditor from "app/results-summary/components/ResultsSummaryEditor/components/BiomarkerEditor"
import { ResourceIdentifier } from "app/swr/types"
import { colors } from "app/theme"
import interleave from "app/utils/interleave"
import makeAppStyles from "app/utils/makeAppStyles"

const useStyles = makeAppStyles((theme) => ({
  sortableBiomarkerList: {
    width: "100%",
    display: "flex",
    flexFlow: "column nowrap",
    marginTop: theme.spacing(2.0),
    gap: theme.spacing(2.0),
  },
  divider: {
    background: colors.blueGray[100],
  },
}))

const SortableBiomarkerEditor = ({
  identifier,
}: {
  identifier: ResourceIdentifier
}) => {
  const {
    attributes,
    isDragging,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id: identifier.id })
  const style = {
    opacity: isDragging ? 0.4 : undefined,
    transform: CSS.Transform.toString(transform),
    transition,
  }
  return (
    <BiomarkerEditor
      dragHandleProps={{
        ...attributes,
        ...listeners,
      }}
      identifier={identifier}
      ref={setNodeRef}
      style={style}
    />
  )
}

export default function SortableBiomarkerListEditor({
  identifiers,
  onReorder,
}: {
  identifiers: ResourceIdentifier[]
  onReorder: (reorderedIdentifiers: ResourceIdentifier[]) => void
}) {
  const classes = useStyles()
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  )
  const [activeId, setActiveId] = useState<string | null>(null)
  const activeIdentifier = find(identifiers, { id: activeId }) as
    | ResourceIdentifier
    | undefined

  const handleDragStart = useEventCallback(function handleDragStart(event) {
    const { active } = event
    setActiveId(active.id)
  })

  const handleDragEnd = useEventCallback(function handleDragEnd(event) {
    const { active, over } = event

    if (active.id !== over.id) {
      const oldIndex = findIndex(identifiers, { id: active.id })
      const newIndex = findIndex(identifiers, { id: over.id })

      const nextIdentifiers = arrayMove(identifiers, oldIndex, newIndex)
      onReorder(nextIdentifiers)
    }

    setActiveId(null)
  })

  return (
    <div className={classes.sortableBiomarkerList}>
      <DndContext
        collisionDetection={closestCenter}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
        sensors={sensors}
      >
        <SortableContext
          items={identifiers}
          strategy={verticalListSortingStrategy}
        >
          {interleave(
            identifiers.map((identifier) => (
              <SortableBiomarkerEditor
                key={identifier.id}
                identifier={identifier}
              />
            )),
            (elem) => (
              <Divider
                key={`divider-${elem.key}`}
                className={classes.divider}
              />
            )
          )}
        </SortableContext>

        <DragOverlay className={classes.dragOverlay}>
          {activeIdentifier ? (
            <BiomarkerEditor identifier={activeIdentifier} overlay />
          ) : null}
        </DragOverlay>
      </DndContext>
    </div>
  )
}
