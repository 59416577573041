import { lightGrayBlue, shadows } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"

const usePatientOrderStyles = makeAppStyles((theme) => ({
  button: {
    alignItems: "center",
    padding: "12px",
    border: `1px solid ${lightGrayBlue}`,

    maxWidth: 300,
    width: "100%",
    height: "auto",

    boxShadow: shadows.sm,

    [theme.breakpoints.up("sm")]: {
      marginTop: 0,
      marginBottom: 0,
    },

    [theme.breakpoints.down("md")]: {
      maxWidth: "none",
    },
  },
  buttonContainer: {
    maxWidth: 300,
    width: "100%",
    height: "auto",
    [theme.breakpoints.down("md")]: {
      maxWidth: "none",
    },
  },
}))

export default usePatientOrderStyles
