/**
 * The AutoUpload component is used to provide a single dropzone where files
 * are automatically uploaded to the specified endpoint.
 *
 * It takes a `children` prop, which should be a Dropzone component.
 *
 * Note: this is a modified version of the Upload component, which handles
 * multi-file, non-instant uploads.
 */
import { useEffect, useState } from "react"

import authService, {
  BEARER_TOKEN_PREFIX,
} from "app/auth/services/simplejwt-auth-service"
import { getApiBaseUrl } from "app/utils"

import BaseFileInput, { BaseFileInputProps } from "./BaseFileInput"

interface Props extends Omit<BaseFileInputProps, "onFilesAdded"> {
  fileContentFieldName: string
  handleUploadFail: Function
  handleUploadStart?: Function
  handleUploadSuccess?: Function
  uploadUrl: string
}

enum UploadProgressState {
  PENDING = "pending",
  DONE = "done",
  ERROR = "error",
}
interface UploadProgress {
  state?: UploadProgressState
  progress?: Number
}

const AutoUpload = ({
  children,
  fileContentFieldName,
  handleUploadStart,
  handleUploadSuccess,
  handleUploadFail,
  uploadUrl,
  ...props
}: Props) => {
  const [files, setFiles] = useState<Array<File>>([])
  const [uploadProgress, setUploadProgress] = useState<UploadProgress>({})

  const onFilesAdded = (files: Array<File>): void => {
    setFiles(files)
  }

  useEffect(() => {
    if (files.length > 0) {
      uploadFiles()
    }
  }, [files])

  const uploadFiles = async () => {
    setUploadProgress({})
    const promises: Promise<void>[] = []
    files.forEach((file) => {
      promises.push(sendRequest(file))
    })
    try {
      if (handleUploadStart) {
        handleUploadStart()
      }
      const responses = await Promise.all(promises)

      if (handleUploadSuccess) {
        handleUploadSuccess(responses)
      }
      // Clear files to prevent automatic re-uploads
      setFiles([])
    } catch (e) {
      handleUploadFail()
    }
  }

  const sendRequest = async (file: File): Promise<void> => {
    const accessToken = await authService.getAccessToken()

    return new Promise((resolve, reject) => {
      const req = new XMLHttpRequest()
      req.upload.addEventListener("progress", (event): void => {
        if (event.lengthComputable) {
          const copy = { ...uploadProgress }
          copy[file.name] = {
            state: UploadProgressState.PENDING,
            percentage: (event.loaded / event.total) * 100,
          }
          setUploadProgress(copy)
        }
      })

      req.onreadystatechange = function receiveResponse() {
        if (this.readyState === 4) {
          if (this.status === 201) {
            const copy = { ...uploadProgress }
            copy[file.name] = {
              state: UploadProgressState.DONE,
              percentage: 100,
            }
            setUploadProgress(copy)
            resolve(req.response)
          } else {
            handleUploadFail()
            reject(req.response)
          }
        }
      }

      req.upload.addEventListener("error", (event): void => {
        const copy = { ...uploadProgress }
        copy[file.name] = { state: UploadProgressState.ERROR, percentage: 0 }
        setUploadProgress(copy)
        reject(req.response)
      })

      const formData = new FormData()
      const fieldName = fileContentFieldName
        ? fileContentFieldName
        : "file_content"
      formData.append(fieldName, file, file.name)

      req.open("POST", getApiBaseUrl() + uploadUrl)
      req.setRequestHeader(
        "Authorization",
        `${BEARER_TOKEN_PREFIX} ${accessToken}`
      )
      req.send(formData)
    })
  }

  return (
    <BaseFileInput onFilesAdded={onFilesAdded} {...props}>
      {children}
    </BaseFileInput>
  )
}

export default AutoUpload
