import { z } from "zod"

import { formatDollars } from "app/utils"

export const CreateCustomFeeSchema = (maxPrice: number) =>
  z.object({
    name: z
      .string()
      .nonempty("A default fee name is required.")
      .regex(/^((?!rupa).)*$/gi, "Fee name cannot reference Rupa."),
    price: z
      .number()
      .or(
        z
          .string()
          .nonempty("A default fee price is required.")
          .regex(/^\d*\.?\d{0,2}$/)
          .transform(Number)
      )
      .refine(
        (n) => n >= 0,
        "A default fee price greater than $0.00 is required"
      )
      .refine(
        (n) => 1 <= n && n <= maxPrice / 100,
        `A Custom Fee can be between $1 to ${formatDollars(maxPrice / 100)}`
      ),
  })
