import React, { useMemo } from "react"

import { Button, cn } from "@rupahealth/design"

import RupaLoadingButton from "app/components/RupaLoadingButton"
import { SpecimenIssueActionUnion } from "app/constants.typed"

import { formatSpecimenIssueActionForSuccess } from "../helpers"
import { SpecimenIssueResolveModalProps } from "./SpecimenIssueResolveModal"

interface Props
  extends Pick<SpecimenIssueResolveModalProps, "specimenIssue" | "patient"> {
  image: string
  actionChosen: SpecimenIssueActionUnion
  submitting: boolean
  onSubmit: () => void
  onGoBack: () => void
}

const ModalContentStep2Confirm: React.FC<Props> = ({
  actionChosen,
  patient,
  image,
  submitting,
  onGoBack,
  onSubmit,
}) => {
  const description = useMemo(() => {
    return formatSpecimenIssueActionForSuccess(
      actionChosen,
      patient?.first_name
    )
  }, [actionChosen, patient])

  return (
    <div className="h-full">
      <img
        alt="hedgehog"
        src={image}
        className="border-b border-slate-200 w-full rounded-t-lg"
      />
      <div className="py-6 px-8 flex flex-col gap-[27px] text-center">
        <div className="flex flex-col gap-[6px]">
          <p className="text-2xl font-semibold font-title">All set!</p>
          <p className="text-base15">{description}</p>
        </div>
        <div
          className={cn(
            "flex flex-col space-y-2 justify-center",
            "sm:space-y-0 sm:flex-row sm:space-x-3.5"
          )}
        >
          <Button
            variant="outline"
            className="w-full shadow-sm pt-3 pb-2 text-base15 font-title font-semibold"
            onClick={onGoBack}
            disabled={submitting}
          >
            Go Back
          </Button>
          <RupaLoadingButton
            variant="primary"
            className="w-full shadow-sm pt-3 pb-2 text-base15 font-title font-semibold text-white"
            wrapperClassName="w-full"
            onClick={onSubmit}
            loading={submitting}
          >
            Confirm!
          </RupaLoadingButton>
        </div>
      </div>
    </div>
  )
}

export default ModalContentStep2Confirm
