import { faChevronRight } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { makeStyles } from "@material-ui/core"

import { ReactComponent as QuestionCircleIcon } from "app/assets/icons/rupa-blood-dashboards/question-circle.svg"
import Tooltip from "app/components/Tooltip"
import Button from "app/components/design-system/Button"
import useFeatureFlag from "app/hooks/use-feature-flag"
import { FeatureFlag } from "app/providers/FeatureFlagProvider"
import { colors } from "app/theme"

import WarningContainer, { WarningType } from "../warnings/WarningContainer"
import {
  getVibrantBundleCardWarning,
  getVibrantBundleCardWarningTooltip,
} from "../warnings/utils"

const useStyles = makeStyles(() => ({
  questionIcon: {
    "& path": {
      fill: colors.amber[900],
    },
  },
}))

export default function VibrantBundleCardWarning({
  handleUpdateClick,
  onOpen,
  onClose,
}) {
  const styles = useStyles()
  const [isVibrantDisabled] = useFeatureFlag(FeatureFlag.VibrantDisabled)
  const message = getVibrantBundleCardWarning(isVibrantDisabled)
  const title = getVibrantBundleCardWarningTooltip(isVibrantDisabled)

  return (
    <Tooltip arrow placement="top" title={title} className="cursor-help">
      <div className="my-[10px]" onMouseEnter={onOpen} onMouseLeave={onClose}>
        <WarningContainer condensed warningType={WarningType.WARNING}>
          <div className="flex gap-x-2 flex-wrap items-center">
            <QuestionCircleIcon className={styles.questionIcon} />
            {message}
            <Button
              onClick={handleUpdateClick}
              color="text"
              type="button"
              className="text-inherit"
            >
              <span className="whitespace-nowrap underline text-[14px]">
                Update
                <FontAwesomeIcon
                  icon={faChevronRight}
                  className="self-center"
                />
              </span>
            </Button>
          </div>
        </WarningContainer>
      </div>
    </Tooltip>
  )
}
