import { useRef, useState } from "react"

import clsx from "clsx"
import { isEmpty } from "lodash"

import * as Popover from "@radix-ui/react-popover"

import CustomTooltip, {
  CustomTooltipData,
} from "app/main/results-over-time/chart-components/CustomTooltip"
import { BiomarkerStatus } from "app/patient-portal/blood-lab-dashboard/constants"
import { colors, navy } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"

import { parseStringValueToFloat } from "../utils"
import { gradients } from "./constants"
import {
  getColorFromGradient,
  getLimitsFromValue,
  getRatioBetweenPoints,
  getActiveSectionLabelFromStatus,
  getMiddleSectionLabel,
} from "./utils"

const RANGE_HEIGHT_NORMAL = 10
const RANGE_HEIGHT_SMALL = 6

const useStyles = makeAppStyles<{ isCondensed: boolean }>((theme) => ({
  root: {
    width: "100%",
    display: "flex",
    flexFlow: "column nowrap",
    alignItems: "center",
    gap: theme.spacing(0.75),
  },
  labelVisible: {
    opacity: 1,
    transition: "opacity 0.25s ease-in-out",
  },
  labelHidden: {
    opacity: 0,
    transition: "opacity 0.25s ease-in-out",
    pointerEvents: "none", // Prevents interaction while invisible
  },
  gradientContainer: {
    paddingTop: 20,
    position: "relative",
  },
  status: {
    fontSize: (props) => (props.isCondensed ? 11 : 12),
    marginBottom: -20,
    fontWeight: 700,
    textTransform: "uppercase",
    color: navy,
    textAlign: "center",
  },
  individualStatus: {
    width: "auto", // Change from 100% to auto to allow natural content width
    height: 17,
    fontSize: (props) => (props.isCondensed ? 11 : 12),
    fontWeight: 700,
    position: "absolute",
    left: "50%", // Center the element horizontally relative to its parent
    transform: "translateX(-50%)", // Shift it back to ensure true centering
    overflow: "visible",
    textTransform: "uppercase",
    color: colors.blueGray[400],
    textAlign: "center",
    "&[data-active='true']": {
      color: navy,
    },
    marginBottom: 4,
    whiteSpace: "nowrap",
  },
  ranges: {
    position: "relative",
    width: "100%",
    display: "flex",
    flexFlow: "row nowrap",
    gap: (props) => (props.isCondensed ? 2 : 4),
  },
  nonNormalContainer: {
    flex: 1,
    fontSize: 14,
    fontWeight: 600,
    color: colors.blueGray[400],
    position: "relative",
  },
  normalContainer: {
    flex: 2,
    "&[data-has-min='true'], &[data-has-max='true']": {
      flex: 3,
    },
    "&[data-has-optimal-min='true'], &[data-has-optimal-max='true']": {
      flex: 1,
    },
    height: (props) =>
      (props.isCondensed ? RANGE_HEIGHT_SMALL : RANGE_HEIGHT_NORMAL) * 2,
    fontSize: 14,
    fontWeight: 600,
    color: colors.blueGray[400],
    position: "relative",
  },
  labelRight: {
    fontSize: (props) => (props.isCondensed ? 11 : 12),
    textTransform: "uppercase",
    textAlign: "right",
    marginRight: -16,
    marginTop: 4,
    "&[data-label-active='true']": {
      color: navy,
    },
  },
  labelLeft: {
    fontSize: (props) => (props.isCondensed ? 11 : 12),
    textTransform: "uppercase",
    textAlign: "left",
    marginLeft: -16,
    marginTop: 4,
    "&[data-label-active='true']": {
      color: navy,
    },
  },
  rangeItem: {
    position: "relative",
    flex: 1,
    height: (props) =>
      props.isCondensed ? RANGE_HEIGHT_SMALL : RANGE_HEIGHT_NORMAL,
    borderRadius: 100,
  },
  lowRangeItem: {
    background: gradients.abnormal.inactive,
    "&[data-active='true']": {
      background: gradients.abnormal.active,
    },
  },
  belowOptimalRangeItem: {
    background: gradients.nonOptimal.inactive,
    "&[data-active='false']": {
      opacity: "0.2",
    },
  },
  normalRangeItem: {
    height: (props) =>
      props.isCondensed ? RANGE_HEIGHT_SMALL : RANGE_HEIGHT_NORMAL,
    borderRadius: 100,
    background: gradients.normal.active,
    "&[data-has-min='false'][data-has-optimal-min='false']": {
      background: gradients.normalDirectional.active,
    },
    "&[data-has-max='false'][data-has-optimal-max='false']": {
      background: gradients.normalDirectional.active,
      transform: "matrix(-1, 0, 0, 1, 0, 0)",
    },
    "&[data-active='false']": {
      opacity: "0.2",
    },
    position: "relative",
  },
  aboveOptimalRangeItem: {
    background: gradients.nonOptimal.inactive,
    transform: "matrix(-1, 0, 0, 1, 0, 0)",
    "&[data-active='false']": {
      opacity: "0.2",
    },
  },
  highRangeItem: {
    background: gradients.abnormal.inactive,
    transform: "matrix(-1, 0, 0, 1, 0, 0)",
    "&[data-active='true']": {
      background: gradients.abnormal.active,
    },
  },
  marker: {
    zIndex: 1,
    position: "absolute",
    top: 13.5,
    marginLeft: -6.5,
    width: 23,
    height: 23,
    borderRadius: "50%",
    border: "4.5px solid white",
    filter: "drop-shadow(0px 0px 7.977023124694824px rgba(77, 199, 95, 0.30))",
  },
  ovalMarker: {
    zIndex: 1,
    position: "absolute",
    top: 13.5,
    marginLeft: -6.5,
    width: 8.128,
    height: 17.69,
    borderRadius: 28,
    border: "2px solid white",
  },
}))

export default function NumericBiomarkerGraphic({
  value,
  normalMax,
  normalMin,
  optimalMax,
  optimalMin,
  unit,
  status,
  labCompanyName,
  singleLabel = true,
  hideTooltip = false,
  isCondensed = false,
  highlightCenter = false,
  isHovered = true,
}: {
  value: string
  normalMax: string | undefined
  normalMin: string | undefined
  optimalMax: string | undefined
  optimalMin: string | undefined
  unit?: string
  status: BiomarkerStatus
  labCompanyName?: string
  singleLabel?: boolean
  hideTooltip?: boolean
  isCondensed?: boolean
  highlightCenter?: boolean
  isHovered?: boolean
}) {
  const classes = useStyles({ isCondensed })

  // Whether or not to generally show labels
  const showLabels = isHovered || !isCondensed

  const hasNormalMin = !isEmpty(normalMin)
  const hasNormalMax = !isEmpty(normalMax)
  const hasOptimalMin = !isEmpty(optimalMin)
  const hasOptimalMax = !isEmpty(optimalMax)

  const [showTooltip, setShowTooltip] = useState(false)

  const gradientContainerRef = useRef<any>(null)

  const ratio = getRatioBetweenPoints(
    value,
    normalMin,
    normalMax,
    optimalMin,
    optimalMax
  )
  const { upperValue, lowerValue } = getLimitsFromValue(
    value,
    normalMin,
    normalMax,
    optimalMin,
    optimalMax
  )

  const markerPosition = ratio * 100 + "%"

  if (!hasNormalMin && !hasNormalMax && !hasOptimalMin && !hasOptimalMax) {
    // No point in rendering a graphic if there is not range of values.
    return null
  }

  const resultData: CustomTooltipData = {
    status: status ?? undefined,
    value: parseStringValueToFloat(value),
    display_value: value,
    unit,
    name: "",
    subText: labCompanyName
      ? value
        ? `${labCompanyName} test imported by clinic`
        : `From ${labCompanyName} test`
      : "",
  }

  const optimalSectionActive =
    (highlightCenter && status === BiomarkerStatus.NORMAL) ||
    status === BiomarkerStatus.OPTIMAL ||
    status === BiomarkerStatus.BELOW_OPTIMAL ||
    status === BiomarkerStatus.ABOVE_OPTIMAL

  const { sectionLabelValue, secondarySectionLabelValue } =
    getActiveSectionLabelFromStatus(status, isCondensed)

  return (
    <div className={classes.root}>
      {/* Show single label centered across the top of graphic if the values are not high or low and single label is enabled.
          The high and low labels still will appear above the high and low sections */}
      {singleLabel &&
        status !== BiomarkerStatus.LOW &&
        status !== BiomarkerStatus.HIGH && (
          <div
            className={clsx(classes.status, {
              [classes.labelVisible]: showLabels,
              [classes.labelHidden]: !showLabels,
            })}
          >
            {sectionLabelValue}
            {secondarySectionLabelValue ? (
              <span className="font-semibold">
                {" "}
                {secondarySectionLabelValue}
              </span>
            ) : (
              ""
            )}
          </div>
        )}

      <div className={classes.ranges}>
        {hasNormalMin && (
          <NonNormalSection
            isCondensed={isCondensed}
            section={BiomarkerStatus.LOW}
            sectionActive={status === BiomarkerStatus.LOW}
            sectionMarkerActive={
              status === BiomarkerStatus.LOW && Boolean(value)
            }
            ratio={ratio}
            numberLabelValue={normalMin}
            numberLabelActive={
              lowerValue === normalMin || upperValue === normalMin
            }
            numberLabelDirection="right"
            singleLabel={singleLabel}
            markerPosition={markerPosition}
            gradient={gradients.abnormal.active}
            gradientReversed={true}
            rangeItemClass={classes.lowRangeItem}
            resultData={resultData}
            hideTooltip={hideTooltip}
            showLabels={showLabels}
          />
        )}

        {hasOptimalMin && (
          <NonNormalSection
            isCondensed={isCondensed}
            section={BiomarkerStatus.BELOW_OPTIMAL}
            sectionActive={
              status === BiomarkerStatus.BELOW_OPTIMAL || optimalSectionActive
            }
            sectionMarkerActive={
              status === BiomarkerStatus.BELOW_OPTIMAL && Boolean(value)
            }
            ratio={ratio}
            numberLabelValue={optimalMin}
            numberLabelActive={
              lowerValue === optimalMin || upperValue === optimalMin
            }
            numberLabelDirection="right"
            singleLabel={singleLabel}
            markerPosition={markerPosition}
            gradient={gradients.nonOptimal.active}
            rangeItemClass={classes.belowOptimalRangeItem}
            resultData={resultData}
            hideTooltip={hideTooltip}
            showLabels={showLabels}
          />
        )}

        {/* Normal section of graphic */}
        <div
          className={classes.normalContainer}
          data-active={
            status === BiomarkerStatus.NORMAL ||
            status === BiomarkerStatus.OPTIMAL ||
            optimalSectionActive
          }
          data-has-min={hasNormalMin}
          data-has-max={hasNormalMax}
          data-has-optimal-min={hasOptimalMin}
          data-has-optimal-max={hasOptimalMax}
        >
          <SectionLabel
            isCondensed={isCondensed}
            labelValue={getMiddleSectionLabel(hasOptimalMin, hasOptimalMax)}
            labelActive={
              status === BiomarkerStatus.NORMAL ||
              status === BiomarkerStatus.OPTIMAL
            }
            showLabels={showLabels}
            labelVisible={!singleLabel}
          />
          <div className={classes.gradientContainer} ref={gradientContainerRef}>
            {(status === BiomarkerStatus.NORMAL ||
              status === BiomarkerStatus.OPTIMAL) && (
              <Popover.Root
                open={showTooltip && !singleLabel && !hideTooltip}
                onOpenChange={setShowTooltip}
                modal={false}
              >
                <Popover.Trigger
                  asChild
                  onClick={(e) => {
                    if (showTooltip) {
                      // disable closing on click
                      e.preventDefault()
                    } else {
                      setShowTooltip(true)
                    }
                  }}
                >
                  {/* This div is the marker */}
                  {Boolean(value) && (
                    <div
                      className={
                        isCondensed ? classes.ovalMarker : classes.marker
                      }
                      style={{
                        left: markerPosition,
                        backgroundColor: getColorFromGradient(
                          gradients.normal.active,
                          ratio * 100
                        ),
                      }}
                      onMouseEnter={() => setShowTooltip(true)}
                      onMouseLeave={() => setShowTooltip(false)}
                    />
                  )}
                </Popover.Trigger>
                <Popover.Content side="top" sideOffset={10}>
                  <CustomTooltip data={resultData} />
                </Popover.Content>
              </Popover.Root>
            )}
            <div
              data-has-min={hasNormalMin}
              data-has-max={hasNormalMax}
              data-has-optimal-min={hasOptimalMin}
              data-has-optimal-max={hasOptimalMax}
              className={classes.normalRangeItem}
              data-active={
                status === BiomarkerStatus.NORMAL ||
                status === BiomarkerStatus.OPTIMAL ||
                optimalSectionActive
              }
            />
          </div>
        </div>

        {hasOptimalMax && (
          <NonNormalSection
            isCondensed={isCondensed}
            section={BiomarkerStatus.ABOVE_OPTIMAL}
            sectionActive={
              status === BiomarkerStatus.ABOVE_OPTIMAL || optimalSectionActive
            }
            sectionMarkerActive={
              status === BiomarkerStatus.ABOVE_OPTIMAL && Boolean(value)
            }
            ratio={ratio}
            numberLabelValue={optimalMax}
            numberLabelActive={
              lowerValue === optimalMax || upperValue === optimalMax
            }
            numberLabelDirection="left"
            singleLabel={singleLabel}
            markerPosition={markerPosition}
            gradient={gradients.nonOptimal.active}
            gradientReversed={true}
            rangeItemClass={classes.aboveOptimalRangeItem}
            resultData={resultData}
            hideTooltip={hideTooltip}
            showLabels={showLabels}
          />
        )}

        {hasNormalMax && (
          <NonNormalSection
            isCondensed={isCondensed}
            section={BiomarkerStatus.HIGH}
            sectionActive={status === BiomarkerStatus.HIGH}
            sectionMarkerActive={
              status === BiomarkerStatus.HIGH && Boolean(value)
            }
            ratio={ratio}
            numberLabelValue={normalMax}
            numberLabelActive={
              lowerValue === normalMax || upperValue === normalMax
            }
            numberLabelDirection="left"
            singleLabel={singleLabel}
            markerPosition={markerPosition}
            gradient={gradients.abnormal.active}
            rangeItemClass={classes.highRangeItem}
            resultData={resultData}
            hideTooltip={hideTooltip}
            showLabels={showLabels}
          />
        )}
      </div>
    </div>
  )
}

function NonNormalSection({
  gradient,
  gradientReversed,
  markerPosition,
  numberLabelActive,
  numberLabelDirection,
  numberLabelValue,
  rangeItemClass,
  ratio,
  section,
  sectionActive,
  sectionMarkerActive,
  singleLabel,
  resultData,
  hideTooltip,
  isCondensed,
  showLabels,
}: {
  gradient: string
  gradientReversed?: boolean
  markerPosition: string
  numberLabelActive: boolean
  numberLabelDirection: "left" | "right"
  numberLabelValue: string | undefined
  rangeItemClass: string
  ratio: number
  section: BiomarkerStatus
  sectionActive: boolean
  sectionMarkerActive: boolean
  singleLabel: boolean
  resultData: CustomTooltipData
  hideTooltip: boolean
  isCondensed: boolean
  showLabels: boolean
}) {
  const classes = useStyles({ isCondensed })

  const gradientContainerRef = useRef<any>(null)

  const [showTooltip, setShowTooltip] = useState(false)

  const numberLabelVisible =
    (section !== BiomarkerStatus.ABOVE_OPTIMAL &&
      section !== BiomarkerStatus.BELOW_OPTIMAL) ||
    showLabels

  const isOuterValueLabel =
    section === BiomarkerStatus.HIGH || section === BiomarkerStatus.LOW

  const labelVisible = (isOuterValueLabel && sectionActive) || !singleLabel

  const { sectionLabelValue, secondarySectionLabelValue } =
    getActiveSectionLabelFromStatus(section, isCondensed)

  return (
    <div className={classes.nonNormalContainer}>
      <SectionLabel
        isCondensed={isCondensed}
        labelValue={sectionLabelValue}
        secondaryLabelValue={secondarySectionLabelValue}
        labelActive={sectionMarkerActive}
        showLabels={showLabels}
        labelVisible={labelVisible}
      />
      <div className={classes.gradientContainer} ref={gradientContainerRef}>
        {sectionMarkerActive && (
          <Popover.Root
            open={showTooltip && !singleLabel && !hideTooltip}
            onOpenChange={setShowTooltip}
            modal={false}
          >
            <Popover.Trigger
              asChild
              onClick={(e) => {
                if (showTooltip) {
                  // disable closing on click
                  e.preventDefault()
                } else {
                  setShowTooltip(true)
                }
              }}
            >
              <div
                className={isCondensed ? classes.ovalMarker : classes.marker}
                style={{
                  left: markerPosition,
                  backgroundColor: getColorFromGradient(
                    gradient,
                    ratio * 100,
                    gradientReversed
                  ),
                }}
                onMouseEnter={() => setShowTooltip(true)}
                onMouseLeave={() => setShowTooltip(false)}
              />
            </Popover.Trigger>
            <Popover.Content side="top" sideOffset={10}>
              <CustomTooltip data={resultData} />
            </Popover.Content>
          </Popover.Root>
        )}
        <div
          className={clsx(classes.rangeItem, rangeItemClass)}
          data-active={sectionActive}
        />
      </div>
      <div
        className={clsx(
          numberLabelDirection === "left"
            ? classes.labelLeft
            : classes.labelRight,
          {
            [classes.labelVisible]: numberLabelVisible,
            [classes.labelHidden]: !numberLabelVisible,
          }
        )}
        data-label-active={numberLabelActive}
      >
        {numberLabelValue}
      </div>
    </div>
  )
}

function SectionLabel({
  labelValue,
  secondaryLabelValue,
  labelActive,
  isCondensed,
  showLabels,
  labelVisible,
}: {
  labelValue: string
  secondaryLabelValue?: string
  labelActive: boolean
  isCondensed: boolean
  showLabels?: boolean // Whether to generally display labels
  labelVisible?: boolean // Whether to display this specific label
}) {
  const classes = useStyles({ isCondensed })
  return (
    <div
      className={clsx(classes.individualStatus, {
        [classes.labelVisible]: showLabels,
        [classes.labelHidden]: !showLabels,
      })}
      data-active={labelActive}
    >
      {labelVisible ? (
        <>
          {labelValue}
          <span className="font-semibold"> {secondaryLabelValue}</span>
        </>
      ) : (
        ""
      )}
    </div>
  )
}
