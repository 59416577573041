import { Skeleton } from "@material-ui/lab"

import Tooltip from "app/components/Tooltip"
import Button from "app/components/design-system/Button"
import makeAppStyles from "app/utils/makeAppStyles"

const useStyles = makeAppStyles((theme) => ({
  buttonContainer: {
    width: 150,
    [theme.breakpoints.down("sm")]: {
      width: "45%",
      marginBottom: 8,
    },
  },
  cursorAuto: {
    cursor: "auto",
  },
}))

export default function PanelItemCreatePanel({
  onClick,
  isLoading,
  alreadyExists,
  isBlocked,
}: {
  onClick: () => void
  isLoading: boolean
  alreadyExists?: boolean
  isBlocked: boolean
}) {
  const classes = useStyles()

  const content = (
    <Tooltip
      title={
        <div>
          This is already the selected lab company for this panel. When this
          panel is selected during checkout the lab tests included in this panel
          will be added to your cart.
        </div>
      }
      disableHoverListener={!isBlocked}
      placement="top"
      interactive
      arrow
    >
      <div
        className={classes.cursorAuto}
        onClick={(e) => {
          e.stopPropagation() // This prevents the event from propagating to the parent
        }}
      >
        <Button
          onClick={onClick}
          className={classes.buttonContainer}
          disabled={isBlocked}
        >
          {alreadyExists ? "Update" : "Create"} Panel
        </Button>
      </div>
    </Tooltip>
  )

  return <>{isLoading ? <Skeleton>{content}</Skeleton> : content}</>
}
