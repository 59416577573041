import * as React from "react"
import { useEffect, useMemo, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import clsx from "clsx"
// This restricted import predates our decision to discontinue using formsy-react.
// We now opt to use react-hook-form instead.
// When introducing changes to this component, please consider refactoring to remove
// formsy-react and replace it with react-hook-form where applicable.
// eslint-disable-next-line no-restricted-imports
import Formsy from "formsy-react"
import _ from "lodash"
import moment from "moment"
import { Link } from "react-router-dom"
import { useIntersection } from "react-use"

import ErrorLabelFormsy from "@fuse/components/formsy/ErrorLabelFormsy"
import { makeStyles, styled, Theme } from "@material-ui/core"
import CircularProgress from "@material-ui/core/CircularProgress"
import Divider from "@material-ui/core/Divider"
import Paper from "@material-ui/core/Paper"
import Typography from "@material-ui/core/Typography"
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline"
import ChevronRightIcon from "@material-ui/icons/ChevronRight"
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline"
import { cn } from "@rupahealth/design"

import InfoIcon from "app/assets/icons/info-gray-circle.svg"
import checkmarkCloud from "app/assets/images/checkmark-cloud.svg"
import expandIcon from "app/assets/images/expand.svg"
import ScheduleIconBlue from "app/assets/images/schedule-icon-blue.svg"
import ScheduleIcon from "app/assets/images/schedule-icon.svg"
import { DuplicateBiomarkersCheckoutWarning } from "app/components/CheckoutWarning/DuplicateBiomarkersCheckoutWarning"
import { FirstOrderWithLabCompanyTurnaroundCheckoutWarning } from "app/components/CheckoutWarning/FirstOrderWithLabCompanyTurnaroundCheckoutWarning"
import {
  StaticCheckoutWarning,
  StaticCheckoutWarningTypes,
} from "app/components/CheckoutWarning/StaticCheckoutWarning"
import GenovaInsuranceExplanation from "app/components/GenovaInsuranceExplanation"
import BodyText from "app/components/design-system/BodyText"
import DesignSystemButton from "app/components/design-system/Button"
import DisplayText from "app/components/design-system/DisplayText"
import InfoTextTooltip from "app/components/design-system/InfoTextTooltip"
import ScheduleOrderModal from "app/components/modals/ScheduleOrderModal"
import SignatureAndLicenseModalButton, {
  signatureAndLicenseButtonTitle,
} from "app/components/modals/SignatureAndLicenseModal/SignatureAndLicenseModalButton"
import {
  LAB_COMPANY_KEY,
  ORDER_LINE_ITEM_TYPE,
  ORDER_NOTIFICATION_METHOD,
  ORDER_STATUS,
  VENDOR_PHYSICIAN_AUTHORIZATION_LABEL,
} from "app/constants"
import {
  getEmpireMixedTestsError,
  getTestsCausingDuplicateBiomarkers,
  hasAMLAndLabcorpByAMLTests,
  hasAMLDropshipAndIOKTests,
  hasDHAAndLabcorpPhlebTests,
  hasLTAQuestTests,
  hasMultipleBloodLabTests,
  showEmpireBloodDrawInfo,
} from "app/dataServices/labTestDataService"
import {
  labCompaniesUsingInsurance,
  orderBlockedMissingSignature,
  patientNotificationRequired,
} from "app/dataServices/orderDataService"
import useAppSelector from "app/hooks/useAppSelector"
import { ICD10CodesModal } from "app/main/checkout/ICD10CodesModal"
import InsuranceFields from "app/main/checkout/InsuranceFields"
import useInsuranceOptions from "app/main/checkout/useInsuranceOptions"
import VibrantLeavingWarning from "app/main/warnings/VibrantLeavingWarning"
import { selectPractitioner } from "app/store/selectors/practitioner.selectors"
import withReducer from "app/store/withReducer"
import {
  colors,
  lightDisabledColor,
  navy,
  physAuthBannerColor,
  shadows,
  veryLightBlue,
} from "app/theme"
import { Order, OrderedTest } from "app/types"
import { formatDate, isPractitionerPayingOrder } from "app/utils"
import makeAppStyles from "app/utils/makeAppStyles"
import { getOrderPatientLabel, getOrderTypeLabel } from "app/utils/order-utils"

import Tooltip from "../../components/Tooltip"
import { hasInstantRequisitions } from "../../models/order"
import reducer from "../dashboard/store/reducers"
import { requiresActivation } from "../in-office-kits/utils"
import { anyLabTestIsVibrant } from "../warnings/utils"
import NotesModal from "./NotesModal"
import { OrderedTestList } from "./OrderedTestList"
import PractitionerPayment from "./PractitionerPayment"
import PractitionerSelects from "./PractitionerSelects"
import OrderCartSidebarEmptyState from "./components/OrderCartSidebarEmptyState"
import useCheckoutDraft from "./hooks/use-checkout-draft"
import AdditionalFeeLineItem from "./orderSidebar/AdditionalFeeLineItem"
import { Coupons } from "./orderSidebar/Coupons"
import CustomFees from "./orderSidebar/CustomFees/CustomFees"
import NotificationToggleButton from "./orderSidebar/NotificationToggleButton"
import OrderActions from "./orderSidebar/OrderActions"
import OrderSummary from "./orderSidebar/OrderSummary"
import VendorPhysAuthPhysicianAuthorizationWarning from "./orderSidebar/VendorPhysicianAuthorizationWarning"
import * as Actions from "./store/actions"
import { getOrder } from "./store/actions"

const SIDEBAR_SCROLL_GRADIENT = 160

const useStyles = makeStyles<Theme, { isFullyScrolled: boolean }>((theme) => ({
  container: {
    position: "relative",
    borderLeft: `1px solid ${colors.coolGray[200]}`,
    background: "white",
    flex: "0 0 420px",
    transition: "box-shadow 300ms linear, padding 300ms linear",
    boxShadow: `${shadows.md}, inset 0px 0px 0px 0px ${physAuthBannerColor}`,
    "&[data-requires-vendor-physician-authorization=true]": {
      paddingTop: 31,
      paddingBottom: 5,
      boxShadow: `${shadows.md}, inset 0px 0px 0px 5px ${physAuthBannerColor}`,
    },

    [theme.breakpoints.up("md")]: {
      height: "100vh",
      maxHeight: "100vh",
      position: "relative",

      "&:hover": {
        "&:after": {
          opacity: 0,
        },
      },

      "&:after": {
        // MaterialUI incorrectly processes `content` unless quoted like this
        content: '""',
        position: "absolute",
        bottom: 0,
        left: 0,
        right: 0,
        height: SIDEBAR_SCROLL_GRADIENT,
        pointerEvents: "none",
        background:
          "linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 0.01%, #FFFFFF 90%)",
        opacity: (props) => (props.isFullyScrolled ? 0 : 1),
        transition: "0.24s opacity",
      },
    },
  },
  innerContainer: {
    overflowY: "auto",
    height: "100%",
    padding: 24,
  },
  intersectionElement: {
    height: 1,
    width: 1,
    opacity: 0,
    pointerEvents: "none",
  },
  vibrantWarningContainer: {
    marginBottom: 20,
  },
}))

const StyledMedicareWarningBox = styled("div")({
  backgroundColor: colors.blueGray[50],
  borderColor: veryLightBlue,
  display: "flex",
  flexDirection: "row",
  alignItems: "top",
  padding: "0px",
  paddingLeft: "16px",
  paddingRight: "16px",
  paddingBottom: "8px",
  border: `1px solid ${colors.blueGray[300]}`,
  boxSizing: "border-box",
  borderRadius: "6px",
  margin: "18px 0px",
})
const StyledMedicareWarningContent = styled("div")({
  fontSize: "14px",
  lineHeight: "135%",
  left: "10px",
  top: "14px",
  display: "flex",
  alignItems: "center",
  color: navy,
  margin: "10px 0px",
})

const StyledMedicareWarningIcon = styled("img")({
  width: "16px",
  height: "16px",
  marginRight: "9px",
  marginTop: "10px",
})

const PhysicianAuthorizationWarning = styled(BodyText)({
  marginBottom: 16,
  padding: "8px 0",
})

const PhysicianAuthorizationWarningIcon = styled(BodyText)({
  display: "inline",
  marginRight: 8,
  fontSize: 12,
})

const ScheduleButton = styled(({ noBorder, ...other }) => (
  <DesignSystemButton {...other} />
))({
  marginLeft: 8,
  display: "flex",
  alignItems: "center",
  padding: "8px 12px",
  height: "auto",
  textAlign: "left",
  borderRadius: 6,
  boxShadow: shadows.default,
  "&:disabled": {
    pointerEvents: "auto",
    backgroundColor: lightDisabledColor,
  },
})

const OrderSidebarTitleWrapper = styled("div")({
  display: "flex",
  alignItems: "center",
  marginBottom: 10,
})

interface Props {
  order: Order
  orderIsSubmitting: boolean
  orderValidationErrors: Record<string, string>
  onSubmit: () => Promise<void>
  onSave: () => void
  onDelete: () => Promise<void>
  openModalWithLabTest: (labTest: any) => void
  edit: boolean
}

const OrderSidebar: React.FC<Props> = ({
  order,
  orderIsSubmitting,
  orderValidationErrors,
  onSubmit,
  onSave,
  onDelete,
  edit,
  openModalWithLabTest,
}) => {
  const { ordered_tests } = order
  const status = order.status
  const [validationErrors, setValidationErrors] = useState({})
  const practitioner = useSelector(selectPractitioner)
  const patient = useAppSelector(({ orders }) => orders.orders.patient)
  const dispatch = useDispatch()
  const formRef = useRef<Formsy>(null)
  const [insuranceOption, medicareOption] = useInsuranceOptions(order)
  const useCardChecked = useAppSelector(
    ({ orders }) => orders.paymentCards?.useCardChecked
  )

  const stripeConnectAccounts = useAppSelector(
    ({ orders }) => orders.customFees?.stripeConnectAccounts
  )

  const { updateValue, values } = useCheckoutDraft()
  const [scheduleOrderModalIsOpen, setScheduleOrderModalIsOpen] =
    useState(false)

  // Use the IntersectionObserver API to detect when the order sidebar has
  // been fully scrolled. We render a 1x1 invisible element at the bottom of
  // the container and observe when it enters the viewport.
  const intersectionRef = useRef(null)
  const intersection = useIntersection(intersectionRef, {})
  const styles = useStyles({
    isFullyScrolled: intersection?.isIntersecting || false,
  })

  const showMultipleBloodLabsWarning = useMemo(
    () =>
      hasMultipleBloodLabTests(
        ordered_tests.map((orderedTest) => orderedTest.lab_test)
      ),
    [ordered_tests]
  )

  const orderedTestLabsWithoutLabCompanyAccount = ordered_tests.filter(
    (orderedTest) =>
      !order?.practitioner?.lab_company_accounts?.some((labCompanyAccount) =>
        labCompanyAccount.lab_companies.some(
          (labCompany) =>
            labCompany.key === orderedTest.lab_test.lab_company.key
        )
      )
  )

  const orderedTestWithLongestTurnaroundTime: OrderedTest | undefined =
    orderedTestLabsWithoutLabCompanyAccount
      .filter((orderedTest) =>
        Boolean(
          orderedTest.lab_test.lab_company
            .estimated_registration_turnaround_time_days
        )
      )
      .sort(
        (a, b) =>
          (a.lab_test.lab_company.estimated_registration_turnaround_time_days ||
            0) -
          (b.lab_test.lab_company.estimated_registration_turnaround_time_days ||
            0)
      )[0]

  const showFirstOrderTurnaroundTimeWarning = Boolean(
    orderedTestWithLongestTurnaroundTime
  )

  const duplicateBiomarkerData = useMemo(() => {
    const duplicatesByLabCompany = getTestsCausingDuplicateBiomarkers(
      ordered_tests.map((orderedTest) => orderedTest.lab_test)
    )
    return Object.entries(duplicatesByLabCompany).map(
      ([labCompanyName, testsData]) => {
        const testsCausingDuplicates = Object.entries(testsData).map(
          ([testName, biomarkers]) => ({
            testName,
            biomarkersList: biomarkers,
          })
        )

        return {
          labCompanyName,
          testsCausingDuplicates,
        }
      }
    )
  }, [ordered_tests])

  const showAMLWarning = useMemo(
    () =>
      hasAMLAndLabcorpByAMLTests(
        ordered_tests.map((orderedTest) => orderedTest.lab_test)
      ),
    [ordered_tests]
  )

  const mixedDhaAndLabcorpOrderWarning = useMemo(
    () =>
      hasDHAAndLabcorpPhlebTests(
        ordered_tests.map((orderedTest) => orderedTest.lab_test)
      ),
    [ordered_tests]
  )

  const empireMixedTestsError = useMemo(() => {
    return getEmpireMixedTestsError(
      ordered_tests.map((orderedTest) => orderedTest.lab_test)
    )
  }, [ordered_tests])

  const showEmpireDrawWarning = useMemo(() => {
    return !empireMixedTestsError && showEmpireBloodDrawInfo(order)
  }, [empireMixedTestsError, order])

  const showAMLDeliveryMethodError = useMemo(
    () => hasAMLDropshipAndIOKTests(ordered_tests),
    [ordered_tests]
  )

  const showOrderWithLTAQuestWarning = useMemo(
    () =>
      hasLTAQuestTests(
        ordered_tests.map((orderedTest) => orderedTest.lab_test)
      ),
    [ordered_tests]
  )

  const hasVibrantTests = useMemo(
    () => anyLabTestIsVibrant(ordered_tests.map((ot) => ot.lab_test)),
    [ordered_tests]
  )

  const orderHasLabcorpAndMedicareInsurance =
    order.insurance_enabled_for?.includes(
      LAB_COMPANY_KEY.HEALTH_GORILLA_LABCORP
    ) && order.use_insurance

  const showInsuranceFields =
    !practitioner?.hide_insurance &&
    !useCardChecked &&
    (insuranceOption || medicareOption) &&
    !values.requires_vendor_physician_authorization

  const [kitActivationIdSelectedFor, setKitActivationIdSelectedFor] = useState<
    string[]
  >([])

  const orderedTestsRequiringKitActivationIds = useMemo(() => {
    return ordered_tests.filter(
      (orderedTest) =>
        orderedTest.instant_requisition &&
        requiresActivation(orderedTest.lab_test.lab_company.key)
    )
  }, [ordered_tests])

  const areAllKitActivationIdsSelected = useMemo(() => {
    return orderedTestsRequiringKitActivationIds.every((orderedTest) =>
      kitActivationIdSelectedFor.includes(orderedTest.id)
    )
  }, [orderedTestsRequiringKitActivationIds, kitActivationIdSelectedFor])

  useEffect(() => {
    if (!showInsuranceFields && order.use_insurance) {
      // If insurance was previously selected, and the insurance fields are now hidden,
      // switch back to cash pay
      dispatch(Actions.setUseInsurance(false, false))
    }
  }, [showInsuranceFields, order.use_insurance])

  // Don't show the practitioner payment option when editing an order for now, to make the implementation simpler
  const showPractitionerPayOption =
    !edit && !practitioner?.hide_practitioner_pay && !order.use_insurance

  useEffect(() => {
    const values = formRef && formRef.current && formRef.current.getModel()

    if (values) {
      const errors = validateForm(values)
      formRef.current?.updateInputsWithError(errors)
    }
  }, [order.ordered_tests])

  useEffect(() => {
    if (order.clinic && order.clinic.id) {
      dispatch(Actions.getPractitioners(order.clinic.id))
    }
  }, [dispatch, order.clinic])

  useEffect(() => {
    setValidationErrors(orderValidationErrors)
  }, [orderValidationErrors])

  useEffect(() => {
    dispatch(Actions.getStripeConnectAccount())
  }, [])

  const isDateToday = (date) => {
    if (!date) {
      return true
    }

    const dataObj = new Date(date)
    const today = new Date()

    if (dataObj.toDateString() === today.toDateString()) {
      return true
    }

    return false
  }

  const calendarScheduleButtonValue = useMemo(() => {
    if (Boolean(order.date_sent_to_patient)) {
      if (isDateToday(order.date_sent_to_patient)) {
        return "Today"
      }

      return formatDate(order.date_sent_to_patient)
    }

    if (isDateToday(order.date_scheduled)) {
      return "Today"
    } else {
      return formatDate(order.date_scheduled)
    }
  }, [order.date_scheduled, order.date_sent_to_patient])

  const scheduleOrderText = useMemo(() => {
    const verb = Boolean(order.date_sent_to_patient) ? "Sent" : "Send"
    const toWhom = getOrderPatientLabel(
      values.requires_vendor_physician_authorization
    ).toLowerCase()
    const action = calendarScheduleButtonValue === "Today" ? "" : "on"

    return `${verb} to ${toWhom} ${action}`
  }, [
    order.date_sent_to_patient,
    values.requires_vendor_physician_authorization,
    calendarScheduleButtonValue,
  ])

  const validateForm = (values, submission = false) => {
    const errors = {}

    // If insurance is selected we must ensure that the user provides ICD-10 codes for the order
    const insuranceLabCompanies = labCompaniesUsingInsurance(order)
    if (
      submission &&
      insuranceLabCompanies.length &&
      _.isEmpty(order.icd10_codes_discrete)
    ) {
      errors[
        "icd10CodesError"
      ] = `Please enter the ICD-10 codes for the ${insuranceLabCompanies
        .map((company) => company.name)
        .join(" and ")} tests`
    }

    if (
      submission &&
      showPractitionerField(practitioner) &&
      !order.practitioner
    ) {
      errors["orderingPractitioner"] =
        "Please select the patient's practitioner"
    }

    if (
      submission &&
      isPractitionerPayingOrder(order) &&
      hasInstantRequisitions(order) &&
      !patient.has_order_info
    ) {
      errors["submitError"] = `Please provide the ${getOrderPatientLabel(
        order.requires_vendor_physician_authorization
      ).toLowerCase()}'s demographic information.`
    }

    if (
      submission &&
      order.notification_method === ORDER_NOTIFICATION_METHOD.EMAIL_AND_TEXT &&
      !patient.phone_number
    ) {
      errors["submitError"] = `Please enter the ${getOrderPatientLabel(
        order.requires_vendor_physician_authorization
      ).toLowerCase()}'s phone number for them to receive text notifications.`
    }

    // Formsy throws an error if you provide keys that do not correspond to fields currently shown in the DOM.
    // By picking out the fields in the current model we avoid this error.
    const fieldErrors = _.pick(errors, _.keys(formRef.current?.getModel()))
    setValidationErrors(fieldErrors)
    return fieldErrors
  }

  const formSubmit = (data, resetForm, invalidateForm) => {
    const errors = validateForm(data, true)

    // A null value should not be considered an error.
    // However, it's important to set errors to null and not undefined, as undefined will not trigger the component
    // to update and clear its error message.
    if (_.isEmpty(_.compact(_.values(errors)))) {
      onSubmit()
    } else {
      invalidateForm(errors)
    }
  }
  if (!ordered_tests || _.isEmpty(patient)) {
    return <CircularProgress aria-label="Loading orders in cart" />
  }

  const additionalFeeLineItems = _.filter(
    order.line_items,
    (item) =>
      item.type === ORDER_LINE_ITEM_TYPE.VIBRANT_MINIMUM ||
      item.type === ORDER_LINE_ITEM_TYPE.SCARLET_BLOOD_DRAW_FEE
  )

  const scheduleButton = (
    <ScheduleButton
      color="secondary"
      size="xsmall"
      className={cn({
        "border-slate-300": !Boolean(order.date_sent_to_patient),
      })}
      startIcon={
        Boolean(order.date_sent_to_patient) ? ScheduleIcon : ScheduleIconBlue
      }
      onClick={() => setScheduleOrderModalIsOpen(true)}
      disabled={Boolean(order.date_sent_to_patient)}
      noBorder={Boolean(order.date_sent_to_patient)}
    >
      <BodyText
        weight="semibold"
        size="md"
        className={
          Boolean(order.date_sent_to_patient) ? "text-body" : "text-primary"
        }
      >
        {calendarScheduleButtonValue}
      </BodyText>
    </ScheduleButton>
  )

  const isCustomFeesShown = Boolean(ordered_tests.length) && !useCardChecked

  return (
    <div
      className={styles.container}
      data-requires-vendor-physician-authorization={
        values.requires_vendor_physician_authorization
      }
    >
      <VendorPhysAuthPhysicianAuthorizationWarning
        show={!!values.requires_vendor_physician_authorization}
      />
      <div className={styles.innerContainer}>
        <DisplayText size="xl" weight="semibold">
          {titleText(order, edit, values)}
        </DisplayText>

        <OrderSidebarTitleWrapper>
          <BodyText size="md">{scheduleOrderText}</BodyText>
          {/* The scheduled date can be edited for Scheduled orders which are not been sent */}
          {Boolean(order.date_sent_to_patient) ? (
            <Tooltip
              title={`${getOrderTypeLabel(
                values.requires_vendor_physician_authorization
              )}s that have been sent to the ${getOrderPatientLabel(
                values.requires_vendor_physician_authorization
              ).toLowerCase()} can no longer be edited`}
              arrow
            >
              <div>{scheduleButton}</div>
            </Tooltip>
          ) : (
            scheduleButton
          )}
        </OrderSidebarTitleWrapper>

        {edit ? <EditSavedText updatedAt={order.updated_at} /> : null}

        {order.requires_physician_authorization && (
          <PhysicianAuthorizationWarning weight="semibold">
            <PhysicianAuthorizationWarningIcon>
              🖊
            </PhysicianAuthorizationWarningIcon>
            This order includes {VENDOR_PHYSICIAN_AUTHORIZATION_LABEL}.
          </PhysicianAuthorizationWarning>
        )}

        <PractitionerSelects />

        <Divider className="mb-4 bg-slate-200" />
        {status === ORDER_STATUS.PENDING_PAYMENT && !edit && (
          <OrderSentMessage order={order} practitioner={practitioner} />
        )}
        {ordered_tests.length > 0 && (
          <>
            {showMultipleBloodLabsWarning &&
              !showAMLWarning &&
              !empireMixedTestsError && (
                <>
                  <StaticCheckoutWarning
                    type={StaticCheckoutWarningTypes.MULTIPLE_BLOOD_LABS}
                  />
                  <Divider className="mt-5 mb-6 bg-slate-200" />
                </>
              )}
            {orderHasLabcorpAndMedicareInsurance && (
              <>
                <StaticCheckoutWarning
                  type={
                    StaticCheckoutWarningTypes.HEALTH_GORILLA_LABCORP_AND_MEDICARE
                  }
                />
                <Divider className="mt-5 mb-6 bg-slate-200" />
              </>
            )}
            {!!duplicateBiomarkerData?.length &&
              duplicateBiomarkerData.map(
                ({ labCompanyName, testsCausingDuplicates }) => (
                  <React.Fragment key={labCompanyName}>
                    <DuplicateBiomarkersCheckoutWarning
                      labCompanyName={labCompanyName}
                      testsCausingDuplicates={testsCausingDuplicates}
                    />
                    <Divider className="mt-5 mb-6 bg-slate-200" />
                  </React.Fragment>
                )
              )}
            {empireMixedTestsError ? (
              <>
                <StaticCheckoutWarning
                  type={
                    StaticCheckoutWarningTypes.MIXED_EMPIRE_AND_LABCORP_ORDER
                  }
                />
                <Divider className="mt-5 mb-6 bg-slate-200" />
              </>
            ) : (
              showAMLWarning && (
                <>
                  <StaticCheckoutWarning
                    type={StaticCheckoutWarningTypes.MIXED_AML_ORDER}
                  />
                  <Divider className="mt-5 mb-6 bg-slate-200" />
                </>
              )
            )}
            {mixedDhaAndLabcorpOrderWarning && (
              <StaticCheckoutWarning
                type={StaticCheckoutWarningTypes.MIXED_DHA_LABCORP_ORDER}
              />
            )}
            {showAMLDeliveryMethodError && (
              <>
                <StaticCheckoutWarning
                  type={StaticCheckoutWarningTypes.AML_MIXED_DROPSHIP_IOK}
                />
                <Divider className="mt-5 mb-6 bg-slate-200" />
              </>
            )}

            {showOrderWithLTAQuestWarning && (
              <>
                <StaticCheckoutWarning
                  type={StaticCheckoutWarningTypes.ORDER_WITH_LTA_QUEST_TEST}
                />
                <Divider className="mt-5 mb-6 bg-slate-200" />
              </>
            )}
          </>
        )}
        {ordered_tests.length === 0 && <OrderCartSidebarEmptyState />}

        <OrderedTestList
          order={order}
          edit={edit}
          openModalWithLabTest={openModalWithLabTest}
          setKitActivationIdSelectedFor={setKitActivationIdSelectedFor}
        />
        {isCustomFeesShown && (
          <CustomFees
            stripeConnectAccounts={stripeConnectAccounts}
            order={order}
          />
        )}
        <div className="relative">
          {!(
            order.status === ORDER_STATUS.DRAFT ||
            order.status === ORDER_STATUS.DEMO
          ) &&
            !edit && (
              <div className="absolute top-0 bottom-0 left-0 right-0 z-99 opacity-75 bg-white" />
            )}
          <Coupons order={order} className="mt-5" />
          {ordered_tests.length > 0 && (
            <div>
              <Divider className="my-5 bg-slate-200" />
              {hasVibrantTests && (
                <div className={styles.vibrantWarningContainer}>
                  <VibrantLeavingWarning />
                </div>
              )}
              {additionalFeeLineItems.map((lineItem) => (
                <AdditionalFeeLineItem lineItem={lineItem} key={lineItem.id} />
              ))}
              <OrderSummary order={order} />
            </div>
          )}

          {showEmpireDrawWarning && (
            <StaticCheckoutWarning
              type={StaticCheckoutWarningTypes.EMPIRE_DRAW_WARNING}
            />
          )}

          {showPractitionerPayOption && (
            <>
              <Divider className="mt-5 mb-6 bg-slate-200" />
              <PractitionerPayment order={order} />
            </>
          )}

          <Formsy
            onSubmit={formSubmit}
            onChange={validateForm}
            validationErrors={validationErrors}
            onKeyPress={(event) => {
              // We don't want someone trying to press enter on the practitioner autocomplete field and
              // accidentally submitting the order, so disable the enter key for this form.
              if (event.key === "Enter") {
                event.preventDefault()
              }
            }}
            ref={formRef}
          >
            {showInsuranceFields && (
              <div>
                {edit && <Divider className="mt-8 mb-6 bg-slate-200" />}
                <InsuranceFields
                  order={order}
                  insuranceOption={insuranceOption}
                  medicareOption={medicareOption}
                />
                <GenovaInsuranceExplanation location="cart" order={order} />
                <Divider className="mt-6 bg-slate-200" />
              </div>
            )}
            {order.patient_has_medicare ? (
              <MedicareWarning order={order} />
            ) : (
              ""
            )}
            <OrderNotes order={order} edit={edit} />
            {ordered_tests.length > 0 &&
              patientNotificationRequired(order, patient) && (
                <NotificationToggleButton patient={patient} className="mt-8" />
              )}
            {showFirstOrderTurnaroundTimeWarning && (
              <div className="mt-8">
                <FirstOrderWithLabCompanyTurnaroundCheckoutWarning
                  labCompany={
                    orderedTestWithLongestTurnaroundTime.lab_test.lab_company
                  }
                />
              </div>
            )}
            <OrderActions
              order={order}
              areAllKitActivationIdsSelected={areAllKitActivationIdsSelected}
              orderIsSubmitting={orderIsSubmitting}
              patient={patient}
              onSave={onSave}
              onDelete={onDelete}
              edit={edit}
            />
          </Formsy>
        </div>
        <ScheduleOrderModal
          order={order}
          open={scheduleOrderModalIsOpen}
          onClose={() => setScheduleOrderModalIsOpen(false)}
          onSubmit={(dateScheduled) => {
            updateValue("date_scheduled", dateScheduled)
            setScheduleOrderModalIsOpen(false)
          }}
        />
        <div className={styles.intersectionElement} ref={intersectionRef} />
      </div>
    </div>
  )
}

export default withReducer("dashboard", reducer)(OrderSidebar)

function titleText(order, edit, values) {
  if (order.status === ORDER_STATUS.DRAFT) {
    if (order.ordered_tests.length > 0) {
      return `Draft ${getOrderTypeLabel(
        values.requires_vendor_physician_authorization
      )}`
    } else {
      return `New ${getOrderTypeLabel(
        values.requires_vendor_physician_authorization
      )}`
    }
  }

  if (edit || !order.date_sent_to_patient) {
    return `Edit ${getOrderTypeLabel(
      values.requires_vendor_physician_authorization
    )}`
  }

  return `Sent ${moment(order.date_sent_to_patient).format("ll")}`
}

/**
 * Displays a line of text detailing the time at which the order was last updated.
 * When the updatedAt prop changes it will flash green for 1 second to signal to the user that the change was saved.
 * @param updatedAt
 */
function EditSavedText({ updatedAt }) {
  const defaultColor = "#4a5568"
  const [color, setColor] = React.useState(defaultColor)
  const updateTimer = React.useRef<any>(null)
  const isFirstRenderRef = useRef(true)

  useEffect(() => {
    if (isFirstRenderRef.current) {
      isFirstRenderRef.current = false
      return
    }

    if (!updateTimer.current) {
      setUpdate()
    }
  }, [updatedAt])

  useEffect(() => {
    return () => {
      if (updateTimer.current) {
        clearTimeout(updateTimer.current)
      }
    }
  }, [])

  function setUpdate() {
    setColor("#31BB7D")
    updateTimer.current = setTimeout(() => {
      setColor(defaultColor)
      updateTimer.current = null
    }, 1000)
  }

  return (
    <div className="flex items-center mb-2">
      <img src={checkmarkCloud} alt="" />
      <Typography
        variant="h6"
        style={{ color: color }}
        className="font-semibold transition-250 transition-colors transition-ease-in fs-exclude text-lg ml-2 mt-2"
      >
        Saved {moment(updatedAt).format("LLL")}
      </Typography>
    </div>
  )
}

function OrderNotes({ order, edit }) {
  const [patientNotesOpen, setPatientNotesOpen] = useState(false)
  const [rupaNotesOpen, setRupaNotesOpen] = useState(false)
  const [icd10CodesModalOpen, setICD10CodesModalOpen] = useState(false)
  const { updateValue, values } = useCheckoutDraft()

  let notesToRupaDisabledReason: string | undefined = undefined

  if (hasInstantRequisitions(order)) {
    notesToRupaDisabledReason =
      "We're unable to process special requests for an order with In-Office Kits."
  } else if (
    _.some(
      order.line_items,
      (line_item) => line_item.coupon && line_item.coupon.disable_notes_to_rupa
    )
  ) {
    notesToRupaDisabledReason =
      "We're unable to process special requests for an order that includes your current discount offer."
  }

  const patientFirstName = order?.patient?.first_name

  const icd10Title = "ICD-10 Codes For Insurance"
  const icd10Subtitle =
    "ICD-10 codes are required to generate an insurance superbill for your patient."

  const renderIcd10Codes = !values.requires_vendor_physician_authorization

  const requireICD10Codes =
    order.use_insurance ||
    order.insurance_enabled_for?.some((insurance_enabled_company_key) =>
      order.ordered_tests.find(
        (orderedTest) =>
          orderedTest.lab_test.lab_company.key === insurance_enabled_company_key
      )
    )

  return (
    <div className="pt-6">
      <NotesField
        title={`Notes to ${getOrderPatientLabel(
          values.requires_vendor_physician_authorization
        )}`}
        value={values.notes_to_patient}
        disabled={false}
        onClick={() => setPatientNotesOpen(true)}
      />
      <NotesField
        title="Notes to Rupa"
        value={values.notes_to_rupa}
        disabled={
          (edit && order.status !== ORDER_STATUS.SCHEDULED) ||
          !!notesToRupaDisabledReason
        }
        disabledReason={notesToRupaDisabledReason}
        onClick={() => setRupaNotesOpen(true)}
      />

      {renderIcd10Codes && (
        <NotesField
          title={icd10Title}
          value={
            order.icd10_codes_discrete &&
            order.icd10_codes_discrete
              .map((icd10Code) => icd10Code.code)
              .join(", ")
          }
          disabled={false}
          onClick={() => setICD10CodesModalOpen(true)}
          errorName="icd10CodesError"
          required={requireICD10Codes}
          infoText={icd10Subtitle}
        />
      )}

      <NotesModal
        open={patientNotesOpen}
        onClose={() => setPatientNotesOpen(false)}
        onSave={(note) => updateValue("notes_to_patient", note)}
        text={values.notes_to_patient}
        placeholder="Enter notes here"
        title={`Notes to ${patientFirstName}`}
        info={`Add any notes to ${patientFirstName} here. These will be shown to ${patientFirstName} on their checkout page and also in the instructions we send.`}
        showInsertSnippetButton={true}
        warningText=""
      />
      <NotesModal
        open={rupaNotesOpen}
        onClose={() => setRupaNotesOpen(false)}
        onSave={(note) => updateValue("notes_to_rupa", note)}
        text={values.notes_to_rupa}
        warningText={`When you enter a note here, your ${getOrderPatientLabel(
          values.requires_vendor_physician_authorization
        ).toLowerCase()} will not get their ${getOrderTypeLabel(
          values.requires_vendor_physician_authorization
        ).toLowerCase()} email instantaneously. A member of our team will review this note before we send it out.`}
        placeholder="Enter notes here"
        title="Notes to Rupa"
        info=""
      />

      {renderIcd10Codes && (
        <ICD10CodesModal
          open={icd10CodesModalOpen}
          onClose={() => setICD10CodesModalOpen(false)}
          title={icd10Title}
          subtitle={icd10Subtitle}
        />
      )}
    </div>
  )
}

const useSelectStyles = makeAppStyles({
  rowContainer: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
  },
  columnContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  infoIcon: {
    "& svg": {
      marginLeft: 4,
    },
  },
  newLine: {
    "& p": {
      width: 200,
      textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "nowrap",
      paddingLeft: 0,
    },
  },
  growingDiv: {
    flexGrow: 4,
  },
})

interface NotesFieldProps {
  value: string | undefined
  disabled?: boolean
  disabledReason?: string
  title: string
  onClick: () => void
  errorName?: string
  required?: boolean
  infoText?: string
}
function NotesField({
  value,
  disabled,
  disabledReason,
  title,
  onClick,
  errorName,
  required,
  infoText,
}: NotesFieldProps) {
  const classes = useSelectStyles()
  return (
    <Tooltip
      title={disabledReason || ""}
      disableHoverListener={!disabled || !disabledReason}
      placement="top"
      arrow
    >
      <div>
        <div
          className={clsx(
            "flex items-center justify-between cursor-pointer",
            "rounded px-4 py-2 mb-4 border border-slate-300",
            disabled ? "bg-gray-50" : "bg-white hover:bg-blue-50 shadow"
          )}
          onClick={() => !disabled && onClick()}
        >
          <div className={classes.rowContainer}>
            <div className={classes.columnContainer}>
              <div className="flex w-full">
                <Typography className="text-base font-500 font-semibold text-gray-700">
                  {title}
                </Typography>
                {required && (
                  <Typography className="text-base font-500 font-semibold text-red-700 ml-1">
                    *
                  </Typography>
                )}
                {infoText && (
                  <InfoTextTooltip
                    className={[classes.columnContainer, classes.infoIcon].join(
                      " "
                    )}
                  >
                    {infoText}
                  </InfoTextTooltip>
                )}
              </div>
              <div className={classes.newLine}>
                <Typography
                  variant="body1"
                  className="text-xs font-500 text-gray-700 pl-4 fs-exclude flex-1 pt-px pr-2 whitespace-pre-line"
                >
                  {value}
                </Typography>
              </div>
            </div>
            <div className={classes.growingDiv} />
            <div className={classes.columnContainer}>
              {!disabled && (
                <img src={expandIcon} alt="Expand" width={19} height={19} />
              )}
            </div>
          </div>
        </div>
        {errorName && (
          <ErrorLabelFormsy
            name={errorName}
            align="center"
            className="my-4 px-3"
          />
        )}
      </div>
    </Tooltip>
  )
}

function OrderSentMessage({ order, practitioner }) {
  let message: React.ReactNode | undefined = undefined
  const dispatch = useDispatch()

  // The order status will be draft before signing link is opened and the checkout page is refreshed
  const isOrderBlockedMissingSignature = orderBlockedMissingSignature(order)

  const canSignForOrder = order?.signing_practitioner?.id === practitioner?.id

  // The order will be blocked when the practitioner has another signing practitioner, and the
  // signing practitioner is verified and has a signature but, the practitioner is not verified.
  const isUnverifiedOrderingPractitioner =
    !order?.practitioner?.user?.is_verified

  const onCloseSignatureAndLicenseModal = (fullVerificationComplete) => {
    // When the signature and license modal is closed,
    // reload the order if verification was completed.
    // This will update the sidebar to display that the order is submitted.
    if (fullVerificationComplete) {
      dispatch(getOrder(order.id))
    }
  }

  const StyledTypography = styled(Typography)({
    fontSize: "16px",
    color: colors.gray[400],
    marginBottom: "24px",
  })

  if (
    isOrderBlockedMissingSignature &&
    !order?.signing_practitioner?.user?.is_verified
  ) {
    message = (
      <StyledTypography align="center">
        {`${order?.signing_practitioner?.titled_full_name} needs to log in to Rupa and sign
        their documents. Please have ${order?.signing_practitioner?.titled_full_name} check
        their email and sign their documents.`}
      </StyledTypography>
    )
  } else if (isOrderBlockedMissingSignature) {
    message = (
      <StyledTypography align="center">
        The{" "}
        {getOrderTypeLabel(
          order.requires_vendor_physician_authorization
        ).toLowerCase()}{" "}
        will not be sent to{" "}
        {order.patient.user ? order.patient.user.email : "the patient's email"}{" "}
        until we have your e-signature and license on file.
      </StyledTypography>
    )
  } else if (isUnverifiedOrderingPractitioner) {
    message = (
      <StyledTypography align="center">
        {`${order?.practitioner?.titled_full_name} needs to verify their email. Please have
        ${order?.practitioner?.titled_full_name} check their email and verify their account.`}
      </StyledTypography>
    )
  } else if (isPractitionerPayingOrder(order)) {
    message = (
      <StyledTypography align="center">
        Congratulations, your{" "}
        {getOrderTypeLabel(
          order.requires_vendor_physician_authorization
        ).toLowerCase()}{" "}
        has been submitted. We'll keep you up to date with its progress.
      </StyledTypography>
    )
  } else {
    message = (
      <StyledTypography className="fs-exclude" align="center">
        We've sent the{" "}
        {getOrderTypeLabel(
          order.requires_vendor_physician_authorization
        ).toLowerCase()}{" "}
        to{" "}
        {order.patient.user ? order.patient.user.email : "the patient's email"}{" "}
        for payment
      </StyledTypography>
    )
  }

  const isOrderBlocked =
    isOrderBlockedMissingSignature || isUnverifiedOrderingPractitioner

  const statusIcon = isOrderBlocked ? (
    <ErrorOutlineIcon className="w-16 h-16 text-red-700 mb-4" />
  ) : (
    <CheckCircleOutlineIcon className="w-16 h-16 text-green-A400 mb-4" />
  )

  const signNowLink = (
    <SignatureAndLicenseModalButton
      location="order_sidebar"
      onClose={onCloseSignatureAndLicenseModal}
      disabled={!canSignForOrder}
      returnLinkText={`Return to ${getOrderTypeLabel(
        order.requires_vendor_physician_authorization
      )}`}
    >
      {signatureAndLicenseButtonTitle(practitioner)}
    </SignatureAndLicenseModalButton>
  )

  const sidebarLink =
    isOrderBlockedMissingSignature && canSignForOrder ? (
      signNowLink
    ) : (
      <Link className="text-blue-A700 flex items-center" to="/dashboard">
        Return to Dashboard <ChevronRightIcon />
      </Link>
    )

  return (
    <Paper
      className="border border-gray-100 rounded-lg flex flex-col items-center justify-center px-16 py-16 mb-6"
      style={{
        background:
          "linear-gradient(160.9deg, #FFFFFF 11.16%, rgba(255, 255, 255, 0) 84.19%), #F5F5F5",
      }}
      elevation={8}
    >
      {statusIcon}
      <Typography className="text-xl text-gray-800 mb-4" align="center">
        {getOrderTypeLabel(order.requires_vendor_physician_authorization)}{" "}
        {isOrderBlocked ? "Blocked" : "Sent"}
      </Typography>
      {message}
      {sidebarLink}
    </Paper>
  )
}

function MedicareWarning({ order }) {
  return (
    <div>
      <StyledMedicareWarningBox>
        <StyledMedicareWarningIcon src={InfoIcon} alt="infoIcon" />
        <StyledMedicareWarningContent>
          Please note that you must be PECOS-enrolled to order through Medicare.
          These tests are only covered by traditional Medicare (parts A & B).
          This does not include Medicare Advantage, Medicare Replacement or
          Medicaid.
        </StyledMedicareWarningContent>
      </StyledMedicareWarningBox>
    </div>
  )
}

/**
 * Returns true if the practitioner selection field should be shown to this user.
 * @param practitioner
 * @returns {boolean}
 */
function showPractitionerField(practitioner) {
  const multipleOrderingPractitioners =
    practitioner &&
    practitioner.clinic &&
    practitioner.clinic.multiple_ordering_practitioners
  const isClinicStaff = practitioner && practitioner.is_clinic_staff
  return !!(multipleOrderingPractitioners || isClinicStaff)
}
