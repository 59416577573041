import { colors, dashboardHighlight, shadows } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"

const useTableStyles = makeAppStyles((theme) => ({
  container: {
    display: "grid",
    gridTemplateRows: "repeat(1, 1fr)",
    border: `1px solid ${colors.coolGray[200]}`,
    borderRadius: 8,
    overflow: "auto",
    background: "white",
    marginTop: 16,
    boxShadow: shadows.default,

    [theme.breakpoints.up("sm")]: {
      marginTop: 24,
    },
  },
  table: {
    tableLayout: "auto",
  },
  tableHeaderRow: {
    color: theme?.palette?.text?.secondary,
    backgroundColor: colors.blueGray[50],
  },
  tableDataRow: {
    backgroundColor: "white",
    height: 82,
  },
  tableDataRowCanExpand: {
    cursor: "pointer",
  },
  tableDataRowIsExpanded: {
    backgroundColor: dashboardHighlight,
  },
  tableCell: {
    padding: theme.spacing(2),
    wordWrap: "break-word",
  },
  tableDataCellStickyRight: {
    "$tableDataRow:hover &": {
      backgroundImage:
        "linear-gradient(90deg, rgba(249, 250, 251, 0) 0%, rgb(255, 255, 255) 10%)",
      position: "sticky",
      right: 0,
    },
    "$tableDataRowIsExpanded:hover &": {
      backgroundImage: `linear-gradient(90deg, rgba(246, 252, 255, 0) 0%, ${dashboardHighlight} 10%)`,
    },
  },
  tableDataCellStickyLeft: {
    "$tableDataRow:hover &": {
      backgroundImage:
        "linear-gradient(-90deg, rgba(249, 250, 251, 0) 0%, rgb(255, 255, 255) 10%)",
      position: "sticky",
      left: 0,
    },
    "$tableDataRowIsExpanded:hover &": {
      backgroundImage: `linear-gradient(-90deg, rgba(246, 252, 255, 0) 0%, ${dashboardHighlight} 10%)`,
    },
  },
  tableEmptyRow: {
    height: 164,
  },
  tableEmptyRowIcon: {
    fill: colors.blueGray[500],
    marginBottom: theme.spacing(1.0),
  },
  tableEmptyRowText: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "white",
    gap: theme.spacing(1.0),
    color: colors.blueGray[500],
  },
  tablePaginationRow: {
    color: theme?.palette?.text?.secondary,
    backgroundColor: colors.blueGray[50],
  },
}))

export default useTableStyles
