import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import { Practitioner } from "app/types"
import { SpecimenIssue } from "types/specimen-issue"

import SpecimenIssueResolveButton from "./SpecimenIssueResolveButton"
import { SpecimenIssueResolveModalProps } from "./resolution-modal/SpecimenIssueResolveModal"

interface Props extends Omit<SpecimenIssueResolveModalProps, "specimenIssue"> {
  specimenIssue: SpecimenIssue | undefined
  modal: any
  pathname: string
  practitioner: Practitioner
}

const SpecimenIssueResolveTitle: React.FC<Props> = ({
  specimenIssue,
  ...props
}) => {
  return (
    <div className="flex flex-row gap-1 items-center">
      <FontAwesomeIcon
        icon={faTriangleExclamation}
        className="text-destructive"
      />
      <span>Specimen Issue</span>
      {specimenIssue && (
        <SpecimenIssueResolveButton specimenIssue={specimenIssue} {...props} />
      )}
    </div>
  )
}

export default SpecimenIssueResolveTitle
