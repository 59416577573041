import { faChevronDown, faChevronUp } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Skeleton } from "@rupahealth/design"
import { CellContext, HeaderContext } from "@tanstack/react-table"

import BodyText from "app/components/design-system/BodyText"
import useCachedResource from "app/swr/hooks/use-cached-resource"
import { Biomarker } from "types/biomarker"
import { LabCompanyBiomarkerRange } from "types/lab-company-biomarker-range"

import BiomarkerFilter from "../BiomarkerFilter"
import columnHelper from "./column-helper"

interface BiomarkerNameCellContext
  extends CellContext<
    LabCompanyBiomarkerRange,
    LabCompanyBiomarkerRange["relationships"]["biomarker"]
  > {
  demographicRanges?: any[]
  demographicRangesLoading?: boolean
}

const BiomarkerNameCell = ({
  getValue,
  row,
  demographicRanges,
  demographicRangesLoading,
}: BiomarkerNameCellContext) => {
  const biomarker = useCachedResource<Biomarker>(getValue().data)

  const handleToggleClick = () => {
    row.toggleExpanded()
  }

  return (
    <div className="flex flex-col gap-[5px]">
      <BodyText weight="semibold">
        {biomarker?.attributes.long_name || "---"}
      </BodyText>
      {demographicRangesLoading && <Skeleton className="w-[150px] h-3" />}
      {Boolean(demographicRanges?.length) && (
        <BodyText
          weight="semibold"
          size="xs"
          className="text-primary hover:cursor-pointer"
          onClick={handleToggleClick}
        >
          Ranges By Demographic{" "}
          <DemographicRangesCount numRanges={demographicRanges?.length} />{" "}
          <FontAwesomeIcon
            icon={row.getIsExpanded() ? faChevronUp : faChevronDown}
            className="ml-1"
          />
        </BodyText>
      )}
    </div>
  )
}

const DemographicRangesCount = ({ numRanges }) => {
  return (
    <span className="bg-primary text-slate-50 px-[5px] py-[1px] rounded-full text-[10px]">
      {numRanges}
    </span>
  )
}

const BiomarkerHeader = ({ column }: HeaderContext<any, any>) => {
  return (
    <BiomarkerFilter
      isLoading={false}
      label="Biomarker"
      onChange={column.setFilterValue}
      value={column.getFilterValue() as string}
    />
  )
}

export const biomarkerNameColumn = columnHelper.accessor(
  "relationships.biomarker",
  {
    cell: (info) => <BiomarkerNameCell {...info} />,
    enableColumnFilter: true,
    header: (info) => <BiomarkerHeader {...info} />,
    id: "biomarkerName",
    size: 200,
    minSize: 200,
  }
)
