import useResourceSWR from "app/swr/hooks/use-resource-swr"

import { PractitionerProfileApplication } from "../types"

export default function useProfileApplication(
  applicationId: string | undefined
) {
  const {
    data: profileApplication,
    isLoading: isUpdatingProfileApplication,
    mutate: mutateProfileApplication,
  } = useResourceSWR<PractitionerProfileApplication>(
    applicationId
      ? `/practitioner_profile_applications/${applicationId}`
      : null,
    {},
    {
      shouldHandleApiError: false,
    }
  )

  return {
    profileApplication,
    isUpdatingProfileApplication,
    mutateProfileApplication,
  }
}
