import BodyText from "app/components/design-system/BodyText"
import { colors, rupaTeal } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"
import { LabTest } from "types/lab-test"

import ComboGroupLabTestList from "./ComboGroupLabTestList"

const useStyles = makeAppStyles((theme) => ({
  container: {
    backgroundColor: "white",
    padding: 20,
  },
  savingContainer: {
    border: `1px solid ${rupaTeal}`,
    backgroundColor: `${rupaTeal}11`,
    borderRadius: 7,
    padding: 10,
    margin: "10px 0px",
  },
  warningContainer: {
    border: `1px solid ${colors.blueGray[300]}`,
    backgroundColor: `${colors.blueGray[50]}`,
    borderRadius: 7,
    padding: 10,
    margin: "10px 0px",
  },
}))

export interface ComboGroupLabTestsProps {
  selectedOptionLabTestIds: string[]
  optionLabTests?: LabTest[]
  onAdd: (id: string) => void
  onRemove: (id: string) => void
  isLoading: boolean
}

export default function ComboGroupLabTests({
  selectedOptionLabTestIds,
  optionLabTests,
  onAdd,
  onRemove,
  isLoading,
}: ComboGroupLabTestsProps) {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <BodyText size="sm" weight="semibold">
        Lab Tests
      </BodyText>
      <ComboGroupLabTestList
        labTests={optionLabTests ?? []}
        selectedIds={selectedOptionLabTestIds}
        onAdd={onAdd}
        onRemove={onRemove}
        isLoading={isLoading}
      />
    </div>
  )
}
