import { useCallback, useEffect, useState } from "react"

import { debounce } from "lodash"
import { useForm } from "react-hook-form"
import { z } from "zod"

import { zodResolver } from "@hookform/resolvers/zod"
import { PaginationState } from "@tanstack/react-table"

import { LabCompanyBiomarkerRange } from "types/lab-company-biomarker-range"

import useOptimalRanges from "./use-optimal-ranges"
import usePatchOptimalRange from "./use-patch-optimal-range"

/**
 * Sets up a form hook to edit an optimal range. The form is debounced to avoid sending too many requests to the server.
 *
 * @param optimalRange the optimal range to edit
 * @returns a form hook to edit the biomarker
 */
export default function useOptimalRangesForm(
  pagination: PaginationState,
  columnFilters: any,
  optimalRange: LabCompanyBiomarkerRange,
  labCompanyKey: string,
  hideUnsetRanges: boolean
) {
  const [isUserTyping, setIsUserTyping] = useState(false)

  const OptimalRangeEditableAttributesSchema = z
    .object({
      optimal_range_min: z.preprocess(
        (a) => (a === "" || !a ? null : parseFloat(z.string().parse(a))),
        z.number().optional().nullable()
      ),
      optimal_range_max: z.preprocess(
        (a) => (a === "" || !a ? null : parseFloat(z.string().parse(a))),
        z.number().optional().nullable()
      ),
    })
    .refine(
      (data) => {
        // Ensure optimal_range_min is less than optimal_range_max
        const min = data.optimal_range_min
        const max = data.optimal_range_max

        // Both values need to be non-null to perform the comparison
        if (min && max && min >= max) {
          return false
        }
        return true
      },
      {
        message: "Optimal range min must be below optimal range max",
        path: ["optimal_range_min"],
      }
    )
    .refine(
      (data) => {
        // Ensure optimal_range_min is less than optimal_range_max
        const min = data.optimal_range_min
        const max = data.optimal_range_max

        // Both values need to be non-null to perform the comparison
        if (min && max && max <= min) {
          return false
        }
        return true
      },
      {
        message: "Optimal range max must be above optimal range min",
        path: ["optimal_range_max"],
      }
    )
  const { patchOptimalRange, loading } = usePatchOptimalRange(optimalRange)

  const { createOptimalRange, isCreateLoading } = useOptimalRanges({
    pagination,
    columnFilters,
    labCompanyKey,
    hideUnsetRanges,
  })

  const [generalFormError, setGeneralFormError] = useState<string | null>(null)

  const methods = useForm<LabCompanyBiomarkerRange["attributes"]>({
    criteriaMode: "all",
    defaultValues: {
      optimal_range_min: optimalRange.attributes.optimal_range_min || "",
      optimal_range_max: optimalRange.attributes.optimal_range_max || "",
    },
    mode: "onChange",
    resolver: zodResolver(OptimalRangeEditableAttributesSchema),
    reValidateMode: "onChange",
    shouldFocusError: false,
    shouldUnregister: true,
  })

  const { handleSubmit, watch } = methods

  const updateOrCreateOptimalRange = async (
    data: LabCompanyBiomarkerRange["attributes"]
  ) => {
    setGeneralFormError(null)

    if (optimalRange.attributes.source === "Clinic") {
      try {
        await patchOptimalRange(data)
      } catch (error: any) {
        setGeneralFormError(
          error?.response?.data?.errors?.errors?.[0]?.detail ||
            error?.response?.data?.errors?.[0]?.detail ||
            error?.message ||
            "Unable to save optimal range"
        )
      }
    } else {
      try {
        await createOptimalRange(
          {
            type: "lab_company_biomarker_range",
            attributes: data,
            relationships: {
              lab_company: optimalRange.relationships.lab_company,
              biomarker: optimalRange.relationships.biomarker,
            },
          },
          optimalRange
        )
      } catch (error: any) {
        setGeneralFormError(
          error?.response?.data?.errors?.errors?.[0]?.detail ||
            error?.response?.data?.errors?.[0]?.detail ||
            error?.message ||
            "Unable to save optimal range"
        )
      }
    }
  }

  const setIsUserTypingImmediately = useCallback(() => {
    setIsUserTyping(true)
  }, [])

  const debounceOnSubmit = useCallback(
    debounce((data) => {
      handleSubmit(updateOrCreateOptimalRange)().finally(() => {
        setIsUserTyping(false) // Reset loading state after handleSubmit resolves
      })
    }, 800),
    [handleSubmit]
  )

  /**
   * Debounce the form submission on change to avoid sending too many requests to the server.
   */
  useEffect(() => {
    const subscription = watch((data) => {
      setIsUserTypingImmediately() // Set is user typing immediately, but only once on init of subscription
      debounceOnSubmit(data) // Then debounce the handleSubmit call
    })

    return () => {
      debounceOnSubmit.flush() // Ensures the save still goes through on unmount
      subscription.unsubscribe()
    }
  }, [debounceOnSubmit, setIsUserTypingImmediately, watch])

  return {
    methods,
    isPatchLoading: loading,
    isCreateLoading,
    isUserTyping,
    generalFormError,
  }
}
