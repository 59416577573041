import NiceModal, { muiDialog, useModal } from "@ebay/nice-modal-react"
import { Dialog, DialogTitle, styled, IconButton } from "@material-ui/core"
import CloseIcon from "@material-ui/icons/Close"

import AddIcon from "app/assets/icons/add-blue.svg"
import CheckIcon from "app/assets/icons/blue-checkmark.svg"
import Button from "app/components/design-system/Button"
import DisplayText from "app/components/design-system/DisplayText"
import BiomarkersSection from "app/components/modals/LabTestModal/BiomarkersSection"
import InformationSection from "app/components/modals/LabTestModal/InformationSection"
import useCachedCollection from "app/swr/hooks/use-cached-collection"
import useCachedResource from "app/swr/hooks/use-cached-resource"
import { colors, navy, primaryColor, white } from "app/theme"
import { Biomarker as LegacyBiomarker, labTestLocation } from "app/types"
import makeAppStyles from "app/utils/makeAppStyles"
import { Biomarker } from "types/biomarker"
import { LabCompany } from "types/lab-company"
import { LabTest } from "types/lab-test"
import { LabTestType } from "types/lab-test-type"

const useStyles = makeAppStyles<{ isInCombo: boolean }>((theme) => ({
  dialogTitle: {
    display: "flex",
    flexFlow: "row nowrap",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "white",
    borderBottomWidth: 1,
    borderColor: colors.blueGray[300],
    padding: theme.spacing(2.0),
    gap: 10,
  },
  body: {
    backgroundColor: colors.blueGray[100],
    padding: 20,
    display: "flex",
    flexDirection: "column",
    gap: 20,
  },
  spacer: {
    flexGrow: 100,
  },
  biomarkersContainer: {
    backgroundColor: white,
    padding: "20px 0px",
  },
  actionButton: {
    width: 200,
    flexGrow: 2,
    "& button": {
      display: "flex",
      background: (props) => (props.isInCombo ? colors.lightBlue[50] : "white"),
      border: (props) =>
        `1px solid ${props.isInCombo ? primaryColor : colors.blueGray[300]}`,
      alignItems: "center",
      width: "100%",
    },
  },
  actionIcon: {
    marginTop: -3,
    width: 12,
  },
}))

const CloseButton = styled(IconButton)(({ theme }) => ({
  color: navy,
  position: "absolute",
  top: 1,
  right: 12,
  marginRight: -12,

  [theme.breakpoints.up("sm")]: {
    position: "relative",
    right: 0,
  },
}))

export interface ComboGroupLabTestModalProps {
  labTestId: string
  selected: boolean
  onAdd: (id: string) => void
  onRemove: (id: string) => void
}

function ComboGroupLabTestModal({
  labTestId,
  selected,
  onAdd,
  onRemove,
}: ComboGroupLabTestModalProps) {
  const modal = useModal()
  const muiDialogControl = muiDialog(modal)
  const classes = useStyles({ isInCombo: selected })

  const labTest = useCachedResource<LabTest>({
    type: "lab_test",
    id: labTestId,
  })
  const labCompany = useCachedResource<LabCompany>(
    labTest?.relationships.lab_company.data
  )
  const labTestTypes = useCachedCollection<LabTestType>(
    labTest?.relationships.lab_test_types.data
  )
  const biomarkers = useCachedCollection<Biomarker>(
    labTest?.relationships.biomarkers.data
  )
  const legacyBiomarkers = biomarkers.map((biomarker) => {
    const legacyBiomarker: LegacyBiomarker = {
      id: Number(biomarker.id),
      short_name: biomarker.attributes.short_name,
      long_name: biomarker.attributes.long_name,
    }
    return legacyBiomarker
  })

  const onClick = () => {
    if (selected) {
      onRemove(labTestId)
    } else {
      onAdd(labTestId)
    }
    muiDialogControl.onClose()
  }

  return (
    <Dialog
      {...muiDialogControl}
      disableEnforceFocus
      disableEscapeKeyDown
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle className={classes.dialogTitle} disableTypography>
        <DisplayText className={classes.titleText} weight="semibold" size="lg">
          {labTest?.attributes.name}
        </DisplayText>
        <div className={classes.spacer} />
        <div className={classes.actionButton}>
          <Button
            aria-label="Add to Combo"
            color="secondary"
            onClick={onClick}
            size="small"
            className={classes.actionButton}
            startIcon={
              <img
                src={selected ? CheckIcon : AddIcon}
                className={classes.actionIcon}
                alt=""
              />
            }
          >
            {selected ? "Remove from Combo" : "Add to Combo"}
          </Button>
        </div>
        <CloseButton
          onClick={muiDialogControl.onClose}
          aria-label="close"
          key="close-button"
        >
          <CloseIcon />
        </CloseButton>
      </DialogTitle>
      {labTest && labCompany && labTestTypes && (
        <div className={classes.body}>
          <InformationSection
            details={labTest.attributes.details}
            rupaUrl={labTest.attributes.rupa_url}
            url={labTest.attributes.url}
            vendorPhysAuthApproved={
              labTest.attributes.physician_authorization_approved
            }
            labCompanyName={labCompany.attributes.name}
            labCompanyDetails={labCompany.attributes.details}
            labCompanyKey={labCompany.attributes.key}
            msrpPrice={labTest.attributes.msrp_price.toString()}
            rupaPrice={labTest.attributes.rupa_price.toString()}
            labTestTypes={labTestTypes.map((type) => type.attributes.name)}
            shippingDaysMin={labTest.attributes.shipping_days_min}
            shippingDaysMax={labTest.attributes.shipping_days_max}
            estimatedDaysForResults={
              labTest.attributes.estimated_days_for_results
            }
            cheatsheetUrl={labTest.attributes.cheatsheet_url}
            sampleReportUrl={labTest.attributes.sample_report_url}
            stateOrderingRestrictions={
              labTest.attributes.state_ordering_restrictions
            }
            isPhlebotomyRequired={labTestTypes.some(
              (type) => type.attributes.is_phlebotomy_required
            )}
          />
          {legacyBiomarkers.length !== 0 && (
            <div className={classes.biomarkersContainer}>
              <BiomarkersSection
                biomarkers={legacyBiomarkers}
                location={labTestLocation.CHECKOUT}
              />
            </div>
          )}
        </div>
      )}
    </Dialog>
  )
}

const ComboGroupLabTestNiceModal =
  NiceModal.create<ComboGroupLabTestModalProps>((props) => {
    return <ComboGroupLabTestModal key={props.labTestId} {...props} />
  })

export default ComboGroupLabTestNiceModal
