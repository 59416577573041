import {
  PatientPortalOrderLineItemLabTest,
  PatientPortalOrder,
} from "app/types"

import ComboGroupLineItem from "./ComboGroupLineItem"

interface ComboGroupLineItemsProps {
  comboGroups: PatientPortalOrderLineItemLabTest[]
  showPrices: boolean
  order: PatientPortalOrder
  isEditing: boolean
  updatingOrderedTestIds: string[]
  isUpdatingOrder: boolean
  enableLineItems: (orderedTestIds: string[]) => void
  disableLineItems: (orderedTestIds: string[]) => void
}

const ComboGroupLineItems = ({
  comboGroups,
  ...props
}: ComboGroupLineItemsProps) => {
  return (
    <div className="mt-6">
      {comboGroups.map((comboGroup) => (
        <ComboGroupLineItem
          key={comboGroup.id}
          comboGroup={comboGroup}
          {...props}
        />
      ))}
    </div>
  )
}
export default ComboGroupLineItems
