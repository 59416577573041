import React from "react"

import { KeyedMutator } from "swr"

import { Practitioner } from "app/types"

import { OnboardingTask, OnboardingTaskKey } from "../types"
import ExistingLicense from "./ExistingLicense"
import InOfficeKits from "./InOfficeKits"
import InviteTeam from "./InviteTeam"
import LicenseCombined from "./LicenseCombined"
import LicenseInvited from "./LicenseInvited"
import PhysServicesLicense from "./PhysServicesLicense"
import PointOfContact from "./PointOfContact"
import PreferredPhlebotomoy from "./PreferredPhlebotomy"
import VerifyEmail from "./VerifyEmail"

// Interface for the props that are passed to all of the task item options
export interface OnboardingTaskItemProps {
  task: OnboardingTask
  markTaskAsComplete: (key: OnboardingTaskKey) => void
  onTaskComplete: (key: OnboardingTaskKey) => void
  practitioner: Practitioner
  refreshPractitioner: KeyedMutator<Practitioner>
}

interface Props extends Omit<OnboardingTaskItemProps, "practitioner"> {
  practitioner?: Practitioner
}

const OnboardingTaskItem: React.FC<Props> = ({ practitioner, ...props }) => {
  // wait until practitioner is loaded before rendering the task
  if (practitioner) {
    const taskProps: OnboardingTaskItemProps = { ...props, practitioner }

    switch (props.task.key) {
      case "preferred_phlebotomy":
        return <PreferredPhlebotomoy {...taskProps} />
      case "verify_email":
        return <VerifyEmail {...taskProps} />
      case "license_existing_license":
        return <ExistingLicense {...taskProps} />
      case "license_vendor_physician_service":
        return <PhysServicesLicense {...taskProps} />
      case "license_combined":
        return <LicenseCombined {...taskProps} />
      case "license_invited":
        return <LicenseInvited {...taskProps} />
      case "invite_your_team":
        return <InviteTeam {...taskProps} />
      case "point_of_contact":
        return <PointOfContact {...taskProps} />
      case "in_office_kits":
        return <InOfficeKits {...taskProps} />
    }
  }

  // TODO: return loading state
  return null
}

export default OnboardingTaskItem
