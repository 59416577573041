import { Skeleton } from "@material-ui/lab"

import BodyText from "app/components/design-system/BodyText"
import { LAB_COMPANY_KEY } from "app/constants"
import VibrantLeavingWarning from "app/main/warnings/VibrantLeavingWarning"
import { navy, colors } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"

import { Panel } from "../types"

const useStyles = makeAppStyles((theme) => ({
  nameContainer: {
    display: "flex",
    flexWrap: "wrap",
    flex: 1,
    minWidth: 150,
  },
  vibrantWarningContainer: {
    margin: "10px 0px",
  },
  selectedLabLabel: {
    color: navy,
    backgroundColor: colors.blueGray[200],
    borderRadius: 16,
    paddingLeft: 12,
    paddingRight: 12,
    paddingTop: 2,
    paddingBottom: 2,
    marginLeft: 8,
  },
}))

export default function PanelItemLabCompany({
  panel,
  isSelectedLabCompany,
}: {
  panel?: Panel
  isSelectedLabCompany: boolean
}) {
  const classes = useStyles()

  const showVibrantWarning =
    panel &&
    [
      LAB_COMPANY_KEY.VIBRANT_AMERICA,
      LAB_COMPANY_KEY.VIBRANT_WELLNESS,
    ].includes(panel.lab_company.key)

  return (
    <>
      {panel ? (
        <div className={classes.nameContainer}>
          <BodyText weight="semibold">
            {panel.lab_company.name}
            {isSelectedLabCompany && (
              <span className={classes.selectedLabLabel}>Selected Lab</span>
            )}
          </BodyText>
          {showVibrantWarning && (
            <div className={classes.vibrantWarningContainer}>
              <VibrantLeavingWarning />
            </div>
          )}
        </div>
      ) : (
        <Skeleton className={classes.nameContainer} />
      )}
    </>
  )
}
