import { AxiosRequestConfig } from "axios"

/**
 * Resource identifier as defined by the JSON:API specification.
 *
 * {@link https://jsonapi.org/format/#document-resource-identifier-objects}
 */
export interface ResourceIdentifier {
  type: string
  id: string
}

/**
 * Single resource relationship as defined by the JSON:API specification.
 *
 * {@link https://jsonapi.org/format/#document-resource-object-relationships}
 */
export interface ResourceRelationshipSingle {
  data: ResourceIdentifier
  meta?: Record<string, any>
}

/**
 * Many resource relationship as defined by the JSON:API specification.
 *
 * {@link https://jsonapi.org/format/#document-resource-object-relationships}
 */
export interface ResourceRelationshipMany {
  data: ResourceIdentifier[]
  meta: {
    count: number
    [key: string]: any
  }
}

/**
 * Resource relationships as defined by the JSON:API specification.
 *
 * {@link https://jsonapi.org/format/#document-resource-object-relationships}
 */
export type ResourceRelationship =
  | ResourceRelationshipSingle
  | ResourceRelationshipMany

/**
 * Resource as defined by the JSON:API specification.
 *
 * {@link https://jsonapi.org/format/#document-resource-objects}
 */
export interface Resource {
  type: string
  id: string
  attributes: Record<string, any>
  relationships: Record<string, ResourceRelationship>
  meta?: Record<string, any>
}

/**
 * Resource collection identifier.
 */
export interface ResourceCollection<I = ResourceIdentifier> {
  data: I[]
  meta?: Record<string, any>
}

export type ResourceRequestConfiguration<D = any> = Omit<
  AxiosRequestConfig<D>,
  "url" | "params"
> & {
  /**
   * The url of the endpoint.
   *
   * May include query parameters, but it is recommended to use #params.
   */
  url: string

  /**
   * The query parameters to include in the request url.
   */
  params?: Record<string, string | string[] | undefined>

  /**
   * The include parameters for the resource endpoint.
   *
   * Encoded as a comma-separated string and includes as a query parameter in the request url, for example:
   *
   * ```
   * /lab_tests?include=lab_company,lab_test_types
   * ```
   */
  include?: string[]

  /**
   * Returns the bearer access token for secured APIs. This token will be attached to the 'Authorization' header as a bearer token.
   *
   * ```
   * `Authorization: Bearer ${accessToken}`
   * ```
   */
  accessToken?: (() => Promise<string>) | (() => string) | string | null
}

/**
 * Properties of the request configuration used as the cache key for resource requests in SWR. Must be serializable.
 */
export type ResourceRequestConfigurationSWRKeyProps =
  | "url"
  | "params"
  | "include"
  | "method"

/**
 * The structure of the cache key used for caching resource requests in SWR.
 */
export type ResourceRequestCacheKey = Pick<
  ResourceRequestConfiguration,
  ResourceRequestConfigurationSWRKeyProps
>

/**
 * The response data for the JSON:API resource fetch endpoint.
 */
export interface ResourceResponse<R extends Resource = Resource> {
  data: R
  included?: Resource[]
}

export function isResourceResponse(object: any): object is ResourceResponse {
  return (
    typeof object === "object" && ("data" in object || "included" in object)
  )
}

/**
 * The response data for the JSON:API resource collection fetch endpoint.
 */
export type ResourceCollectionResponse<R extends Resource = Resource> = {
  data: R[]
  included?: Resource[]
  meta?: Record<string, any>
}

export function isResourceCollectionResponse(
  object: any
): object is ResourceCollectionResponse {
  return (
    typeof object === "object" && ("data" in object || "included" in object)
  )
}

/**
 * A resource error object as defined by the JSON:API specification.
 *
 * {@link https://jsonapi.org/format/#error-objects}
 */
export interface ResourceError {
  status: string
  id?: string
  code?: string
  title?: string
  detail?: string
  source?: {
    pointer?: string
    parameter?: string
    header?: string
  }
  meta?: Record<string, any>
}

/**
 * A resource error response as defined by the JSON:API specification.
 *
 * {@link https://jsonapi.org/format/#error-objects}
 */
export interface ResourceErrorResponse {
  errors: ResourceError[]
}
