import { useState } from "react"

import { Grid, Dialog, Theme, makeStyles, Button } from "@material-ui/core"
import MuiDialogActions from "@material-ui/core/DialogActions"
import MuiDialogContent from "@material-ui/core/DialogContent"
import MuiDialogTitle from "@material-ui/core/DialogTitle"
import PauseIcon from "@material-ui/icons/Pause"

import CloseIcon from "app/assets/icons/close-icon-navy.svg"
import BodyText from "app/components/design-system/BodyText"
import DisplayText from "app/components/design-system/DisplayText"
import { UsePractitionerProfile } from "app/main/find-a-practitioner/hooks/use-practitioner-profile"
import { colors, navy, white } from "app/theme"

export interface PauseProfileButtonProps {
  pauseProfile: UsePractitionerProfile["pauseProfile"]
  isPausing: boolean
}

const styles = (theme: Theme) => ({
  pauseButton: {
    color: colors.red[600],
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    marginTop: "35px",
  },
  closeIcon: {
    cursor: "pointer",
  },
  contentWrapper: {
    padding: theme.spacing(2),
    backgroundColor: colors.coolGray[100],
  },
  modalContentText: {
    padding: theme.spacing(1.5),
    color: navy,
  },
  dialogWrapper: {
    "& .MuiDialog-paper": {
      border: `1px solid ${colors.blueGray[300]}`,
      margin: "8px",
      borderRadius: 8,
    },
  },
  dialogTitle: {
    borderBottom: `1px solid ${colors.blueGray[300]}`,
  },
  titleContainer: {
    alignItems: "center",
    display: "flex",
    "text-align": "center",
    margin: "0 2px",
    color: navy,
    justifyContent: "space-between",
  },
  actionContainer: {
    alignItems: "center",
    background: white,
    borderTop: `1px solid ${colors.blueGray[300]}`,
    padding: theme.spacing(2),
  },
  customizeActionButton: {
    border: `1px solid ${colors.blueGray[300]}`,
    borderRadius: 6,
    height: "100%",
  },
  pauseModalConfirmText: {
    color: theme.palette.primary.main,
    margin: "8px 36px",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      margin: "8px",
    },
  },
  pauseModalCancelText: {
    color: navy,
    margin: "8px 36px",
  },
})

const useStyles = makeStyles(styles)

const PauseProfileModal = ({ open, handleClose, handlePause, isPausing }) => {
  const classes = useStyles()
  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="service-dialog"
      open={open}
      fullWidth
      maxWidth="sm"
      className={classes.dialogWrapper}
    >
      <MuiDialogTitle disableTypography className={classes.dialogTitle}>
        <div className={classes.titleContainer}>
          <DisplayText weight="semibold" size="xl">
            Pause Page
          </DisplayText>
          <img
            src={CloseIcon}
            className={classes.closeIcon}
            alt="close icon"
            onClick={handleClose}
          />
        </div>
      </MuiDialogTitle>
      <MuiDialogContent className={classes.contentWrapper}>
        <BodyText weight="semibold" className={classes.modalContentText}>
          Pausing will remove your page from Find a Practitioner.
        </BodyText>
      </MuiDialogContent>
      <MuiDialogActions className={classes.actionContainer}>
        <Grid container direction="row" spacing={2}>
          <Grid item xs={6}>
            <Button
              className={classes.customizeActionButton}
              onClick={handleClose}
              disabled={isPausing}
              variant="outlined"
              fullWidth
            >
              <BodyText
                size="base"
                weight="semibold"
                className={classes.pauseModalCancelText}
              >
                Back
              </BodyText>
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              className={classes.customizeActionButton}
              onClick={handlePause}
              disabled={isPausing}
              variant="outlined"
              fullWidth
            >
              <BodyText
                size="base"
                weight="semibold"
                className={classes.pauseModalConfirmText}
              >
                Pause Profile
              </BodyText>
            </Button>
          </Grid>
        </Grid>
      </MuiDialogActions>
    </Dialog>
  )
}

export const PauseProfileButton = ({
  pauseProfile,
  isPausing,
}: PauseProfileButtonProps) => {
  const classes = useStyles()

  const [modalOpen, setModalOpen] = useState(false)

  const handleClose = () => {
    setModalOpen(false)
  }

  const handlePause = async () => {
    await pauseProfile()
    handleClose()
  }

  return (
    <>
      <div
        className={classes.pauseButton}
        onClick={() => setModalOpen(!modalOpen)}
      >
        <PauseIcon />
        <span>Pause my page on Find a Practitioner</span>
      </div>
      <PauseProfileModal
        open={modalOpen}
        handleClose={handleClose}
        handlePause={handlePause}
        isPausing={isPausing}
      />
    </>
  )
}
