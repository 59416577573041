import React, { useMemo, useState } from "react"

import { CircularProgress } from "@material-ui/core"
import { Button } from "@rupahealth/design"

import { ReactComponent as FlaskIcon } from "app/assets/icons/flask.svg"
import { ReactComponent as QuestionCircleIcon } from "app/assets/icons/rupa-blood-dashboards/question-circle-outlined.svg"
import useFeatureFlag, { FeatureFlag } from "app/hooks/use-feature-flag"
import { ResultsUploadButton } from "app/main/results-upload-v2/ResultsUploadButton"
import { primaryColor } from "app/theme"
import { Patient } from "app/types"
import { PatientSettings } from "types/patient"

import { PatientOrdersTabHeader } from "../components/PatientOrdersTabHeader"
import Trends from "./Trends"
import TrendsEmptyState from "./TrendsEmptyState"
import TrendsAboutOptimalRangesButton from "./components/TrendsAboutOptimalRangesButton"
import RequestPastResultsButton from "./components/TrendsRequestPastResultsButton"
import {
  IMPORT_RESULTS_INTERCOM_SURVEY_ID,
  SUPPORTED_LABS_INTERCOM_ARTICLE_ID,
  SUPPORTED_LABS_INTERCOM_ARTICLE_URL,
} from "./constants/constants"

interface Props {
  patient: Patient
}

const PractitionerTrends = ({ patient }: Props) => {
  const handleImportResultsClick = () => {
    window.Intercom("startSurvey", IMPORT_RESULTS_INTERCOM_SURVEY_ID)
  }

  const [isTrendsManualUploadEnabled] = useFeatureFlag(
    FeatureFlag.ResultsOverTimeManualResultsUpload
  )

  const [isPatientPortalResultsUploadEnabled] = useFeatureFlag(
    FeatureFlag.PatientPortalResultsUploads
  )

  const [isLoading, setIsLoading] = useState(false)
  const [optimalRanges, setOptimalRanges] = useState<string[]>([])

  const showHighLowDescriptions = useMemo(() => {
    return patient?.patient_settings.includes(
      PatientSettings.INCLUDE_HIGH_LOW_DESCRIPTIONS_ON_BLOOD_REPORTS
    )
  }, [patient])

  return (
    <div className="rounded-lg p-7 bg-white shadow">
      <PatientOrdersTabHeader
        actionButtons={[
          <TrendsAboutOptimalRangesButton
            optimalRanges={optimalRanges}
            practitionerView={true}
          />,
          ...(isPatientPortalResultsUploadEnabled
            ? [<RequestPastResultsButton patient={patient} />]
            : []),
          ...(isTrendsManualUploadEnabled
            ? [<ResultsUploadButton patientId={patient.id} />]
            : [
                <Button
                  variant={null}
                  className="w-full h-auto text-[15px] text-primary fill-primary hover:bg-slate-100 py-1 px-2"
                  onClick={handleImportResultsClick}
                >
                  <FlaskIcon className="mr-2" fill={primaryColor} /> Import
                  Results (Coming Soon)
                </Button>,
              ]),
        ]}
        title={<PractitionerTrendsTitle isLoading={isLoading} />}
      />

      {patient.user.id && (
        <Trends
          userId={patient.user.id}
          onLoading={setIsLoading}
          showHighLowDescriptions={showHighLowDescriptions}
          onOptimalRanges={setOptimalRanges}
          emptyChildren={<TrendsEmptyState patient={patient} />}
        />
      )}
    </div>
  )
}

const PractitionerTrendsTitle: React.FC<{ isLoading: boolean }> = ({
  isLoading,
}) => {
  return (
    <div className="flex gap-2 items-center">
      Trends
      <Button
        variant={null}
        className="text-primary w-auto h-auto p-0"
        onClick={() =>
          window.Intercom
            ? window.Intercom("showArticle", SUPPORTED_LABS_INTERCOM_ARTICLE_ID)
            : window.open(SUPPORTED_LABS_INTERCOM_ARTICLE_URL, "_blank")
        }
      >
        <QuestionCircleIcon fill={primaryColor} width={15} height={15} />
      </Button>
      {isLoading && <CircularProgress size={18} />}
    </div>
  )
}

export default PractitionerTrends
