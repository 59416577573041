import { useForm } from "react-hook-form"
import { z } from "zod"

import { zodResolver } from "@hookform/resolvers/zod"

import { stateCodeIfValid } from "app/main/patient-checkout/utils/usStates"
import { Practitioner } from "app/types"
import { PractitionerLicenseCreateForm } from "types/practitioner-license"

import { LicenseType } from "./types"

interface Props {
  licenseType?: LicenseType
  practitioner: Practitioner
}

export default function useLicenseCreateForm({
  licenseType,
  practitioner,
}: Props) {
  const defaultValues: PractitionerLicenseCreateForm = {
    file: undefined,
    type: licenseType ?? LicenseType.license,
    practitioner_type_id:
      practitioner.primary_practitioner_type?.id?.toString() ?? "",
    npi_number: practitioner.npi_number ?? "",
    expiration_date: undefined,
    state:
      licenseType === LicenseType.license
        ? stateCodeIfValid(practitioner.clinic?.state)
        : "",
  }

  let schema = z.object({
    npi_number: z
      .string()
      .regex(/^[0-9]*$/, "NPI Number should only contain numbers.")
      .length(10, "NPI Number should be 10 digits.")
      .optional()
      .or(z.literal("")),
    type: z.nativeEnum(LicenseType),
  })

  if (licenseType === LicenseType.license) {
    schema = schema.extend({
      file: z.instanceof(File, "License upload is required."),
      state: z.string().nonempty("State Licensed is required."),
      practitioner_type_id: z
        .string()
        .nonempty("Practitioner Type is required."),
      expiration_date: z
        .date({ required_error: "Expiration Date is required." })
        .refine((d) => d >= new Date(), {
          message: "Expiration Date cannot be in the past.",
        }),
    })
  } else if (licenseType === LicenseType.certificate) {
    schema = schema.extend({
      file: z.instanceof(File, "Certification upload is required."),
      practitioner_type_id: z
        .string()
        .nonempty("Certification Type is required."),
      expiration_date: z
        .date()
        .optional()
        .refine((d) => !d || d >= new Date(), {
          message: "Expiration Date cannot be in the past.",
        }),
    })
  }

  return useForm<PractitionerLicenseCreateForm>({
    defaultValues,
    resolver: zodResolver(schema),
    mode: "onChange",
  })
}
