import * as React from "react"

import clsx from "clsx"

import { makeStyles, Theme } from "@material-ui/core"

/*
 * Legacy DashboardBanner component.
 * Use Banner from design-system instead.
 */

interface BannerProps {
  onClick?: (event: React.MouseEvent<HTMLElement>) => void
  backgroundColor?: string
  borderColor?: string
  [x: string]: any
}

const Banner = ({
  onClick,
  backgroundColor,
  borderColor,
  className,
  ...props
}: BannerProps) => {
  const classes = useStyles({
    backgroundColor,
    borderColor,
    clickable: !!onClick,
  })
  return (
    <div
      className={clsx(classes.Container, className)}
      onClick={onClick}
      style={props.style}
    >
      {props.children}
    </div>
  )
}

const useStyles = makeStyles<
  Theme,
  { backgroundColor?: string; borderColor?: string; clickable?: boolean }
>((theme) => ({
  Container: {
    display: "flex",
    padding: "8px 12px",
    marginBottom: "19px",
    alignItems: "center",
    justifyContent: "left",
    backgroundColor: (props) => props.backgroundColor,
    border: (props) => `2px solid ${props.borderColor}`,
    borderLeft: (props) => `8px solid ${props.borderColor}`,
    boxSizing: "border-box",
    borderRadius: 6,
    cursor: (props) => (props.clickable ? "pointer" : undefined),

    boxShadow:
      "0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)",
  },
}))

export default Banner
