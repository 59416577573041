import * as React from "react"
import { Fragment } from "react"

import clsx from "clsx"

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
} from "@material-ui/core"
import { Skeleton } from "@material-ui/lab"
import { Row, Table as TanstackTable, flexRender } from "@tanstack/react-table"

import { ReactComponent as InboxIcon } from "app/assets/icons/inbox.svg"
import ScrollToAnchor from "app/components/ScrollToAnchor"
import BodyText from "app/components/design-system/BodyText"
import Button from "app/components/design-system/Button"
import TableRowProvider from "app/dashboard/contexts/TableRowProvider"
import { DashboardOrder } from "types/dashboard/dashboard-order"

import TablePaginationRenderer from "../TablePaginationRenderer"
import { GlobalFilterState, TabId } from "./hooks/use-table"
import useTableStyles from "./hooks/use-table-styles"

export interface TableTabsContext {
  table: TanstackTable<DashboardOrder>
}

export interface TableRowDetailContext {
  row: Row<DashboardOrder>
}

export interface TableRendererProps {
  RowDetail: React.ReactNode
  renderTabs: (info: TableTabsContext) => React.ReactElement
  table: TanstackTable<DashboardOrder>
}

export default function TableRenderer({
  RowDetail,
  renderTabs,
  table,
}: TableRendererProps) {
  const classes = useTableStyles()
  return (
    <ScrollToAnchor
      behavior="smooth"
      block="start"
      component={TableContainer}
      componentProps={{
        className: classes.container,
        component: Paper,
      }}
      top={-150}
      when={table.getState().pagination.pageIndex}
    >
      {renderTabs({ table })}
      <Table className={classes.table} data-testid="order-list">
        <TableHead>
          <TableHeaderRow table={table} />
        </TableHead>

        <TableBody>
          {table.options.meta?.isLoading ? (
            <TablePlaceholderRows table={table} />
          ) : table.getRowModel().rows.length > 0 ? (
            <TableDataRows RowDetail={RowDetail} table={table} />
          ) : (
            <TableEmptyRow table={table} />
          )}
        </TableBody>

        <TableFooter>
          <TableRow className={classes.tablePaginationRow}>
            <TablePaginationRenderer table={table} />
          </TableRow>
        </TableFooter>
      </Table>
    </ScrollToAnchor>
  )
}

const TableHeaderRow = ({
  table,
}: {
  table: TanstackTable<DashboardOrder>
}) => {
  const classes = useTableStyles()
  return (
    <>
      {table.getHeaderGroups().map((headerGroup) => (
        <TableRow key={headerGroup.id} className={classes.tableHeaderRow}>
          {headerGroup.headers.map((header) => (
            <TableCell
              key={header.id}
              className={classes.tableCell}
              colSpan={header.colSpan}
              style={{
                ...header.column.columnDef.meta?.cellStyle,
                ...header.column.columnDef.meta?.headerCellStyle,
                maxWidth: header.column.columnDef.maxSize,
                minWidth: header.column.columnDef.minSize,
                width: header.getSize(),
              }}
            >
              {header.isPlaceholder
                ? null
                : flexRender(
                    header.column.columnDef.header,
                    header.getContext()
                  )}
            </TableCell>
          ))}
        </TableRow>
      ))}
    </>
  )
}

const TablePlaceholderRows = ({
  table,
}: {
  table: TanstackTable<DashboardOrder>
}) => {
  const classes = useTableStyles()
  return (
    <>
      {[0, 1, 2, 3, 4].map((index) => (
        <TableRow key={index} className={classes.tableDataRow}>
          {table.getAllColumns().map((column) => (
            <TableCell
              key={column.id}
              className={clsx(classes.tableCell, {
                [classes.tableDataCellStickyLeft]:
                  column.columnDef.meta?.stickyOnRowHover === "left",
                [classes.tableDataCellStickyRight]:
                  column.columnDef.meta?.stickyOnRowHover === "right",
              })}
              style={{
                ...column.columnDef.meta?.cellStyle,
                ...column.columnDef.meta?.dataCellStyle,
              }}
            >
              {Boolean(column.accessorFn) ? (
                <Skeleton
                  animation="wave"
                  component="div"
                  height={24}
                  width="75%"
                  variant="text"
                />
              ) : null}
            </TableCell>
          ))}
        </TableRow>
      ))}
    </>
  )
}

const TableDataRows = ({
  RowDetail,
  table,
}: {
  RowDetail: React.ReactNode
  table: TanstackTable<DashboardOrder>
}) => {
  const classes = useTableStyles()
  return (
    <>
      {table.getRowModel().rows.map((row) => (
        <Fragment key={row.id}>
          <TableRow
            className={clsx(classes.tableDataRow, {
              [classes.tableDataRowCanExpand]: row.getCanExpand(),
              [classes.tableDataRowIsExpanded]: row.getIsExpanded(),
            })}
            onClick={
              row.getCanExpand() ? row.getToggleExpandedHandler() : undefined
            }
          >
            {row.getVisibleCells().map((cell) => (
              <TableCell
                key={cell.id}
                className={clsx(classes.tableCell, {
                  [classes.tableDataCellStickyLeft]:
                    cell.column.columnDef.meta?.stickyOnRowHover === "left",
                  [classes.tableDataCellStickyRight]:
                    cell.column.columnDef.meta?.stickyOnRowHover === "right",
                })}
                style={{
                  ...cell.column.columnDef.meta?.cellStyle,
                  ...cell.column.columnDef.meta?.dataCellStyle,
                }}
              >
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </TableCell>
            ))}
          </TableRow>
          {row.getIsExpanded() && (
            <TableRowProvider row={row}>{RowDetail}</TableRowProvider>
          )}
        </Fragment>
      ))}
    </>
  )
}

const TAB_LABELS = {
  draft: "Drafts",
  in_progress: "In Progress",
  results_in: "Results In",
  reviewed: "Reviewed",
}

const TableEmptyRow = ({ table }: { table: TanstackTable<DashboardOrder> }) => {
  const classes = useTableStyles()
  const tab = (table.getState().globalFilter as GlobalFilterState).tab
  const isTabFiltered = tab && tab !== TabId.ALL
  const isFiltered = table.getState().columnFilters.length > 0

  const parts =
    tab === TabId.ALERTS
      ? ["We were unable to find any orders with active alerts."]
      : [
          "We were unable to find any ",
          isTabFiltered ? `${TAB_LABELS[tab]} ` : "",
          "orders ",
          isFiltered ? "for your search." : "for you.",
        ]

  return (
    <TableRow className={classes.tableEmptyRow}>
      <TableCell
        align="center"
        className={classes.tableCell}
        colSpan={table.getAllColumns().length}
      >
        <InboxIcon className={classes.tableEmptyRowIcon} />
        <div className={classes.tableEmptyRowText}>
          <BodyText color="inherit" weight="semibold" size="sm">
            {parts.join("")}
          </BodyText>

          {isTabFiltered && (
            <Button
              color="text"
              disableRipple
              onClick={() =>
                table.setGlobalFilter((globalFilter: GlobalFilterState) => ({
                  ...globalFilter,
                  tab: TabId.ALL,
                }))
              }
              size="small"
              type="button"
            >
              Search all orders.
            </Button>
          )}
        </div>
      </TableCell>
    </TableRow>
  )
}
