import { useMemo } from "react"

import { AxiosError } from "axios"
import { assign, isFunction } from "lodash"
import useSWR, {
  MutatorCallback,
  MutatorOptions,
  SWRConfiguration,
  useSWRConfig,
} from "swr"

import useEventCallback from "app/hooks/use-event-callback"
import useHandleApiError from "app/hooks/use-handle-api-error"
import { getResourceRequestCacheKey } from "app/swr/helpers/keys"
import { writeToCache } from "app/swr/helpers/swr"
import useCachedResource from "app/swr/hooks/use-cached-resource"
import useMutateResource from "app/swr/hooks/use-mutate-resource"
import {
  Resource,
  ResourceErrorResponse,
  ResourceIdentifier,
  ResourceRequestCacheKey,
  ResourceRequestConfiguration,
  ResourceResponse,
  isResourceResponse,
} from "app/swr/types"
import resourceRequest from "app/swr/utils/resource-request"

export type ResourceSWRConfiguration = SWRConfiguration & {
  /**
   * A fallback identifier to use as the identifier if data has not yet been fetched.
   */
  fallbackIdentifier?: ResourceIdentifier

  /**
   * Whether or not to engage `handleApiError` function when the fetcher fails. Defaults to true.
   */
  shouldHandleApiError?: boolean
}

type MutatorType<R extends Resource> =
  | R
  | ResourceResponse
  | undefined
  | Promise<R | ResourceResponse | undefined>
  | MutatorCallback<R>
  | ((
      currentData?: R
    ) => ResourceResponse | Promise<ResourceResponse | undefined> | undefined)

export interface UseResourceSwrReturn<R extends Resource> {
  error: AxiosError<ResourceErrorResponse> | undefined
  isValidating: boolean
  isLoading: boolean
  data: R | undefined
  identifier: ResourceIdentifier | undefined
  mutate: (
    mutator: MutatorType<R>,
    _mutatorOptions: MutatorOptions<R>
  ) => Promise<R | undefined>
}

/**
 * Fetches a resource at the given url using the SWR pattern.
 *
 * Useful for fetching data for a single resource object, for example:
 *
 * ```typescript
 * const { data } = useResourceSWR<LabTest>("/lab_tests/123")
 * ```
 *
 * Supports non-standard endpoint urls as long as the response conforms to JSON:API specification, for example:
 *
 * ```typescript
 * const { data } = useResourceSWR<StorefrontOrder>("/storefront/123/cart")
 * ```
 *
 * Supports the "include" parameter first-class to support fetching related resources efficiently, for example:
 *
 * ```typescript
 * const { data: labTest } = useResourceSWR<LabTest>("/lab_tests/123", { include: ["lab_company"] })
 *
 * // Access the included lab company from the cache.
 * const { data: labCompany } = useCachedResource<LabCompany>(labTest.relationships.lab_company.data)
 * ```
 *
 * Supports custom parameters if needed for certain scenarios, for example:
 *
 * ```typescript
 * const { data } = useResourceSWR<PatientCheckoutOrder>("/patientcheckout", { params: { token: "TOKEN" }})
 * ```
 *
 * Supports providing an access token to be included as an Authorization header bearer token, for example:
 *
 * ```typescript
 * const { data } = useResourceSWR<LabTest>("/lab_tests/123", { accessToken: myJwtToken })
 * ```
 *
 * Supports mutation operations using the returned `mutate` function, for example:
 *
 * ```typescript
 * const { data, mutate } = useResourceSWR<Cart>("/my-cart")
 *
 * async function handleSubmit(update) {
 *   await mutate(updateCart(update))
 * }
 * ```
 *
 * Supports using mutate to revalidate the data explicitly, for example:
 *
 * ```typescript
 * const { data, mutate } = useResourceSWR<Cart>("/my-cart")
 *
 * function onEvent() {
 *   // do some stuff...
 *
 *   // force a revalidation
 *   mutate()
 * }
 * ```
 *
 * @param url the JSON:API endpont url
 * @param requestConfig the request configuration
 * @param resourceSwrConfig the SWR configuration
 * @returns the swr response with resource data
 */
export default function useResourceSWR<R extends Resource>(
  url?: string | null | false,
  requestConfig: Omit<ResourceRequestConfiguration, "url"> = {},
  resourceSwrConfig: ResourceSWRConfiguration = {}
): UseResourceSwrReturn<R> {
  const globalConfig = useSWRConfig()
  const requestKey = useMemo(
    () => getResourceRequestCacheKey(url, requestConfig),
    [url, requestConfig]
  )
  const {
    fallbackIdentifier,
    shouldHandleApiError = true,
    ...swrHookConfig
  } = resourceSwrConfig
  const handleApiError = useHandleApiError()

  const {
    data: identifier,
    mutate: identifierMutate,
    ...swr
  } = useSWR<
    ResourceIdentifier | undefined,
    AxiosError<ResourceErrorResponse>,
    ResourceRequestCacheKey | null
  >(
    requestKey,
    useEventCallback(async (requestKey) => {
      const { data, included = [] } = await resourceRequest<ResourceResponse>({
        ...requestKey,
        ...requestConfig,
      })

      if (!data) {
        return undefined
      }

      const identifier: ResourceIdentifier = {
        type: data.type,
        id: data.id,
      }

      await writeToCache(globalConfig, data, ...included)

      return identifier
    }),
    {
      ...swrHookConfig,
      onError(err, _key, _config) {
        if (shouldHandleApiError) {
          handleApiError(err)
        }

        swrHookConfig?.onError?.(err, _key, _config)
      },
      fallbackData: fallbackIdentifier,
    }
  )

  const resource = useCachedResource<R>(identifier)
  const mutateResource = useMutateResource()

  // Implement a custom mutator to support accepting ResourceResponse in addition to Resource as mutator results.
  const mutate = useEventCallback(
    async (mutator: MutatorType<R>, _mutatorOptions: MutatorOptions<R>) => {
      if (
        typeof mutator === "undefined" &&
        typeof _mutatorOptions === "undefined"
      ) {
        // Ensure we cause a revalidation when called with empty arguments.
        await identifierMutate()
        return
      }

      if (!identifier) {
        // Unable to mutate if there is no resource to mutate.
        return
      }

      const mutatorOptions = assign(
        // Defaults from swr's mutate
        {
          revalidate: true,
          populateCache: true,
          rollbackOnError: true,
          throwOnError: true,
        },
        typeof _mutatorOptions === "boolean"
          ? { revalidate: _mutatorOptions }
          : _mutatorOptions || {}
      )

      const mutatorFn = isFunction(mutator) ? mutator : async () => mutator

      const result = await mutateResource<R>(
        identifier,
        async (currentData) => {
          const mutationResult = await mutatorFn(currentData)

          if (isResourceResponse(mutationResult)) {
            const { data, included = [] } = mutationResult

            if (mutatorOptions.populateCache) {
              await writeToCache(globalConfig, ...included)
            }

            return data as R
          }

          return mutationResult
        },
        mutatorOptions
      )

      if (mutatorOptions.revalidate) {
        // Ensure we cause a revalidation in this case.
        await identifierMutate()
      }

      return result
    }
  )

  return {
    ...swr,
    data: resource,
    identifier,
    mutate,
  }
}
