import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"

import { Button } from "@rupahealth/design"

import { ReactComponent as InitialsIcon } from "app/assets/icons/initials.svg"
import { ReactComponent as PhysAuthIcon } from "app/assets/icons/phys-auth-glob.svg"
import ActionRequiredImage from "app/assets/images/action-required.jpg"
import { resendRegistrationEmail } from "app/auth/store/actions"
import DesignSystemButton from "app/components/design-system/Button"
import ActionRequiredModal from "app/components/modals/ActionRequiredModal"
import usePhysicianServicesOptInModal from "app/components/modals/PhysicianServicesOptInModal/use-physician-services-opt-in-modal"
import SignatureAndLicenseModalButton, {
  signatureAndLicenseButtonTitle,
} from "app/components/modals/SignatureAndLicenseModal/SignatureAndLicenseModalButton"
import { VENDOR_PHYSICIAN_AUTHORIZATION_LABEL } from "app/constants"
import { trackUploadLicense } from "app/services/segment"

import TaskContainer, { TaskContainerProps } from "../TaskContainer"
import { TaskStepComplete } from "../TaskStepComplete"
import taskStyles from "../TaskStyles"
import { PHYSICIAN_SERVICES_TASK_TEXT } from "../constants"
import { OnboardingTaskItemProps } from "./OnboardingTaskItem"

// LicenseCombined and LicenseInvited both use this shared component
// to display License/Signature button and Physician Services button.

type Props = OnboardingTaskItemProps &
  Omit<TaskContainerProps, "taskKey" | "header" | "isTaskComplete"> & {}

const LicenseAndPhysicianServicesTask: React.FC<Props> = ({
  task,
  onTaskComplete,
  practitioner,
  refreshPractitioner,
  ...containerProps
}) => {
  const taskClasses = taskStyles()

  const [isActionRequiredModalOpen, setIsActionRequiredModalOpen] =
    useState(false)

  const isLicenseComplete = practitioner?.has_valid_license
  const isSignatureComplete = practitioner?.has_signature
  const isPhysicianServicesEnabled =
    practitioner?.vendor_physician_authorization_enabled

  useEffect(() => {
    if (
      !task.complete &&
      isLicenseComplete &&
      isSignatureComplete &&
      isPhysicianServicesEnabled
    ) {
      onTaskComplete(task.key)
    }
  }, [isLicenseComplete, isSignatureComplete, isPhysicianServicesEnabled])

  const dispatch = useDispatch()

  const resendEmailVerification = () => {
    dispatch(resendRegistrationEmail({ email: practitioner.user.email }))
    setIsActionRequiredModalOpen(false)
  }

  const physicianServicesOptInModal = usePhysicianServicesOptInModal()

  const onEnablePhysicianServicesClick = () => {
    physicianServicesOptInModal.show({
      returnToDescriptor: "Onboarding",
      onClose: async (finishedApplication) => {
        if (finishedApplication) {
          await refreshPractitioner()
        }
        physicianServicesOptInModal.remove()
      },
    })
  }

  return (
    <TaskContainer
      taskKey={task.key}
      header={`Upload Your License or Add ${VENDOR_PHYSICIAN_AUTHORIZATION_LABEL} to your Account`}
      isTaskComplete={task.complete}
      timeTag="2 mins"
      {...containerProps}
    >
      <InitialsIcon className={taskClasses.initialsIcon} />
      <div className={taskClasses.taskTextContainer}>
        <div className={taskClasses.taskText}>
          Order for clients under your own license. Your information will be on
          your patient's requisitions and results.
        </div>
      </div>
      {isLicenseComplete && isSignatureComplete ? (
        <TaskStepComplete taskText="Uploaded License & E-Signature" />
      ) : (
        <>
          {isLicenseComplete ? (
            <TaskStepComplete taskText="Uploaded License" />
          ) : isSignatureComplete ? (
            <TaskStepComplete taskText="Uploaded E-Signature" />
          ) : null}
          <SignatureAndLicenseModalButton
            location={task.key}
            onClose={() => refreshPractitioner()}
            autoClose
            handleClick={(openModal) => {
              if (practitioner?.user?.is_verified) {
                openModal()
              } else {
                setIsActionRequiredModalOpen(true)
              }
              trackUploadLicense()
            }}
          >
            {signatureAndLicenseButtonTitle(practitioner)}
          </SignatureAndLicenseModalButton>
        </>
      )}
      <div className={taskClasses.divider} />
      <PhysAuthIcon className={taskClasses.physServicesIcon} />
      <div className={taskClasses.taskTextContainer}>
        <div className={taskClasses.taskText}>
          {PHYSICIAN_SERVICES_TASK_TEXT}
        </div>
      </div>
      {practitioner?.vendor_physician_authorization_enabled ? (
        <TaskStepComplete
          taskText={`Enabled ${VENDOR_PHYSICIAN_AUTHORIZATION_LABEL}`}
        />
      ) : (
        <div className="flex flex-col gap-2 items-center justify-center">
          <Button
            variant="outline-primary"
            className="shadow-sm"
            onClick={onEnablePhysicianServicesClick}
          >
            Add {VENDOR_PHYSICIAN_AUTHORIZATION_LABEL}
          </Button>
          <div className="text-slate-500">
            You can always do this later in Settings.
          </div>
        </div>
      )}

      <ActionRequiredModal
        testId="verification-required-modal"
        open={isActionRequiredModalOpen}
        image={ActionRequiredImage}
        imageAlt="Action Required"
        title="Verification Required"
        subtitle="Please verify your email address first."
        button={
          <DesignSystemButton
            color="primary"
            variant="text"
            onClick={resendEmailVerification}
          >
            Verify Email Address
          </DesignSystemButton>
        }
      />
    </TaskContainer>
  )
}

export default LicenseAndPhysicianServicesTask
