import { makeStyles } from "@material-ui/core"

import { ReactComponent as FeedbackIconBlue } from "app/assets/icons/feedback-blue.svg"
import Button from "app/components/design-system/Button"

const useStyles = makeStyles({
  surveyButton: {
    display: "flex",
    alignItems: "center",
    alignSelf: "center",
    "& .MuiButton-label": {
      marginBottom: -3,
    },
  },
})

export interface FeedbackButtonProps {
  surveyId: Number
  text: string
}

export default function FeedbackButton({
  surveyId,
  text,
}: FeedbackButtonProps) {
  const classes = useStyles()
  return (
    <Button
      color="text"
      disableRipple
      className={classes.surveyButton}
      onClick={() => window.Intercom("startSurvey", surveyId)}
      size="small"
      type="button"
      startIcon={<FeedbackIconBlue />}
    >
      {text}
    </Button>
  )
}
