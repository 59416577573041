import { ORDERED_TEST_STATUS } from "app/constants.typed"

import { StatusConfiguration } from "../../types"
import { StatusConfigurationProps } from "../../types/patient-orders-types"
import { PROGRESS_PERCENTAGES } from "../consts"

const SubTitle = ({ patient }: StatusConfigurationProps) => {
  return (
    <span>
      {`The authorizing physician is reviewing these tests for ${patient.full_name}.`}
    </span>
  )
}

const pendingPhysServicesAuthStatusConfiguration: StatusConfiguration<StatusConfigurationProps> =
  {
    when: {
      orderedTestStatus: ORDERED_TEST_STATUS.PENDING_PHYSICIAN_AUTHORIZATION,
    },
    progressPercentage: PROGRESS_PERCENTAGES.PENDING_PHYSICIAN_AUTHORIZATION,
    title: "Pending Authorizing Physician Review",
    subTitle: SubTitle,
  }

export default pendingPhysServicesAuthStatusConfiguration
