import { useState, useMemo, useEffect } from "react"

import { useParams } from "react-router-dom"

import { makeStyles, Theme, useMediaQuery } from "@material-ui/core"

import WhiteShoppingCartLogo from "app/assets/icons/shopping-cart-white.svg"
import RupaLogo from "app/assets/images/logos/rupa-health-logo.svg"
import DesignSystemButton from "app/components/design-system/Button"
import {
  OrderIntent,
  StorefrontType,
  useResource,
} from "app/hooks/use-resource"
import { primaryColor, textPrimaryColor } from "app/theme"

import { CartSidebar } from "./CartSidebar"

const styles = (theme: Theme) => ({
  container: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    padding: "10px 20px",
    "box-shadow":
      "0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)",
    background: "white",
    "z-index": 2,
    alignItems: "center",
    position: "sticky" as any,
    top: 0,
    zIndex: 999,
  },
  rupaLogo: {
    width: 200,
  },
  navbarLink: {
    fontWeight: 600,
    marginRight: 34.5,
    color: textPrimaryColor,
    cursor: "pointer",
  },
  cartBtnText: {
    display: "flex",
    alignItems: "baseline",
  },
  shoppingCartLogo: {
    position: "relative" as any,
  },
  cartCounter: {
    borderRadius: 79,
    color: primaryColor,
    background: "white",
    height: 21,
    width: 21,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: 2,
    marginLeft: 8,
  },
})

const useStyles = makeStyles(styles)

interface Props {
  hideCart?: boolean
  scrollToFaq: () => void
  storefront: StorefrontType | null
}

const Navbar = ({ hideCart, scrollToFaq, storefront }: Props) => {
  const [cartOpen, setCartOpen] = useState(false)
  const classes = useStyles()
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"))
  const { storefrontId } = useParams<{
    storefrontId: string
  }>()
  const { data: cart } = useResource<OrderIntent>(
    `storefronts/${storefrontId}/cart/`,
    {
      include: [
        "line_items",
        "storefront_products.bundle.lab_tests.lab_company",
        "storefront_products.lab_test.lab_company",
      ],
    }
  )

  const cartCount = useMemo(
    () =>
      cart
        ? (cart.relationships.lab_tests?.data?.length || 0) +
          (cart.relationships.bundles?.data?.length || 0) +
          (cart.relationships.storefront_products?.data?.length || 0)
        : 0,
    [cart]
  )

  useEffect(() => {
    if (cart && cartCount === 0) {
      setCartOpen(false)
    }
  }, [cartCount, cart])

  return (
    <>
      <div className={classes.container} style={{ position: "-webkit-sticky" }}>
        <img
          src={RupaLogo}
          alt="rupahealth logo"
          className={classes.rupaLogo}
        />
        <div>
          {!isMobile && (
            <span onClick={scrollToFaq} className={classes.navbarLink}>
              FAQ
            </span>
          )}
          {!hideCart && (
            <DesignSystemButton
              id="open-cart"
              color="primary"
              onClick={() => setCartOpen(!cartOpen)}
              startIcon={
                <img
                  src={WhiteShoppingCartLogo}
                  alt="shopping cart"
                  className={classes.shoppingCartLogo}
                />
              }
            >
              <div className={classes.cartBtnText}>
                Cart <div className={classes.cartCounter}>{cartCount}</div>
              </div>
            </DesignSystemButton>
          )}
        </div>
      </div>
      {cartOpen && (
        <CartSidebar close={() => setCartOpen(false)} storefront={storefront} />
      )}
    </>
  )
}

export default Navbar
