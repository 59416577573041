import validator from "validator"
import { z } from "zod"

import {
  emailPassesRegex,
  urlPassesRegex,
} from "app/main/find-a-practitioner/utils"

export const AddAccountSchema = () =>
  z.object({
    practiceName: z.string().nonempty("This field is required."),
    practiceStreetAddress: z.string().nonempty("This field is required."),
    practiceCity: z.string().nonempty("This field is required."),
    practiceState: z.string().nonempty("This field is required."),
    practiceZip: z
      .string()
      .length(5, "Zip code must be 5 digits.")
      .nonempty("This field is required."),
    clinicPhoneNumber: z.string().refine(validator.isMobilePhone),
    clinicWebsite: z
      .string()
      .refine(
        (value) => !value || value.length === 0 || urlPassesRegex(value),
        "Please enter a valid URL including http:// or https://"
      ),
    practiceNPI: z
      .string()
      .length(10, "An NPI must be 10 digits.")
      .optional()
      .or(z.literal("")),
    providerName: z.string().nonempty("This field is required."),
    providerNPI: z
      .string()
      .length(10, "An NPI must be 10 digits.")
      .nonempty("This field is required."),
    accountNumber: z.string().min(8).nonempty("This field is required."),
    accountRep: z.string().nonempty("This field is required."),
    accountRepEmail: z
      .string()
      .refine(
        (value) => emailPassesRegex(value),
        "Please enter a valid email address."
      ),
  })
