import { useState } from "react"

import { find, pick } from "lodash"
import { useParams } from "react-router-dom"

import useFoodPlanGenerateFeed from "app/food-plans/hooks/use-food-plan-generate-feed"
import useLegacyPatientResource from "app/food-plans/hooks/use-legacy-patient-resource"
import useEventCallback from "app/hooks/use-event-callback"
import useHandleApiError from "app/hooks/use-handle-api-error"
import useCachedResource from "app/swr/hooks/use-cached-resource"
import useResourceSWR from "app/swr/hooks/use-resource-swr"
import { ResourceResponse } from "app/swr/types"
import resourceRequest from "app/swr/utils/resource-request"
import { FoodPlan } from "types/food-plan"
import {
  EditableAttributes,
  FoodPlanVersion,
  FoodPlanVersionStartGenerate,
} from "types/food-plan-version"

function foodPlanMutator(promise: Promise<ResourceResponse<FoodPlanVersion>>) {
  return async (prevFoodPlan?: FoodPlan) => {
    const { data, included = [] } = await promise

    const nextFoodPlan = find(included, { type: "food_plan" })

    return {
      data: nextFoodPlan || prevFoodPlan,
      included: [data, ...included],
    } as ResourceResponse<FoodPlan>
  }
}

export default function usePatientFoodPlanResources() {
  const handleApiError = useHandleApiError()
  const legacyPatient = useLegacyPatientResource()
  const { foodPlanId } = useParams<{ foodPlanId: string }>()
  const { data: foodPlan, mutate: mutateFoodPlan } = useResourceSWR<FoodPlan>(
    `/food_plans/${foodPlanId}/`,
    {
      include: ["latest_version"],
    },
    {
      fallbackIdentifier: {
        type: "food_plan",
        id: foodPlanId,
      },
      revalidateOnMount: true,
      revalidateOnFocus: true,
      revalidateOnReconnect: true,
    }
  )
  const foodPlanVersion = useCachedResource<FoodPlanVersion>(
    foodPlan?.relationships.latest_version.data
  )

  const { feed, mutate: mutateFeed } = useFoodPlanGenerateFeed({
    foodPlanVersion,
    onFinish: useEventCallback(() => {
      // Revalidate the food plan to get the latest version once the generation finishes.
      mutateFoodPlan(undefined, { revalidate: true, populateCache: false })
    }),
  })

  const [isPatching, setIsPatching] = useState(false)
  const onPatch = useEventCallback(
    async (attributes: Partial<EditableAttributes>) => {
      if (!foodPlanVersion) {
        return
      }

      try {
        setIsPatching(true)

        await mutateFoodPlan(
          foodPlanMutator(
            resourceRequest<ResourceResponse<FoodPlanVersion>>({
              url: `/food_plan_versions/${foodPlanVersion.id}/`,
              method: "PATCH",
              include: ["food_plan"],
              data: {
                data: {
                  type: "food_plan_version",
                  id: foodPlanVersion.id,
                  attributes,
                },
              },
            })
          ),
          {
            revalidate: false,
            throwOnError: true,
          }
        )
      } catch (error) {
        handleApiError(error)
      } finally {
        setIsPatching(false)
      }
    }
  )

  const [isStartingCancel, setIsStartingCancel] = useState(false)
  const onStartCancel = useEventCallback(async () => {
    if (!foodPlanVersion) {
      return
    }

    try {
      setIsStartingCancel(true)

      await mutateFoodPlan(
        foodPlanMutator(
          resourceRequest<ResourceResponse<FoodPlanVersion>>({
            method: "post",
            url: `/food_plan_versions/${foodPlanVersion.id}/cancel/`,
            include: ["food_plan"],
            data: {
              data: {
                type: "food_plan_version",
                id: foodPlanVersion.id,
              },
            },
          })
        ),
        {
          revalidate: false,
          throwOnError: true,
        }
      )

      mutateFeed(undefined)
    } catch (error) {
      handleApiError(error)
    } finally {
      setIsStartingCancel(false)
    }
  })

  const [isStartingGenerate, setIsStartingGenerate] = useState(false)
  const onStartGenerate = useEventCallback(
    async (attributes: FoodPlanVersionStartGenerate) => {
      if (!foodPlanVersion) {
        return
      }

      try {
        setIsStartingGenerate(true)

        await mutateFoodPlan(
          foodPlanMutator(
            resourceRequest<ResourceResponse<FoodPlanVersion>>({
              method: "post",
              url: `/food_plan_versions/${foodPlanVersion.id}/generate/`,
              include: ["food_plan"],
              data: {
                data: {
                  type: "food_plan_version",
                  attributes,
                },
              },
            })
          ),
          {
            revalidate: false,
            throwOnError: true,
          }
        )
      } catch (error) {
        handleApiError(error)
      } finally {
        setIsStartingGenerate(false)
      }
    }
  )

  const [isRestarting, setIsRestarting] = useState(false)
  const onRestart = useEventCallback(async () => {
    if (!foodPlanVersion || !foodPlan) {
      return
    }

    try {
      setIsRestarting(true)

      await mutateFoodPlan(
        foodPlanMutator(
          resourceRequest<ResourceResponse<FoodPlanVersion>>({
            method: "post",
            url: "/food_plan_versions/",
            include: ["food_plan"],
            data: {
              data: {
                type: "food_plan_version",
                attributes: pick(foodPlanVersion.attributes, [
                  "meal_frequency",
                  "food_plan_type",
                  "preferences",
                  "dietary_restrictions",
                  "layout",
                  "include_ingredients",
                  "daily_nutrients",
                  "daily_calories",
                ]),
                relationships: {
                  food_plan: {
                    data: {
                      type: "food_plan",
                      id: foodPlan.id,
                    },
                  },
                },
              },
            },
          })
        ),
        {
          revalidate: false,
          throwOnError: true,
        }
      )
    } catch (error) {
      handleApiError(error)
    } finally {
      setIsRestarting(false)
    }
  })

  return {
    feed,
    foodPlan,
    foodPlanVersion,
    isPatching,
    isStartingGenerate,
    isRestarting,
    isStartingCancel,
    legacyPatient,
    onPatch,
    onRestart,
    onStartGenerate,
    onStartCancel,
  }
}

export type UsePatientFoodPlanResources = ReturnType<
  typeof usePatientFoodPlanResources
>
