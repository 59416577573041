import * as React from "react"

import HeroBackground from "app/assets/images/rupa-blood-dashboard/patient-portal-background.svg"
import { colors } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"

const useStyles = makeAppStyles((theme) => ({
  background: {
    width: "100%",
    background: `url(${HeroBackground})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    backgroundColor: colors.trueGray[100],
    backgroundPosition: "100% 0%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      backgroundSize: "auto",
    },
  },
}))

export default function PatientPortalBloodDashboardBackground({
  children,
}: {
  children?: React.ReactNode
}) {
  const classes = useStyles()
  return <div className={classes.background}>{children}</div>
}
