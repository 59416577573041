import { useCallback, useEffect, useMemo, useState } from "react"

import { UseFormReturn } from "react-hook-form"

import { faTrashCan } from "@fortawesome/free-regular-svg-icons"
import {
  faArrowDown,
  faArrowUp,
  faMagnifyingGlass,
} from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { CircularProgress, InputAdornment } from "@material-ui/core"
import { Button, cn } from "@rupahealth/design"

import BodyText from "app/components/design-system/BodyText"
import TextField from "app/components/forms/TextFieldComponent"
import {
  ControlledAutoCompleteField,
  ControlledSelectField,
  ControlledTextField,
  FieldLabel,
} from "app/main/patient-checkout/fields"
import { BIOMARKER_RESULT_UNITS } from "app/main/results-upload/constants"
import useInputFormStyles from "app/main/results-upload/hooks/use-input-form-styles"
import useCachedResource from "app/swr/hooks/use-cached-resource"
import { LabTestType } from "app/types"
import { Biomarker } from "types/biomarker"

import BiomarkerSearchPopover from "./BiomarkerSearchPopover"
import { UserBiomarkerResultFormData, UserResultFormData } from "./form-helpers"

interface Props {
  field: UserBiomarkerResultFormData
  index: number
  methods: UseFormReturn<UserResultFormData>
  onDelete?: () => void
  isDeleteLoading?: boolean
  existingBiomarkerIds: string[]
  availableLabTestTypes: LabTestType[]
  currentUnit?: string
  isReadOnly?: boolean
}

export const UserResultFormBiomarkerSection = ({
  field,
  index,
  onDelete,
  existingBiomarkerIds,
  isDeleteLoading = true,
  availableLabTestTypes,
  currentUnit,
  methods,
  isReadOnly = false,
}: Props) => {
  const classes = useInputFormStyles()

  const [showBiomarkerSearch, setShowBiomarkerSearch] = useState(false)

  const biomarker = useCachedResource<Biomarker>({
    type: "biomarker",
    id: field.biomarker_id,
  })

  const errors = useMemo(
    () => methods.formState.errors.results?.[index],
    [methods.formState.errors.results?.[index]]
  )

  const userResultSampleTypeId = methods.watch("sample_type_id")
  const watchSampleTypeId = methods.watch(`results.${index}.sample_type_id`)
  const watchValue = methods.watch(`results.${index}.value`)
  const watchLowRange = methods.watch(`results.${index}.normal_min`)
  const watchHighRange = methods.watch(`results.${index}.normal_max`)

  // Set the sample type to the default sample type
  useEffect(() => {
    if (!userResultSampleTypeId)
      if (!watchSampleTypeId) {
        methods.setValue(
          `results.${index}.sample_type_id`,
          userResultSampleTypeId,
          { shouldValidate: true }
        )
      }
  }, [userResultSampleTypeId])

  const handleBiomarkerSelect = async (biomarker: Biomarker) => {
    methods.setValue(`results.${index}.biomarker_id`, biomarker.id)
  }

  const highOrLowText = useMemo(() => {
    if (watchLowRange && watchValue) {
      const min = parseFloat(watchLowRange)
      const numValue = parseFloat(watchValue)

      if (!isNaN(min) && !isNaN(numValue) && min > numValue) {
        return "LOW"
      }
    }

    if (watchValue && watchHighRange) {
      const max = parseFloat(watchHighRange)
      const numValue = parseFloat(watchValue)

      if (!isNaN(max) && !isNaN(numValue) && numValue > max) {
        return "HIGH"
      }
    }

    return null
  }, [watchValue, watchLowRange, watchHighRange])

  // Custom filter so that µ shows when user types "u"
  const filterOptions = (options, { inputValue }) => {
    return options.filter((option) =>
      option
        ?.toLowerCase()
        .replace(/µ/g, "u")
        .includes(inputValue.toLowerCase().replace(/µ/g, "u"))
    )
  }

  const biomarkerName =
    biomarker?.attributes.long_name === biomarker?.attributes.short_name
      ? biomarker?.attributes.long_name
      : `${biomarker?.attributes.long_name} (${biomarker?.attributes.short_name})`

  // Temporary solution disables enter key on form fields because
  // enter key triggers the delete button
  const onKeyDown = useCallback((e: React.KeyboardEvent) => {
    if (e.key === "Enter") e.preventDefault()
  }, [])

  return (
    <div className="w-full flex items-center rounded-md border border-slate-200">
      <div className="w-full border-slate-200 py-3 px-4 flex flex-col gap-[2px]">
        <div className="flex gap-[14px] items-start flex-col lg:flex-row">
          {isReadOnly ? (
            <div className="w-full">
              <FieldLabel className="font-normal" label="Biomarker" />
              <div className="font-semibold md-5-">{biomarkerName}</div>
            </div>
          ) : (
            <div className="flex flex-col w-full lg:min-w-[300px] lg:w-[300px]">
              <FieldLabel
                className="text-sm font-normal"
                label="Biomarker"
                required
              />
              {showBiomarkerSearch ? (
                <BiomarkerSearchPopover
                  existingBiomarkerIds={existingBiomarkerIds}
                  onBiomarkerSelect={handleBiomarkerSelect}
                  widthOfToggleContent={300}
                  isFromBiomarkerForm
                  actionText="Update"
                  toggleSearch={setShowBiomarkerSearch}
                />
              ) : (
                <TextField
                  value={biomarkerName}
                  className={cn(
                    classes.customTextInput,
                    classes.customTextInputBold
                  )}
                  required
                  onClick={(e) => {
                    e.preventDefault()
                    setShowBiomarkerSearch(true)
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <FontAwesomeIcon
                          className="text-primary text-sm"
                          icon={faMagnifyingGlass}
                        />
                      </InputAdornment>
                    ),
                    onKeyDown: (e) => {
                      if (e.key === "Enter") {
                        e.preventDefault()
                        setShowBiomarkerSearch(true)
                      }
                    },
                  }}
                />
              )}

              <BodyText
                size="xs"
                className={cn("text-destructive", {
                  invisible: !errors?.biomarker_id?.message,
                })}
              >
                {errors?.biomarker_id?.message ?? "filler"}
              </BodyText>
            </div>
          )}

          {isReadOnly ? (
            <div className="w-full">
              <FieldLabel className="font-normal" label="Units" />
              <div className="font-semibold">{field.units}</div>
            </div>
          ) : (
            <div className="flex flex-col gap-1 items-start w-full lg:max-w-[120px]">
              <ControlledAutoCompleteField
                name={`results.${index}.units`}
                className="w-full"
                label={<BodyText size="sm">Units</BodyText>}
                labelClassName="text-sm font-normal"
                getOptionLabel={(option) => option}
                customFilterOptions={filterOptions}
                options={[
                  ...BIOMARKER_RESULT_UNITS,
                  currentUnit || field.units,
                ]}
                inputClassName={classes.customInput}
                showErrors={false}
                freeSolo
                includeInputTextInOptions
                dropdownOnlyWhileTyping
                InputProps={{ onKeyDown }}
              />
            </div>
          )}

          {isReadOnly ? (
            <div className="w-full">
              <FieldLabel className="font-normal" label="Sample Type" />
              <div className="font-semibold">
                {availableLabTestTypes.find(
                  (labTestType) => labTestType.id === field.sample_type_id
                )?.name ?? "None"}
              </div>
            </div>
          ) : (
            <ControlledSelectField
              name={`results.${index}.sample_type_id`}
              label="Sample Type"
              className="w-full"
              labelClassName="text-sm font-normal"
              required
              onKeyDown={onKeyDown}
            >
              {availableLabTestTypes.map((labTestType) => (
                <option key={labTestType.id} value={labTestType.id}>
                  {labTestType.name}
                </option>
              ))}
            </ControlledSelectField>
          )}
        </div>

        <div className="flex flex-col lg:flex-row gap-[14px] items-start">
          {isReadOnly ? (
            <div className="w-full mt-4">
              <FieldLabel className="font-normal" label="Value" />
              <div className="font-semibold flex items-center">
                {watchValue}
                {highOrLowText && (
                  <div className="ml-2 py-[1px] px-2 bg-red-100 rounded-full">
                    <BodyText size="xxs" weight="bold" className="text-red-500">
                      {highOrLowText}
                    </BodyText>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <ControlledTextField
              name={`results.${index}.value`}
              label="Value"
              className="w-full"
              labelClassName="text-sm font-normal"
              required
              InputProps={{
                onKeyDown,
                endAdornment: Boolean(highOrLowText) && (
                  <InputAdornment position="end">
                    <div className="py-[1px] px-2 bg-red-100 rounded-full">
                      <BodyText
                        size="xxs"
                        weight="bold"
                        className="text-red-500"
                      >
                        {highOrLowText}
                      </BodyText>
                    </div>
                  </InputAdornment>
                ),
              }}
            />
          )}

          {isReadOnly ? (
            <div className="w-full mt-4">
              <FieldLabel
                className="font-normal"
                label="Standard Range Low"
                beforeLabelIcon={<FontAwesomeIcon icon={faArrowDown} />}
              />
              <div className="font-semibold">{watchLowRange}</div>
            </div>
          ) : (
            <ControlledTextField
              name={`results.${index}.normal_min`}
              label="Standard Range Low"
              beforeLabelIcon={<FontAwesomeIcon icon={faArrowDown} />}
              className="w-full"
              labelClassName="text-sm font-normal"
              placeholder="None"
              InputProps={{ onKeyDown }}
            />
          )}

          {isReadOnly ? (
            <div className="w-full mt-4">
              <FieldLabel
                className="font-normal"
                label="Standard Range High"
                beforeLabelIcon={<FontAwesomeIcon icon={faArrowUp} />}
              />
              <div className="font-semibold">{watchHighRange}</div>
            </div>
          ) : (
            <ControlledTextField
              name={`results.${index}.normal_max`}
              label="Standard Range High"
              beforeLabelIcon={<FontAwesomeIcon icon={faArrowUp} />}
              className="w-full"
              labelClassName="text-sm font-normal"
              placeholder="None"
              InputProps={{ onKeyDown }}
            />
          )}
        </div>
      </div>
      {!isReadOnly && (
        <div className=" border-slate-200 border-l bg-slate-50 px-[15px] self-stretch flex items-center flex-shrink ">
          <Button
            variant="ghost"
            className="p-0"
            onClick={onDelete}
            disabled={isDeleteLoading}
          >
            {isDeleteLoading ? (
              <CircularProgress size={14} />
            ) : (
              <FontAwesomeIcon
                className="text-destructive text-base"
                icon={faTrashCan}
              />
            )}
          </Button>
        </div>
      )}
    </div>
  )
}
