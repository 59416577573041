import { isFunction } from "lodash"

import { OnChangeFn, PaginationState } from "@tanstack/react-table"

import useQueryState from "app/hooks/use-query-state"

/**
 * Hook used to encapsulate the query state for the dashboard, which includes global filters, column filters, and pagination.
 *
 * @returns the query states and functions to update the query states
 */
export default function useOptimalRangesQueryState() {
  const [queryState, setQueryState] = useQueryState<
    {
      pagination: PaginationState
    },
    {
      page?: string
    }
  >(
    (query) => {
      const pagination: PaginationState = {
        pageIndex: 0,
        pageSize: 25,
      }
      if (query.page) {
        pagination.pageIndex = Number(query.page)
      }

      return {
        pagination,
      }
    },
    (state) => ({
      page:
        state.pagination.pageIndex > 0
          ? state.pagination.pageIndex.toString()
          : undefined,
    })
  )

  const onPaginationChange: OnChangeFn<PaginationState> = (updater) => {
    setQueryState((state) => {
      const nextPagination = isFunction(updater)
        ? updater(state.pagination)
        : updater
      return {
        ...state,
        pagination: nextPagination,
      }
    })
  }

  return {
    ...queryState,
    onPaginationChange,
  }
}
