import { useMemo } from "react"

import { differenceInYears } from "date-fns"
import { useController, useFormContext, useWatch } from "react-hook-form"
import { useDebounce } from "react-use"

import { faUserCheck } from "@fortawesome/free-solid-svg-icons"
import { Divider, Grid, Typography } from "@material-ui/core"

import Tooltip from "app/components/Tooltip"
import BodyText from "app/components/design-system/BodyText"
import { normalizeDate } from "app/components/forms/normalization"

import PatientCheckoutBanner from "../PatientCheckoutBanner"
import {
  ControlledDateField,
  ControlledSelectField,
  ControlledTextField,
  FieldNames,
} from "../fields"

export function PersonalInfo({
  className,
  subtitle,
  title,
  isStorefrontOrder,
  order,
  updateOrder,
}) {
  const { control } = useFormContext()

  const disableEmail = !order.patient.user.can_modify_email
  const disableName = !order.patient.can_modify_name

  // For storefront orders, the first and last name fields will be empty, so
  // there's no need for the ClickToEdit fields.
  const areNameFieldsClickToEdit = !isStorefrontOrder && !disableEmail

  const {
    field: { value: birthday },
  } = useController({
    control,
    name: "birthday",
    defaultValue: null,
  })

  const isUnder18 = useMemo(() => {
    if (!birthday) {
      return false
    }

    return differenceInYears(new Date(), birthday) < 18
  }, [birthday])

  const watchBirthday = useWatch({
    control,
    name: FieldNames.BIRTHDAY,
  })

  useDebounce(
    () => {
      if (!watchBirthday || !order) return

      const birthday = normalizeDate(watchBirthday)

      if (birthday === order.patient.birthday) return

      updateOrder({ birthday })
    },
    300,
    [watchBirthday, Boolean(order)]
  )

  return (
    <div className={className} aria-label="Patient's Information">
      <Typography variant="h4" color="textPrimary" style={{ fontSize: 24 }}>
        {title}
      </Typography>
      <PatientCheckoutBanner
        className="mt-3 mb-6"
        type="info"
        text={subtitle}
        icon={faUserCheck}
      />

      <div className="mt-3">
        <Grid container spacing={2}>
          <Grid container item spacing={2}>
            <Grid item xs={12} sm={6}>
              <Tooltip
                arrow
                interactive
                placement="bottom"
                disableHoverListener={!disableName}
                disableFocusListener={!disableName}
                disableTouchListener={!disableName}
                title={
                  <div>
                    Please{" "}
                    <a href="mailto:support@rupahealth.com">
                      reach out to support
                    </a>{" "}
                    if you need to modify this name.
                  </div>
                }
              >
                <div>
                  <ControlledTextField
                    clickToEdit={areNameFieldsClickToEdit}
                    label="Preferred First Name"
                    name={FieldNames.FIRST_NAME}
                    required
                    disabled={disableName}
                  />
                </div>
              </Tooltip>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Tooltip
                arrow
                interactive
                placement="bottom"
                disableHoverListener={!disableName}
                disableFocusListener={!disableName}
                disableTouchListener={!disableName}
                title={
                  <div>
                    Please{" "}
                    <a href="mailto:support@rupahealth.com">
                      reach out to support
                    </a>{" "}
                    if you need to modify this name.
                  </div>
                }
              >
                <div>
                  <ControlledTextField
                    clickToEdit={areNameFieldsClickToEdit}
                    label="Last Name"
                    name={FieldNames.LAST_NAME}
                    required
                    disabled={disableName}
                  />
                </div>
              </Tooltip>
            </Grid>
          </Grid>

          <Grid container item spacing={2}>
            <Grid item xs={12} sm={6}>
              <ControlledSelectField
                label="Sex assigned at birth"
                name={FieldNames.BIOLOGICAL_SEX}
                required
              >
                <option value="F">Female</option>
                <option value="M">Male</option>
              </ControlledSelectField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <ControlledDateField
                label="Birthday"
                name={FieldNames.BIRTHDAY}
                required
              />
            </Grid>
          </Grid>

          {isUnder18 && (
            <>
              <Grid container item spacing={2}>
                <Grid item xs={12}>
                  <Divider className="mb-6 mt-3" />
                  <BodyText size="sm" weight="semibold">
                    Please enter the information of a parent or legal guardian
                    over the age of 18.
                  </BodyText>
                </Grid>
              </Grid>
              <Grid container item spacing={2}>
                <Grid item xs={12} sm={6}>
                  <ControlledTextField
                    label="Parent/Guardian's First Name"
                    name={FieldNames.GUARDIAN_FIRST_NAME}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <ControlledTextField
                    label="Parent/Guardian's Last Name"
                    name={FieldNames.GUARDIAN_LAST_NAME}
                    required
                  />
                </Grid>
              </Grid>
            </>
          )}

          <Grid container item spacing={2}>
            <Grid item xs={12} sm={6}>
              <ControlledTextField
                label="Phone Number"
                name={FieldNames.PHONE_NUMBER}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Tooltip
                arrow
                interactive
                placement="bottom"
                disableHoverListener={!disableEmail}
                disableFocusListener={!disableEmail}
                disableTouchListener={!disableEmail}
                title={
                  <div>
                    Please{" "}
                    <a href="mailto:support@rupahealth.com">
                      reach out to support
                    </a>{" "}
                    if you need to modify this email.
                  </div>
                }
              >
                <div>
                  <ControlledTextField
                    label="Email address"
                    name={FieldNames.EMAIL}
                    required
                    disabled={disableEmail}
                  />
                </div>
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  )
}
