import React, { useCallback, useEffect, useRef, useState } from "react"

import SplitPane from "react-split-pane"

import { debounce, Theme, useMediaQuery } from "@material-ui/core"
import { cn } from "@rupahealth/design"

import Grabber from "app/assets/images/results-over-time/grabber.svg"
import ResultUploadPdfViewer from "app/main/results-upload/modals/components/ResultUploadPdfViewer"
import { colors } from "app/theme"

import {
  UserResultForm,
  UserResultFormProps,
} from "./user-result-form/UserResultForm"

/**
 * Component that wraps the UserResultForm and handles responsive layout &
 * layout based on whether there is a File or not
 */
interface Props extends UserResultFormProps {
  pdfFileUrl: string | undefined
}

export const UserResultFormContainer: React.FC<Props> = ({
  pdfFileUrl,
  ...formProps
}) => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"))

  if (isMobile && pdfFileUrl) {
    return <MobileStackedEntryView {...formProps} pdfFileUrl={pdfFileUrl} />
  }

  return (
    <div className="flex gap-[18px] overflow-hidden transition-opacity duration-500">
      <div
        className={cn(
          "rounded-md overflow-y-auto",
          pdfFileUrl ? "w-1/2" : "w-full"
        )}
      >
        <UserResultForm {...formProps} />
      </div>
      <div className={cn("w-1/2 bg-white rounded-md", { hidden: !pdfFileUrl })}>
        <ResultUploadPdfViewer pdfFileUrl={pdfFileUrl} />
      </div>
    </div>
  )
}

interface MobileStackedEntryProps extends Props {
  pdfFileUrl: string
}

const MobileStackedEntryView: React.FC<MobileStackedEntryProps> = ({
  pdfFileUrl,
  ...formProps
}) => {
  const [staticHeight, setStaticHeight] = useState(window.innerHeight - 100)
  const [dynamicPdfContainerHeight, setDynamicPdfContainerHeight] = useState(
    window.innerHeight - 400
  )

  const windowHeight = window.innerHeight

  const pdfContainerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    function handleResize() {
      setStaticHeight(window.innerHeight - 100)
    }

    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)
  }, [])

  const handleSizeChange = useCallback(
    debounce((size) => {
      const pdfContainerHeight = windowHeight - size
      setDynamicPdfContainerHeight(pdfContainerHeight)
    }, 300),
    [windowHeight]
  )

  return (
    <div className="relative w-full" style={{ height: staticHeight }}>
      <SplitPane
        split="horizontal"
        minSize={100}
        maxSize={staticHeight - 100}
        defaultSize={400}
        resizerStyle={{
          background: colors.blueGray[300],
          backgroundImage: `url(${Grabber})`,
          backgroundPosition: "center",
          height: 11,
        }}
        onChange={handleSizeChange}
      >
        <div className="w-full overflow-y-scroll pb-5">
          <UserResultForm {...formProps} />
        </div>

        <div className="w-full" ref={pdfContainerRef}>
          <div className="relative mt-[10px]">
            <div
              className="absolute w-full overflow-y-scroll"
              style={{ height: dynamicPdfContainerHeight }}
            >
              <ResultUploadPdfViewer pdfFileUrl={pdfFileUrl} />
            </div>
          </div>
        </div>
      </SplitPane>
    </div>
  )
}
