import { KIT_STATUS, ORDER_PAYER } from "app/constants.typed"
import { getRequisitionLabCompanyName } from "app/utils/order-status-utils"

import { StatusConfiguration } from "../../types"
import { StatusConfigurationProps } from "../../types/dashboard-types"
import { PROGRESS_PERCENTAGES } from "../consts"

const progressPercentage = ({ order }: StatusConfigurationProps) => {
  if (
    order.attributes.order_payer === ORDER_PAYER.PRACTITIONER &&
    !order.attributes.date_patient_checkout
  ) {
    return PROGRESS_PERCENTAGES.AWAITING_PATIENT_CHECKOUT
  }
  return PROGRESS_PERCENTAGES.PAID
}

const Title = ({
  order,
  labCompany,
  kit,
  requisitionAvailable,
}: StatusConfigurationProps) => {
  if (
    order.attributes.order_payer !== ORDER_PAYER.PATIENT &&
    !order.attributes.date_patient_checkout
  ) {
    return "Patient Consent Form Pending"
  }

  switch (kit.attributes.sample_collection_coordinator) {
    case "LAB":
      if (requisitionAvailable) {
        return "Requisition is Ready"
      } else {
        const name = getRequisitionLabCompanyName(labCompany?.attributes.key)
        return `Awaiting requisition from ${name}`
      }
    default:
      if (order.attributes.order_payer !== ORDER_PAYER.PATIENT) {
        return "Patient Consent Form Complete"
      }
      return "Invoice Paid"
  }
}
const SubTitle = ({ labCompany, kit }: StatusConfigurationProps) => {
  // Leaving comments for PROD-2070
  // switch(kit.attributes.sample_collection_coordinator) {
  //   case "LAB":
  //     if (requisitionAvailable) {
  //       // patient can go to lab any time.  do we know if they have yet?
  //     } else {
  //       // patient shouldn't go to lab location yet.
  //     }
  //   case "CLINIC":
  //     if (requisitionAvailable) {
  //       // patient can come to the clinic any time. then what? do we know if they have yet?
  //     } else {
  //       // should never happen
  //     }
  return null
}

const paidStatusConfiguration: StatusConfiguration<StatusConfigurationProps> = {
  when: { kitStatus: KIT_STATUS.PAID },
  progressPercentage: progressPercentage,
  title: Title,
  subTitle: SubTitle,
}

export default paidStatusConfiguration
