import { makeStyles, Divider } from "@material-ui/core"

import LabcorpAlreadyPaid from "app/assets/images/patient-instructions/labcorp-already-paid-instruction.png"
import LabcorpInsurancePay from "app/assets/images/patient-instructions/labcorp-insurance-pay-option.png"
import BodyText from "app/components/design-system/BodyText"
import { colors, primaryColor } from "app/theme"
import { LabCompanySchedulingData } from "app/types"

const useStyles = makeStyles((theme) => ({
  schedulingDetails: {
    padding: 23,
    backgroundColor: colors.blue[50],
    border: `1px solid ${primaryColor}`,
    borderRadius: 8,
    display: "flex",
    flexDirection: "column",
    gap: 5,
  },
  schedulingDivider: {
    marginTop: 5,
    marginBottom: 20,
  },
  alreadyPaidText: {
    paddingTop: 15,
  },
  alreadyPaidImageContainer: {
    backgroundColor: "white",
    padding: 5,
    borderRadius: 8,
    marginTop: 5,
    marginBottom: 15,
  },
  alreadyPaidImage: {
    [theme.breakpoints.up("sm")]: {
      maxWidth: 400,
    },
  },
  introText: {
    paddingBottom: 25,
  },
  headerDivider: {
    marginTop: 30,
    marginBottom: 30,
  },
  headerText: {
    paddingBottom: 20,
  },
  link: {
    color: primaryColor,
    fontWeight: 600,
  },
}))

function FastingDetails() {
  return (
    <>
      <BodyText weight="semibold" size="sm">
        🥗 Choose Yes for Fasting.
      </BodyText>
      <BodyText size="sm">
        Your test requires fasting for 12 hours prior to your blood draw.
      </BodyText>
    </>
  )
}

function CashPayDetails() {
  const classes = useStyles()
  return (
    <>
      <BodyText className={classes.alreadyPaidText} weight="semibold" size="sm">
        💳 Select I have already paid.
      </BodyText>
      <BodyText size="sm">
        When you schedule your blood draw, Labcorp may ask for billing details.
        Just select this option:
      </BodyText>
      <div className={classes.alreadyPaidImageContainer}>
        <img
          className={classes.alreadyPaidImage}
          src={LabcorpAlreadyPaid}
          alt="Labcorp already paid"
        />
      </div>
    </>
  )
}

function InsurancePayDetails() {
  const classes = useStyles()
  return (
    <>
      <BodyText weight="semibold" size="sm">
        💳 Select I have already paid.
      </BodyText>
      <BodyText size="sm">
        When you schedule your blood draw, Labcorp may ask for billing details.
        Just select this option:
      </BodyText>
      <div className={classes.alreadyPaidImageContainer}>
        <img
          className={classes.alreadyPaidImage}
          src={LabcorpInsurancePay}
          alt="Labcorp use insurance"
        />
      </div>
    </>
  )
}

export function ScheduleLabcorpDetails({
  requiresFasting,
  data,
  showHeader,
}: {
  requiresFasting: Boolean
  data: LabCompanySchedulingData
  showHeader: Boolean
}) {
  const classes = useStyles()
  return (
    <>
      {showHeader && (
        <>
          <Divider className={classes.headerDivider} />
          <BodyText weight="semibold" size="lg" className={classes.headerText}>
            Blood Draw at Labcorp
          </BodyText>
        </>
      )}
      <BodyText size="sm" className={classes.introText}>
        You'll need to go to a Labcorp location to get your blood drawn. You can
        walk in to a local Labcorp clinic or schedule an appointment
        {data.should_show_schedule_link ? (
          <>
            {" "}
            <a
              target="_blank"
              rel="noreferrer"
              href={data.scheduling_url}
              className={classes.link}
            >
              here
            </a>
          </>
        ) : null}
        .
      </BodyText>
      <div className={classes.schedulingDetails}>
        <BodyText weight="semibold" size="md">
          Key details for scheduling your blood draw:
        </BodyText>
        <Divider className={classes.schedulingDivider} />
        {requiresFasting && <FastingDetails />}
        {data.uses_insurance ? <InsurancePayDetails /> : <CashPayDetails />}
        <BodyText weight="semibold" size="sm">
          Remember to bring your ID
          {data.uses_insurance ? ", insurance card," : null} and have your{" "}
          {data.requisition_form_url ? (
            <u>
              <a
                target="_blank"
                rel="noreferrer"
                href={data.requisition_form_url}
              >
                digital requisition
              </a>
            </u>
          ) : (
            "digital requisition"
          )}{" "}
          ready to show at the time of your appointment.
        </BodyText>
      </div>
    </>
  )
}
