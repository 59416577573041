import { useCallback, useEffect, useMemo, useState } from "react"

import { useHistory } from "react-router-dom"

import { BiomarkerIdentifier } from "types/biomarker"
import { BiomarkerGroupingIdentifier } from "types/biomarker_grouping"

export interface UseDropdownSelectProps {
  items: (BiomarkerIdentifier | BiomarkerGroupingIdentifier)[]
  loading: boolean
  onItemSelect?: (
    item: BiomarkerIdentifier | BiomarkerGroupingIdentifier
  ) => void
  search?: string
  setSearch?: (search: string) => void
}

export default function useDropdownSelect({
  items,
  loading,
  onItemSelect,
  search,
  setSearch,
}: UseDropdownSelectProps) {
  const history = useHistory()
  const [open, setOpen] = useState(false)
  const [cursor, setCursor] = useState<number>(0)

  // ensures the dropdown is open while the user is searching
  useEffect(() => {
    if (search) setOpen(true)
  }, [search])

  const highlightedItem = useMemo(() => {
    if (cursor >= items.length) {
      return undefined
    }

    // return the item at the index
    return items[cursor]
  }, [cursor, items])

  const selectItem = useCallback(
    (item: BiomarkerIdentifier | BiomarkerGroupingIdentifier) => {
      setOpen(false)

      if (onItemSelect) {
        onItemSelect(item)
        setSearch?.("")
      }
    },
    [history.push, onItemSelect]
  )

  const moveCursorUp = useCallback(() => {
    if (loading) {
      // skip while we are waiting for results
      return
    }

    if (!open) {
      setOpen(!!search)
    } else {
      setCursor((prevIndex) => {
        if (prevIndex === 0) {
          return items.length - 1 || 0
        }
        return prevIndex - 1
      })
    }
  }, [loading, open, search, items.length])

  const moveCursorDown = useCallback(() => {
    if (loading) {
      // skip while we are waiting for results
      return
    }

    if (!open) {
      setOpen(!!search)
    } else {
      setCursor((prevIndex) => {
        if (!items.length || prevIndex === items.length - 1) {
          return 0
        }
        return prevIndex + 1
      })
    }
  }, [loading, open, search, items.length])

  const moveCursorToItem = useCallback(
    (item: BiomarkerIdentifier | BiomarkerGroupingIdentifier) => {
      const itemIndex = items.indexOf(item)
      setCursor(itemIndex)
    },
    [items]
  )

  return {
    highlightedItem,
    moveCursorDown,
    moveCursorToItem,
    moveCursorUp,
    open,
    search,
    selectItem,
    setOpen,
    setCursor,
  }
}
