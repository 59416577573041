import { Dispatch, useMemo } from "react"

/**
 * Helper hook used to bind a React Dispatch function to a map of action creators.
 *
 * @param dispatch the dispatch function
 * @param actions the actions to bind
 * @returns the bound actions
 */
export default function useBindActionCreators<
  D extends Dispatch<any>,
  Actions extends { [actionName: string]: (...args: any[]) => any }
>(dispatch: D, actions: Actions): Actions {
  return useMemo(() => {
    function bindActionCreator(
      actionCreator: (...args: any[]) => any,
      dispatcher: typeof dispatch
    ) {
      return function (this: any) {
        return dispatcher(
          actionCreator.apply(this as any, arguments as unknown as any[])
        )
      }
    }

    const newActions = Object.keys(actions).reduce((ba, actionName) => {
      ba[actionName] = bindActionCreator(actions[actionName], dispatch)
      return ba
    }, {} as { [key: string]: (...args: any[]) => any })
    return newActions as Actions
  }, [dispatch])
}
