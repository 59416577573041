import { useCallback, useState } from "react"

import { DeepPartial } from "global"
import { useSWRConfig } from "swr"

import useHandleApiError from "app/hooks/use-handle-api-error"

import { writeToCache } from "../helpers/swr"
import useMutateResource from "../hooks/use-mutate-resource"
import { Resource, ResourceResponse } from "../types"
import resourceRequest from "../utils/resource-request"

/**
 * Utility hook for creating a resource.
 * - Manages cache updates
 * - Includes default error handling
 * - Provides loading state
 */

interface CreateResorceProps<R extends Resource> {
  // In the future, we could infer the url from the resource type or vice versa
  url: string

  // Passed to the post request
  include?: string[]

  // Fires when creation is successful
  onSuccess?: (resource: R) => void
  // Replaces the default error toast
  onError?: (error: any) => void
  // Fires whether creation is successful or errors
  onSettled?: () => void
}

export function useCreateResource<R extends Resource, D = DeepPartial<R>>({
  url,
  include,
  onSuccess,
  onError,
  onSettled,
}: CreateResorceProps<R>) {
  const handleApiError = useHandleApiError()
  const mutateResource = useMutateResource()
  const globalConfig = useSWRConfig()
  const [isCreating, setIsCreating] = useState(false)

  const postRequest = useCallback(
    async (requestData: D) =>
      resourceRequest<ResourceResponse<R>>({
        method: "post",
        url,
        data: { data: requestData },
        include,
      }),
    [url, include]
  )

  const createResource = useCallback(
    async (requestData: D) => {
      try {
        setIsCreating(true)

        const { data, included } = await postRequest(requestData)
        await writeToCache(globalConfig, data, ...(included ?? []))

        onSuccess?.(data)
      } catch (error) {
        if (onError) {
          onError(error)
        } else {
          handleApiError(error)
        }
      } finally {
        setIsCreating(false)
        onSettled?.()
      }
    },
    [postRequest, mutateResource, onSuccess, onError, handleApiError, onSettled]
  )
  return { createResource, isCreating }
}
