import * as Sentry from "@sentry/react"

import {
  LAB_COMPANY_KEY,
  LABCORP_COMPANY_KEYS,
  QUEST_COMPANY_KEYS,
} from "app/constants"

export const getRequisitionLabCompanyName = (
  labCompanyKey: string | undefined
) => {
  const _captureError = () => {
    Sentry.captureMessage(
      "Unknown lab company key for RequisitionLabCompanyName",
      {
        level: "error",
        extra: { labCompanyKey: labCompanyKey },
      }
    )
  }

  if (!labCompanyKey) {
    _captureError()
    return "Lab"
  }

  if (LABCORP_COMPANY_KEYS.includes(labCompanyKey)) {
    return "Labcorp"
  } else if (QUEST_COMPANY_KEYS.includes(labCompanyKey)) {
    return "Quest"
  } else if (labCompanyKey === LAB_COMPANY_KEY.EMPIRE_CITY_LABS) {
    return "Empire City Labs"
  }

  _captureError()

  return "Lab"
}
