import { styled } from "@material-ui/core"

import { colors } from "app/theme"

const Separator = styled("hr")({
  height: 1,
  backgroundColor: colors.coolGray[300],
  marginTop: 24,
  marginBottom: 24,
})

export default Separator
