class HellosignConfig {
  clientId: string = ""
  skipDomainVerification: boolean = false
  debug: boolean = false

  constructor(init?: Partial<HellosignConfig>) {
    Object.assign(this, init)
  }
}

export class SettingsData {
  nodeEnv: string = ""
  publicUrl: string = ""
  sentryRelease: string = ""
  environment: string = ""
  nodeEnvironment: string = ""
  prNumber: string = ""
  sentryDsn: string = ""
  segmentKey: string = ""
  facebookAppId: string = ""
  hellosignConfig: HellosignConfig = new HellosignConfig({})
  plaidPublicKey: string = ""
  plaidEnvironment: string = ""
  stripePublishableApiKey: string = ""
  marketingSite: string = ""
  practitionerSignup: string = ""
  refinerProject: string = ""
  splitItUrl: string = ""
  splitItNumInstallments: number = 0
  mapboxToken: string = ""
  intercomAppId: string = ""
  intercomRequestLabTestSurveyId: number = 0
  intercomBloodDashboardsFeedbackId: number = 0
  intercomResultsInterpretationFeedbackId: number = 0
  intercomFoodPLansFeedbackId: number = 0
  intercomIokInventoryFeednackId: number = 0
  intercomAddBiomarkerSurveyId: number = 0
  practiceBetterUrl: string = ""
  bodysiteUrl: string = ""
  optimalDxUrl: string = ""
  practiceQUrl: string = ""
  kalixUrl: string = ""
  healthieUrl: string = ""
  myHolisticOfficeUrl: string = ""
  cerboUrl: string = ""
  calendlyGroupDemoUrl: string = ""
  onboardingDemoVideoUrl: string = ""
  localExposedBackendUrl: string = ""
  generated: string | null = null

  constructor(init: SettingsData) {
    Object.assign(this, init)
  }

  isLoaded() {
    return !!this.generated
  }

  isDebug() {
    return this.environment === "Dev"
  }

  isPR() {
    return this.environment === "PR"
  }

  isStaging() {
    return this.environment === "Staging"
  }

  isSandbox() {
    return this.environment === "Sandbox"
  }

  isProduction() {
    return this.environment === "Production"
  }
}
