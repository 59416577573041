import { PDFDocument, rgb, StandardFonts } from "pdf-lib"

import rupaHealthLogo from "app/assets/images/logos/rupa-health-logo.png"

import { wrapText } from "./helpers"

/**
 * Blood lab dashboard footer generator for PDF documents.
 *
 * Adds a separator line, page numbers, the Rupa logo, and dynamic footer content to the footer of each page.
 *
 * @param pdfDoc The PDF document to add the footer to.
 * @param descriptionsByText The text to display for the descriptions by line in the footer.
 * @param rangesByText The text to display for the optimal ranges by line in the footer.
 */
const bloodLabDashboardFooterGenerator = async (
  pdfDoc: PDFDocument,
  descriptionsByText: string,
  rangesByText: string
): Promise<void> => {
  const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica)
  const footerTextStartingYPosition = 60
  const footerTextColor = rgb(0.392, 0.455, 0.545) // #64748B

  // Prepare Rupa logo
  const response = await fetch(rupaHealthLogo)
  const logoBlob = await response.blob()
  const logoBytes = await logoBlob.arrayBuffer()
  const pngImage = await pdfDoc.embedPng(logoBytes)
  const pngDims = pngImage.scale(0.1)

  // Prepare footer content
  const rangesBySentence = rangesByText
    ? `Optimal Ranges by ${rangesByText}. `
    : ""
  const dynamicFooterContent =
    `Descriptions by ${descriptionsByText}. ${rangesBySentence}These descriptions and ${
      rangesByText ? "optimal ranges " : ""
    }offer insight into overall well-being but are not personalized medical advice. ` +
    `Consult your healthcare provider for any health-related questions, decisions, or concerns.`

  const staticFooterContent1 =
    "This document is intended for informational purposes only and should not be used as a substitute for professional medical advice, diagnosis, or treatment. The original lab report (the “Report”) is"

  const staticFooterContent2 =
    "the official documentation of your results; please always refer back to the Report. Rupa is not liable for any discrepancies between the Report and this visualization."

  const pages = pdfDoc.getPages()
  pages.forEach((page, index) => {
    const { width } = page.getSize()
    const fontSize = 6
    const leftMargin = 40
    const lineHeight = fontSize + 2

    // Add static footer text
    page.drawText(staticFooterContent1, {
      x: leftMargin,
      y: footerTextStartingYPosition,
      size: fontSize,
      font: helveticaFont,
      color: footerTextColor,
    })
    page.drawText(staticFooterContent2, {
      x: leftMargin,
      y: footerTextStartingYPosition - lineHeight,
      size: fontSize,
      font: helveticaFont,
      color: footerTextColor,
    })

    // Add dynamic footer text
    const footerContentLines = wrapText(
      dynamicFooterContent,
      width - leftMargin * 2,
      helveticaFont,
      fontSize
    )
    const currentFooterTextStartingYPosition =
      footerTextStartingYPosition - lineHeight * 2 - lineHeight / 2
    footerContentLines.forEach((line, i) => {
      page.drawText(line, {
        x: leftMargin,
        y: currentFooterTextStartingYPosition - i * lineHeight,
        size: fontSize,
        font: helveticaFont,
        color: footerTextColor,
      })
    })

    // Add page numbers
    page.drawText(`Page ${index + 1} of ${pages.length}`, {
      x: width - 76,
      y: 78,
      size: 7,
      font: helveticaFont,
      color: rgb(0.169, 0.271, 0.373), // #2B455F
    })

    // Add Rupa logo
    page.drawImage(pngImage, {
      x: leftMargin,
      y: 74,
      width: pngDims.width,
      height: pngDims.height,
    })

    // Add separator line
    page.drawRectangle({
      x: leftMargin,
      y: 105,
      width: width - leftMargin * 2,
      height: 1,
      color: rgb(0.886, 0.91, 0.941), // #e2e8f0
      opacity: 1,
    })
  })
}

export default bloodLabDashboardFooterGenerator
