import { CheckoutDraftState } from "app/types"
import { isShallowEqual } from "app/utils"

import * as Actions from "../actions/draft.actions"

export const initialState: CheckoutDraftState = {
  dirty: false,
  error: undefined,
  pending: false,
  values: {},
}

export default function draft(
  state: CheckoutDraftState = initialState,
  action:
    | Actions.CheckoutDraftUpdateAction
    | Actions.CheckoutDraftResetAction
    | Actions.CheckoutDraftRequestPendingAction
    | Actions.CheckoutDraftRequestSuccessAction
    | Actions.CheckoutDraftRequestFailureAction
) {
  switch (action.type) {
    case Actions.CHECKOUT_DRAFT_UPDATE: {
      const nextValues = {
        ...state.values,
        ...action.payload,
      }
      return {
        ...state,
        dirty: state.dirty || !isShallowEqual(state.values, nextValues),
        values: nextValues,
      }
    }
    case Actions.CHECKOUT_DRAFT_RESET: {
      return {
        ...initialState,
        values: action.payload,
      }
    }
    case Actions.CHECKOUT_DRAFT_REQUEST_PENDING: {
      return {
        ...state,
        dirty: false,
        pending: true,
      }
    }
    case Actions.CHECKOUT_DRAFT_REQUEST_SUCCESS: {
      return {
        ...state,
        pending: false,
      }
    }
    case Actions.CHECKOUT_DRAFT_REQUEST_FAILURE: {
      return {
        ...state,
        error: action.payload,
        pending: false,
      }
    }
    default:
      return state
  }
}
