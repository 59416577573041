import * as React from "react"

import { makeStyles, Theme } from "@material-ui/core"

import HowItWorksMail from "app/assets/images/phys-auth/phys-auth-mail.svg"
import HowItWorksResults from "app/assets/images/phys-auth/phys-auth-results.svg"
import HowItWorksSignature from "app/assets/images/phys-auth/phys-auth-signature.svg"
import HowItWorksStar from "app/assets/images/phys-auth/phys-auth-star.svg"
import DesignSystemButton from "app/components/design-system/Button"
import ClipboardContainer from "app/components/modals/PhysicianServicesOptInModal/ClipboardContainer"
import LearnMoreButton from "app/components/modals/PhysicianServicesOptInModal/LearnMoreButton"
import {
  PHYSICIAN_AUTHORIZATION_GUIDE_URL,
  VENDOR_PHYSICIAN_AUTHORIZATION_LABEL,
} from "app/constants"
import { colors, primaryColor } from "app/theme"
import { getOrderPatientLabel, getOrderTypeLabel } from "app/utils/order-utils"

const styles = (theme: Theme) => ({
  continueButton: {
    marginTop: "32px",
    marginBottom: "16px",
    width: 500,
    fontSize: 18,
    fontWeight: 400,
    [theme.breakpoints.down("sm")]: {
      width: "96%",
    },
  },
  howItWorksItem: {
    display: "flex",
    "text-align": "left",
  },
  howItWorksItemImg: {
    margin: "0 auto",
    width: "50%",
    height: "auto",
  },
  howItWorksItemImgContainer: {
    background: colors.blueGray[100],
    width: 60,
    height: 60,
    marginRight: 15,
    borderRadius: "7px",
    flexGrow: 0,
    flexShrink: 0,
    display: "flex",
    justifyContent: "center",
  },
  howItWorksItemText: {
    marginTop: "auto",
    marginBottom: "auto",
  },
  separator: {
    height: 1,
    backgroundColor: colors.coolGray[200],
    marginTop: 15,
    marginBottom: 15,
  },
  goBackText: {
    color: primaryColor,
    cursor: "pointer",
    fontSize: 16,
    fontWeight: 600,
    marginBottom: 32,
    height: 24,
  },
})

const useStyles = makeStyles(styles)

interface ItemProps {
  text: React.ReactNode
  imgSrc: string
}

const HowItWorksItem = ({ text, imgSrc }: ItemProps) => {
  const classes = useStyles(styles)
  return (
    <div>
      <div className={classes.howItWorksItem}>
        <div className={classes.howItWorksItemImgContainer}>
          <img src={imgSrc} className={classes.howItWorksItemImg} alt="" />
        </div>
        <div className={classes.howItWorksItemText}>{text}</div>
      </div>
      <hr className={classes.separator} />
    </div>
  )
}

interface Props {
  onSuccess: () => void
  onGoBack: () => void
}

/**
 * How it works step for physician services opt in modal
 * @param onSuccess - Action to take on step submit
 */
const HowItWorksStep = ({ onSuccess, onGoBack }: Props) => {
  const classes = useStyles(styles)

  const patientLabel = getOrderPatientLabel(true).toLowerCase()
  const orderLabel = getOrderTypeLabel(true).toLowerCase()

  return (
    <div>
      <ClipboardContainer title={"How it works:"}>
        <HowItWorksItem
          text={
            <div>
              You request that a physician orders your {patientLabel}'s testing
              by choosing '{VENDOR_PHYSICIAN_AUTHORIZATION_LABEL}' as the
              signing practitioner when creating a {orderLabel}, or, as the
              signing physician for your LabShop.
            </div>
          }
          imgSrc={HowItWorksStar}
        />
        <HowItWorksItem
          text={`Your client gets an email about their testing and consents to ${VENDOR_PHYSICIAN_AUTHORIZATION_LABEL}.`}
          imgSrc={HowItWorksMail}
        />
        <HowItWorksItem
          text="The signing physician places the order with the lab."
          imgSrc={HowItWorksSignature}
        />
        <HowItWorksItem
          text={`When results are in, the signing physician reviews for any critical values and releases to you and your ${patientLabel} at the same time.`}
          imgSrc={HowItWorksResults}
        />
        <LearnMoreButton url={PHYSICIAN_AUTHORIZATION_GUIDE_URL} />
      </ClipboardContainer>
      <DesignSystemButton
        color="primary"
        onClick={onSuccess}
        className={classes.continueButton}
      >
        Go to Final Step!
      </DesignSystemButton>
      <div className={classes.goBackText} onClick={onGoBack}>
        Go back
      </div>
    </div>
  )
}

export default HowItWorksStep
