import { Link, styled } from "@material-ui/core"

import BodyText from "../design-system/BodyText"
import { GenovaExplanation } from "./useGenovaInsuranceExplanation"

interface Props {
  explanation: GenovaExplanation
}

const Container = styled("div")({
  paddingTop: 24,
})

const List = styled("ul")({
  padding: "10px 16px",
  "& ul": {
    padding: "0 16px",
  },
  marginBottom: 16,
})

const Text = styled(BodyText)({
  marginBottom: 16,
})

const HelpLink = styled(Link)({
  display: "block",
})

function GenovaInsuranceExplanationCheckout({ explanation }: Props) {
  const {
    genovaTests,
    totalPotentialExtraCost,
    totalMaxCost,
    totalInitialInsurancePrice,
  } = explanation

  const isSingleTest = genovaTests.length === 1

  return (
    <Container>
      <Text weight="semibold">
        Your testing from Genova Diagnostics will be run through your insurance.
      </Text>
      {isSingleTest ? (
        <Text>
          The total cost of the test is {totalMaxCost}. We're charging you{" "}
          {totalInitialInsurancePrice} up-front. The remaining balance will be
          determined based on your insurance. Depending on your insurance
          coverage, the extra bill could range from $0-{totalPotentialExtraCost}
          .
        </Text>
      ) : (
        <>
          <Text>
            We're charging you a fraction of the test cost up-front. The
            remaining balance will be determined based on your insurance
            coverage. You may receive a bill for up to {totalPotentialExtraCost}{" "}
            more from Genova.
          </Text>
          <BodyText weight="semibold">
            This is the max amount Genova may bill you for each test:
          </BodyText>
          <List>
            {genovaTests.map(({ name, potentialExtraCost }) => (
              <li>
                <Text variantMapping={{ body1: "span" }} size="sm">
                  {`${name}: `}
                </Text>
                <Text
                  variantMapping={{ body1: "span" }}
                  weight="semibold"
                  size="sm"
                >
                  {`Up to ${potentialExtraCost} more`}
                </Text>
              </li>
            ))}
          </List>
        </>
      )}
      <Text weight="semibold">
        Genova will reach out to you directly if there is any remaining balance.
        This will happen after you mail in your samples.
      </Text>
      <HelpLink
        href="https://support.rupahealth.com/article/1150-how-insurance-works-for-genova-diagnostics"
        target="_blank"
      >
        How Insurance Works for Genova Diagnostics
      </HelpLink>
      <HelpLink
        href="https://support.rupahealth.com/hc/en-us/articles/360049956674"
        target="_blank"
      >
        How to Figure Out What Your Insurance May Cover
      </HelpLink>
    </Container>
  )
}

export default GenovaInsuranceExplanationCheckout
