import { ReactNode, useState } from "react"

import _ from "lodash"

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  withStyles,
} from "@material-ui/core"
import Divider from "@material-ui/core/Divider"
import Fade from "@material-ui/core/Fade"
import Typography from "@material-ui/core/Typography"

import expandPanelIcon from "app/assets/images/expand-panel-icon.svg"
import InfoTextTooltip from "app/components/design-system/InfoTextTooltip"
import {
  additionalLineItemInfo,
  ORDER_LINE_ITEM_TYPE,
  VENDOR_PHYSICIAN_AUTHORIZATION_LABEL,
} from "app/constants"
import { navy, veryLightBlue } from "app/theme"
import { formatDollars } from "app/utils"
import makeAppStyles from "app/utils/makeAppStyles"

const useStyles = makeAppStyles((theme) => ({
  lineItemRowContainer: {
    alignItems: "center",
    display: "flex",
    marginBottom: 8,
    padding: "0 16px",
  },
  lineItemRowText: {
    color: navy,
    fontSize: 15,
  },
  lineItemRowCost: {
    color: navy,
    fontSize: 15,
    marginLeft: "auto",
    marginRight: 0,
  },
  lineItemRowTooltip: {
    marginLeft: 5,
  },
}))

interface LineItemRowProps {
  title: string
  cost: number
  tooltip?: ReactNode
}

const LineItemRow = ({ title, cost, tooltip }: LineItemRowProps) => {
  const classes = useStyles()

  return (
    <div className={classes.lineItemRowContainer}>
      <Typography className={classes.lineItemRowText}>{title}</Typography>
      {tooltip}
      <Typography className={classes.lineItemRowCost}>
        {formatDollars(cost)}
      </Typography>
    </div>
  )
}

const OrderAccordion = withStyles({
  root: {
    "&::before": {
      display: "none",
    },
  },
})(Accordion)

const OrderAccordionSummary = withStyles({
  expanded: {
    minHeight: "0px !important",
    marginTop: "2px !important",
    marginBottom: "2px !important",
  },
})(AccordionSummary)

const OrderSummary = ({ order }) => {
  const [expanded, setExpanded] = useState(false)
  const classes = useStyles()

  const handleChange = (event, isExpanded) => {
    setExpanded(isExpanded)
  }

  const processingFeeLineItems = _.filter(
    order.line_items,
    (item) => item.type === ORDER_LINE_ITEM_TYPE.PROCESSING_FEE
  )
  const processingFee = processingFeeLineItems.reduce(
    (acc, item) => acc + Number(item.cost),
    0
  )

  const customFeeLineItem = _.find(
    order.line_items,
    (item) => item.type === ORDER_LINE_ITEM_TYPE.CUSTOM_FEE
  )

  const questServiceFee = _.find(
    order.line_items,
    (item) => item.type === ORDER_LINE_ITEM_TYPE.QUEST_SERVICE_FEE
  )

  let customFeeLineItemTitle = ""
  if (customFeeLineItem) {
    customFeeLineItemTitle = order.practitioner
      ? `${customFeeLineItem.title} paid to ${order.practitioner.clinic.name}`
      : customFeeLineItem.title
  }
  // The Accordion is rotated by 180 degrees so that it opens upwards instead of downwards.
  // The Summary and Detail view are therefore also reversed 180 degrees so that they show up as expected.
  return (
    <div className="shadow rounded-lg fs-unmask">
      <OrderAccordion
        expanded={expanded}
        onChange={handleChange}
        style={{ transform: "rotate(180deg)", borderColor: veryLightBlue }}
        elevation={0}
        className="border"
      >
        <OrderAccordionSummary
          style={{ transform: "rotate(180deg)" }}
          expandIcon={
            <img src={expandPanelIcon} alt="Expand" width={19} height={19} />
          }
          className="px-4"
        >
          <div className="flex flex-row flex-grow justify-between">
            <Typography className="text-base15 font-semibold text-body">
              Total
            </Typography>
            <div>
              <Fade in={!expanded}>
                <Typography className="text-base15 font-semibold line-through text-gray-400 inline mr-4">
                  {order.subtotal !== order.total_price &&
                    formatDollars(order.subtotal)}
                </Typography>
              </Fade>
              <Typography className="text-base15 font-semibold inline text-body">
                {formatDollars(order.total_price)}
              </Typography>
            </div>
          </div>
        </OrderAccordionSummary>
        <AccordionDetails
          style={{ transform: "rotate(180deg)" }}
          className="flex flex-col p-0 pt-3"
        >
          <LineItemRow title="Total Test Price" cost={order.msrp_total} />

          {order &&
            order.line_items &&
            order.line_items
              .filter(
                (lineItem) => lineItem.type === ORDER_LINE_ITEM_TYPE.SHIPPING
              )
              .map((lineItem) => (
                <LineItemRow
                  key={lineItem.id}
                  title={lineItem.title}
                  cost={lineItem.cost}
                />
              ))}

          {processingFeeLineItems && processingFeeLineItems.length > 0 && (
            <LineItemRow
              title={processingFeeLineItems[0].title}
              cost={processingFee}
            />
          )}

          {order.physician_authorization_total > 0 && (
            <LineItemRow
              title={`Rupa ${VENDOR_PHYSICIAN_AUTHORIZATION_LABEL}`}
              cost={order.physician_authorization_total}
              tooltip={
                <InfoTextTooltip className={classes.lineItemRowTooltip}>
                  {
                    additionalLineItemInfo[
                      ORDER_LINE_ITEM_TYPE.PHYSICIAN_SERVICES_VENDOR_FEE
                    ].tooltipMessage
                  }
                </InfoTextTooltip>
              }
            />
          )}

          {questServiceFee && (
            <LineItemRow
              title={questServiceFee.title}
              cost={questServiceFee?.cost}
              tooltip={
                <InfoTextTooltip className={classes.lineItemRowTooltip}>
                  {
                    additionalLineItemInfo[
                      ORDER_LINE_ITEM_TYPE.QUEST_SERVICE_FEE
                    ].tooltipMessage
                  }
                </InfoTextTooltip>
              }
            />
          )}

          {customFeeLineItem && (
            <LineItemRow
              title={customFeeLineItemTitle}
              cost={customFeeLineItem.cost}
            />
          )}

          {order.subtotal > 0 && order.subtotal !== order.total_price ? (
            <LineItemRow title="Subtotal" cost={order.subtotal} />
          ) : (
            ""
          )}
          {order.coupon_discount > 0 ? (
            <div className="flex justify-between px-4">
              <Typography className="text-base15 font-semibold text-cyan-600">
                Discounts
              </Typography>
              <Typography
                className="text-base15 font-semibold text-cyan-600"
                align="right"
              >
                -{formatDollars(order.coupon_discount)}
              </Typography>
            </div>
          ) : (
            ""
          )}
          {order.rupa_discount > 0 ? (
            <div className="flex justify-between px-4 mt-1">
              <Typography className="text-base15 font-semibold text-cyan-600">
                Rupa Savings
              </Typography>
              <Typography
                className="text-base15 font-semibold text-cyan-600"
                align="right"
              >
                -{formatDollars(order.rupa_discount)}
              </Typography>
            </div>
          ) : (
            ""
          )}
          <Divider className="mt-4" />
        </AccordionDetails>
      </OrderAccordion>
    </div>
  )
}

export default OrderSummary
