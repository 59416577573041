import { forwardRef, useRef, useState } from "react"

export default forwardRef<
  HTMLInputElement | null,
  React.ComponentPropsWithoutRef<"input">
>((props, forwardedRef) => {
  const ref = useRef<HTMLInputElement | null>(null)
  const [value, setValue] = useState(() => props.value)

  return (
    <input
      {...props}
      value={value}
      ref={(elem) => {
        ref.current = elem

        if (forwardedRef) {
          if (typeof forwardedRef === "function") {
            forwardedRef(elem)
          } else {
            forwardedRef.current = elem
          }
        }
      }}
      onInput={(event) => setValue(event.currentTarget.value)}
      style={{ resize: "none", overflow: "hidden" }}
      autoComplete="off"
      autoCorrect="off"
      autoCapitalize="off"
      spellCheck="false"
    />
  )
})
