import {
  Practitioner,
  PractitionerListItem,
  SigningPractitioner,
} from "app/types"

/**
 * Get the signing practitioner from the practitioner object.
 * If the practitioner has signing permission, it will return the given  practitioner.
 * Otherwise, it will return their signing practitioner.
 *
 * @param practitioner The practitioner to check
 * @returns The practitioner which should be used as the signing practitioner.
 */
export function getSigningPractitioner(
  practitioner?: Practitioner | null
): Practitioner | SigningPractitioner | undefined {
  return practitioner?.has_signing_permission
    ? practitioner
    : practitioner?.signing_practitioner
}

/**
 * Given a practitioner, this will return the correct practitioner to evaluate Ordering rights
 * If the practitioner has signing permission, it will return the given practitioner.
 * If the practitioner is clinic staff, it will return the given practitioner.
 * If the practitioner has a signing practitioner, it will return the signing practitioner.
 * If none of these conditions are met, the given practitioner will be returned.
 *
 * @param practitioner The practitioner to check
 * @returns The practitioner which should be used as to evaluate Ordering rights.
 */
export function getPractitionerToEvalOrderingRights(practitioner) {
  if (practitioner?.is_clinic_staff) {
    return practitioner
  }

  if (practitioner?.has_signing_permission) {
    return practitioner
  }

  if (practitioner?.signing_practitioner) {
    return practitioner?.signing_practitioner
  }

  return practitioner
}

/**
 * Determine if the practitioner is clinic staff.
 *
 * @param practitioner Practitioner or signing practitioner.
 * @returns Boolean to indicate whether they are clinic staff.
 */
export function isClinicStaff(
  practitioner?: Practitioner | SigningPractitioner | PractitionerListItem
) {
  return practitioner?.is_clinic_staff
}
