import { keys, reduce } from "lodash"
import { AnyAction } from "redux"

import { PhlebotomistFeature } from "app/types"

import * as Actions from "./phlebotomist-map.actions"

export default function phlebotomistMap(
  state = {
    featureIds: [],
    featureById: {},
    selectedFeatureId: undefined,
  },
  action: AnyAction
) {
  switch (action.type) {
    case Actions.CLEAR_FEATURES: {
      return {
        ...state,
        featureIds: [],
        featureById: {},
      }
    }
    case Actions.RECEIVE_COLLECTION: {
      const nextFeatureById: {
        [phlebotomistId: string]: PhlebotomistFeature
      } = reduce(
        action.payload,
        (prevState, feature) => {
          return {
            ...prevState,
            [feature.properties.id]: feature,
          }
        },
        { ...state.featureById }
      )
      return {
        ...state,
        featureIds: keys(nextFeatureById),
        featureById: nextFeatureById,
      }
    }
    case Actions.SET_SELECTED_FEATURE_ID: {
      return {
        ...state,
        selectedFeatureId: action.payload,
      }
    }
    default:
      return state
  }
}
