import BodyText from "app/components/design-system/BodyText"

const EmptyResultCard = () => {
  return (
    <div className="flex flex-row-reverse items-center justify-center py-3 px-1 rounded-lg border border-transparent bg-slate-50">
      <BodyText weight="semibold" className="text-slate-400">
        --
      </BodyText>
    </div>
  )
}

export default EmptyResultCard
