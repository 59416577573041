import PageToolbar from "app/components/PageToolbar"
import Coupons from "app/components/Promotions/PromotionsCoupons"
import PromotionsIframe from "app/components/Promotions/PromotionsIframe"
import { colors } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"

const useStyles = makeAppStyles((theme) => ({
  container: {
    background: colors.coolGray[50],
    display: "flex",
    flexFlow: "column",
    padding: "20px 24px 0px 24px",
    height: "100%",
    [theme.breakpoints.down("sm")]: {
      background: colors.coolGray[100],
      padding: "20px 16px 0px 16px",
    },
  },
}))

function Promotions() {
  const classes = useStyles()
  return (
    <>
      <PageToolbar title="Promotions" fullBorder={true} greyBackground={true} />
      <div className={classes.container}>
        <Coupons />
        <PromotionsIframe />
      </div>
    </>
  )
}

export default Promotions
