import { useCallback } from "react"

import { faArrowRotateRight } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import resourceRequest from "app/swr/utils/resource-request"

import IOKStartOrderModalButton from "../IOKStartOrderModalButton"
import { InOfficeKitOrder } from "../types"
import { useOrderHistory } from "./OrderHistoryDataProvider"

interface Props {
  order: InOfficeKitOrder
}

const ReorderModalButton: React.FC<Props> = ({ order }) => {
  const { mutateOrders } = useOrderHistory()

  // TODO:
  //  - Normalize, type with InOfficeKitOrder, and use SWR
  //  - put in a hook so it can be shared with OrderDetailsDialog
  const getInitialValues = useCallback(async () => {
    return resourceRequest({
      url: `/in_office_kit_order/${order.id}/?include=in_office_kit_ordered_products&in_office_kit_lab_company`,
      method: "GET",
    }).then((response) => {
      const orderedProductData = response as any
      const orderedProducts = Object.fromEntries(
        orderedProductData.included.map((product) => {
          return [
            product.relationships.in_office_kit_product.data.id,
            product.attributes.quantity,
          ]
        })
      )
      orderedProducts["iok_lab_company"] =
        orderedProductData.data.relationships.in_office_kit_lab_company.data.id
      return orderedProducts
    })
  }, [order])

  return (
    <IOKStartOrderModalButton
      className="w-36"
      // For now, just invalidate the cache so it refetches all orders
      onSuccess={mutateOrders}
      getInitialValues={getInitialValues}
    >
      <FontAwesomeIcon icon={faArrowRotateRight} className="mr-2" />
      <span className="pt-1">Reorder</span>
    </IOKStartOrderModalButton>
  )
}

export default ReorderModalButton
