import { createContext } from "react"

import {
  ComparisonCallToActionTypes,
  ComparisonCallToActionContextShape,
} from "../types"

const ComparisonCallToActionContext =
  createContext<ComparisonCallToActionContextShape>({
    callToAction: ComparisonCallToActionTypes.START_ORDER,
    onCallToAction: () => {},
  })

export default ComparisonCallToActionContext
