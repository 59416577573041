import axios from "axios"
import { isEmpty, omitBy } from "lodash"

import { API } from "app/api"
import { acknowledgeUnreadResultAction } from "app/main/patient-orders/PatientOrdersOrderedTest/acknowledgeUnreadResult"
import { markOrderedTestResultAction } from "app/main/patient-orders/PatientOrdersOrderedTest/markOrderResultReviewed"
import { getApiBaseUrl, handleApiError } from "app/utils"

export const GET_ORDERS_REQUEST = "[ECOMMERCE] GET ORDERS REQUEST"
export const GET_ORDERS_SUCCESS = "[ECOMMERCE] GET ORDERS SUCCESS"
export const GET_ORDERS_FAILURE = "[ECOMMERCE] GET ORDERS FAILURE"
export const CLEAR_ORDERS = "[ECOMMERCE] CLEAR ORDERS"
export const UPDATE_ORDER = "[ECOMMERCE] UPDATE ORDER"

export const GET_UNREAD_RESULT_NOTIFICATIONS =
  "[ECOMMERCE] GET UNREAD RESULT NOTIFICATIONS"
export const GET_UNREAD_RESULT_NOTIFICATIONS_FAILURE =
  "[ECOMMERCE] GET UNREAD RESULT NOTIFICATIONS FAILURE"

export function getOrders(offset, appliedFilters, pageSize = 25) {
  const params = {
    offset: offset,
    limit: pageSize,
    ...omitBy(appliedFilters, isEmpty),
  }

  const request = axios.get(getApiBaseUrl() + `/api/order/`, {
    params: params,
  })

  return (dispatch) => {
    dispatch({ type: GET_ORDERS_REQUEST })

    request
      .then((response) => {
        dispatch({
          type: GET_ORDERS_SUCCESS,
          payload: response.data,
        })
      })
      .catch((error) => {
        dispatch({ type: GET_ORDERS_FAILURE })
        dispatch(handleApiError(error))
      })
  }
}

export function clearOrders() {
  return (dispatch) => dispatch({ type: CLEAR_ORDERS })
}

export function getUnreadResultNotifications(appliedFilters) {
  const params = {
    ...omitBy(appliedFilters, isEmpty),
  }

  const request =
    API.PractitionerNotifications.getUnreadResultNotifications(params)

  return (dispatch) => {
    request
      .then((response) =>
        dispatch({
          type: GET_UNREAD_RESULT_NOTIFICATIONS,
          payload: response.data,
        })
      )
      .catch((error) => {
        dispatch({ type: GET_ORDERS_FAILURE })
        dispatch(handleApiError(error))
      })
  }
}

export function updateOrder(orderId) {
  const request = API.Order.get(orderId)

  return (dispatch) =>
    request
      .then((response) => {
        dispatch({
          type: UPDATE_ORDER,
          payload: response.data,
        })
      })
      .catch((error) => dispatch(handleApiError(error)))
}

export function markOrderedTestResultReviewed(orderedTest, order) {
  return markOrderedTestResultAction(orderedTest, order, UPDATE_ORDER)
}

export function acknowledgeUnreadResult(orderedTest, order, appliedFilters) {
  return acknowledgeUnreadResultAction(
    orderedTest,
    order,
    UPDATE_ORDER,
    (dispatch) => {
      dispatch(getUnreadResultNotifications(appliedFilters))
    }
  )
}
