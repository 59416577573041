import { Dispatch } from "redux"

import { API } from "app/api"
import { EMRIntegration, RootState } from "app/types"
import { handleApiError } from "app/utils"

export const GET_EMR_INTEGRATIONS = "[EMR INTEGRATIONS] GET"
export const RECEIVE_EMR_INTEGRATIONS = "[EMR INTEGRATIONS] RECEIVE"
export const FAILED_EMR_INTEGRATIONS = "[EMR INTEGRATIONS] FAILED"

export interface PractitionerEMRIntegrationAction<T = string> {
  type: T
}

export type GetPractitionerEMRIntegrationsAction =
  PractitionerEMRIntegrationAction<typeof GET_EMR_INTEGRATIONS>

export interface FailedPractitionerEMRIntegrationsAction
  extends PractitionerEMRIntegrationAction<typeof FAILED_EMR_INTEGRATIONS> {
  payload: Error
}
export interface ReceivePractitionerEMRIntegrationsAction
  extends PractitionerEMRIntegrationAction<typeof RECEIVE_EMR_INTEGRATIONS> {
  payload: EMRIntegration[]
}

export function getPractitionerEMRIntegrationsAction(): GetPractitionerEMRIntegrationsAction {
  return {
    type: GET_EMR_INTEGRATIONS,
  }
}

export function failedPractitionerEMRIntegrationsAction(
  error: Error
): FailedPractitionerEMRIntegrationsAction {
  return {
    type: FAILED_EMR_INTEGRATIONS,
    payload: error,
  }
}

export function receivePractitionerEMRIntegrationsAction(
  payload: EMRIntegration[]
): ReceivePractitionerEMRIntegrationsAction {
  return {
    type: RECEIVE_EMR_INTEGRATIONS,
    payload,
  }
}

export function fetchPractitionerEmrIntegrations() {
  return async (dispatch: Dispatch<any>, getState: () => RootState) => {
    if (getState().emrIntegrations?.pending) {
      return
    }

    dispatch(getPractitionerEMRIntegrationsAction())

    try {
      const response = await API.PractitionerEMRIntegrations.get()

      dispatch(
        receivePractitionerEMRIntegrationsAction(response.data.emr_integrations)
      )
    } catch (error: any) {
      dispatch(failedPractitionerEMRIntegrationsAction(error))
      dispatch(handleApiError(error))
    }
  }
}

export function disconnectPractitionerEMRIntegrationAction(
  emrIntegration: string,
  disconnectingReason: string
) {
  return async (dispatch: Dispatch<any>, getState: () => RootState) => {
    try {
      await API.PractitionerEMRIntegrations.disconnect(
        emrIntegration,
        disconnectingReason
      )

      dispatch(fetchPractitionerEmrIntegrations())
    } catch (error: any) {
      dispatch(failedPractitionerEMRIntegrationsAction(error))
      dispatch(handleApiError(error))
    }
  }
}

export function requestPractitionerEMRIntegrationAction(
  emrIntegration: string
) {
  return async (dispatch: Dispatch<any>, getState: () => RootState) => {
    try {
      await API.PractitionerEMRIntegrations.request(emrIntegration)

      dispatch(fetchPractitionerEmrIntegrations())
    } catch (error: any) {
      dispatch(failedPractitionerEMRIntegrationsAction(error))
      dispatch(handleApiError(error))
    }
  }
}
