import useComparisonState from "./use-comparison-state"

/**
 * Helper hook that returns the ids of the lab tests being compared.
 *
 * @returns the ids of the lab tests being compared
 */
export default function useLabTestIds() {
  return useComparisonState().labTestIds
}
