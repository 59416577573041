import { ReactComponent as LabCompanyIcon } from "app/assets/icons/lab-company.svg"
import {
  ComparisonRow,
  ComparisonColumnProps,
  EmptyComparisonValue,
} from "app/main/comparison/components/ComparisonLabTests/components"

const LabCompanyColumn = ({ labTest }: ComparisonColumnProps) => {
  return <>{labTest?.lab_company.name || <EmptyComparisonValue />}</>
}

export default function ComparisonLabCompanyRow() {
  return (
    <ComparisonRow
      Column={LabCompanyColumn}
      headerIcon={<LabCompanyIcon viewBox="0 0 13 12" />}
      headerLabel="Lab Company"
    />
  )
}
