import _ from "lodash"

import { ReactComponent as BasketIcon } from "app/assets/icons/basket.svg"
import PendingPlaceholder from "app/components/LabTests/PendingPlaceholder"
import BodyText from "app/components/design-system/BodyText"
import { colors, rupaTeal } from "app/theme"
import { formatDollars } from "app/utils"
import makeAppStyles from "app/utils/makeAppStyles"
import { LabTest } from "types/lab-test"

const useStyles = makeAppStyles((theme) => ({
  container: {
    backgroundColor: "white",
    padding: "0px 20px 20px 20px",
  },
  savingContainer: {
    border: `1px solid ${rupaTeal}`,
    backgroundColor: `${rupaTeal}11`,
    borderRadius: 7,
    padding: 10,
    margin: "10px 0px",
  },
  warningContainer: {
    border: `1px solid ${colors.blueGray[300]}`,
    backgroundColor: `${colors.blueGray[50]}`,
    borderRadius: 7,
    padding: 10,
    margin: "10px 0px",
  },
}))

export interface ComboGroupConfigurationValidationDetailsProps {
  targetLabTest?: LabTest
  selectedOptionLabTestIds: string[]
  optionLabTests?: LabTest[]
  availableTargets?: LabTest[]
  optionsSumCost: number
  validConfiguration: boolean
  configurationWarningMessage: string
  isLoading: boolean
}

export default function ComboGroupConfigurationValidationDetails({
  targetLabTest,
  selectedOptionLabTestIds,
  optionLabTests,
  availableTargets,
  optionsSumCost,
  validConfiguration,
  configurationWarningMessage,
  isLoading,
}: ComboGroupConfigurationValidationDetailsProps) {
  const classes = useStyles()

  const targetCost = Number(targetLabTest?.attributes.rupa_price) ?? 0
  const savings = formatDollars(optionsSumCost - targetCost)

  const nextTarget = availableTargets?.find(
    (target) =>
      target.attributes.combo_num_selections ===
      selectedOptionLabTestIds.length + 1
  )
  const unselectedOptions = _.sortBy(
    optionLabTests?.filter(
      (option) => !selectedOptionLabTestIds.includes(option.id)
    ),
    (option) => Number(option.attributes.rupa_price)
  )
  let nextTargetMessage: string | undefined = undefined
  if (nextTarget && unselectedOptions.length > 0) {
    const cheapestUnselectedOption = _.sortBy(unselectedOptions, (option) =>
      Number(option.attributes.rupa_price)
    )[0]
    const nextTargetSavings = formatDollars(
      Number(cheapestUnselectedOption.attributes.rupa_price) -
        (Number(nextTarget.attributes.rupa_price) -
          Number(targetLabTest?.attributes.rupa_price))
    )
    nextTargetMessage = `Add 1 more test to save ${nextTargetSavings} or more with the ${nextTarget.attributes.name}.`
  }

  return (
    <div className={classes.container}>
      {isLoading ? (
        <div className={classes.savingContainer}>
          <PendingPlaceholder width={200} />
        </div>
      ) : validConfiguration ? (
        <div className={classes.savingContainer}>
          <BodyText size="sm" style={{ color: rupaTeal }}>
            <BasketIcon /> Saving{" "}
            <span style={{ fontWeight: "bold" }}>{savings}</span> with a{" "}
            {targetLabTest?.attributes.name}.
          </BodyText>
          {nextTargetMessage && (
            <BodyText size="sm" style={{ marginTop: 8 }}>
              {nextTargetMessage}
            </BodyText>
          )}
        </div>
      ) : (
        <div className={classes.warningContainer}>
          <BodyText size="sm">{configurationWarningMessage}</BodyText>
        </div>
      )}
    </div>
  )
}
