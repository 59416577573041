import { useState, useMemo } from "react"

import {
  TextField,
  Chip,
  makeStyles,
  Typography,
  Link,
} from "@material-ui/core"
import Autocomplete from "@material-ui/lab/Autocomplete"

import BodyText from "app/components/design-system/BodyText"
import useCachedCollection from "app/swr/hooks/use-cached-collection"
import useCollectionSWR from "app/swr/hooks/use-collection-swr"
import { colors, primaryColor, shadows } from "app/theme"

interface Props {
  statesSelectField: any
  statesSelectFormState: any
}

const styles = () => ({
  chip: {
    backgroundColor: colors.blue[50],
    gap: 10,

    border: "1px solid #0075CD",

    boxShadow: shadows["sm"],
    borderRadius: 6,
    minWidth: 65,
  },
  chipLabel: {
    padding: "6px 0 6px 4px",
    color: primaryColor,
    fontSize: 15,
    fontWeight: 600,
  },
  chipDeleteIcon: {
    color: primaryColor,
    "&:hover": {
      color: colors.blue[500],
    },
  },
  link: {
    color: primaryColor,
    cursor: "pointer",
  },
  labelContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  inputRoot: {
    background: "white",
  },
})

const useStyles = makeStyles(styles)

const PractitionerProfileStateSelect = ({
  statesSelectField,
  statesSelectFormState,
}: Props) => {
  const classes = useStyles()
  const [isOpen, setIsOpen] = useState(false)

  const { data: licensedRegionsOptionsIdentifiers } = useCollectionSWR<any>(
    "/webflow_field_options/",
    {
      params: {
        "filter[field_slug]": "practitioner-licensed-region",
      },
    }
  )

  const licensedRegionsOptions = useCachedCollection<any>(
    licensedRegionsOptionsIdentifiers ?? []
  )

  const options = useMemo(() => {
    const options: { value: string; label: string; chipLabel: string }[] = []

    licensedRegionsOptions.forEach((option) => {
      options.push({
        value: option.id,
        label: option.attributes.field_name,
        chipLabel: option.attributes.field_option_slug.toUpperCase(),
      })
    })

    return options
  }, [licensedRegionsOptions])

  const onChange = (event, values) => {
    const allSelections = new Set(values.map((value) => value.value ?? value))
    statesSelectField.onChange(Array.from(allSelections))
  }

  return (
    <>
      <div className={classes.labelContainer}>
        <div className="mb-1 fs-unmask">
          <Typography
            color="textPrimary"
            className="font-semibold inline"
            style={{ fontSize: 15 }}
          >
            In which states can you see clients?
          </Typography>
          <Typography
            color="error"
            className="font-semibold inline"
            style={{ fontSize: 15 }}
          >
            {" *"}
          </Typography>
          {statesSelectFormState.errors["licensed_regions"] && (
            <BodyText size="sm" color="error">
              {statesSelectFormState.errors["licensed_regions"].message}
            </BodyText>
          )}
        </div>
        <div>
          <Link
            onClick={(e) => {
              e.preventDefault()
              statesSelectField.onChange(options.map((option) => option.value))
            }}
            target="_blank"
          >
            <div className="mb-1 fs-unmask">
              <BodyText weight="semibold" className={classes.link}>
                Select All States
              </BodyText>
            </div>
          </Link>
        </div>
      </div>
      <Autocomplete
        multiple
        open={isOpen}
        options={options}
        onFocus={() => setIsOpen(true)}
        onBlur={() => setIsOpen(false)}
        getOptionLabel={(option) => option.label}
        value={statesSelectField.value.map((value) => value.id ?? value)}
        onChange={onChange}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => {
            const chipLabel = options.find((o) => o.value === option)?.chipLabel
            return (
              <Chip
                key={option}
                label={chipLabel ?? option}
                classes={{
                  root: classes.chip,
                  label: classes.chipLabel,
                  deleteIcon: classes.chipDeleteIcon,
                }}
                {...getTagProps({ index })}
              />
            )
          })
        }
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            placeholder="Select States"
            InputProps={{
              ...params.InputProps,
              classes: { root: classes.inputRoot },
            }}
          />
        )}
      />
    </>
  )
}

export default PractitionerProfileStateSelect
