import { Marker } from "react-map-gl"

import { makeStyles } from "@material-ui/core"

import { primaryColor } from "app/theme"

interface PhlebotomistMapSearchMarkerProps {
  latitude: number
  longitude: number
}

/**
 * Marker to mark current searched location
 *
 * This component is meant to be rendered within the PhlebotomistMap
 *
 * @component
 */
const PhlebotomistMapSearchMarker = ({
  latitude,
  longitude,
}: PhlebotomistMapSearchMarkerProps) => {
  const classes = useStyles()

  return (
    <Marker
      latitude={latitude}
      longitude={longitude}
      offsetLeft={-12.5}
      offsetTop={-12.5}
    >
      <div className={classes.searchMarker}>
        <svg viewBox="0 0 150 150" xmlns="http://www.w3.org/2000/svg">
          <circle cx="75" cy="75" r="75" fill="#FFFFFF" />
          <circle cx="75" cy="75" r="50" fill={primaryColor} />
        </svg>
      </div>
    </Marker>
  )
}

const useStyles = makeStyles((theme) => ({
  searchMarker: {
    width: 25,
  },
}))

export default PhlebotomistMapSearchMarker
