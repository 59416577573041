import _ from "lodash"

import {
  StorefrontLabTestType,
  useResourceSelector,
} from "app/hooks/use-resource"

export const useGetLabTestCompanies = (
  labTests: (StorefrontLabTestType | undefined)[]
) => {
  const labCompanyIdsInOrder = _.uniq(
    labTests
      .map((labTest) => labTest?.relationships.lab_company?.data?.id)
      .filter((id) => !!id) as string[]
  )

  const labCompaniesInOrder = useResourceSelector((entities) =>
    labCompanyIdsInOrder.map((id) => entities["lab_company"]?.[id])
  )

  return labCompaniesInOrder
}
