import { useCallback, useMemo } from "react"

import clsx from "clsx"
import { find, isEmpty, sortBy } from "lodash"

import { Divider } from "@material-ui/core"

import usePhysicianServicesOptInModal from "app/components/modals/PhysicianServicesOptInModal/use-physician-services-opt-in-modal"
import { VENDOR_PHYSICIAN_AUTHORIZATION_LABEL } from "app/constants"
import { isPractitionerBlockedFromTests } from "app/dataServices/labTestDataService"
import useFeatureFlag, { FeatureFlag } from "app/hooks/use-feature-flag"
import useAppSelector from "app/hooks/useAppSelector"
import { primaryColor } from "app/theme"
import {
  DefaultPractitionerOption,
  labTestLocation,
  OneOfAnyPractitionerOption,
  PhysicianAuthorizationType,
  Practitioner,
  PractitionerOptionType,
  VENDOR_PHYS_AUTH_PRACTITIONER_OPTION,
} from "app/types"
import makeAppStyles from "app/utils/makeAppStyles"

import OrderingPractitionerSelect from "./OrderingPractitionerSelect"
import SigningPractitionerSelect from "./SigningPractitionerSelect"
import useCheckoutDraft from "./hooks/use-checkout-draft"

const useStyles = makeAppStyles({
  container: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    gap: 10,
    marginBottom: "18px",
  },
  link: {
    color: primaryColor,
    cursor: "pointer",
  },
})

export default function PractitionerSelects() {
  const classes = useStyles()
  const { updateValues, values } = useCheckoutDraft()

  const patientFirstName = usePatientFirstName()

  const orderingPractitionerOptions = useOrderingPractitionerOptions()
  const currentOrderingPractitionerOption =
    useCurrentOrderingPractitionerOption(
      orderingPractitionerOptions,
      values.practitioner
    )

  const signingPractitionerOptions = useSigningPractitionerOptions(
    orderingPractitionerOptions,
    currentOrderingPractitionerOption,
    values.signing_practitioner
  )
  const currentSigningPractitionerOption = useCurrentSigningPractitionerOption(
    signingPractitionerOptions,
    values.signing_practitioner,
    values.requires_vendor_physician_authorization ||
      (currentOrderingPractitionerOption?.practitioner.clinic
        .is_international_clinic &&
        !currentOrderingPractitionerOption?.practitioner
          .vendor_physician_authorization_enabled)
  )

  const handleSelectOrderingPractitioner = useCallback(
    (option?: DefaultPractitionerOption) => {
      if (option?.type === PractitionerOptionType.DEFAULT) {
        const practitioner = option.practitioner

        // attempt to optimistically default the signing practitioner
        let signing_practitioner: Practitioner | null = null
        if (practitioner.has_signing_permission) {
          signing_practitioner = practitioner
        } else if (
          practitioner.physician_authorization ===
          PhysicianAuthorizationType.ALWAYS_REQUIRED
        ) {
          // for pilot phys auth, we let the API default the signing practitioner
          signing_practitioner = null
        } else {
          const optionForDefaultSigning = find(
            orderingPractitionerOptions,
            (orderingOption) =>
              orderingOption.practitioner.id ===
              practitioner.signing_practitioner?.id
          )
          signing_practitioner = optionForDefaultSigning?.practitioner || null
        }

        updateValues({
          practitioner,
          signing_practitioner,
          // if this signing prac selects phys auth by default, then make this order phys auth
          requires_vendor_physician_authorization:
            signing_practitioner?.vendor_physician_authorization_enabled &&
            signing_practitioner?.default_physician_authorization,
        })
      } else {
        updateValues({
          practitioner: null,
          signing_practitioner: null,
          requires_vendor_physician_authorization: false,
        })
      }
    },
    [orderingPractitionerOptions, updateValues, values]
  )

  const handleSelectSigningPractitioner = useCallback(
    (option?: OneOfAnyPractitionerOption) => {
      if (option?.type === PractitionerOptionType.DEFAULT) {
        updateValues({
          signing_practitioner: option.practitioner,
          requires_vendor_physician_authorization: false,
        })
      } else if (option?.type === PractitionerOptionType.VENDOR_PHYS_AUTH) {
        updateValues({
          signing_practitioner: null,
          requires_vendor_physician_authorization: true,
        })
      } else {
        updateValues({
          signing_practitioner: null,
          requires_vendor_physician_authorization: false,
        })
      }
    },
    [updateValues]
  )

  const [isInternationalClinicsEnabled] = useFeatureFlag(
    FeatureFlag.InternationalClinics
  )

  const physicianServicesOptInModal = usePhysicianServicesOptInModal()

  const physicianServicesText = "You must add "

  let signingPractitionerTooltipText: any = ""

  if (
    isInternationalClinicsEnabled &&
    currentOrderingPractitionerOption?.practitioner?.clinic
      ?.is_international_clinic &&
    !currentOrderingPractitionerOption?.practitioner
      ?.vendor_physician_authorization_enabled
  ) {
    signingPractitionerTooltipText = (
      <span>
        {physicianServicesText}
        <span
          id="physician-services-link"
          onClick={() =>
            physicianServicesOptInModal.show({
              onClose: () => {
                physicianServicesOptInModal.remove()
              },
            })
          }
          className={classes.link}
        >
          {VENDOR_PHYSICIAN_AUTHORIZATION_LABEL}
        </span>{" "}
        to order as a practitioner outside of the US.
      </span>
    )
  }

  const hasLoadedSigningPractitioners = !isEmpty(signingPractitionerOptions)
  return (
    <>
      <Divider className="my-4" />
      <div
        className={clsx(
          classes.container,
          "tour-onboarding-select-practitioner"
        )}
      >
        <OrderingPractitionerSelect
          infoText={`We will file ${patientFirstName}'s order under this practitioner in your portal.`}
          label={`${patientFirstName}'s Practitioner`}
          onSelect={handleSelectOrderingPractitioner}
          options={orderingPractitionerOptions}
          value={currentOrderingPractitionerOption}
        />

        {hasLoadedSigningPractitioners && (
          <SigningPractitionerSelect
            infoText={`This practitioner's information will be on the requisition forms.`}
            label={`Signing Practitioner`}
            onSelect={handleSelectSigningPractitioner}
            options={signingPractitionerOptions}
            value={currentSigningPractitionerOption}
            tooltipText={signingPractitionerTooltipText}
          />
        )}
      </div>
    </>
  )
}

function usePatientFirstName() {
  const order = useAppSelector(({ orders }) => orders.orders.order)
  return order?.patient?.first_name
}

/**
 * Returns the set of ordering practitioner options ordered by their accessibility.
 *
 * @returns the set of ordering practitioner options to choose between
 */
function useOrderingPractitionerOptions() {
  const order = useAppSelector(({ orders }) => orders.orders.order)
  const practitioners = useAppSelector(
    ({ orders }) => orders.orders.practitioners
  )
  return useMemo<DefaultPractitionerOption[]>(() => {
    if (isEmpty(practitioners)) {
      return []
    }
    return sortBy(
      practitioners.map((practitioner) => ({
        type: PractitionerOptionType.DEFAULT,
        practitioner,
        blocked: isPractitionerBlockedFromTests(
          order,
          practitioner,
          labTestLocation.CHECKOUT
        ),
      })),
      (option) => (option.blocked ? 1 : 0)
    )
  }, [order, practitioners])
}

/**
 * Returns the option matching the current ordering practitioner.
 *
 * @param practitionerOptions the set of practitioner options
 * @param practitioner the ordering practitioner, if it exists
 * @returns the current ordering practitioner option
 */
function useCurrentOrderingPractitionerOption(
  practitionerOptions: DefaultPractitionerOption[],
  practitioner?: Practitioner | null
) {
  const order = useAppSelector(({ orders }) => orders.orders.order)
  return useMemo(() => {
    if (practitioner) {
      const matchingOption = find(
        practitionerOptions,
        (option) => option.practitioner.id === practitioner?.id
      )
      if (matchingOption) {
        return matchingOption
      }
      return {
        type: PractitionerOptionType.DEFAULT,
        practitioner,
        blocked: isPractitionerBlockedFromTests(
          order,
          practitioner,
          labTestLocation.CHECKOUT
        ),
      } as DefaultPractitionerOption
    }
    return undefined
  }, [order, practitionerOptions, practitioner])
}

/**
 * Returns the set of signing practitioners that may be chosen for the given ordering practitioner options.
 *
 * @param orderingPractitionerOptions the set of ordering practitioner option
 * @param currentOrderingPractitionerOption the current ordering practitioner option
 * @returns the set of signing practitioner options for the inputs
 */
function useSigningPractitionerOptions(
  orderingPractitionerOptions: DefaultPractitionerOption[],
  currentOrderingPractitionerOption?: DefaultPractitionerOption,
  signingPractitioner?: Practitioner | null
) {
  const [isInternationalClinicsEnabled] = useFeatureFlag(
    FeatureFlag.InternationalClinics
  )
  function getSigningPractitionerOptions() {
    if (!currentOrderingPractitionerOption) {
      return []
    }

    // Special handling for international clinics
    if (
      isInternationalClinicsEnabled &&
      currentOrderingPractitionerOption.practitioner?.clinic
        ?.is_international_clinic
    ) {
      return [VENDOR_PHYS_AUTH_PRACTITIONER_OPTION]
    }

    const options: OneOfAnyPractitionerOption[] = []
    var defaultSigningPractitionerOption:
      | DefaultPractitionerOption
      | undefined = undefined
    if (
      currentOrderingPractitionerOption.practitioner?.has_signing_permission
    ) {
      // use ordering practitioner as default signing practitioner if they have signing permission
      defaultSigningPractitionerOption = currentOrderingPractitionerOption
    } else if (
      currentOrderingPractitionerOption.practitioner.signing_practitioner
    ) {
      // use ordering practitioner's default signing practitioner, if there is one
      defaultSigningPractitionerOption = find(
        orderingPractitionerOptions,
        (option) =>
          option.practitioner.id ===
          currentOrderingPractitionerOption.practitioner.signing_practitioner
            ?.id
      )
    }
    if (defaultSigningPractitionerOption) {
      options.push(defaultSigningPractitionerOption)
    }

    // add the vendor phys auth option
    if (
      currentOrderingPractitionerOption.practitioner
        .vendor_physician_authorization_enabled
    ) {
      options.push(VENDOR_PHYS_AUTH_PRACTITIONER_OPTION)
    }

    // add other practitioners in the clinic if they have signing permission
    orderingPractitionerOptions.forEach((option) => {
      if (
        option.practitioner.id !==
          defaultSigningPractitionerOption?.practitioner.id &&
        option.practitioner.has_signing_permission &&
        !option.blocked
      ) {
        options.push(option)
      }
    })

    if (
      signingPractitioner &&
      !signingPractitioner.is_vendor_physician_authorization_clinician &&
      !options.some(
        (option) =>
          option.type === PractitionerOptionType.DEFAULT &&
          option.practitioner.id === signingPractitioner.id
      )
    ) {
      // if a signing practitioner is already defined on the order, ensure we have an option for it
      options.push({
        type: PractitionerOptionType.DEFAULT,
        practitioner: signingPractitioner,
        blocked: false,
      })
    }
    return options
  }
  return useMemo<OneOfAnyPractitionerOption[]>(getSigningPractitionerOptions, [
    orderingPractitionerOptions,
    currentOrderingPractitionerOption,
    signingPractitioner,
  ])
}

/**
 * Returns the matching option for the current order signing practitioner.
 *
 * @param signingPractitionerOptions the set of signing practitioner options
 * @param signingPractitioner the current signing practitioner on the order
 * @returns the matching signing practitioner option, if it exists
 */
function useCurrentSigningPractitionerOption(
  signingPractitionerOptions: OneOfAnyPractitionerOption[],
  signingPractitioner?: Practitioner | null,
  requiresVendorPhysicianAuthorization?: boolean
) {
  return useMemo(() => {
    if (isEmpty(signingPractitionerOptions)) {
      return undefined
    }
    if (requiresVendorPhysicianAuthorization) {
      return VENDOR_PHYS_AUTH_PRACTITIONER_OPTION
    }
    return find(
      signingPractitionerOptions,
      (option) =>
        option.type === PractitionerOptionType.DEFAULT &&
        option.practitioner.id === signingPractitioner?.id
    )
  }, [
    signingPractitionerOptions,
    signingPractitioner?.id,
    requiresVendorPhysicianAuthorization,
  ])
}
