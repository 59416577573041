import { ReactComponent as ComparisonTableIcon } from "app/assets/icons/comparison-table-empty-blue.svg"
import DesignSystemButton from "app/components/design-system/Button"
import useFeatureFlag, { FeatureFlag } from "app/hooks/use-feature-flag"
import useComparisonActions from "app/main/comparison/hooks/use-comparison-actions"
import { AnyLabTest } from "app/types"
import makeAppStyles from "app/utils/makeAppStyles"

const useStyles = makeAppStyles((theme) => ({
  link: {
    alignItems: "center",
    "& .MuiButton-startIcon": {
      fill: "currentColor",
      width: 15,
      height: 15,
      // workaround to get the icon to be positioned in the center
      marginBottom: 2,
    },
  },
}))

interface CompareTestsButtonProps {
  labTest?: AnyLabTest
}

function CompareTestsButton({ labTest }: CompareTestsButtonProps) {
  const actions = useComparisonActions()
  const classes = useStyles()

  return (
    <DesignSystemButton
      className={classes.link}
      color="secondary"
      onClick={() => actions.openComparisonModal(labTest?.id, labTest)}
      startIcon={<ComparisonTableIcon viewBox="0 0 17 14" />}
      variant="text"
    >
      Compare Tests
    </DesignSystemButton>
  )
}

export default function FeatureFlaggedCompareTestsButton(
  props: CompareTestsButtonProps
) {
  const [isLabTestComparisonEnabled] = useFeatureFlag(
    FeatureFlag.LabTestComparison
  )
  return isLabTestComparisonEnabled ? <CompareTestsButton {...props} /> : null
}
