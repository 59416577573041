import { Typography } from "@material-ui/core"

import { ReactComponent as PatientDocumentsIcon } from "app/assets/images/documents/patient-documents-icon.svg"
import useFeatureFlag from "app/hooks/use-feature-flag"
import useSetQueryParam from "app/hooks/use-set-query-param"
import { PatientPortalResultsUploadButton } from "app/patient-portal/trends/PatientPortalResultsUploadButton"
import { FeatureFlag } from "app/providers/FeatureFlagProvider"

import {
  DOCUMENT_TYPE_QUERY_PARAM_KEY,
  DocumentTypeOption as DocumentTypeOptionV2,
  DocumentTypeQueryParamValue,
} from "./DocumentTypeSelect"

interface Props {
  patientFirstName: string
  totalDocumentCount: number
  selectedDocumentType?: DocumentTypeOptionV2
  patientPortalUserId?: string
}
const PatientDocumentsListEmpty: React.FC<Props> = ({
  patientFirstName,
  totalDocumentCount,
  selectedDocumentType,
  patientPortalUserId,
}) => {
  const [isPatientPortalResultsUploadEnabled, flagLoading] = useFeatureFlag(
    FeatureFlag.PatientPortalResultsUploads
  )
  const showUploadButton = !flagLoading && isPatientPortalResultsUploadEnabled
  const { setQueryParam } = useSetQueryParam()

  let title: string, subtitle: React.ReactNode
  if (totalDocumentCount > 0 && selectedDocumentType) {
    title = `There aren't any ${selectedDocumentType.label} documents for ${patientFirstName} yet!`
    subtitle = (
      <span
        className="text-primary-500 cursor-pointer"
        onClick={() =>
          setQueryParam(
            DOCUMENT_TYPE_QUERY_PARAM_KEY,
            DocumentTypeQueryParamValue.ALL
          )
        }
      >
        See all of {patientFirstName}'s documents
      </span>
    )
  } else {
    title = `There aren't any documents${
      patientFirstName ? ` for ${patientFirstName}` : ""
    } yet!`
    subtitle =
      "Documents include: Lab Results, Food Plans, Results Summaries, and more."
  }

  return (
    <div className="bg-slate-50 rounded-lg flex flex-col justify-center items-center text-center">
      <div className="py-7 px-2 max-w-sm flex flex-col justify-center items-center">
        <PatientDocumentsIcon />

        <div className="flex flex-col gap-4">
          <Typography className="font-semibold text-base15">{title}</Typography>
          <Typography className="text-sm">{subtitle}</Typography>
          {patientPortalUserId && showUploadButton && (
            <PatientPortalResultsUploadButton
              variant="outline-primary"
              userId={patientPortalUserId}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default PatientDocumentsListEmpty
