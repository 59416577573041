import { ComponentProps } from "react"
import * as React from "react"

import clsx from "clsx"

import { CircularProgress } from "@material-ui/core"

import { ReactComponent as TrashCanIcon } from "app/assets/icons/trash-can.svg"
import { ReactComponent as VerticalDragHandle } from "app/assets/images/results-interpretation/vertical-drag-handle.svg"
import TextField from "app/results-summary/components/ResultsSummaryEditor/components/TextField"
import useBiomarkerEditorForm from "app/results-summary/hooks/use-biomarker-editor-form"
import useDeleteInterpretationBiomarker from "app/results-summary/hooks/use-delete-interpretation-biomarker"
import useCachedResource from "app/swr/hooks/use-cached-resource"
import { ResourceIdentifier } from "app/swr/types"
import { colors, primaryColor, shadows } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"
import { ResultsInterpretationBiomarker } from "types/results-interpretation"

const useStyles = makeAppStyles((theme) => ({
  root: {
    width: "100%",
    display: "flex",
    flexFlow: "row nowrap",
    gap: theme.spacing(1.0),
    padding: theme.spacing(1.0),
    border: `1px solid transparent`,
    backgroundColor: "white",
    borderRadius: 6,
  },
  rootDragging: {
    borderColor: primaryColor,
    boxShadow: shadows.xl,
  },
  dragHandle: {
    flex: "0 0 16px",
    cursor: "grab !important",
    alignSelf: "flex-start",
    "$rootDragging &": {
      cursor: "grabbing !important",
    },
  },
  fields: {
    flex: "1 1 auto",
    display: "flex",
    flexFlow: "column nowrap",
    gap: theme.spacing(1.5),
  },
  fieldRow: {
    width: "100%",
    display: "flex",
    flexFlow: "row wrap",
    gap: theme.spacing(1.75),
  },
  fieldCol: {
    flex: 1,
    display: "flex",
  },
  actions: {
    flex: "0 0 16px",
    display: "flex",
    flexFlow: "column nowrap",
    gap: theme.spacing(1.0),
  },
  deleteButton: {
    display: "flex",
    alignItems: "center",
  },
  deleteIcon: {
    width: 16,
    height: 16,
    fill: colors.blueGray[400],
  },
}))

interface BiomarkerEditorFormProps {
  biomarker: ResultsInterpretationBiomarker
  overlay?: boolean
  dragHandleProps?: ComponentProps<"button">
  style?: React.CSSProperties
}

const BiomarkerEditorForm = React.forwardRef<
  HTMLFormElement,
  BiomarkerEditorFormProps
>(({ biomarker, dragHandleProps, overlay, style }, ref) => {
  const classes = useStyles()
  const { control } = useBiomarkerEditorForm(biomarker)
  const { deleteBiomarker, isDeleting } =
    useDeleteInterpretationBiomarker(biomarker)

  return (
    <form
      className={clsx(classes.root, { [classes.rootDragging]: overlay })}
      ref={ref}
      style={style}
    >
      <button className={classes.dragHandle} {...dragHandleProps} type="button">
        <VerticalDragHandle viewBox="0 0 15 19" />
      </button>

      <div className={classes.fields}>
        <div className={classes.fieldRow}>
          <div className={classes.fieldCol}>
            <TextField control={control} disabled label="Marker" name="name" />
          </div>
        </div>

        <div className={classes.fieldRow}>
          <div className={classes.fieldCol}>
            <TextField
              control={control}
              disabled={overlay}
              label="Value"
              name="value"
              placeholder="Value"
              required
            />
          </div>
          <div className={classes.fieldCol}>
            <TextField
              control={control}
              disabled={overlay}
              label="Units"
              name="units"
              placeholder="Units"
            />
          </div>
          <div className={classes.fieldCol}>
            <TextField
              control={control}
              disabled={overlay}
              label="Normal Min."
              name="normal_min"
              placeholder="Value"
            />
          </div>
          <div className={classes.fieldCol}>
            <TextField
              control={control}
              disabled={overlay}
              label="Normal Max."
              name="normal_max"
              placeholder="Value"
            />
          </div>
        </div>

        <div className={classes.fieldRow}>
          <div className={classes.fieldCol}>
            <TextField
              control={control}
              disabled={overlay}
              label="Description"
              name="description"
              placeholder="Describe the marker..."
              variant="textarea"
            />
          </div>
        </div>
      </div>

      <div className={classes.actions}>
        {isDeleting ? (
          <CircularProgress size={16} />
        ) : (
          <button
            className={classes.deleteButton}
            disabled={overlay}
            onClick={deleteBiomarker}
            type="button"
          >
            <TrashCanIcon className={classes.deleteIcon} viewBox="0 0 15 17" />
          </button>
        )}
      </div>
    </form>
  )
})

export interface BiomarkerEditorProps {
  dragHandleProps?: ComponentProps<"button">
  identifier: ResourceIdentifier
  overlay?: boolean
  style?: React.CSSProperties
}

export default React.forwardRef<HTMLFormElement, BiomarkerEditorProps>(
  function BiomarkerEditor({ identifier, ...props }, ref) {
    const biomarker =
      useCachedResource<ResultsInterpretationBiomarker>(identifier)

    if (!biomarker) {
      return null
    }

    return <BiomarkerEditorForm biomarker={biomarker} {...props} ref={ref} />
  }
)
