import { CellContext } from "@tanstack/react-table"

import BodyText from "app/components/design-system/BodyText"
import { colors } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"
import { LabCompanyBiomarkerRange } from "types/lab-company-biomarker-range"

import columnHelper from "./column-helper"

const useStyles = makeAppStyles(() => ({
  container: {
    display: "flex",
    gap: 4,
  },
}))

const ReferenceRangeCell = ({
  getValue,
}: CellContext<
  LabCompanyBiomarkerRange,
  LabCompanyBiomarkerRange["attributes"]
>) => {
  const classes = useStyles()

  const attributes = getValue()
  const standardRangeMin = attributes?.standard_range_min
  const standardRangeMax = attributes?.standard_range_max

  let referenceRangeText = ""

  if (standardRangeMin && standardRangeMax) {
    referenceRangeText = `${standardRangeMin} to ${standardRangeMax}`
  } else if (standardRangeMin && !standardRangeMax) {
    referenceRangeText = `> ${standardRangeMin}`
  } else if (!standardRangeMin && standardRangeMax) {
    referenceRangeText = `< ${standardRangeMax}`
  }

  return (
    <div className={classes.container}>
      <BodyText weight="semibold">{referenceRangeText}</BodyText>
      <BodyText weight="semibold" style={{ color: colors.blueGray[400] }}>
        {attributes?.unit}
      </BodyText>
    </div>
  )
}

export const referenceRangeColumn = columnHelper.accessor("attributes", {
  cell: (info) => <ReferenceRangeCell {...info} />,
  header: (info) => (
    <BodyText size="sm" weight="semibold">
      Lab Company's Reference Range
    </BodyText>
  ),
  id: "referenceRange",
  size: 200,
  minSize: 200,
})
