/*
 * A traditional Hamburger menu icon created using only css. Animates between
 * the three horizontal bars and a close icon depending on the value of the `open`
 * prop.
 */
import { makeStyles } from "@material-ui/core"

import theme from "app/theme"

interface Props {
  open: boolean
  color?: string
}

const useStyles = makeStyles<typeof theme, { open: boolean; color: string }>({
  menu: {
    width: 24,
    height: 13,
    position: "relative",
    transform: "rotate(0deg)",
    transition: ".5s ease-in-out",
    cursor: "pointer",

    "& div": {
      position: "absolute",
      height: 2,
      width: "100%",
      background: (props) => props.color,
      borderRadius: 10,
      left: 0,
      transform: "rotate(0deg)",
      transition: ".20s ease-in-out",
    },

    "& div:nth-child(1)": {
      top: (props) => (props.open ? 6 : 0),
      transform: (props) => (props.open ? "scale(0)" : "none"),
      opacity: (props) => (props.open ? 0 : 1),
    },

    "& div:nth-child(2)": {
      top: 6,
      transform: (props) => (props.open ? "rotate(45deg)" : "none"),
    },
    "& div:nth-child(3)": {
      top: 6,
      transform: (props) => (props.open ? "rotate(-45deg)" : "none"),
    },

    "& div:nth-child(4)": {
      top: (props) => (props.open ? 6 : 12),
      transform: (props) => (props.open ? "scale(0)" : "none"),
      opacity: (props) => (props.open ? 0 : 1),
    },
  },
})

const HamburgerMenuIcon = ({ open = false, color = "white" }: Props) => {
  const { menu } = useStyles({ open, color })

  return (
    <div className={menu}>
      <div />
      <div />
      <div />
      <div />
    </div>
  )
}

export default HamburgerMenuIcon
