import axios from "axios"

import { OrderedTest, Order } from "app/types"
import { getApiBaseUrl, handleApiError } from "app/utils"

/**
 * Given an ordered test update the order with the new ordered test
 * @param updatedOrderedTest ordered test that has been updated
 * @param order order to be updated
 * @returns updated order
 */
function setOrderState(updatedOrderedTest: OrderedTest, order: Order) {
  // Replace ordered test in list with updated ordered test
  const updatedOrderedTests = order.ordered_tests.map((o) =>
    o.id === updatedOrderedTest.id ? updatedOrderedTest : o
  )

  // Return order with updated fields
  return {
    ...order,
    ordered_tests: updatedOrderedTests,
  }
}

/**
 * Marks an ordered test results reviewed
 * @param orderedTest ordered test to update
 * @returns updated ordered test
 */
function updateOrderedToResultsRead(orderedTest: OrderedTest) {
  return {
    ...orderedTest,
    is_ordered_test_result_unread: false,
  }
}

/**
 * Given an updated ordered test, updates an entire order
 * Properly updates all ordered tests that are associated with the updated ordered result from updated ordered test
 * @param updatedOrderedTest ordered result that has been updated
 * @param order order to update
 * @returns updated order
 */
function updateCompleteOrderWithUpdatedOrderedTest(
  updatedOrderedTest: OrderedTest,
  order: Order
) {
  if (updatedOrderedTest.latest_ordered_result === null) {
    return setOrderState(updatedOrderedTest, order)
  }

  const orderedTestObjectsToUpdate = order.ordered_tests.filter((o) =>
    updatedOrderedTest?.latest_ordered_result?.ordered_tests.includes(
      String(o.id)
    )
  )

  for (let orderedTest of orderedTestObjectsToUpdate) {
    const newUpdatedOrderedTest = updateOrderedToResultsRead(orderedTest)
    order = setOrderState(newUpdatedOrderedTest, order)
  }
  return order
}

/**
 * Generic action for acknowledging unread results for an ordered test
 * Will optimistically update the cache with the new value and then send a request to the server
 * @param orderedTest ordered test that is being toggled
 * @param order parent order
 * @param dispatch_type type of dispatch to use
 */
export function acknowledgeUnreadResultAction(
  orderedTest: OrderedTest,
  order: Order,
  dispatch_type: string,
  onAcknowledgeUnreadResult?: Function
) {
  // Must be an ordered result to mark reviewed
  if (!orderedTest.latest_ordered_result) {
    return (dispatch) => {
      dispatch(handleApiError("Missing result"))
    }
  }

  // Calculate the optimistic update
  const optimisticOrderedTest = updateOrderedToResultsRead(orderedTest)
  const optimisticOrder = updateCompleteOrderWithUpdatedOrderedTest(
    optimisticOrderedTest,
    order
  )

  // Build request patching with new value
  const request = axios.patch(
    getApiBaseUrl() + `/api/orderedtest/${orderedTest.id}/`,
    {
      acknowledge_unread_result: true,
    }
  )

  return (dispatch) => {
    // Do optimistic update
    dispatch({
      type: dispatch_type,
      payload: optimisticOrder,
    })

    // Send request to server and handle response
    request
      .then((response) => {
        if (onAcknowledgeUnreadResult) {
          dispatch(onAcknowledgeUnreadResult)
        }
        dispatch({
          type: dispatch_type,
          payload: updateCompleteOrderWithUpdatedOrderedTest(
            response.data,
            order
          ),
        })
      })
      .catch((error) => {
        dispatch(handleApiError(error))
        dispatch({
          type: dispatch_type,
          payload: order,
        })
      })
  }
}
