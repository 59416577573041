import { useContext, useMemo } from "react"

import {
  FeatureFlag,
  FeatureFlagContext,
} from "app/providers/FeatureFlagProvider"

export type UseFeatureFlag = [boolean, boolean]

// re-export for easier usage patterns
export { FeatureFlag }

/**
 * Hook used to retrieve the status of a feature flag.
 *
 * Determine whether a feature is enabled:
 * ```
 * const [enabled] = useFeatureFlag(FeatureFlag.MY_FEATURE)
 * ```
 *
 * Show a loading state while a feature is determined to be enabled:
 * ```
 * const [enabled, loading] = useFeatureFlag(FeatureFlag.MY_FEATURE)
 *
 * if (loading) {
 *   return <LoadingState/>
 * }
 * ```
 *
 * @param flagKey the feature flag key
 * @returns
 */
export default function useFeatureFlag(flagKey: FeatureFlag) {
  const [flags, { loading }] = useContext(FeatureFlagContext)
  return useMemo<UseFeatureFlag>(() => {
    return [flags[flagKey], loading]
  }, [flagKey, flags, loading])
}

// Same as useFeatureFlag, but returns undefined instead of false when the flag is not yet loaded
export function useFeatureFlagNoDefault(flagKey: FeatureFlag) {
  const [flags, { loading }] = useContext(FeatureFlagContext)
  return useMemo<[boolean | undefined, boolean]>(() => {
    return [loading ? undefined : flags[flagKey], loading]
  }, [flagKey, flags, loading])
}
