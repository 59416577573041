import { useMemo } from "react"
import * as React from "react"

import { TransitionGroup } from "react-transition-group"

import { Fade } from "@material-ui/core"

import {
  COLUMN_TRANSITION_TIMEOUT,
  COLUMN_PADDING,
  COLUMN_WIDTH,
} from "app/main/comparison/constants"
import useLabTestById from "app/main/comparison/hooks/use-lab-test-by-id"
import useLabTestIds from "app/main/comparison/hooks/use-lab-test-ids"
import { textPrimaryColor } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"

import ComparisonRowHeader, {
  ComparisonRowHeaderProps,
} from "./ComparisonRowHeader"

const useStyles = makeAppStyles((theme) => ({
  row: {
    display: "flex",
    flexFlow: "column nowrap",
    gap: COLUMN_PADDING,
  },
  columns: {
    display: "flex",
    flexFlow: "row nowrap",
  },
  column: {
    width: COLUMN_WIDTH,
    padding: `0 ${COLUMN_PADDING}px`,
    color: textPrimaryColor,
    fontSize: 15,
    fontWeight: theme.typography.fontWeightMedium,
    lineHeight: 1.35,
  },
}))

const ComparisonRowChild = React.memo(
  ({
    Column,
    labTestId,
  }: {
    Column: ComparisonRowProps["Column"]
    labTestId: string
  }) => {
    const labTestData = useLabTestById(labTestId)
    const MemoizedColumn = useMemo(() => React.memo(Column), [Column])
    return <MemoizedColumn {...labTestData} />
  }
)

export type ComparisonColumnProps = ReturnType<typeof useLabTestById>

interface ComparisonRowProps {
  Column: React.VoidFunctionComponent<ComparisonColumnProps>
  children?: (props: {
    classes: ReturnType<typeof useStyles>
  }) => React.ReactNode
  headerColor?: ComparisonRowHeaderProps["color"]
  headerIcon?: ComparisonRowHeaderProps["icon"]
  headerLabel?: ComparisonRowHeaderProps["children"]
}

export default function ComparisonRow({
  Column,
  children,
  headerColor,
  headerIcon,
  headerLabel,
}: ComparisonRowProps) {
  const classes = useStyles()
  const labTestIds = useLabTestIds()

  return (
    <div className={classes.row}>
      {headerLabel && (
        <ComparisonRowHeader color={headerColor} icon={headerIcon}>
          {headerLabel}
        </ComparisonRowHeader>
      )}
      <TransitionGroup className={classes.columns}>
        {labTestIds.map((labTestId) => (
          <Fade key={labTestId} timeout={COLUMN_TRANSITION_TIMEOUT}>
            <div className={classes.column}>
              <ComparisonRowChild Column={Column} labTestId={labTestId} />
            </div>
          </Fade>
        ))}
        {children?.({ classes })}
      </TransitionGroup>
    </div>
  )
}
