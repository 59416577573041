import { Popup } from "react-map-gl"

import { makeStyles } from "@material-ui/core"

import { ReactComponent as AlertTriangle } from "app/assets/icons/alert-triangle-gray.svg"
import BodyText from "app/components/design-system/BodyText"
import { STATES_WITH_ORDERING_RIGHTS } from "app/constants"
import { colors, secondaryColor, white } from "app/theme"
import { PhlebotomistFeature } from "app/types"
import { formatPhone } from "app/utils"
import "mapbox-gl/dist/mapbox-gl.css"

import { NJ_NY_RI_RESTRICTIONS_URL } from "../settings/constants"

const POPUP_OFFSET_LEFT = 15
const POPUP_OFFSET_TOP = -10
const MOBILE_POPUP_OFFSET_LEFT = 0
const MOBILE_POPUP_OFFSET_TOP = 0

interface PhlebotomistMapPopupProps {
  isMobile: boolean
  phlebotomistFeature: PhlebotomistFeature
  onClose: () => void
}

/**
 * Map popup to be placed on Mapbox map to display a selected markers data
 *
 * This component is meant to be rendered within the PhlebotomistMap
 *
 * @component
 */
const PhlebotomistMapPopup = ({
  isMobile,
  phlebotomistFeature,
  onClose,
}: PhlebotomistMapPopupProps) => {
  const classes = useStyles()

  const isPhlebFeatureAddressBlank = () => {
    return (
      phlebotomistFeature.properties.address_street === "" &&
      phlebotomistFeature.properties.address_city === "" &&
      phlebotomistFeature.properties.address_state === "" &&
      phlebotomistFeature.properties.address_zip === ""
    )
  }

  const showNYNJRIWarning =
    phlebotomistFeature.properties.address_state &&
    STATES_WITH_ORDERING_RIGHTS.includes(
      phlebotomistFeature.properties.address_state
    )

  return (
    <Popup
      latitude={phlebotomistFeature.geometry.coordinates[1]}
      longitude={phlebotomistFeature.geometry.coordinates[0]}
      closeOnClick={true}
      captureClick={true}
      capturePointerMove={true}
      onClose={onClose}
      anchor={isMobile ? "top" : "left"}
      dynamicPosition={false}
      offsetLeft={isMobile ? MOBILE_POPUP_OFFSET_LEFT : POPUP_OFFSET_LEFT}
      offsetTop={isMobile ? MOBILE_POPUP_OFFSET_TOP : POPUP_OFFSET_TOP}
    >
      <div className={classes.popupWrapper}>
        <BodyText className={classes.popupTitle} weight="semibold" size="md">
          {phlebotomistFeature.properties.name}
        </BodyText>
        <hr className={classes.separator} />
        <BodyText className={classes.popupGrid}>
          {phlebotomistFeature.properties.url && (
            <>
              <span className={classes.popupFieldLabel}>Website:</span>
              <span className={classes.popupFieldData}>
                <a
                  href={phlebotomistFeature.properties.url}
                  target="_blank"
                  rel="noreferrer"
                  className={classes.styledLink}
                >
                  {phlebotomistFeature.properties.url}
                </a>
              </span>
            </>
          )}

          <span className={classes.popupFieldLabel}>Type:</span>
          <span className={classes.popupFieldData}>
            {phlebotomistFeature.properties.location_types}
          </span>
          {phlebotomistFeature.properties.phone && (
            <>
              <span className={classes.popupFieldLabel}>Phone:</span>
              <span className={classes.popupFieldData}>
                {formatPhone(phlebotomistFeature.properties.phone)}
              </span>
            </>
          )}
          {phlebotomistFeature.properties.email && (
            <>
              <span className={classes.popupFieldLabel}>Email:</span>
              <span className={classes.popupFieldData}>
                <a
                  href={"mailto: " + phlebotomistFeature.properties.email}
                  className={classes.styledLink}
                >
                  {phlebotomistFeature.properties.email}
                </a>
              </span>
            </>
          )}
          {phlebotomistFeature.properties.hours && (
            <>
              <span className={classes.popupFieldLabel}>Hours:</span>
              <span className={classes.popupFieldData}>
                {phlebotomistFeature.properties.hours}
              </span>
            </>
          )}
          {Boolean(phlebotomistFeature.properties.approximate_fee) && (
            <>
              <span className={classes.popupFieldLabel}>Fee:</span>
              <span className={classes.popupFieldData}>
                ${phlebotomistFeature.properties.approximate_fee}
              </span>
            </>
          )}
          {!isPhlebFeatureAddressBlank() ? (
            <>
              <span className={classes.popupFieldLabel}>Address:</span>
              <span className={classes.popupFieldData}>
                {phlebotomistFeature.properties.address_street}
                <br />
                {phlebotomistFeature.properties.address_city},{" "}
                {phlebotomistFeature.properties.address_state}
                <br />
                {phlebotomistFeature.properties.address_zip}
              </span>
            </>
          ) : null}
        </BodyText>
        {showNYNJRIWarning && (
          <div className={classes.stateWarningContainer}>
            <div className={classes.stateAlertIconContainer}>
              <AlertTriangle
                width={20}
                viewBox="0 0 14 14"
                fill={white}
                fillOpacity={1.0}
              />
            </div>
            <BodyText
              size="sm"
              weight="semibold"
              className={classes.stateAlertText}
            >
              There are restrictions in{" "}
              {phlebotomistFeature.properties.address_state}. Only some Rupa lab
              tests can be drawn there.{" "}
              <u>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={NJ_NY_RI_RESTRICTIONS_URL}
                >
                  Learn more
                </a>
              </u>
              .
            </BodyText>
          </div>
        )}
      </div>
    </Popup>
  )
}

const useStyles = makeStyles((theme) => ({
  styledLink: {
    "&:link": {
      color: theme.palette.text.primary,
      textDecoration: "underline",
    },
  },
  popupGrid: {
    display: "grid",
    gridTemplateColumns: "auto",
    gridRowGap: "7.5px",
    gridColumnGap: "30px",
    height: 200,
    overflowY: "auto",
    padding: 5,
    [theme.breakpoints.up("sm")]: {
      gridTemplateColumns: "auto 2fr",
      height: "auto",
      overflowY: "visible",
    },
  },
  popupTitle: {
    width: "calc(100% - 17px)",
    overflow: "hidden",
    overflowWrap: "break-word",
  },
  popupFieldLabel: {
    fontWeight: 600,
  },
  popupFieldData: {
    userSelect: "text",
    cursor: "text",
    userDrag: "none",
    overflowWrap: "anywhere",
  },
  popupWrapper: {
    margin: 10,
    maxWidth: 350,
    minWidth: 300,
  },
  separator: {
    height: 1,
    width: "100%",
    backgroundColor: colors.coolGray[300],
    marginTop: 10,
    marginBottom: 10,
  },
  stateWarningContainer: {
    display: "flex",
    flexDirection: "row",
    border: `1px solid ${secondaryColor}`,
    borderRadius: 7,
  },
  stateAlertIconContainer: {
    backgroundColor: secondaryColor,
    padding: 5,
    borderRadius: "7px 0px 0px 7px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  stateAlertText: {
    padding: 7,
  },
}))

export default PhlebotomistMapPopup
