import { Checkbox, Label } from "@rupahealth/design"

import { InOfficeKitLabCompanyType } from "../types"
import { CategoryValidation } from "./ConfirmOrderForm"

interface LargeOrderWarningProps {
  selectedLabCompany: InOfficeKitLabCompanyType
  categoriesOverMax: (CategoryValidation & { display_name?: string })[]
  isOverValidOrderQuantities: boolean
  setIsOverValidOrderQuantities: (isValidOrderQuantities: boolean) => void
}

export const LargeOrderWarning = ({
  selectedLabCompany,
  categoriesOverMax,
  isOverValidOrderQuantities,
  setIsOverValidOrderQuantities,
}: LargeOrderWarningProps) => {
  categoriesOverMax.forEach((group) => {
    if (group.name === "Kit") {
      group.display_name = "kits"
    } else if (group.name === "Collection Supply") {
      group.display_name = "collection supplies"
    } else {
      group.display_name = `${group.name} items`
    }
  })
  const warningText = `This seems like a big order! Labs often decrease orders over ${categoriesOverMax
    .map((group) => `${group.order_max} ${group.display_name || group.name}`)
    .join(
      ", or "
    )}. We suggest decreasing this order size and requesting more supplies as needed.${"\u00A0"}😊`

  return (
    <div className="w-full bg-white self-center border-b-[1px] border-b-gray-300">
      <div className="h-2 bg-gradient-to-r from-orange-300 to-transparent" />
      <div className="p-4 flex gap-4 flex-row">
        <div className="bg-slate-100 p-3 w-10 h-10 min-w-10 rounded-md flex items-center justify-center">
          👀
        </div>
        <div className="flex flex-col gap-3">
          <p>{warningText}</p>
          <div className="flex items-center space-x-3 text-sm">
            <Checkbox
              id="override-order-limits"
              variant="default"
              checked={!isOverValidOrderQuantities}
              onCheckedChange={() =>
                setIsOverValidOrderQuantities(!isOverValidOrderQuantities)
              }
            />
            <Label
              htmlFor="override-order-limits"
              className="font-semibold text-sm cursor-pointer"
            >
              No thanks! I confirm I plan to use all the items in this order for{" "}
              {selectedLabCompany.attributes.lab_company_name}.
            </Label>
          </div>
        </div>
      </div>
    </div>
  )
}
