import { useState, useRef, useMemo, useCallback, useEffect } from "react"

import {
  DndContext,
  useSensor,
  PointerSensor,
  KeyboardSensor,
  useSensors,
  closestCenter,
  DragOverlay,
  DropAnimation,
  defaultDropAnimationSideEffects,
  MeasuringStrategy,
  CollisionDetection,
  pointerWithin,
  rectIntersection,
  getFirstCollision,
  UniqueIdentifier,
} from "@dnd-kit/core"
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable"
import { Theme } from "@material-ui/core"

import {
  StorefrontLabTestBundleType,
  StorefrontLabTestType,
  StorefrontSectionType,
} from "app/hooks/use-resource"
import makeAppStyles from "app/utils/makeAppStyles"

import SectionProducts from "./SectionProducts"
import SortableSection, { SectionData } from "./dnd-components/SortableSection"
import UnsortableProduct from "./dnd-components/UnsortableProduct"
import UnsortableSection from "./dnd-components/UnsortableSection"

const styles = (theme: Theme) => ({
  container: {
    width: "100%",
    display: "flex",
    "flex-direction": "column",
    gap: 18,
    paddingTop: 35,
  },
})

const useStyles = makeAppStyles(styles)

interface Props {
  sections: StorefrontSectionType[]
  openProductCustomizeModal: (productId: string) => void
  removeLabTest: (labTest: StorefrontLabTestType | undefined) => void
  removeBundle: (bundle: StorefrontLabTestBundleType | undefined) => void
  openRemoveSectionModal: (sectionId: string) => void
  updateSections: (sectionData: SectionData[]) => void
  updateSectionsOrder: (sections: any[]) => void
  hidePhysServicesPricing: boolean
}

enum DragObjectType {
  SECTION = "Section",
  PRODUCT = "Product",
}

const EcommerceSections = ({
  sections,
  openProductCustomizeModal,
  removeLabTest,
  removeBundle,
  openRemoveSectionModal,
  updateSections,
  updateSectionsOrder,
  hidePhysServicesPricing,
}: Props) => {
  const classes = useStyles()

  const [activeId, setActiveId] = useState<string | null>(null)
  const [activeType, setActiveType] = useState<DragObjectType | null>(null)
  const [items, setItems] = useState(sections)
  const [newCategoryMovement, setNewCategoryMovement] = useState<any | null>(
    null
  )

  const recentlyMovedToNewContainer = useRef(false)

  const lookupTable = useMemo(() => {
    let table = {}

    items.forEach((section, index) => {
      table[section.id] = { index, container: section, value: section }
      section.relationships.storefront_products.data.forEach((product) => {
        table[product?.id] = { index, container: section, value: product }
      })
    })

    return table
  }, [items])

  const handleDragStart = (event) => {
    const { active } = event

    setActiveId(active.id)
    setActiveType(
      items.find((item) => item.id === active.id)
        ? DragObjectType.SECTION
        : DragObjectType.PRODUCT
    )
  }

  const findContainerIndex = (id: string) => {
    const index = items.findIndex((item) => item?.id === id)
    if (index > -1) {
      return index
    }

    return items.findIndex((item) =>
      item?.relationships.storefront_products.data.find(
        (child) => child.id === id
      )
    )
  }

  const handleDragOver = useCallback(
    ({ active, over }) => {
      const overId = over?.id
      const activeId = active.id

      if (overId == null || items.find((item) => item?.id === activeId)) {
        return
      }

      const overContainerIndex = findContainerIndex(overId)
      const overContainer = items[overContainerIndex]
      const activeContainerIndex = findContainerIndex(active.id)
      const activeContainer = items[activeContainerIndex]

      if (!overContainer || !activeContainer) {
        return
      }

      // Dragging between containers
      if (activeContainer.id !== overContainer.id) {
        const itemsCopy = [...items]

        const activeItems =
          activeContainer.relationships.storefront_products.data
        const overItems = overContainer.relationships.storefront_products.data
        const overIndex = overItems.findIndex((item) => item.id === overId)
        const activeIndex = activeItems.findIndex(
          (item) => item.id === activeId
        )

        let newIndex: number

        if (items.find((item) => item?.id === overId)) {
          newIndex = overItems.length + 1
        } else {
          const isBelowOverItem =
            over &&
            active.rect.current.translated &&
            active.rect.current.translated.top >
              over.rect.top + over.rect.height

          const modifier = isBelowOverItem ? 1 : 0

          newIndex =
            overIndex >= 0 ? overIndex + modifier : overItems.length + 1
        }

        recentlyMovedToNewContainer.current = true

        overContainer.relationships.storefront_products.data.splice(
          newIndex,
          0,
          activeContainer.relationships.storefront_products.data[activeIndex]
        )
        activeContainer.relationships.storefront_products.data.splice(
          activeIndex,
          1
        )

        itemsCopy[overContainerIndex] = overContainer
        itemsCopy[activeContainerIndex] = activeContainer
        setNewCategoryMovement({
          from: activeContainer.id,
          to: overContainer.id,
        })
        setItems(itemsCopy)
      }
    },
    [items]
  )

  const updateAcrossSections = () => {
    if (newCategoryMovement) {
      const toSection = lookupTable[newCategoryMovement.to]
      const fromSection = lookupTable[newCategoryMovement.from]

      updateSections([
        {
          id: newCategoryMovement.to,
          name: toSection.value.attributes.name,
          description: toSection.value.description,
          products:
            toSection.value.relationships.storefront_products?.data.filter(
              (product) => product
            ),
        },
        {
          id: newCategoryMovement.from,
          name: fromSection.value.attributes.name,
          description: fromSection.value.description,
          products:
            fromSection.value.relationships.storefront_products?.data.filter(
              (product) => product
            ),
        },
      ])
    }
  }

  const handleDragEnd = useCallback(
    (event) => {
      const { active, over } = event

      const isSectionId = Boolean(items.find((item) => item?.id === active?.id))
      if (active?.id !== over?.id) {
        // Moving Sections Around
        if (isSectionId) {
          let itemsCopy = [...items]
          const oldIndex = items.findIndex((item) => item?.id === active.id)
          const newIndex = items.findIndex((item) => item?.id === over.id)
          itemsCopy = arrayMove(items, oldIndex, newIndex)
          setItems(itemsCopy)

          updateSectionsOrder(
            itemsCopy.map((item) => ({
              type: "storefront_section",
              id: item.id,
            }))
          )
        } else {
          // Moving Products Around
          const itemsCopy = [...items]

          // Child drag
          const originParentSectionIndex = lookupTable[active.id]?.index
          const originParentSection = items[originParentSectionIndex]
          const oldIndex =
            originParentSection?.relationships.storefront_products.data.findIndex(
              (item) => item?.id === active?.id
            )

          if (oldIndex === -1) {
            updateAcrossSections()
            return
          }

          // Moving within same section
          if (
            originParentSection?.relationships.storefront_products.data.find(
              (child) => child?.id === over?.id
            )
          ) {
            const newIndex =
              originParentSection?.relationships.storefront_products.data.findIndex(
                (item) => item.id === over.id
              )

            const newChildrenOrder = arrayMove(
              originParentSection?.relationships.storefront_products.data,
              oldIndex,
              newIndex
            )

            if (itemsCopy && itemsCopy[originParentSectionIndex]) {
              ;(
                itemsCopy[originParentSectionIndex] as any
              ).relationships.storefront_products.data = newChildrenOrder
              setItems(itemsCopy)
            }

            updateSections([
              {
                id: originParentSection?.id,
                name: originParentSection?.attributes.name,
                description: originParentSection.attributes.description,
                products:
                  originParentSection?.relationships.storefront_products.data,
              },
            ])
          } else {
            // Moving to a new section
            const newParentSectionIndex = items.findIndex((item) =>
              item?.relationships.storefront_products.data.find(
                (child) => child?.id === over?.id
              )
            )

            if (newParentSectionIndex === -1) {
              const newParentSection = items[newParentSectionIndex]
              const childObject =
                originParentSection?.relationships.storefront_products.data[
                  oldIndex
                ]

              const newIndex =
                newParentSection?.relationships.storefront_products.data.findIndex(
                  (item) => item.id === over.id
                )

              if (!newIndex || !childObject) {
                updateAcrossSections()
              }
            }
          }
        }
      } else {
        updateAcrossSections()
      }
    },
    [items]
  )

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  )

  useEffect(() => {
    setItems(sections)
  }, [sections])

  const dropAnimation: DropAnimation = {
    sideEffects: defaultDropAnimationSideEffects({
      styles: {
        active: {
          opacity: "0.5",
        },
      },
    }),
  }

  const lastOverId = useRef<UniqueIdentifier | null>(null)

  /**
   * Custom collision detection strategy optimized for multiple containers
   *
   * - First, find any droppable containers intersecting with the pointer.
   * - If there are none, find intersecting containers with the active draggable.
   * - If there are no intersecting containers, return the last matched intersection
   *
   */
  const collisionDetectionStrategy: CollisionDetection = useCallback(
    (args) => {
      if (activeId && activeId in items) {
        return closestCenter({
          ...args,
          droppableContainers: args.droppableContainers.filter(
            (container) => container.id in items
          ),
        })
      }

      // Start by finding any intersecting droppable
      const pointerIntersections = pointerWithin(args)
      const intersections =
        pointerIntersections.length > 0
          ? // If there are droppables intersecting with the pointer, return those
            pointerIntersections
          : rectIntersection(args)
      let overId = getFirstCollision(intersections, "id")

      if (overId != null) {
        if (overId in items) {
          const containerItems = items[overId]

          // If a container is matched and it contains items (columns 'A', 'B', 'C')
          if (containerItems.length > 0) {
            // Return the closest droppable within that container
            overId = closestCenter({
              ...args,
              droppableContainers: args.droppableContainers.filter(
                (container) =>
                  container.id !== overId &&
                  containerItems.includes(container.id)
              ),
            })[0]?.id
          }
        }

        lastOverId.current = overId

        return [{ id: overId }]
      }

      // When a draggable item moves to a new container, the layout may shift
      // and the `overId` may become `null`. We manually set the cached `lastOverId`
      // to the id of the draggable item that was moved to the new container, otherwise
      // the previous `overId` will be returned which can cause items to incorrectly shift positions
      if (recentlyMovedToNewContainer.current) {
        lastOverId.current = activeId
      }

      // If no droppable is matched, return the last match
      return lastOverId.current ? [{ id: lastOverId.current }] : []
    },
    [activeId, items]
  )

  return (
    <DndContext
      sensors={sensors}
      onDragOver={handleDragOver}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      measuring={{
        droppable: {
          strategy: MeasuringStrategy.Always,
        },
      }}
      collisionDetection={collisionDetectionStrategy}
    >
      <SortableContext items={items} strategy={verticalListSortingStrategy}>
        <div className={classes.container}>
          {items.map((section) => (
            <SortableSection
              key={section.id}
              id={section.id}
              items={section.relationships.storefront_products.data}
              section={section}
              openRemoveSectionModal={openRemoveSectionModal}
              updateSection={(data) => updateSections([data])}
            >
              <SortableContext
                items={section.relationships.storefront_products.data}
              >
                <SectionProducts
                  section={section}
                  openProductCustomizeModal={openProductCustomizeModal}
                  removeLabTest={removeLabTest}
                  removeBundle={removeBundle}
                  hidePhysServicesPricing={hidePhysServicesPricing}
                />
              </SortableContext>
            </SortableSection>
          ))}
        </div>
      </SortableContext>
      <DragOverlay dropAnimation={dropAnimation}>
        {activeType === DragObjectType.SECTION ? (
          <UnsortableSection
            section={activeId ? lookupTable[activeId]?.container : undefined}
          >
            <SectionProducts
              section={activeId ? lookupTable[activeId]?.container : undefined}
              openProductCustomizeModal={openProductCustomizeModal}
              removeLabTest={removeLabTest}
              removeBundle={removeBundle}
              hidePhysServicesPricing={hidePhysServicesPricing}
            />
          </UnsortableSection>
        ) : (
          <UnsortableProduct
            id={activeId}
            openProductCustomizeModal={openProductCustomizeModal}
            removeLabTest={removeLabTest}
            removeBundle={removeBundle}
            hidePhysServicesPricing={hidePhysServicesPricing}
          />
        )}
      </DragOverlay>
    </DndContext>
  )
}

export default EcommerceSections
