import { Chip } from "@material-ui/core"

import makeAppStyles from "app/utils/makeAppStyles"

const useStyles = makeAppStyles((theme) => ({
  root: {
    verticalAlign: "baseline",
    padding: "0.5px 0",
  },
  label: {
    fontWeight: 700,
    fontSize: 13,
    overflow: "hidden",
    textOverflow: "ellipsis",
    textTransform: "uppercase",
    whiteSpace: "nowrap",
  },
}))

export default function BetaChip({
  backgroundColor,
  color,
  className,
}: {
  backgroundColor: string
  color: string
  className?: string
}) {
  const classes = useStyles()
  return (
    <Chip
      classes={{
        root: classes.root,
        label: classes.label,
      }}
      className={className}
      label="BETA"
      size="small"
      style={{
        backgroundColor,
        color,
      }}
    />
  )
}
