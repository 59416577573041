import { useHistory, useRouteMatch } from "react-router-dom"

import { PendingOrderModal } from "./PendingOrderModal"
import { RequisitionReadyModal } from "./RequisitionReadyModal"

function PatientOrderModals({ patient }) {
  const {
    params: { orderId, patientId },
    path,
  } = useRouteMatch<{ orderId: string; patientId: string }>()
  const patientFirstName = patient?.first_name
  const history = useHistory()

  const onClose = () => {
    history.push(`/patient/${patientId}`)
  }

  const requisitionReadyModalOpen = !!(
    path.includes("requisitions") && patientFirstName
  )
  const pendingPatientPaymentModalOpen = !!(
    path.includes("pendingpayment") && patientFirstName
  )

  return (
    <div>
      <RequisitionReadyModal
        orderId={orderId}
        open={requisitionReadyModalOpen}
        onClose={onClose}
        patientFirstName={patientFirstName}
      />
      <PendingOrderModal
        orderId={orderId}
        open={pendingPatientPaymentModalOpen}
        onClose={onClose}
        patient={patient}
      />
    </div>
  )
}

export default PatientOrderModals
