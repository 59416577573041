import { withStyles } from "@material-ui/core"
import Tooltip from "@material-ui/core/Tooltip"

import { navy } from "../theme"

export const NavyTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: navy,
    color: "white",
    maxWidth: 320,
    fontSize: theme.typography.pxToRem(14),
    padding: 16,
    borderRadius: 8,
    textAlign: "center",
  },
  arrow: {
    color: navy,
  },
}))(Tooltip)
