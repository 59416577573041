import { useCallback } from "react"

import { useCreateResource } from "app/swr/requests/use-create-resource"
import { useUpdateResource } from "app/swr/requests/use-update-resource"
import { LabCompany } from "app/types"
import { UserResult } from "types/user-result"

import {
  formDataToCreateData,
  UserBiomarkerResultFormData,
  UserResultFormData,
} from "./form-helpers"

type UR = UserResult

// Shape of the data for a single UserBiomarkerResult passed to the API
export interface UserBiomarkerResultData
  extends Omit<UserBiomarkerResultFormData, "user_biomarker_result_id"> {
  id: string | undefined
  value_type: string
}

// Shape of the data for a POST request to create a UserResult
export interface UserResultCreateData extends Pick<UR, "type"> {
  results: UserBiomarkerResultData[]
  attributes: Partial<UserResult["attributes"]>
  relationships: Pick<UR["relationships"], "patient"> & {
    sample_types: Omit<UR["relationships"]["sample_types"], "meta">
    lab_company: UR["relationships"]["lab_company"] | { data: null }
  }
}

// Shape of the data for a PUT request to update a UserResult
export interface UserResultUpdateData
  extends UserResultCreateData,
    Pick<UR, "id"> {}

// Hook to create or update a UserResult based on whether an existing one is provided
export function useCreateOrUpdateUserResult({
  userResult,
  patientId,
  handleSave,
  labCompanies,
}: {
  userResult: UR | undefined
  patientId?: string
  handleSave: (userResult: UR) => void
  labCompanies: LabCompany[]
}) {
  const { createResource } = useCreateResource<UR, UserResultCreateData>({
    url: "/user_results/",
    include: ["lab_company", "user_biomarker_results.biomarker"],
    onSuccess: handleSave,
  })
  const { updateResource } = useUpdateResource<UR, UserResultUpdateData>({
    path: (userResult: UR) => `/user_results/${userResult.id}/`,
    include: ["lab_company", "user_biomarker_results.biomarker"],
    onSuccess: handleSave,
  })

  return useCallback(
    async (formData: UserResultFormData) => {
      const createData = formDataToCreateData({
        formData,
        patientId,
        labCompanies,
      })

      if (userResult) {
        await updateResource(userResult, { ...createData, id: userResult.id })
      } else {
        await createResource(createData)
      }
    },
    [userResult, patientId, handleSave]
  )
}
