import useResourceSWR from "app/swr/hooks/use-resource-swr"
import { ComboGroup } from "types/combo-group"

export default function useComboGroupModalResources(comboGroupId: string) {
  const comboGroupSwr = useResourceSWR<ComboGroup>(
    `/combo_groups/${comboGroupId}/`,
    {
      include: [
        "options",
        "options.lab_test",
        "options.lab_test.lab_test_types",
        "options.lab_test.biomarkers",
        "lab_tests",
        "lab_tests.lab_company",
        "lab_tests.lab_test_types",
        "lab_tests.add_ons",
        "lab_tests.add_ons.lab_test_types",
        "lab_tests.add_ons.biomarkers",
      ],
    },
    {
      fallbackIdentifier: {
        type: "combo_group",
        id: comboGroupId,
      },
      revalidateOnMount: true,
    }
  )

  return { comboGroupSwr }
}

export type UseComboGroupModalResourcesHook = ReturnType<
  typeof useComboGroupModalResources
>
