import { MutatorCallback, MutatorOptions, useSWRConfig } from "swr"

import useEventCallback from "app/hooks/use-event-callback"
import { getResourceCacheKey } from "app/swr/helpers/keys"
import { Resource, ResourceIdentifier } from "app/swr/types"

/**
 * Provides a mutate function for a resource in the swr cache.
 *
 * Useful for updating a cached resource in response to a server-side update, for example:
 *
 * ```typescript
 * const mutateResource = useMutateResource()
 *
 * function handleSubmit(update) {
 *   return mutateResource<LabTest>(
 *      { type: "lab_test", id: "lbts_123" },
 *      async () => {
 *        const response = await axios.patch("/lab_tests/lbts_123", update)
 *
 *        return response.data
 *      }
 *   )
 * }
 * ```
 *
 * Supports optimistic updates to the cached resource with rollbacks on error:
 *
 * ```typescript
 * const mutateResource = useMutateResource()
 *
 * function handleSubmit(update) {
 *   return mutateResource<LabTest>(
 *      { type: "lab_test", id: "lbts_123" },
 *      async () => {
 *        const response = await axios.patch("/lab_tests/lbts_123", update)
 *
 *        return response.data
 *      },
 *      {
 *        // Optimistic data updates the cached resource immediately
 *        optimisticData: (current) => merge({}, current, update)
 *        // Rollback on error ensures the optimistic data is rolled back if the patch fails.
 *        rollbackOnError: true
 *      }
 *   )
 * }
 * ```
 *
 * @returns the mutate resource function
 */
export default function useMutateResource() {
  const globalConfig = useSWRConfig()

  return useEventCallback(async function mutateResource<
    R extends Resource = Resource
  >(
    identifier: ResourceIdentifier,
    data?: R | Promise<R> | MutatorCallback<R>,
    opts?: MutatorOptions<R>
  ) {
    return globalConfig.mutate<R>(getResourceCacheKey(identifier), data, opts)
  })
}
