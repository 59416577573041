import { useMemo } from "react"
import * as React from "react"

import { IconButton, makeStyles, Theme } from "@material-ui/core"

import AddWhiteIcon from "app/assets/icons/add-white.svg"
import CheckIcon from "app/assets/icons/blue-checkmark.svg"
import { ReactComponent as CarrotRightIcon } from "app/assets/icons/labshops/carrot-right.svg"
import SampleTypePill from "app/components/SampleTypePill"
import BodyText from "app/components/design-system/BodyText"
import DesignSystemButton from "app/components/design-system/Button"
import {
  OrderIntent,
  StorefrontLabTestType,
  StorefrontLabTestTypeType,
  useResourceByIdSelector,
  useResourceSelector,
} from "app/hooks/use-resource"
import { colors, navy, primaryColor } from "app/theme"
import { formatDollars } from "app/utils"

import { calculateTotalTestPricing } from "../../utils"
import ProductImage from "./ProductImage"

const styles = (theme: Theme) => ({
  content: {
    "&:hover": {
      cursor: "pointer",
      "& $labTestTextTestName": {
        textDecoration: "underline",
      },
      "& $labTestTextCompanyName": {
        textDecoration: "underline",
      },
      "& $labTestTextPrice": {
        textDecoration: "underline",
      },
    },
    width: "calc(25% - 23px)",
    "box-sizing": "border-box",
    [theme.breakpoints.down(1600)]: {
      width: "calc(33% - 17px)",
    },
    [theme.breakpoints.down("md")]: {
      width: "calc(50% - 15px)",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  card: {
    background: "#f2f2f2",
    border: `0.5px solid #f2f2f2`,
    borderRadius: 8,
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  genericImageContainer: {
    position: "relative" as any,
    perspective: 1608,
  },
  labTestLogo: {
    position: "absolute" as any,
    left: "-8%",
    top: "6%",
    right: 0,
    bottom: 0,
    width: "100%",
    height: "100%",
    paddingTop: "8%",
    paddingRight: "38%",
    paddingBottom: "49%",
    paddingLeft: "10%",
    transform: "rotateX(50deg) rotateY(0deg) rotateZ(34deg)",
    objectFit: "contain" as any,
  },
  labTestInfo: {
    position: "absolute" as any,
    bottom: 14,
    left: 12,
    display: "flex",
    "flex-direction": "row",
    justifyContent: "space-between",
    width: "calc(100% - 24px)",
    justifyItems: "end",
    "flex-wrap": "nowrap",
    alignItems: "end",
  },
  bundleInfo: {
    position: "absolute" as any,
    bottom: 14,
    left: 12,
    display: "flex",
    "flex-direction": "row",
    justifyContent: "end",
    width: "calc(100% - 24px)",
    alignItems: "end",
  },
  sampleTypes: {
    display: "flex",
    "flex-direction": "row",
    width: "80%",
    "flex-wrap": "wrap",
    gap: 4,
  },
  addToCartBtn: {
    background: primaryColor,
    borderRadius: 8,
    padding: 8,
    "&:hover": {
      background: "rgb(38, 137, 212)",
    },
    width: 33,
    height: 33,
  },
  removeFromCartBtn: {
    background: colors.blue[50],
    borderRadius: 8,
    border: `1px solid ${primaryColor}`,
    padding: 8,
    width: 33,
    height: 33,
  },
  labTestText: {
    display: "flex",
    "flex-direction": "row",
    justifyContent: "space-between",
    marginTop: 13,
    width: "100%",
    padding: "0 12px",
  },
  labTestTextTestName: {
    color: navy,
    fontWeight: 600,
    lineHeight: "135%",
    "word-break": "break-word",
  },
  labTestTextCompanyName: {
    color: colors.blueGray[500],
    fontSize: 14,
    lineHeight: "135%",
    marginTop: 6,
  },
  labTestTextPrice: {
    color: navy,
    fontWeight: 700,
    lineHeight: "135%",
    marginLeft: 3,
  },
  detailsBtn: {
    padding: "0 12px",
    width: "fit-content",
  },
})

const useStyles = makeStyles(styles)

interface Props {
  productId: string
  onClick: (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    queryType: string,
    productId: string
  ) => void
  onAddToCart: () => void
  cart: OrderIntent | null
  labshopLogo: string | null
  hidePhysServicesPricing: boolean
}

const StorefrontItemCard = ({
  productId,
  onClick,
  onAddToCart,
  cart,
  labshopLogo,
  hidePhysServicesPricing,
}: Props) => {
  const classes = useStyles()

  const product = useResourceByIdSelector("storefront_product", productId)
  const labTest = useResourceByIdSelector(
    "lab_test",
    product ? product.relationships.lab_test.data?.id : productId
  )
  const bundle = useResourceByIdSelector(
    "lab_test_bundle",
    product ? product.relationships.bundle.data?.id : productId
  )
  const labTests = useResourceSelector(
    (entities) =>
      bundle?.relationships.lab_tests.data.map(
        ({ id }) => entities?.lab_test?.[id]
      ) || []
  ) as StorefrontLabTestType[]
  const labCompany = useResourceByIdSelector(
    "lab_company",
    labTest?.relationships.lab_company.data?.id
  )
  const customFee = useResourceByIdSelector(
    "custom_fee",
    product?.relationships.custom_fee?.data?.id
  )

  const labTestTypes: (StorefrontLabTestTypeType | undefined)[] =
    useResourceSelector((entities) => {
      if (!labTest?.relationships.lab_test_types) {
        return []
      }

      return labTest?.relationships?.lab_test_types.data.map((ref) => {
        return entities?.lab_test_type?.[ref.id]
      })
    })

  const relationship = product
    ? "storefront_products"
    : labTest
    ? "lab_tests"
    : "bundles"

  let totalTestPrice = 0
  if (bundle) {
    totalTestPrice = calculateTotalTestPricing(
      hidePhysServicesPricing,
      labTests,
      customFee
    )
  } else if (labTest) {
    totalTestPrice = calculateTotalTestPricing(
      hidePhysServicesPricing,
      [labTest],
      customFee
    )
  }

  const inCart = useMemo(() => {
    return Boolean(
      cart?.relationships?.[relationship]?.data.some(
        (data) => data.id === productId
      )
    )
  }, [cart?.relationships?.[relationship]?.data])

  const handleOnAddToCart = (e) => {
    e.preventDefault()
    onAddToCart()
  }

  const handleOnCardClick = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    let queryType = ""
    if (relationship === "storefront_products") {
      queryType = "storefrontProduct"
    } else if (relationship === "lab_tests") {
      queryType = "labTest"
    } else {
      queryType = "bundle"
    }

    onClick(e, queryType, productId)
  }

  if (!product?.attributes.is_available) {
    return null
  }

  return (
    <div
      className={`${classes.content} item-card`}
      onClick={handleOnCardClick}
      id={`item-card-${productId}`}
    >
      <div
        className={classes.card}
        onClick={handleOnCardClick}
        id={`item-card-${productId}`}
      >
        <div className={classes.genericImageContainer}>
          <ProductImage
            productId={productId}
            width={undefined}
            labshopLogo={labshopLogo}
          />
          <div className={labTest ? classes.labTestInfo : classes.bundleInfo}>
            <div className={classes.sampleTypes}>
              {labTestTypes.map((ltt, idx) => (
                <SampleTypePill
                  key={ltt?.id || idx}
                  name={ltt?.attributes.name}
                  style={{ marginLeft: 0, width: "fit-content" }}
                />
              ))}
            </div>

            {!inCart ? (
              <IconButton
                onClick={handleOnAddToCart}
                className={classes.addToCartBtn}
              >
                <img src={AddWhiteIcon} alt="Add" />
              </IconButton>
            ) : (
              <IconButton
                onClick={handleOnAddToCart}
                className={classes.removeFromCartBtn}
              >
                <img src={CheckIcon} alt="Remove" />
              </IconButton>
            )}
          </div>
        </div>
      </div>
      <div>
        {labTest && (
          <div className={classes.labTestText}>
            <div>
              <BodyText className={classes.labTestTextTestName}>
                {labTest?.attributes.name}
              </BodyText>
              <BodyText className={classes.labTestTextCompanyName}>
                By: {labCompany?.attributes.name}
              </BodyText>
            </div>
            <div>
              <BodyText className={classes.labTestTextPrice}>
                {formatDollars(totalTestPrice)}
              </BodyText>
            </div>
          </div>
        )}
        {bundle && (
          <div className={classes.labTestText}>
            <div>
              <BodyText className={classes.labTestTextTestName}>
                {bundle?.attributes.name}
              </BodyText>
              <BodyText className={classes.labTestTextCompanyName}>
                Custom Bundle
              </BodyText>
            </div>
            <div>
              <BodyText className={classes.labTestTextPrice}>
                {formatDollars(totalTestPrice)}
              </BodyText>
            </div>
          </div>
        )}
        <div className={classes.detailsBtn}>
          <DesignSystemButton
            color="text"
            endIcon={
              <CarrotRightIcon
                fill={primaryColor}
                width={16}
                height={16}
                viewBox="0 -2 16 16"
              />
            }
          >
            Details
          </DesignSystemButton>
        </div>
      </div>
    </div>
  )
}

export default StorefrontItemCard
