import { useEffect, useState } from "react"

import { makeStyles } from "@material-ui/core"

import BodyText from "app/components/design-system/BodyText"
import DesignSystemButton from "app/components/design-system/Button"
import {
  Dialog,
  ModalActions,
  ModalContent,
  ModalHeader,
} from "app/components/modals"

const useStyles = makeStyles((theme) => ({
  contentContainer: {
    display: "flex",
    "flex-direction": "column",
    gap: 16,
    alignItems: "start",
    padding: "15px 6px",
  },
  btnContainer: {
    display: "flex",
    "flex-direction": "row",
    width: "100%",
    gap: 15,
  },
}))

interface Props {
  open: boolean
  onCloseModal: () => void
  confirmDelete: () => void
}

const RemoveStorefrontSectionModal = ({
  open,
  onCloseModal,
  confirmDelete,
}: Props) => {
  const classes = useStyles()

  const [loading, setLoading] = useState(false)

  const handleConfirmDelete = () => {
    setLoading(true)
    confirmDelete()
  }

  useEffect(() => {
    setLoading(false)
  }, [open])

  return (
    <Dialog open={open} fullWidth maxWidth="xs">
      <ModalHeader
        title="Delete Section"
        onClose={onCloseModal}
        ariaLabelId={"remove-section-modal-title"}
      />
      <ModalContent>
        <div className={classes.contentContainer}>
          <BodyText weight="semibold" color="textPrimary">
            Deleting this section will also remove lab tests contained within in
            (if any).
          </BodyText>
        </div>
      </ModalContent>
      <ModalActions>
        <div className={classes.btnContainer}>
          <DesignSystemButton
            onClick={onCloseModal}
            color="noaction"
            loading={false}
            fullWidth
          >
            Go Back
          </DesignSystemButton>
          <DesignSystemButton
            onClick={() => handleConfirmDelete()}
            color="destructivePrimary"
            loading={loading}
            fullWidth
          >
            Delete Section
          </DesignSystemButton>
        </div>
      </ModalActions>
    </Dialog>
  )
}

export default RemoveStorefrontSectionModal
