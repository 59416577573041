import { styled, Button as MuiButton } from "@material-ui/core"

import PriceTagIcon from "app/assets/icons/price-tag-dark.svg"
import lockIcon from "app/assets/images/lock.svg"
import BodyText from "app/components/design-system/BodyText"
import { LabTest } from "app/types"

const StyledBodyContainer = styled("div")({
  marginTop: 14,
  display: "flex",
  flexDirection: "row",
  "&:hover": {
    cursor: "auto",
  },
})

const IconImage = styled("img")({
  width: 17.42,
})
const StyledMuiButton = styled(MuiButton)({
  marginTop: 14,
  paddingLeft: 0,
  paddingTop: 0,
  paddingBottom: 0,
})

interface Props {
  isAuthenticated: boolean
  msrpPrice: LabTest["msrp_price"]
  rupaPrice: LabTest["rupa_price"]
  priceVariesByPanel?: boolean
}

const PriceSection = ({
  isAuthenticated,
  msrpPrice,
  rupaPrice,
  priceVariesByPanel,
}: Props) => {
  const UnauthedItem = (
    <StyledMuiButton href="https://www.rupahealth.com/sign-up">
      <IconImage src={lockIcon} alt="lock" />
      <BodyText className="pl-1.5" weight="semibold">
        Sign up to View
      </BodyText>
    </StyledMuiButton>
  )
  const AuthedItem = (
    <StyledBodyContainer>
      <IconImage src={PriceTagIcon} alt="pricetag" />
      {priceVariesByPanel ? (
        <BodyText className="pl-1.5" weight="semibold">
          Varies by panel
        </BodyText>
      ) : (
        <>
          {msrpPrice !== rupaPrice && (
            <BodyText
              className="pl-1.5 line-through text-gray-500"
              weight="semibold"
            >
              ${msrpPrice}
            </BodyText>
          )}
          <BodyText className="pl-1.5" weight="semibold">
            {`$${rupaPrice}`}
          </BodyText>
        </>
      )}
    </StyledBodyContainer>
  )
  return (
    <>
      <BodyText size="sm">Price</BodyText>
      {isAuthenticated ? AuthedItem : UnauthedItem}
    </>
  )
}

export default PriceSection
