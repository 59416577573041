import { useState, useCallback } from "react"
import { useDispatch } from "react-redux"

import moment from "moment"

import { Typography, Link, Dialog, Button, Divider } from "@material-ui/core"

import { API } from "app/api"
import ClockIcon from "app/assets/icons/clock.svg"
import PauseOrderImage from "app/assets/images/pause-order.svg"
import { PAUSE_DURATIONS } from "app/constants"
import { handleApiError } from "app/utils"
import { getOrderTypeLabel } from "app/utils/order-utils"

import RoundedContainer from "./RoundedContainer"

const pauseDurations = [
  {
    name: "2 weeks",
    duration: PAUSE_DURATIONS.TWO_WEEKS,
  },
  {
    name: "1 month",
    duration: PAUSE_DURATIONS.ONE_MONTH,
  },
  {
    name: "2 months",
    duration: PAUSE_DURATIONS.TWO_MONTHS,
  },
]

function PauseOrder({ checkoutToken, order, setCancelModalOpenWithOptions }) {
  const urlParams = new URLSearchParams(window.location.search)
  const [open, setOpen] = useState(urlParams.get("open_pause_modal") || false)
  const [prettyPausedDuration, setPrettyPausedDuration] = useState(null)
  const dispatch = useDispatch()

  const allowOrderDelayOrCancellation = !order.storefront_id

  const handlePauseOrder = useCallback(
    async (duration) => {
      try {
        await API.PatientCheckout.pause(checkoutToken, {
          duration: duration.duration,
        })
        setPrettyPausedDuration(duration.name)
      } catch (error) {
        dispatch(handleApiError(error))
      }
    },
    [checkoutToken]
  )

  if (order.date_unpause_scheduled) {
    const date_unpause_scheduled_formatted = moment(
      order.date_unpause_scheduled
    ).format("MMM Do, YYYY")

    return (
      <RoundedContainer>
        {`We’ll remind you about this order via email on ${date_unpause_scheduled_formatted}. You can also choose to pay for the order anytime before then.`}
      </RoundedContainer>
    )
  }

  const orderTypeLabel = getOrderTypeLabel(
    order.requires_vendor_physician_authorization
  ).toLowerCase()

  return (
    <>
      {allowOrderDelayOrCancellation && (
        <RoundedContainer>
          <Typography className="text-base15" color="textPrimary">
            Not ready to move forward?
          </Typography>
          <div className="underline inline">
            <Link
              onClick={() => setOpen(true)}
              className="font-semibold underline cursor-pointer"
              color="textPrimary"
            >
              Delay or cancel this {orderTypeLabel}.
            </Link>
          </div>
        </RoundedContainer>
      )}

      <Dialog
        onClose={() => {
          setOpen(false)

          if (prettyPausedDuration) {
            // When the patient closes the modal after pausing, refresh so that it redirects to the pause page.
            window.location.reload()
          }
        }}
        aria-labelledby="pause-order-title"
        aria-describedby="pause-order-description"
        open={open}
        fullWidth
        maxWidth="sm"
        scroll="body"
      >
        <div className="py-12 px-6 sm:px-16 flex flex-col items-center justify-center">
          <img src={PauseOrderImage} alt="" />
          {prettyPausedDuration ? (
            <>
              <Typography
                className="text-3xl mt-12 mb-6 text-center"
                variant="h4"
              >
                All set! We'll remind you in {prettyPausedDuration}.
              </Typography>
              <Typography className="text-base15 mb-6 text-center">
                Reach us at{" "}
                <Link color="primary" href="mailto:support@rupahealth.com">
                  support@rupahealth.com
                </Link>{" "}
                if we can support with anything in the meantime.
              </Typography>
            </>
          ) : (
            <>
              <Typography
                className="text-3xl mt-12 mb-6 text-center"
                variant="h4"
              >
                Should we re-send this {orderTypeLabel} at a later date?
              </Typography>
              <Typography className="text-base15 mb-6 text-center">
                If you’d like to have the option to get this testing in the
                future, let us send you a reminder in:
              </Typography>
              <div className="flex flex-col sm:flex-row items-stretch sm:items-center w-full">
                {pauseDurations.map((duration, index) => (
                  <Button
                    key={duration}
                    color="primary"
                    variant="contained"
                    startIcon={<img src={ClockIcon} alt="" />}
                    onClick={() => handlePauseOrder(duration)}
                    className={`flex-1 border border-solid border-gray-300 bg-white text-primary hover:bg-white hover:border-primary ${
                      index !== pauseDurations.length - 1 &&
                      "sm:mr-4 mb-4 sm:mb-0"
                    }`}
                  >
                    {duration.name}
                  </Button>
                ))}
              </div>
              <Divider className="mt-6 mb-4 w-full" />
              <Button
                color="primary"
                variant="text"
                onClick={() => setCancelModalOpenWithOptions({})}
              >
                No, let's just cancel my {orderTypeLabel}.
              </Button>
            </>
          )}
        </div>
      </Dialog>
    </>
  )
}

export default PauseOrder
