import { Button } from "@material-ui/core"

interface Props {
  surveyId: any
  buttonText: string
  beforeButtonText?: string
  afterButtonText?: string
}

const AutoSuggestSurveyRequest: React.FC<Props> = ({
  surveyId,
  buttonText,
  beforeButtonText,
  afterButtonText,
}) => {
  return (
    <div className="text-slate-500">
      {beforeButtonText}
      <Button
        onClick={() => window.Intercom("startSurvey", surveyId)}
        variant="text"
        className="text-primary font-semibold p-0 mx-1 align-baseline hover:bg-transparent"
      >
        {buttonText}
      </Button>
      {afterButtonText}
    </div>
  )
}

export default AutoSuggestSurveyRequest
