import * as React from "react"
import { MutableRefObject, useEffect, useRef } from "react"

import clsx from "clsx"
import { isEqual } from "lodash"
import { useIntersection } from "react-use"

import { CircularProgress } from "@material-ui/core"

import { ReactComponent as BiomarkerIcon } from "app/assets/icons/biomarker.svg"
import { ReactComponent as ReturnIcon } from "app/assets/icons/return.svg"
import { colors, primaryColor, textPrimaryColor } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"
import { Biomarker } from "types/biomarker"

const useStyles = makeAppStyles((theme) => ({
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(0.25),
  },
  header: {
    display: "flex",
    flexFlow: "row nowrap",
    maxHeight: 26,
    height: 26,
  },
  headerTitle: {
    flex: 1,
    color: textPrimaryColor,
    fontSize: 11,
    fontWeight: 600,
  },
  option: {
    width: "100%",
    display: "flex",
    flexFlow: "row nowrap",
    alignItems: "flex-start",
    padding: theme.spacing(1.25, 1.25),
    gap: theme.spacing(1.0),
    color: textPrimaryColor,
    borderRadius: 6,
    cursor: "pointer",
  },
  optionHighlighted: {
    backgroundColor: colors.blue[50],
    color: primaryColor,

    "&$optionCreating": {
      backgroundColor: colors.blueGray[100],
      color: textPrimaryColor,
    },
  },
  optionCreating: {
    cursor: "progress",
  },
  icon: {
    flex: "0 0 13px",
    marginTop: theme.spacing(0.5),
    width: 13,
    height: 13,
    fill: textPrimaryColor,
    "$optionHighlighted &": {
      fill: primaryColor,
    },
    "$optionCreating &": {
      fill: textPrimaryColor,
    },
  },
  info: {
    flex: "0 1 calc(90% - 13px)",
    display: "flex",
    flexFlow: "row wrap",
    gap: theme.spacing(1.0),
    [theme.breakpoints.up("md")]: {
      flexFlow: "row nowrap",
    },
  },
  infoText: {
    flex: "1 1 55%",
    display: "flex",
    flexFlow: "column nowrap",
    gap: 3,
  },
  infoTitle: {
    fontSize: 15,
    fontWeight: 600,
  },
  infoSubTitle: {
    fontSize: 13,
    fontWeight: 400,
  },
  infoGraphic: {
    flex: "1 1 100%",
    alignSelf: "center",
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0.0, 2.0, 0.0, 0.0),
    [theme.breakpoints.up("md")]: {
      flex: "0 1 45%",
      padding: theme.spacing(0.0, 1.0),
    },
  },
  addIndicator: {
    flex: "0 1 15%",
    alignSelf: "center",
    display: "inline-flex",
    alignItems: "center",
    gap: theme.spacing(1.5),
    fontSize: 15,
    fontWeight: 600,
    visibility: "hidden",
    "& svg": {
      fill: primaryColor,
    },
    "$optionCreating & svg": {
      fill: textPrimaryColor,
    },
    [theme.breakpoints.up("md")]: {
      flex: "0 1 10%",
    },
    "$optionHighlighted &": {
      visibility: "visible",
    },
  },
}))

const BiomarkerOption = ({
  isCreating,
  graphic,
  highlighted,
  innerRef,
  onClick,
  onHover,
  subTitle,
  title,
  actionText = "Add",
}: {
  isCreating?: boolean
  graphic?: React.ReactNode
  highlighted: boolean
  innerRef: MutableRefObject<HTMLDivElement | null>
  onClick: () => void
  onHover: () => void
  subTitle: React.ReactNode
  title: React.ReactNode
  actionText?: string
}) => {
  const classes = useStyles()

  return (
    <div
      className={clsx(classes.option, {
        [classes.optionCreating]: isCreating,
        [classes.optionHighlighted]: highlighted,
      })}
      onClick={!isCreating ? onClick : undefined}
      onMouseEnter={onHover}
      ref={innerRef}
    >
      <BiomarkerIcon className={classes.icon} viewBox="0 0 12 11" />

      <div className={classes.info}>
        <div className={classes.infoText}>
          <div className={classes.infoTitle}>{title}</div>
          <div className={classes.infoSubTitle}>{subTitle}</div>
        </div>
      </div>

      <div
        className={clsx(classes.addIndicator, {
          [classes.optionHighlighted]: highlighted,
        })}
      >
        <span>{actionText}</span>
        <ReturnIcon viewBox="0 0 17 14" />
      </div>
    </div>
  )
}

function AddBiomarkerSearchOption({
  highlighted,
  identifier,
  actionText,
  ...props
}: {
  highlighted: boolean
  identifier: Biomarker
  isCreating?: boolean
  actionText?: string
  onClick: () => void
  onHover: () => void
}) {
  const innerRef = useRef<HTMLDivElement>(null)

  /**
   * Tracks whether the option is in view. If it is not, we scroll it into view
   */
  const intersection = useIntersection(innerRef, {
    root: null,
    rootMargin: "0px",
    threshold: 1,
  })

  /**
   * If the option is highlighted and not in view, scroll it into view.
   */
  useEffect(() => {
    if (highlighted && intersection && intersection.intersectionRatio < 1) {
      innerRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      })
    }
  }, [highlighted])

  const biomarkerName =
    identifier?.attributes.long_name === identifier?.attributes.short_name
      ? identifier?.attributes.long_name
      : `${identifier?.attributes.long_name} (${identifier?.attributes.short_name})`

  return (
    <BiomarkerOption
      highlighted={highlighted}
      innerRef={innerRef}
      isCreating={props.isCreating}
      onClick={props.onClick}
      onHover={props.onHover}
      subTitle=""
      title={biomarkerName}
      actionText={actionText}
    />
  )
}

export default function AddBiomarkerSearchBiomarkerOptions({
  cursor,
  identifiers,
  isCreating,
  actionText,
  moveCursorTo,
  onSelect,
}: {
  cursor?: Biomarker
  identifiers: Biomarker[]
  isCreating?: boolean
  actionText?: string
  moveCursorTo: (identifier: Biomarker) => void
  onSelect: (identifier: Biomarker) => void
}) {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <span className={classes.headerTitle}>Markers in Results</span>

        {isCreating && <CircularProgress size={16} />}
      </div>

      {identifiers.map((identifier) => (
        <AddBiomarkerSearchOption
          key={identifier.id}
          highlighted={isEqual(identifier, cursor)}
          identifier={identifier}
          isCreating={isCreating}
          actionText={actionText}
          onClick={() => !isCreating && onSelect(identifier)}
          onHover={() => !isCreating && moveCursorTo(identifier)}
        />
      ))}
    </div>
  )
}
