import { CircularProgress, withStyles } from "@material-ui/core"
import Button from "@material-ui/core/Button"

const progressSize = 24

const ButtonProgress = withStyles({
  root: {
    position: "absolute",
    left: "50%",
    top: "50%",
    "margin-left": `-${progressSize / 2}px`,
    "margin-top": `-${progressSize / 2}px`,
  },
})(CircularProgress)

export const LoadingButton = ({ loading, children, ...props }) => {
  return (
    <div
      style={{ position: "relative", display: "inline-block" }}
      className={props.fullWidth ? "w-full" : ""}
    >
      <Button
        variant="contained"
        color="primary"
        {...props}
        disabled={loading || props.disabled}
      >
        {children}
      </Button>
      {loading && <ButtonProgress size={progressSize} />}
    </div>
  )
}
