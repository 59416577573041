import { Skeleton } from "@material-ui/lab"

import { colors } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"

const useAutoSuggestSuggestionsLoadingStyles = makeAppStyles((theme) => ({
  skeletonGroup: {
    width: "100%",
  },
  skeletonGroupContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    width: "100%",
  },
  skeletonSuggestionLink: {
    display: "flex",
    flexDirection: "row",
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(1),
  },
  skeletonSuggestionLinkIcon: {
    borderRadius: 3,
    marginRight: theme.spacing(1),
    backgroundColor: colors.blueGray[200],
  },
  skeletonRect: {
    borderRadius: 3,
    backgroundColor: colors.blueGray[200],
  },
}))

const SkeletonLink = ({
  classes,
  width,
}: {
  classes: ReturnType<typeof useAutoSuggestSuggestionsLoadingStyles>
  width: number
}) => (
  <div className={classes.skeletonSuggestionLink}>
    <Skeleton
      className={classes.skeletonSuggestionLinkIcon}
      variant="rect"
      animation="wave"
      height={12}
      width={12}
    />
    <Skeleton
      className={classes.skeletonRect}
      variant="rect"
      animation="wave"
      height={12}
      width={width}
    />
  </div>
)

export default function AutoSuggestSuggestionsLoading() {
  const classes = useAutoSuggestSuggestionsLoadingStyles()
  return (
    <div className={classes.skeletonGroup} data-testid="skeleton-group">
      <Skeleton
        className={classes.skeletonRect}
        variant="rect"
        animation="wave"
        height={12}
        width={100}
      />
      <div className={classes.skeletonGroupContent}>
        <SkeletonLink classes={classes} width={180} />

        <SkeletonLink classes={classes} width={120} />

        <SkeletonLink classes={classes} width={160} />
      </div>
    </div>
  )
}
