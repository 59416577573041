import { useMemo, useState } from "react"
import { useDispatch } from "react-redux"

import { Checkbox, FormControlLabel } from "@material-ui/core"

import Loading from "app/components/Loading"
import { ORDER_LINE_ITEM_TYPE } from "app/constants"
import useFeatureFlag, { FeatureFlag } from "app/hooks/use-feature-flag"
import { Order, OrderLineItem } from "app/types"
import makeAppStyles from "app/utils/makeAppStyles"

import * as Actions from "../store/actions/orders.actions"
import { FastingDisableModal } from "./FastingDisableModal"

const useStyles = makeAppStyles({
  checkbox: {
    padding: "20px 8px 16px 10px",
  },
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    height: "100%",
    "& .MuiFormControlLabel-label": {
      paddingTop: "2px",
    },
  },
})

interface FastingCheckboxProps {
  order: Order
  lineItems: OrderLineItem[]
}

export default function FastingCheckbox({
  order,
  lineItems,
}: FastingCheckboxProps) {
  const classes = useStyles()

  const [isFastingCheckboxInPracCartEnabled] = useFeatureFlag(
    FeatureFlag.FastingCheckboxInPracCart
  )

  const [isToggling, setIsToggling] = useState(false)
  const [showDisableFastingModal, setShowDisableFastingModal] = useState(false)

  const dispatch = useDispatch()

  const showFastingCheckbox =
    isFastingCheckboxInPracCartEnabled &&
    lineItems?.length > 0 &&
    lineItems.some(
      (lineItem) =>
        lineItem.type === ORDER_LINE_ITEM_TYPE.LAB_TEST &&
        // The panel must be a blood panel
        lineItem.ordered_test.lab_test.lab_test_types.some(
          (type) => type.phlebotomy_required
        ) &&
        // And the tests must require fasting
        lineItem.ordered_test.lab_test.requires_fasting
    )
  const testsRequiringFasting = useMemo(
    () =>
      lineItems.filter(
        (lineItem) =>
          lineItem.type === ORDER_LINE_ITEM_TYPE.LAB_TEST &&
          lineItem.ordered_test.lab_test.requires_fasting
      ),
    [lineItems]
  )
  const fastingCheckboxEnabled =
    showFastingCheckbox &&
    lineItems.some(
      (lineItem) =>
        lineItem.type === ORDER_LINE_ITEM_TYPE.LAB_TEST &&
        lineItem.ordered_test.requires_fasting
    )

  const toggleFasting = async (direction) => {
    setIsToggling(true)

    const ordered_tests = lineItems.map((lineItem) => lineItem.ordered_test)
    await dispatch(
      Actions.toggleFastingOnPanel(order.id, ordered_tests, direction)
    )
    setTimeout(() => {
      setIsToggling(false)
    }, 1000)
  }

  const continueButtonFastingModal = async () => {
    await toggleFasting(false)
    setShowDisableFastingModal(false)
    setIsToggling(false)
  }

  const toggleFastingCheckbox = async (val) => {
    if (!val.target.checked && testsRequiringFasting.length > 0) {
      setShowDisableFastingModal(true)
      setIsToggling(false)
      return
    }
    toggleFasting(val.target.checked)
  }

  if (!showFastingCheckbox) {
    return null
  }

  if (isToggling) {
    return <Loading ariaLabel="loading-fasting" />
  }

  return (
    <>
      <FormControlLabel
        className={classes.container}
        control={
          <Checkbox
            className={classes.checkbox}
            color="primary"
            size="small"
            checked={fastingCheckboxEnabled}
            onChange={toggleFastingCheckbox}
          />
        }
        label="Require Fasting"
      />
      <FastingDisableModal
        labTests={testsRequiringFasting}
        showModal={showDisableFastingModal}
        isToggling={isToggling}
        onExit={() => setShowDisableFastingModal(false)}
        onContinue={continueButtonFastingModal}
      />
    </>
  )
}
