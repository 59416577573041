import { ReactNode } from "react"

import { useController, useFormContext } from "react-hook-form"

import {
  FormControlLabel,
  Radio,
  RadioGroup,
  RadioGroupProps,
  RadioProps,
} from "@material-ui/core"

export type ControlledRadioGroupProps = RadioGroupProps & {
  labelClassName?: string
  inputClassName?: string
  infoText?: ReactNode
  label?: string
  defaultValue?: string
  name: string
  required?: boolean
  shouldUnregister?: boolean
  radioProps?: RadioProps
}

const ControlledBooleanRadioGroup = ({
  defaultValue,
  name,
  required,
  shouldUnregister,
  radioProps,
  ...radioGroupProps
}: ControlledRadioGroupProps) => {
  const { control } = useFormContext()
  const {
    field: { ref, value, onChange, ...controlProps },
  } = useController({
    control,
    defaultValue: defaultValue || "",
    name: `${name}` as const,
    shouldUnregister,
  })
  return (
    <RadioGroup {...radioGroupProps} {...controlProps}>
      <FormControlLabel
        checked={value === true}
        onChange={() => onChange(true)}
        control={<Radio inputRef={ref} {...radioProps} />}
        label="Yes"
      />
      <FormControlLabel
        checked={value === false}
        onChange={() => onChange(false)}
        control={<Radio inputRef={ref} {...radioProps} />}
        label="No"
      />
    </RadioGroup>
  )
}

export default ControlledBooleanRadioGroup
