import { useContext } from "react"

import TableRendererContext from "../../../contexts/TableRendererContext"

/**
 * Provides a callback from context to revalidate orders within the table.
 *
 * @returns a callback that causes the table to revalidate orders
 */
export default function useRevalidateOrders() {
  const { revalidateOrders } = useContext(TableRendererContext)

  return revalidateOrders
}
