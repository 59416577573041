import { useEffect, useState } from "react"

import axios from "axios"

import reloadService from "app/services/frontend-reload-service"

/**
 * Hook that initializes the frontend reloading service.
 *
 * @returns whether initialization is complete
 */
export default function useFrontendReload() {
  const [isInitialized, setIsInitialized] = useState(false)

  useEffect(() => {
    const deregisterInterceptors = reloadService.attachInterceptors(axios)
    setIsInitialized(true)

    return () => {
      deregisterInterceptors()
    }
  }, [])

  return isInitialized
}
