import * as Actions from "../actions"

const shareBundleReducer = function (state = {}, action) {
  switch (action.type) {
    case Actions.GET_SHARE_BUNDLE: {
      return action.payload
    }
    default:
      return state
  }
}

export default shareBundleReducer
