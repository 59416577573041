import { MutableRefObject, useEffect, useRef } from "react"
import * as React from "react"

import clsx from "clsx"
import { useIntersection } from "react-use"

import { ReactComponent as BiomarkerIcon } from "app/assets/icons/biomarker.svg"
import { ReactComponent as ReturnIcon } from "app/assets/icons/return.svg"
import NumericBiomarkerGraphic from "app/results-summary/components/ResultsSummaryEditor/components/BiomarkerRenderer/NumericBiomarkerGraphic"
import useCachedCollection from "app/swr/hooks/use-cached-collection"
import useCachedResource from "app/swr/hooks/use-cached-resource"
import { colors, primaryColor, textPrimaryColor } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"
import { DiscreteResult, DiscreteResultIdentifier } from "types/discrete-result"
import { Kit } from "types/kit"
import {
  OrderedBiomarker,
  OrderedBiomarkerIdentifier,
  OrderedBiomarkerLabTest,
} from "types/ordered-biomarker"
import { OrderedResult } from "types/ordered-result"

import { AnyBiomarkerResourceIdentifier } from "../../types"

const useStyles = makeAppStyles((theme) => ({
  option: {
    width: "100%",
    display: "flex",
    flexFlow: "row nowrap",
    alignItems: "flex-start",
    padding: theme.spacing(1.25, 1.25),
    gap: theme.spacing(1.0),
    color: textPrimaryColor,
    borderRadius: 6,
    cursor: "pointer",
  },
  optionHighlighted: {
    backgroundColor: colors.blue[50],
    color: primaryColor,

    "&$optionCreating": {
      backgroundColor: colors.blueGray[100],
      color: textPrimaryColor,
    },
  },
  optionCreating: {
    cursor: "progress",
  },
  icon: {
    flex: "0 0 13px",
    marginTop: theme.spacing(0.5),
    width: 13,
    height: 13,
    fill: textPrimaryColor,
    "$optionHighlighted &": {
      fill: primaryColor,
    },
    "$optionCreating &": {
      fill: textPrimaryColor,
    },
  },
  info: {
    flex: "0 1 calc(90% - 13px)",
    display: "flex",
    flexFlow: "row wrap",
    gap: theme.spacing(1.0),
    [theme.breakpoints.up("md")]: {
      flexFlow: "row nowrap",
    },
  },
  infoText: {
    flex: "1 1 55%",
    display: "flex",
    flexFlow: "column nowrap",
    gap: 3,
  },
  infoTitle: {
    fontSize: 15,
    fontWeight: 600,
  },
  infoSubTitle: {
    fontSize: 13,
    fontWeight: 400,
  },
  infoGraphic: {
    flex: "1 1 100%",
    alignSelf: "center",
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0.0, 2.0, 0.0, 0.0),
    [theme.breakpoints.up("md")]: {
      flex: "0 1 45%",
      padding: theme.spacing(0.0, 1.0),
    },
  },
  addIndicator: {
    flex: "0 1 15%",
    alignSelf: "center",
    display: "inline-flex",
    alignItems: "center",
    gap: theme.spacing(1.5),
    fontSize: 15,
    fontWeight: 600,
    visibility: "hidden",
    "& svg": {
      fill: primaryColor,
    },
    "$optionCreating & svg": {
      fill: textPrimaryColor,
    },
    [theme.breakpoints.up("md")]: {
      flex: "0 1 10%",
    },
    "$optionHighlighted &": {
      visibility: "visible",
    },
  },
}))

const BiomarkerOption = ({
  isCreating,
  graphic,
  highlighted,
  innerRef,
  onClick,
  onHover,
  subTitle,
  title,
}: {
  isCreating: boolean
  graphic?: React.ReactNode
  highlighted: boolean
  innerRef: MutableRefObject<HTMLDivElement | null>
  onClick: () => void
  onHover: () => void
  subTitle: React.ReactNode
  title: React.ReactNode
}) => {
  const classes = useStyles()

  return (
    <div
      className={clsx(classes.option, {
        [classes.optionCreating]: isCreating,
        [classes.optionHighlighted]: highlighted,
      })}
      onClick={!isCreating ? onClick : undefined}
      onMouseEnter={onHover}
      ref={innerRef}
    >
      <BiomarkerIcon className={classes.icon} viewBox="0 0 12 11" />

      <div className={classes.info}>
        <div className={classes.infoText}>
          <div className={classes.infoTitle}>{title}</div>
          <div className={classes.infoSubTitle}>{subTitle}</div>
        </div>

        {graphic && <div className={classes.infoGraphic}>{graphic}</div>}
      </div>

      <div
        className={clsx(classes.addIndicator, {
          [classes.optionHighlighted]: highlighted,
        })}
      >
        <span>{"Add"}</span>
        <ReturnIcon viewBox="0 0 17 14" />
      </div>
    </div>
  )
}

const OrderedBiomarkerOption = ({
  highlighted,
  identifier,
  innerRef,
  isCreating,
  onClick,
  onHover,
}: {
  isCreating: boolean
  highlighted: boolean
  identifier: OrderedBiomarkerIdentifier
  innerRef: MutableRefObject<HTMLDivElement | null>
  onClick: () => void
  onHover: () => void
}) => {
  const orderedBiomarker = useCachedResource<OrderedBiomarker>(identifier)
  const labTests = useCachedCollection<OrderedBiomarkerLabTest>(
    orderedBiomarker?.relationships.ordered_lab_tests.data
  )

  if (!orderedBiomarker) {
    return null
  }

  let subTitle: React.ReactNode = ""
  if (labTests.length > 0) {
    subTitle = labTests.map((labTest) => (
      <div key={labTest.id}>{labTest.attributes.name}</div>
    ))
  }
  return (
    <BiomarkerOption
      highlighted={highlighted}
      innerRef={innerRef}
      isCreating={isCreating}
      onClick={onClick}
      onHover={onHover}
      subTitle={subTitle}
      title={orderedBiomarker.attributes.long_name}
    />
  )
}

const DiscreteResultOption = ({
  highlighted,
  identifier,
  innerRef,
  isCreating,
  onClick,
  onHover,
}: {
  isCreating: boolean
  highlighted: boolean
  identifier: DiscreteResultIdentifier
  innerRef: MutableRefObject<HTMLDivElement | null>
  onClick: () => void
  onHover: () => void
}) => {
  const discreteResult = useCachedResource<DiscreteResult>(identifier)
  const orderedResult = useCachedResource<OrderedResult>(
    discreteResult?.relationships.ordered_result.data
  )
  const kit = useCachedResource<Kit>(orderedResult?.relationships.kit.data)
  const orderedBiomarker = useCachedResource<OrderedBiomarker>(
    discreteResult?.relationships.biomarker.data
  )

  if (!discreteResult) {
    return null
  }

  let title = discreteResult.attributes.name
  let graphic: React.ReactNode = null
  if (
    discreteResult.attributes.value_type === "numeric" &&
    discreteResult.attributes.value
  ) {
    if (discreteResult.attributes.value) {
      title += ` • ${discreteResult.attributes.value}`
    }

    if (discreteResult.attributes.units) {
      title += ` ${discreteResult.attributes.units}`
    }

    if (
      discreteResult.attributes.normal_max ||
      discreteResult.attributes.normal_min ||
      orderedBiomarker?.attributes.normal_max ||
      orderedBiomarker?.attributes.normal_min
    ) {
      graphic = (
        <NumericBiomarkerGraphic
          includeLabels={false}
          normalMax={
            discreteResult.attributes.normal_max !== ""
              ? discreteResult.attributes.normal_max
              : orderedBiomarker?.attributes.normal_max ?? ""
          }
          normalMin={
            discreteResult.attributes.normal_min !== ""
              ? discreteResult.attributes.normal_min
              : orderedBiomarker?.attributes.normal_min ?? ""
          }
          value={discreteResult.attributes.value}
        />
      )
    }
  }

  let subTitle = ""
  if (kit) {
    subTitle = kit.attributes.name
  }

  return (
    <BiomarkerOption
      graphic={graphic}
      highlighted={highlighted}
      innerRef={innerRef}
      isCreating={isCreating}
      onClick={onClick}
      onHover={onHover}
      subTitle={subTitle}
      title={title}
    />
  )
}

export default function AddBiomarkerSearchOption({
  highlighted,
  identifier,
  ...props
}: {
  highlighted: boolean
  identifier: AnyBiomarkerResourceIdentifier
  isCreating: boolean
  onClick: () => void
  onHover: () => void
}) {
  const innerRef = useRef<HTMLDivElement>(null)

  /**
   * Tracks whether the option is in view. If it is not, we scroll it into view
   */
  const intersection = useIntersection(innerRef, {
    root: null,
    rootMargin: "0px",
    threshold: 1,
  })

  /**
   * If the option is highlighted and not in view, scroll it into view.
   */
  useEffect(() => {
    if (highlighted && intersection && intersection.intersectionRatio < 1) {
      innerRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      })
    }
  }, [highlighted])

  /**
   * Render the appropriate option based on the identifier type.
   */
  switch (identifier.type) {
    case "ordered_biomarker":
      return (
        <OrderedBiomarkerOption
          {...props}
          highlighted={highlighted}
          identifier={identifier}
          innerRef={innerRef}
        />
      )
    case "discrete_result":
      return (
        <DiscreteResultOption
          {...props}
          highlighted={highlighted}
          identifier={identifier}
          innerRef={innerRef}
        />
      )
  }
}
