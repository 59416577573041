import { Divider } from "@material-ui/core"

import useFeatureFlag, { FeatureFlag } from "app/hooks/use-feature-flag"
import EditorLoadingError from "app/results-summary/components/ResultsSummaryEditor/components/EditorLoadingError"
import EditorLoadingPlaceholder from "app/results-summary/components/ResultsSummaryEditor/components/EditorLoadingPlaceholder"
import { UseInterpretationDraftHook } from "app/results-summary/hooks/use-interpretation-draft"
import { UseResultsVisualizerResourcesHook } from "app/results-summary/hooks/use-results-summary-resources"
import { colors } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"

import BiomarkersForm from "./components/BiomarkersForm"
import ContentOnlyForm from "./components/ContentOnlyForm"
import ContentTitleForm from "./components/ContentTitleForm"
import DownloadPDFButton from "./components/DownloadPDFButton"
import SendToPatientButton from "./components/SendToPatientButton"
import TasksForm from "./components/TasksForm"

const useStyles = makeAppStyles((theme) => ({
  editor: {
    maxWidth: 750,
    margin: "auto",
    display: "flex",
    flexFlow: "column nowrap",
    // Add extra bottom padding to ensure we give room for scrolling.
    padding: theme.spacing(3.0, 2.5, 16.0, 2.5),
    gap: theme.spacing(2.5),
  },
  divider: {
    background: colors.blueGray[100],
  },
}))

export type ResultsSummaryEditorProps = UseResultsVisualizerResourcesHook &
  UseInterpretationDraftHook

export default function ResultsSummaryEditor({
  initializationError,
  isDirty,
  isInitializing,
  isPatching,
  orderSwr,
  updateDraft,
  interpretationSwr,
  outOfRangeBiomarkers,
}: ResultsSummaryEditorProps) {
  const classes = useStyles()
  const { data: order } = orderSwr
  const { data: interpretation } = interpretationSwr

  const [isSnippetsRefactorEnabled] = useFeatureFlag(
    FeatureFlag.RefactoredSnippets
  )

  const loadingError =
    initializationError || orderSwr.error || interpretationSwr.error
  if (loadingError && (!order || !interpretation)) {
    return <EditorLoadingError error={loadingError} />
  }

  if (
    isInitializing ||
    interpretationSwr.isLoading ||
    !order ||
    !interpretation
  ) {
    return <EditorLoadingPlaceholder />
  }

  return (
    <div className={classes.editor}>
      <ContentTitleForm
        addLabel="Add Introduction"
        content={interpretation.attributes.content_intro}
        isDirty={isDirty}
        isPatching={isPatching}
        onChangeContent={(nextContent) =>
          updateDraft({
            attributes: {
              content_intro: nextContent,
            },
          })
        }
        onChangeTitle={(nextTitle) =>
          updateDraft({
            attributes: {
              title_intro: nextTitle,
            },
          })
        }
        title={interpretation.attributes.title_intro}
        showAddSnippetButton={isSnippetsRefactorEnabled}
      />

      <Divider className={classes.divider} />

      <ContentOnlyForm
        addLabel="Add Symptoms"
        content={interpretation.attributes.content_symptoms}
        isDirty={isDirty}
        isPatching={isPatching}
        onChangeContent={(nextContent) =>
          updateDraft({
            attributes: {
              content_symptoms: nextContent,
            },
          })
        }
        title="Symptoms"
      />

      <Divider className={classes.divider} />

      <BiomarkersForm
        order={order}
        outOfRangeBiomarkers={outOfRangeBiomarkers}
        interpretationSwr={interpretationSwr}
      />

      <Divider className={classes.divider} />

      <ContentTitleForm
        addLabel="Add Next Steps"
        content={interpretation.attributes.content_next_steps}
        isDirty={isDirty}
        isPatching={isPatching}
        onChangeContent={(nextContent) =>
          updateDraft({
            attributes: {
              content_next_steps: nextContent,
            },
          })
        }
        onChangeTitle={(nextTitle) =>
          updateDraft({
            attributes: {
              title_next_steps: nextTitle,
            },
          })
        }
        title={interpretation.attributes.title_next_steps}
        showAddSnippetButton={isSnippetsRefactorEnabled}
      />

      <Divider className={classes.divider} />

      <TasksForm interpretationSwr={interpretationSwr} />

      <Divider className={classes.divider} />

      <DownloadPDFButton interpretationSwr={interpretationSwr} />

      <SendToPatientButton
        interpretationSwr={interpretationSwr}
        order={order}
      />
    </div>
  )
}
