import { useState } from "react"

import { Container } from "@material-ui/core"
import {
  getCoreRowModel,
  getFilteredRowModel,
  isFunction,
  OnChangeFn,
  PaginationState,
  useReactTable,
} from "@tanstack/react-table"

import PageToolbar from "app/components/PageToolbar"
import RupaButton from "app/components/design-system/Button"
import useDebounceState from "app/hooks/use-debounce-state"
import useAppSelector from "app/hooks/useAppSelector"
import { getStartDraftText } from "app/utils"

import RupaTable from "../design-system/RupaTable/RupaTable"
import StartOrderModal from "../modals/StartOrderModal"
import AddAClientButtonAndModal from "./AddAClientButtonAndModal"
import ClientsListPlaceholder from "./ClientsListPlaceholder"
import {
  PatientNameColumn,
  PatientEmailColumn,
  PatientBirthdateColumn,
  PatientDetailsButtonColumn,
} from "./table/ClientsTableColumns"
import usePatientsCollection from "./usePatientsCollection"

const ClientsPage: React.FC = () => {
  const practitioner = useAppSelector(({ practitioner }) => practitioner)
  const startDraftText = getStartDraftText(practitioner)

  const [orderOpen, setOrderOpen] = useState(false)

  const [debouncedSearchTerm, searchTerm, setSearchTerm] = useDebounceState("")
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 20,
  })

  const onPaginationChange: OnChangeFn<PaginationState> = (updater) => {
    setPagination((prev) => (isFunction(updater) ? updater(prev) : updater))
  }

  // Calling mutate after saving a new patient will invalidate the collection and cause the cache to update, adding to the page immediately
  const { patients, isLoading, isValidating, meta, mutate } =
    usePatientsCollection({
      filters: { search: debouncedSearchTerm },
      pagination,
    })

  const count = meta?.pagination?.count
  const pageCount = count ? Math.ceil(count / pagination.pageSize) : -1

  // There are no patients to show and we're not limiting results by searching
  const noDataForTable =
    patients.length === 0 && !isLoading && !debouncedSearchTerm

  const columns = [
    PatientNameColumn,
    PatientEmailColumn,
    PatientBirthdateColumn,
    PatientDetailsButtonColumn,
  ]

  const table = useReactTable({
    data: patients,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getRowId: (row) => row.id,
    meta: { isLoading, isValidating, count },
    pageCount,
    manualPagination: true,
    onPaginationChange,
    state: { pagination },
  })

  return (
    <div>
      <PageToolbar title="Clients">
        <AddAClientButtonAndModal
          practitioner={practitioner}
          onSavePatient={mutate}
        />

        <RupaButton
          size="small"
          color="primary"
          onClick={() => setOrderOpen(true)}
        >
          {startDraftText}
        </RupaButton>

        <StartOrderModal open={orderOpen} onClose={() => setOrderOpen(false)} />
      </PageToolbar>

      <Container className="mt-6 mb-10" maxWidth="xl">
        {noDataForTable ? (
          <ClientsListPlaceholder onSavePatient={mutate} />
        ) : (
          <RupaTable
            table={table}
            searchTerm={searchTerm}
            onSearchTermChange={noDataForTable ? undefined : setSearchTerm}
            searchPlaceholder="Filter by name or email..."
          />
        )}
      </Container>
    </div>
  )
}

export default ClientsPage
