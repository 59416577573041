import { Fragment } from "react"

import { TableRow, TableCell } from "@material-ui/core"
import { cn } from "@rupahealth/design"

import BodyText from "app/components/design-system/BodyText"
import { BiologicalSex } from "types/lab-company-biomarker-range"

import DemographicOptimalRangeRow from "./DemographicOptimalRangeRow"
import useTableStyles from "./hooks/use-table-styles"

const DemographicOptimalRangeRows = ({
  groupedRangeKey,
  groupedRanges,
  numDemographicRanges,
}) => {
  const classes = useTableStyles()

  const getBiologicalSexKeyByValue = (
    value: string
  ): keyof BiologicalSex | null => {
    const entries = Object.entries(BiologicalSex) as [string, string][]
    for (let [key, val] of entries) {
      if (val === value) {
        return key as keyof BiologicalSex
      }
    }
    return null
  }

  return (
    <Fragment>
      <TableRow className={cn(classes.tableDataRow, "h-[20px] bg-slate-50")}>
        <TableCell colSpan={3} className="border-none py-0">
          <div className="rounded-lg bg-slate-200 p-2">
            <BodyText weight="semibold" size="sm">
              {getBiologicalSexKeyByValue(groupedRangeKey) || groupedRangeKey}
            </BodyText>
          </div>
        </TableCell>
      </TableRow>
      {groupedRanges[groupedRangeKey].map((optimalRange, index) => (
        <DemographicOptimalRangeRow
          key={index}
          currIndex={index}
          optimalRange={optimalRange}
          numDemographicRanges={numDemographicRanges}
        />
      ))}
    </Fragment>
  )
}

export default DemographicOptimalRangeRows
