import { useCallback, useState } from "react"
import { useDispatch } from "react-redux"

// This restricted import predates our decision to discontinue using formsy-react.
// We now opt to use react-hook-form instead.
// When introducing changes to this component, please consider refactoring to remove
// formsy-react and replace it with react-hook-form where applicable.
// eslint-disable-next-line no-restricted-imports
import Formsy from "formsy-react"

import { Dialog, Grid, Typography } from "@material-ui/core"

import { API } from "app/api"
import AlertIcon from "app/assets/icons/alert-triangle-gray.svg"
import { LoadingButton } from "app/components/LoadingButton"
import { SecondaryButton } from "app/components/SecondaryButton"
import { FormTextField } from "app/components/forms/fields"
import { ModalActions, ModalContent, ModalHeader } from "app/components/modals"
import { handleApiError } from "app/utils"
import { getOrderTypeLabel } from "app/utils/order-utils"

function CancelOrderModal({ checkoutToken, order, openWithOptions, onClose }) {
  const [valid, setIsValid] = useState(false)
  const [isCanceled, setIsCanceled] = useState(false)
  const [cancelPending, setCancelPending] = useState(false)
  const dispatch = useDispatch()

  const handleCancelOrder = useCallback(
    async ({ reason }) => {
      try {
        setCancelPending(true)
        await API.PatientCheckout.cancel(checkoutToken, {
          reason,
        })
        setIsCanceled(true)
      } catch (error) {
        dispatch(handleApiError(error))
      }

      setCancelPending(false)
    },
    [checkoutToken, setCancelPending]
  )

  const showAlertExplainingPracWillNeedToPlaceAnotherOrder =
    openWithOptions && !openWithOptions.cancellationReason

  return (
    <Dialog
      onClose={() => {
        onClose()

        // When the user closes the modal, if they have canceled, refresh the page so it redirects to canceled page.
        if (isCanceled) {
          window.location.reload()
        }
      }}
      aria-labelledby="cancel-order-modal-title"
      open={Boolean(openWithOptions)}
      fullWidth
      maxWidth="xs"
    >
      {isCanceled ? (
        <div className="px-6 pb-6">
          <Typography className="text-3xl mt-12 mb-6 text-center" variant="h4">
            {getOrderTypeLabel(order.requires_vendor_physician_authorization)}{" "}
            Canceled
          </Typography>
          <Typography className="text-base15 mb-6 text-center">
            We’ll let {order.practitioner.titled_full_name} know that you
            canceled your{" "}
            {getOrderTypeLabel(
              order.requires_vendor_physician_authorization
            ).toLowerCase()}
            .
          </Typography>
        </div>
      ) : (
        <Formsy
          onValidSubmit={({ reason }) => {
            handleCancelOrder({ reason })
          }}
          onValid={() => setIsValid(true)}
          onInvalid={() => setIsValid(false)}
        >
          <ModalHeader id="patient-modal-title" title="Cancel Order" />

          <ModalContent>
            <div className="bg-white p-4 rounded">
              <Grid container spacing={2} direction={"column"}>
                <Grid item>
                  <FormTextField
                    name="reason"
                    value={openWithOptions?.cancellationReason || ""}
                    label={`Let ${order.practitioner.titled_full_name} know why you’d like to cancel your order.`}
                    placeholder={`This will be shared with ${order.practitioner.titled_full_name}`}
                    multiline={true}
                    rows={4}
                  />
                </Grid>
                {showAlertExplainingPracWillNeedToPlaceAnotherOrder && (
                  <Grid item>
                    <div className="flex items-start rounded px-4 py-3  border border-gray-400 bg-gray-90">
                      <img src={AlertIcon} alt="" className="mr-2 mt-2" />
                      <Typography className="text-xs font-500 font-semibold">
                        If you cancel, {order.practitioner.titled_full_name}{" "}
                        will need to place another{" "}
                        {getOrderTypeLabel(
                          order.requires_vendor_physician_authorization
                        ).toLowerCase()}{" "}
                        for you to receive these tests.
                      </Typography>
                    </div>
                  </Grid>
                )}
              </Grid>
            </div>
          </ModalContent>

          <ModalActions>
            <SecondaryButton className="px-4" onClick={onClose}>
              Go Back
            </SecondaryButton>
            <LoadingButton
              variant="contained"
              loading={cancelPending}
              // TODO: Design system - update to delete color
              className="ml-3 bg-red-700 hover:bg-red-900"
              type="submit"
              disabled={!valid}
            >
              Cancel{" "}
              {getOrderTypeLabel(order.requires_vendor_physician_authorization)}
            </LoadingButton>
          </ModalActions>
        </Formsy>
      )}
    </Dialog>
  )
}

export default CancelOrderModal
