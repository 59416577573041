import { useCallback, useEffect, useMemo, useState } from "react"
import { useDispatch } from "react-redux"

import { useHistory } from "react-router-dom"
import { useLocation, useMount } from "react-use"

import * as Actions from "app/store/actions"
import { OptionType } from "app/types"

import {
  FILTER_NAMES,
  FilterNames,
  NonFilterNames,
  OnResetFilter,
  OnSelectFilterOption,
  OnSetQueryParams,
  QUERY_NAMES,
  Query,
  QueryMap,
  QueryNames,
  QueryParam,
} from "../types"
import useFiltersById from "./use-filters-by-id"

function isValidQueryName(queryName: string): queryName is QueryNames {
  return QUERY_NAMES.has(queryName as QueryNames)
}

function isValidFilterName(filterName: string): filterName is FilterNames {
  return FILTER_NAMES.has(filterName as FilterNames)
}

interface UseLabTestQueryHook {
  // An array of QueryParam, each of which represents a single query option with
  // the interface: {label: string, value: string, queryName}. If it's
  // null, it means we need to load it from the URL but not all params
  // had a label, so we're waiting for the filters to load.
  query: Query | null
  // A map derived from `query` that makes it easy to lookup the query by queryName.
  queryMap: QueryMap
  // This controls if the next query change should set the query as pending.
  shouldSetPending: boolean
  // A handler that selects an option for thegiven filter within the query (filters
  // are a subset of all query params).
  onSelectFilterOption: OnSelectFilterOption
  // A handler to set a non-filter query param.
  onSetQueryParams: OnSetQueryParams
  // A handler that resets a given filter.
  onResetFilter: OnResetFilter
}

function getQueryFromLocation(
  location: ReturnType<typeof useLocation>,
  filtersById: ReturnType<typeof useFiltersById>
) {
  let newQuery: Query = []

  const searchQuery = new URLSearchParams(location.search)

  for (const [queryName, valueWithLabel] of searchQuery) {
    if (!isValidQueryName(queryName)) {
      continue
    }

    if (FILTER_NAMES.has(queryName as FilterNames)) {
      try {
        let [value, label] = valueWithLabel.split("|")
        if (!label) {
          if (!filtersById) {
            // If the filters haven't loaded yet, we just return.
            // An effect will run again when the filters have
            // loaded and will call this function. This means that if
            // all the params in the URL have a label, this condition
            // won't be hit, the `query` will be set and so we won't
            // be delaying rendering.
            return null
          }

          label = filtersById[value]
        }

        newQuery.push({
          queryName,
          value,
          label,
        })
      } catch (error) {
        // Invalid filter option
        continue
      }
    } else {
      newQuery.push({
        queryName,
        value: valueWithLabel,
        label: null,
      })
    }
  }

  return newQuery
}

/*
 * Provides all the state and handlers required to render build the filtering UI.
 */
function useLabTestQuery(syncWithUrl: boolean): UseLabTestQueryHook {
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const filtersById = useFiltersById()

  // The query can either be an array of query params or null. If it's
  // null, it means we need to load it from the URL but the query params
  // in the URL didn't have a label, so we need to wait for the
  // filters to load. This will be handled in the `useEffect` below.
  const [query, setQuery] = useState<Query | null>(() => {
    // If we don't sync with the URL, immediately set the query as
    // we don't need to load anything.
    if (!syncWithUrl) {
      return []
    }

    // Otherwise we'll try to grab it from the URL
    return getQueryFromLocation(location, filtersById)
  })

  // This controls if the next query change should set the query as pending.
  const [shouldSetPending, setShouldSetPending] = useState(true)

  // Fetch filters. We need this to load labels for query params if they
  // aren't supplied in the URL. `use-filter-config.tsx` uses them too.
  useMount(() => {
    dispatch(Actions.getLabCompanyList())
    dispatch(Actions.getLabTestTypeList())
    dispatch(Actions.getHealthCategoryList())
  })

  // Sync the URL with our query on mount
  useEffect(() => {
    // If we don't sync from the URL or the query is already available,
    // we don't need to grab it again from the URL.
    if (!syncWithUrl || query) {
      return
    }

    // Grab the query from the URL now that the filters may have
    // been loaded.
    const newQuery = getQueryFromLocation(location, filtersById)
    if (newQuery) {
      setQuery(newQuery)
    }
  }, [syncWithUrl, location, query, setQuery, filtersById])

  // Sync our query with the URL
  useEffect(() => {
    if (!syncWithUrl || !query) {
      return
    }

    const searchParams = new URLSearchParams(location.search)

    // Delete any existing supported params, but leaving unsupported
    // params in tact.
    for (const queryName of QUERY_NAMES) {
      if (searchParams.has(queryName)) {
        searchParams.delete(queryName)
      }
    }

    query.forEach((query) => {
      let serializedParam = query.value

      if (query.label) {
        serializedParam = `${serializedParam}|${query.label}`
      }

      searchParams.append(query.queryName, serializedParam)
    })

    history.replace({
      search: `?${searchParams.toString()}`,
    })
  }, [query])

  // Create a map from the query for easy lookup
  const queryMap: QueryMap = useMemo(() => {
    const _queryMap: QueryMap = {
      filters: {
        lab_company: new Map(),
        lab_test_type: new Map(),
        phlebotomy_required: new Map(),
        favorites: new Map(),
        health_category: new Map(),
        biomarker: new Map(),
        lab_test: new Map(),
        allowed_in_state: new Map(),
        signing_practitioner: new Map(),
      },
      order_by: undefined,
      order_direction: undefined,
      page: undefined,
      search: undefined,
      shipping_state: undefined,
      clinic_state: undefined,
      clinic_country: undefined,
      using_physician_services: undefined,
      order_id: undefined,
      action: undefined,
    }

    if (!query) {
      return _queryMap
    }

    for (const [index, filter] of query.entries()) {
      if (isValidFilterName(filter.queryName)) {
        _queryMap.filters[filter.queryName].set(filter.value, {
          filterName: filter.queryName,
          value: filter.value,
          label: filter.label as string,
          index,
        })
      } else if (isValidQueryName(filter.queryName)) {
        _queryMap[filter.queryName] = {
          queryName: filter.queryName,
          value: filter.value,
          label: filter.label as string,
          index,
        }
      }
    }

    return _queryMap
  }, [query])

  // A handler to make selecting a filter more easy
  const onSelectFilterOption = useCallback(
    (filterName: FilterNames, option: OptionType, isRadio: boolean) => {
      if (!query) {
        return
      }

      let newQuery = [...query]
      const { label, value } = option

      const filtersForType = queryMap.filters[filterName]
      const existingFilter = filtersForType.get(value)
      const indicesToRemove: Set<number> = new Set()
      const queryParamsToPush: QueryParam[] = []

      // Always reset the page
      if (queryMap.page?.index !== undefined) {
        indicesToRemove.add(queryMap.page.index)
      }

      if (existingFilter) {
        indicesToRemove.add(existingFilter.index)
      } else {
        if (isRadio) {
          for (const filter of filtersForType.values()) {
            indicesToRemove.add(filter.index)
          }

          queryParamsToPush.push({ value, label, queryName: filterName })
        } else {
          queryParamsToPush.push({ value, label, queryName: filterName })
        }
      }

      if (indicesToRemove.size) {
        newQuery = newQuery.filter((_, index) => !indicesToRemove.has(index))
      }

      for (const queryParam of queryParamsToPush) {
        newQuery.push(queryParam)
      }

      setShouldSetPending(true)
      setQuery(newQuery)
    },
    [query, queryMap, setQuery]
  )

  // Resets either a single filter type or all filters
  const onResetFilter = useCallback(
    (filterName?: FilterNames) => {
      if (!query) {
        return
      }

      let newQuery = [...query]

      if (filterName) {
        const indicesToRemove = new Set(
          Array.from(queryMap.filters[filterName].values()).map(
            (filter) => filter.index
          )
        )

        newQuery = newQuery.filter((_, index) => !indicesToRemove.has(index))
      } else {
        newQuery = []
      }

      setQuery(newQuery)
      setShouldSetPending(true)
    },

    [query, queryMap, setQuery]
  )

  // A handler to make selecting a non-filter query param more easy
  const onSetQueryParams = useCallback(
    (
      params: { queryName: NonFilterNames; value: string }[],
      shouldSetPending: boolean = true
    ) => {
      if (!query) {
        return
      }

      let newQuery = [...query]
      const indicesToRemove: Set<number> = new Set()
      const queryParamsToPush: QueryParam[] = []

      for (const { queryName, value } of params) {
        const existingParam = queryMap[queryName]

        if (existingParam) {
          indicesToRemove.add(existingParam.index)
        }

        if (value) {
          queryParamsToPush.push({ value, label: null, queryName })
        }
      }

      if (indicesToRemove.size) {
        newQuery = newQuery.filter((_, index) => !indicesToRemove.has(index))
      }

      for (const queryParam of queryParamsToPush) {
        newQuery.push(queryParam)
      }

      setShouldSetPending(shouldSetPending)
      setQuery(newQuery)
    },
    [query, queryMap, setQuery]
  )

  return {
    query,
    queryMap,
    shouldSetPending,
    onSelectFilterOption,
    onSetQueryParams,
    onResetFilter,
  }
}

export default useLabTestQuery
