import { useState } from "react"

import { isEqual } from "lodash"

import RichTextEditor, { getInitialValue } from "app/components/RichTextEditor"
import RichTextRenderer from "app/components/RichTextRenderer"
import DisplayText from "app/components/design-system/DisplayText"
import EditorFormPlaceholder from "app/results-summary/components/ResultsSummaryEditor/components/EditorFormPlaceholder"
import { bodyFontFamilyImportant, colors } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"
import { EditorState } from "types/editor-state"

import FormSection from "./FormSection"

const useStyles = makeAppStyles(() => ({
  title: {
    color: colors.blueGray[500],
    fontSize: 13,
    fontFamily: bodyFontFamilyImportant,
    textTransform: "uppercase",
  },
}))

export interface ContentOnlyFormProps {
  addLabel: string
  content: EditorState
  isDirty?: boolean
  isPatching?: boolean
  onChangeContent: (content: EditorState) => void
  title: string
}

export default function ContentOnlyForm({
  addLabel,
  content,
  isDirty = false,
  isPatching = false,
  onChangeContent,
  title,
}: ContentOnlyFormProps) {
  const classes = useStyles()
  const [isEditing, setIsEditing] = useState(false)
  const hasEmptyState = isEqual(content, getInitialValue())
  return (
    <FormSection
      disableEditButton={isEditing && (isPatching || isDirty)}
      isEditing={isEditing}
      onToggleEditing={() => setIsEditing(!isEditing)}
      showEditButton={!hasEmptyState || isEditing}
      title={
        <DisplayText
          className={classes.title}
          size="base"
          variant="h4"
          weight="semibold"
        >
          {title}
        </DisplayText>
      }
    >
      {isEditing ? (
        <RichTextEditor onChange={onChangeContent} value={content} />
      ) : hasEmptyState ? (
        <EditorFormPlaceholder
          addLabel={addLabel}
          onClick={() => setIsEditing(true)}
          type="content"
        />
      ) : (
        <RichTextRenderer>{content}</RichTextRenderer>
      )}
    </FormSection>
  )
}
