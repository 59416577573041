import { Link } from "@material-ui/core"

import BodyText from "app/components/design-system/BodyText"
import { colors } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"
import { BiomarkerCustomDescription } from "types/biomarker-custom-description"

const useStyles = makeAppStyles(() => ({
  disclaimerText: {
    color: colors.blueGray[500],
  },
  underlined: {
    textDecoration: "underline !important",
    color: colors.blueGray[500],
  },
}))

interface Props {
  biomarkerCustomDescription?: BiomarkerCustomDescription
  clinicName?: string
  isOptimalRangePresent: boolean
}

const DiscreteResultDetailLegalDisclaimers = ({
  biomarkerCustomDescription,
  clinicName,
  isOptimalRangePresent,
}: Props) => {
  const classes = useStyles()

  return (
    <div className="mt-4 flex flex-col gap-2">
      <BodyText size="xxs" className={classes.disclaimerText}>
        Descriptions by{" "}
        {biomarkerCustomDescription ? clinicName : "Rupa Health"}.{" "}
        {isOptimalRangePresent ? (
          <>Optimal Range by {clinicName ? clinicName : "Rupa Health"}. </>
        ) : (
          ""
        )}
        These descriptions {isOptimalRangePresent ? "and optimal ranges" : ""}{" "}
        are for informational purposes only and are not personalized medical
        advice.{" "}
        {isOptimalRangePresent ? (
          <>
            These ranges are developed through research and clinical experience
            to offer insight into overall well-being.{" "}
            <Link
              className={classes.underlined}
              href="https://www.rupahealth.com/optimal-ranges"
              target="_blank"
              onClick={(e) => e.stopPropagation()}
            >
              Learn more
            </Link>
            .
          </>
        ) : (
          ""
        )}
      </BodyText>
      <BodyText size="xxs" className={classes.disclaimerText}>
        Consult your healthcare provider for any health-related questions,
        decisions, or concerns. Rupa Health makes no warranty or representation
        with respect to the content, completeness, reliability, accuracy, or
        availability of these descriptions and optimal ranges.
      </BodyText>
    </div>
  )
}

export default DiscreteResultDetailLegalDisclaimers
