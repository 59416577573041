import { makeStyles, Divider } from "@material-ui/core"

import QuestChooseProvideInsurance from "app/assets/images/patient-instructions/quest-choose-provide-insurance-now.png"
import QuestAllOtherTestReason from "app/assets/images/patient-instructions/quest-reason-all-other-tests-selection.png"
import QuestSelectReason from "app/assets/images/patient-instructions/quest-select-reason.png"
import QuestWhichTestingSelection from "app/assets/images/patient-instructions/quest-what-testing-selection.png"
import BodyText from "app/components/design-system/BodyText"
import { colors, primaryColor } from "app/theme"
import { LabCompanySchedulingData } from "app/types"

const useStyles = makeStyles((theme) => ({
  schedulingDetails: {
    padding: 23,
    backgroundColor: colors.blue[50],
    border: `1px solid ${primaryColor}`,
    borderRadius: 8,
    display: "flex",
    flexDirection: "column",
    gap: 5,
  },
  schedulingDivider: {
    marginTop: 5,
    marginBottom: 20,
  },
  requiresFastingText: {
    paddingBottom: 10,
  },
  imageContainer: {
    backgroundColor: "white",
    padding: 5,
    borderRadius: 8,
    marginTop: 10,
    marginBottom: 15,
    textAlign: "center",
  },
  alreadyPaidImage: {
    [theme.breakpoints.up("sm")]: {
      maxWidth: 400,
    },
  },
  introText: {
    paddingBottom: 25,
  },
  headerDivider: {
    marginTop: 30,
    marginBottom: 30,
  },
  headerText: {
    paddingBottom: 20,
  },
  link: {
    color: primaryColor,
    fontWeight: 600,
  },
  modalConfirmationText: {
    marginBottom: 10,
  },
}))

function CashPayDetails() {
  const classes = useStyles()
  return (
    <>
      <div className={classes.requiresFastingText}>
        <BodyText weight="semibold" size="sm">
          💳 Choose “Purchased a test from Quest”
        </BodyText>
        <div className={classes.imageContainer}>
          <img
            className={classes.alreadyPaidImage}
            src={QuestSelectReason}
            alt="Quest select reason"
          />
        </div>
      </div>
      <BodyText weight="semibold" size="sm">
        ✔️ Choose “All other tests”
      </BodyText>
      <div className={classes.imageContainer}>
        <img
          className={classes.alreadyPaidImage}
          src={QuestWhichTestingSelection}
          alt="Quest select which testing you ordered"
        />
      </div>
    </>
  )
}

function InsurancePayDetails() {
  const classes = useStyles()
  return (
    <>
      <BodyText weight="semibold" size="sm">
        Choose “All Other Tests”
      </BodyText>
      <div className={classes.imageContainer}>
        <img
          className={classes.alreadyPaidImage}
          src={QuestAllOtherTestReason}
          alt="Quest visit reason all other tests"
        />
      </div>
      <BodyText weight="semibold" size="sm">
        Choose “I’ll save time and provide it now”
      </BodyText>
      <div className={classes.imageContainer}>
        <img
          className={classes.alreadyPaidImage}
          src={QuestChooseProvideInsurance}
          alt="Quest choose provide insurance now"
        />
      </div>
    </>
  )
}

export function ScheduleQuestDetails({
  data,
  showHeader,
}: {
  data: LabCompanySchedulingData
  showHeader: boolean
}) {
  const classes = useStyles()
  return (
    <>
      {showHeader && (
        <>
          <Divider className={classes.headerDivider} />
          <BodyText weight="semibold" size="lg" className={classes.headerText}>
            Blood Draw at Quest
          </BodyText>
        </>
      )}
      <BodyText size="sm" className={classes.introText}>
        You'll need to go to a Quest location to get your blood drawn. You can
        walk into a local Quest clinic or schedule an appointment
        {data.should_show_schedule_link ? (
          <>
            {" "}
            <a
              target="_blank"
              rel="noreferrer"
              href={data.scheduling_url}
              className={classes.link}
            >
              here
            </a>
          </>
        ) : null}
        .
      </BodyText>
      <div className={classes.schedulingDetails}>
        <BodyText weight="semibold" size="md">
          Key details for scheduling your blood draw:
        </BodyText>
        <Divider className={classes.schedulingDivider} />

        {data.uses_insurance ? <InsurancePayDetails /> : <CashPayDetails />}
        <BodyText weight="semibold" size="sm">
          ✅ Confirm your selection.
        </BodyText>
        <BodyText size="sm" className={classes.modalConfirmationText}>
          There will be a confirmation modal to confirm that you have already
          purchased your tests, choose yes.
        </BodyText>
        <BodyText weight="semibold" size="sm">
          Remember to bring your ID
          {data.uses_insurance ? ", insurance card," : null} and have your{" "}
          {data.requisition_form_url ? (
            <u>
              <a
                target="_blank"
                rel="noreferrer"
                href={data.requisition_form_url}
              >
                digital requisition
              </a>
            </u>
          ) : (
            "digital requisition"
          )}{" "}
          ready to show at the time of your appointment.
        </BodyText>
      </div>
    </>
  )
}
