import { ReactComponent as ChevronRight } from "app/assets/icons/patient-portal/chevron-right.svg"
import BloodDrawIcon from "app/assets/images/patient-portal/blood-draw-icon.svg"
import InsuranceIcon from "app/assets/images/patient-portal/insurance-icon.svg"
import ShippingIcon from "app/assets/images/patient-portal/support-icon.svg"
import DesignSystemButton, {
  ButtonProps,
} from "app/components/design-system/Button"
import DisplayText from "app/components/design-system/DisplayText"
import { navy, primaryColor } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"

import Card from "../Card"
import MessageSupport from "../MessageSupport"
import PatientPortalFooterCard from "./PatientPortalFooterCard"

const SHIPPING_TRACKING_URL =
  "https://support.rupahealth.com/articles/8552020-shipping-tracking-faq"
const BLOOD_TESTS_URL =
  "https://support.rupahealth.com/collections/6796347-navigating-your-blood-tests"
const SUPERBILLS_URL =
  "https://support.rupahealth.com/articles/8551983-submitting-your-receipt-superbill-to-insurance"

const useStyles = makeAppStyles((theme) => ({
  heading: {
    fontFamily: "Josefin Sans",
    fontSize: 24,
    fontWeight: 400,
  },
  resourceCardContainer: {
    display: "flex",
    justifyContent: "center",
    gap: 24,
    marginBottom: 44,
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  buttonOverwrite: {
    color: navy,
  },
  faqContainer: {
    maxWidth: 732,
    width: "100%",
    marginTop: 35,
    marginBottom: 44,
  },
}))

const PatientPortalOrderQuickLinks = ({
  title = "Quick Links",
  messageSupportButtonColor = "secondary",
}: {
  title?: string
  messageSupportButtonColor?: ButtonProps["color"]
}) => {
  const classes = useStyles()

  return (
    <Card className="flex flex-col gap-5">
      <div className="flex flex-col gap-2 sm:justify-between sm:items-center sm:flex-row">
        <DisplayText size="lg" weight="semibold">
          {title}
        </DisplayText>
        <MessageSupport messageSupportButtonColor={messageSupportButtonColor} />
      </div>
      <div className="flex flex-col w-full gap-4 sm:flex-row sm:items-stretch">
        <div className="sm:flex-1">
          <PatientPortalFooterCard
            icon={<img src={ShippingIcon} alt="" />}
            actionButton={
              <DesignSystemButton
                color="text"
                variant="text"
                endIcon={
                  <ChevronRight fill={primaryColor} viewBox="0 0 10 15" />
                }
                className={classes.buttonOverwrite}
              >
                Shipping & Tracking
              </DesignSystemButton>
            }
            onClick={() =>
              window.Intercom
                ? window.Intercom("showArticle", 8552020)
                : window.open(SHIPPING_TRACKING_URL, "_blank")
            }
          />
        </div>
        <div className="sm:flex-1">
          <PatientPortalFooterCard
            icon={<img src={BloodDrawIcon} alt="" />}
            actionButton={
              <DesignSystemButton
                color="text"
                variant="text"
                endIcon={
                  <ChevronRight viewBox="0 0 10 15" fill={primaryColor} />
                }
                className={classes.buttonOverwrite}
              >
                Navigating Blood Tests
              </DesignSystemButton>
            }
            onClick={() => window.open(BLOOD_TESTS_URL, "_blank")}
          />
        </div>
        <div className="sm:flex-1">
          <PatientPortalFooterCard
            icon={<img src={InsuranceIcon} alt="" />}
            actionButton={
              <DesignSystemButton
                color="text"
                variant="text"
                endIcon={
                  <ChevronRight viewBox="0 0 10 15" fill={primaryColor} />
                }
                className={classes.buttonOverwrite}
              >
                Superbills & Insurance
              </DesignSystemButton>
            }
            onClick={() =>
              window.Intercom
                ? window.Intercom("showArticle", 8551983)
                : window.open(SUPERBILLS_URL, "_blank")
            }
          />
        </div>
      </div>
    </Card>
  )
}

export default PatientPortalOrderQuickLinks
