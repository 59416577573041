import * as React from "react"
import { useDispatch } from "react-redux"

import { Button, cn } from "@rupahealth/design"
import { CellContext } from "@tanstack/table-core"

import { resendRegistrationEmail } from "app/auth/store/actions/user.actions"
import RupaLoadingButton from "app/components/RupaLoadingButton"
import Tooltip from "app/components/Tooltip"
import BodyText from "app/components/design-system/BodyText"
import RupaButton from "app/components/design-system/Button"
import useAppSelector from "app/hooks/useAppSelector"
import { selectPractitioner } from "app/store/selectors/practitioner.selectors"
import useCachedResource from "app/swr/hooks/use-cached-resource"
import { Practitioner } from "app/types"
import { DashboardOrder } from "types/dashboard/dashboard-order"
import { DashboardPractitioner } from "types/dashboard/dashboard-practitioner"

import useSendActivationLinks from "./use-send-activation-links"

const VerifyAccountTooltip = ({
  children,
  order,
}: {
  children: React.ReactElement
  order: DashboardOrder
}) => {
  const orderPractitioner = useCachedResource<DashboardPractitioner>(
    order.relationships.practitioner?.data
  )
  const currentPractitioner = useAppSelector(selectPractitioner)
  if (!orderPractitioner) {
    return children
  } else if (orderPractitioner.id === currentPractitioner?.id) {
    return (
      <VerifyYourAccountTooltip
        practitioner={currentPractitioner}
        children={children}
      />
    )
  }
  return (
    <VerifyOtherAccountTooltip
      order={order}
      practitioner={orderPractitioner}
      children={children}
    />
  )
}

const VerifyOtherAccountTooltip = ({
  children,
  order,
  practitioner,
}: {
  children: React.ReactElement
  order: DashboardOrder
  practitioner: DashboardPractitioner
}) => {
  const { isSending, sendActivationLinks } = useSendActivationLinks()

  return (
    <Tooltip
      arrow
      interactive
      title={
        <>
          <BodyText align="center" size="xs">
            {`${practitioner.attributes.titled_full_name} needs to log in to Rupa and verify their account.`}
          </BodyText>
          <RupaLoadingButton
            type="button"
            variant="ghost"
            className="text-primary text-sm py-0 px-1 h-auto rounded-md mt-1 whitespace-break-spaces"
            loading={isSending}
            onClick={() =>
              sendActivationLinks(order.id, practitioner.attributes.email)
            }
          >
            {`Send ${practitioner.attributes.email} a link to log in`}
          </RupaLoadingButton>
        </>
      }
    >
      <div>{children}</div>
    </Tooltip>
  )
}

const VerifyYourAccountTooltip = ({
  children,
  practitioner,
}: {
  practitioner: Practitioner
  children: React.ReactElement
}) => {
  const dispatch = useDispatch()
  const onClickVerifyEmail = () => {
    dispatch(resendRegistrationEmail({ email: practitioner.user.email }))
  }
  return (
    <Tooltip
      arrow
      interactive
      title={
        <>
          <BodyText align="center" size="xs">
            You need to verify your email address to unblock this order.
          </BodyText>
          <Button
            type="button"
            variant="ghost"
            className="text-primary text-sm py-0 px-1 h-auto rounded-md mt-1"
            onClick={onClickVerifyEmail}
          >
            Resend verification email
          </Button>
        </>
      }
    >
      <div>{children}</div>
    </Tooltip>
  )
}

export default function VerifyAccountButton({
  className,
  row,
}: CellContext<DashboardOrder, unknown> & { className: string }) {
  return (
    <VerifyAccountTooltip order={row.original}>
      <RupaButton
        className={cn(className, "cursor-help")}
        color="secondary"
        disabled
        type="button"
      >
        Verify Account
      </RupaButton>
    </VerifyAccountTooltip>
  )
}
