import { useCallback, useMemo } from "react"

import { isEqual } from "lodash"
import { useSWRConfig } from "swr"

import { isSubscriptionCache } from "app/swr/helpers/cache"
import { getResourceCacheKey } from "app/swr/helpers/keys"
import { Resource, ResourceIdentifier } from "app/swr/types"
import { useSyncExternalStore } from "use-sync-external-store/shim"

/**
 * Accesses a resource object from the cache by the given identifier.
 *
 * Best used once data is fetched from a useResourceSWR hook, for example:
 *
 * ```typescript
 * const { data: labTest } = useResourceSWR<LabTest>("/lab_tests/123", { include: ["lab_company"] })
 *
 * // Access the included lab company from the cache.
 * const labCompany = useCachedResource<LabCompany>(labTest.relationships.lab_company.data)
 * ```
 *
 * @param identifier the resource identifier
 * @returns the resource object from cache, if it exists
 */
export default function useCachedResource<R extends Resource>(
  identifier?: ResourceIdentifier
) {
  const { cache } = useSWRConfig()

  const key = getResourceCacheKey(identifier)

  const getSnapshot = useMemo(() => {
    const getNewSnapshot = () => {
      return cache.get(key)?.data
    }

    let memorizedSnapshot = getNewSnapshot()

    return () => {
      const snapshot = getNewSnapshot()
      return isEqual(snapshot, memorizedSnapshot)
        ? memorizedSnapshot
        : (memorizedSnapshot = snapshot)
    }
  }, [cache, key])

  const cached: R | undefined = useSyncExternalStore(
    useCallback(
      (callback) => {
        if (isSubscriptionCache(cache) && key) {
          return cache.subscribe(key, (prev, current) => {
            if (!isEqual(prev, current)) {
              callback()
            }
          })
        }
      },
      [cache, key]
    ),
    getSnapshot,
    getSnapshot
  )

  return cached
}
