import RupaHealthLogoDark from "app/assets/images/logos/rupa-health-dark.svg"
import RupaHealthMark from "app/assets/images/logos/rupa-logo-mark.svg"

interface Props {
  showText?: boolean
  className?: string
}

const Logo = ({ showText, className }: Props) =>
  showText ? (
    <img src={RupaHealthLogoDark} alt="logo" className={className} />
  ) : (
    <img src={RupaHealthMark} alt="logo" className={className} />
  )

export default Logo
