import { useCallback, useEffect, useMemo } from "react"

import clsx from "clsx"

import { Button, ButtonGroup, useMediaQuery } from "@material-ui/core"

import useQueryState from "app/hooks/use-query-state"

export interface PageTab {
  id: string
  LabelIcon?: JSX.Element
  LabelIconAfter?: JSX.Element
  label: string
  // Tab will only be shown if set to true
  show: boolean | undefined
}

interface Props {
  tabs: PageTab[]
  // the default tab id to use if the component loads and {queryParam} is undefined
  defaultTabId: string
  // the url param name to use for the active tab. Defaults to "tab"
  queryParam?: string
  // other params to strip from the url when the active tab changes
  paramsToRemoveOnTabChange?: string[]
}

const PageTabs: React.FC<Props> = ({
  tabs,
  defaultTabId,
  queryParam = "tab",
  paramsToRemoveOnTabChange = [],
}) => {
  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down("sm"))

  // extra params to remove from the url when the active tab changes
  const extraStateOnTabChange = useMemo(() => {
    return paramsToRemoveOnTabChange.reduce(
      (acc, param) => ({ ...acc, [param]: undefined }),
      {}
    )
  }, [paramsToRemoveOnTabChange])

  const [{ [queryParam]: activeTab }, setQueryState] = useQueryState(
    ({ [queryParam]: tab, ...query }) => ({
      [queryParam]: tab,
      ...query,
    }),
    (state) => state
  )

  const handleTabChange = useCallback(
    (tabId: string) => {
      setQueryState((state) => ({
        ...state,
        ...extraStateOnTabChange,
        [queryParam]: tabId,
      }))
    },
    [setQueryState, extraStateOnTabChange, queryParam]
  )

  useEffect(() => {
    for (const tab of tabs) {
      // If tab.show is undefined, we the tab won't be shown but we won't redirect to the default tab
      // This allows the show prop to be determined by a feature flag that may not be loaded yet
      if (tab.id === activeTab && tab.show !== false) return
    }
    setQueryState((state) => ({ ...state, [queryParam]: defaultTabId }))
  }, [tabs, activeTab, queryParam, defaultTabId])

  const shownTabs = useMemo(() => tabs.filter((t) => t.show), [tabs])

  return (
    <ButtonGroup
      variant="outlined"
      orientation={isMobile ? "vertical" : "horizontal"}
      className={clsx("bg-white rounded-lg", { "w-full": isMobile })}
      disableRipple
    >
      {shownTabs.map(({ id: tabId, label, LabelIcon, LabelIconAfter }) => (
        <Button
          key={tabId}
          onClick={() => handleTabChange(tabId)}
          className={clsx(
            "whitespace-nowrap border-slate-200 text-body px-6 py-2 shadow-sm rounded-lg hover:bg-primary hover:bg-opacity-5 hover:shadow-none",
            {
              "text-primary bg-primary bg-opacity-5": tabId === activeTab,
            }
          )}
        >
          <div className="flex items-center gap-x-2">
            {LabelIcon}
            <span>{label}</span>
            {LabelIconAfter}
          </div>
        </Button>
      ))}
    </ButtonGroup>
  )
}

export default PageTabs
