import { isEmpty, isNil } from "lodash"

import { ReactComponent as ShippingBoxIcon } from "app/assets/icons/shipping-box-dark.svg"
import {
  ComparisonRow,
  ComparisonColumnProps,
  EmptyComparisonValue,
} from "app/main/comparison/components/ComparisonLabTests/components"
import useAnyComparedLabTestHasProperty from "app/main/comparison/hooks/use-any-compared-lab-test-has-property"
import { pluralizeDays } from "app/main/comparison/utils"

const ShippingTimeColumn = ({ labTest }: ComparisonColumnProps) => {
  if (!labTest) {
    return <EmptyComparisonValue />
  }
  const { lab_company, shipping_days_max, shipping_days_min } = labTest
  const { shipping_carrier } = lab_company
  const hasMax = !isNil(shipping_days_max)
  const hasMin = !isNil(shipping_days_min)
  const hasCarrier = !isEmpty(shipping_carrier)
  if (!hasMax && !hasMin && !hasCarrier) {
    return <EmptyComparisonValue />
  }

  let shippingTimeText: string
  if (hasMax && hasMin) {
    if (shipping_days_max !== shipping_days_min) {
      shippingTimeText = `${shipping_days_min} - ${shipping_days_max} days`
    } else {
      shippingTimeText = `${shipping_days_min} ${pluralizeDays(
        shipping_days_min
      )}`
    }
  } else if (hasMax) {
    shippingTimeText = `${shipping_days_max} ${pluralizeDays(
      shipping_days_max
    )}`
  } else if (hasMin) {
    shippingTimeText = `${shipping_days_min} ${pluralizeDays(
      shipping_days_min
    )}`
  } else {
    shippingTimeText = "Varies"
  }

  return (
    <>
      {hasCarrier
        ? `${shippingTimeText}, ${shipping_carrier}`
        : shippingTimeText}
    </>
  )
}

export default function ComparisonShippingTimeRow() {
  const hasShippingTime = useAnyComparedLabTestHasProperty(
    "shipping_days_max",
    "shipping_days_min",
    "lab_company.shipping_carrier"
  )
  if (!hasShippingTime) {
    return null
  }
  return (
    <ComparisonRow
      Column={ShippingTimeColumn}
      headerIcon={<ShippingBoxIcon viewBox="0 0 15 16" />}
      headerLabel="Shipping Time"
    />
  )
}
