import {
  getCoreRowModel,
  getFilteredRowModel,
  useReactTable,
} from "@tanstack/react-table"

import { useIOKInventory } from "./InventoryDataProvider"
import {
  ActionsColumn,
  InventoryNameColumn,
  LabCompanyNameColumn,
  StockColumn,
} from "./InventoryTableColumns"
import InventoryTableRenderer from "./InventoryTableRenderer"

const InventoryTable: React.FC = () => {
  const { filteredData, isLoading, isValidating } = useIOKInventory()

  const columns = [
    InventoryNameColumn,
    LabCompanyNameColumn,
    StockColumn,
    ActionsColumn,
  ]

  const table = useReactTable({
    data: filteredData,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getRowId: (row) => row.in_office_kit_product_id,
    meta: { isLoading, isValidating },
  })

  return <InventoryTableRenderer table={table} />
}

export default InventoryTable
