import { useMemo } from "react"

import { useRefWidth } from "app/hooks/use-ref-width"

import { FilterNames } from "../types"

const MAX_FILTER_WIDTH = 200

// The order in which they will appear
const orderedFilterNames: FilterNames[] = [
  "health_category",
  "lab_company",
  "signing_practitioner",
  "lab_test_type",
  "phlebotomy_required",
  "favorites",
  "allowed_in_state",
]

export const useVisibleFilters = (
  filterNames: Set<FilterNames>,
  containerRef: React.RefObject<HTMLDivElement>
): Set<FilterNames> => {
  const width = useRefWidth(containerRef)

  const orderedFilters = useMemo(() => {
    return orderedFilterNames.filter((f) => filterNames.has(f))
  }, [filterNames])

  const numVisible = useMemo(() => {
    if (width === undefined) return filterNames.size
    const numVisible = Math.floor(width / MAX_FILTER_WIDTH)
    return numVisible >= filterNames.size ? filterNames.size : numVisible - 1
  }, [width, filterNames.size])

  const visibleFilters = useMemo(() => {
    if (numVisible >= orderedFilters.length) {
      return new Set(orderedFilters)
    }
    return new Set(orderedFilters.slice(0, numVisible))
  }, [orderedFilters, numVisible])

  return visibleFilters
}
