import { forwardRef, useRef, useLayoutEffect, useState } from "react"

export default forwardRef<
  HTMLTextAreaElement | null,
  React.ComponentPropsWithoutRef<"textarea">
>((props, forwardedRef) => {
  const ref = useRef<HTMLTextAreaElement | null>(null)
  const [value, setValue] = useState(() => props.value)

  useLayoutEffect(() => {
    if (ref.current) {
      let minHeight: number
      try {
        // Calculate the minimum height based on the line height and the number of rows.
        const lineHeightPx = window
          .getComputedStyle(ref.current)
          .getPropertyValue("line-height")
        minHeight = parseInt(lineHeightPx) * (ref.current.rows || 3)
      } catch (error) {
        minHeight = 32
      }

      ref.current.style.height = "inherit"
      ref.current.style.height = `${Math.max(
        ref.current.scrollHeight,
        minHeight
      )}px`
    }
  }, [value])

  return (
    <textarea
      {...props}
      ref={(elem) => {
        ref.current = elem

        if (forwardedRef) {
          if (typeof forwardedRef === "function") {
            forwardedRef(elem)
          } else {
            forwardedRef.current = elem
          }
        }
      }}
      onInput={(event) => setValue(event.currentTarget.value)}
      style={{ resize: "none", overflow: "hidden" }}
    />
  )
})
