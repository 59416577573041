import { forwardRef, ComponentProps } from "react"

import * as DropdownMenu from "@radix-ui/react-dropdown-menu"

import { ReactComponent as AddWhiteIcon } from "app/assets/icons/add-white.svg"
import Button from "app/components/design-system/Button"
import { colors, primaryColor } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"

const useStyles = makeAppStyles((theme) => ({
  addTaskButton: {
    display: "flex",
    alignItems: "center",
    "& .MuiButton-startIcon": {
      margin: theme.spacing(0.0, 0.5, 0.0, 0.0),
    },
  },
  addIcon: {
    height: 16,
    width: 16,
    padding: 3,
    backgroundColor: primaryColor,
    fill: "white",
    borderRadius: "50%",
    verticalAlign: "middle",
    "$addTaskButton:disabled &": {
      backgroundColor: colors.blueGray[300],
    },
  },
}))

export default forwardRef<HTMLButtonElement, ComponentProps<typeof Button>>(
  function ToggleAddTaskButton(props, ref) {
    const classes = useStyles()
    return (
      <DropdownMenu.Trigger asChild ref={ref}>
        <Button
          {...props}
          className={classes.addTaskButton}
          color="text"
          startIcon={
            <AddWhiteIcon className={classes.addIcon} viewBox="0 0 15 16" />
          }
          type="button"
        >
          Add Task
        </Button>
      </DropdownMenu.Trigger>
    )
  }
)
