import { Practitioner, RootState } from "app/types"

export function selectPractitioner(state: RootState): Practitioner | undefined {
  /*
   * The practitioner reducer uses an empty object for its initial state. This makes
   * client code that uses a practitioner have to manually use `isEmpty` rather than more
   * simply checking for truthiness. We should change the reducer to use undefined as its
   * initial state, but until we do that refactor, this selector can be used instead.
   */
  const practitioner = state.practitioner
  if ("id" in practitioner) {
    return practitioner
  }

  return
}
