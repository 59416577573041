import clsx from "clsx"

import { Box, Grid, Theme, makeStyles } from "@material-ui/core"

import BodyText from "app/components/design-system/BodyText"
import { FormFieldLabel } from "app/components/forms/fields"
import {
  ControlledSelectField,
  ControlledTextField,
} from "app/main/patient-checkout/fields"
import useCachedCollection from "app/swr/hooks/use-cached-collection"
import useCollectionSWR from "app/swr/hooks/use-collection-swr"
import useResourceSWR from "app/swr/hooks/use-resource-swr"
import { navy } from "app/theme"

import PractitionerProfilePricingSelect from "./PractitionerProfilePricingSelect"
import ControlledBooleanRadioGroup from "./form-components/ControlledBooleanRadioGroup"
import { WebflowFieldOption } from "./types"
import { INSURANCE_INSURANCE_ACCEPTED } from "./utils"

const styles = (theme: Theme) => ({
  headerText: {
    marginBottom: theme.spacing(4.25),
  },
  label: {
    marginTop: theme.spacing(2),
  },
  fieldSet: {
    width: "100%",
    marginRight: theme.spacing(2),
    display: "flex",
    "flex-direction": "column",
    marginBottom: theme.spacing(2),
  },
  noSelection: {
    // Placeholder text color
    "& .MuiSelect-select.MuiSelect-select": {
      color: navy,
      opacity: 0.5,
    },
  },
  formRow: {
    marginBottom: theme.spacing(4.25),
  },
  formRowLast: {
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      marginBottom: theme.spacing(1),
    },
  },
  gridContainer: {
    alignItems: "end",
  },
})

const useStyles = makeStyles(styles)

interface Props {
  pricingSelectField: any
  pricingSelectFormState: any
  insuranceAcceptedField: any
}

const InsuranceTypeField = ({ selectedInsuranceOptionId }) => {
  const classes = useStyles()
  const { data: selectedInsuranceOption } = useResourceSWR<WebflowFieldOption>(
    "/webflow_field_options/" + selectedInsuranceOptionId
  )

  return selectedInsuranceOption?.attributes?.key ===
    INSURANCE_INSURANCE_ACCEPTED ? (
    <Grid item xs={12} md={12} className={classes.formRowLast}>
      <FormFieldLabel label="Insurance Type" required />
      <ControlledTextField
        className={classes.fieldSet}
        name="insurance_type"
        placeholder="Describe the type of insurance you accept"
        defaultValue=""
        required
      />
    </Grid>
  ) : null
}

export default function PractitionerProfilePricing({
  pricingSelectField,
  pricingSelectFormState,
  insuranceAcceptedField,
}: Props) {
  const classes = useStyles()

  const { data: insuranceOptionsIdentifiers } = useCollectionSWR<any>(
    "/webflow_field_options/",
    {
      params: {
        "filter[field_slug]": "insurance",
      },
    }
  )

  const insuranceOptions = useCachedCollection<any>(
    insuranceOptionsIdentifiers ?? []
  )

  return (
    <div>
      <BodyText size="lg" weight="semibold" className={classes.headerText}>
        Pricing
      </BodyText>
      <Box className={classes.label}>
        <Grid container className={classes.gridContainer}>
          <Grid item xs={12} md={12} className={classes.formRow}>
            <PractitionerProfilePricingSelect
              pricingSelectField={pricingSelectField}
              pricingSelectFormState={pricingSelectFormState}
            />
          </Grid>
          <Grid item xs={12} md={12} className={classes.formRow}>
            <FormFieldLabel
              label={
                <BodyText
                  weight="semibold"
                  size="base"
                  style={{ float: "left", marginRight: 4 }}
                >
                  Do you offer a free initial consultation call to potential
                  clients?"
                </BodyText>
              }
              required
            />
            <ControlledBooleanRadioGroup
              row
              name="has_free_initial_patient_consulation"
              defaultValue={undefined}
              required
              radioProps={{ color: "primary" }}
            />
          </Grid>
          <Grid item xs={12} md={12} className={classes.formRow}>
            <FormFieldLabel
              required
              label={
                <BodyText
                  weight="semibold"
                  size="base"
                  style={{ float: "left", marginRight: 4 }}
                >
                  Pricing For Clients
                </BodyText>
              }
            />
            <ControlledTextField
              className={classes.fieldSet}
              name="pricing_for_clients"
              placeholder="A short sentence or two about your pricing."
              defaultValue=""
              required={true}
            />
          </Grid>
          <Grid item xs={12} md={12} className={classes.formRowLast}>
            <FormFieldLabel
              label={
                <BodyText
                  weight="semibold"
                  size="base"
                  style={{ float: "left", marginRight: 4 }}
                >
                  Insurance
                </BodyText>
              }
              required
            />
            <ControlledSelectField
              className={clsx(classes.fieldSet, {
                [classes.noSelection]: !insuranceAcceptedField.value,
              })}
              name="insurance_accepted"
              placeholder="Cash-Only, or Insurance Accepted"
              required
            >
              {insuranceOptions
                .map((option) => (
                  <option key={option.id} value={option.id}>
                    {option.attributes.field_name}
                  </option>
                ))
                .reverse()}
            </ControlledSelectField>
          </Grid>
          {insuranceAcceptedField.value && (
            <InsuranceTypeField
              selectedInsuranceOptionId={insuranceAcceptedField.value}
            />
          )}
        </Grid>
      </Box>
    </div>
  )
}
