import { useMemo } from "react"

import { pick } from "lodash"
import { useForm } from "react-hook-form"

import { zodResolver } from "@hookform/resolvers/zod"

import { loadDate } from "app/components/forms/initialization"
import { PatientCheckoutFormData, PatientPortalOrder } from "app/types"

import createSchema from "../validation/create-schema"

export default function useCheckoutForm(
  order: PatientPortalOrder,
  beastCoastEnabled: Boolean,
  orderHasInsuranceTests: Boolean
) {
  const defaultValues = useDefaultValues(order)

  const schema = useMemo(() => {
    return createSchema(order, beastCoastEnabled, orderHasInsuranceTests)
  }, [order, beastCoastEnabled, orderHasInsuranceTests])

  return useForm<PatientCheckoutFormData>({
    defaultValues,
    mode: "onTouched",
    resolver: zodResolver(schema),
  })
}

function useDefaultValues(order: PatientPortalOrder): PatientCheckoutFormData {
  return useMemo(() => {
    const patient = order.patient
    const { insurance, user } = patient

    const email = user.email.includes("dev+labshop_patient") ? "" : user.email

    const defaultValues: PatientCheckoutFormData = {
      birthday: patient.birthday ? loadDate(patient.birthday) : null,
      consent: false,
      email: email || "",
      first_name: patient.first_name || "",
      biological_sex: patient.biological_sex || "",
      last_name: patient.last_name || "",
      phone_number: patient.phone_number || "",
      default_shipping_address: pick(
        patient.default_shipping_address,
        "city",
        "state",
        "street_1",
        "street_2",
        "zipcode"
      ) || {
        city: "",
        state: "",
        street_1: "",
        street_2: "",
        zipcode: "",
      },
      sample_collection_state: order.sample_collection_state || "",
      guardian_first_name: order.guardian_first_name,
      guardian_last_name: order.guardian_last_name,
    }
    // override the shipping address state with billing_state if defined and not prac pay
    if (order.billing_state && !order.is_practitioner_paying) {
      defaultValues.default_shipping_address.state = order.billing_state
    }

    if (order.use_insurance) {
      if (insurance) {
        defaultValues.insurance = insurance
        defaultValues.insurance.subscribers_birthday = loadDate(
          insurance.subscribers_birthday
        )
      } else {
        defaultValues.insurance = {
          provider: "",
          subscribers_birthday: null,
          subscribers_first_name: "",
          subscribers_id_number: "",
          subscribers_last_name: "",
          subscribers_relationship: "",
        }

        if (!order.patient_has_medicare) {
          defaultValues.insurance = {
            ...defaultValues.insurance,
            claims_city: "",
            claims_country: "US",
            claims_phone_number: "",
            claims_state: "",
            claims_street: "",
            claims_zipcode: "",
            group_number: "",
          }
        }
      }
    }

    if (order.requires_vendor_physician_authorization) {
      defaultValues.symptom_attestation = false
    }

    if (order.flags.CHECK_SCARLET_ELIGIBILITY) {
      defaultValues.scarlet = {
        blood_draw_zipcode: order.scarlet_zipcode || "",
      }
    }

    return defaultValues
  }, [order])
}
