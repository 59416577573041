import { KeyboardEvent, MouseEvent, useEffect, useRef } from "react"

import clsx from "clsx"

import { ReactComponent as CloseIcon } from "app/assets/icons/close-box.svg"
import FilterIcon from "app/components/search/FilterIcon"
import { colors, navy } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"

const useQueryTokenStyles = makeAppStyles<{ focused: boolean }>((theme) => ({
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: theme.spacing(0.875, 1.25),
    borderRadius: 46,
    fontSize: 14,
    fontWeight: 600,
    backgroundColor: ({ focused }) =>
      focused ? colors.blueGray[300] : colors.blueGray[200],
    color: navy,
  },
  filterIcon: {
    marginRight: theme.spacing(1),
    width: 15,
    height: 15,
  },
  removeIconButton: {
    marginLeft: theme.spacing(1),
    padding: 0,
    margin: 0,
    display: "inline-flex",
  },
}))

export interface QueryTokenProps {
  className?: string
  focused: boolean
  focusPrev: () => void
  focusNext: () => void
  label: string
  onBlur: () => void
  onFocus: () => void
  onRemove: () => void
  type: string
  nonRemovable?: boolean
}

export default function QueryToken({
  className,
  focused,
  focusNext,
  focusPrev,
  label,
  onBlur,
  onFocus,
  onRemove,
  type,
  nonRemovable,
}: QueryTokenProps) {
  const classes = useQueryTokenStyles({ focused })
  const buttonRef = useRef<HTMLButtonElement>(null)

  useEffect(() => {
    if (focused) {
      buttonRef.current?.focus()
    }
  }, [focused])

  function handleClick(e: MouseEvent<HTMLDivElement>) {
    e.preventDefault()

    // ensure we focus when clicking the token itself
    onFocus()
  }

  function handleClickRemove(e: MouseEvent<HTMLButtonElement>) {
    e.preventDefault()

    onRemove()
  }

  function handleKeyDown(e: KeyboardEvent<HTMLButtonElement>) {
    if (e.key === "Escape" || e.key === "Backspace") {
      e.preventDefault()

      onRemove()
    } else if (e.key === "ArrowLeft") {
      e.preventDefault()

      focusPrev()
    } else if (e.key === "ArrowRight") {
      e.preventDefault()

      focusNext()
    } else if (e.key === "Enter") {
      // disable enter key when focused from causing a deletion
      e.preventDefault()
    }
  }
  return (
    <div
      className={clsx(className, classes.container)}
      onClick={handleClick}
      tabIndex={-1}
    >
      <FilterIcon className={classes.filterIcon} alt={label} type={type} />
      {label}
      {!nonRemovable && (
        <button
          className={classes.removeIconButton}
          onBlur={onBlur}
          onClick={handleClickRemove}
          onFocus={onFocus}
          onKeyDown={handleKeyDown}
          ref={buttonRef}
          type="button"
        >
          <CloseIcon
            fill={focused ? colors.blueGray[500] : colors.blueGray[400]}
            className="mt-[1px]"
          />
        </button>
      )}
    </div>
  )
}
