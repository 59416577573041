import clsx from "clsx"

import { Theme } from "@material-ui/core"

import { colors } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"

import {
  STICKY_TABLE_COLUMN_WIDTH,
  STICKY_TABLE_COLUMN_WIDTH_MOBILE,
} from "../constants/constants"

const useStyles = makeAppStyles((theme: Theme) => ({
  stickyCol: {
    left: 0,
    position: "absolute",
    top: "auto",
    width: STICKY_TABLE_COLUMN_WIDTH,
    textAlign: "left !important" as any,
    boxShadow: "5px 0 20px -1px rgba(0, 0, 0, 0.05)",
  },
  stickyColHeader: {
    // This use of negative margin and padding is to make the primary body system
    // header row appear as if it is 12px wider than the table body.
    marginLeft: -12,
    paddingLeft: 12,
    paddingRight: 12,
    width: STICKY_TABLE_COLUMN_WIDTH + 12,
    [theme.breakpoints.down(640)]: {
      width: STICKY_TABLE_COLUMN_WIDTH_MOBILE + 12,
    },
  },
  bodySystemHeader: {
    background: colors.gray[100],
  },
}))

interface Props {
  primaryBodySystemName?: string
  colSpan: number
}

const BodySystemHeaderRow = ({ primaryBodySystemName, colSpan }: Props) => {
  const classes = useStyles()
  return (
    <tr className={classes.bodySystemHeader}>
      <td
        className={clsx(
          classes.stickyCol,
          classes.stickyColHeader,
          "bg-slate-100 py-1 rounded-tl-lg rounded-bl-lg font-bold"
        )}
      >
        {primaryBodySystemName}
      </td>
      <td
        colSpan={colSpan}
        className="bg-slate-100 py-1 px-3 rounded-tr-lg rounded-br-lg"
      >
        <span style={{ opacity: 0 }}>filler</span>
      </td>
    </tr>
  )
}

export default BodySystemHeaderRow
