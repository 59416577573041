import React from "react"

import { cn } from "@rupahealth/design"

interface Props {
  className?: string
}

const Card = (props: React.PropsWithChildren<Props>) => (
  <div
    {...props}
    className={cn(
      "px-4 py-6 sm:px-6 border border-white rounded-2xl bg-white/75 backdrop-blur-lg flex flex-col gap-4",
      props.className
    )}
  />
)

export default Card
