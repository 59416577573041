import { ReactComponent as DollarSquareIcon } from "app/assets/icons/dollar-square.svg"
import { LAB_COMPANY_KEY } from "app/constants"
import {
  ComparisonRow,
  ComparisonColumnProps,
  EmptyComparisonValue,
} from "app/main/comparison/components/ComparisonLabTests/components"
import useAnyComparedLabTestHasProperty from "app/main/comparison/hooks/use-any-compared-lab-test-has-property"

const AdditionalFeesColumn = ({ labTest }: ComparisonColumnProps) => {
  if (
    !labTest ||
    !labTest.lab_company.additional_fees ||
    // For Labcorp (by PCS or AML) and LabsFirst Quest, the additional fee text is a duplicate of phlebotomy fee text,
    // but more relevant in phleb section. This is short term, we can hopefully remove it from additional_fees soon.
    // Thread: https://rupahealth.slack.com/archives/C03A47YTSDT/p1682449827742009
    labTest.lab_company.key === LAB_COMPANY_KEY.LABCORP ||
    labTest.lab_company.key === LAB_COMPANY_KEY.LABSFIRST_QUEST ||
    labTest.lab_company.key === LAB_COMPANY_KEY.LABCORP_BY_ACCESS_MEDICAL_LABS
  ) {
    return <EmptyComparisonValue />
  }

  return <>{labTest.lab_company.additional_fees}</>
}

export default function ComparisonAdditionalFeesRow() {
  const hasAdditionalFees = useAnyComparedLabTestHasProperty(
    "lab_company.additional_fees"
  )
  if (!hasAdditionalFees) {
    return null
  }
  return (
    <ComparisonRow
      Column={AdditionalFeesColumn}
      headerIcon={<DollarSquareIcon viewBox="0 0 13 12" />}
      headerLabel="Additional Fees"
    />
  )
}
