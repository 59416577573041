import { Theme, useMediaQuery } from "@material-ui/core"
import { Skeleton } from "@material-ui/lab"

import Card from "app/patient-portal/Card"

const PatientPortalOrderLoadingState = () => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"))

  return (
    <>
      <Card>
        <div className="flex flex-col items-stretch gap-6 justify-between sm:flex-row sm:items-center">
          <Skeleton
            variant="rect"
            animation="wave"
            height={42}
            width={220}
            className="rounded-xl"
          />
          <Skeleton
            variant="rect"
            animation="wave"
            height={42}
            width={220}
            className="rounded-xl"
          />
        </div>
        <Skeleton
          variant="rect"
          animation="wave"
          height={366}
          width={"100%"}
          className="rounded-xl"
        />
      </Card>

      <Card className="">
        <Skeleton
          variant="rect"
          animation="wave"
          height={42}
          width={isMobile ? "100%" : 493}
          className="rounded-xl"
        />

        <div className="flex flex-col gap-8">
          <Skeleton
            variant="rect"
            animation="wave"
            height={104}
            width={"100%"}
            className="rounded-xl"
          />
          <Skeleton
            variant="rect"
            animation="wave"
            height={104}
            width={"100%"}
            className="rounded-xl"
          />
          <Skeleton
            variant="rect"
            animation="wave"
            height={104}
            width={"100%"}
            className="rounded-xl"
          />
        </div>
      </Card>
    </>
  )
}

export default PatientPortalOrderLoadingState
