import { useEffect } from "react"

import { isUserGuest } from "app/auth/util"

import useAppSelector from "./useAppSelector"

function makeCookieString({
  key,
  value,
  maxAge = 604800,
}: {
  key: string
  value: string | boolean
  maxAge?: number
}) {
  // Sets domain to rupahealth.com so it can be written from labs.rupahealth.com
  // and read from rupahealth.com.
  // Omits domain on localhost so you can verify cookie behavior locally.
  const domainString =
    document.domain === "localhost"
      ? ""
      : " domain=rupahealth.com; samesite=strict; secure;"
  const _maxAge = maxAge || 604800 // default expiration 1 week
  return `${key}=${value};${domainString} max-age=${_maxAge}`
}

export default function useWebflowAuthCookie() {
  /* Sets cookie values to be read from webflow:
      - user_role
      - is_currently_logged_in
      - has_logged_in
  */
  const user = useAppSelector(({ auth }) => auth.user)
  const userRole = user.role

  useEffect(() => {
    // TODO: Figure out what this is used for. May be able to remove
    document.cookie = makeCookieString({ key: "user_role", value: userRole })

    const isCurrentlyLoggedIn = !isUserGuest(user)
    document.cookie = makeCookieString({
      key: "is_currently_logged_in",
      value: isCurrentlyLoggedIn,
    })

    // Here, we look for a value for the has_logged_in cookie key.
    const hasLoggedInCookieKey = "has_logged_in"
    let hasLoggedInCookieValue: string = ""

    const hasLoggedInCookiePair = document.cookie
      .split("; ")
      .find((row) => row.startsWith(`${hasLoggedInCookieKey}=`))

    if (hasLoggedInCookiePair) {
      hasLoggedInCookieValue = hasLoggedInCookiePair.split("=")[1]
    }

    // we want to set or refresh this cookie in any case EXCEPT
    // when it would be changed from true to false
    if (hasLoggedInCookieValue === "true" && !isCurrentlyLoggedIn) {
      return
    }

    document.cookie = makeCookieString({
      key: hasLoggedInCookieKey,
      value: isCurrentlyLoggedIn,
      maxAge: 2592000, // 1 month
    })
  }, [userRole])
}
