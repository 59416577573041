import React, { useEffect, useRef, useState } from "react"

import Lottie, { LottieRefCurrentProps } from "lottie-react"
import { useHistory, useLocation } from "react-router-dom"

import { Button, cn } from "@rupahealth/design"

import BodyText from "app/components/design-system/BodyText"

import resultsImportSuccessAnimation from "../../assets/results-import-success.json"

interface Props {
  onClose: () => void
  forceRefresh?: boolean
}
const ResultsUploadPdfAllSetCard: React.FC<Props> = ({
  onClose,
  forceRefresh = true,
}) => {
  const [isShown, setIsShown] = useState(false)
  const lottieRef = useRef<LottieRefCurrentProps | null>(null)

  const location = useLocation()
  const history = useHistory()

  // This effect is used to give the card a transition effect on show
  useEffect(() => {
    // wait 300 ms then show the card
    const timeout = setTimeout(() => {
      setIsShown(true)
    }, 100)

    return () => clearTimeout(timeout)
  }, [])

  const goToTrends = () => {
    const searchParams = new URLSearchParams(location.search)
    let newPath = ""
    // TODO: PROD-2332 cleanup url logic
    if (searchParams.has("tab")) {
      // in practitioner portal
      searchParams.set("tab", "results-over-time")
      newPath = `${location.pathname}?${searchParams.toString()}`
    } else {
      // in patient portal
      newPath = location.pathname.split("/").slice(0, -1).join("/") + "/trends"
    }
    if (forceRefresh) {
      window.open(newPath, "_self")
    } else {
      history.push(newPath)
    }
    onClose()
  }

  return (
    <div
      className={cn(
        "py-[53px] px-[75px] flex flex-col items-center gap-[18px] rounded-xl border-2 transition-opacity",
        isShown ? "opacity-100" : "opacity-0"
      )}
      style={{
        background: "linear-gradient(155deg, #E2F4FC 27.65%, #FFF8E9 83.95%)",
        borderColor: "rgba(0,0,0,0)",
      }}
    >
      <div className="w-[175px]">
        <Lottie
          animationData={resultsImportSuccessAnimation}
          loop={false}
          autoplay={true}
          lottieRef={lottieRef}
        />
      </div>
      <div className="flex flex-col justify-center items-center gap-y-1 w-[270px] text-center">
        <BodyText weight="semibold" size="md" className="text-center">
          All Set!
        </BodyText>
        <BodyText className="text-center">
          You can always edit the data later from Documents.
        </BodyText>
      </div>
      <div className="w-full">
        <Button variant="primary" className="w-full" onClick={goToTrends}>
          Go to Trends
        </Button>
      </div>
    </div>
  )
}

export default ResultsUploadPdfAllSetCard
