import { useMemo, useState } from "react"

import { some, sum } from "lodash"

import { Collapse, Typography } from "@material-ui/core"
import Skeleton from "@material-ui/lab/Skeleton"
import { cn } from "@rupahealth/design"

import {
  COMPANY_KEY_INSURANCE_TOGGLE_PAIRS,
  LAB_COMPANY_KEY,
  ORDER_LINE_ITEM_TYPE,
} from "app/constants"
import { getPanelTitle } from "app/dataServices/labTestDataService"
import { colors, navy, shadows } from "app/theme"
import { PatientPortalOrder } from "app/types"
import { formatDollars } from "app/utils"
import makeAppStyles from "app/utils/makeAppStyles"

import { LabTestDetailsButton } from "../../LabTestLineItem"
import { CompanyPanel } from "../types"
import PanelDetails from "./PanelDetails"
import PanelName from "./PanelName"
import { PhlebotomyRequired } from "./PhlebotomyRequired"
import { RemoveLineItemButton } from "./RemoveLIneItemButton"
import { TestLineItemsContainer } from "./TestLineItemContainer"

interface PanelLineItemProps {
  panel: CompanyPanel
  showPrices: boolean
  order: PatientPortalOrder
  isEditing: boolean
  updatingOrderedTestIds: string[]
  isUpdatingOrder: boolean
  enableLineItems: (orderedTestIds: string[]) => void
  disableLineItems: (orderedTestIds: string[]) => void
}

const useStyles = makeAppStyles({
  container: {
    borderRadius: 8,
    boxShadow: shadows.default,
    overflow: "hidden",
    border: `1px solid ${colors.blueGray[200]}`,
    margin: "16px 0 8px",
  },
  panelContainer: {
    padding: "16px 18px",
  },
  panelTitle: {
    fontWeight: 700,
    color: navy,
  },
  companyNameContainer: {
    color: navy,
    marginBottom: 21,
  },
  panelRowContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    flexDirection: "row",
  },
  bottomRowContainer: {
    marginTop: 25,
    marginBottom: 10,
  },
  panelCardPricePlaceholder: {
    backgroundColor: colors.blueGray[200],
    borderRadius: 12,
    marginLeft: "auto",
    height: 14,
    marginTop: 5,
    marginBottom: 5,
    width: 60,
  },
  priceText: {
    color: navy,
  },
})

const PanelLineItem = ({
  panel,
  showPrices,
  order,
  isEditing,
  updatingOrderedTestIds,
  isUpdatingOrder,
  enableLineItems,
  disableLineItems,
}: PanelLineItemProps) => {
  const classes = useStyles()
  const [detailsExpanded, setDetailsExpanded] = useState(
    panel.companyKey === LAB_COMPANY_KEY.LABCORP_BY_ACCESS_MEDICAL_LABS
      ? true
      : false
  )

  const orderedTestLineItems = panel.lineItems.flatMap((lineItem) =>
    lineItem.type === ORDER_LINE_ITEM_TYPE.LAB_TEST ? lineItem : []
  )
  const rupaCostSum = sum(
    panel.lineItems.map((lineItem) =>
      parseFloat(lineItem.discounted_cost || lineItem.cost)
    )
  )
  const totalRupaCost = formatDollars(rupaCostSum)
  const totalMsrpCost = formatDollars(
    sum(
      panel.lineItems.map((lineItem) =>
        lineItem.cost !== undefined ? parseFloat(lineItem.cost) : 0
      )
    )
  )

  const phlebotomyRequired = some(
    orderedTestLineItems.map((lineItem) =>
      some(
        lineItem.ordered_test.lab_test.lab_test_types.map(
          (labTestType) => labTestType.phlebotomy_required
        )
      )
    )
  )

  const panelHasInsuranceTests = useMemo(() => {
    const insuredLabCompanies = COMPANY_KEY_INSURANCE_TOGGLE_PAIRS.map(
      (pair) => pair.insuredCompanyKey
    )

    return orderedTestLineItems.some((lineItem) =>
      insuredLabCompanies.find(
        (key) => key === lineItem.ordered_test.lab_test.lab_company.key
      )
    )
  }, [panel])

  const onDetailsClick = () => {
    setDetailsExpanded(!detailsExpanded)
  }

  const onlyInHousePhlebotomy = panel.onlyInHousePhlebotomy
  let panelTitle = getPanelTitle(panel.groupKey)

  if (!onlyInHousePhlebotomy) {
    panelTitle = `${panelTitle} (1 kit)`
  }

  const panelHasDisabledItem = panel.lineItems.some(
    (lineItem) => lineItem.date_disabled
  )
  const panelOrderedTestIds = panel.lineItems.map((lineItem) =>
    String(lineItem.ordered_test?.id)
  )

  return (
    <TestLineItemsContainer
      lineItems={panel.lineItems}
      isEditing={isEditing}
      ariaLabel={panel.groupKey}
      disabled={panelHasDisabledItem}
    >
      <div className={classes.panelContainer}>
        <div className={classes.panelRowContainer}>
          <div
            className={cn("flex flex-col justify-center items-start mt-2", {
              "opacity-disabled": panelHasDisabledItem,
            })}
          >
            <div className={classes.panelTitle}>{panelTitle}</div>
            <div className={classes.companyNameContainer}>
              {panel.companyDisplayName}
            </div>
          </div>

          <div className="flex flex-col flex-start items-end mt-2">
            <div className={cn({ "opacity-disabled": panelHasDisabledItem })}>
              <PanelCardPrice
                isPriced={showPrices}
                discountedCost={totalRupaCost}
                cost={totalMsrpCost}
                insuranceToggledOn={panelHasInsuranceTests}
              />
            </div>
            {isEditing && (
              <RemoveLineItemButton
                removing={!panelHasDisabledItem}
                disabled={isUpdatingOrder}
                orderedTestIds={panelOrderedTestIds}
                enableLineItems={enableLineItems}
                disableLineItems={disableLineItems}
                updatingOrderedTestIds={updatingOrderedTestIds}
              />
            )}
          </div>
        </div>

        <div className={cn({ "opacity-disabled": panelHasDisabledItem })}>
          {panel.lineItems
            .filter(
              (lineItem) => lineItem.type === ORDER_LINE_ITEM_TYPE.LAB_TEST
            )
            .flatMap((lineItem) => (
              <PanelName
                key={lineItem.id}
                testName={lineItem.title}
                price={lineItem.cost || "0"}
                showPrice={showPrices && panel.showLineItemPrices}
                cptCodes={lineItem.ordered_test!.lab_test.cpt_codes}
                labCompanyKey={lineItem.ordered_test!.lab_test.lab_company.key}
              />
            ))}
        </div>

        <div
          className={cn(classes.panelRowContainer, classes.bottomRowContainer, {
            "opacity-disabled": panelHasDisabledItem,
          })}
        >
          {phlebotomyRequired && (
            <PhlebotomyRequired labCompanyKey={panel.companyKey} />
          )}
          <LabTestDetailsButton
            expanded={detailsExpanded}
            onClick={onDetailsClick}
          />
        </div>
      </div>

      <Collapse in={detailsExpanded}>
        <PanelDetails orderedTest={orderedTestLineItems[0].ordered_test} />
      </Collapse>
    </TestLineItemsContainer>
  )
}

const PanelCardPrice = ({
  cost,
  discountedCost,
  isPriced,
  insuranceToggledOn,
}) => {
  const classes = useStyles()
  if (insuranceToggledOn) {
    return (
      <Typography
        className={cn("font-bold text-base15", classes.priceText)}
        align="right"
      ></Typography>
    )
  }
  return isPriced ? (
    <Typography
      className={cn("font-bold text-base15", classes.priceText)}
      align="right"
    >
      {discountedCost !== cost ? (
        <>
          <span className="text-gray-500 line-through">{cost}</span>
          <span className="ml-2">{discountedCost}</span>
        </>
      ) : (
        <span className="ml-2">{discountedCost}</span>
      )}
    </Typography>
  ) : (
    <Skeleton
      className={classes.panelCardPricePlaceholder}
      variant="rect"
      animation="wave"
    />
  )
}

export default PanelLineItem
