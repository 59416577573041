import { gradients } from "app/main/blood-lab-dashboards/NumericBiomarkerGraphic/constants"
import { BiomarkerStatus } from "app/patient-portal/blood-lab-dashboard/constants"

// Results Over Time Table constants
export const STICKY_TABLE_COLUMN_WIDTH = 300
export const STICKY_TABLE_COLUMN_WIDTH_MOBILE = 145

// Max number of columns we want to show in the table before
// we start hiding them and show a horizontal scroller
export const MAX_DATA_COLUMNS = 5

export const IMPORT_RESULTS_INTERCOM_SURVEY_ID = 38349389
export const SUPPORTED_LABS_INTERCOM_ARTICLE_ID = 8899871
export const SUPPORTED_LABS_INTERCOM_ARTICLE_URL =
  "https://help.rupahealth.com/en/articles/8899871-results-over-time"

export const RESULT_STATUS_TO_CHART_COLOR = {
  [BiomarkerStatus.NORMAL]: "#37CE72",
  [BiomarkerStatus.OPTIMAL]: "#37CE72",
  [BiomarkerStatus.ABOVE_OPTIMAL]: "#FDC24A",
  [BiomarkerStatus.BELOW_OPTIMAL]: "#FDC24A",
  [BiomarkerStatus.HIGH]: "#F55440",
  [BiomarkerStatus.LOW]: "#F55440",
}

export const STATUS_GRADIENT_MAP = {
  [BiomarkerStatus.LOW]: gradients.abnormal.active,
  [BiomarkerStatus.BELOW_OPTIMAL]: gradients.nonOptimal.active,
  [BiomarkerStatus.NORMAL]: gradients.normal.active,
  [BiomarkerStatus.OPTIMAL]: gradients.normal.active,
  [BiomarkerStatus.ABOVE_OPTIMAL]: gradients.nonOptimal.active,
  [BiomarkerStatus.HIGH]: gradients.abnormal.active,
}
