import { useCallback, useLayoutEffect, useRef } from "react"

/**
 * Stores the provided callback in a ref that may be called by the returned function.
 * Useful for event handlers as the returned function will not update as the callback changes.
 *
 * @param fn the callback
 * @returns a function that will call the latest callback
 */
export default function useEventCallback<A extends any[], R>(
  fn: (...args: A) => R
): (...args: A) => R {
  let ref = useRef<(...args: A) => R>(fn)
  useLayoutEffect(() => {
    ref.current = fn
  })
  return useCallback((...args) => ref.current(...args), [])
}
