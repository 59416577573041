import { TableRow, TableCell } from "@material-ui/core"
import { Button, cn } from "@rupahealth/design"

import BodyText from "app/components/design-system/BodyText"

import useTableStyles from "./hooks/use-table-styles"

const DemographicOptimalRangeRow = ({
  numDemographicRanges,
  optimalRange,
  currIndex,
}) => {
  const classes = useTableStyles()

  const demographicRangeRequestMessage =
    "Hi! I'd like to set custom optimal ranges based on client demographics. Can you help me set this up?"

  const convertMonthsToYears = (months: number) => {
    if (months) {
      return Math.floor(months / 12)
    }

    return 0
  }

  const generateAgeRangeString = (
    ageMinMonths: number,
    ageMaxMonths: number
  ) => {
    const useMonths =
      (Boolean(ageMinMonths) && ageMinMonths < 12) ||
      (Boolean(ageMaxMonths) && ageMaxMonths < 24)
    let ageMin = ageMinMonths
    let ageMax = ageMaxMonths

    if (!useMonths) {
      ageMin = convertMonthsToYears(ageMinMonths)
      ageMax = convertMonthsToYears(ageMaxMonths)
    }

    if (ageMin !== 0 && ageMaxMonths !== 0) {
      return `Age ${ageMin} - ${ageMax} ${useMonths ? "months" : ""}`
    } else if (ageMin === 0 && ageMax !== 0) {
      return `Age Under ${ageMax} ${useMonths ? "months" : ""}`
    } else if (ageMax === 0 && ageMin !== 0) {
      return `Age Over ${ageMin} ${useMonths ? "months" : ""}`
    } else {
      return "Any Age"
    }
  }

  return (
    <TableRow className={cn(classes.tableDataRow, "bg-slate-50")}>
      <TableCell
        className={currIndex !== numDemographicRanges - 1 ? "border-none" : ""}
      >
        <BodyText weight="semibold">
          {generateAgeRangeString(
            optimalRange.attributes.age_min_months || 0,
            optimalRange.attributes.age_max_months || 0
          )}
        </BodyText>
      </TableCell>
      <TableCell
        className={currIndex !== numDemographicRanges - 1 ? "border-none" : ""}
      >
        <div className={classes.inputsContainer}>
          <div className="w-full flex flex-col gap-2">
            <div className={classes.label}>OPTIMAL MIN.</div>

            <BodyText weight="semibold">
              {optimalRange.attributes.optimal_range_min ?? "None"}
            </BodyText>
          </div>
          <div className="w-full flex flex-col gap-2">
            <div className={classes.label}>OPTIMAL MAX.</div>

            <BodyText weight="semibold">
              {optimalRange.attributes.optimal_range_max ?? "None"}
            </BodyText>
          </div>
        </div>
      </TableCell>
      <TableCell
        className={cn(
          currIndex !== numDemographicRanges - 1 ? "border-none" : "",
          "flex justify-end"
        )}
      >
        <Button
          variant={null}
          className="text-primary text-sm font-semibold"
          onClick={() =>
            window.Intercom("showNewMessage", demographicRangeRequestMessage)
          }
        >
          Message us to edit
        </Button>
      </TableCell>
    </TableRow>
  )
}

export default DemographicOptimalRangeRow
