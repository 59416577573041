import { useState } from "react"

import useEventCallback from "app/hooks/use-event-callback"
import useHandleApiError from "app/hooks/use-handle-api-error"
import useMutateResource from "app/swr/hooks/use-mutate-resource"
import { ResourceIdentifier, ResourceResponse } from "app/swr/types"
import resourceRequest from "app/swr/utils/resource-request"
import { Biomarker } from "types/biomarker"
import { BiomarkerCustomDescription } from "types/biomarker-custom-description"

export default function usePatchCustomBiomarkerDescription(
  identifier: ResourceIdentifier,
  biomarker?: Biomarker
) {
  const handleApiError = useHandleApiError()
  const mutateResource = useMutateResource()

  const [loading, setLoading] = useState(false)

  const resetDefaultDescriptions = (
    attributes: BiomarkerCustomDescription["attributes"]
  ) => {
    if (attributes.description === biomarker?.attributes.description) {
      attributes.description = ""
    }
    if (attributes.low_description === biomarker?.attributes.low_description) {
      attributes.low_description = ""
    }
    if (
      attributes.high_description === biomarker?.attributes.high_description
    ) {
      attributes.high_description = ""
    }

    return attributes
  }

  const patchBiomarkerCustomDescription = useEventCallback(
    async (formAttributes: BiomarkerCustomDescription["attributes"]) => {
      const attributes = resetDefaultDescriptions(formAttributes)

      setLoading(true)

      const sendPatchRequest = async () => {
        try {
          const { data } = await resourceRequest<
            ResourceResponse<BiomarkerCustomDescription>
          >({
            method: "patch",
            url: `/biomarker_custom_descriptions/${identifier.id}/`,
            data: {
              data: {
                ...identifier,
                attributes,
              },
            },
          })

          setLoading(false)

          return data
        } catch (error) {
          handleApiError(error)

          setLoading(false)

          // re-throw
          throw error
        }
      }

      return mutateResource<BiomarkerCustomDescription>(
        identifier,
        sendPatchRequest,
        {
          optimisticData(currentData) {
            if (!currentData) {
              // should never be reached, but type error without
              return undefined as any
            }

            return {
              ...currentData,
              attributes: {
                ...currentData.attributes,
                ...attributes,
              },
            }
          },
          rollbackOnError: true,
        }
      )
    }
  )

  return {
    patchBiomarkerCustomDescription,
    loading,
  }
}
