import _ from "lodash"

import { ORDER_LINE_ITEM_TYPE } from "app/constants"

// Note: phys services is also under this section but handled independently
export const PROCESSING_AND_ORDERING_ORDER_LINE_ITEMS = [
  ORDER_LINE_ITEM_TYPE.PROCESSING_FEE,
  ORDER_LINE_ITEM_TYPE.CREDIT_CARD_FEE,
  ORDER_LINE_ITEM_TYPE.VIBRANT_MINIMUM,
  ORDER_LINE_ITEM_TYPE.SHIPPING,
  ORDER_LINE_ITEM_TYPE.QUEST_SERVICE_FEE,
]
// A list of line items that are additional fees on top of the test price. They'll be rendered
// separately from the list of tests.
export const ADDITIONAL_FEE_LINE_ITEM_TYPES = [
  ORDER_LINE_ITEM_TYPE.SCARLET_BLOOD_DRAW_FEE,
  ORDER_LINE_ITEM_TYPE.LABCORP_BLOOD_DRAW_FEE,
  ORDER_LINE_ITEM_TYPE.QUEST_BLOOD_DRAW_FEE,
]

export const SUBTRACT_FROM_MSRP_ITEM_TYPES = [
  ...ADDITIONAL_FEE_LINE_ITEM_TYPES,
  ORDER_LINE_ITEM_TYPE.VIBRANT_MINIMUM,
  ORDER_LINE_ITEM_TYPE.QUEST_SERVICE_FEE,
]

function getTotalByTypes(lineItems, types) {
  return _.sum(
    lineItems
      .filter((lineItem) => types.includes(lineItem.type))
      .map((lineItem) => Number(lineItem.cost))
  )
}

export function calculateRetailPrice(msrpTotal, line_items) {
  // remove any additional fee line items from msrp so they do not show up twice
  const msrpLineItemTotal = getTotalByTypes(
    line_items,
    SUBTRACT_FROM_MSRP_ITEM_TYPES
  )

  const retailPrice = Number(msrpTotal) - msrpLineItemTotal

  return retailPrice
}

/** Storefront orders do not show Rupa discount, and should have it deducted from the total retail price */
export function calculateStorefrontRetailPrice(
  msrpTotal,
  line_items,
  rupaSavings
) {
  // remove any additional fee line items from msrp so they do not show up twice
  const subtractFromMsrpLineItems = line_items.filter((lineItem) =>
    SUBTRACT_FROM_MSRP_ITEM_TYPES.includes(lineItem.type)
  )

  const retailPrice =
    Number(msrpTotal) -
    _.sum(subtractFromMsrpLineItems.map((lineItem) => lineItem.cost)) -
    rupaSavings

  return retailPrice
}

export function sortLineItemTypeToLast(lineItemType) {
  // Returns a sorting function that puts the given line item last in list
  return function (firstItem, secondItem) {
    return firstItem.type === lineItemType
      ? 1
      : secondItem.type === lineItemType
      ? -1
      : 0
  }
}
