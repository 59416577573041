import React from "react"

import _ from "lodash"
import moment from "moment"

import { faUserCheck } from "@fortawesome/free-solid-svg-icons"
import { Dialog, Grid, Typography } from "@material-ui/core"
import Button from "@material-ui/core/Button"

import GenovaInsuranceExplanation from "app/components/GenovaInsuranceExplanation"
import { SecondaryButton } from "app/components/SecondaryButton"
import BodyText from "app/components/design-system/BodyText"
import useAppSelector from "app/hooks/useAppSelector"
import { selectSplitItInstallmentAmounts } from "app/main/patient-checkout/store/selectors"
import { lightGray, veryLightBlue } from "app/theme"
import {
  PatientCheckoutFormData,
  PatientInsurance,
  PatientPortalOrder,
  PaymentMethodDetails,
  PaymentMethodType,
} from "app/types"
import { formatDollars } from "app/utils"
import { getOrderPatientLabel, getOrderTypeLabel } from "app/utils/order-utils"

import PatientCheckoutBanner from "./PatientCheckoutBanner"

interface Props {
  formData: PatientCheckoutFormData
  onClose: () => void
  onConfirm: () => void
  open: boolean
  order: PatientPortalOrder
  paymentMethod?: PaymentMethodDetails
  willTestsBeShipped: boolean
}
export const ConfirmationModal: React.FC<Props> = ({
  formData,
  onClose,
  onConfirm,
  open,
  order,
  paymentMethod,
  willTestsBeShipped,
}) => {
  const installmentAmounts = useAppSelector(selectSplitItInstallmentAmounts)
  const streetAddress = formData.default_shipping_address.street_2?.length
    ? `${formData.default_shipping_address.street_1}, ${formData.default_shipping_address.street_2}`
    : formData.default_shipping_address.street_1

  const SPLITIT_PAYMENT_CONFIRMATION_ORDER_PROCESSING_TEXT = `Your ${getOrderTypeLabel(
    order.requires_vendor_physician_authorization
  ).toLowerCase()} will be processed immediately after receiving the first payment.`
  const SPLITIT_PAYMENT_CONFIRMATION_CARD_HOLD_TEXT =
    "You have selected our payment plan option. You will only be charged a portion of the total cost of the tests at a time. However, SplitIt will add a hold to your card for the remaining amount until it has been charged."
  const SPLITIT_PAYMENT_CONFIRMATION_TEXT = (
    <>
      <BodyText size="sm">
        {SPLITIT_PAYMENT_CONFIRMATION_CARD_HOLD_TEXT}
      </BodyText>
      <BodyText size="sm" style={{ marginTop: "5px" }}>
        {SPLITIT_PAYMENT_CONFIRMATION_ORDER_PROCESSING_TEXT}
      </BodyText>
    </>
  )

  const splititInstallmentText =
    installmentAmounts &&
    Math.abs(installmentAmounts[0] - installmentAmounts[1]) <= 0.01 ? (
      <>
        <Typography className="font-bold" component="span">
          {`${formatDollars(installmentAmounts[0])}/month`}
        </Typography>
        {" for 3 months powered by SplitIt"}
      </>
    ) : (
      <>
        <Typography className="font-bold" component="span">
          {`${formatDollars(installmentAmounts?.[0])}`}
        </Typography>
        {" today + "}
        <Typography className="font-bold" component="span">
          {`${formatDollars(installmentAmounts?.[1])}/month`}
        </Typography>
        {" for two months powered by SplitIt"}
      </>
    )

  return (
    <Dialog
      onClose={onClose}
      aria-label="Confirm your details"
      open={open}
      fullWidth
      maxWidth="sm"
    >
      <div
        className="flex flex-col justify-end min-h-full"
        style={{ backgroundColor: lightGray }}
      >
        {open && formData && (
          <div className="flex flex-col px-6 pb-6 overflow-y-auto">
            <GenovaInsuranceExplanation
              location="patientCheckoutConfirmation"
              order={order}
            />
            <Section
              title={`${getOrderPatientLabel(
                order.requires_vendor_physician_authorization
              )}'s Information`}
            >
              <PatientCheckoutBanner
                text="Please make sure this information is for the person collecting the sample. The patient’s name written on the lab forms must match this name exactly!"
                icon={faUserCheck}
                type="warning"
                className="mb-2"
              />
              <Field
                items={[
                  `${formData.first_name} ${formData.last_name}`,
                  formData.email,
                  biological_sex(formData.biological_sex),
                  formData.phone_number,
                  formData.birthday && moment(formData.birthday).format("L"),
                ]}
              />
            </Section>
            <Section
              title={
                willTestsBeShipped ? "Lab Kits Will Be Shipped To" : "Address"
              }
            >
              <Field
                items={[
                  streetAddress,
                  `${formData.default_shipping_address.city}, 
          ${formData.default_shipping_address.state}`,
                  formData.default_shipping_address.zipcode,
                ]}
              />
            </Section>
            {paymentMethod && (
              <Section
                hint={
                  paymentMethod.type === PaymentMethodType.SPLITIT
                    ? SPLITIT_PAYMENT_CONFIRMATION_TEXT
                    : undefined
                }
                title="Payment"
              >
                {paymentMethod.type === PaymentMethodType.ACH &&
                  !!paymentMethod.ach && (
                    <Field
                      items={[
                        paymentMethod.ach.bank_name,
                        `${paymentMethod.ach.account_name} *** *** ${paymentMethod.ach.last4}`,
                      ]}
                    />
                  )}
                {paymentMethod.type === PaymentMethodType.CARD &&
                  !!paymentMethod.card && (
                    <Field
                      items={[`**** **** **** ${paymentMethod.card.last4}`]}
                    />
                  )}
                {paymentMethod.type === PaymentMethodType.SPLITIT &&
                  !!paymentMethod.splitit && (
                    <Field
                      items={[
                        <Typography className="font-semibold">
                          {splititInstallmentText}
                        </Typography>,
                        `**** **** **** ${paymentMethod.splitit.last4}`,
                        ...splitItBillingInfo(paymentMethod),
                      ]}
                    />
                  )}
              </Section>
            )}

            {order.use_insurance && formData.insurance && (
              <Section title="Insurance Information">
                <Grid container>
                  <Grid item sm={6} xs={12}>
                    <Field
                      items={insuranceSubscriberItems(formData.insurance)}
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <Field
                      items={[
                        formData.insurance.provider,
                        formData.insurance.claims_street,
                        formData.insurance.claims_city &&
                          formData.insurance.claims_state &&
                          `${formData.insurance.claims_city} ${formData.insurance.claims_state}`,
                        formData.insurance.claims_zipcode,
                        formData.insurance.claims_phone_number,
                      ]}
                    />
                  </Grid>
                </Grid>
              </Section>
            )}
          </div>
        )}
        <div
          className="flex justify-end px-6 py-5 bg-white"
          style={{ borderTop: `1px solid ${veryLightBlue}` }}
        >
          <SecondaryButton onClick={onClose} className="px-4">
            Edit
          </SecondaryButton>
          <Button
            variant="contained"
            color="primary"
            onClick={onConfirm}
            className="px-4 ml-4"
          >
            Submit
          </Button>
        </div>
      </div>
    </Dialog>
  )
}

interface SectionProps {
  title: string
  hint?: string | React.ReactNode
  children: React.ReactNode
}
const Section: React.FC<SectionProps> = ({ hint, title, children }) => {
  return (
    <div className="pt-8">
      <Typography variant={"h4"} style={{ fontSize: 22 }} className="fs-unmask">
        {title}
      </Typography>
      {hint && (
        <Typography className="mb-2" color="textPrimary">
          {hint}
        </Typography>
      )}
      <div
        className="bg-white rounded p-2 mt-2"
        style={{ border: `1px solid ${veryLightBlue}` }}
      >
        {children}
      </div>
    </div>
  )
}

function Field({ items }: { items: React.ReactNode[] }) {
  return (
    <div>
      {_.flatMap(items, (item, i) => (
        <Typography
          color="textPrimary"
          className="block font-semibold my-1 fs-exclude"
          key={i}
        >
          {item}
        </Typography>
      ))}
    </div>
  )
}

function biological_sex(abbreviation) {
  if (abbreviation === "M") {
    return "Male"
  } else if (abbreviation === "F") {
    return "Female"
  }

  return null
}

function splitItBillingInfo(paymentMethod) {
  const { billing_address } = paymentMethod.splitit
  return [
    billing_address.street,
    `${billing_address.city}, ${billing_address.state}`,
    billing_address.zipcode,
  ]
}

function insuranceSubscriberItems(insurance: Partial<PatientInsurance>) {
  const bday = insurance.subscribers_birthday
    ? new Date(insurance.subscribers_birthday).toLocaleDateString("en-US")
    : null

  return [
    insurance.subscribers_relationship,
    `${insurance.subscribers_first_name} ${insurance.subscribers_last_name}`,
    [...(bday ? [bday] : [])],
    insurance.subscribers_id_number,
    insurance.group_number,
  ]
}
