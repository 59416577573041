import _ from "lodash"
import { useFormContext, useWatch } from "react-hook-form"

import { Grid } from "@material-ui/core"

import Button from "app/components/design-system/Button"
import { normalizeDate } from "app/components/forms/normalization"
import {
  INSURANCE_PROVIDERS_COMMERIAL_CARRIERS,
  INSURANCE_PROVIDERS_MEDICARE,
  INSURANCE_PROVIDERS_NON_MEDICARE,
  MEDICARE,
  LAB_COMPANY_KEY,
} from "app/constants"
import useFeatureFlag, { FeatureFlag } from "app/hooks/use-feature-flag"
import {
  PatientInsurance,
  PatientPortalOrder,
  PatientCheckoutFormData,
} from "app/types"
import makeAppStyles from "app/utils/makeAppStyles"

import {
  ControlledAutoCompleteField,
  ControlledDateField,
  ControlledSelectField,
  ControlledTextField,
  FieldNames,
} from "../fields"
import { US_STATES } from "../utils/usStates"

interface InsuranceFieldsProps {
  formData?: Partial<PatientInsurance>
  className?: string
  isMedicareUsed: boolean
  order: PatientPortalOrder
  updateOrder: (string) => {}
  isUpdatingOrder: boolean
}
// Enable the medicare verification button if all of the medicare fields are populated
const REQUIRED_MEDICARE_CHECK_FIELDS = [
  "provider",
  "subscribers_birthday",
  "subscribers_first_name",
  "subscribers_id_number",
  "subscribers_last_name",
  "subscribers_relationship",
]

const useStyles = makeAppStyles({
  verifyMedicareContainer: {
    "flex-grow": 1,
  },
  verifyMedicareButton: {
    marginTop: "24px",
    width: "100%",
  },
})

const InsuranceFields = ({
  formData,
  className,
  isMedicareUsed,
  order,
  updateOrder,
  isUpdatingOrder,
}: InsuranceFieldsProps) => {
  const [isMedicareCheckEnabled, isMedicareCheckLoading] = useFeatureFlag(
    FeatureFlag.isMedicareCheckEnabled
  )
  const methods = useFormContext<PatientCheckoutFormData>()

  const insuranceFormData = useWatch({ name: "insurance" })

  const hasMedicareCheckData =
    insuranceFormData &&
    REQUIRED_MEDICARE_CHECK_FIELDS.every((f) => insuranceFormData[f])
  const isMedicareCheckButtonEnabled = !isUpdatingOrder && hasMedicareCheckData

  const styles = useStyles()

  if (
    !order ||
    !order.ordered_tests ||
    !order.ordered_tests.length ||
    isMedicareCheckLoading
  ) {
    return null
  }
  let insuranceProviders
  const labCompanyKeysInOrder = order.ordered_tests.map(
    (orderedTest) => orderedTest.lab_test.lab_company.key
  )
  let isFreeSolo = true
  if (isMedicareUsed) {
    isFreeSolo = false
    // DSL only supports Medicare, not Tricare
    if (labCompanyKeysInOrder.includes(LAB_COMPANY_KEY.DSL as "DSL")) {
      insuranceProviders = [MEDICARE]
    } else {
      insuranceProviders = INSURANCE_PROVIDERS_MEDICARE
    }
  } else {
    // Genova doesn't handle Medicaid
    if (labCompanyKeysInOrder.includes(LAB_COMPANY_KEY.GENOVA as "GENOVA")) {
      insuranceProviders = INSURANCE_PROVIDERS_COMMERIAL_CARRIERS
    } else {
      insuranceProviders = INSURANCE_PROVIDERS_NON_MEDICARE
    }
  }

  const handleVerifyMedicareButtonClick = () => {
    updateOrder({
      medicare_form_data: {
        ...methods.getValues().insurance,
        subscribers_birthday: normalizeDate(
          methods.getValues()?.insurance?.subscribers_birthday
            ? methods.getValues()?.insurance?.subscribers_birthday
            : ""
        ),
      },
    })
  }

  const showMedicareCheck = isMedicareCheckEnabled && isMedicareUsed
  return (
    <div className={className}>
      <div>
        <Grid container spacing={2}>
          <Grid container item spacing={2}>
            <Grid item xs={12} sm={6} md={12} lg={4}>
              <ControlledTextField
                label="Subscriber's First Name"
                name={FieldNames.INSURANCE_SUBSCRIBERS_FIRST_NAME}
                required
                shouldUnregister
              />
            </Grid>
            <Grid item xs={12} sm={6} md={12} lg={4}>
              <ControlledTextField
                label="Subscriber's Last Name"
                name={FieldNames.INSURANCE_SUBSCRIBERS_LAST_NAME}
                required
                shouldUnregister
              />
            </Grid>
            <Grid item xs={12} sm={12} lg={4}>
              <ControlledDateField
                label="Date of Birth"
                name={FieldNames.INSURANCE_SUBSCRIBERS_BIRTHDAY}
                required
                shouldUnregister
              />
            </Grid>
          </Grid>

          <Grid container item spacing={2}>
            <Grid item xs={12} sm={12}>
              <ControlledAutoCompleteField
                freeSolo={isFreeSolo}
                label="Insurance Provider"
                name={FieldNames.INSURANCE_PROVIDER}
                getOptionLabel={(option) =>
                  (option && option.carrier) || option
                }
                groupBy={(option) => option.group}
                optionFromValue={(value, options) =>
                  _.find(options, (option) => option.carrier === value) || value
                }
                options={insuranceProviders}
                required
                shouldUnregister
                valueFromOption={(option) =>
                  (option && option.carrier) || option
                }
              />
            </Grid>
            <Grid container item spacing={2}>
              <Grid item xs={12} sm={12} xl={12}>
                <ControlledAutoCompleteField
                  freeSolo
                  label="Subscriber's Relationship"
                  name={FieldNames.INSURANCE_SUBSCRIBERS_RELATIONSHIP}
                  getOptionLabel={(option) => option}
                  options={["Self", "Spouse"]}
                  required
                  shouldUnregister
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                xl={showMedicareCheck ? 8 : "auto"}
                lg={showMedicareCheck ? 8 : "auto"}
              >
                <ControlledTextField
                  label={`Subscriber's ${
                    isMedicareUsed ? "Medicare" : "Insurance ID"
                  } Number`}
                  name={FieldNames.INSURANCE_SUBSCRIBERS_ID_NUMBER}
                  required
                  shouldUnregister
                />
              </Grid>
              {showMedicareCheck && (
                <Grid
                  item
                  xs={12}
                  md={12}
                  lg={4}
                  xl={4}
                  className={styles.verifyMedicareContainer}
                >
                  <Button
                    color="secondary"
                    disabled={!isMedicareCheckButtonEnabled}
                    loading={isUpdatingOrder}
                    onClick={() => handleVerifyMedicareButtonClick()}
                    size="small"
                    className={styles.verifyMedicareButton}
                  >
                    Verify Medicare
                  </Button>
                </Grid>
              )}
            </Grid>
          </Grid>

          {!isMedicareUsed && (
            <>
              <Grid container item spacing={2}>
                <Grid item xs={12} sm={6}>
                  <ControlledTextField
                    label="Group Number"
                    name={FieldNames.INSURANCE_GROUP_NUMBER}
                    required
                    shouldUnregister
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <ControlledTextField
                    label="Claims Phone Number"
                    name={FieldNames.INSURANCE_CLAIMS_PHONE_NUMBER}
                    required
                    shouldUnregister
                  />
                </Grid>
              </Grid>

              <Grid container item>
                <Grid item xs={12}>
                  <ControlledTextField
                    label="Claims Address"
                    name={FieldNames.INSURANCE_CLAIMS_STREET}
                    required
                    shouldUnregister
                  />
                </Grid>
              </Grid>

              <Grid container item spacing={2}>
                <Grid item xs={12} sm={4}>
                  <ControlledTextField
                    label="Claims City"
                    name={FieldNames.INSURANCE_CLAIMS_CITY}
                    required
                    shouldUnregister
                  />
                </Grid>

                <Grid item xs={12} sm={4}>
                  <ControlledSelectField
                    defaultValue=""
                    label="Claims State"
                    name={FieldNames.INSURANCE_CLAIMS_STATE}
                    required
                    shouldUnregister
                  >
                    {Object.entries(US_STATES).map(([code, name]) => (
                      <option key={code} value={code}>
                        {name}
                      </option>
                    ))}
                  </ControlledSelectField>
                </Grid>

                <Grid item xs={12} sm={4}>
                  <ControlledTextField
                    label="Claims Zip Code"
                    name={FieldNames.INSURANCE_CLAIMS_ZIPCODE}
                    required
                    shouldUnregister
                  />
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
      </div>
    </div>
  )
}

export default InsuranceFields
