import clsx from "clsx"

import { colors } from "@material-ui/core"

import { ReactComponent as AlertTriangle } from "app/assets/icons/alert-triangle-gray.svg"
import DesignSystemButton from "app/components/design-system/Button"
import { ORDERING_ISSUE_KEYS } from "app/constants"
import { cancelRed } from "app/theme"
import { OrderingIssue } from "app/types"
import makeAppStyles from "app/utils/makeAppStyles"

const useStyles = makeAppStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
    borderRadius: 7,
    padding: 10,
    display: "flex",
    gap: 10,
  },
  errorRoot: {
    backgroundColor: colors.red[50],
    border: `1px solid ${colors.red[100]}`,
  },
  warningRoot: {
    backgroundColor: colors.amber[50],
    border: `1px solid ${colors.amber[200]}`,
  },
  iconCirlce: {
    padding: 3,
    height: 25,
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  errorIconCircle: {
    backgroundColor: colors.red[200],
  },
  warningIconCircle: {
    backgroundColor: colors.amber[200],
  },
  headerText: {
    fontWeight: 500,
    fontSize: 15,
  },
  textColumn: {
    display: "flex",
    flexDirection: "column",
    gap: 5,
  },
  bodyText: {
    fontSize: 13,
  },
  errorText: {
    color: cancelRed,
  },
  warningText: {
    color: colors.amber[900],
  },
}))

export function ShippingStateOrderingWarning({
  error,
  primaryOrderingIssue,
  onCancel,
  pracName,
  sampleCollectionState,
  shippingState,
  isLabshopsOrder,
}: {
  error: boolean
  primaryOrderingIssue: OrderingIssue | undefined
  onCancel: (cancellationReason: string) => void
  pracName: string
  sampleCollectionState: string | null
  shippingState: string | null
  isLabshopsOrder: boolean
}) {
  const classes = useStyles()

  if (!primaryOrderingIssue) {
    return null
  }

  const message = `Some of the ordered tests cannot be completed in ${
    primaryOrderingIssue.key ===
    ORDERING_ISSUE_KEYS.LAB_TEST_SAMPLE_COLLECTION_STATE_NOT_ALLOWED
      ? sampleCollectionState
      : shippingState
  }.`

  return (
    <div
      className={clsx(
        classes.root,
        error ? classes.errorRoot : classes.warningRoot
      )}
    >
      <div
        className={clsx(
          classes.iconCirlce,
          error ? classes.errorIconCircle : classes.warningIconCircle
        )}
      >
        <AlertTriangle
          width={20}
          viewBox="0 0 14 14"
          fill={error ? colors.red[800] : colors.amber[900]}
        />
      </div>
      <div className={classes.textColumn}>
        <div
          className={clsx(
            classes.headerText,
            error ? classes.errorText : classes.warningText
          )}
        >
          {message}
        </div>
        <div
          className={clsx(
            classes.bodyText,
            error ? classes.errorText : classes.warningText
          )}
        >
          You'll need to either collect this sample in another state and change
          your selection, or cancel this order.
        </div>
        {!isLabshopsOrder && (
          <DesignSystemButton
            color="secondary"
            className={classes.button}
            onClick={() => onCancel(primaryOrderingIssue.message)}
          >
            Cancel and Notify {pracName}
          </DesignSystemButton>
        )}
      </div>
    </div>
  )
}

export default ShippingStateOrderingWarning
