import { forwardRef } from "react"

import { styled, Typography, TypographyProps } from "@material-ui/core"

export type DisplayTextProps = {
  size?: "base" | "lg" | "xl" | "2xl"
  weight?: "regular" | "semibold"
}

const DisplayText = forwardRef(
  (
    {
      size = "base",
      weight = "regular",
      ...props
    }: DisplayTextProps & TypographyProps,
    ref
  ) => <StyledTypography size={size} weight={weight} {...props} ref={ref} />
)

const SizedTypography = forwardRef<any>(
  ({ weight, size, ...other }: DisplayTextProps & TypographyProps, ref) => (
    <Typography color="textPrimary" variant="h2" {...other} ref={ref} />
  )
)

const StyledTypography = styled(SizedTypography)({
  lineHeight: ({ size }: DisplayTextProps) =>
    size &&
    {
      base: 1.35,
      lg: 1.35,
      xl: 1.4,
      "2xl": 1.4,
    }[size],
  fontSize: ({ size }: DisplayTextProps) =>
    size &&
    {
      base: 15,
      lg: 19,
      xl: 24,
      "2xl": 30,
    }[size],
  fontWeight: ({ weight }: DisplayTextProps) =>
    weight &&
    {
      regular: 400,
      semibold: 600,
    }[weight],
})

export default DisplayText
