import { z, ZodObject, ZodTypeAny } from "zod"

import { LabCompany } from "app/types"
import { safeParseDate } from "app/utils/format-date-for-human"
import { UserBiomarkerResult } from "types/user-biomarker-result"
import { UserResult } from "types/user-result"

import { UserResultCreateData } from "./use-create-or-update-user-result"

export const LAB_COMPANY_OTHER_ID = "other"

// Shape of the form data for a single UserBiomarkerResult
export interface UserBiomarkerResultFormData {
  // Can't use "id" as a key because React Hook Form uses it
  user_biomarker_result_id: string | undefined
  value: string
  units: string
  normal_min: string
  normal_max: string
  sample_type_id: string
  biomarker_id: string
}

type UserBiomarkerResultFormSchema = ZodObject<{
  [key in keyof UserBiomarkerResultFormData]: ZodTypeAny
}>

// Shape of the form data for the entire UserResult form
export interface UserResultFormData {
  collection_date: Date | string
  fasting_required: "yes" | "no"
  // lab_company can be either a lab company ID or a custom name
  lab_company: string
  sample_type_id: string
  review_confirmation: boolean
  results: UserBiomarkerResultFormData[]
}

type UserResultFormSchema = ZodObject<{
  [key in keyof UserResultFormData]: ZodTypeAny
}>

const USER_RESULT_FORM_EMPTY_VALUES: UserResultFormData = {
  collection_date: "",
  fasting_required: "no",
  lab_company: "",
  sample_type_id: "",
  review_confirmation: false,
  results: [],
}

// Schema for a single UserBiomarkerResult
const BiomarkerResultDataSchema: UserBiomarkerResultFormSchema = z.object({
  user_biomarker_result_id: z.string().nullable().optional(),
  value: z.string().nonempty("Please enter a value."),
  value_type: z.string().optional(),
  units: z.string().optional(),
  normal_min: z.string().optional(),
  normal_max: z.string().optional(),
  sample_type_id: z.string().nonempty("Please select a sample type."),
  biomarker_id: z.string().nonempty("Please select a biomarker."),
})

// Schema for the entire UserResult form
export function getUserResultFormSchema({
  requireReviewConfirmation,
}: {
  requireReviewConfirmation?: boolean
}): UserResultFormSchema {
  let schema: UserResultFormSchema = z.object({
    collection_date: z.date({
      invalid_type_error: "Must provide a valid sample collection date",
      required_error: "Please provide your sample collection date",
    }),
    fasting_required: z.string().nonempty("Please select an option."),
    lab_company: z.string().nullable(),
    sample_type_id: z.string().optional(),
    results: z
      .array(BiomarkerResultDataSchema)
      .min(1, "Please add at least one result."),
    review_confirmation: z.boolean().optional(),
  })

  if (requireReviewConfirmation) {
    schema = schema.extend({
      review_confirmation: z.literal(true, {
        errorMap: () => ({
          message: "Please confirm that the extracted information is accurate.",
        }),
      }),
    })
  }
  return schema
}

// Get default values for the UserResult form
export function getUserResultFormDefaultValues({
  userResult,
  userBiomarkerResults,
  labCompanies,
}: {
  userResult?: UserResult
  userBiomarkerResults?: UserBiomarkerResult[]
  labCompanies: LabCompany[]
}): UserResultFormData {
  const labCompany = labCompanies.find(
    (lc) => lc.id === userResult?.relationships.lab_company?.data?.id
  )

  return {
    ...USER_RESULT_FORM_EMPTY_VALUES,
    ...userResult?.attributes,
    fasting_required: userResult?.attributes.fasting_required ? "yes" : "no",
    collection_date:
      safeParseDate(userResult?.attributes.collection_date) ?? "",
    lab_company:
      (labCompany
        ? labCompany.results_name
        : userResult?.attributes.other_lab_company_name) ?? "",
    sample_type_id: userResult?.relationships.sample_types?.data[0]?.id ?? "",
    results:
      userBiomarkerResults?.map((br) => ({
        user_biomarker_result_id: br.id,
        value: br.attributes.value ?? "",
        units: br.attributes.units ?? "",
        normal_min: br.attributes.normal_min ?? "",
        normal_max: br.attributes.normal_max ?? "",
        sample_type_id: br.relationships.sample_type?.data?.id ?? "",
        biomarker_id: br.relationships.biomarker?.data?.id ?? "",
      })) ?? [],
    review_confirmation: false,
  }
}

// Convert form data to data for creating a UserResult
export const formDataToCreateData = ({
  formData,
  patientId,
  labCompanies,
}: {
  formData: UserResultFormData
  patientId?: string
  labCompanies: LabCompany[]
}): UserResultCreateData => {
  const selectedLabCompany = formData.lab_company
    ? labCompanies.find((lc) => lc.results_name === formData.lab_company)
    : undefined

  return {
    type: "user_result",
    results: formData.results.map(
      ({ user_biomarker_result_id, ...result }) => ({
        ...result,
        id: user_biomarker_result_id,
        value_type: "numeric",
      })
    ),
    attributes: {
      collection_date:
        safeParseDate(formData.collection_date)?.toISOString() ?? "",
      fasting_required: formData.fasting_required === "yes" ? true : false,
      has_specimen_issues: false,
      other_lab_company_name: selectedLabCompany ? "" : formData.lab_company,
    },
    relationships: {
      patient: patientId
        ? { data: { type: "patient", id: patientId } }
        : undefined,
      lab_company: selectedLabCompany
        ? { data: { id: selectedLabCompany.id, type: "lab_company" } }
        : { data: null },
      sample_types: {
        data: formData.sample_type_id
          ? [{ id: formData.sample_type_id, type: "lab_test_type" }]
          : [],
      },
    },
  }
}
