import { useEffect } from "react"
import { useDispatch } from "react-redux"

import { FuseSplashScreen } from "@fuse"
import Cookies from "js-cookie"
import jwtDecode from "jwt-decode"
import { useHistory, useLocation } from "react-router-dom"

import { GuestPaths } from "app/Routes"
import authService from "app/auth/services/simplejwt-auth-service"
import {
  IMPERSONATING_FIELD,
  IMPERSONATING_USER_FIELD,
} from "app/auth/services/simplejwt-auth-service"
import useHandleApiError from "app/hooks/use-handle-api-error"

/**

pulls token out of url params
makes request to magic link login api

sets session with jwt in response
updates user data

 */

function MagicLogin({ user }) {
  const dispatch = useDispatch()
  const location = useLocation()
  const history = useHistory()
  const handleApiError = useHandleApiError()

  useEffect(() => {
    const effect = async () => {
      const urlParams = new URLSearchParams(location.search)
      const token = urlParams.get("token")

      try {
        let next = undefined
        let userId = undefined
        if (token && typeof token === "string") {
          const decodedToken = jwtDecode(token)
          if (decodedToken && typeof decodedToken === "object") {
            next = decodedToken["next"]
            userId = decodedToken["id"]
            // Set impersonating cookie if present
            // This will trigger an impersonation banner to appear (see ImpersonationBanner.tsx)
            if (decodedToken[IMPERSONATING_FIELD]) {
              Cookies.set(
                IMPERSONATING_FIELD,
                decodedToken[IMPERSONATING_FIELD]
              )
            }
            if (decodedToken[IMPERSONATING_USER_FIELD]) {
              Cookies.set(
                IMPERSONATING_USER_FIELD,
                decodedToken[IMPERSONATING_USER_FIELD]
              )
            }
          }
        }

        // Login if not logged in or logged in as a different user.
        // If the user is the same, authentication fails and the redirect doesn't happen
        if (!user || !userId || user.id !== userId) {
          // Pass the next parameter to the login function. This will ensure we redirect _after_ the user data is retrieved.
          await authService.loginWithMagicLinkToken(token, next)
        } else {
          // If the user is already logged in, just redirect to the next page.
          history.push(next || "/")
        }
      } catch (error) {
        handleApiError(error)
        history.push(GuestPaths.LOGIN)
      }
    }

    effect()
  }, [dispatch])

  return <FuseSplashScreen />
}

export default MagicLogin
