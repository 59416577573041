import { Dispatch, MutableRefObject, SetStateAction } from "react"
import * as React from "react"

import { CircularProgress } from "@material-ui/core"
import * as Popover from "@radix-ui/react-popover"
import { cn } from "@rupahealth/design"

import { ReactComponent as TrashCanIcon } from "app/assets/icons/trash-can.svg"
import useEventCallback from "app/hooks/use-event-callback"
import { colors, primaryColor, textPrimaryColor } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"

export const useStyles = makeAppStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    padding: theme.spacing(0.75),
    background: colors.blueGray[100],
    border: `1px solid ${primaryColor}`,
    borderRadius: 8,
    gap: 4,
  },
  input: {
    flex: "1 1 auto",
    background: colors.blueGray[100],
    color: textPrimaryColor,
    fontSize: 16,
    lineHeight: 1.25,

    [theme.breakpoints.up("sm")]: {
      fontSize: "inherit",
    },

    "&::placeholder": {
      color: colors.blueGray[500],
    },
  },
  cancelButton: {
    display: "flex",
    alignItems: "center",
  },
  cancelIcon: {
    width: 16,
    height: 16,
    fill: colors.blueGray[400],
  },
}))

export default function AddBiomarkerSearchInput({
  moveBiomarkerCursorDown,
  moveBiomarkerCursorUp,
  onEnterSelect,
  onToggleAdding,
  search,
  setSearch,
  showLoading,
  triggerRef,
  customRootClasses,
  customInputClasses,
}: {
  moveBiomarkerCursorDown: () => void
  moveBiomarkerCursorUp: () => void
  onEnterSelect: () => void
  onToggleAdding?: () => void
  search: string
  setSearch: Dispatch<SetStateAction<string>>
  showLoading: boolean
  triggerRef: MutableRefObject<any>
  customRootClasses?: string
  customInputClasses?: string
}) {
  const classes = useStyles()

  /**
   * Handle keydown events on the search input.
   *
   * - ArrowUp: move the biomarker cursor up
   * - ArrowDown: move the biomarker cursor down
   * - Enter: select the current biomarker
   */
  const handleKeyDown: React.KeyboardEventHandler<HTMLInputElement> =
    useEventCallback((e) => {
      if (e.key === "ArrowUp") {
        // disable moving text cursor to start
        e.preventDefault()

        moveBiomarkerCursorUp()
      } else if (e.key === "ArrowDown") {
        // disable moving text cursor to end
        e.preventDefault()

        moveBiomarkerCursorDown()
      } else if (e.key === "Enter") {
        // disable enter behavior
        e.preventDefault()

        onEnterSelect()
      } else if (e.key === "Escape" && onToggleAdding) {
        e.preventDefault()
        onToggleAdding()
      }
    })

  return (
    <Popover.Trigger
      asChild
      onClick={(e) => {
        e.preventDefault()
      }}
      ref={triggerRef}
    >
      <div className={cn(classes.root, customRootClasses)}>
        <input
          aria-label="Search for biomarkers by name..."
          autoFocus
          className={cn(classes.input, customInputClasses)}
          onChange={(e) => setSearch(e.target.value)}
          onKeyDown={handleKeyDown}
          placeholder="Search for biomarkers by name..."
          value={search}
          autoComplete="off"
          autoCorrect="off"
          autoCapitalize="off"
          spellCheck="false"
        />

        {showLoading && <CircularProgress size={16} />}

        <button
          className={classes.cancelButton}
          onClick={onToggleAdding}
          type="button"
        >
          <TrashCanIcon className={classes.cancelIcon} viewBox="0 0 15 17" />
        </button>
      </div>
    </Popover.Trigger>
  )
}
