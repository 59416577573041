import { useDispatch } from "react-redux"

import CircularCheckFilled from "app/assets/images/circular-check-filled.svg"
import CircularCheck from "app/assets/images/circular-check.svg"
import Button from "app/components/design-system/Button"

import usePatientOrderStyles from "./hooks/usePatientOrderStyles"
import * as Actions from "./store/actions"

function ResultsReviewedButton({ order }) {
  const dispatch = useDispatch()
  const styles = usePatientOrderStyles()

  const isReviewed = !!order.date_results_reviewed

  const onClick = () => {
    dispatch(Actions.markResultsReviewed(order))
  }

  return (
    <Button
      color="secondary"
      shadow="default"
      startIcon={isReviewed ? CircularCheckFilled : CircularCheck}
      onClick={onClick}
      className={styles.button}
    >
      {isReviewed ? "Marked As Reviewed" : "Mark As Reviewed"}
    </Button>
  )
}

export default ResultsReviewedButton
