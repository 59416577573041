import { useMemo } from "react"

import BodyText from "app/components/design-system/BodyText"
import makeAppStyles from "app/utils/makeAppStyles"

import { STICKY_TABLE_COLUMN_WIDTH } from "../constants/constants"
import { ResultsOverTimeDateGrouping } from "../types/types"
import { calculateTableCellWidth, convertTimestampForHeader } from "../util"

const useStyles = makeAppStyles(() => ({
  stickyCol: {
    left: 0,
    position: "absolute",
    top: "auto",
    width: STICKY_TABLE_COLUMN_WIDTH,
    textAlign: "left !important" as any,
    boxShadow: "5px 0 20px -1px rgba(0, 0, 0, 0.05)",
  },
}))

interface Props {
  dates: string[]
  groupedBy: ResultsOverTimeDateGrouping
  tableWidth?: number
}

const ResultsOverTimeTableDatesHeader = ({
  dates,
  groupedBy,
  tableWidth,
}: Props) => {
  const classes = useStyles()

  const cellWidth = useMemo(() => {
    return calculateTableCellWidth(dates.length, tableWidth)
  }, [dates, tableWidth])

  return Boolean(dates.length) ? (
    <tr>
      <th className={classes.stickyCol} style={{ opacity: 0 }}>
        Filler
      </th>
      {dates.map((date, index) => {
        return (
          <th
            key={date}
            className="py-3"
            style={{
              minWidth: `${cellWidth}px`,
            }}
          >
            <BodyText
              size="xs"
              weight={index === 0 ? "bold" : "regular"}
              className={index === 0 ? "text-navy" : "text-slate-500"}
            >
              {convertTimestampForHeader(date, groupedBy)}
            </BodyText>
          </th>
        )
      })}
    </tr>
  ) : null
}

export default ResultsOverTimeTableDatesHeader
