import React from "react"

import { ReactComponent as ReceiptIcon } from "app/assets/icons/receipt.svg"
import { ReactComponent as SuperbillIcon } from "app/assets/icons/superbill.svg"
import {
  PatientPortalPayment,
  SUPERBILL_UNAVAILABLE_REASON,
} from "app/patient-portal/types"

export interface OrderPaymentDisplayType {
  type: "receipt" | "superbill"
  attributes?: PatientPortalPayment["attributes"]
  url?: string | null
  disabled?: boolean
  tooltipTitle?: React.ReactNode
  tooltipSubtitle?: React.ReactNode
}

export interface SuperbillType extends OrderPaymentDisplayType {
  type: "superbill"
}

export interface ReceiptType extends OrderPaymentDisplayType {
  type: "receipt"
}

export const getButtonDisplayInfo = (
  paymentType: OrderPaymentDisplayType["type"]
) => {
  switch (paymentType) {
    case "receipt":
      return { Icon: ReceiptIcon, text: "Receipt" }
    case "superbill":
      return { Icon: SuperbillIcon, text: "Superbill" }
  }
}

export const getSuperbillsToDisplay = (
  payments: PatientPortalPayment[]
): SuperbillType[] => {
  return payments.reduce((acc, payment) => {
    const superbill = getSuperbillDisplayInfo(payment)
    if (superbill) {
      acc.push(superbill)
    }
    return acc
  }, [] as SuperbillType[])
}

export const getReceiptsToDisplay = (
  payments: PatientPortalPayment[]
): ReceiptType[] => {
  return payments.reduce((acc, payment) => {
    const receipt = getReceiptDisplayInfo(payment)
    if (receipt) {
      acc.push(receipt)
    }
    return acc
  }, [] as ReceiptType[])
}

const getSuperbillDisplayInfo = (
  payment: PatientPortalPayment | undefined
): SuperbillType | undefined => {
  if (!payment) {
    return undefined
  }

  const baseInfo: SuperbillType = {
    type: "superbill",
    url: payment.attributes.superbill_url,
    attributes: payment.attributes,
  }

  switch (payment.attributes.superbill_unavailable_reason) {
    case SUPERBILL_UNAVAILABLE_REASON.RESULTS_NOT_RECEIVED:
      return {
        ...baseInfo,
        disabled: true,
        tooltipTitle: "Results Not In Yet",
        tooltipSubtitle:
          "Your insurance company is only able to process a Superbill once all of your results are in.",
      }
    case SUPERBILL_UNAVAILABLE_REASON.SPLITIT_PAYMENT_INCOMPLETE:
      return {
        ...baseInfo,
        disabled: true,
        tooltipTitle: "Payment Incomplete",
        tooltipSubtitle:
          "Your Superbill will be available once your Splitit payment is completed and processed.",
      }
    case SUPERBILL_UNAVAILABLE_REASON.ORDER_HAS_COUPONS:
      return {
        ...baseInfo,
        disabled: true,
        tooltipTitle: "Order Ineligible",
        tooltipSubtitle:
          "Rupa cannot create a Superbill for orders that used a coupon or discount",
      }
    case SUPERBILL_UNAVAILABLE_REASON.UNAVAILABLE_PAYMENT_STATUS:
    case SUPERBILL_UNAVAILABLE_REASON.ORDER_IS_PRAC_PAY:
    case SUPERBILL_UNAVAILABLE_REASON.ORDER_REQUIRES_PHYS_AUTH:
    case SUPERBILL_UNAVAILABLE_REASON.ORDER_FROM_LABSHOP:
      return undefined
  }
  return baseInfo
}

const getReceiptDisplayInfo = (
  payment: PatientPortalPayment | undefined
): ReceiptType | undefined => {
  if (!payment?.attributes.receipt_url) {
    return undefined
  }

  return {
    type: "receipt",
    url: payment.attributes.receipt_url,
    attributes: payment.attributes,
  }
}
