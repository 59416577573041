import _ from "lodash"

import { LAB_COMPANY_KEY, LABCORP_COMPANY_KEYS } from "app/constants"
import { OrderIntent, useResourceSelector } from "app/hooks/use-resource"

import { useGetCartLabTests } from "./use-get-cart-lab-tests"

/*
 * Returns true if there is at least one phleb test from both DHA and at least one phleb test from LabCorp on the same order.
 */
export const useHasMixedDHAAndLabcorpOrder = (cart: OrderIntent | null) => {
  const labTests = useGetCartLabTests(cart)

  const labCompanyIdsInOrder = _.uniq(
    labTests
      .map((labTest) => labTest?.relationships.lab_company?.data?.id)
      .filter((id) => !!id) as string[]
  )

  const labCompaniesIdToKey = _.merge(
    {},
    ...useResourceSelector((entities) =>
      labCompanyIdsInOrder.map((id) => {
        return { [id]: entities["lab_company"]?.[id].attributes.key }
      })
    )
  )

  const listOfAllTestTypeIds: any = []
  for (const labTest of labTests) {
    for (const lab_test_types of labTest?.relationships.lab_test_types?.data ??
      []) {
      if (lab_test_types?.id) {
        listOfAllTestTypeIds.push(lab_test_types.id)
      }
    }
  }
  const labTestTypeIdToPhlebRequired = _.merge(
    {},
    ...useResourceSelector((entities) => {
      return listOfAllTestTypeIds.map((id) => {
        return {
          [id]: entities["lab_test_type"]?.[id].attributes.phlebotomy_required,
        }
      })
    })
  )

  // Convert the list of lab tests to a list of either:
  // null
  // "dha" (when there is a DHA phleb test)
  // "labcorp" (when there is a LabCorp phleb test)

  // Then, remove the nulls. If both "dha" and "labcorp" are in the list, block the order.
  const labTestsFiltered = labTests.map((labTest) => {
    const companyId = labTest?.relationships.lab_company?.data?.id
    const testIds = labTest?.relationships.lab_test_types.data.map(
      (lab_test_type) => lab_test_type.id
    )

    const oneOrMoreTestsRequirePhleb = _.some(
      testIds,
      (testId) => labTestTypeIdToPhlebRequired[testId]
    )

    if (!oneOrMoreTestsRequirePhleb || !companyId) {
      return null
    }

    if (labCompaniesIdToKey[companyId] === LAB_COMPANY_KEY.DHA_LABORATORY) {
      return "dha"
    }

    if (LABCORP_COMPANY_KEYS.includes(labCompaniesIdToKey[companyId])) {
      return "labcorp"
    }

    return null
  })

  const uniqCompanyList = _.uniq(_.pull(labTestsFiltered, null))

  return uniqCompanyList.length >= 2
}
