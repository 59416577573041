import { isFunction } from "lodash"

import {
  ColumnFiltersState,
  OnChangeFn,
  PaginationState,
} from "@tanstack/react-table"

import useQueryState from "app/hooks/use-query-state"

import {
  GlobalFilterState,
  TabId,
  PATIENT_COLUMN_ID,
  PRACTITIONER_COLUMN_ID,
  STATUS_COLUMN_ID,
} from "../components/TableRenderer"

/**
 * Hook used to encapsulate the query state for the dashboard, which includes global filters, column filters, and pagination.
 *
 * @returns the query states and functions to update the query states
 */
export default function useDashboardQueryState() {
  const [queryState, setQueryState] = useQueryState<
    {
      globalFilter: GlobalFilterState
      columnFilters: ColumnFiltersState
      pagination: PaginationState
    },
    {
      tab?: string
      patient?: string
      practitioner?: string
      status?: string
      page?: string
    }
  >(
    (query) => {
      const globalFilter: GlobalFilterState = {}
      if (query.tab) {
        globalFilter.tab = query.tab as TabId
      }

      const columnFilters: ColumnFiltersState = []
      if (query.patient) {
        columnFilters.push({
          id: PATIENT_COLUMN_ID,
          value: query.patient,
        })
      }

      if (query.practitioner) {
        columnFilters.push({
          id: PRACTITIONER_COLUMN_ID,
          value: query.practitioner,
        })
      }

      if (query.status) {
        columnFilters.push({
          id: STATUS_COLUMN_ID,
          value: query.status,
        })
      }

      const pagination: PaginationState = {
        pageIndex: 0,
        pageSize: 25,
      }
      if (query.page) {
        pagination.pageIndex = Number(query.page)
      }

      return {
        globalFilter,
        columnFilters,
        pagination,
      }
    },
    (state) => ({
      tab: state.globalFilter.tab,
      patient: state.columnFilters.find(
        (filter) => filter.id === PATIENT_COLUMN_ID
      )?.value as string,
      practitioner: state.columnFilters.find(
        (filter) => filter.id === PRACTITIONER_COLUMN_ID
      )?.value as string,
      status: state.columnFilters.find(
        (filter) => filter.id === STATUS_COLUMN_ID
      )?.value as string,
      page:
        state.pagination.pageIndex > 0
          ? state.pagination.pageIndex.toString()
          : undefined,
    })
  )

  const onGlobalFilterChange: OnChangeFn<GlobalFilterState> = (updater) => {
    setQueryState((state) => {
      const nextGlobalFilter = isFunction(updater)
        ? updater(state.globalFilter)
        : updater
      return {
        ...state,
        globalFilter: nextGlobalFilter,
        pagination: {
          ...state.pagination,
          // Reset page index when filters change
          pageIndex: 0,
        },
      }
    })
  }

  const onColumnFiltersChange: OnChangeFn<ColumnFiltersState> = (updater) => {
    setQueryState((state) => {
      const nextColumnFilters = isFunction(updater)
        ? updater(state.columnFilters)
        : updater
      return {
        ...state,
        columnFilters: nextColumnFilters,
        pagination: {
          ...state.pagination,
          // Reset page index when filters change
          pageIndex: 0,
        },
      }
    })
  }

  const onPaginationChange: OnChangeFn<PaginationState> = (updater) => {
    setQueryState((state) => {
      const nextPagination = isFunction(updater)
        ? updater(state.pagination)
        : updater
      return {
        ...state,
        pagination: nextPagination,
      }
    })
  }

  return {
    ...queryState,
    onGlobalFilterChange,
    onColumnFiltersChange,
    onPaginationChange,
  }
}
