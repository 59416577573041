import * as DropdownMenu from "@radix-ui/react-dropdown-menu"

import BlueCheckmark from "app/assets/icons/blue-checkmark.svg"
import SortIcon from "app/assets/icons/sort.svg"
import { LabTestOrdering } from "app/main/catalog/const"
import { primaryColor, shadows } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"

import BodyText from "../design-system/BodyText"
import Button from "../design-system/Button"
import { OnSetQueryParams, QueryMap } from "./types"

const useStyles = makeAppStyles({
  sortButton: {
    padding: 8,
    minWidth: "auto",
    marginLeft: 11,
  },
  dropdownContent: {
    background: "white",
    borderRadius: 6,
    border: `1px solid ${primaryColor}`,
    boxShadow: shadows.lg,
    padding: "0 10px",
    marginTop: 5,
    overflowY: "auto",
    zIndex: 1300,
  },
  item: {
    display: "flex",
    alignItems: "center",
    padding: "8px 12px 8px 24px",
    cursor: "pointer",
    position: "relative",

    "& p": {
      transition: "0.08s color",
    },

    '&:focus, &:hover, &[data-state="checked"]': {
      "& p": {
        color: primaryColor,
      },
    },
  },
  itemCheckmark: {
    position: "absolute",
    left: 1,
    top: 12,
    width: 14,
  },
})

const dropdownItems: {
  value: string
  label: string
  queryParams: { order_by?: string; order_direction?: string }
}[] = [
  {
    value: LabTestOrdering.priceAscending,
    label: "Price: Low to High",
    queryParams: { order_by: "rupa_price", order_direction: "asc" },
  },
  {
    value: LabTestOrdering.priceDescending,
    label: "Price: High to Low",
    queryParams: { order_by: "rupa_price", order_direction: "desc" },
  },
  {
    value: LabTestOrdering.alphabetical,
    label: "Alphabetical",
    queryParams: { order_by: "name", order_direction: "asc" },
  },
  {
    value: LabTestOrdering.popular,
    label: "Popularity",
    queryParams: {},
  },
  {
    value: LabTestOrdering.mostRelevant,
    label: "Most Relevant",
    queryParams: {},
  },
]

function getSelectedOrderByFromFilters(queryMap: QueryMap) {
  const orderBy = queryMap.order_by
  const orderDirection = queryMap.order_direction
  const search = queryMap.search

  if (orderBy?.value === "rupa_price") {
    if (orderDirection?.value === "asc") {
      return LabTestOrdering.priceAscending
    } else {
      return LabTestOrdering.priceDescending
    }
  } else if (orderBy?.value === "name") {
    return LabTestOrdering.alphabetical
  }

  if (search) {
    return LabTestOrdering.mostRelevant
  }

  return LabTestOrdering.popular
}

interface Props {
  queryMap: QueryMap
  onSetQueryParams: OnSetQueryParams
}

const OrderBy = ({ queryMap, onSetQueryParams }: Props) => {
  const styles = useStyles()
  const selectedOrderBy = getSelectedOrderByFromFilters(queryMap)

  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger asChild>
        <Button
          className={styles.sortButton}
          color="secondary"
          shadow="sm"
          aria-label="Sort lab tests"
        >
          <img src={SortIcon} alt="" />
        </Button>
      </DropdownMenu.Trigger>
      <DropdownMenu.Portal>
        <DropdownMenu.Content className={styles.dropdownContent} align="end">
          <DropdownMenu.RadioGroup value={selectedOrderBy}>
            {dropdownItems
              .filter(({ value }) => {
                if (
                  value === LabTestOrdering.mostRelevant &&
                  !queryMap.search?.value
                ) {
                  return false
                } else if (
                  value === LabTestOrdering.popular &&
                  queryMap.search?.value
                ) {
                  return false
                }

                return true
              })
              .map(({ label, value, queryParams }) => (
                <DropdownMenu.RadioItem
                  className={styles.item}
                  value={value}
                  onSelect={() => {
                    onSetQueryParams([
                      {
                        queryName: "order_by",
                        value: queryParams.order_by || "",
                      },
                      {
                        queryName: "order_direction",
                        value: queryParams.order_direction || "",
                      },
                    ])
                  }}
                  key={value}
                >
                  <DropdownMenu.ItemIndicator>
                    <img
                      src={BlueCheckmark}
                      alt="Active"
                      className={styles.itemCheckmark}
                    />
                  </DropdownMenu.ItemIndicator>
                  <BodyText className={styles.itemLabel} weight="semibold">
                    {label}
                  </BodyText>
                </DropdownMenu.RadioItem>
              ))}
          </DropdownMenu.RadioGroup>
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  )
}

export default OrderBy
