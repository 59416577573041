import { useCallback, useState } from "react"
import * as React from "react"

import { Link } from "react-router-dom"

import { IconButton, makeStyles } from "@material-ui/core"

import { UserPaths } from "app/Routes/paths"
import CrossIcon from "app/assets/icons/blue-cross.svg"
import ArticleIcon from "app/assets/images/dashboard-cards/article-icon.svg"
import BloodDraws from "app/assets/images/dashboard-cards/blood-draws.svg"
import Bloodwork from "app/assets/images/dashboard-cards/bloodwork.svg"
import CalendarIcon from "app/assets/images/dashboard-cards/calendar-icon.svg"
import Call from "app/assets/images/dashboard-cards/call.svg"
import CatalogIcon from "app/assets/images/dashboard-cards/catalog-icon.svg"
import Catalog from "app/assets/images/dashboard-cards/catalog.svg"
import InviteStaffIcon from "app/assets/images/dashboard-cards/invite-staff-icon.svg"
import InviteStaff from "app/assets/images/dashboard-cards/invite-staff.svg"
import LearnFunctionalLabs from "app/assets/images/dashboard-cards/learn-functional-labs.svg"
import PatientExperience from "app/assets/images/dashboard-cards/patient-experience.svg"
import PlayVideoIcon from "app/assets/images/dashboard-cards/play-video-icon.svg"
import Pricing from "app/assets/images/dashboard-cards/pricing.svg"
import StepByStepGuide from "app/assets/images/dashboard-cards/step-by-step-guide.jpg"
import BodyText from "app/components/design-system/BodyText"
import theme, { colors, shadows } from "app/theme"
import { Practitioner, DashboardCard as DashboardCardType } from "app/types"

const cardConfig: {
  [key in DashboardCardType]: {
    title: string
    image: string
    icon: string
    label?: string
    to?: string
    href?: string | ((practitioner: Practitioner) => string)
  }
} = {
  [DashboardCardType.SCHEDULE_1_ON_1]: {
    title: "Join a Live Demo",
    image: Call,
    icon: CalendarIcon,
    href: "https://rupahealth.com/demo",
  },
  [DashboardCardType.EXPLORE_TEST_CATALOG]: {
    title: "Explore the Lab Test Catalog",
    image: Catalog,
    icon: CatalogIcon,
    to: "/discover-labs/search",
  },
  [DashboardCardType.STEP_BY_STEP_GUIDE]: {
    title: "A step-by-step guide to Rupa",
    label: "8 min video",
    image: StepByStepGuide,
    icon: PlayVideoIcon,
    href: "https://www.rupahealth.com/videos/welcome-to-the-rupa-lab-ordering-portal",
  },
  [DashboardCardType.PRICING_AND_PAYMENT]: {
    title: "How Pricing & Payment Works",
    label: "2 min read",
    image: Pricing,
    icon: ArticleIcon,
    href: "https://www.rupahealth.com/pricing?utm_source=dashboard&utm_medium=card&utm_campaign=resources-for-new-signups",
  },
  [DashboardCardType.HOW_BLOOD_DRAWS_WORK]: {
    title: "How Blood Draws Work",
    label: "1 min read",
    image: BloodDraws,
    icon: ArticleIcon,
    href: "https://www.rupahealth.com/phlebotomy?utm_source=dashboard&utm_medium=card&utm_campaign=resources-for-new-signups",
  },
  [DashboardCardType.PATIENT_EXPERIENCE]: {
    title: "How Your Patients Experience Rupa",
    label: "3 min read",
    image: PatientExperience,
    icon: ArticleIcon,
    href: "https://www.rupahealth.com/patient-experience?utm_source=dashboard&utm_medium=card&utm_campaign=resources-for-new-signups",
  },
  [DashboardCardType.INVITE_CLINIC_STAFF]: {
    title: "Invite Team Members",
    image: InviteStaff,
    icon: InviteStaffIcon,
    to: UserPaths.TEAM,
  },
  [DashboardCardType.ORDERING_BLOODWORK]: {
    title: "Ordering Basic Blood Labs",
    label: "1 min read",
    image: Bloodwork,
    icon: ArticleIcon,
    href: "https://www.rupahealth.com/basic-blood-labs?utm_source=dashboard&utm_medium=card&utm_campaign=resources-for-new-signups",
  },
  [DashboardCardType.LEARN_FUNCTIONAL_LABS]: {
    title: "Learn About Functional Labs",
    label: "Series",
    image: LearnFunctionalLabs,
    icon: ArticleIcon,
    href: "https://www.rupahealth.com/getting-started-with-functional-lab-testing?utm_source=dashboard&utm_medium=card&utm_campaign=resources-for-new-signups",
  },
}

const useStyle = makeStyles<typeof theme, { image: string }>({
  container: {
    borderRadius: 12,
    border: `1px solid ${colors.blueGray[300]}`,
    background: "white",
    display: "flex",
    height: 97,
    width: 292,
    minWidth: 292,
    marginRight: 24,
    position: "relative",
    overflow: "hidden",
    boxShadow: shadows.default,
    transition: "0.24s box-shadow",
    cursor: "pointer",
    "&:hover": {
      boxShadow: shadows.md,
    },
  },
  closeButton: {
    position: "absolute",
    top: 6,
    right: 6,
  },
  pill: {
    borderRadius: 100,
    background: colors.coolGray[100],
    padding: "2px 10px",
    marginBottom: 8,
  },
  cardContent: {
    flex: 1,
    display: "flex",
    flexFlow: "column",
    justifyContent: "center",
    alignItems: "start",
    padding: "16px 40px 16px 16px",
  },
  cardIconContainer: {
    borderRadius: 22,
    height: 44,
    width: 44,
    zIndex: 1,
    background: "white",
    border: `1px solid ${colors.blueGray[300]}`,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    boxShadow: shadows.sm,
  },
  cardImage: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    height: "100%",
    background: (props) =>
      `
     linear-gradient(180deg, rgba(255, 255, 255, 0.28) 0%, rgba(202, 210, 219, 0.42) 100%),
     top left/cover url(${props.image}),
     linear-gradient(180deg, #ECECEC 0%, #DADEE1 100%)
     `,
  },
  cardImageContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 90,
    position: "relative",
  },
})

const DashboardCard = ({
  name,
  practitioner,
  handleDismissCard,
}: {
  name: string
  practitioner: Practitioner
  handleDismissCard: (name: string) => void
}) => {
  const [isVisible, setIsVisible] = useState(true)
  const { title, label, image, icon, to, href } = cardConfig[name]
  const {
    cardImage,
    cardImageContainer,
    cardContent,
    closeButton,
    cardIconContainer,
    container,
    pill,
  } = useStyle({ image })

  const hideCard = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation()
      event.preventDefault()
      setIsVisible(false)
      handleDismissCard(name)
    },
    [setIsVisible, handleDismissCard]
  )

  if (!isVisible) {
    return null
  }

  const content = (
    <>
      <div className={cardImageContainer}>
        <div className={cardImage} />
        <div className={cardIconContainer}>
          <img src={icon} alt="" />
        </div>
      </div>
      <div className={cardContent}>
        {!!label && (
          <div className={pill}>
            <BodyText size="xs">{label}</BodyText>
          </div>
        )}
        <BodyText weight="semibold">{title}</BodyText>
      </div>
      <IconButton aria-label="close" onClick={hideCard} className={closeButton}>
        <img src={CrossIcon} alt="Cross" />
      </IconButton>
    </>
  )

  if (to) {
    return (
      <Link className={container} to={to}>
        {content}
      </Link>
    )
  }

  // We want the referrer to be passed to Mixpanel for analytics purposes.
  /* eslint-disable react/jsx-no-target-blank */
  return (
    <a
      className={container}
      href={typeof href === "function" ? href(practitioner) : href}
      target="_blank"
      rel="noopener"
    >
      {content}
    </a>
  )
  /* eslint-enable react/jsx-no-target-blank */
}

export default DashboardCard
