import { useEffect, useMemo, useState } from "react"

import { ColumnFiltersState, ExpandedState } from "@tanstack/react-table"

import { LabCompany } from "app/types"

import OptimalRangesLabCompanyDropdown from "./OptimalRangesLabCompanyDropdown"
import OptimalRangesTabs from "./OptimalRangesTabs"
import TableRenderer from "./TableRenderer"
import { Tab } from "./TableTabsRenderer"
import {
  biomarkerNameColumn,
  optimalRangeColumn,
  referenceRangeColumn,
} from "./columns"
import useOptimalRanges from "./hooks/use-optimal-ranges"
import useOptimalRangesQueryState from "./hooks/use-optimal-ranges-query-state"
import useTable from "./hooks/use-table"

export default function OptimalRangesTable() {
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([])
  const [globalFilter, setGlobalFilter] = useState({
    labCompanyKey: "",
    hideUnsetRanges: false,
  })
  const [tabsToShow, setTabsToShow] = useState<Tab[]>([])
  const [expanded, setExpanded] = useState<ExpandedState>({})

  const queryState = useOptimalRangesQueryState()
  const { pagination, onPaginationChange } = queryState

  const handleSelect = (labCompany: LabCompany) => {
    setGlobalFilter((previousGlobalFilter) => ({
      ...previousGlobalFilter,
      labCompanyKey: labCompany.key,
    }))
    setTabsToShow((prev) => {
      return [...prev, { id: labCompany.key, label: labCompany.results_name }]
    })
  }

  const {
    optimalRanges,
    availableLabCompanyTabs,
    isLoading,
    removeAllOptimalRangesForLabCompany,
    mutateOptimalRanges,
    meta,
  } = useOptimalRanges({
    pagination,
    columnFilters,
    labCompanyKey: globalFilter.labCompanyKey,
    hideUnsetRanges: globalFilter.hideUnsetRanges,
  })

  const columns = useMemo(() => {
    return [biomarkerNameColumn, optimalRangeColumn, referenceRangeColumn]
  }, [])

  useEffect(() => {
    // Init selected tab and tab collection - the first render will return tabs from all lab companies that have custom ranges
    if (availableLabCompanyTabs.length && globalFilter.labCompanyKey === "") {
      setGlobalFilter((previousGlobalFilter) => ({
        ...previousGlobalFilter,
        labCompanyKey: availableLabCompanyTabs[0].id,
      }))
      // This state may change when the user initially selects a tab without any custom ranges
      setTabsToShow(availableLabCompanyTabs)
    }
  }, [availableLabCompanyTabs])

  const handleDeleteClick = async (key: string) => {
    if (availableLabCompanyTabs.find((tab) => tab.id === key)) {
      await removeAllOptimalRangesForLabCompany(key)
      const newTabsToShow = tabsToShow.filter((tab) => tab.id !== key)
      setTabsToShow(newTabsToShow)

      if (newTabsToShow.length > 0) {
        setGlobalFilter((previousGlobalFilter) => ({
          ...previousGlobalFilter,
          labCompanyKey: newTabsToShow[0].id,
        }))
      } else {
        setGlobalFilter((previousGlobalFilter) => ({
          ...previousGlobalFilter,
          labCompanyKey: "",
        }))
      }
    } else {
      setTabsToShow((prev) => prev.filter((tab) => tab.id !== key))
    }
  }

  const handleToggleHideUnsetRangesClick = () => {
    setGlobalFilter((previousGlobalFilter) => ({
      ...previousGlobalFilter,
      hideUnsetRanges: !previousGlobalFilter.hideUnsetRanges,
    }))
  }

  const table = useTable({
    columns,
    columnFilters,
    count: meta?.pagination?.count,
    globalFilter,
    data: optimalRanges,
    isLoading: isLoading,
    isValidating: false,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange,
    pagination,
    expanded,
    handleExpandedChange: setExpanded,
  })

  useEffect(() => {
    mutateOptimalRanges()
  }, [])

  return (
    <>
      <div>
        <OptimalRangesLabCompanyDropdown
          keysAlreadyPresent={tabsToShow.map((tab) => tab.id)}
          onSelect={handleSelect}
        />
      </div>
      {tabsToShow.length > 0 && (
        <TableRenderer
          table={table}
          renderTabs={(props) => (
            <OptimalRangesTabs
              {...props}
              availableLabCompanyTabs={tabsToShow}
              handleDeleteClick={handleDeleteClick}
              handleToggleHideUnsetRangesClick={
                handleToggleHideUnsetRangesClick
              }
            />
          )}
        />
      )}
    </>
  )
}
