import { Typography } from "@material-ui/core"

import { navy } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"

const useStyles = makeAppStyles({
  processingFeeTitle: {
    marginTop: "30px",
    fontSize: "19px",
  },
  processingFeeExplanation: {
    margin: "8px 0 16px",
    color: navy,
  },
})

const ProcessingFee = () => {
  const classes = useStyles()

  return (
    <>
      <Typography
        color="textPrimary"
        variant="h5"
        className={classes.processingFeeTitle}
      >
        Payment for Remaining Order
      </Typography>
      <div className={classes.processingFeeExplanation}>
        Select how you want to pay for the remaining tests and processing fees
        below.
      </div>
    </>
  )
}

export default ProcessingFee
