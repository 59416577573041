import { Button } from "@rupahealth/design"

import { ReactComponent as CredentialIcon } from "app/assets/icons/credential.svg"
import CardSelect from "app/components/forms/CardSelect"

import { useCredentialModalContext } from "./CredentialModalProvider"
import { LICENSE_TYPES } from "./types"

const Step1LicenseType: React.FC = () => {
  const { licenseType, setLicenseType, advanceStep } =
    useCredentialModalContext()

  return (
    <>
      <div className="bg-slate-50">
        <div className="p-5">
          <div className="text-center">
            <span className="inline-flex rounded-xl bg-white p-3">
              <CredentialIcon className="m-auto" />
            </span>
            <div className="font-title text-xl19 font-semibold my-4">
              Upload your credential for lab access.
            </div>
          </div>
          <div className="bg-white p-4 rounded-xl flex flex-col gap-3 text-sm">
            <span>
              Please choose the option that best fits your credential.
            </span>
            <CardSelect
              options={LICENSE_TYPES}
              selectedValue={licenseType}
              onSelect={setLicenseType}
            />
          </div>
        </div>
      </div>
      <div className="flex gap-4 border-t border-slate-200 bg-white py-4 px-5 text-base15 font-semibold">
        <Button
          variant="primary"
          className="w-full"
          onClick={advanceStep}
          disabled={!licenseType}
        >
          Continue
        </Button>
      </div>
    </>
  )
}

export default Step1LicenseType
