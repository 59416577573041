import { AnyLabTest } from "app/types"

import {
  ActionTypes,
  AddComparedTestAction,
  CloseComparisonModalAction,
  CloseSearchModalAction,
  FetchTestsFailureAction,
  FetchTestsRequestAction,
  FetchTestsSuccessAction,
  OpenComparisonModalAction,
  OpenSearchModalAction,
  RemoveComparedTestAction,
  SetComparedTestsAction,
} from "../types"

/**
 * Creates an action to open the comparison modal. Optionally provide an initial lab test to compare.
 *
 * @param [labTestId] the id of the lab test to compare
 * @param [labTest] the lab test data for the compared test
 * @returns the action to dispatch
 */
export function openComparisonModal(
  labTestId?: string,
  labTest?: AnyLabTest
): OpenComparisonModalAction {
  return {
    type: ActionTypes.OPEN_COMPARISON_MODAL,
    payload: {
      labTestId,
      labTest,
    },
  }
}

/**
 * Creates an action to close the comparison modal.
 *
 * @returns the action to dispatch
 */
export function closeComparisonModal(): CloseComparisonModalAction {
  return {
    type: ActionTypes.CLOSE_COMPARISON_MODAL,
  }
}

/**
 * Creates an action to open the search modal.
 *
 * @returns the action to dispatch
 */
export function openSearchModal(): OpenSearchModalAction {
  return {
    type: ActionTypes.OPEN_SEARCH_MODAL,
  }
}

/**
 * Creates an action to close the search modal.
 *
 * @returns the action to dispatch
 */
export function closeSearchModal(): CloseSearchModalAction {
  return {
    type: ActionTypes.CLOSE_SEARCH_MODAL,
  }
}

/**
 * Creates an action to add a lab test to the list of lab tests to compare.
 *
 * @param labTestId the id of the lab test to add to the comparison
 * @param [labTest] the lab test data for the added test
 * @returns the action to dispatch
 */
export function addComparedTest(
  labTestId: string,
  labTest?: AnyLabTest
): AddComparedTestAction {
  return {
    type: ActionTypes.ADD_COMPARED_TEST,
    payload: {
      labTestId,
      labTest,
    },
  }
}

/**
 * Creates an action to remove a lab test from the list of lab tests to compare.
 *
 * @param labTestId the id of the lab test to remove from the comparison
 * @returns an action to dispatch
 */
export function removeComparedTest(
  labTestId: string
): RemoveComparedTestAction {
  return {
    type: ActionTypes.REMOVE_COMPARED_TEST,
    payload: labTestId,
  }
}

/**
 * Creates an action to update the set of lab test ids.
 *
 * @param labTestIds the set of lab test ids
 * @returns an action to dispatch
 */
export function setComparedTests(labTestIds: string[]): SetComparedTestsAction {
  return {
    type: ActionTypes.SET_COMPARED_TESTS,
    payload: labTestIds,
  }
}

/**
 * Creates an action to request lab test data for the currently compared tests.
 *
 * @returns the action to dispatch
 */
export function fetchTestsRequest(): FetchTestsRequestAction {
  return {
    type: ActionTypes.FETCH_TESTS_REQUEST,
  }
}

/**
 * Creates an action to indicate that lab test data has been successfully fetched.
 *
 * @param labTests the lab test data for the currently compared tests
 * @returns the action to dispatch
 */
export function fetchTestsSuccess(
  labTests: AnyLabTest[]
): FetchTestsSuccessAction {
  return {
    type: ActionTypes.FETCH_TESTS_SUCCESS,
    payload: labTests,
  }
}

/**
 * Creates an action to indicate that lab test data failed to be fetched.
 *
 * @param error the error that occurred while fetching lab test data
 * @returns the action to dispatch
 */
export function fetchTestsFailure(error: Error): FetchTestsFailureAction {
  return {
    type: ActionTypes.FETCH_TESTS_FAILURE,
    payload: error,
  }
}
