export const CONFIRM_UNFAVORITE_BUNDLE_MODAL_TITLE = "Unfavorite Bundle"
export const CONFIRM_UNFAVORITE_BUNDLE_MODAL_TEXT =
  "Unfavoriting this bundle will hide it from the cart. You can access it again from settings."
export const CONFIRM_UNFAVORITE_BUNDLE_MODAL_BTN_TEXT = "Unfavorite & Hide"

export const DELETE_SHARED_BUNDLE_TITLE = "Delete Shared Bundle"
export const DELETE_SHARED_BUNDLE_TEXT =
  "Deleting this panel removes it from the Shared section for everyone in your clinic. If anyone has saved this panel to their own Saved Bundle section, it’ll remain there."
export const DELETE_SHARED_BUNDLE_PRIMARY_BTN_TEXT = "Delete Shared Bundle"
export const DELETE_SHARED_BUNDLE_SECONDARY_BTN_TEXT = "Just Hide From My Cart"
export const DELETE_BUNDLE_TITLE = "Delete Bundle"
export const DELETE_BUNDLE_TEXT =
  "This will delete this bundle from your account. This action cannot be reversed."
export const DELETE_BUNDLE_PRIMARY_BTN_TEXT = "Delete Bundle"
export const DELETE_BUNDLE_SECONDARY_BTN_TEXT = "Actually, Let's Keep It"
