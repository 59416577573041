import { SVGProps } from "react"

import { useUniqueId } from "app/hooks/use-unique-id"

export default function RupaLogoIcon(props?: SVGProps<any>) {
  const uniqId = useUniqueId()
  const svgId = `svg_${uniqId}`
  const gradientId = `linearGradient_${uniqId}`
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      id={svgId}
    >
      <defs>
        <linearGradient
          id={gradientId}
          x1="27.3157"
          y1="4.17301"
          x2="7.21366"
          y2="28.534"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.119792" stopColor="#1C749C" />
          <stop offset="0.645833" stopColor="#5DC1C7" />
        </linearGradient>
      </defs>
      <g>
        <circle cx="16.4325" cy="15.6061" r="15.5548" fill="white" />
        <path
          d="M15.7103 31.1036C9.77086 30.8329 4.7031 27.2287 2.32557 22.1198C3.5438 23.0232 5.07117 23.4804 6.88065 23.4804C11.2535 23.4804 14.0182 19.8116 16.6917 16.2635C19.4987 12.5384 22.1499 9.01987 26.5252 9.01987C28.981 9.01987 30.8503 9.75463 31.7193 12.7297C31.889 13.6505 31.982 14.5981 31.982 15.5681C31.982 23.916 25.4051 30.7275 17.1501 31.1036H15.7103ZM6.88065 21.8434C5.114 21.8434 3.71024 21.319 2.70836 20.2849C1.15399 18.6804 0.916889 16.2502 0.883057 15.379C0.883329 15.3621 0.884148 15.3454 0.884421 15.3282C2.11002 12.8386 4.06466 10.6569 7.15349 10.6569C9.45054 10.6569 11.4134 13.2448 12.9901 15.3241C13.4106 15.8788 13.8092 16.401 14.1898 16.8449C12.0562 19.578 9.87808 21.8434 6.88065 21.8434ZM1.36489 11.6948C3.08625 4.97964 9.17824 0.0161133 16.4301 0.0161133C22.3095 0.0161133 27.4256 3.27957 30.0694 8.09303C29.0894 7.61583 27.9126 7.38282 26.5252 7.38282C21.3339 7.38282 18.3091 11.3966 15.3843 15.2783C15.3256 15.3563 15.2667 15.4344 15.208 15.5121C14.921 15.1599 14.6146 14.7572 14.2946 14.3351C12.4987 11.9671 10.2636 9.01987 7.15349 9.01987C4.92001 9.01987 2.95774 9.94398 1.36489 11.6948Z"
          fill={`url(#${gradientId})`}
        />
      </g>
    </svg>
  )
}
