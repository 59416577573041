import { RefObject, useMemo } from "react"

import clsx from "clsx"
import { sortBy } from "lodash"

import { CircularProgress, Collapse, Link, makeStyles } from "@material-ui/core"

import UpRightArrow from "app/assets/icons/up-right-arrow.svg"
import expandCaretIcon from "app/assets/images/expand-caret.svg"
import BodyText from "app/components/design-system/BodyText"
import Button from "app/components/design-system/Button"
import { markerTypeMapping } from "app/main/phlebotomy-map/PhlebotomistMapMarker"
import { primaryColor } from "app/theme"
import { PhlebotomistFeature } from "app/types"
import "react-map-gl-geocoder/dist/mapbox-gl-geocoder.css"

const useStyles = makeStyles((theme) => ({
  listView: {
    position: "absolute",
    width: "calc(100% - 20px)",
    minHeight: "40px",
    maxHeight: "90%",
    left: "10px",
    top: "10px",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "11px 8px",
    background: "rgba(255, 255, 255, 0.73)",
    border: "1px solid #FFFFFF",
    boxSizing: "border-box",
    backdropFilter: "blur(52px)",
    borderRadius: "24px",
    [theme.breakpoints.up("sm")]: {
      width: 300,
    },
  },
  loadingContainer: {
    marginTop: "10px",
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  listHeader: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingTop: "10px",
    paddingLeft: "5px",
    paddingRight: "5px",
  },
  toggleControl: {
    display: "flex",
    alignItems: "center",
  },
  listCollapse: {
    width: "100%",
    "overflow-y": "auto",
  },
  listCollapseEntered: {
    marginTop: "8px",
  },
  listItem: {
    padding: "5px",
    margin: "5px",
    marginBottom: "10px",
    marginTop: "10px",
    position: "relative",
    display: "grid",
    gridTemplateColumns: "auto 2fr",
    gridColumnGap: "10px",
    borderRadius: "6px",
    cursor: "pointer",
  },
  selectedListItem: {
    background: primaryColor,
    color: "#FFFFFF",
  },
  selectedText: {
    color: "#FFFFFF",
  },
  circleIcon: {
    width: "33px",
  },
  overviewText: {
    color: "#7D8D9D",
  },
  styledMapMarkerTypeImage: {
    position: "absolute",
    width: 17,
    top: 13.5,
    left: 13.5,
    cursor: "pointer",
    userDrag: "none",
  },
  geocoderContainer: {
    margin: "0 auto",
    position: "relative",
    width: "100%",
  },
  expandNetworkWrapper: {
    alignItems: "center",
    background: "#FFFFFF",
    border: "2px solid #E2E8F0",
    borderRadius: "5px",
    padding: "15px",
    textAlign: "center",
    margin: "5px",
    marginTop: "10px",
  },
  styledLink: {
    cursor: "pointer",
    fontWeight: 600,
    display: "inline-block",
    color: primaryColor,
  },
  styledImage: {
    width: 10,
    marginLeft: 5,
    marginRight: 10,
  },
}))

interface FeatureListItemProps {
  classes: ReturnType<typeof useStyles>
  feature: PhlebotomistFeature
  selectedFeature?: PhlebotomistFeature
  onItemClick: (id: string) => void
}

const FeatureListItem = ({
  feature,
  classes,
  selectedFeature,
  onItemClick,
}: FeatureListItemProps) => {
  const showPhlebDistance =
    feature.properties.distance && feature.properties.distance !== 0

  const isSelectedFeature = (): boolean => {
    // Same feature, show selected
    if (selectedFeature?.properties?.id === feature.properties?.id) {
      return true
    }

    return false
  }

  return (
    <div
      className={clsx(classes.listItem, {
        [classes.selectedListItem]: isSelectedFeature(),
      })}
      onClick={() => onItemClick(feature.properties?.id)}
    >
      <div>
        {feature.properties.location_types && (
          <div className={classes.circleIcon}>
            <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
              <circle
                cx="50"
                cy="50"
                r="50"
                fill={markerTypeMapping[feature.properties.location_types][0]}
              />
            </svg>
            <img
              src={markerTypeMapping[feature.properties.location_types][1]}
              alt=""
              className={classes.styledMapMarkerTypeImage}
            />
          </div>
        )}
      </div>
      <div>
        <BodyText
          weight="semibold"
          className={clsx({
            [classes.selectedText]: isSelectedFeature(),
          })}
        >
          {feature.properties.name}
        </BodyText>
        <BodyText
          className={clsx({
            [classes.selectedText]: isSelectedFeature(),
          })}
        >
          {showPhlebDistance && (
            <span>
              {feature.properties.location_types.replace(",", " &")}
              {feature.properties.location_types !== "Mobile" ? (
                <span>
                  {" "}
                  • {Math.round(Number(feature.properties.distance))}mi away{" "}
                </span>
              ) : (
                ""
              )}
            </span>
          )}
          {Boolean(feature.properties.approximate_fee) && (
            <span>${feature.properties.approximate_fee} Approximate Fee.</span>
          )}
        </BodyText>
      </div>
    </div>
  )
}

interface FeatureListProps {
  classes: ReturnType<typeof useStyles>
  features: PhlebotomistFeature[]
  isListOpen?: boolean
  onItemClick: (id: string) => void
  searchQuery?: string
  selectedFeature?: PhlebotomistFeature
}

const FeatureList = ({
  classes,
  features,
  isListOpen = true,
  onItemClick,
  searchQuery,
  selectedFeature,
}: FeatureListProps) => {
  const phlebRequestMsg =
    "Hello, I am interested in finding phlebotomists within an area." +
    " I used the Phlebotomy Network tool to browse phlebotomists near" +
    ` ${searchQuery}, but was unable to find sufficient options.` +
    " Would you be able to look into adding additional options within this area?"

  return (
    <Collapse
      classes={{
        entered: classes.listCollapseEntered,
      }}
      className={classes.listCollapse}
      in={isListOpen}
    >
      {features.map((feature) => {
        return (
          <FeatureListItem
            key={feature.properties.id}
            classes={classes}
            feature={feature}
            onItemClick={onItemClick}
            selectedFeature={selectedFeature}
          />
        )
      })}
      <div className={classes.expandNetworkWrapper}>
        <BodyText>
          We are always expanding our network. Message us to request more
          phlebotomists in this area.
        </BodyText>
        <Link
          className={classes.styledLink}
          onClick={() => {
            window.Intercom("showNewMessage", phlebRequestMsg)
          }}
        >
          Message Rupa
          <img
            src={UpRightArrow}
            alt="up-right-arrow"
            className={classes.styledImage}
          />
        </Link>
      </div>
    </Collapse>
  )
}

interface PhlebotomistMapListViewProps {
  features: PhlebotomistFeature[]
  geocoderContainerRef: RefObject<HTMLDivElement>
  hasCurrentSearch: boolean
  isListOpen?: boolean
  isWaitingOnSearchResults?: boolean
  onItemClick: (id: string) => void
  onListToggle: () => void
  searchQuery?: string
  selectedFeature?: PhlebotomistFeature
}

/**
 * List of currently viewed phlebotomy locations as well as search bar
 *
 * This component is meant to be rendered within the PhlebotomistMap
 *
 * @component
 */
const PhlebotomistMapListView = ({
  features,
  isListOpen = true,
  isWaitingOnSearchResults = false,
  hasCurrentSearch = false,
  geocoderContainerRef,
  onItemClick,
  onListToggle,
  selectedFeature,
  searchQuery,
}: PhlebotomistMapListViewProps) => {
  const classes = useStyles()

  // Features sorted by distance
  const featuresSortedByDistance = useMemo(() => {
    return sortBy(features, [
      (feature) => {
        if (feature.properties.distance) {
          return feature.properties.distance
        }
      },
    ])
  }, [features])

  return (
    <div className={classes.listView}>
      <div className={classes.geocoderContainer} ref={geocoderContainerRef} />
      {hasCurrentSearch && isWaitingOnSearchResults && (
        <div className={classes.loadingContainer}>
          <CircularProgress aria-label="Loading Locations" size={16} />
        </div>
      )}
      {hasCurrentSearch && !isWaitingOnSearchResults && (
        <>
          <div className={classes.listHeader}>
            <BodyText
              className={classes.overviewText}
              weight="semibold"
              size="sm"
            >
              {featuresSortedByDistance.length} Results Nearby
            </BodyText>
            <Button
              className={classes.toggleControl}
              color="text"
              disableRipple={true}
              onClick={() => onListToggle()}
              size="small"
              variant="text"
            >
              <div>{isListOpen ? "Collapse" : "Expand"}</div>
              <img
                src={expandCaretIcon}
                className="ml-1"
                alt={""}
                style={{
                  transform: `rotate(${isListOpen ? "0deg" : "180deg"})`,
                }}
              />
            </Button>
          </div>
          <FeatureList
            classes={classes}
            features={featuresSortedByDistance}
            isListOpen={isListOpen}
            selectedFeature={selectedFeature}
            searchQuery={searchQuery}
            onItemClick={onItemClick}
          />
        </>
      )}
    </div>
  )
}

export default PhlebotomistMapListView
