import { useState } from "react"
import * as React from "react"
import { useDispatch } from "react-redux"

import { Grid } from "@material-ui/core"
import { Skeleton } from "@material-ui/lab"

import { API } from "app/api"
import Tooltip from "app/components/Tooltip"
import BodyText from "app/components/design-system/BodyText"
import GenericConfirmationModal from "app/components/modals/GenericConfirmationModal"
import { CUSTOM_FEE_TYPE } from "app/constants"
import {
  CREATE_CUSTOM_FEE,
  CREATE_STRIPE_CONNECT_ACCOUNT,
  UPDATE_CUSTOM_FEE,
} from "app/store/actions"
import { Clinic, CustomFee, StripeConnectAccount } from "app/types"

import { StyledFormControlLabel } from "../components/StyledFormControlLabel"
import { StyledSwitch } from "../components/StyledSwitch"
import CustomFeeForm from "./CustomFeeForm"
import CustomFeesOnboardingModal from "./CustomFeesOnboardingModal"
import SignInToStripeLink from "./SignInToStripeLink"
import StripeConnectAccountBadge from "./StripeConnectAccountBadge"

type CustomFeesSettingsProps = {
  clinic?: Clinic
  customFee?: CustomFee
  stripeConnectAccount?: StripeConnectAccount
  toggleCustomFees: (value: boolean) => void
  title: string
  disabledReason?: string
}

const CustomFeesSettings: React.FC<CustomFeesSettingsProps> = ({
  clinic,
  toggleCustomFees,
  customFee,
  stripeConnectAccount,
  title,
  disabledReason,
}) => {
  const [onboardingModalOpen, setOnboardingModalOpen] = useState(false)
  const [disableCustomFeesModalOpen, setDisableCustomFeesModalOpen] =
    useState(false)

  const dispatch = useDispatch()

  const createOrUpdateCustomFee = async (
    account: StripeConnectAccount,
    customFee: CustomFee | undefined,
    { price, name }: Omit<Omit<CustomFee, "id">, "application_fee_percentage">
  ) => {
    if (!customFee) {
      const response = await API.CustomFee.post(
        account.id,
        name,
        price,
        CUSTOM_FEE_TYPE.WHOLE_ORDER
      )
      dispatch({
        type: CREATE_CUSTOM_FEE,
        payload: response.data,
      })
    } else {
      const response = await API.CustomFee.put(customFee.id, name, price)

      dispatch({
        type: UPDATE_CUSTOM_FEE,
        payload: response.data,
      })
    }
  }

  const createStripeConnectAccount =
    async (): Promise<StripeConnectAccount> => {
      const response = await API.StripeConnectAccount.post()

      dispatch({
        type: CREATE_STRIPE_CONNECT_ACCOUNT,
        payload: response.data,
      })

      return response.data
    }

  const getDashboardLink = async (
    account: StripeConnectAccount
  ): Promise<string> => {
    const response = await API.StripeConnectAccount.dashboardLink(account.id)
    return response.data.link
  }

  return (
    <>
      <Grid container direction="row" spacing={2}>
        <Grid item md={8} xs={12}>
          <BodyText weight="semibold" size="lg">
            {title}
          </BodyText>
          {clinic ? (
            <StyledFormControlLabel
              control={
                <Tooltip
                  title={disabledReason ?? ""}
                  disableHoverListener={!Boolean(disabledReason)}
                  disableFocusListener={!Boolean(disabledReason)}
                  disableTouchListener={!Boolean(disabledReason)}
                  placement="bottom"
                  arrow
                >
                  <div>
                    <StyledSwitch
                      onChange={() => {
                        // If previously disabled, open the onboarding modal.
                        if (!clinic?.custom_fees_enabled) {
                          setOnboardingModalOpen(true)
                        } else {
                          setDisableCustomFeesModalOpen(true)
                        }
                      }}
                      checked={clinic?.custom_fees_enabled}
                      name="custom_fees_enabled"
                      disabled={Boolean(disabledReason)}
                    />
                  </div>
                </Tooltip>
              }
              checked
              label={
                <div>
                  <BodyText weight="semibold">
                    Include your own fee in lab orders
                  </BodyText>
                  <BodyText>
                    You can add your own custom fee to patient-pay orders
                    through Rupa. Patients will see this on checkout, and Rupa
                    will pay you out for custom fees once these orders are
                    completed.{" "}
                    {stripeConnectAccount && (
                      <SignInToStripeLink
                        getDashboardLink={() =>
                          getDashboardLink(stripeConnectAccount)
                        }
                      />
                    )}
                  </BodyText>
                </div>
              }
            />
          ) : (
            <Skeleton height={10} width={200} variant="rect" />
          )}

          {clinic?.custom_fees_enabled && (
            <>
              <StripeConnectAccountBadge
                stripeConnectAccount={stripeConnectAccount}
              />

              <CustomFeeForm
                customFee={customFee}
                stripeConnectAccount={stripeConnectAccount}
                createOrUpdateCustomFee={createOrUpdateCustomFee}
              />
            </>
          )}
        </Grid>
      </Grid>

      <CustomFeesOnboardingModal
        open={onboardingModalOpen}
        onClose={() => setOnboardingModalOpen(false)}
        customFee={customFee}
        stripeConnectAccount={stripeConnectAccount}
        createStripeConnectAccount={createStripeConnectAccount}
        createOrUpdateCustomFee={createOrUpdateCustomFee}
        getDashboardLink={getDashboardLink}
        enableCustomFee={() => toggleCustomFees(true)}
        isClinicLevelSetup={true}
      />

      <GenericConfirmationModal
        open={disableCustomFeesModalOpen}
        data={{
          title: "Turn off Custom Fees",
          text: `Turning off custom fees will disable this feature for your entire clinic.`,
          buttonText: "Disable Custom Fees",
        }}
        onClose={() => setDisableCustomFeesModalOpen(false)}
        actionFn={() => {
          toggleCustomFees(false)
          setDisableCustomFeesModalOpen(false)
        }}
        actionButtonColor="destructiveSecondary"
      />
    </>
  )
}

export default CustomFeesSettings
