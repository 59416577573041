import { useState } from "react"

import InfoIcon from "app/assets/icons/info-gray-circle.svg"
import { ReactComponent as CarrotRightIcon } from "app/assets/icons/labshops/carrot-right.svg"
import Tooltip from "app/components/Tooltip"
import BodyText from "app/components/design-system/BodyText"
import DesignSystemButton from "app/components/design-system/Button"
import {
  physAuthTooltipText,
  PHYSICIAN_SERVICES_FEE_AMOUNT,
  VENDOR_PHYSICIAN_AUTHORIZATION_LABEL,
} from "app/constants"
import { colors } from "app/theme"
import { formatDollars } from "app/utils"
import makeAppStyles from "app/utils/makeAppStyles"

const useStyles = makeAppStyles({
  priceBreakdownDivider: {
    width: "100%",
    marginTop: 18,
    marginBottom: 12,
    position: "relative",
  },
  lineThrough: {
    height: 1,
    width: "100%",
    background: colors.blueGray[300],
  },
  priceBreakdownButton: {
    color: colors.blueGray[500],
    position: "absolute",
    top: -8,
    marginLeft: "auto",
    marginRight: "auto",
    left: 0,
    right: 0,
    background: "#f8f8f9",
    width: "fit-content",
    padding: "0px 5px",
    "&:hover": {
      background: "#f8f8f9",
    },
  },
  priceBreakdownContainer: {
    display: "flex",
    "flex-direction": "column",
    gap: 7,
    height: "100%",
    opacity: 1,
    transition: "all 100ms",
  },
  priceBreakdownContainerHidden: {
    display: "flex",
    "flex-direction": "column",
    gap: 7,
    height: 0,
    opacity: 0,
    transition: "all 100ms",
  },
  priceBreakdownRow: {
    display: "flex",
    "flex-direction": "row",
    justifyContent: "space-between",
  },
  closedCarrot: {
    transform: "rotate(90deg)",
    marginLeft: 8,
    position: "relative",
    top: -1,
    transition: "transform 250ms",
  },
  openCarrot: {
    transform: "rotate(-90deg)",
    marginLeft: 8,
    position: "relative",
    top: -1,
    transition: "transform 250ms",
  },
})

const PriceBreakdown = ({
  rupaPrice,
  customFeePrice,
  customFeeName,
  clinicName,
  hidePhysServicesPricing,
}) => {
  const classes = useStyles()

  const [showBreakdown, setShowBreakdown] = useState(false)

  return (
    <>
      <div className={classes.priceBreakdownDivider}>
        <div className={classes.lineThrough}></div>
        <DesignSystemButton
          color="text"
          className={classes.priceBreakdownButton}
          onClick={() => setShowBreakdown(!showBreakdown)}
        >
          Price Breakdown{" "}
          <CarrotRightIcon
            className={
              showBreakdown ? classes.openCarrot : classes.closedCarrot
            }
            fill={colors.blueGray[500]}
          />
        </DesignSystemButton>
      </div>

      <div
        className={
          showBreakdown
            ? classes.priceBreakdownContainer
            : classes.priceBreakdownContainerHidden
        }
      >
        <div className={classes.priceBreakdownRow}>
          <BodyText>Rupa Price</BodyText>
          <BodyText>{formatDollars(rupaPrice)}</BodyText>
        </div>
        {customFeePrice > 0 && (
          <div className={classes.priceBreakdownRow}>
            <BodyText>{`${customFeeName} going to ${clinicName}`}</BodyText>
            <BodyText>{formatDollars(customFeePrice / 100)}</BodyText>
          </div>
        )}
        {!hidePhysServicesPricing && (
          <div className={classes.priceBreakdownRow}>
            <Tooltip
              title={<PhysAuthFeeTooltip />}
              placement="bottom"
              arrow
              interactive
            >
              <div
                className="flex flex-row justify-start"
                style={{ alignItems: "flex-start", paddingRight: 25 }}
              >
                Physician Services
                <img
                  src={InfoIcon}
                  alt=""
                  className="ml-2"
                  width={18}
                  style={{ position: "relative", top: 2 }}
                />
              </div>
            </Tooltip>
            <BodyText>{formatDollars(PHYSICIAN_SERVICES_FEE_AMOUNT)}</BodyText>
          </div>
        )}
      </div>
    </>
  )
}

export const PhysAuthFeeTooltip = () => (
  <>
    <BodyText size="sm" weight="bold">
      About {VENDOR_PHYSICIAN_AUTHORIZATION_LABEL}
    </BodyText>

    <BodyText size="sm">{physAuthTooltipText}</BodyText>
  </>
)

export default PriceBreakdown
