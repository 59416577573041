import React, { useMemo } from "react"

import { differenceBy, some, sum, sumBy } from "lodash"

import { Button } from "@material-ui/core"
import Grow from "@material-ui/core/Grow"
import Paper from "@material-ui/core/Paper"
import Typography from "@material-ui/core/Typography"
import AddIcon from "@material-ui/icons/Add"

import { DuplicateBiomarkersCheckoutWarning } from "app/components/CheckoutWarning/DuplicateBiomarkersCheckoutWarning"
import {
  StaticCheckoutWarning,
  StaticCheckoutWarningTypes,
} from "app/components/CheckoutWarning/StaticCheckoutWarning"
import {
  LABCORP_COMPANY_KEYS,
  ORDER_LINE_ITEM_TYPE,
  QUEST_COMPANY_KEYS,
} from "app/constants"
import {
  INDIVIDUAL_CARD_KEY_PREFIX,
  LAB_COMPANY_PANELS,
  getTestsCausingDuplicateBiomarkers,
  groupSelectedLabTests,
  hasAMLAndLabcorpByAMLTests,
  hasBioReferencePhlebTests,
  hasDHAAndLabcorpPhlebTests,
  hasLTAQuestTests,
  hasMultipleBloodLabTests,
  hasVibrantTests,
} from "app/dataServices/labTestDataService"
import {
  AnyLabTest,
  AnyLimitedLabTest,
  LabCompany,
  LabTest,
  labTestLocation,
} from "app/types"
import { formatDollars } from "app/utils"

import { InfoMessage } from "./InfoMessage"
import { LabTestItem } from "./LabTestItem"
import { PanelItem } from "./PanelItem"
import { PhlebotomyRequired } from "./PhlebotomyRequired"

const REMOVE_TIMEOUT_DURATION = 250

interface BloodDrawLineItem {
  id: string
  title: string
  type: string
  cost: number
}

const questDrawLineItem: BloodDrawLineItem = {
  id: "quest_draw",
  title: "Blood Draw",
  type: ORDER_LINE_ITEM_TYPE.QUEST_BLOOD_DRAW_FEE,
  // NOTE: This MUST equal LABSFIRST_BLOOD_DRAW_TOTAL_FEE in `pricing.py`
  cost: 10.0,
}

const labcorpDrawLineItem: BloodDrawLineItem = {
  id: "labcorp_draw",
  title: "Blood Draw at Labcorp",
  type: ORDER_LINE_ITEM_TYPE.LABCORP_BLOOD_DRAW_FEE,
  // NOTE: This MUST equal LABCORP_VIA_ACCESS_MEDICAL_PHLEBOTOMY_FEE in `pricing.py`
  cost: 10.0,
}

interface Props {
  selectedLabTests: LabTest[]
  openModalWithLabTest: (labTest: AnyLabTest) => void
  handleRemove: (labTest: AnyLimitedLabTest) => void
  handleRemovePanel: (labTests: AnyLimitedLabTest[]) => void
  location: labTestLocation
}

export const BundleTestList: React.FC<Props> = ({
  selectedLabTests,
  openModalWithLabTest,
  handleRemove,
  handleRemovePanel,
  location,
}) => {
  const lineItemsForLabCompanies = useMemo(() => {
    // For Quest and Labcorp, we want to include the blood draw fee within the panel. But as the panel
    // builder currently deals only in LabTests and not line items, we have to manually construct them.
    const _lineItemsForLabCompanies: Record<string, BloodDrawLineItem[]> = {}

    for (const labTest of selectedLabTests) {
      const labCompanyKey = labTest.lab_company.key

      if (QUEST_COMPANY_KEYS.includes(labCompanyKey)) {
        _lineItemsForLabCompanies[labCompanyKey] = [questDrawLineItem]
      }

      if (LABCORP_COMPANY_KEYS.includes(labCompanyKey)) {
        _lineItemsForLabCompanies[labCompanyKey] = [labcorpDrawLineItem]
      }
    }

    return _lineItemsForLabCompanies
  }, [selectedLabTests])

  const total =
    sumBy(selectedLabTests, function (o) {
      return parseFloat(o.rupa_price)
    }) +
    sum(
      Object.values(lineItemsForLabCompanies).flatMap((lineItems) =>
        lineItems.map((lineItem) => lineItem.cost)
      )
    )

  const groupedTests = groupSelectedLabTests(selectedLabTests)

  const vibrantInfoContainer =
    total < 200 && hasVibrantTests(selectedLabTests) ? (
      <InfoMessage message="Vibrant has a small order fee for orders under $200" />
    ) : null

  const scarletInfoContainer = hasBioReferencePhlebTests(selectedLabTests) ? (
    <InfoMessage message="There is an additional $25 Scarlet mobile phlebotomy fee for BioReference testing." />
  ) : null

  const multipleBloodLabWarning = useMemo(
    () => hasMultipleBloodLabTests(selectedLabTests),
    [selectedLabTests]
  ) ? (
    <StaticCheckoutWarning
      type={StaticCheckoutWarningTypes.MULTIPLE_BLOOD_LABS}
    />
  ) : null

  const orderWithLTAQuestTests = useMemo(
    () => hasLTAQuestTests(selectedLabTests),
    [selectedLabTests]
  ) ? (
    <StaticCheckoutWarning
      type={StaticCheckoutWarningTypes.ORDER_WITH_LTA_QUEST_TEST}
    />
  ) : null

  const mixedAMLOrderWarning = useMemo(
    () => hasAMLAndLabcorpByAMLTests(selectedLabTests),
    [selectedLabTests]
  ) ? (
    <StaticCheckoutWarning type={StaticCheckoutWarningTypes.MIXED_AML_ORDER} />
  ) : null

  const mixedDhaAndLabcorpOrderWarning = useMemo(
    () => hasDHAAndLabcorpPhlebTests(selectedLabTests),
    [selectedLabTests]
  ) ? (
    <StaticCheckoutWarning
      type={StaticCheckoutWarningTypes.MIXED_DHA_LABCORP_ORDER}
    />
  ) : null

  const duplicateBiomarkerWarnings = useMemo(() => {
    const duplicatesByLabCompany =
      getTestsCausingDuplicateBiomarkers(selectedLabTests)
    return Object.entries(duplicatesByLabCompany).map(
      ([labCompanyName, testsData]) => {
        const testsCausingDuplicates = Object.entries(testsData).map(
          ([testName, biomarkers]) => ({
            testName,
            biomarkersList: biomarkers,
          })
        )

        return (
          <DuplicateBiomarkersCheckoutWarning
            labCompanyName={labCompanyName}
            testsCausingDuplicates={testsCausingDuplicates}
          />
        )
      }
    )
  }, [selectedLabTests])

  return (
    <div>
      {Object.entries(groupedTests).map(([groupKey, tests]) => {
        // Tests not grouped by anything
        if (groupKey.startsWith(INDIVIDUAL_CARD_KEY_PREFIX)) {
          return tests.map((test) => (
            <LabTestItem
              key={test.id}
              labTest={test}
              title={test.name}
              cost={test.msrp_price}
              discountedCost={
                test.msrp_price !== test.rupa_price ? test.rupa_price : ""
              }
              labCompany={test.lab_company}
              onRemoveTest={() => handleRemove(test)}
              insideBundleModal={true}
              location={location}
            />
          ))
        }

        // Blood panels
        if (LAB_COMPANY_PANELS.includes(groupKey)) {
          const panelLabCompany = tests[0]?.lab_company
          const panelTitle = `${groupKey} Panel${
            panelLabCompany.only_in_house_phlebotomy ? "" : " (1 kit)"
          }`

          return (
            <PanelCard
              labTests={tests}
              lineItems={lineItemsForLabCompanies[panelLabCompany.key] || []}
              panelName={panelTitle}
              labCompany={panelLabCompany}
              onRemoveTest={handleRemove}
              handleRemovePanel={handleRemovePanel}
              key={panelLabCompany.id}
            />
          )
        }

        // Tests with add-ons
        const parentLabTestId = groupKey
        const parentLabTest = tests.find(
          (labTest) => labTest.id === parentLabTestId
        )

        return (
          <PanelCard
            labTests={tests}
            bundleTests={selectedLabTests}
            panelName={parentLabTest?.name ?? ""}
            labCompany={parentLabTest?.lab_company}
            onRemoveTest={handleRemove}
            handleRemovePanel={handleRemovePanel}
            parentLabTest={parentLabTest}
            openModalWithLabTest={openModalWithLabTest}
            key={parentLabTestId}
          />
        )
      })}

      {vibrantInfoContainer}
      {scarletInfoContainer}
      {multipleBloodLabWarning}
      {orderWithLTAQuestTests}
      {mixedAMLOrderWarning}
      {mixedDhaAndLabcorpOrderWarning}
      {duplicateBiomarkerWarnings}
      {selectedLabTests.length !== 0 && (
        <div className="flex flex-row flex-grow justify-between border-gray-300 py-2 mt-2 border-t border-b">
          <Typography className="text-base font-semibold text-gray-800">
            Total
          </Typography>
          <Typography className="text-base font-semibold inline text-gray-800">
            {formatDollars(total)}
          </Typography>
        </div>
      )}
    </div>
  )
}

interface PanelCardProps {
  labTests: LabTest[]
  bundleTests?: LabTest[]
  panelName: string | undefined
  labCompany: LabCompany | undefined
  onRemoveTest: (labTest: AnyLimitedLabTest) => void
  handleRemovePanel: (labTests: AnyLimitedLabTest[]) => void
  parentLabTest?: LabTest
  openModalWithLabTest?: (labTest: AnyLabTest) => void
  lineItems?: BloodDrawLineItem[]
}

const PanelCard: React.FC<PanelCardProps> = ({
  labTests,
  bundleTests = [],
  panelName,
  labCompany,
  onRemoveTest,
  handleRemovePanel,
  parentLabTest,
  openModalWithLabTest,
  lineItems = [],
}) => {
  const lineItemCost = sum(lineItems.map((lineItem) => lineItem.cost))

  const totalRupaCost = formatDollars(
    sum(labTests.map((labTest) => parseFloat(labTest.rupa_price))) +
      lineItemCost
  )
  const totalMsrpCost = formatDollars(
    sum(
      labTests.map((labTest) =>
        parseFloat(labTest.msrp_price || labTest.rupa_price)
      )
    ) + lineItemCost
  )

  const availableAddOns = differenceBy(
    parentLabTest?.add_ons.map((addOn) => addOn.name),
    bundleTests.map((t) => t.name)
  )
  const hasMoreAddons = !!availableAddOns.length

  const phlebotomyRequired = some(
    labTests.map((labTest) =>
      some(labTest.lab_test_types.map((t) => t.phlebotomy_required))
    )
  )

  return (
    <Grow
      in={true}
      style={{ transformOrigin: "50% 0 0" }}
      timeout={REMOVE_TIMEOUT_DURATION}
    >
      <Paper
        className="bg-gray-50 rounded-lg px-4 py-3 flex flex-col mb-4 border-b-4 border-gray-400"
        elevation={0}
      >
        <div className="flex">
          <div className="flex flex-grow">
            <div>
              <Typography className="text-base font-bold text-gray-800 flex-grow">
                {panelName}
              </Typography>
              <Typography className="text-base text-gray-700">
                {labCompany?.name}
              </Typography>
            </div>
            {phlebotomyRequired && (
              <PhlebotomyRequired labCompany={labCompany} />
            )}
          </div>
          <div className="mb-4">
            <Typography className="font-bold text-lg" align="right">
              {totalRupaCost !== totalMsrpCost ? (
                <>
                  <span className="text-gray-500 line-through">
                    {totalMsrpCost}
                  </span>
                  <span className="ml-2">{totalRupaCost}</span>
                </>
              ) : (
                <span className="ml-2">{totalRupaCost}</span>
              )}
            </Typography>
            <div
              onClick={() => handleRemovePanel(labTests)}
              className="cursor-pointer font-semibold text-base text-right"
              style={{ color: "#0073ca" }}
            >
              Remove
            </div>
          </div>
        </div>
        {labTests
          .filter((labTest) => {
            if (parentLabTest) {
              return labTest.id !== parentLabTest.id
            }

            return true
          })
          .map((labTest) => {
            // For both Labcorp and Quest, we don't want to show biomarkers within the panel. We still
            // want them to contribute towards the total panel price, so we just don't render them by
            // filtering them out here.
            const labCompanyKey = labTest.lab_company.key
            const isInQuest = QUEST_COMPANY_KEYS.includes(labCompanyKey)

            return (
              <div key={labTest.id} className="mt-1">
                <PanelItem
                  title={labTest.name}
                  cost={labTest.rupa_price}
                  discountedCost={
                    labTest.msrp_price !== labTest.rupa_price
                      ? labTest.rupa_price
                      : ""
                  }
                  showPricing={!isInQuest}
                  onRemove={() => onRemoveTest(labTest)}
                />
              </div>
            )
          })}
        {lineItems.map((lineItem) => (
          <div key={lineItem.id} className="mt-1">
            <PanelItem
              title={lineItem.title}
              cost={lineItem.cost}
              showPricing={
                lineItem.type !== ORDER_LINE_ITEM_TYPE.QUEST_BLOOD_DRAW_FEE
              }
            />
          </div>
        ))}
        {hasMoreAddons && openModalWithLabTest && parentLabTest && (
          <Button
            variant="outlined"
            size="small"
            color="primary"
            className="mt-1 mb-1 bg-white"
            fullWidth
            startIcon={<AddIcon />}
            onClick={() => openModalWithLabTest(parentLabTest)}
          >
            Add-on Tests Available
          </Button>
        )}
      </Paper>
    </Grow>
  )
}
