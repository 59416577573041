import moment from "moment"

import { Theme, useMediaQuery } from "@material-ui/core"

import couponTagIcon from "app/assets/icons/coupon-tag-teal.svg"
import disabledCouponTagIcon from "app/assets/icons/coupon-tag.svg"
import PromotionsCouponStartOrderButton from "app/components/Promotions/PromotionsCouponStartOrderButton"
import SelfOrderOnlyPill from "app/components/Promotions/SelfOrderOnlyPill"
import BodyText from "app/components/design-system/BodyText"
import { colors, lightRupaTeal } from "app/theme"
import { Coupon } from "app/types"
import makeAppStyles from "app/utils/makeAppStyles"

import FirstOrderOnlyPill from "./FirstOrderOnlyPill"

const useStyles = makeAppStyles((theme) => ({
  tableRow: {
    backgroundColor: "white",
    "&:not(:last-of-type)": {
      borderBottom: `1px solid ${colors.blueGray[200]}`,
    },
    display: "flex",
    justifyContent: "space-between",
    padding: "30px 22px",
    [theme.breakpoints.down("sm")]: {
      "flex-direction": "column",
      gap: 23,
    },
  },
  tableCell: {
    borderBottom: "none",
    width: "max-content",
    display: "flex",
    gap: 23,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  couponButton: {
    textAlign: "center",
    width: 165,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  logo: {
    marginRight: 0,
    marginLeft: "auto",
    maxWidth: 150,
    [theme.breakpoints.down("sm")]: {
      marginTop: 10,
      marginLeft: 0,
    },
  },
  iconContainer: {
    padding: 14.5,
    background: lightRupaTeal,
    display: "inline-block",
    width: "45px",
    height: "45px",
    borderRadius: 8,
    "&.disabled": {
      backgroundColor: colors.blueGray[50],
    },
  },
}))

interface Props {
  coupon: Coupon
  index: number
  isAvailable: boolean
}

function PromotionsCouponsTableRow(props: Props) {
  const classes = useStyles()

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"))

  const labCompany = props.coupon.lab_test
    ? props.coupon.lab_test.lab_company
    : props.coupon.lab_company
    ? props.coupon.lab_company
    : null

  return (
    <div className={classes.tableRow}>
      <div className={classes.tableCell}>
        <div>
          {props.isAvailable ? (
            <div className={classes.iconContainer}>
              <img src={couponTagIcon} alt="tag" />
            </div>
          ) : (
            <div className={`disabled ${classes.iconContainer}`}>
              <img src={disabledCouponTagIcon} alt="tag" />
            </div>
          )}
        </div>
        <div>
          <BodyText weight="semibold">
            {props.coupon.practitioner_title}
          </BodyText>
          <BodyText size="sm" style={{ marginTop: 3 }}>
            {props.coupon.practitioner_subtitle}
          </BodyText>
          {props.coupon.is_practitioner_self_order_only && (
            <SelfOrderOnlyPill />
          )}
          {props.coupon.is_first_order_only && <FirstOrderOnlyPill />}
          {labCompany && labCompany.logo && isMobile && (
            <div className={classes.logo}>
              <img src={labCompany.logo} alt={labCompany.name} />
            </div>
          )}
        </div>
      </div>
      <div className={classes.tableCell}>
        {labCompany && labCompany.logo && !isMobile && (
          <div className={classes.logo}>
            <img src={labCompany.logo} alt={labCompany.name} />
          </div>
        )}
        {props.isAvailable ? (
          <div className={classes.couponButton}>
            <PromotionsCouponStartOrderButton coupon={props.coupon} />
            {!isMobile && (
              <BodyText size="sm">
                {moment(props.coupon.end_date).format("LL") === "Invalid date"
                  ? "Never Expires"
                  : `Expires ${moment(props.coupon.end_date).format("LL")}`}
              </BodyText>
            )}
          </div>
        ) : (
          <div className={classes.tableCellExpired} style={{ width: 207.5 }}>
            <BodyText size="sm" style={{ fontStyle: "italic" }}>
              {moment(props.coupon.end_date) < moment()
                ? "Code Expired"
                : props.coupon.is_first_order_only
                ? "Unavailable"
                : "Code Used"}
            </BodyText>
          </div>
        )}
      </div>
    </div>
  )
}

export default PromotionsCouponsTableRow
