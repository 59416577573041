import { useState } from "react"

import clsx from "clsx"

import { DialogTitle } from "@material-ui/core"
import { IconButton, styled } from "@material-ui/core"
import CloseIcon from "@material-ui/icons/Close"

import AddIcon from "app/assets/icons/add-blue.svg"
import PendingPlaceholder from "app/components/LabTests/PendingPlaceholder"
import Tooltip from "app/components/Tooltip"
import Button from "app/components/design-system/Button"
import DisplayText from "app/components/design-system/DisplayText"
import { colors, navy } from "app/theme"
import { formatDollars } from "app/utils"
import makeAppStyles from "app/utils/makeAppStyles"
import { LabTest } from "types/lab-test"

import ComboGroupConfigurationValidationDetails from "./ComboGroupConfigurationValidationDetails"

const useStyles = makeAppStyles((theme) => ({
  dialogTitleContainer: {
    borderBottomWidth: 1,
    borderColor: colors.blueGray[300],
  },
  dialogTitle: {
    display: "flex",
    flexFlow: "row nowrap",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "white",
    padding: theme.spacing(2.0),
    paddingBottom: 0,
    gap: 10,
  },
  mainGroup: {
    display: "flex",
    flexFlow: "row wrap",
    gap: 15,
    flexGrow: 100,
  },
  titleText: {
    flex: "1 1 auto",
    fontSize: 19,
    lineHeight: 1.35,
    marginTop: 3,
  },
  spacer: {
    flexGrow: 100,
  },
  originalPrice: {
    textDecoration: "line-through",
    color: colors.blueGray[400],
  },
  leftGroup: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    flexWrap: "nowrap",
    justifyContent: "flexstart",
    gap: 5,
    flexGrow: 100,
    paddingRight: 25,
  },
  addToCartContainer: {
    flexGrow: 1,
  },
  addToCartButton: {
    width: 170,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  addToCartIcon: {
    marginTop: -3,
    width: 12,
  },
  configurationValidationDetails: {
    paddingTop: 0,
  },
}))

const CloseButton = styled(IconButton)(({ theme }) => ({
  color: navy,
  position: "absolute",
  top: 1,
  right: 12,
  marginRight: -12,

  [theme.breakpoints.up("sm")]: {
    position: "relative",
    right: 0,
  },
}))

export interface ComboGroupModalHeaderProps {
  updating: boolean
  targetLabTest?: LabTest
  onClose: () => void
  optionsSumCost: number
  addOnsSumCost: number
  validConfiguration: boolean
  configurationWarningMessage: string
  onAddToCart: () => void
  isLoading: boolean
  selectedOptionLabTestIds: string[]
  optionLabTests?: LabTest[]
  availableTargets?: LabTest[]
}

export function ComboGroupModalHeader({
  updating,
  targetLabTest,
  onClose,
  optionsSumCost,
  addOnsSumCost,
  validConfiguration,
  configurationWarningMessage,
  onAddToCart,
  isLoading,
  selectedOptionLabTestIds,
  optionLabTests,
  availableTargets,
}: ComboGroupModalHeaderProps) {
  const classes = useStyles()

  const [addToCartLoading, setAddToCartLoading] = useState(false)

  const msrpPriceAsNumber = optionsSumCost + addOnsSumCost
  const msrpPrice = formatDollars(msrpPriceAsNumber)
  const comboPrice = Number(targetLabTest?.attributes.rupa_price) ?? 0
  const discountedPriceAsNumber = comboPrice + addOnsSumCost
  const discountedPrice = formatDollars(discountedPriceAsNumber)

  const handleOnAddToCart = () => {
    setAddToCartLoading(true)
    onAddToCart()
  }

  const buttonDisabled = !validConfiguration || isLoading

  return (
    <div className={classes.dialogTitleContainer}>
      <DialogTitle className={classes.dialogTitle} disableTypography>
        <div className={classes.mainGroup}>
          <div className={classes.leftGroup}>
            {isLoading ? (
              <PendingPlaceholder width={200} />
            ) : (
              <DisplayText
                className={classes.titleText}
                weight="semibold"
                size="lg"
              >
                {targetLabTest?.attributes.name ?? "--"}
              </DisplayText>
            )}
            {isLoading ? (
              <PendingPlaceholder width={100} />
            ) : (
              msrpPriceAsNumber > discountedPriceAsNumber &&
              msrpPriceAsNumber > 0 && (
                <DisplayText
                  className={clsx(classes.titleText, classes.originalPrice)}
                  weight="semibold"
                  size="lg"
                >
                  {msrpPrice}
                </DisplayText>
              )
            )}
            {isLoading ? (
              <PendingPlaceholder width={100} />
            ) : (
              <DisplayText
                className={classes.titleText}
                weight="semibold"
                size="lg"
              >
                {discountedPrice}
              </DisplayText>
            )}
            <div className={classes.spacer} />
          </div>
          <Tooltip
            title={configurationWarningMessage}
            placement="bottom"
            arrow
            key="title-button-and-tooltip"
            interactive
            disableFocusListener={validConfiguration}
            disableHoverListener={validConfiguration}
            disableTouchListener={validConfiguration}
          >
            <div className={classes.addToCartContainer}>
              <Button
                className={classes.addToCartButton}
                aria-label="Save & Close"
                color="secondary"
                onClick={handleOnAddToCart}
                size="small"
                loading={addToCartLoading}
                disabled={buttonDisabled}
                startIcon={
                  !updating &&
                  !buttonDisabled && (
                    <img
                      src={AddIcon}
                      className={classes.addToCartIcon}
                      alt=""
                    />
                  )
                }
              >
                {updating ? "Update Cart" : "Add to Cart"}
              </Button>
            </div>
          </Tooltip>
        </div>
        <CloseButton aria-label="close" onClick={onClose} key="close-button">
          <CloseIcon />
        </CloseButton>
      </DialogTitle>
      <div className={classes.configurationValidationDetails}>
        <ComboGroupConfigurationValidationDetails
          targetLabTest={targetLabTest}
          selectedOptionLabTestIds={selectedOptionLabTestIds}
          optionLabTests={optionLabTests}
          availableTargets={availableTargets}
          optionsSumCost={optionsSumCost}
          validConfiguration={validConfiguration}
          configurationWarningMessage={configurationWarningMessage}
          isLoading={isLoading}
        />
      </div>
    </div>
  )
}
