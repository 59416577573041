import { useEffect } from "react"

import { useParams } from "react-router-dom"

import Loading from "app/components/Loading"
import useQuery from "app/hooks/use-query"

import useV0Redirect from "./hooks/use-v0-redirect"
import { buildPatientPortalUrl } from "./util"

export default function PatientPortalV0Redirect() {
  const { orderId, orderedResultId } = useParams<{
    orderId: string
    orderedResultId: string
  }>()

  const query = useQuery()

  const { data, isLoading } = useV0Redirect(
    orderId,
    orderedResultId,
    query.get("token") || ""
  )

  useEffect(() => {
    if (data?.redirectUrl) {
      // If we have a redirect URL, go there
      window.location.href = data.redirectUrl
    }
    if (!isLoading && !data?.redirectUrl) {
      // Fall back on just going to top level page
      window.location.href = buildPatientPortalUrl()
    }
  }, [data, isLoading])

  return <Loading ariaLabel="Loading patient portal" />
}
