import { useMemo } from "react"

import axios from "axios"
import useSWR from "swr"

import { ColumnFiltersState } from "@tanstack/react-table"

import { getApiBaseUrl } from "app/utils"

import {
  PATIENT_COLUMN_ID,
  PRACTITIONER_COLUMN_ID,
  STATUS_COLUMN_ID,
} from "../components/TableRenderer"

/**
 * Fetches the number of unread results for the current user and set of column filters.
 *
 * @param columnFilters the current column filters
 * @returns the number of unread results as an SWR hook result
 */
export default function useDashboardUnreadResultsCount(
  columnFilters: ColumnFiltersState
) {
  const filterParams = useMemo(() => {
    return columnFilters.reduce((result, filter) => {
      switch (filter.id) {
        case PATIENT_COLUMN_ID: {
          return {
            ...result,
            patient_id: filter.value as string,
          }
        }
        case PRACTITIONER_COLUMN_ID: {
          return {
            ...result,
            practitioner_id: filter.value as string,
          }
        }
        case STATUS_COLUMN_ID: {
          return {
            ...result,
            status: filter.value as string,
          }
        }
        default:
          return result
      }
    }, {})
  }, [columnFilters])

  return useSWR<number>(["/dashboard-unread-results-count/", filterParams], {
    async fetcher([url, params]) {
      const response = await axios.request<{ count: number }>({
        baseURL: getApiBaseUrl() + "/api/",
        url,
        method: "get",
        params,
      })

      return response.data.count
    },
    revalidateIfStale: true,
    revalidateOnMount: true,
    revalidateOnFocus: true,
    revalidateOnReconnect: true,
  })
}
