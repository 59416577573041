import _ from "lodash"

export function forEachError(
  errors: Record<string, any>,
  callback: (name: string, message: string) => void,
  prefix = ""
) {
  for (let [path, messageOrOther] of _.entries(errors)) {
    const name = `${prefix}${path}`
    if (_.isString(messageOrOther)) {
      callback(name, messageOrOther)
    } else if (_.isPlainObject(messageOrOther)) {
      forEachError(messageOrOther, callback, `${name}.`)
    }
  }
}
