import { ComponentProps, useCallback, useEffect, useState } from "react"

import clsx from "clsx"
import { isEqual } from "lodash"
import { createEditor, Descendant, Editor, Transforms } from "slate"
import { withHistory } from "slate-history"
import {
  Editable,
  withReact,
  Slate,
  RenderElementProps,
  RenderLeafProps,
  useFocused,
  useSlate,
  ReactEditor,
} from "slate-react"

import { CircularProgress } from "@material-ui/core"

import {
  BlockButton,
  Element,
  Leaf,
  MarkButton,
  Toolbar,
} from "app/components/RichTextEditor/components"
import Content from "app/components/RichTextEditor/components/Content"
import { handleHotKeyDown } from "app/components/RichTextEditor/utils/hotkey"
import withTables from "app/components/RichTextEditor/utils/with-tables"
import { InsertSnippetButton } from "app/main/checkout/InsertSnippetButton"
import { SnippetTypes } from "app/main/settings/SavedTextSnippets/constants"
import { colors } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"

/**
 * Gets the initial editor state value for the given text.
 *
 * @param text the initial text value
 * @returns the initial editor value
 */
export function getInitialValue(text: string = ""): Descendant[] {
  return [
    {
      type: "paragraph",
      children: [{ text }],
    },
  ]
}

const useStyles = makeAppStyles((theme) => ({
  loading: {
    color: colors.blueGray[500],
    marginLeft: "auto",
  },
  editable: {
    "& table": {
      margin: theme.spacing(1.0, 0.0),
      tableLayout: "fixed",
      width: "100%",
      borderCollapse: "collapse",
    },
    "& th": {
      padding: theme.spacing(1.0),
      backgroundColor: colors.blue[50],
      border: `1px solid ${colors.blue[100]}`,

      textAlign: "left",
      fontWeight: 400,
    },
    "& td": {
      padding: theme.spacing(1.0),
      border: `1px solid ${colors.blue[100]}`,
      fontWeight: 400,
    },
  },
}))

const RichTextEditorEditable = ({
  autoFocus,
  classes,
  ...editableProps
}: {
  autoFocus?: boolean
  classes?: {
    editable?: string
    editableFocused?: string
  }
  disabled?: boolean
  onBlur?: ComponentProps<typeof Editable>["onBlur"]
  onKeyDown?: ComponentProps<typeof Editable>["onKeyDown"]
  placeholder?: string
  readOnly?: boolean
  spellCheck?: boolean
}) => {
  const renderElement = useCallback(
    (props: RenderElementProps) => <Element {...props} />,
    []
  )
  const renderLeaf = useCallback(
    (props: RenderLeafProps) => <Leaf {...props} />,
    []
  )

  const editor = useSlate()
  const isFocused = useFocused()

  useEffect(() => {
    if (autoFocus) {
      ReactEditor.focus(editor)

      Transforms.select(editor, Editor.end(editor, []))
    }
  }, [autoFocus])

  return (
    <Editable
      spellCheck={false}
      {...editableProps}
      autoFocus={autoFocus}
      className={clsx(
        classes?.editable,
        classes?.editableFocused && {
          [classes.editableFocused]: isFocused,
        }
      )}
      renderElement={renderElement}
      renderLeaf={renderLeaf}
    />
  )
}

export interface RichTextEditorFeatures {
  table?: boolean
}

function initializeEditor(features: RichTextEditorFeatures = {}) {
  const editor = withHistory(withReact(createEditor()))

  if (features.table) {
    return withTables(editor)
  }

  return editor
}

export interface RichTextEditorProps {
  autoFocus?: boolean
  classes?: {
    content?: string
    editable?: string
    editableFocused?: string
    loading?: string
    toolbar?: string
  }
  className?: string
  controlled?: boolean
  disabled?: boolean
  features?: RichTextEditorFeatures
  loading?: boolean
  onBlur?: ComponentProps<typeof Editable>["onBlur"]
  onChange?: (nextValue: Descendant[]) => void
  placeholder?: string
  readOnly?: boolean
  spellCheck?: boolean
  name?: string
  value: Descendant[]
  showInsertSnippetButton?: boolean
}

export default function RichTextEditor({
  autoFocus,
  classes,
  className,
  controlled = false,
  disabled,
  features,
  loading,
  onChange,
  readOnly,
  value,
  showInsertSnippetButton,
  ...editableProps
}: RichTextEditorProps) {
  const internalClasses = useStyles()
  const [editor] = useState(() => initializeEditor(features))

  const [internalValue, setInternalValue] = useState(value)

  useEffect(() => {
    if (controlled && !isEqual(editor.children, value)) {
      editor.children = value
      editor.selection = {
        anchor: { path: [0, 0], offset: 0 },
        focus: { path: [0, 0], offset: 0 },
      }
    }
  }, [controlled, value])

  useEffect(() => {
    setInternalValue(value)
  }, [value])

  const overrideEditorValue = (nextValue: Descendant[]) => {
    const newContent = [...internalValue, ...nextValue]
    editor.children = newContent
    setInternalValue(newContent)
    onChange?.(newContent)
  }

  return (
    <>
      {showInsertSnippetButton && (
        <InsertSnippetButton
          onAdd={overrideEditorValue}
          snippetType={SnippetTypes.RESULTS_SUMMARY}
        />
      )}

      <Slate editor={editor} onChange={onChange} value={internalValue}>
        <Content className={clsx(className, classes?.content)}>
          <Toolbar className={classes?.toolbar}>
            <MarkButton
              disabled={disabled || readOnly}
              format="bold"
              icon="format_bold"
            />
            <MarkButton
              disabled={disabled || readOnly}
              format="italic"
              icon="format_italic"
            />
            <MarkButton
              disabled={disabled || readOnly}
              format="underline"
              icon="format_underlined"
            />
            <MarkButton
              disabled={disabled || readOnly}
              format="hyperlink"
              icon="format_hyperlink"
            />
            <BlockButton
              disabled={disabled || readOnly}
              format="numbered-list"
              icon="format_list_numbered"
            />
            <BlockButton
              disabled={disabled || readOnly}
              format="bulleted-list"
              icon="format_list_bulleted"
            />

            {loading && (
              <CircularProgress
                className={clsx(classes?.loading, internalClasses.loading)}
                size={16}
              />
            )}
          </Toolbar>

          <RichTextEditorEditable
            spellCheck={false}
            {...editableProps}
            autoFocus={autoFocus}
            classes={{
              editable: clsx(internalClasses.editable, classes?.editable),
              editableFocused: classes?.editableFocused,
            }}
            disabled={disabled}
            readOnly={readOnly}
            onKeyDown={(event) => handleHotKeyDown(editor, event)}
          />
        </Content>
      </Slate>
    </>
  )
}
