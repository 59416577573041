import { useEffect } from "react"

import Loading from "app/components/Loading"
import BodyText from "app/components/design-system/BodyText"
import {
  trackPhlebotomyBookingEvent,
  PHLEBOTOMY_BOOKING_EVENTS,
} from "app/services/segment"
import { navy, colors } from "app/theme"
import { PHLEBOTOMY_PROVIDERS } from "app/types"
import makeAppStyles from "app/utils/makeAppStyles"

import { ScheduleAxleBooking } from "../ScheduleAxleBooking"
import { BookingData } from "../hooks/use-booking-data"
import useConfirmationModal from "../hooks/use-confirmation-modal"

const useStyles = makeAppStyles((theme) => ({
  iframeSection: {
    backgroundColor: "white",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 8,
    marginBottom: 10,
    boxShadow:
      "0px 1px 2px 0px rgba(0, 0, 0, 0.06), 0px 1px 3px 0px rgba(0, 0, 0, 0.10)",
  },
  subtitle: {
    textAlign: "center",
    color: navy,
    fontFamily: "Open Sans",
    fontSize: "15px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "135%",
    alignSelf: "stretch",
    maxWidth: 500,
    marginTop: 10,
    marginLeft: 16,
    marginRight: 16,
  },
  title: {
    marginTop: 40,
    color: navy,
    textAlign: "center",
    fontFamily: "Josefin Sans",
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "140%",
  },
  footer: {
    marginTop: 24,
    marginBottom: 36,
    color: navy,
    textAlign: "center",
    fontFamily: "Open Sans",
    fontSize: "13px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "135%",
    maxWidth: "527px",
    marginLeft: 16,
    marginRight: 16,
  },
  divider: {
    width: "100%",
    maxWidth: 704,
    height: 1,
    backgroundColor: colors.blueGray[200],
    marginTop: 36,
    marginBottom: 10,
    marginLeft: 16,
    marginRight: 16,
  },
  emailLink: {
    color: navy,
    textDecoration: "underline",
  },
}))

export const ScheduleSection = ({
  data,
  loginUrl,
  fromLocation,
}: {
  data: BookingData | undefined
  loginUrl: string | undefined
  fromLocation?: string
}) => {
  const classes = useStyles()
  const confirmationModal = useConfirmationModal()

  useEffect(() => {
    // Show confirmation modal after we get booking data gets returned back to us
    if (data?.order.axle_health_visits.length) {
      confirmationModal.show({ data, loginUrl, fromLocation })
    }
  }, [data, loginUrl])

  useEffect(() => {
    trackPhlebotomyBookingEvent(
      PHLEBOTOMY_BOOKING_EVENTS.PHLEBOTOMY_BOOKING_PAGE_VISIT,
      {
        provider: PHLEBOTOMY_PROVIDERS.AXLE,
        order_id: data?.order.id,
        from_location: fromLocation,
      }
    )
  }, [])

  return (
    <div className={classes.iframeSection}>
      <div>
        <div className={classes.title}>Schedule Your Mobile Blood Draw</div>
        <div className={classes.subtitle}>
          The times listed below indicate the start of a one-hour appointment
          window during which a healthcare professional may arrive at any time.
        </div>
        <div className={classes.subtitle}>
          <BodyText weight="semibold">
            {" "}
            A healthcare professional will visit you at:
          </BodyText>
          {data?.order.patient.default_shipping_address.street_1 && (
            <div>
              {data?.order.patient.default_shipping_address.street_1}
              {data?.order.patient.default_shipping_address.street_2
                ? " " + data?.order.patient.default_shipping_address.street_2
                : ""}
              , {data?.order.patient.default_shipping_address.city},{" "}
              {data?.order.patient.default_shipping_address.state}{" "}
              {data?.order.patient.default_shipping_address.zipcode}
            </div>
          )}
        </div>
      </div>

      {data ? (
        <div>
          <ScheduleAxleBooking bookingData={data} />
        </div>
      ) : (
        <Loading ariaLabel="loading-scheduling" />
      )}

      <div className={classes.divider}></div>
      <div className={classes.footer}>
        Need to draw blood at another location or have other concerns? Please
        reach out to{" "}
        <a
          role="button"
          className={classes.emailLink}
          href="mailto: support@rupahealth.com"
        >
          support@rupahealth.com
        </a>
        .
      </div>
    </div>
  )
}
