import { DialogTitle, IconButton, useMediaQuery } from "@material-ui/core"
import CloseIcon from "@material-ui/icons/Close"

import DisplayText from "app/components/design-system/DisplayText"
import { colors, navy, Theme } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"

import useComparisonActions from "../../hooks/use-comparison-actions"
import ComparisonCopyLink from "../ComparisonCopyLink/ComparisonCopyLink"

const useStyles = makeAppStyles((theme) => ({
  dialogTitle: {
    display: "flex",
    flexFlow: "row nowrap",
    justifyContent: "space-between",
    alignItems: "center",
    gap: 4,
    backgroundColor: "white",
    borderBottomWidth: 1,
    borderColor: colors.blueGray[200],
    padding: theme.spacing(2.0, 2.75),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1.5, 2.0),
    },
  },
  titleText: {
    flex: "1 1 auto",
    display: "inline-flex",
    alignItems: "center",
    minHeight: 40,
    marginTop: 3,
  },
  copyLink: {
    flex: "0 1 auto",
    marginTop: 0,
    marginRight: theme.spacing(4.0),
  },
  closeButton: {
    position: "absolute",
    top: theme.spacing(1.5),
    right: theme.spacing(0.5),
    color: navy,
    [theme.breakpoints.down("sm")]: {
      top: theme.spacing(1.25),
      right: 0,
    },
  },
}))

export default function ComparisonModalTitle() {
  const classes = useStyles()
  const actions = useComparisonActions()
  const isMobile = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"))
  return (
    <DialogTitle
      className={classes.dialogTitle}
      disableTypography
      id="comparison-modal-title"
    >
      <DisplayText className={classes.titleText} weight="semibold" size="lg">
        {isMobile ? "Compare Tests" : "Compare Lab Tests"}
      </DisplayText>
      <ComparisonCopyLink className={classes.copyLink} />
      <IconButton
        aria-label="close"
        className={classes.closeButton}
        onClick={actions.closeComparisonModal}
      >
        <CloseIcon />
      </IconButton>
    </DialogTitle>
  )
}
