import * as React from "react"

import { makeStyles } from "@material-ui/core"
import MuiDialogTitle from "@material-ui/core/DialogTitle"
import IconButton from "@material-ui/core/IconButton"
import CloseIcon from "@material-ui/icons/Close"

import DisplayText from "app/components/design-system/DisplayText"
import { navy, colors } from "app/theme"

type CustomFeesOnboardingModalTitleSectionProps = {
  title: string
  onClose: () => void
}

const CustomFeesOnboardingModalTitleSection: React.FC<
  CustomFeesOnboardingModalTitleSectionProps
> = ({ title, onClose }) => {
  const styles = () => ({
    titleAndInfoContainer: {
      display: "flex",
      "flex-direction": "column",
      "flex-wrap": "wrap",
      marginRight: 30,
      marginLeft: 30,
      marginTop: 10,
      marginBottom: 20,
      fontWeight: 200,
      "text-align": "center",
    },
    closeButton: {
      color: navy,
      position: "relative" as "relative",
      top: -5,
      right: 0,
      marginRight: -12,
      alignSelf: "end",
    },
    styledDialogTitle: {
      display: "flex",
      "flex-direction": "column",
      justifyContent: "space-between",
      "flex-wrap": "nowrap",
      backgroundColor: colors.blueGray[50],
      borderBottomWidth: 1,
      borderColor: colors.blueGray[50],
      alignItems: "center",
      padding: "15px 24px 8px 24px",
    },
  })

  const useStyles = makeStyles(styles)
  const classes = useStyles(styles)
  const closeButton = onClose && (
    <IconButton
      aria-label="close"
      onClick={onClose}
      key="close-button"
      className={classes.closeButton}
    >
      <CloseIcon />
    </IconButton>
  )

  return (
    <MuiDialogTitle disableTypography className={classes.styledDialogTitle}>
      {closeButton}
      <div className={classes.titleAndInfoContainer}>
        <DisplayText size="2xl" weight="regular">
          {title}
        </DisplayText>
      </div>
    </MuiDialogTitle>
  )
}

export default CustomFeesOnboardingModalTitleSection
