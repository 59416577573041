import { RenderElementProps } from "slate-react"

export default function Element({
  attributes,
  children,
  element,
}: RenderElementProps) {
  switch (element.type) {
    case "bulleted-list":
      return <ul {...attributes}>{children}</ul>
    case "list-item":
      return <li {...attributes}>{children}</li>
    case "numbered-list":
      return <ol {...attributes}>{children}</ol>
    case "table":
      return <table {...attributes}>{children}</table>
    case "table-row":
      return <tr {...attributes}>{children}</tr>
    case "table-cell":
      return <td {...attributes}>{children}</td>
    case "table-cell-header":
      return <th {...attributes}>{children}</th>
    default:
      return <p {...attributes}>{children}</p>
  }
}
