import { useEffect } from "react"

import { isEqual } from "lodash"
import { useHistory, useLocation } from "react-router-dom"
import { useUpdateEffect } from "react-use"

import { trackLabTestComparison } from "app/services/segment"

import { COMPARISON_TEST_ID_PARAM } from "../constants"
import {
  openComparisonModal,
  setComparedTests,
} from "../store/comparison.actions"
import { ComparisonState, ComparisonDispatch } from "../types"

export default function useSyncWithUrl(
  state: ComparisonState,
  dispatch: ComparisonDispatch
) {
  const history = useHistory()
  const location = useLocation()

  useEffect(
    /**
     * Sync: Location -> Lab Test IDs
     */
    () => {
      if (state.labTestIds.length > 0) {
        // skip syncing from location if we already have lab test IDs
        return
      }

      const params = new URLSearchParams(location.search)
      const urlLabTestIds = params.getAll(COMPARISON_TEST_ID_PARAM)

      if (isEqual(state.labTestIds, urlLabTestIds)) {
        // skip sync if url already includes compared tests
        return
      }

      dispatch(setComparedTests(urlLabTestIds))
      if (urlLabTestIds.length > 0) {
        dispatch(openComparisonModal())
      }

      trackLabTestComparison(urlLabTestIds)
    },
    [location.search]
  )

  useUpdateEffect(
    /**
     * Sync: Lab Test IDs -> Location
     *
     * Uses update effect as we do not want this to run on the initial page load.
     */
    () => {
      const params = new URLSearchParams(location.search)
      const urlLabTestIds = params.getAll(COMPARISON_TEST_ID_PARAM)
      if (isEqual(state.labTestIds, urlLabTestIds)) {
        // skip sync if url already includes compared tests
        return
      }

      // Ensure any saved lab-test-id is dropped as the async load will cause it to show on top of the search modal.
      params.delete("lab-test-id")
      params.delete(COMPARISON_TEST_ID_PARAM)

      state.labTestIds.forEach((labTestId) =>
        params.append(COMPARISON_TEST_ID_PARAM, labTestId)
      )

      history.replace({
        search: `?${params.toString()}`,
      })

      trackLabTestComparison(state.labTestIds)
    },
    [location.search, state.labTestIds]
  )
}
