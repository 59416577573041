import LabOrderingAccessListItem from "app/components/OrderingAccess/LabOrderingAccessListItem"
import { colors } from "app/theme"
import { LabCompany, PractitionerListItem } from "app/types"
import makeAppStyles from "app/utils/makeAppStyles"

const useStyles = makeAppStyles({
  container: {
    background: colors.coolGray[100],
    display: "flex",
    flexFlow: "column",
    alignItems: "center",
    "margin-bottom": 20,
  },
})

interface Props {
  labCompanies: LabCompany[] | null
  allowedLabCompanies: LabCompany[]
  selectedPractitionerListItem?: PractitionerListItem
}

function LabOrderingAccessList(props: Props) {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      {props.labCompanies?.map((labCompany) => (
        <LabOrderingAccessListItem
          hasOrderingAccess={props.allowedLabCompanies.includes(labCompany)}
          key={labCompany.name}
          labCompany={labCompany}
          selectedPractitionerListItem={props.selectedPractitionerListItem}
        />
      ))}
    </div>
  )
}

export default LabOrderingAccessList
