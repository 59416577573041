import * as React from "react"

import { Location } from "history"

import {
  CircularProgress,
  Button as MaterialButton,
  lighten,
  styled,
} from "@material-ui/core"

import makeAppStyles from "app/utils/makeAppStyles"

import { cancelRed, colors, navy, primaryColor, shadows } from "../../theme"

const progressSize = 24

type MuiButtonProps = React.ComponentProps<typeof MaterialButton>

export interface ButtonProps {
  color?:
    | "primary"
    | "secondary"
    | "destructivePrimary"
    | "destructiveSecondary"
    | "noaction"
    | "text"
    | "destructiveText"
    | string
  size?: "xsmall" | "small" | "medium" | "large"
  shadow?: "sm" | "default"
  loading?: boolean
  disabled?: boolean
  disableRipple?: boolean
  className?: string
  children?: React.ReactNode
  onClick?: React.MouseEventHandler
  onPointerDown?: React.MouseEventHandler
  onKeyDown?: React.KeyboardEventHandler
  href?: MuiButtonProps["href"]
  target?: string
  startIcon?: string | React.ReactNode
  endIcon?: string | React.ReactNode
  type?: string
  variant?: string
  fullWidth?: boolean
  component?: React.ElementType
  to?: Location<unknown>
  id?: string
  isMobile?: boolean
  // For cypress testing
  ["data-cy"]?: string
}

type StyleProps = Pick<ButtonProps, "color" | "size" | "disabled" | "shadow">

const useStyles = makeAppStyles<StyleProps>({
  button: {
    alignItems: "center",
    position: "relative",
    backgroundColor: ({ color }) =>
      color &&
      {
        primary: primaryColor,
        secondary: "white",
        destructivePrimary: cancelRed,
        destructiveSecondary: "white",
        noaction: "white",
        text: "transparent",
        destructiveText: "transparent",
        success: colors.emerald[500],
      }[color],
    "&:hover": {
      backgroundColor: ({ color }) =>
        color &&
        {
          primary: lighten(primaryColor, 0.15),
          secondary: "rgba(0, 117, 205, 0.04)",
          destructivePrimary: lighten(cancelRed, 0.15),
          destructiveSecondary: "white",
          noaction: undefined,
          text: "transparent",
          destructiveText: "transparent",
          success: lighten(colors.emerald[500], 0.15),
        }[color],
      "&:disabled": {
        pointerEvents: "unset",
        cursor: "not-allowed",
      },
    },
    color: ({ color }) =>
      color &&
      {
        primary: "white",
        secondary: primaryColor,
        destructivePrimary: "white",
        destructiveSecondary: cancelRed,
        noaction: navy,
        text: primaryColor,
        destructiveText: cancelRed,
        success: "white",
      }[color],
    lineHeight: 1,
    fontSize: ({ size, color }) => {
      if (color === "text" || color === "destructiveText") {
        return {
          xsmall: 15,
          small: 15,
          medium: 17,
          large: 19,
        }[size || "small"]
      }

      return size === "small" || size === "xsmall" ? 15 : 19
    },
    fontWeight: 600,
    border: ({ color, disabled }) => {
      if (!color || color === "text" || color === "destructiveText") {
        return "0px"
      }

      if (disabled) {
        return `1px solid ${colors.gray[300]}`
      }

      return {
        primary: `1px solid ${primaryColor}`,
        secondary: `1px solid ${colors.blueGray[300]}`,
        destructivePrimary: `1px solid ${cancelRed}`,
        destructiveSecondary: `1px solid ${colors.blueGray[300]}`,
        noaction: `1px solid ${colors.blueGray[300]}`,
        success: `1px solid ${colors.emerald[500]}`,
      }[color]
    },
    borderRadius: 8,
    boxShadow: ({ color, shadow }) => {
      return color === "text" || color === "destructiveText"
        ? "none"
        : shadow === "default"
        ? shadows.default
        : shadows.sm
    },
    height: ({ size }) => {
      if (!size) {
        return "auto"
      }
      return {
        xsmall: "40px",
        small: "auto",
        medium: "auto",
        large: "auto",
      }[size]
    },
    padding: ({ size, color }) => {
      if (color === "text" || color === "destructiveText" || !size) {
        return 0
      }

      return {
        xsmall: "8px 12px",
        small: "14px 20px 12px 20px",
        medium: "16px 28px 14px 28px",
        large: "24px 32px 22px 32px",
      }[size]
    },
    minWidth: ({ size }) => (size === "large" ? 300 : undefined),
  },
})

export default React.forwardRef<HTMLButtonElement, ButtonProps>(function Button(
  {
    size = "small",
    color = "primary",
    shadow = "sm",
    disabled = false,
    loading = false,
    className,
    children,
    onClick,
    startIcon,
    endIcon,
    id = "",
    ...props
  },
  ref
) {
  const styles = useStyles({
    size,
    color,
    shadow,
    disabled,
  })

  return (
    <MaterialButton
      id={id}
      disabled={loading || disabled}
      // @ts-ignore
      variant={
        color === "text" || color === "destructiveText" ? "text" : "contained"
      }
      disableElevation
      onClick={onClick}
      className={`${styles.button} ${className}`}
      {...props}
      startIcon={
        typeof startIcon === "string" ? (
          <img src={startIcon} alt="" />
        ) : (
          startIcon
        )
      }
      endIcon={
        typeof endIcon === "string" ? <img src={endIcon} alt="" /> : endIcon
      }
      ref={ref}
    >
      {children}
      {loading && <ButtonProgress size={progressSize} />}
    </MaterialButton>
  )
})

const ButtonProgress = styled(CircularProgress)({
  position: "absolute",
  left: "50%",
  top: "50%",
  "margin-left": `-${progressSize / 2}px`,
  "margin-top": `-${progressSize / 2}px`,
})
