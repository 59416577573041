import clsx from "clsx"

import { Skeleton } from "@material-ui/lab"

import { colors } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"

const useStyles = makeAppStyles((theme) => ({
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(0.5),
  },
  skeletonRect: {
    borderRadius: 3,
    backgroundColor: colors.blueGray[200],
  },
  skeletonHeader: {
    marginBottom: theme.spacing(1.0),
  },
}))

export default function AddBiomarkerSearchLoading() {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Skeleton
        className={clsx(classes.skeletonRect, classes.skeletonHeader)}
        variant="rect"
        animation="wave"
        height={13}
        width={120}
      />

      <Skeleton
        className={classes.skeletonRect}
        variant="rect"
        animation="wave"
        height={60}
        width="100%"
      />

      <Skeleton
        className={classes.skeletonRect}
        variant="rect"
        animation="wave"
        height={60}
        width="100%"
      />
    </div>
  )
}
