import { memo } from "react"

import { useSortable } from "@dnd-kit/sortable"
import { CSS } from "@dnd-kit/utilities"
import { Theme } from "@material-ui/core"

import {
  StorefrontLabTestBundleType,
  StorefrontLabTestType,
  useResourceByIdSelector,
} from "app/hooks/use-resource"
import makeAppStyles from "app/utils/makeAppStyles"

import EcommerceBundleCard from "../EcommerceBundleCard"
import EcommerceLabTestCard from "../EcommerceLabTestCard"

const styles = (theme: Theme) => ({
  container: {
    width: "calc(25% - 8px)",
    height: "auto",
    [theme.breakpoints.down(2000)]: {
      width: "calc(33% - 10px)",
    },
    [theme.breakpoints.down("md")]: {
      width: "calc(50% - 15px)",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
})

const useStyles = makeAppStyles(styles)

interface Props {
  id: string
  openProductCustomizeModal: (productId: string) => void
  removeLabTest: (labTest: StorefrontLabTestType | undefined) => void
  removeBundle: (bundle: StorefrontLabTestBundleType | undefined) => void
  hidePhysServicesPricing: boolean
}

const SortableProduct = memo(
  ({
    id,
    openProductCustomizeModal,
    removeLabTest,
    removeBundle,
    hidePhysServicesPricing,
  }: Props) => {
    const classes = useStyles()

    const { attributes, listeners, setNodeRef, transform, transition } =
      useSortable({ id: id })

    const product = useResourceByIdSelector("storefront_product", id)

    if (!product?.attributes.is_available) {
      return null
    }

    return (
      <div
        ref={setNodeRef}
        className={classes.container}
        style={{ transform: CSS.Transform.toString(transform), transition }}
      >
        {product?.relationships.lab_test.data && (
          <EcommerceLabTestCard
            product={product}
            onLabTestDelete={removeLabTest}
            openModal={() => openProductCustomizeModal(product.id)}
            dragAttributes={attributes}
            dragListeners={listeners}
            hidePhysServicesPricing={hidePhysServicesPricing}
          />
        )}
        {product?.relationships.bundle.data && (
          <EcommerceBundleCard
            product={product}
            onRemove={removeBundle}
            openModal={() => openProductCustomizeModal(product.id)}
            dragAttributes={attributes}
            dragListeners={listeners}
            hidePhysServicesPricing={hidePhysServicesPricing}
          />
        )}
      </div>
    )
  }
)

export default SortableProduct
