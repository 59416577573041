import { pick } from "lodash"
import { useForm } from "react-hook-form"

import { FoodPlanVersion } from "types/food-plan-version"

export type FoodPlanUpdateConfigurationFormValues = Partial<
  Pick<FoodPlanVersion["attributes"], "suggestions" | "layout">
>

export interface UseFoodPlanUpdateFormProps {
  defaultValues?: FoodPlanUpdateConfigurationFormValues
}

export default function useFoodPlanUpdateForm({
  defaultValues = {
    suggestions: "",
    layout: "table",
  },
}: UseFoodPlanUpdateFormProps) {
  defaultValues = pick(defaultValues, ["suggestions", "layout"])

  return useForm<FoodPlanUpdateConfigurationFormValues>({
    defaultValues,
    shouldUnregister: true,
  })
}
