import { pickBy } from "lodash"

import LabTests from "app/components/LabTests/LabTests"
import useAppSelector from "app/hooks/useAppSelector"
import { SCROLL_CONTAINER_PADDING } from "app/main/comparison/constants"
import useComparisonActions from "app/main/comparison/hooks/use-comparison-actions"
import useComparisonState from "app/main/comparison/hooks/use-comparison-state"
import { selectPractitioner } from "app/store/selectors/practitioner.selectors"
import { AnyLabTest, labTestLocation } from "app/types"
import makeAppStyles from "app/utils/makeAppStyles"

const useStyles = makeAppStyles((theme) => ({
  comparisonSearchLabTestsContainer: {
    width: "100%",
    height: "100%",
    padding: SCROLL_CONTAINER_PADDING,
    [theme.breakpoints.down("sm")]: {
      padding: SCROLL_CONTAINER_PADDING / 1.5,
    },
  },
}))

export default function ComparisonSearchLabTests() {
  const classes = useStyles()
  const practitioner = useAppSelector(selectPractitioner)
  const actions = useComparisonActions()
  const { labTestIds, labTestById } = useComparisonState()

  const selectedLabTests = pickBy(
    labTestById,
    (labTest) => labTest && labTestIds.includes(labTest.id)
  ) as { [id: string]: AnyLabTest }
  return (
    <div className={classes.comparisonSearchLabTestsContainer}>
      {/* TODO add param here to indicate we want comparison tests */}
      <LabTests
        autoFocusSearch
        location={labTestLocation.COMPARISON}
        onAddLabTest={(labTest) => actions.addComparedTest(labTest.id)}
        onRemoveLabTest={(labTest) => actions.removeComparedTest(labTest.id)}
        practitioner={practitioner}
        selectedLabTests={selectedLabTests}
        signingPractitioner={practitioner}
      />
    </div>
  )
}
