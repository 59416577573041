import { styled } from "@material-ui/core"

import OfficeBlockIcon from "app/assets/icons/office-block-dark-gray.svg"
import BodyText from "app/components/design-system/BodyText"
import InfoTextTooltip from "app/components/design-system/InfoTextTooltip"
import { primaryColor } from "app/theme"
import { LabTest } from "app/types"

const BodyContainer = styled("div")({
  marginTop: 14,
  display: "flex",
  flexDirection: "row",
})

const IconImage = styled("img")({
  width: 17.42,
})

interface Props {
  name: LabTest["name"]
  details: LabTest["details"]
}

const CompanySection = ({ name, details }: Props) => (
  <>
    <BodyText size="sm">Company</BodyText>
    <BodyContainer>
      <IconImage src={OfficeBlockIcon} alt="company" />
      <BodyText className="truncate pl-1.5" weight="semibold">
        {name}
      </BodyText>
      {details && (
        <InfoTextTooltip
          style={{ marginLeft: "5px", marginTop: "3px", color: "#95A2AF" }}
          iconFill={primaryColor}
        >
          <div dangerouslySetInnerHTML={{ __html: details }} />
        </InfoTextTooltip>
      )}
    </BodyContainer>
  </>
)

export default CompanySection
