import { ORDERED_TEST_STATUS, ORDER_PAYER } from "app/constants.typed"
import { getRequisitionLabCompanyName } from "app/utils/order-status-utils"

import { StatusConfiguration } from "../../types"
import { StatusConfigurationProps } from "../../types/patient-orders-types"
import { PROGRESS_PERCENTAGES } from "../consts"

const Title = ({
  order,
  labCompany,
  orderedTest,
  requisitionAvailable,
}: StatusConfigurationProps) => {
  if (order.payer !== ORDER_PAYER.PATIENT && !order.date_patient_checkout) {
    return "Patient Consent Form Pending"
  }

  switch (orderedTest.sample_collection_coordinator) {
    case "LAB":
      if (requisitionAvailable) {
        return "Requisition is Ready"
      } else {
        const name = getRequisitionLabCompanyName(labCompany.key)
        return `Awaiting requisition from ${name}`
      }
    default:
      if (order.payer !== ORDER_PAYER.PATIENT) {
        return "Patient Consent Form Complete"
      }
      return "Invoice Paid"
  }
}

const progressPercentage = ({ order }: StatusConfigurationProps) => {
  if (
    order.payer === ORDER_PAYER.PRACTITIONER &&
    !order.date_patient_checkout
  ) {
    return PROGRESS_PERCENTAGES.AWAITING_PATIENT_CHECKOUT
  }
  return PROGRESS_PERCENTAGES.PAID
}

const SubTitle = () => {
  // PROD-2070: see comments in dashboard/paid-status-configuration.tsx
  return null
}

const paidStatusConfiguration: StatusConfiguration<StatusConfigurationProps> = {
  when: { orderedTestStatus: ORDERED_TEST_STATUS.PAID },
  progressPercentage: progressPercentage,
  title: Title,
  subTitle: SubTitle,
}

export default paidStatusConfiguration
