import AutoCompleteInput, { AutoCompleteInputProps } from "./AutoCompleteInput"
import FieldLabel, { FieldLabelProps } from "./FieldLabel"

export type AutoCompleteFieldProps = AutoCompleteInputProps &
  FieldLabelProps & {
    autoCompleteClassName?: string
    labelClassName?: string
  }

const AutoCompleteField = ({
  className,
  infoText,
  label,
  labelClassName,
  required,
  beforeLabelIcon,
  InputProps,
  autoCompleteClassName,
  ...textInputProps
}: AutoCompleteFieldProps) => {
  return (
    <div className={className}>
      {Boolean(label) && (
        <FieldLabel
          className={labelClassName}
          label={label}
          beforeLabelIcon={beforeLabelIcon}
          infoText={infoText}
          required={required}
        />
      )}
      <AutoCompleteInput
        className={autoCompleteClassName}
        InputProps={{
          "aria-label": typeof label === "string" ? label : undefined,
          ...InputProps,
        }}
        {...textInputProps}
      />
    </div>
  )
}

export default AutoCompleteField
