import Button from "@material-ui/core/Button"

import { ReactComponent as PhysAuthIcon } from "app/assets/icons/phys-auth-glob.svg"
import usePhysicianServicesOptInModal from "app/components/modals/PhysicianServicesOptInModal/use-physician-services-opt-in-modal"
import { VENDOR_PHYSICIAN_AUTHORIZATION_LABEL } from "app/constants"
import { trackPhysicianServicesApplicationClicked } from "app/services/segment"

import TaskContainer from "../TaskContainer"
import taskStyles from "../TaskStyles"
import { PHYSICIAN_SERVICES_TASK_TEXT } from "../constants"
import { OnboardingTaskItemProps } from "./OnboardingTaskItem"

const PhysServicesLicense: React.FC<OnboardingTaskItemProps> = ({
  task,
  onTaskComplete,
  refreshPractitioner,
}) => {
  const taskClasses = taskStyles()

  const physicianServicesEnableText = `Add ${VENDOR_PHYSICIAN_AUTHORIZATION_LABEL} to your Account`
  const physicianServicesButtonText = `Add ${VENDOR_PHYSICIAN_AUTHORIZATION_LABEL}`

  const physicianServicesOptInModal = usePhysicianServicesOptInModal()

  const onEnablePhysicianServicesClick = () => {
    physicianServicesOptInModal.show({
      returnToDescriptor: "Onboarding",
      onClose: (finishedApplication) => {
        refreshPractitioner()
        physicianServicesOptInModal.remove()
        if (finishedApplication) {
          onTaskComplete(task.key)
        }
      },
    })
  }

  return (
    <TaskContainer
      taskKey={task.key}
      header={physicianServicesEnableText}
      isTaskComplete={task.complete}
      timeTag={"1 min"}
    >
      <PhysAuthIcon
        className={taskClasses.physServicesIcon}
        style={{ marginTop: 25 }}
      />
      <div className={taskClasses.taskTextContainer}>
        <div className={taskClasses.taskText}>
          {PHYSICIAN_SERVICES_TASK_TEXT}
        </div>
      </div>
      <Button
        variant="contained"
        color="primary"
        className={taskClasses.taskButton}
        style={{ marginBottom: 30 }}
        onClick={() => {
          onEnablePhysicianServicesClick()
          trackPhysicianServicesApplicationClicked()
        }}
      >
        <span className={taskClasses.buttonText}>
          {physicianServicesButtonText}
        </span>
      </Button>
    </TaskContainer>
  )
}

export default PhysServicesLicense
