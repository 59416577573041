import { Link, styled, withStyles } from "@material-ui/core"

import ArrowTopRight from "app/assets/icons/arrow-top-right.svg"
import InfoGrayCircle from "app/assets/icons/info-gray-circle.svg"
import { colors } from "app/theme"
import { Order, PatientPortalOrder } from "app/types"

import Markdown from "../Markdown"
import Tooltip from "../Tooltip"
import BodyText from "../design-system/BodyText"
import { GenovaExplanation } from "./useGenovaInsuranceExplanation"

interface Props {
  explanation: GenovaExplanation
  location: "cart" | "patientCheckout" | "patientCheckoutConfirmation"
  order: Order | PatientPortalOrder
}

const Container = styled("div")({
  background: (props: { isCart?: boolean }) =>
    props.isCart ? colors.blueGray[50] : "white",
  border: `1px solid ${colors.blueGray[300]}`,
  padding: "10px 16px",
  borderRadius: 6,
  marginTop: 16,
  display: "flex",
  alignItems: "center",
})

const Icon = styled("img")({
  marginRight: 10,
})

const SeeDetails = styled(BodyText)({
  borderBottom: `2px dotted ${colors.blueGray[400]}`,
})

const List = styled("ul")({
  padding: "10px 16px",
  "& ul": {
    padding: "0 16px",
  },
})

const TestListItem = styled("li")({
  "&:not(:last-child)": {
    marginBottom: 8,
  },
})

const GenovaTooltip = withStyles({
  tooltip: { maxWidth: 412 },
})(Tooltip)

const LearnMoreIcon = styled("img")({
  marginLeft: 4,
})

const GenovaInsuranceExplanationTooltip = ({
  explanation,
  location,
  order,
}: Props) => {
  const { genovaTests, totalPotentialExtraCost } = explanation

  const isCart = location === "cart"
  const patientFirstName = order.patient.first_name
  const title = isCart
    ? `Genova may balance-bill ${patientFirstName} for **up to ${totalPotentialExtraCost}** more. `
    : `Genova may send you a bill for **up to ${totalPotentialExtraCost}** more for your lab tests. `

  const tooltipDescription = isCart
    ? `The amount ${patientFirstName} will be billed by Genova depends on how much their insurance covers. The maximum out-of-pocket for this order (including what ${patientFirstName} pays Rupa) is:`
    : `Genova may send you an additional bill depending on your insurance coverage. Here is the maximum amount that Genova may bill you for each test:`

  return (
    <GenovaTooltip
      arrow
      interactive
      placement="bottom"
      title={
        <div>
          <BodyText size="sm">{tooltipDescription}</BodyText>
          <List>
            {genovaTests.map(
              ({ name, potentialExtraCost, maxCost, rupaPrice }) => (
                <TestListItem>
                  <BodyText
                    variantMapping={{ body1: "span" }}
                    weight={isCart ? "semibold" : "regular"}
                    size="sm"
                  >
                    {`${name}: `}
                  </BodyText>
                  {isCart ? (
                    <BodyText
                      variantMapping={{ body1: "ul" }}
                      size="sm"
                      weight="regular"
                    >
                      <li>Max out of pocket price for insurance: {maxCost}</li>
                      <li>Cash price: {rupaPrice}</li>
                    </BodyText>
                  ) : (
                    <BodyText
                      variantMapping={{ body1: "span" }}
                      weight="semibold"
                      size="sm"
                    >
                      {`Up to ${potentialExtraCost} more`}
                    </BodyText>
                  )}
                </TestListItem>
              )
            )}
          </List>
          <Link
            href="https://support.rupahealth.com/article/1150-how-insurance-works-for-genova-diagnostics"
            target="_blank"
          >
            Learn more
            <LearnMoreIcon src={ArrowTopRight} alt="" />
          </Link>
        </div>
      }
    >
      <Container isCart={isCart}>
        <Icon src={InfoGrayCircle} />
        <BodyText>
          <Markdown>{title}</Markdown>
          <SeeDetails variantMapping={{ body1: "span" }}>
            See details
          </SeeDetails>
          .
        </BodyText>
      </Container>
    </GenovaTooltip>
  )
}

export default GenovaInsuranceExplanationTooltip
