import { ReactComponent as HeartsSeriesIcon } from "app/assets/images/hearts-series.svg"
import BodyText from "app/components/design-system/BodyText"

const FavoritesEmptyState = () => {
  return (
    <div
      className="w-full py-[19px] px-[42px] flex flex-col sm:flex-row justify-center items-start sm:items-center gap-[10px] rounded-lg border border-slate-100 bg-slate-50"
      style={{ boxShadow: "0px 2px 2.9px 0px rgba(43, 69, 95, 0.08) inset" }}
    >
      <div className="w-[35px] flex flex-col items-center">
        <HeartsSeriesIcon width={35} />
      </div>
      <BodyText>
        Quickly access your most-used tests by adding them to your favorites.
      </BodyText>
    </div>
  )
}

export default FavoritesEmptyState
