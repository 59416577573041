import { Order } from "types/order"
import { ResultsInterpretation } from "types/results-interpretation"

import { AnyBiomarkerResourceIdentifier } from "../../types"
import AddBiomarkerSearch from "./AddBiomarkerSearch"
import ToggleAddBiomarkerButton from "./ToggleAddBiomarkerButton"

export interface AddBiomarkerProps {
  interpretation: ResultsInterpretation
  isAdding: boolean
  isCreating: boolean
  onCreate: (identifier: AnyBiomarkerResourceIdentifier) => Promise<void>
  onToggleAdding: () => void
  order: Order
}

export default function AddBiomarker({
  interpretation,
  isAdding,
  isCreating,
  onCreate,
  onToggleAdding,
  order,
}: AddBiomarkerProps) {
  return (
    <>
      {!isAdding ? (
        <ToggleAddBiomarkerButton onToggleAdding={onToggleAdding} />
      ) : (
        <AddBiomarkerSearch
          interpretation={interpretation}
          isCreating={isCreating}
          onCreate={onCreate}
          onToggleAdding={onToggleAdding}
          order={order}
        />
      )}
    </>
  )
}
