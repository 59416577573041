import { API } from "app/api"
import { SnippetTypes } from "app/main/settings/SavedTextSnippets/constants"
import { handleApiSuccess, handleApiError } from "app/utils"

export const GET_SNIPPETS = "[APP] GET_SNIPPETS"

export function getSnippets(
  search = "",
  snippetType = SnippetTypes.NOTES_TO_PATIENT
) {
  return (dispatch) => {
    const request = API.Snippet.getList(search, snippetType)

    return request
      .then(async (response) => {
        await dispatch({
          type: GET_SNIPPETS,
          payload: response.data.results,
        })
      })
      .catch((error) => dispatch(handleApiError(error)))
  }
}

export function createSnippet(title, text, onClose, stopSubmitting) {
  const request = API.Snippet.create({
    title,
    text,
  })

  return (dispatch, getState) =>
    request
      .then(async (response) => {
        dispatch(handleApiSuccess("Snippet created"))

        let snippets = getState().snippet
        snippets = [...snippets, response.data]

        await dispatch({
          type: GET_SNIPPETS,
          payload: snippets,
        })

        // Close Modal
        onClose()
      })
      .catch((error) => {
        stopSubmitting()
        dispatch(handleApiError(error))
      })
}

export function updateSnippet(snippetId, title, text, onClose, stopSubmitting) {
  const request = API.Snippet.update(snippetId, {
    title,
    text,
  })

  return (dispatch, getState) =>
    request
      .then(async (response) => {
        dispatch(handleApiSuccess("Snippet updated"))

        let snippets = getState().snippet
        snippets = [...snippets].filter((snippet) => snippet.id !== snippetId)
        snippets = [...snippets, response.data]

        await dispatch({
          type: GET_SNIPPETS,
          payload: snippets,
        })

        // Close Modal
        onClose()
      })
      .catch((error) => {
        stopSubmitting()
        dispatch(handleApiError(error))
      })
}

export function deleteSnippet(snippetId, onClose, stopSubmitting) {
  const request = API.Snippet.delete(snippetId)

  return (dispatch, getState) =>
    request
      .then(async (response) => {
        dispatch(handleApiSuccess("Snippet deleted"))

        let snippets = getState().snippet
        snippets = [...snippets].filter((snippet) => snippet.id !== snippetId)

        await dispatch({
          type: GET_SNIPPETS,
          payload: snippets,
        })

        // Close Modal
        onClose()
      })
      .catch((error) => {
        stopSubmitting()
        dispatch(handleApiError(error))
      })
}
