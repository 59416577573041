import { useEffect, useState } from "react"
import * as React from "react"
import { useDispatch } from "react-redux"

import clsx from "clsx"
import { useFormContext } from "react-hook-form"

import { CircularProgress } from "@material-ui/core"

import BloodIcon from "app/assets/icons/blood.svg"
import GreenCheckIcon from "app/assets/icons/green-check.svg"
import AlertRedIcon from "app/assets/images/alert-triangle-red.svg"
import AlertYellowIcon from "app/assets/images/alert-triangle-yellow.svg"
import Markdown from "app/components/Markdown"
import BodyText from "app/components/design-system/BodyText"
import Button from "app/components/design-system/Button"
import {
  LAB_COMPANY_KEY,
  ORDER_LINE_ITEM_TYPE,
  SUPPORT_EMAIL,
} from "app/constants"
import {
  isLineItemActive,
  isOrderedTestForCompany,
  orderHasTestWithCompany,
} from "app/dataServices/orderDataService"
import useAppSelector from "app/hooks/useAppSelector"
import * as actions from "app/main/patient-checkout/store/actions"
import { ControlledCheckboxInput } from "app/patient-portal/fields"
import { colors, textPrimaryColor } from "app/theme"
import { PatientPortalOrder } from "app/types"
import makeAppStyles from "app/utils/makeAppStyles"

import { FieldNames } from "../fields"
import { useCheckoutTokenFromLocation } from "../hooks"
import { selectOrderState } from "../store/selectors"

const useStyles = makeAppStyles<{
  borderColor: string
  backgroundColor: string
  iconBackgroundColor: string
  textColor: string
}>((theme) => ({
  wrapper: {
    display: "flex",
    alignItems: "flex-start",
    padding: 16,
    borderRadius: 6,
    backgroundColor: (props) => props.backgroundColor,
    border: (props) => `1px solid ${props.borderColor}`,
  },
  hybridOrderWrapper: {
    marginTop: 16,
  },
  iconContainer: {
    flexShrink: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: 25,
    width: 25,
    padding: 5,
    borderRadius: "50%",
    backgroundColor: (props) => props.iconBackgroundColor,
    marginRight: 10,
  },
  loadingContainer: {
    marginRight: 10,
  },
  title: {
    marginBottom: 5,
    color: (props) => props.textColor,
  },
  message: {
    paddingInlineStart: "0",
    color: (props) => props.textColor,

    "& > p:not(:last-child)": {
      marginBottom: 8,
    },

    // We need to use an overly specific selector to override the base style
    "& > p > a": {
      color: "inherit",
      textDecoration: "underline",
    },
  },
  failureOnlyBRLButtons: {
    display: "flex",
    flexDirection: "column",
    marginTop: 16,
    color: textPrimaryColor,

    [theme.breakpoints.up("lg")]: {
      flexDirection: "row",
    },
  },
  cancelOrderContainer: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    alignSelf: "center",
    marginTop: 8,

    [theme.breakpoints.up("lg")]: {
      flexDirection: "row",
      marginLeft: 16,
      marginTop: 0,
    },
  },
  cancelButton: {
    color: textPrimaryColor,
    textDecoration: "underline",
    marginTop: 8,

    [theme.breakpoints.up("lg")]: {
      marginLeft: 4,
      marginTop: 0,
    },
  },
}))

interface Props {
  zipcode: string
  setCancelModalOpenWithOptions: (options: {
    cancellationReason?: string
  }) => void
}

type Statuses =
  | "loading"
  | "success"
  | "failureOnlyBRL"
  | "failureWithOtherCompanies"
  | "error"
  | "loadingSlow"

const statusToData: {
  [status in Statuses]: {
    icon: React.ReactNode
    borderColor: string
    backgroundColor: string
    iconBackgroundColor: string
    textColor: string
    title: string
    description: string
  }
} = {
  loading: {
    icon: <CircularProgress aria-label="Loading" size={25} />,
    borderColor: colors.blueGray[300],
    backgroundColor: colors.blueGray[50],
    iconBackgroundColor: "",
    textColor: textPrimaryColor,
    title: "Checking for mobile blood draw services nearby...",
    description: "This should only take a few seconds.",
  },
  success: {
    icon: GreenCheckIcon,
    borderColor: colors.emerald[200],
    backgroundColor: colors.emerald[50],
    iconBackgroundColor: colors.emerald[200],
    textColor: colors.emerald[800],
    title: "Scarlet has mobile phlebotomists near you.",
    description:
      "Scarlet, a mobile phlebotomy company, will reach out to you to coordinate your BioReference testing. A trained Scarlet phlebotomist will come to the address of your choosing to complete your blood draw.",
  },
  failureOnlyBRL: {
    icon: AlertRedIcon,
    borderColor: colors.red[200],
    backgroundColor: colors.red[50],
    iconBackgroundColor: colors.red[200],
    textColor: colors.red[800],
    title: "Scarlet does not service your area for blood draws.",
    description: `BioReference lab tests require a blood draw, which is completed by a mobile phlebotomy company called Scarlet. Unfortunately, Scarlet does not have a phlebotomist available in your area. You won't be able to proceed with this order.\n\nWe're sorry for this incovenience. Our team is here to support you and your practitioner through the process of finding alternate labs. You can try another zip code, or [message us](mailto:${SUPPORT_EMAIL}) for support.`,
  },
  failureWithOtherCompanies: {
    icon: AlertYellowIcon,
    borderColor: colors.yellow[200],
    backgroundColor: colors.yellow[50],
    iconBackgroundColor: colors.yellow[200],
    textColor: colors.yellow[800],
    title:
      "Scarlet does not service your area for blood draws, so we've removed BioReference Testing from your order.",
    description: `BioReference lab tests require a blood draw, which is completed by a mobile phlebotomy company called Scarlet. Unfortunately, Scarlet does not have a phlebotomist available in your area. You won't be able to proceed with this order in full.\n\nWe've updated your cart to remove BioReference testing. You can still proceed with payment for all non-BioReference tests. We'll let your practitioner know as well. You can try another zip code, or [message us](mailto:${SUPPORT_EMAIL}) for support.`,
  },
  error: {
    icon: AlertRedIcon,
    borderColor: colors.red[200],
    backgroundColor: colors.red[50],
    iconBackgroundColor: colors.red[200],
    textColor: colors.red[800],
    title: "Checking for mobile blood draw services nearby...",
    description: `This seems to be taking longer than expected. [Message us](mailto:${SUPPORT_EMAIL}) for support.`,
  },
  loadingSlow: {
    icon: <CircularProgress aria-label="Loading" size={25} />,
    borderColor: colors.blueGray[300],
    backgroundColor: colors.blueGray[50],
    iconBackgroundColor: "",
    textColor: textPrimaryColor,
    title: "Checking for mobile blood draw services nearby...",
    description: `This seems to be taking longer than expected. [Message us](mailto:${SUPPORT_EMAIL}) for support.`,
  },
}

const CANCELLATION_REASON =
  "I could not proceed with my order because I'm in a location that isn't covered by Scarlet mobile phlebotomy services for BioReference tests."

function getStatusFromOrder(order: PatientPortalOrder): Statuses {
  let status: Statuses

  const labTestLineItems = order.line_items.filter(
    (lineItem) => lineItem.type === ORDER_LINE_ITEM_TYPE.LAB_TEST
  )

  const bioreferenceLineItems = labTestLineItems.filter(
    (lineItem) =>
      lineItem.type === ORDER_LINE_ITEM_TYPE.LAB_TEST &&
      isOrderedTestForCompany(
        lineItem.ordered_test,
        LAB_COMPANY_KEY.BIOREFERENCE
      )
  )
  const bioreferenceIsDisabled = bioreferenceLineItems.some(
    (lineItem) => !isLineItemActive(lineItem)
  )
  const onlyBioreferenceLineItems =
    labTestLineItems.length === bioreferenceLineItems.length

  if (onlyBioreferenceLineItems && bioreferenceIsDisabled) {
    status = "failureOnlyBRL"
  } else if (bioreferenceIsDisabled) {
    status = "failureWithOtherCompanies"
  } else {
    status = "success"
  }

  return status
}

const ScarletResponse = ({ zipcode, setCancelModalOpenWithOptions }: Props) => {
  const token = useCheckoutTokenFromLocation()
  const order = useAppSelector(selectOrderState).order
  const dispatch = useDispatch()

  const [status, setStatus] = useState<Statuses>("loading")

  // On mount we fetch the response
  useEffect(() => {
    const updateOrderWithZipcode = async () => {
      setStatus("loading")

      let updatedOrder: PatientPortalOrder
      const slowLoadingTimeoutId = setTimeout(() => {
        setStatus("loadingSlow")
      }, 8 * 1000)

      try {
        // @ts-ignore
        updatedOrder = await dispatch(actions.updateOrder({ token, zipcode }))
      } catch (error) {
        setStatus("error")
        // We have a result so we can clear this
        clearTimeout(slowLoadingTimeoutId)
        return
      }

      // We have a result so we can clear this
      clearTimeout(slowLoadingTimeoutId)

      setStatus(getStatusFromOrder(updatedOrder))
    }

    if (token) {
      updateOrderWithZipcode()
    }
  }, [zipcode, setStatus, token])

  const {
    icon,
    borderColor,
    backgroundColor,
    iconBackgroundColor,
    textColor,
    title,
    description,
  } = statusToData[status]

  const classes = useStyles({
    borderColor,
    backgroundColor,
    iconBackgroundColor,
    textColor,
  })

  const hybridOrderClasses = useStyles({
    borderColor: colors.blueGray[200],
    backgroundColor: colors.blueGray[50],
    iconBackgroundColor: colors.blueGray[200],
    textColor: textPrimaryColor,
  })

  const showCustomerSupportLinks = status === "failureOnlyBRL"
  const showHybridOrderWarning =
    order?.flags.HYBRID_SCARLET_PHLEB &&
    ["success", "failureWithOtherCompanies"].includes(status)
  const statusSupportsOrdering = [
    "success",
    "failureWithOtherCompanies",
  ].includes(status)

  const { setValue, trigger } = useFormContext()

  // Update the hidden field's value based on scarlet eligibility. The hidden field
  // prevents the form from being submitted when ineligible.
  useEffect(() => {
    setValue(FieldNames.SCARLET_IS_ELIGIBLE, statusSupportsOrdering)
    trigger(FieldNames.SCARLET_IS_ELIGIBLE)
  }, [statusSupportsOrdering, setValue, trigger])

  if (!order) {
    // NOTE: This is just to make TS happy. It's not possible for this component to be rendered
    // without an order.
    return null
  }

  return (
    <>
      <ControlledCheckboxInput hidden name={FieldNames.SCARLET_IS_ELIGIBLE} />
      <div className={classes.wrapper}>
        {typeof icon === "string" ? (
          <div className={classes.iconContainer}>
            <img src={icon} alt={status} />
          </div>
        ) : (
          <div className={classes.loadingContainer}>{icon}</div>
        )}
        <div>
          <BodyText weight="semibold" className={classes.title}>
            {title}
          </BodyText>
          <Markdown className={classes.message}>{description}</Markdown>
          {showCustomerSupportLinks && (
            <div className={classes.failureOnlyBRLButtons}>
              <Button
                size="xsmall"
                color="secondary"
                href={`mailto:${SUPPORT_EMAIL}`}
              >
                Contact Customer Support
              </Button>
              <div className={classes.cancelOrderContainer}>
                {"or "}
                <Button
                  color="text"
                  onClick={() =>
                    setCancelModalOpenWithOptions({
                      cancellationReason: CANCELLATION_REASON,
                    })
                  }
                  className={classes.cancelButton}
                >
                  cancel your order
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
      {showHybridOrderWarning &&
        orderHasTestWithCompany(order, LAB_COMPANY_KEY.BIOREFERENCE) && (
          <div
            className={clsx(
              hybridOrderClasses.wrapper,
              hybridOrderClasses.hybridOrderWrapper
            )}
          >
            <div className={hybridOrderClasses.iconContainer}>
              <img src={BloodIcon} alt="" />
            </div>
            <div>
              <Markdown className={hybridOrderClasses.message}>
                You'll need an additional blood draw for some of your
                non-BioReference kits. You will pay this directly to the person
                who draws your blood, and it typically ranges from $30-$100.
                We'll send you instructions on how to coordinate this.
              </Markdown>
            </div>
          </div>
        )}
    </>
  )
}

export default ScarletResponse
