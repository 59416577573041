import makeAppStyles from "app/utils/makeAppStyles"
import { BiomarkerCustomDescription } from "types/biomarker-custom-description"
import { DiscreteResult } from "types/discrete-result"

import BloodLabDashboardsBiomarkerGrouping from "./BloodLabDashboardsBiomarkerGrouping"

const useStyles = makeAppStyles((theme) => ({
  container: {},
  groupedSection: {
    borderRadius: 12,
    boxShadow:
      "0px 1px 2px 0px rgba(0, 0, 0, 0.06), 0px 1px 3px 0px rgba(0, 0, 0, 0.10)",
  },
  title: {
    fontFamily: "Open Sans",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "135%",
    marginBottom: 9,
  },
}))

export default function BloodLabDashboardsBiomarkerSection({
  sectionTitle,
  discreteResults,
  showHighLowDescriptions,
  useOptimalRanges,
  biomarkerCustomDescriptionsMapping,
  biomarkersIdsWithoutResults,
}: {
  sectionTitle: string
  discreteResults: DiscreteResult[]
  showHighLowDescriptions?: boolean
  useOptimalRanges?: boolean
  biomarkerCustomDescriptionsMapping?: Record<
    string,
    BiomarkerCustomDescription
  >
  biomarkersIdsWithoutResults?: string[]
}) {
  const classes = useStyles()

  return discreteResults.length > 0 ? (
    <div className={classes.container}>
      <div className={classes.title}>{sectionTitle}</div>
      <div className={classes.groupedSection}>
        <BloodLabDashboardsBiomarkerGrouping
          discreteResults={discreteResults}
          showHighLowDescriptions={showHighLowDescriptions}
          useOptimalRanges={useOptimalRanges}
          biomarkerCustomDescriptionsMapping={
            biomarkerCustomDescriptionsMapping
          }
          biomarkersIdsWithoutResults={biomarkersIdsWithoutResults}
        />
      </div>
    </div>
  ) : null
}
