import { Link } from "react-router-dom"

import { Icon, Input, Paper, Typography } from "@material-ui/core"

import { UserPaths } from "app/Routes"

function Error404Page() {
  return (
    <div className="flex flex-col flex-1 items-center justify-center p-4">
      <div className="max-w-lg text-center">
        <Typography variant="h1" color="inherit" className="font-semibold mb-4">
          404
        </Typography>

        <Typography variant="h5" color="textSecondary" className="mb-4">
          Sorry but we could not find the page you are looking for
        </Typography>

        <Paper
          className="flex items-center w-full h-14 p-4 mt-12 mb-4"
          elevation={1}
        >
          <Icon color="action">search</Icon>
          <Input
            placeholder="Search for anything"
            className="pl-4"
            disableUnderline
            fullWidth
            inputProps={{
              "aria-label": "Search",
            }}
          />
        </Paper>

        <Link className="font-semibold" to={UserPaths.ROOT}>
          Go back to dashboard
        </Link>
      </div>
    </div>
  )
}

export default Error404Page
