import React from "react"

import { ReactComponent as CertificationIcon } from "app/assets/icons/certification.svg"
import { ReactComponent as LicenseIcon } from "app/assets/icons/license.svg"

import { LicenseType } from "../types"

interface Props {
  licenseType: LicenseType | undefined
  className?: string
}

export const CredentialIcon: React.FC<Props> = ({ licenseType, className }) => {
  if (licenseType === LicenseType.license) {
    return <LicenseIcon className={className} />
  } else if (licenseType === LicenseType.certificate) {
    return <CertificationIcon className={className} />
  }
  return null
}
