import { Link, Typography, makeStyles } from "@material-ui/core"

import useAppSelector from "app/hooks/useAppSelector"
import { FieldNames } from "app/main/patient-checkout/fields"
import {
  selectSplitItPrivacyPolicyUrl,
  selectSplitItTermsAndConditionsUrl,
} from "app/main/patient-checkout/store/selectors"
import { ControlledCheckboxInput } from "app/patient-portal/fields"

const SplitItTermsAndConditions = () => {
  const classes = useStyles()
  const privacyPolicyUrl = useAppSelector(selectSplitItPrivacyPolicyUrl)
  const termsAndConditionsUrl = useAppSelector(
    selectSplitItTermsAndConditionsUrl
  )
  return (
    <ControlledCheckboxInput
      defaultValue={false}
      label={
        <Typography color="textPrimary" component="span">
          {"I accept SplitIt's "}
          <Link
            className={classes.link}
            href={termsAndConditionsUrl}
            target="_blank"
          >
            {"terms and conditions"}
          </Link>
          {" and "}
          <Link
            className={classes.link}
            href={privacyPolicyUrl}
            target="_blank"
          >
            {"privacy policy"}
          </Link>
          {"."}
        </Typography>
      }
      name={FieldNames.SPLITIT_IS_TERMS_ACCEPTED}
      shouldUnregister
    />
  )
}

const useStyles = makeStyles((theme) => ({
  link: {
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightMedium,
    textDecoration: "underline !important",
  },
}))

export default SplitItTermsAndConditions
