import BodyText from "app/components/design-system/BodyText"

import { BiomarkerCustomDescriptionStatuses } from "../../constants"
import BioamrkerCustomDescriptionStatus from "../BiomarkerCustomDescriptionStatus"
import columnHelper from "./column-helper"

export const biomarkerDescriptionStateColumn = columnHelper.accessor(
  "attributes.description",
  {
    cell: (info) => (
      <BioamrkerCustomDescriptionStatus
        status={
          info.getValue()
            ? BiomarkerCustomDescriptionStatuses.EDITED
            : BiomarkerCustomDescriptionStatuses.DEFAULT
        }
      />
    ),
    header: (info) => (
      <BodyText size="sm" weight="semibold">
        General Description
      </BodyText>
    ),
    id: "biomarkerDescriptionState",
    size: 200,
    minSize: 200,
  }
)
