import React from "react"

import { useController, useFormContext } from "react-hook-form"

import { FormControl, FormHelperText } from "@material-ui/core"

import FieldLabel, {
  FieldLabelProps,
} from "app/main/patient-checkout/fields/FieldLabel"

import RupaDatePicker, { RupaDatePickerProps } from "./RupaDatePicker"

interface Props extends Omit<RupaDatePickerProps, "value" | "onChange"> {
  name: string
  className?: string
  defaultValue?: string
  shouldUnregister?: boolean
  fieldLabelProps?: FieldLabelProps
}

const ControlledDatePicker: React.FC<Props> = ({
  name,
  defaultValue,
  shouldUnregister,
  className,
  fieldLabelProps,
  ...datePickerProps
}) => {
  const { control } = useFormContext()
  const {
    field: { ref, value, ...controlProps },
    fieldState: { error },
  } = useController({
    control,
    defaultValue,
    name,
    shouldUnregister,
  })

  return (
    <FormControl className={className} error={!!error} fullWidth>
      {fieldLabelProps && <FieldLabel {...fieldLabelProps} />}
      <RupaDatePicker
        value={value}
        errorOutline={!!error}
        {...datePickerProps}
        {...controlProps}
      />
      {!!error && <FormHelperText>{error.message}</FormHelperText>}
    </FormControl>
  )
}

export default ControlledDatePicker
