import { useState } from "react"

import { Helmet } from "react-helmet"

import useInterval from "app/hooks/use-interval"
import useAppSelector from "app/hooks/useAppSelector"
import { loadIntercom } from "app/services/intercom"
import { INTERCOM_APP_ID } from "app/settings"
import { IsUserLoggedIn } from "app/utils/auth-utils"

// Wait window.Intercom for a maximum of 10 seconds
const MAX_ATTEMPTS = 100
const POLLING_FREQUENCY = 100
const IntercomProvider = () => {
  const user = useAppSelector(({ auth }) => auth.user)
  const [loaded, setLoaded] = useState(false)
  const [attempts, setAttempts] = useState(0)
  const pollForIntercom =
    !loaded && attempts <= MAX_ATTEMPTS && IsUserLoggedIn(user)

  useInterval(
    () => {
      if (window.Intercom) {
        setLoaded(true)
        loadIntercom(user)
        loadIntercomCloseButton()
      }
      setAttempts(attempts + 1)
    },
    pollForIntercom ? POLLING_FREQUENCY : null
  )

  const loadIntercomCloseButton = () => {
    // method will inject closeButton which will be visible in all screen types
    // (home, list of messages, and single message) and set the styles for this button
    // to match the styles of other navigation messenger buttons
    const closeBtnId = "intercom-close"

    window.Intercom("onShow", function () {
      let i = 0
      const intervalId = setInterval(() => {
        // clear interval and exit when by some reason closeButton is not attached.
        // this is effectively sets a limit of maximum number of interval executions
        if (i > 50) {
          clearInterval(intervalId)
          return
        }

        const iframeMessenger = document.querySelector(
          'iframe[name="intercom-messenger-frame"]'
        ) as HTMLIFrameElement | null

        if (iframeMessenger) {
          // clear interval if close button exist
          const closeBtn = iframeMessenger.contentDocument?.getElementById(
            closeBtnId
          ) as HTMLElement | null
          // if button exist clear interval and return
          if (closeBtn) {
            clearInterval(intervalId)
            return
          }

          const iframeLauncher = document.querySelector(
            'iframe[name="intercom-launcher-frame"]'
          ) as HTMLIFrameElement | null

          var toggleLauncher = () => {
            const launcher = iframeLauncher?.contentDocument?.querySelector(
              "#intercom-container .intercom-launcher"
            ) as HTMLElement | null

            if (launcher) {
              launcher.addEventListener("click", () =>
                loadIntercomCloseButton()
              )
              launcher.click()
            }
          }

          // inject actual button with styles
          const doc = iframeMessenger.contentDocument
          if (doc) {
            const htmlContent = `
<div role="button" id="${closeBtnId}">
  <i size="12">
    <svg focusable="false" aria-hidden="true" width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg" color="white">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M8 7.23251L7.23251 8L4 4.76749L0.767492 8L0 7.23251L3.23251 4L0 0.767492L0.767492 0L4 3.23251L7.23251 0L8 0.767492L4.76749 4L8 7.23251Z" fill="currentColor"></path>
    </svg>
  </i>
</div>
`

            const btnDiv = doc.createElement("div")
            btnDiv.innerHTML = htmlContent
            btnDiv.addEventListener("click", () => toggleLauncher())
            doc.body.appendChild(btnDiv)

            if (doc.head) {
              const styleSheet = doc.createElement("style")
              styleSheet.innerHTML = `                
                #${closeBtnId} {    
                  position: fixed;   
                  top: 7px;
                  right: 9px;           
                  display: block;
                  z-index: 1000;
                  width: 48px;
                  height: 48px;
                  border-radius: 10px;
                  -webkit-box-align: center;
                  align-items: center;
                  -webkit-box-pack: center;
                  justify-content: center;
                  cursor: pointer;
                  transition: background-color 150ms ease 0s;
                  display: flex;
                }

                #${closeBtnId}:hover {    
                  background: rgba(0, 0, 0, 0.2);
                }

                #${closeBtnId} i {
                  display: flex;
                  -webkit-box-align: center;
                  align-items: center;
                  width: auto;                  
                }

                #${closeBtnId} svg {
                  width: auto;
                  min-width: 12px;
                  height: 12px;
                }

                #intercom-container [aria-label="Close"] {
                    display: none;
                }
              `
              doc.head.appendChild(styleSheet)
            }
          }
        }

        i++
      }, 200)
    })
  }

  return (
    // We've modified Intercom's Messenger widget script below to
    // add a `loading` flag. This flag allows us to determine the
    // the current state of the script, and act on whether or not the
    // script is currently loading, or has loaded.
    // We do so in the `setIsIntercomBlocked()` function when
    // determining whether or not to render the <AdblockBanner /> component.
    // It's worth noting that Intercom strips the `loading` flag when the
    // Intercom messenger is successfully initialized.
    <Helmet>
      <script>
        {`
              var APP_ID = "${INTERCOM_APP_ID}";
              (function () {
                var w = window;
                var ic = w.Intercom;
                if (typeof ic === "function") {
                  ic("reattach_activator");
                  ic("update", w.intercomSettings);
                } else {
                  var d = document;
                  var i = function () {
                    i.c(arguments);
                    i.loading = true;
                  };
                  i.q = [];
                  i.c = function (args) {
                    i.q.push(args);
                  };
                  w.Intercom = i;
                  var l = function () {
                    var s = d.createElement("script");
                    s.type = "text/javascript";
                    s.async = true;
                    s.src = "https://widget.intercom.io/widget/" + APP_ID;
                    var x = d.getElementsByTagName("script")[0];
                    x.parentNode.insertBefore(s, x);
                  };
                  if (document.readyState === "complete") {
                    l();
                  } else if (w.attachEvent) {
                    w.attachEvent("onload", l);
                  } else {
                    w.addEventListener("load", l, false);
                  }
                }
              })();

          `}
      </script>
    </Helmet>
  )
}

export default IntercomProvider
