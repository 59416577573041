import { Link as MuiLink } from "@material-ui/core"

import { colors } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"

const useStyles = makeAppStyles(() => ({
  disclaimerSection: {
    color: colors.blueGray[500],
    fontFamily: "Open Sans",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "130%",
  },
  underlined: {
    textDecoration: "underline !important",
    color: colors.blueGray[500],
    cursor: "pointer",
  },
}))

interface Props {
  optimalRangesSettingTurnedOn: boolean | undefined
  handleOriginalLabReportClick: () => void
}

const BloodLabDashboardsDisclaimerText = ({
  optimalRangesSettingTurnedOn,
  handleOriginalLabReportClick,
}: Props) => {
  const classes = useStyles()
  return (
    <div className={classes.disclaimerSection}>
      These descriptions {optimalRangesSettingTurnedOn && "and optimal ranges"}{" "}
      are developed through research and clinical experience to offer insight
      into overall well-being
      {optimalRangesSettingTurnedOn ? (
        <>
          ; these ranges are not personalized medical advice.{" "}
          <MuiLink
            className={classes.underlined}
            href="https://www.rupahealth.com/optimal-ranges"
            target="_blank"
            onClick={(e) => e.stopPropagation()}
          >
            Learn more
          </MuiLink>
          .
        </>
      ) : (
        "."
      )}
      <br />
      <br />
      Your client's{" "}
      <MuiLink
        className={classes.underlined}
        onClick={handleOriginalLabReportClick}
      >
        original lab report
      </MuiLink>{" "}
      (the “Report”) is the official documentation of their results; please
      always refer back to the Report. Rupa is not liable for any discrepancies
      between the Report and this visualization.
      <br />
      <br />
      Rupa Health makes no warranty or representation with respect to the
      content, completeness, reliability, accuracy, or availability of these
      descriptions and optimal ranges.
    </div>
  )
}

export default BloodLabDashboardsDisclaimerText
