import { useParams } from "react-router-dom"

import useFeatureFlag, { FeatureFlag } from "app/hooks/use-feature-flag"
import PatientDocumentsListV2 from "app/main/patient-orders/patient-documents/PatientDocumentsList"
import { PatientPortalTabCard } from "app/patient-portal/components/PatientPortalTabCard"
import { PatientPortalUser } from "app/patient-portal/types"
import useCachedResource from "app/swr/hooks/use-cached-resource"

const PatientPortalUserDocuments = () => {
  const [patientPortalDocumentsEnabled] = useFeatureFlag(
    FeatureFlag.PatientPortalDocuments
  )

  const { userId } = useParams<{
    userId: string
  }>()
  const user = useCachedResource<PatientPortalUser>({
    id: userId,
    type: "patient_portal_user",
  })

  if (!patientPortalDocumentsEnabled) {
    return <></>
  }

  return (
    <PatientPortalTabCard title="Documents" showResultsUploadIfEnabled>
      <PatientDocumentsListV2 inPatientPortal={true} user={user} />
    </PatientPortalTabCard>
  )
}

export default PatientPortalUserDocuments
