import { Link } from "react-router-dom"

import Dialog from "@material-ui/core/Dialog"

import { UserPaths } from "app/Routes"
import BodyText from "app/components/design-system/BodyText"
import DesignSystemButton from "app/components/design-system/Button"
import { ModalContent, ModalHeader } from "app/components/modals"
import { primaryColor } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"

const useStyles = makeAppStyles((theme) => ({
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(2),
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
  link: {
    color: primaryColor,
  },
}))

export function CouponDeleteModal({ open, onClose, title, onSuccess }) {
  const classes = useStyles()
  return (
    <Dialog
      maxWidth="xs"
      fullWidth
      onClose={onClose}
      aria-labelledby="remove-coupon-modal-title"
      open={open}
    >
      <ModalHeader
        title={title}
        onClose={onClose}
        ariaLabelId={"remove-coupon-modal-title"}
      />
      <ModalContent>
        <BodyText>
          Are you sure you'd like to remove this from your account? Re-applying
          the coupon from the{" "}
          <Link className={classes.link} to={UserPaths.PROMOTIONS}>
            Promotions
          </Link>{" "}
          page will add it back to your cart.
        </BodyText>
        <div className={classes.buttonContainer}>
          <DesignSystemButton
            color="secondary"
            onClick={onClose}
            className={classes.button}
          >
            Go Back
          </DesignSystemButton>
          <DesignSystemButton
            onClick={() => {
              onSuccess()
              onClose()
            }}
            className={classes.button}
          >
            Delete
          </DesignSystemButton>
        </div>
      </ModalContent>
    </Dialog>
  )
}
