import React from "react"

interface Props {
  title: React.ReactNode
  actionButtons?: React.ReactNode[]
}

export const PatientOrdersTabHeader: React.FC<Props> = ({
  title,
  actionButtons,
}) => {
  return (
    <div className="flex flex-col md:flex-row justify-between items-center gap-2 mb-4 pb-2 border-b-[1px] border-slate-200">
      <div className="font-semibold text-xl19 mr-auto">{title}</div>
      <div className="flex flex-wrap items-center self-start -ml-2 md:ml-0">
        {actionButtons?.map((button, index) => (
          <div
            key={`header-action-${index}`}
            className="px-2 border-r-2 border-slate-200 last:border-r-0"
          >
            {button}
          </div>
        ))}
      </div>
    </div>
  )
}
