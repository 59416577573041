import { useState } from "react"

import useEventCallback from "app/hooks/use-event-callback"
import useHandleApiError from "app/hooks/use-handle-api-error"
import usePatchInterpretation from "app/results-summary/hooks/use-patch-interpretation"
import { UseResultsVisualizerResourcesHook } from "app/results-summary/hooks/use-results-summary-resources"
import useResourceSWR from "app/swr/hooks/use-resource-swr"
import { ResourceIdentifier } from "app/swr/types"
import { ResultsInterpretation } from "types/results-interpretation"

export default function useDownloadResultsSummary(
  interpretationSwr: UseResultsVisualizerResourcesHook["interpretationSwr"]
) {
  const handleApiError = useHandleApiError()
  const [isDownloading, setIsDownloading] = useState(false)

  const patchInterpretation = usePatchInterpretation(interpretationSwr)

  const download = useEventCallback(async () => {
    const pdfTab = window.open(
      "https://embed.rupahealth.com/loading-interpretation",
      "_blank"
    )
    try {
      setIsDownloading(true)
      // Send patch request to trigger PDF generation
      const data = await patchInterpretation({
        attributes: { generate_pdf: true },
      })
      if (data?.attributes.generated_file && pdfTab) {
        // update the tab with the pdf link
        pdfTab!.location.href = data.attributes.generated_file
      }
    } catch (error) {
      // in case of error, close the pdf tab
      if (pdfTab) {
        pdfTab.close()
      }
      handleApiError(error)
    } finally {
      setIsDownloading(false)
    }
  })

  return { download, isDownloading }
}

export function useLoadAndDownloadResultsSummary(
  identifier: ResourceIdentifier
) {
  const interpretationSwr = useResourceSWR<ResultsInterpretation>(
    `/results_interpretations/${identifier.id}/`,
    {
      include: ["biomarkers", "tasks", "biomarkers.discrete_result"],
    },
    {
      fallbackIdentifier: identifier,
      revalidateOnMount: true,
    }
  )
  return useDownloadResultsSummary(interpretationSwr)
}
