import { Control, useController } from "react-hook-form"

import RichTextEditor from "app/components/RichTextEditor"
import { colors, primaryColor } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"
import { EditorState } from "types/editor-state"

const useStyles = makeAppStyles((theme) => ({
  root: {
    position: "relative",
    width: "100%",
  },
  content: {
    position: "relative",
    display: "block",
    overflow: "auto",
    width: "100%",
    padding: 0,
    borderRadius: 0,
  },
  toolbar: {
    position: "sticky",
    left: 0,
    padding: theme.spacing(1.0, 3.0, 0.0),
  },
  editable: {
    minWidth: 750,
    width: "100%",
    padding: theme.spacing(3.0),
    backgroundColor: "white",
    border: "1px solid transparent",
    borderBottomLeftRadius: 6,
    borderBottomRightRadius: 6,
    "&[disabled]": {
      backgroundColor: colors.coolGray[50],
    },
    "& p + p, & ul + p, & ol + p": {
      marginTop: theme.spacing(1.0),
    },
  },
  editableFocused: {
    border: `1px solid ${primaryColor}`,
    boxShadow: `0px 0px 3.1099px ${primaryColor}`,
  },
}))

export interface FoodPlanRichTextEditorFieldProps {
  control: Control<any>
  disabled?: boolean
  feedContent?: EditorState
  loading?: boolean
  name: string
  placeholder?: string
  readOnly?: boolean
}

export default function FoodPlanRichTextEditorField({
  control,
  disabled,
  feedContent,
  loading,
  name,
  placeholder,
  readOnly,
}: FoodPlanRichTextEditorFieldProps) {
  const classes = useStyles()

  const { field } = useController({
    control,
    name,
    shouldUnregister: true,
  })

  const hasFeedContent = Boolean(feedContent)

  return (
    <div className={classes.root}>
      <RichTextEditor
        autoFocus={!readOnly && !hasFeedContent}
        classes={{
          content: classes.content,
          editable: classes.editable,
          editableFocused: classes.editableFocused,
          toolbar: classes.toolbar,
        }}
        controlled
        disabled={disabled}
        features={{
          table: true,
        }}
        loading={loading}
        onBlur={!hasFeedContent ? field.onBlur : undefined}
        onChange={!hasFeedContent ? field.onChange : undefined}
        placeholder={placeholder}
        readOnly={readOnly || hasFeedContent}
        value={!hasFeedContent ? field.value : feedContent}
      />
    </div>
  )
}
