import { makeStyles } from "@material-ui/core/styles"

import InfoIcon from "app/assets/icons/info-gray-circle.svg"
import BodyText from "app/components/design-system/BodyText"
import { colors } from "app/theme"

const useStyles = makeStyles({
  infoIcon: {
    marginRight: 10,
  },
  infoWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    width: "100%",
    marginTop: 20,
    padding: "10px 14px",
    borderRadius: 6,
    background: colors.blueGray[50],
    border: `1px solid ${colors.blueGray[300]}`,
  },
})

interface Props {
  message: string
}

export const InfoMessage = ({ message }: Props) => {
  const classes = useStyles()

  return (
    <div className={classes.infoWrapper}>
      <img src={InfoIcon} alt="" className={classes.infoIcon} />
      <BodyText size="sm">{message}</BodyText>
    </div>
  )
}
