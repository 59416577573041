import clsx from "clsx"

import {
  faCircleCheck,
  faCircleExclamation,
} from "@fortawesome/free-solid-svg-icons"
import { faArrowUpArrowDown } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@rupahealth/design"

import { primaryColor, textPrimaryColor } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"

import { DropDownOption, InRangeOption } from "../types/types"
import OptionLabel from "./OptionLabel"

const IN_RANGE_OPTIONS: DropDownOption[] = [
  {
    key: InRangeOption.ALL,
    label: <>Out of Range</>,
    dropdownLabel: <>All Markers</>,
    icon: <FontAwesomeIcon icon={faArrowUpArrowDown} className="text-sm" />,
  },
  {
    key: InRangeOption.IN_RANGE,
    label: <>Normal</>,
    dropdownLabel: <>Normal</>,
    icon: (
      <FontAwesomeIcon
        icon={faCircleCheck}
        className="text-emerald-500 text-sm"
      />
    ),
  },
  {
    key: InRangeOption.OUTSIDE_OPTIMAL_RANGE,
    label: <>Outside Optimal</>,
    dropdownLabel: <>Outside Optimal</>,
    icon: (
      <FontAwesomeIcon
        icon={faCircleExclamation}
        className="text-yellow-500 text-sm"
      />
    ),
  },
  {
    key: InRangeOption.OUT_OF_RANGE,
    label: <>Abnormal</>,
    dropdownLabel: <>Abnormal</>,
    icon: (
      <FontAwesomeIcon
        icon={faCircleExclamation}
        className="text-rose-500 text-sm"
      />
    ),
  },
]

const useStyles = makeAppStyles(() => ({
  activeSelect: {
    borderColor: `${primaryColor} !important`,
  },
}))

interface Props {
  handleInRangeValueChange: (value: InRangeOption) => void
  inRangeValue: InRangeOption
}

const InRangeFilter = ({ handleInRangeValueChange, inRangeValue }: Props) => {
  const classes = useStyles()

  const getInRangeOptionByKey = (key: string) => {
    return IN_RANGE_OPTIONS.find((option) => option.key === key)
  }

  return (
    <div>
      <Select
        onValueChange={handleInRangeValueChange}
        value={inRangeValue}
        defaultValue={InRangeOption.ALL}
      >
        <SelectTrigger
          className={clsx(
            "w-full lg:w-[200px] py-1 pr-2 pl-4 rounded-lg shadow-sm border border-slate-200 whitespace-nowrap",
            inRangeValue !== InRangeOption.ALL ? classes.activeSelect : ""
          )}
        >
          <SelectValue
            placeholder={
              <OptionLabel
                option={getInRangeOptionByKey(InRangeOption.ALL)}
                color={textPrimaryColor}
              />
            }
          >
            <OptionLabel
              option={getInRangeOptionByKey(inRangeValue)}
              color={textPrimaryColor}
            />
          </SelectValue>
        </SelectTrigger>
        <SelectContent className="z-[1300]">
          {IN_RANGE_OPTIONS.map((option) => (
            <SelectItem key={option.key} value={option.key}>
              <OptionLabel
                option={option}
                color={
                  option.key === inRangeValue &&
                  option.key !== InRangeOption.ALL
                    ? primaryColor
                    : textPrimaryColor
                }
                useDropdownLabel
              />
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
    </div>
  )
}

export default InRangeFilter
