import { combineReducers } from "redux"

import dashboard from "./dashboard.reducer"
import onboarding from "./onboarding.reducer"

const reducer = combineReducers({
  dashboard,
  onboarding,
})

export default reducer
