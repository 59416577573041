import { useState } from "react"

import Grow from "@material-ui/core/Grow"
import Paper from "@material-ui/core/Paper"
import Typography from "@material-ui/core/Typography"

import Markdown from "app/components/Markdown"
import Tooltip from "app/components/Tooltip"
import { additionalLineItemInfo } from "app/constants"
import { OrderLineItem } from "app/types"
import makeAppStyles from "app/utils/makeAppStyles"

const useStyles = makeAppStyles({
  icon: {
    height: 16,
    width: 16,
  },
})

function AdditionalFeeLineItem({ lineItem }: { lineItem: OrderLineItem }) {
  const [tooltipOpen, setTooltipOpen] = useState(false)
  const classes = useStyles()

  const handleTooltipOpen = () => {
    setTooltipOpen(true)
  }

  const handleTooltipClose = () => {
    setTooltipOpen(false)
  }

  if (!lineItem) {
    return null
  }

  const { tooltipMessage, icon } = additionalLineItemInfo[lineItem.type]

  return (
    <Grow in={true} style={{ transformOrigin: "50% 0 0" }} timeout={250}>
      <Tooltip
        interactive
        open={tooltipOpen}
        onClose={handleTooltipClose}
        onOpen={handleTooltipOpen}
        title={<Markdown padded={false}>{tooltipMessage}</Markdown>}
        placement="top"
        arrow
      >
        <Paper
          className="bg-gray-100 rounded-lg px-4 py-3 flex justify-between items-center mb-4 border-b-4 border-gray-400"
          elevation={0}
        >
          <div className="flex items-center">
            {typeof icon === "string" ? (
              <img src={icon} alt="" className={classes.icon} />
            ) : (
              // If it's not a string, assume it's a rendered react element
              icon
            )}
            <Typography className="text-base15 font-bold text-gray-800 ml-2">
              {lineItem.title}
            </Typography>
          </div>
          <div className="flex flex-col justify-end">
            <Typography className="font-bold text-lg" align="right">
              ${lineItem.cost}
            </Typography>
          </div>
        </Paper>
      </Tooltip>
    </Grow>
  )
}

export default AdditionalFeeLineItem
