import { Order } from "app/types"

export default function orderContainsLabTestFromCompany(
  order: Order,
  companies: string[]
) {
  return Boolean(
    order?.ordered_tests?.find(({ lab_test }) =>
      companies.includes(lab_test?.lab_company?.key)
    )
  )
}

export function orderedTestsContainingInOfficeKitFromAGivenCompany(
  order: Order,
  companies: string[]
) {
  return order?.ordered_tests?.filter(
    (ot) =>
      companies.includes(ot.lab_test?.lab_company?.key) &&
      ot.instant_requisition
  )
}
