import { createContext, useContext, useMemo, useRef } from "react"
import * as React from "react"

import clsx from "clsx"

import { darken, lighten } from "@material-ui/core"
import * as DropdownMenuPrimitive from "@radix-ui/react-dropdown-menu"

import { ReactComponent as DownArrowIcon } from "app/assets/icons/carets/down-caret-dark-blue.svg"
import { ReactComponent as WhiteCheckmarkIcon } from "app/assets/icons/white-checkmark.svg"
import useResizeObserver from "app/hooks/use-resize-observer"
import { colors, primaryColor, shadows, textPrimaryColor } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"

export interface PractitionerSelectContextShape {
  onValueChange: (value: string) => void
  value?: string
}

export const PractitionerSelectContext =
  createContext<PractitionerSelectContextShape>({
    onValueChange: () => {},
    value: undefined,
  })

const useSelectItemStyles = makeAppStyles({
  item: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 14,
    backgroundColor: "white",
    color: textPrimaryColor,
    textAlign: "left",
    fontSize: 15,
    fontWeight: 600,
    lineHeight: 1.35,
    cursor: "pointer",
    "&:first-child": {
      borderTopLeftRadius: 5,
      borderTopRightRadius: 5,
    },
    "& + &": {
      borderTop: `1px solid ${colors.blueGray[300]}`,
    },
    "&:last-child": {
      borderBottomLeftRadius: 5,
      borderBottomRightRadius: 5,
    },
    "&:hover, &:focus": {
      backgroundColor: colors.blueGray[100],
      color: darken(textPrimaryColor, 0.25),
    },
    "&[data-is-active=true]": {
      backgroundColor: primaryColor,
      color: "white",
    },
    "&[data-is-active=true]:hover, &[data-is-active=true]:focus": {
      backgroundColor: darken(primaryColor, 0.1),
      color: "white",
    },
  },
  itemActiveIcon: {
    fill: "white",
    minWidth: 16,
    marginLeft: 6,
  },
})

export interface PractitionerSelectItemProps {
  children: React.ReactNode
  className?: string
  value: string
}

export function PractitionerSelectItem({
  children,
  className,
  value,
}: PractitionerSelectItemProps) {
  const classes = useSelectItemStyles()
  const { onValueChange, value: currentValue } = useContext(
    PractitionerSelectContext
  )
  const isActive = currentValue === value

  const handleClick: React.MouseEventHandler<HTMLDivElement> = (e) => {
    if (!isActive) {
      onValueChange(value)
    }
  }

  return (
    <DropdownMenuPrimitive.Item
      className={clsx(className, classes.item)}
      data-is-active={isActive}
      onClick={handleClick}
    >
      {children}
      {isActive && (
        <WhiteCheckmarkIcon
          className={classes.itemActiveIcon}
          height={16}
          width={16}
          viewBox="0 0 17 17"
        />
      )}
    </DropdownMenuPrimitive.Item>
  )
}

const useSelectStyles = makeAppStyles<{ width?: number }>({
  selectTrigger: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    padding: "17px 13px",
    border: `1px solid ${colors.blueGray[300]}`,
    borderRadius: 6,
    boxShadow: shadows.default,
    backgroundColor: "white",
    color: textPrimaryColor,
    textAlign: "left",
    fontSize: 15,
    fontWeight: 600,
    lineHeight: 1.35,
    "&:hover": {
      backgroundColor: colors.blueGray[100],
      color: darken(textPrimaryColor, 0.2),
    },
    "&[data-has-value=false]": {
      color: colors.blueGray[400],
    },
    "&[data-has-value=false]:hover": {
      color: darken(colors.blueGray[400], 0.25),
    },
    "&[disabled], &[disabled=true], &:disabled": {
      backgroundColor: colors.gray[100],
      color: lighten(textPrimaryColor, 0.25),
      boxShadow: "none",
      cursor: "not-allowed",
    },
    "&:hover $selectTriggerIcon": {
      fill: darken(primaryColor, 0.25),
    },
    '&[data-state="open"] $selectTriggerIcon': {
      transform: "rotate(180deg)",
    },
  },
  selectTriggerIcon: {
    fill: primaryColor,
  },
  selectContent: {
    maxHeight: "330px",
    overflowY: "auto",
    display: "flex",
    flexDirection: "column",
    width: ({ width }) => width,
    margin: 0,
    border: `1px solid ${colors.blueGray[300]}`,
    borderRadius: 5,
    boxShadow: shadows.xl,
    backgroundColor: "white",
  },
})

export interface PractitionerSelectProps {
  children: React.ReactNode
  displayValue?: React.ReactNode
  onValueChange: (value: string) => void
  placeholder?: string
  readOnly: boolean
  value?: string
  // additional props passed through to the trigger
  [key: string]: any
}

export function PractitionerSelect({
  children,
  displayValue,
  onValueChange,
  placeholder = "Select a practitioner...",
  readOnly,
  value,
  ...triggerProps
}: PractitionerSelectProps) {
  const context = useMemo<PractitionerSelectContextShape>(() => {
    return {
      onValueChange,
      value,
    }
  }, [onValueChange, value])
  const ref = useRef<HTMLButtonElement>(null)
  const { width } = useResizeObserver<HTMLButtonElement>({
    box: "border-box",
    ref,
  })
  const classes = useSelectStyles({ width })
  return (
    <PractitionerSelectContext.Provider value={context}>
      <DropdownMenuPrimitive.Root>
        <DropdownMenuPrimitive.Trigger
          className={classes.selectTrigger}
          data-has-value={Boolean(value)}
          disabled={readOnly}
          ref={ref}
          {...triggerProps}
        >
          {Boolean(value) ? displayValue : placeholder}
          {!readOnly && (
            <DownArrowIcon
              className={classes.selectTriggerIcon}
              height={16}
              width={16}
              viewBox="0 0 13 8"
            />
          )}
        </DropdownMenuPrimitive.Trigger>

        <DropdownMenuPrimitive.Portal>
          <DropdownMenuPrimitive.Content
            avoidCollisions={false}
            className={classes.selectContent}
            sideOffset={5}
          >
            {children}
          </DropdownMenuPrimitive.Content>
        </DropdownMenuPrimitive.Portal>
      </DropdownMenuPrimitive.Root>
    </PractitionerSelectContext.Provider>
  )
}

PractitionerSelect.Item = PractitionerSelectItem

export default PractitionerSelect
