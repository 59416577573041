import { LabCompany } from "types/lab-company"

import { DisplayLabCompany, LabCompanyAccount } from "../types"
import AccountCard from "./AccountCard"

interface AccountCardContainerProps {
  displayCompany: DisplayLabCompany
  accounts: LabCompanyAccount[]
  isLoadingLabCompanyAccounts: boolean
  mutateLabCompanyAccounts: () => void
  labCompaniesWithAccounts: LabCompany[]
}

const findMatchingAccount = (
  accounts: LabCompanyAccount[],
  displayCompany: DisplayLabCompany,
  labCompaniesWithAccounts: LabCompany[]
) => {
  for (const account of accounts) {
    const companiesOnAccount = labCompaniesWithAccounts.filter((company) => {
      return account.relationships?.lab_companies.data.find(
        (companyIdentifier) => companyIdentifier.id === company.id
      )
    })
    const companyToDisplayAccountFor = companiesOnAccount.find((company) => {
      return company.attributes.key === displayCompany.key
    })
    if (companyToDisplayAccountFor) {
      return account
    }
  }
  return undefined
}

const AccountCardContainer = ({
  displayCompany,
  accounts,
  isLoadingLabCompanyAccounts,
  mutateLabCompanyAccounts,
  labCompaniesWithAccounts,
}: AccountCardContainerProps) => {
  const matchedAccount = findMatchingAccount(
    accounts,
    displayCompany,
    labCompaniesWithAccounts
  )
  return (
    <AccountCard
      displayCompany={displayCompany}
      account={matchedAccount}
      isLoadingLabCompanyAccounts={isLoadingLabCompanyAccounts}
      mutateLabCompanyAccounts={mutateLabCompanyAccounts}
    />
  )
}

export default AccountCardContainer
