import * as React from "react"

import { Dialog } from "@material-ui/core"

import useComparisonActions from "app/main/comparison/hooks/use-comparison-actions"
import useComparisonState from "app/main/comparison/hooks/use-comparison-state"
import makeAppStyles from "app/utils/makeAppStyles"

const useStyles = makeAppStyles((theme) => ({
  paper: {
    height: "100%",
    width: "100%",
    maxHeight: `calc(100% - ${theme.spacing(4.0)}px)`,
    maxWidth: `calc(100% - ${theme.spacing(4.0)}px)`,
    margin: theme.spacing(2.0),
    borderRadius: 7,
    [theme.breakpoints.down("sm")]: {
      maxHeight: "100%",
      maxWidth: "100%",
      margin: 0,
      borderRadius: 0,
    },
  },
}))

export default function ComparisonModalDialog({
  children,
}: {
  children: React.ReactNode
}) {
  const classes = useStyles()
  const actions = useComparisonActions()
  const state = useComparisonState()
  return (
    <Dialog
      aria-labelledby="comparison-modal-title"
      classes={{
        paper: classes.paper,
      }}
      className="fs-unmask"
      disableEnforceFocus
      fullWidth
      maxWidth={false}
      onClose={actions.closeComparisonModal}
      open={state.isComparisonModalActive}
    >
      {children}
    </Dialog>
  )
}
