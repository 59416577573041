import { ReactElement, useEffect } from "react"
import * as React from "react"
import { useDispatch } from "react-redux"

import { useForm, FormProvider } from "react-hook-form"
import { z } from "zod"

import { zodResolver } from "@hookform/resolvers/zod"
import { Box, makeStyles, InputAdornment } from "@material-ui/core"

import BodyText from "app/components/design-system/BodyText"
import { FormFieldLabel } from "app/components/forms/fields"
import { ControlledTextField } from "app/main/patient-checkout/fields"
import { Theme } from "app/theme"
import { CustomFee, StripeConnectAccount } from "app/types"
import { handleApiError, handleApiSuccess } from "app/utils"

import { CreateCustomFeeSchema } from "./CreateCustomFeeFormSchema"

type CustomFeeFormProps = {
  customFee?: CustomFee
  stripeConnectAccount?: StripeConnectAccount
  maxPrice?: number
  createOrUpdateCustomFee: (
    account: StripeConnectAccount,
    customFee: CustomFee | undefined,
    newCustomFee: Pick<CustomFee, "price" | "name">
  ) => Promise<void>
  label?: string
  subtitle?: string | ReactElement
}

const CustomFeeForm: React.FC<CustomFeeFormProps> = ({
  customFee,
  stripeConnectAccount,
  createOrUpdateCustomFee,
  maxPrice = 200000,
  label = "Default fee",
  subtitle,
}) => {
  const CustomFeeSchema = CreateCustomFeeSchema(maxPrice)

  const methods = useForm<z.infer<typeof CustomFeeSchema>>({
    defaultValues: {
      name: customFee?.name,
      price: customFee ? customFee.price / 100 : undefined,
    },
    resolver: zodResolver(CustomFeeSchema),
    mode: "onChange",
  })

  const styles = (theme: Theme) => ({
    container: {
      display: "flex",
      "flex-direction": "row",
      [theme.breakpoints.down("md")]: {
        "flex-direction": "column",
      },
      width: "100%",
    },
    fieldSet: {
      width: "100%",
      marginRight: theme.spacing(2),
      display: "flex",
      "flex-direction": "column",
      marginBottom: theme.spacing(2),
    },
    label: {
      marginTop: theme.spacing(2),
    },
    subtitle: {
      marginBottom: 7,
    },
  })

  const useStyles = makeStyles(styles)
  const classes = useStyles(styles)

  useEffect(() => {
    if (customFee) {
      methods.setValue("name", customFee.name)
      methods.setValue("price", customFee.price / 100)
    }
  }, [customFee])

  const dispatch = useDispatch()

  const onSubmit = async ({ name, price: currentPrice }) => {
    const price = Number((currentPrice * 100).toFixed())

    if (!stripeConnectAccount) {
      return
    }

    try {
      await createOrUpdateCustomFee(stripeConnectAccount, customFee, {
        name,
        price,
      })

      dispatch(handleApiSuccess("Saved Custom Fee"))
    } catch (error) {
      dispatch(handleApiError(error))
    }
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Box className={classes.label}>
          <FormFieldLabel label={label} required={true} />
          {subtitle ? (
            <BodyText size="sm" className={classes.subtitle}>
              {subtitle}
            </BodyText>
          ) : null}
        </Box>

        <Box className={classes.container}>
          <ControlledTextField
            className={classes.fieldSet}
            name="name"
            placeholder="Ex. Lab interpretation fee, follow-up appointment, etc"
            defaultValue={customFee?.name}
            clickToEdit={true}
            required={true}
          />
          <ControlledTextField
            className={classes.fieldSet}
            name="price"
            placeholder="0.00"
            clickToEdit={true}
            defaultValue={
              customFee?.price ? String(customFee.price / 100) : undefined
            }
            required={true}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start" style={{ marginRight: 0 }}>
                  $
                </InputAdornment>
              ),
            }}
          />
        </Box>
      </form>
    </FormProvider>
  )
}

export default CustomFeeForm
