import { useRef, useState } from "react"

import clsx from "clsx"
import { useClickAway } from "react-use"

import { ChevronLeft } from "@material-ui/icons"

import LogoutIcon from "app/assets/images/logout-icon.svg"
import authService from "app/auth/services/simplejwt-auth-service"
import { shadows, titleFontFamily } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"

import BodyText from "../design-system/BodyText"
import DisplayText from "../design-system/DisplayText"
import AccountMenuItem from "./AccountMenuItem"

const buttonActive = {
  background: "rgba(255, 255, 255, 0.1)",
  border: "1px solid rgba(255, 255, 255, 0.03)",
}

const useStyles = makeAppStyles<{
  animationOrder: number
  menuOpen: boolean
  floating?: boolean
}>({
  container: {
    display: "flex",
    alignItems: "center",
    position: "relative",
    cursor: "pointer",

    "--animation-order": (props) => props.animationOrder,

    // This is the width of the expanded sidebar - padding. This makes the container the size of
    // max content size when expanded/hovered, so that content doesn't reflow when the navbar container changes.
    width: 266 - 30,
    marginTop: 24,
  },
  menu: {
    borderRadius: (props) => (props.floating ? 12 : 6),
    overflow: "hidden",
    // This is unique to the navbar so it's not from the color system
    backgroundColor: "#455b71",
    boxShadow: shadows.lg,
    position: "absolute",
    left: 0,
    right: 0,
    bottom: "calc(100% + 12px)",
    padding: 8,
    margin: "0 -6px",
    // Used by UserNavBar to hide the menu when the navbar is collpased
    transition: "0.24s opacity",
  },
  avatar: {
    height: 44,
    flex: "0 0 44px",
    borderRadius: 22,
    background: "linear-gradient(180deg, #4D6A86 0%, #425B72 100%)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "1px solid #4C657D",
  },
  avatarText: {
    fontFamily: titleFontFamily,
    fontSize: 19,
    color: "white",
    fontWeight: 600,
    marginTop: 4,
  },
  chevron: {
    transform: (props) =>
      props.menuOpen ? "rotate(-270deg)" : "rotate(-90deg)",
    color: "white",
  },
  name: {
    flex: 1,
    color: "white",
  },
  expandedContent: {
    display: "flex",
    alignItems: "center",
    flex: 1,
    transition: "0.24s opacity",
    marginLeft: 15,
    textAlign: "left",
  },
  button: (props) => ({
    display: "flex",
    alignItems: "center",
    transition: "0.16s background, 0.16s border",
    border: "1px solid rgba(255, 255, 255, 0)",
    borderRadius: props.floating ? 12 : 6,
    padding: 6,
    margin: -6,
    flex: 1,

    ...(props.menuOpen && buttonActive),
    "&:hover": buttonActive,
  }),
})

interface Props {
  className?: string
  animationOrder?: number
  firstName: string
  lastName: string
  floating?: boolean
}

const AccountMenu = ({
  className,
  animationOrder = 0,
  firstName,
  lastName,
  children,
  floating = false,
}: React.PropsWithChildren<Props>) => {
  const [menuOpen, setMenuOpen] = useState(false)

  const {
    container,
    menu,
    avatar,
    chevron,
    name,
    avatarText,
    expandedContent,
    button,
  } = useStyles({ animationOrder, menuOpen, floating })

  const containerRef = useRef(null)
  useClickAway(containerRef, () => setMenuOpen(false))

  const handleLogout = () => {
    setMenuOpen(false)

    authService.logout()
  }

  return (
    <div className={clsx(container, className)} ref={containerRef}>
      <button
        className={clsx(
          button,
          "account-menu__button",
          menuOpen && "account-menu__button--active"
        )}
        onClick={() => setMenuOpen(!menuOpen)}
      >
        <div className={avatar}>
          <DisplayText size="lg" weight="semibold" className={avatarText}>
            {firstName[0]}
            {lastName[0]}
          </DisplayText>
        </div>
        <div
          className={clsx(expandedContent, "account-menu__expanded-content")}
        >
          <BodyText weight="semibold" className={name}>
            {firstName} {lastName}
          </BodyText>
          <ChevronLeft className={chevron} />
        </div>
      </button>
      {menuOpen && (
        <div className={clsx(menu, "account-menu__menu")}>
          {children}
          <AccountMenuItem
            label="Sign Out"
            icon={LogoutIcon}
            onClick={handleLogout}
          />
        </div>
      )}
    </div>
  )
}

export default AccountMenu
