import { useState } from "react"

import { CellContext } from "@tanstack/react-table"

import BodyText from "app/components/design-system/BodyText"
import DesignSystemButton from "app/components/design-system/Button"
import useConfirmationModal from "app/components/modals/generic/hooks/use-confirmation-modal"
import useCachedResource from "app/swr/hooks/use-cached-resource"
import { colors } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"
import { Biomarker } from "types/biomarker"
import { BiomarkerCustomDescription } from "types/biomarker-custom-description"

import useModifyBiomarkerCustomDescriptionModal from "../../modals/hooks/use-modify-biomarker-custom-description"
import useBiomarkerCustomDescriptions from "../hooks/use-biomarker-custom-descriptions"
import columnHelper from "./column-helper"

const useStyles = makeAppStyles(() => ({
  buttons: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "baseline",
    gap: 10,
    marginTop: 6,
  },
  button: {
    minWidth: 0,
    "& .MuiButton-label": {
      justifyContent: "flex-start",
    },
  },
}))

const BiomarkerNameCell = ({
  getValue,
  row,
}: CellContext<
  BiomarkerCustomDescription,
  BiomarkerCustomDescription["relationships"]["biomarker"]
>) => {
  const classes = useStyles()

  const biomarker = useCachedResource<Biomarker>(getValue().data)

  const [modalDeleteLoading, setModalDeleteLoading] = useState(false)

  const confirmationModal = useConfirmationModal()

  const { onDeleteBiomarkerCustomDescription } =
    useBiomarkerCustomDescriptions()

  const modifyBiomarkerCustomDescriptionsModal =
    useModifyBiomarkerCustomDescriptionModal()

  const biomarkerCustomDescription =
    useCachedResource<BiomarkerCustomDescription>({
      id: row.id,
      type: "biomarker_custom_description",
    })

  const handleDeleteClick = () => {
    confirmationModal.show({
      title: "Remove Custom Biomarker Description",
      message: `This will remove your custom biomarker description for ${biomarker?.attributes.short_name} for everyone in your clinic.`,
      backButtonTitle: "Actually, Let's Keep It",
      confirmButtonTitle: "Remove Description",
      confirmButtonColor: "destructiveSecondary",
      onClose: confirmationModal.hide,
      loading: modalDeleteLoading,
      handleConfirm: async () => {
        setModalDeleteLoading(true)
        await onDeleteBiomarkerCustomDescription(row.id)
        setModalDeleteLoading(false)
        confirmationModal.hide()
      },
    })
  }

  return (
    <div>
      <BodyText weight="semibold">
        {biomarker?.attributes.short_name || "---"}
      </BodyText>
      <div className={classes.buttons}>
        <div>
          <DesignSystemButton
            color="text"
            onClick={() => {
              if (biomarkerCustomDescription) {
                modifyBiomarkerCustomDescriptionsModal.show({
                  biomarkerCustomDescription,
                })
              }
            }}
            className={classes.button}
          >
            Edit
          </DesignSystemButton>
        </div>
        <div style={{ color: colors.blueGray[200] }}>|</div>
        <div>
          <DesignSystemButton
            color="destructiveText"
            onClick={handleDeleteClick}
            className={classes.button}
          >
            Delete
          </DesignSystemButton>
        </div>
      </div>
    </div>
  )
}

export const biomarkerNameColumn = columnHelper.accessor(
  "relationships.biomarker",
  {
    cell: (info) => <BiomarkerNameCell {...info} />,
    header: (info) => (
      <BodyText size="sm" weight="semibold">
        Biomarker
      </BodyText>
    ),
    id: "biomarkerName",
    size: 200,
    minSize: 200,
  }
)
