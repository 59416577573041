import { useEffect, useState } from "react"

import { Dialog, Divider, Grid } from "@material-ui/core"
import MuiDialogContent from "@material-ui/core/DialogContent"
import MuiDialogTitle from "@material-ui/core/DialogTitle"

import CloseIcon from "app/assets/icons/close-icon-navy.svg"
import LabCompanyIcon from "app/assets/icons/lab-company-light.svg"
import Loading from "app/components/Loading"
import BodyText from "app/components/design-system/BodyText"
import DisplayText from "app/components/design-system/DisplayText"
import useCachedResource from "app/swr/hooks/use-cached-resource"
import { ResourceIdentifier, ResourceResponse } from "app/swr/types"
import resourceRequest from "app/swr/utils/resource-request"
import { colors, navy, shadows, white } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"
import { LabCompany } from "types/lab-company"

import {
  InOfficeKitLabCompanyType,
  InOfficeKitOrder,
  OrderDetailsType,
} from "../types"

const useStyles = makeAppStyles((theme) => ({
  closeIcon: {
    cursor: "pointer",
  },
  contentWrapper: {
    padding: 22.5,
    backgroundColor: colors.coolGray[100],
  },
  dialogWrapper: {
    "& .MuiDialog-paper": {
      border: `1px solid ${colors.blueGray[300]}`,
      margin: "8px",
      borderRadius: 8,
    },
  },
  dialogTitle: {
    borderBottom: `1px solid ${colors.blueGray[300]}`,
  },
  titleContainer: {
    alignItems: "center",
    display: "flex",
    margin: "0 2px",
    color: navy,
    justifyContent: "space-between",
    textAlign: "left",
  },
  dialogContent: {
    background: white,
    borderRadius: 4,
    boxShadow: shadows.default,
    padding: 25,
  },
  companyText: {
    marginBottom: 12,
  },
  labCompanyIcon: {
    marginRight: 9,
  },
  companyName: {
    display: "flex",
    alignItems: "center",
    "& img": {
      width: 17.4,
      height: 15.2,
    },
  },
  logoContainer: {
    margin: "auto",
    textAlign: "end",

    [theme.breakpoints.down("sm")]: {
      textAlign: "start",
      marginTop: "9px",
    },
  },
  logo: {
    maxHeight: 30,
    maxWidth: "75%",
    textAlign: "end",
    margin: "auto",
  },
  divider: {
    margin: "18px 0",
  },
  productDivider: {
    width: "100%",
    margin: "18px 0",
    background: colors.coolGray[100],
  },
  productContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
}))

interface Props {
  order?: InOfficeKitOrder
  setSelectedOrder: (order: InOfficeKitOrder | undefined) => void
}
export const OrderDetailsDialog = ({ order, setSelectedOrder }: Props) => {
  const classes = useStyles()

  const handleClose = () => setSelectedOrder(undefined)

  const iokLabCompanyId = order?.relationships?.in_office_kit_lab_company.data
  const iokLabCompany = useCachedResource<InOfficeKitLabCompanyType>(
    iokLabCompanyId ? iokLabCompanyId : undefined
  )

  const labCompanyId = iokLabCompany?.relationships?.lab_company.data
  const labCompany = useCachedResource<LabCompany>(
    labCompanyId ? labCompanyId : undefined
  )

  const [orderedProducts, setOrderedProducts] = useState<any[]>([])
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    const fetchOrder = async () => {
      if (!order?.id) {
        return
      }
      setLoading(true)

      // TODO: properly type this, use SWR, and put in a hook so it can be shared with ReorderModalButton
      const orderDetailsUrl = `/in_office_kit_order/${order?.id}`

      const orderDetails = (await resourceRequest({
        url: orderDetailsUrl,
        include: ["in_office_kit_ordered_products.in_office_kit_product"],
        method: "GET",
      })) as ResourceResponse<OrderDetailsType>

      const productsById =
        orderDetails.included
          ?.filter(
            (includedModel) => includedModel.type === "in_office_kit_product"
          )
          .reduce((productMap, product) => {
            productMap[product.id] = product
            return productMap
          }, {}) || {}

      const orderedProductsWithData =
        orderDetails.included
          ?.filter(
            (includedModel) =>
              includedModel.type === "in_office_kit_ordered_product"
          )
          .map((orderedProduct) => ({
            ...orderedProduct.attributes,
            ...(
              productsById[
                (
                  orderedProduct.relationships.in_office_kit_product
                    .data as ResourceIdentifier
                ).id
              ] || { attributes: {} }
            ).attributes,
          })) || []

      setOrderedProducts(orderedProductsWithData)
      setLoading(false)
    }

    fetchOrder()
  }, [order])

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="service-dialog"
      open={!!order}
      fullWidth
      maxWidth="sm"
      className={classes.dialogWrapper}
    >
      <MuiDialogTitle disableTypography className={classes.dialogTitle}>
        <div className={classes.titleContainer}>
          <DisplayText weight="semibold" size="xl">
            In-Office Supplies Order
          </DisplayText>
          <img
            src={CloseIcon}
            className={classes.closeIcon}
            alt="close icon"
            onClick={handleClose}
          />
        </div>
      </MuiDialogTitle>
      <MuiDialogContent className={classes.contentWrapper}>
        <div className={classes.dialogContent}>
          <Grid container>
            <Grid item md={6} xs={12}>
              <BodyText size="sm" className={classes.companyText}>
                Company
              </BodyText>
              <BodyText
                size="sm"
                weight="semibold"
                className={classes.companyName}
              >
                <img
                  src={LabCompanyIcon}
                  className={classes.labCompanyIcon}
                  alt="close icon"
                />
                {labCompany?.attributes?.name}
              </BodyText>
            </Grid>
            <Grid item md={6} xs={12} className={classes.logoContainer}>
              {labCompany?.attributes.logo && (
                <img
                  className={classes.logo}
                  src={labCompany.attributes.logo}
                  alt={labCompany.attributes.name}
                />
              )}
            </Grid>
          </Grid>
          <Divider className={classes.divider} />
          {loading && <Loading ariaLabel="Loading products" />}
          {orderedProducts?.map((orderedProduct, index) => (
            <Grid container>
              <Grid item xs={12} className={classes.productContainer}>
                <BodyText size="base" className={classes.companyText}>
                  {orderedProduct.name}
                </BodyText>
                <BodyText
                  size="base"
                  weight="semibold"
                  className={classes.companyText}
                >
                  {orderedProduct.quantity}
                </BodyText>
              </Grid>
              {index < orderedProducts.length - 1 && (
                <Divider className={classes.productDivider} />
              )}
            </Grid>
          ))}
        </div>
      </MuiDialogContent>
    </Dialog>
  )
}
