import { ReactComponent as CloseIcon } from "app/assets/icons/close-icon.svg"
import BodyText from "app/components/design-system/BodyText"

interface Props {
  headerText: string
  headerDescription?: string
  onClose: () => void
}
const FormHeader = ({ headerText, headerDescription, onClose }: Props) => {
  return (
    <div className="flex flex-row items-start justify-center w-full bg-white border-b border-slate-300">
      <div className="flex flex-col items-start justify-center w-full m-4">
        <BodyText className="font-semibold text-body text-xl leading-6 font-title">
          {headerText}
        </BodyText>
        {headerDescription && (
          <BodyText className="text-sm font-normal text-body font-sans">
            {headerDescription}
          </BodyText>
        )}
      </div>
      <CloseIcon
        className="cursor-pointer ml-auto mr-4 mt-4"
        onClick={onClose}
      />
    </div>
  )
}

export default FormHeader
