import { Link, styled } from "@material-ui/core"

import Blood from "app/assets/icons/blood.svg"
import BodyText from "app/components/design-system/BodyText"
import { colors } from "app/theme"
import {
  ClinicRecommendedPhlebotomist,
  PhlebotomistLocationType,
} from "app/types"
import { formatPhone } from "app/utils"

interface Props {
  clinicRecommendation: ClinicRecommendedPhlebotomist
  title?: string
}

const Container = styled("div")({
  borderTop: `1px solid ${colors.blueGray[200]}`,
  paddingTop: 22,
  marginTop: 22,
})

const InnerContainer = styled("div")({
  display: "flex",
  alignItems: "flex-start",
})

const BloodIcon = styled("img")({
  marginRight: 8,
  marginTop: 3,
})

const Content = styled("div")({
  display: "flex",
  flexFlow: "column",
})

const Text = styled(BodyText)({
  marginBottom: 8,
  overflowWrap: "anywhere",
})

const Title = styled(BodyText)({
  marginBottom: 16,
})

const Label = styled(BodyText)({
  display: "inline",
})

/*
 * NOTE: Below I've used `variantMapping` to render the bolded part of the
 * address as a `<span>` instead of `<p>` as HTML prohibits nesting `<p>`.
 * We should be able to use `component` over `variantMapping`, but MaterialUI's
 * Typescript definitions don't include it for some reason.
 */
const ClinicRecommendation = ({ clinicRecommendation, title }: Props) => (
  <Container>
    {Boolean(title) && (
      <Title size="md" weight="semibold">
        {title}:
      </Title>
    )}
    <InnerContainer>
      <BloodIcon src={Blood} alt="" />
      <Content>
        <Text weight="semibold" size="md">
          {clinicRecommendation.name}
        </Text>
        <PhlebotomistLocationTypeText
          location_types={clinicRecommendation.location_types}
        />
        {Boolean(clinicRecommendation.external_notes) && (
          <Text>
            <Label weight="semibold" variantMapping={{ body1: "span" }}>
              Notes:{" "}
            </Label>
            {clinicRecommendation.external_notes}
          </Text>
        )}
        {clinicRecommendation.address && (
          <Text>
            <Label weight="semibold" variantMapping={{ body1: "span" }}>
              Address:{" "}
            </Label>
            {clinicRecommendation.address}
          </Text>
        )}
        {clinicRecommendation.phone && (
          <Text>
            <Label weight="semibold" variantMapping={{ body1: "span" }}>
              Phone:{" "}
            </Label>
            <Link href={`tel:${clinicRecommendation.phone}`}>
              {formatPhone(clinicRecommendation.phone)}
            </Link>
          </Text>
        )}
        {clinicRecommendation.email && (
          <Text>
            <Label weight="semibold" variantMapping={{ body1: "span" }}>
              Email:{" "}
            </Label>
            <Link href={`mailto:${clinicRecommendation.email}`}>
              {clinicRecommendation.email}
            </Link>
          </Text>
        )}
        {clinicRecommendation.url && (
          <Text>
            <Label weight="semibold" variantMapping={{ body1: "span" }}>
              Website:{" "}
            </Label>
            <Link
              href={clinicRecommendation.url}
              target="_blank"
              rel="noreferrer"
            >
              {` ${clinicRecommendation.url} `}
            </Link>
          </Text>
        )}
        {clinicRecommendation.hours && (
          <Text>
            <Label weight="semibold" variantMapping={{ body1: "span" }}>
              Hours:{" "}
            </Label>
            {clinicRecommendation.hours}
          </Text>
        )}
        {clinicRecommendation.fee && (
          <Text>
            <Label weight="semibold" variantMapping={{ body1: "span" }}>
              Fee:{" "}
            </Label>
            {clinicRecommendation.fee}
          </Text>
        )}
      </Content>
    </InnerContainer>
  </Container>
)

interface PhlebotomistLocationTypeTextProps {
  location_types: PhlebotomistLocationType[]
}

const PhlebotomistLocationTypeText = ({
  location_types,
}: PhlebotomistLocationTypeTextProps) => {
  if (location_types.includes("mobile") && location_types.includes("clinic")) {
    return (
      <Text>
        This is a blood draw site that also offers mobile phlebotomy. You can go
        to this location or the phlebotomist can meet you wherever is
        convenient.
      </Text>
    )
  }
  if (location_types.includes("mobile")) {
    return (
      <Text>
        This is a mobile phlebotomist. They can meet you wherever is convenient.
      </Text>
    )
  }
  return null
}

export default ClinicRecommendation
