import React from "react"

import { ReactComponent as AbnormalResultsIcon } from "app/assets/icons/rupa-blood-dashboards/abnormal-icon.svg"
import { ReactComponent as NormalResultsIcon } from "app/assets/icons/rupa-blood-dashboards/normal-icon.svg"
import { PatientSettings } from "types/patient"

import { PdfAbnormalMarkersSection } from "./PdfAbnormalMarkersSection"
import { PdfAllMarkersLabTestSection } from "./PdfAllMarkersLabTestSection"
import { PdfHeader } from "./PdfHeader"
import { PdfDivider, PdfSectionTitle } from "./PdfHelpers"
import { ResultsPdfPayload } from "./use-bundle-results-pdf-payload"

export const BloodLabDashboardPdf: React.FC<{
  payload: ResultsPdfPayload
}> = ({ payload }) => {
  const patientHighLowEnabled =
    payload.patient?.attributes.patient_settings.includes(
      PatientSettings.INCLUDE_HIGH_LOW_DESCRIPTIONS_ON_BLOOD_REPORTS
    )

  return (
    // Total width of element for pdf is 718px
    <div className="w-[700px] mb-6 ml-3 ">
      <PdfHeader
        patientFirstName={payload.patient?.attributes.first_name}
        clinicLogoUrl={
          payload.clinic?.attributes.logo
            ? payload.clinic?.attributes.logo
            : undefined
        }
        practitioner={payload.practitioner}
        sampleCollectionDate={payload.sampleCollectionDate}
        isFasted={payload.isFasted}
        labCompanyName={payload.labCompanyName}
      />

      {payload.outOfRangeBloodReportResults.length > 0 && (
        <>
          <PdfDivider />
          <PdfSectionTitle
            title={"Abnormal Markers"}
            icon={<AbnormalResultsIcon width="28" />}
          />
          <PdfAbnormalMarkersSection
            bloodReportResults={payload.outOfRangeBloodReportResults}
            showHighLowDescriptions={patientHighLowEnabled}
            biomarkerCustomDescriptionsMapping={
              payload.biomarkerCustomDescriptionsMapping
            }
          />
        </>
      )}
      <PdfDivider />
      <PdfSectionTitle
        title={"All Markers"}
        icon={<NormalResultsIcon width="28" />}
      />
      {payload.bloodReportResultsByLabTest.map((labTest) => (
        <PdfAllMarkersLabTestSection
          key={labTest.labTestName}
          bloodReportResults={labTest.bloodReportResults}
          labTestTitle={labTest.labTestName}
        />
      ))}
    </div>
  )
}
