import { makeStyles, Theme } from "@material-ui/core"

import Storefront404Image from "app/assets/images/storefront/storefront-404.svg"
import BodyText from "app/components/design-system/BodyText"
import Button from "app/components/design-system/Button"
import DisplayText from "app/components/design-system/DisplayText"
import { colors, navy } from "app/theme"

import Navbar from "./components/Navbar"

const styles = (theme: Theme) => ({
  container: {
    width: "100%",
    display: "flex",
    padding: "100px 50px",
    background: "linear-gradient(180deg, #FFFFFF 0%, #EAF4FF 100%)",
    alignItems: "center",
    justifyContent: "center",
    "flex-direction": "column",
    gap: 35,
  },
  content: {
    width: "60%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "flex-direction": "column",
    gap: 35,
    "text-align": "center",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  supportCenter: {
    width: "100%",
    padding: "60px 75px",
    background: "white",
    height: "100%",
    marginBottom: "-24px",
  },
  supportCenterContainer: {
    background: colors.blueGray[50],
    borderRadius: 12,
    display: "flex",
    "flex-direction": "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "40px 16px",
    width: "100%",
  },
  visitSupportCenterButton: {
    margin: "24px 0",
  },
  emailUsLink: {
    color: navy,
    textDecoration: "underline !important",
    fontWeight: 600,
  },
})

const useStyles = makeStyles(styles)

const PublicStorefront404 = () => {
  const classes = useStyles()

  const handleScrollToFaq = () => {
    const section = document.querySelector("#faq")

    if (section) {
      section.scrollIntoView({ behavior: "smooth", block: "start" })
    }
  }

  return (
    <>
      <Navbar hideCart scrollToFaq={handleScrollToFaq} storefront={null} />
      <div className={classes.container}>
        <div className={classes.content}>
          <img src={Storefront404Image} alt="LabShop 404" />
          <BodyText size="2xl" weight="semibold" style={{ fontSize: 30 }}>
            This store is currently under construction or is no longer active.
          </BodyText>
        </div>
      </div>
      <div className={classes.supportCenter} id="faq">
        <div className={classes.supportCenterContainer}>
          <DisplayText size="xl">Have any questions?</DisplayText>
          <Button
            className={classes.visitSupportCenterButton}
            href="https://support.rupahealth.com"
            color="secondary"
            target="_blank"
          >
            Visit Our Support Center
          </Button>
          <BodyText>
            {"Or "}
            <a
              href="mailto:support@rupahealth.com"
              className={classes.emailUsLink}
            >
              email us
            </a>
            {" instead."}
          </BodyText>
        </div>
      </div>
    </>
  )
}

export default PublicStorefront404
