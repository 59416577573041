import { useCallback } from "react"

import { debounce } from "lodash"

export const useDebounce = (
  fnToDebounce: (...args: any) => any,
  durationInMs: number,
  options?: object
) => {
  return useCallback(debounce(fnToDebounce, durationInMs, options), [
    fnToDebounce,
    durationInMs,
  ])
}

export default useDebounce
