import { useMemo } from "react"

import { styled } from "@material-ui/core/styles"

import { colors } from "app/theme"
import { getNextStepsForHandfilledRequisitions } from "app/utils/order-utils"

const HandfilledRequisitionNextSteps = ({ order, ...passthroughProps }) => {
  const nextSteps = useMemo(() => {
    return getNextStepsForHandfilledRequisitions(order)
  }, [order])
  return (
    <NextStepsContainer {...passthroughProps}>
      <NextStepHeader>{"Next Steps:"}</NextStepHeader>
      <NextStepList>
        {nextSteps.map((nextStep, index) => {
          return (
            <NextStepListItem key={index} index={index}>
              {nextStep}
            </NextStepListItem>
          )
        })}
      </NextStepList>
    </NextStepsContainer>
  )
}

const NextStepsContainer = styled("div")(({ theme }) => ({
  backgroundColor: colors.blueGray[50],
  border: `1px solid ${colors.blueGray[200]}`,
  borderRadius: 4,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  maxWidth: 360,
  width: "100%",
}))

const NextStepHeader = styled("div")(({ theme }) => ({
  alignItems: "center",
  backgroundColor: colors.blueGray[200],
  display: "flex",
  fontSize: 14,
  fontWeight: 600,
  lineHeight: 1.35,
  padding: theme.spacing(0.5),
  justifyContent: "center",
  width: "100%",
}))

const NextStepList = styled("ul")(({ theme }) => ({
  listStyle: "none",
  margin: 0,
  padding: theme.spacing(1.5),
  marginTop: theme.spacing(1),
  width: "100%",
}))

const NextStepListItem = styled(({ children, index, ...passthroughProps }) => (
  <li {...passthroughProps}>
    <NextStepIcon>{`${index + 1}`}</NextStepIcon>
    <NextStepText>{children}</NextStepText>
  </li>
))(({ theme }) => ({
  display: "flex",
  marginBottom: theme.spacing(1),
}))

const NextStepIcon = styled("div")({
  alignItems: "center",
  backgroundColor: colors.blueGray[200],
  borderRadius: "50%",
  display: "flex",
  flex: "0 0 auto",
  fontSize: 12,
  fontWeight: 600,
  height: 16,
  lineHeight: 1,
  justifyContent: "center",
  marginTop: 3,
  width: 16,
})

const NextStepText = styled("div")(({ theme }) => ({
  flex: "1 1 auto",
  fontSize: 15,
  fontWeight: 600,
  lineHeight: 1.35,
  marginLeft: theme.spacing(1),
}))

export default HandfilledRequisitionNextSteps
