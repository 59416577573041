import { useEffect } from "react"

import { ReactComponent as AddressArrow } from "app/assets/icons/address-arrow.svg"
import { ReactComponent as CalendarIcon } from "app/assets/icons/calendar.svg"
import { ReactComponent as ClockOutline } from "app/assets/icons/clock-outline.svg"
import { ReactComponent as UpRightArrowIcon } from "app/assets/icons/up-right-arrow.svg"
import authService from "app/auth/services/simplejwt-auth-service"
import BodyText from "app/components/design-system/BodyText"
import Button from "app/components/design-system/Button"
import {
  trackPhlebotomyBookingEvent,
  PHLEBOTOMY_BOOKING_EVENTS,
} from "app/services/segment"
import { navy, colors, primaryColor } from "app/theme"
import { PHLEBOTOMY_PROVIDERS } from "app/types"
import makeAppStyles from "app/utils/makeAppStyles"

import { BookingData } from "../hooks/use-booking-data"

const useStyles = makeAppStyles((theme) => ({
  container: {
    maxWidth: 575,
    padding: 24,
    backgroundColor: "white",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 8,
    marginBottom: 10,
    boxShadow:
      "0px 1px 2px 0px rgba(0, 0, 0, 0.06), 0px 1px 3px 0px rgba(0, 0, 0, 0.10)",
    fontFamily: "Open Sans",
    fontSize: "15px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "135%",
    color: navy,
  },
  title: {
    margin: 24,
    color: navy,
    textAlign: "center",
    fontFamily: "Josefin Sans",
    fontSize: "30px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "140%",
  },
  appointmentDetailsText: {
    fontFamily: "Open Sans",
    fontSize: "15px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "135%",
    display: "flex",
    alignItems: "center",
  },
  appointmentDetailsSection: {
    gap: 9,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginBottom: 48,
  },
  icon: {
    marginRight: 8,
  },
  rescheduleText: {
    fontFamily: "Open Sans",
    fontSize: "15px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "135%",
    color: navy,
  },
  link: {
    color: primaryColor,
    fontWeight: 600,
  },
  separator: {
    width: "100%",
    height: 1,
    backgroundColor: colors.blueGray[200],
    marginTop: 24,
    marginBottom: 24,
  },
  disclaimerText: {
    color: colors.blueGray[500],
    fontFamily: "Open Sans",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "135%",
    marginLeft: 24,
    marginRight: 24,
    textAlign: "center",
  },
  footerText: {
    color: navy,
    textAlign: "center",
    fontFamily: "Open Sans",
    fontSize: "15px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "135%",
    margin: 24,
    marginTop: 8,
  },
  emailLink: {
    color: navy,
    textDecoration: "underline",
  },
  signInSection: {
    margin: "16px 48px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  signInButton: {
    width: "100%",
    maxWidth: 370,
    marginTop: 24,
    marginBottom: 24,
  },
  centeredText: {
    textAlign: "center",
  },
}))

export const ConfirmationSection = ({
  data,
  loginUrl,
  fromLocation,
}: {
  data: BookingData | undefined
  loginUrl: string | undefined
  fromLocation: string | undefined
}) => {
  const classes = useStyles()

  const isAuthenticated = authService.isAuthenticated()

  const visit = data?.order?.axle_health_visits?.[0]

  const appointmentStartUtc = visit?.visit_datetime
    ? new Date(visit?.visit_datetime)
    : null
  const appointmentEndUtc = visit?.visit_end_datetime
    ? new Date(visit?.visit_end_datetime)
    : null

  useEffect(() => {
    trackPhlebotomyBookingEvent(
      PHLEBOTOMY_BOOKING_EVENTS.PHLEBOTOMY_BOOKING_CONFIRMATION_PAGE_VISIT,
      {
        provider: PHLEBOTOMY_PROVIDERS.AXLE,
        order_id: data?.order.id,
        from_location: fromLocation,
      }
    )
  }, [])

  const faqLink = (
    <a
      className={classes.link}
      href="https://support.rupahealth.com/en/articles/8599332-your-axle-health-blood-draw-faq"
      target="_blank"
      rel="noopener noreferrer"
    >
      Visit mobile blood draw FAQ <UpRightArrowIcon />
    </a>
  )

  if (visit?.status === "canceled") {
    return (
      <div className={classes.container}>
        <div className={classes.title}>
          Your mobile blood draw appointment is paid for but has been canceled
        </div>
        <div className={classes.footerText}>
          Need to reschedule your blood draw? Please reach out to{" "}
          <a
            role="button"
            className={classes.emailLink}
            href="mailto: support@rupahealth.com"
          >
            support@rupahealth.com
          </a>
          .
        </div>
        {faqLink}
      </div>
    )
  }

  return (
    <div>
      <div className={classes.container}>
        <div>
          <div className={classes.title}>
            Your mobile blood draw appointment has been booked! 🩸
          </div>
        </div>
        <div className={classes.appointmentDetailsSection}>
          {visit?.address ? (
            <BodyText size="md" className={classes.appointmentDetailsText}>
              <AddressArrow
                fill={colors.blueGray[500]}
                className={classes.icon}
              />
              {visit.address.address1}
              {visit.address.address2 ? " " + visit.address.address2 : ""},{" "}
              {visit.address.city}, {visit.address.state}{" "}
              {visit.address.zip_code}
            </BodyText>
          ) : null}

          {appointmentStartUtc && (
            <BodyText size="md" className={classes.appointmentDetailsText}>
              <CalendarIcon
                fill={colors.blueGray[500]}
                className={classes.icon}
              />
              {appointmentStartUtc.toLocaleDateString("en-us", {
                weekday: "long",
                year: "numeric",
                month: "short",
                day: "numeric",
              })}
            </BodyText>
          )}
          {appointmentStartUtc && appointmentEndUtc && (
            <BodyText size="md" className={classes.appointmentDetailsText}>
              <ClockOutline
                fill={colors.blueGray[500]}
                className={classes.icon}
              />
              {appointmentStartUtc.toLocaleTimeString("en-us", {
                hour: "numeric",
                minute: "numeric",
                hour12: true,
              })}{" "}
              -{" "}
              {appointmentEndUtc.toLocaleTimeString("en-us", {
                hour: "numeric",
                minute: "numeric",
                hour12: true,
              })}
            </BodyText>
          )}
        </div>
        <div className={classes.rescheduleText}>
          Need to reschedule or cancel?{" "}
          <a
            className={classes.link}
            href={visit?.axle_reschedule_link}
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => {
              trackPhlebotomyBookingEvent(
                PHLEBOTOMY_BOOKING_EVENTS.PHLEBOTOMY_RESCHEDULE_APPOINTMENT,
                { provider: PHLEBOTOMY_PROVIDERS.AXLE }
              )
            }}
          >
            Use this link
          </a>
        </div>
        <div className={classes.divider}></div>
        <br></br>
        <div className={classes.disclaimerText}>
          Please note, we offer free cancellation and reschedules up to 24 hours
          before your appointment time. There are no refunds within 24 hours of
          your appointment time.
          <br></br>
          <br></br>
          {faqLink}
        </div>

        <div className={classes.separator}></div>
        <div className={classes.footerText}>
          Need to draw blood at another location or have other concerns? Please
          reach out to{" "}
          <a
            role="button"
            className={classes.emailLink}
            href="mailto: support@rupahealth.com"
          >
            support@rupahealth.com
          </a>
          .
        </div>
      </div>
      <br></br>
      {loginUrl && (
        <div className={classes.container}>
          <div className={classes.signInSection}>
            <div className={classes.centeredText}>
              You can check your order status and access results, sample updates
              & more in your Patient Portal anytime.
            </div>
            <Button
              href={loginUrl}
              color="secondary"
              className={classes.signInButton}
            >
              {isAuthenticated ? "Go to Account" : "Sign In"}
            </Button>
            <div className={classes.centeredText}>
              We’re here for you and anything lab-testing related!<br></br>
              <br></br>Best,<br></br> The Rupa Team <br></br>
              <a
                role="button"
                className={classes.emailLink}
                href="mailto: support@rupahealth.com"
              >
                support@rupahealth.com
              </a>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
