import { colors, primaryColor } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"

import { NavItem } from "./constants"

const styles = () => ({
  container: {
    paddingBottom: 4,
    borderBottom: `1px solid ${colors.blueGray[200]}`,
    "&:last-of-type": {
      borderBottom: "none",
      paddingBottom: 0,
    },
  },
  item: {
    display: "flex",
    flexFlow: "row nowrap",
    gap: 10,
    paddingTop: 7,
    paddingBottom: 5,
    color: (props) => (props.color ? props.color : primaryColor),
    borderRadius: 6,
    paddingRight: 6,
    "&:hover": {
      cursor: "pointer",
      background: (props) =>
        props.background ? props.background : colors.blue[50],
    },
  },
})
const useStyles = makeAppStyles(styles)

interface Props {
  item: NavItem
}

const TooltipNavItem = ({ item }: Props) => {
  const classes = useStyles(item)

  return (
    <div className={classes.container}>
      <div onClick={item.onClick} className={classes.item}>
        <div style={{ position: "relative", top: 1 }}>
          <img src={item.icon} alt="" />
        </div>
        <div>{item.name}</div>
      </div>
    </div>
  )
}

export default TooltipNavItem
