import { Grid, makeStyles } from "@material-ui/core"

import BodyText from "app/components/design-system/BodyText"
import useCachedCollection from "app/swr/hooks/use-cached-collection"
import useCollectionSWR from "app/swr/hooks/use-collection-swr"
import { ResourceCollection } from "app/swr/types"

import { NewPractitionerServicesItem } from "./NewPractitionerServicesItem"
import { PractitionerServicesItem } from "./PractitionerServicesItem"
import {
  PractitionerProfileService,
  WebflowFieldOption,
  WebflowFieldOptionIdentifier,
} from "./types"

const useStyles = makeStyles((theme) => ({
  headerText: {
    marginTop: theme.spacing(4.25),
  },
}))

export const PractitionerServices = ({ mutateProfile }) => {
  const classes = useStyles()

  const {
    data: practitionerProfileServiceIdentifiers,
    mutate: mutateServices,
  } = useCollectionSWR<ResourceCollection<PractitionerProfileService>>(
    "/practitioner_profile_services/"
  )

  const cachedServices = useCachedCollection<PractitionerProfileService>(
    practitionerProfileServiceIdentifiers
  )

  const highlightedServices = cachedServices.filter(
    (service) => !service.relationships?.labshop?.data
  )

  const { data: connectionOptionIdentifiers } = useCollectionSWR<
    ResourceCollection<WebflowFieldOptionIdentifier>
  >("/webflow_field_options/", {
    params: {
      "filter[field_slug]": "how-to-connect",
    },
  })

  const { data: serviceTypeOptionIdentifiers } = useCollectionSWR<
    ResourceCollection<WebflowFieldOptionIdentifier>
  >("/webflow_field_options/", {
    params: {
      "filter[field_slug]": "service-type",
    },
  })

  const connectionTypeOptions = useCachedCollection<WebflowFieldOption>(
    connectionOptionIdentifiers
  )

  const serviceTypeOptions = useCachedCollection<WebflowFieldOption>(
    serviceTypeOptionIdentifiers
  )

  return (
    <Grid container>
      <Grid item xs={12}>
        <BodyText size="lg" weight="semibold" className={classes.headerText}>
          Services
        </BodyText>
      </Grid>
      <Grid item xs={12}>
        <BodyText size="sm">
          Link out to your existing services so that patients can find them.
        </BodyText>
      </Grid>
      <Grid spacing={1} container item xs={12}>
        {highlightedServices.map((service) => {
          return (
            <Grid item key={service.id}>
              <PractitionerServicesItem
                key={service.id}
                service={service}
                connectionTypeOptions={connectionTypeOptions}
                serviceTypeOptions={serviceTypeOptions}
                mutateServices={mutateServices}
                mutateProfile={mutateProfile}
              />
            </Grid>
          )
        })}
        <Grid item>
          <NewPractitionerServicesItem
            connectionTypeOptions={connectionTypeOptions}
            serviceTypeOptions={serviceTypeOptions}
            mutateServices={mutateServices}
            mutateProfile={mutateProfile}
          />
        </Grid>
      </Grid>
    </Grid>
  )
}
