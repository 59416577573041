import { useMemo, useState } from "react"
import { useDispatch } from "react-redux"

import { SampleCollectionCoordinator } from "app/constants.typed"
import { requisitionDisabledForOrder } from "app/dataServices/orderDataService"
import useEventCallback from "app/hooks/use-event-callback"
import { openLabCompanyRequisition } from "app/main/patient-orders/store/actions/patient.actions"
import { LabCompany, Order, Patient } from "app/types"
import { requisitionGenerationAllowed } from "app/utils/lab-company-utils"

/**
 * Hook used to open a requisition for an Order.
 * Whether the function returned opens a requisition or generates a requisition depends on the props passed to the hook.
 */

interface ReturnProps {
  // The requisition is loading after openRequisition is called
  loading: boolean
  // The requisition is disabled and cannot be opened
  disabled: boolean
  // If the requisition is enabled, open the requisition
  openRequisition: () => Promise<void>
}

interface UseOpenRequisitionProps {
  // Disabled will be true if no order passed
  order?: Pick<Order, "id" | "date_paid">
  // Disabled will be true if no patient passed
  patient?: Pick<Patient, "has_order_info" | "given_consent">

  // Requisition URLs only used for opening requisition. Can be undefined and disabled will still be false if canGenerate is true
  requisitionUrl?: string | null
  recollectionRequisitionUrl?: string | null // overrides requisitionUrl

  // Used for generating requisition - can be undefined, and disabled will still be false if requisitionUrl is set
  labCompany?: Pick<LabCompany, "id" | "key">
  orderedTestId?: string
  sampleCollectionCoordinator?: SampleCollectionCoordinator
}

export default function useOpenRequisition({
  sampleCollectionCoordinator,
  order,
  patient,
  orderedTestId,
  labCompany,
  requisitionUrl,
  recollectionRequisitionUrl,
}: UseOpenRequisitionProps): ReturnProps {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const requisitionUrlToUse = recollectionRequisitionUrl ?? requisitionUrl

  const { canGenerate, canDownload } = isRequisitionAvailable({
    sampleCollectionCoordinator,
    order,
    patient,
    labCompany,
    requisitionUrl,
    recollectionRequisitionUrl,
  })

  const disabled = useMemo(() => {
    return loading || (!canGenerate && !canDownload)
  }, [loading, canGenerate, canDownload])

  const openRequisition = useEventCallback(async () => {
    if (disabled) return

    if (requisitionUrlToUse) {
      window.open(requisitionUrlToUse)
    } else if (!loading && canGenerate) {
      try {
        await dispatch(
          openLabCompanyRequisition(order?.id, labCompany?.id, orderedTestId)
        )
      } finally {
        setLoading(false)
      }
    }
  })

  return { loading, disabled, openRequisition }
}

export function isRequisitionAvailable({
  sampleCollectionCoordinator,
  order,
  patient,
  labCompany,
  requisitionUrl,
  recollectionRequisitionUrl,
}: UseOpenRequisitionProps): {
  canGenerate: boolean
  canDownload: boolean
} {
  let result = { canGenerate: false, canDownload: false }

  // If order & patient don't have the necessary base info, neither allowed
  if (!order || !patient) return result
  const [disabledForOrder] = requisitionDisabledForOrder(order, patient)
  if (disabledForOrder) return result

  // Check if the requisition is available for download
  if (requisitionUrl || recollectionRequisitionUrl) {
    result.canDownload = true
  }

  // Check if the requisition is available for generation
  if (labCompany) {
    result.canGenerate = requisitionGenerationAllowed({
      labCompanyKey: labCompany?.key,
      sampleCollectionCoordinator,
    })
  }
  return result
}
