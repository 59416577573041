import axios from "axios"
import { differenceInYears, parse } from "date-fns"
import useSWR from "swr"

import { PHLEBOTOMY_PROVIDERS, PhlebotomyProviderName } from "app/types"
import { getApiBaseUrl } from "app/utils"

export interface ZipCodeCheckResponse {
  zip_code: string
  providers: PhlebotomyProviderName[]
}

export const AXLE_UNAVAILABLE_NOTIFICATIONS = {
  NOT_IN_AREA:
    "Mobile phlebotomy is not available in your area. Removing from order.",
  PATIENT_TOO_YOUNG:
    "Mobile phlebotomy is not available for patients less than three years old. Removing from order.",
}

export const EMPIRE_UNAVAILABLE_NOTIFICATION =
  "Empire blood draw is not available in your area. Removing from order."

export default function usePhlebotomyProviderAvailability(
  zipCode: string | null,
  checkoutToken: string,
  patient_birthday: string | null,
  allowAxle: boolean
) {
  const { data: response, ...swr } = useSWR(
    zipCode && checkoutToken
      ? `/phlebotomy-provider-availability/?token=${checkoutToken}&zip_code=${zipCode}`
      : null,
    {
      async fetcher(url) {
        const response = await axios.request<ZipCodeCheckResponse>({
          baseURL: getApiBaseUrl() + "/api/",
          url,
          method: "get",
        })

        return response
      },
      revalidateIfStale: true,
    }
  )

  let isPatientOverThreeYearsOld: boolean | undefined
  let availableProviders = response?.data?.providers ?? []

  if (patient_birthday) {
    const dateToCompare = parse(patient_birthday, "yyyy-MM-dd", new Date())
    isPatientOverThreeYearsOld =
      Math.abs(differenceInYears(new Date(), dateToCompare)) >= 3
  }

  const axleIndex = availableProviders.indexOf(PHLEBOTOMY_PROVIDERS.AXLE)
  const axleInUsersArea = !!axleIndex && axleIndex > -1

  if (axleInUsersArea && (!isPatientOverThreeYearsOld || !allowAxle)) {
    availableProviders.splice(axleIndex, 1)
  }

  let axleMessage: string | null = null
  if (!axleInUsersArea) {
    axleMessage = AXLE_UNAVAILABLE_NOTIFICATIONS.NOT_IN_AREA
  } else if (!isPatientOverThreeYearsOld) {
    axleMessage = AXLE_UNAVAILABLE_NOTIFICATIONS.PATIENT_TOO_YOUNG
  }

  return {
    ...swr,
    axleMessage,
    availableProviders,
    zipCode,
  }
}
