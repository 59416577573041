import * as React from "react"

import DesignSystemButton from "app/components/design-system/Button"
import { primaryColor } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"

const useStyles = makeAppStyles<{ style: React.CSSProperties | undefined }>({
  button: {
    width: (props) => (props.style ? props.style.width : 101),
    color: (props) => (props.style?.color ? props.style?.color : primaryColor),
  },
})

interface Props {
  text: string | React.ReactNode
  onClick: () => void
  startIcon?: React.ReactNode
  style?: React.CSSProperties
  disabled?: boolean
}

export default function SystemButtonTableCell({
  text,
  onClick,
  startIcon,
  style,
  disabled,
}: Props) {
  const classes = useStyles({ style })

  return (
    <DesignSystemButton
      color="secondary"
      className={classes.button}
      onClick={onClick}
      startIcon={startIcon}
      disabled={disabled}
    >
      {text}
    </DesignSystemButton>
  )
}
