import { useCallback } from "react"

import useQueryState from "app/hooks/use-query-state"

export function usePatientOrdersListQueryState() {
  const [queryState, setQueryState] = useQueryState(
    (query) => ({
      status: query.status || null,
      page: parseInt(query.page, 10) || 1,
    }),
    (state) => ({
      status: state.status,
      page: state.page > 0 ? state.page.toString() : undefined,
    })
  )

  const setStatusQueryParam = useCallback(
    (status: string | null) => {
      setQueryState((prevState) => ({ ...prevState, status }))
    },
    [setQueryState]
  )

  const setPageQueryParam = useCallback(
    (page: number) => {
      setQueryState((prevState) => ({ ...prevState, page }))
    },
    [setQueryState]
  )

  return {
    status: queryState.status,
    page: queryState.page,
    setStatusQueryParam,
    setPageQueryParam,
  }
}
