import _ from "lodash"

import { Typography, makeStyles } from "@material-ui/core"
import Divider from "@material-ui/core/Divider"
import { Skeleton } from "@material-ui/lab"

import InfoIcon from "app/assets/icons/info-gray-circle.svg"
import Tooltip from "app/components/Tooltip"
import BodyText from "app/components/design-system/BodyText"
import { physAuthTooltipText } from "app/constants"
import { VENDOR_PHYSICIAN_AUTHORIZATION_LABEL } from "app/constants"
import useFeatureFlag, { FeatureFlag } from "app/hooks/use-feature-flag"
import useQuery from "app/hooks/use-query"
import useAppSelector from "app/hooks/useAppSelector"
import { LINE_ITEM_INFO_CONFIG } from "app/main/patient-checkout/sections/OrderDetails"
import {
  selectIsSplitItInitialized,
  selectSplitItInstallmentAmounts,
} from "app/main/patient-checkout/store/selectors"
import {
  PatientCheckoutTrackingEvents,
  trackPatientCheckoutEvent,
} from "app/services/segment"
import { PatientPortalOrder, PatientPortalOrderLineItem } from "app/types"
import { formatDollars } from "app/utils"
import { getPhysicianAuthorizationFee } from "app/utils/order-utils"

import { ORDER_LINE_ITEM_TYPE } from "../../../../constants"
import PauseOrder from "../PauseOrder"
import ProcessingAndOrderingCollapse from "../ProcessingAndOrderingCollapse"
import {
  ADDITIONAL_FEE_LINE_ITEM_TYPES,
  PROCESSING_AND_ORDERING_ORDER_LINE_ITEMS,
  calculateRetailPrice,
  calculateStorefrontRetailPrice,
  sortLineItemTypeToLast,
} from "../pricing-helpers"
import { calculateAxlePricesFromLineItems } from "./utils"

interface LineItemProps {
  title: string
  cost: string | number
  info?: JSX.Element | string
  tooltipDataCy?: string
  onTooltipClose?: () => void
}

function LineItem({
  title,
  cost,
  info,
  tooltipDataCy,
  onTooltipClose,
}: LineItemProps) {
  const titleElement = (
    <Typography className="text-base15 text-gray-800">{title}</Typography>
  )

  return (
    <div className="flex justify-between mb-2">
      {info ? (
        <Tooltip
          title={info}
          placement="bottom"
          onClose={onTooltipClose}
          arrow
          interactive
        >
          <div
            className="flex flex-row justify-start"
            data-cy={tooltipDataCy}
            style={{ alignItems: "flex-start", paddingRight: 25 }}
          >
            {titleElement}
            <img
              src={InfoIcon}
              alt=""
              className="ml-2"
              width={21}
              style={{ position: "relative", top: 2 }}
            />
          </div>
        </Tooltip>
      ) : (
        titleElement
      )}
      <Typography className="text-base15 text-gray-800" align="right">
        {formatDollars(cost)}
      </Typography>
    </div>
  )
}

const PhysAuthFeeTooltip = () => (
  <>
    <BodyText size="sm" weight="bold">
      About {VENDOR_PHYSICIAN_AUTHORIZATION_LABEL}
    </BodyText>

    <BodyText size="sm">{physAuthTooltipText}</BodyText>
  </>
)

const PhysicianAuthorizationLineItem = ({ order }) => {
  const physAuthFee = getPhysicianAuthorizationFee(order)

  if (physAuthFee === 0) {
    return null
  }

  return (
    <LineItem
      title={VENDOR_PHYSICIAN_AUTHORIZATION_LABEL}
      cost={physAuthFee}
      info={<PhysAuthFeeTooltip />}
      onTooltipClose={() => {
        trackPatientCheckoutEvent(
          PatientCheckoutTrackingEvents.VIEW_PHYSICIAN_SERVICES_TOOLTIP,
          order
        )
      }}
    />
  )
}

const PaymentPlanPrice = () => {
  const classes = useStyles()
  const isInitialized = useAppSelector(selectIsSplitItInitialized)
  const installmentAmounts = useAppSelector(selectSplitItInstallmentAmounts)
  if (!installmentAmounts) {
    return null
  }
  if (!isInitialized) {
    // do not show if we are initializing or splitit has failed to initialize
    return null
  }
  const installmentPlanText =
    Math.abs(installmentAmounts[0] - installmentAmounts[1]) <= 0.01
      ? `${formatDollars(installmentAmounts[0])}/mo. for 3 mo.`
      : `${formatDollars(installmentAmounts[0])} today + ${formatDollars(
          installmentAmounts[1]
        )}/mo. for 2 mo.`
  return (
    <div className={classes.installmentPriceWrapper}>
      <BodyText size="lg">or</BodyText>
      <BodyText
        size="lg"
        weight="semibold"
        className={classes.installmentPrice}
      >
        {installmentPlanText}
      </BodyText>
    </div>
  )
}

const CREDIT_CARD_FEE_WITHOUT_ACH_INFO = (
  <BodyText size="sm">
    In some regions, we charge a fee for accepting credit card payments. This
    fee covers the cost of the payment gateway and merchant services.
  </BodyText>
)

const useStyles = makeStyles({
  installmentPriceWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    flexGrow: 1,
  },
  installmentPrice: {
    marginLeft: 4,
  },
  infoIcon: {
    marginLeft: 4,
  },
})

function PriceBreakdown({
  checkoutToken,
  order,
  setCancelModalOpenWithOptions,
  customFeeLineItems,
  isUpdatingLineItems,
  ...props
}: {
  checkoutToken: string
  order: PatientPortalOrder
  setCancelModalOpenWithOptions: (options: any) => void
  customFeeLineItems: PatientPortalOrderLineItem[]
  isUpdatingLineItems: boolean
}) {
  const additionalFeeLineItems = order.line_items.filter((lineItem) =>
    ADDITIONAL_FEE_LINE_ITEM_TYPES.some(
      (lineItemType) => lineItemType === lineItem.type
    )
  )

  const {
    totalAxlePrice,
    axleBaseFee,
    axleBaseTitle,
    additionalAxleKitsCount,
    additionalAxleKitsTitle,
    additionalAxleKitFees,
  } = calculateAxlePricesFromLineItems(order.line_items)

  const processingAndOrderingLineItems = order.line_items.filter((lineItem) =>
    PROCESSING_AND_ORDERING_ORDER_LINE_ITEMS.some(
      (lineItemType) => lineItemType === lineItem.type
    )
  )

  const query = useQuery()
  const [useCustomFeesExpansionFlag] = useFeatureFlag(
    FeatureFlag.CustomFeesExpansion
  )
  const useCustomFeesExpansion =
    useCustomFeesExpansionFlag || query.get("custom-fees-expansion") === "1"

  // Disable payment plan for custom fees or storefronts
  const disablePaymentPlan =
    (Boolean(customFeeLineItems.length) && !useCustomFeesExpansion) ||
    (Boolean(order.storefront_id) && !useCustomFeesExpansion)

  const processingAndOrderingCost =
    _.sum(
      processingAndOrderingLineItems.map((lineItem) =>
        parseFloat(lineItem.cost)
      )
    ) + getPhysicianAuthorizationFee(order)

  const processingFeeSum = _.sum(
    order.line_items
      .filter(
        (lineItem) => lineItem.type === ORDER_LINE_ITEM_TYPE.PROCESSING_FEE
      )
      .map((lineItem) => parseFloat(lineItem.cost))
  )

  const showSubtotal =
    order.subtotal &&
    order.subtotal !== order.total_price &&
    !order.storefront_id

  return (
    <div {...props}>
      {isUpdatingLineItems ? (
        <PricingBreakdownSkeleton />
      ) : (
        <div>
          <LineItem
            title={order.storefront_id ? "Lab Tests" : "Retail Price"}
            cost={
              order.storefront_id
                ? calculateStorefrontRetailPrice(
                    order.msrp_total,
                    order.line_items,
                    order.rupa_discount
                  )
                : calculateRetailPrice(order.msrp_total, order.line_items)
            }
          />

          {Boolean(customFeeLineItems.length) &&
            customFeeLineItems.map((customFee) => (
              <LineItem
                key={customFee.id}
                title={`${customFee.title} paid to ${order.practitioner.clinic_name}`}
                cost={customFee.cost}
                info={
                  "This line item was added by your practitioner and is paid to them once your results come in."
                }
                tooltipDataCy="customFeeInfo"
                onTooltipClose={() => {
                  trackPatientCheckoutEvent(
                    PatientCheckoutTrackingEvents.VIEW_CUSTOM_FEE_TOOLTIP,
                    order
                  )
                }}
              />
            ))}

          {additionalFeeLineItems.map((lineItem) => (
            <LineItem
              key={lineItem.id}
              title={lineItem.title}
              cost={lineItem.cost}
              info={LINE_ITEM_INFO_CONFIG[lineItem.type]?.info ?? ""}
            />
          ))}

          {totalAxlePrice > 0 && (
            <ProcessingAndOrderingCollapse
              header={<LineItem title={"Axle Fees"} cost={totalAxlePrice} />}
            >
              <LineItem title={axleBaseTitle} cost={axleBaseFee} />
              {additionalAxleKitsCount > 0 && (
                <LineItem
                  title={additionalAxleKitsTitle}
                  cost={additionalAxleKitFees}
                />
              )}
            </ProcessingAndOrderingCollapse>
          )}

          <ProcessingAndOrderingCollapse
            header={
              <LineItem
                title={"Processing & Ordering"}
                cost={processingAndOrderingCost}
              />
            }
            onExpand={() => {
              trackPatientCheckoutEvent(
                PatientCheckoutTrackingEvents.EXPAND_PROCESSING_ORDERING_FEES,
                order
              )
            }}
          >
            {order.requires_vendor_physician_authorization && (
              <PhysicianAuthorizationLineItem order={order} />
            )}
            {processingAndOrderingLineItems
              // put processing second to last and credit card fee last in section
              .sort(sortLineItemTypeToLast(ORDER_LINE_ITEM_TYPE.PROCESSING_FEE))
              .sort(
                sortLineItemTypeToLast(ORDER_LINE_ITEM_TYPE.CREDIT_CARD_FEE)
              )
              .map((lineItem) => (
                <LineItem
                  key={lineItem.id}
                  title={lineItem.title}
                  cost={
                    lineItem.type === ORDER_LINE_ITEM_TYPE.PROCESSING_FEE
                      ? processingFeeSum
                      : lineItem.cost
                  }
                  // Display a custom tooltip for credit card fee if ACH is disabled because of custom fees.
                  info={
                    Boolean(customFeeLineItems.length) &&
                    lineItem.type === ORDER_LINE_ITEM_TYPE.CREDIT_CARD_FEE
                      ? CREDIT_CARD_FEE_WITHOUT_ACH_INFO
                      : LINE_ITEM_INFO_CONFIG[lineItem.type]?.info ?? ""
                  }
                />
              ))}
          </ProcessingAndOrderingCollapse>

          {showSubtotal && (
            <LineItem title={"Subtotal"} cost={order.subtotal} />
          )}

          {order.coupon_discount > 0 && (
            <div className="flex justify-between">
              <Typography className="text-base15 font-semibold text-cyan-600">
                Discounts
              </Typography>
              <Typography
                className="text-base15 font-semibold text-cyan-600"
                align="right"
              >
                -{formatDollars(order.coupon_discount)}
              </Typography>
            </div>
          )}

          {order.rupa_discount > 0 && !order.storefront_id && (
            <div className="flex justify-between mt-3">
              <Typography className="text-base15 font-semibold text-cyan-600">
                Rupa Savings
              </Typography>
              <Typography
                className="text-base15 font-semibold text-cyan-600"
                align="right"
              >
                -{formatDollars(order.rupa_discount)}
              </Typography>
            </div>
          )}

          <Divider className="my-3" />
          <div className="flex flex-row flex-grow justify-between">
            <Typography className="text-xl19 font-bold" color="textPrimary">
              Total
            </Typography>
            <Typography className="text-xl19 font-bold" color="textPrimary">
              {formatDollars(order.total_price)}
            </Typography>
          </div>
          {!disablePaymentPlan && (
            <div className="flex mt-2">
              <PaymentPlanPrice />
            </div>
          )}
        </div>
      )}
      <Divider className="my-4" />
      <PauseOrder
        checkoutToken={checkoutToken}
        order={order}
        setCancelModalOpenWithOptions={setCancelModalOpenWithOptions}
      />
    </div>
  )
}

const PricingBreakdownSkeleton = () => {
  return (
    <div>
      {[...Array(3)].map((_, index) => (
        <div className="flex justify-between my-3" key={index}>
          <Skeleton
            className="rounded-xl"
            variant="rect"
            animation="wave"
            height={20}
            width={200}
          />
          <Skeleton
            className="rounded-xl"
            variant="rect"
            animation="wave"
            height={20}
            width={100}
          />
        </div>
      ))}
      <Divider className="my-3" />
      <div className="flex justify-between my-3">
        <Skeleton
          className="rounded-xl"
          variant="rect"
          animation="wave"
          height={20}
          width={200}
        />
        <Skeleton
          className="rounded-xl"
          variant="rect"
          animation="wave"
          height={20}
          width={100}
        />
      </div>
      <div className="flex flex-row-reverse my-3">
        <Skeleton
          className="rounded-xl"
          variant="rect"
          animation="wave"
          height={20}
          width={100}
        />
      </div>
    </div>
  )
}

export default PriceBreakdown
