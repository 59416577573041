import { styled } from "@material-ui/core"

import SampleReport from "app/assets/icons/sample-report.svg"
import UpRightArrow from "app/assets/icons/up-right-arrow.svg"
import BodyText from "app/components/design-system/BodyText"
import { primaryColor } from "app/theme"
import { LabTest } from "app/types"

const BodyContainer = styled("div")({
  marginTop: 14,
  display: "flex",
  flexDirection: "row",
})

const StyledIconImage15 = styled("img")({
  width: 15,
})

const StyledImage18 = styled("img")({
  width: 18,
  paddingLeft: "5px",
})

const StyledAnchorTag = styled("a")({
  paddingLeft: "6px",
  color: primaryColor,
  textDecoration: "none",
  fontWeight: 600,
})

interface Props {
  sampleReportUrl: LabTest["sample_report_url"]
}

const SampleReportSection = ({ sampleReportUrl }: Props) => (
  <>
    <BodyText size="sm">Sample Report</BodyText>
    <BodyContainer>
      <StyledIconImage15 src={SampleReport} alt="document" />
      <StyledAnchorTag className="flex" href={sampleReportUrl} target="__blank">
        View <StyledImage18 src={UpRightArrow} alt="up-right-arrow" />
      </StyledAnchorTag>
    </BodyContainer>
  </>
)

export default SampleReportSection
