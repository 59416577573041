import { CircularProgress, DialogTitle } from "@material-ui/core"

import Button from "app/components/design-system/Button"
import DisplayText from "app/components/design-system/DisplayText"
import FeedbackButton from "app/components/design-system/FeedbackButton"
import { INTERCOM_RESULTS_INTERPRETATION_FEEDBACK_ID } from "app/settings"
import useCachedResource from "app/swr/hooks/use-cached-resource"
import { colors } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"
import { Order } from "types/order"
import { Patient } from "types/patient"

const useResultsSummaryModalHeaderStyles = makeAppStyles((theme) => ({
  dialogTitle: {
    display: "flex",
    flexFlow: "row nowrap",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "white",
    borderBottomWidth: 1,
    borderColor: colors.blueGray[300],
    padding: theme.spacing(2.0),
  },
  titleText: {
    flex: "1 1 auto",
    fontSize: 19,
    lineHeight: 1.35,
    marginTop: 3,
  },
  actionGroup: {
    display: "flex",
    flexFlow: "row nowrap",
    alignItems: "center",
    gap: theme.spacing(1.5),
  },
}))

export default function ResultsSummaryModalHeader({
  onClose,
  order,
  showLoading,
}: {
  onClose: () => void
  order?: Order
  showLoading: boolean
}) {
  const classes = useResultsSummaryModalHeaderStyles()
  const patient = useCachedResource<Patient>(order?.relationships.patient.data)

  return (
    <DialogTitle className={classes.dialogTitle} disableTypography>
      <DisplayText className={classes.titleText} weight="semibold" size="lg">
        {patient
          ? `${patient?.attributes.first_name}'s Results `
          : "Results Summary"}
      </DisplayText>
      <div className={classes.actionGroup}>
        {showLoading && <CircularProgress size={24} />}
        <FeedbackButton
          surveyId={INTERCOM_RESULTS_INTERPRETATION_FEEDBACK_ID}
          text="Feedback"
        />
        <Button
          aria-label="Save & Close"
          color="secondary"
          onClick={() => onClose()}
          size="small"
        >
          Save & Close
        </Button>
      </div>
    </DialogTitle>
  )
}
