import { VENDOR_PHYSICIAN_AUTHORIZATION_LABEL } from "app/constants"

import { OnboardingTaskKey } from "./types"

export const PHYSICIAN_SERVICES_TASK_TEXT = `Add ${VENDOR_PHYSICIAN_AUTHORIZATION_LABEL} to your account for your clients to gain access to testing through Rupa, and a Signing Physician will review the results for critical values.`

export const CREDENTIAL_TASKS: OnboardingTaskKey[] = [
  "license_existing_license",
  "license_vendor_physician_service",
  "license_combined",
  "license_invited",
]
