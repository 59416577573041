import { LabCompanyKey } from "app/constants"
import { OrderingRightsStatus, PractitionerListItem } from "app/types"

export type LabCompanyOrderingAccessResponse = LabCompanyOrderingAccess[]

export interface LabCompanyOrderingAccess {
  id: string
  key: LabCompanyKey
  name: string
  logo: string | null
  physician_authorization_ordering_rights_status: OrderingRightsStatus
  signing_practitioner_ordering_rights_status: OrderingRightsStatus
}

export const PRAC_OPTION_GROUPS = {
  CURRENT_USER: 1,
  TEAM_MEMBER: 2,
}

interface SelectOption {
  id: string
  label: string
  group: number | string
}

export type PracSelectOption = PractitionerListItem &
  SelectOption & {
    group: typeof PRAC_OPTION_GROUPS[keyof typeof PRAC_OPTION_GROUPS]
  }
