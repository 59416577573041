import { ReactElement } from "react"

import { Theme } from "@material-ui/core"

import { colors, shadows } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"

interface CardContentProps {
  title: string
  icon: ReactElement
  actionButton?: ReactElement
  [x: string]: any
}

const useStyles = makeAppStyles((theme: Theme) => ({
  card: {
    backgroundColor: "white",
    borderRadius: 8,
    boxShadow: shadows.sm,
    display: "flex",
    flexDirection: "column",
    gap: 10,
    padding: 10,
    width: "100%",
  },
  cardTitle: {
    borderRadius: 8,
    background: colors.blueGray[100],
    display: "flex",
    gap: 6,
    alignItems: "center",
    padding: "6px 8px",
    width: "100%",
    fontWeight: 600,
    fontSize: 14,
    lineHeight: "130%",
    justifyContent: "space-between",
    flexWrap: "wrap",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "row",
      alignItems: "flex-start",
      gap: 8,
    },
  },
  cardTitleContent: {
    display: "flex",
    gap: 6,
    alignItems: "center",
  },
}))

const ContentCard = ({
  title,
  icon,
  actionButton,
  ...props
}: CardContentProps) => {
  const classes = useStyles()

  return (
    <div className={classes.card}>
      <div className={classes.cardTitle}>
        <div className={classes.cardTitleContent}>
          {icon}
          {title}
        </div>

        {actionButton}
      </div>
      {props.children}
    </div>
  )
}

export default ContentCard
