import { ActionTypes, ComparisonActionUnion, ComparisonState } from "../types"

export const initialState: ComparisonState = {
  error: undefined,
  isComparisonModalActive: false,
  isLoading: false,
  isSearchModalActive: false,
  labTestById: {},
  labTestIds: [],
}

export default function comparisonReducer(
  state: ComparisonState = initialState,
  action: ComparisonActionUnion
): ComparisonState {
  switch (action.type) {
    case ActionTypes.OPEN_COMPARISON_MODAL: {
      const nextLabTestIds = action.payload.labTestId
        ? [action.payload.labTestId]
        : state.labTestIds
      const nextLabTestById =
        action.payload.labTestId && action.payload.labTest
          ? {
              ...state.labTestById,
              [action.payload.labTestId]: action.payload.labTest,
            }
          : state.labTestById
      return {
        ...state,
        isComparisonModalActive: true,
        isSearchModalActive: nextLabTestIds.length > 0 ? false : true,
        labTestById: nextLabTestById,
        labTestIds: nextLabTestIds,
      }
    }
    case ActionTypes.CLOSE_COMPARISON_MODAL: {
      return {
        ...state,
        isComparisonModalActive: false,
        isSearchModalActive: false,
        labTestIds: [],
      }
    }
    case ActionTypes.OPEN_SEARCH_MODAL: {
      return {
        ...state,
        isSearchModalActive: true,
      }
    }
    case ActionTypes.CLOSE_SEARCH_MODAL: {
      return {
        ...state,
        isSearchModalActive: false,
      }
    }
    case ActionTypes.ADD_COMPARED_TEST: {
      return {
        ...state,
        labTestIds: state.labTestIds.includes(action.payload.labTestId)
          ? state.labTestIds
          : [...state.labTestIds, action.payload.labTestId],
        labTestById: action.payload.labTest
          ? {
              ...state.labTestById,
              [action.payload.labTestId]: action.payload.labTest,
            }
          : state.labTestById,
      }
    }
    case ActionTypes.REMOVE_COMPARED_TEST: {
      return {
        ...state,
        labTestIds: state.labTestIds.filter((id) => id !== action.payload),
      }
    }
    case ActionTypes.SET_COMPARED_TESTS: {
      return {
        ...state,
        labTestIds: action.payload,
      }
    }
    case ActionTypes.FETCH_TESTS_REQUEST: {
      return {
        ...state,
        isLoading: true,
      }
    }
    case ActionTypes.FETCH_TESTS_SUCCESS: {
      const nextLabTestById = action.payload.reduce(
        (labTestById, labTest) => ({
          ...labTestById,
          [labTest.id]: labTest,
        }),
        state.labTestById
      )
      return {
        ...state,
        isLoading: false,
        labTestById: nextLabTestById,
        // Remove any lab tests we were unable to find data for.
        labTestIds: state.labTestIds.filter((labTestId) =>
          Boolean(nextLabTestById[labTestId])
        ),
      }
    }
    case ActionTypes.FETCH_TESTS_FAILURE: {
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      }
    }
    default:
      return state
  }
}
