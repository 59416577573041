import { API } from "app/api"
import { AppThunkAction, HealthCategory } from "app/types"
import { handleApiError } from "app/utils"

export const GET_HEALTH_CATEGORY_LIST_SUCCESS =
  "GET HEALTH CATEGORY LIST SUCCESS"

export interface GetHealthCategoryListAction {
  type: typeof GET_HEALTH_CATEGORY_LIST_SUCCESS
  payload: HealthCategory[]
}

export type ActionTypes = GetHealthCategoryListAction

export function getHealthCategoryList(): AppThunkAction<void> {
  return async (dispatch) => {
    try {
      const response = await API.HealthCategories.getList()
      dispatch({
        type: GET_HEALTH_CATEGORY_LIST_SUCCESS,
        payload: response.data,
      })
    } catch (error) {
      dispatch(handleApiError(error))
    }
  }
}
