import { useMemo, useReducer } from "react"
import * as React from "react"

import useEventCallback from "app/hooks/use-event-callback"
import useFeatureFlag, { FeatureFlag } from "app/hooks/use-feature-flag"
import ComparisonModal from "app/main/comparison/components/ComparisonModal"
import ComparisonSearchModal from "app/main/comparison/components/ComparisonSearchModal"
import ComparisonCallToActionContext from "app/main/comparison/contexts/ComparisonCallToActionContext"
import ComparisonContext from "app/main/comparison/contexts/ComparisonContext"
import useFetchMissingLabTests from "app/main/comparison/hooks/use-fetch-missing-lab-tests"
import useSyncWithUrl from "app/main/comparison/hooks/use-sync-with-url"
import comparisonReducer, {
  initialState,
} from "app/main/comparison/store/comparison.reducer"
import {
  ComparisonCallToActionTypes,
  ComparisonCallToActionContextShape,
} from "app/main/comparison/types"

export type ComparisonProviderProps =
  Partial<ComparisonCallToActionContextShape> & {
    children: React.ReactNode
  }

function PassthroughComparisonProvider({ children }: ComparisonProviderProps) {
  return <>{children}</>
}

export function ComparisonProvider({
  callToAction = ComparisonCallToActionTypes.START_ORDER,
  children,
  onCallToAction = () => {},
  orderId,
}: ComparisonProviderProps) {
  const context = useReducer(comparisonReducer, initialState)
  const onCallToActionHandler = useEventCallback(onCallToAction)
  const callToActionContext = useMemo(
    () => ({
      callToAction,
      onCallToAction: onCallToActionHandler,
    }),
    [callToAction, onCallToActionHandler]
  )

  useSyncWithUrl(...context)
  useFetchMissingLabTests(...context, orderId)

  return (
    <ComparisonContext.Provider value={context}>
      <ComparisonCallToActionContext.Provider value={callToActionContext}>
        {children}
        <ComparisonModal />
        <ComparisonSearchModal />
      </ComparisonCallToActionContext.Provider>
    </ComparisonContext.Provider>
  )
}

export default function FeatureFlaggedComparisonProvider(
  props: ComparisonProviderProps
) {
  const [enabled] = useFeatureFlag(FeatureFlag.LabTestComparison)
  return enabled ? (
    <ComparisonProvider {...props} />
  ) : (
    <PassthroughComparisonProvider {...props} />
  )
}
