import { ReactComponent as DownloadIcon } from "app/assets/icons/download-icon.svg"
import DesignSystemButton from "app/components/design-system/Button"
import useDownloadResultsSummary from "app/results-summary/hooks/use-download-results-summary"
import { UseResultsVisualizerResourcesHook } from "app/results-summary/hooks/use-results-summary-resources"
import { colors, primaryColor } from "app/theme"

export interface DownloadPDFButtonProps {
  interpretationSwr: UseResultsVisualizerResourcesHook["interpretationSwr"]
}

export default function DownloadPDFButton({
  interpretationSwr,
}: DownloadPDFButtonProps) {
  const { data: interpretation } = interpretationSwr
  const { download, isDownloading } =
    useDownloadResultsSummary(interpretationSwr)

  const pdfAvailable =
    interpretation?.relationships.biomarkers.data.length ||
    interpretation?.relationships.tasks.data.length

  return (
    <DesignSystemButton
      color="secondary"
      disabled={!pdfAvailable}
      loading={isDownloading}
      onClick={download}
      startIcon={
        <DownloadIcon fill={pdfAvailable ? primaryColor : colors.gray[300]} />
      }
    >
      Download PDF
    </DesignSystemButton>
  )
}
