import { useState } from "react"

import { Document, Page, pdfjs } from "react-pdf"

import {
  CircularProgress,
  makeStyles,
  Theme,
  useMediaQuery,
} from "@material-ui/core"
import { cn } from "@rupahealth/design"

import { ReactComponent as CloseBoxIcon } from "app/assets/icons/close-box.svg"
import DisplayText from "app/components/design-system/DisplayText"
import { colors, red } from "app/theme"

// needed to prevent an error while loading the PDF.
// error: Error: Setting up fake worker failed: "Cannot read properties of undefined (reading 'WorkerMessageHandler')"
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    maxHeight: "100%",
  },
  documentContainer: {
    backgroundColor: colors.blueGray[100],
    width: "100%",
    flexGrow: 2,
    borderRadius: 7,
    overflow: "auto",
  },
  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 10,
    gap: 10,
  },
  zoomButtons: {
    alignSelf: "center",
    backgroundColor: "white",
    borderRadius: 8,
    height: 35,
    display: "flex",
    flexDirection: "row",
    flexShrink: 0,
    padding: 8,
    gap: 8,
  },
  zoomButtonDivider: {
    width: 1,
    backgroundColor: colors.blueGray[300],
  },
  zoomButton: {
    "&:hover": {
      cursor: "pointer",
    },
  },
  placeholder: {
    width: 86,
    height: 40,
    flexShrink: 10,
  },
  thumbnails: {
    display: "flex",
    flexDirection: "row",
    overflowX: "auto",
    // add a fade out on left and right edge. works cross-browser.
    "-webkit-mask-image": `-webkit-gradient(linear, left center, right center,
        color-stop(0, transparent),
        color-stop(.07, black),
        color-stop(.93, black),
        color-stop(1, transparent))`,
    paddingRight: 15,
    paddingLeft: 15,
  },
  thumbnailDivider: {
    margin: "0px 5px",
  },
  pdfPage: {
    marginBottom: 5,
    backgroundColor: "white",
  },
  pdfDocument: {
    gap: 10,
  },
  pdfPlaceholder: {
    maxWidth: 540,
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginLeft: "auto",
    marginRight: "auto",
    gap: theme.spacing(2.0),
    padding: theme.spacing(4.0),
  },
  documentErrorIcon: {
    maxWidth: "100%",
    width: 120,
    fill: red,
    height: "auto",
  },
  pdfLoader: {
    maxWidth: "100%",
    height: "auto",
  },
}))

const DocumentError = () => {
  const classes = useStyles()
  return (
    <div className={classes.pdfPlaceholder}>
      <CloseBoxIcon className={classes.documentErrorIcon} viewBox="0 0 14 13" />
      <DisplayText size="2xl" variant="h1">
        File Not Found
      </DisplayText>
    </div>
  )
}

const ResultsPdfViewerContent = ({
  pdfFile,
  pdfFileUrl,
}: {
  pdfFile?: File
  pdfFileUrl?: string
}) => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"))
  const classes = useStyles({ isMobile })
  const [numPages, setNumPages] = useState(null)

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages)
  }

  return (
    <div className={classes.container}>
      <div className={classes.documentContainer}>
        <Document
          className={classes.pdfDocument}
          error={() => <DocumentError />}
          file={pdfFile || pdfFileUrl}
          loading={() => (
            <div className={classes.pdfPlaceholder}>
              <CircularProgress size={24} className={classes.pdfLoader} />
            </div>
          )}
          onLoadSuccess={onDocumentLoadSuccess}
          options={{ isEvalSupported: false }}
        >
          {Array.from(new Array(numPages), (el, index) => (
            <Page
              key={`page_${index + 1}`}
              className={cn(classes.pdfPage, "rounded-md")}
              pageNumber={index + 1}
              scale={1.0}
            />
          ))}
        </Document>
      </div>
    </div>
  )
}

export interface ResultUploadPdfViewerProps {
  pdfFile?: File
  pdfFileUrl?: string
}

export default function ResultUploadPdfViewer({
  pdfFile,
  pdfFileUrl,
}: ResultUploadPdfViewerProps) {
  return <ResultsPdfViewerContent pdfFile={pdfFile} pdfFileUrl={pdfFileUrl} />
}
