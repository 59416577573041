import { Theme } from "@material-ui/core"

import DesignSystemButton from "app/components/design-system/Button"
import { AnyLimitedLabTest, LabCompany } from "app/types"
import makeAppStyles from "app/utils/makeAppStyles"

const useStyles = makeAppStyles((theme: Theme) => ({
  buttonStyle: {
    display: "block",
    width: "100%",
  },
  textStyle: {
    marginBottom: 14,
    textAlign: "left",
  },
}))

interface Props {
  name: string
  labCompany?: LabCompany
  labTest?: AnyLimitedLabTest
  overrideTestAction?: (unavailableState?: string) => void
  unavailableState?: string
}

const OverrideAccessTooltip = (props: Props) => {
  const classes = useStyles()
  const labCompanyName =
    props.labTest?.lab_company?.name ?? props.labCompany?.name

  if (!labCompanyName) {
    return null
  }

  return (
    <>
      <div className={classes.textStyle}>
        {`Our records don't show whether practitioners licensed as ${
          props.name
        } ${
          props.unavailableState ? ` in ${props.unavailableState}` : ""
        } can order from ${labCompanyName}`}
        .
      </div>
      <DesignSystemButton
        color="secondary"
        className={classes.buttonStyle}
        onClick={() =>
          props.overrideTestAction &&
          props.overrideTestAction(props.unavailableState)
        }
      >
        Enable Access to Lab
      </DesignSystemButton>
    </>
  )
}

export default OverrideAccessTooltip
