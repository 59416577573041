import { styled } from "@material-ui/core"

import ArrowTopRight from "app/assets/icons/arrow-top-right.svg"
import BodyText from "app/components/design-system/BodyText"
import { colors, primaryColor } from "app/theme"
import { LabCompanyRecommendedPhlebotomist } from "app/types"

interface Props {
  labCompanyRecommendation: LabCompanyRecommendedPhlebotomist
}

const Container = styled("div")({
  borderTop: `1px solid ${colors.blueGray[200]}`,
  paddingTop: 22,
  marginTop: 22,
})

const Title = styled(BodyText)({
  marginBottom: 8,
})

const CostDetailsContainer = styled("div")({
  background: colors.blueGray[50],
  border: `1px solid ${colors.blueGray[200]}`,
  borderRadius: 6,
  padding: 12,
  marginBottom: 8,
})

const List = styled("ul")({
  marginTop: 22,
  paddingLeft: 18,
})

const Item = styled("li")({
  "&:not(:last-child)": {
    marginBottom: 16,
  },
})

const Link = styled("a")({
  display: "flex",
  alignItems: "center",
  color: primaryColor,
  marginTop: 8,
  fontWeight: 600,
})

const DirectoryIcon = styled("img")({
  marginLeft: 4,
})

const LabCompanyRecommendation = ({ labCompanyRecommendation }: Props) => (
  <Container>
    <Title weight="semibold" size="md">
      {labCompanyRecommendation.title}:
    </Title>
    {Boolean(labCompanyRecommendation.phlebotomy_cost_details) && (
      <CostDetailsContainer>
        {`💳 ${labCompanyRecommendation.phlebotomy_cost_details}`}
      </CostDetailsContainer>
    )}
    <List>
      {Boolean(labCompanyRecommendation.online_directory_title) && (
        <Item>
          {labCompanyRecommendation.online_directory_title}:
          <Link href={labCompanyRecommendation.online_directory_url}>
            {`🌐 ${labCompanyRecommendation.online_directory_name}`}
            <DirectoryIcon src={ArrowTopRight} alt="" />
          </Link>
        </Item>
      )}
      {Boolean(labCompanyRecommendation.customer_service_description) && (
        <Item>
          {labCompanyRecommendation.customer_service_description}:
          {labCompanyRecommendation.customer_service_email && (
            <Link
              href={`mailto:${labCompanyRecommendation.customer_service_email}`}
            >
              {`✉️   ${labCompanyRecommendation.customer_service_email}`}
            </Link>
          )}
          {labCompanyRecommendation.customer_service_phone && (
            <Link
              href={`tel:${labCompanyRecommendation.customer_service_phone}`}
            >
              {`☎️   ${labCompanyRecommendation.customer_service_phone}`}
            </Link>
          )}
        </Item>
      )}
    </List>
  </Container>
)

export default LabCompanyRecommendation
