import { isObject, compact } from "lodash"

/**
 * The update order endpoint expects foreign key ids instead of the full objects for efficiency.
 * This method returns an Order object with those ids in place of the full objects.
 * @param patch
 */

const FIELDS_TO_NORMALIZE = [
  "practitioner",
  "signing_practitioner",
  "payment_card",
  "bank_account",
  "icd10_codes_discrete",
]

export function normalizeOrderPatch(patch) {
  const normalizedPatch = {
    ...patch,
  }

  FIELDS_TO_NORMALIZE.forEach((field) => {
    if (field in normalizedPatch) {
      normalizedPatch[field] = getId(normalizedPatch[field])
    }
  })

  if ("date_scheduled" in normalizedPatch) {
    // Convert datetime object to YYYY-MM-DD
    normalizedPatch.date_scheduled = normalizedPatch.date_scheduled
      ? new Date(normalizedPatch.date_scheduled).toISOString().slice(0, 10)
      : null
  }

  return normalizedPatch
}

/**
 * Returns the ID for a given item.
 * If the id is passed in then that same id is returned.
 * If an object is passed in then the id is extracted.
 * If undefined is passed in then null is returned.
 * @param itemOrId
 * @returns {*|null}
 */
function getId(itemOrId) {
  if (Array.isArray(itemOrId)) {
    return compact(itemOrId.map(getId))
  }

  if (isObject(itemOrId)) {
    return itemOrId.id || null
  }

  return itemOrId || null
}
