import { useCallback, useMemo, useEffect, useState } from "react"

import * as Sentry from "@sentry/react"

import AchFieldsContext from "app/main/patient-checkout/contexts/AchFieldsContext"
import { plaidEnvironment, plaidPublicKey } from "app/settings"
import { AchConnectionStatus } from "app/types"
import { loadScript } from "app/utils"

interface AchFieldsProviderProps {
  children: JSX.Element
}

const AchFieldsProvider = ({ children }: AchFieldsProviderProps) => {
  const [publicToken, setPublicToken] = useState()
  const [accountId, setAccountId] = useState()
  const [last4, setLast4] = useState()
  const [bankName, setBankName] = useState()
  const [accountName, setAccountName] = useState()
  const [plaidLinkHandler, setPlaidLinkHandler] = useState<{
    open: () => void
  }>()
  const [status, setStatus] = useState(AchConnectionStatus.NOT_STARTED)

  const updateValues = useCallback(
    ({ token, account_id, last_4, bank_name, account_name }) => {
      setPublicToken(token)
      setAccountId(account_id)
      setLast4(last_4)
      setBankName(bank_name)
      setAccountName(account_name)
    },
    []
  )

  useEffect(() => {
    loadScript(
      "plaid",
      "https://cdn.plaid.com/link/v2/stable/link-initialize.js",
      () => {
        setPlaidLinkHandler(
          window.Plaid.create({
            env: plaidEnvironment,
            clientName: "Rupa Health",
            key: plaidPublicKey,
            product: ["auth", "transactions"],
            selectAccount: true,
            onSuccess: async function (public_token, metadata) {
              if (updateValues) {
                updateValues({
                  token: public_token,
                  account_id: metadata.account_id,
                  last_4: metadata.account.mask,
                  account_name: metadata.account.name,
                  bank_name: metadata.institution.name,
                })
              }
              setStatus(AchConnectionStatus.COMPLETE)
            },
            onExit: function (error, metadata) {
              // If there's not an error it means the user just exited the Plaid flow.
              // An error implies something went wrong with the auth process.
              if (error != null) {
                const errorMessage = "Failed to authenticate bank account"
                Sentry.withScope(function (scope) {
                  scope.setExtra("error", JSON.stringify(error))
                  Sentry.captureException(new PlaidError(errorMessage))
                })
              }
              setStatus(AchConnectionStatus.INCOMPLETE)
            },
          })
        )
      }
    )
  }, [])

  const context = useMemo(
    () => ({
      publicToken,
      accountId,
      last4,
      bankName,
      accountName,
      updateValues,
      status,
      openPlaid: plaidLinkHandler?.open,
    }),
    [
      publicToken,
      accountId,
      last4,
      bankName,
      accountName,
      updateValues,
      status,
      plaidLinkHandler,
    ]
  )

  return (
    <AchFieldsContext.Provider value={context}>
      {children}
    </AchFieldsContext.Provider>
  )
}

export default AchFieldsProvider

class PlaidError extends Error {
  constructor(message) {
    super(message)
    this.name = "PlaidError"
  }
}
