import { Box } from "@material-ui/core"

import { ReactComponent as AlertIcon } from "app/assets/icons/alert-round-red.svg"
import BodyText from "app/components/design-system/BodyText"
import { colors } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"

import AccountCardWrapper from "../AccountCardWrapper"
import EditAccountTooltip from "../tooltips/EditAccountTooltip"

const useStyles = makeAppStyles((theme) => ({
  accountStatusRow: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    padding: "0 24px 24px",
  },
  accountStatusText: {
    color: colors.red[600],
  },
  accountStatusMessage: {
    backgroundColor: colors.red[100],
    border: `1px solid ${colors.red[600]}`,
    borderRadius: 7,
    fontSize: 14,
    marginTop: "26px",
    paddingBottom: theme.spacing(1),
    paddingLeft: "12px",
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(1),
    width: "fit-content",
    display: "flex",
    "flex-direction": "row",
    justifyContent: "space-between",
    alignItems: "start",
  },
  statusIndicator: {
    margin: "2px 6px 0 0",
    overflow: "visible",
  },
}))

const FailedAccountCard = ({ company, account }) => {
  const classes = useStyles()
  return (
    <AccountCardWrapper
      company={company}
      isConfirmed={false}
      actionElement={<EditAccountTooltip companyName={company.display} />}
    >
      <div className={classes.accountStatusRow}>
        <Box className={classes.accountStatusMessage}>
          <AlertIcon className={classes.statusIndicator} />
          <BodyText size="sm" className={classes.accountStatusText}>
            Account registration failed due to{" "}
            {account.attributes.failed_reason}
          </BodyText>
        </Box>
      </div>
    </AccountCardWrapper>
  )
}

export default FailedAccountCard
