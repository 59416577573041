import { useMemo } from "react"

import { ReactComponent as CubeIcon } from "app/assets/icons/cube.svg"
import { ReactComponent as TestTubesIcon } from "app/assets/icons/test-tubes-small.svg"
import PageTabs from "app/components/PageTabs/PageTabs"
import BetaChip from "app/components/design-system/BetaChip"
import { useFeatureFlagNoDefault } from "app/hooks/use-feature-flag"
import { FeatureFlag } from "app/providers/FeatureFlagProvider"
import { colors } from "app/theme"

export enum InOfficeKitTabId {
  ORDER_HISTORY = "order-history",
  INVENTORY = "inventory",
}

export const DEFAULT_IOK_TAB = InOfficeKitTabId.ORDER_HISTORY

const InOfficeKitTabs: React.FC = () => {
  const [showInventory] = useFeatureFlagNoDefault(
    FeatureFlag.InOfficeKitInventory
  )
  const tabs = useMemo(
    () => [
      {
        id: InOfficeKitTabId.ORDER_HISTORY,
        LabelIcon: <CubeIcon fill="currentColor" />,
        label: "Supply Order History",
        show: true,
      },
      {
        id: InOfficeKitTabId.INVENTORY,
        LabelIcon: <TestTubesIcon fill="currentColor" />,
        label: "Kit Inventory",
        show: showInventory,
        LabelIconAfter: (
          <span>
            <BetaChip
              backgroundColor={colors.teal[100]}
              color={colors.teal[700]}
              className="cursor-pointer"
            />
          </span>
        ),
      },
    ],
    [showInventory]
  )

  return <PageTabs tabs={tabs} defaultTabId={DEFAULT_IOK_TAB} />
}

export default InOfficeKitTabs
