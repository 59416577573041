import { useState } from "react"

import { getInitialValue } from "app/components/RichTextEditor"
import useEventCallback from "app/hooks/use-event-callback"
import useHandleApiError from "app/hooks/use-handle-api-error"
import useMutateResource from "app/swr/hooks/use-mutate-resource"
import { ResourceIdentifier, ResourceResponse } from "app/swr/types"
import resourceRequest from "app/swr/utils/resource-request"
import setIn from "app/utils/set-in"
import {
  ResultsInterpretation,
  ResultsInterpretationTask,
  ResultsInterpretationTaskCreate,
  TaskTypesLiteral,
} from "types/results-interpretation"

/**
 * Returns a callback that is used to create an interpretation task and update the resource cache with the result.
 *
 * @returns the callback to create an intepretation task
 */
function useCreateInterpretationTaskResource() {
  const mutateResource = useMutateResource()
  const handleApiError = useHandleApiError()
  return useEventCallback(async (payload: ResultsInterpretationTaskCreate) => {
    const sendCreateRequest = async () => {
      try {
        const { data } = await resourceRequest<
          ResourceResponse<ResultsInterpretationTask>,
          { data: ResultsInterpretationTaskCreate }
        >({
          method: "post",
          url: `/results_interpretation_tasks/`,
          data: {
            data: payload,
          },
        })

        return data
      } catch (error) {
        handleApiError(error)

        // re-throw
        throw error
      }
    }

    const task = await sendCreateRequest()
    const identifier = {
      type: task.type,
      id: task.id,
    } as ResourceIdentifier

    // Add task to cache
    await mutateResource<ResultsInterpretationTask>(identifier, task)

    // Update cached interpretation with new task
    await mutateResource<ResultsInterpretation>(
      task.relationships.interpretation.data,
      (currentVisualization) => {
        if (!currentVisualization) {
          // should never be reached, but type error without
          return undefined as any
        }

        const { tasks } = currentVisualization.relationships

        return setIn(currentVisualization, "relationships.tasks", {
          data: [...tasks.data, identifier],
          meta: {
            ...tasks.meta,
            count: tasks.meta.count + 1,
          },
        })
      }
    )
  })
}

/**
 * Used to create a task from a task identifier. This is used when the user selects a task from the search results.
 *
 * @param interpretation the interpretation to create the task for
 * @param onSuccess a callback to run when the task is successfully created
 * @returns a boolean tracking whether the task is being created, and a function to create a task from a task identifier
 */
export default function useCreateInterpretationTask(
  interpretation: ResultsInterpretation | undefined,
  onSuccess?: () => void
) {
  const [isCreating, setIsCreating] = useState(false)
  const createInterpretationTaskResource = useCreateInterpretationTaskResource()

  /**
   * Create a new task for the interpretation. This is used when the user selects a task from the search results.
   */
  const createInterpretationTask = useEventCallback(
    async (taskType: TaskTypesLiteral) => {
      if (isCreating || !interpretation) {
        return
      }

      try {
        setIsCreating(true)

        const payload: ResultsInterpretationTaskCreate = {
          type: "results_interpretation_task",
          attributes: {
            task_type: taskType,
            name: "",
            content_instructions: getInitialValue(""),
          },
          relationships: {
            interpretation: {
              data: { type: interpretation.type, id: interpretation.id },
            },
          },
        }

        await createInterpretationTaskResource(payload)

        onSuccess?.()
      } finally {
        setIsCreating(false)
      }
    }
  )

  return {
    createInterpretationTask,
    isCreating,
  }
}
