import { RootState } from "app/types"

import * as Actions from "../actions/practitionerBundles.actions"

export const initialState: RootState["practitionerBundles"] = {
  error: undefined,
  pending: false,
  results: [],
}

export default function practitionerBundles(
  state = initialState,
  action:
    | Actions.RequestPractitionerBundlesAction
    | Actions.FailedPractitionerBundlesAction
    | Actions.ReceivePractitionerBundlesAction
    | Actions.PractitionerBundleCreatedAction
    | Actions.PractitionerBundleDeletedAction
    | Actions.PractitionerBundleUpdatedAction
): RootState["practitionerBundles"] {
  switch (action.type) {
    case Actions.REQUEST_PRACTITIONER_BUNDLES: {
      return {
        ...state,
        pending: true,
      }
    }
    case Actions.FAILED_PRACTITIONER_BUNDLES: {
      return {
        ...state,
        error: action.payload,
        pending: false,
      }
    }
    case Actions.RECEIVE_PRACTITIONER_BUNDLES: {
      return {
        ...state,
        error: undefined,
        pending: false,
        results: action.payload,
      }
    }
    case Actions.PRACTITIONER_BUNDLE_CREATED: {
      return {
        ...state,
        results: [...state.results, action.payload],
      }
    }
    case Actions.PRACTITIONER_BUNDLE_DELETED: {
      return {
        ...state,
        results: state.results.filter((bundle) => bundle.id !== action.payload),
      }
    }
    case Actions.PRACTITIONER_BUNDLE_UPDATED: {
      return {
        ...state,
        results: state.results.map((bundle) =>
          bundle.id === action.payload.id ? action.payload : bundle
        ),
      }
    }
    default:
      return state
  }
}
