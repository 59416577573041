import { LAB_COMPANIES_REQUIRING_IOK_ACTIVATION_ID } from "app/constants"
import { getUnavailableReason } from "app/dataServices/orderingRights"

import { InOfficeKitLabTest } from "./types"

export const INTERCOM_IOK_LEARN_MORE_LINK =
  "https://help.rupahealth.com/en/articles/8977586-stocking-kits-and-supplies-in-your-clinic-through-rupa"

export function requiresActivation(labCompanyKey: string) {
  return LAB_COMPANIES_REQUIRING_IOK_ACTIVATION_ID.includes(labCompanyKey)
}

export function getUnavailableReasonForActivation(labTest: InOfficeKitLabTest) {
  let reason = getUnavailableReason({
    labTest: labTest.attributes,
    showOverrideOption: false,
  })
  if (!reason && !labTest.attributes.has_instant_requisition) {
    reason = "This test is not currently available to hand out in-office."
  }
  return reason
}
