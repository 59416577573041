import { useMemo } from "react"

import { useForm } from "react-hook-form"

import { zodResolver } from "@hookform/resolvers/zod"

import useFeatureFlag from "app/hooks/use-feature-flag"
import { FeatureFlag } from "app/providers/FeatureFlagProvider"
import useCachedCollection from "app/swr/hooks/use-cached-collection"
import { LabCompany } from "app/types"
import { UserBiomarkerResult } from "types/user-biomarker-result"
import { UserResult } from "types/user-result"

import {
  getUserResultFormDefaultValues,
  getUserResultFormSchema,
  UserResultFormData,
} from "./form-helpers"
import { useCreateOrUpdateUserResult } from "./use-create-or-update-user-result"

// Hook Input Props
interface Props {
  userResult: UserResult | undefined
  patientId?: string // If no patientId, we are in patient portal
  labCompanies: LabCompany[]
  handleSave: (userResult: UserResult | undefined) => Promise<void>
}

export const useUserResultForm = ({
  userResult,
  labCompanies,
  handleSave,
  patientId,
}: Props) => {
  const [isPatientPortalResultsUploadEnabled] = useFeatureFlag(
    FeatureFlag.PatientPortalResultsUploads
  )
  const readOnly = useMemo(
    () =>
      userResult?.attributes.can_write === false ||
      (!patientId && !isPatientPortalResultsUploadEnabled),
    [userResult, patientId, isPatientPortalResultsUploadEnabled]
  )
  const requireReviewConfirmation = useMemo(() => {
    return !readOnly && !!userResult?.attributes.pdf_file
  }, [readOnly, userResult])

  const Schema = useMemo(() => {
    return getUserResultFormSchema({ requireReviewConfirmation })
  }, [requireReviewConfirmation])

  const userBiomarkerResults = useCachedCollection<UserBiomarkerResult>(
    userResult?.relationships.user_biomarker_results?.data ?? []
  )

  const defaultValues: UserResultFormData = useMemo(() => {
    return getUserResultFormDefaultValues({
      userResult,
      userBiomarkerResults,
      labCompanies,
    })
  }, [userResult, userBiomarkerResults, labCompanies])

  const formMethods = useForm<UserResultFormData>({
    defaultValues,
    mode: "onChange",
    resolver: zodResolver(Schema),
    shouldFocusError: true,
  })

  const submitForm = useCreateOrUpdateUserResult({
    userResult,
    patientId,
    handleSave,
    labCompanies,
  })

  return { formMethods, submitForm, readOnly, requireReviewConfirmation }
}
