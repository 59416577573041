import { ComponentProps } from "react"

import clsx from "clsx"

import { ReactComponent as ResultsSummaryIcon } from "app/assets/icons/results-summary.svg"
import Button from "app/components/design-system/Button"
import useFeatureFlag, { FeatureFlag } from "app/hooks/use-feature-flag"
import usePatientOrderStyles from "app/main/patient-orders/hooks/usePatientOrderStyles"
import useResultsSummaryModal from "app/results-summary/hooks/use-results-summary-modal"

export type OpenResultsSummaryModalButtonProps = Omit<
  ComponentProps<typeof Button>,
  "children" | "onClick" | "href" | "to"
> & {
  orderId?: string
}

export default function OpenResultsSummaryModalButton({
  className,
  orderId,
  ...buttonProps
}: OpenResultsSummaryModalButtonProps) {
  const sharedStyles = usePatientOrderStyles()

  const modal = useResultsSummaryModal()
  const [featureEnabled] = useFeatureFlag(FeatureFlag.ResultsVisualizer)
  if (!featureEnabled) {
    // Render nothing if the feature is disabled.
    return null
  }
  return (
    <Button
      {...buttonProps}
      className={clsx(className, sharedStyles.button)}
      color="secondary"
      onClick={() => {
        if (!orderId) {
          return
        }

        modal.show({ orderId })
      }}
      shadow="default"
      size="xsmall"
      startIcon={<ResultsSummaryIcon viewBox="0 0 13 17" />}
    >
      {"Create Results Summary"}
    </Button>
  )
}
