import { forwardRef } from "react"

import { faChevronDown } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { cn } from "@rupahealth/design"

import FilterIcon from "../search/FilterIcon"
import { FilterNames } from "./types"

interface Props {
  label: string
  // The number of active filters for the given filter
  count?: number
  filterName: FilterNames | "moreFilters"
  onClick?: () => void
  showChevron?: boolean
  defaultText?: string
  className?: string
}

const FilterButton = forwardRef<HTMLButtonElement, Props>(
  (
    {
      filterName,
      label,
      count,
      onClick,
      className,
      showChevron = true,
      defaultText = "All",
      ...props
    },
    ref
  ) => {
    const isActive = Boolean(count)

    return (
      <button
        className={cn(
          "flex items-center justify-around gap-1 py-[3px] px-2 cursor-pointer h-10 w-full rounded-md shadow-sm bg-white border",
          "hover:bg-blue-50 hover:border-primary data-[state=open]:bg-blue-50 data-[state=open]:border-primary",
          {
            "text-primary border-primary": isActive,
            "text-body border-slate-300": !isActive,
          },
          className
        )}
        ref={ref}
        onClick={onClick}
        {...props}
      >
        <FilterIcon
          type={filterName}
          className={cn("w-4 h-4 fill-body", { "fill-primary": isActive })}
        />
        <span className={cn("whitespace-nowrap", { "font-semibold": count })}>
          {label}
        </span>
        {count !== undefined && (
          <div className="flex items-center gap-1">
            <span className="font-semibold">
              {count ? `(${count})` : defaultText}
            </span>
            {showChevron && (
              <FontAwesomeIcon
                className={cn("text-[17px] downArrow shrink-0", {})}
                icon={faChevronDown}
              />
            )}
          </div>
        )}
      </button>
    )
  }
)

export default FilterButton
