import { ReactComponent as InfoIcon } from "app/assets/icons/info-gray-circle.svg"
import Tooltip from "app/components/Tooltip"
import BodyText from "app/components/design-system/BodyText"
import { primaryColor } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"

const useStyles = makeAppStyles((theme) => ({
  actionButton: {
    margin: 0,
    padding: 0,
  },
  actionText: {
    color: primaryColor,
    fontSize: 15,
  },
}))
const InsuranceToggleTooltip = () => {
  const classes = useStyles()
  return (
    <Tooltip
      interactive
      arrow
      title={
        <div>
          <BodyText size="sm">
            Toggle on to default to using insurance for new orders containing
            Labcorp testing. (Note: You can turn this on/off per order)
          </BodyText>
        </div>
      }
    >
      <button className={classes.actionButton}>
        <InfoIcon />
      </button>
    </Tooltip>
  )
}

export default InsuranceToggleTooltip
