import { TaskTypesLiteral } from "types/results-interpretation"

export function getTaskTypeLabel(taskType: TaskTypesLiteral) {
  switch (taskType) {
    case "lab_testing":
      return "Lab Testing"
    case "medication":
      return "Medication"
    case "movement_mindfulness_sleep":
      return "Movement, Mindfulness, & Sleep"
    case "nutrition":
      return "Nutrition"
    case "supplement":
      return "Supplement"
    case "custom":
    default:
      return "Custom Task"
  }
}
