import { combineReducers } from "redux"

import { USER_LOGGED_OUT } from "app/auth/store/actions"
import auth from "app/auth/store/reducers"

import customFees from "./customFees.reducer"
import fuse from "./fuse"
import healthCategory from "./healthCategory.reducer"
import labCompany from "./labCompany.reducer"
import labTestType from "./labTestType.reducer"
import overrideLabTest from "./overrideLabTest.reducer"
import phlebotomist from "./phlebotomist.reducer"
import practitioner from "./practitioner.reducer"
import practitionerBundles from "./practitionerBundles.reducer"
import emrIntegrations from "./practitionerEmrIntegrations.reducer"
import profile from "./profile.reducer"
import shareBundle from "./shareBundle.reducer"
import snippet from "./snippet.reducer"
import teamMember from "./teamMember.reducer"

const createReducer = (asyncReducers) => {
  // This wrapper ensures that redux state is fully cleared after logging out:
  // https://netbasal.com/how-to-secure-your-users-data-after-logout-in-redux-30468c6848e8
  return (state, action) => {
    if (action.type === USER_LOGGED_OUT) {
      state = undefined
    }

    return combineReducers({
      auth,
      fuse,
      healthCategory,
      labCompany,
      labTestType,
      profile,
      practitioner,
      practitionerBundles,
      emrIntegrations,
      phlebotomist,
      teamMember,
      snippet,
      shareBundle,
      overrideLabTest,
      customFees,
      ...asyncReducers,
    })(state, action)
  }
}

export default createReducer
