import { Link } from "@material-ui/core"

import BodyText from "app/components/design-system/BodyText"
import { navy } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"

import { StyledFormControlLabel } from "../components/StyledFormControlLabel"
import { StyledSwitch } from "../components/StyledSwitch"
import OptimalRangesTable from "./OptimalRangesTable/OptimalRangesTable"

interface Props {
  toggled: boolean
  onToggle: () => void
}

const useStyles = makeAppStyles({
  description: {
    display: "flex",
    flexDirection: "column",
    gap: 6,
  },
  underlined: {
    textDecoration: "underline !important",
    color: navy,
  },
})

const RupaBloodDashboardsOptimalRanges = ({ toggled, onToggle }: Props) => {
  const classes = useStyles()

  return (
    <>
      <div>
        <BodyText weight="semibold" size="md">
          Optimal Ranges
        </BodyText>
        <StyledFormControlLabel
          control={
            <StyledSwitch
              checked={toggled}
              onChange={onToggle}
              name="enable_optimal_ranges"
            />
          }
          label={
            <div className={classes.description}>
              <BodyText weight="semibold">Show Optimal Ranges</BodyText>
              <BodyText>
                Optimal ranges are a set of values that indicate the ideal level
                of a particular biomarker for optimal health. These ranges are
                often narrower than the lab's standard reference ranges.
              </BodyText>
              <BodyText>
                Use our optimal range defaults, or customize them yourself.{" "}
                <Link
                  className={classes.underlined}
                  href="https://www.rupahealth.com/optimal-ranges"
                  target="_blank"
                  onClick={(e) => e.stopPropagation()}
                >
                  Learn more
                </Link>
                .
              </BodyText>
            </div>
          }
        />
      </div>

      {toggled && <OptimalRangesTable />}
    </>
  )
}

export default RupaBloodDashboardsOptimalRanges
