import EmptyTrendsIcon from "app/assets/images/results-over-time/trends-empty.png"

import { PatientPortalResultsUploadButton } from "./PatientPortalResultsUploadButton"

interface Props {
  userId: string
  resultsUploadEnabled: boolean
}

export const TrendsEmpty: React.FC<Props> = ({
  userId,
  resultsUploadEnabled,
}) => {
  return (
    <div className="flex flex-col justify-center items-center text-center">
      <div className="max-w-xs flex flex-col justify-center items-center">
        <div className="w-[225px]">
          <img src={EmptyTrendsIcon} alt="no trends" />
        </div>
        <div className="flex flex-col gap-4 -mt-4">
          <div className="font-semibold">
            Track your biomarkers over time to get a complete view of your
            health journey.
          </div>
          {resultsUploadEnabled ? (
            <PatientPortalResultsUploadButton
              userId={userId}
              variant="primary"
            />
          ) : (
            <div>
              If you have results from compatible labs, they’ll show up here.
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
