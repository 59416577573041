import { BiomarkerCustomDescription } from "types/biomarker-custom-description"
import { BloodReportResult } from "types/blood-report-result"

import BloodLabDashboardsSnapshotBiomarkerGrouping from "./BloodLabDashboardsSnapshotBiomarkerGrouping"

export default function BloodLabDashboardsSnapshotBiomarkerSection({
  sectionTitle,
  bloodReportResults,
  showHighLowDescriptions,
  biomarkerCustomDescriptionsMapping,
  biomarkersIdsWithoutResults,
  showBiomarkersWithoutResults,
}: {
  sectionTitle: string
  bloodReportResults: BloodReportResult[]
  showHighLowDescriptions?: boolean
  biomarkerCustomDescriptionsMapping?: Record<
    string,
    BiomarkerCustomDescription
  >
  showBiomarkersWithoutResults?: boolean
  biomarkersIdsWithoutResults?: string[]
}) {
  return bloodReportResults.length > 0 ? (
    <div className={"bg-white"}>
      <div
        className={"m-3 mb-0 p-1 pl-3 pr-3 bg-slate-100 rounded-lg font-bold"}
      >
        {sectionTitle}
      </div>
      <div className={"mb-4"}>
        <BloodLabDashboardsSnapshotBiomarkerGrouping
          bloodReportResults={bloodReportResults}
          showHighLowDescriptions={showHighLowDescriptions}
          biomarkerCustomDescriptionsMapping={
            biomarkerCustomDescriptionsMapping
          }
          biomarkersIdsWithoutResults={
            showBiomarkersWithoutResults ? biomarkersIdsWithoutResults : []
          }
        />
      </div>
    </div>
  ) : null
}
