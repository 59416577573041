import { useEffect } from "react"

import clsx from "clsx"
import { noop } from "lodash"
import { usePrevious } from "react-use"

import { CircularProgress } from "@material-ui/core"

import { ReactComponent as GenerateFoodPlanIcon } from "app/assets/icons/generate-food-plan.svg"
import { ReactComponent as ListIcon } from "app/assets/icons/list.svg"
import { ReactComponent as RestartIcon } from "app/assets/icons/restart.svg"
import { ReactComponent as TableIcon } from "app/assets/icons/table.svg"
import Button from "app/components/design-system/Button"
import FoodPlanButtonSelectField from "app/food-plans/components/FoodPlanButtonSelectField"
import FoodPlanCard from "app/food-plans/components/FoodPlanCard"
import FoodPlanErrorMessage from "app/food-plans/components/FoodPlanErrorMessage"
import FoodPlanTextAreaField from "app/food-plans/components/FoodPlanTextAreaField"
import useFoodPlanUpdateForm, {
  FoodPlanUpdateConfigurationFormValues,
} from "app/food-plans/hooks/use-food-plan-update-form"
import { colors, navy } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"

const useStyles = makeAppStyles((theme) => ({
  restartButton: {
    display: "flex",
    alignItems: "flex-start",
    "& .MuiButton-label": {
      marginBottom: -3,
    },
    "& .MuiButton-startIcon": {
      margin: theme.spacing(0.0, 0.5, 0.5, 0.0),
    },
  },
  cancelButton: {
    background: colors.blueGray[200],
    color: navy,
    border: `1px solid ${colors.blueGray[300]}`,
    "&:hover": {
      background: colors.blueGray[300],
      color: navy,
    },
  },
  updateButton: {
    display: "flex",
    alignItems: "center",
    "& .MuiButton-label": {
      marginBottom: -3,
    },
    "& .MuiButton-startIcon": {
      margin: theme.spacing(0.0, 1.0, 0.5, 0.0),
    },
  },
}))

export interface FoodPlanUpdateConfigurationCardProps {
  defaultValues?: FoodPlanUpdateConfigurationFormValues
  errorMessage?: string
  isGenerating?: boolean
  isRestarting?: boolean
  isStartingGenerate?: boolean
  onRestart?: () => Promise<void>
  onStartGenerate?: (
    values: FoodPlanUpdateConfigurationFormValues
  ) => Promise<void>
  onStartCancel?: () => Promise<void>
}

export default function FoodPlanUpdateConfigurationCard({
  defaultValues,
  errorMessage,
  isGenerating,
  isRestarting,
  isStartingGenerate,
  onRestart,
  onStartGenerate,
  onStartCancel,
}: FoodPlanUpdateConfigurationCardProps) {
  const classes = useStyles()
  const methods = useFoodPlanUpdateForm({
    defaultValues,
  })

  const wasGenerating = usePrevious(isGenerating)
  useEffect(() => {
    if (wasGenerating && !isGenerating) {
      methods.reset(defaultValues)
    }
  }, [isGenerating])

  const isDisabled = isStartingGenerate || isGenerating || isRestarting
  const canCancel = isStartingGenerate || isGenerating

  return (
    <form
      onSubmit={methods.handleSubmit(
        (isGenerating ? onStartCancel : onStartGenerate) || noop
      )}
    >
      <FoodPlanCard>
        <div>
          <Button
            className={classes.restartButton}
            color="text"
            disabled={isDisabled}
            onClick={() => onRestart?.()}
            startIcon={
              isRestarting ? (
                <CircularProgress color="inherit" size={16} />
              ) : (
                <RestartIcon
                  fill="currentColor"
                  height={16}
                  width={16}
                  viewBox="0 0 14 16"
                />
              )
            }
            type="button"
          >
            {isRestarting ? "Restarting..." : "Restart"}
          </Button>
        </div>

        <FoodPlanTextAreaField
          control={methods.control}
          disabled={isDisabled}
          name="suggestions"
          placeholder="Are there any changes you'd like Rupa AI to make? E.g. Use whole-grains, no seafood, more Thai food etc."
          rows={5}
        />

        <FoodPlanButtonSelectField
          control={methods.control}
          disabled={isDisabled}
          label="Layout"
          name="layout"
          options={[
            {
              icon: <TableIcon fill="currentColor" viewBox="0 0 16 12" />,
              label: "Table",
              value: "table",
            },
            {
              icon: <ListIcon fill="currentColor" viewBox="0 0 15 11" />,
              label: "List",
              value: "list",
            },
          ]}
        />

        {errorMessage && (
          <FoodPlanErrorMessage>{errorMessage}</FoodPlanErrorMessage>
        )}

        <Button
          className={clsx(classes.updateButton, [
            canCancel && classes.cancelButton,
          ])}
          color="primary"
          fullWidth
          size="medium"
          startIcon={
            isStartingGenerate || isGenerating ? (
              <CircularProgress color="inherit" size={15} />
            ) : (
              <GenerateFoodPlanIcon fill="currentColor" viewBox="0 0 15 18" />
            )
          }
          type="submit"
        >
          {canCancel ? "Cancel" : "Update Food Plan"}
        </Button>
      </FoodPlanCard>
    </form>
  )
}
