import {
  ORDERED_TEST_STATUS,
  OrderedTestStatusUnion,
} from "app/constants.typed"
import { OrderedTest } from "app/types"

// The priority of specimen issue statuses, from highest to lowest
const ORDERED_TEST_SPECIMEN_ISSUE_STATUSES: OrderedTestStatusUnion[] = [
  ORDERED_TEST_STATUS.ACTION_REQUIRED_SPECIMEN_ISSUE,
  ORDERED_TEST_STATUS.RECOLLECTION_IN_PROGRESS,
  ORDERED_TEST_STATUS.PROCESSING_SPECIMEN_ISSUE_ACTION,
]

/**
 * Returns the OrderedTest with the highest priority SpecimenIssue for the OrderedTests provided.
 * Used for when we are showing a panel or kit that represents multiple OrderedTests,
 * and we want to show the highest priority SpecimenIssue for those OrderedTests.
 *
 * @param orderedTests - OrderedTest[]
 * @returns - OrderedTest | undefined
 */
export function orderedTestWithHighestPrioritySpecimenIssue(
  orderedTests: OrderedTest[]
) {
  let result: OrderedTest | undefined = undefined

  for (const orderedTest of orderedTests) {
    const { specimen_issue, status } = orderedTest
    if (
      specimen_issue &&
      ORDERED_TEST_SPECIMEN_ISSUE_STATUSES.includes(status)
    ) {
      if (!result) {
        result = orderedTest
      } else if (
        ORDERED_TEST_SPECIMEN_ISSUE_STATUSES.indexOf(status) <
        ORDERED_TEST_SPECIMEN_ISSUE_STATUSES.indexOf(result.status)
      ) {
        result = orderedTest
      }
    }
  }

  return result
}
