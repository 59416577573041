import { useEffect, useState } from "react"

import { isEmpty } from "lodash"

import { Divider } from "@material-ui/core"

import DisplayText from "app/components/design-system/DisplayText"
import useEventCallback from "app/hooks/use-event-callback"
import EditorFormPlaceholder from "app/results-summary/components/ResultsSummaryEditor/components/EditorFormPlaceholder"
import SortableBiomarkerListEditor from "app/results-summary/components/ResultsSummaryEditor/components/SortableBiomarkerListEditor"
import { AnyBiomarkerResourceIdentifier } from "app/results-summary/components/ResultsSummaryEditor/types"
import useCreateInterpretationBiomarker from "app/results-summary/hooks/use-create-interpretation-biomarker"
import useReorderBiomarkers from "app/results-summary/hooks/use-reorder-biomarkers"
import { UseResultsVisualizerResourcesHook } from "app/results-summary/hooks/use-results-summary-resources"
import { ResourceIdentifier } from "app/swr/types"
import { bodyFontFamilyImportant, colors } from "app/theme"
import interleave from "app/utils/interleave"
import makeAppStyles from "app/utils/makeAppStyles"
import { Order } from "types/order"
import { ResultsInterpretation } from "types/results-interpretation"

import AddBiomarker from "./AddBiomarker"
import BiomarkerRenderer from "./BiomarkerRenderer"
import FormSection from "./FormSection"
import OutOfRangeBiomarkersTooltip from "./OutOfRangeBiomarkersTooltip"

const useStyles = makeAppStyles((theme) => ({
  title: {
    color: colors.blueGray[500],
    fontSize: 13,
    fontFamily: bodyFontFamilyImportant,
    textTransform: "uppercase",
  },
  biomarkersList: {
    width: "100%",
    display: "flex",
    flexFlow: "column nowrap",
    marginTop: theme.spacing(2.0),
    gap: theme.spacing(3.0),
  },
  addBiomarkerSlot: {
    width: "100%",
    marginTop: theme.spacing(2.0),
  },
  divider: {
    background: colors.blueGray[100],
  },
}))

const BiomarkerListRenderer = ({
  identifiers,
}: {
  identifiers: ResourceIdentifier[]
}) => {
  const classes = useStyles()
  return (
    <div className={classes.biomarkersList}>
      {interleave(
        identifiers.map((identifier) => (
          <BiomarkerRenderer key={identifier.id} identifier={identifier} />
        )),
        (elem) => (
          <Divider key={`divider-${elem.key}`} className={classes.divider} />
        )
      )}
    </div>
  )
}

const BiomarkersFormEditing = ({
  hasEmptyState,
  identifiers,
  interpretation,
  isAdding,
  isCreating,
  onCreate,
  onReorder,
  onToggleAdding,
  order,
}: {
  hasEmptyState: boolean
  identifiers: ResourceIdentifier[]
  interpretation: ResultsInterpretation
  isAdding: boolean
  isCreating: boolean
  onCreate: (identifier: AnyBiomarkerResourceIdentifier) => Promise<any>
  onReorder: (identifiers: ResourceIdentifier[]) => Promise<any>
  onToggleAdding: () => void
  order: Order
}) => {
  const classes = useStyles()
  return (
    <>
      {!hasEmptyState && (
        <SortableBiomarkerListEditor
          identifiers={identifiers}
          onReorder={onReorder}
        />
      )}
      <div className={classes.addBiomarkerSlot}>
        <AddBiomarker
          interpretation={interpretation}
          isAdding={isAdding}
          isCreating={isCreating}
          onCreate={onCreate}
          onToggleAdding={onToggleAdding}
          order={order}
        />
      </div>
    </>
  )
}

const BiomarkersFormRenderer = ({
  identifiers,
  interpretation,
  isAdding,
  isCreating,
  onCreate,
  onToggleAdding,
  order,
}: {
  identifiers: ResourceIdentifier[]
  interpretation: ResultsInterpretation
  isAdding: boolean
  isCreating: boolean
  onCreate: (identifier: AnyBiomarkerResourceIdentifier) => Promise<void>
  onToggleAdding: () => void
  order: Order
}) => {
  const classes = useStyles()
  return (
    <>
      <BiomarkerListRenderer identifiers={identifiers} />
      <div className={classes.addBiomarkerSlot}>
        <AddBiomarker
          interpretation={interpretation}
          isAdding={isAdding}
          isCreating={isCreating}
          onCreate={onCreate}
          onToggleAdding={onToggleAdding}
          order={order}
        />
      </div>
    </>
  )
}

export interface BiomarkersFormProps {
  order: Order
  outOfRangeBiomarkers: UseResultsVisualizerResourcesHook["outOfRangeBiomarkers"]
  interpretationSwr: UseResultsVisualizerResourcesHook["interpretationSwr"]
}

export default function BiomarkersForm({
  order,
  outOfRangeBiomarkers,
  interpretationSwr,
}: BiomarkersFormProps) {
  const classes = useStyles()
  const [isAdding, setIsAdding] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const { data: interpretation } = interpretationSwr
  const biomarkersRelationship = interpretation?.relationships.biomarkers
  const hasEmptyState = isEmpty(biomarkersRelationship?.data)

  const reorderBiomarkers = useReorderBiomarkers(interpretationSwr)

  /**
   * Toggle editing state and reset adding state.
   */
  const handleToggleEditing = useEventCallback(() => {
    const nextIsEditing = !isEditing

    setIsEditing(nextIsEditing)
    if (!nextIsEditing) {
      setIsAdding(false)
    }
  })

  /**
   * Toggle adding state and set editing state to true.
   */
  const handleToggleAdding = useEventCallback(() => {
    const nextIsAdding = !isAdding

    setIsAdding(nextIsAdding)

    if (nextIsAdding) {
      setIsEditing(true)
    } else {
      setIsEditing(!hasEmptyState)
    }
  })

  const { createInterpretationBiomarker, isCreating } =
    useCreateInterpretationBiomarker(interpretation, handleToggleAdding)

  useEffect(() => {
    if (hasEmptyState && isEditing && !isAdding) {
      setIsEditing(false)
    }
  }, [hasEmptyState, isEditing, isAdding])

  return (
    <FormSection
      disableEditButton={isCreating}
      isEditing={isEditing}
      onToggleEditing={handleToggleEditing}
      showEditButton={isEditing || !hasEmptyState}
      title={
        <OutOfRangeBiomarkersTooltip
          outOfRangeBiomarkers={outOfRangeBiomarkers}
        >
          <DisplayText
            className={classes.title}
            size="base"
            variant="h4"
            weight="semibold"
          >
            {"Biomarker Visualization"}
          </DisplayText>
        </OutOfRangeBiomarkersTooltip>
      }
    >
      {isEditing && !!interpretation ? (
        <BiomarkersFormEditing
          hasEmptyState={hasEmptyState}
          identifiers={interpretation.relationships.biomarkers.data}
          interpretation={interpretation}
          isAdding={isAdding}
          isCreating={isCreating}
          onCreate={createInterpretationBiomarker}
          onReorder={reorderBiomarkers}
          onToggleAdding={handleToggleAdding}
          order={order}
        />
      ) : hasEmptyState || !interpretation ? (
        <EditorFormPlaceholder
          addLabel="Add Biomarker"
          onClick={handleToggleAdding}
          type="biomarker"
        />
      ) : (
        <BiomarkersFormRenderer
          identifiers={interpretation.relationships.biomarkers.data}
          interpretation={interpretation}
          isAdding={isAdding}
          isCreating={isCreating}
          onCreate={createInterpretationBiomarker}
          onToggleAdding={handleToggleAdding}
          order={order}
        />
      )}
    </FormSection>
  )
}
