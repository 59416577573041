import * as React from "react"

import clsx from "clsx"
import _ from "lodash"

import { Divider, Typography } from "@material-ui/core"

import { ReactComponent as InfoIcon } from "app/assets/icons/info-gray-circle.svg"
import SampleTypePill from "app/components/SampleTypePill"
import Tooltip from "app/components/Tooltip"
import { ORDER_LINE_ITEM_TYPE } from "app/constants"
import { LINE_ITEM_INFO_CONFIG } from "app/main/patient-checkout/sections/OrderDetails"
import ProcessingAndOrderingCollapse from "app/main/patient-checkout/sections/ProcessingAndOrderingCollapse"
import {
  ADDITIONAL_FEE_LINE_ITEM_TYPES,
  PROCESSING_AND_ORDERING_ORDER_LINE_ITEMS,
  calculateRetailPrice,
  sortLineItemTypeToLast,
} from "app/main/patient-checkout/sections/pricing-helpers"
import { PaymentCollectionConfiguration } from "app/main/payment-collection/types"
import { colors, rupaTeal, shadows, textPrimaryColor } from "app/theme"
import { PatientPortalOrderLineItemLabTest } from "app/types"
import { formatDollars } from "app/utils"
import makeAppStyles from "app/utils/makeAppStyles"

const useStyles = makeAppStyles((theme) => ({
  container: {
    flex: "1 1 auto",
    order: 1,
    padding: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1.0),
    backgroundColor: "white",
    border: `1px solid ${colors.blueGray[200]}`,
    borderRadius: 8,
    boxShadow: shadows.default,
    [theme.breakpoints.up("md")]: {
      minWidth: 460,
      maxWidth: 500,
      order: 2,
    },
  },
  header: {
    color: textPrimaryColor,
    fontSize: 24,
  },
  body: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2.0),
  },
  labTestItem: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "white",
    padding: theme.spacing(2.0),
    border: `1px solid ${colors.blueGray[200]}`,
    borderRadius: 8,
    boxShadow: shadows.default,
  },
  labTestItemDisabled: {
    "& $labTestItemNameTitle": {
      textDecoration: "line-through",
    },
  },
  labTestItemName: {
    flex: "1 1 auto",
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(0.5),
  },
  labTestItemNameTitle: {
    color: colors.blueGray[800],
    fontWeight: theme.typography.fontWeightBold,
    fontSize: 15,
  },
  labTestItemNameSubtitle: {
    color: colors.blueGray[700],
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: 15,
  },
  labTestItemTypes: {
    display: "flex",
    gap: 2,
  },
  labTestItemPrice: {
    flex: "0 1 auto",
    display: "flex",
    flexDirection: "column",
  },
  labTestItemPriceCost: {
    color: colors.blueGray[800],
    fontWeight: theme.typography.fontWeightBold,
    fontSize: 15,
  },
  labTestItemPriceOriginalCost: {
    color: colors.blueGray[300],
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: 15,
    textDecoration: "line-through",
  },
  simpleLineItem: {
    display: "flex",
    justifyContent: "space-between",
  },
  simpleLineItemWithInfo: {
    display: "flex",
  },
  simpleLineItemSemibold: {
    "& $simpleLineItemTitle, & $simpleLineItemCost": {
      fontWeight: theme.typography.fontWeightMedium,
    },
  },
  simpleLineItemTitle: {
    color: colors.blueGray[800],
    fontSize: 15,
  },
  simpleLineItemCost: {
    color: colors.blueGray[800],
    fontSize: 15,
  },
  priceBreakdown: {
    display: "flex",
    flexDirection: "column",
  },
  priceBreakdownDetails: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1.0),
    marginBottom: theme.spacing(2.0),
  },
  priceBreakdownFooter: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  priceBreakdownTotal: {
    fontSize: 19,
    fontWeight: theme.typography.fontWeightBold,
  },
  processingAndOrderingExpand: {
    marginBottom: theme.spacing(2.0),
  },
  simpleLineItemWithIcon: {
    display: "flex",
  },
  icon: {
    margin: "auto",
    marginLeft: "8px",
  },
}))

type Classes = ReturnType<typeof useStyles>

const LabTestName = ({
  classes,
  lineItem,
}: {
  classes: Classes
  lineItem: PatientPortalOrderLineItemLabTest
}) => {
  return (
    <div className={classes.labTestItemName}>
      <Typography className={classes.labTestItemNameTitle}>
        {lineItem.ordered_test.lab_test.name}
      </Typography>
      <Typography className={classes.labTestItemNameSubtitle}>
        {lineItem.ordered_test.lab_test.lab_company.name}
      </Typography>
      <LabTestType classes={classes} lineItem={lineItem} />
    </div>
  )
}

const LabTestPrice = ({
  classes,
  lineItem,
}: {
  classes: Classes
  lineItem: PatientPortalOrderLineItemLabTest
}) => {
  if (!lineItem.discounted_cost) {
    return (
      <div className={classes.labTestItemPrice}>
        <Typography className={classes.labTestItemPriceCost}>
          {formatDollars(lineItem.cost)}
        </Typography>
      </div>
    )
  }
  return (
    <div className={classes.labTestItemPrice}>
      <Typography className={classes.labTestItemPriceCost}>
        {formatDollars(lineItem.discounted_cost)}
      </Typography>
      <Typography className={classes.labTestItemPriceOriginalCost}>
        {formatDollars(lineItem.cost)}
      </Typography>
    </div>
  )
}

const LabTestType = ({
  classes,
  lineItem,
}: {
  classes: Classes
  lineItem: PatientPortalOrderLineItemLabTest
}) => {
  return (
    <div className={classes.labTestItemTypes}>
      {lineItem.ordered_test.lab_test.lab_test_types.map((type) => {
        return (
          <SampleTypePill
            name={type.name}
            key={type.name}
            style={{
              marginLeft: 0,
              marginRight: 0,
              marginTop: 0,
              marginBottom: 0,
            }}
          />
        )
      })}
    </div>
  )
}

const LabTestLineItem = ({
  classes,
  lineItem,
}: {
  classes: Classes
  lineItem: PatientPortalOrderLineItemLabTest
}) => {
  return (
    <div
      className={clsx(classes.labTestItem, {
        [classes.labTestItemDisabled]: Boolean(lineItem.date_disabled),
      })}
    >
      <LabTestName classes={classes} lineItem={lineItem} />
      <LabTestPrice classes={classes} lineItem={lineItem} />
    </div>
  )
}

const SimpleLineItem = ({
  classes,
  color = colors.blueGray[800],
  fontWeight,
  formattedCost,
  title,
  info,
}: {
  classes: Classes
  color?: string
  fontWeight?: "semibold"
  formattedCost: string
  info?: React.ReactNode
  title: string
}) => {
  const titleElement = (
    <Typography className={classes.simpleLineItemTitle} style={{ color }}>
      {title}
    </Typography>
  )

  return (
    <div
      className={clsx(classes.simpleLineItem, {
        [classes.simpleLineItemSemibold]: fontWeight === "semibold",
      })}
    >
      {info ? (
        <Tooltip title={info} placement="bottom" arrow>
          <div className={classes.simpleLineItemWithIcon}>
            {titleElement}

            <InfoIcon viewBox="0 0 16 16" className={classes.icon} />
          </div>
        </Tooltip>
      ) : (
        titleElement
      )}
      <Typography
        className={classes.simpleLineItemCost}
        align="right"
        style={{ color }}
      >
        {formattedCost}
      </Typography>
    </div>
  )
}

const PriceBreakdown = ({
  classes,
  config,
}: {
  classes: Classes
  config: PaymentCollectionConfiguration
}) => {
  const { line_items, order_pricing } = config.pricing

  const processingAndOrderingLineItems = line_items.filter((lineItem) =>
    PROCESSING_AND_ORDERING_ORDER_LINE_ITEMS.some(
      (type) => type === lineItem.type
    )
  )

  const processingAndOrderingCost = _.sum(
    processingAndOrderingLineItems.map((lineItem) => Number(lineItem.cost))
  )

  return (
    <div className={classes.priceBreakdown}>
      <div>
        <SimpleLineItem
          classes={classes}
          formattedCost={formatDollars(
            calculateRetailPrice(order_pricing.msrp_total, line_items)
          )}
          title={"Retail Price"}
        />

        {line_items
          .filter(
            (lineItem) => lineItem.type === ORDER_LINE_ITEM_TYPE.CUSTOM_FEE
          )
          .map((lineItem, index) => (
            <SimpleLineItem
              key={`${lineItem.type}-${index}`}
              classes={classes}
              formattedCost={formatDollars(lineItem.cost)}
              title={`${lineItem.title} paid to ${config.practitioner.clinic_name}}`}
            />
          ))}

        {line_items
          .filter((lineItem) =>
            ADDITIONAL_FEE_LINE_ITEM_TYPES.some(
              (type) => type === lineItem.type
            )
          )
          .map((lineItem, index) => (
            <SimpleLineItem
              key={`${lineItem.type}-${index}`}
              classes={classes}
              formattedCost={formatDollars(lineItem.cost)}
              info={LINE_ITEM_INFO_CONFIG[lineItem.type]?.info ?? ""}
              title={lineItem.title}
            />
          ))}

        <div className={classes.processingAndOrderingExpand}>
          <ProcessingAndOrderingCollapse
            header={
              <SimpleLineItem
                title={"Processing & Ordering"}
                classes={classes}
                formattedCost={formatDollars(processingAndOrderingCost)}
              />
            }
          >
            {line_items
              .filter((lineItem) =>
                PROCESSING_AND_ORDERING_ORDER_LINE_ITEMS.some(
                  (type) => type === lineItem.type
                )
              )
              // put processing second to last and credit card fee last in section
              .sort(sortLineItemTypeToLast(ORDER_LINE_ITEM_TYPE.PROCESSING_FEE))
              .sort(
                sortLineItemTypeToLast(ORDER_LINE_ITEM_TYPE.CREDIT_CARD_FEE)
              )
              .map((lineItem, index) => (
                <SimpleLineItem
                  key={`${lineItem.type}-${index}`}
                  classes={classes}
                  formattedCost={formatDollars(lineItem.cost)}
                  info={LINE_ITEM_INFO_CONFIG[lineItem.type]?.info ?? ""}
                  title={lineItem.title}
                />
              ))}
          </ProcessingAndOrderingCollapse>
        </div>

        {order_pricing.subtotal &&
          order_pricing.subtotal !== order_pricing.total_price && (
            <SimpleLineItem
              classes={classes}
              formattedCost={formatDollars(order_pricing.subtotal)}
              title="Subtotal"
            />
          )}

        {parseFloat(order_pricing.coupon_discount) !== 0 && (
          <SimpleLineItem
            classes={classes}
            color={rupaTeal}
            fontWeight="semibold"
            formattedCost={"-" + formatDollars(order_pricing.coupon_discount)}
            title="Discounts"
          />
        )}

        {parseFloat(order_pricing.rupa_discount) !== 0 && (
          <SimpleLineItem
            classes={classes}
            color={rupaTeal}
            fontWeight="semibold"
            formattedCost={"-" + formatDollars(order_pricing.rupa_discount)}
            title="Rupa Savings"
          />
        )}

        <Divider />
      </div>

      <div className={classes.priceBreakdownFooter}>
        <Typography className={classes.priceBreakdownTotal} color="textPrimary">
          {formatDollars(order_pricing.total_price)}
        </Typography>
      </div>
    </div>
  )
}

export interface PaymentCollectionSidebarProps {
  config: PaymentCollectionConfiguration
}

export default function PaymentCollectionSidebar({
  config,
}: PaymentCollectionSidebarProps) {
  const classes = useStyles()
  return (
    <div className={classes.container}>
      <Typography className={classes.header} variant="h4">
        Details
      </Typography>

      <div className={classes.body}>
        {config.pricing.line_items.flatMap((lineItem) =>
          lineItem.type === ORDER_LINE_ITEM_TYPE.LAB_TEST ? (
            <LabTestLineItem
              key={lineItem.ordered_test.id}
              classes={classes}
              lineItem={lineItem}
            />
          ) : (
            []
          )
        )}

        <Divider />

        <PriceBreakdown classes={classes} config={config} />
      </div>
    </div>
  )
}
