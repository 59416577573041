import { styled } from "@material-ui/core"

import ArrowTopRight from "app/assets/icons/arrow-top-right.svg"
import Markdown from "app/components/Markdown"
import BodyText from "app/components/design-system/BodyText"
import { colors, primaryColor } from "app/theme"
import { PatientInstructionPrintReqForms } from "app/types"

interface Props {
  patientInstruction: PatientInstructionPrintReqForms
}

const ReqFormForCompany = styled("div")({
  display: "flex",
  flexDirection: "column",

  "&:not(:first-child)": {
    borderTop: `1px solid ${colors.blueGray[200]}`,
    paddingTop: 20,
  },
})

const PrintReqURL = styled("a")({
  color: primaryColor,
  marginTop: 5,
  fontWeight: 600,
})

const PrintReqIcon = styled("img")({
  marginLeft: 4,
})

const Description = styled(Markdown)({
  marginTop: 5,
  fontWeight: 600,
})

const PrintReqForms = ({ patientInstruction }: Props) => (
  <div className="w-full flex flex-col gap-5">
    {patientInstruction.req_form_for_companies.map((reqFormForCompany) => (
      <ReqFormForCompany key={reqFormForCompany.title}>
        <BodyText weight="semibold" size="md">
          {reqFormForCompany.title}
        </BodyText>
        {reqFormForCompany.description && (
          <Description>{reqFormForCompany.description}</Description>
        )}
        {reqFormForCompany.req_form && (
          <PrintReqURL href={reqFormForCompany.req_form} target="_blank">
            Print Requisition
            <PrintReqIcon src={ArrowTopRight} alt="" />
          </PrintReqURL>
        )}
      </ReqFormForCompany>
    ))}
  </div>
)

export default PrintReqForms
