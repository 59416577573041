import * as React from "react"

import { styled, Typography, TypographyProps } from "@material-ui/core"
import { CSSProperties } from "@material-ui/core/styles/withStyles"

import { colors } from "app/theme"

export interface BodyTextProps {
  size?: "xxs" | "xs" | "sm" | "base" | "md" | "lg" | "2xl"
  weight?: "regular" | "semibold" | "bold"
  underline?: boolean
  italic?: boolean
  children?: React.ReactNode
  variantMapping?: TypographyProps["variantMapping"]
  className?: string
  style?: CSSProperties
  dangerouslySetInnerHTML?: { __html: () => void }
  component?: React.ElementType
}

const BodyText = ({
  size = "base",
  weight = "regular",
  underline = false,
  italic = false,
  ...props
}: BodyTextProps & TypographyProps) => (
  <StyledTypography
    size={size}
    weight={weight}
    underline={underline}
    italic={italic}
    {...props}
  />
)

const StyledTypography = styled(
  ({ weight, size, italic, underline, ...other }) => (
    <Typography color="textPrimary" {...other} />
  )
)({
  lineHeight: ({ size }: BodyTextProps) =>
    size &&
    {
      xxs: 1.3,
      xs: 1.3,
      sm: 1.35,
      base: 1.35,
      md: 1.35,
      lg: 1.35,
      "2xl": 1.4,
    }[size],
  fontSize: ({ size }: BodyTextProps) =>
    size &&
    {
      xxs: 12,
      xs: 13,
      sm: 14,
      base: 15,
      md: 17,
      lg: 19,
      "2xl": 24,
    }[size],
  fontWeight: ({ weight }: BodyTextProps) =>
    weight &&
    {
      regular: 400,
      semibold: 600,
      bold: 700,
    }[weight],
  borderBottom: ({ underline }) =>
    underline ? `2px dotted ${colors.blueGray[400]}` : 0,
  fontStyle: ({ italic }) => (italic ? `italic` : "none"),
})

export default BodyText
