import * as Actions from "../actions"

interface stateType {
  orders: { results: any[] }
  ordersPending: boolean
  unreadResultNotifications: { count: number }
}

export const initialState: stateType = {
  orders: { results: [] },
  ordersPending: true,
  unreadResultNotifications: { count: 0 },
}

export default function ecommerceReducer(state = initialState, action) {
  switch (action.type) {
    case Actions.GET_ORDERS_REQUEST: {
      return {
        ...state,
        ordersPending: true,
      }
    }
    case Actions.GET_ORDERS_SUCCESS: {
      return {
        ...state,
        orders: action.payload,
        ordersPending: false,
      }
    }
    case Actions.GET_ORDERS_FAILURE: {
      return {
        ...state,
        ordersPending: true,
      }
    }
    case Actions.CLEAR_ORDERS: {
      return {
        ...state,
        orders: initialState.orders,
        newPatientModalOpen: false,
      }
    }
    case Actions.UPDATE_ORDER: {
      let updatedState = { ...state }

      const updateOrder = (o) =>
        o.id === action.payload.id ? action.payload : o

      if (state.orders) {
        updatedState.orders = {
          ...updatedState.orders,
          results: state.orders.results.map(updateOrder),
        }
      }

      return updatedState
    }
    case Actions.GET_UNREAD_RESULT_NOTIFICATIONS: {
      return {
        ...state,
        unreadResultNotifications: action.payload,
      }
    }
    case Actions.GET_UNREAD_RESULT_NOTIFICATIONS_FAILURE: {
      return {
        ...state,
        unreadResultNotifications: { count: 0 },
      }
    }
    default: {
      return state
    }
  }
}
