import { SimpleBodySystem } from "types/body-system"

import TrendsTable from "./table/TrendsTable"
import { InRangeOption, ResultsOverTimeDateGrouping } from "./types/types"

interface Props {
  primaryBodySystems: SimpleBodySystem[]
  availableDates: string[]
  userId: string
  timeSeriesGrouping: ResultsOverTimeDateGrouping
  inRangeValue: InRangeOption
  bodySystemId: string
  isAvailableDatesLoading: boolean
  biomarkerSearchValue: string
  showHighLowDescriptions: boolean
}

const TrendsContainer = ({
  primaryBodySystems,
  availableDates,
  userId,
  timeSeriesGrouping,
  inRangeValue,
  bodySystemId,
  isAvailableDatesLoading,
  biomarkerSearchValue,
  showHighLowDescriptions,
}: Props) => {
  return (
    <div>
      <TrendsTable
        primaryBodySystems={primaryBodySystems}
        groupedBy={timeSeriesGrouping}
        userId={userId}
        availableDates={availableDates}
        inRangeValue={inRangeValue}
        dropdownBodySystemId={bodySystemId}
        isAvailableDatesLoading={isAvailableDatesLoading}
        biomarkerSearchValue={biomarkerSearchValue}
        showHighLowDescriptions={showHighLowDescriptions}
      />
    </div>
  )
}

export default TrendsContainer
