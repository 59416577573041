import * as React from "react"

import clsx from "clsx"

import { colors, navy } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"

const useStyles = makeAppStyles((theme) => ({
  sectionHeader: {
    backgroundColor: colors.blueGray[200],
    borderRadius: 6,
    fontSize: 13,
    fontStyle: "normal",
    fontWeight: 600,
    textTransform: "uppercase",
    minWidth: 300,
    [theme.breakpoints.down("sm")]: {
      minWidth: "100%",
    },
    paddingLeft: 22,
    paddingTop: 6,
    paddingBottom: 6,
    display: "flex",
    alignItems: "center",
  },
  sectionContent: {
    marginTop: 16,
    marginLeft: 22,
    marginRight: 22,
    color: navy,
  },
  detailSection: {
    margin: 20,
  },
}))

export default function PanelDetailsSection({
  title,
  icon,
  children,
  className,
}: {
  title: string
  icon: React.ReactNode
  children: React.ReactNode
  className?: string
}) {
  const classes = useStyles()
  return (
    <div className={clsx(classes.detailSection, className)}>
      <div className={classes.sectionHeader}>
        {icon}
        {title}
      </div>
      <div className={classes.sectionContent}>{children}</div>
    </div>
  )
}
