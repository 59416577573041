import { useState } from "react"

import { styled } from "@material-ui/core"

import DisplayText from "app/components/design-system/DisplayText"

import FAQ from "./FAQ"

const Container = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "stretch",
})
const Title = styled(DisplayText)(({ theme }) => ({
  marginBottom: 16,
  textAlign: "center",
  [theme.breakpoints.up("sm")]: {
    marginBottom: 40,
  },
}))

interface Props {
  faqs: { question: string; answer: string }[]
}

const FAQs = ({ faqs }: Props) => {
  const [expandedIndex, setExpandedIndex] = useState(-1)

  return (
    <Container>
      <Title size="xl">Frequently Asked Questions</Title>
      {faqs.map(({ question, answer }, index) => (
        <FAQ
          key={question}
          question={question}
          answer={answer}
          expanded={index === expandedIndex}
          onExpand={() =>
            setExpandedIndex(expandedIndex === index ? -1 : index)
          }
        />
      ))}
    </Container>
  )
}

export default FAQs
