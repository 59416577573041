import { useState } from "react"

import { Skeleton } from "@material-ui/lab"

import Tooltip from "app/components/Tooltip"

import { StyledFormControlLabel } from "../components/StyledFormControlLabel"
import { StyledSwitch } from "../components/StyledSwitch"
import { NJ_NY_RI_RESTRICTIONS_URL } from "../constants"

export interface CartPreferencesProps {
  defaultPracPayEnabled: boolean
  onToggled: (enabled: boolean) => void
  disabledReason?: string
  loading: boolean
}

const CartPreferences = ({
  defaultPracPayEnabled,
  onToggled,
  disabledReason,
  loading,
}: CartPreferencesProps) => {
  const [checked, setChecked] = useState(defaultPracPayEnabled)
  return (
    <div>
      <p className="font-semibold text-lg">Cart Preferences</p>
      {!loading ? (
        <StyledFormControlLabel
          control={
            <Tooltip
              title={disabledReason ?? ""}
              disableHoverListener={!Boolean(disabledReason)}
              disableFocusListener={!Boolean(disabledReason)}
              disableTouchListener={!Boolean(disabledReason)}
              placement="bottom"
              arrow
            >
              <div>
                <StyledSwitch
                  onChange={() => {
                    onToggled(!checked)
                    setChecked(!checked)
                  }}
                  checked={checked}
                  name="default_practitioner_pay_enabled"
                  disabled={Boolean(disabledReason)}
                />
              </div>
            </Tooltip>
          }
          checked
          label={
            <div>
              <p className="font-semibold">
                Use Practitioner-Pay as the default for new orders
              </p>
              <p>
                This will apply to orders from every practitioner in your
                clinic.{" "}
                <span className="italic">
                  You cannot use practitioner-pay for patients based in NY, NJ
                  and RI with many lab companies. Learn more about restrictions{" "}
                  <span
                    className="text-primary underline"
                    onClick={() =>
                      window.Intercom
                        ? window.Intercom("showArticle", 8067351)
                        : window.open(NJ_NY_RI_RESTRICTIONS_URL, "_blank")
                    }
                  >
                    here
                  </span>
                  .
                </span>
              </p>
            </div>
          }
        />
      ) : (
        <Skeleton height={10} width={200} variant="rect" />
      )}
    </div>
  )
}

export default CartPreferences
