import { useEffect, useMemo, useReducer } from "react"
import { useDispatch } from "react-redux"

import axios from "axios"
import { isMatch, pick } from "lodash"
import { useDebounce } from "react-use"
import { ThunkDispatch } from "redux-thunk"

import useAppSelector from "app/hooks/useAppSelector"
import { RootState, CHECKOUT_DRAFT_FIELDS } from "app/types"
import { handleApiError, isPractitionerPayingOrder } from "app/utils"

import CheckoutDraftContext from "../contexts/CheckoutDraftContext"
import * as Actions from "../store/actions/draft.actions"
import * as OrderActions from "../store/actions/orders.actions"
import * as CardActions from "../store/actions/paymentCards.actions"
import draftReducer, { initialState } from "../store/reducers/draft.reducer"

export default function CheckoutDraftProvider({ children }) {
  const value = useCheckoutDraftProvider()
  return (
    <CheckoutDraftContext.Provider value={value}>
      {children}
    </CheckoutDraftContext.Provider>
  )
}

export function useCheckoutDraftProvider() {
  const reduxDispatch = useDispatch<ThunkDispatch<RootState, any, any>>()
  const order = useAppSelector(({ orders }) => orders.orders.order)

  const [state, dispatch] = useReducer(
    draftReducer,
    initialState,
    (initialState) => ({
      ...initialState,
      values: pick(order, ...CHECKOUT_DRAFT_FIELDS),
    })
  )

  /**
   * This effect ensures the draft state is updated in response to global order updates.
   */
  useEffect(() => {
    if (!state.dirty && !state.pending && !isMatch(order, state.values)) {
      dispatch(Actions.resetValuesAction(pick(order, ...CHECKOUT_DRAFT_FIELDS)))
    }
  }, [order])

  /**
   * This effect ensures the draft state is reverted after a failure to update
   */
  useEffect(() => {
    if (state.error) {
      dispatch(Actions.resetValuesAction(pick(order, ...CHECKOUT_DRAFT_FIELDS)))

      // workaround as the checked state is managed separately
      reduxDispatch(
        CardActions.setUseCardChecked(isPractitionerPayingOrder(order))
      )
    }
  }, [state.error])

  /**
   * This debounced effect is intended to patch the order state when the partial fields are updated.
   */
  useDebounce(
    async () => {
      if (state.dirty) {
        try {
          dispatch(Actions.requestPendingAction())

          const shouldUpdatePricing =
            order.requires_vendor_physician_authorization !==
            state.values.requires_vendor_physician_authorization
          await reduxDispatch(OrderActions.patchOrder(state.values))

          if (shouldUpdatePricing) {
            // Certain field updates, such as physician authorization, require a pricing update
            // to reflect line item fees.
            reduxDispatch(OrderActions.refreshPricing(order.id))
          }

          dispatch(Actions.requestSuccessAction())
        } catch (error: any) {
          if (axios.isCancel(error)) {
            return
          }

          reduxDispatch(handleApiError(error))

          dispatch(Actions.requestFailureAction(error))
        }
      }
    },
    500,
    [state.dirty, state.values]
  )

  return useMemo(() => {
    return [state, dispatch] as [typeof state, typeof dispatch]
  }, [state, dispatch])
}
