import {
  Resource,
  ResourceRelationshipMany,
  ResourceRelationshipSingle,
} from "app/swr/types"

import { EditorState } from "./editor-state"

export interface ResultsInterpretation extends Resource {
  type: "results_interpretation"
  attributes: {
    content_intro: EditorState
    content_next_steps: EditorState
    content_symptoms: EditorState
    date_released: string | null
    generated_file: string | null
    is_released: boolean
    title_intro: string
    title_next_steps: string
  }
  relationships: {
    biomarkers: ResourceRelationshipMany
    order: ResourceRelationshipSingle
    tasks: ResourceRelationshipMany
  }
}

export interface ResultsInterpretationPatch {
  attributes?: Partial<ResultsInterpretation["attributes"]> & {
    generate_pdf?: boolean
    release_to_patient?: boolean
  }
  relationships?: {
    biomarkers?: Partial<ResourceRelationshipMany>
    tasks?: Partial<ResourceRelationshipMany>
  }
}

export interface ResultsInterpretationBiomarker extends Resource {
  type: "results_interpretation_biomarker"
  attributes: {
    description: string
    name: string
    normal_max: string
    normal_min: string
    units: string
    value_type: "numeric" | "string"
    value: string
  }
  relationships: {
    biomarker: ResourceRelationshipSingle
    discrete_result: ResourceRelationshipSingle
    interpretation: ResourceRelationshipSingle
  }
}

export interface ResultsInterpretationBiomarkerCreate {
  type: "results_interpretation_biomarker"
  attributes?: Partial<ResultsInterpretationBiomarker["attributes"]>
  relationships: Partial<ResultsInterpretationBiomarker["relationships"]>
}

export const TASK_TYPES = [
  "custom",
  "lab_testing",
  "medication",
  "movement_mindfulness_sleep",
  "nutrition",
  "supplement",
] as const

export type TaskTypesLiteral = typeof TASK_TYPES[number]

export interface ResultsInterpretationTask extends Resource {
  type: "results_interpretation_task"
  attributes: {
    content_instructions: EditorState
    name: string
    task_type: TaskTypesLiteral
  }
  relationships: {
    interpretation: ResourceRelationshipSingle
  }
}

export interface ResultsInterpretationTaskCreate {
  type: "results_interpretation_task"
  attributes?: Partial<ResultsInterpretationTask["attributes"]>
  relationships: Partial<ResultsInterpretationTask["relationships"]>
}
