import { orderBy } from "lodash"

import { Theme } from "@material-ui/core"

import { ReactComponent as BarChartIcon } from "app/assets/icons/bar-chart.svg"
import { ReactComponent as CheatSheetIcon } from "app/assets/icons/cheat-sheet.svg"
import { ReactComponent as CheckMarkCogIcon } from "app/assets/icons/check-mark-cog.svg"
import { ReactComponent as CollectionDayIcon } from "app/assets/icons/collection-day.svg"
import { ReactComponent as BiomarkerIcon } from "app/assets/icons/labshops/biomarker.svg"
import { ReactComponent as CategoryIcon } from "app/assets/icons/labshops/category.svg"
import { ReactComponent as SampleReportIcon } from "app/assets/icons/sample-report.svg"
import { ReactComponent as ScheduleIcon } from "app/assets/icons/schedule-dark.svg"
import { ReactComponent as ShippingBoxIcon } from "app/assets/icons/shipping-box-dark.svg"
import { ReactComponent as UpRightArrowIcon } from "app/assets/icons/up-right-arrow.svg"
import { ReactComponent as CheckCircleIcon } from "app/assets/images/circular-check-filled.svg"
import BodyText from "app/components/design-system/BodyText"
import {
  StorefrontBiomarkerType,
  StorefrontHealthCategoryType,
  StorefrontLabCompanyType,
  StorefrontLabTestType,
  useResourceSelector,
} from "app/hooks/use-resource"
import { EmptyComparisonValue } from "app/main/comparison/components/ComparisonLabTests/components"
import { colors, primaryColor } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"

import AboutThisTestColumn from "./AboutThisTestColumn"
import CertificationsColumn from "./columns/CertificationsColumn"
import CollectionDaysColumn from "./columns/CollectionDaysColumn"
import PreparationTimeColumn from "./columns/PreperationTimeColumn"
import ShippingTimeColumn from "./columns/ShippingTimeColumn"
import TurnAroundTimeColumn from "./columns/TurnAroundTimeColumn"

interface Props {
  labTest: StorefrontLabTestType
  labCompany: StorefrontLabCompanyType | undefined
}

const styles = (theme: Theme) => ({
  aboutThisTestTitle: {
    "text-align": "center",
  },
  testContents: {
    width: "100%",
    display: "flex",
    "flex-direction": "row",
    "flex-wrap": "wrap",
    [theme.breakpoints.down("sm")]: {
      "flex-direction": "column",
    },
  },
  collectionInstructionsLink: {
    color: primaryColor,
    textDecoration: "none",
    fontWeight: theme.typography.fontWeightMedium,
    "& svg": {
      width: 12,
      height: 12,
      fill: "currentColor",
      verticalAlign: "middle",
    },
  },
  biomarkersContainer: {
    width: "100%",
    display: "flex",
    "flex-direction": "row",
    "flex-wrap": "wrap",
  },
  biomarkerTitle: {
    "text-transform": "uppercase",
    fontWeight: 600,
    fontSize: 13,
    marginTop: 49,
    paddingLeft: 22.5,
    paddingRight: 22.5,
  },
  biomarker: {
    width: "25%",
    "box-sizing": "border-box",
    marginTop: 11,
    display: "flex",
    gap: 5,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    paddingLeft: 22.5,
    paddingRight: 22.5,
  },
})

const useStyles = makeAppStyles(styles)

const AboutThisTest = ({ labTest, labCompany }: Props) => {
  const classes = useStyles()

  const biomarkers: (StorefrontBiomarkerType | undefined)[] =
    useResourceSelector((entities) => {
      if (!labTest?.relationships.biomarkers) {
        return []
      }

      return labTest?.relationships?.biomarkers.data.map((ref) => {
        return entities?.biomarker?.[ref.id]
      })
    })

  const healthCategories: (StorefrontHealthCategoryType | undefined)[] =
    useResourceSelector((entities) => {
      if (!labTest?.relationships.health_categories) {
        return []
      }

      return labTest?.relationships?.health_categories.data.map((ref) => {
        return entities?.health_category?.[ref.id]
      })
    })

  const sortedBiomarkers = orderBy(
    biomarkers,
    (biomarker) => biomarker?.attributes.long_name,
    ["asc"]
  )

  return (
    <>
      <div className={classes.aboutThisTestTitle}>
        <BodyText size="md" weight="semibold">
          About This Test
        </BodyText>
      </div>
      <div className={classes.testContents}>
        <AboutThisTestColumn
          icon={<SampleReportIcon viewBox="0 0 13 16" />}
          title="Sample Report"
          content={
            <a
              className={classes.collectionInstructionsLink}
              href={labTest.attributes.sample_report_url}
              target="__blank"
            >
              View Report <UpRightArrowIcon viewBox="0 0 12 12" />
            </a>
          }
        />

        <AboutThisTestColumn
          icon={<CheatSheetIcon viewBox="0 0 13 16" />}
          title="Collection Instructions"
          content={
            <a
              className={classes.collectionInstructionsLink}
              href={labTest.attributes.cheatsheet_url}
              target="__blank"
            >
              View Instructions <UpRightArrowIcon viewBox="0 0 12 12" />
            </a>
          }
        />

        <AboutThisTestColumn
          icon={<ShippingBoxIcon viewBox="0 0 15 16" />}
          title="Shipping Time"
          content={
            <BodyText weight="semibold">
              <ShippingTimeColumn labTest={labTest} labCompany={labCompany} />
            </BodyText>
          }
        />

        <AboutThisTestColumn
          icon={<BarChartIcon viewBox="0 -3 15 20" />}
          title="Turnaround Time"
          content={
            <BodyText weight="semibold">
              <TurnAroundTimeColumn labTest={labTest} />
            </BodyText>
          }
        />

        <AboutThisTestColumn
          icon={<CategoryIcon viewBox="0 0 13 16" />}
          title="Category"
          content={
            <BodyText weight="semibold">
              {Boolean(healthCategories.length) ? (
                healthCategories.map((cat) => cat?.attributes.name).join(", ")
              ) : (
                <EmptyComparisonValue />
              )}
            </BodyText>
          }
        />

        <AboutThisTestColumn
          icon={<ScheduleIcon viewBox="0 0 13 16" />}
          title="Test Preparation Starts"
          content={
            <BodyText weight="semibold">
              <PreparationTimeColumn labTest={labTest} />
            </BodyText>
          }
        />

        <AboutThisTestColumn
          icon={<CollectionDayIcon viewBox="0 0 17 13" />}
          title="Number of Collection Days"
          content={
            <BodyText weight="semibold">
              <CollectionDaysColumn labTest={labTest} />
            </BodyText>
          }
        />

        <AboutThisTestColumn
          icon={<CheckMarkCogIcon viewBox="0 0 17 18" />}
          title="Certifications"
          content={
            <BodyText weight="semibold">
              <CertificationsColumn labCompany={labCompany} />
            </BodyText>
          }
          overrideStyle={{ position: "relative", top: -4 }}
        />

        <BodyText className={classes.biomarkerTitle}>
          <span style={{ marginRight: 6, position: "relative", top: 4 }}>
            {<BiomarkerIcon viewBox="0 0 13 16" />}
          </span>
          <span>Biomarkers</span>
        </BodyText>
        <div className={classes.biomarkersContainer}>
          {sortedBiomarkers.map((biomarker) => (
            <div key={biomarker?.id} className={classes.biomarker}>
              <CheckCircleIcon
                fill={colors.green[500]}
                viewBox="0 0 15 16"
                style={{ position: "relative", top: 3, marginRight: 2 }}
              />{" "}
              <BodyText>{biomarker?.attributes.long_name}</BodyText>
            </div>
          ))}
        </div>
      </div>
    </>
  )
}

export default AboutThisTest
