import { styled } from "@material-ui/core"

import ArrowTopRight from "app/assets/icons/arrow-top-right.svg"
import CalendarIcon from "app/assets/images/calendar-icon.svg"
import BodyText from "app/components/design-system/BodyText"
import useOpenCheatsheet from "app/patient-portal/order/hooks/use-open-cheatsheet"
import { colors } from "app/theme"
import { PatientInstructionTestCompletionInstructions } from "app/types"

interface Props {
  patientInstruction: PatientInstructionTestCompletionInstructions
}

const TestCompletionInstruction = styled("div")({
  display: "flex",
  flexDirection: "column",

  "&:not(:first-child)": {
    borderTop: `1px solid ${colors.blueGray[200]}`,
    paddingTop: 20,
  },
})

const ExpectedDate = styled("div")({
  marginTop: 5,
  display: "flex",
  alignItems: "center",
})

const SeeInstructionsIcon = styled("img")({
  marginLeft: 4,
})

const ExpectedDateIcon = styled("img")({
  marginRight: 4,
})

const CheatsheetUrl = ({ cheatsheetUrl }: { cheatsheetUrl: string }) => {
  const openCheatsheet = useOpenCheatsheet(cheatsheetUrl)

  return (
    <div className="mt-1" role="button" onClick={openCheatsheet}>
      <BodyText className="inline text-primary" weight="semibold">
        See Instructions
      </BodyText>
      <SeeInstructionsIcon src={ArrowTopRight} alt="" />
    </div>
  )
}

const TestCompletionInstructions = ({ patientInstruction }: Props) => {
  return (
    <div className="w-full flex flex-col gap-5">
      {patientInstruction.lab_test_completion_instructions.map(
        (testCompletionInstruction) => {
          return (
            <TestCompletionInstruction key={testCompletionInstruction.title}>
              <BodyText weight="semibold" size="md">
                {testCompletionInstruction.title}
              </BodyText>
              {testCompletionInstruction.estimated_arrival_date && (
                <ExpectedDate>
                  <ExpectedDateIcon src={CalendarIcon} alt="" />
                  <BodyText weight="regular" size="base">
                    {testCompletionInstruction.estimated_arrival_date}
                  </BodyText>
                </ExpectedDate>
              )}
              <CheatsheetUrl
                cheatsheetUrl={testCompletionInstruction.cheatsheet_url}
              />
            </TestCompletionInstruction>
          )
        }
      )}
    </div>
  )
}
export default TestCompletionInstructions
