import { Dispatch } from "react"

import { AnyLabTest } from "app/types"

export const ComparisonCallToActionTypes = {
  ADD_TO_CART: "add_to_cart",
  START_ORDER: "start_order",
} as const

export type ComparisonCallToActionTypesUnion =
  typeof ComparisonCallToActionTypes[keyof typeof ComparisonCallToActionTypes]

/**
 * The shape of the comparison call to action context.
 */
export interface ComparisonCallToActionContextShape {
  /**
   * The type of call to action button to render for each compared test.
   */
  callToAction: ComparisonCallToActionTypesUnion
  /**
   * The callback executed when the call to action is clicked.
   */
  onCallToAction: (labTest: AnyLabTest) => void
  /**
   * Optional orderId to use for ordering rights.
   */
  orderId?: string
}

export interface ComparisonState {
  /**
   * A fetch error, if it occurred.
   */
  error?: Error
  /**
   * Whether or not the comparison modal is active.
   */
  isComparisonModalActive: boolean
  /**
   * Whether or not the lab test data is loading.
   */
  isLoading: boolean
  /**
   * Whether or not the search modal is active.
   */
  isSearchModalActive: boolean
  /**
   * Table of lab tests indexed by ID, if they exist.
   */
  labTestById: Record<string, AnyLabTest | undefined>
  /**
   * The list of lab tests to compare.
   */
  labTestIds: string[]
}

export const ActionTypes = {
  OPEN_COMPARISON_MODAL: "OPEN_COMPARISON_MODAL",
  CLOSE_COMPARISON_MODAL: "CLOSE_COMPARISON_MODAL",
  OPEN_SEARCH_MODAL: "OPEN_SEARCH_MODAL",
  CLOSE_SEARCH_MODAL: "CLOSE_SEARCH_MODAL",
  ADD_COMPARED_TEST: "ADD_COMPARED_TEST",
  REMOVE_COMPARED_TEST: "REMOVE_COMPARED_TEST",
  SET_COMPARED_TESTS: "SET_COMPARED_TESTS",
  FETCH_TESTS_REQUEST: "FETCH_TESTS_REQUEST",
  FETCH_TESTS_SUCCESS: "FETCH_TESTS_SUCCESS",
  FETCH_TESTS_FAILURE: "FETCH_TESTS_FAILURE",
} as const

export type ActionTypesUnion = typeof ActionTypes[keyof typeof ActionTypes]

export interface ComparisonAction<T = ActionTypesUnion> {
  type: T
}

export interface OpenComparisonModalAction
  extends ComparisonAction<typeof ActionTypes.OPEN_COMPARISON_MODAL> {
  payload: {
    labTestId?: string
    labTest?: AnyLabTest
  }
}

export interface CloseComparisonModalAction
  extends ComparisonAction<typeof ActionTypes.CLOSE_COMPARISON_MODAL> {}

export interface OpenSearchModalAction
  extends ComparisonAction<typeof ActionTypes.OPEN_SEARCH_MODAL> {}

export interface CloseSearchModalAction
  extends ComparisonAction<typeof ActionTypes.CLOSE_SEARCH_MODAL> {}

export interface AddComparedTestAction
  extends ComparisonAction<typeof ActionTypes.ADD_COMPARED_TEST> {
  payload: {
    labTestId: string
    labTest?: AnyLabTest
  }
}

export interface RemoveComparedTestAction
  extends ComparisonAction<typeof ActionTypes.REMOVE_COMPARED_TEST> {
  payload: string
}

export interface SetComparedTestsAction
  extends ComparisonAction<typeof ActionTypes.SET_COMPARED_TESTS> {
  payload: string[]
}

export interface FetchTestsRequestAction
  extends ComparisonAction<typeof ActionTypes.FETCH_TESTS_REQUEST> {}
export interface FetchTestsSuccessAction
  extends ComparisonAction<typeof ActionTypes.FETCH_TESTS_SUCCESS> {
  payload: AnyLabTest[]
}
export interface FetchTestsFailureAction
  extends ComparisonAction<typeof ActionTypes.FETCH_TESTS_FAILURE> {
  payload: Error
}

export type ComparisonActionUnion =
  | OpenComparisonModalAction
  | CloseComparisonModalAction
  | OpenSearchModalAction
  | CloseSearchModalAction
  | AddComparedTestAction
  | RemoveComparedTestAction
  | SetComparedTestsAction
  | FetchTestsRequestAction
  | FetchTestsSuccessAction
  | FetchTestsFailureAction

export type ComparisonDispatch = Dispatch<ComparisonActionUnion>

export type ComparisonContextShape = [ComparisonState, ComparisonDispatch]
