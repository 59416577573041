import { Control, useController } from "react-hook-form"

import { Checkbox, FormControl, FormControlLabel } from "@material-ui/core"

import Tooltip from "app/components/Tooltip"
import { textPrimaryColor } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"

const useStyles = makeAppStyles((theme) => ({
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  label: {
    display: "inline-flex",
    flexFlow: "row nowrap",
    alignItems: "center",
    gap: theme.spacing(0.5),
    fontSize: 14,
    fontWeight: 600,
    color: textPrimaryColor,
    "& > svg": {
      fill: "currentColor",
    },
  },
  container: {
    padding: "8px 0",
  },
  checkbox: {
    padding: "0 9px",
    marginRight: -4,
  },
}))

export interface FoodPlanTextCheckboxProps {
  control: Control<any>
  disabled?: boolean
  label?: string
  name: string
  tooltip?: string
}

/**
 * The text area field component for the food plan form.
 */
export default function FoodPlanTextCheckbox({
  control,
  disabled,
  label,
  name,
  tooltip,
}: FoodPlanTextCheckboxProps) {
  const classes = useStyles()
  const { field } = useController({
    control,
    name,
    shouldUnregister: true,
  })

  const checkbox = (
    <Checkbox
      disabled={disabled}
      checked={field.value}
      {...field}
      color="primary"
      className={classes.checkbox}
    />
  )
  const Container = ({ children }) =>
    disabled && tooltip ? (
      <Tooltip placement="bottom" title={tooltip}>
        {children}
      </Tooltip>
    ) : (
      <>{children}</>
    )

  return (
    <div className={classes.root}>
      <FormControl className={classes.container}>
        <Container>
          <FormControlLabel
            className={classes.label}
            control={checkbox}
            label={label}
            classes={{ label: classes.label }}
          />
        </Container>
      </FormControl>
    </div>
  )
}
