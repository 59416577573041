import { LAB_COMPANY_ACCOUNT_STATUS } from "app/constants"
import useCachedCollection from "app/swr/hooks/use-cached-collection"
import useCollectionSWR from "app/swr/hooks/use-collection-swr"
import { LabCompany } from "types/lab-company"

import { LabCompanyAccount } from "../types"

export default function useLabCompanyAccounts() {
  const {
    data: labCompanyAccountIdentifiers,
    isLoading: isLoadingLabCompanyAccounts,
    mutate: mutateLabCompanyAccounts,
  } = useCollectionSWR("/lab_company_account", {
    include: ["lab_companies"],
  })
  const labCompanyAccounts = useCachedCollection<LabCompanyAccount>(
    labCompanyAccountIdentifiers
  )

  const companiesWithAccounts = useCachedCollection<LabCompany>(
    labCompanyAccounts.flatMap(
      (labCompanyAccount) => labCompanyAccount.relationships.lab_companies.data
    )
  )

  const companiesWithConfirmedAccounts = useCachedCollection<LabCompany>(
    labCompanyAccounts
      .filter(
        (labCompanyAccount) =>
          labCompanyAccount.attributes.status ===
          LAB_COMPANY_ACCOUNT_STATUS.CONFIRMED
      )
      .flatMap(
        (labCompanyAccount) =>
          labCompanyAccount.relationships.lab_companies.data
      )
  )

  return {
    labCompanyAccountIdentifiers,
    labCompanyAccounts,
    isLoadingLabCompanyAccounts,
    mutateLabCompanyAccounts,
    companiesWithAccounts,
    companiesWithConfirmedAccounts,
  }
}
