import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react"

import { uniqBy } from "lodash"
import { KeyedMutator } from "swr"

import { Practitioner } from "app/types"

import { requiresActivation } from "../utils"
import { FilterOption, InventoryItem } from "./inventory-types"
import useClinicInventory from "./use-clinic-inventory"

// Data provider for the Kit Inventory tab
interface InventoryContextProps {
  practitioner: Practitioner
  // The filtered data based on current filters
  filteredData: InventoryItem[]
  // The total count of unfiltered items
  count: number | undefined
  isLoading: boolean
  isValidating: boolean
  mutate: KeyedMutator<InventoryItem[] | undefined>
  productOptions: FilterOption[]
  productFilter: FilterOption | null
  setProductFilter: (product: FilterOption | null) => void
  labCompanyOptions: FilterOption[]
  labCompanyFilter: FilterOption | null
  setLabCompanyFilter: (labCompany: FilterOption | null) => void
  // If any of the inventory items require activation
  includesActivatableKits: boolean
}

const InventoryContext = createContext<InventoryContextProps>(
  undefined as never
)

interface Props {
  children: React.ReactNode
  practitioner: Practitioner
}

function InventoryProvider({ children, practitioner }: Props) {
  const { data, isLoading, isValidating, mutate } = useClinicInventory(
    practitioner.clinic.id
  )

  const includesActivatableKits = useMemo(() => {
    return data?.some((i) => requiresActivation(i.lab_company_key)) || false
  }, [data])

  const [productFilter, setProductFilter] = useState<FilterOption | null>(null)
  const [labCompanyFilter, setLabCompanyFilter] = useState<FilterOption | null>(
    null
  )

  const productOptions = useMemo(() => {
    return (
      uniqBy(data, "in_office_kit_product_id").map((i) => ({
        id: i.in_office_kit_product_id,
        label: i.in_office_kit_product_name,
      })) || []
    )
  }, [data])

  const labCompanyOptions = useMemo(() => {
    return (
      uniqBy(data, "lab_company_key").map((i) => ({
        id: i.lab_company_key,
        label: i.lab_company_name,
      })) || []
    )
  }, [data])

  const dataFilterer = useCallback(
    (item: InventoryItem) => {
      if (productFilter && item.in_office_kit_product_id !== productFilter.id) {
        return false
      }
      if (labCompanyFilter && item.lab_company_key !== labCompanyFilter.id) {
        return false
      }
      return true
    },
    [productFilter, labCompanyFilter]
  )

  const filteredData = useMemo(() => {
    return data?.filter((i) => dataFilterer(i)) || []
  }, [data, dataFilterer])

  return (
    <InventoryContext.Provider
      value={{
        practitioner,
        filteredData,
        count: data?.length,
        isLoading,
        isValidating,
        mutate,
        productOptions,
        productFilter,
        setProductFilter,
        labCompanyOptions,
        labCompanyFilter,
        setLabCompanyFilter,
        includesActivatableKits,
      }}
    >
      {children}
    </InventoryContext.Provider>
  )
}

export function useIOKInventory() {
  return useContext(InventoryContext)
}

export default InventoryProvider
