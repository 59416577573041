import { Editor } from "slate"

import { CustomTextMarks } from "custom-types/slate"

export function toggleMark(editor: Editor, format: CustomTextMarks) {
  const isActive = isMarkActive(editor, format)

  if (isActive) {
    Editor.removeMark(editor, format)
  } else {
    if (format === "hyperlink") {
      Editor.addMark(editor, format, prompt("Enter a URL"))
    } else {
      Editor.addMark(editor, format, true)
    }
  }
}

export function isMarkActive(editor: Editor, format: CustomTextMarks) {
  try {
    const marks = Editor.marks(editor)
    return Boolean(marks?.[format])
  } catch (error) {
    return false
  }
}
