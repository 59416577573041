import { ReactComponent as PriceTagIcon } from "app/assets/icons/price-tag-dark.svg"
import { ReactComponent as LockIcon } from "app/assets/images/lock.svg"
import { isUserGuest } from "app/auth/util"
import useAppSelector from "app/hooks/useAppSelector"
import {
  ComparisonRow,
  ComparisonColumnProps,
  EmptyComparisonValue,
} from "app/main/comparison/components/ComparisonLabTests/components"
import { marketingSite } from "app/settings"
import { colors, textPrimaryColor } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"

const usePriceColumnStyles = makeAppStyles((theme) => ({
  msrpPrice: {
    color: colors.gray[400],
    textDecorationLine: "line-through",
    marginRight: theme.spacing(0.5),
  },
  signUpLink: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(0.5),
    color: textPrimaryColor,
    textDecoration: "none",
    fontWeight: theme.typography.fontWeightMedium,
    "& > svg": {
      width: 18,
      height: 18,
      fill: "currentColor",
      verticalAlign: "middle",
    },
  },
}))

const PriceColumn = ({ labTest }: ComparisonColumnProps) => {
  const classes = usePriceColumnStyles()
  const user = useAppSelector(({ auth }) => auth.user)
  const isGuest = isUserGuest(user)
  if (isGuest) {
    return (
      <a className={classes.signUpLink} href={`${marketingSite}/sign-up`}>
        <LockIcon viewBox="0 0 16 15" /> Sign up to View
      </a>
    )
  }
  return (
    <>
      {labTest?.rupa_price ? (
        <>
          {labTest.msrp_price !== labTest.rupa_price && (
            <span className={classes.msrpPrice}>${labTest.msrp_price}</span>
          )}
          <span>${labTest.rupa_price}</span>
        </>
      ) : (
        <EmptyComparisonValue />
      )}
    </>
  )
}

export default function ComparisonPricingRow() {
  return (
    <ComparisonRow
      Column={PriceColumn}
      headerIcon={<PriceTagIcon viewBox="0 0 17 17" />}
      headerLabel="Price"
    />
  )
}
