import { faCircleQuestion } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button, cn } from "@rupahealth/design"
import {
  CellContext,
  ColumnDef,
  HeaderContext,
  createColumnHelper,
} from "@tanstack/react-table"

import { ReactComponent as CubeIcon } from "app/assets/icons/cube.svg"
import { ReactComponent as TestTubeIcon } from "app/assets/icons/test-tubes-small.svg"
import Tooltip from "app/components/Tooltip"
import useOpenCheatsheet from "app/patient-portal/order/hooks/use-open-cheatsheet"

import { Filter } from "../components/filter"
import { INTERCOM_IOK_LEARN_MORE_LINK } from "../utils"
import ActivateKitModalButton from "./ActivateKitModalButton"
import ActivationCodesModalButton from "./ActivationCodesModalButton"
import { useIOKInventory } from "./InventoryDataProvider"
import ReStockKitsModalButton from "./ReStockKitsModalButton"
import { InventoryItem } from "./inventory-types"

const columnHelper = createColumnHelper<InventoryItem>()

// Inventory Name
const InventoryNameCell = ({
  row: { original: item },
}: CellContext<InventoryItem, any>) => {
  return (
    <>
      <div className="font-semibold text-base15">
        {item.in_office_kit_product_name}
      </div>
      {item.activation_id_count > 0 && (
        <ActivationCodesModalButton inventoryItem={item} />
      )}
    </>
  )
}

const InventoryNameHeader = (_info: HeaderContext<InventoryItem, any>) => {
  const { productOptions, productFilter, setProductFilter } = useIOKInventory()
  return (
    <Filter
      options={productOptions}
      value={productFilter}
      onChange={(_e, newValue) => setProductFilter(newValue)}
      placeholder="Lab Kit"
      getOptionLabel={(opt) => opt?.label}
      noOptionsText="No Kit Found"
    />
  )
}

export const InventoryNameColumn: ColumnDef<InventoryItem, any> =
  columnHelper.display({
    id: "inventory_name",
    header: InventoryNameHeader,
    cell: InventoryNameCell,
    meta: { cellStyle: { paddingLeft: "26px" } },
  })

// Lab Company
const LabCompanyNameHeader = (_info: HeaderContext<InventoryItem, any>) => {
  const { labCompanyOptions, labCompanyFilter, setLabCompanyFilter } =
    useIOKInventory()
  return (
    <Filter
      options={labCompanyOptions}
      value={labCompanyFilter}
      onChange={(_e, newValue) => setLabCompanyFilter(newValue)}
      placeholder="Lab Company"
      getOptionLabel={(opt) => opt?.label}
      noOptionsText="No Kits for this Lab"
      selectIcon
    />
  )
}

const LabCompanyNameCell = ({
  row: { original: item },
}: CellContext<InventoryItem, any>) => {
  return (
    <div className="font-semibold text-base15">{item.lab_company_name}</div>
  )
}

export const LabCompanyNameColumn: ColumnDef<InventoryItem, any> =
  columnHelper.display({
    id: "lab_company_name",
    header: LabCompanyNameHeader,
    cell: LabCompanyNameCell,
  })

// Stock
const StockHeader = (_info: HeaderContext<InventoryItem, any>) => {
  const { includesActivatableKits } = useIOKInventory()
  const openCheatsheet = useOpenCheatsheet(INTERCOM_IOK_LEARN_MORE_LINK)
  if (includesActivatableKits) {
    return (
      <Tooltip
        arrow
        interactive
        title={
          <span className="font-normal">
            Only some labs offer an 'On the way' status.{" "}
            <Button
              type="button"
              variant="ghost"
              className="text-primary text-sm py-0 px-1 h-auto"
              onClick={openCheatsheet}
            >
              Learn More
            </Button>
          </span>
        }
      >
        <div className="inline-block">
          <span className="font-normal flex items-center gap-1">
            Stock{" "}
            <FontAwesomeIcon
              icon={faCircleQuestion}
              className="text-gray-400 text-xs"
            />
          </span>
        </div>
      </Tooltip>
    )
  }
  return <span className="font-normal">Stock</span>
}

const StockCell = ({
  row: {
    original: { num_in_stock, num_pre_stock },
  },
}: CellContext<InventoryItem, any>) => {
  return (
    <div className="flex flex-wrap md:flex-nowrap gap-[6px]">
      <div className="table whitespace-nowrap">
        <span
          className={cn(
            "rounded-[39px] py-[2px] px-3 font-semibold flex items-center gap-1",
            {
              "text-green-800 bg-green-100": num_in_stock > 0,
              "text-rose-700 bg-rose-100": num_in_stock === 0,
            }
          )}
        >
          <TestTubeIcon
            className={cn("pt-[1px]", {
              "fill-green-800": num_in_stock > 0,
              "fill-rose-700": num_in_stock === 0,
            })}
            height={15}
          />

          <span>{num_in_stock} in stock</span>
        </span>
      </div>
      {num_pre_stock > 0 && (
        <div className="table whitespace-nowrap">
          <span className="rounded-[39px] py-[2px] px-3 font-semibold flex items-center gap-1 text-sky-700 bg-sky-100">
            <CubeIcon className="fill-sky-700 pt-[1px]" height={15} />
            <span>{num_pre_stock} on the way</span>
          </span>
        </div>
      )}
    </div>
  )
}

export const StockColumn: ColumnDef<InventoryItem, any> = columnHelper.display({
  id: "num_in_stock",
  header: StockHeader,
  cell: StockCell,
})

// Actions / Buttons
const ActionCell = ({
  row: { original: item },
}: CellContext<InventoryItem, any>) => {
  if (item.num_in_stock === 0) {
    return <ReStockKitsModalButton inventoryItem={item} />
  } else if (item.num_in_stock > 0) {
    return <ActivateKitModalButton inventoryItem={item} />
  }
}

export const ActionsColumn: ColumnDef<InventoryItem, any> =
  columnHelper.display({
    id: "actions",
    cell: ActionCell,
    meta: { cellStyle: { textAlign: "right", paddingRight: "26px" } },
  })
