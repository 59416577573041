import clsx from "clsx"

import { CircularProgress } from "@material-ui/core"
import { CellContext, HeaderContext } from "@tanstack/react-table"

import { ReactComponent as OrderCollapsedIcon } from "app/assets/icons/dashboard-order-collapsed.svg"
import { ReactComponent as OrderExpandedIcon } from "app/assets/icons/dashboard-order-expanded.svg"
import makeAppStyles from "app/utils/makeAppStyles"
import { DashboardOrder } from "types/dashboard/dashboard-order"

import columnHelper from "./column-helper"

const useStyles = makeAppStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  expandIcon: {
    width: "100%",
    height: "100%",
    padding: "0",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #CBD5E1",
    boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.07)",
    borderRadius: "4px",
  },
  expandIconContainer: {
    width: "16px",
    height: "16px",
    backgroundColor: "white",
  },
  expandIconContainerHidden: {
    visibility: "hidden",
  },
}))

export const UtilityHeader = ({
  table,
}: HeaderContext<DashboardOrder, unknown>) => {
  const classes = useStyles()

  if (table.options.meta?.isValidating) {
    return (
      <div className={classes.root}>
        <CircularProgress size={16} />
      </div>
    )
  }

  return null
}

export const UtilityCell = ({ row }: CellContext<DashboardOrder, unknown>) => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <div
        className={clsx(classes.expandIconContainer, {
          [classes.expandIconContainerHidden]: !row.getCanExpand(),
        })}
      >
        <div className={classes.expandIcon}>
          {row.getIsExpanded() ? (
            <OrderExpandedIcon viewBox="0 0 10 2" />
          ) : (
            <OrderCollapsedIcon viewBox="0 0 10 9" />
          )}
        </div>
      </div>
    </div>
  )
}

const utilityColumn = columnHelper.display({
  cell: (info) => <UtilityCell {...info} />,
  header: (info) => <UtilityHeader {...info} />,
  id: "utility",
  size: 42,
  maxSize: 42,
  minSize: 42,
  meta: {
    cellStyle: {
      padding: 0,
    },
  },
})

export default utilityColumn
