import { useContext } from "react"

import { styled } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

import { UserPaths } from "app/Routes"
import BankIcon from "app/assets/bank-nav-icon-dark.svg"
import BankIconActive from "app/assets/bank-nav-icon-teal.svg"
import AccountSparkleIconActive from "app/assets/icons/account-sparkle-active.svg"
import AccountSparkleIcon from "app/assets/icons/account-sparkle.svg"
import BundleIconActive from "app/assets/icons/bundle-blue2.svg"
import BundleIcon from "app/assets/icons/bundle-grey.svg"
import ClinicIconActive from "app/assets/icons/clinic-blue.svg"
import ClinicIcon from "app/assets/icons/clinic.svg"
import NotesIconActive from "app/assets/icons/notes-blue.svg"
import NotesIcon from "app/assets/icons/notes.svg"
import PhlebotomistsIconActive from "app/assets/icons/phlebotomists-blue.svg"
import PhlebotomistsIcon from "app/assets/icons/phlebotomists.svg"
import RupaBloodDashboardSidebarActiveIcon from "app/assets/icons/rupa-blood-dashboards/sidebar-active-icon.svg"
import RupaBloodDashboardSidebarIcon from "app/assets/icons/rupa-blood-dashboards/sidebar-icon.svg"
import CheckedShieldActive from "app/assets/icons/settings/checked-shield-active.svg"
import CheckedShield from "app/assets/icons/settings/checked-shield.svg"
import NotificationBellActive from "app/assets/icons/settings/notification-bell-active.svg"
import NotificationBell from "app/assets/icons/settings/notification-bell.svg"
import TeamMembersIconActive from "app/assets/icons/team-members-blue.svg"
import TeamMembersIcon from "app/assets/icons/team-members.svg"
import NavBarItem from "app/components/NavBar/NavBarItem"
import PageToolbar from "app/components/PageToolbar"
import BodyText from "app/components/design-system/BodyText"
import useAppSelector from "app/hooks/useAppSelector"
import {
  FeatureFlag,
  FeatureFlagContext,
} from "app/providers/FeatureFlagProvider"
import { colors, userNavBarMobileHeight } from "app/theme"
import { ClinicFeatures } from "app/types"

const StyledBodyText = styled(BodyText)({
  padding: 12,
  margin: 10,
})

const useStyles = makeStyles((theme) => ({
  container: {
    background: "white",
    height: "100vh",
    [theme.breakpoints.down("xs")]: {
      height: `calc(100vh - ${userNavBarMobileHeight}px)`,
    },
    [theme.breakpoints.down("sm")]: {
      overflowY: "scroll",
    },
  },
  wrapper: {
    background: "white",
    border: `1px solid ${colors.coolGray[200]}`,
    borderBottom: "none",

    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.up("md")]: {
      position: "fixed",
      overflowY: "scroll",
      width: "calc(25% - 19px)",
      height: "calc(100vh - 77.5px)",
    },
    [theme.breakpoints.up("lg")]: {
      width: "calc(25% - 66px)",
    },
  },
}))

export default function SideNav() {
  const classes = useStyles()
  const [featureFlags] = useContext(FeatureFlagContext)
  const isClinicNotificationsEnabled =
    featureFlags[FeatureFlag.ClinicNotificationPreferences]
  const isConnectedAccountSettingsPageEnabled =
    featureFlags[FeatureFlag.ConnectedAccountSettingsPage]
  const isRupaBloodReportsEnabled = featureFlags[FeatureFlag.RupaBloodReports]

  const practitioner = useAppSelector(({ practitioner }) => practitioner)

  return (
    <>
      <PageToolbar title="Settings" fullBorder={true} />
      <div className={classes.container}>
        <div className={classes.wrapper}>
          <StyledBodyText weight="semibold">Personal</StyledBodyText>
          <NavBarItem
            to={UserPaths.ACCOUNT}
            icon={AccountSparkleIcon}
            activeIcon={AccountSparkleIconActive}
            isInsideSettings={true}
          >
            Personal Settings
          </NavBarItem>
          <NavBarItem
            icon={BundleIcon}
            activeIcon={BundleIconActive}
            to={UserPaths.BUNDLES}
            isInsideSettings={true}
          >
            Custom Test Bundles
          </NavBarItem>
          <NavBarItem
            icon={NotesIcon}
            activeIcon={NotesIconActive}
            to={UserPaths.SNIPPETS}
            isInsideSettings={true}
          >
            Saved Text Snippets
          </NavBarItem>
          {isConnectedAccountSettingsPageEnabled && (
            <NavBarItem
              icon={CheckedShield}
              activeIcon={CheckedShieldActive}
              to={UserPaths.INSURANCE_ACCOUNT_SETTINGS}
              isInsideSettings={true}
            >
              Insurance Order Labs
            </NavBarItem>
          )}
          <StyledBodyText weight="semibold">Clinic</StyledBodyText>
          <NavBarItem
            icon={ClinicIcon}
            activeIcon={ClinicIconActive}
            to={UserPaths.CLINIC}
            isInsideSettings={true}
          >
            Clinic Settings
          </NavBarItem>
          <NavBarItem
            icon={TeamMembersIcon}
            activeIcon={TeamMembersIconActive}
            to={UserPaths.TEAM}
            isInsideSettings={true}
          >
            Team Members
          </NavBarItem>
          {isClinicNotificationsEnabled && (
            <NavBarItem
              icon={NotificationBell}
              activeIcon={NotificationBellActive}
              to={UserPaths.NOTIFICATIONS}
              isInsideSettings={true}
            >
              Notifications
            </NavBarItem>
          )}
          <NavBarItem
            icon={BankIcon}
            activeIcon={BankIconActive}
            to={UserPaths.BILLING_AND_CUSTOM_FEES}
            isInsideSettings={true}
          >
            Billing & Custom Fees
          </NavBarItem>
          <NavBarItem
            icon={PhlebotomistsIcon}
            activeIcon={PhlebotomistsIconActive}
            to={UserPaths.PHLEBOTOMISTS}
            isInsideSettings={true}
          >
            Preferred Phlebotomists
          </NavBarItem>
          {isRupaBloodReportsEnabled &&
            practitioner?.clinic?.clinic_features.includes(
              ClinicFeatures.RUPA_BLOOD_REPORTS
            ) && (
              <NavBarItem
                icon={RupaBloodDashboardSidebarIcon}
                activeIcon={RupaBloodDashboardSidebarActiveIcon}
                to={UserPaths.RUPA_BLOOD_DASHBOARDS}
                isInsideSettings={true}
              >
                Results Visualization
              </NavBarItem>
            )}
        </div>
      </div>
    </>
  )
}
