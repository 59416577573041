import { useMemo } from "react"

import { sortBy, values } from "lodash"

import { Biomarker } from "app/types"

import { ComparisonState } from "../types"
import useComparisonState from "./use-comparison-state"

export interface BiomarkerWithIncludedLabTestIds {
  biomarker: Biomarker
  includedLabTestIds: string[]
}

export function useAllBiomarkersForComparisonState({
  labTestIds,
  labTestById,
}: ComparisonState) {
  return useMemo(() => {
    const allBiomarkersById = labTestIds.reduce(
      (prevBiomarkersById, labTestId) => {
        const labTest = labTestById[labTestId]
        if (!labTest?.biomarkers?.length) {
          return prevBiomarkersById
        }
        return labTest.biomarkers.reduce((nextBiomarkersById, biomarker) => {
          return {
            ...nextBiomarkersById,
            [biomarker.id]: {
              biomarker,
              includedLabTestIds: [
                ...(nextBiomarkersById[biomarker.id]?.includedLabTestIds || []),
                labTest.id,
              ],
            },
          }
        }, prevBiomarkersById)
      },
      {} as { [id: Biomarker["id"]]: BiomarkerWithIncludedLabTestIds }
    )

    return sortBy(
      values(allBiomarkersById),
      (biomarker) =>
        // Ensure uppercase and lowercase are grouped.
        `${biomarker.biomarker.long_name[0].toUpperCase()}${
          biomarker.biomarker.long_name
        }`
    )
  }, [labTestIds, labTestById])
}

/**
 * Gets biomarkers with available lab test IDs for all compared lab tests
 *
 * @returns a list of all biomarkers with available labtests sorted by name
 */
export default function useAllBiomarkersForComparedLabTests() {
  const state = useComparisonState()
  return useAllBiomarkersForComparisonState(state)
}
