import { useFormContext } from "react-hook-form"

import {
  FormHelperText,
  Typography,
  makeStyles,
  Button,
} from "@material-ui/core"

import { ReactComponent as CheckCircleIcon } from "app/assets/images/circular-check-filled.svg"
import useFeatureFlag, { FeatureFlag } from "app/hooks/use-feature-flag"
import useAppSelector from "app/hooks/useAppSelector"
import { FieldNames } from "app/main/patient-checkout/fields"
import useAchFields from "app/main/patient-checkout/hooks/use-ach-fields"
import PaymentMethodBase from "app/main/patient-checkout/sections/payment-methods/PaymentMethodBase"
import { selectOrderState } from "app/main/patient-checkout/store/selectors"
import { colors, lightGray, navy, primaryColor } from "app/theme"
import { AchConnectionStatus, PaymentMethodType } from "app/types"

interface AchPaymentMethodProps {
  active: boolean
  paymentMethodError?: string
  disabled: boolean
}

const AchPaymentMethod = ({
  active,
  paymentMethodError,
  disabled,
}: AchPaymentMethodProps) => {
  const classes = useStyles()

  const { status, openPlaid } = useAchFields()
  const { clearErrors } = useFormContext()

  return (
    <PaymentMethodBase
      active={active}
      className={classes.wrapper}
      header={<AchHeader />}
      title="Pay in Full via ACH"
      type={PaymentMethodType.ACH}
      disabled={disabled}
    >
      <div className={classes.expansion}>
        {(status === AchConnectionStatus.INCOMPLETE ||
          status === AchConnectionStatus.NOT_STARTED) && (
          <Button
            size="medium"
            color="primary"
            onClick={() => {
              clearErrors(FieldNames.PAYMENT_METHOD_TYPE)
              openPlaid && openPlaid()
            }}
            className={classes.connectButton}
            variant="contained"
          >
            {status === AchConnectionStatus.NOT_STARTED
              ? "Connect Bank Account"
              : "Finish Connecting Bank Account"}
          </Button>
        )}
        {status === AchConnectionStatus.COMPLETE && (
          <div className={classes.completeDiv}>
            <CheckCircleIcon
              fill={colors.green[500]}
              width={21}
              height={21}
              viewBox="0 0 15 16"
            />
            <Typography component="span" className={classes.connectedText}>
              Your bank will be used for payment.
            </Typography>
          </div>
        )}
        {paymentMethodError && (
          <FormHelperText error>{paymentMethodError}</FormHelperText>
        )}
      </div>
    </PaymentMethodBase>
  )
}

const AchHeader = () => {
  const classes = useStyles()
  const order = useAppSelector(selectOrderState).order
  const creditCardFee = order?.credit_card_fee_price
  const [creditCardFeeEnabled] = useFeatureFlag(
    FeatureFlag.CreditCardFeeCheckoutEnabled
  )

  return (
    <div className={classes.headerWrapper}>
      <Typography
        className={classes.headerTitle}
        color="primary"
        component="span"
      >
        Pay in Full via ACH Bank Transfer
      </Typography>
      {creditCardFeeEnabled && creditCardFee && creditCardFee > 0 && (
        <div className={classes.savePill}>
          <Typography className={classes.savePillText} component="span">
            Save ${creditCardFee}
          </Typography>
        </div>
      )}
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  wrapper: {
    borderTopWidth: "1px",
    borderColor: colors.gray[300],
    borderBottomRightRadius: "8px",
    borderBottomLeftRadius: "8px",
  },
  headerWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    alignItems: "center",
  },
  savePill: {
    backgroundColor: colors.blueGray[100],
    borderRadius: 9999, // makes the border radius equal to half of height
    padding: "5px 10px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  savePillText: {
    fontWeight: theme.typography.fontWeightMedium,
    textAlign: "center",
  },
  headerTitle: {
    fontWeight: theme.typography.fontWeightMedium,
  },
  headerSubtitle: {
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(0.4),
    },
  },
  expansion: {
    padding: "10px 15px",
    backgroundColor: lightGray,
  },
  connectButton: {
    backgroundColor: primaryColor,
    color: "white",
    padding: "5px 15px",
    borderRadius: 6,
  },
  errorText: {
    paddingTop: 5,
  },
  completeDiv: {
    display: "flex",
    flexDirection: "row",
    gap: 10,
  },
  connectedText: {
    color: navy,
  },
}))

export default AchPaymentMethod
