import _ from "lodash"

import { Grid } from "@material-ui/core"

import { ReactComponent as InfoIcon } from "app/assets/icons/info-dark-gray-circle.svg"
import { ReactComponent as CompanyIcon } from "app/assets/icons/lab-company.svg"
import { ReactComponent as PriceIcon } from "app/assets/icons/price-tag-dark.svg"
import PendingPlaceholder from "app/components/LabTests/PendingPlaceholder"
import BodyText from "app/components/design-system/BodyText"
import useCachedResource from "app/swr/hooks/use-cached-resource"
import { colors } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"
import { LabCompany } from "types/lab-company"
import { LabTest } from "types/lab-test"

const useStyles = makeAppStyles((theme) => ({
  container: {
    backgroundColor: "white",
    padding: 20,
  },
  description: {
    display: "flex",
    flexDirection: "row",
    gap: 10,
    paddingTop: 10,
    paddingBottom: 15,
    borderBottom: `1px solid ${colors.blueGray[200]}`,
  },
  companyOrPriceHeader: {
    paddingTop: 15,
  },
  companyOrPrice: {
    display: "flex",
    flexDirection: "row",
    paddingTop: 10,
    gap: 10,
  },
  prices: {
    display: "flex",
    flexDirection: "column",
    gap: 10,
  },
}))

export interface ComboGroupDetailsProps {
  targetLabTest?: LabTest
  availableTargets?: LabTest[]
  isLoading: boolean
}

export default function ComboGroupDetails({
  targetLabTest,
  availableTargets,
  isLoading,
}: ComboGroupDetailsProps) {
  const classes = useStyles()

  const labCompany = useCachedResource<LabCompany>(
    targetLabTest?.relationships.lab_company.data
  )

  const sortedTargets = _.sortBy(availableTargets, (target) =>
    Number(target.attributes.msrp_price)
  )

  return (
    <div className={classes.container}>
      <Grid item xs={12}>
        <BodyText size="sm">Details</BodyText>
        <div className={classes.description}>
          <InfoIcon width={16} height={16} viewBox="0 0 16 16" />
          {isLoading ? (
            <PendingPlaceholder width={200} />
          ) : (
            <BodyText size="sm" weight="semibold">
              {targetLabTest?.attributes.details ?? "--"}
            </BodyText>
          )}
        </div>
      </Grid>
      <Grid container>
        <Grid item xs={12} sm={6} className={classes.companyOrPriceHeader}>
          <BodyText size="sm">Company</BodyText>
          <div className={classes.companyOrPrice}>
            <CompanyIcon width={16} height={16} viewBox="0 0 13 12" />

            {isLoading ? (
              <PendingPlaceholder width={200} />
            ) : (
              <BodyText size="sm" weight="semibold">
                {labCompany?.attributes.name ?? "--"}
              </BodyText>
            )}
          </div>
        </Grid>
        <Grid item xs={12} sm={6} className={classes.companyOrPriceHeader}>
          <BodyText size="sm">Price</BodyText>
          <div className={classes.companyOrPrice}>
            <PriceIcon width={16} height={16} viewBox="0 0 18 19" />
            {isLoading ? (
              <PendingPlaceholder width={200} />
            ) : (
              <div className={classes.prices}>
                {sortedTargets?.map((target) => (
                  <BodyText size="sm">
                    {target.attributes.name} - ${target.attributes.rupa_price}
                  </BodyText>
                ))}
              </div>
            )}
          </div>
        </Grid>
      </Grid>
    </div>
  )
}
