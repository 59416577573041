import { useMemo } from "react"

import { get, isEmpty, isFunction, isNil, isObjectLike, isString } from "lodash"

import { AnyLabTest } from "app/types"

import useComparisonState from "./use-comparison-state"

/**
 * Returns whether or not the given property exists for any of the compared lab tests and has a non-empty value.
 *
 * @param name the name (or names) of the property(s) to check, or a function to check dynamically
 */
export default function useAnyComparedLabTestHasProperty(
  propToCheck: string | ((labTest: AnyLabTest) => boolean),
  ...additionalPropNames: string[]
) {
  const state = useComparisonState()
  return useMemo(() => {
    return state.labTestIds.some((labTestId) => {
      const labTest = state.labTestById[labTestId]
      if (!labTest) {
        return false
      }
      if (isFunction(propToCheck)) {
        return propToCheck(labTest)
      }
      return [propToCheck, ...additionalPropNames].some((propName) => {
        const value = get(labTest, propName)
        if (isNil(value)) {
          return false
        }
        if ((isObjectLike(value) || isString(value)) && isEmpty(value)) {
          return false
        }
        return true
      })
    })
  }, [...additionalPropNames, propToCheck, state.labTestIds, state.labTestById])
}
