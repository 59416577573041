export default class CheckoutValidationError extends Error {
  public validationErrors: Record<string, any>

  constructor(message: string, validationErrors: Record<string, any>) {
    super(message)
    this.name = "CheckoutValidationError"
    this.validationErrors = validationErrors

    Object.setPrototypeOf(this, CheckoutValidationError.prototype)
  }
}
