import { useHistory } from "react-router-dom"

import { makeStyles } from "@material-ui/core"

import { colors, shadows } from "app/theme"
import { AlertTypes, LabCompanyAlert, NoticeAlert } from "app/types"

import Alert from "./Alert"

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: 16,

    [theme.breakpoints.up("sm")]: {
      marginTop: 24,
    },
  },
  alerts: {
    display: "flex",
    alignItems: "stretch",
    flexFlow: "column",
    background: "white",
    border: `1px solid ${colors.blueGray[200]}`,
    boxShadow: shadows.default,
    borderRadius: 6,
  },
  alertContainer: {
    margin: "0 8px",
    "&:not(:last-child)": {
      borderBottom: `1px solid ${colors.blueGray[200]}`,
    },
  },
}))

interface AlertsListLegacyProps {
  notice: NoticeAlert | null
  labCompanyAlerts: LabCompanyAlert[]
}

export default function AlertsListLegacy({
  notice,
  labCompanyAlerts,
}: AlertsListLegacyProps) {
  const history = useHistory()
  const { container, alerts: alertsStyle, alertContainer } = useStyles()

  if (!notice && !labCompanyAlerts.length) {
    return null
  }

  return (
    <div className={container}>
      <div className={alertsStyle}>
        {notice && (
          <div className={alertContainer}>
            <Alert
              type={AlertTypes.NOTICE}
              title={notice.title}
              subtitle={notice.subtitle}
              linkUrl={notice.link_url}
              linkText={notice.link_text}
            />
          </div>
        )}

        {labCompanyAlerts.map((labCompanyAlert) => (
          <div className={alertContainer} key={labCompanyAlert.type}>
            <Alert
              type={labCompanyAlert.type}
              title={labCompanyAlert.title}
              subtitle={labCompanyAlert.subtitle}
              linkText="See Delayed Orders"
              linkOnClick={() => history.push({ search: "tab=alert" })}
              linkTarget="_self"
            />
          </div>
        ))}
      </div>
    </div>
  )
}
