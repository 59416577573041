import { useState } from "react"

import { DropdownSelectField } from "app/components/forms/DropdownSelectField"
import { FormFieldLabel } from "app/components/forms/fields"
import {
  LAB_COMPANY_ACTIVATION_ID_NAME_MAP,
  LAB_COMPANY_KEY,
} from "app/constants"
import useHandleApiError from "app/hooks/use-handle-api-error"
import resourceRequest from "app/swr/utils/resource-request"

import IOKActivationCodeInfoTooltip from "../in-office-kits/components/IOKActivationCodeInfoTooltip"
import { InOfficeKitType } from "../in-office-kits/types"

interface Props {
  inStockInOfficeKits: InOfficeKitType[] | null
  orderedTestIds: string[]
  labCompanyKey: string
  setKitActivationIdSelectedFor: (setter: (prev: string[]) => string[]) => void
}

const associateOrderedTestsToKit = async (
  selectedKit: string,
  orderedTestIds: string[],
  handleApiError: (error: any) => void
) => {
  try {
    await resourceRequest({
      url: `/in_office_kit/${selectedKit}/`,
      method: "PATCH",
      data: {
        data: {
          type: "in_office_kit",
          id: selectedKit,
          relationships: {
            ordered_tests: {
              meta: { count: orderedTestIds.length },
              data: orderedTestIds.map((id) => ({
                type: "ordered_test",
                id: id,
              })),
            },
          },
        },
      },
    })
  } catch (error: any) {
    if (error.response?.status === 404) {
      handleApiError(
        new Error(
          "We couldn't find a kit with this ID. Please confirm your kit was ordered on Rupa and matches your current test selection."
        )
      )
    }
  }
}

export default function IOKActivationDropdown({
  inStockInOfficeKits,
  orderedTestIds,
  labCompanyKey,
  setKitActivationIdSelectedFor,
}: Props) {
  const handleApiError = useHandleApiError()
  const [selectedKit, setSelectedKit] = useState<string | null>(null)

  const labCompanyActivationIdName =
    LAB_COMPANY_ACTIVATION_ID_NAME_MAP[labCompanyKey] || "Activation ID"

  const allowsUnknownActivationId = labCompanyKey === LAB_COMPANY_KEY.GENOVA

  const unknownActivationIdText = allowsUnknownActivationId
    ? "We don't have an ID on file for this kit. If this kit was ordered on Rupa, please send us a message and we'll add it to your account!"
    : "We don't have an ID on file for this kit. Please confirm your kit was ordered on Rupa and matches your current test selection."

  return (
    <div className="flex flex-col justify-between px-4 py-2 bg-gray-200">
      <div className="flex items-center justify-between">
        <FormFieldLabel label={labCompanyActivationIdName} required />
        <IOKActivationCodeInfoTooltip
          labCompanyKey={labCompanyKey}
          tooltipContentChildren={
            <p className="bg-gray-100 text-center italic mt-1 py-1 px-2 rounded">
              By completing this step on Rupa, your kit is activated!
            </p>
          }
          className="text-sm mb-1"
        />
      </div>
      <DropdownSelectField
        options={inStockInOfficeKits?.map((kit) => kit.id) || []}
        getOptionLabel={(option) =>
          inStockInOfficeKits?.find((kit) => kit.id === option)?.attributes
            ?.activation_id || option
        }
        value={selectedKit}
        onChange={(_e, newValue) => {
          if (newValue)
            associateOrderedTestsToKit(newValue, orderedTestIds, handleApiError)
          setSelectedKit(newValue)
          setKitActivationIdSelectedFor((prev: string[]) =>
            !!newValue
              ? [...prev, ...orderedTestIds.filter((id) => !prev.includes(id))]
              : prev.filter((id) => !orderedTestIds.includes(id))
          )
        }}
        placeholder="Select..."
        noOptionsText={unknownActivationIdText}
      />
    </div>
  )
}
