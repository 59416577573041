import { useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import { find } from "lodash"
import { Link } from "react-router-dom"

import {
  CircularProgress,
  Dialog,
  IconButton,
  Typography,
  styled,
} from "@material-ui/core"
import CloseIcon from "@material-ui/icons/Close"

import DesignSystemButton from "app/components/design-system/Button"
import { LAB_COMPANY_KEY } from "app/constants"
import usePreloadImages from "app/hooks/use-preload-image"
import { colors, navy, shadows } from "app/theme"
import { RootState } from "app/types"
import makeAppStyles from "app/utils/makeAppStyles"
import {
  checkHasAvailableRequisitionForms,
  checkHasHandfilledRequisitionForms,
} from "app/utils/order-utils"

import { requiresActivation } from "../in-office-kits/utils"
import HandfilledRequisitionNextSteps from "./HandfilledRequisitionNextSteps"
import * as Actions from "./store/actions"

const useStyles = makeAppStyles((theme) => ({
  button: {
    fontWeight: 600,
    fontSize: 15,
    width: "100%",
  },
  root: {
    paddingBottom: 32,
    alignItems: "center",
    flexDirection: "column",
    display: "flex",
  },
  spinner: {
    marginTop: 8,
  },
  dialog: {
    borderRadius: 12,
    border: "6px solid white",
    boxShadow: shadows["2xl"],
    width: "100%",
    textAlign: "center",
  },
  modal: {
    borderRadius: 12,
    border: "6px solid white",
    boxShadow: shadows["2xl"],
    maxWidth: 524,
    width: "100%",
    backgroundColor: colors.blueGray[50],
  },
  closeButton: {
    color: navy,
    position: "absolute",
    right: 8,
    top: 8,
  },
  dialogTitle: {
    display: "flex",
    justifyContent: "flex-end",
    flexWrap: "nowrap",
    alignItems: "center",
    paddingBottom: 27,
    overflow: "hidden",
  },
  image: {
    height: 180,
    borderBottom: `1px solid ${colors.blueGray[200]}`,
    width: "100%",
    objectFit: "cover",

    [theme.breakpoints.up("xs")]: {
      height: 280,
    },
  },
}))

export function RequisitionReadyModal({
  patientFirstName,
  orderId,
  open,
  onClose,
}) {
  const dispatch = useDispatch()
  const classes = useStyles({})
  const [isLoadingRequisitions, setIsLoadingRequisitions] = useState(false)

  const order = useSelector(({ patient }: RootState) =>
    find(patient.orders?.results, { id: orderId })
  )

  // These booleans are used only with the "handfilled requisitions" workaround, not with the core IOK flow
  const clinicUsesHandfilledRequisitions =
    order?.clinic?.allow_handfilled_requisition_forms
  const hasHandfilledRequisitionForms = useMemo(
    () => (order ? checkHasHandfilledRequisitionForms(order) : false),
    [order]
  )
  const hasNonHandfilledRequisitionForms = useMemo(
    () => (order ? checkHasAvailableRequisitionForms(order) : false),
    [order]
  )

  const confirmationImageLoaded = usePreloadImages([
    order?.confirmation_image?.image || "",
  ])

  const loadInstantRequisitions = async () => {
    setIsLoadingRequisitions(true)
    await dispatch(Actions.openInstantRequisitions(orderId))
    setIsLoadingRequisitions(false)
  }

  const requisitionLabCompanyKeysOnOrder: string[] = useMemo(
    () =>
      order?.ordered_tests
        ? order?.ordered_tests
            ?.filter((order_test) => order_test?.instant_requisition)
            .map((order_test) => order_test?.lab_test?.lab_company?.key)
        : [],
    [order]
  )

  const hasAmlLabTest = useMemo(
    () =>
      Boolean(
        order?.ordered_tests?.find(
          (order_test) =>
            order_test?.lab_test?.lab_company?.key ===
            LAB_COMPANY_KEY.ACCESS_MEDICAL_LABS
        )
      ),
    [order]
  )

  const modalHeader = order?.is_practitioner_paying
    ? `Submitted! You're ready to hand the kit to ${patientFirstName}.`
    : `${patientFirstName} Paid for Their Order`

  const hasAvailableRequisitionForms =
    !requisitionLabCompanyKeysOnOrder.every((labCompanyKey) =>
      requiresActivation(labCompanyKey)
    ) ||
    (clinicUsesHandfilledRequisitions && hasNonHandfilledRequisitionForms)

  const imageAlt = "Requisition form ready"
  const image = (
    <img
      alt={imageAlt}
      src={order?.confirmation_image?.image}
      className={classes.image}
    />
  )

  return (
    <Dialog
      aria-labelledby="requisition-ready-title"
      className={classes.root}
      disableBackdropClick
      disableEscapeKeyDown
      fullWidth
      onClose={onClose}
      open={open && confirmationImageLoaded}
      maxWidth="lg"
      classes={{
        root: classes.dialog,
        paper: classes.modal,
      }}
    >
      <TitleSection onClose={onClose} image={image} />

      {Boolean(order) ? (
        <div className="flex flex-col gap-y-7 mb-8 mx-3 md:mx-11 px-1.5 md:px-9 items-center">
          <div className="flex flex-col gap-y-1.5 items-center px-2.25 max-w-sm">
            <Typography
              color="textPrimary"
              variant="h5"
              className="font-semibold fs-exclude"
              id="requisition-ready-title"
            >
              {modalHeader}
            </Typography>

            <Typography color="textPrimary" className="font-normal fs-exclude">
              {(hasAvailableRequisitionForms
                ? "Some tests in your order need a new Requisition. "
                : "") +
                "Don't forget to write the Date of Sample Collection inside each kit before shipping to the lab!"}
            </Typography>

            {clinicUsesHandfilledRequisitions &&
              hasHandfilledRequisitionForms && (
                <StyledHandfilledRequisitionsNextSteps order={order} />
              )}
          </div>
          <div className="flex flex-col gap-y-2.5 w-full">
            {hasAmlLabTest && (
              <>
                <Typography className="font-normal fs-exclude">
                  Here are instructions for which test tubes to use for the
                  blood draw.
                </Typography>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://rupahealth.com/access-med-phlebotomy-instructions"
                  style={{ textDecoration: "none", width: "100%" }}
                >
                  <DesignSystemButton
                    className={classes.button}
                    color="secondary"
                    size="medium"
                  >
                    See Phlebotomy Instructions
                  </DesignSystemButton>
                </a>
              </>
            )}

            {(hasAvailableRequisitionForms || hasAmlLabTest) && (
              <DesignSystemButton
                className={classes.button}
                color="primary"
                loading={isLoadingRequisitions}
                onClick={loadInstantRequisitions}
                size="medium"
              >
                {clinicUsesHandfilledRequisitions &&
                hasHandfilledRequisitionForms
                  ? "View Available Requisitions"
                  : "View & Print Requisitions"}
              </DesignSystemButton>
            )}

            {!hasAmlLabTest && (
              <Link
                to="/dashboard"
                style={{ textDecoration: "none", width: "100%" }}
              >
                <DesignSystemButton
                  className={classes.button}
                  color="secondary"
                  size="medium"
                >
                  Return to Dashboard
                </DesignSystemButton>
              </Link>
            )}
          </div>
        </div>
      ) : (
        <CircularProgress
          className={classes.spinner}
          aria-label={`Waiting for ${patientFirstName}'s Order`}
        />
      )}
    </Dialog>
  )
}

const TitleSection = ({ onClose, image }) => {
  const classes = useStyles()

  const closeButton = onClose && (
    <IconButton
      className={classes.closeButton}
      aria-label="close"
      onClick={onClose}
      key="close-button"
    >
      <CloseIcon />
    </IconButton>
  )

  return (
    <div className={classes.dialogTitle}>
      <div className="w-full h-fit">{image}</div>
      {closeButton}
    </div>
  )
}

const StyledHandfilledRequisitionsNextSteps = styled(
  HandfilledRequisitionNextSteps
)(({ theme }) => ({
  marginTop: theme.spacing(3),
}))
