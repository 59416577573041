import { Grid, Theme, useMediaQuery } from "@material-ui/core"

import { UserPaths } from "app/Routes"
import CogIconGray from "app/assets/images/cog-icon-gray.svg"
import PageToolbar from "app/components/PageToolbar"
import SideNav from "app/main/settings/SideNav"

const SettingsPageWrapper = ({ children, pageLabel }) => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"))
  return (
    <>
      {isMobile && (
        <PageToolbar
          breadcrumbs={[
            {
              to: UserPaths.SETTINGS,
              icon: CogIconGray,
              label: "Settings",
            },
            {
              label: pageLabel,
            },
          ]}
          title={pageLabel}
        />
      )}
      <Grid container>
        {!isMobile && (
          <Grid item xs={12} md={3}>
            <SideNav />
          </Grid>
        )}
        <Grid item xs={12} md={9}>
          {!isMobile && (
            <PageToolbar
              title={pageLabel}
              greyBackground={true}
              fullBorder={true}
            />
          )}
          {children}
        </Grid>
      </Grid>
    </>
  )
}

export default SettingsPageWrapper
