import { useSelector } from "react-redux"

import _ from "lodash"
import { NavLink } from "react-router-dom"

import { makeStyles, Theme, useMediaQuery } from "@material-ui/core"

import { UserPaths } from "app/Routes"
import GlobeShieldIcon from "app/assets/icons/globe-shield.svg"
import BodyText from "app/components/design-system/BodyText"
import { VENDOR_PHYSICIAN_AUTHORIZATION_LABEL } from "app/constants"
import Banner from "app/main/dashboard/Banner"
import { primaryColor, rupaTeal } from "app/theme"
import { RootState } from "app/types"

export default function NoNPIBanner() {
  const classes = useStyles()

  const practitioner = useSelector(
    ({ practitioner }: RootState) => practitioner
  )

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"))

  // Make sure to evaluate that the practitioner is not empty.
  // Otherwise the banner will be visible when the practitioner has not been loaded yet and fields are undefined.
  const visible =
    !_.isEmpty(practitioner) &&
    !practitioner.npi_number &&
    !practitioner?.is_clinic_staff &&
    !practitioner.vendor_physician_authorization_enabled

  if (!visible) {
    return null
  }

  const titleText = "Looks like we don't have an NPI on file for you."
  const defaultBody = (
    <>
      To place an order, provide your{" "}
      <NavLink className={classes.styledLink} to={UserPaths.PROFILE}>
        NPI
      </NavLink>
    </>
  )
  const physicianAuthorizationBody = (
    <>
      {" "}
      or{" "}
      <NavLink
        className={classes.styledLink}
        to={UserPaths.PHYSICIAN_SERVICES_ENABLE_MODAL}
      >
        {`add ${VENDOR_PHYSICIAN_AUTHORIZATION_LABEL}`}
      </NavLink>
    </>
  )

  return (
    <Banner backgroundColor="#FFFFFF" borderColor={rupaTeal}>
      {!isMobile && (
        <img
          src={GlobeShieldIcon}
          alt="globe-shield-icon"
          className={classes.globeIcon}
        />
      )}
      <div>
        <BodyText weight="semibold" size="md" className={classes.titleText}>
          {titleText}
        </BodyText>
        <BodyText>
          {defaultBody}
          {physicianAuthorizationBody} in settings.
        </BodyText>
      </div>
    </Banner>
  )
}

const useStyles = makeStyles(() => ({
  globeIcon: {
    width: 60,
    marginLeft: 0,
    marginRight: 22,
  },
  rightCarrotIcon: {
    marginLeft: 5,
  },
  styledLink: {
    fontWeight: 600,
    display: "inline-block",
    color: primaryColor,
  },
  titleText: {
    marginBottom: 4,
  },
}))
