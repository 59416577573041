import { ORDER_STATUS } from "app/constants.typed"

import { StatusConfiguration } from "../types"
import { UnionStatusConfigurationProps } from "../types/types"
import { PROGRESS_PERCENTAGES } from "./consts"

const pausedStatusConfiguration: StatusConfiguration<UnionStatusConfigurationProps> =
  {
    when: { orderStatus: ORDER_STATUS.PAUSED },
    progressPercentage: PROGRESS_PERCENTAGES.PAUSED,
    title: "Order Paused",
  }

export default pausedStatusConfiguration
