import { cn } from "@rupahealth/design"

import { OptionType } from "app/types"

import RupaCheckbox from "../RupaCheckbox"

interface Props<T = string> {
  options: OptionType<T>[]
  selectedValue: T | undefined
  onSelect: (value: T) => void
  // Currently only supports single selection (radio-style).
  multi?: false
}

function CardSelect<T = string>({
  options,
  selectedValue,
  onSelect,
}: Props<T>) {
  return (
    <div className="flex flex-col gap-[10px]">
      {options.map(({ label, value, description }) => {
        const selected = selectedValue === value
        return (
          <div
            key={`${label}-${value}`}
            className={cn(
              "group cursor-pointer rounded-lg flex items-center gap-2 p-4 border border-slate-300 hover:border-primary",
              { "border-primary bg-blue-50": selected }
            )}
            onClick={() => onSelect(value)}
            onKeyDown={(e) => e.key === "Enter" && onSelect(value)}
          >
            <RupaCheckbox checked={selected} className="rounded-full h-5 w-5" />
            <div>
              <div className="font-semibold">{label}</div>
              {description && <div>{description}</div>}
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default CardSelect
