import { cn } from "@rupahealth/design"

import { ReactComponent as BiomarkerGroupIcon } from "app/assets/icons/biomarker-grouping.svg"
import { ReactComponent as BiomarkerIcon } from "app/assets/icons/biomarker.svg"
import { ReactComponent as HealthCategoryIcon } from "app/assets/icons/health-category.svg"
import { ReactComponent as HeartIcon } from "app/assets/icons/heart.svg"
import { ReactComponent as LabCompanyIcon } from "app/assets/icons/lab-company.svg"
import { ReactComponent as LabTestIcon } from "app/assets/icons/lab-test.svg"
import { ReactComponent as MoreFiltersIcon } from "app/assets/icons/more-filters.svg"
import { ReactComponent as SigningPractitionerFilterIcon } from "app/assets/icons/person-shield.svg"
import { ReactComponent as PhlebotomyIcon } from "app/assets/icons/phlebotomists.svg"
import { ReactComponent as SampleTypeIcon } from "app/assets/icons/sample-type.svg"
import { ReactComponent as SearchIcon } from "app/assets/icons/search.svg"
import { ReactComponent as StateRestrictionFilterIcon } from "app/assets/icons/state-restriction-filter.svg"
import { FilterNames } from "app/components/LabTests/types"
import { ReactSVGIconType } from "app/types"

type IconName = FilterNames | "biomarker_grouping" | "search" | "moreFilters"

export const FILTER_ICONS_BY_TYPE: Record<IconName, ReactSVGIconType> = {
  biomarker: BiomarkerIcon,
  biomarker_grouping: BiomarkerGroupIcon,
  favorites: HeartIcon,
  health_category: HealthCategoryIcon,
  lab_company: LabCompanyIcon,
  lab_test: LabTestIcon,
  lab_test_type: SampleTypeIcon,
  phlebotomy_required: PhlebotomyIcon,
  allowed_in_state: StateRestrictionFilterIcon,
  signing_practitioner: SigningPractitionerFilterIcon,
  search: SearchIcon,
  moreFilters: MoreFiltersIcon,
}

const VIEWBOX_BY_TYPE: Record<IconName, string> = {
  biomarker: "0 0 12 12",
  biomarker_grouping: "0 0 13 13",
  favorites: "0 0 24 24",
  health_category: "0 0 17 17",
  lab_company: "0 0 12 12",
  lab_test: "0 0 12 12",
  lab_test_type: "0 0 12 12",
  phlebotomy_required: "0 0 18 18",
  allowed_in_state: "0 0 16 16",
  signing_practitioner: "0 0 15 16",
  search: "0 0 12 12",
  moreFilters: "0 0 16 16",
}

export interface FilterIconProps {
  type: string
  alt?: string
  className?: string
}

export default function FilterIcon({
  type,
  alt = "",
  className,
}: FilterIconProps) {
  const TypeIcon = FILTER_ICONS_BY_TYPE[type]
  const viewBox = VIEWBOX_BY_TYPE[type]

  if (!TypeIcon) {
    // render nothing if no matching icon found
    return null
  }

  return (
    <TypeIcon
      viewBox={viewBox}
      title={alt}
      className={cn("h-4 w-4 fill-body", className)}
    />
  )
}
