import { faAngleRight } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button } from "@rupahealth/design"

import { trackEventWithProperties } from "app/services/segment.typed"
import { Practitioner } from "app/types"
import { SpecimenIssueEvents } from "types/specimen-issue"

import { SpecimenIssueResolveModalProps } from "./resolution-modal/SpecimenIssueResolveModal"

interface Props extends SpecimenIssueResolveModalProps {
  modal: any
  pathname: string
  practitioner: Practitioner
}

const SpecimenIssueResolveButton: React.FC<Props> = (props) => {
  const { modal, pathname, practitioner, ...modalProps } = props
  return (
    <Button
      type="button"
      variant="ghost"
      className="flex items-center gap-1 py-0 px-1 h-auto text-primary hover:text-primary rounded-sm"
      onClick={() => {
        trackEventWithProperties(
          SpecimenIssueEvents.SPECIMEN_ISSUE_RESOLVE_CLICKED,
          {
            specimenIssueId: modalProps.specimenIssue.id,
            practitionerId: practitioner?.id,
            pathname: pathname,
          }
        )
        modal.show(modalProps)
      }}
    >
      Resolve <FontAwesomeIcon icon={faAngleRight} />
    </Button>
  )
}

export default SpecimenIssueResolveButton
