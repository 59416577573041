import React, { Fragment, useEffect, useMemo, useState } from "react"
import { useDispatch } from "react-redux"

import Button from "app/components/design-system/Button"
import useUpdateOrder from "app/main/patient-checkout/hooks/use-update-order"
import {
  PHLEBOTOMY_BOOKING_EVENTS,
  trackPhlebotomyBookingEvent,
} from "app/services/segment"
import { PhlebotomyProviderName } from "app/types"

import {
  bloodDrawErrorMessage,
  bloodDrawSuccessMessage,
  getProviderOptions,
  updateProviderList,
} from "./utils"

interface SelectProviderProps {
  phlebProviders: PhlebotomyProviderName[]
  availableProviders: PhlebotomyProviderName[]
  onFormIsModified: () => void
  onFormIsConfirmed: () => void
}

export const SelectProvider = ({
  phlebProviders,
  availableProviders,
  onFormIsModified,
  onFormIsConfirmed,
}: SelectProviderProps) => {
  const dispatch = useDispatch()

  const { updateOrder } = useUpdateOrder()

  const [confirmDisabled, setConfirmDisabled] = useState(false)

  const [selectedProvider, setSelectedProvider] = useState(phlebProviders[0])

  const handleProviderChange = (event) => {
    setSelectedProvider(event.target.value)
  }

  // Check if the form has been modified
  const isFormModified = !phlebProviders.includes(selectedProvider)

  useEffect(() => {
    if (isFormModified) {
      setConfirmDisabled(false)
      onFormIsModified()
    }
  }, [selectedProvider, isFormModified])

  const onConfirm = () => {
    setConfirmDisabled(true)
    onFormIsConfirmed()
    const newPhlebotomyProviders = updateProviderList(
      selectedProvider,
      phlebProviders
    )
    updateOrder({
      phlebotomy_providers: newPhlebotomyProviders,
    })
      .then(() => {
        trackPhlebotomyBookingEvent(
          PHLEBOTOMY_BOOKING_EVENTS.PHLEBOTOMY_OPTIONS_PICKED,
          { selected_option: selectedProvider }
        )
        dispatch(bloodDrawSuccessMessage(selectedProvider))
      })
      .catch(() => {
        dispatch(bloodDrawErrorMessage())
      })
  }

  const providerOptions = useMemo(
    () => getProviderOptions(availableProviders),
    [phlebProviders]
  )

  return (
    <div>
      <fieldset>
        <div className="rounded-lg border border-slate-200 bg-white shadow-sm">
          {providerOptions.map(({ label, value, description }, idx) => (
            <Fragment key={`${label}-${value}`}>
              <label
                htmlFor={value}
                className="flex items-start p-4 pl-6 cursor-pointer"
              >
                <input
                  type="radio"
                  id={value}
                  name="phleb_selection"
                  value={value}
                  className="mt-1"
                  onChange={handleProviderChange}
                  checked={selectedProvider === value}
                />
                <RadioSelection body={description} title={label} />
              </label>
              {idx < providerOptions.length - 1 && (
                <hr className="h-[1px] bg-slate-200" />
              )}
            </Fragment>
          ))}
        </div>
        {isFormModified && (
          <Button
            className="mt-5"
            onClick={onConfirm}
            disabled={confirmDisabled}
          >
            Confirm Selection
          </Button>
        )}
      </fieldset>
    </div>
  )
}

interface RadioSelectionProps {
  title: string
  body: React.ReactNode
}

const RadioSelection = ({ title, body }: RadioSelectionProps) => {
  return (
    <div className="pl-3 font-sans text-base15 cursor-pointer">
      <div className="font-semibold text-primary">{title}</div>
      <div className="pr-6">{body}</div>
    </div>
  )
}
