import { cn } from "@rupahealth/design"

interface Props {
  title?: React.ReactNode
  body?: React.ReactNode
  actions?: React.ReactNode
  className?: string
}

const InfoBanner: React.FC<Props> = ({ title, body, actions, className }) => {
  return (
    <div
      className={cn(
        "bg-white border border-slate-200 rounded-lg shadow-sm",
        className
      )}
    >
      <div className="h-2 w-full rounded-t-lg bg-gradient-to-r from-info-turquoise from-40% to-white to-100%" />
      <div className="p-4">
        <div className="flex flex-col sm:flex-row gap-4 sm:gap-0 items-center justify-between">
          <div>
            {!!title && <div className="font-semibold">{title}</div>}
            {body}
          </div>

          {!!actions && <div className="ml-4">{actions}</div>}
        </div>
      </div>
    </div>
  )
}

export default InfoBanner
