import React, { useEffect, useMemo, useState } from "react"
import { useDispatch } from "react-redux"

import NiceModal, { useModal } from "@ebay/nice-modal-react"
import { CircularProgress } from "@material-ui/core"
import { cn, Dialog, DialogContent } from "@rupahealth/design"

import useCreateSnapshot from "app/main/results-upload/hooks/use-create-snapshot"
import useLabCompanies from "app/main/results-upload/hooks/use-lab-companies"
import useResultUploadLabTestTypes from "app/main/results-upload/modals/components/useResultUploadLabTestTypes"
import ResultsUploadPdfAllSetCard from "app/main/results-upload/modals/pdf-upload/ResultsUploadPdfAllSetCard"
import * as Actions from "app/store/actions"
import useResourceSWR from "app/swr/hooks/use-resource-swr"
import { LabCompany, LabTestType } from "app/types"
import { UserResult } from "types/user-result"

import { useInvalidateTrendsAndDocuments } from "../use-invalidate-trends-and-documents"
import { UserResultFormContainer } from "./UserResultFormContainer"

interface Props {
  // If no patientId is provided, we're in patient portal
  patientId?: string
  // If no userResultId is provided, the modal will show create form
  userResultId?: string
  onSave?: () => Promise<void>
}

const UserResultModal: React.FC<Props> = ({ userResultId, ...innerProps }) => {
  const modal = useModal()
  const dispatch = useDispatch()

  // Track dirty outside of the form so we know if we need to confirm before closing
  const [dirty, setDirty] = useState(false)

  // Load data that the form needs
  useEffect(() => {
    dispatch(Actions.getLabTestTypeList())
  }, [dispatch])
  const { data: userResult, isLoading } = useResourceSWR<UserResult>(
    userResultId ? `/user_results/${userResultId}/` : null,
    { include: ["lab_company", "user_biomarker_results.biomarker"] }
  )

  const labCompanies = useLabCompanies()
  const labTestTypes = useResultUploadLabTestTypes()

  // Show loading spinner until all necessary data is loaded
  const loading = useMemo(() => {
    return isLoading || !labCompanies.length || !labTestTypes.length
  }, [isLoading, labCompanies, labTestTypes])

  const onOpenChange = (open: boolean) => {
    if (open) return

    if (dirty) {
      if (!window.confirm("Are you sure? Unsaved changes will be lost.")) {
        return
      }
    }
    modal.remove()
  }

  return (
    <Dialog open={modal.visible} onOpenChange={onOpenChange}>
      {loading ? (
        <DialogContent className="bg-slate-100 h-[90vh] w-[38rem] border-[4px]">
          <div className="flex justify-center items-center">
            <CircularProgress size={48} />
          </div>
        </DialogContent>
      ) : (
        <UserResultModalInner
          {...innerProps}
          userResult={userResult}
          labCompanies={labCompanies}
          labTestTypes={labTestTypes}
          onDirtyChange={setDirty}
          closeModal={() => onOpenChange(false)}
        />
      )}
    </Dialog>
  )
}

interface InnerProps {
  patientId?: string
  userResult: UserResult | undefined
  labCompanies: LabCompany[]
  labTestTypes: LabTestType[]
  onSave?: () => Promise<void>
  onDirtyChange: (dirty: boolean) => void
  closeModal: () => void
}

const UserResultModalInner: React.FC<InnerProps> = ({
  userResult,
  onSave,
  onDirtyChange,
  closeModal,
  ...formProps
}) => {
  const [showAllSetCard, setShowAllSetCard] = useState(false)
  const { createSnapshotFromUserResult } = useCreateSnapshot()
  const defaultOnSave = useInvalidateTrendsAndDocuments()

  const handleSave = async (savedUserResult: UserResult | undefined) => {
    onDirtyChange(false)
    if (savedUserResult?.relationships.user_biomarker_results) {
      await createSnapshotFromUserResult(savedUserResult.id)
    }
    setShowAllSetCard(true)
    await onSave?.()
    await defaultOnSave()
  }

  const pdfFile = userResult?.attributes.pdf_file

  const title = useMemo(() => {
    if (!userResult) {
      return "Manually Enter Lab Results"
    } else if (pdfFile) {
      return "Imported Lab Result"
    } else {
      return "Manually Entered Lab Result"
    }
  }, [userResult])

  return (
    <DialogContent
      className={cn(
        "bg-slate-100 p-4 sm:p-5 max-h-[90vh] max-w-[90vw] border-[4px] overflow-hidden flex flex-col",
        { "w-[36rem]": showAllSetCard }
      )}
    >
      <div className="overflow-hidden flex flex-col gap-3 sm:gap-4 text-body h-full">
        <div className="flex flex-col gap-1">
          <span className="font-title font-semibold text-lg">{title}</span>
          {!userResult && (
            <span>You'll always be able to edit these later in Documents.</span>
          )}
        </div>

        {showAllSetCard ? (
          <ResultsUploadPdfAllSetCard
            forceRefresh={false}
            onClose={closeModal}
          />
        ) : (
          <UserResultFormContainer
            pdfFileUrl={pdfFile}
            {...formProps}
            userResult={userResult}
            handleSave={handleSave}
            onDirtyChange={onDirtyChange}
          />
        )}
      </div>
    </DialogContent>
  )
}

const UserResultNiceModal = NiceModal.create<Props>(UserResultModal)

export default function useUserResultModal() {
  return useModal(UserResultNiceModal)
}
