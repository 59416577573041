import { ReactComponent as AddWhiteIcon } from "app/assets/icons/add-white.svg"
import Button from "app/components/design-system/Button"
import { primaryColor } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"

const useStyles = makeAppStyles((theme) => ({
  addBiomarkerButton: {
    display: "flex",
    alignItems: "center",
    "& .MuiButton-startIcon": {
      margin: theme.spacing(0.0, 0.5, 0.0, 0.0),
    },
  },
  addIcon: {
    height: 16,
    width: 16,
    padding: 3,
    backgroundColor: primaryColor,
    borderRadius: "50%",
    verticalAlign: "middle",
  },
}))

export default function ToggleAddBiomarkerButton({
  onToggleAdding,
}: {
  onToggleAdding: () => void
}) {
  const classes = useStyles()
  return (
    <Button
      key="add-button"
      className={classes.addBiomarkerButton}
      color="text"
      onClick={onToggleAdding}
      startIcon={
        <AddWhiteIcon className={classes.addIcon} viewBox="0 0 15 16" />
      }
      type="button"
    >
      Add Marker
    </Button>
  )
}
