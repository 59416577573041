import { colors, dashboardHighlight, shadows } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"

const useTableStyles = makeAppStyles((theme) => ({
  container: {
    border: `1px solid ${colors.coolGray[200]}`,
    borderRadius: 8,
    overflow: "auto",
    background: "white",
    boxShadow: shadows.default,
  },
  table: {
    tableLayout: "auto",
  },
  tableHeaderRow: {
    color: theme?.palette?.text?.secondary,
    backgroundColor: colors.blueGray[50],
  },
  tableDataRow: {
    backgroundColor: "white",
    height: 63,
  },
  tableDataRowCanExpand: {
    cursor: "pointer",
  },
  tableDataRowIsExpanded: {
    backgroundColor: dashboardHighlight,
  },
  tableCell: {
    padding: theme.spacing(2),
    wordWrap: "break-word",
  },
  tableEmptyRow: {
    height: "auto",
    padding: "20px 22px",
  },
  tableEmptyRowIcon: {
    fill: colors.blueGray[500],
    marginBottom: theme.spacing(1.0),
  },
  tableEmptyRowText: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "white",
    gap: theme.spacing(1.0),
    color: colors.blueGray[500],
  },
  tablePaginationRow: {
    color: theme?.palette?.text?.secondary,
    backgroundColor: colors.blueGray[50],
  },
  popover: {
    maxWidth: 400,
    width: "100%",
  },
  emptyTableCellContainer: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  emptyTableCell: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1.0),
    width: 500,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
}))

export default useTableStyles
