import { makeStyles, Theme } from "@material-ui/core"
import { Styles, WithStylesOptions } from "@material-ui/core/styles/withStyles"

import theme from "app/theme"

type AppTheme = Theme & typeof theme

function makeAppStyles<
  Props extends object = {},
  ClassKey extends string = string
>(
  styles: Styles<AppTheme, Props, ClassKey>,
  options?: Omit<WithStylesOptions<AppTheme>, "withTheme">
) {
  return makeStyles<AppTheme, Props>(styles, options)
}

export default makeAppStyles
