import _ from "lodash"

import { normalizeDate } from "app/components/forms/normalization"
import {
  PATIENT_CHECKOUT_REQUIREMENTS,
  PatientCheckoutRequirement,
  PatientPortalOrder,
  PatientPortalOrderedTest,
} from "app/types"

export function isPaymentRequired(order: PatientPortalOrder) {
  return order.requirements.includes("payment")
}

/**
 * Normalize data for submission
 * @param data: data to be submitted
 */
export function normalizeData(data) {
  const newData = { ...data }

  newData["birthday"] = normalizeDate(data["birthday"])

  if (newData.insurance) {
    newData.insurance.subscribers_birthday = normalizeDate(
      data.insurance.subscribers_birthday
    )
  }

  return newData
}

export function isEmptyErrors(errors: any) {
  return _.isEmpty(_.compact(_.values(errors)))
}

/**
 * Gets the checkout requirements we know how to handle.
 *
 * @param order the PatientPortalOrder
 * @returns the checkout requirements we know how to handle
 */
export function getKnownRequirements(
  requirements: Array<PatientCheckoutRequirement> = []
) {
  if (_.isEmpty(requirements)) {
    return []
  }

  // use #find to ensure we only return requirements we know how to handle
  return requirements.filter((requirement) =>
    PATIENT_CHECKOUT_REQUIREMENTS.includes(requirement)
  )
}

export function getEnabledOrderedTests(
  order: PatientPortalOrder
): PatientPortalOrderedTest[] {
  return order.line_items
    .filter(
      (lineItem) =>
        !Boolean(lineItem.date_disabled) && Boolean(lineItem.ordered_test)
    )
    .map((lineItem) => lineItem.ordered_test!)
}

export function orderedTestRequiresPhleb(
  orderedTest: PatientPortalOrderedTest
) {
  return orderedTest.lab_test.lab_test_types.some(
    (type) => type.phlebotomy_required
  )
}
