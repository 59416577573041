import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"

import _ from "lodash"

import { API } from "app/api"
import { Coupon, COUPON_ACTION } from "app/components/Coupon"
import BodyText from "app/components/design-system/BodyText"
import { VENDOR_PHYSICIAN_AUTHORIZATION_LABEL } from "app/constants"
import { COUPON_TYPE, ORDER_LINE_ITEM_TYPE } from "app/constants"
import { formatDollars, handleApiError } from "app/utils"
import makeAppStyles from "app/utils/makeAppStyles"
import { isValidSelfOrderOnlyCoupon } from "app/utils/order-utils"
import { getSigningPractitioner } from "app/utils/practitioner-utils"

import * as Actions from "../store/actions"

const useStyles = makeAppStyles(() => ({
  bodyTextStyle: {
    fontSize: 13,
    marginBottom: "14px",
    whiteSpace: "pre-line",
    textAlign: "center",
  },
}))

export function Coupons({ order, className }) {
  const dispatch = useDispatch()
  const classes = useStyles()
  const [fetchedCoupons, setFetchedCoupons] = useState([])

  const fetchCoupons = () => {
    API.Coupon.getList({
      is_ordering: true,
    })
      .then((response) => {
        setFetchedCoupons(response.data.results)
      })
      .catch((error) => dispatch(handleApiError(error)))
  }

  const deleteCouponUser = (coupon_id) => {
    API.Coupon.deleteCouponUser(coupon_id)
      .then(() => {
        fetchCoupons()
      })
      .catch((error) => dispatch(handleApiError(error)))
  }

  const couponLineItems = _.filter(
    order.line_items,
    (item) => item.type === ORDER_LINE_ITEM_TYPE.COUPON
  )

  useEffect(() => {
    fetchCoupons()
  }, [])

  useEffect(() => {
    const couponCode = window.sessionStorage.getItem("coupon_code_for_checkout")
    if (couponCode && fetchedCoupons.length) {
      const availableCoupons = _.reject(fetchedCoupons, (coupon) =>
        _.some(couponLineItems, (lineItem) => lineItem.coupon.id === coupon.id)
      )
      const coupon = availableCoupons.find((c) => c.code === couponCode)
      window.sessionStorage.removeItem("coupon_code_for_checkout")
      handleApply(coupon)
    }
  }, [fetchedCoupons])

  const handleApply = async (coupon) => {
    await dispatch(Actions.addCoupon(coupon))
  }

  const handleRemove = async (coupon) => {
    await dispatch(Actions.removeCoupon(coupon))
  }

  // If there are no fetched coupons or already-applied coupons,
  // there's nothing to render.
  if (fetchedCoupons.length === 0 && couponLineItems.length === 0) {
    return <></>
  }

  const availableCoupons = _.reject(fetchedCoupons, (coupon) =>
    _.some(couponLineItems, (lineItem) => lineItem.coupon.id === coupon.id)
  )

  const getCouponDisabledReason = (order, coupon) => {
    if (
      order.requires_vendor_physician_authorization &&
      !coupon.allow_physician_services
    ) {
      const isSigningPractitionerOptionAvailable = Boolean(
        getSigningPractitioner(order.practitioner)
      )

      return (
        <>
          <BodyText
            className={classes.bodyTextStyle}
            size="sm"
            weight="semibold"
          >
            This coupon cannot be applied to a{" "}
            {VENDOR_PHYSICIAN_AUTHORIZATION_LABEL} order.
          </BodyText>
          {isSigningPractitionerOptionAvailable && (
            <>
              {" "}
              <BodyText
                className={classes.bodyTextStyle}
                size="sm"
                weight="regular"
              >
                If you'd like to apply your coupon: select a different Signing
                Practitioner from the menu of available options above to disable
                {VENDOR_PHYSICIAN_AUTHORIZATION_LABEL} and apply the coupon to
                your order.
              </BodyText>
            </>
          )}
        </>
      )
    }

    if (!isValidSelfOrderOnlyCoupon(order, coupon)) {
      return (
        <>
          <BodyText size="sm" weight="regular" style={{ display: "inline" }}>
            This coupon can only be used for self-orders.
          </BodyText>{" "}
          <BodyText size="sm" weight="semibold" style={{ display: "inline" }}>
            To apply this coupon, start an order for a new patient with{" "}
            {order.practitioner?.user.email
              ? `the email: ${" "}${order.practitioner.user.email}`
              : "your email address"}
            .
          </BodyText>
        </>
      )
    }

    if (
      parseFloat(coupon.discount) >
        parseFloat(order.total_price) + parseFloat(order.coupon_discount) &&
      ![
        COUPON_TYPE.DISCOUNTED_LAB_TEST,
        COUPON_TYPE.LAB_WIDE_DISCOUNT,
      ].includes(coupon.type)
    ) {
      // Don't disable the coupon for small order values if it's a discounted lab test coupon.
      // This is because selecting the coupon will add the relevant test to the cart automatically.
      return `This coupon can only be applied to orders above ${formatDollars(
        coupon.discount
      )}.`
    }

    if (
      coupon.lab_company &&
      !order.line_items.some(
        (lineItem) =>
          lineItem.type === ORDER_LINE_ITEM_TYPE.LAB_TEST &&
          lineItem.ordered_test.lab_test.lab_company.id ===
            coupon.lab_company.id &&
          !coupon.excluded_lab_test_ids.includes(
            lineItem.ordered_test.lab_test.id
          )
      )
    ) {
      return `This coupon can only be applied to orders with applicable tests from ${coupon.lab_company.name}.`
    }

    return null
  }

  return (
    <div className={className}>
      {couponLineItems &&
        couponLineItems.map((lineItem) => (
          <Coupon
            coupon_id={couponLineItems.id}
            className="mb-4 border"
            key={lineItem.id}
            title={lineItem.coupon.practitioner_title}
            subtitle={lineItem.coupon.practitioner_subtitle}
            cost={
              lineItem.coupon.percent_discount
                ? lineItem.coupon.percent_discount
                : lineItem.cost
            }
            onRemove={() => handleRemove(lineItem.coupon)}
            action={COUPON_ACTION.REMOVE}
            percentOffTestName={
              lineItem.coupon.percent_discount && lineItem.coupon.lab_test?.name
                ? lineItem.coupon.lab_test.name
                : ""
            }
            disabledReason={getCouponDisabledReason(order, lineItem.coupon)}
            isPercentOffCoupon={lineItem.coupon.percent_discount ? true : false}
            isAutomaticCoupon={lineItem.coupon.is_automatic}
          />
        ))}
      {availableCoupons &&
        availableCoupons.map((coupon) => {
          return (
            <Coupon
              coupon_id={coupon.id}
              className="mb-4 border"
              key={coupon.id}
              title={coupon.practitioner_title}
              subtitle={coupon.practitioner_subtitle}
              onDelete={() => deleteCouponUser(coupon.id)}
              onApply={() => handleApply(coupon)}
              action={COUPON_ACTION.APPLY}
              disabledReason={getCouponDisabledReason(order, coupon)}
              isPercentOffCoupon={false}
              isFirstOrderOnly={coupon.is_first_order_only}
            />
          )
        })}
    </div>
  )
}
