import { useParams } from "react-router-dom"

import { Theme } from "@material-ui/core"

import BloodLabDashboardNavBar from "app/main/blood-lab-dashboards/BloodLabDashboardsPatientPreview/BloodLabDashboardNavBar"
import Error404Page from "app/main/errors/404/Error404Page"
import Error500Page from "app/main/errors/500/Error500Page"
import PatientPortalBloodDashboardBackground from "app/patient-portal/PatientPortalBloodDashboardBackground"
import makeAppStyles from "app/utils/makeAppStyles"

import useDiscreteResults from "../hooks/use-discrete-results"
import BloodLabDashboardsPatientHero from "./BloodLabDashboardsPatientHero"
import BloodLabsDashboardsPatientReportContainer from "./BloodLabDashboardsPatientReportContainer"

const HEIGHT_OF_NAVBAR = 80

const useStyles = makeAppStyles((theme: Theme) => ({
  container: {
    width: "100%",
    maxWidth: 1500,
    display: "flex",
    flexDirection: "column",
    gap: 20,
    paddingTop: HEIGHT_OF_NAVBAR + 57, // 57px is to offset the padding of the hero component
    paddingLeft: 42,
    paddingRight: 42,
    minHeight: "100vh",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 22,
      paddingRight: 22,
    },
  },
}))

const BloodLabDashboardsPatientPreview = () => {
  const classes = useStyles()

  const { orderedResultId } = useParams<{
    orderedResultId: string
  }>()

  const {
    orderedResult,
    discreteResults,
    discreteResultsBiomarkers: biomarkers,
    missingBiomarkers,
    getAbnormalDiscreteResults,
    getMatchingDiscreteResultByBiomarkerId,
    getMatchingOptimalRangeByBiomarkerId,
    bodySystems,
    patient,
    practitioner,
    clinic,
    isLoading,
    error,
  } = useDiscreteResults(orderedResultId)

  const abnormalDiscreteResults = getAbnormalDiscreteResults()

  if (error) {
    console.log(error)
    if (error?.response?.status === 404) {
      return <Error404Page />
    } else {
      return <Error500Page />
    }
  }

  return (
    <div className="fs-unmask">
      <BloodLabDashboardNavBar />
      <PatientPortalBloodDashboardBackground>
        <div className={classes.container}>
          <BloodLabDashboardsPatientHero
            orderedResult={orderedResult}
            patient={patient}
            missingBiomarkers={missingBiomarkers}
            clinicLogoUrl={clinic?.attributes.logo || ""}
            practitionerTitledName={
              practitioner?.attributes.titled_full_name || ""
            }
            isLoading={isLoading}
            isPatientPreview={true}
          />

          <BloodLabsDashboardsPatientReportContainer
            orderedResult={orderedResult}
            discreteResults={discreteResults}
            abnormalDiscreteResults={abnormalDiscreteResults}
            bodySystems={bodySystems}
            isLoading={isLoading}
            isPatientPreview={true}
            clinic={clinic}
            practitioner={practitioner}
            biomarkers={biomarkers}
            getAbnormalDiscreteResults={getAbnormalDiscreteResults}
            getMatchingDiscreteResultByBiomarkerId={
              getMatchingDiscreteResultByBiomarkerId
            }
            getMatchingOptimalRangeByBiomarkerId={
              getMatchingOptimalRangeByBiomarkerId
            }
          />
        </div>
      </PatientPortalBloodDashboardBackground>
    </div>
  )
}

export default BloodLabDashboardsPatientPreview
