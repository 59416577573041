import * as React from "react"
import { useState } from "react"

import clsx from "clsx"

import { Container, Link, Typography } from "@material-ui/core"

import Loading from "app/components/Loading"
import Button from "app/components/design-system/Button"
import NavBar from "app/main/patient-checkout/NavBar"
import PaymentCollectionForm from "app/main/payment-collection/components/PaymentCollectionForm"
import PaymentCollectionSidebar from "app/main/payment-collection/components/PaymentCollectionSidebar"
import usePaymentCollectionConfiguration from "app/main/payment-collection/hooks/use-payment-collection-configuration"
import usePaymentCollectionToken from "app/main/payment-collection/hooks/use-payment-collection-token"
import { PaymentCollectionSubmitResult } from "app/main/payment-collection/types"
import { marketingSite } from "app/settings"
import { colors, shadows, textPrimaryColor } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"

const useStyles = makeAppStyles((theme) => ({
  container: {
    // height and margin bottom ensure the background stretches to the footer
    height: `calc(100% + ${theme.spacing(3.0)}px)`,
    marginBottom: theme.spacing(-3.0),
    backgroundColor: colors.blueGray[100],
  },
  body: {
    padding: theme.spacing(3.0),
    display: "flex",
    flexFlow: "column nowrap",
    gap: theme.spacing(3.0),
    [theme.breakpoints.up("md")]: {
      flexFlow: "row nowrap",
    },
  },
  statusCard: {
    padding: theme.spacing(3.0),
    backgroundColor: "white",
    boxShadow: shadows.default,
    borderRadius: 8,
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(8.0),
    },
  },
  statusCardTitle: {
    color: textPrimaryColor,
  },
  statusCardDescription: {
    marginTop: theme.spacing(2.0),
  },
  statusCardFooter: {
    marginTop: theme.spacing(4.0),
    fontSize: 16,
    fontWeight: theme.typography.fontWeightMedium,
  },
  shareShippingInfoButton: {},
}))

type Classes = ReturnType<typeof useStyles>

const PaymentCollectionLoadError = ({ classes }: { classes: Classes }) => {
  return (
    <div className={clsx(classes.container, "fs-unmask")}>
      <NavBar />

      <Container className={classes.body} maxWidth="sm">
        <div className={classes.statusCard}>
          <Typography
            className={classes.statusCardTitle}
            align="center"
            variant="h4"
          >
            Order Not Found
          </Typography>
          <Typography
            align="center"
            className={classes.statusCardDescription}
            color="textPrimary"
          >
            We couldn't find that order. If you think this is a mistake please
            get in touch. You can contact us at{" "}
            <Link color="primary" href="mailto:support@rupahealth.com">
              support@rupahealth.com
            </Link>
            <br />
          </Typography>

          <Typography align="center" className={classes.statusCardFooter}>
            <Link color="primary" target="_blank" href={marketingSite}>
              Learn more about Rupa Health
            </Link>
          </Typography>
        </div>
      </Container>
    </div>
  )
}

const PaymentCollectionLoading = ({ classes }: { classes: Classes }) => {
  return (
    <div className={clsx(classes.container, "fs-unmask")}>
      <NavBar />

      <Container className={classes.body} maxWidth="sm">
        <Loading ariaLabel="Loading order" />
      </Container>
    </div>
  )
}

const PaymentCollectionConfirmation = ({
  classes,
  submitResult,
}: {
  classes: Classes
  submitResult: PaymentCollectionSubmitResult
}) => {
  let title: string
  let description: React.ReactNode

  switch (submitResult.next_action) {
    case "patient_checkout": {
      title = "Almost Done! 🎊"
      description = (
        <>
          Look out for an email coming from the Rupa team as a receipt of your
          payment.
          <br />
          <br />
          Fill out this quick 20-second form so we can ship your kits to the
          right address. After you've filled out your information, you'll get an
          email with estimated arrival times of your kits once they're on their
          way.
          <br />
          <br />
          <Button
            className={classes.shareShippingInfoButton}
            color="primary"
            href={submitResult.next_url}
            size="medium"
            variant="contained"
          >
            {"Provide your Shipping Details"}
          </Button>
        </>
      )
      break
    }
    case "confirmation":
    default: {
      title = "Payment Successful 🎊"
      description = (
        <>
          Look out for an email coming from the Rupa team as a receipt of your
          payment.
          <br />
          <br />
          We're here for you for anything lab-testing related!
          <br />
          <br />
          Best,
          <br />
          The Rupa Health Team
          <br />
          <br />
          <Link color="primary" href="mailto:support@rupahealth.com">
            support@rupahealth.com
          </Link>
        </>
      )
    }
  }

  return (
    <div className={clsx(classes.container, "fs-unmask")}>
      <NavBar />

      <Container className={classes.body} maxWidth="sm">
        <div className={classes.statusCard}>
          <Typography
            className={classes.statusCardTitle}
            align="center"
            variant="h4"
          >
            {title}
          </Typography>
          <Typography
            align="center"
            className={classes.statusCardDescription}
            color="textPrimary"
          >
            {description}
          </Typography>

          <Typography align="center" className={classes.statusCardFooter}>
            <Link color="primary" target="_blank" href={marketingSite}>
              Learn more about Rupa Health
            </Link>
          </Typography>
        </div>
      </Container>
    </div>
  )
}

export default function PaymentCollection() {
  const classes = useStyles()
  const token = usePaymentCollectionToken()
  const { config, error, ready } = usePaymentCollectionConfiguration(token)
  const [submitResult, setSubmitResult] =
    useState<PaymentCollectionSubmitResult>()

  if (submitResult) {
    return (
      <PaymentCollectionConfirmation
        classes={classes}
        submitResult={submitResult}
      />
    )
  }

  if (error) {
    return <PaymentCollectionLoadError classes={classes} />
  }

  if (!ready) {
    return <PaymentCollectionLoading classes={classes} />
  }

  return (
    <div className={clsx(classes.container, "fs-unmask")}>
      <NavBar patient={config.patient} />

      <Container className={classes.body} maxWidth="lg">
        <PaymentCollectionForm
          config={config}
          onSubmitSuccess={setSubmitResult}
          token={token}
        />

        <PaymentCollectionSidebar config={config} />
      </Container>
    </div>
  )
}
