import { Element as SlateElement } from "slate"
import { useSlate } from "slate-react"

import Button, {
  ButtonProps,
} from "app/components/RichTextEditor/components/Button"
import Icon, { IconProps } from "app/components/RichTextEditor/components/Icon"
import {
  isBlockActive,
  toggleBlock,
} from "app/components/RichTextEditor/utils/block"

export type BlockButtonProps = {
  format: SlateElement["type"]
  icon: IconProps["name"]
} & Omit<ButtonProps, "active" | "onMouseDown">

export default function BlockButton({
  format,
  icon,
  ...props
}: BlockButtonProps) {
  const editor = useSlate()
  return (
    <Button
      {...props}
      active={isBlockActive(editor, format)}
      onMouseDown={(event) => {
        if (props.disabled) {
          return
        }

        event.preventDefault()
        toggleBlock(editor, format)
      }}
    >
      <Icon name={icon} />
    </Button>
  )
}
