import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"

import { FuseSplashScreen } from "@fuse"
import _ from "lodash"
import { Redirect } from "react-router-dom"

import { getAPIEndpoint } from "app/api"
import { handleApiError } from "app/utils"

/**
 * The loader page is used for opening urls from the backend that are authenticated and require some time to generate.
 * For example when requisitions are requested these should only be accessible by logged in users, and they take
 * a few seconds to be created. This component extracts the API endpoint from the url parameter. i.e.
 * "/api/order/1030/labcompanyrequisition/17/" and makes a request to the API using this path.
 *
 * This should only be used for API endpoints that return a URL to be opened by the client.
 */

// List of regexs that the endpoint parameter must match in order for this loader to run.
// This is just an extra security precaution to prevent any endpoint being hit using this URL.
const ALLOWED_ENDPOINTS = [/api\/order\/\d+\/labcompanyrequisition\/\d+\//]

function Loader(props) {
  const dispatch = useDispatch()

  const [invalidEndpoint, setInvalidEndpoint] = useState(false)

  useEffect(() => {
    async function handleCallback() {
      const urlParams = new URLSearchParams(window.location.search)
      const endpoint = urlParams.get("endpoint")

      const allowedEndpoint = _.some(ALLOWED_ENDPOINTS, (regex) =>
        endpoint.match(regex)
      )

      if (!allowedEndpoint) {
        setInvalidEndpoint(true)
        return
      }

      getAPIEndpoint(endpoint)
        .then((response) => {
          window.open(response.data, "_self")
        })
        .catch((error) => dispatch(handleApiError(error)))
    }

    handleCallback()
  }, [dispatch])

  if (invalidEndpoint) {
    return <Redirect to={"/errors/404"} />
  }

  return <FuseSplashScreen />
}

export default Loader
