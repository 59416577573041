import { useHistory } from "react-router-dom"

import { getFoodPlanPath } from "app/food-plans/utils/paths"
import useEventCallback from "app/hooks/use-event-callback"
import useHandleApiError from "app/hooks/use-handle-api-error"
import { getIdentifier } from "app/swr/helpers/resource"
import useMutateResource from "app/swr/hooks/use-mutate-resource"
import { ResourceResponse } from "app/swr/types"
import resourceRequest from "app/swr/utils/resource-request"
import { FoodPlan, FoodPlanCreate } from "types/food-plan"

/**
 * Hook that returns a function that starts a food plan for a patient.
 *
 * @returns a function that takes a patient ID and starts a food plan for that patient
 */
export default function useStartFoodPlan() {
  const history = useHistory()
  const handleApiError = useHandleApiError()
  const mutateResource = useMutateResource()

  return useEventCallback(async (patientId: string) => {
    try {
      const { data } = await resourceRequest<
        ResourceResponse<FoodPlan>,
        FoodPlanCreate
      >({
        method: "post",
        url: "/food_plans/",
        include: ["latest_version"],
        data: {
          data: {
            type: "food_plan",
            relationships: {
              patient: {
                data: {
                  type: "patient",
                  id: patientId,
                },
              },
            },
          },
        },
      })

      // Pre-populate the SWR cache with the returned data
      await mutateResource<FoodPlan>(getIdentifier(data), data)

      history.push(getFoodPlanPath(patientId, data.id))
    } catch (error) {
      handleApiError(error)
    }
  })
}
