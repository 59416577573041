import { useEffect } from "react"

import { useContextSelector } from "use-context-selector"

import { getNormalizedApiUrl } from "app/utils"

import makeFetcher, { FetcherResponse, JSONAPIError } from "./make-fetcher"
import makeProvider from "./make-provider"
import { Resource } from "./types"

export default function makeResource<ValidResource extends Resource>() {
  const fetcher = makeFetcher<ValidResource>()
  const { defaultState, ResourceContext, ResourceProvider } =
    makeProvider<ValidResource>()
  type ResourceState = typeof defaultState

  function useResourceDispatch() {
    return useContextSelector(ResourceContext, (state) => state.dispatch)
  }

  // Type map used for getting the resource by its type.
  type TypeToResource = {
    [R in ValidResource as R["type"]]: R
  }

  /**
   * Retrieves a resource for the given type and ID.
   *
   * @param type the resource type
   * @param [id] the ID of the resource
   * @param [options] the options to configure the resource fetch
   * @param [options.accessToken] the access token to provide as a bearer token for the request
   * @param [options.include=[]] the relationships to include in the API request as `?include` parameter
   * @param [options.refresh=false] whether or not the refresh the data even if there is a cached value
   * @returns the resource object response
   */
  function useResource<TypedResource>(
    key: string,
    options: {
      accessToken?: string | (() => Promise<string | undefined>)
      include?: string[]
      refresh?: boolean
    } = {}
  ) {
    const { accessToken, include, refresh = false } = options
    const dispatch = useResourceDispatch()

    let url = `${getNormalizedApiUrl()}/${key}`
    if (include) {
      url = `${url}?include=${include.join(",")}`
    }

    const request = useContextSelector(ResourceContext, (state) => {
      return key && key in state.requests ? state.requests[key] : undefined
    })

    const currentValue = useContextSelector(ResourceContext, (state) => {
      const request = state.requests?.[key]
      if (!request || request.status !== "success") {
        return undefined
      }

      // If request.entities is an array, this indicates that the response had multiple objects and we should return a list of objects
      if (request.type === "list") {
        return request.entities.map(
          ({ id, type }) => state.entities?.[type]?.[id]
        )
      } else {
        return state.entities?.[request.entity.type]?.[request.entity.id]
      }
    })

    // Only when access token is provided directly do we want it to cause a refresh of the fetch effect.
    const accessTokenRefreshKey =
      typeof accessToken === "function" ? null : accessToken

    useEffect(() => {
      if (!url) {
        return
      }

      if (!key) {
        return
      }

      if (!refresh && currentValue) {
        return
      }

      if (request?.status === "pending") {
        return
      }

      ;(async () => {
        try {
          dispatch({ type: "fetch_resource_pending", payload: { key } })

          const response = await fetcher(url, {
            accessToken,
          })

          dispatch({
            type: "fetch_resource_success",
            payload: { ...response, key },
          })
        } catch (error) {
          if (error instanceof JSONAPIError) {
            dispatch({
              type: "fetch_resource_error",
              payload: { key, error },
            })
          } else {
            throw error
          }
        }
      })()
    }, [accessTokenRefreshKey, url, key])

    const updateCache = (payload: FetcherResponse<ValidResource>) => {
      dispatch({
        type: "update_resource_cache_success",
        payload: { ...payload, key },
      })
    }

    type ErrorResourceObject = {
      data: null
    } & {
      ready: false
      errors: JSONAPIError["errors"]
      updateCache: (payload: FetcherResponse<ValidResource>) => void
    }

    type UnreadyResourceObject = {
      data: null
    } & {
      ready: false
      errors: null
      updateCache: (payload: FetcherResponse<ValidResource>) => void
    }

    type ReadyResourceObject = {
      data: TypedResource
    } & {
      ready: true
      errors: null
      updateCache: (payload: FetcherResponse<ValidResource>) => void
    }

    if (currentValue) {
      return {
        data: currentValue,
        errors: null,
        ready: true,
        updateCache,
      } as ReadyResourceObject
    }

    if (request?.status === "error" && request.error) {
      return {
        data: null,
        errors: request?.error?.errors || null,
        ready: false,
        updateCache,
      } as ErrorResourceObject
    }

    return {
      data: null,
      errors: null,
      ready: false,
      updateCache,
    } as UnreadyResourceObject
  }

  /**
   * Selects from the entities table any relevant data. Useful for fetching relationships in bulk.
   *
   * @param selector the selector function
   * @returns the selected value(s)
   */
  function useResourceSelector<ReturnValue extends unknown>(
    selector: (entities: ResourceState["entities"]) => ReturnValue
  ) {
    return useContextSelector(ResourceContext, (state) =>
      selector(state.entities)
    )
  }

  /**
   * Selects and returns the resource with the given ID.
   *
   * @param type the resource type
   * @param id the ID of the resource
   * @returns the resource or undefined if not found
   */
  function useResourceByIdSelector<
    Type extends keyof TypeToResource,
    TypedResource extends TypeToResource[Type]
  >(type: Type, id?: string) {
    return useResourceSelector((entities) =>
      id ? (entities[type]?.[id] as TypedResource) : undefined
    )
  }

  return {
    defaultState,
    useResource,
    useResourceByIdSelector,
    useResourceSelector,
    ResourceProvider,
  }
}
