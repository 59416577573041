import { colors } from "@material-ui/core"

import { ReactComponent as PanelBuilderIcon } from "app/assets/icons/panel-builder.svg"
import Button from "app/components/design-system/Button"
import makeAppStyles from "app/utils/makeAppStyles"

const useStyles = makeAppStyles((theme) => ({
  container: {
    height: 251,
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  content: {
    height: 127,
    maxWidth: 370,
    textAlign: "center",
  },
  icon: {},
  iconContainer: {
    width: 81,
    height: 97,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "0 auto",
  },
  text: {
    color: colors.blueGrey[500],
    fontSize: 15,
    fontWeight: 600,
  },
  button: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    width: "100%",
  },
}))

export default function PanelsNotSearchingState({
  hasSelectedBiomarkers,
  setIsSearching,
}: {
  hasSelectedBiomarkers: boolean
  setIsSearching: (isSearching: boolean) => void
}) {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <div className={classes.iconContainer}>
          <PanelBuilderIcon className={classes.icon} />
        </div>
        <div className={classes.text}>
          {"Start by identifying the biomarkers for your panel."}
        </div>
        <Button
          className={classes.button}
          color="primary"
          disabled={!hasSelectedBiomarkers}
          onClick={() => setIsSearching(true)}
          type="button"
        >
          {"Search for Panels"}
        </Button>
      </div>
    </div>
  )
}
