import { DropdownMenu } from "app/components/DropdownMenu"

import PaymentDownloadButton from "./PaymentDownloadButton"
import PaymentDropdownItem from "./PaymentDropdownItem"
import { OrderPaymentDisplayType } from "./helpers"

interface Props {
  payments: OrderPaymentDisplayType[]
}

const DropdownOrButton: React.FC<Props> = ({ payments }) => {
  // If there's only 1, or if all of them are disabled, return a single button
  if (payments.length === 1 || payments.every((payment) => payment.disabled)) {
    return <PaymentDownloadButton payment={payments[0]} />
  }

  // If there's more than 1, and at least one is enabled, return a dropdown
  return (
    <DropdownMenu
      base={<PaymentDownloadButton payment={{ type: payments[0].type }} />}
    >
      {payments.map((payment, idx) => (
        <PaymentDropdownItem key={idx} payment={payment} />
      ))}
    </DropdownMenu>
  )
}

export default DropdownOrButton
