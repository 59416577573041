import { useState } from "react"

import useSnippetFormModal from "./hooks/use-snippet-form-modal"
import useSnippets from "./hooks/use-snippets"
import EmptySnippetsTable from "./table/EmptySnippetsTable"
import SnippetsTable from "./table/SnippetsTable"
import SnippetsTableLoader from "./table/SnippetsTableLoader"

const ResultSummarySnippetsTable = () => {
  const [currPage, setCurrPage] = useState(1)

  const {
    data: snippets,
    paginationInfo,
    isLoading,
    isValidating,
    updateSnippet,
    deleteSnippet,
  } = useSnippets({
    snippetType: "results_summary",
    page: currPage,
  })

  const [isSnippetUpdateLoading, setIsSnippetUpdateLoading] = useState(false)
  const [isSnippetDeleteLoading, setIsSnippetDeleteLoading] = useState(false)

  const snippetFormModal = useSnippetFormModal()

  const handleDelete = async (snippetId: number) => {
    setIsSnippetDeleteLoading(true)

    await deleteSnippet(snippetId)
    await setIsSnippetDeleteLoading(false)

    snippetFormModal.remove()
  }

  const handleSave = async (snippetData: any, snippetId?: number) => {
    if (!snippetId) {
      return
    }

    setIsSnippetUpdateLoading(true)

    await updateSnippet(snippetId, snippetData)

    setIsSnippetUpdateLoading(false)

    snippetFormModal.remove()
  }

  if (isLoading) {
    return <SnippetsTableLoader />
  }

  if (snippets?.length === 0) {
    return <EmptySnippetsTable tableType="Results Summary" />
  }

  return (
    <SnippetsTable
      snippets={snippets}
      page={currPage}
      totalNumResults={paginationInfo?.count ?? 0}
      isValidating={isValidating}
      onChangePage={setCurrPage}
      onEditClick={(snippet) =>
        snippetFormModal.show({
          snippet,
          title: "Edit Results Summary Snippet",
          isSaveLoading: isSnippetUpdateLoading,
          isDeleteLoading: isSnippetDeleteLoading,
          useRichTextEditor: true,
          onClose: snippetFormModal.remove,
          handleDelete,
          handleSave,
        })
      }
    />
  )
}

export default ResultSummarySnippetsTable
