import Dialog from "@material-ui/core/Dialog"

import Loading from "app/components/Loading"
import { ModalHeader } from "app/components/modals"
import useAppSelector from "app/hooks/useAppSelector"
import resourceRequest from "app/swr/utils/resource-request"

import { DisplayLabCompany } from "../../types"
import AddAccountForm from "./AddAccountForm"

interface AddAccountModalProps {
  open: boolean
  onClose: () => void
  displayCompany: DisplayLabCompany
  mutateLabCompanyAccounts: () => void
}

export function AddAccountModal({
  open,
  onClose,
  displayCompany,
  mutateLabCompanyAccounts,
}: AddAccountModalProps) {
  const practitioner = useAppSelector(({ practitioner }) => practitioner)
  const clinic = practitioner.clinic

  const defaultFormValues = {
    practiceName: clinic?.name || "",
    practiceStreetAddress: clinic?.street || "",
    practiceCity: clinic?.city || "",
    practiceState: clinic?.state || "",
    practiceZip: clinic?.zipcode || "",
    clinicPhoneNumber: clinic?.phone_number || "",
    clinicWebsite: "",
    practiceNPI: "",
    providerName: practitioner?.user?.full_name || "",
    providerNPI: practitioner?.npi_number || "",
    accountNumber: "",
    accountRep: "",
    accountRepEmail: "",
  }

  const onSubmit = async (form) => {
    const post = {
      data: {
        type: "lab_company_account",
        attributes: {
          practitioner: practitioner.id,
          lab_companies: [displayCompany.key],
          registration_account_id: form.accountNumber,
          default_for_insurance_orders: false,
          extra_registration_data: { ...form },
        },
      },
    }
    try {
      await resourceRequest({
        method: "POST",
        url: `/lab_company_account/`,
        data: post,
      })
      await mutateLabCompanyAccounts()
      onClose()
    } catch (e) {}
  }

  return (
    <Dialog
      maxWidth="md"
      fullWidth
      onClose={onClose}
      aria-labelledby="add-account-modal-title"
      open={open}
    >
      <ModalHeader
        title={`Connect Your ${displayCompany.display} Account`}
        subtitle={`Enter your ${displayCompany.display} account details below. Approval typically takes 2 weeks. We'll email you when the connection is complete.`}
        onClose={onClose}
        ariaLabelId={"add-account-modal-title"}
      />
      {practitioner ? (
        <AddAccountForm
          companyName={displayCompany.display}
          defaultFormValues={defaultFormValues}
          onSubmit={(formVals) => onSubmit(formVals)}
          onClose={onClose}
        />
      ) : (
        <Loading ariaLabel="loading account information" />
      )}
    </Dialog>
  )
}
