import { Resource, ResourceIdentifier } from "app/swr/types"

/**
 * Returns the resource identifier for the given resource.
 *
 * @param resource the resource
 * @returns the resource identifier
 */
export function getIdentifier(resource: Resource): ResourceIdentifier {
  return {
    type: resource.type,
    id: resource.id,
  }
}
