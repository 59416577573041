import { ReactComponent as NumberSquareIcon } from "app/assets/icons/number-square.svg"
import {
  ComparisonBiomarkersSearchGrid,
  ComparisonRow,
  ComparisonColumnProps,
  EmptyComparisonValue,
} from "app/main/comparison/components/ComparisonLabTests/components"
import useAnyComparedLabTestHasProperty from "app/main/comparison/hooks/use-any-compared-lab-test-has-property"

const TotalNumberOfBiomarkersColumn = ({ labTest }: ComparisonColumnProps) => {
  if (!labTest?.biomarkers) {
    return <EmptyComparisonValue />
  }
  return <>{`${labTest.biomarkers.length}`}</>
}

export default function ComparisonBiomarkersRow() {
  const hasBiomarkers = useAnyComparedLabTestHasProperty("biomarkers")
  if (!hasBiomarkers) {
    return null
  }
  return (
    <>
      <ComparisonRow
        Column={TotalNumberOfBiomarkersColumn}
        headerIcon={<NumberSquareIcon viewBox="0 0 12 13" />}
        headerLabel="Total Number of Biomarkers"
      />
      <ComparisonBiomarkersSearchGrid />
    </>
  )
}
