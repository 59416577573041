/**
 * The width of a column within the comparison grid.
 */
export const COLUMN_WIDTH = 320

/**
 * The padding on the scroll container.
 */
export const SCROLL_CONTAINER_PADDING = 22

/**
 * The padding on the section.
 */
export const SECTION_PADDING = 24

/**
 * The column padding.
 */
export const COLUMN_PADDING = 22

/**
 * The timeout used for transitioning a column in/out.
 */
export const COLUMN_TRANSITION_TIMEOUT = 400

/**
 * Name of the url parameter used for storing the compared test ID
 */
export const COMPARISON_TEST_ID_PARAM = "compared_test"
