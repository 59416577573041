import { useEffect, useState } from "react"

import { isEmpty } from "lodash"

import * as DropdownMenu from "@radix-ui/react-dropdown-menu"

import DisplayText from "app/components/design-system/DisplayText"
import useEventCallback from "app/hooks/use-event-callback"
import ToggleAddTaskButton from "app/results-summary/components/ResultsSummaryEditor/components/AddTask/ToggleAddTaskButton"
import EditorFormPlaceholder from "app/results-summary/components/ResultsSummaryEditor/components/EditorFormPlaceholder"
import SortableTaskListEditor from "app/results-summary/components/ResultsSummaryEditor/components/SortableTaskListEditor"
import useCreateInterpretationTask from "app/results-summary/hooks/use-create-interpretation-task"
import useReorderTasks from "app/results-summary/hooks/use-reorder-tasks"
import { UseResultsVisualizerResourcesHook } from "app/results-summary/hooks/use-results-summary-resources"
import { ResourceIdentifier } from "app/swr/types"
import { bodyFontFamilyImportant, colors } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"
import { TaskTypesLiteral } from "types/results-interpretation"

import AddTask from "./AddTask"
import FormSection from "./FormSection"
import TaskRenderer from "./TaskRenderer"

const useStyles = makeAppStyles((theme) => ({
  title: {
    color: colors.blueGray[500],
    fontSize: 13,
    fontFamily: bodyFontFamilyImportant,
    textTransform: "uppercase",
  },
  tasksList: {
    width: "100%",
    display: "flex",
    flexFlow: "column nowrap",
    marginTop: theme.spacing(1.0),
    gap: theme.spacing(2.0),
  },
  addTaskSlot: {
    width: "100%",
    marginTop: theme.spacing(2.0),
    display: "flex",
    gap: theme.spacing(1.0),
  },
  divider: {
    background: colors.blueGray[100],
  },
}))

const TaskListRenderer = ({
  identifiers,
}: {
  identifiers: ResourceIdentifier[]
}) => {
  const classes = useStyles()
  return (
    <div className={classes.tasksList}>
      {identifiers.map((identifier) => (
        <TaskRenderer key={identifier.id} identifier={identifier} />
      ))}
    </div>
  )
}

const TasksFormEditing = ({
  hasEmptyState,
  identifiers,
  isAdding,
  isCreating,
  onCreate,
  onReorder,
  onToggleAdding,
}: {
  hasEmptyState: boolean
  identifiers: ResourceIdentifier[]
  isAdding: boolean
  isCreating: boolean
  onCreate: (taskType: TaskTypesLiteral) => void
  onReorder: (identifiers: ResourceIdentifier[]) => Promise<any>
  onToggleAdding: () => void
}) => {
  const classes = useStyles()
  return (
    <>
      {!hasEmptyState && (
        <SortableTaskListEditor
          identifiers={identifiers}
          onReorder={onReorder}
        />
      )}
      <div className={classes.addTaskSlot}>
        <AddTask
          isAdding={isAdding}
          onCreate={onCreate}
          onToggleAdding={onToggleAdding}
          trigger={
            <ToggleAddTaskButton disabled={isCreating} loading={isCreating} />
          }
        />
      </div>
    </>
  )
}

const TasksFormPlaceholder = ({
  isAdding,
  isCreating,
  onCreate,
  onToggleAdding,
}: {
  isAdding: boolean
  isCreating: boolean
  onCreate: (taskType: TaskTypesLiteral) => void
  onToggleAdding: () => void
}) => {
  const classes = useStyles()
  return (
    <div className={classes.addTaskSlot}>
      <AddTask
        isAdding={isAdding}
        onCreate={onCreate}
        onToggleAdding={onToggleAdding}
        trigger={
          // Use the placeholder as a trigger for the add menu.
          <DropdownMenu.Trigger asChild>
            <EditorFormPlaceholder
              addLabel="Add Task"
              loading={isCreating}
              type="task"
            />
          </DropdownMenu.Trigger>
        }
      />
    </div>
  )
}

const TasksFormRenderer = ({
  identifiers,
  isAdding,
  isCreating,
  onCreate,
  onToggleAdding,
}: {
  identifiers: ResourceIdentifier[]
  isAdding: boolean
  isCreating: boolean
  onCreate: (taskType: TaskTypesLiteral) => void
  onToggleAdding: () => void
}) => {
  const classes = useStyles()
  return (
    <>
      <TaskListRenderer identifiers={identifiers} />
      <div className={classes.addTaskSlot}>
        <AddTask
          isAdding={isAdding}
          onCreate={onCreate}
          onToggleAdding={onToggleAdding}
          trigger={
            <ToggleAddTaskButton disabled={isCreating} loading={isCreating} />
          }
        />
      </div>
    </>
  )
}

export interface TasksFormProps {
  interpretationSwr: UseResultsVisualizerResourcesHook["interpretationSwr"]
}

export default function TasksForm({ interpretationSwr }: TasksFormProps) {
  const classes = useStyles()
  const { data: interpretation } = interpretationSwr
  const [isAdding, setIsAdding] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const tasksRelationship = interpretation?.relationships?.tasks
  const hasEmptyState = isEmpty(tasksRelationship?.data)

  const reorderTasks = useReorderTasks(interpretationSwr)

  /**
   * Toggle editing state and reset adding state.
   */
  const handleToggleEditing = useEventCallback(() => {
    const nextIsEditing = !isEditing

    setIsEditing(nextIsEditing)
    if (!nextIsEditing) {
      setIsAdding(false)
    }
  })

  /**
   * Toggle adding state and set editing state to true.
   */
  const handleToggleAdding = useEventCallback(() => {
    const nextIsAdding = !isAdding

    setIsAdding(nextIsAdding)

    if (!nextIsAdding && hasEmptyState) {
      setIsEditing(false)
    }
  })

  const { createInterpretationTask, isCreating } = useCreateInterpretationTask(
    interpretation,
    () => setIsEditing(true)
  )

  useEffect(() => {
    if (hasEmptyState && isEditing && !isAdding) {
      setIsEditing(false)
    }
  }, [hasEmptyState, isEditing, isAdding])

  return (
    <FormSection
      disableEditButton={isCreating}
      isEditing={isEditing}
      onToggleEditing={handleToggleEditing}
      showEditButton={isEditing || !hasEmptyState}
      title={
        <DisplayText
          className={classes.title}
          size="base"
          variant="h4"
          weight="semibold"
        >
          {"Tasks"}
        </DisplayText>
      }
    >
      {isEditing ? (
        <TasksFormEditing
          hasEmptyState={hasEmptyState}
          isAdding={isAdding}
          identifiers={tasksRelationship?.data ?? []}
          isCreating={isCreating}
          onCreate={createInterpretationTask}
          onReorder={reorderTasks}
          onToggleAdding={handleToggleAdding}
        />
      ) : hasEmptyState ? (
        <TasksFormPlaceholder
          isAdding={isAdding}
          isCreating={isCreating}
          onCreate={createInterpretationTask}
          onToggleAdding={handleToggleAdding}
        />
      ) : (
        <TasksFormRenderer
          identifiers={tasksRelationship?.data || []}
          isAdding={isAdding}
          isCreating={isCreating}
          onCreate={createInterpretationTask}
          onToggleAdding={handleToggleAdding}
        />
      )}
    </FormSection>
  )
}
