import moment from "moment"

import BodyText from "app/components/design-system/BodyText"
import makeAppStyles from "app/utils/makeAppStyles"

const useStyles = makeAppStyles(() => ({
  container: {
    whiteSpace: "nowrap",
  },
}))

export default function DateTableCell({ date }) {
  const classes = useStyles()
  return (
    <BodyText className={classes.container}>
      {moment(date).format("LL")}
    </BodyText>
  )
}
