import { useEffect, useState } from "react"

import { cn } from "@rupahealth/design"

import RupaLoadingButton, {
  RupaLoadingButtonProps,
} from "app/components/RupaLoadingButton"
import useAppSelector from "app/hooks/useAppSelector"
import { selectPractitioner } from "app/store/selectors/practitioner.selectors"
import { Practitioner } from "app/types"

import { CredentialsModalProps, useCredentialsModal } from "./CredentialsModal"
import useCredentialTracking, {
  CredentialEvents,
} from "./use-credential-tracking"

export type CredentialModalButtonProps = Omit<
  CredentialsModalProps,
  "practitioner"
> &
  Omit<RupaLoadingButtonProps, "onClick" | "loading" | "children"> & {
    // Allow for overriding the default click behavior
    handleClick?: (openModal: () => void) => void
    children?: React.ReactNode
  }

const CredentialModalButton: React.FC<CredentialModalButtonProps> = ({
  handleClick,
  onClose,
  onLicenseUpload,
  variant = "outline-primary",
  disabled,
  className,
  children = "Upload Credentials & E-Signature",
  autoClose,
  location,
  ...buttonProps
}) => {
  const practitioner = useAppSelector(selectPractitioner)
  const [loading, setLoading] = useState(false)
  const modal = useCredentialsModal()
  const trackEvent = useCredentialTracking({ location, practitioner })

  const showModal = (practitioner: Practitioner) => {
    trackEvent(CredentialEvents.CREDENTIAL_UPLOAD_MODAL_OPENED)
    modal.show({ location, practitioner, autoClose, onClose, onLicenseUpload })
  }

  const onClick = () => {
    const openModal = () => {
      if (!practitioner) {
        setLoading(true)
        return
      }
      showModal(practitioner)
    }

    handleClick ? handleClick(openModal) : openModal()
  }

  useEffect(() => {
    if (loading && practitioner && !modal.visible) {
      setLoading(false)
      showModal(practitioner)
    }
  }, [loading, practitioner, modal])

  return (
    <RupaLoadingButton
      loading={loading}
      disabled={disabled || modal.visible}
      variant={variant}
      className={cn("shadow-sm whitespace-break-spaces h-auto", className)}
      onClick={onClick}
      {...buttonProps}
    >
      {children}
    </RupaLoadingButton>
  )
}

export default CredentialModalButton
