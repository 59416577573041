import { ReactComponent as ChartIcon } from "app/assets/icons/chart-sharp.svg"
import { ReactComponent as CubeIcon } from "app/assets/icons/cube.svg"
import { ReactComponent as FileIcon } from "app/assets/icons/file.svg"
import PageTabs, { PageTab } from "app/components/PageTabs/PageTabs"

import { DOCUMENT_TYPE_QUERY_PARAM_KEY } from "./patient-documents/DocumentTypeSelect"

export enum PatientDetailsTabId {
  ORDERS = "orders",
  DOCUMENTS = "documents",
  RESULTS_OVER_TIME = "results-over-time",
}

export const PATIENT_DETAILS_TAB_DEFAULT = PatientDetailsTabId.ORDERS

const PatientDetailsTabs: React.FC = () => {
  const tabs: PageTab[] = [
    {
      id: PatientDetailsTabId.ORDERS,
      LabelIcon: <CubeIcon fill="currentColor" />,
      label: "Lab Orders",
      show: true,
    },
    {
      id: PatientDetailsTabId.RESULTS_OVER_TIME,
      LabelIcon: <ChartIcon fill="currentColor" />,
      label: "Trends",
      show: true,
    },
    {
      id: PatientDetailsTabId.DOCUMENTS,
      LabelIcon: <FileIcon fill="currentColor" />,
      label: "Documents",
      show: true,
    },
  ]

  return (
    <PageTabs
      tabs={tabs}
      defaultTabId={PATIENT_DETAILS_TAB_DEFAULT}
      paramsToRemoveOnTabChange={["orderId", DOCUMENT_TYPE_QUERY_PARAM_KEY]}
    />
  )
}

export default PatientDetailsTabs
