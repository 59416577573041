import { useState } from "react"
import * as React from "react"

import { Typography } from "@material-ui/core"

import { ReactComponent as InfoCircleDarkIcon } from "app/assets/images/info-circle-dark.svg"
import Tooltip from "app/components/Tooltip"

export type InfoTextTooltipProps = Pick<
  React.ComponentProps<typeof Tooltip>,
  "disableTouchListener" | "disableFocusListener" | "disableHoverListener"
> & {
  children: NonNullable<React.ReactNode>
  className?: string
  iconFill?: string
  style?: { [key: string]: string }
  alternateIcon?: React.ReactNode
}

/**
 * Renders an info icon with a popover tooltip on hover. Useful for adding additional context to a label.
 */
const InfoTextTooltip = ({
  children,
  className,
  iconFill = "#95A2AF",
  style,
  alternateIcon,
  ...tooltipProps
}: InfoTextTooltipProps) => {
  const [open, setOpen] = useState(false)
  return (
    <Tooltip
      arrow
      interactive
      placement="bottom"
      disableTouchListener
      open={open}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      title={children}
      {...tooltipProps}
    >
      <Typography
        className={className}
        component="span"
        onClick={() => setOpen((prevOpen) => !prevOpen)}
        style={style}
      >
        {alternateIcon ?? (
          <InfoCircleDarkIcon fill={iconFill} viewBox="0 0 15 15" />
        )}
      </Typography>
    </Tooltip>
  )
}

export default InfoTextTooltip
