import ComparisonLabTests from "../ComparisonLabTests"
import ComparisonModalContent from "./ComparisonModalContent"
import ComparisonModalDialog from "./ComparisonModalDialog"
import ComparisonModalTitle from "./ComparisonModalTitle"

export default function ComparisonModal() {
  return (
    <ComparisonModalDialog>
      <ComparisonModalTitle />
      <ComparisonModalContent>
        <ComparisonLabTests />
      </ComparisonModalContent>
    </ComparisonModalDialog>
  )
}
