import { RootState } from "app/types"

import * as Actions from "../actions/labTestType.actions"

const initialState: RootState["labTestType"] = {
  list: null,
}

function labTestTypeReducer(
  state = initialState,
  action: Actions.GetLabTestTypeListAction
): typeof initialState {
  switch (action.type) {
    case Actions.GET_LAB_TEST_TYPE_LIST_SUCCESS: {
      return {
        ...state,
        list: action.payload,
      }
    }
    default: {
      return state
    }
  }
}

export default labTestTypeReducer
