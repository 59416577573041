import { ComponentProps } from "react"
import * as React from "react"

import useIsFoodPlansFeature from "app/food-plans/hooks/use-is-food-plans-feature"

export default function withIsFoodPlansFeature<
  C extends React.ComponentType<any>
>(Component: C) {
  return function WithIsFoodPlansFeature(props: ComponentProps<C>) {
    const enabled = useIsFoodPlansFeature()
    if (enabled) {
      return <Component {...props} />
    }
    return null
  }
}
