import { faArrowRight } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Link } from "@material-ui/core"

import { ReactComponent as BooksIcon } from "app/assets/icons/books.svg"
import { REFERENCE_GUIDE_URL } from "app/constants"

const LabOrderingAccessReferenceGuideBanner: React.FC = () => {
  return (
    <div className="flex gap-3 items-center justify-center">
      <span
        className="rounded-lg py-[11.5px] px-[8.5px] border-white border-[3px]"
        style={{
          background: "linear-gradient(166.1deg, #FDE9F1 0%, #DFE9FF 100%)",
        }}
      >
        <BooksIcon className="block m-auto h-full" />
      </span>
      <span className="font-semibold">
        <Link
          href={REFERENCE_GUIDE_URL}
          target="_blank"
          rel="noopener noreferrer"
          className="text-body"
        >
          Learn more about each of our lab partners in the Reference Guide{" "}
          <FontAwesomeIcon icon={faArrowRight} />
        </Link>
      </span>
    </div>
  )
}

export default LabOrderingAccessReferenceGuideBanner
