import { API } from "app/api"
import { AppThunkAction, LabTestType } from "app/types"
import { handleApiError } from "app/utils"

export const GET_LAB_TEST_TYPE_LIST_SUCCESS = "GET LAB TEST TYPE LIST SUCCESS"

export interface GetLabTestTypeListAction {
  type: typeof GET_LAB_TEST_TYPE_LIST_SUCCESS
  payload: LabTestType[]
}

export type ActionTypes = GetLabTestTypeListAction

export function getLabTestTypeList(): AppThunkAction<void> {
  return async (dispatch) => {
    try {
      const response = await API.LabTestType.getList()
      dispatch({
        type: GET_LAB_TEST_TYPE_LIST_SUCCESS,
        payload: response.data.results,
      })
    } catch (error) {
      dispatch(handleApiError(error))
    }
  }
}
