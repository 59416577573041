import { useContext } from "react"

import ComparisonContext from "../contexts/ComparisonContext"

/**
 * Hook that returns the current comparison state.
 *
 * @returns the comparison state
 */
export default function useComparisonState() {
  return useContext(ComparisonContext)[0]
}
