import * as React from "react"

import { makeStyles, Theme } from "@material-ui/core/styles"

import { colors } from "app/theme"

export enum WarningType {
  WARNING = "WARNING",
  ERROR = "ERROR",
}
const WARNING_COLORS = {
  [WarningType.WARNING]: {
    background: colors.amber[50],
    border: colors.amber[300],
    text: colors.amber[900],
  },
  [WarningType.ERROR]: {
    background: colors.red[50],
    border: colors.red[200],
    text: colors.red[900],
  },
}

const useStyles = makeStyles<
  Theme,
  { warningType: WarningType; condensed: boolean }
>((theme) => ({
  root: {
    border: ({ warningType }) =>
      `1px solid ${WARNING_COLORS[warningType].border}`,
    backgroundColor: ({ warningType }) =>
      WARNING_COLORS[warningType].background,
    padding: ({ condensed }) => (condensed ? "7px 8px" : "8px 11px"),
    borderRadius: 6,
    color: ({ warningType }) => WARNING_COLORS[warningType].text,
    lineHeight: ({ condensed }) => (condensed ? "135%" : "auto"),
    fontSize: ({ condensed }) => (condensed ? "14px" : "15px"),
  },
}))

export default function WarningContainer({
  children,
  warningType = WarningType.WARNING,
  condensed = false,
}: {
  children: React.ReactNode
  warningType?: WarningType
  condensed?: boolean
}) {
  const classes = useStyles({ warningType, condensed })

  return <div className={classes.root}>{children}</div>
}
