import { Link } from "@material-ui/core"

import { ReactComponent as CircleQuestionIcon } from "app/assets/icons/circular/circle-question.svg"
import InfoTextTooltip from "app/components/design-system/InfoTextTooltip"
import { PhysicianServicesOptInModalProps } from "app/components/modals/PhysicianServicesOptInModal/PhysicianServicesOptInModal"
import {
  PHYSICIAN_AUTHORIZATION_GUIDE_URL,
  VENDOR_PHYSICIAN_AUTHORIZATION_LABEL,
} from "app/constants"
import { getOrderPatientLabel } from "app/utils/order-utils"

import OnOffPill from "./OnOffPill"
import TurnOnPhysicianServicesButton from "./TurnOnPhysicianServicesButton"

interface Props extends PhysicianServicesOptInModalProps {
  showTurnOnButton: boolean
  physicianAuthorizationEnabled?: boolean
}

const PhysicianServicesInfoTooltip: React.FC<Props> = ({
  showTurnOnButton,
  physicianAuthorizationEnabled,
  ...psModalProps
}) => {
  return (
    <InfoTextTooltip
      alternateIcon={
        <div
          className="flex items-center gap-x-1 p-2 -m-2"
          onClick={(e) => e.preventDefault()}
        >
          {physicianAuthorizationEnabled !== undefined && (
            <OnOffPill on={physicianAuthorizationEnabled} />
          )}
          <CircleQuestionIcon className="fill-slate-400 shrink-0" />
        </div>
      }
    >
      With {VENDOR_PHYSICIAN_AUTHORIZATION_LABEL}, your{" "}
      {getOrderPatientLabel(true)}s can gain access to testing on Rupa through a
      signing physician.{" "}
      <Link
        href={PHYSICIAN_AUTHORIZATION_GUIDE_URL}
        target="_blank"
        rel="noopener noreferrer"
      >
        <span className="text-body underline">Learn More</span>
      </Link>
      {showTurnOnButton && <TurnOnPhysicianServicesButton {...psModalProps} />}
    </InfoTextTooltip>
  )
}

export default PhysicianServicesInfoTooltip
