import { flatMap, isFunction } from "lodash"

/**
 * Interleaves the provided value in-between each element of the array.
 *
 * @example
 * interleave([1, 3, 5], v => v + 1) // ==> [1, 2, 3, 4, 5]
 *
 * @param array the array to interleave the insert into
 * @param valueOrGetValue the value to insert or a function that returns the value to insert
 * @returns the array with the insert interleaved
 */
export function interleave<T, V>(
  array: T[],
  valueOrGetValue: (elem: T, key: number) => V
): any[]
export function interleave<T, V>(array: T[], valueOrGetValue: V): any[]
export default function interleave<T, V>(
  array: T[],
  valueOrGetValue: ((elem: T, key: number) => V) | V
) {
  return flatMap(array, (elem, key) => [
    elem,
    isFunction(valueOrGetValue) ? valueOrGetValue(elem, key) : valueOrGetValue,
  ]).slice(0, -1)
}
