import { API } from "app/api"
import { AppThunkAction, LabCompany } from "app/types"
import { handleApiError } from "app/utils"

export const GET_LAB_COMPANY_LIST_SUCCESS = "GET LAB COMPANY LIST SUCCESS"

export interface GetLabCompanyListAction {
  type: typeof GET_LAB_COMPANY_LIST_SUCCESS
  payload: LabCompany[]
}

export type ActionTypes = GetLabCompanyListAction

export function getLabCompanyList(): AppThunkAction<void> {
  return async (dispatch) => {
    try {
      const response = await API.LabCompany.getList()
      dispatch({
        type: GET_LAB_COMPANY_LIST_SUCCESS,
        payload: response.data.results,
      })
    } catch (error) {
      dispatch(handleApiError(error))
    }
  }
}
