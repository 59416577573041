import { primaryColor } from "app/theme"

interface Props {
  cx: number
  cy: number
  fillColor: string
  cxDeduction: number
  cyDeduction: number
  width: string
  height: string
  showActiveHighlight: boolean
  showShadows: boolean
}

const CustomDot = ({
  cx,
  cy,
  fillColor,
  cxDeduction,
  cyDeduction,
  width,
  height,
  showActiveHighlight,
  showShadows,
}: Props) => {
  return (
    <svg
      x={cx - cxDeduction}
      y={cy - cyDeduction}
      width={width}
      height={height}
      viewBox="0 0 20 21"
      fill="none"
      style={{ cursor: "pointer" }}
    >
      {showShadows && (
        <circle cx="10" cy="10.5" r="10" fill="rgba(0, 0, 0, 0.1)" />
      )}

      <circle cx="10" cy="10" r="7.5" fill={fillColor} />
      <circle
        cx="10"
        cy="10"
        r="7.5"
        stroke="white"
        strokeWidth={showShadows ? "4" : "2"}
      />
      {showActiveHighlight && (
        <circle cx="10" cy="10" r="9" stroke={primaryColor} strokeWidth="1" />
      )}
    </svg>
  )
}

export default CustomDot
