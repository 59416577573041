import { createContext } from "react"

import { initialState } from "../store/comparison.reducer"
import { ComparisonContextShape } from "../types"

const ComparisonContext = createContext<ComparisonContextShape>([
  initialState,
  () => {},
])

export default ComparisonContext
