import { ORDERED_TEST_STATUS } from "app/constants.typed"

import { StatusConfiguration } from "../../types"
import { StatusConfigurationProps } from "../../types/patient-portal-types"
import { PROGRESS_PERCENTAGES } from "../consts"

const SubTitle = ({ order, practitioner }: StatusConfigurationProps) => {
  return (
    <span>
      {`The authorizing physician is reviewing these lab tests from ${
        practitioner?.attributes.titled_full_name || "your practitioner"
      }.`}
    </span>
  )
}

const pendingPhysServicesAuthStatusConfiguration: StatusConfiguration<StatusConfigurationProps> =
  {
    when: {
      orderedTestStatus: ORDERED_TEST_STATUS.PENDING_PHYSICIAN_AUTHORIZATION,
    },
    progressPercentage: PROGRESS_PERCENTAGES.PENDING_PHYSICIAN_AUTHORIZATION,
    title: "Pending Authorizing Physician Review",
    subTitle: SubTitle,
  }

export default pendingPhysServicesAuthStatusConfiguration
