import { Resource, ResourceRelationshipSingle } from "app/swr/types"

export enum BiologicalSex {
  // Sex following the HL7 code table: https://hl7-definition.caristix.com/v2/HL7v2.7/Tables/0001

  Ambiguous = "A",
  Female = "F",
  Male = "M",
  NOT_APPLICABLE = "N",
  Other = "O",
  Unknown = "U",
}

enum PhaseOfCycle {
  FOLLICULAR = "follicular",
  OVULATION = "ovulation",
  LUTEAL = "luteal",
  POSTMENOPAUSE = "postmenopause",
}

export interface LabCompanyBiomarkerRange extends Resource {
  type: "lab_company_biomarker_range"
  attributes: {
    updated_at: string
    optimal_range_min?: string
    optimal_range_max?: string
    standard_range_min?: string
    standard_range_max?: string
    unit: string
    source: string
    age_min_months?: number
    age_max_months?: number
    biological_sex?: BiologicalSex
    phase_of_cycle?: PhaseOfCycle
  }
  relationships: {
    lab_company: ResourceRelationshipSingle
    biomarker: ResourceRelationshipSingle
  }
}

export interface LabCompanyBiomarkerRangeCreate {
  type: "lab_company_biomarker_range"
  attributes?: Partial<LabCompanyBiomarkerRange["attributes"]>
  relationships: Partial<LabCompanyBiomarkerRange["relationships"]>
}
