import { useMemo } from "react"

import { useForm } from "react-hook-form"
import { z } from "zod"

import { zodResolver } from "@hookform/resolvers/zod"

import {
  PaymentCollectionConfiguration,
  PaymentCollectionFormData,
} from "app/main/payment-collection/types"

const BaseSchema = z.object({
  consent: z
    .boolean({
      required_error: "Must provide your consent to proceed",
    })
    .refine((val) => val === true, "Must provide your consent to proceed"),
  payment_method: z
    .string()
    .nonempty("Must provide the information of a card to be charged"),
})

const HasSplitPaymentSchema = z.object({
  payment_method_secondary: z
    .string()
    .nonempty("Must provide the information of a secondary card to be charged"),
})

function getSchema(config: PaymentCollectionConfiguration) {
  let Schema = BaseSchema

  if (config.is_split_payment) {
    Schema = Schema.merge(HasSplitPaymentSchema)
  }

  return Schema
}

function getDefaultValues(config: PaymentCollectionConfiguration) {
  const defaultValues = {
    consent: false,
    payment_method: "",
  } as PaymentCollectionFormData

  if (config.is_split_payment) {
    defaultValues.payment_method_secondary = ""
  }

  return defaultValues
}

export default function usePaymentCollectionForm(
  config: PaymentCollectionConfiguration
) {
  const resolver = useMemo(() => zodResolver(getSchema(config)), [config])
  const defaultValues = useMemo(() => getDefaultValues(config), [config])
  return useForm<PaymentCollectionFormData>({
    defaultValues,
    mode: "onChange",
    resolver,
  })
}
