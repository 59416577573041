import React from "react"

import { Divider } from "@material-ui/core"
import { Button } from "@rupahealth/design"

import { EMPIRE_DRAW_SITES_URL } from "app/constants"
import { LabCompanySchedulingData } from "app/types"
import { onClickUrl } from "app/utils/link-utils"

interface Props {
  data: LabCompanySchedulingData
  showHeader: Boolean
}

const ScheduleEmpireDetails: React.FC<Props> = ({ data, showHeader }) => {
  return (
    <>
      {showHeader && (
        <>
          <Divider />
          <span className="font-semibold text-lg">
            Empire City Labs Scheduling Details
          </span>
        </>
      )}
      <div className="flex flex-col gap-4">
        <span className="text-base15">
          You’ll need to go to an Empire site to get your blood drawn. Remember
          to bring your ID and have your{" "}
          {data.requisition_form_url ? (
            <a
              target="_blank"
              rel="noreferrer"
              href={data.requisition_form_url}
              className="text-body font-semibold !underline"
            >
              digital requisition
            </a>
          ) : (
            "digital requisition"
          )}{" "}
          ready to show at the time of your appointment.
        </span>
        <Button
          variant="outline-primary"
          className="self-start"
          onClick={onClickUrl(EMPIRE_DRAW_SITES_URL)}
        >
          Schedule Appointment
        </Button>
      </div>
    </>
  )
}

export default ScheduleEmpireDetails
