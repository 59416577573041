import { useState } from "react"
import * as React from "react"
import { useDispatch } from "react-redux"

import { CircularProgress, Link, makeStyles } from "@material-ui/core"

import ArrowTopRight from "app/assets/icons/arrow-top-right.svg"
import { handleApiError } from "app/utils"

type SignInToStripeLinkProps = {
  getDashboardLink: () => Promise<string>
  text?: string
}

const useStyles = makeStyles((theme) => ({
  link: {
    fontWeight: 600,
    cursor: "pointer",
  },
}))

const SignInToStripeLink: React.FC<SignInToStripeLinkProps> = ({
  getDashboardLink,
  text = "Sign into your Stripe Account",
}) => {
  const classes = useStyles()
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()

  const onClick = async (e: React.MouseEvent) => {
    e.preventDefault()
    e.stopPropagation()

    if (loading) {
      return
    }

    try {
      setLoading(true)

      const link = await getDashboardLink()

      window.location.href = link
    } catch (error) {
      dispatch(handleApiError(error))
    } finally {
      setLoading(false)
    }
  }

  return (
    <Link onClick={onClick} className={classes.link}>
      {text}
      <span style={{ marginLeft: 4 }}>
        {loading ? (
          <CircularProgress aria-label="Loading" thickness={5} size={12} />
        ) : (
          <img src={ArrowTopRight} alt="" />
        )}
      </span>
    </Link>
  )
}

export default SignInToStripeLink
