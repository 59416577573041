import BodyText from "app/components/design-system/BodyText"
import formatDateForHuman from "app/utils/format-date-for-human"

import columnHelper from "./column-helper"

const updatedAtColumn = columnHelper.accessor("attributes.updated_at", {
  cell: (info) => (
    <BodyText weight="regular" style={{ whiteSpace: "nowrap" }}>
      {formatDateForHuman(info.getValue())}
    </BodyText>
  ),
  header: () => <BodyText weight="regular">Updated On</BodyText>,
  size: 200,
  minSize: 200,
})

export default updatedAtColumn
