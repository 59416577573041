import { ResourceIdentifier, ResourceResponse } from "app/swr/types"
import resourceRequest from "app/swr/utils/resource-request"
import {
  ResultsInterpretation,
  ResultsInterpretationPatch,
} from "types/results-interpretation"

export async function sendPatchInterpretation(
  identifier: ResourceIdentifier,
  patch: ResultsInterpretationPatch
) {
  return await resourceRequest<ResourceResponse<ResultsInterpretation>>({
    method: "patch",
    url: `/results_interpretations/${identifier?.id}/`,
    data: {
      data: {
        ...identifier,
        ...patch,
      },
    },
  })
}
