import { MAX_DATA_COLUMNS } from "./constants/constants"
import { ResultsOverTimeDateGrouping } from "./types/types"

/**
 * Calculates the cell width for the results over time table.
 *
 * The cell width is calculated by taking the number of dates
 * and dividing 100 (percent) by that number.
 *
 * If the number of dates is greater than the maximum number of
 * columns (MAX_DATA_COLUMNS), the cell width is calculated
 * using the maximum number.
 *
 * The return width should be used as a percentage.
 *
 * @param numDates
 *
 * @returns Width of the cell as a percentage relative to the table
 */
export const calculateTableCellWidth = (
  numDates: number,
  tableWidth?: number
) => {
  let numCells = numDates

  if (numDates > MAX_DATA_COLUMNS) {
    numCells = MAX_DATA_COLUMNS
  } else if (numDates === 0) {
    numCells = 1
  }

  const width = tableWidth ? tableWidth : 1186

  return width / numCells
}

/**
 * Converts a string timestamp for representation in the Results Over Time
 * table header.
 *
 * @param timestamp timestamp to convert
 * @param groupedBy day or month; determines how we convert the timestamp
 * @returns
 */
export const convertTimestampForHeader = (
  timestamp: string,
  groupedBy: ResultsOverTimeDateGrouping
) => {
  if (!timestamp) {
    return ""
  }

  const date = new Date(timestamp)

  if (groupedBy === "month") {
    const months = [
      "JAN",
      "FEB",
      "MARCH",
      "APRIL",
      "MAY",
      "JUNE",
      "JULY",
      "AUG",
      "SEP",
      "OCT",
      "NOV",
      "DEC",
    ]
    const month = months[date.getMonth()]
    const year = date.getFullYear()

    return `${month} ${year}`
  } else {
    // If not grouped by month it is grouped by day
    const options: Intl.DateTimeFormatOptions = {
      year: "numeric" as const,
      month: "short" as const,
      day: "numeric" as const,
    }
    const formattedDate = date.toLocaleDateString("en-US", options)

    return formattedDate
  }
}
