import { useInvalidateCollections } from "app/swr/hooks/use-invalidate-collections"

// Hooks to invalidate a specific subsets of collections that are impacted
// by actions on the documents and trends pages.

export const useInvalidateTrendsAndDocuments = () =>
  useInvalidateCollections([
    "/documents/",
    "/body_system/",
    "/results_over_time/",
    "/user_results/",
  ])

export const useInvalidateTrends = () =>
  useInvalidateCollections([
    "/body_system/",
    "/results_over_time/",
    "/user_results/",
  ])
