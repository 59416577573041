import { LAB_COMPANY_KEY } from "app/constants"

export enum ClinicSettings {
  USE_RUPA_BLOOD_REPORTS = "use_rupa_blood_reports",
  USE_OPTIMAL_RANGES_FOR_BLOOD_REPORTS = "use_optimal_ranges_for_blood_reports",
}

export enum BiomarkerCustomDescriptionStatuses {
  DEFAULT = "Default",
  EDITED = "Edited",
}

export enum OptimalRangeSource {
  // TODO: Remove ODX once we have migrated all ODX ranges to Rupa Health - https://app.asana.com/0/1205298136487588/1205900165584568/f
  ODX = "ODX",
  CLINIC = "Clinic",
  RUPA_HEALTH = "Rupa Health",
}

export const LAB_COMPANY_KEY_OPTIONS = [
  LAB_COMPANY_KEY.ACCESS_MEDICAL_LABS,
  LAB_COMPANY_KEY.LABCORP_BY_ACCESS_MEDICAL_LABS,
  LAB_COMPANY_KEY.BOSTON_HEART,
  LAB_COMPANY_KEY.LABSFIRST_QUEST,
]

export const LAB_COMPANY_KEY_OPTIONS_WITHOUT_LABCORP =
  LAB_COMPANY_KEY_OPTIONS.filter(
    (key) => key !== LAB_COMPANY_KEY.LABCORP_BY_ACCESS_MEDICAL_LABS
  )
