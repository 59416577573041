import { ReactNode } from "react"

import { cn } from "@rupahealth/design"

import InfoTextTooltip from "app/components/design-system/InfoTextTooltip"

export interface FieldLabelProps {
  className?: String
  infoText?: ReactNode
  alternateIcon?: ReactNode
  beforeLabelIcon?: ReactNode
  label?: string | JSX.Element
  required?: boolean
}

const FieldLabel = ({
  className,
  infoText,
  label,
  required,
  alternateIcon,
  beforeLabelIcon,
}: FieldLabelProps) => {
  return (
    <div className="mb-2 flex items-center gap-[6px]">
      {beforeLabelIcon}
      <div
        className={cn("flex items-center gap-[3px] font-semibold", className)}
      >
        <span>{label}</span>
        <span className={cn("text-destructive", { invisible: !required })}>
          *
        </span>
      </div>
      {infoText && (
        <InfoTextTooltip
          className="inline-flex align-middle"
          alternateIcon={alternateIcon}
        >
          {infoText}
        </InfoTextTooltip>
      )}
    </div>
  )
}

export default FieldLabel
