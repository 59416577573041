import { useState } from "react"

import { CircularProgress } from "@material-ui/core"

import { ReactComponent as FoodPlanIcon } from "app/assets/icons/food-plan-white.svg"
import Button from "app/components/design-system/Button"
import withIsFoodPlansFeature from "app/food-plans/components/withIsFoodPlansFeature"
import useStartFoodPlan from "app/food-plans/hooks/use-start-food-plan"
import useEventCallback from "app/hooks/use-event-callback"
import useAppSelector from "app/hooks/useAppSelector"
import makeAppStyles from "app/utils/makeAppStyles"

const useStyles = makeAppStyles((theme) => ({
  button: {
    display: "flex",
    alignItems: "flex-start",
    alignSelf: "center",
    "& .MuiButton-label": {
      marginBottom: -3,
    },
    "& .MuiButton-startIcon": {
      margin: theme.spacing(0.0, 0.5, 0.5, 0.0),
    },
  },
}))

export default withIsFoodPlansFeature(
  function FoodPlanCreateForPatientButton() {
    const classes = useStyles()
    const legacyPatient = useAppSelector(({ patient }) => patient.patient)
    const [isCreating, setIsCreating] = useState(false)
    const startFoodPlan = useStartFoodPlan()

    const onClick = useEventCallback(async () => {
      if (isCreating) {
        return
      }

      try {
        setIsCreating(true)

        await startFoodPlan(legacyPatient.id)
      } finally {
        setIsCreating(false)
      }
    })

    return (
      <Button
        className={classes.button}
        color="text"
        onClick={onClick}
        startIcon={
          isCreating ? (
            <CircularProgress color="inherit" size={16} />
          ) : (
            <FoodPlanIcon
              fill="currentColor"
              height={16}
              width={16}
              viewBox="0 0 11 18"
            />
          )
        }
        type="button"
      >
        Create Food Plan
      </Button>
    )
  }
)
