import { Theme } from "@material-ui/core"

import RupaHealthLogo from "app/assets/images/logos/rupa-health-logo.svg"
import makeAppStyles from "app/utils/makeAppStyles"

const useStyles = makeAppStyles((theme: Theme) => ({
  container: {
    width: "100%",
    padding: "20px 0px",
    position: "absolute",
    top: 0,
    left: 0,
    background: "rgba(255, 255, 255, 0.50)",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  imageContainer: {
    width: "100%",
    maxWidth: 1500,
    paddingLeft: 42,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 22,
    },
  },
  logo: {
    width: 233,
  },
}))

const BloodLabDashboardNavBar = () => {
  const classes = useStyles()
  return (
    <div className={classes.container}>
      <div className={classes.imageContainer}>
        <img
          src={RupaHealthLogo}
          className={classes.logo}
          alt="Rupa Health Logo"
        />
      </div>
    </div>
  )
}

export default BloodLabDashboardNavBar
