import * as React from "react"

import { useHistory } from "react-router-dom"

import { Theme, makeStyles } from "@material-ui/core"

import GreyGlobeIcon from "app/assets/icons/globe-grey.svg"
import BodyText from "app/components/design-system/BodyText"
import { OrderIntent } from "app/hooks/use-resource"
import { NJ_NY_RI_RESTRICTIONS_URL } from "app/main/settings/constants"
import { colors, rupaTeal } from "app/theme"

import { CardOptions } from "../PublicStorefront"
import StorefrontItemCard from "./StorefrontItemCard"

const styles = (theme: Theme) => ({
  productsContainer: {
    display: "flex",
    "flex-direction": "column",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 22.5,
      paddingRight: 22.5,
    },
  },
  labTestCardsContainer: {
    gap: "30px",
    display: "flex",
    "flex-direction": "row",
    "flex-wrap": "wrap",
    width: "100%",
  },
  storeTitle: {
    color: rupaTeal,
    fontSize: 17,
    fontFamily: "Open Sans",
    fontWeight: 600,
  },
  testingNotAvailableText: {
    color: colors.blueGray[500],
    lineHeight: "130%",
    "text-align": "center",
    marginTop: 55,
  },
})

const useStyles = makeStyles(styles)

interface Props {
  storefrontProductRefIds: string[]
  onAddToCart: (type: CardOptions, id: string) => void
  cart: OrderIntent | null
  showTestingAvailabilityText?: boolean
  labshopLogo: string | null
  hidePhysServicesPricing: boolean
}

const StorefrontCards = ({
  storefrontProductRefIds,
  onAddToCart,
  cart,
  showTestingAvailabilityText = true,
  labshopLogo,
  hidePhysServicesPricing,
}: Props) => {
  const classes = useStyles()

  const history = useHistory()

  const handleCardClick = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    queryType: string,
    pushedStorefrontProductId: string
  ) => {
    if (pushedStorefrontProductId && !e.defaultPrevented) {
      e.preventDefault()
      history.push({
        search: `?${queryType}=${pushedStorefrontProductId}`,
      })
    }
  }

  return (
    <div className={classes.productsContainer}>
      <div className={classes.labTestCardsContainer}>
        {Boolean(storefrontProductRefIds.length) &&
          storefrontProductRefIds.map((productId) => (
            <StorefrontItemCard
              key={productId}
              cart={cart}
              productId={productId}
              onClick={handleCardClick}
              onAddToCart={() => onAddToCart(CardOptions.BUNDLE, productId)}
              labshopLogo={labshopLogo}
              hidePhysServicesPricing={hidePhysServicesPricing}
            />
          ))}
      </div>
      {showTestingAvailabilityText && (
        <div className={classes.testingNotAvailableText}>
          <BodyText size="xs" color="inherit">
            <img
              src={GreyGlobeIcon}
              alt="globe icon"
              style={{ position: "relative", top: 1, marginRight: 3 }}
            />{" "}
            Rupa only sells tests within the United States. Some testing is not
            available in NY, NJ or RI.{" "}
            <a
              href={NJ_NY_RI_RESTRICTIONS_URL}
              target="_blank"
              rel="noreferrer"
            >
              Learn more here
            </a>
            .
          </BodyText>
        </div>
      )}
    </div>
  )
}

export default StorefrontCards
