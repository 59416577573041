import FoodPlanEditorCard from "app/food-plans/components/FoodPlanEditorCard"
import FoodPlanIntroCard from "app/food-plans/components/FoodPlanIntroCard"
import { UsePatientFoodPlanResources } from "app/food-plans/hooks/use-patient-food-plan-resources"
import {
  FoodPlanVersion,
  FoodPlanVersionGenerateFeed,
} from "types/food-plan-version"

const PatientFoodPlanCreateMain = ({
  feed,
  foodPlanVersion,
  isRestarting,
  isStartingGenerate,
  onPatch,
}: PatientFoodPlanMainProps) => {
  switch (foodPlanVersion.attributes.state) {
    case "draft":
    default:
      return <FoodPlanIntroCard />
    case "ready":
    case "processing":
      return (
        <FoodPlanEditorCard
          feed={feed}
          foodPlanVersion={foodPlanVersion}
          isGenerating
        />
      )
    case "complete":
      return (
        <FoodPlanEditorCard
          foodPlanVersion={foodPlanVersion}
          isRestarting={isRestarting}
          isStartingGenerate={isStartingGenerate}
          onPatch={onPatch}
        />
      )
    case "failed":
      return <FoodPlanIntroCard />
  }
}

const PatientFoodPlanUpdateMain = ({
  feed,
  foodPlanVersion,
  isRestarting,
  isStartingGenerate,
  onPatch,
}: PatientFoodPlanMainProps) => {
  switch (foodPlanVersion.attributes.state) {
    case "draft":
    default:
      return <FoodPlanIntroCard />
    case "ready":
    case "processing":
      return (
        <FoodPlanEditorCard
          feed={feed}
          foodPlanVersion={foodPlanVersion}
          isGenerating
        />
      )
    case "complete":
    case "failed":
      return (
        <FoodPlanEditorCard
          foodPlanVersion={foodPlanVersion}
          isRestarting={isRestarting}
          isStartingGenerate={isStartingGenerate}
          onPatch={onPatch}
        />
      )
  }
}

export interface PatientFoodPlanMainProps {
  feed?: FoodPlanVersionGenerateFeed
  foodPlanVersion: FoodPlanVersion
  isRestarting?: boolean
  isStartingGenerate?: boolean
  onPatch: UsePatientFoodPlanResources["onPatch"]
}

export default function PatientFoodPlanMain(props: PatientFoodPlanMainProps) {
  switch (props.foodPlanVersion.attributes.prompt_type) {
    case "create":
    default:
      return <PatientFoodPlanCreateMain {...props} />
    case "update":
      return <PatientFoodPlanUpdateMain {...props} />
  }
}
