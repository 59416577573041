import { useCallback } from "react"

import { Button } from "@rupahealth/design"

import { useActivationCodesModal } from "./ActivationCodesModal"
import { InventoryItem } from "./inventory-types"

interface Props {
  inventoryItem: InventoryItem
}

const ActivationCodesModalButton: React.FC<Props> = ({ inventoryItem }) => {
  const modal = useActivationCodesModal()
  const onClick = useCallback(() => {
    modal.show({ inventoryItem })
  }, [inventoryItem])

  return (
    <Button
      type="button"
      variant="ghost"
      className="p-0 h-auto text-[13px] text-primary hover:text-primary rounded"
      onClick={onClick}
    >
      See Activation Codes
    </Button>
  )
}

export default ActivationCodesModalButton
