import { ReactComponent as ChevronRight } from "app/assets/icons/patient-portal/chevron-right.svg"
import BodyText from "app/components/design-system/BodyText"
import { primaryColor, shadows } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"

interface Props {
  name: string
  birthday?: string
  onClick: () => void
}

const useStyles = makeAppStyles((theme) => ({
  card: {
    width: "100%",
    padding: "20px 19px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    background: "white",
    borderRadius: 12,
    transition: "box-shadow 0.2s ease-in-out",
    "&:hover": {
      cursor: "pointer",
      boxShadow: shadows.md,
      transition: "box-shadow 0.2s ease-in-out",
    },
  },
}))

const PatientPortalUserCard = ({ name, birthday, onClick }: Props) => {
  const classes = useStyles()

  return (
    <div
      className={classes.card}
      onClick={onClick}
      data-testid="patient-portal-user-card"
    >
      <div>
        <BodyText size="md" weight="semibold">
          {name}
        </BodyText>
        {birthday && (
          <BodyText size="sm" weight="regular">
            {birthday}
          </BodyText>
        )}
      </div>
      <ChevronRight fill={primaryColor} />
    </div>
  )
}

export default PatientPortalUserCard
