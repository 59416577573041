import { reduce } from "lodash"

import { ColumnFiltersState, PaginationState } from "@tanstack/react-table"

import {
  PATIENT_COLUMN_ID,
  PRACTITIONER_COLUMN_ID,
  STATUS_COLUMN_ID,
} from "./components/TableRenderer"

export function convertColumnFiltersToParams(
  columnFilters: ColumnFiltersState
) {
  return reduce(
    columnFilters,
    (result, filter) => {
      if (filter.value !== undefined) {
        switch (filter.id) {
          case PRACTITIONER_COLUMN_ID:
            result["filter[practitioner.in]"] = filter.value as string
            break
          case PATIENT_COLUMN_ID:
            result["filter[patient.in]"] = filter.value as string
            break
          case STATUS_COLUMN_ID:
            result["filter[cached_status.in]"] = filter.value as string
            break
        }
      }

      return result
    },
    {} as Record<string, string | string[] | undefined>
  )
}

export function convertGlobalFilterToParams(globalFilter: { tab?: string }) {
  if (globalFilter.tab) {
    return {
      "filter[tab]": globalFilter.tab,
    }
  }

  return {}
}

export function convertPaginationToParams(pagination: PaginationState) {
  return {
    "page[offset]": `${pagination.pageIndex * pagination.pageSize}`,
    "page[limit]": `${pagination.pageSize}`,
  }
}
