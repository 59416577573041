import { Container, Link, Typography } from "@material-ui/core"

import NavBar from "app/main/patient-checkout/NavBar"
import { marketingSite } from "app/settings"

export default function CheckoutError({ errorCode }: { errorCode?: string }) {
  let header: string
  let description: string

  switch (errorCode) {
    case "order_canceled": {
      header = "Order Canceled"
      description =
        "This order has been canceled. Let us know if you think this is a mistake."
      break
    }
    case "checkout_completed": {
      header = "You're All Done Here"
      description =
        "We've got all the information we need from you for now. If there's anything you need to change please get in touch."
      break
    }
    default: {
      header = "Order Not Found"
      description =
        "We couldn't find that order. If you think this is a mistake please get in touch."
    }
  }

  return (
    <div>
      <NavBar />
      <Container maxWidth="sm">
        <div className="p-6 md:p-16 mt-8 shadow bg-white rounded-lg">
          <Typography align="center" variant="h4" color="textPrimary">
            {header}
          </Typography>
          <Typography align="center" color="textPrimary" className="mt-4">
            {description} You can contact us at{" "}
            <Link color="primary" href="mailto:support@rupahealth.com">
              support@rupahealth.com
            </Link>
            <br />
          </Typography>

          <Typography
            align="center"
            style={{ fontSize: 16 }}
            className="mt-8 font-semibold"
          >
            <Link color="primary" target="_blank" href={marketingSite}>
              Learn more about Rupa Health
            </Link>
          </Typography>
        </div>
      </Container>
    </div>
  )
}
