import { useState } from "react"

import useEventCallback from "app/hooks/use-event-callback"
import useHandleApiError from "app/hooks/use-handle-api-error"
import useAppSelector from "app/hooks/useAppSelector"
import { trackEventWithProperties } from "app/services/segment.typed"
import resourceRequest from "app/swr/utils/resource-request"

import { FoodPlansEvents } from "../constants"

export default function useDownloadFoodPlan() {
  const handleApiError = useHandleApiError()
  const [isDownloadingFoodPlan, setIsDownloadingFoodPlan] = useState(false)
  const practitioner = useAppSelector(({ practitioner }) => practitioner)

  const downloadFoodPlan = useEventCallback(async (foodPlanId: string) => {
    if (isDownloadingFoodPlan) {
      return
    }

    // We could move the event closer to the actual click, but this is good enough
    // and requires one less use of `useAppSelector`. The start of this function
    // is essentially the source of the click anyways. It also prevents multiple
    // click events from being triggered should the user click the button multiple
    // times while the download is loading.
    trackEventWithProperties(FoodPlansEvents.DOWNLOAD_FOOD_PLAN_CLICKED, {
      foodPlanId,
      practitionerId: practitioner?.id,
    })

    const pdfTab = window.open(
      "https://embed.rupahealth.com/loading-food-plan",
      "_blank"
    )

    try {
      setIsDownloadingFoodPlan(true)

      const response = await resourceRequest<{ data: { url: string } }>({
        url: `/food_plans/${foodPlanId}/download/`,
        method: "post",
      })

      if (pdfTab) {
        pdfTab.location.href = response.data.url
      }

      trackEventWithProperties(FoodPlansEvents.DOWNLOAD_FOOD_PLAN_COMPLETED, {
        foodPlanId,
        practitionerId: practitioner?.id,
      })
    } catch (error) {
      if (pdfTab) {
        pdfTab.close()
      }

      handleApiError(error)
    } finally {
      setIsDownloadingFoodPlan(false)
    }
  })

  return {
    isDownloadingFoodPlan,
    downloadFoodPlan,
  }
}
