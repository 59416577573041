import { useMemo } from "react"

import { Table } from "@tanstack/react-table"

import { OrderStatusesState } from "app/main/dashboard/constants"
import { DashboardOrder } from "types/dashboard/dashboard-order"

import ResultsInTabLabel from "./ResultsInTabLabel"
import { TabId } from "./TableRenderer"
import TableTabsRenderer, { TabGroup } from "./TableTabsRenderer"

const groups: TabGroup[] = [
  {
    tabs: [
      {
        label: "Drafts",
        id: TabId.DRAFT,
      },
      {
        label: "In Progress",
        id: TabId.IN_PROGRESS,
      },
      {
        label: (table) => <ResultsInTabLabel table={table} />,
        id: TabId.RESULTS_IN,
      },
      {
        label: "Reviewed",
        id: TabId.REVIEWED,
      },
    ],
  },
  {
    tabs: [
      {
        label: "All",
        id: TabId.ALL,
      },
    ],
  },
]

const alertsGroup: TabGroup = {
  tabs: [
    {
      label: "Alerts",
      id: TabId.ALERTS,
    },
  ],
}

interface Props {
  table: Table<DashboardOrder>
  orderStatusesState: OrderStatusesState | undefined
}

const DashboardOrdersTableTabs: React.FC<Props> = ({
  table,
  orderStatusesState,
}) => {
  const groupedTabs = useMemo(() => {
    if (
      orderStatusesState?.has_delayed_orders ||
      orderStatusesState?.has_specimen_issue_orders
    ) {
      return [...groups, alertsGroup]
    }
    return groups
  }, [orderStatusesState])
  return <TableTabsRenderer groups={groupedTabs} table={table} />
}

export default DashboardOrdersTableTabs
