import { BaseCheckoutWarning } from "./BaseCheckoutWarning"
import {
  CHECKOUT_WARNINGS,
  StaticCheckoutWarningTypes,
} from "./StaticCheckoutWarning"

/**
 * A warning indicating that there are duplicate biomarkers in the cart, which will be enumerated in a styled list.
 */
export const DuplicateBiomarkersCheckoutWarning = ({
  labCompanyName,
  testsCausingDuplicates,
}: {
  labCompanyName: string
  testsCausingDuplicates: {
    testName: string
    biomarkersList: string[]
  }[]
}) => {
  return (
    <BaseCheckoutWarning
      key={labCompanyName}
      title={`${
        CHECKOUT_WARNINGS[StaticCheckoutWarningTypes.DUPLICATE_BIOMARKERS].title
      } -- ${labCompanyName}`}
      severity={
        CHECKOUT_WARNINGS[StaticCheckoutWarningTypes.DUPLICATE_BIOMARKERS]
          .severity
      }
    >
      {
        CHECKOUT_WARNINGS[StaticCheckoutWarningTypes.DUPLICATE_BIOMARKERS]
          .message
      }
      <ul>
        {testsCausingDuplicates.map(({ testName, biomarkersList }, index) => (
          <li key={index}>
            <DuplicateBiomarkersListItem
              testName={testName}
              biomarkersList={biomarkersList}
            />
          </li>
        ))}
      </ul>
    </BaseCheckoutWarning>
  )
}

const DuplicateBiomarkersListItem = ({
  testName,
  biomarkersList,
}: {
  testName: string
  biomarkersList: string[]
}) => {
  return (
    <div>
      <div className="font-semibold">{testName}</div>
      <div className="ml-2 flex">
        <div>-</div>
        <div className="ml-1">{biomarkersList.join(", ")}</div>
      </div>
    </div>
  )
}
