import { useCallback } from "react"

function useOpenCheatsheet(cheatsheetUrl: string) {
  const openCheatsheet = useCallback(() => {
    // regex the article id from the intercom url
    const articleId = cheatsheetUrl.match(/\/articles\/(\d+)(-|\/|$)/)?.[1]

    window.Intercom && articleId
      ? window.Intercom("showArticle", articleId)
      : window.open(cheatsheetUrl, "_blank")
  }, [cheatsheetUrl])

  return openCheatsheet
}

export default useOpenCheatsheet
