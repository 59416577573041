import {
  IconButton,
  makeStyles,
  Theme,
  Checkbox,
  FormControlLabel,
  FormGroup,
} from "@material-ui/core"
import MuiDialogContent from "@material-ui/core/DialogContent"
import CloseIcon from "@material-ui/icons/Close"

import PhlebotomistsRedIcon from "app/assets/icons/phlebotomists-red.svg"
import BodyText from "app/components/design-system/BodyText"
import DesignSystemButton from "app/components/design-system/Button"
import DisplayText from "app/components/design-system/DisplayText"
import { Dialog } from "app/components/modals"
import { SCARLET_BLOOD_DRAW_PRICE } from "app/constants"
import { navy, colors } from "app/theme"
import { Patient } from "app/types"

interface Props {
  open: boolean
  patient: Patient
  bloodDrawModalEnabled: Boolean
  checkboxToggleHandler: () => void
  onSubmit: () => void
  onClose: () => void
}

const useStyles = makeStyles((theme: Theme) => ({
  actionsContainer: {
    display: "flex",
    padding: "12px 20px",
    justifyContent: "space-around",
    borderTop: `1px solid ${colors.blueGray[300]}`,
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  actionButton: {
    padding: "10px 40px",
    [theme.breakpoints.down("sm")]: {
      padding: "10px 20px",
      marginTop: 10,
    },
  },
  closeButton: {
    color: navy,
  },
  dialogTitle: {
    display: "flex",
    justifyContent: "flex-end",
    flexWrap: "nowrap",
    alignItems: "center",
    padding: 8,
  },
  dialogContent: {
    padding: "6px 32px",
    [theme.breakpoints.down("sm")]: {
      padding: 12,
    },
  },
  contentHeader: {
    textAlign: "center",
    maxWidth: 300,
    margin: "auto",
  },
  contentBody: {
    textAlign: "center",
    marginTop: 20,
  },
  bloodDrawInstructionsWrapper: {
    backgroundColor: colors.blueGray[50],
    padding: 12,
    marginTop: 20,
    border: `1px solid ${colors.blueGray[200]}`,
    borderRadius: 6,
  },
  bloodDrawInstruction: {
    display: "flex",
    marginBottom: 6,
  },
  bloodDrawIcon: {
    width: 25,
    height: 25,
    border: `1px solid ${colors.blueGray[200]}`,
    background: colors.blueGray[200],
    borderRadius: "50%",
    padding: 6,
    marginRight: 10,
    flex: "none",
  },
  dontShowCheckboxWrapper: {
    display: "flex",
    alignContent: "center",
    margin: "20px 0",
  },
}))

const BloodDrawModal = ({
  open,
  patient,
  bloodDrawModalEnabled,
  checkboxToggleHandler,
  onSubmit,
  onClose,
}: Props) => {
  const classes = useStyles()

  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="blood-draw"
      open={open}
      fullWidth
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth="xs"
    >
      <TitleSection onClose={onClose} />

      <MuiDialogContent className={classes.dialogContent}>
        <DisplayText
          weight="semibold"
          size="xl"
          className={classes.contentHeader}
        >
          Your Patient Will Need Two Separate Blood Draws
        </DisplayText>
        <BodyText className={classes.contentBody}>
          This order has tests that require phlebotomy from BioReference and
          other labs. {patient.first_name} will have to get:
        </BodyText>

        <div className={classes.bloodDrawInstructionsWrapper}>
          <div className={classes.bloodDrawInstruction}>
            <img
              className={classes.bloodDrawIcon}
              src={PhlebotomistsRedIcon}
              alt="phlebotomist-icon"
            />
            <BodyText>
              A {SCARLET_BLOOD_DRAW_PRICE} blood draw coordinated by Scarlet for
              the BioReference tests (included).
            </BodyText>
          </div>
          <div className={classes.bloodDrawInstruction}>
            <img
              className={classes.bloodDrawIcon}
              src={PhlebotomistsRedIcon}
              alt="phlebotomist-icon"
            />
            <BodyText>
              An additional blood draw for the other tests that require
              phlebotomy (to be scheduled and paid for separately).
            </BodyText>
          </div>
        </div>

        <FormGroup className={classes.dontShowCheckboxWrapper}>
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                size="small"
                value={bloodDrawModalEnabled}
                onChange={checkboxToggleHandler}
              />
            }
            label="Don’t show me this again."
          />
        </FormGroup>
      </MuiDialogContent>

      <div className={classes.actionsContainer}>
        <DesignSystemButton
          color="secondary"
          onClick={onClose}
          size="xsmall"
          className={classes.actionButton}
        >
          Edit Order
        </DesignSystemButton>
        <DesignSystemButton
          variant="contained"
          color="primary"
          type="submit"
          disabled={false}
          loading={false}
          size="xsmall"
          className={classes.actionButton}
          onClick={onSubmit}
        >
          Send to Patient
        </DesignSystemButton>
      </div>
    </Dialog>
  )
}

export default BloodDrawModal

const TitleSection = ({ onClose }) => {
  const classes = useStyles()

  const closeButton = onClose && (
    <IconButton
      className={classes.closeButton}
      aria-label="close"
      onClick={onClose}
      key="close-button"
    >
      <CloseIcon />
    </IconButton>
  )

  return <div className={classes.dialogTitle}>{closeButton}</div>
}
