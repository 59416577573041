import { Descendant } from "slate"

import RichTextEditor from "app/components/RichTextEditor"
import makeAppStyles from "app/utils/makeAppStyles"

const useStyles = makeAppStyles((theme) => ({
  root: {
    position: "relative",
    width: "100%",
  },
  content: {
    paddingRight: theme.spacing(2.0),
    position: "relative",
    display: "block",
    width: "100%",
    padding: 0,
    borderRadius: 0,
  },
  toolbar: {
    border: `1px solid #cbd5e1`,
    borderBottom: "none",
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6,
    left: 0,
    padding: theme.spacing(1.0, 3.0, 0.0),
    background: "white",
  },
  editable: {
    border: `1px solid #cbd5e1`,
    fontSize: 16,
    width: "100%",
    minHeight: "120px",
    padding: theme.spacing(2.0),
    paddingRight: theme.spacing(5.0),
    paddingBottom: theme.spacing(4.0),
    backgroundColor: "white",
    borderBottomLeftRadius: 6,
    borderBottomRightRadius: 6,
    "& p + p, & ul + p, & ol + p": {
      marginTop: theme.spacing(1.0),
    },
    // Style the placeholder text so it doesn't overflow.
    "& [data-slate-placeholder=true]": {
      paddingRight: theme.spacing(4.0),
    },
  },
  editableFocused: {},
}))

export interface StorefrontRichTextEditorProps {
  onChange: (value: Descendant[]) => void
  value: Descendant[]
  placeholder?: string
}

export default function StorefrontRichTextEditor(
  props: StorefrontRichTextEditorProps
) {
  const clases = useStyles()

  return (
    <RichTextEditor
      classes={{
        content: clases.content,
        editable: clases.editable,
        editableFocused: clases.editableFocused,
        toolbar: clases.toolbar,
      }}
      onChange={props.onChange}
      value={props.value}
      placeholder={props.placeholder}
    />
  )
}
