import { Table, TableContainer, Paper, TableBody } from "@material-ui/core"

import PromotionsCouponsTableRow from "app/components/Promotions/PromotionsCouponsTableRow"
import { colors, shadows } from "app/theme"
import { Coupon } from "app/types"
import makeAppStyles from "app/utils/makeAppStyles"

const useStyles = makeAppStyles((theme) => ({
  container: {
    border: `1px solid ${colors.blueGray[200]}`,
    marginTop: "12px",
    boxShadow: shadows.default,
    overflow: "hidden",
    borderRadius: 8,
  },
}))

interface Props {
  coupons: Coupon[]
  areCouponsAvailable: boolean
}

function PromotionsCouponsTable(props: Props) {
  const classes = useStyles()

  return (
    <>
      <br></br>
      <TableContainer className={classes.container} component={Paper}>
        <Table aria-label="coupons">
          <TableBody>
            {props.coupons?.map((coupon, index) => (
              <PromotionsCouponsTableRow
                coupon={coupon}
                index={index}
                isAvailable={props.areCouponsAvailable}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

export default PromotionsCouponsTable
