import * as Actions from "../actions"

const initialState = {
  paymentCards: null,
  pending: false,
  useCardChecked: false,
}

const paymentCardsReducer = function (state = initialState, action) {
  switch (action.type) {
    case Actions.CLEAR_ORDER: {
      return { ...initialState }
    }
    case Actions.GET_PAYMENT_CARDS_REQUEST: {
      return {
        ...state,
        pending: true,
      }
    }
    case Actions.GET_PAYMENT_CARDS_SUCCESS: {
      return {
        ...state,
        paymentCards: action.payload,
        pending: false,
      }
    }
    case Actions.GET_PAYMENT_CARDS_FAILURE: {
      return {
        ...state,
        pending: false,
      }
    }
    case Actions.ADD_PAYMENT_CARD: {
      return {
        ...state,
        paymentCards: [...state.paymentCards, action.payload],
      }
    }
    case Actions.DELETE_PAYMENT_CARD: {
      return {
        ...state,
        paymentCards: state.paymentCards.filter(
          (card) => card.id !== action.payload
        ),
      }
    }
    case Actions.SET_USE_CARD_CHECKED: {
      return {
        ...state,
        useCardChecked: action.payload,
      }
    }
    case Actions.UPDATE_PAYMENT_CARD: {
      return {
        ...state,
        paymentCards: state.paymentCards.map((card) =>
          card.id === action.payload.id ? action.payload : card
        ),
      }
    }
    default: {
      return state
    }
  }
}

export default paymentCardsReducer
