import { Redirect, useHistory } from "react-router-dom"
import { useUpdateEffect } from "react-use"

import DisplayText from "app/components/design-system/DisplayText"
import useAppSelector from "app/hooks/useAppSelector"
import {
  PATIENT_PORTAL_TRACKING_EVENTS,
  trackPatientPortalEvent,
} from "app/services/segment"
import formatDateForHuman from "app/utils/format-date-for-human"

import Card from "../Card"
import PatientPortalEmptyState from "../PatientPortalEmptyState"
import PatientPortalGeneralQuickLinks from "../footer/PatientPortalGeneralQuickLinks"
import PatientPortalOrderQuickLinks from "../footer/PatientPortalOrderQuickLinks"
import PatientPortalPatientsLoadingState from "../patients/PatientPortalPatientsLoadingState"
import { buildPatientPortalUserUrl } from "../util"
import PatientPortalUserCard from "./PatientPortalUserCard"
import usePatientPortalUsers from "./hooks/use-patient-portal-users"

const PatientPortalUsers = () => {
  const { users, isLoading } = usePatientPortalUsers()
  const history = useHistory()

  const routeToPatientOrders = (userId: string) => {
    history.push(buildPatientPortalUserUrl(userId))
  }

  const authedUser = useAppSelector(({ auth }) => auth.user)

  const hasMultipleUsers = users.length > 0
  const shouldRedirectToOrdersPage = users.length <= 1

  useUpdateEffect(() => {
    // Do not track if we are just going to redirect to orders page
    if (!isLoading && !shouldRedirectToOrdersPage) {
      trackPatientPortalEvent(
        PATIENT_PORTAL_TRACKING_EVENTS.ACCOUNTS_PAGE_VIEWED,
        { user_id: authedUser.id.toString() }
      )
    }
  }, [isLoading])

  if (isLoading) {
    return <PatientPortalPatientsLoadingState />
  }

  if (shouldRedirectToOrdersPage) {
    return (
      <Redirect
        to={buildPatientPortalUserUrl(
          users[0]?.id.toString() || authedUser.id.toString()
        )}
      />
    )
  }

  return (
    <div className="flex flex-col gap-4">
      <Card>
        {hasMultipleUsers ? (
          <>
            <DisplayText size="lg" weight="semibold">
              Select a Patient
            </DisplayText>

            <div className="flex flex-col gap-4">
              {users.map((user) => (
                <PatientPortalUserCard
                  birthday={formatDateForHuman(user.attributes?.birthday)}
                  name={user.attributes?.full_name}
                  onClick={() => routeToPatientOrders(user.id)}
                  key={user.id}
                />
              ))}
            </div>
          </>
        ) : (
          <>
            <DisplayText size="lg" weight="semibold">
              Welcome, {authedUser?.first_name}!
            </DisplayText>
            <PatientPortalEmptyState authUser={authedUser} />
          </>
        )}
      </Card>
      {hasMultipleUsers ? (
        <PatientPortalOrderQuickLinks />
      ) : (
        <PatientPortalGeneralQuickLinks />
      )}
    </div>
  )
}

export default PatientPortalUsers
