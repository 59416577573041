import axios from "axios"
import useSWR from "swr"

import { getApiBaseUrl } from "app/utils"

export interface LegacyRedirectResponse {
  redirectUrl: string
}

export default function useLegacyRedirect(
  patientId: string,
  orderId: string,
  supportPage: boolean,
  fullPathname: string
) {
  return useSWR<LegacyRedirectResponse>("patient_portal/legacy_redirect", {
    async fetcher(url) {
      const response = await axios.request<LegacyRedirectResponse>({
        baseURL: getApiBaseUrl() + "/api/",
        url,
        method: "post",
        data: {
          patientId,
          orderId,
          supportPage,
          fullPathname,
        },
      })
      return response.data
    },
  })
}
