import { compact, uniqBy } from "lodash"

import useCachedCollection from "app/swr/hooks/use-cached-collection"
import { ResourceIdentifier } from "app/swr/types"
import { DiscreteResult } from "types/discrete-result"
import { Kit } from "types/kit"
import { OrderedResult } from "types/ordered-result"
import { ResultsInterpretationBiomarker } from "types/results-interpretation"

export default function useBiomarkerKitNames(
  biomarkerIdentifiers: ResourceIdentifier[]
) {
  const biomarkers =
    useCachedCollection<ResultsInterpretationBiomarker>(biomarkerIdentifiers)
  const discreteResults = useCachedCollection<DiscreteResult>(
    biomarkers.map((biomarker) => biomarker.relationships.discrete_result.data)
  )
  const orderedResults = useCachedCollection<OrderedResult>(
    uniqBy(
      compact(
        discreteResults.map((dr) => dr?.relationships.ordered_result.data)
      ),
      (or) => or.id
    )
  )
  const kits = useCachedCollection<Kit>(
    uniqBy(
      compact(orderedResults.map((or) => or?.relationships.kit.data)),
      (kit) => kit.id
    )
  )
  const kitNames = kits.map((kit) => kit.attributes.name)

  return kitNames
}
