import { useState } from "react"

import classNames from "classnames"

import {
  IconButton,
  makeStyles,
  Paper,
  Typography,
  styled,
  useMediaQuery,
  Theme,
} from "@material-ui/core"
import DeleteIcon from "@material-ui/icons/Delete"

import BundleIcon from "app/assets/icons/bundle-grey.svg"
import GreenDollarCircleIcon from "app/assets/icons/green-dollar-circle.svg"
import TealMessageBubbleIcon from "app/assets/icons/teal-message-bubble.svg"
import DragHandleIcon from "app/assets/images/storefront/drag-handle.svg"
import Tooltip from "app/components/Tooltip"
import BodyText from "app/components/design-system/BodyText"
import DesignSystemButton from "app/components/design-system/Button"
import {
  StorefrontLabTestBundleType,
  StorefrontProductType,
  useResourceByIdSelector,
  useResourceSelector,
} from "app/hooks/use-resource"
import { colors, navy } from "app/theme"
import { formatDollars } from "app/utils"

import { calculateTotalTestPricing, generatePricingTooltipText } from "../utils"

const useStyles = makeStyles((theme) => ({
  testWrapper: {
    display: "flex",
    "flex-direction": "column",
    alignItems: "flex-start",
    gap: 10,
    background: "#FFFFFF",
    border: "1px solid #E2E8F0",
    boxShadow:
      "0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)",
    borderRadius: 8,
    padding: "13px 11px",
    justifyContent: "space-around",
  },
  iconHover: {
    "&:hover": {
      backgroundColor: "rgba(0,0,0,0.05)",
    },
  },
  iconHoverSelected: {
    "&:hover": {
      backgroundColor: "rgba(255,255,255,0.15)",
    },
  },
  loadingContainer: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  bundleCardContentContainer: {
    display: "flex",
    gap: 7,
  },
  dollarText: {
    color: navy,
    fontWeight: 600,
    borderBottom: `1px dashed ${colors.blueGray[400]}`,
    display: "inline-block",
    paddingBottom: 3,
  },
  customFeeContainer: {
    display: "flex",
    padding: "2px 8px",
    borderRadius: 40,
    background: colors.green[100],
    height: 23,
    gap: 6,
  },
  notesIconContainer: {
    display: "flex",
    padding: "0px 5px",
    background: colors.lightBlue[100],
    borderRadius: 40,
  },
  customizeButtonContainer: {
    width: "100%",
  },
  topTextContainer: {
    display: "flex",
    "flex-direction": "row",
    width: "100%",
    // justifyContent: "center",
  },
  topTextItem: {
    flex: "1",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "&:first-child": {
      marginRight: "auto",
      justifyContent: "flex-start",
    },
    "&:last-child": {
      marginLeft: "auto",
      justifyContent: "flex-end",
    },
  },
}))

const IconImage = styled("img")({
  width: 17.42,
  marginRight: 3,
  marginTop: 3,
})

interface Props {
  product?: StorefrontProductType
  onRemove: (bundle: StorefrontLabTestBundleType | undefined) => void
  openModal: (product: StorefrontProductType | undefined) => void
  dragAttributes?: any
  dragListeners?: any
  hidePhysServicesPricing: boolean
}

export default function EcommerceBundleCard({
  product,
  onRemove,
  openModal,
  dragAttributes,
  dragListeners,
  hidePhysServicesPricing,
}: Props) {
  const classes = useStyles()
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"))

  const bundle = useResourceByIdSelector(
    "lab_test_bundle",
    product?.relationships.bundle.data?.id
  )

  const bundleLabTests = useResourceSelector((entities) => {
    if (!bundle?.relationships.lab_tests) {
      return []
    }

    return bundle?.relationships?.lab_tests.data.map((ref) => {
      return entities?.lab_test?.[ref.id]
    })
  })

  const [cardHovered, setCardHovered] = useState(false)

  const customFee = useResourceByIdSelector(
    "custom_fee",
    product?.relationships.custom_fee.data?.id
  )

  const pricingTooltipText = generatePricingTooltipText(
    hidePhysServicesPricing,
    customFee
  )

  let totalTestPrice = 0
  if (bundle) {
    totalTestPrice = calculateTotalTestPricing(
      hidePhysServicesPricing,
      bundleLabTests,
      customFee
    )
  }

  return (
    <>
      <Paper
        className={classes.testWrapper}
        elevation={0}
        onMouseEnter={() => !isMobile && setCardHovered(true)}
        onMouseLeave={() => !isMobile && setCardHovered(false)}
      >
        <div className={classes.topTextContainer}>
          <div className={classes.topTextItem}>
            <IconImage src={BundleIcon} alt="bundle-icon" />
          </div>
          <div className={classes.topTextItem}>
            {dragAttributes && dragListeners && (
              <div {...dragAttributes} {...dragListeners}>
                <img src={DragHandleIcon} alt="drag handle" />
              </div>
            )}
          </div>
          <div
            className={classes.topTextItem}
            style={{ opacity: cardHovered || isMobile ? 1 : 0 }}
          >
            <div>
              <IconButton
                className={classNames("hover:bg-gray-200", "p-px rounded w-6")}
                onClick={() => onRemove(bundle)}
                aria-label="Test delete"
              >
                <DeleteIcon
                  fontSize="small"
                  style={{
                    color: colors.blueGray[400],
                  }}
                />
              </IconButton>
            </div>
          </div>
        </div>
        <div>
          <Typography variant="body1" className="font-semibold text-gray-800">
            <span className={"text-gray-800"}>{bundle?.attributes.name}</span>{" "}
          </Typography>
          <Typography variant="body1">
            <span className={"text-gray-600"}>Custom Bundle</span>
          </Typography>
        </div>

        <div className={classes.bundleCardContentContainer}>
          <Tooltip
            arrow
            interactive
            title={
              pricingTooltipText === "" ? (
                pricingTooltipText
              ) : (
                <BodyText size="xs">{pricingTooltipText}</BodyText>
              )
            }
            placement="top"
          >
            <div>
              <BodyText variant="body1" className={classes.dollarText}>
                {formatDollars(totalTestPrice)}
              </BodyText>
            </div>
          </Tooltip>

          {customFee && (
            <div className={classes.customFeeContainer}>
              <img
                src={GreenDollarCircleIcon}
                alt="green dollar circle"
                style={{ height: 19 }}
              />
              <BodyText weight="semibold" size="sm">
                Incl. ${customFee?.attributes.price / 100} Fee
              </BodyText>
            </div>
          )}
          {product?.attributes.notes && (
            <Tooltip
              arrow
              interactive
              title={
                <BodyText size="xs">
                  This Custom Bundle contains a custom note from you
                </BodyText>
              }
              placement="top"
            >
              <div className={classes.notesIconContainer}>
                <img src={TealMessageBubbleIcon} alt="teal message bubble" />
              </div>
            </Tooltip>
          )}
        </div>

        <div className={classes.customizeButtonContainer}>
          <DesignSystemButton
            color="secondary"
            fullWidth
            onClick={() => openModal(product)}
          >
            Edit Fee & Note
          </DesignSystemButton>
        </div>
      </Paper>
    </>
  )
}
