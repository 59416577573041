import { ReactComponent as BiomarkerIcon } from "app/assets/icons/biomarker.svg"
import Tooltip from "app/components/Tooltip"
import BodyText from "app/components/design-system/BodyText"
import {
  PatientPortalKit,
  PatientPortalLabTest,
  PatientPortalOrderedTest,
} from "app/patient-portal/types"
import useCachedCollection from "app/swr/hooks/use-cached-collection"
import useCachedResource from "app/swr/hooks/use-cached-resource"
import { colors, primaryColor } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"

const useStyles = makeAppStyles((theme) => ({
  tooltipTrigger: {
    display: "flex",
    flexFlow: "row nowrap",
    alignItems: "center",
    gap: 2,
    width: "fit-content",
  },
  tooltipContentList: {
    padding: 0,
    paddingLeft: theme.spacing(1.6),
  },
}))

const PatientPortalOrderKitListKitTestPanelTooltipTestName = ({
  orderedTest,
}: {
  orderedTest: PatientPortalOrderedTest
}) => {
  const labTest = useCachedResource<PatientPortalLabTest>(
    orderedTest?.relationships.lab_test.data
  )
  if (!orderedTest || !labTest) {
    return null
  }

  return (
    <div className="flex items-center justify-start gap-[10px]">
      <BiomarkerIcon
        fill={colors.emerald[500]}
        width={15}
        height={14}
        viewBox="-1 -1 12 12"
      />
      <BodyText
        size="sm"
        weight="semibold"
        className={
          orderedTest.attributes.date_canceled
            ? "line-through text-left"
            : "text-left"
        }
      >
        {labTest.attributes.name}
      </BodyText>
    </div>
  )
}

export default function PatientPortalOrderKitListKitTestPanelTooltip({
  kit,
}: {
  kit: PatientPortalKit
}) {
  const classes = useStyles()
  const orderedTests = useCachedCollection<PatientPortalOrderedTest>(
    kit.relationships.ordered_tests.data
  )
  return (
    <Tooltip
      arrow
      title={
        <div className="flex flex-col items-start justify-start gap-[10px]">
          {orderedTests.map((orderedTest) => (
            <PatientPortalOrderKitListKitTestPanelTooltipTestName
              key={orderedTest.id}
              orderedTest={orderedTest}
            />
          ))}
        </div>
      }
      placement="bottom-start"
      className="cursor-default"
      interactive
    >
      <div className={classes.tooltipTrigger}>
        <BiomarkerIcon
          fill={primaryColor}
          width={15}
          height={14}
          viewBox="0 0 12 12"
        />
        <BodyText weight="semibold" style={{ color: primaryColor }}>
          See Biomarkers
        </BodyText>
      </div>
    </Tooltip>
  )
}
