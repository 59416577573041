import { isProduction } from "app/settings"

// Dev
const AXLE_PARTNER_ID_DEV = "8fbc872d-efce-4008-9027-ed8eb5a0a373"
const AXLE_SERVICE_ID_DEV = "d159fb66-e0ed-400e-a03d-ad6b080e19c4"
const AXLE_IFRAME_URL_DEV = "https://patient.axlehealth-dev.com/book?"

// Production
const AXLE_PARTNER_ID_PROD = "4da68974-f90c-47b6-94c9-bd7e4e73f761"
const AXLE_SERVICE_ID_PROD = "4a0ff74b-4099-4a65-81a3-a9790dd7144e"
const AXLE_IFRAME_URL_PROD = "https://patient.axlehealth.com/book?"

export const AXLE_PARTNER_ID = isProduction
  ? AXLE_PARTNER_ID_PROD
  : AXLE_PARTNER_ID_DEV
export const AXLE_SERVICE_ID = isProduction
  ? AXLE_SERVICE_ID_PROD
  : AXLE_SERVICE_ID_DEV
export const AXLE_IFRAME_URL = isProduction
  ? AXLE_IFRAME_URL_PROD
  : AXLE_IFRAME_URL_DEV

export const PHLEBOTOMY_BOOKING_FROM_LOCATIONS = {
  PATIENT_PORTAL_BANNER: "patient-portal-banner",
  PATIENT_PORTAL_INSTRUCTIONS: "patient-portal-instructions",
  PATIENT_CHECKOUT_SUCCESS: "patient-checkout-success",
  REMINDER_EMAIL: "reminder-email",
}
