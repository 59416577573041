import { useDispatch, useSelector } from "react-redux"

import { makeStyles } from "@material-ui/core"

import { resendRegistrationEmail } from "app/auth/store/actions/user.actions"
import BodyText from "app/components/design-system/BodyText"
import Banner from "app/main/dashboard/Banner"
import { colors } from "app/theme"
import { RootState } from "app/types"

import RightCaretIcon from "../../assets/images/right-caret-yellow-900.svg"

interface VerifyEmailBannerProps {
  displayText?: string
}

export default function VerifyEmailBanner({
  displayText,
}: VerifyEmailBannerProps) {
  const classes = useStyles()
  const dispatch = useDispatch()

  const user = useSelector(({ profile }: RootState) => profile.user)

  const { surveyModalOpen } = useSelector(
    ({ dashboard }: RootState) => dashboard?.onboarding || ({} as any)
  )

  const onClickVerifyEmail = () => {
    dispatch(resendRegistrationEmail({ email: user.email }))
  }

  const shouldShow = user && !user.is_verified && !surveyModalOpen

  if (!shouldShow) {
    return null
  }

  const bodyText =
    displayText ||
    "You'll need to verify your email address before sending orders to your patients. "

  const linkText = "Resend verification email"

  return (
    <Banner
      onClick={onClickVerifyEmail}
      backgroundColor={colors.yellow[50]}
      borderColor={colors.yellow[400]}
    >
      <div>
        <BodyText className={classes.bodyText}>
          {bodyText}
          <span className={classes.linkText}>
            {linkText}
            <img
              src={RightCaretIcon}
              alt="right-carrot-icon"
              className={classes.rightCarrotIcon}
            />
          </span>
        </BodyText>
      </div>
    </Banner>
  )
}

const useStyles = makeStyles(() => ({
  bodyText: {
    color: colors.yellow[900],
  },
  linkText: {
    color: colors.yellow[900],
    "font-weight": 600,
  },
  rightCarrotIcon: {
    marginLeft: 5,
  },
}))
