import axios from "axios"
import useSWR from "swr"

import { getApiBaseUrl } from "app/utils"

import { PANEL_BUILDER_LAB_COMPANIES } from "../constants"
import { FindOptimalPanelsResponse } from "../types"

export default function usePanels(combinedSelectedBiomarkerIds: string[]) {
  return useSWR(
    ["/api/panel_builder/find_optimal_panels/", combinedSelectedBiomarkerIds],
    async ([url, combinedSelectedBiomarkerIds]) => {
      const response = await axios.post(getApiBaseUrl() + url, {
        biomarkers: combinedSelectedBiomarkerIds,
        lab_company_keys: PANEL_BUILDER_LAB_COMPANIES,
      })
      return response.data as FindOptimalPanelsResponse
    },
    {
      revalidateIfStale: true,
      revalidateOnMount: true,
      revalidateOnFocus: true,
      revalidateOnReconnect: true,
    }
  )
}
