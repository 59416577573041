import React from "react"

import {
  faFileMagnifyingGlass,
  faXmark,
} from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button, cn } from "@rupahealth/design"

import { default as pdfThumbnail } from "app/assets/images/documents/pdf-thumbnail.svg"
import useUserResultModal from "app/main/results-upload-v2/user-result-modal/UserResultModal"
import useCachedResource from "app/swr/hooks/use-cached-resource"
import { ResourceIdentifier } from "app/swr/types"
import formatDateForHuman from "app/utils/format-date-for-human"
import { UserResult } from "types/user-result"

interface Props {
  userResultIdentifier: ResourceIdentifier
  onDismiss: () => void
  onDetailsAdded: () => Promise<void>
  importedBy?: string
  className?: string
}

export const ResultMissingInfo: React.FC<Props> = ({
  userResultIdentifier,
  onDismiss,
  onDetailsAdded,
  importedBy,
  className,
}) => {
  const userResult = useCachedResource<UserResult>(userResultIdentifier)
  const userResultModal = useUserResultModal()

  if (!userResult) return null

  return (
    <div
      className={cn(
        "flex items-start sm:items-center gap-2 bg-white py-3 px-1",
        className
      )}
    >
      <img
        alt={userResult.attributes.display_name}
        src={userResult?.attributes.latest_thumbnail ?? pdfThumbnail}
        width={30}
        className="rounded-sm border border-slate-200"
      />

      <div className="flex flex-col items-center sm:flex-row justify-between w-full">
        <div>
          <span className="font-semibold">
            Result imported on{" "}
            {formatDateForHuman(userResult.attributes.created_at)}
          </span>
          {importedBy && ` by ${importedBy}`}
        </div>
        <Button
          variant={null}
          className="text-primary max-md:px-0 max-md:justify-start hover:bg-slate-100 py-1 px-2 h-auto text-[15px]"
          onClick={() =>
            userResultModal.show({
              patientId: userResult.relationships.patient?.data?.id,
              userResultId: userResult.id,
              onSave: onDetailsAdded,
            })
          }
        >
          <span className="text-base15">
            <FontAwesomeIcon
              icon={faFileMagnifyingGlass}
              className="text-primary mr-2"
            />
            Add Details
          </span>
        </Button>
      </div>
      <Button
        variant="ghost"
        size="icon"
        className="h-4 w-4 rounded-sm sm:ml-2"
        onClick={onDismiss}
      >
        <FontAwesomeIcon icon={faXmark} className="text-slate-400 text-xl19" />
      </Button>
    </div>
  )
}
