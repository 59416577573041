import { BiomarkerStatus } from "app/patient-portal/blood-lab-dashboard/constants"

export interface ResultsOverTimeData {
  dates: string[]
  biomarkers: ResultsOverTimeBiomarkerData[]
}

export interface ResultsOverTimeBiomarkerData {
  id: string
  short_name: string
  long_name: string
  description?: string
  short_description?: string
  low_description?: string
  high_description?: string
  low_symptoms?: string
  high_symptoms?: string
  sample_type: string
  primary_body_system_id: string
  sample_types: string[] | null
  results: ResultsOverTimeResultData[]
}

export interface ResultsOverTimeResultData {
  id: string
  created_at: string
  value: string | null
  alternate_value: string
  display_value: string | null
  status: BiomarkerStatus
  is_range_value: boolean
  unit: string
  standard_range_min: string
  standard_range_max: string
  optimal_range_min: string
  optimal_range_max: string
  out_of_optimal_range: boolean
  out_of_standard_range: boolean
  lab_company_id: string
  lab_company_name: string
  lab_company_results_name: string
}

export interface ResultsOverTimeResultSimpleChartData {
  id: string
  created_at: string
  value: number | null
  status: BiomarkerStatus
  standardRangeMin?: number
  standardRangeMax?: number
  optimalRangeMin?: number
  optimalRangeMax?: number
}

export interface ResultsOverTimeResultChartData {
  id: string
  x: number
  created_at: string
  value: number
  display_value: string
  alternateValue: string
  status?: BiomarkerStatus
  unit: string
  labCompanyName: string
  standardRangeMin: number
  standardRangeMax: number
  optimalRangeMin: number
  optimalRangeMax: number
}

export enum ResultsOverTimeDateGrouping {
  DAY = "day",
  MONTH = "month",
}

export enum InRangeOption {
  ALL = "all",
  IN_RANGE = "in_range",
  OUT_OF_RANGE = "out_of_range",
  OUTSIDE_OPTIMAL_RANGE = "outside_optimal_range",
}

export interface DropDownOption {
  key: string
  icon: JSX.Element
  label: JSX.Element
  dropdownLabel?: JSX.Element
}
