import { useCallback } from "react"
import { useDispatch } from "react-redux"

import { keys, pick } from "lodash"

import { API } from "app/api"
import { isEmptyErrors } from "app/main/patient-checkout/utils/checkout-utils"
import { PaymentCollectionFormData } from "app/main/payment-collection/types"
import { PaymentCollectionSubmitResult } from "app/main/payment-collection/types"
import { handleApiError } from "app/utils"

export class PaymentCollectionSubmitError extends Error {
  public validationErrors: Record<string, any>

  constructor(message: string, validationErrors: Record<string, any>) {
    super(message)
    this.name = "PaymentCollectionSubmitError"
    this.validationErrors = validationErrors

    Object.setPrototypeOf(this, PaymentCollectionSubmitError.prototype)
  }
}

export default function usePaymentCollectionSubmitCallback(token: string) {
  const dispatch = useDispatch()
  return useCallback(
    async (formData: PaymentCollectionFormData) => {
      try {
        const response = await API.PaymentCollection.post(token, formData)
        return response.data as PaymentCollectionSubmitResult
      } catch (error: any) {
        const errorData = error?.response?.data
        const submitErrors = pick(errorData?.errors, keys(formData))

        if (!isEmptyErrors(submitErrors)) {
          throw new PaymentCollectionSubmitError(
            "Validation error in payment submission",
            submitErrors
          )
        } else {
          // ensure we surface and report the request error
          dispatch(handleApiError(error))

          throw error
        }
      }
    },
    [token]
  )
}
