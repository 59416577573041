import Button from "@material-ui/core/Button"

import { ReactComponent as TestTube } from "app/assets/icons/test-tube.svg"
import { INTERCOM_REQUEST_LAB_TEST_SURVEY_ID } from "app/settings"
import { colors, primaryColor, textPrimaryColor } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"

const useStyles = makeAppStyles({
  container: {
    backgroundColor: "white",
    border: "1px solid " + colors.blueGray[200],
    borderRadius: "6px",
    display: "flex",
    "flex-direction": "column",
    alignItems: "center",
    padding: "24px",
    gap: "10px",
  },
  blurbContainer: {
    color: textPrimaryColor,
    // link button alignment is off by a few px if the font is not the same
    // between the blurb and the link
    "& *": {
      lineHeight: "135%",
      fontSize: "15px",
      fontWeight: 400,
    },
  },
  requestNewLabText: {
    marginLeft: 4,
    color: primaryColor,
    padding: 0,
    verticalAlign: "inherit",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
})

const LabTestNoResults = () => {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <TestTube
        fill={colors.blueGray[400]}
        width={35}
        height={35}
        viewBox="0 0 15 15"
      />
      <div className={classes.blurbContainer}>
        Bummer, we don’t have any matching tests! We’re always expanding our
        catalog,
        <Button
          variant="text"
          className={classes.requestNewLabText}
          onClick={() =>
            window.Intercom("startSurvey", INTERCOM_REQUEST_LAB_TEST_SURVEY_ID)
          }
        >
          request a new lab.
        </Button>
      </div>
    </div>
  )
}

export default LabTestNoResults
