import { useState } from "react"

import { CircularProgress } from "@material-ui/core"
import { Button } from "@rupahealth/design"

import { ReactComponent as FoodPlanIcon } from "app/assets/icons/food-plan-white.svg"
import useEventCallback from "app/hooks/use-event-callback"

interface Props {
  onCreate: () => Promise<void>
}

const FoodPlanCreateButton: React.FC<Props> = ({ onCreate }) => {
  const [isCreating, setIsCreating] = useState(false)

  const onClick = useEventCallback(async () => {
    if (isCreating) return

    try {
      setIsCreating(true)
      await onCreate()
    } finally {
      setIsCreating(false)
    }
  })

  return (
    <Button
      type="button"
      variant="ghost"
      className="flex items-center text-[15px] gap-[6px] py-1 px-2 h-auto text-primary hover:text-primary rounded-md"
      onClick={onClick}
    >
      {isCreating ? (
        <CircularProgress color="inherit" size={16} />
      ) : (
        <FoodPlanIcon fill="currentColor" />
      )}
      Create Food Plan
    </Button>
  )
}

export default FoodPlanCreateButton
