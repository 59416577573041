import { useEffect } from "react"
import { useDispatch } from "react-redux"

import { useParams } from "react-router-dom"

import useAppSelector from "app/hooks/useAppSelector"
import * as Actions from "app/main/patient-orders/store/actions"

export default function useLegacyPatientResource() {
  const dispatch = useDispatch()
  const { patientId } = useParams<{ patientId: string }>()

  const legacyPatient = useAppSelector(({ patient }) => patient.patient)

  useEffect(() => {
    dispatch(Actions.getPatient(patientId))

    return () => {
      dispatch(Actions.reset())
    }
  }, [patientId])

  return legacyPatient
}
