import clsx from "clsx"

import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@rupahealth/design"

import { primaryColor, textPrimaryColor } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"

import { DropDownOption } from "../types/types"
import OptionLabel from "./OptionLabel"

const useStyles = makeAppStyles(() => ({
  activeSelect: {
    borderColor: `${primaryColor} !important`,
    "& svg": {
      color: `${primaryColor} !important`,
    },
  },
}))

interface Props {
  availableBodySystemOptions: DropDownOption[]
  bodySystemId: string
  handleBodySystemIdChange: (value: string) => void
}

const BodySystemFilter = ({
  availableBodySystemOptions,
  bodySystemId,
  handleBodySystemIdChange,
}: Props) => {
  const classes = useStyles()
  const getBodySystemOptionByKey = (key: string) => {
    return availableBodySystemOptions.find((option) => option.key === key)
  }

  return (
    <div>
      <Select
        onValueChange={handleBodySystemIdChange}
        value={bodySystemId}
        defaultValue={"all"}
      >
        <SelectTrigger
          className={clsx(
            "min-w-[200px] py-1 pr-2 pl-4 rounded-lg shadow-sm border border-slate-200",
            bodySystemId !== "all" ? classes.activeSelect : ""
          )}
        >
          <SelectValue
            placeholder={
              <OptionLabel
                option={getBodySystemOptionByKey("all")}
                color={textPrimaryColor}
              />
            }
          />
        </SelectTrigger>
        <SelectContent className="z-[1300]">
          {availableBodySystemOptions.map((option) => (
            <SelectItem key={option.key} value={option.key}>
              <OptionLabel
                option={option}
                color={
                  option.key === bodySystemId && option.key !== "all"
                    ? primaryColor
                    : textPrimaryColor
                }
              />
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
    </div>
  )
}

export default BodySystemFilter
