import { useCallback, useMemo, useState } from "react"
import { useDispatch } from "react-redux"

import { Tooltip } from "@material-ui/core"

import { ReactComponent as PrintBlue } from "app/assets/images/print-blue.svg"
import { ReactComponent as PrintDark } from "app/assets/images/print-dark.svg"
import Button from "app/components/design-system/Button"
import { requisitionDisabledForOrder } from "app/dataServices/orderDataService"
import { checkHasHandfilledRequisitionForms } from "app/utils/order-utils"

import ViewHandfilledRequisitionsModal from "./ViewHandfilledRequisitionsModal"
import usePatientOrderStyles from "./hooks/usePatientOrderStyles"
import * as Actions from "./store/actions"

function ViewRequisitionsButton({ order, patient }) {
  const dispatch = useDispatch()
  const hasHandfilledRequisitionForms = useMemo(() => {
    return checkHasHandfilledRequisitionForms(order)
  }, [order])
  const [isHandfilledReqModalOpen, setIsHandfilledReqModalOpen] =
    useState(false)
  const [isLoadingRequisitions, setIsLoadingRequisitions] = useState(false)

  const loadInstantRequisitions = async () => {
    setIsLoadingRequisitions(true)
    await dispatch(Actions.openInstantRequisitions(order.id))
    setIsLoadingRequisitions(false)
  }

  const handleClick = useCallback(() => {
    if (hasHandfilledRequisitionForms) {
      setIsHandfilledReqModalOpen(true)
      return
    }

    loadInstantRequisitions()
  }, [hasHandfilledRequisitionForms, loadInstantRequisitions])

  const styles = usePatientOrderStyles()

  const [disabled] = requisitionDisabledForOrder(order, order.patient)

  return (
    <Tooltip
      arrow
      disableHoverListener={!disabled}
      title={
        <div>
          <b>Pending Patient Payment</b>
          <br />
          Ask {patient.first_name} to check their email for the payment link.
        </div>
      }
    >
      <span className={styles.buttonContainer}>
        <Button
          color="secondary"
          shadow="default"
          disabled={disabled}
          startIcon={
            disabled ? <PrintDark width={15} /> : <PrintBlue width={15} />
          }
          onClick={handleClick}
          loading={isLoadingRequisitions}
          className={styles.button}
        >
          View & Print Requisitions
        </Button>
        {isHandfilledReqModalOpen && (
          <ViewHandfilledRequisitionsModal
            loadInstantRequisitions={loadInstantRequisitions}
            onClose={() => setIsHandfilledReqModalOpen(false)}
            open={true}
            order={order}
          />
        )}
      </span>
    </Tooltip>
  )
}

export default ViewRequisitionsButton
