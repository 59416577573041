import { Order, PatientPortalOrder } from "app/types"

import GenovaInsuranceExplanationCheckout from "./GenovaInsuranceExplanationCheckout"
import GenovaInsuranceExplanationTooltip from "./GenovaInsuranceExplanationTooltip"
import useGenovaInsuranceExplanation from "./useGenovaInsuranceExplanation"

interface Props {
  order: Order | PatientPortalOrder
  location: "cart" | "patientCheckout" | "patientCheckoutConfirmation"
}

function GenovaInsuranceExplanation({ order, location }: Props) {
  const explanation = useGenovaInsuranceExplanation(order)

  if (!explanation) {
    return null
  }

  if (["cart", "patientCheckoutConfirmation"].includes(location)) {
    return (
      <GenovaInsuranceExplanationTooltip
        explanation={explanation}
        location={location}
        order={order}
      />
    )
  }

  return <GenovaInsuranceExplanationCheckout explanation={explanation} />
}

export default GenovaInsuranceExplanation
