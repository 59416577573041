import { useCallback, useMemo, useState } from "react"

import { reduce } from "lodash"
import { useSWRConfig } from "swr"

import { ColumnFiltersState, PaginationState } from "@tanstack/react-table"

import { API } from "app/api"
import { convertPaginationToParams } from "app/dashboard/utils"
import useEventCallback from "app/hooks/use-event-callback"
import useHandleApiError from "app/hooks/use-handle-api-error"
import { getIdentifier } from "app/swr/helpers/resource"
import { writeToCache } from "app/swr/helpers/swr"
import useCachedCollection from "app/swr/hooks/use-cached-collection"
import useCollectionSWR from "app/swr/hooks/use-collection-swr"
import { ResourceCollection, ResourceResponse } from "app/swr/types"
import resourceRequest from "app/swr/utils/resource-request"
import { Biomarker } from "types/biomarker"
import {
  LabCompanyBiomarkerRange,
  LabCompanyBiomarkerRangeCreate,
} from "types/lab-company-biomarker-range"

import { OptimalRangeSource } from "../../constants"

interface Props {
  pagination?: PaginationState
  columnFilters?: ColumnFiltersState
  labCompanyKey?: string
  hideUnsetRanges?: boolean
  labCompanyId?: string
  biomarkerId?: string
  onlyDemographicRanges?: boolean
}

export default function useOptimalRanges({
  pagination,
  columnFilters,
  labCompanyKey,
  hideUnsetRanges,
  labCompanyId,
  biomarkerId,
  onlyDemographicRanges,
}: Props) {
  const handleApiError = useHandleApiError()
  const globalConfig = useSWRConfig()

  const [isCreateLoading, setIsCreateLoading] = useState(false)

  let params = {}
  if (Boolean(labCompanyKey)) {
    params["filter[lab_company.key]"] = labCompanyKey
  } else if (Boolean(labCompanyId)) {
    params["filter[lab_company.id]"] = labCompanyId
  }

  if (hideUnsetRanges) {
    params["hideUnsetRanges"] = true
  } else {
    delete params["hideUnsetRanges"]
  }

  if (biomarkerId) {
    params["filter[biomarker.id]"] = biomarkerId
  } else {
    delete params["filter[biomarker.id]"]
  }

  if (onlyDemographicRanges) {
    params["onlyDemographicRanges"] = true
  } else {
    delete params["onlyDemographicRanges"]
  }

  const paginationParams = useMemo(
    () => (pagination ? convertPaginationToParams(pagination) : {}),
    [pagination]
  )

  const columnFilterParams = useMemo(() => {
    return reduce(
      columnFilters,
      (result, filter) => {
        if (filter.value !== undefined && filter.id === "biomarkerName") {
          result["biomarker_name"] = filter.value as string
        }

        return result
      },
      {} as Record<string, string | string[] | undefined>
    )
  }, [columnFilters])

  const {
    data,
    mutate: mutateOptimalRanges,
    isLoading,
    meta,
  } = useCollectionSWR<ResourceCollection>(
    "/lab_company_biomarker_range/",
    {
      include: ["biomarker", "lab_company"],
      params: {
        ...params,
        ...paginationParams,
        ...columnFilterParams,
      },
    },
    {
      revalidateIfStale: true,
      revalidateOnFocus: true,
      revalidateOnMount: false,
      revalidateOnReconnect: true,
    }
  )

  const createOptimalRange = useCallback(
    async (
      payload: LabCompanyBiomarkerRangeCreate,
      previousOptimalRange: LabCompanyBiomarkerRange
    ) => {
      try {
        setIsCreateLoading(true)

        await mutateOptimalRanges(
          async (previousData) => {
            if (!previousData) {
              return previousData
            }

            const { data: responseData } = await resourceRequest<
              ResourceResponse<LabCompanyBiomarkerRange>,
              { data: LabCompanyBiomarkerRangeCreate }
            >({
              method: "post",
              url: `/lab_company_biomarker_range/`,
              data: {
                data: payload,
              },
            })

            await writeToCache(globalConfig, responseData)

            const nextData = previousData.data.map((existingRange) =>
              existingRange.id === previousOptimalRange.id
                ? getIdentifier(responseData)
                : existingRange
            )

            return { data: nextData, meta: previousData.meta }
          },
          {
            revalidate: true,
            rollbackOnError: false,
            throwOnError: true,
          }
        )

        setIsCreateLoading(false)
      } catch (error) {
        setIsCreateLoading(false)
        handleApiError(error)

        // re-throw so that the caller can handle the error
        throw error
      }
    },
    [mutateOptimalRanges]
  )

  const resetOptimalRange = useEventCallback(
    async (biomarkerRangeId: string) => {
      try {
        await mutateOptimalRanges(
          async (previousData) => {
            if (!previousData) {
              return previousData
            }

            const { data: responseData } = await resourceRequest<
              ResourceResponse<LabCompanyBiomarkerRange>,
              { data: LabCompanyBiomarkerRangeCreate }
            >({
              method: "post",
              url: `/lab_company_biomarker_range/${biomarkerRangeId}/reset/`,
            })

            await writeToCache(globalConfig, responseData)

            const nextData = previousData.data.map((existingRange) =>
              existingRange.id === biomarkerRangeId
                ? getIdentifier(responseData)
                : existingRange
            )

            return { data: nextData, meta: previousData.meta }
          },
          {
            revalidate: true,
            rollbackOnError: false,
            throwOnError: true,
          }
        )
      } catch (error) {
        handleApiError(error)
      }
    }
  )

  const removeAllOptimalRangesForLabCompany = useEventCallback(
    async (labCompanyKey: string) => {
      try {
        await mutateOptimalRanges(
          async (previousData) => {
            if (!previousData) {
              return previousData
            }

            await API.RupaBloodDashboards.removeAllClinicOptimalRanges(
              labCompanyKey
            )
          },
          {
            revalidate: true,
            throwOnError: true,
          }
        )
      } catch (error) {
        handleApiError(error)
      }
    }
  )

  const optimalRanges = useCachedCollection<LabCompanyBiomarkerRange>(data)

  const biomarkers = useCachedCollection<Biomarker>(
    optimalRanges.map(
      (optimalRange) => optimalRange.relationships.biomarker.data
    )
  )

  const availableLabCompanies = useCachedCollection(
    optimalRanges
      .filter((range) => range.attributes.source === OptimalRangeSource.CLINIC)
      .map((optimalRange) => optimalRange.relationships.lab_company.data)
  )

  const uniqueLabCompanyKeys = {}
  const allTabs = availableLabCompanies.map((labCompany) => {
    return {
      label: labCompany.attributes.name,
      id: labCompany.attributes.key,
    }
  })
  const availableLabCompanyTabs = allTabs.filter((tab) => {
    if (!uniqueLabCompanyKeys[tab.id]) {
      uniqueLabCompanyKeys[tab.id] = true
      return true
    }

    return false
  })

  return {
    optimalRanges,
    meta,
    biomarkers,
    mutateOptimalRanges,
    isLoading,
    isCreateLoading,
    availableLabCompanyTabs,
    createOptimalRange,
    resetOptimalRange,
    removeAllOptimalRangesForLabCompany,
  }
}
