import NiceModal, { useModal } from "@ebay/nice-modal-react"
import {
  Button,
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@rupahealth/design"

interface Props {
  onDelete: () => Promise<void>
  documentTitle?: React.ReactNode
  patientName?: string
}

const DeleteDocumentConfirmModal: React.FC<Props> = ({
  onDelete,
  documentTitle = "Document",
  patientName = "your patient",
}) => {
  const modal = useModal()

  return (
    <Dialog
      open={modal.visible}
      onOpenChange={(open) => {
        if (!open) modal.hide()
      }}
    >
      <DialogContent>
        <DialogHeader>
          <DialogTitle tabIndex={0}>Delete {documentTitle}</DialogTitle>
          <DialogDescription>
            This document will be permanently deleted and not accessible by you
            or {patientName}!
          </DialogDescription>
        </DialogHeader>
        <DialogFooter>
          <Button
            variant="outline"
            className="w-full"
            onClick={() => modal.hide()}
          >
            Actually, Let's Keep It
          </Button>
          <Button
            variant="outline-destructive"
            className="w-full"
            onClick={() => onDelete().then(() => modal.hide())}
          >
            Delete Document
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

export const DeleteDocumentConfirmNiceModal = NiceModal.create<Props>(
  (props) => {
    return <DeleteDocumentConfirmModal {...props} />
  }
)

export default DeleteDocumentConfirmModal
