import { useState } from "react"

import classNames from "classnames"

import {
  IconButton,
  makeStyles,
  Paper,
  Typography,
  useMediaQuery,
  Theme,
} from "@material-ui/core"
import DeleteIcon from "@material-ui/icons/Delete"

import GreenDollarCircleIcon from "app/assets/icons/green-dollar-circle.svg"
import TealMessageBubbleIcon from "app/assets/icons/teal-message-bubble.svg"
import DragHandleIcon from "app/assets/images/storefront/drag-handle.svg"
import SampleTypePill, {
  backgroundColorForColorGroup,
  colorGroupForName,
  textColorForColorGroup,
} from "app/components/SampleTypePill"
import Tooltip from "app/components/Tooltip"
import BodyText from "app/components/design-system/BodyText"
import DesignSystemButton from "app/components/design-system/Button"
import {
  StorefrontLabTestType,
  StorefrontLabTestTypeType,
  StorefrontProductType,
  useResourceByIdSelector,
  useResourceSelector,
} from "app/hooks/use-resource"
import { colors, navy } from "app/theme"
import { formatDollars } from "app/utils"

import { calculateTotalTestPricing, generatePricingTooltipText } from "../utils"

const useStyles = makeStyles((theme) => ({
  testWrapper: {
    display: "flex",
    "flex-direction": "column",
    alignItems: "flex-start",
    gap: 10,
    background: "#FFFFFF",
    border: "1px solid #E2E8F0",
    boxShadow:
      "0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)",
    borderRadius: 8,
    padding: "13px 11px",
    height: "100%",
    justifyContent: "space-around",
  },
  iconHover: {
    "&:hover": {
      backgroundColor: "rgba(0,0,0,0.05)",
    },
  },
  iconHoverSelected: {
    "&:hover": {
      backgroundColor: "rgba(255,255,255,0.15)",
    },
  },
  labTestCardContentContainer: {
    display: "flex",
    "flex-wrap": "nowrap",
    gap: 7,
  },
  dollarText: {
    color: navy,
    fontWeight: 600,
    borderBottom: `1px dashed ${colors.blueGray[400]}`,
    display: "inline-block",
    paddingBottom: 3,
  },
  customFeeContainer: {
    display: "flex",
    padding: "2px 8px",
    borderRadius: 40,
    background: colors.green[100],
    height: 23,
    gap: 6,
  },
  notesIconContainer: {
    display: "flex",
    padding: "0px 5px",
    background: colors.lightBlue[100],
    borderRadius: 40,
  },
  customizeButtonContainer: {
    width: "100%",
  },
  topTextContainer: {
    display: "flex",
    "flex-direction": "row",
    width: "100%",
    // justifyContent: "center",
  },
  topTextItem: {
    flex: "1",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "&:first-child": {
      marginRight: "auto",
      justifyContent: "flex-start",
    },
    "&:last-child": {
      marginLeft: "auto",
      justifyContent: "flex-end",
    },
  },
}))

const LabTestCardSampleTypePill = ({
  name,
  isSelected,
  isAvailable,
  shouldTruncateName,
}) => {
  const colorGroup = colorGroupForName(name)
  const textStyle = {
    color: isSelected
      ? "white"
      : !isAvailable
      ? "rgb(160, 160, 160)"
      : textColorForColorGroup(colorGroup),
  }
  const style = {
    backgroundColor: isSelected
      ? textColorForColorGroup(colorGroup)
      : !isAvailable
      ? "rgb(238, 238, 239)"
      : backgroundColorForColorGroup(colorGroup),
    marginLeft: 0,
    marginRight: 6,
    minWidth: 0,
  }

  return (
    <SampleTypePill
      name={shouldTruncateName && name ? name.slice(0, 2) : name}
      style={style}
      textStyle={textStyle}
      title={name}
    />
  )
}

interface Props {
  product?: StorefrontProductType
  onLabTestDelete: (labTest: StorefrontLabTestType | undefined) => void
  openModal: (product: StorefrontProductType | undefined) => void
  dragAttributes?: any
  dragListeners?: any
  hidePhysServicesPricing: boolean
}

export default function EcommerceLabTestCard({
  product,
  onLabTestDelete,
  openModal,
  dragAttributes,
  dragListeners,
  hidePhysServicesPricing,
}: Props) {
  const classes = useStyles()
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"))

  const labTest = useResourceByIdSelector(
    "lab_test",
    product?.relationships.lab_test.data?.id
  )

  const [cardHovered, setCardHovered] = useState(false)

  const labCompany = useResourceByIdSelector(
    "lab_company",
    labTest?.relationships.lab_company.data.id
  )

  const labTestTypes: (StorefrontLabTestTypeType | undefined)[] =
    useResourceSelector((entities) => {
      if (!labTest?.relationships.lab_test_types) {
        return []
      }

      return labTest?.relationships?.lab_test_types.data.map((ref) => {
        return entities?.lab_test_type?.[ref.id]
      })
    })

  const customFee = useResourceByIdSelector(
    "custom_fee",
    product?.relationships.custom_fee.data?.id
  )

  const pricingTooltipText = generatePricingTooltipText(
    hidePhysServicesPricing,
    customFee
  )

  let totalTestPrice = 0
  if (labTest) {
    totalTestPrice = calculateTotalTestPricing(
      hidePhysServicesPricing,
      [labTest],
      customFee
    )
  }

  return (
    <>
      <Paper
        className={classes.testWrapper}
        elevation={0}
        tabIndex={0}
        onMouseEnter={() => !isMobile && setCardHovered(true)}
        onMouseLeave={() => !isMobile && setCardHovered(false)}
      >
        <div className={classes.topTextContainer}>
          {labTestTypes.length === 1 && (
            <div className={classes.topTextItem}>
              {labTestTypes[0]?.attributes.name && (
                <LabTestCardSampleTypePill
                  name={labTestTypes[0]?.attributes.name}
                  isAvailable={true}
                  isSelected={false}
                  shouldTruncateName={false}
                />
              )}
            </div>
          )}
          {labTestTypes.length > 1 && (
            <div className={classes.topTextItem}>
              <div className="flex items-center flex-wrap">
                {labTestTypes.map((type) => {
                  return (
                    labTestTypes[0]?.attributes.name && (
                      <LabTestCardSampleTypePill
                        name={type?.attributes.name}
                        key={type?.attributes.name}
                        shouldTruncateName={true}
                        isSelected={false}
                        isAvailable={true}
                      />
                    )
                  )
                })}
              </div>
            </div>
          )}
          {dragAttributes && dragListeners && (
            <div
              {...dragAttributes}
              {...dragListeners}
              className={classes.topTextItem}
            >
              <img src={DragHandleIcon} alt="drag handle" />
            </div>
          )}
          <div
            className={classes.topTextItem}
            style={{ opacity: cardHovered || isMobile ? 1 : 0 }}
          >
            <div>
              <IconButton
                className={classNames("hover:bg-gray-200", "p-px rounded w-6")}
                onClick={() => onLabTestDelete(labTest)}
                aria-label="Test delete"
              >
                <DeleteIcon
                  fontSize="small"
                  style={{
                    color: colors.blueGray[400],
                  }}
                />
              </IconButton>
            </div>
          </div>
        </div>
        <div>
          <Typography variant="body1" className="font-semibold text-gray-800">
            <span className={"text-gray-800"}>{labTest?.attributes.name}</span>{" "}
            <br />
            <span className={"text-gray-600"}>
              {labCompany && labCompany.attributes.short_name}
            </span>
          </Typography>
        </div>

        <div className={classes.labTestCardContentContainer}>
          <Tooltip
            arrow
            interactive
            title={
              pricingTooltipText === "" ? (
                pricingTooltipText
              ) : (
                <BodyText size="xs">{pricingTooltipText}</BodyText>
              )
            }
            placement="top"
          >
            <div>
              <BodyText variant="body1" className={classes.dollarText}>
                {formatDollars(totalTestPrice)}
              </BodyText>
            </div>
          </Tooltip>

          {customFee && (
            <div className={classes.customFeeContainer}>
              <img
                src={GreenDollarCircleIcon}
                alt="green dollar circle"
                style={{ height: 19 }}
              />
              <BodyText weight="semibold" size="sm">
                Incl. ${customFee?.attributes.price / 100} Fee
              </BodyText>
            </div>
          )}
          {product?.attributes.notes && (
            <Tooltip
              arrow
              interactive
              title={
                <BodyText size="xs">
                  This Lab Test contains a custom note from you
                </BodyText>
              }
              placement="top"
            >
              <div className={classes.notesIconContainer}>
                <img src={TealMessageBubbleIcon} alt="teal message bubble" />
              </div>
            </Tooltip>
          )}
        </div>

        <div className={classes.customizeButtonContainer}>
          <DesignSystemButton
            color="secondary"
            fullWidth
            onClick={() => openModal(product)}
          >
            Edit Fee & Note
          </DesignSystemButton>
        </div>
      </Paper>
    </>
  )
}
