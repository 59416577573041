import { Dialog, Grid, makeStyles, Theme } from "@material-ui/core"
import MuiDialogContent from "@material-ui/core/DialogContent"
import MuiDialogTitle from "@material-ui/core/DialogTitle"
import IconButton from "@material-ui/core/IconButton"
import CloseIcon from "@material-ui/icons/Close"

import BodyText from "app/components/design-system/BodyText"
import DesignSystemButton from "app/components/design-system/Button"
import DisplayText from "app/components/design-system/DisplayText"
import { ModalActions } from "app/components/modals"
import { colors, navy } from "app/theme"

const styles = (theme: Theme) => ({
  dialogPaper: {
    [theme.breakpoints.up("md")]: {
      minWidth: 400,
    },
    margin: 15,
    borderRadius: 7,
  },
  titleAndInfoContainer: {
    display: "flex",
    "flex-direction": "column",
    "flex-wrap": "wrap",
    marginRight: 24,
  },
  closeButton: {
    color: navy,
    position: "relative" as "relative",
    top: -5,
    right: 0,
    marginRight: -12,
  },
  styledDialogTitle: {
    display: "flex",
    "flex-direction": "row",
    justifyContent: "space-between",
    "flex-wrap": "nowrap",
    backgroundColor: "white",
    borderBottomWidth: 1,
    borderColor: colors.blueGray[200],
    alignItems: "center",
    padding: "15px 24px 8px 24px",
  },
  styledContent: {
    padding: theme.spacing(2),
    backgroundColor: colors.coolGray[100],
  },
})

const useStyles = makeStyles(styles)

interface InstructionsModalProps {
  open: boolean
  onClose: () => void
  selectedEmr: any
}

export default function EMRInstructionsModal({
  open,
  onClose,
  selectedEmr,
}: InstructionsModalProps) {
  const classes = useStyles(styles)
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="emr-instruction-modal"
      classes={{
        paper: classes.dialogPaper,
      }}
    >
      <TitleSection
        title={`Connect to ${selectedEmr.name}`}
        onClose={onClose}
      />
      <MuiDialogContent className={classes.styledContent}>
        <Grid container direction="row" spacing={2}>
          <Grid item xs={12}>
            <BodyText size="sm" weight="regular">
              {selectedEmr.instructionText}
            </BodyText>
          </Grid>
        </Grid>
      </MuiDialogContent>
      <ModalActions>
        <DesignSystemButton
          onClick={() => selectedEmr.connectFn()}
          color="secondary"
        >
          {selectedEmr.connectButtonText}
        </DesignSystemButton>
      </ModalActions>
    </Dialog>
  )
}

const TitleSection = ({ title, onClose }) => {
  const classes = useStyles(styles)
  const closeButton = onClose && (
    <IconButton
      aria-label="close"
      onClick={onClose}
      key="close-button"
      className={classes.closeButton}
    >
      <CloseIcon />
    </IconButton>
  )

  return (
    <MuiDialogTitle disableTypography className={classes.styledDialogTitle}>
      <div className={classes.titleAndInfoContainer}>
        <DisplayText weight="semibold" size="lg">
          {title}
        </DisplayText>
      </div>
      {closeButton}
    </MuiDialogTitle>
  )
}
