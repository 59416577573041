import { CircularProgress, styled } from "@material-ui/core"

interface Props {
  ariaLabel: string
}

const LoadingContainer = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flex: 1,
})

const Loading = ({ ariaLabel }: Props) => (
  <LoadingContainer>
    <CircularProgress aria-label={ariaLabel} />
  </LoadingContainer>
)

export default Loading
