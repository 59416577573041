import axios from "axios"
import useSWR from "swr"

import { getApiBaseUrl } from "app/utils"

interface BookingDataProvider {
  provider_name: string
}

interface AxleHealthAddress {
  address1: string
  address2: string
  city: string
  state: string
  zip_code: string
}

interface AxleHealthVisit {
  address: AxleHealthAddress
  visit_datetime: string
  visit_end_datetime: string
  axle_reschedule_link: string
  status: string
}

export interface BookingData {
  order: {
    id: string
    phlebotomy_providers: BookingDataProvider[]
    patient: {
      id: string
      first_name: string
      last_name: string
      phone_number: string
      biological_sex: string
      birthday: string
      default_shipping_address: {
        street_1: string
        street_2: string
        city: string
        state: string
        zipcode: string
      }
      user: {
        email: string
      }
    }
    axle_health_visits: AxleHealthVisit[]
  }
}

export default function useBookingData(token: string | undefined) {
  const { data, error, isLoading } = useSWR<BookingData>(
    token ? "/phlebotomy-booking/?token=" + token : null,
    {
      async fetcher(url) {
        const response = await axios.request<BookingData>({
          baseURL: getApiBaseUrl() + "/api/",
          url,
          method: "get",
        })

        return response.data
      },
      revalidateOnMount: true,
      revalidateOnReconnect: true,
      refreshInterval(latestData) {
        // While there is no booking data, poll waiting for booking webhook to come in
        if (latestData?.order.axle_health_visits.length) {
          return 0
        } else {
          return 500
        }
      },
    }
  )

  return {
    data,
    error,
    isLoading,
  }
}
