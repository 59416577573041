import { useMemo } from "react"

import { useLocation } from "react-router-dom"

export function useCheckoutTokenFromLocation(): string {
  const location = useLocation()
  return useMemo(() => {
    const urlParams = new URLSearchParams(location.search)
    return urlParams.get("token") || ""
  }, [location.search])
}
