import * as React from "react"

import { makeStyles } from "@material-ui/core"

import { ReactComponent as ClipboardTop } from "app/assets/images/clipboard-top.svg"
import { colors } from "app/theme"

const styles = (theme) => ({
  clipboardContainerOuter: {
    width: 532,
    [theme.breakpoints.down("sm")]: {
      width: "96%",
    },
    margin: "auto",
    marginTop: "30px",
    marginBottom: "10px",
    padding: 16,
    background: colors.blueGray[200],
    boxShadow:
      "0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)",
    borderRadius: "7px",
  },
  clipboardContainerInner: {
    padding: "30px 20px",
    paddingBottom: "1px",
    background: "white",
    borderRadius: "7px",
  },
  clipboardTitle: {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%);",
    width: 300,
  },
  clipboardTop: {
    marginBottom: "-65px",
    position: "relative" as "relative",
    textAlign: "center" as "center",
    color: "white",
  },
})

const useStyles = makeStyles(styles)

interface Props {
  children: React.ReactNode
  title: string
}

/**
 * Clipboard component
 * @param children - Content to go inside container
 * @param title - Title at the top of the clipboard
 */
const ClipboardContainer = ({ children, title }: Props) => {
  const classes = useStyles(styles)

  return (
    <div>
      <div className={classes.clipboardTop}>
        <div className={classes.clipboardTitle}>{title}</div>
        <ClipboardTop />
      </div>
      <div className={classes.clipboardContainerOuter}>
        <div className={classes.clipboardContainerInner}>{children}</div>
      </div>
    </div>
  )
}

export default ClipboardContainer
