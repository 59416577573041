import { makeStyles } from "@material-ui/core/styles"

import { ReactComponent as InboxIcon } from "app/assets/icons/inbox.svg"
import BodyText from "app/components/design-system/BodyText"
import { colors } from "app/theme"

const useStyles = makeStyles((theme) => ({
  container: {
    height: 164,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "white",
  },
  text: {
    marginTop: 7,
    color: colors.blueGray[500],
  },
}))

const TAB_STATUS_NAMES = {
  draft: "Drafts",
  in_progress: "In Progress",
  results_in: "Results In",
  reviewed: "Reviewed",
}

interface Props {
  appliedFilters: any
  multipleOrderingPractitioners?: boolean
}

export default function OrderTableEmptyFiller({
  appliedFilters,
  multipleOrderingPractitioners,
}: Props) {
  const classes = useStyles()

  const filters_active =
    appliedFilters?.status ||
    appliedFilters?.patient_id ||
    appliedFilters?.storefront_ids ||
    (appliedFilters?.practitioner_id && multipleOrderingPractitioners)

  const defaultContent = TAB_STATUS_NAMES[appliedFilters?.tab_status]
    ? `Looks like you don't have any ${
        TAB_STATUS_NAMES[appliedFilters.tab_status]
      } orders yet.`
    : "Looks like you don't have any orders yet."
  const textContent = filters_active
    ? "Looks like there aren’t any orders that match the filters applied!"
    : defaultContent

  return (
    <div className={classes.container}>
      <div>
        <InboxIcon />
        <BodyText
          color="inherit"
          weight="semibold"
          size="sm"
          className={classes.text}
        >
          {textContent}
        </BodyText>
      </div>
    </div>
  )
}
