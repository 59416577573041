import { useEffect } from "react"

import { useHistory, useLocation } from "react-router-dom"

import useFeatureFlag, { FeatureFlag } from "app/hooks/use-feature-flag"
import useQuery from "app/hooks/use-query"
import {
  PANEL_BUILDER_TRACKING_EVENTS,
  trackPanelBuilderEvent,
} from "app/services/segment"

import usePanelBuilderModal from "./use-panel-builder-modal"

const LAUNCH_PANEL_BUILDER_PARAM = "launch-panel-builder"

export default function useLaunchPanelBuilderDeepLink() {
  const [panelBuilderEnabled] = useFeatureFlag(FeatureFlag.PanelBuilder)
  const panelBuilderModal = usePanelBuilderModal()
  const location = useLocation()
  const history = useHistory()
  const query = useQuery()
  const shouldLaunch =
    panelBuilderEnabled &&
    query.get(LAUNCH_PANEL_BUILDER_PARAM) === "true" &&
    !panelBuilderModal.visible

  function openPanelBuilderModal() {
    trackPanelBuilderEvent(
      PANEL_BUILDER_TRACKING_EVENTS.CREATE_BASIC_BLOOD_PANEL,
      {}
    )
    panelBuilderModal.show({
      onClose: () => {
        panelBuilderModal.remove()
      },
    })
  }

  useEffect(() => {
    if (shouldLaunch) {
      openPanelBuilderModal()

      const nextQuery = new URLSearchParams(query)

      nextQuery.delete(LAUNCH_PANEL_BUILDER_PARAM)

      history.replace({
        ...location,
        search: nextQuery.toString(),
      })
    }
  }, [shouldLaunch])
}
