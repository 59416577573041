import { ReactComponent as DownloadIcon } from "app/assets/images/download-blue.svg"
import Tooltip from "app/components/Tooltip"
import Button from "app/components/design-system/Button"
import useQuery from "app/hooks/use-query"
import { PatientPortalOrderedTest } from "app/patient-portal/types"
import { trackPatientPortalAction } from "app/services/segment.typed"
import makeAppStyles from "app/utils/makeAppStyles"

const useStyles = makeAppStyles((theme) => ({
  buttonContainer: {
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: 140,
    },
  },
  button: {
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: 140,
    },
  },
}))

export interface DownloadResultsButtonProps {
  orderedTest: PatientPortalOrderedTest
}

export default function DownloadResultsButton({
  orderedTest,
}: DownloadResultsButtonProps) {
  const classes = useStyles()
  const query = useQuery()
  const available = Boolean(orderedTest.attributes.lab_results)

  function handleTrackingOnClick() {
    trackPatientPortalAction(query.get("token"), "download_results")
  }

  const buttonNode = (
    <Button
      className={classes.button}
      color="primary"
      disabled={!available}
      href={orderedTest.attributes.lab_results}
      onClick={handleTrackingOnClick}
      size="small"
      startIcon={<DownloadIcon fill="currentColor" viewBox="0 0 18 17" />}
      target="_blank"
      type="button"
    >
      Results
    </Button>
  )

  if (available) {
    return buttonNode
  }

  return (
    <Tooltip
      arrow
      interactive
      placement="bottom"
      title="Results have not been released by your practitioner."
    >
      {/* Needs a span to apply the hover listener to. */}
      <span className={classes.buttonContainer}>{buttonNode}</span>
    </Tooltip>
  )
}
