import PendingPlaceholder from "app/components/LabTests/PendingPlaceholder"
import { colors } from "app/theme"
import interleave from "app/utils/interleave"
import makeAppStyles from "app/utils/makeAppStyles"
import { LabTest } from "types/lab-test"

import ComboGroupLabTestRow from "./ComboGroupLabTestRow"

const useStyles = makeAppStyles((theme) => ({
  separator: {
    borderBottom: `1px solid ${colors.blueGray[200]}`,
  },
  placeholderContainer: {
    padding: "20px 0px",
  },
}))

export interface ComboGroupLabTestListProps {
  labTests: LabTest[]
  selectedIds: string[]
  onAdd: (id: string) => void
  onRemove: (id: string) => void
  isLoading: boolean
  maxAddOns?: number
}

export default function ComboGroupLabTestList({
  labTests,
  selectedIds,
  onAdd,
  onRemove,
  isLoading,
  maxAddOns,
}: ComboGroupLabTestListProps) {
  const classes = useStyles()

  return (
    <div>
      {interleave(
        isLoading
          ? Array(2).fill(
              <div className={classes.placeholderContainer}>
                <PendingPlaceholder width={200} />
              </div>
            )
          : labTests.map((labTest) => (
              <ComboGroupLabTestRow
                labTest={labTest}
                selected={selectedIds.includes(labTest.id)}
                onAdd={onAdd}
                onRemove={onRemove}
                disabled={
                  Boolean(maxAddOns && selectedIds.length >= maxAddOns) &&
                  !selectedIds.includes(labTest.id)
                }
                disabledReason={
                  Boolean(maxAddOns && selectedIds.length >= maxAddOns) &&
                  !selectedIds.includes(labTest.id)
                    ? `Maximum of ${maxAddOns} add-ons allowed`
                    : ""
                }
              />
            )),
        (_, key) => (
          <div className={classes.separator} />
        )
      )}
    </div>
  )
}
