export enum IOKInventoryEvents {
  VISIT = "IOK Inventory Visit",
  ACTIVATE_KIT = "IOK Inventory Activate Kit",
  START_ORDER = "IOK Inventory Start Order",
  REORDER_SUPPLIES = "IOK Inventory Reorder Supplies",
}

export interface InventoryItem {
  in_office_kit_product_name: string
  in_office_kit_product_id: string
  in_office_kit_lab_company_id: string
  lab_company_name: string
  lab_company_key: string
  num_in_stock: number
  num_pre_stock: number
  activation_id_count: number
  most_recent_order_quantity: number
}

export interface FilterOption {
  id: string
  label: string
}
