import { API } from "app/api"
import { refreshPricing } from "app/main/checkout/store/actions"
import { handleApiError } from "app/utils"

export const GET_STRIPE_CONNECT_ACCOUNTS = "[STRIPE_CONNECT] GET ACCOUNTS"
export const GET_CUSTOM_FEES = "[STRIPE_CONNECT] GET CUSTOM FEES"
export const REMOVE_CUSTOM_FEES = "[STRIPE_CONNECT] REMOVE CUSTOM FEES"

export function getStripeConnectAccount() {
  const request = API.StripeConnectAccount.getList()

  return (dispatch) =>
    request
      .then(async (response) => {
        await dispatch({
          type: GET_STRIPE_CONNECT_ACCOUNTS,
          payload: response.data.objects,
        })
      })
      .catch((error) => dispatch(handleApiError(error)))
}

export function getCustomFees(stripeConnectAccountId) {
  const request = API.CustomFee.get(stripeConnectAccountId)

  return (dispatch) =>
    request
      .then(async (response) => {
        await dispatch({
          type: GET_CUSTOM_FEES,
          payload: response.data.results,
        })
      })
      .catch((error) => dispatch(handleApiError(error)))
}

export function removeCustomFeeLineItem(orderId, customFeeLineItemId) {
  const request = API.CustomFeeLineItem.delete(orderId, customFeeLineItemId)

  return (dispatch, getState) => {
    const prevState = getState().orders.customFees.customFees

    return request
      .then(async (response) => {
        await dispatch({
          type: GET_CUSTOM_FEES,
          payload: [],
        })
        dispatch(refreshPricing(orderId))
      })
      .catch((error) => {
        dispatch(handleApiError(error))
        dispatch({
          type: GET_CUSTOM_FEES,
          payload: prevState,
        })
      })
  }
}
