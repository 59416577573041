import { Theme } from "@material-ui/core"

import BodyText from "app/components/design-system/BodyText"
import { colors } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"

import BloodLabDashboardsSendToPatientButton from "./BloodLabDashboardsSendToPatientButton"

const useStyles = makeAppStyles((theme: Theme) => ({
  container: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "11px 20px",
    borderRadius: "6px",
    border: `1px solid ${colors.blueGray[300]}`,
    background: colors.blueGray[50],
    boxShadow:
      "0px 1px 2px 0px rgba(0, 0, 0, 0.06), 0px 1px 3px 0px rgba(0, 0, 0, 0.10)",
    fontFamily: "Open Sans",
    fontSize: "15px",
    fontStyle: "normal",
    lineHeight: "135%",
    gap: 73,
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "flex-start",
      gap: 16,
    },
  },
  textContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    gap: 4,
  },
}))

interface Props {
  patientFirstName: string
  kitId: string
  orderId: string
  practitionerId: string
  isBloodDashboardsOn: boolean
  onSendResultsToPatient: () => void
}

const BloodLabDashboardsResultsNotSharedWarning = ({
  patientFirstName,
  kitId,
  orderId,
  practitionerId,
  isBloodDashboardsOn,
  onSendResultsToPatient,
}: Props) => {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <div className={classes.textContainer}>
        <BodyText weight="semibold">
          Results are not yet shared with {patientFirstName}
        </BodyText>
        <BodyText>
          When emailed, {patientFirstName} will receive their original lab
          report PDF{isBloodDashboardsOn ? " and Blood Lab Dashboard" : ""}.
        </BodyText>
      </div>
      <BloodLabDashboardsSendToPatientButton
        patientName={patientFirstName}
        kitId={kitId}
        orderId={orderId}
        practitionerId={practitionerId}
        onSendResultsToPatient={onSendResultsToPatient}
      />
    </div>
  )
}

export default BloodLabDashboardsResultsNotSharedWarning
