import { RouterHistory } from "@sentry/react/types/reactrouter"

import {
  ALERT_DISPLAY_TYPES,
  AnyBanner,
  BannerLink,
} from "app/main/banner/types"

/*
 * Determines the link used for a banner, if applicable.
 * If a link_url and link_text are provided, it will generate a link using that.
 * Otherwise, it may generate a link based on the display_type.
 * @param: history - the history object from react-router-dom
 * @param: banner - the banner to generate a link for
 */
export const bannerLink = (
  history: RouterHistory,
  banner: AnyBanner
): BannerLink | undefined => {
  if (banner.attributes.link_url) {
    return {
      text: banner.attributes.link_text || "",
      onClick: () => history.push(banner.attributes.link_url || ""),
      target: "_self",
    }
  }

  if (isAlert(banner)) {
    return {
      text: "See Orders",
      onClick: () => history.push({ search: "tab=alerts" }),
      target: "_self",
    }
  }
  return undefined
}

export function isAlert(banner: AnyBanner) {
  return ALERT_DISPLAY_TYPES.includes(banner.attributes.display_type)
}
