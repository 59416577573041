import { useCallback, useState } from "react"

import { Divider, makeStyles } from "@material-ui/core"
import MuiDialogActions from "@material-ui/core/DialogActions"
import MuiDialogContent from "@material-ui/core/DialogContent"
import MuiDialogTitle from "@material-ui/core/DialogTitle"

import ClinicInviteIcon from "app/assets/icons/clinic-invite.svg"
import EmailInviteIcon from "app/assets/icons/envelope/clinic-invite-email-green.svg"
import BodyText from "app/components/design-system/BodyText"
import DesignSystemButton from "app/components/design-system/Button"
import DisplayText from "app/components/design-system/DisplayText"
import { Dialog } from "app/components/modals"
import TeamMemberModal from "app/components/modals/TeamMemberModal"
import useAppSelector from "app/hooks/useAppSelector"
import { trackTeamMemberInvitedViaClinicInviteOnboarding } from "app/services/segment"
import { colors } from "app/theme"

const styles = (theme) => ({
  dialogPaper: {
    margin: 15,
    borderRadius: 8,
    maxWidth: 640,
  },
  titleContainer: {
    alignItems: "center",
    display: "flex",
    margin: "25px 125px 0px 125px",
    "text-align": "center",
    [theme.breakpoints.down("sm")]: {
      margin: "25px 30px 0px 30px",
    },
  },
  teamMemberContainer: {
    alignItems: "center",
    background: colors.blueGray[50],
    borderRadius: 12,
    color: colors.blueGray[500],
    display: "flex",
    "flex-direction": "column",
    padding: "33px 20px",
    margin: "0px 50px 50px 50px",
    "text-align": "center",
    "& > *": {
      margin: "5px 0px",
    },
    [theme.breakpoints.down("sm")]: {
      margin: "0px 0px 45px 0px",
    },
  },
  teamMemberListContainer: {
    display: "flex",
    "flex-direction": "row",
    "& > img": {
      marginRight: "5px",
    },
    marginBottom: "9px",
  },
  actionContainer: {
    alignItems: "center",
    background: colors.blueGray[50],
    borderTop: `1px solid ${colors.blueGray[300]}`,
    padding: "7px 11px",
    [theme.breakpoints.down("sm")]: {
      "& > * > *": {
        width: "100%",
      },
    },
  },
  divider: {
    margin: "10px 0px",
    width: "100%",
  },
})

const useStyles = makeStyles(styles)

interface Props {
  isOpen: boolean
  title?: string
  onClose: () => void
}

/**
 * Clinic Invite Modal
 * @param isOpen - If true, the modal is open. If false, the modal is closed.
 * @param title - The displayed title of the modal.
 * @param onClose - The action performed when the modal closes
 */
const ClinicInviteModal = ({
  isOpen,
  title = "Invite Team Members to streamline your workflow.",
  onClose,
}: Props) => {
  const classes = useStyles(styles)
  const practitioner = useAppSelector(({ practitioner }) => practitioner)
  const [teamMemberModalIsOpen, setTeamMemberModalIsOpen] =
    useState<boolean>(false)
  const [invitedTeamMembers, setInvitedTeamMembers] = useState<Array<Object>>(
    []
  )

  const handleContinue = useCallback(() => {
    trackTeamMemberInvitedViaClinicInviteOnboarding(
      invitedTeamMembers,
      practitioner.id
    )
    onClose()
  }, [invitedTeamMembers])

  const handleInvite = useCallback(() => {
    setTeamMemberModalIsOpen(true)
  }, [])

  const handleSkip = useCallback(() => {
    onClose()
  }, [])

  const onAddTeamMember = (newTeamMember) => {
    setInvitedTeamMembers([...invitedTeamMembers, newTeamMember])
  }

  return (
    <>
      <Dialog
        aria-labelledby="lab-test-bundle-title"
        onClose={onClose}
        open={isOpen}
        className={"fs-unmask"}
        fullWidth
        classes={{
          paper: classes.dialogPaper,
        }}
        disableBackdropClick
        disableEscapeKeyDown
      >
        <TitleSection title={title} />
        <TeamMemberSection
          handleInvite={handleInvite}
          teamMembersList={invitedTeamMembers}
        />
        <ActionSection
          handleContinue={handleContinue}
          handleSkip={handleSkip}
          teamMembersList={invitedTeamMembers}
        />
      </Dialog>
      <TeamMemberModal
        open={teamMemberModalIsOpen}
        onClose={() => setTeamMemberModalIsOpen(false)}
        onAddTeamMember={onAddTeamMember}
      />
    </>
  )
}

const TitleSection = ({ title }) => {
  const classes = useStyles(styles)

  return (
    <MuiDialogTitle disableTypography>
      <div className={classes.titleContainer}>
        <DisplayText weight="semibold" size="xl">
          {title}
        </DisplayText>
      </div>
    </MuiDialogTitle>
  )
}

const TeamMemberSection = ({ handleInvite, teamMembersList }) => {
  const classes = useStyles(styles)

  return (
    <MuiDialogContent>
      <div className={classes.teamMemberContainer}>
        <div>
          <img src={ClinicInviteIcon} alt="" />
        </div>
        <BodyText color="inherit" weight="semibold">
          Who else orders labs and should have access to your clinic?
        </BodyText>
        <BodyText color="inherit">
          Invite your team members so they can access your patients, orders, and
          results.
        </BodyText>
        {teamMembersList.length > 0 ? (
          <>
            <Divider className={classes.divider} />
            <TeamMemberListSection teamMembersList={teamMembersList} />
            <Divider className={classes.divider} />
            <DesignSystemButton
              color="secondary"
              disableRipple={true}
              onClick={handleInvite}
            >
              Invite Another Team Member
            </DesignSystemButton>
          </>
        ) : (
          <DesignSystemButton color="primary" onClick={handleInvite}>
            Invite a Team Member
          </DesignSystemButton>
        )}
      </div>
    </MuiDialogContent>
  )
}

export const TeamMemberListSection = ({ teamMembersList }) => {
  const classes = useStyles(styles)

  const teamMembers = teamMembersList.map((teamMember) => (
    <div
      className={classes.teamMemberListContainer}
      key={teamMembersList.findIndex((element) => element === teamMember)}
    >
      <img src={EmailInviteIcon} alt="" />
      {teamMember.primary_practitioner_type ? (
        <BodyText>
          {teamMember.first_name} {teamMember.last_name}{" "}
          {teamMember.primary_practitioner_type}
        </BodyText>
      ) : (
        <BodyText>
          {teamMember.first_name} {teamMember.last_name}
        </BodyText>
      )}
    </div>
  ))
  return teamMembers
}

const ActionSection = ({ handleContinue, handleSkip, teamMembersList }) => {
  const classes = useStyles(styles)

  return (
    <div className={classes.actionContainer}>
      <MuiDialogActions>
        {teamMembersList.length > 0 ? (
          <DesignSystemButton color="primary" onClick={handleContinue}>
            Continue
          </DesignSystemButton>
        ) : (
          <DesignSystemButton color="secondary" onClick={handleSkip}>
            Skip for Now
          </DesignSystemButton>
        )}
      </MuiDialogActions>
    </div>
  )
}

export default ClinicInviteModal
