import { TabId } from "../components/TableRenderer"
import { DashboardOrderStatuses } from "./use-dashboard-order-statuses"

/**
 * We want to default to the tabs in the following order if they have orders. If they don't, move on to the next priority tab:
 *
 * 1. Results In
 * 2. In Progress
 * 3. Draft
 * 4. Reviewed
 * 5. All
 */
export default function useDashboardDefaultTab({
  data,
  error,
  isLoading,
}: DashboardOrderStatuses): TabId | undefined {
  if (isLoading) {
    return undefined
  }
  if (error || !data) {
    return TabId.ALL
  }

  if (data.has_results_in_orders) {
    return TabId.RESULTS_IN
  } else if (data.has_in_progress_orders) {
    return TabId.IN_PROGRESS
  } else if (data.has_draft_orders) {
    return TabId.DRAFT
  } else if (data.has_reviewed_orders) {
    return TabId.REVIEWED
  }
  return TabId.ALL
}
