import { Skeleton } from "@rupahealth/design"

const ResultsUploadManualEntryNewBiomarkerLoadingState = () => {
  return (
    <div className="w-full flex items-center rounded-md border border-slate-200">
      <div className="w-full border-r border-slate-200 py-[14px] px-4 flex flex-col gap-5">
        <div className="flex gap-[14px] items-start sm:items-center flex-col sm:flex-row">
          <Skeleton className="w-full sm:w-[300px] h-10 rounded-md" />
          <Skeleton className="w-full h-10 rounded-md" />
          <Skeleton className="w-full h-10 rounded-md" />
        </div>
        <div className="flex flex-col sm:flex-row gap-[14px] items-center">
          <Skeleton className="w-full h-10 rounded-md" />
          <Skeleton className="w-full h-10 rounded-md" />
          <Skeleton className="w-full h-10 rounded-md" />
        </div>
      </div>
      <div className="bg-slate-50 px-[15px] self-stretch flex items-center flex-shrink">
        <Skeleton className="w-4 h-5 rounded-md" />
      </div>
    </div>
  )
}

export default ResultsUploadManualEntryNewBiomarkerLoadingState
