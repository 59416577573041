import { ReactComponent as InfoSquareIcon } from "app/assets/icons/info-square.svg"
import { ReactComponent as UpRightArrowIcon } from "app/assets/icons/up-right-arrow.svg"
import {
  ComparisonRow,
  ComparisonColumnProps,
  EmptyComparisonValue,
} from "app/main/comparison/components/ComparisonLabTests/components"
import useAnyComparedLabTestHasProperty from "app/main/comparison/hooks/use-any-compared-lab-test-has-property"
import { primaryColor } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"

const useAboutLabColumnStyles = makeAppStyles((theme) => ({
  aboutUrlLink: {
    color: primaryColor,
    textDecoration: "none",
    fontWeight: theme.typography.fontWeightMedium,
    "& svg": {
      width: 12,
      height: 12,
      fill: "currentColor",
      verticalAlign: "middle",
    },
  },
}))

const AboutLabColumn = ({ labTest }: ComparisonColumnProps) => {
  const classes = useAboutLabColumnStyles()
  return (
    <>
      {labTest?.lab_company.about_url ? (
        <a
          className={classes.aboutUrlLink}
          href={labTest.lab_company.about_url}
          target="__blank"
        >
          {`About ${labTest.lab_company.short_name}`}{" "}
          <UpRightArrowIcon viewBox="0 0 12 12" />
        </a>
      ) : (
        <EmptyComparisonValue />
      )}
    </>
  )
}

export default function ComparisonAboutLabRow() {
  const hasAboutUrl = useAnyComparedLabTestHasProperty("lab_company.about_url")
  if (!hasAboutUrl) {
    return null
  }
  return (
    <ComparisonRow
      Column={AboutLabColumn}
      headerIcon={<InfoSquareIcon viewBox="0 0 12 13" />}
      headerLabel="About Lab"
    />
  )
}
