import { useMount } from "react-use"

import { makeStyles } from "@material-ui/core"
import * as Sentry from "@sentry/react"

import BodyText from "app/components/design-system/BodyText"
import Button from "app/components/design-system/Button"

const useStyles = makeStyles({
  errorWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100%",
  },
  errorMessage: {
    fontSize: 21,
    marginBottom: 12,
  },
})

const PhlebotomistMapError = ({ error, resetErrorBoundary }) => {
  const classes = useStyles()

  useMount(() => {
    // capture the map load error in sentry
    Sentry.captureException(error)
  })

  return (
    <div className={classes.errorWrapper}>
      <BodyText className={classes.errorMessage} weight="semibold">
        Sorry, we appear to have run into an issue rendering the map!
      </BodyText>
      <Button color="primary" onClick={resetErrorBoundary} size="small">
        Try Again
      </Button>
    </div>
  )
}

export default PhlebotomistMapError
