import * as React from "react"

import clsx from "clsx"

import makeAppStyles from "app/utils/makeAppStyles"

const useStyles = makeAppStyles<{
  borderColor: string
  backgroundColor: string
  iconBackgroundColor: string
  withBackground?: boolean
}>((theme) => ({
  container: (props) => {
    if (!props.withBackground) {
      return {
        display: "flex",
        alignItems: "center",
      }
    }

    return {
      borderRadius: "6px",
      border: `1px solid ${props.borderColor}`,
      background: props.backgroundColor,
      padding: "12px 16px",
      display: "flex",
      alignItems: "center",
    }
  },
  iconContainer: (props) => ({
    marginRight: 14,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: props.iconBackgroundColor,
    width: 30,
    minWidth: 30,
    height: 30,
    borderRadius: props.withBackground ? "50%" : 6,
  }),
}))

export interface PatientPortalBannerProps {
  children: React.ReactNode
  icon: React.ReactNode
  borderColor: string
  backgroundColor: string
  iconBackgroundColor: string
  withBackground?: boolean
  containerClass?: string | null
}

export default function PatientPortalBanner({
  children,
  icon,
  borderColor,
  backgroundColor,
  iconBackgroundColor,
  withBackground = true,
  containerClass = null,
}: PatientPortalBannerProps) {
  const classes = useStyles({
    borderColor,
    backgroundColor,
    iconBackgroundColor,
    withBackground,
  })

  return (
    <div className={clsx(classes.container, containerClass)}>
      {icon && <div className={classes.iconContainer}>{icon}</div>}
      {children}
    </div>
  )
}
