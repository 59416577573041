import * as Actions from "../actions"

const profileReducer = function (state = {}, action) {
  switch (action.type) {
    case Actions.GET_USER: {
      return action.payload
    }
    case Actions.PROFILE_PASSWORD_UPDATE_COMPLETE: {
      return { ...state, user: { ...state.user, force_password_change: false } }
    }
    default: {
      return state
    }
  }
}

export default profileReducer
