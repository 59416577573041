import { useState } from "react"

import { useHistory } from "react-router-dom"
import { useMount } from "react-use"

import { makeStyles, useMediaQuery, Grid, Theme } from "@material-ui/core"

import { UserPaths } from "app/Routes"
import StoreIconGrey from "app/assets/icons/ecommerce-store-grey.svg"
import PageToolbar from "app/components/PageToolbar"
import { StorefrontType } from "app/hooks/use-resource/types"
import withReducer from "app/store/withReducer"
import { colors } from "app/theme"

import { API } from "../../api"
import EcommerceOrderList from "./components/EcommerceOrdersList"
import SideNav from "./components/SideNav"
import ecommerceReducer from "./store/reducers/ecommerce.reducer"

const useStyles = makeStyles(() => ({
  wrapper: {
    background: colors.coolGray[50],
    border: `1px solid ${colors.coolGray[200]}`,
    padding: 24,
    minHeight: "100vh",
  },
  container: {
    overflowY: "scroll" as any,
    height: "calc(100vh - 3px)",
  },
}))

function EcommerceOrders() {
  const history = useHistory()
  const styles = useStyles()
  const [storefronts, setStorefronts] = useState<StorefrontType[]>([])
  const [showSidebarOnMobile, setShowSidebarOnMobile] = useState(false)

  useMount(async () => {
    const response = await API.Storefront.retrieveStorefronts()

    const data = response.data

    setStorefronts(data.data)
  })

  const createNewLabShop = async () => {
    const response = await API.Storefront.createStorefront()
    setStorefronts([...storefronts, response.data.data])
  }

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"))

  const handleSelectedStorefront = (storefrontId: string) => {
    setShowSidebarOnMobile(false)
    history.push(`/store?storefrontId=${storefrontId}`)
  }

  return (
    <Grid container>
      <>
        <Grid item xs={12} md={3}>
          {isMobile && (
            <>
              <PageToolbar
                breadcrumbs={[
                  {
                    to: UserPaths.ECOMMERCE_STORE_ORDERS,
                    icon: StoreIconGrey,
                    label: "LabShop",
                    onClick: () => setShowSidebarOnMobile(true),
                  },
                  {
                    label: "Orders",
                  },
                ]}
              />
              {showSidebarOnMobile && (
                <SideNav
                  storefronts={storefronts}
                  createNewLabShop={createNewLabShop}
                  selectedStorefrontId={""}
                  selectStorefront={handleSelectedStorefront}
                  isMobile={isMobile}
                />
              )}
            </>
          )}
          {!isMobile && (
            <>
              <Grid item xs={12} md={12}>
                <PageToolbar title="LabShop" fullBorder={true} />
              </Grid>
              <SideNav
                storefronts={storefronts}
                createNewLabShop={createNewLabShop}
                selectedStorefrontId={""}
                selectStorefront={handleSelectedStorefront}
                isMobile={isMobile}
              />
            </>
          )}
        </Grid>
        <Grid item xs={12} md={9} className={styles.container}>
          <PageToolbar
            title="Store Setup"
            fullBorder={true}
            topContainerStyle={{ paddingTop: "17px", paddingBottom: "16px" }}
          />
          {Boolean(storefronts.length) && (
            <EcommerceOrderList storefronts={storefronts} />
          )}
        </Grid>
      </>
    </Grid>
  )
}

export default withReducer("ecommerce", ecommerceReducer)(EcommerceOrders)
