import { useMemo } from "react"

import { Link as MuiLink } from "@material-ui/core"

import DesignSystemButton from "app/components/design-system/Button"
import useFeatureFlag, { FeatureFlag } from "app/hooks/use-feature-flag"

import InfoBanner from "../design-system/banners/InfoBanner"

interface VibrantLeavingBannerProps {
  searchTexts: string[]
}

const MATCHING_SEARCHES = [
  "vibrant",
  "zoomer",
  "food sensitivity profile",
  "food allergy",
  "tox burden",
]

export default function VibrantSearchWarning({
  searchTexts,
}: VibrantLeavingBannerProps) {
  const [vibrantLeavingBannerFlagEnabled] = useFeatureFlag(
    FeatureFlag.VibrantLeavingBanner
  )
  const [vibrantDisabled, vibrantDisabledLoading] = useFeatureFlag(
    FeatureFlag.VibrantDisabled
  )

  const hasMatchingText = useMemo(() => {
    return searchTexts.some((text) =>
      MATCHING_SEARCHES.includes(text.toLowerCase())
    )
  }, [searchTexts])

  if (vibrantDisabledLoading) {
    return null
  }

  if (!vibrantLeavingBannerFlagEnabled) {
    return null
  }

  if (!hasMatchingText) {
    return null
  }

  const title = "Looking for Vibrant Wellness or America tests?"
  const bodyText = vibrantDisabled ? (
    <>
      Vibrant Wellness & America are no longer available on platforms like Rupa,
      but we’ve created a list of comparable tests.
    </>
  ) : (
    <>
      Vibrant Wellness & America will no longer be available starting Feb 1st,
      but we’ve created a list of comparable tests.
    </>
  )
  const body = (
    <div className="text-sm font-normal max-w-xl">
      {bodyText} You can also
      <MuiLink
        className="cursor-pointer"
        onClick={() => window.Intercom("showNewMessage")}
      >
        {" "}
        talk to a Rupa rep{" "}
      </MuiLink>
      to help you transition!
    </div>
  )

  return (
    <InfoBanner
      title={title}
      body={body}
      className="my-4"
      actions={
        <DesignSystemButton
          color="secondary"
          fullWidth={true}
          className="whitespace-nowrap"
          onClick={() => {
            window.open(
              "https://www.rupahealth.com/lab-test-comparisons",
              "_blank",
              "noopener,noreferrer"
            )
          }}
        >
          See Alternative Tests
        </DesignSystemButton>
      }
    />
  )
}
