import * as React from "react"
import { useCallback } from "react"

import { Link, MenuItem, Select, styled } from "@material-ui/core"

import { ReactComponent as ArrowTopRightIcon } from "app/assets/icons/arrow-top-right.svg"
import PageToolbar from "app/components/PageToolbar"
import BodyText from "app/components/design-system/BodyText"
import {
  PHYSICIAN_AUTHORIZATION_GUIDE_URL,
  VENDOR_PHYSICIAN_AUTHORIZATION_LABEL,
} from "app/constants"
import { US_STATES } from "app/main/patient-checkout/utils/usStates"
import { colors, navy, rupaTeal } from "app/theme"
import { PractitionerListItem } from "app/types"
import makeAppStyles from "app/utils/makeAppStyles"

const useStyles = makeAppStyles((theme) => ({
  container: {
    background: colors.coolGray[50],
    display: "flex",
    flexFlow: "column",
    padding: "0px 18px 0px 16px",
    [theme.breakpoints.down("sm")]: {
      background: colors.coolGray[100],
      padding: 0,
    },
  },
  rowContainer: {
    display: "flex",
    flexFlow: "row",
    alignItems: "center",
    paddingRight: 6,
    [theme.breakpoints.down("sm")]: {
      flexFlow: "column-reverse",
      marginLeft: 4,
      paddingRight: 0,
    },
  },
  infoContainer: {
    margin: "15px 12px 0 12px",
    width: "75%",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "15px",
      width: "90%",
    },
  },
  selectContainer: {
    marginTop: 15,
    maxWidth: 400,
    [theme.breakpoints.down("sm")]: {
      maxWidth: "90%",
    },
    width: "100%",
  },
  titleWrapper: {
    marginBottom: 6,
  },
  physServicesBanner: {
    border: `2px solid ${rupaTeal}`,
    margin: "20px 6px 5px 10px",
    borderRadius: 6,
    display: "flex",
    flexFlow: "row",
    backgroundColor: "white",
    gap: 7,
    [theme.breakpoints.down("sm")]: {
      alignSelf: "center",
      margin: "0px 0px 15px 0px",
      width: "90%",
    },
  },
  bannerPatch: {
    width: 7,
    backgroundColor: rupaTeal,
  },
  bannerText: {
    padding: "7px 0px",
    display: "flex",
    flexFlow: "row",
    gap: 5,
    [theme.breakpoints.down("sm")]: {
      flexFlow: "column",
    },
  },
}))

const StyledSelect = styled(Select)({
  background: "white",
  border: `1px solid ${colors.blueGray[300]}`,
  borderRadius: 6,
  boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);",
  color: navy,
  height: 48,
  paddingLeft: 13,
  width: "100%",
})

interface Props {
  isMobile: boolean
  onSelectInputChange?: React.Dispatch<React.SetStateAction<string | undefined>>
  practitionerListItems: PractitionerListItem[]
  selectedPractitionerListItem?: PractitionerListItem
  selectedPractitionerListItemId?: string
}

function LabOrderingAccessHeader(props: Props) {
  const classes = useStyles({ isMobile: props.isMobile })
  const handleChange = useCallback(
    (event: React.ChangeEvent<{ value: unknown }>) => {
      if (!props.onSelectInputChange) {
        return
      }

      props.onSelectInputChange(event.target.value as string)
    },
    [props.onSelectInputChange]
  )

  return (
    <>
      {props.isMobile ? (
        <PageToolbar title="Lab Ordering Access" />
      ) : (
        <PageToolbar
          title="Lab Ordering Access"
          greyBackground={true}
          fullBorder={true}
        />
      )}
      <div className={classes.container}>
        <div className={classes.rowContainer}>
          <div className={classes.infoContainer}>
            {props.selectedPractitionerListItem?.titled_full_name && (
              <>
                <div className={classes.titleWrapper}>
                  <BodyText weight="semibold" size="lg">
                    {props.selectedPractitionerListItem?.titled_full_name}'s Lab
                    Ordering Access
                  </BodyText>
                </div>
                <BodyText size="base">
                  Lab ordering access is determined by each individual lab
                  company according to your state and license. Practitioners
                  licensed as{" "}
                  {
                    props.selectedPractitionerListItem
                      ?.primary_practitioner_type?.name
                  }{" "}
                  in{" "}
                  {US_STATES[props.selectedPractitionerListItem?.clinic.state]}{" "}
                  are able to order from:
                </BodyText>
              </>
            )}
          </div>
          <div className={classes.selectContainer}>
            {props.practitionerListItems.length >= 1 && (
              <StyledSelect
                labelId="select-practitioner"
                id="select-practitioner"
                value={props.selectedPractitionerListItemId || ""}
                label="Select Practitioner"
                onChange={handleChange}
                disableUnderline={true}
              >
                {props.practitionerListItems?.map((practitionerListItem) => (
                  <MenuItem
                    value={practitionerListItem.id}
                    key={practitionerListItem.id}
                  >
                    {practitionerListItem.titled_full_name}
                  </MenuItem>
                ))}
              </StyledSelect>
            )}
          </div>
        </div>
        <div className={classes.physServicesBanner}>
          <div className={classes.bannerPatch} />
          <div className={classes.bannerText}>
            <BodyText>
              With {VENDOR_PHYSICIAN_AUTHORIZATION_LABEL}, you can give your
              clients access to 3000+ labs.
            </BodyText>
            <Link href={PHYSICIAN_AUTHORIZATION_GUIDE_URL} target="_blank">
              Learn More <ArrowTopRightIcon viewBox="0 0 12 12" />
            </Link>
          </div>
        </div>
      </div>
    </>
  )
}

export default LabOrderingAccessHeader
