import { ReactComponent as SampleReportIcon } from "app/assets/icons/sample-report.svg"
import { ReactComponent as UpRightArrowIcon } from "app/assets/icons/up-right-arrow.svg"
import {
  ComparisonRow,
  ComparisonColumnProps,
  EmptyComparisonValue,
} from "app/main/comparison/components/ComparisonLabTests/components"
import useAnyComparedLabTestHasProperty from "app/main/comparison/hooks/use-any-compared-lab-test-has-property"
import { primaryColor } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"

const useSampleReportColumnStyles = makeAppStyles((theme) => ({
  sampleReportLink: {
    color: primaryColor,
    textDecoration: "none",
    fontWeight: theme.typography.fontWeightMedium,
    "& svg": {
      width: 12,
      height: 12,
      fill: "currentColor",
      verticalAlign: "middle",
    },
  },
}))

const SampleReportColumn = ({ labTest }: ComparisonColumnProps) => {
  const classes = useSampleReportColumnStyles()
  return (
    <>
      {labTest?.sample_report_url ? (
        <a
          className={classes.sampleReportLink}
          href={labTest.sample_report_url}
          target="__blank"
        >
          View Report <UpRightArrowIcon viewBox="0 0 12 12" />
        </a>
      ) : (
        <EmptyComparisonValue />
      )}
    </>
  )
}

export default function ComparisonSampleReportColumn() {
  const hasSampleReport = useAnyComparedLabTestHasProperty("sample_report_url")
  if (!hasSampleReport) {
    return null
  }
  return (
    <ComparisonRow
      Column={SampleReportColumn}
      headerIcon={<SampleReportIcon viewBox="0 0 13 16" />}
      headerLabel="Sample Report"
    />
  )
}
