import { PropsWithChildren, useRef } from "react"

import * as DropdownMenu from "@radix-ui/react-dropdown-menu"

import { primaryColor, shadows } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"

const useStyles = makeAppStyles((theme) => ({
  addTaskContent: {
    maxHeight: 300,
    width: 300,
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: theme.spacing(1.0),
    padding: theme.spacing(1.25),
    scrollPaddingBottom: `${theme.spacing(4.5)}px`,
    scrollPaddingTop: `${theme.spacing(4.5)}px`,
    zIndex: theme.zIndex.modal,
    overflow: "auto",
    backgroundColor: "white",
    border: `0.82px solid ${primaryColor}`,
    borderRadius: 6,
    boxShadow: shadows.xl,
  },
}))

export default function AddTaskPopoverContent({
  children,
}: PropsWithChildren<{}>) {
  const classes = useStyles()
  const contentRef = useRef<HTMLDivElement>(null)
  return (
    <DropdownMenu.Portal>
      <DropdownMenu.Content
        align="start"
        className={classes.addTaskContent}
        onCloseAutoFocus={(e) => e.preventDefault()}
        // The following two handlers ensure the click outside handler does not cause
        // this popover to close when rendered within a portal or modal.
        onPointerDownCapture={(e) => {
          const target = e.target as HTMLElement
          const targetIsContent = contentRef.current?.contains(target)

          if (targetIsContent) {
            e.preventDefault()
          }
        }}
        onPointerDownOutside={(e) => {
          const target = e.target as HTMLElement
          const targetIsContent = contentRef.current?.contains(target)

          if (targetIsContent) {
            e.preventDefault()
          }
        }}
        sideOffset={5}
        ref={contentRef}
      >
        {children}
      </DropdownMenu.Content>
    </DropdownMenu.Portal>
  )
}
