import { PATIENT_EMPIRE_BLOOD_DRAW_INFO } from "app/constants"
import { showMessage } from "app/store/actions/fuse"
import {
  OptionType,
  PHLEBOTOMY_PROVIDERS,
  PhlebotomyProviderName,
  SELECTABLE_PHLEBOTOMY_PROVIDERS,
} from "app/types"
import uniq from "lodash/uniq"

/**
 * True if the provider is selectable by the user.
 */
export function isSelectable(providerName: PhlebotomyProviderName) {
  return SELECTABLE_PHLEBOTOMY_PROVIDERS.includes(providerName)
}

/**
 * Update the list of providers to replace any existing selectable providers
 * with the new provider.
 */
export function updateProviderList(
  newProviderToSetName: PhlebotomyProviderName,
  currentProviderNames: PhlebotomyProviderName[]
) {
  return resetProviderList(currentProviderNames).concat(newProviderToSetName)
}

/**
 * Reset the list of providers to remove any selectable providers.
 */
function resetProviderList(currentProviderNames: PhlebotomyProviderName[]) {
  return uniq(currentProviderNames.filter((p) => !isSelectable(p)))
}

/**
 * Returns the available provider options based on the available providers.
 */
export function getProviderOptions(
  availableProviders: PhlebotomyProviderName[]
) {
  let providerOptions: OptionType<PhlebotomyProviderName>[] = []
  let axleLabelAppend = " ($85 draw + $50 per additional kit)"
  let patientChosenLabelAppend = ""

  if (availableProviders.includes(PHLEBOTOMY_PROVIDERS.EMPIRE)) {
    axleLabelAppend = " ($25 shipping + $85 draw + $50 per additional kit)"
    patientChosenLabelAppend = " ($25 shipping + cost of draw)"
    providerOptions.push({
      label: "Visit a local blood draw center (no additional charge)",
      value: PHLEBOTOMY_PROVIDERS.EMPIRE,
      description: PATIENT_EMPIRE_BLOOD_DRAW_INFO,
    })
  }
  if (availableProviders.includes(PHLEBOTOMY_PROVIDERS.AXLE)) {
    providerOptions.push({
      label: `Book an at-home blood draw${axleLabelAppend}`,
      value: PHLEBOTOMY_PROVIDERS.AXLE,
      description:
        "A mobile phlebotomist will draw your blood at your home or office. We'll help you schedule your appointment and ship you a test kit.",
    })
  }
  if (availableProviders.includes(PHLEBOTOMY_PROVIDERS.PATIENT_CHOSEN)) {
    providerOptions.push({
      label: `Book your blood draw separately${patientChosenLabelAppend}`,
      value: PHLEBOTOMY_PROVIDERS.PATIENT_CHOSEN,
      description:
        "We'll show you other local phlebotomy options and ship you a test kit. You'll schedule your appointment and pay the phlebotomist directly. Blood draws typically cost $30-$100.",
    })
  }

  return providerOptions
}

export function bloodDrawErrorMessage() {
  return showMessage({
    message: "Something went wrong. Please try again or contact support",
    variant: "error",
    anchorOrigin: { vertical: "top", horizontal: "right" },
  })
}

export function bloodDrawWarningMessage(message: string) {
  return showMessage({
    message: message,
    variant: "warning",
    anchorOrigin: { vertical: "top", horizontal: "right" },
  })
}

const _bloodDrawSuccessCopy = (phlebProvider: PhlebotomyProviderName) => {
  switch (phlebProvider) {
    case PHLEBOTOMY_PROVIDERS.AXLE:
      return "Mobile blood draw added to order!"
    case PHLEBOTOMY_PROVIDERS.EMPIRE:
      return "Empire blood draw added to order!"
    default:
      return "Selection confirmed!"
  }
}

export function bloodDrawSuccessMessage(phlebProvider: PhlebotomyProviderName) {
  return showMessage({
    message: _bloodDrawSuccessCopy(phlebProvider),
    variant: "success",
    anchorOrigin: { vertical: "top", horizontal: "right" },
  })
}
