import { ORDER_STATUS } from "app/constants"
import * as EcommerceActions from "app/main/ecommerce/store/actions"
import PatientOrdersOrderedTestName from "app/main/patient-orders/PatientOrdersOrderedTest/PatientOrdersOrderedTestName"
import PatientOrdersOrderedTestOrderActions from "app/main/patient-orders/PatientOrdersOrderedTest/PatientOrdersOrderedTestOrderActions"
import PatientOrdersOrderedTestPanelTooltip from "app/main/patient-orders/PatientOrdersOrderedTest/PatientOrdersOrderedTestPanelTooltip"
import PatientOrdersOrderedTestProgress from "app/main/patient-orders/PatientOrdersOrderedTest/PatientOrdersOrderedTestProgress"
import PatientOrdersOrderedTestSampleType from "app/main/patient-orders/PatientOrdersOrderedTest/PatientOrdersOrderedTestSampleType"
import { colors, dashboardHighlight } from "app/theme"
import {
  OrderedTest,
  PanelLabTest,
  Patient,
  PatientOrdersOrder,
} from "app/types"
import makeAppStyles from "app/utils/makeAppStyles"

const useStyles = makeAppStyles<{
  dashboardColumns: number | undefined
}>((theme) => ({
  wrapper: {
    backgroundColor: dashboardHighlight,
  },
  firstColumnSpacer: {
    width: 28,
  },
  gridContainer: {
    padding: theme.spacing(1.0, 0),
    borderTopWidth: "1px",
    borderColor: colors.blueGray[200],
    borderOpacity: "1",
    flexDirection: "row",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  testNameGridItem: {
    paddingLeft: 4,
    width: (props) => `calc(100% / ${props.dashboardColumns})`,
    minWidth: 320,
    padding: theme.spacing(2.0, 2.0),
  },
  testNameContainer: {
    maxWidth: 300,
    minWidth: 200,
  },
  progressGridItem: {
    width: (props) => `calc(100% / ${props.dashboardColumns})`,
    flexGrow: 1,
    marginRight: 64,
    padding: theme.spacing(0, 2.0),
    minWidth: 260,
  },
  actionsGridItem: {
    width: 170,
    marginLeft: "auto",
    marginRight: 32,
  },
}))

interface Props {
  order: PatientOrdersOrder
  orderedTest: OrderedTest
  patient: Patient
  displayName: string
  panelLabTests?: PanelLabTest[]
  dashboardColumns?: number
  appliedFilters?: any
  isStorefrontDashboard?: boolean
}

function PatientOrdersOrderedTestDashboard({
  order,
  orderedTest,
  patient,
  displayName,
  panelLabTests,
  dashboardColumns,
  appliedFilters,
}: Props) {
  const styles = useStyles({ dashboardColumns })

  return (
    <div className={styles.wrapper}>
      <div className={styles.gridContainer}>
        <div className={styles.firstColumnSpacer} />
        <div className={styles.testNameGridItem}>
          <div className={styles.testNameContainer}>
            <PatientOrdersOrderedTestName
              orderedTest={orderedTest}
              displayName={displayName}
              size={"base"}
            />
            <PatientOrdersOrderedTestSampleType orderedTest={orderedTest} />
            {panelLabTests && (
              <PatientOrdersOrderedTestPanelTooltip
                panelLabTests={panelLabTests}
              />
            )}
          </div>
        </div>
        <div className={styles.progressGridItem}>
          <PatientOrdersOrderedTestProgress
            orderedTest={orderedTest}
            order={order}
            patient={patient}
          />
        </div>
        {order.status !== ORDER_STATUS.DRAFT && (
          <div className={styles.actionsGridItem}>
            <PatientOrdersOrderedTestOrderActions
              orderedTest={orderedTest}
              order={order}
              patient={patient}
              markOrderedTestResultReviewed={
                EcommerceActions.markOrderedTestResultReviewed
              }
              acknowledgeUnreadResult={EcommerceActions.acknowledgeUnreadResult}
              appliedFilters={appliedFilters}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default PatientOrdersOrderedTestDashboard
