import { useMemo } from "react"
import { useDispatch } from "react-redux"

import axios from "axios"
import useSWR from "swr"

import { getApiBaseUrl, handleApiError, handleApiSuccess } from "app/utils"
import { PractitionerSnippet } from "types/practitioner-snippet"

interface PractitionerSnippetResponse {
  count: number
  next: string | null
  previous: string | null
  results: PractitionerSnippet[]
}

const SNIPPET_TYPE_MAPPING = {
  results_summary: "Results Summary",
  notes_to_patient: "Notes to patient",
}

const useSnippets = ({ snippetType, page }) => {
  const dispatch = useDispatch()

  const key = ["snippets/", snippetType, page]

  const paginationParams = useMemo(() => {
    if (page > 1) {
      return { page }
    } else {
      return {}
    }
  }, [page])

  const { data, error, isLoading, isValidating, mutate } =
    useSWR<PractitionerSnippetResponse>(key, {
      async fetcher(key) {
        const url = key[0]

        const response = await axios.request<PractitionerSnippetResponse>({
          baseURL: getApiBaseUrl() + "/api/",
          url,
          method: "get",
          params: {
            snippet_type: snippetType,
            ...paginationParams,
          },
        })

        return response.data
      },
      revalidateIfStale: true,
      revalidateOnMount: true,
      revalidateOnFocus: true,
      revalidateOnReconnect: true,
    })

  const createSnippet = async (snippetData: Partial<PractitionerSnippet>) => {
    try {
      const response = await axios.post(getApiBaseUrl() + "/api/snippets/", {
        ...snippetData,
        snippet_type: SNIPPET_TYPE_MAPPING[snippetType],
        shared_with_clinic: snippetType === "results_summary",
      })

      mutate()

      dispatch(handleApiSuccess("Successfully created Snippet"))

      return response.data
    } catch (error) {
      dispatch(handleApiError(error))
    }
  }

  const updateSnippet = async (
    snippetId: number,
    snippetData: Partial<PractitionerSnippet>
  ) => {
    try {
      const response = await axios.patch(
        getApiBaseUrl() + `/api/snippets/${snippetId}/`,
        snippetData
      )

      mutate()

      dispatch(handleApiSuccess("Successfully updated Snippet"))

      return response.data
    } catch (error) {
      dispatch(handleApiError(error))
    }
  }

  const deleteSnippet = async (snippetId: number) => {
    try {
      await axios.delete(getApiBaseUrl() + `/api/snippets/${snippetId}/`)
      mutate()
      dispatch(handleApiSuccess("Successfully deleted Snippet"))
    } catch (error) {
      dispatch(handleApiError(error))
    }
  }

  return {
    data: data?.results,
    paginationInfo: {
      count: data?.count,
      next: data?.next,
      previous: data?.previous,
    },
    error,
    isLoading,
    isValidating,
    createSnippet,
    updateSnippet,
    deleteSnippet,
  }
}

export default useSnippets
