import { ComponentProps, ElementType, useRef } from "react"
import * as React from "react"

import { useUpdateEffect } from "react-use"

interface ScrollToAnchorProps<
  C extends React.ElementType,
  P extends ComponentProps<C>
> {
  /**
   * The scroll behavior to use.
   */
  behavior?: ScrollBehavior
  /**
   * The block alignment to use.
   */
  block?: ScrollLogicalPosition
  /**
   * The children to render.
   * This should be the element that should be scrolled to.
   */
  children: React.ReactNode
  /**
   * The component to render. Defaults to a "div".
   */
  component?: C
  /**
   * The props to pass to the component.
   */
  componentProps?: P
  /**
   * The inline alignment to use.
   */
  inline?: ScrollLogicalPosition
  /**
   * The top offset to use on the anchor. Defaults to 0.
   */
  top?: string | number
  /**
   * The prop to watch for changes.
   */
  when: any
}

/**
 * A component that scrolls to an anchor element when a prop changes.
 * Intended to be used as a wrapper around an element that should be scrolled to when a prop changes.
 *
 * @example
 * ```
 * <ScrollToAnchor
 *   behavior="smooth"
 *   block="start"
 *   component={TableContainer}
 *   componentProps={{
 *     className: classes.container,
 *     component: Paper,
 *   }}
 *   top={-100}
 *   when={pagination.pageIndex}
 * >
 *   <Table/>
 * </ScrollToAnchor>
 * ```
 */
export default function ScrollToAnchor<
  C extends ElementType,
  P extends ComponentProps<C>
>({
  behavior,
  block,
  children,
  component,
  componentProps,
  inline,
  top = 0,
  when,
}: ScrollToAnchorProps<C, P>) {
  const scrollAnchorRef = useRef<any>(null)

  useUpdateEffect(() => {
    scrollAnchorRef.current?.scrollIntoView({
      behavior,
      block,
      inline,
    })
  }, [when])

  const Component = component ?? "div"

  return (
    <Component
      {...componentProps}
      style={{ position: "relative", ...componentProps?.style }}
    >
      <div
        ref={scrollAnchorRef}
        style={{ position: "absolute", height: 0, top }}
      />
      {children}
    </Component>
  )
}
