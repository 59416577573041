import React, { isValidElement, useEffect, useMemo, useState } from "react"

import clsx from "clsx"
import { NavLink, useLocation } from "react-router-dom"

import { makeStyles, Theme } from "@material-ui/core"

import { UserPaths } from "app/Routes"
import RupaHealthLogoDark from "app/assets/images/logos/rupa-health-dark.svg"
import {
  navy,
  shadows,
  userNavBarCollapsedWidth,
  userNavBarMobileHeight,
  userNavBarWidth,
} from "app/theme"

import HamburgerMenuIcon from "../HamburgerMenuIcon"
import NavBarContext from "./NavBarContext"

interface Props {
  collapsed?: boolean
  children?: React.ReactNode
  floating?: boolean
  footer?: React.ReactNode
}

const useStyles = makeStyles<
  Theme,
  {
    collapsed?: boolean
    logo: string
    mobileMenuToggled: boolean
    floating?: boolean
  }
>((theme) => ({
  // We have a separate container to the content so that we can
  // expand the collapsed sidebar without reflowing the page content.
  container: {
    // We add 1 to ensure this goes over any other drawers that might be used.
    zIndex: theme.zIndex.drawer + 1,
    position: "relative",
    height: userNavBarMobileHeight,
    flexShrink: 0,

    [theme.breakpoints.up("sm")]: {
      position: "sticky",
      width: (props) =>
        props.floating ? userNavBarWidth : userNavBarCollapsedWidth,
      borderRadius: 16,

      top: (props) => (props.floating ? 24 : 0),
      height: (props) => (props.floating ? "calc(100vh - 48px)" : "100vh"),
      overflow: (props) => (props.floating ? "hidden" : "visible"),
    },

    [theme.breakpoints.up("lg")]: {
      width: (props) =>
        props.collapsed && !props.floating
          ? userNavBarCollapsedWidth
          : userNavBarWidth,
    },

    [theme.breakpoints.up("xl")]: {
      width: () => userNavBarWidth,
    },
  },
  contentContainer: {
    background: navy,
    backdropFilter: (props) => (props.floating ? "blur(7.05px)" : "none"),
    display: "flex",
    flexDirection: "column",
    transition: "width 0.24s, height 0.24s, box-shadow 0.24s",
    width: "100%",
    padding: 15,
    overflow: "hidden",
    overflowY: "auto",
    "-ms-overflow-style": "none",
    scrollbarWidth: "none",

    // Hide scrollbar on small windows
    "&::-webkit-scrollbar": {
      display: "none",
    },

    [theme.breakpoints.down("xs")]: {
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      height: (props) =>
        props.mobileMenuToggled ? "100vh" : userNavBarMobileHeight,

      // On mobile Safari, sets 100vh to be exclusive of the toolbar.
      "@supports (-webkit-touch-callout: none)": {
        height: (props) =>
          props.mobileMenuToggled
            ? "-webkit-fill-available"
            : userNavBarMobileHeight,
      },

      "& .navbar-item": {
        opacity: (props) => (props.mobileMenuToggled ? 1 : 0),
        transform: (props) =>
          props.mobileMenuToggled ? "translate(0, 4px)" : "none",
        transition:
          "background 0.24s, opacity 0.40s ease-in-out calc(var(--animation-order) * 30ms), transform 0.40s ease-in-out calc(var(--animation-order) * 30ms)",
      },

      "& .navbar__account-menu": {
        // Overrides the hardcoded width we have for the expanded navbar on desktop
        width: (props) => (props.mobileMenuToggled ? "calc(100vw - 30px)" : 0),
        pointerEvents: (props) => (props.mobileMenuToggled ? "auto" : "none"),
      },
    },

    [theme.breakpoints.up("sm")]: {
      padding: "24px 15px 13px 15px",
      width: (props) =>
        props.floating ? userNavBarWidth : userNavBarCollapsedWidth,
      height: "100%",
      background: (props) => (props.floating ? "rgba(12, 45, 78, 0.85)" : navy),

      "& .navbar__mobile-menu-button": {
        display: "none",
      },

      "& .navbar-item__label": {
        opacity: (props) => (props.floating ? 1 : 0),
      },

      "& .navbar-item__unread": {
        opacity: (props) => (props.floating ? 1 : 0),
      },

      "& .account-menu__expanded-content": {
        opacity: (props) => (props.floating ? 1 : 0),
      },

      "& .account-menu__menu": {
        opacity: (props) => (props.floating ? 1 : 0),
      },

      "& .account-menu__button--active": {
        background: "none",
        border: "1px solid rgba(255, 255, 255, 0)",
      },

      "&:hover": {
        width: userNavBarWidth,
        boxShadow: shadows["3xl"],

        "& .navbar-item__label": {
          opacity: 1,
        },

        "& .navbar-item__unread": {
          opacity: 1,
        },

        "& .navbar__item-container-title": {
          opacity: 0.9,
        },

        "& .account-menu__expanded-content": {
          opacity: 1,
        },

        "& .account-menu__menu": {
          opacity: 1,
        },

        "& .account-menu__button--active": {
          background: "rgba(255, 255, 255, 0.1)",
          border: "1px solid rgba(255, 255, 255, 0.03)",
        },
      },
    },

    [theme.breakpoints.up("lg")]: {
      width: (props) =>
        props.collapsed ? userNavBarCollapsedWidth : userNavBarWidth,

      "&:hover": {
        boxShadow: (props) => (props.collapsed ? shadows["3xl"] : "none"),
      },

      "& .navbar-item__label": {
        opacity: (props) => (props.collapsed ? 0 : 1),
      },

      "& .navbar-item__unread": {
        opacity: (props) => (props.collapsed ? 0 : 1),
      },

      "& .account-menu__expanded-content": {
        opacity: (props) => (props.collapsed ? 0 : 1),
      },

      "& .account-menu__menu": {
        opacity: (props) => (props.collapsed ? 0 : 1),
      },

      "& .account-menu__button--active": {
        background: (props) =>
          props.collapsed ? "none" : "rgba(255, 255, 255, 0.1)",
      },
    },

    // At XL, we always expand, even if collapsed was requested
    [theme.breakpoints.up("xl")]: {
      width: () => userNavBarWidth,

      "&:hover": {
        boxShadow: () => "none",
      },

      "& .navbar-item__label": {
        opacity: () => 1,
      },

      "& .navbar-item__unread": {
        opacity: () => 1,
      },

      "& .navbar__item-container-title": {
        opacity: () => 0.9,
      },

      "& .account-menu__expanded-content": {
        opacity: () => 1,
      },

      "& .account-menu__menu": {
        opacity: () => 1,
      },

      "& .account-menu__button--active": {
        background: () => "rgba(255, 255, 255, 0.1)",
      },
    },
  },
  header: {
    display: "flex",
    flexFlow: "row",
    justifyContent: "space-between",
    minHeight: "32px",

    [theme.breakpoints.up("sm")]: {
      justifyContent: "flex-start",
      overflow: "hidden",
    },
  },
  logoLink: {
    display: "block",
    height: 32,

    width: 155,
    [theme.breakpoints.up("sm")]: {
      width: "100%",
    },
  },
  logo: {
    backgroundImage: (props) => `url(${props.logo})`,
    backgroundSize: "155px 100%",
    backgroundRepeat: "no-repeat",
    height: "100%",
    width: "100%",
    marginLeft: (props) => (props.floating ? 0 : 8),

    [theme.breakpoints.up("sm")]: {
      backgroundSize: "214px 100%",
    },
  },
  mobileMenuButton: {
    width: 24,
  },
  headerMobileDivider: {
    background: "#5C7186",
    position: "absolute",
    top: userNavBarMobileHeight,
    left: 0,
    right: 0,
    height: 1,
    transition: "opacity 0.24s",

    opacity: (props) => (props.mobileMenuToggled ? 1 : 0),
  },
  itemsContainer: {
    flex: 1,
    display: "flex",
    flexFlow: "column",
    gap: 36,
    marginTop: 24,
  },
}))

function NavBar({ collapsed, children, floating, footer }: Props) {
  const [mobileMenuToggled, setMobileMenuToggled] = useState(false)
  const location = useLocation()

  const {
    container,
    contentContainer,
    logoLink,
    logo,
    header,
    mobileMenuButton,
    headerMobileDivider,
    itemsContainer,
  } = useStyles({
    collapsed,
    logo: RupaHealthLogoDark,
    mobileMenuToggled,
    floating,
  })
  // Close the mobile menu when changing location
  useEffect(() => {
    if (mobileMenuToggled) {
      setMobileMenuToggled(false)
    }
  }, [location.pathname])

  const navBarContext = useMemo(
    () => ({ collapsed: collapsed || false }),
    [collapsed]
  )

  const countedChildren = useMemo(() => {
    let animationOrderCounter = 0

    return React.Children.map(children, (child) => {
      if (!isValidElement(child)) {
        return child
      }

      if (!("children" in child.props)) {
        return child
      }

      const group = React.cloneElement(child, {
        ...child.props,
        animationOrder: animationOrderCounter,
      })

      animationOrderCounter += 1

      const numberOfItemsInGroup = React.Children.count(child.props.children)
      animationOrderCounter += numberOfItemsInGroup - 1

      return group
    })
  }, [children])

  return (
    <NavBarContext.Provider value={navBarContext}>
      <nav className={container}>
        <div className={contentContainer}>
          <div className={header}>
            <button
              aria-label="Open menu"
              onClick={() => setMobileMenuToggled(!mobileMenuToggled)}
              className={clsx(mobileMenuButton, "navbar__mobile-menu-button")}
            >
              <HamburgerMenuIcon open={mobileMenuToggled} />
            </button>
            <NavLink
              to={UserPaths.ROOT}
              className={logoLink}
              aria-label="Dashboard"
            >
              <div className={logo} />
            </NavLink>
            <div
              className={clsx(mobileMenuButton, "navbar__mobile-menu-button")}
            />
          </div>
          <div className={headerMobileDivider} />
          <div className={itemsContainer}>{countedChildren}</div>
          {footer}
        </div>
      </nav>
    </NavBarContext.Provider>
  )
}

export default NavBar
