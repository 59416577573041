import { PropsWithChildren } from "react"

import clsx from "clsx"

import { colors, textPrimaryColor } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"

const useStyles = makeAppStyles((theme) => ({
  content: {
    display: "flex",
    flexFlow: "column nowrap",
    padding: theme.spacing(2.0),
    gap: theme.spacing(2.0),
    color: textPrimaryColor,
    backgroundColor: colors.blueGray[100],
    borderRadius: 6,
  },
}))

export type ContentProps = PropsWithChildren<{
  className?: string
}>

export default function Content({ children, className }: ContentProps) {
  const classes = useStyles()
  return <div className={clsx(className, classes.content)}>{children}</div>
}
