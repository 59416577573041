import { useCallback } from "react"

import { faAngleRight } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { CircularProgress } from "@material-ui/core"
import { cn } from "@rupahealth/design"

import { SpecimenIssueActionUnion } from "app/constants.typed"

import { useSpecimenIssueActionComponents } from "../helpers"

interface Props {
  action: SpecimenIssueActionUnion
  loading: boolean
  onSelect: (action: SpecimenIssueActionUnion) => void
}

const SpecimenIssueActionItem: React.FC<Props> = ({
  action,
  loading,
  onSelect,
}) => {
  const onClick = useCallback(() => onSelect(action), [action])
  const { title, subtitle, Icon } = useSpecimenIssueActionComponents(action)

  return (
    <div className="relative inline-block w-full h-full">
      {loading && (
        <CircularProgress
          size={36}
          className="absolute top-1/2 left-1/2 -ml-3 -mt-3"
        />
      )}
      <div
        className={cn(
          "flex flex-col items-center text-center h-full justify-center",
          "shadow-sm p-[22px] rounded-lg",
          "bg-white border border-slate-200 cursor-pointer",
          "group hover:border-primary hover:bg-blue-50 hover:text-primary duration-200"
        )}
        onClick={onClick}
      >
        <span className="w-[37px] h-[38px] flex-grow-0 flex-shrink-0 rounded-md bg-slate-100 group-hover:bg-white duration-200">
          <Icon className="block m-auto h-full fill-slate-500 group-hover:fill-primary duration-200" />
        </span>

        <span className="font-semibold mt-2">
          {title}
          {"  "}
          <FontAwesomeIcon icon={faAngleRight} />
        </span>
        {subtitle && <p className="text-sm">{subtitle}</p>}
      </div>
    </div>
  )
}

export default SpecimenIssueActionItem
