import { ReactElement, useEffect, useState } from "react"

import clsx from "clsx"

import { Button, ButtonGroup } from "@material-ui/core"

import { colors, navy, primaryColor } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"

const useStyles = makeAppStyles((theme) => ({
  tabsContainer: {
    padding: "12px 18px",
    borderBottom: `1px solid ${colors.blueGray[200]}`,
    overflow: "scroll",
    display: "flex",
  },
  buttonGroup: {
    boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.07)",
    border: `1px solid ${colors.blueGray[200]}`,
    borderRadius: 6,
    marginRight: 12,
  },
  buttonGroupButton: {
    whiteSpace: "nowrap",
    borderRadius: 0,
    borderColor: colors.blueGray[200],
    color: navy,
    padding: "7.5px 26px",
    fontSize: 15,
    "&:hover": {
      background: "rgba(0, 117, 205, 0.05)",
      boxShadow: "none",
    },
  },
  buttonSeparator: {
    background: colors.blueGray[200],
    width: 1,
    minWidth: 1,
  },
  activeButtonGroupButton: {
    background: "rgba(0, 117, 205, 0.05)",
    color: primaryColor,
  },
  resultsNotificationCount: {
    background: colors.emerald[100],
    color: colors.emerald[600],
    borderRadius: 33,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "0px 12px",
    marginLeft: 8,
    fontWeight: 600,
  },
}))

export enum TabOptions {
  DRAFT = "draft",
  IN_PROGRESS = "in_progress",
  RESULTS_IN = "results_in",
  REVIEWED = "reviewed",
  ALL = "all",
}

interface Props {
  onTabChange: (tab: TabOptions) => void
  appliedTabFilter?: TabOptions
  unreadNotificationCount: number
  useStorefrontTabs: boolean
}

const TabButton = ({
  isActive,
  text,
  handleTabSelection,
}: {
  isActive: boolean
  text: string | ReactElement
  handleTabSelection: () => void
}) => {
  const classes = useStyles()
  return (
    <Button
      className={clsx(
        classes.buttonGroupButton,
        isActive ? classes.activeButtonGroupButton : ""
      )}
      onClick={handleTabSelection}
      disableRipple
    >
      {text}
    </Button>
  )
}

const OrderListTabs = ({
  onTabChange,
  appliedTabFilter,
  unreadNotificationCount,
  useStorefrontTabs,
}: Props) => {
  const classes = useStyles()

  const [activeTab, setActiveTab] = useState<TabOptions | null>(
    TabOptions.DRAFT
  )

  const handleTabSelection = (tab: TabOptions) => {
    setActiveTab(tab)
    onTabChange(tab)
  }

  useEffect(() => {
    if (!appliedTabFilter) {
      setActiveTab(null)
    } else {
      setActiveTab(appliedTabFilter)
    }
  }, [appliedTabFilter])

  return (
    <div className={classes.tabsContainer}>
      <ButtonGroup variant="outlined" className={classes.buttonGroup}>
        {!useStorefrontTabs && [
          <TabButton
            key="drafts"
            isActive={activeTab === TabOptions.DRAFT}
            text="Drafts"
            handleTabSelection={() => handleTabSelection(TabOptions.DRAFT)}
          />,
          <div
            key="drafts_separator"
            className={classes.buttonSeparator}
          ></div>,
        ]}

        <TabButton
          isActive={activeTab === TabOptions.IN_PROGRESS}
          text="In Progress"
          handleTabSelection={() => handleTabSelection(TabOptions.IN_PROGRESS)}
        />
        <div className={classes.buttonSeparator}></div>
        <TabButton
          isActive={activeTab === TabOptions.RESULTS_IN}
          text={
            <span style={{ display: "flex" }}>
              Results In{" "}
              {unreadNotificationCount ? (
                <span
                  className={classes.resultsNotificationCount}
                  data-cy="unread-notification-count"
                >
                  {unreadNotificationCount}
                </span>
              ) : null}
            </span>
          }
          handleTabSelection={() => handleTabSelection(TabOptions.RESULTS_IN)}
        />
        <div className={classes.buttonSeparator}></div>
        <TabButton
          isActive={activeTab === TabOptions.REVIEWED}
          text="Reviewed"
          handleTabSelection={() => handleTabSelection(TabOptions.REVIEWED)}
        />
      </ButtonGroup>
      <ButtonGroup variant="outlined" className={classes.buttonGroup}>
        <TabButton
          isActive={activeTab === TabOptions.ALL}
          text="All"
          handleTabSelection={() => handleTabSelection(TabOptions.ALL)}
        />
      </ButtonGroup>
    </div>
  )
}

export default OrderListTabs
