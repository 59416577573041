import { Divider } from "@material-ui/core"

import makeAppStyles from "app/utils/makeAppStyles"

import ResultsSummaryLogoSettings from "../components/ResultsSummaryLogoSettings"
import PlaceHolderCustomDescriptionsTable from "./PlaceHolderCustomDescriptionsTable"
import RupaBloodDashboardsOptimalRanges from "./RupaBloodDashboardsOptimalRanges"

const useStyles = makeAppStyles(() => ({
  greyedOut: {
    opacity: 0.38,
    pointerEvents: "none",
  },
  divider: {
    marginTop: 24,
    marginBottom: 24,
  },
}))

const RupaBloodDashboardsGreyedOutComponents = () => {
  const classes = useStyles()

  return (
    <>
      <div className={classes.divider}>
        <Divider />
      </div>

      <div className={classes.greyedOut}>
        <ResultsSummaryLogoSettings />

        <div className={classes.divider}>
          <Divider />
        </div>

        <PlaceHolderCustomDescriptionsTable />

        <div className={classes.divider}>
          <Divider />
        </div>

        <RupaBloodDashboardsOptimalRanges toggled={false} onToggle={() => {}} />
      </div>
    </>
  )
}

export default RupaBloodDashboardsGreyedOutComponents
