import { useEffect } from "react"

import clsx from "clsx"

import { CircularProgress, makeStyles } from "@material-ui/core"

import useAppSelector from "app/hooks/useAppSelector"
import useSplitItFlexFields from "app/main/patient-checkout/hooks/use-splitit-flex-fields"
import {
  selectIsSplitItInitialized,
  selectSplitItPublicToken,
} from "app/main/patient-checkout/store/selectors"

const useStyles = makeStyles<any, { isReady: boolean }>((theme) => ({
  wrapper: {
    display: ({ isReady }) => (isReady ? "block" : "none"),
    marginBottom: theme.spacing(1),
  },
  group: {
    display: "flex",
    flexWrap: "wrap",
    [theme.breakpoints.up("sm")]: {
      alignItems: "center",
      flexWrap: "nowrap",
    },
  },
  number: {
    flexGrow: 2,
    width: "100% !important",
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up("sm")]: {
      width: "64% !important",
      marginRight: theme.spacing(1),
      marginBottom: 0,
    },
  },
  exp: {
    flexGrow: 1,
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up("sm")]: {
      flexGrow: 0,
      marginBottom: 0,
    },
  },
  cvv: {
    flexGrow: 1,
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up("sm")]: {
      flexGrow: 0,
      marginBottom: 0,
    },
  },
  loadingWrapper: {
    width: "100%",
    position: "relative",
    display: "flex",
    justifyContent: "center",
    marginBottom: theme.spacing(2),
  },
}))

const SplitItCreditCardForm = () => {
  const isInitialized = useAppSelector(selectIsSplitItInitialized)
  const publicToken = useAppSelector(selectSplitItPublicToken)
  const { isReady, setup } = useSplitItFlexFields()
  const classes = useStyles({ isReady })

  useEffect(() => {
    if (isInitialized) {
      return setup({
        container: "#splitit-card-data",
        fields: {
          number: {
            selector: "#splitit-card-number",
          },
          cvv: {
            selector: "#splitit-cvv",
          },
          expirationDate: {
            selector: "#splitit-expiration-date",
          },
          style: {
            fontSize: 14,
          },
        },
        errorBox: {
          selector: "#splitit-error-box",
        },
        publicToken,
      })
    }
  }, [isInitialized, publicToken])

  return (
    <>
      {!isReady && (
        <div className={classes.loadingWrapper}>
          <CircularProgress size={24} />
        </div>
      )}
      <div
        className={clsx("splitit-design-horizontal", classes.wrapper)}
        id="splitit-card-data"
      >
        <div className={classes.group}>
          <div
            className={classes.number}
            data-testid="splitit-card-number"
            id="splitit-card-number"
          />

          <div
            className={classes.exp}
            data-testid="splitit-expiration-date"
            id="splitit-expiration-date"
          />

          <div
            className={classes.cvv}
            data-testid="splitit-cvv"
            id="splitit-cvv"
          />
        </div>

        <div
          className="splitit-horizontal-cc-error-box"
          id="splitit-error-box"
        />
      </div>
    </>
  )
}

export default SplitItCreditCardForm
