import * as React from "react"

import { ReactComponent as BiomarkerIcon } from "app/assets/icons/biomarker.svg"
import { ReactComponent as InfoSquareIcon } from "app/assets/icons/info-square.svg"
import { ReactComponent as PersonIcon } from "app/assets/icons/person.svg"
import {
  ComparisonAboutLabRow,
  ComparisonAdditionalFeesRow,
  ComparisonBiomarkersRow,
  ComparisonCertificationsRow,
  ComparisonCollectionDaysRow,
  ComparisonCollectionInstructionsRow,
  ComparisonConsultationAvailabilityRow,
  ComparisonLabCompanyRow,
  ComparisonPhlebotomyRow,
  ComparisonPreparationStartsRow,
  ComparisonPricingRow,
  ComparisonProcessingMethodsRow,
  ComparisonProcessingTimeRow,
  ComparisonRowHeader,
  ComparisonSampleReportRow,
  ComparisonSampleTypeRow,
  ComparisonShipmentTrackingNumberRow,
  ComparisonShippingTimeRow,
} from "app/main/comparison/components/ComparisonLabTests/components"
import { COLUMN_PADDING, SECTION_PADDING } from "app/main/comparison/constants"
import { shadows } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"

import ComparisonWarningRow from "./ComparisonWarningRow"

const useStyles = makeAppStyles((theme) => ({
  sections: {
    display: "flex",
    flexDirection: "column",
    gap: COLUMN_PADDING,
  },
  section: {
    display: "flex",
    flexDirection: "column",
    gap: COLUMN_PADDING / 2,
    padding: SECTION_PADDING,
    backgroundColor: "white",
    borderRadius: 6,
    boxShadow: shadows.sm,
    [theme.breakpoints.down("sm")]: {
      padding: SECTION_PADDING / 1.5,
    },
  },
  sectionRows: {
    display: "flex",
    flexDirection: "column",
    gap: COLUMN_PADDING,
  },
}))

export interface ComparisonLabTestDetailsSectionProps {
  children: React.ReactNode
  classes: ReturnType<typeof useStyles>
  headerIcon?: React.ReactNode
  headerLabel: React.ReactNode
}

export const ComparisonLabTestDetailsSection = ({
  children,
  classes,
  headerIcon,
  headerLabel,
}: ComparisonLabTestDetailsSectionProps) => {
  return (
    <div className={classes.section}>
      <ComparisonRowHeader color="teal" icon={headerIcon}>
        {headerLabel}
      </ComparisonRowHeader>
      <div className={classes.sectionRows}>{children}</div>
    </div>
  )
}

export default function ComparisonLabTestDetails() {
  const classes = useStyles()
  return (
    <div className={classes.sections}>
      <ComparisonLabTestDetailsSection
        classes={classes}
        headerIcon={<InfoSquareIcon viewBox="0 0 12 13" />}
        headerLabel="Lab Test Information"
      >
        <ComparisonWarningRow />
        <ComparisonPricingRow />
        <ComparisonAdditionalFeesRow />
        <ComparisonSampleTypeRow />
        <ComparisonPhlebotomyRow />
        <ComparisonSampleReportRow />
        <ComparisonProcessingMethodsRow />
      </ComparisonLabTestDetailsSection>
      <ComparisonLabTestDetailsSection
        classes={classes}
        headerIcon={<PersonIcon viewBox="0 0 11 12" />}
        headerLabel="Patient Experience"
      >
        <ComparisonCollectionInstructionsRow />
        <ComparisonShippingTimeRow />
        <ComparisonShipmentTrackingNumberRow />
        <ComparisonProcessingTimeRow />
        <ComparisonPreparationStartsRow />
        <ComparisonCollectionDaysRow />
      </ComparisonLabTestDetailsSection>
      <ComparisonLabTestDetailsSection
        classes={classes}
        headerIcon={<InfoSquareIcon viewBox="0 0 12 13" />}
        headerLabel="Lab Company Information"
      >
        <ComparisonLabCompanyRow />
        <ComparisonConsultationAvailabilityRow />
        <ComparisonAboutLabRow />
        <ComparisonCertificationsRow />
      </ComparisonLabTestDetailsSection>
      <ComparisonLabTestDetailsSection
        classes={classes}
        headerIcon={<BiomarkerIcon viewBox="0 0 12 11" />}
        headerLabel="Biomarkers"
      >
        <ComparisonBiomarkersRow />
      </ComparisonLabTestDetailsSection>
    </div>
  )
}
