import { ReactComponent as BarChartIcon } from "app/assets/icons/bar-chart.svg"
import {
  ComparisonRow,
  ComparisonColumnProps,
  EmptyComparisonValue,
} from "app/main/comparison/components/ComparisonLabTests/components"
import useAnyComparedLabTestHasProperty from "app/main/comparison/hooks/use-any-compared-lab-test-has-property"
import { pluralizeDays } from "app/main/comparison/utils"

const ProcessingTimeColumn = ({ labTest }: ComparisonColumnProps) => {
  return (
    <>
      {labTest?.estimated_days_for_results ? (
        `${labTest.estimated_days_for_results} ${pluralizeDays(
          labTest.estimated_days_for_results
        )}`
      ) : (
        <EmptyComparisonValue />
      )}
    </>
  )
}

export default function ComparisonProcessingTimeRow() {
  const hasEstimatedDaysForResults = useAnyComparedLabTestHasProperty(
    "estimated_days_for_results"
  )
  if (!hasEstimatedDaysForResults) {
    return null
  }
  return (
    <ComparisonRow
      Column={ProcessingTimeColumn}
      headerIcon={<BarChartIcon viewBox="0 0 20 15" />}
      headerLabel="Turnaround Time"
    />
  )
}
