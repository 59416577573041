import * as React from "react"

import { ReactComponent as CloseIcon } from "app/assets/icons/close-icon.svg"
import { NavyTooltip } from "app/components/NavyTooltip"
import useBiomarkerKitNames from "app/results-summary/hooks/use-biomarker-kit-names"
import { UseResultsVisualizerResourcesHook } from "app/results-summary/hooks/use-results-summary-resources"
import { bodyFontFamilyImportant, colors } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"

const useStyles = makeAppStyles((theme) => ({
  title: {
    color: colors.blueGray[500],
    fontSize: 13,
    fontFamily: bodyFontFamilyImportant,
    textTransform: "uppercase",
  },
  biomarkersList: {
    width: "100%",
    display: "flex",
    flexFlow: "column nowrap",
    marginTop: theme.spacing(2.0),
    gap: theme.spacing(3.0),
  },
  addBiomarkerSlot: {
    width: "100%",
    marginTop: theme.spacing(2.0),
  },
  divider: {
    background: colors.blueGray[100],
  },
  outOfRangeBiomarkerTooltip: {
    display: "flex",
    flexDirection: "row",
  },
  outOfRangeTooltipText: {
    width: 250,
  },
  outOfRangeTooltipCloseIcon: {
    cursor: "pointer",
  },
}))

export default function OutOfRangeBiomarkersTooltip({
  children,
  outOfRangeBiomarkers,
}: {
  children: NonNullable<React.ReactElement>
  outOfRangeBiomarkers: UseResultsVisualizerResourcesHook["outOfRangeBiomarkers"]
}) {
  const classes = useStyles()
  const { outOfRangeIdentifiers, setOutOfRangeIdentifiers } =
    outOfRangeBiomarkers
  const isOpen = outOfRangeIdentifiers.length > 0

  const kitNames = useBiomarkerKitNames(outOfRangeIdentifiers)

  return (
    <NavyTooltip
      arrow
      interactive
      placement="bottom"
      open={isOpen}
      title={
        <div className={classes.outOfRangeBiomarkerTooltip}>
          <div className={classes.outOfRangeTooltipText}>
            <p>
              Rupa was able to automatically retrieve out of range biomarkers
              from {joinStringsGrammatically(kitNames)}.
            </p>
            <br />
            <p>Please review other tests for out of range results.</p>
          </div>
          <CloseIcon
            className={classes.outofRangeTooltipCloseIcon}
            onClick={() => setOutOfRangeIdentifiers([])}
          />
        </div>
      }
    >
      {children}
    </NavyTooltip>
  )
}

/**
 * Takes an array of string and joins them with commas and an "and"
 * @param arr of strings
 * @returns string joined together gramatically
 */
function joinStringsGrammatically(arr: String[]): String {
  if (arr.length === 1) return arr[0]
  const firsts = arr.slice(0, arr.length - 1)
  const last = arr[arr.length - 1]
  return firsts.join(", ") + " and " + last
}
