import { useEffect } from "react"

import { useLocation, useParams } from "react-router-dom"

import Loading from "app/components/Loading"

import useLegacyRedirect from "./hooks/use-legacy-redirect"
import { buildPatientPortalUserUrl } from "./util"

export default function PatientPortalLegacyRedirect() {
  const location = useLocation()
  const supportPage = location.pathname.includes("support")

  const { patientId, orderId } = useParams<{
    patientId: string
    orderId: string
  }>()

  const { data, isLoading } = useLegacyRedirect(
    patientId,
    orderId,
    supportPage,
    location.pathname
  )

  useEffect(() => {
    if (data?.redirectUrl) {
      // If we have a redirect URL, go there
      window.location.href = data.redirectUrl
    }
    if (!isLoading && !data?.redirectUrl) {
      // Fall back on just going to top level page
      window.location.href = buildPatientPortalUserUrl()
    }
  }, [data, isLoading])

  return <Loading ariaLabel="Loading patient portal" />
}
