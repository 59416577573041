import { useState, useEffect, useMemo } from "react"
import { useDispatch } from "react-redux"

import { useHistory, useLocation } from "react-router-dom"
import { useSearchParam } from "react-use"

import { Tab, Tabs } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

import { AllowAnyPaths, GuestPaths } from "app/Routes"
import { PatientPortalPatientPaths } from "app/Routes/paths/patient-portal-paths"
import { API } from "app/api"
import { ReactComponent as PatientUserIcon } from "app/assets/icons/rupa-blood-dashboards/patient-user-icon.svg"
import { ReactComponent as PractitionerUserIcon } from "app/assets/icons/rupa-blood-dashboards/practitioner-user-icon.svg"
import {
  PATIENT_PORTAL_SOURCE,
  PATIENT_PORTAL_TRACKING_EVENTS,
  trackPatientPortalEvent,
} from "app/services/segment"
import { practitionerSignup } from "app/settings"
import { primaryColor } from "app/theme"
import { colors, shadows } from "app/theme"
import { getBaseUrl } from "app/utils"

import { requestPasswordReset, resetPassword } from "../../auth/store/actions"
import LandingPage from "../patient-signup/LandingPage"
import NewPasswordModal from "./NewPasswordModal"
import PasswordResetModal from "./PasswordResetModal"
import LoginTab from "./tabs/LoginTab"
import { ROLES } from "./types"

const useStyles = makeStyles((theme) => ({
  form: {
    border: `1px solid ${colors.blueGray[200]}`,
    borderRadius: 6,
    background: "white",
    padding: "12px 24px 24px 24px",
    marginTop: 12,
    display: "flex",
    flexFlow: "column",
    alignItems: "center",
    boxShadow: shadows.md,
    minWidth: 316,

    [theme.breakpoints.up("sm")]: {
      padding: "24px 42px 42px 42px",
      minWidth: 450,
    },
  },
  tabHeader: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    [theme.breakpoints.up("sm")]: {
      padding: "0 32px",
    },
  },
  unselectedTabText: {
    color: colors.blueGray[400],
  },
  tabs: {
    marginBottom: 20,
  },
}))

const ROLE_TABS = {
  [ROLES.PRACTITIONER]: {
    label: "Practitioner",
    value: ROLES.PRACTITIONER,
    icon: (props) => <PractitionerUserIcon {...props} />,
    signupLink: practitionerSignup,
  },
  [ROLES.PATIENT]: {
    label: "Patient",
    value: ROLES.PATIENT,
    icon: (props) => <PatientUserIcon {...props} />,
    // TODO https://app.asana.com/0/1205660765244454/1205660767871111/f: Update this link to point to the patient sign up page.
    signupLink: getBaseUrl() + "/patient_signup",
  },
}

function Login() {
  const { form, tabHeader, unselectedTabText, tabs } = useStyles()
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()

  const searchParams = new URLSearchParams(location.search)
  const token = useSearchParam("token")
  const email = useSearchParam("email")
  const signupEmail = useSearchParam("signup-email")
  const role = useSearchParam("role")
  const next = useSearchParam("next")
  const patientPortalToken = useSearchParam("patient-portal-token")
  const defaultRole = role === "patient" ? ROLES.PATIENT : ROLES.PRACTITIONER

  const [currentTab, setCurrentTab] = useState<string | null>(null)

  // fall back to practitioner tab if no tab is set
  const nonNullCurrentTab = currentTab ?? ROLES.PRACTITIONER

  useEffect(() => {
    // Wait until search params before setting current tab
    if (defaultRole && searchParams) {
      setCurrentTab(defaultRole)
    }
  }, [defaultRole])

  const signUpLink = useMemo(() => {
    const link = new URL(ROLE_TABS[nonNullCurrentTab].signupLink)

    if (next) {
      link.searchParams.append("next", next)
    }

    if (patientPortalToken && nonNullCurrentTab === ROLES.PATIENT) {
      link.searchParams.append("patient-portal-token", patientPortalToken)
    }

    if (signupEmail && nonNullCurrentTab === ROLES.PATIENT) {
      link.searchParams.append("signup-email", signupEmail)
    }

    return link.href
  }, [next, nonNullCurrentTab, patientPortalToken, signupEmail])

  useEffect(() => {
    const query = new URLSearchParams(window.location.search)
    let queryModified = false
    // If the patient tab is selected with no next param or the next param does not go to patient portal
    // then we want to redirect to patient portal root
    if (
      currentTab === ROLES.PATIENT &&
      (!next || !next.startsWith(PatientPortalPatientPaths.ROOT))
    ) {
      query.set("next", PatientPortalPatientPaths.ROOT)
      queryModified = true
    }

    // If the practitioner tab is selected and the next param goes to patient portal
    // then remove the next param
    if (
      currentTab === ROLES.PRACTITIONER &&
      next?.startsWith(PatientPortalPatientPaths.ROOT)
    ) {
      query.delete("next")
      queryModified = true
    }

    // If practitioner tab is selected, then remove role param regardless
    if (currentTab === ROLES.PRACTITIONER) {
      query.delete("role")
      queryModified = true
    }

    if (queryModified) {
      history.replace({
        ...location,
        search: query.toString(),
      })
    }
  }, [currentTab, next])

  useEffect(() => {
    if (currentTab === ROLES.PATIENT) {
      let source = PATIENT_PORTAL_SOURCE.WEBSITE
      if (email) {
        source = PATIENT_PORTAL_SOURCE.POST_PATIENT_CHECKOUT
      } else if (signupEmail) {
        source = PATIENT_PORTAL_SOURCE.EMAIL
      }
      trackPatientPortalEvent(
        PATIENT_PORTAL_TRACKING_EVENTS.LOGIN_PAGE_VIEWED,
        {
          source,
        }
      )
    }
  }, [currentTab])

  const [forgotPasswordModalOpen, setForgotPasswordModalOpen] = useState(false)
  const [passwordResetTokenExpired, setPasswordResetTokenExpired] =
    useState(false)
  const [newPasswordModalOpen, setNewPasswordModalOpen] = useState(false)

  useEffect(() => {
    if (!!token && !!email) {
      const checkTokenValidity = async () => {
        try {
          await API.PasswordResetToken.validate(token)
          setPasswordResetTokenExpired(false)
        } catch (error) {
          setPasswordResetTokenExpired(true)
        }

        setNewPasswordModalOpen(true)
      }

      checkTokenValidity()
    }
  }, [token, email])

  const toggleForgotPasswordModal = () => {
    setForgotPasswordModalOpen(!forgotPasswordModalOpen)
  }

  const handleResetPassword = (email: string) => {
    dispatch(requestPasswordReset(email))
  }

  const handleChangePassword = async (password: string) => {
    let isSetPassword = false

    if (location.pathname === AllowAnyPaths.SET_PASSWORD) {
      isSetPassword = true
    }

    return dispatch(
      resetPassword(history, token, email, password, isSetPassword, defaultRole)
    )
  }

  const onCloseNewPasswordModal = () => {
    setNewPasswordModalOpen(false)

    const query = new URLSearchParams(window.location.search)
    const next = query.get("next")

    // Redirect with the next param if it's present in the URL.
    if (next) {
      history.push(`${GuestPaths.LOGIN}?next=${next}`)
    } else {
      history.push(`${GuestPaths.LOGIN}`)
    }
  }

  const handleTabChange = (event, newTab) => {
    setCurrentTab(newTab)
  }

  return (
    <LandingPage title="Sign in to your account.">
      <div className={form}>
        <Tabs
          value={nonNullCurrentTab}
          onChange={handleTabChange}
          textColor="primary"
          TabIndicatorProps={{
            style: {
              backgroundColor: primaryColor,
            },
          }}
          aria-label="secondary tabs example"
          variant="scrollable"
          className={tabs}
        >
          {Object.keys(ROLE_TABS).map((role) => (
            <Tab
              key={role}
              label={
                <div className={tabHeader}>
                  {ROLE_TABS[role].icon({
                    fill:
                      nonNullCurrentTab === ROLE_TABS[role].value
                        ? primaryColor
                        : colors.blueGray[400],
                    viewBox: "0 0 25 25",
                    height: 40,
                    width: 40,
                  })}
                  <span
                    className={
                      nonNullCurrentTab !== ROLE_TABS[role].value
                        ? unselectedTabText
                        : undefined
                    }
                  >
                    {ROLE_TABS[role].label}
                  </span>
                </div>
              }
              value={ROLE_TABS[role].value}
              style={{
                borderBottom: `2px solid ${colors.blueGray[200]}`,
              }}
            />
          ))}
        </Tabs>

        <LoginTab
          signUpLink={signUpLink}
          toggleForgotPasswordModal={toggleForgotPasswordModal}
          role={nonNullCurrentTab}
          initialEmail={email}
        />
      </div>

      <PasswordResetModal
        open={forgotPasswordModalOpen}
        onClose={() => setForgotPasswordModalOpen(false)}
        onResetPassword={handleResetPassword}
      />

      <NewPasswordModal
        open={newPasswordModalOpen}
        tokenExpired={passwordResetTokenExpired}
        openForgotPasswordModal={toggleForgotPasswordModal}
        onClose={onCloseNewPasswordModal}
        onChangePassword={handleChangePassword}
      />
    </LandingPage>
  )
}

export default Login
