import { Collapse, Link } from "@material-ui/core"

import { ReactComponent as ArrowTopRightIcon } from "app/assets/icons/arrow-top-right.svg"
import InfoTextTooltip from "app/components/design-system/InfoTextTooltip"
import RupaLogoIcon from "app/components/svgs/RupaLogoIcon"
import {
  PHYSICIAN_AUTHORIZATION_GUIDE_URL,
  VENDOR_PHYSICIAN_AUTHORIZATION_LABEL,
} from "app/constants"
import { physAuthBannerColor, primaryColor } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"

const useStyles = makeAppStyles({
  warning: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    backgroundColor: physAuthBannerColor,
  },
  warningWrapperInner: {
    height: 31,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: 5,
    paddingTop: 5,
    paddingBottom: 5,
  },
  text: {
    textTransform: "uppercase",
    fontWeight: 600,
    color: "white",
  },
  logo: {
    border: "1px solid white",
    borderRadius: "50%",
  },
  infoText: {
    display: "inline-flex",
  },
  learnMore: {
    display: "inline-flex",
    alignItems: "center",
    gap: 4,
    color: primaryColor,
  },
})

export default function PhysicianAuthorizationWarning({
  show = false,
}: {
  show: boolean
}) {
  const classes = useStyles()
  return (
    <Collapse
      className={classes.warning}
      classes={{
        wrapperInner: classes.warningWrapperInner,
      }}
      timeout={300}
      in={show}
    >
      <RupaLogoIcon className={classes.logo} height={16} width={16} />
      <div className={classes.text}>{VENDOR_PHYSICIAN_AUTHORIZATION_LABEL}</div>
      <InfoTextTooltip className={classes.infoText} iconFill="white">
        With {VENDOR_PHYSICIAN_AUTHORIZATION_LABEL}, you can request a signing
        physician to order your clients lab testing. The signing physician is
        responsible for reviewing, ordering, and checking lab results for
        critical values.{" "}
        <Link
          className={classes.learnMore}
          href={PHYSICIAN_AUTHORIZATION_GUIDE_URL}
          target="_blank"
        >
          Learn More <ArrowTopRightIcon viewBox="0 0 12 12" />
        </Link>
      </InfoTextTooltip>
    </Collapse>
  )
}
