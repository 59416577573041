import { FC, useState } from "react"
import { useDispatch } from "react-redux"

import { Dialog, makeStyles, Theme } from "@material-ui/core"

import { CustomFee, StripeConnectAccount } from "app/types"
import { handleApiError } from "app/utils"

import CustomFeesOnboardingModalStepOne from "./CustomFeesOnboardingModalStepOne"
import CustomFeesOnboardingModalStepTwo from "./CustomFeesOnboardingModalStepTwo"

const styles = (theme: Theme) => ({
  dialogPaper: {
    [theme.breakpoints.up("md")]: {
      minWidth: 400,
    },
    maxWidth: 700,
    margin: 15,
    borderRadius: 7,
  },
})

const useStyles = makeStyles(styles)

type CustomFeesOnboardingModalProps = {
  open: boolean
  onClose: () => void
  customFee?: CustomFee
  stripeConnectAccount?: StripeConnectAccount
  createStripeConnectAccount: () => Promise<StripeConnectAccount>
  createOrUpdateCustomFee: (
    account: StripeConnectAccount,
    customFee: CustomFee | undefined,
    newCustomFee: Pick<CustomFee, "price" | "name">
  ) => Promise<void>
  getDashboardLink: (account: StripeConnectAccount) => Promise<string>
  enableCustomFee?: () => void
  isClinicLevelSetup: boolean
}

const CustomFeesOnboardingModal: FC<CustomFeesOnboardingModalProps> = ({
  open,
  onClose,
  stripeConnectAccount,
  createStripeConnectAccount,
  customFee,
  createOrUpdateCustomFee,
  enableCustomFee,
  getDashboardLink,
  isClinicLevelSetup,
}) => {
  const classes = useStyles(styles)
  const [onboardingStep, setOnboardingStep] = useState(1)
  const [completeOnboardingLoading, setCompleteOnboardingLoading] =
    useState(false)

  const dispatch = useDispatch()

  const completeOnboarding = async (
    price: string | undefined,
    name: string | undefined,
    createCustomFee: boolean
  ) => {
    try {
      setCompleteOnboardingLoading(true)
      if (!stripeConnectAccount) {
        try {
          stripeConnectAccount = await createStripeConnectAccount()
        } catch (error: any) {
          dispatch(handleApiError(error))
          setCompleteOnboardingLoading(false)
          return
        }
      }

      if (createCustomFee && name && price) {
        await createOrUpdateCustomFee(stripeConnectAccount, customFee, {
          price: Number((Number(price) * 100).toFixed()),
          name,
        })

        enableCustomFee?.()
      }

      const dashboardLink = await getDashboardLink(stripeConnectAccount)

      window.location.href = dashboardLink
    } catch (error) {
      dispatch(handleApiError(error))
      setCompleteOnboardingLoading(false)
    }
  }

  let stepOneContinueFunc = () => setOnboardingStep(2)
  if (!isClinicLevelSetup) {
    stepOneContinueFunc = () => completeOnboarding(undefined, undefined, false)
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="custom-fees-onboarding-modal"
      classes={{
        paper: classes.dialogPaper,
      }}
    >
      {onboardingStep === 1 ? (
        <CustomFeesOnboardingModalStepOne
          onClose={onClose}
          isClinicLevelSetup={isClinicLevelSetup}
          nextStep={stepOneContinueFunc}
        />
      ) : (
        <CustomFeesOnboardingModalStepTwo
          defaultName={customFee?.name}
          defaultPrice={customFee?.price}
          maxPrice={stripeConnectAccount?.max_price}
          loading={completeOnboardingLoading}
          onClose={onClose}
          nextStep={({ price, name }) => completeOnboarding(price, name, true)}
        />
      )}
    </Dialog>
  )
}

export default CustomFeesOnboardingModal
