import { useController, useFormContext } from "react-hook-form"

import TextField, { TextFieldProps } from "./TextField"

export type ControlledTextFieldProps = Omit<
  TextFieldProps,
  "error" | "helperText" | "onBlur" | "onChange" | "value"
> & {
  defaultValue?: string
  name: string
  required?: boolean
  showErrors?: boolean
  shouldUnregister?: boolean
}

const ControlledTextField = ({
  defaultValue,
  name,
  shouldUnregister,
  showErrors = true,
  ...textFieldProps
}: ControlledTextFieldProps) => {
  const { control } = useFormContext()
  const {
    field: { ref, value, ...controlProps },
    fieldState: { error },
  } = useController({
    control,
    defaultValue: defaultValue || "",
    name: `${name}` as const,
    shouldUnregister,
  })
  return (
    <TextField
      error={Boolean(error) && showErrors}
      helperText={showErrors && error?.message}
      inputRef={ref}
      value={value ?? ""}
      {...textFieldProps}
      {...controlProps}
    />
  )
}

export default ControlledTextField
